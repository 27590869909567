import { AgentStatus, IAgentDetail } from '../../../ApplicationState/ApiClient';
import { TickOrCrossIcon } from '../../../Components/FusionShift/Icons/TickOrCrossIcon';
import { AgentHelper } from '../../../Helpers/AgentHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    agent: IAgentDetail
}

export const ActionReadiness = (props: Props) => {

    if (props.agent.status !== AgentStatus.Covert && props.agent.status !== AgentStatus.Overt && props.agent.status !== AgentStatus.Stowaway) {
        return null;
    }

    const actionReady = AgentHelper.readyForAction(props.agent);

    const readyDate = props.agent.actionCooldown;
    const fleetDate = props.agent.fleetPosition?.arrivalDate;

    const dateToUse = readyDate === undefined ? fleetDate :
        fleetDate !== undefined && fleetDate.getTime() > readyDate.getTime() ? fleetDate : readyDate;

    return <>
        <TickOrCrossIcon type={actionReady ? "tick" : "cross"} />
        {actionReady ? "Ready for next action" : `Action ready ${ValueFormatter.formatDateFromNowWithMoment(dateToUse!)}`}
    </>;
}