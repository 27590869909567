import { FleetMovementType } from "../../ApplicationState/ApiClient";
import { FederationAccoladesView } from "../../Views/Accolades/FederationAccoladesView";
import { PlayerAccoladesView } from "../../Views/Accolades/PlayerAccoladesView";
import { ChangeArchiveView } from "../../Views/Changes/ChangeArchiveView";
import { ChatIndex } from "../../Views/Chat/ChatIndex";
import { CombatSimulatorView } from "../../Views/CombatSimulator/CombatSimulatorView";
import { DevelopedByView } from "../../Views/DevelopedBy/DevelopedByView";
import { EmpireView } from "../../Views/Empire/EmpireView";
import { FleetsIndex } from "../../Views/Fleets/FleetsIndex";
import { SearchView } from "../../Views/Search/SearchView";
import { SendShipsView } from "../../Views/SendShips/SendShipsView";
import { VictoryProgressView } from "../../Views/World/VictoryProgress/VictoryProgressView";
import { route } from "./Root";

export const combatSimulator = "/combatsimulator";
export const changeArchive = "/changearchive";
export const playerAccolades = "/accolades/player";
export const federationAccolades = "/accolades/federation";
export const empire = "/empire";
export const send_ships = "/ships/send";
export const fleets = "/fleets";
export const chat = "/chat";
export const developedBy = "/developedby";
export const inviteFriends = "/invites";
export const search = "/search";
export const victoryProgress = "/victoryprogress";

export function send_ships_to(targetX: number, targetY: number, movementType: FleetMovementType) {
  return `${send_ships}?targetX=${targetX}&targetY=${targetY}&movementType=${movementType}`;
}

export function send_ships_edit_repeat(repeatFleetId: number) {
  return `${send_ships}?repeatFleetId=${repeatFleetId}`;
}

export function send_ships_to_contract(targetX: number, targetY: number, movementType: FleetMovementType, contractId: number) {
  return `${send_ships}?targetX=${targetX}&targetY=${targetY}&movementType=${movementType}&contractId=${contractId}`;
}

export function search_with_term(searchTerm: string) {
  return `${search}?searchTerm=${searchTerm}`;
}

export function combatSimulator_simulation(simulation: string) {
  return `${combatSimulator}?simulation=${simulation}`;
}

export const miscRoutes = [
  route(combatSimulator, CombatSimulatorView, "Combat Simulator"),
  route(playerAccolades, PlayerAccoladesView, "Accolades"),
  route(federationAccolades, FederationAccoladesView, "Federation Accolades"),
  route(empire, EmpireView, "Empire"),
  route(send_ships, SendShipsView, "Send Ships"),
  route(fleets, FleetsIndex, "Fleets"),
  route(chat, ChatIndex, "Chat"),
  route(developedBy, DevelopedByView, "Credits"),
  route(search, SearchView, "Search"),
  route(changeArchive, ChangeArchiveView, "Change Archive"),
  route(victoryProgress, VictoryProgressView, "Victory Progress")
];
