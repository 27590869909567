import { observer } from "mobx-react-lite";
import React from "react";
import { AddItemsAndShipsInput, IAddItemsAndShipsInput, ISolarSystemMinimal, ItemCategory, ItemType, Player, ShipClass, ShipType } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { H3 } from "../../../Components/Base/Text/H";
import { ItemIconWithQuantity } from "../../../Components/FusionShift/Icons/Items/ItemIconWithQuantity";
import { ShipTypeLink } from "../../../Components/FusionShift/Links/ShipTypeLink";
import { ShipQuantities } from "../../../Entities/Shared";
import { IconHelper } from "../../../Helpers/IconHelper";

type Props = {
    playerId: number | undefined,
    messageCallback: (message: string) => any
}

export const AddItemsAndShipsForm = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const worldState = React.useContext(WorldStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [itemQuantities, setItemQuantities] = React.useState<{ [key: string]: number; }>({});
    const [shipQuantities, setShipQuantites] = React.useState<ShipQuantities>({});

    const [player, setPlayer] = React.useState<Player | undefined>(undefined);
    const [solarSystems, setSolarSystems] = React.useState<ISolarSystemMinimal[] | undefined>(undefined);

    const [targetSolarSystemId, setTargetSolarSystemId] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (props.playerId) {
            apiState.PlayerClient.get(props.playerId).then(player => {
                if (player) {
                    setPlayer(player);
                }
            });
            apiState.AdminClient.listSolarSystemsByPlayerId(props.playerId).then(solarSystems => {
                if (solarSystems) {
                    setSolarSystems(solarSystems);
                }
            });
        } else {
            setSolarSystems(undefined);
            setPlayer(undefined);
        }
    }, [props.playerId]);

    React.useEffect(() => {
        if (!targetSolarSystemId && solarSystems && solarSystems.length > 0) {
            setTargetSolarSystemId(solarSystems[0].solarSystemId);
        }
    }, [solarSystems]);

    function cheat() {
        if (targetSolarSystemId) {
            const model: IAddItemsAndShipsInput = {
                ships: shipQuantities,
                items: itemQuantities
            };
            return apiState.CheatClient.addItemsAndShips(props.playerId, targetSolarSystemId, new AddItemsAndShipsInput(model)).then(_ => {
                props.messageCallback("Added resources and/or ships");
            })
        }
    }

    function setShipQuantity(ship: ShipType, quantity: number) {
        shipQuantities[ship.typeName || ""] = quantity;
        setShipQuantites(shipQuantities);
    }

    function setItemQuantity(resource: ItemType, quantity: number) {
        itemQuantities[resource.typeName || ""] = quantity;
        setItemQuantities(itemQuantities);
    }


    if (!appState.IsAdmin || !solarSystems || !player || !worldState.ItemTypeSettings || !worldState.ShipTypeSettings) {
        return <LoadingSpinner />;
    }

    const shipsToAdd = Object.keys(worldState.ShipTypeSettings.data!)
        .map(s => worldState.ShipTypeSettings!.data![s])
        .filter(s => s.factionTypeName === player.factionTypeName && s.class !== ShipClass.Drone)
        .sort(((a, b) => a.order > b.order ? 1 : -1));

    if (Object.keys(shipQuantities).length === 0) {
        shipsToAdd.forEach(s => shipQuantities[s.typeName || ""] = 0);
    }

    const resourcesToAdd = Object.keys(worldState.ItemTypeSettings.data!)
        .map(s => worldState.ItemTypeSettings!.data![s])
        .filter(s => s.category === ItemCategory.Resource)
        .sort(((a, b) => a.order > b.order ? 1 : -1));
    if (Object.keys(itemQuantities).length === 0) {
        resourcesToAdd.forEach(s => itemQuantities[s.typeName || ""] = appState.IsDebug ? 25000 : 0);
    }

    return <>
        <div className="field is-horizontal">
            <div className="field-label is-small">
                <label className="label">Target Solar System</label>
            </div>
            <div className="field-body">
                <div className="field is-small">
                    <div className="select is-small">
                        <select onChange={(e) => setTargetSolarSystemId(Number(e.target.value))} defaultValue={targetSolarSystemId || ""}>
                            {solarSystems.map(c =>
                                <option key={c.solarSystemId} value={c.solarSystemId}>
                                    {c.name}
                                </option>)
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div className="tile is-ancestor">
            <div className="tile is-parent">
                <div className="tile is-child">
                    <H3>Add Items</H3>
                    <div className="">
                        {resourcesToAdd.map(s =>
                            <div key={s.typeName} className="field is-horizontal">
                                <div className="field-label">
                                    <label className="label">
                                        <ItemIconWithQuantity itemType={s} />
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-small">
                                        <div className="control">
                                            <input
                                                className="input is-small input-5"
                                                type="number"
                                                size={5}
                                                onFocus={e => e.target.select()}
                                                defaultValue={itemQuantities[s.typeName || ""].toString()}
                                                onChange={e => setItemQuantity(s, Number(e.target.value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>
            <div className="tile is-parent">
                <div className="tile is-child">
                    <H3>Add Ships</H3>
                    <div className="">
                        {shipsToAdd.map(s =>
                            <div key={s.typeName} className="field is-horizontal">
                                <div className="field-label is-small">
                                    <label className="label"><ShipTypeLink shipType={s} /></label>
                                </div>
                                <div className="field-body">
                                    <div className="field is-small">
                                        <div className="control">
                                            <input
                                                className="input is-small input-5"
                                                type="number"
                                                size={5}
                                                onFocus={e => e.target.select()}
                                                defaultValue={shipQuantities[s.typeName || ""].toString()}
                                                onChange={e => setShipQuantity(s, Number(e.target.value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </div>
        </div>
        <div className="control">
            <Button
                isDisabled={targetSolarSystemId === undefined}
                type="action"
                icon={IconHelper.General.Confirm}
                action={() => cheat()}
                text="Add Items and/or Ships"
            />
        </div></>;
});