import { observer } from "mobx-react-lite";
import * as React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { FederationIncoming, FleetMovementType, SolarSystemDetail } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, SolarSystemStateContext } from "../../ApplicationState/ContextRoot";
import { choice, MultiButtonChooser } from "../../Components/Base/Buttons/ButtonChooser";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Table } from "../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { FederationLink } from "../../Components/FusionShift/Links/FederationLink";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { SolarSystemLink } from "../../Components/FusionShift/Links/SolarSystemLink";
import { FleetHelper } from "../../Helpers/FleetHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { valuesFromEnum } from "../../Helpers/TypeHelper";
import { help_page } from "../../Navigation/Routing/Help";
import { SimpleBaseView } from "../BaseView";
import { FleetTable } from "./SubParts/FleetTable";

const hostileMovements = valuesFromEnum(FleetMovementType).filter(FleetHelper.isHostileMovementType);

export const FederationIncomingFleets = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [fleetMovementTypes, setFleetMovementTypes] = React.useState<FleetMovementType[]>(hostileMovements);

    const [incoming, setIncoming] = React.useState<FederationIncoming | undefined>(undefined);

    function reloadCallback(solarSystemDetail: SolarSystemDetail) {
        solarSystemState.loadSolarSystemIfRelevant(solarSystemDetail);
    }

    function fleetMovementTypeChanged(value: FleetMovementType, isSet: boolean) {
        if (isSet) {
            setFleetMovementTypes([...fleetMovementTypes, value]);
        } else {
            setFleetMovementTypes(fleetMovementTypes.filter(x => x !== value));
        }

        setIncoming(undefined);
    }

    React.useEffect(() => {

        if (playerState.Player?.federation !== undefined && incoming === undefined) {
            apiState.FleetClient.getIncomingForFederation(fleetMovementTypes).then(setIncoming);
        }

    }, [playerState.Player, setIncoming]);


    if (playerState.Player === undefined || !solarSystemState.CurrentSolarSystem) {
        return null;
    }

    const availableShips = solarSystemState.CurrentSolarSystem.availableShips;
    const choices = hostileMovements.map(x => choice<FleetMovementType>(x, FleetMovementType[x], IconHelper.Ships.movementType(x)));

    return <SimpleBaseView heading={{
        text: "Incoming Federation Fleets",
        icon: IconHelper.Ships.Ship
    }}
    >
        {playerState.Player.federation === undefined &&
            <Paragraph type="danger">
                You must be in a federation to use this view. See <Link to={help_page("federations")}>federations help</Link> for more information.
            </Paragraph>}
        {playerState.Player.federation !== undefined &&
            <SubPanel
                heading={"Fleets"}
                headingContent={
                    <MultiButtonChooser
                        value={fleetMovementTypes}
                        values={choices}
                        valueChanged={fleetMovementTypeChanged}
                    />
                }>
                <Paragraph>
                    Showing incoming hostile fleets for all systems claimed by members of <FederationLink federation={playerState.Player.federation} />.
                </Paragraph>
                {incoming === undefined && <LoadingSpinner />}
                {incoming !== undefined && <>
                    {incoming.apocalypseShots.length > 0 && <SubPanel
                        heading={{
                            text: "Apocalypse Shots",
                            icon: IconHelper.Celestials.Apocalypse
                        }}
                        isUnpadded
                    >
                        <Table isFullWidth
                            heading={<>
                                <th />
                                <th>Source</th>
                                <th>Target</th>
                                <th className="is-hidden-mobile">Owner</th>
                            </>}
                        >
                            {incoming.apocalypseShots.map(x => <tr key={x.apocalypseShotId}>

                                <td>
                                    <Countdown date={x.nextUpdateDate} />
                                </td>
                                <td>
                                    <SolarSystemLink solarSystem={x.sourceSolarSystem} />
                                </td>
                                <td>
                                    <SolarSystemLink solarSystem={x.targetSolarSystem} />
                                </td>
                                <td className="is-hidden-mobile">
                                    <PlayerLink player={x.sourceSolarSystem.owner} />
                                </td>
                            </tr>)}
                        </Table>
                    </SubPanel>}
                    <SubPanel
                        heading={{
                            text: "Fleets",
                            icon: IconHelper.Ships.Ship
                        }}
                        isUnpadded={incoming.fleets.length > 0}
                    >
                        {incoming.fleets.length === 0 && <Paragraph>No incoming fleets</Paragraph>}
                        {incoming.fleets.length > 0 &&
                            <FleetTable
                                fleets={incoming.fleets}
                                reloadCallback={reloadCallback}
                                availableShips={availableShips}
                                display={{
                                    arrivalDate: true,
                                    source: true,
                                    target: true,
                                    mass: true,
                                    headings: "hide on mobile",
                                    owner: "with linebreak",
                                    targetOwner: "with linebreak",
                                    countPerPage: 50,
                                }}
                            />}
                    </SubPanel>
                </>}
            </SubPanel>}
    </SimpleBaseView>;
});