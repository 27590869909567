import * as React from 'react';
import { MissionType, PlayerMissionLog } from '../../ApplicationState/ApiClient';
import { AppStateContext } from '../../ApplicationState/ContextRoot';
import { ViewButton } from '../../Components/Base/Buttons/ViewButton';
import { Table } from '../../Components/Base/Containers/Table';
import { ProgressBar } from '../../Components/Base/ProgressBar';
import { IconHelper } from '../../Helpers/IconHelper';
import { mission } from '../../Navigation/Routing/Help';
import { MissionStatus } from './MissionStatus';
import { MissionWrapper } from './MissionWrapper';

type Props = {
    category: string,
    missions: MissionWrapper[],
    missionTypes: { [key: string]: MissionType },
    missionLog: PlayerMissionLog
}

export const MissionTable = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    const { num, sum } = React.useMemo(() => {

        const missionsInCategory = Object.values(props.missionTypes)
            .filter(x => x.category === props.category);

        const n = missionsInCategory.filter(x => {
            if (props.missionLog.completedMissions.includes(x.typeName)) {
                return true;
            }
            if (props.missionLog.rewardPendingMissions.includes(x.typeName)) {
                return true;
            }
            return false;
        })
            .length;
        const s = missionsInCategory.length;

        return { num: n, sum: s };

    }, [props.missions, props.category]);

    if (props.missions.length == 0) {
        return null;
    }

    return <Table
        isHoverable
        isFullWidth
        isFixed
        heading={
            <>
                <th className="is-hidden-mobile">Mission</th>
                <th colSpan={2}>
                    <ProgressBar
                        max={sum}
                        value={num}
                        text={`${num} / ${sum}`}
                        leftIcon={IconHelper.General.Confirm}
                        danger={0}
                        warning={0}
                    />
                </th>
            </>
        }>
        {props.missions.map(m =>
            <tr key={m.MissionType.typeName} className="is-clickable" onClick={() => appState.navigate(mission(m.MissionType.typeName))}>
                <td>
                    {m.MissionType.name}
                </td>
                <td className="is-hidden-mobile">
                    <MissionStatus mission={m} />
                </td>
                <td className="is-content-width">
                    <span className="is-hidden-tablet">
                        <MissionStatus mission={m} />
                    </span>
                    <ViewButton to={mission(m.MissionType.typeName)} hideTextOnMobile />
                </td>
            </tr>)}
    </Table>;
};