import * as React from 'react';
import { AdminWorld } from '../../../ApplicationState/ApiClient';
import { Table } from '../../../Components/Base/Containers/Table';
import { H3 } from '../../../Components/Base/Text/H';

type Props = {
    world: AdminWorld
}

type TableProps = {
    keys?: string[] | undefined,
    settingsObject: any
}

const SettingsTable = (props: TableProps) => {

    const keys = props.keys ? props.keys : Object.keys(props.settingsObject);

    if (keys.length === 0) {
        return null;
    }

    return <Table isHoverable isFullWidth heading={
        <>
            <td>
                Key
                </td>
            <td>
                Value
                </td>
        </>
    }>
        {keys.map(t => <tr key={t}>
            <td>
                {t}
            </td>
            <td>
                {props.settingsObject[t]}
            </td>
        </tr>)}
    </Table>;
}

type SettingsProps = {
    settings: {} | undefined,
    title: string,
    depth: number
}

const SettingsContent = (props: SettingsProps) => {
    if (!props.settings) {
        return null;
    }

    const keys = Object.keys(props.settings);
    const keysOfObjects = keys.filter(k => {
        const a = props.settings![k];
        const b = Object(props.settings![k]);
        const compare = a === b;

        return compare;
    });
    const keysOfValues = keys.filter(k => !keysOfObjects.includes(k));

    return <>
        <H3>
            {" - ".repeat(props.depth)}
            {props.title}</H3>
        <SettingsTable settingsObject={props.settings} keys={keysOfValues} />
        {keysOfObjects.map(k => <div key={k}>
            <SettingsContent settings={props.settings![k]} title={k} depth={props.depth + 1} />
        </div>)}
    </>;
};


export const SettingsView = (props: Props) => {

    return <>
        <SettingsContent settings={props.world.settings.gameSettings} title="Game Settings" depth={0} />
        <hr />
        <SettingsContent settings={props.world.settings.serverSettings} title="Server Settings" depth={0} />
    </>;
};