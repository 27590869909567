export class CanvasCamera {

    constructor(bounds: { width: number, height: number }, initial: { x: number, y: number }) {
        this.cameraOffset = {
            x: initial.x + (bounds.width / 2),
            y: initial.y + (bounds.height / 2)
        };
    }

    cameraOffset: { x: number, y: number };
    cameraZoom = 1;
    MAX_ZOOM = 3;
    MIN_ZOOM = 0.4;
    SCROLL_SENSITIVITY = 0.0005;
    PAN_SPEED = 300;

    initialPinchDistance: number | undefined = undefined;
    lastZoom = 1

    isDragging = false
    dragStart = { x: 0, y: 0 }

    public goTo(bounds: { width: number, height: number }, target: { x: number, y: number }) {
        this.cameraOffset = {
            x: target.x + (bounds.width / 2),
            y: target.y + (bounds.height / 2)
        };
    }

    public panBy(target: { x: number, y: number }) {

        const ratio = this.PAN_SPEED * this.cameraZoom;

        this.cameraOffset = {
            x: this.cameraOffset.x + -(target.x * ratio),
            y: this.cameraOffset.y + -(target.y * ratio)
        };
    }

    public draw(ctx: CanvasRenderingContext2D, bounds: { width: number, height: number }, render: () => any) {

        ctx.save();

        ctx.clearRect(0, 0, bounds.width, bounds.height);

        ctx.translate(bounds.width / 2, bounds.height / 2);
        ctx.scale(this.cameraZoom, this.cameraZoom);
        ctx.translate(-bounds.width / 2 + this.cameraOffset.x, -bounds.height / 2 + this.cameraOffset.y);

        render();

        ctx.restore();
    }

    private getEventLocation(e) {
        if (e.touches && e.touches.length == 1) {
            return { x: e.touches[0].clientX, y: e.touches[0].clientY };
        }
        else if (e.clientX && e.clientY) {
            return { x: e.clientX, y: e.clientY };
        }

        return { x: 0, y: 0 };
    }


    public onPointerDown(e) {
        this.isDragging = true;
        this.dragStart.x = this.getEventLocation(e).x / this.cameraZoom - this.cameraOffset.x;
        this.dragStart.y = this.getEventLocation(e).y / this.cameraZoom - this.cameraOffset.y;
    }

    public onPointerUp(e) {
        this.isDragging = false;
        this.initialPinchDistance = undefined;
        this.lastZoom = this.cameraZoom;
    }

    public onPointerMove(e) {
        if (this.isDragging) {
            this.cameraOffset.x = this.getEventLocation(e).x / this.cameraZoom - this.dragStart.x;
            this.cameraOffset.y = this.getEventLocation(e).y / this.cameraZoom - this.dragStart.y;
        }
    }

    public handleTouch(e, singleTouchHandler) {
        if (e.touches.length == 1) {
            singleTouchHandler(e);
        }
        else if (e.type == "touchmove" && e.touches.length == 2) {
            this.isDragging = false;
            this.handlePinch(e);
        }
    }

    public handlePinch(e) {
        e.preventDefault();

        let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY };

        // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
        let currentDistance = (touch1.x - touch2.x) ** 2 + (touch1.y - touch2.y) ** 2;

        if (this.initialPinchDistance == null) {
            this.initialPinchDistance = currentDistance;
        }
        else {
            this.adjustZoom(null, currentDistance / this.initialPinchDistance);
        }
    }

    public adjustZoom(zoomAmount, zoomFactor?: number) {
        if (!this.isDragging) {
            if (zoomAmount) {
                this.cameraZoom += zoomAmount;
            }
            else if (zoomFactor) {
                this.cameraZoom = zoomFactor * this.lastZoom;
            }

            this.cameraZoom = Math.min(this.cameraZoom, this.MAX_ZOOM);
            this.cameraZoom = Math.max(this.cameraZoom, this.MIN_ZOOM);
        }
    }
}