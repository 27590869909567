import { observer } from "mobx-react-lite";
import * as React from "react";
import { ApiStateContext, AppStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { IconHelper } from "../../../Helpers/IconHelper";

export const DebugTicker = observer(() => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [isLoadingTicks, setIsLoadingTicks] = React.useState(false);

    function tick(ticks: number) {
        if (ticks > 0) {
            setIsLoadingTicks(true);
            return apiState.DebugClient.ticks(ticks).then(_ => {
                setIsLoadingTicks(false);
            });
        }
    }

    if (!appState.IsDebug) {
        return null;
    }

    return <div className={`control is-small has-addons`}>
        <Button type="warning" text="Tick Once" className={`${isLoadingTicks ? "is-loading" : ""}`} action={() => tick(1)} icon={IconHelper.General.Time} />
    </div>
});