import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ItemCategory } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ItemCategoryIcon } from '../../../Components/FusionShift/Icons/Items/ItemCategoryIcon';
import { FancyItem, ItemsHelper } from '../../../Helpers/ItemsHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    capacity: number,
    components: FancyItem[]
}

export const ComponentCapacityBar = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings || !worldState.SectorTypeSettings || !worldState.GameSettings) {
        return null;
    }

    const reservedPerComponent = props.capacity * worldState.GameSettings.solarSystem.componentCapacityReservationPercent;

    const components = Object.values(worldState.ItemTypeSettings.data)
        .filter(x => x.category === ItemCategory.Component)
        .sort((a, b) => a.order - b.order)
        .map(x => {

            const quantity = props.components.find(y => y.itemType.typeName === x.typeName)?.quantity ?? 0;
            const availableReservedSpace = Math.max(0, reservedPerComponent - quantity);
            const sharedCapacityUsed = Math.max(0, quantity - reservedPerComponent);

            return {
                itemType: x,
                numeral: ItemsHelper.findNumeral(worldState.ItemTypeSettings!, worldState.SectorTypeSettings!, x),
                quantity,
                availableReservedSpace,
                sharedCapacityUsed
            }
        });

    function widthFor(value: number, total: number) {
        let percent = total === 0 ? 0 : value / total;

        if (percent >= 1) {
            percent = 1;
        }

        return {
            width: `${percent * 100}%`
        }
    }

    const used = components.reduce((a, b) => a + b.quantity, 0);
    const excessUsed = components.reduce((a, b) => a + b.sharedCapacityUsed, 0);

    const reservedCapacity = worldState.GameSettings.solarSystem.componentCapacityReservationPercent;
    const remainingSize = 1 - (components.length * reservedCapacity);

    const ratio = used / props.capacity;

    const styleType = ratio >= 1 ? "is-danger" : ratio >= 0.9 ? "is-warning" : "is-success"

    const capacityText = `${ValueFormatter.toShorthand(used)} / ${ValueFormatter.toShorthand(props.capacity)}`;

    return <>
        <div className={`component-capacity-bar ${styleType}`}>

            <div className="capacity-percent" style={widthFor(excessUsed, props.capacity)} />

            <div className="bar-pieces">
                <div className={`shared-capacity`} style={widthFor(remainingSize, 1)} />
                {components.map(x => {

                    const sectionStyleType = x.availableReservedSpace <= 0 ? "is-warning" : "is-success"

                    return <div
                        key={x.itemType.typeName}
                        className={`reserved-capacity ${sectionStyleType}`} style={widthFor(reservedCapacity, 1)}
                    >
                        <div className='capacity-percent' style={widthFor(reservedPerComponent - x.availableReservedSpace, reservedPerComponent)} />
                        <span>
                            {x.numeral}
                        </span>
                    </div>;
                })}
            </div>

            <span className="capacity">
                <ItemCategoryIcon itemCategory={ItemCategory.Component} noText />
                {capacityText}
            </span>
        </div>
        <br />
        <Paragraph>
            {ValueFormatter.fromDecimalToDisplayPercent(worldState.GameSettings.solarSystem.componentCapacityReservationPercent, true, 0)} ({ValueFormatter.formatLocaleNumber(reservedPerComponent)}) of component storage capacity is reserved for each component.
        </Paragraph>
    </>;
});