import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetConfig, FleetMovementType, IFleet, ISendShipsToSolarSystemInput, IShipTypeSettings, SendShipsToSolarSystemInput } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { Checkbox } from '../../../Components/Base/Form/Checkbox';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { ErrorMessageBox } from '../../../Components/Base/MessageBox';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ShipTypeImage } from '../../../Components/FusionShift/Images/ShipTypeImage';
import { ShipTypeLink } from '../../../Components/FusionShift/Links/ShipTypeLink';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { ChooseOwnSolarSystemFromState } from '../../../Components/FusionShift/SolarSystems/ChooseOwnSolarSystem';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { FleetTable } from '../../Fleets/SubParts/FleetTable';
import { EmpireViewProps } from '../EmpireViewProps';

type SolarSystemProps = {
    solarSystem: SolarSystemWrapper,
    shipTypeSettings: IShipTypeSettings,
    shipQuantities: { [key: string]: number },
    setShipQuantity: (shipTypeName: string, quantity: number) => any
}

const RebaseSolarSystem = (props: SolarSystemProps) => {

    const [isExpanded, setIsExpanded] = React.useState(true);

    const availableShips = Object.keys(props.solarSystem.solarSystem.availableShips ?? {})
        .map(x => {
            return {
                shipType: props.shipTypeSettings.data[x],
                quantity: props.solarSystem.solarSystem.availableShips![x]
            }
        })
        .filter(x => x.quantity > 0)
        .sort((a, b) => a.shipType.order < b.shipType.order ? -1 : 1);

    if (availableShips.length === 0) {
        return <tr>
            <td colSpan={2}>
                <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} />
            </td>
            <td>
                No ships available
            </td>
        </tr>
    }

    const sum = CollectionHelper.sumOfDictionary(props.shipQuantities);

    return <>
        <tr>
            <td colSpan={2}>
                <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} />
            </td>
            <td>
                <span className='is-pulled-right'>
                    {sum > 0 && <span>{ValueFormatter.formatLocaleNumber(sum)} ships    </span>}
                    <ExpandButton isExpanded={isExpanded} setIsExpanded={e => setIsExpanded(e)} />
                </span>
            </td>
        </tr>
        {isExpanded && availableShips.map(x => {

            const value = x.shipType.typeName in props.shipQuantities ? props.shipQuantities[x.shipType.typeName] : 0;
            const noMax = value >= x.quantity;
            const noZero = value <= 0;

            return <tr key={x.shipType.typeName}>
                <td className="is-content-width">
                    <ShipTypeImage shipType={x.shipType} size="tiny" />
                </td>
                <td className="is-content-width">
                    <ShipTypeLink shipType={x.shipType} hideTextOnMobile />
                </td>
                <td>
                    <div className="field is-small has-addons">
                        <div className="control">
                            <NumberInput
                                size={5}
                                value={value}
                                valueChanged={value => props.setShipQuantity(x.shipType.typeName, value)}
                            />
                        </div>
                        <div className={`control ${noZero ? "is-hidden-mobile" : ""}`}>
                            <Button type="nav" icon="" className="is-small" text="0" action={() => props.setShipQuantity(x.shipType.typeName, 0)} isDisabled={noZero} />
                        </div>
                        <div className={`control ${noMax ? "is-hidden-mobile" : ""}`}>
                            <Button type="nav" icon="" className="is-small" text={ValueFormatter.formatLocaleNumber(x.quantity)} action={() => props.setShipQuantity(x.shipType.typeName, x.quantity)} isDisabled={noMax} />
                        </div>
                    </div>
                </td>
            </tr>;
        })}
    </>
}

type RebaseConfiguration = { [key: number]: { [key: string]: number } };

type Result = {
    fleets: IFleet[],
    errors: string[]
}

const reducer = (state: Result | "Nothing" | "Loading", toAdd: Result | "Loading"): Result | "Nothing" | "Loading" => {
    if (state === "Nothing" || state == "Loading") {
        return toAdd;
    }

    if (toAdd === "Loading") {
        return toAdd;
    }

    return {
        fleets: [...state.fleets, ...toAdd.fleets],
        errors: [...state.errors, ...toAdd.errors]
    }
};

export const RebaseMany = observer((props: EmpireViewProps) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [selectedSolarSystemId, setSelectedSolarSystemId] = React.useState<number | undefined>(undefined);

    const [groundOnArrival, setGroundOnArrival] = React.useState(false);

    const [selectedQuantities, setSelectedQuantites] = React.useState<RebaseConfiguration>({});

    const [result, dispatchResult] = React.useReducer(reducer, "Nothing");

    const solarSystem = props.solarSystems.find(x => x.solarSystemId === selectedSolarSystemId);

    const otherSolarSystems = props.solarSystems.filter(x => x.solarSystemId !== selectedSolarSystemId);

    React.useEffect(() => {

        if (selectedSolarSystemId === undefined && solarSystemState.SolarSystem !== undefined) {
            setSelectedSolarSystemId(solarSystemState.SolarSystem?.solarSystemId);
        }
    }, [solarSystemState.SolarSystem, selectedSolarSystemId]);

    function submit() {

        dispatchResult("Loading");

        for (const solarSystemId of Object.keys(selectedQuantities)) {

            if (Number(solarSystemId) === selectedSolarSystemId) {
                continue;
            }

            if (CollectionHelper.isAnyQuantityInDictionary(selectedQuantities[solarSystemId])) {

                const data: ISendShipsToSolarSystemInput = {
                    movementType: FleetMovementType.Rebase,
                    targetSolarSystemId: selectedSolarSystemId!,
                    ships: selectedQuantities[solarSystemId],
                    config: new FleetConfig({
                        groundOnArrival,
                        notifyOnArrival: false,
                    })
                };

                const config = new SendShipsToSolarSystemInput(data);

                apiState.FleetClient.sendShipsToSolarSystem(Number(solarSystemId), config).then(x => {

                    const res: Result = {
                        fleets: x?.result?.fleets?.filter(x => x.isNew) ?? [],
                        errors: x.error.length > 0 ? [`${x.result !== undefined ? x.result.name + ": " : ""}${x.error}`] : []
                    };

                    dispatchResult(res);
                    if (x.result) {
                        solarSystemState.loadSolarSystem(x.result);
                    }
                });
            }
        }

        setSelectedQuantites({});
    }

    function hasNone() {

        for (const solarSystemId of Object.keys(selectedQuantities)) {

            if (selectedQuantities[solarSystemId] === undefined) {
                continue;
            }

            for (const shipTypeName of Object.keys(selectedQuantities[solarSystemId])) {
                if (selectedQuantities[solarSystemId] !== undefined &&
                    selectedQuantities[solarSystemId][shipTypeName] !== undefined &&
                    selectedQuantities[solarSystemId][shipTypeName] > 0) {
                    return false;
                }
            }
        }

        return true;
    }

    function setShipQuantity(solarSystemId: number, shipTypeName: string, quantity: number) {
        if (!(solarSystemId in selectedQuantities)) {
            selectedQuantities[solarSystemId] = {};
        }

        selectedQuantities[solarSystemId][shipTypeName] = quantity;

        setSelectedQuantites({ ...selectedQuantities });
    }

    const hasResult = result !== 'Loading' && result !== "Nothing";

    return <>
        {result === 'Nothing' &&
            <ChooseOwnSolarSystemFromState
                prompt='Choose a solar system for the rebase target.'
                solarSystemFoundCallback={x => setSelectedSolarSystemId(x?.solarSystemId)}
                selectedSolarSystemId={selectedSolarSystemId}
            />
        }
        {hasResult && result.errors.map((x, i) => <ErrorMessageBox key={i} text={x} />)}
        {hasResult && <FleetTable
            solarSystemId={selectedSolarSystemId}
            fleets={result.fleets}
            reloadCallback={() => { }} availableShips={FleetWrapper.empty()}
            display={{
                headings: 'hide on mobile',
                arrivalDate: true,
                source: true
            }}
        />
        }
        {result === "Loading" && <>
            <Paragraph>
                Configuring orders for rebase fleets...
            </Paragraph>
            <LoadingSpinner />
        </>}
        {solarSystem !== undefined && result === "Nothing" && <Table
            numberOfColumns={3}
            className='form'
            isFullWidth isFixed
            footer={
                <div className='is-pulled-right is-flex'>
                    <Checkbox label='Ground on arrival' value={groundOnArrival} valueChanged={setGroundOnArrival} />
                    <Button
                        type="action"
                        text='Rebase'
                        forceClip='bottom-right'
                        icon={IconHelper.Ships.movementType(FleetMovementType.Rebase)}
                        action={submit}
                        isDisabled={hasNone()}
                    />
                </div>}
        >
            {otherSolarSystems.map(x => <RebaseSolarSystem
                key={x.solarSystemId}
                solarSystem={x}
                shipTypeSettings={props.shipTypeSettings}
                shipQuantities={x.solarSystemId in selectedQuantities ? selectedQuantities[x.solarSystemId] : {}}
                setShipQuantity={(s, q) => setShipQuantity(x.solarSystemId, s, q)}
            />)}
        </Table>}
    </>;
});