import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../MissionWrapper';

type Props = {
    mission: MissionWrapper
}

export const Prerequisites = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const hasPrevious = CollectionHelper.isAnyInArray(props.mission.MissionType.prerequisiteMissionTypeNames);

    if (!hasPrevious || !worldState.MissionTypeSettings) {
        return null;
    }

    const title = `Prev. ${ValueFormatter.pluralize(" Mission", props.mission.MissionType.prerequisiteMissionTypeNames!.length)}`;

    return <SubPanel heading={{ text: title, icon: IconHelper.Missions.Prerequisite }}>
        <ul>
            {props.mission.MissionType.prerequisiteMissionTypeNames!.map((m, i) => <li key={i}>
                <Link to={`/missions/${m}`} >
                    {worldState.MissionTypeSettings![m].name}
                </Link>
            </li>)}
        </ul>
    </SubPanel>;
});