import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DescriptionStateContext, TutorialStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { RichTextArray } from '../../Components/FusionShift/RichTextArray';
import { TypeDataVoice } from '../../Components/FusionShift/TypeDataVoice';
import { IconHelper } from '../../Helpers/IconHelper';
import { MissionWrapper } from './MissionWrapper';
import { Complete } from './ViewParts/Complete';
import { Images } from './ViewParts/Images';
import { Info } from './ViewParts/Info';

type Props = {
    mission: MissionWrapper | undefined,
    redirectOnComplete?: boolean
}

export const MissionContent = observer((props: Props) => {

    const mission = props.mission;
    const tutorialState = React.useContext(TutorialStateContext);
    const descriptionState = React.useContext(DescriptionStateContext);

    if (mission === undefined) {
        return <Paragraph>Mission not found.</Paragraph>;
    }

    if (mission.Status === "Locked") {
        return <Paragraph>Mission unavailable.</Paragraph>;
    }

    const description = descriptionState.Missions?.find(x => x.typeName === props.mission?.MissionType.typeName);

    return <>
        <SplitLayout
            sizing="70-30"
            left={<SubPanel heading={"Description"} headingContent={
                <ButtonHolder>
                    {mission.MissionType.isTutorial && <Button
                        type="nav"
                        hideTextOnMobile
                        action={() => tutorialState.showIntroduction()}
                        icon={IconHelper.Missions.ReplayTutorial}
                        text="Replay Tutorial"
                    />
                    }
                    <TypeDataVoice typeData={mission.MissionType} type="MissionType" />
                </ButtonHolder>
            }>
                <RichTextArray text={description?.description} />
                <Images mission={mission} />
            </SubPanel>}
            right={<Info mission={mission} />} />
        <Complete mission={mission} redirectOnComplete={props.redirectOnComplete} />
    </>;
});