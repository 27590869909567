import { observer } from "mobx-react-lite";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { AgentActionCategory, AgentActionType, IInstallationTypeSettings } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { TabMenuWithChildren } from "../../../Components/Base/Containers/TabMenu";
import { Table } from "../../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { AgentActionTypeDescription } from "../../../Components/FusionShift/Descriptions/AgentActionTypeDescription";
import { AgentActionTypeStats } from "../../../Components/FusionShift/Descriptions/AgentActionTypeStats";
import { AgentActionCategoryIcon } from "../../../Components/FusionShift/Icons/AgentActionCategoryIcon";
import { AgentStatusIcon } from "../../../Components/FusionShift/Icons/AgentStatusIcon";
import { CreditsIconWithQuantity } from "../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity";
import { InstallationTypeImage } from "../../../Components/FusionShift/Images/InstallationTypeImage";
import { InstallationTypeLink } from "../../../Components/FusionShift/Links/InstallationTypeLink";
import { TypeDataVoice } from "../../../Components/FusionShift/TypeDataVoice";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { CommodityHelper } from "../../../Helpers/CommodityHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { valuesFromEnum } from "../../../Helpers/TypeHelper";
import { UrlHelper } from "../../../Helpers/UrlHelper";
import { help_page } from "../../../Navigation/Routing/Help";
import { TypeDataBaseView } from "./TypeDataBaseView";

type Props = {
    installationTypeSettings: IInstallationTypeSettings,
    agentActionType: AgentActionType,
    commodityHelper: CommodityHelper
}

const AgentActionTypeControl = (props: Props) => {

    return <SubPanel
        id={props.agentActionType.typeName}
        heading={{ text: props.agentActionType.name, icon: <AgentActionCategoryIcon category={props.agentActionType.category} /> }}
        headingContent={<TypeDataVoice type="AgentActionType" typeData={props.agentActionType} />}
    >
        <SplitLayout
            horizontalAfter="tablet"
            isRightEmpty={!CollectionHelper.isAnyInArray(props.agentActionType.requiresInstallationTypeName)}
            left={<>
                <AgentActionTypeDescription {...props} />
                <ul>
                    <li>
                        Cost: <CreditsIconWithQuantity quantity={props.agentActionType.cost} />
                    </li>
                    <li>
                        Status: <AgentStatusIcon status={props.agentActionType.requiredStatus} />
                    </li>
                    {props.agentActionType.durationHours && <li>
                        Duration: {props.agentActionType.durationHours} hours
                    </li>}
                </ul>
            </>
            }
            right={CollectionHelper.isAnyInArray(props.agentActionType.requiresInstallationTypeName) &&
                <SubPanel heading={{ text: "Installations", icon: IconHelper.Installations.Installation }} isUnpadded>
                    <Table isFixed isFullWidth isUnpadded>
                        {props.agentActionType.requiresInstallationTypeName!
                            .map(x => props.installationTypeSettings.data[x])
                            .filter(x => x.researchPointsRequired === undefined || x.researchPointsRequired === null || x.researchPointsRequired === 0)
                            .map(x => <tr key={x.typeName}>
                                <td>
                                    <InstallationTypeImage installationType={x} size="tiny" />
                                </td>
                                <td>
                                    <InstallationTypeLink installationType={x} />
                                </td>
                            </tr>
                            )}
                    </Table>
                </SubPanel>}
        />
        <AgentActionTypeStats {...props} />
    </SubPanel>;
};

const AgentActionTypesContent = observer(() => {

    const worldState = React.useContext(WorldStateContext);

    const [category, setCategory] = React.useState<AgentActionCategory | undefined>(undefined);

    const location = useLocation();

    if (!worldState.AgentActionTypeSettings || !worldState.InstallationTypeSettings || !worldState.CommodityHelper) {
        return <LoadingSpinner />;
    }

    if (category === undefined) {
        const agentActionTypeToFind = UrlHelper.tryGetValueFromSearch(location, "agentActionTypeName");

        if (agentActionTypeToFind !== undefined && agentActionTypeToFind.length > 0) {

            for (const agentAction of Object.values(worldState.AgentActionTypeSettings.data)) {
                if (agentAction.typeName === agentActionTypeToFind) {
                    setCategory(agentAction.category);
                    break;
                }
            }

        } else {
            setCategory(AgentActionCategory.Utility);
        }
    }

    const agentActions = Object.values(worldState.AgentActionTypeSettings.data)
        .filter(x => x.category == category && !x.isInternalOnly)
        .sort((a, b) => a.order < b.order ? -1 : 1);

    const allCategories = valuesFromEnum(AgentActionCategory);

    return <TabMenuWithChildren
        active={category}
        changeTab={t => setCategory(t)}
        tabs={allCategories.map(x => {
            return {
                id: x,
                title: AgentActionCategory[x],
                icons: <AgentActionCategoryIcon category={x} />,
                isAvailable: true
            };
        })}
    >
        {agentActions.map(c => <AgentActionTypeControl key={c.typeName} agentActionType={c} installationTypeSettings={worldState.InstallationTypeSettings!} commodityHelper={worldState.CommodityHelper!} />)}
    </TabMenuWithChildren>;
});

export const AgentActionTypesView = () => {

    return <TypeDataBaseView title="Agent Actions" icon={IconHelper.Agents.Agent}
        links={[
            { icon: IconHelper.Agents.Agent, to: help_page("agents"), text: "Agents" },
        ]}>
        <AgentActionTypesContent />
    </TypeDataBaseView>
};