import { AgentActionType, IAgentActionTypeSettings, IAgentEffect, ICelestialDetail, IGameSettings, IInstallationTypeSettings, IItemTypeSettings, Installation, InstallationType, ISectorTypeSettings, IShipTypeSettings, ISolarSystemDetail, IWorldEffects } from "../../ApplicationState/ApiClient";
import { AgentHelper } from "../../Helpers/AgentHelper";
import { BuildHelper, UpgradeDetails } from "../../Helpers/BuildHelper";
import { CelestialHelper } from "../../Helpers/CelestialHelper";
import { DroneHelper } from "../../Helpers/DroneHelper";
import { FleetHelper, SolarSystemFleets } from "../../Helpers/FleetHelper";
import { InstallationHelper } from "../../Helpers/InstallationHelper";
import { MiningHelper } from "../../Helpers/MiningHelper";
import { SolarSystemHelper } from "../../Helpers/SolarSystemHelper";
import { FleetWrapper, onlyCombatShips } from "../FleetWrapper";
import { Problem } from "./Problems/Problem";
import { ProblemHelper } from "./Problems/ProblemHelper";

export type MilitaryStats = {
    defenceFromInstallations: number,
    defenceFromShips: number,
    defenceFromDrones: number,
    defenceFromReinforcements: number,
    availableDefence: number,
    totalAttack: number,
    totalDefence: number,
    totalDefenceBonus: number
}

export type SystemMiner = { needs: number; has: number; rebasing: number; }

export class SolarSystemWrapper {

    public ConstructingInstallationId: number | undefined;

    public get solarSystemId() { return this.solarSystem.solarSystemId; }

    public loadNumber: number;

    public solarSystem: ISolarSystemDetail;
    public militaryStats: MilitaryStats;
    public installations: { installation: Installation, celestial: ICelestialDetail, installationType: InstallationType; }[];
    public problems: Problem[];
    public upgrades: UpgradeDetails[];
    public fleets: SolarSystemFleets;
    public availableShips: FleetWrapper;
    public agentEffects: { agentEffect: IAgentEffect, agentActionType: AgentActionType, strength: number }[];
    public systemMiners: { [key: string]: SystemMiner };

    constructor(
        itemTypeSettings: IItemTypeSettings,
        shipTypeSettings: IShipTypeSettings,
        installationTypeSettings: IInstallationTypeSettings,
        sectorTypeSettings: ISectorTypeSettings,
        agentActionTypeSettings: IAgentActionTypeSettings,
        gameSettings: IGameSettings, worldEffects: IWorldEffects, solarSystem: ISolarSystemDetail, loadNumber: number) {

        this.loadNumber = loadNumber;

        this.solarSystem = solarSystem;

        this.installations = CelestialHelper.buildInstallationForSolarSystem(installationTypeSettings, solarSystem);

        this.availableShips = new FleetWrapper(shipTypeSettings, gameSettings, solarSystem.availableShips);
        this.fleets = FleetHelper.solarSystemFleets(solarSystem);

        const localShips = new FleetWrapper(shipTypeSettings, gameSettings, solarSystem.availableShips, onlyCombatShips);

        const fleets = solarSystem.fleets ?? [];

        const ownFleets = fleets.filter(x => x.homeSolarSystemId === solarSystem.solarSystemId);
        const foreignPresentFleets = fleets.filter(x => x.ownerPlayerId !== solarSystem.playerId && FleetHelper.isArrivedAndPresentInSolarSystem(x));

        const totalBonus = worldEffects.solarSystemDefenceModifierPercent +
            SolarSystemHelper.megacityCombatBonus(sectorTypeSettings, solarSystem) +
            (solarSystem.effects?.solarSystemDefenceModifierPercent ?? 0);

        const defenceFromInstallations = InstallationHelper.sumOfStatPerLevelForSolarSystem(installationTypeSettings, solarSystem, i => i.defencePerLevel);
        const defenceFromShips = localShips.TotalDefence;
        const defenceFromDrones = new FleetWrapper(shipTypeSettings, gameSettings, DroneHelper.activeDronesAsShips(solarSystem.drones)).TotalDefence;
        const defenceFromReinforcements = foreignPresentFleets.reduce((v, s) => new FleetWrapper(shipTypeSettings, gameSettings, s.ships, onlyCombatShips).TotalDefence + v, 0);

        const ownFleetWrappers = ownFleets.map(x => new FleetWrapper(shipTypeSettings, gameSettings, x.ships, onlyCombatShips));

        const attackFromFleets = ownFleetWrappers.reduce((v, s) => s.TotalAttack(false) + v, 0);
        const defenceFromFleets = ownFleetWrappers.reduce((v, s) => s.TotalDefence + v, 0);

        this.militaryStats = {
            defenceFromDrones,
            defenceFromInstallations,
            defenceFromReinforcements,
            defenceFromShips,
            availableDefence: (1 + totalBonus) * (defenceFromDrones + defenceFromInstallations + defenceFromShips + defenceFromReinforcements),
            totalDefence: defenceFromDrones + defenceFromInstallations + defenceFromShips + defenceFromReinforcements + defenceFromFleets,
            totalAttack: attackFromFleets + localShips.TotalAttack(false),
            totalDefenceBonus: totalBonus
        };

        this.systemMiners = MiningHelper.systemMiners(shipTypeSettings, solarSystem);

        this.agentEffects = solarSystem.agentEffects.map(x => {
            return {
                agentEffect: x,
                agentActionType: x.agentActionTypeName in agentActionTypeSettings.data ? agentActionTypeSettings.data[x.agentActionTypeName] : undefined
            }
        })
            .filter(x => x.agentActionType !== undefined)
            .map(x => {
                return {
                    agentEffect: x.agentEffect,
                    strength: AgentHelper.calculateFromLevelModifier(x.agentActionType!.level, x.agentActionType!.strengthModifier, x.agentEffect.level),
                    agentActionType: x.agentActionType!
                }
            });

        this.problems = ProblemHelper.calculateProblems(installationTypeSettings,
            shipTypeSettings,
            itemTypeSettings,
            sectorTypeSettings,
            this);

        this.upgrades = this.installations.map(i => BuildHelper.upgradeDetails(i.installation, i.celestial, this.solarSystem, installationTypeSettings, itemTypeSettings, gameSettings, worldEffects))
            .filter(i => i.canUpgrade)
            .sort((a, b) => a.celestial.order - b.celestial.order);

        this.ConstructingInstallationId = this.solarSystem.installationBuildQueue?.find(x => true)?.installationId;
    }

    public getItemQuantity(itemTypeName: string) {

        if (this.solarSystem.items === undefined) {
            return 0;
        }

        if (itemTypeName in this.solarSystem.items) {
            return this.solarSystem.items[itemTypeName];
        }

        return 0;
    }


    public nextCompleteDate() {

        let nextDate: Date | undefined = undefined;

        const bqi = this.solarSystem.installationBuildQueue;
        if (bqi) {
            for (let i of bqi) {
                if (!nextDate || i.completedDate < nextDate) {
                    nextDate = i.completedDate;
                }
            }
        }

        const bqs = this.solarSystem.shipBuildQueueByCelestial;
        if (bqs) {
            for (let c of Object.keys(bqs)) {
                const bq = bqs[c];
                for (let i of bq) {
                    if (!nextDate || i.allCompletedDate < nextDate) {
                        nextDate = i.allCompletedDate;
                    }
                }
            }
        }

        const fleets = this.solarSystem.fleets;
        if (fleets) {
            for (let s of fleets) {
                // Exclude things that have arrived because they will always trigger an update elsewhere
                if (!FleetHelper.isArrivedAndPresentInSolarSystem(s)
                    && (!nextDate || (s.arrivalDate && s.arrivalDate < nextDate))) {
                    nextDate = s.arrivalDate;
                }
            }
        }

        const activating = this.solarSystem.activatingShips;

        if (activating) {
            for (const a of activating) {
                if (!nextDate || a.activationCompletedDate < nextDate) {
                    nextDate = a.activationCompletedDate;
                }
            }
        }

        for (const apoc of this.solarSystem.apocalypseShots) {
            if (!nextDate || apoc.nextUpdateDate < nextDate) {
                nextDate = apoc.nextUpdateDate;
            }
        }

        return nextDate;
    }
}