import classNames from 'classnames';
import * as React from 'react';
import { ISolarSystem } from '../../../ApplicationState/ApiClient';
import { handleOnClick } from '../../../Components/Base/Util';
import { SolarSystemActionIcons } from '../../../Components/FusionShift/Icons/SolarSystemActionIcons';

type Props = {
    position: { x: number, y: number },
    solarSystem: ISolarSystem,
    isSelected: boolean,
    onClick: () => any
}

export const PositionedSolarSystem = (props: Props) => {

    const className = classNames(
        "solar-system",
        "is-clickable",
        {
            "is-selected": props.isSelected
        }
    );

    return <div className={className}
        style={{
            top: props.position.y,
            left: props.position.x,
        }}
        onClick={e => handleOnClick(e, props.onClick)}
    >
        <span>
            {props.solarSystem.name}
        </span>
        <span>
            <SolarSystemActionIcons solarSystem={props.solarSystem} />
        </span>
    </div>;
}