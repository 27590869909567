import * as React from 'react';
import { Button } from './Button';
import { ButtonHolder } from './ButtonHolder';

type Props<T> = {
    className?: string | undefined,
    values: (Choice<T> | undefined)[],
    children?: React.ReactNode,
    hideTextOnMobile?: boolean,
    isVerticalOnMobile?: boolean,
    isPulledRight?: boolean,
    isDisabled?: boolean,
    additionalButtons?: React.ReactNode[]
}

type ChooserProps<T> = Props<T> & {
    valueChanged: (value: T) => any,
    value: T,
}

type MultiProps<T> = Props<T> & {
    valueChanged: (value: T, isSet: boolean) => any,
    value: T[],
}

export type Choice<T> = { value: T, text: string, icon: string, className?: string | undefined }

export function maybeChoice<T>(maybe: boolean, value: T, text: string, icon: string): Choice<T> | undefined {
    return maybe ? choice(value, text, icon) : undefined;
}

export function choice<T>(value: T, text: string, icon?: string | undefined, className?: string | undefined): Choice<T> {
    return {
        value,
        text,
        icon: icon !== undefined ? icon : "",
        className
    }
}

export function ButtonChooser<T>(props: ChooserProps<T>) {

    const valuesToUse = props.values.filter(v => !!v).map(v => v!);

    if (valuesToUse.length == 0) {
        return null;
    }

    return <ButtonHolder className={`button-chooser ${props.className ? props.className : ""}`} isVerticalOnMobile={props.isVerticalOnMobile} isPulledRight={props.isPulledRight} >
        {
            valuesToUse.map((v, i) => <Button
                key={v.text}
                type="nav"
                icon={v.icon}
                text={v.text}
                className={v.className}
                action={() => props.valueChanged(v.value)}
                isSelected={v.value === props.value}
                isDisabled={props.isDisabled}
                hideTextOnMobile={props.hideTextOnMobile}
            />)
        }
        {props.children}
        {props.additionalButtons !== undefined && props.additionalButtons.map((x, i) => <React.Fragment key={i}>
            {x}
        </React.Fragment>
        )}
    </ ButtonHolder>
}

export function MultiButtonChooser<T>(props: MultiProps<T>) {

    const valuesToUse = props.values.filter(v => !!v).map(v => v!);

    if (valuesToUse.length == 0) {
        return null;
    }

    function valueChanged(value: T) {
        const wasSelected = props.value.includes(value);

        props.valueChanged(value, !wasSelected);
    }

    return <ButtonHolder className={`button-chooser ${props.className ? props.className : ""}`} isVerticalOnMobile={props.isVerticalOnMobile} isPulledRight={props.isPulledRight} >
        {
            valuesToUse.map((v, i) => <Button
                key={v.text}
                type="nav"
                icon={v.icon}
                text={v.text}
                className={v.className}
                action={() => valueChanged(v.value)}
                isSelected={props.value.includes(v.value)}
                isDisabled={props.isDisabled}
                hideTextOnMobile={props.hideTextOnMobile}
            />)
        }
        {props.children}
        {props.additionalButtons !== undefined && props.additionalButtons.map((x, i) => <React.Fragment key={i}>
            {x}
        </React.Fragment>
        )}
    </ ButtonHolder>
}


ButtonChooser.defaultProps = {
    hideTextOnMobile: true
};

MultiButtonChooser.defaultProps = {
    hideTextOnMobile: true
};