import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { HomepageStats } from "../../ApplicationState/ApiClient";
import { PlayerStateContext } from '../../ApplicationState/ContextRoot';
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Table } from "../../Components/Base/Containers/Table";
import { CountingNumber } from "../../Components/Base/Text/CountingNumber";
import { FederationLink } from '../../Components/FusionShift/Links/FederationLink';
import { AggregatedGraph } from '../../Components/FusionShift/Reports/AggregateGraph';

type Props = {
    homepageStats: HomepageStats
};

export const StatsControl = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    const hasFederationPlayers = props.homepageStats.players.inLastHour.playersInFederation !== null || props.homepageStats.players.inLast24Hours.playersInFederation !== null;

    const hasFederationCombat = props.homepageStats.combat.inLastHour.combatsWhereFederationWasAttacker !== null ||
        props.homepageStats.combat.inLastHour.combatsWhereFederationWasDefender !== null ||
        props.homepageStats.combat.inLast24Hours.combatsWhereFederationWasAttacker !== null ||
        props.homepageStats.combat.inLast24Hours.combatsWhereFederationWasDefender !== null;

    const federationName = playerState.Player && playerState.Player.federation ?
        <FederationLink federation={playerState.Player.federation} /> : "Your Federation";

    return <>
        <SubPanel heading="Galaxy">
            <AggregatedGraph />
        </SubPanel>
        <SubPanel heading="Online Players" isUnpadded>
            <Table heading={
                <>
                    <th></th>
                    <th>Now</th>
                    <th>Last 24 Hours</th>
                </>
            }>
                <tr>
                    <td>Players</td>
                    <td><CountingNumber value={props.homepageStats.players.inLastHour.players} /></td>
                    <td><CountingNumber value={props.homepageStats.players.inLast24Hours.players} /></td>
                </tr>
                {hasFederationPlayers &&
                    <tr>
                        <td>{federationName}</td>
                        <td><CountingNumber value={props.homepageStats.players.inLastHour.playersInFederation} /></td>
                        <td><CountingNumber value={props.homepageStats.players.inLast24Hours.playersInFederation} /></td>
                    </tr>
                }
            </Table>
        </SubPanel>
        <SubPanel heading="Recent Battles" isUnpadded>
            <Table heading={
                <>
                    <th></th>
                    <th>Now</th>
                    <th>Last 24 Hours</th>
                </>
            }>
                <tr>
                    <td>Battles</td>
                    <td>
                        <CountingNumber value={props.homepageStats.combat.inLastHour.combats} /></td>
                    <td><CountingNumber value={props.homepageStats.combat.inLast24Hours.combats} /></td>
                </tr>
                {hasFederationCombat &&
                    <>
                        <tr>
                            <td>{federationName} was Attacker</td>
                            <td><CountingNumber value={props.homepageStats.combat.inLastHour.combatsWhereFederationWasAttacker} /></td>
                            <td><CountingNumber value={props.homepageStats.combat.inLast24Hours.combatsWhereFederationWasAttacker} /></td>
                        </tr>
                        <tr>
                            <td>{federationName} was Defender</td>
                            <td><CountingNumber value={props.homepageStats.combat.inLastHour.combatsWhereFederationWasDefender} /></td>
                            <td><CountingNumber value={props.homepageStats.combat.inLast24Hours.combatsWhereFederationWasDefender} /></td>
                        </tr>
                    </>
                }
            </Table>
        </SubPanel>
    </>;
});