import { observer } from "mobx-react-lite";
import * as React from "react";
import Linkify from 'react-linkify';
import { ChatMessage } from "../../ApplicationState/ApiClient";
import { AppStateContext, PlayerStateContext } from "../../ApplicationState/ContextRoot";
import { ChatChannel } from "../../ApplicationState/States/ChatState";
import { Button } from "../../Components/Base/Buttons/Button";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { IconHelper } from "../../Helpers/IconHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";

type Props = {
    channel: ChatChannel | undefined
}

type MessageItemProps = {
    scroll: () => any,
    isLastItem: boolean,
    chatMessage: ChatMessage,
    isSelf: boolean
}

class ChatMessageItem extends React.Component<MessageItemProps> {

    ref: React.RefObject<HTMLParagraphElement>;
    constructor(props: MessageItemProps) {
        super(props);
        this.ref = React.createRef<HTMLParagraphElement>();
    }

    componentDidMount() {
        if (this.props.isLastItem && this.ref.current) {
            this.ref.current.scrollIntoView();
            this.props.scroll();
        }
    }

    render() {
        const dateParts = ValueFormatter.formatTimeOrDate(this.props.chatMessage.date, false, true, false, "ddd Do").split(" ");
        const count = dateParts.length;

        const firstClass = count >= 2 ? "date" : "time";

        return <div className="chat-message" ref={this.ref}>
            <div className="chronology is-inline-block">
                <span className={firstClass}>
                    {dateParts[0]}
                </span>
                {count >= 2 &&
                    <span className="time">
                        {dateParts[1]}
                    </span>
                }
            </div>
            <span className="sender">
                <PlayerLink player={this.props.chatMessage.player} />:
            </span>
            <div className="text">
                <Paragraph className="is-inline">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => {
                            return <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        }}
                    >
                        {this.props.chatMessage.message}
                    </Linkify>
                </Paragraph>
            </div>
        </div>;
    }
}

const characterLimit = 500;
const ChatComposeControl = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    const [message, setMessage] = React.useState("");

    React.useEffect(() => {
        if (message.length > characterLimit) {
            setMessage(message.substring(0, characterLimit));
        }
    }, [message]);

    function send() {
        if (message && message.length > 0 && props.channel) {
            const p = appState.sendChatMessage(props.channel.Chat.chatId, message);
            setMessage("");
            return p;
        }
    }

    function changed(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setMessage(e.target.value);
    }

    function keyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.keyCode === 13) {
            e.preventDefault();
            send();
            return false;
        }
    }

    if (!props.channel) {
        return null;
    }

    props.channel.markAsRead();

    return <>
        <div className="field-body">
            <div className="field has-addons chat-compose ">
                <div className="control">
                    <textarea className="textarea" placeholder={"Chat..."} rows={1} onKeyDown={keyDown} onChange={changed} value={message} />
                </div>
                <div className="control">
                    <Button type="action" text="Send" action={send} icon={IconHelper.Chat.Send} />
                </div>
            </div>
        </div>
        <Paragraph
            className="character-limit"
            type={message.length > characterLimit ? "danger" : "prompt"}
        >
            {message.length} / {characterLimit} characters
        </Paragraph>
    </>;
}

export const ChatView = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (!props.channel || !playerState.Player) {
        return null;
    }

    return <div className="chat-holder">
        <div className="chat-messages">
            {props.channel.Messages.map((c, i) => <ChatMessageItem
                scroll={appState.scrollToTop}
                isLastItem={i === props.channel!.Messages.length - 1}
                key={c.chatMessageId}
                chatMessage={c}
                isSelf={c.player ? c.player.playerId === playerState.Player!.playerId : false}
            />)}
        </div>
        <ChatComposeControl {...props} />
    </div>;
});