import { CelestialFeature } from "../../Helpers/CelestialHelper";
import { SelectedSolarSystemView } from "../../Views/SolarSystem/SelectedSolarSystemView";
import { SolarSystemByIdView } from "../../Views/SolarSystem/SolarSystemByIdView";
import { route } from "./Root";

export const selected_system = "/system";

export function systemById(solarSystemId: number | string) {
    return "/systemById/" + solarSystemId;
}

export function system_subView(page: string) {
    return "/system/" + page;
}

export const selected_system_arrivals = system_subView("ships") + "?tab=Arrivals";
export const selected_system_repeats = system_subView("ships") + "?tab=Repeats";

export const selected_system_items = system_subView("items");
export const selected_system_celestials = system_subView("celestials");

function celestial(celestialId: number | string) {
    return system_subView("celestials") + "/" + celestialId;
}

export function celestial_summary(celestialId) {
    return celestial(celestialId);
}

export function celestial_page(celestialId: number | string, page: string) {
    return celestial(celestialId) + "/" + page;
}

export function celestial_installation(celestialId: number | string, installationId: number | string) {
    return celestial_page(celestialId, "installation/" + installationId);
}

export function celestial_build(celestialId: number | string) {
    return celestial_page(celestialId, "Build");
}

export function celestial_megacity(celestialId: number | string) {
    return celestial_page(celestialId, "Megacity");
}

export function celestial_manufactory(celestialId: number | string) {
    return celestial_page(celestialId, "Manufactory");
}

export function celestial_ships(celestialId: number | string) {
    return celestial_page(celestialId, "Ships");
}

export function celestial_drones(celestialId: number | string) {
    return celestial_page(celestialId, "Drones");
}

export function celestial_feature(celestialId: number | number, feature: CelestialFeature) {
    if (typeof feature === "string") {
        return celestial_page(celestialId, feature);
    }

    return celestial_installation(celestialId, feature.installationId);
};

const systemRoute = selected_system + "/:subView?/:celestialId?/:feature?/:installationId?"

export const solarSystemRoutes = [

    route(systemById(":solarSystemId"), SolarSystemByIdView, "Solar system"),

    route(systemRoute, SelectedSolarSystemView, "Solar System")
];