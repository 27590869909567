import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button, ButtonAction } from './Button';

type Props = {
    confirm?: string | undefined,
    action: ButtonAction
}

export const DeleteButton = (props: Props) => {
    return <Button {...props} type="danger" text="Delete" icon={IconHelper.General.Delete} hideTextOnMobile />
};