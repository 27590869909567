import { IInstallation, IInstallationTypeSettings, InstallationType, IShipTypeSettings } from '../../../ApplicationState/ApiClient';
import { Paragraph } from '../../Base/Text/Paragraph';

type Props = {
    otherInstallations: IInstallation[],
    installationType: InstallationType,
    installationTypeSettings: IInstallationTypeSettings,
    shipTypeSettings: IShipTypeSettings
};

export const CelestialShipInstallationWarning = (props: Props) => {

    function areShipsBuiltAt(installationType: InstallationType) {
        return Object.values(props.shipTypeSettings.data).find(x => x.builtAtInstallationTypeName === installationType.typeName) !== undefined;
    }

    const areShipsBuiltHere = areShipsBuiltAt(props.installationType);
    const hasExistingShipBuilder = areShipsBuiltHere && props.otherInstallations.find(x => {
        const installationType = props.installationTypeSettings.data[x.installationTypeName];
        return areShipsBuiltAt(installationType);
    }) !== undefined;

    if (!hasExistingShipBuilder) {
        return null;
    }

    return <Paragraph type="warning">
        An installation which produces ships is already present here. Only 1 ship can be built at a time at each celestial.
    </Paragraph>;
};