import { ISolarSystemMinimal } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { SendShipsButton } from "./SendShipsButton";

type Props = {
    solarSystem: ISolarSystemMinimal,
    marketOrderId: number
}

export const AcceptMarketOrderButton = (props: Props) => {

    return <SendShipsButton
        targetSolarSystem={props.solarSystem}
        icon={IconHelper.Market.Trade}
        additionalParams={`marketOrderId=${props.marketOrderId}`}
        text="Trade"
    />;
};