import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router-dom";
import { AppStateContext, PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { admin, admin_cheat, admin_users, admin_world, admin_worlds, dev_balance_costOfUpgrades, dev_balance_production, dev_solarSystemSamples } from "../../Routing/Admin";
import { MenuLink, isActiveSubMenu } from "../MenuState";
import { DebugTicker } from "./DebugTicker";

export const AdminMenu = observer(() => {

    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);
    const location = useLocation();

    if (!appState.IsAdmin || !worldState.World) {
        return null;
    }

    return <div className={`navbar-item has-dropdown is-hoverable${isActiveSubMenu(location, admin)}`}>
        <a className="navbar-link">
            Admin
        </a>
        <div className="navbar-dropdown">
            <MenuLink to={admin_worlds} className="navbar-item" end>All Worlds</MenuLink>
            <MenuLink to={admin_world(worldState.World.worldId)} className="navbar-item">{worldState.World.name}</MenuLink>
            <MenuLink to={admin_users} className="navbar-item">Users</MenuLink>
            {!!playerState.Player && <MenuLink to={admin_cheat(playerState.Player.playerId)} className="navbar-item">Your cheats</MenuLink>}
            <hr className="navbar-divider" />
            <MenuLink to={dev_solarSystemSamples} className="navbar-item">Solar System Samples</MenuLink>
            <hr className="navbar-divider" />
            <MenuLink to={dev_balance_production} className="navbar-item">Production</MenuLink>
            <MenuLink to={dev_balance_costOfUpgrades} className="navbar-item">Cost of Upgrades</MenuLink>
            <hr className="navbar-divider" />
            <DebugTicker />
        </div>
    </div>;
});