import { combatStatsProblems } from '../../../../../Entities/SolarSystem/Problems/Military/CombatStatsProblems';
import { mergeProblemMaybes, ProblemCategory } from '../../../../../Entities/SolarSystem/Problems/Problem';
import { SolarSystemWrapper } from '../../../../../Entities/SolarSystem/SolarSystemWrapper';
import { ProblemList } from '../Advisor/ProblemList';

type Props = {
    solarSystemWrapper: SolarSystemWrapper
}

export const Problems = (props: Props) => {

    const shipProblems = props.solarSystemWrapper.problems.filter(x => x.category === ProblemCategory.Ships);

    const problems = mergeProblemMaybes(combatStatsProblems(props.solarSystemWrapper), shipProblems);

    return <ProblemList problems={problems} />;
};