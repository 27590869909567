import React from 'react';
import { CurrencyTransactionType, IPagedOfCurrencyTransactionDetail } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { PagedLinks } from '../../../Components/Base/Paging/PagedLinks';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    currencyTransactions: IPagedOfCurrencyTransactionDetail,
    onLinkClicked: (pageIndex: number) => void | Promise<void>
}

export const CurrencyTransactionTable = (props: Props) => {
    if (!props.currencyTransactions.results || props.currencyTransactions.results.length <= 0) {
        return null;
    }

    return <SubPanel heading={"Currency Transactions"}
        footerContent={<PagedLinks {...props.currencyTransactions} onLinkClicked={(pageIndex) => props.onLinkClicked(pageIndex)} />}>
        <Table isFullWidth
            heading={
                <>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Detail</th>
                </>
            }>
            {props.currencyTransactions.results.map((c, i) => <tr key={i}>
                <td>{ValueFormatter.formatTimeOrDate(c.date)}</td>
                <td>{CurrencyTransactionType[c.type]}</td>
                <td>{c.amount}</td>
                <td>{c.detail}</td>
            </tr>)}
        </Table>
    </SubPanel>
};