import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, ISolarSystemDetail, ShipClass } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonChooser, choice } from '../../../Components/Base/Buttons/ButtonChooser';
import { Table } from '../../../Components/Base/Containers/Table';
import { LocaleNumberFromCollection } from '../../../Components/Base/Text/LocaleNumber';
import { SendShipsBetweenSystemButtons } from '../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { ShipTypeLinkResponsive } from '../../../Components/FusionShift/Links/ShipTypeLink';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';

type Display = "All" | "Available" | "Moving" | "Non Grounded" | "Grounded"

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type Count = {
    all: { [key: string]: number },
    available: { [key: string]: number },
    moving: { [key: string]: number },
    grounded: { [key: string]: number },
    nonGrounded: { [key: string]: number }
}

type BaseRowProps = Count & {
    id: string,
    label: React.ReactNode
}

type RowProps = BaseRowProps & {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem?: ISolarSystemDetail | undefined,
    display: Display,
    shipTypeNames: string[]
}

type CellProps = Count & {
    shipTypeName: string,
    display: Display
}

const movementTypes = [
    FleetMovementType.Reinforce,
    FleetMovementType.Rebase
];

const Cell = (props: CellProps) => {

    return <td>
        {props.display === "All" && <LocaleNumberFromCollection id={props.shipTypeName} values={props.all} />}
        {props.display === "Available" && <LocaleNumberFromCollection id={props.shipTypeName} values={props.available} />}
        {props.display === "Moving" && <LocaleNumberFromCollection id={props.shipTypeName} values={props.moving} />}
        {props.display === "Non Grounded" && <LocaleNumberFromCollection id={props.shipTypeName} values={props.nonGrounded} />}
        {props.display === "Grounded" && <LocaleNumberFromCollection id={props.shipTypeName} values={props.grounded} />}
    </td>;
};

const Row = (props: RowProps) => {

    return <tr className={props.id === "total" ? "is-total" : ""}>
        <td>
            {props.label}
        </td>
        {props.shipTypeNames.map(s => <Cell key={s} shipTypeName={s} {...props} />)}
        <td className="is-double-button">
            {props.showSendButtons && props.solarSystem !== undefined &&
                <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem} movementTypes={movementTypes} />
            }
        </td>
    </tr>;
};

export const Ships = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [display, setDisplay] = React.useState<Display>("All");
    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    const memoRows = React.useMemo(() => {

        if (!worldState.ShipTypeSettings) {
            return [];
        }

        const rows: BaseRowProps[] = [];

        for (let solarSystem of props.solarSystems) {

            const available = solarSystem.solarSystem.availableShips ?? {};
            const moving = FleetHelper.sumOfShipsFromFleets(solarSystem.solarSystem.fleets ? solarSystem.solarSystem.fleets.filter(x => x.homeSolarSystemId === solarSystem.solarSystem.solarSystemId) : []);
            const grounded = CollectionHelper.mergeQuantityDictionaries([solarSystem.solarSystem.groundedShips, FleetHelper.sumOfShipsFromFleets(solarSystem.solarSystem.activatingShips)]);
            const nonGrounded = CollectionHelper.mergeQuantityDictionaries([available, moving]);
            const all = CollectionHelper.mergeQuantityDictionaries([grounded, nonGrounded]);

            const row = {
                id: solarSystem.solarSystem.solarSystemId.toString(),
                solarSystem: solarSystem.solarSystem,
                label: <SelectSolarSystemLink solarSystem={solarSystem.solarSystem} hideActionIcons />,
                all,
                available,
                moving,
                grounded,
                nonGrounded
            };

            rows.push(row);
        }

        rows.push({
            id: "total",
            label: "Total",
            all: CollectionHelper.mergeQuantityDictionaries(rows.map(x => x.all)),
            available: CollectionHelper.mergeQuantityDictionaries(rows.map(x => x.available)),
            moving: CollectionHelper.mergeQuantityDictionaries(rows.map(x => x.moving)),
            grounded: CollectionHelper.mergeQuantityDictionaries(rows.map(x => x.grounded)),
            nonGrounded: CollectionHelper.mergeQuantityDictionaries(rows.map(x => x.nonGrounded))
        });

        return rows;

    }, [worldState.ShipTypeSettings, props.solarSystems]);

    const memoShips = React.useMemo(() => {

        if (!worldState.ShipTypeSettings || !playerState.Player) {
            return [];
        }

        return Object.values(worldState.ShipTypeSettings.data)
            .filter(x => x.factionTypeName === playerState.Player!.factionTypeName && x.class !== ShipClass.Drone)
            .sort((a, b) => a.order - b.order);

    }, [worldState.ShipTypeSettings, playerState.Player]);

    if (!worldState.ShipTypeSettings) {
        return null;
    }

    const shipTypeNames = memoShips.map(x => x.typeName);

    const options = [
        choice<Display>("All", "All", IconHelper.Categories.Summary),
        choice<Display>("Available", "Available", IconHelper.Ships.Ship),
        choice<Display>("Moving", "Moving", IconHelper.Ships.Send),
        choice<Display>("Non Grounded", "Non Grounded", IconHelper.Ships.Ship),
        choice<Display>("Grounded", "Grounded", IconHelper.Ships.Grounded)
    ];

    return <>
        <ButtonChooser values={options} valueChanged={setDisplay} value={display} isPulledRight />
        <Table isFullWidth heading={
            <>
                <th>
                </th>
                {memoShips.map(x => <th key={x.typeName}>
                    <ShipTypeLinkResponsive shipType={x} />
                </th>)}
                <th></th>
            </>
        }>
            {memoRows.map(x => <Row
                key={x.id}
                shipTypeNames={shipTypeNames}
                display={display}
                {...x}
                sourceSolarSystemId={sourceSolarSystemId}
                setSourceSolarSystemId={setSourceSolarSystemId}
                showSendButtons={props.solarSystems.length > 1}
            />)}
        </Table>
    </>;
});