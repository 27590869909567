import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IGameSettings, ItemCategory } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../../Components/Base/Containers/SplitLayout';
import { ItemsTableWithHeading } from '../../../../Components/FusionShift/Items/ItemsTable';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { ItemsHelper } from '../../../../Helpers/ItemsHelper';
import { ResourceDetailTable } from './DetailTable/ResourceDetailTable';
import { ShipUpkeepTable } from './ShipUpkeepTable';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
    gameSettings: IGameSettings,
}

export const Items = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const solarSystem = props.solarSystemWrapper.solarSystem;

    if (!solarSystem.itemsDetail || !solarSystem.items || !worldState.ShipTypeSettings || !worldState.ItemTypeSettings) {
        return null;
    }

    const items = ItemsHelper.splitItems(solarSystem.items, worldState.ItemTypeSettings.data);

    return <>
        <ResourceDetailTable
            itemsDetail={solarSystem.itemsDetail}
            items={props.solarSystemWrapper.solarSystem.items ?? {}}
            celestials={solarSystem.celestials}
        />
        <ShipUpkeepTable
            solarSystem={solarSystem}
            shipTypeSettings={worldState.ShipTypeSettings}
            itemTypeSettings={worldState.ItemTypeSettings}
            gameSettings={props.gameSettings}
        />
        <SplitLayout
            left={
                <ItemsTableWithHeading
                    capacities={solarSystem.itemsDetail.otherCapacities}
                    production={solarSystem.itemsDetail.netFinal}
                    category={ItemCategory.Commodity}
                    items={items.commodities} />
            }
            right={
                <ItemsTableWithHeading
                    capacities={solarSystem.itemsDetail.otherCapacities}
                    production={solarSystem.itemsDetail.netFinal}
                    consumption={solarSystem.itemsDetail.production.consumed}
                    category={ItemCategory.RawResource}
                    items={items.rawResource} />
            } />
    </>;
});