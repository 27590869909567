import React from "react";
import { Subscription, CheckoutState } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";

type ActiveOngoingPaidSubscriptionControlProps = {
    subscription: Subscription,
    reloadCallback: (message: string) => any
}

export const ActiveOngoingPaidSubscriptionControl = (props: ActiveOngoingPaidSubscriptionControlProps) => {

    const apiState = React.useContext(ApiStateContext);

    function cancel() {
        return apiState.PremiumClient.cancelCheckoutSubscription().then(_ => {
            props.reloadCallback(`Your subscription has been scheduled for cancellation. It will remain active until ${ValueFormatter.formatShortDate(props.subscription.periodEnd)}. It may take a few minutes for this change to take effect.`);
        });
    }

    function resume() {
        return apiState.PremiumClient.resumeCheckoutSubscription().then(_ => {
            props.reloadCallback(`Your subscription has been renewed. It will renew automatically on ${ValueFormatter.formatShortDate(props.subscription.periodEnd)} and your original payment method will be charged. It may take a few minutes for this change to take effect.`);
        });
    }

    function describe() {
        if (props.subscription.state === CheckoutState.Ongoing) {
            return "You have an active subscription which will automatically renew on " + ValueFormatter.formatShortDate(props.subscription.periodEnd)
        }
        if (props.subscription.state === CheckoutState.PendingCancel) {
            return "Your subscription is currently active but is scheduled to be cancelled on " + ValueFormatter.formatShortDate(props.subscription.periodEnd)
        }

        return "Unknown subscription state";
    }

    return <Paragraph>
        {describe()}.
        {props.subscription.state === CheckoutState.Ongoing && <Button className="is-pulled-right" type="danger" confirm="Are you sure you want to cancel your subscription? It will be active for the remainder of the current billing period." action={cancel} icon={IconHelper.General.Cancel} text="Cancel Subscription" />}
        {props.subscription.state === CheckoutState.PendingCancel && <Button className="is-pulled-right" type="action" action={resume} icon={IconHelper.General.Confirm} text="Resume Subscription" />}
    </Paragraph>;
}