import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { AppStateContext } from './ApplicationState/ContextRoot';
import { MessageRoot } from './Components/FusionShift/Messages/MessageRoot';
import { AuthenticatedInitialLoad } from './Navigation/AuthenticatedInitialLoad';
import { InitialLoadError } from './Views/Home/InitialLoadError';
import { TutorialMissionControl } from './Views/Missions/TutorialMissionControl';

export const AppContent = observer(() => {

    const appState = React.useContext(AppStateContext);

    if (appState.IsAuthenticatedButLoading) {
        return <AuthenticatedInitialLoad />;
    }

    if (appState.ApplicationState === "ErrorDuringInitialLoad") {
        return <InitialLoadError />;
    }

    return <>
        <MessageRoot />
        <TutorialMissionControl />
        <Outlet />
    </>;
});