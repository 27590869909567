import { observer } from "mobx-react-lite";
import * as React from "react";
import { CelestialType, IItemTypeSettings } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext } from "../../../ApplicationState/ContextRoot";
import { ItemTypeLink } from "../Links/Items/ItemTypeLink";
import { RichTextArray } from "../RichTextArray";

type Props = {
    itemTypeSettings: IItemTypeSettings,
    celestialType: CelestialType
}

export const CelestialTypeDescription = observer((props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const generatedItem = props.celestialType.generatedItemTypeName in props.itemTypeSettings.data ? props.itemTypeSettings.data[props.celestialType.generatedItemTypeName] : undefined;

    const description = descriptionState.Celestials?.find(x => x.typeName === props.celestialType.typeName);

    return <>
        <RichTextArray text={description?.description} />
        {!!generatedItem && <>
            <br />
            Generates <ItemTypeLink itemType={generatedItem} /> when mined or harvested
        </>}
    </>;
});