import { IShipTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { shipsProblemBuilder } from "./ShipsProblems";

export function notEnoughMinersProblems(shipTypeSettings: IShipTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    return Object.keys(wrapper.systemMiners).map(x => {
        return {
            ...wrapper.systemMiners[x],
            shipType: shipTypeSettings.data[x]
        }
    })
        .filter(x => x.has < x.needs)
        .map(x => {

            const text = `${wrapper.solarSystem.name} has configured mining repeats requiring ${x.needs} ${x.shipType.name} but only ${x.has} are non-grounded in the system.${x.rebasing > 0 ? ` ${x.rebasing} are rebasing to the system.` : ""}`;

            if (x.has + x.rebasing >= x.needs) {
                return shipsProblemBuilder.infoProblem("Not Enough Miners", text);
            }

            return shipsProblemBuilder.warningProblem("Not Enough Miners", text);
        });
}


export function idleMinersProblems(shipTypeSettings: IShipTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    return Object.keys(wrapper.systemMiners).map(x => {
        return {
            ...wrapper.systemMiners[x],
            shipType: shipTypeSettings.data[x]
        }
    })
        .filter(x => x.has + x.rebasing > x.needs)
        .map(x => {

            if (x.has >= x.needs && x.rebasing > 0 && x.needs > 0) {
                const rebasingText = `${wrapper.solarSystem.name} has ${x.rebasing} ${x.shipType.name} rebasing to the system which will need to be assigned to a repeat fleet.`;

                return shipsProblemBuilder.infoProblem("Idle Miners", rebasingText);
            }

            const description = x.needs === 0 ? "none" : `only ${x.needs}`;
            const idleText = `${wrapper.solarSystem.name} has ${x.has} ${x.shipType.name} but ${description} are assigned to repeat fleets.${x.rebasing > 0 ? ` ${x.rebasing} are rebasing to the system.` : ""}`;

            return shipsProblemBuilder.infoProblem("Idle Miners", idleText);
        });
}