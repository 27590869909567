import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button } from './Button';

type Props = {
    action: () => any,
    isDisabled?: boolean,
    scrollToTop?: boolean
}

export const NextButton = (props: Props) => {
    return <Button type="nav" {...props} icon={IconHelper.General.Next} text="Next" />
};

NextButton.defaultProps = {
    scrollToTop: true
};