import * as React from 'react';
import { FleetMovementType } from '../../../ApplicationState/ApiClient';
import { StepData, Steps, stepWithIcon } from '../../../Components/Base/Steps';
import { IconHelper } from '../../../Helpers/IconHelper';
import { SendShipsHelper } from '../../../Helpers/SendShipsHelper';
import { SendShipsBag } from "../SendShipsBag";

export type SendShipsStage = "Target" | "Movement Type" | "Ships" | "Trade" | "Cargo" | "Collection" | "Summary";

type Props = {
    currentStage: SendShipsStage,
    changeStage: (stage: SendShipsStage) => any,
    sendShipsBag: SendShipsBag
}

export const StagesControl = (props: Props) => {

    const [steps, setSteps] = React.useState<StepData<SendShipsStage>[] | undefined>(undefined);

    React.useEffect(() => {

        const allowedStages = SendShipsHelper.allowedStages(props.sendShipsBag);
        const asSteps = allowedStages.map(s => {
            switch (s) {
                case "Target":
                    return stepWithIcon<SendShipsStage>("Target", IconHelper.Combat.Target, "Target", "Choose a target");
                case "Movement Type":
                    return stepWithIcon<SendShipsStage>("Movement Type", IconHelper.Ships.Send, "Movement Type", "Choose a movement type");
                case "Ships":
                    return stepWithIcon<SendShipsStage>("Ships", IconHelper.Ships.Ship, "Ships", "Select ships to send");
                case "Trade":
                    return stepWithIcon<SendShipsStage>("Trade", IconHelper.Ships.movementType(FleetMovementType.Trade), "Trade", "Goods to trade");
                case "Cargo":
                    return stepWithIcon<SendShipsStage>("Cargo", IconHelper.Ships.movementType(FleetMovementType.Delivery), "Cargo", "Goods in cargo");
                case "Collection":
                    return stepWithIcon<SendShipsStage>("Collection", IconHelper.Ships.movementType(FleetMovementType.Delivery), "Collection", "Goods to collect");
                case "Summary":
                    return stepWithIcon<SendShipsStage>("Summary", IconHelper.General.Confirm, "Summary", "Summary and confirmation");
            }
        });
        setSteps(asSteps)

    }, [props.sendShipsBag.MovementType, props.sendShipsBag.allowedConfigurations]);

    if (steps === undefined) {
        return null;
    }

    const canSkip = props.sendShipsBag.HasTarget && props.sendShipsBag.MovementType !== undefined;

    return <Steps
        steps={steps}
        currentStep={props.currentStage}
        onStepClicked={props.changeStage}
        allowSkippingToFutureSteps={canSkip}
        isMobileHorizontal
    />;
};