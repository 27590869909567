import * as React from "react";
import { CombatReportStatus, CombatResultScale, FleetMovementType, ICombatReport, IPlayerMinimal, ISolarSystemMinimal } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { FleetMovementTypeIcon } from "../../../Components/FusionShift/Icons/FleetMovementTypeIcon";
import { EmblemImage } from "../../../Components/FusionShift/Images/EmblemImage";
import { LocationLink } from "../../../Components/FusionShift/Links/LocationLink";
import { PlayerLink } from "../../../Components/FusionShift/Links/PlayerLink";
import { SolarSystemLink } from "../../../Components/FusionShift/Links/SolarSystemLink";
import { RelationHelper } from "../../../Helpers/RelationHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Visibility } from "./Visibility";

type Props = {
    combatReport: ICombatReport
}

type PlayerProps = {
    isSimulated: boolean,
    player: IPlayerMinimal
}

const CombatReportPlayer = (props: PlayerProps) => {

    if (props.isSimulated) {
        return <>{props.player.name}</>;
    }

    return <PlayerLink {...props} />;
}

type SolarSystemProps = {
    isSimulated: boolean,
    solarSystem: ISolarSystemMinimal
}

const CombatReportSolarSystem = (props: SolarSystemProps) => {

    if (props.isSimulated) {
        return <>{props.solarSystem.name}</>;
    }

    return <SolarSystemLink {...props} />;
}

export const Summary = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    function victoryClassName() {

        if (props.combatReport.playerId !== playerState.Player!.playerId) {
            return "";
        }
        if (props.combatReport.victory) {
            return RelationHelper.Relation_Friend;
        }

        return RelationHelper.Relation_Enemy;
    }

    function combatReportResult() {
        if (!props.combatReport.attackingPlayer) {
            return "Unknown";
        }

        const scale = props.combatReport.resultScale !== null && props.combatReport.resultScale !== undefined ? CombatResultScale[props.combatReport.resultScale] + " " : "";

        if (props.combatReport.playerId !== playerState.Player!.playerId) {
            return `${scale}${props.combatReport.defenderVictory ? "Defender Victory" : "Attacker Victory"}`;
        }

        if (props.combatReport.victory) {
            return <span className={RelationHelper.Relation_Friend}>{scale} Victory</span>
        }

        if (props.combatReport.attackingFleetMovementType === FleetMovementType.Recon && props.combatReport.defendingPlayer?.playerId === playerState.Player?.playerId) {
            return <span className={RelationHelper.Relation_Enemy}>Reconned</span>
        }

        return <span className={RelationHelper.Relation_Enemy}>{scale} Defeat</span>
    }

    const reinforcingPlayersToUse = props.combatReport.detail && props.combatReport.detail.reinforcingPlayers ? props.combatReport.detail.reinforcingPlayers.filter(x => x.playerId !== props.combatReport.defendingPlayer?.playerId) : [];

    const showReinforcingPlayers = reinforcingPlayersToUse.length > 0;
    const reinforcingPlayers = showReinforcingPlayers && reinforcingPlayersToUse
        .filter(p => p.playerId !== props.combatReport.defendingPlayer?.playerId)
        .map((p, i) => [
            i > 0 && ", ",
            <CombatReportPlayer key={p.playerId} player={p} isSimulated={props.combatReport.isSimulated} />
        ]);

    return <SubPanel heading={{
        content:
            <>
                <FleetMovementTypeIcon movementType={props.combatReport.attackingFleetMovementType} setColour={false} isPlayerOwner={false} className={victoryClassName()} />
                {combatReportResult()}
            </>
    }}
    >
        <div className="summary">
            <div className="attacker-emblem">
                {!props.combatReport.isSimulated &&
                    props.combatReport.attackingPlayer && props.combatReport.attackingPlayer!.emblemFilename &&
                    <EmblemImage filename={props.combatReport.attackingPlayer && props.combatReport.attackingPlayer.emblemFilename} />}
            </div>
            <ul className="summary-info">
                <li>
                    Date: {ValueFormatter.formatTimeOrDate(props.combatReport.date, true)}
                </li>
                <li><br /></li>
                <li>
                    Attacker:&nbsp;
                    {!!props.combatReport.attackingPlayer && <CombatReportPlayer player={props.combatReport.attackingPlayer} isSimulated={props.combatReport.isSimulated} />}
                </li>
                <li>
                    Defender:&nbsp;
                    {!!props.combatReport.defendingPlayer && <CombatReportPlayer player={props.combatReport.defendingPlayer} isSimulated={props.combatReport.isSimulated} />}
                </li>
                <li><br /></li>
                <li>
                    Location:&nbsp;
                    {!!props.combatReport.defendingSolarSystem && <CombatReportSolarSystem solarSystem={props.combatReport.defendingSolarSystem} isSimulated={props.combatReport.isSimulated} />}
                    {!props.combatReport.defendingSolarSystem && <LocationLink x={props.combatReport.locationX} y={props.combatReport.locationY} />}
                </li>
                {showReinforcingPlayers &&
                    <li>
                        Reinforced by:{" "}
                        {reinforcingPlayers}
                    </li>
                }
                {props.combatReport.detail && props.combatReport.detail.rounds.length > 1 &&
                    <li>
                        Combat took place over {props.combatReport.detail.rounds.length} rounds
                    </li>
                }
                {props.combatReport.playerId === playerState.Player?.playerId && (props.combatReport.status === CombatReportStatus.Public || playerState.Player.federation !== undefined) &&
                    <>
                        <li><br /></li>
                        <li>
                            <Visibility combatReport={props.combatReport} player={playerState.Player!} />
                        </li>
                    </>
                }
            </ul>
            <div className="defender-emblem">
                {!props.combatReport.isSimulated && <EmblemImage filename={props.combatReport.defendingPlayer && props.combatReport.defendingPlayer.emblemFilename} />}
            </div>
        </div>
    </SubPanel>;
};
