import { CreditsView } from "../../Views/Credits/CreditsView";
import EmblemView from "../../Views/Emblems/EmblemView";
import { InviteFriends } from "../../Views/InviteFriends/InviteFriendsView";
import { SettingsView } from "../../Views/Settings/SettingsView";
import { route } from "./Root";

export const credits = "/credits";
export const inviteFriends = "/invites";
export const settings = "/settings"
export const emblems = "/emblems";

export const personalRoutes = [

    route(credits, CreditsView, "Credits"),
    route(emblems, EmblemView, "Emblem Editor"),
    route(inviteFriends, InviteFriends, "Invite Friends"),
    route(settings, SettingsView, "Settings")
];