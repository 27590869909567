import classNames from "classnames";
import React from "react";
import { Cost, IGameSettings } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { BuildHelper } from "../../../Helpers/BuildHelper";
import { HoursIcon } from "../Icons/HoursIcon";
import { ItemsControl } from "./ItemsControl";

type Props = {
    cost: Cost,
    availableItems?: { [key: string]: number } | undefined,
    multipliedBy?: number,
    hideDuration?: boolean,
    className?: string | undefined,
    onePerLine?: boolean,
    showFullName?: boolean
}

type DurationProps = {
    cost: Cost,
    multipliedBy?: number,
    gameSettings: IGameSettings
}

export const CostControl = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!props.cost.items || !worldState.GameSettings) {
        return null;
    }

    const className = classNames(props.className);

    return <span className={className}>
        {!props.hideDuration && <CostDurationIcon {...props} gameSettings={worldState.GameSettings} />}
        <ItemsControl
            items={props.cost.items}
            {...props}
            expandDetails
        />
    </span>;
};

export const CostDurationIcon = (props: DurationProps) => {

    const duration = BuildHelper.calculateDurationInHours(props.cost, props.gameSettings, props.multipliedBy);

    return <HoursIcon quantity={duration} />
};