import { Coordinate } from '../../Entities/Shared';

export class MapHelper {

    public static get SolarSystemSize(): number { return 70; }

    public static fixOffsetX(value: number, by: number): number {
        return ((value - by) * this.SolarSystemSize) + (this.SolarSystemSize / 2);
    }

    public static fixOffsetY(value: number, by: number): number {
        return ((value - by) * this.SolarSystemSize) + (this.SolarSystemSize / 2);
    }

    public static coordinateDiff(coord: Coordinate, map: { centerX: number, centerY: number }) {
        const diff = {
            x: coord.x - map.centerX,
            y: coord.y - map.centerY
        };

        return diff;
    }
}