import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { MarketSearch } from "../BrowseView";
import { MarketSearchRender } from "./MarketTradeRender";

type Props = {
    theyDesireItem: MarketSearch,
    theyOfferItem: MarketSearch,
    isWrapped?: boolean
}

export const BrowseSummary = (props: Props) => {

    if (props.theyDesireItem === undefined && props.theyOfferItem === undefined) {
        return null;
    }

    function content() {
        return <>Showing orders where <MarketSearchRender item={props.theyDesireItem} /> can be traded for <MarketSearchRender item={props.theyOfferItem} /></>;
    }

    if (!props.isWrapped) {
        return content();
    }

    return <Paragraph>
        {content()}
    </Paragraph>;
};
