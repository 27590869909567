import classNames from "classnames";
import * as React from "react";
import { ReactNode } from "react";

type Props = {
    children: ReactNode,
    className?: string,
    isVerticalOnMobile?: boolean,
    isPulledRight?: boolean,
    isGrowChildren?: boolean,
    isFieldOnly?: boolean,
    isCentered?: boolean
}

export const ButtonHolder = (props: Props) => {

    const children = React.Children.toArray(props.children);

    if (!children || children.length === 0) {
        return null;
    }

    const classes = classNames(
        "field",
        "has-addons",
        props.className,
        {
            "button-holder": !props.isFieldOnly,
            "is-vertical-mobile": props.isVerticalOnMobile,
            "is-pulled-right": props.isPulledRight,
            "is-grow-children": props.isGrowChildren,
            "is-justify-content-center": props.isCentered
        });

    return <div className={classes}>
        {children.map((c, i) =>
            <div key={i} className="control">{c}</div>
        )}
    </div>;
};

ButtonHolder.defaultProps = {
    className: ""
};