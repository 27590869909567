import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IPlayerMinimal, IPolicyTypeSettings, Policy, PolicyProposalType, PolicyStatus } from '../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Table } from '../../Components/Base/Containers/Table';
import { CountingNumber } from '../../Components/Base/Text/CountingNumber';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { PolicyTypeIcon } from '../../Components/FusionShift/Icons/PolicyTypeIcon';
import { PolicyLink } from '../../Components/FusionShift/Links/PolicyLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';

type Props = {
    policies: Policy[],
    reloadCallback: () => any
};

type RowProps = {
    policy: Policy,
    player: IPlayerMinimal,
    policyTypeSettings: IPolicyTypeSettings,
    reloadCallback: () => any
};

const PolicyRow = (props: RowProps) => {

    const apiState = React.useContext(ApiStateContext);

    function vote(vote: boolean | undefined) {
        if (vote === undefined) {
            return apiState.PoliticsClient.abstainFromPolicyVote(props.policy.policyId).then(_ => {
                props.reloadCallback();
            });
        }

        return apiState.PoliticsClient.voteForPolicy(props.policy.policyId, vote).then(_ => {
            props.reloadCallback();
        });
    }

    const canVote = !props.policy.hasObservingPlayerAcceptedBribe && !props.player.isDefeated;

    return <tr>
        <td><PolicyTypeIcon policyType={props.policyTypeSettings.data[props.policy.policyTypeName]} /> </td>
        <td><PolicyLink {...props} /></td>
        <td>{PolicyStatus[props.policy.status]}        </td>
        {props.policy.proposalType !== PolicyProposalType.Vote && <td colSpan={3}>
            {ValueFormatter.friendlyNameForPolicyProposalType(props.policy.proposalType)}
        </td>}
        {props.policy.proposalType === PolicyProposalType.Vote && <>
            <td>
                {canVote && props.policy.status === PolicyStatus.Voting && <Button type="action" icon={IconHelper.Politics.Vote} action={() => vote(true)} isDisabled={props.policy.observingPlayersVote === true} />}
                {props.policy.status !== PolicyStatus.Voting && <CountingNumber value={props.policy.votesFor} />}
            </td>
            <td>
                {canVote && props.policy.status === PolicyStatus.Voting && <Button type="danger" icon={IconHelper.Politics.Vote} action={() => vote(false)} isDisabled={props.policy.observingPlayersVote === false} />}
                {props.policy.status !== PolicyStatus.Voting && <CountingNumber value={props.policy.votesAgainst} />}
            </td>
            <td>
                {canVote && props.policy.status === PolicyStatus.Voting && <Button type="warning" icon={IconHelper.Politics.Vote} action={() => vote(undefined)} isDisabled={props.policy.observingPlayersVote === undefined || props.policy.observingPlayersVote === null} />}
                {props.policy.status !== PolicyStatus.Voting && <CountingNumber value={props.policy.votesAbstain} />}
            </td>
        </>}
    </tr>;
};

export const PolicyTable = observer((props: Props) => {


    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (!worldState.PolicyTypeSettings || !playerState.Player) {
        return null;
    }

    return <Table
        isFullWidth
        heading={<>
            <td></td>
            <th>Policy</th>
            <th>Status</th>
            <th>Yea</th>
            <th>Nay</th>
            <th>Abstain</th>
        </>}>
        {props.policies.sort((a, b) => b.policyId - a.policyId).map(x => <PolicyRow
            key={x.policyId}
            policy={x}
            policyTypeSettings={worldState.PolicyTypeSettings!}
            player={playerState.Player!}
            {...props}
        />)}
    </Table>;
});


export const PolicyTableInPanel = (props: Props) => {

    return <SubPanel heading="Policies" isUnpadded={props.policies.length !== 0}>
        {props.policies.length === 0 && <Paragraph>No policies at this time.</Paragraph>}
        {props.policies.length !== 0 && <PolicyTable {...props} />}
    </SubPanel>
};