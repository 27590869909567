import { CombatRoundType, FleetMovementType, IGameSettings, IInstallationTypeSettings, ShipType } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { TimeHelper } from "../../../Helpers/TimeHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { TextAndIcon } from "../../Base/Text/TextAndIcon";
import { InstallationTypeLink } from "../Links/InstallationTypeLink";
import { ShipClassName } from "./ShipClassName";

type Props = {
    shipType: ShipType,
    installationTypeSettings: IInstallationTypeSettings,
    gameSettings: IGameSettings
}

export const ShipTypeStats = (props: Props) => {

    const installationType = props.installationTypeSettings.data![props.shipType.builtAtInstallationTypeName!];

    return <ul>
        <li>
            Class: <ShipClassName shipClass={props.shipType.class} />
        </li>
        <li>
            Mass: {ValueFormatter.formatMass(props.shipType.mass, false)}
        </li>
        <li>
            Built at: <InstallationTypeLink installationType={installationType} /> (level {props.shipType.builtAtMinimumLevel})
        </li>
        <li>
            Default Combat Round: {CombatRoundType[props.shipType.defaultCombatRoundType]}
        </li>
        {props.shipType.canClaim &&
            <li>
                <TextAndIcon text={"Can claim systems"} icon={IconHelper.Ships.movementType(FleetMovementType.Claim)} />
            </li>
        }
        {props.shipType.canIntercept &&
            <li>
                <TextAndIcon text={"Can intercept"} icon={IconHelper.Combat.Intercept} />

            </li>
        }
        {props.shipType.hasProbes &&
            <li>
                Has probes
            </li>
        }
        {props.shipType.miningStrength > 0 &&
            <li>
                Mining strength: {props.shipType.miningStrength} ({TimeHelper.tickValueToPerHour(props.gameSettings, props.shipType.miningStrength)} per hour)
            </li>
        }
        {props.shipType.siegeDamage > 0 &&
            <li>
                <TextAndIcon text={`Siege damage: ${props.shipType.siegeDamage}`} icon={IconHelper.Combat.Siege} />

            </li>
        }
        {!props.shipType.canDefendAgainstRecon &&
            <li className="has-text-warning">
                Unable to defend against recon
            </li>}
    </ul>;
}