import * as React from "react";
import { ApiStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Icon } from "../../Components/Base/Icon";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";
import { checkRequestFcmPermission } from "../../fcm";

export const NotificationSettings = () => {

    const apiState = React.useContext(ApiStateContext);
    const [permission, setPermission] = React.useState(Notification.permission);

    async function askForPermission() {
        const permission = await checkRequestFcmPermission(apiState.DevicesClient);

        setPermission(permission);
    }

    return <SubPanel heading={{ text: "Notifications", icon: IconHelper.Statuses.Warning }}>
        {permission === "granted" && <>
            <Paragraph>
                You have granted permission for offline notifications.
            </Paragraph>
            <Icon icon={IconHelper.General.Confirm} className="has-text-success" />
        </>}
        {permission !== "granted" && <>
            <Paragraph>
                You have not granted permission for offline notifications.
            </Paragraph>
            <Button type="nav" isPulledRight action={askForPermission} text="Give Permission" icon={IconHelper.General.Confirm} />
        </>}
    </SubPanel>;
};