import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { AppStateContext, PlayerStateContext } from "../../ApplicationState/ContextRoot";
import { IconLink } from "../../Components/Base/IconLink";
import { UnreadIcons } from "../../Components/FusionShift/Icons/UnreadIcons";
import { IconHelper } from "../../Helpers/IconHelper";
import { send_ships } from "../Routing/Misc";
import { CreditsLink } from "./Submenus/CreditsLink";
import { PersonalMenu } from "./Submenus/PersonalMenu";
import { SolarSystemMenu } from "./Submenus/SolarSystemMenu";
import { YourFederationMenu } from "./Submenus/YourFederationMenu";

type ChildProps = {
    className?: string | undefined,
    children?: React.ReactNode | undefined
};

const Child = (props: ChildProps) => {

    const className = classNames("child", props.className);

    return <div className={className}>
        {props.children && props.children}
    </div>
}

export const BottomMenuBar = observer(() => {

    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const ready = appState.IsReady;

    return <div className="navbar bottombar">
        {ready &&
            <Child>
                <SolarSystemMenu />
            </Child>
        }
        <Child className="filler"></Child>
        {playerState.Player && playerState.Player.federation &&
            <Child>
                <YourFederationMenu />
            </Child>
        }
        {playerState.Player &&
            <Child>
                <PersonalMenu />
            </Child>
        }
        {ready &&
            <Child>
                <div className="nav-icons navbar-item">
                    <IconLink to={send_ships} icon={IconHelper.Ships.Send} title="Send ships" />
                    <UnreadIcons />
                    <CreditsLink />
                </div>
            </Child>
        }
    </div>;
});
