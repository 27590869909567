import { ContractType } from "../../ApplicationState/ApiClient";
import { ContractBrowse } from "../../Views/Contracts/ContractBrowse";
import { ContractIndex } from "../../Views/Contracts/ContractIndex";
import { ContractView } from "../../Views/Contracts/ContractView";
import { CreateContractView } from "../../Views/Contracts/CreateContractView";
import { route } from "./Root";

export const contracts = "/contracts";

export function contractById(contractId: number | string) {
    return `${contracts}/${contractId}`;
}
export const contract_create = contracts + "/create";
export const contract_browse = contracts + "/browse";

export function contract_createRansom(agentId: number, playerId?: number | undefined) {

    if (playerId !== undefined) {
        return `${contract_create}?type=${ContractType.Ransom}&forPlayerId=${playerId}&agentId=${agentId}`;
    }

    return `${contract_create}?type=${ContractType.Ransom}&agentId=${agentId}`;
}


export function contract_createForPlayer(playerId: number) {
    return `${contract_create}?forPlayerId=${playerId}`;
}

export function contract_createForTargetSolarSystemId(targetSolarSystemId: number) {
    return `${contract_create}?targetSolarSystemId=${targetSolarSystemId}`;
}

export const contractRoutes = [

    route(contract_create, CreateContractView, "Create Contract"),
    route(contract_browse, ContractBrowse, "Browse Contracts"),
    route(contractById(":contractId"), ContractView, "Contract"),
    route(contracts, ContractIndex, "Your Contracts")
];