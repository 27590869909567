import * as React from "react";
import { CelestialSize, IShipTypeSettings, ISolarSystemDetail, ShipBuildQueueItem, ShipType, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext, WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../../../Components/Base/Buttons/ButtonHolder";
import { Table } from "../../../../../Components/Base/Containers/Table";
import { NumberInput } from "../../../../../Components/Base/Form/Input";
import { H3 } from "../../../../../Components/Base/Text/H";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { CelestialImage } from "../../../../../Components/FusionShift/Celestials/CelestialImage";
import { DateIcon } from "../../../../../Components/FusionShift/Icons/DateIcon";
import { ShipClassIcon } from "../../../../../Components/FusionShift/Icons/ShipClassIcon";
import { ConstructionAnimation } from "../../../../../Components/FusionShift/Images/ConstructionAnimation";
import { ShipTypeImage } from "../../../../../Components/FusionShift/Images/ShipTypeImage";
import { ShipTypeLink } from "../../../../../Components/FusionShift/Links/ShipTypeLink";
import { CollectionHelper } from "../../../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";

type Props = {
    solarSystem: ISolarSystemDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    showBuildShips: boolean
}

type RowProps = Props & {
    queueItem: ShipBuildQueueItem,
    ship: ShipType,
    isFirst: boolean
}

type AddedProps = {
    solarSystem: ISolarSystemDetail,
    shipTypeSettings: IShipTypeSettings
}

const ShipBuildQueueRow = (props: RowProps) => {

    const apiState = React.useContext(ApiStateContext);

    const [isCancelling, setIsCancelling] = React.useState(false);
    const [quantityToCancel, setQuantityToCancel] = React.useState(props.queueItem.quantity);

    function removeFromQueue() {
        return apiState.ShipClient.removeFromShipBuildQueue(props.solarSystem.solarSystemId, props.queueItem.shipBuildQueueItemId, quantityToCancel).then(solarSystem => {
            if (solarSystem) {
                setIsCancelling(false);
                props.reloadCallback(solarSystem);
            }
        });
    }

    return <>
        {!isCancelling && <>
            <td>
                <DateIcon key={`${props.queueItem.shipBuildQueueItemId}_${props.queueItem.quantity}_${props.queueItem.allCompletedDate}`} date={props.queueItem.allCompletedDate} ticksDown />
            </td>
            <td>
                <ShipTypeImage size="small" shipType={props.ship}>
                    {props.isFirst && <ConstructionAnimation />}
                </ShipTypeImage>
            </td>
        </>}
        <td>
            {`x${props.queueItem.quantity} `}
            <ShipTypeLink shipType={props.ship} />
        </td>
        {isCancelling && <td colSpan={2}>
            <NumberInput
                maxValue={props.queueItem.quantity}
                value={quantityToCancel}
                valueChanged={setQuantityToCancel}
            />
        </td>}
        <td>
            {!isCancelling &&
                <Button type="nav" className="is-small" icon={IconHelper.General.Delete} action={() => setIsCancelling(true)} />
            }
            {isCancelling &&
                <ButtonHolder isPulledRight>
                    <Button type="warning" className="is-small" icon={IconHelper.General.Cancel} action={() => setIsCancelling(false)} />
                    <Button type="danger" className="is-small" icon={IconHelper.General.Delete} action={() => removeFromQueue()} />
                </ButtonHolder>
            }
        </td>
    </>;
};

const ShipBuildQueueAdded = (props: AddedProps) => {

    if (!CollectionHelper.isAnyQuantityInDictionary(props.solarSystem.newlyBuiltShips)) {
        return null;
    }

    const toShow = Object.keys(props.solarSystem.newlyBuiltShips!)
        .map(x => {
            return {
                shipTypeName: x,
                shipClass: props.shipTypeSettings.data[x].class,
                quantity: props.solarSystem.newlyBuiltShips![x]
            }
        });

    return <tr className="has-highlight-bg-success">
        <td colSpan={6}>
            Added to queue:&nbsp;
            {toShow.map((x, i) => [
                i > 0 && ", ",
                <>
                    <ShipClassIcon shipClass={x.shipClass} />{ValueFormatter.formatLocaleNumber(x.quantity)}
                </>
            ])}
        </td>
    </tr>
}

export const ShipBuildQueue = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ShipTypeSettings) {
        return null;
    }

    const isNotBuilding = props.solarSystem.shipBuildQueueByCelestial === undefined || !CollectionHelper.isAnyInDictionary(props.solarSystem.shipBuildQueueByCelestial);

    if (isNotBuilding) {

        if (props.showBuildShips) {
            return null;
        }

        return <Paragraph>
            No ships being assembled.
        </Paragraph>;
    }


    const queueToRender = Object.keys(props.solarSystem.shipBuildQueueByCelestial!).map(q => {
        const items = props.solarSystem.shipBuildQueueByCelestial![q];

        const celestial = props.solarSystem.celestials!.find(c => c.celestialId.toString() === q);

        const queue = items.map(i => {

            const ship = worldState.ShipTypeSettings!.data![i.typeName || ""];

            return {
                queueItem: i,
                ship: ship
            };
        })
            .sort((a, b) => a.queueItem.allCompletedDate < b.queueItem.completedDate ? -1 : 1);

        return {
            celestial: celestial,
            queue: queue
        };
    }).filter(q => q.queue.length > 0);

    if (!queueToRender || queueToRender.length === 0) {
        return null;
    }

    return <Table isFullWidth isHoverable className="ship-build-queue">
        <ShipBuildQueueAdded solarSystem={props.solarSystem} shipTypeSettings={worldState.ShipTypeSettings} />
        {queueToRender.map(q =>
            <React.Fragment key={q.celestial!.celestialId}>
                <tr className={q.queue[0].queueItem.isNew ? "has-highlight-bg-success" : ""} key={q.queue[0].queueItem.shipBuildQueueItemId} >
                    <td>
                        {!!q.celestial && <H3 className="celestial-name">{q.celestial.name} </H3>}
                    </td>
                    <td>
                        {!!q.celestial && <CelestialImage celestial={q.celestial} forcedSize={CelestialSize.Tiny} isLink />}
                    </td>
                    <ShipBuildQueueRow key={q.queue[0].queueItem.shipBuildQueueItemId} {...props} {...q.queue[0]} isFirst />
                </tr>
                {q.queue.map((q2, i) => {
                    if (i === 0) {
                        return null;
                    }
                    return <tr key={q2.queueItem.shipBuildQueueItemId} className={q2.queueItem.isNew ? "has-highlight-bg-success" : ""}>
                        <td colSpan={2}></td>
                        <ShipBuildQueueRow key={q2.queueItem.shipBuildQueueItemId} {...props} {...q2} isFirst={false} />
                    </tr>;
                })}
            </React.Fragment>)}
    </Table>;
};