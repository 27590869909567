import { ICelestialDetail, IItemTypeSettings } from "../../../../ApplicationState/ApiClient";
import { ManufactoryHelper } from "../../../../Helpers/ManufactoryHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { manufactoryProblemBuilder } from "./ManufactoryProblems";

export function outputNodeProblems(itemTypeSettings: IItemTypeSettings, wrapper: SolarSystemWrapper, celestial: ICelestialDetail): ProblemsMaybe {

    if (!celestial.manufactory) {
        return undefined;
    }

    const outputs = celestial.manufactory.outputNodes.filter(n => n.itemTypeName in itemTypeSettings.data &&
        ManufactoryHelper.actualThroughput(wrapper.solarSystem.itemsDetail, celestial.celestialId, n.manufactoryNodeId) === 0).length;

    if (outputs > 0) {
        return manufactoryProblemBuilder.infoProblem(outputs + "x Idle Output Nodes", `The Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} has ${outputs} output ${ValueFormatter.pluralize("node", outputs)} which ${outputs > 1 ? "were" : "was"} unable to get any input resources`)
    }
    return undefined;
}