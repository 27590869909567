import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { NewIcon } from '../../../Components/Base/Icon';
import { PendingAgentActionIcon } from '../../../Components/FusionShift/Icons/UnreadIcons';
import { agents } from '../../Routing/Agents';
import { chat } from '../../Routing/Misc';
import { AuthenticateButtons } from '../Controls/AuthenticateButtons';
import { BurgerButtons } from '../Controls/BurgerButton';
import { MapLink } from '../Controls/MapLink';
import { MenuLink } from '../MenuState';

export const AlwaysShown = observer(() => {

    const appState = React.useContext(AppStateContext);

    if (appState.IsReady) {
        return <>
            <MapLink />
            <MenuLink to={chat} className={`navbar-item ${appState.ChatState.HasUnread ? "unread" : ""}`}>
                {appState.ChatState.HasUnread && <NewIcon isHiddenTablet />}
                Chat
            </MenuLink>
            <MenuLink to={agents} className={`navbar-item is-hidden-mobile500`}>
                <PendingAgentActionIcon />
                Agents
            </MenuLink>
            <BurgerButtons />
        </>
    }

    if (appState.ApplicationState === "Unauthenticated") {
        return <AuthenticateButtons />
    }

    return null;
});