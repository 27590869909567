import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Drone, DroneStatus, ICelestialTypeSettings, IItemTypeSettings, ISolarSystemDetail, ItemType, ShipType } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Icon } from '../../../Components/Base/Icon';
import { ItemTypeIcon } from '../../../Components/FusionShift/Icons/Items/ItemTypeIcon';
import { ShipTypeImage } from '../../../Components/FusionShift/Images/ShipTypeImage';
import { ShipTypeLink } from '../../../Components/FusionShift/Links/ShipTypeLink';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    solarSystem: ISolarSystemDetail
}

type RowProps = {
    solarSystem: ISolarSystemDetail
    shipType: ShipType,
    drones: Drone[],
    celestialTypeSettings: ICelestialTypeSettings,
    itemTypeSettings: IItemTypeSettings
}

const DroneRow = (props: RowProps) => {

    function icons() {
        const statuses: DroneStatus[] = [];
        const items: ItemType[] = [];

        for (let d of props.drones) {
            if (d.status !== DroneStatus.Active && !statuses.includes(d.status)) {
                statuses.push(d.status);
            }
            if (d.status === DroneStatus.Active && d.celestialId) {
                const itemType = CelestialHelper.resourceTypeFromCelestials(props.celestialTypeSettings, props.itemTypeSettings, d.celestialId, props.solarSystem.celestials);
                if (itemType && items.findIndex(r => r.typeName === itemType.typeName) < 0) {
                    items.push(itemType);
                }
            }
        }

        return <>
            {statuses.map(s => <Icon key={s} icon={IconHelper.Ships.droneStatus(s)} />)}
            {items.map(r => <ItemTypeIcon key={r.typeName} itemType={r} />)}
        </>
    }

    return <li>
        <ShipTypeImage shipType={props.shipType} size="small" />
        <span className="name-and-quantity">
            <ShipTypeLink shipType={props.shipType} />
            <br className="is-hidden-tablet" />
            <span className="ship-quantity">
                x{props.drones.length.toLocaleString()}
            </span>
            {icons()}
        </span>
    </li>;
};

export const DronesList = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ShipTypeSettings ||
        !worldState.ItemTypeSettings ||
        !worldState.CelestialTypeSettings ||
        !props.solarSystem.drones || props.solarSystem.drones.activeDrones.length == 0) {
        return null;
    }

    const drones: { [key: string]: RowProps } = {};

    for (let drone of props.solarSystem.drones.activeDrones) {
        if (!(drone.shipTypeName in drones)) {
            drones[drone.shipTypeName] = {
                drones: [],
                solarSystem: props.solarSystem,
                itemTypeSettings: worldState.ItemTypeSettings!,
                celestialTypeSettings: worldState.CelestialTypeSettings!,
                shipType: worldState.ShipTypeSettings.data[drone.shipTypeName]
            };
        }
        drones[drone.shipTypeName].drones.push(drone);
    }

    const dronesToRender = Object.keys(drones).map(d => drones[d]).sort((a, b) => a.shipType.order - b.shipType.order);

    return <>
        {dronesToRender.map(d => <DroneRow key={d.shipType.typeName} {...d} />)}
    </>;
});