import { observer } from "mobx-react-lite";
import * as React from "react";
import { Fleet, RepeatFleet, SolarSystemDetail } from "../../ApplicationState/ApiClient";
import { TabMenuWithChildren } from "../../Components/Base/Containers/TabMenu";
import { Icon } from "../../Components/Base/Icon";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { FleetSummaryIcons } from "../../Components/FusionShift/Icons/FleetSummaryIcons";
import { SolarSystemLink } from "../../Components/FusionShift/Links/SolarSystemLink";
import { SolarSystemWrapper } from "../../Entities/SolarSystem/SolarSystemWrapper";
import { CollectionHelper } from "../../Helpers/CollectionHelper";
import { FleetHelper } from "../../Helpers/FleetHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { TutorialIds } from "../../Navigation/Tutorial/TutorialIds";
import { FleetTable } from "./SubParts/FleetTable";
import { GroundedShipsControl } from "./SubParts/GroundedShipsControl";
import { RepeatFleetList } from "./SubParts/RepeatFleetList";
import { StationedShipsControl } from "./SubParts/StationedShipsControl";

type Props = {
    initialTab?: ShipTabs | undefined
    hideExtras?: boolean,
    isCompressed?: boolean,
    solarSystem: SolarSystemWrapper,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    hideIfDontHaveInitial?: boolean,
    hasSmallTitleOnMobile?: boolean
}

export type ShipTabs = "Stationed" | "Grounded" | "PresentForeign" | "Departures" | "Arrivals" | "Repeats";

export const ShipControl = observer((props: Props) => {

    const [tab, setTab] = React.useState<ShipTabs | undefined>(props.initialTab);

    React.useEffect(() => {
        setTab(props.initialTab);
    }, [props.initialTab]);

    function verify(ar: false | Fleet[] | RepeatFleet[] | undefined | boolean) {
        return ar &&
            ((typeof ar !== "boolean" && ar.length > 0) ||
                (typeof ar === "boolean" && ar === true));
    }

    function hasNew(ar: false | Fleet[] | RepeatFleet[] | undefined | boolean) {
        return ar && typeof ar !== "boolean" && ar.findIndex(s => s.isNew) > -1;
    }

    const solarSystem = props.solarSystem.solarSystem;

    if (!verify(solarSystem.fleets) && !CollectionHelper.isAnyInDictionary(solarSystem.availableShips) && !CollectionHelper.isAnyInDictionary(solarSystem.groundedShips) && !solarSystem.activatingShips) {
        return null;
    }

    const { arrivals,
        departures,
        presentForeign,
        showAvailable,
        showGrounded,
        showPresentForeign,
        showArrivals,
        showDepartures
    } = FleetHelper.solarSystemFleets(solarSystem);

    const showRepeats = !!solarSystem.repeatFleets && solarSystem.repeatFleets.length > 0;

    if (tab === undefined) {
        const preferedOrder: [ShipTabs, false | Fleet[] | RepeatFleet[] | undefined | boolean][] = [
            ["Arrivals", arrivals],
            ["Departures", departures],
            ["PresentForeign", presentForeign],
            ["Repeats", solarSystem.repeatFleets],
            ["Grounded", CollectionHelper.isAnyQuantityInDictionary(solarSystem.groundedShips) || CollectionHelper.isAnyInArray(solarSystem.activatingShips)]
        ];

        let hasSet = false;
        for (let option of preferedOrder) {
            if (hasNew(option[1])) {
                setTab(option[0]);
                hasSet = true;
                break;
            }
        }
        if (tab === undefined && !hasSet) {
            for (let option of preferedOrder) {
                if (verify(option[1])) {
                    setTab(option[0]);
                    break;
                }
            }
        }
        if (tab === undefined && !hasSet && showAvailable) {
            setTab("Stationed");
        }
    }

    function reloadCallback(solarSystem: SolarSystemDetail, goToTab?: ShipTabs | undefined) {
        setTab(goToTab);
        props.reloadCallback(solarSystem);
    }

    function stationedIcons() {
        const hasDrones = solarSystem && solarSystem.drones && solarSystem.drones.activeDrones.length > 0;
        const hasShips = solarSystem && CollectionHelper.isAnyQuantityInDictionary(solarSystem.availableShips);

        return <>
            {hasShips && <Icon icon={IconHelper.Ships.Ship} />}
            {hasDrones && <Icon icon={IconHelper.Ships.Drone} />}
        </>
    }

    function groundedIcons() {
        const hasGrounded = solarSystem && CollectionHelper.isAnyQuantityInDictionary(solarSystem.groundedShips);
        const hasActivating = solarSystem && CollectionHelper.isAnyInArray(solarSystem.activatingShips);

        return <>
            {hasGrounded && <Icon icon={IconHelper.Ships.Grounded} />}
            {hasActivating && <Icon icon={IconHelper.Ships.Activate} />}
        </>;
    }

    const num = (showArrivals ? 1 : 0) + (showPresentForeign ? 1 : 0) + (showDepartures ? 1 : 0) + (showAvailable ? 1 : 0) + (showGrounded ? 1 : 0);

    if (num === 0) {
        return <Paragraph>No ships in <SolarSystemLink solarSystem={solarSystem} /></Paragraph>;
    }

    const tabs = [
        {
            id: "Stationed",
            title: "Stationed",
            isAvailable: showAvailable,
            icons: stationedIcons()
        },
        {
            id: "Grounded",
            title: "Grounded",
            isAvailable: showGrounded,
            icons: groundedIcons()
        },
        {
            id: "Arrivals",
            title: "Arrivals",
            isAvailable: showArrivals,
            icons: <FleetSummaryIcons fleets={arrivals} />
        },
        {
            id: "Departures",
            title: "Departures",
            isAvailable: showDepartures,
            icons: <FleetSummaryIcons fleets={departures} />
        },
        {
            id: "PresentForeign",
            title: "Present Foreign Ships",
            isAvailable: showPresentForeign,
            icons: <FleetSummaryIcons fleets={presentForeign} />
        },
        {
            id: "Repeats",
            title: "Repeats",
            isAvailable: showRepeats,
            icons: <Icon icon={IconHelper.Ships.Repeat} />
        }
    ];

    if (props.initialTab !== undefined && props.hideIfDontHaveInitial && tabs.find(x => x.id === props.initialTab && x.isAvailable) === undefined) {
        return null;
    }

    const availableShips = props.solarSystem.availableShips;

    return <TabMenuWithChildren
        hasSmallTitleOnMobile={props.hasSmallTitleOnMobile}
        id={TutorialIds.ShipsControl}
        className="ships-control"
        active={tab}
        changeTab={t => setTab(t)}
        tabs={tabs}>
        {tab === "Stationed" &&
            <StationedShipsControl solarSystem={solarSystem} hideExtras={props.hideExtras} reloadCallback={props.reloadCallback} />
        }
        {tab === "Grounded" &&
            <GroundedShipsControl solarSystem={solarSystem} hideExtras={props.hideExtras} reloadCallback={props.reloadCallback} isCompressed={props.isCompressed} />
        }
        {tab === "Departures" && departures &&
            <FleetTable solarSystemId={solarSystem.solarSystemId} fleets={departures} reloadCallback={s => reloadCallback(s, "Departures")} availableShips={availableShips}
                display={{
                    arrivalDate: true,
                    target: true
                }} />
        }
        {tab === "Arrivals" && arrivals &&
            <FleetTable solarSystemId={solarSystem.solarSystemId} fleets={arrivals} reloadCallback={reloadCallback} availableShips={availableShips}
                display={{
                    arrivalDate: true,
                    source: true,
                    owner: "with linebreak",
                    mass: true
                }} />
        }
        {tab === "PresentForeign" && presentForeign.length > 0 &&
            <FleetTable solarSystemId={solarSystem.solarSystemId} fleets={presentForeign} reloadCallback={reloadCallback} availableShips={availableShips}
                display={{
                    source: true,
                    owner: "own column"
                }} />
        }
        {tab === "Repeats" && showRepeats &&
            <RepeatFleetList
                fleets={solarSystem.repeatFleets}
                wrapper={props.solarSystem}
                solarSystem={solarSystem}
                reloadCallback={reloadCallback}
                showMinerEdit
            />
        }
    </TabMenuWithChildren>;
});