import classNames from 'classnames';
import { Paragraph } from '../Text/Paragraph';

type Props = {
    className?: string | undefined,
    value: string,
    valueChanged: (value: string) => any,
    rows?: number | undefined,
    placeholder?: string | undefined,
    characterLimit?: number
};

export const TextAreaInput = (props: Props) => {

    const className = classNames(
        "textarea",
        props.className);

    return <div className="control is-clearfix">
        <textarea
            className={className}
            rows={props.rows}
            placeholder={props.placeholder}
            onChange={(e => props.valueChanged(e.target.value))}
            defaultValue={props.value} />
        {props.characterLimit !== undefined && <div className="is-pulled-right">
            <Paragraph
                type={props.value.length > props.characterLimit ? "danger" : "prompt"}>
                {props.value.length} / {props.characterLimit} characters
            </Paragraph>
        </div>}
    </div>;
};

TextAreaInput.defaultProps = {
    rows: 20
};