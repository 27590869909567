import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ContractType, FleetMovementType, IContract, RequestResponse } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ButtonLink } from '../../../Components/Base/Buttons/ButtonLink';
import { ErrorMessageBox } from '../../../Components/Base/MessageBox';
import { ContractHelper } from '../../../Helpers/ContractHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { send_ships_to_contract } from '../../../Navigation/Routing/Misc';

type Props = {
    contract: IContract,
    reloadCallback: () => any
}

export const ContractButtons = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [response, setResponse] = React.useState<RequestResponse | undefined>(undefined);

    function accept() {
        return apiState.ContractsClient.accept(props.contract.contractId).then(response => {

            if (!response.wasSuccess) {
                setResponse(response);
            } else {
                props.reloadCallback();
            }
        })
    }

    function decline() {
        return apiState.ContractsClient.decline(props.contract.contractId).then(response => {

            if (!response.wasSuccess) {
                setResponse(response);
            } else {
                props.reloadCallback();
            }
        })
    }

    function withdraw() {
        return apiState.ContractsClient.withdraw(props.contract.contractId).then(response => {

            if (!response.wasSuccess) {
                setResponse(response);
            } else {
                props.reloadCallback();
            }
        })
    }

    function createSendShipsUrl() {
        if (props.contract.type === ContractType.Attack && props.contract.targetSolarSystem !== undefined) {
            return send_ships_to_contract(props.contract.targetSolarSystem.x, props.contract.targetSolarSystem.y, FleetMovementType.Attack, props.contract.contractId);
        }
        if (props.contract.type === ContractType.Defend && props.contract.targetSolarSystem !== undefined) {
            return send_ships_to_contract(props.contract.targetSolarSystem.x, props.contract.targetSolarSystem.y, FleetMovementType.Reinforce, props.contract.contractId);
        }
        if (props.contract.type === ContractType.Recon && props.contract.targetSolarSystem !== undefined) {
            return send_ships_to_contract(props.contract.targetSolarSystem.x, props.contract.targetSolarSystem.y, FleetMovementType.Recon, props.contract.contractId);
        }
        if (props.contract.type === ContractType.Courier && props.contract.sourceSolarSystem !== undefined) {
            return send_ships_to_contract(props.contract.sourceSolarSystem.x, props.contract.sourceSolarSystem.y, FleetMovementType.Collection, props.contract.contractId);
        }
        if (props.contract.type === ContractType.Ransom && props.contract.targetSolarSystem !== undefined) {
            return send_ships_to_contract(props.contract.targetSolarSystem.x, props.contract.targetSolarSystem.y, FleetMovementType.Collection, props.contract.contractId);
        }
        return undefined;
    }

    if (!playerState.Player) {
        return null;
    }

    const sendShipsUrl = createSendShipsUrl();

    const canAcceptThroughShips = sendShipsUrl !== undefined && sendShipsUrl.length > 0 && ContractHelper.canAccept(props.contract, playerState.Player);
    const canAccept = !canAcceptThroughShips && ContractHelper.canAccept(props.contract, playerState.Player);
    const canDecline = ContractHelper.canDecline(props.contract, playerState.Player.playerId);
    const canWithdraw = ContractHelper.canWithdraw(props.contract, playerState.Player.playerId);

    if (!canAcceptThroughShips && !canAccept && !canDecline && !canWithdraw) {
        return null;
    }

    return <>
        {response !== undefined && <ErrorMessageBox text={response.error} />}
        <ButtonHolder isPulledRight>
            {canWithdraw && <Button type="danger" text="Withdraw" action={withdraw} icon={IconHelper.Economy.ContractWithdraw} confirm="Are you sure you want to withdraw this contract?" isDisabled={response !== undefined} />}
            {canDecline && <Button type="danger" text="Decline" action={decline} icon={IconHelper.Economy.ContractDecline} confirm="Are you sure you want to decline this contract?" isDisabled={response !== undefined} />}
            {canAccept && <Button type="action" text="Accept" action={accept} icon={IconHelper.Economy.ContractAccept} confirm="Are you sure you want to accept this contract?" isDisabled={response !== undefined} />}
            {canAcceptThroughShips && sendShipsUrl && <ButtonLink text="Accept" to={sendShipsUrl} icon={IconHelper.Ships.Send} />}
        </ButtonHolder>
    </>;
});