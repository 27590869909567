import * as React from 'react';
import { ICoordinate, ISolarSystemMinimal } from '../../../ApplicationState/ApiClient';
import { Paragraph } from '../../Base/Text/Paragraph';
import { IRenderFleet } from './Components/AdditionalFleets';
import { PositionalSvg } from './Components/PositionalSvg';
import { OwnedCoordinate, PanAndScale, RenderContext, RenderContextProps } from './RenderContext';

type Props = RenderContextProps & {
    overlayText?: string,
    coordinatesToRender?: (OwnedCoordinate | undefined)[],
    initialCoordinate?: ICoordinate | undefined,
    additionalSolarSystems?: (ISolarSystemMinimal | undefined)[],
    additionalFleets?: (IRenderFleet | undefined)[],
    backgroundFleets?: IRenderFleet[]
}

export const PositionalRender = (props: Props) => {

    const [renderContext, setRenderContext] = React.useState<RenderContext | undefined>(undefined);
    const [previous, setPrevious] = React.useState<PanAndScale>({
        scale: 1,
        x: props.initialCoordinate?.x ?? 0,
        y: props.initialCoordinate?.y ?? 0
    });
    const [override, setOverride] = React.useState<PanAndScale>({
        scale: 1,
        x: 0,
        y: 0
    });

    React.useEffect(() => {

        const coordinatesToRender = (props.coordinatesToRender ?? []).filter(x => x !== undefined).map(x => x!)

        if (!shouldChange(coordinatesToRender, renderContext?.coordinatesToRender)) {
            return;
        }

        if (renderContext !== undefined) {
            setPrevious({
                scale: renderContext.initialScale,
                x: renderContext.boundingBox.centerX,
                y: renderContext.boundingBox.centerY
            });
        }
        setRenderContext(new RenderContext(props, coordinatesToRender));
    }, [props.coordinatesToRender]);

    React.useEffect(() => {
        if (renderContext !== undefined) {
            setOverride({
                scale: renderContext.initialScale,
                x: renderContext.boundingBox.centerX,
                y: renderContext.boundingBox.centerY
            });
        }
    }, [renderContext]);

    function shouldChange(current: OwnedCoordinate[], previous: (OwnedCoordinate[]) | undefined) {
        if (previous === undefined || current.length !== previous.length) {
            return true;
        }

        for (let i = 0; i < current.length; i++) {

            const a = current[i];
            const b = previous[i];

            if (a.x !== b.x || a.y !== b.y || a.owner?.playerId !== b.owner?.playerId) {
                return true;
            }
        }

        return false;
    }

    if (renderContext === undefined) {
        return null;
    }

    return <div className="positional-render">
        <PositionalSvg
            renderContext={renderContext}
            previous={previous}
            override={override}
            {...props}
        />
        {props.overlayText !== undefined && <div className='overlay-message'>
            <Paragraph type='danger' className='has-text-centered'>
                {props.overlayText}
            </Paragraph>
        </div>}
    </div>;
};