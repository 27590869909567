import { ICelestialDetail, IGameSettings, IInstallationTypeSettings, IItemTypeSettings, ISectorTypeSettings, IShipTypeSettings, ISolarSystemDetail, IWorldEffects, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { CelestialFeature } from "../../../../../Helpers/CelestialHelper";

export const Summary_MaximumLines = 4;

export type SummaryTileProps = {
    celestial: ICelestialDetail,
    solarSystem: ISolarSystemDetail,
    itemTypeSettings: IItemTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    sectorTypeSettings: ISectorTypeSettings,
    worldEffects: IWorldEffects,
    gameSettings: IGameSettings,
    setFeature: (feature: CelestialFeature | undefined) => any,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
};