import { IMessage } from "../ApplicationState/ApiClient";

export class MessageHelper {

    public static isGbcMessage(message: IMessage) {
        return message.isAdmin && this.isGbc(message.subject);
    }

    public static isGbc(text: string) {
        return text.startsWith("GBC");
    }
}