import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import useMeasure from 'react-use-measure';
import { ISolarSystemForObserver, ISolarSystemMinimal } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext } from '../../../ApplicationState/ContextRoot';
import { SendShipsButtons } from '../../../Components/FusionShift/Buttons/SendShipsButtons';
import { CanvasCamera } from './CanvasCamera';
import { Controls } from './Controls';
import { GalaxyMapData, galaxyMapParsecPixels, GalaxyMapPerspective, GalaxyMapSelection, RenderBag, TargetsRender } from './Entities';
import { defaultGalaxyMapPreferences, GalaxyMapPreferences } from './GalaxyMapPreferences';
import { PreMapControls } from './PreMapControls';
import { FleetRenderer } from './Renderers/FleetRenderer';
import { OverlayRenderer } from './Renderers/OverlayRenderer';
import { SolarSystemRenderer } from './Renderers/SolarSystemRenderer';
import { TargetsRenderer } from './Renderers/TargetsRenderer';
import { SolarSystemView } from './SolarSystemView';
import { TargetMode } from './TargetMode/TargetMode';
import { useAnimationFrame } from './useAnimationFrame';

type Props = {
  data: GalaxyMapData,
  initialSolarSystem: ISolarSystemMinimal | undefined
}

type RendererState = {
  solarSystems?: boolean,
  fleets?: boolean,
  overlay?: boolean
}

const reducer = (state: RendererState, modify: RendererState | boolean): RendererState => {

  if (typeof modify === "boolean") {
    return {
      solarSystems: modify,
      fleets: modify,
      overlay: modify
    }
  }

  return {
    solarSystems: state.solarSystems || modify.solarSystems,
    fleets: state.fleets || modify.fleets,
    overlay: state.overlay || modify.overlay
  };
}

function defaultPosition(solarSystem: { x: number, y: number } | undefined) {
  if (solarSystem === undefined) {
    return { x: 0, y: 0 };
  }

  return { x: solarSystem.x * -galaxyMapParsecPixels, y: solarSystem.y * -galaxyMapParsecPixels };
}

export const MapRender = observer((props: Props) => {

  const apiState = React.useContext(ApiStateContext);
  const appState = React.useContext(AppStateContext);

  const [ref, bounds] = useMeasure();
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const camera = React.useMemo(() => {
    return new CanvasCamera(bounds, defaultPosition(props.initialSolarSystem));
  }, []);

  const [preferences, setPreferences] = React.useState<GalaxyMapPreferences>(appState.getUserPrefsObjectOrDefault<GalaxyMapPreferences>("GalaxyMapPreferences", defaultGalaxyMapPreferences));

  function modifyPreferences(action: (prefs: GalaxyMapPreferences) => GalaxyMapPreferences) {
    const prefs = action(preferences);
    changePreferences(prefs);
  }

  function changePreferences(prefs: GalaxyMapPreferences) {
    appState.setUserPrefsObject<GalaxyMapPreferences>("GalaxyMapPreferences", prefs);
    setPreferences(prefs);
  }

  const solarSystemCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const fleetCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const overlayCanvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const targetsCanvasRef = React.useRef<HTMLCanvasElement | null>(null);

  const [perspective, setPerspective] = React.useState(new GalaxyMapPerspective());

  const [solarSystemRenderer, setSolarSystemRenderer] = React.useState<SolarSystemRenderer | undefined>();
  const [fleetRenderer, setFleetRenderer] = React.useState<FleetRenderer | undefined>();
  const [overlayRenderer, setOverlayRenderer] = React.useState<OverlayRenderer | undefined>();
  const [targetsRenderer, setTargetsRenderer] = React.useState<TargetsRenderer | undefined>();

  const [targetRenderInfo, setTargetRenderInfo] = React.useState<TargetsRender | undefined>(undefined);

  const [selection, setSelection] = React.useState<GalaxyMapSelection>({
    solarSystemId: props.initialSolarSystem?.solarSystemId
  });

  const [solarSystem, setSolarSystem] = React.useState<ISolarSystemForObserver | undefined>();
  const [loadingSolarSystemId, setLoadingSolarSystemId] = React.useState<number | undefined>(undefined);

  const [isTargetMode, setIsTargetMode] = React.useState(false);

  React.useEffect(() => {

    if (selection.solarSystemId === undefined) {
      setSolarSystem(undefined);
    }

    if (selection.solarSystemId !== undefined &&
      selection.solarSystemId !== solarSystem?.solarSystemId &&
      selection.solarSystemId !== loadingSolarSystemId) {

      setLoadingSolarSystemId(selection.solarSystemId);
      apiState.SolarSystemClient.getDetail(selection.solarSystemId).then(setSolarSystem).finally(() => {
        setLoadingSolarSystemId(undefined);
      })
    }
  }, [selection]);


  React.useEffect(() => {
    if (solarSystemCanvasRef.current) {
      const ctx = solarSystemCanvasRef.current.getContext("2d");

      if (ctx !== undefined && ctx !== null) {

        setSolarSystemRenderer(new SolarSystemRenderer(ctx));
        dispatchRendererState({ solarSystems: true });
      }
    }
    if (fleetCanvasRef.current) {
      const ctx = fleetCanvasRef.current.getContext("2d");

      if (ctx !== undefined && ctx !== null) {

        setFleetRenderer(new FleetRenderer(ctx));
        dispatchRendererState({ fleets: true });
      }
    }
    if (overlayCanvasRef.current) {
      const ctx = overlayCanvasRef.current.getContext("2d");

      if (ctx !== undefined && ctx !== null) {

        setOverlayRenderer(new OverlayRenderer(ctx));
        dispatchRendererState({ overlay: true });
      }
    }

    if (targetsCanvasRef.current) {
      const ctx = targetsCanvasRef.current.getContext("2d");

      if (ctx !== undefined && ctx !== null) {

        setTargetsRenderer(new TargetsRenderer(ctx));
        dispatchRendererState({ overlay: true });
      }
    }
  }, []);

  const wheelHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    camera.adjustZoom(-e.deltaY * camera.SCROLL_SENSITIVITY);
    dispatchRendererState(true);

    return false;
  };

  React.useEffect(() => {

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('wheel', wheelHandler, { passive: false });
    }

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('wheel', wheelHandler, { passive: false } as any);
      }
    }

  }, [wrapperRef.current, camera]);

  const [rendererState, dispatchRendererState] = React.useReducer(reducer, {});

  const renderFrame = () => {

    const stateThisFrame = rendererState;

    dispatchRendererState(false);

    const bag: RenderBag = {
      camera,
      data: props.data,
      perspective,
      bounds,
      selection,
      preferences,
      targets: isTargetMode ? targetRenderInfo : undefined
    };

    if (stateThisFrame.solarSystems && solarSystemRenderer) {
      camera.draw(solarSystemRenderer.ctx, bounds, () => {
        solarSystemRenderer?.render(bag);
      });
    }
    if (stateThisFrame.fleets && fleetRenderer) {
      camera.draw(fleetRenderer.ctx, bounds, () => {
        fleetRenderer?.render(bag);
      });
    }
    if (stateThisFrame.overlay) {
      if (overlayRenderer) {
        camera.draw(overlayRenderer.ctx, bounds, () => {
          overlayRenderer?.render(bag);
        });

        if (targetsRenderer) {
          camera.draw(targetsRenderer.ctx, bounds, () => {
            targetsRenderer?.render(bag);
          });
        }
      }
    }
  };

  useAnimationFrame(renderFrame, (rendererState.solarSystems || rendererState.overlay) ?? false);

  React.useEffect(() => {

    dispatchRendererState(true);

  }, [bounds]);

  React.useEffect(() => {

    dispatchRendererState(true);

  }, [perspective]);

  React.useEffect(() => {

    dispatchRendererState(true);

  }, [preferences]);

  React.useEffect(() => {

    dispatchRendererState({
      overlay: true
    });

  }, [targetRenderInfo]);

  function goHome() {
    camera.goTo(bounds, defaultPosition(props.initialSolarSystem));
    dispatchRendererState(true);
  }

  function panBy(x: number, y: number) {
    camera.panBy({ x, y });
    dispatchRendererState(true);
  }

  function zoomBy(val: number) {
    camera.adjustZoom(val);
    dispatchRendererState(true);
  }

  function toggleLength() {
    modifyPreferences(p => {
      return {
        ...p,
        isFullHeight: !p.isFullHeight
      }
    });
  }

  function getCursorPosition(canvas: HTMLCanvasElement | null, event) {

    if (overlayRenderer === undefined || canvas === null) {
      return { x: 0, y: 0 };
    }

    const rect = canvas.getBoundingClientRect()
    const cX = event.clientX - rect.left
    const cY = event.clientY - rect.top

    const inv = overlayRenderer?.transform.inverse();
    const { x, y } = inv.transformPoint(new DOMPoint(cX, cY));

    return { x, y };
  }

  function getCoordinatePosition(x: number, y: number) {

    x += perspective.parsec;
    y += perspective.parsec;

    const coord = {
      x: Math.floor(x / perspective.parsec),
      y: Math.floor(y / perspective.parsec)
    };

    return coord;
  }

  function mouseMove(e) {
    const pos = getCursorPosition(overlayCanvasRef.current, e);
    const coord = getCoordinatePosition(pos.x, pos.y);

    setSelection({
      ...selection,
      hoverCoordinate: coord
    });
  }

  function click(e) {
    const pos = getCursorPosition(overlayCanvasRef.current, e);
    const coord = getCoordinatePosition(pos.x, pos.y);

    const solarSystem = Object.values(props.data.map.entries).find(s => s.x === coord.x && s.y === coord.y);

    if (solarSystem !== undefined) {
      setSelection({
        ...selection,
        solarSystemId: solarSystem.solarSystemId
      });
    }

    dispatchRendererState(true);
  }

  const className = classNames("galaxy-map",
    !preferences.isFullHeight && "is-half-height"
  );

  return <>
    <PreMapControls
      isTargetMode={isTargetMode}
      setTargetMode={setIsTargetMode}
      preferences={preferences}
      modifyPreferences={changePreferences}
    />
    <div className={className} ref={wrapperRef}>
      <Controls
        panBy={panBy}
        goHome={goHome}
        zoomBy={zoomBy}
        toggleLength={toggleLength}
        preferences={preferences}
      />
      {solarSystem !== undefined && <div className="galaxy-solar-system-buttons">
        <SendShipsButtons
          noText
          solarSystem={solarSystem}
        />
      </div>}
      <div className="max-map-container" ref={ref}

        onMouseUp={e => {
          camera.onPointerUp(e);
          dispatchRendererState(true);
        }}
        onMouseLeave={e => {
          camera.onPointerUp(e);
          dispatchRendererState(true);
        }}
        onMouseDown={e => {
          camera.onPointerDown(e);
          dispatchRendererState(true);
        }}
        onMouseMove={e => {
          camera.onPointerMove(e);
          dispatchRendererState({
            overlay: true,
            fleets: true,
            solarSystems: camera.isDragging
          });
        }}
        onTouchStart={e => {
          camera.handleTouch(e, e2 => camera.onPointerDown(e2));
          dispatchRendererState(true);
        }}
        onTouchEnd={e => {
          camera.handleTouch(e, e2 => camera.onPointerUp(e2));
          dispatchRendererState(true);
        }}
        onTouchMove={e => {
          camera.handleTouch(e, e2 => camera.onPointerMove(e2));
          dispatchRendererState(true);
        }}
      >
        <canvas
          ref={solarSystemCanvasRef}
          width={bounds.width}
          height={bounds.height}
        />
        <canvas
          ref={fleetCanvasRef}
          width={bounds.width}
          height={bounds.height}
        />
        <canvas
          ref={targetsCanvasRef}
          width={bounds.width}
          height={bounds.height}
        />
        <canvas
          ref={overlayCanvasRef}
          width={bounds.width}
          height={bounds.height}
          onMouseMove={mouseMove}
          onClick={click}
        />
      </div>
    </div >
    {!isTargetMode && <SolarSystemView solarSystem={solarSystem} isLoading={loadingSolarSystemId !== undefined} />}
    {isTargetMode && <TargetMode data={props.data} setRender={setTargetRenderInfo} />}
  </>;
});