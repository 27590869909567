import * as React from "react";
import { IRegisterInput, RegisterInput, ValidationResult } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { NavButton } from "../../Components/Base/Buttons/NavButton";
import { Panel } from "../../Components/Base/Containers/Panel";
import { Checkbox } from "../../Components/Base/Form/Checkbox";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { ErrorMessageBox } from "../../Components/Base/MessageBox";
import { GameStartBlocker } from "../../Components/FusionShift/GameStartBlocker";
import { IconHelper } from "../../Helpers/IconHelper";
import { home } from "../../Navigation/Routing/AppRouteValues";
import { VersionNumbers } from "../../Navigation/VersionNumbers";
import { LoginPrompt } from "./AuthenticationContent";
import { AuthenticationWrapper } from "./AuthenticationWrapper";

export const RegisterView = () => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [emailAddress, setEmailAddress] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [displayName, setDisplayName] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [password, setPassword] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [validation, setValidation] = React.useState<ValidationResult | undefined>(undefined);
    const [emailConsent, setEmailConsent] = React.useState(true);

    function isValid() {
        return password.isValid && emailAddress.isValid && displayName.isValid;
    }

    function register() {
        if (!isValid()) {
            return;
        }
        const modelData: IRegisterInput = {
            displayName: displayName.validText!,
            password: password.validText!,
            emailAddress: emailAddress.validText!,
            hasGivenEmailConsent: emailConsent
        };
        const model = new RegisterInput(modelData);

        return apiState.AuthenticationClient.register(model).then(result => {
            if (result) {
                setValidation(result.error);
                if (!result.error || result.error.isValid) {
                    appState.setUser(result);
                }
            }
        })
    }

    function validateEmail(value: string) {
        return apiState.AuthenticationClient.validateEmailAddress(value);
    }

    function validateDisplayName(value: string) {
        return apiState.AuthenticationClient.validateDisplayName(value);
    }

    function validatePassword(value: string) {
        return apiState.AuthenticationClient.validatePassword(value);
    }

    const buttonDisabled = !isValid();

    if (appState.IsReady) {
        appState.navigate(home);
    }

    return <AuthenticationWrapper>
        <Panel
            heading="Register"
            isPaddedMobile
            headingContent={<NavButton to={home} text="Home" icon={IconHelper.Categories.Home} />}
        >
            <GameStartBlocker>
                <ValidatingStringInput
                    label="Email Address"
                    icon={IconHelper.Account.Email}
                    valueChanged={setEmailAddress}
                    validation={validateEmail}
                />
                <ValidatingStringInput
                    label="Password"
                    icon={IconHelper.Account.Password}
                    type="password"
                    valueChanged={setPassword}
                    validation={validatePassword}
                />
                <ValidatingStringInput
                    label="Display Name"
                    icon={IconHelper.Account.Player}
                    valueChanged={setDisplayName}
                    validation={validateDisplayName}
                />
                <Checkbox value={emailConsent} valueChanged={setEmailConsent} label={" Keep me up to date with news by email"} />
                <ButtonHolder isPulledRight>
                    <Button type="action" text="Register" icon={IconHelper.General.Confirm} action={() => register()} isDisabled={buttonDisabled} />
                </ButtonHolder>
                <hr />
                <LoginPrompt />
                {!!validation && !validation.isValid && <ErrorMessageBox text={validation.error!} />}
            </GameStartBlocker>
            <VersionNumbers clientOnly />
        </Panel>
    </AuthenticationWrapper>;
};