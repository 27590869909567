import React from "react";
import { FlexDiv } from "../../Components/Base/Containers/FlexDiv";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { TileHolder } from "../../Components/Base/Containers/TileHolder";
import { ExternalLink } from "../../Components/Base/ExternalLink";
import { SvgImage } from "../../Components/FusionShift/Images/SvgImage";
import { SimpleBaseView } from "../BaseView";

type Props = {
  title: string;
  content: React.ReactNode[];
};

type MainProps = Props & {
  thumbnail: React.ReactNode
}

function credit(title: string, content: React.ReactNode[]) {
  return {
    title, content
  };
}

function mainCredit(thumbnail: React.ReactNode, title: string, content: React.ReactNode[]) {
  return {
    thumbnail, title, content
  };
}

const mainCredits = [
  mainCredit(<SvgImage type="credit" filename="andy" size="small" />, "Andy Longhurst", ["Game Design", "Software Engineering", "Project Management"]),
  mainCredit(<SvgImage type="credit" filename="charlie" size="small" />, "Charlie Rawlinson", ["Game Design", "Graphics & Styling"]),
  mainCredit(<SvgImage type="credit" filename="bradley" size="small" />, "Bradley Gunn", ["Software Engineering", "Cloud Infrastructure", "DevOps", <ExternalLink to={"https://bradleygunn.net/"} />])
];

const extraCredits = [
  credit("Software Engineering", ["Matt Trussler", "James Baxter"]),
  credit("Graphics", ["Andrew Hind", "John Strike", "Spring Collective"]),
  credit("Marketing & Research", ["Margo Kane", "William Longhurst"])
];

const MainBlock = (props: MainProps) => {
  return <SubPanel heading={props.title}>
    <SplitLayout
      fitContent
      left={props.thumbnail}
      right={
        <ul>
          {props.content.map((d, i) => (
            <li key={i}>{d}</li>
          ))}
        </ul>
      }
    />
  </SubPanel>;
};

const CreditBlock = (props: Props) => {
  return <SubPanel heading={props.title}>
    <ul>
      {props.content.map((d, i) => (
        <li key={i}>{d}</li>
      ))}
    </ul>
  </SubPanel>;
};

export const DevelopedByView = () => {
  return <SimpleBaseView heading="Credits">
    <FlexDiv isContentHorizontallyCentered>
      {mainCredits.map(c => (
        <MainBlock key={c.title} {...c} />
      ))}
    </FlexDiv>
    <TileHolder>
      {extraCredits.map(c => (
        <CreditBlock key={c.title} {...c} />
      ))}
    </TileHolder>
  </SimpleBaseView>;
};
