import classNames from "classnames";
import * as React from "react";
import { FleetMovementType, IFleet, IMapSolarSystem, IPlayer, ISolarSystem } from "../../../ApplicationState/ApiClient";
import { AppStateContext } from "../../../ApplicationState/ContextRoot";
import { SolarSystemImage } from "../../../Components/FusionShift/Celestials/SolarSystemImage";
import { FleetSummaryIcons } from "../../../Components/FusionShift/Icons/FleetSummaryIcons";
import { RelationHelper } from "../../../Helpers/RelationHelper";
import { TutorialIds } from "../../../Navigation/Tutorial/TutorialIds";
import { mapSize } from "../MapSize";

export type Cell = {
    x: number,
    y: number,
    solarSystem: IMapSolarSystem | undefined,
    fleets: IFleet[] | undefined,
    sizeFor: mapSize,
    celestialTypeName: string | undefined,
    isLoaded: boolean
}

type Props = {
    isSelected: boolean,
    cell: Cell,
    player: IPlayer | undefined,
    currentSolarSystem: ISolarSystem | undefined,
    setSelectedSolarSystem: (solarSystem: IMapSolarSystem | undefined) => any
}

export const MapCellRender = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    function classNameForCell(): string {

        let visibility = "";
        const cellSizeAsNumber = Number(mapSize[props.cell.sizeFor]);

        for (let item in mapSize) {
            const currentSizeAsNumber = Number(item);
            let biggest = mapSize.none;
            if (!isNaN(currentSizeAsNumber)) {
                const currentSize = mapSize[item as keyof mapSize];
                if (currentSize === mapSize.none || currentSizeAsNumber === 0) {
                    continue;
                }
                if (currentSizeAsNumber <= cellSizeAsNumber && biggest < currentSizeAsNumber) {
                    visibility = `is-not-shown-on-${currentSize}`;
                    biggest = currentSize;
                }
            }
        }

        if (props.isSelected) {
            return `selected ${visibility} `;
        }
        if (appState.SolarSystemId && props.cell.solarSystem && props.cell.solarSystem.solarSystemId === appState.SolarSystemId) {
            return `current ${visibility} `;
        }

        if (props.cell.celestialTypeName !== undefined &&
            props.cell.solarSystem &&
            props.cell.solarSystem.celestialTypeNames &&
            props.cell.solarSystem.celestialTypeNames.includes(props.cell.celestialTypeName)) {
            return `highlighted ${visibility} `;
        }

        return visibility;
    }

    const className = classNames(
        classNameForCell(),
        !props.cell.isLoaded && "is-loading"
    );

    if (!props.cell.solarSystem) {
        return <td className={className} />;
    }



    const id = props.currentSolarSystem?.solarSystemId === props.cell.solarSystem.solarSystemId ? TutorialIds.MapOwnSystem : undefined;

    return <td id={id} className={className} data-x={props.cell.x} data-y={props.cell.y}>
        <a
            className={RelationHelper.relationClassName(props.cell.solarSystem.owner, props.player)}
            onClick={() => {
                props.setSelectedSolarSystem(props.cell.solarSystem);
            }}>
            <span>
                {`${props.cell.solarSystem.x} / ${props.cell.solarSystem.y}`}
            </span>
            <SolarSystemImage solarSystem={props.cell.solarSystem} isReal />
            <span className="movement-icons">
                <FleetSummaryIcons fleets={props.cell.fleets} except={FleetMovementType.ReturnHome} />
            </span>
        </a >
    </td >;
};
