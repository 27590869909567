import * as React from 'react';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const SolarSystemsExploredRequired = (props: Props) => {

    if (!props.mission.MissionType.solarSystemsExploredRequired) {
        return null;
    }

    const required = 1;
    const has = 0;

    return <ObjectiveRow has={has} requires={required} {...props} >
        Explore  {ValueFormatter.formatLocaleNumber(props.mission.MissionType.solarSystemsExploredRequired)} solar systems
    </ObjectiveRow>;
};