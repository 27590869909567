import { Step } from 'react-joyride';
import { AppState } from '../../ApplicationState/States/AppState';
import { map_tactical, map_tactical_x_y } from '../Routing/Map';
import { selected_system, system_subView } from '../Routing/SolarSystem';
import { asId, TutorialIds } from "./TutorialIds";

export type FusionShiftStep = Step & {
    targetId: string,
    targetPage?: string | undefined,
    navigate?: (appState: AppState) => any
};

function step(targetId: string, content: string, targetPage?: string | undefined, navigate?: (appState: AppState) => any) {
    return {
        title: "Tutorial",
        target: asId(targetId),
        targetId: targetId,
        disableBeacon: true,
        targetPage,
        content,
        navigate
    };
}

export const tutorialSteps: FusionShiftStep[] = [
    step(TutorialIds.SolarSystemLink, "This link will take you to your solar system where you can issue commands and manage your empire."),
    step(TutorialIds.SolarSystem_Celestials, "This tab shows you information about the celestials in your system.", selected_system),
    step(TutorialIds.SolarSystemRender, "Here are the planets in your system. You can click on them for more information and to construct installations.", system_subView("celestials")),
    step(TutorialIds.ResourcesBar, "These are the resources in your system. You can spend them to build things such as ships or installations.", selected_system),
    step(TutorialIds.SolarSystem_Ships, "This tab will show you about the ships in your system.", selected_system),
    step(TutorialIds.ShipsControl, "Here are the ships stationed in this solar system. Fleets moving in and out of the system will be shown here too.", system_subView("ships")),
    step(TutorialIds.MapLink, "This link takes you to the map view where you can see the local area around your system."),
    step(TutorialIds.MapOwnSystem, "Here is your solar system!", map_tactical, appState => {
        if (appState.SolarSystemState.SolarSystem !== undefined) {
            appState.navigate(map_tactical_x_y(appState.SolarSystemState.SolarSystem.x, appState.SolarSystemState.SolarSystem.y));
        }
    }),
    step(TutorialIds.TutorialMission, "It is recommended that you follow the tutorial missions to learn how to play. Good luck!")
];