import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button } from './Button';
import { ButtonHolder } from './ButtonHolder';

type Props = {
    entityName: string,
    markAllAsRead: () => any,
    deleteAll: () => any
}

export const InboxButtons = (props: Props) => {

    return <ButtonHolder>
        <Button type="warning" text="Mark all as read" icon={IconHelper.General.Confirm} action={props.markAllAsRead} confirm={`Are you sure you want to mark all ${props.entityName} as read?`} hideTextOnMobile />
        <Button type="danger" text="Delete all" icon={IconHelper.General.Delete} action={props.deleteAll} confirm={`Are you sure you want to delete all ${props.entityName}?`} hideTextOnMobile />
    </ButtonHolder>
};