import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Resources } from '../../../Views/Empire/Summary/Items/Resources';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

export const AllSystemsResources = (props: Props) => {

    return <SubPanel heading={{ text: "Resources", icon: IconHelper.Items.Resource }} isUnpadded>
        <Resources {...props} />
    </SubPanel>;
};