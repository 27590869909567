import * as React from "react";
import { ReactNode } from "react";
import { BulmaTypes } from "./BulmaTypes";
import { ExpandButton } from "./Buttons/ExpandButton";
import { isA } from "./Util";

type Props = {
    type: BulmaTypes,
    className?: string | undefined,
    title: ReactNode,
    children: ReactNode | undefined,
    id?: string | undefined
}

function isBubbleClickDisabled(event: any) {

    let current = event.target;

    while (current !== undefined && current !== null) {

        const x = current.getAttribute('data-disable-bubble-click');

        if (x !== undefined && (x === "true" || x === true)) {
            return true;
        }

        current = current.parentElement;
    }

    return false;
}

export const FancyMessageBox = (props: Props) => {

    const [isExpanded, setIsExpanded] = React.useState(false);

    return <article id={props.id} className={`message is-fancy is-${props.type}`}>
        <div className={`message-header is-clickable ${props.className ? props.className : ""}`} onClick={e => {
            setIsExpanded(!isExpanded)
        }}>
            {props.title}
            <div className="controls">
                <div className="right">
                    <div className="control is-pulled-right">
                        <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                    </div>
                </div>
            </div>
        </div>
        {isExpanded &&
            <div className="message-body" onClick={e => { if (isA(e.target) && !isBubbleClickDisabled(e)) { setIsExpanded(false); } }}>
                {props.children}
            </div>
        }
    </article>
};