import { FleetCollection, FleetConfig, FleetOrders, IRepeatEditInput, IRepeatFleetConfig, ISendShipsToFleetInput, ISendShipsToSolarSystemInput, RepeatEditInput, RepeatFleetConfig, RepeatFromSendInput, SendShipsToFleetInput, SendShipsToSolarSystemInput } from "../../ApplicationState/ApiClient";
import { ApiState } from "../../ApplicationState/States/ApiState";
import { AppState } from "../../ApplicationState/States/AppState";
import { SolarSystemState } from "../../ApplicationState/States/SolarSystemState";
import { SendShipsHelper } from "../../Helpers/SendShipsHelper";
import { SendShipsBag } from "./SendShipsBag";
import { SendShipsResult } from "./SendShipsForm";

export class Send {

    public static send(
        sendShipsBag: SendShipsBag | undefined,
        appState: AppState,
        apiState: ApiState,
        solarSystemState: SolarSystemState,
        reloadCallback: (result: SendShipsResult) => any
    ) {
        if (sendShipsBag && sendShipsBag.validation.valid && sendShipsBag.MovementType !== undefined) {

            // Apply spread operators in this order so the edited config can overwrite the repeated properties in repeatConfiguration
            const config: IRepeatFleetConfig = {
                ...sendShipsBag.RepeatConfiguration,
                ...sendShipsBag.Config
            };

            const duration = SendShipsHelper.durationSteps(sendShipsBag);

            if (sendShipsBag.IsEditingRepeatFleet && sendShipsBag.TargetSolarSystem) {
                const edit: IRepeatEditInput = {
                    movementType: sendShipsBag.MovementType,
                    targetSolarSystemId: sendShipsBag.TargetSolarSystem.solarSystemId,
                    targetCelestialId: sendShipsBag.TargetCelestialId,
                    ships: sendShipsBag.shipQuantities,

                    itemsToSend: sendShipsBag.allowedConfigurations.itemsToSend || sendShipsBag.allowedConfigurations.commoditiesToSend ? sendShipsBag.itemsToSend : undefined,
                    itemsToCollect: sendShipsBag.allowedConfigurations.itemsToCollect ? sendShipsBag.itemsToCollect : undefined,

                    delayHours: sendShipsBag.RepeatConfiguration.delayHours ?? 1,
                    numberOfTimes: sendShipsBag.RepeatConfiguration.numberOfTimes,

                    contractId: sendShipsBag.Contract?.contractId,

                    orders: new FleetOrders(sendShipsBag.Orders),
                    config: new RepeatFleetConfig(config)
                };

                return apiState.FleetClient.editRepeatFleet(sendShipsBag.init.sourceSolarSystem.solarSystemId, sendShipsBag.editingRepeatFleetId!, new RepeatEditInput(edit)).then(solarSystem => {
                    if (solarSystem) {
                        if (sendShipsBag.TargetSolarSystem) {
                            appState.addRecentSolarSystem(sendShipsBag.TargetSolarSystem);
                        }
                        solarSystemState.loadSolarSystem(solarSystem);
                        reloadCallback({
                            wasSuccess: true,
                            targetSolarSystem: sendShipsBag.TargetSolarSystem,
                            targetCoordinate: {
                                x: sendShipsBag.TargetSolarSystem!.x,
                                y: sendShipsBag.TargetSolarSystem!.y,
                            },
                            movementType: edit.movementType,
                            duration,
                            showRepeat: true,
                            wasEditRepeat: true,
                            sourceSolarSystemId: sendShipsBag.init.sourceSolarSystem.solarSystemId,
                            sendShipsBag
                        });
                    }
                });
            }


            if (sendShipsBag.TargetSolarSystem) {

                const itemsToCollect = sendShipsBag.allowedConfigurations.itemsToCollect ? sendShipsBag.itemsToCollect : undefined;
                const agentsToCollect = sendShipsBag.agentIdsToCollect.length > 0 ? sendShipsBag.agentIdsToCollect : undefined;

                const collection = itemsToCollect !== undefined || agentsToCollect !== undefined ? new FleetCollection({
                    agents: agentsToCollect,
                    items: itemsToCollect
                }) : undefined;

                const repeat = sendShipsBag.RepeatConfiguration.delayHours !== undefined ? new RepeatFromSendInput(sendShipsBag.RepeatConfiguration) : undefined;

                const sendShips: ISendShipsToSolarSystemInput = {
                    movementType: sendShipsBag.MovementType,
                    targetSolarSystemId: sendShipsBag.TargetSolarSystem.solarSystemId,
                    targetCelestialId: sendShipsBag.TargetCelestialId,
                    ships: sendShipsBag.shipQuantities,

                    itemsToSend: sendShipsBag.allowedConfigurations.itemsToSend || sendShipsBag.allowedConfigurations.commoditiesToSend ? sendShipsBag.itemsToSend : undefined,

                    repeat: repeat,

                    contractId: sendShipsBag.Contract?.contractId,

                    agentIdsToSend: sendShipsBag.allowedConfigurations.agentsToSend ? sendShipsBag.agentIdsToSend : [],

                    collection: collection,
                    orders: new FleetOrders(sendShipsBag.Orders),
                    config: new FleetConfig(config)
                };

                return apiState.FleetClient.sendShipsToSolarSystem(sendShipsBag.init.sourceSolarSystem.solarSystemId, new SendShipsToSolarSystemInput(sendShips)).then(response => {
                    if (response.wasSuccess) {
                        if (sendShipsBag.TargetSolarSystem) {
                            appState.addRecentSolarSystem(sendShipsBag.TargetSolarSystem);
                        }
                        if (response.result !== undefined)
                            solarSystemState.loadSolarSystem(response.result);
                    }

                    reloadCallback({
                        ...response,
                        fleets: response.result?.fleets?.filter(x => x.isNew),
                        targetSolarSystem: sendShipsBag.TargetSolarSystem,
                        targetCoordinate: {
                            x: sendShipsBag.TargetSolarSystem!.x,
                            y: sendShipsBag.TargetSolarSystem!.y,
                        },
                        movementType: sendShips.movementType,
                        duration,
                        showRepeat: sendShips.repeat?.delayHours !== undefined && sendShips.repeat?.delayHours !== null,
                        sourceSolarSystemId: sendShipsBag.init.sourceSolarSystem.solarSystemId,
                        sendShipsBag
                    });
                });
            }

            if (sendShipsBag.TargetFleet) {

                const sendShips: ISendShipsToFleetInput = {
                    targetFleetId: sendShipsBag.TargetFleet.fleetId,
                    ships: sendShipsBag.shipQuantities,
                    orders: new FleetOrders(sendShipsBag.Orders),
                    config: new FleetConfig(config),
                    fleetMovementType: sendShipsBag.MovementType
                };

                return apiState.FleetClient.sendShipsToFleet(sendShipsBag.init.sourceSolarSystem.solarSystemId, new SendShipsToFleetInput(sendShips)).then(response => {
                    if (response.wasSuccess) {
                        if (sendShipsBag.TargetSolarSystem) {
                            appState.addRecentSolarSystem(sendShipsBag.TargetSolarSystem);
                        }
                        if (response.result !== undefined) {
                            solarSystemState.loadSolarSystem(response.result);
                        }
                    }
                    reloadCallback({
                        ...response,
                        fleets: response.result?.fleets?.filter(x => x.isNew),
                        targetSolarSystem: undefined,
                        targetCoordinate: {
                            x: sendShipsBag.init.sourceSolarSystem.x,
                            y: sendShipsBag.init.sourceSolarSystem.y,
                        },
                        movementType: sendShipsBag.MovementType!,
                        duration,
                        showRepeat: false,
                        sourceSolarSystemId: sendShipsBag.init.sourceSolarSystem.solarSystemId,
                        sendShipsBag
                    });
                });
            }
        }
    }
}