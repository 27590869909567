import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IGameSettings, IInstallationTypeSettings, IItemTypeSettings, InstallationBuildQueueType, IWorldEffects, RequestResponseOfSolarSystemDetail, SolarSystemClaimType } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { Table } from '../../../Components/Base/Containers/Table';
import { Icon } from '../../../Components/Base/Icon';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { DateIcon } from '../../../Components/FusionShift/Icons/DateIcon';
import { HoursIcon } from '../../../Components/FusionShift/Icons/HoursIcon';
import { CostControl } from '../../../Components/FusionShift/Items/CostControl';
import { InstallationTypeLink } from '../../../Components/FusionShift/Links/InstallationTypeLink';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { RequestResponseMessage } from '../../../Components/FusionShift/Messages/RequestResponseMessage';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { BuildHelper } from '../../../Helpers/BuildHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { SolarSystemHelper } from '../../../Helpers/SolarSystemHelper';
import { TimeHelper } from '../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { EmpireViewProps } from '../EmpireViewProps';

type RowProps = {
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    gameSettings: IGameSettings,
    worldEffects: IWorldEffects,
    solarSystem: SolarSystemWrapper,
    total: number,
    reloadCallback: (response: RequestResponseOfSolarSystemDetail) => any
}

const Row = (props: RowProps) => {

    const apiState = React.useContext(ApiStateContext);
    const installation = props.solarSystem.installations.find(x => x.installationType.solarSystemClaimType !== undefined && x.installationType.solarSystemClaimType !== null);

    function upgrade() {
        return installation && apiState.InstallationClient.upgrade(
            props.solarSystem.solarSystem.solarSystemId,
            installation.celestial.celestialId,
            installation.installation.installationId)
            .then(props.reloadCallback);
    }
    if (installation === undefined) {
        return null;
    }

    const buildQueueItem = props.solarSystem.solarSystem.installationBuildQueue !== undefined ? props.solarSystem.solarSystem.installationBuildQueue.find(x => x.installationId === installation.installation.installationId) : undefined;
    const isUpgrading = !!buildQueueItem && buildQueueItem.type === InstallationBuildQueueType.Build;
    const upgradeDetails = BuildHelper.upgradeDetails(installation.installation, installation.celestial, props.solarSystem.solarSystem, props.installationTypeSettings, props.itemTypeSettings, props.gameSettings, props.worldEffects, isUpgrading ? installation.installation.level + 1 : installation.installation.level);
    const hours = upgradeDetails.nextCost && TimeHelper.createTimeSpanFromTimeSpanString(upgradeDetails.nextCost.duration).hours;

    return <>
        <tr className="is-hidden-tablet">
            <td colSpan={5}>
                <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
            </td>
        </tr>
        <tr>
            <td>
                <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons isHiddenMobile /><br className="is-hidden-mobile" />
                <InstallationTypeLink installationType={installation.installationType} className="is-hidden-mobile" />
                <span className={installation.installation.level < props.total ? "has-text-danger" : ""}>
                    {` [${installation.installation.level}]`}
                </span>
            </td>
            <td>
                {hours && <HoursIcon quantity={hours} alwaysCompact />}
            </td>
            <td className="is-hidden-mobile">
                {upgradeDetails.nextCost && <CostControl cost={upgradeDetails.nextCost} hideDuration availableItems={props.solarSystem.solarSystem.items} />}
            </td>
            <td>
                {!!buildQueueItem && <>
                    {buildQueueItem.type === InstallationBuildQueueType.Build && <Icon icon={IconHelper.Installations.Upgrade} />}
                    {buildQueueItem.type === InstallationBuildQueueType.Deconstruct && <Icon className='has-text-danger' icon={IconHelper.Installations.Deconstruct} />}
                    {buildQueueItem.type === InstallationBuildQueueType.ChangeCapital && <Icon icon={IconHelper.SolarSystems.Capital} />}
                    <DateIcon date={buildQueueItem.completedDate} />
                </>}
            </td>
            <td>
                <Button type="action" icon={IconHelper.Installations.Upgrade} text="Upgrade" action={() => upgrade()} isDisabled={!upgradeDetails.canUpgrade} isPulledRight />
            </td>
        </tr>
    </>;
};

export const ControlView = observer((props: EmpireViewProps) => {

    const [response, setResponse] = React.useState<RequestResponseOfSolarSystemDetail | undefined>(undefined);
    const worldState = React.useContext(WorldStateContext);

    if (!worldState.GameSettings) {
        return null;
    }

    function reloadCallback(response: RequestResponseOfSolarSystemDetail) {
        setResponse(response);
        if (response.wasSuccess && response.result !== undefined) {
            props.reloadCallback(response.result);
        }
    }

    const capital = props.solarSystems.find(x => x.solarSystem.claimType === SolarSystemClaimType.Capital);
    const capitalInstallation = capital?.installations.find(x => x.installationType.solarSystemClaimType === SolarSystemClaimType.Capital);

    const colonies = props.solarSystems.filter(x => x.solarSystem.claimType === SolarSystemClaimType.Colony);
    const outposts = props.solarSystems.filter(x => x.solarSystem.claimType === SolarSystemClaimType.Outpost);

    const maxOutposts = capitalInstallation !== undefined ? SolarSystemHelper.maximumOutposts(capitalInstallation.installation.level, worldState.GameSettings) : 0;

    const total = colonies.length + 1;

    return <>
        <RequestResponseMessage response={response} />
        {capital !== undefined &&
            <Paragraph>
                Used {colonies.length} {ValueFormatter.pluralize("colony", colonies.length)} out of a possible {(capitalInstallation?.installation?.level ?? 0) - 1}.
                {(maxOutposts > 0 || outposts.length > 0) && <span>
                    &nbsp;Used {outposts.length} {ValueFormatter.pluralize("outpost", outposts.length)} out of a possible {maxOutposts}.
                </span>}
            </Paragraph>
        }
        <Table isFullWidth isMobileMultirow>
            {capital !== undefined && <Row
                installationTypeSettings={worldState.InstallationTypeSettings!}
                itemTypeSettings={worldState.ItemTypeSettings!}
                gameSettings={worldState.GameSettings!}
                worldEffects={worldState.Effects!}
                solarSystem={capital}
                total={total}
                reloadCallback={reloadCallback}
            />}
            {colonies.map(x => <Row
                key={x.solarSystem.solarSystemId}
                installationTypeSettings={worldState.InstallationTypeSettings!}
                itemTypeSettings={worldState.ItemTypeSettings!}
                gameSettings={worldState.GameSettings!}
                worldEffects={worldState.Effects!}
                solarSystem={x}
                total={total}
                reloadCallback={reloadCallback}
            />)}
            {outposts.map(x => <Row
                key={x.solarSystem.solarSystemId}
                installationTypeSettings={worldState.InstallationTypeSettings!}
                itemTypeSettings={worldState.ItemTypeSettings!}
                gameSettings={worldState.GameSettings!}
                worldEffects={worldState.Effects!}
                solarSystem={x}
                total={total}
                reloadCallback={reloadCallback}
            />)}
        </Table>
    </>;
});