import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BribeForPolicyTerms, ITerms, Policy, ValidationResult } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ThumbnailSlate } from '../../../Components/Base/Containers/Slate';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { ValidationHelper } from '../../../Components/Base/Form/ValidationHelper';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { QuoteParagraph } from '../../../Components/Base/Text/QuoteParagraph';
import { PolicyTypeDescription } from '../../../Components/FusionShift/Descriptions/PolicyTypeDescription';
import { PolicyCategoryIcon } from '../../../Components/FusionShift/Icons/PolicyCategoryIcon';
import { PolicyLink } from '../../../Components/FusionShift/Links/PolicyLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { Configuration } from '../CreateContractView';

type Props = {
    terms: BribeForPolicyTerms,
    configuration: Configuration,
    setTermsAndConfigure: (terms: ITerms, configuration: Configuration) => any,
    setValidation: (validation: ValidationResult | undefined) => any,
}

export const BribeForPolicyForm = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [payment, setPayment] = React.useState(0);
    const [policyId, setPolicyId] = React.useState<number | undefined>(undefined);
    const [vote, setVote] = React.useState<boolean | null>(null);

    const [policy, setPolicy] = React.useState<Policy | undefined>(undefined);

    React.useEffect(() => {
        apiState.PoliticsClient.getPolicyOpenForVoting().then(policy => {
            setPolicy(policy);
        });
    }, []);

    React.useEffect(() => {
        if (policy) {
            setPolicyId(policy.policyId);
        }
    }, [policy]);

    React.useEffect(() => {
        props.setValidation(validate());

        const terms = createTerms();

        props.setTermsAndConfigure(terms, {
            ...props.configuration
        });
    }, [policyId, payment]);

    function createTerms() {
        return BribeForPolicyTerms.fromJS({
            policyId,
            vote,
            payment,
            type: "BribeForPolicyTerms"
        });
    }

    function validate() {
        if (policyId === undefined) {
            return ValidationHelper.invalid("Pick a valid policy");
        }
    }

    if (!worldState.PolicyTypeSettings) {
        return <LoadingSpinner />;
    }

    if (policy === undefined || policy === null) {
        return <Paragraph>No open policy vote at this time</Paragraph>;
    }

    return <>
        <FieldHolder label="Payment">
            <NumberInput value={payment} valueChanged={setPayment} />
        </FieldHolder>
        <ThumbnailSlate
            title={<PolicyLink policy={policy} policyTypeSettings={worldState.PolicyTypeSettings} />}
            thumbnail={<PolicyCategoryIcon isGiant policyCategory={worldState.PolicyTypeSettings.data[policy.policyTypeName].category} />}
            footerContent={
                <ButtonHolder isPulledRight>
                    <Button type="action" icon={IconHelper.Politics.Vote} action={() => setVote(true)} text="Yea" isDisabled={vote === true} />
                    <Button type="danger" icon={IconHelper.Politics.Vote} action={() => setVote(false)} text="Nay" isDisabled={vote === false} />
                    <Button type="warning" icon={IconHelper.Politics.Vote} action={() => setVote(null)} text="Abstain" isDisabled={vote === null} />
                </ButtonHolder>
            }
        >
            <QuoteParagraph>
                {policy.description}
            </QuoteParagraph>
            <PolicyTypeDescription policyType={worldState.PolicyTypeSettings.data[policy.policyTypeName]} />
            <Paragraph>Voting ends {ValueFormatter.formatDateFromNowWithMoment(policy.votingEndDate)}</Paragraph>
        </ThumbnailSlate>
    </>;
});