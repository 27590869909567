import { observer } from "mobx-react-lite";
import React from "react";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CommodityUsage } from "../../../Helpers/CommodityHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Table } from "../../Base/Containers/Table";
import { SectorTypeLink } from "../Links/SectorTypeLink";

type Props = {
    commodities: { [key: string]: number } | undefined,
    production?: { [key: string]: number } | undefined
}

type Commodity = {
    commodity: CommodityUsage,
    quantity: number | undefined,
    production: number | undefined
}

type CellProps = {
    possibles: Commodity[],
    tier: number
}

export const Cell = (props: CellProps) => {

    const commodity = props.possibles.find(x => x.commodity.tier === props.tier);

    const quantity = commodity && commodity.quantity;
    const production = commodity && commodity.production;

    return <td>
        {quantity !== undefined && ValueFormatter.formatLocaleNumber(quantity)}
        {production !== undefined && production !== 0 && <span className={production < 0 ? "per-hour has-text-danger" : "per-hour "}>
            ({production > 0 ? "+" : "-"}{ValueFormatter.formatLocaleNumber(production)})
        </span>}
    </td>
}

export const CommoditySummaryTable = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CommodityHelper) {
        return null;
    }

    const commodities = worldState.CommodityHelper.allCommodities().map(c => {
        return {
            commodity: c,
            production: props.production && c.itemType.typeName in props.production ? props.production[c.itemType.typeName] : 0,
            quantity: props.commodities && c.itemType.typeName in props.commodities ? props.commodities[c.itemType.typeName] : 0
        }
    }).filter(c => c.quantity > 0 || c.production > 0);

    if (commodities.length === 0) {
        return null;
    }

    const sectors: { [key: string]: Commodity[] } = {};

    commodities.reduce((p, c) => {

        if (!(c.commodity.sectorType.typeName in p)) {
            p[c.commodity.sectorType.typeName] = [c];
        } else {
            p[c.commodity.sectorType.typeName].push(c);
        }
        return p;
    }, sectors);

    return <Table isHoverable isFullWidth heading={
        <>
            <td>Sector</td>
            <td>I</td>
            <td>II</td>
            <td>III</td>
            <td>IV</td>
            <td>V</td>
        </>}>
        {Object.keys(sectors).map(c => {

            const sector = sectors[c];
            const sectorType = sector[0].commodity.sectorType;

            return <tr key={sectorType.typeName}>
                <td>
                    <SectorTypeLink sectorType={sectorType} />
                </td>
                <Cell possibles={sector} tier={1} />
                <Cell possibles={sector} tier={2} />
                <Cell possibles={sector} tier={3} />
                <Cell possibles={sector} tier={4} />
                <Cell possibles={sector} tier={5} />
            </tr>
        })}
    </Table>;
});