import * as React from 'react';
import { FleetConfig, IFleet, IFleetConfig, SolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from '../../../Components/Base/Buttons/Button';
import { FlexDiv } from '../../../Components/Base/Containers/FlexDiv';
import { Checkbox } from '../../../Components/Base/Form/Checkbox';
import { StringInput } from '../../../Components/Base/Form/Input';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    fleet: IFleet,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const FleetConfigControl = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const [config, setConfig] = React.useState<IFleetConfig | undefined>(props.fleet.config);
    const [isChanged, setIsChanged] = React.useState(false);

    function save() {
        if (config) {
            apiState.FleetClient.editConfig(props.fleet.fleetId, new FleetConfig(config)).then(s => {
                if (s.wasSuccess && s.result) {
                    props.reloadCallback(s.result);
                }
                setIsChanged(false);
            })
        }
    }

    function change(newConfig: IFleetConfig) {
        setConfig(newConfig);
        setIsChanged(true);
    }

    if (config === undefined) {
        return null;
    }

    return <FlexDiv isContentVerticallyCentered>
        <Checkbox
            label='Ground on arrival'
            value={config.groundOnArrival}
            valueChanged={v => change({ ...config, groundOnArrival: v })}
        />
        <Checkbox
            label='Notify on arrival'
            value={config.notifyOnArrival}
            valueChanged={v => change({ ...config, notifyOnArrival: v })}
        />
        {config.notifyOnArrival &&
            <StringInput
                value={config.notifyOnArrivalMessage ?? ""}
                valueChanged={v => change({ ...config, notifyOnArrivalMessage: v })}
            />
        }
        {isChanged && <span className="is-flex-end">
            <Button
                type="action"
                text="Save"
                icon={IconHelper.General.Edit}
                action={save}
                isPulledRight
                forceClip='bottom-right'
            />
        </span>
        }
    </FlexDiv>
}