import { observer } from "mobx-react-lite";
import * as React from 'react';
import { AudioStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Button } from "./Button";
import { ButtonHolder } from "./ButtonHolder";

export const AudioButtons = observer(() => {

    const audioState = React.useContext(AudioStateContext);

    if (audioState.State === "Nothing") {
        return null;
    }

    return <ButtonHolder className="is-pulled-right" data-disable-bubble-click="true">
        {audioState.State === "Playing" && <Button type="nav" text="Pause" icon={IconHelper.Audio.Pause} action={() => audioState.pause()} hideTextOnMobile />}
        {audioState.State === "Paused" && <Button type="nav" text="Resume" icon={IconHelper.Audio.Play} action={() => audioState.resume()} hideTextOnMobile />}
        <Button type="nav" text="Stop" icon={IconHelper.Audio.Stop} action={() => audioState.stop()} hideTextOnMobile />
    </ButtonHolder>;
});