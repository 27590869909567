import { FleetMovementType } from "../../../../ApplicationState/ApiClient"
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel"
import { ProgressBar } from "../../../../Components/Base/ProgressBar"
import { IconHelper } from "../../../../Helpers/IconHelper"
import { ValueFormatter } from "../../../../Helpers/ValueFormatter"

type Props = {
    type: FleetMovementType,
    capacity: number,
    current: number
}

export const FleetCapacityBar = (props: Props) => {

    const text = `${ValueFormatter.formatLocaleNumber(props.current)} / ${ValueFormatter.formatLocaleNumber(props.capacity)}`;

    return <SubPanel heading={"Cargo Capacity"}>
        <ProgressBar
            leftIcon={IconHelper.Ships.movementType(props.type)}
            value={props.current}
            max={props.capacity}
            text={text}
            warning={0}
            danger={0}
        />
    </SubPanel>
}