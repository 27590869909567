import { observer } from "mobx-react-lite";
import * as React from 'react';
import { Button } from "../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../Components/Base/Buttons/ButtonHolder";
import { ExpandButton } from "../../../Components/Base/Buttons/ExpandButton";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../Components/Base/Containers/Table";
import { InfoMessageBox } from "../../../Components/Base/MessageBox";
import { CreditsIconWithQuantity } from "../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity";
import { PlayerLink } from "../../../Components/FusionShift/Links/PlayerLink";
import { SolarSystemLink } from "../../../Components/FusionShift/Links/SolarSystemLink";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { ContractStatusDescription } from "../../Contracts/Components/ContractStatusDescription";
import { ContractTypeSpecificsForContractor } from "../../Contracts/Components/ContractTypeSpecificsForContractor";
import { TermsDetail } from "../../Contracts/Components/TermsDetail";
import { SendShipsBag } from "../SendShipsBag";

type Props = {
    sendShipsBag: SendShipsBag
}

export const AvailableContracts = observer((props: Props) => {

    const [isExpanded, setIsExpanded] = React.useState(false);
    const [expandedContractId, setExpandedContractId] = React.useState(-1);

    if (props.sendShipsBag.TargetSolarSystem === undefined ||
        props.sendShipsBag.MovementType === undefined ||
        props.sendShipsBag.AvailableContractsInTarget === undefined ||
        !(props.sendShipsBag.MovementType in props.sendShipsBag.AvailableContractsInTarget)) {
        return null;
    }

    const availableContracts = props.sendShipsBag.AvailableContractsInTarget[props.sendShipsBag.MovementType] ?? [];

    // Only show if there is a choice on the contracts
    // If there is only 1 available contract and it was pre-selected then don't show 
    if (availableContracts.length === 0 ||
        (availableContracts.length === 1 && availableContracts[0].contractId === props.sendShipsBag.init.initialContractId)) {
        return null;
    }

    if (!isExpanded) {
        return <>
            <hr />
            <InfoMessageBox
                text={<>
                    There {ValueFormatter.pluralizeAndWriteValue("contract", availableContracts.length)} available at <SolarSystemLink solarSystem={props.sendShipsBag.TargetSolarSystem} />.
                </>
                }
                button={
                    <Button type="nav" text="Show" icon={IconHelper.General.Plus} action={() => setIsExpanded(true)} />
                }
            />
        </>;
    }

    const selectedContractId = props.sendShipsBag.Contract?.contractId;

    return <>
        <hr />
        <SubPanel heading={{ text: "Contracts", icon: IconHelper.Economy.Contract }}>
            <Table isFullWidth isFixed>
                {availableContracts.map(x => <React.Fragment key={x.contractId}>
                    <tr>
                        <td>
                            <PlayerLink player={x.player} />
                        </td>
                        <td>
                            <CreditsIconWithQuantity quantity={x.remainingCredits} />
                        </td>
                        <td>
                            <ContractStatusDescription contractStatus={x.status} />
                        </td>
                        <td>
                            <ButtonHolder>
                                <ExpandButton isExpanded={x.contractId === expandedContractId} setIsExpanded={() => setExpandedContractId(x.contractId)} />
                                {selectedContractId === x.contractId &&
                                    <Button
                                        type="nav"
                                        icon={IconHelper.General.Cancel}
                                        text="Decline"
                                        action={() => props.sendShipsBag.setContract(undefined)}
                                    />
                                }
                                {selectedContractId !== x.contractId &&
                                    <Button
                                        type="nav"
                                        icon={IconHelper.General.Confirm}
                                        text="Accept"
                                        action={() => props.sendShipsBag.setContract(x)}
                                    />
                                }
                            </ButtonHolder>
                        </td>
                    </tr>
                    {expandedContractId === x.contractId && <tr>
                        <td colSpan={4}>
                            <SplitLayout
                                left={
                                    <TermsDetail contract={x} />
                                }
                                right={
                                    <ContractTypeSpecificsForContractor contractType={x.type} />
                                }
                            />
                        </td>
                    </tr>}
                </React.Fragment>)}
            </Table>
        </SubPanel>
    </>
});