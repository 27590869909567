import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { SendShipsButton } from '../../../../Components/FusionShift/Buttons/SendShipsButton';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { CollectionHelper } from '../../../../Helpers/CollectionHelper';
import { DroneHelper } from '../../../../Helpers/DroneHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { ShipControl, ShipTabs } from '../../../Fleets/FleetControl';
import { GroundShipsForm } from '../../../Fleets/Forms/GroundShipsForm';
import { DronesView } from '../Celestials/Drones/DronesView';
import { BuildShipsInSolarSystemButton } from './BuildShipsInSolarSystemButton';
import { ChooseWhereToBuildShips } from './ChooseWhereToBuildShips';
import { Summary } from './Summary';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const Ships = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const worldState = React.useContext(WorldStateContext);

    const [display, setDisplay] = React.useState("Tabs");
    const [initialTab, setInitialTab] = React.useState<ShipTabs | undefined>(undefined);

    React.useEffect(() => {
        let search = window.location.search;
        if (search.startsWith('?')) {
            search = search.substring(1);
        }

        const parsedFromSearch = new URLSearchParams(search);
        if (parsedFromSearch.has("tab")) {
            setInitialTab(parsedFromSearch.get("tab") as ShipTabs);
        }
    }, []);

    function groundShips(ships: { [key: string]: number }) {
        return apiState.ShipClient.groundShips(props.solarSystemWrapper.solarSystem.solarSystemId, ships).then(s => {
            if (s) {
                props.reloadCallback(s);
                setDisplay("Tabs");
            }
        })
    }

    if (!worldState.ItemTypeSettings || !worldState.InstallationTypeSettings) {
        return null;
    }
    const hasShips = CollectionHelper.isAnyInDictionary(props.solarSystemWrapper.solarSystem.availableShips);

    const celestialForDrones = DroneHelper.celestialForDronesAtSystem(worldState.InstallationTypeSettings, props.solarSystemWrapper.solarSystem);


    return <>
        <Summary {...props} itemTypeSettings={worldState.ItemTypeSettings} />
        <SubPanel
            heading="Ships"
            isUnpadded={display === "Build"}
            headingContent={
                <ButtonHolder>
                    {hasShips &&
                        <SendShipsButton text="Send" hideTextOnMobile />
                    }
                    <BuildShipsInSolarSystemButton
                        isPulledRight
                        isExpanded={display === "Build"}
                        setExpanded={() => setDisplay("Build")}
                    />
                    {hasShips &&
                        <Button
                            type="nav"
                            text="Ground"
                            icon={IconHelper.Ships.Grounded}
                            action={() => setDisplay("Ground")}
                            isSelected={display === "Ground"}
                            hideTextOnMobile
                        />
                    }

                </ButtonHolder>
            }>
            {display === "Ground" && <GroundShipsForm
                groundShipsCallback={groundShips}
                closeCallback={() => setDisplay("Ground")}
                ships={props.solarSystemWrapper.solarSystem.availableShips!}
                solarSystem={props.solarSystemWrapper.solarSystem} />}

            {display === "Build" && <ChooseWhereToBuildShips solarSystem={props.solarSystemWrapper.solarSystem} supportsLarge />}

            {display === "Tabs" && <ShipControl
                solarSystem={props.solarSystemWrapper}
                {...props}
                initialTab={initialTab}
            />}
        </SubPanel>
        {celestialForDrones !== undefined && <SubPanel heading="Drones">
            <DronesView
                celestial={celestialForDrones}
                solarSystem={props.solarSystemWrapper.solarSystem}
                reloadLocallyCallback={props.reloadCallback} />
        </SubPanel>}
    </>;
});