import { IContract } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { ContractHelper } from '../../../Helpers/ContractHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { AttackTermsDetail } from './Terms/AttackTermsDetail';
import { BribeForManifestoTermsDetail } from './Terms/BribeForManifestoTermsDetail';
import { BribeForPolicyTermsDetail } from './Terms/BribeForPolicyTermsDetail';
import { CourierTermsDetail } from './Terms/CourierTermsDetail';
import { DefendTermsDetail } from './Terms/DefendTermsDetail';
import { RansomTermsDetail } from './Terms/RansomTermsDetail';
import { ReconTermsDetail } from './Terms/ReconTermsDetail';

type Props = {
    contract: IContract
}

export const TermsDetail = (props: Props) => {

    const terms = props.contract.terms;

    return <SubPanel heading={{ text: "Terms", icon: IconHelper.Economy.ContractTerms }}>
        {ContractHelper.isAttackTerms(terms) && <AttackTermsDetail {...props} terms={terms} />}
        {ContractHelper.isDefendTerms(terms) && <DefendTermsDetail {...props} terms={terms} />}
        {ContractHelper.isReconTerms(terms) && <ReconTermsDetail {...props} terms={terms} />}
        {ContractHelper.isCourierTerms(terms) && <CourierTermsDetail {...props} terms={terms} />}
        {ContractHelper.isBribeForManifestoTerms(terms) && <BribeForManifestoTermsDetail {...props} terms={terms} />}
        {ContractHelper.isBribeForPolicyTerms(terms) && <BribeForPolicyTermsDetail {...props} terms={terms} />}
        {ContractHelper.isRansomTerms(terms) && <RansomTermsDetail {...props} terms={terms} />}
    </SubPanel>;
}