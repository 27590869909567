import * as React from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import * as Showdown from "showdown";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Button } from "../Buttons/Button";
import { ButtonHolder } from "../Buttons/ButtonHolder";
import { SubPanel } from "../Containers/SubPanel";

type Props = {
    title: string,
    markdown: string | undefined,
    submitCallback: (markdown: string) => Promise<boolean>,
    hideIfEmpty?: boolean,
    showEdit?: boolean
}

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

export const MarkdownControl = (props: Props) => {

    const [isEditing, setIsEditing] = React.useState(false);
    const [value, setValue] = React.useState(props.markdown || "");
    const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">("write");

    const hasMarkdown = value.length > 0;

    if (!hasMarkdown && props.hideIfEmpty) {
        return null;
    }

    function submit() {
        return props.submitCallback(value).then(b => {
            if (b) {
                setIsEditing(false);
            }
        });
    }

    return <SubPanel heading={props.title} headingContent={props.showEdit && <ButtonHolder>
        {!isEditing && <Button type="nav" text="Edit" icon={IconHelper.General.Edit} action={() => setIsEditing(true)} hideTextOnMobile />}
        {isEditing && <Button type="danger" text="Cancel" icon={IconHelper.General.Cancel} action={() => setIsEditing(false)} hideTextOnMobile />}
        {isEditing && <Button type="action" text="Save" icon={IconHelper.General.Confirm} action={submit} hideTextOnMobile />}
    </ButtonHolder>}>
        {!hasMarkdown && !isEditing && <p><i>Nothing here yet</i></p>}
        {hasMarkdown && !isEditing && <div className="has-linebreaks" dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.markdown!) }}></div>}
        {!!isEditing && <ReactMde
            value={value}
            onChange={setValue}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={markdown =>
                Promise.resolve(converter.makeHtml(markdown))
            }
        />}
    </SubPanel>;
};