import { FederationRank, FederationStatus, IPlayerMinimal, ISolarSystemMinimal, PlayerFederation, PlayerMinimal, SolarSystemClaimType } from "../../../../ApplicationState/ApiClient";
import { MathHelper } from "../../../../Helpers/MathHelper";
import { AcquiredTarget, AcquireTargetClosestConfiguration, AcquireTargetsConfig, GalaxyMapData, TargetsRender } from "../Entities";

export function createTargetsRender(targets: AcquiredTarget[], config: AcquireTargetsConfig): TargetsRender {

    const allies: {
        [key: number]: {
            solarSystem: ISolarSystemMinimal,
            targets: { x: number, y: number }[]
        }
    } = {};

    for (const target of targets) {
        if (target.nearestSolarSystem !== undefined) {
            if (!(target.nearestSolarSystem.solarSystem.solarSystemId in allies)) {
                allies[target.nearestSolarSystem.solarSystem.solarSystemId] = {
                    solarSystem: target.nearestSolarSystem.solarSystem,
                    targets: []
                };
            }

            allies[target.nearestSolarSystem.solarSystem.solarSystemId] = {
                ...allies[target.nearestSolarSystem.solarSystem.solarSystemId],
                targets: [
                    { x: target.solarSystem.x, y: target.solarSystem.y },
                    ...allies[target.nearestSolarSystem.solarSystem.solarSystemId].targets
                ]
            };
        }
    }

    return {
        targetRadius: config.additionalDistance,
        targets: targets.map(x => x.solarSystem),
        allies: Object.values(allies)
    }
}

export function acquireTargets(data: GalaxyMapData, config: AcquireTargetsConfig, closestConfig: AcquireTargetClosestConfiguration): AcquiredTarget[] {

    const targets: ISolarSystemMinimal[] = [];
    const allies: ISolarSystemMinimal[] = [];
    const ownSystems: ISolarSystemMinimal[] = [];

    const ownFederation = new PlayerFederation({
        federationId: data.player.federation?.federationId,
        federationRank: FederationRank.Member,
        shortName: "",
        status: FederationStatus.Active
    });

    const targetFederation = new PlayerFederation({
        federationId: config.federationId,
        federationRank: FederationRank.Member,
        shortName: "",
        status: FederationStatus.Active
    });

    for (const solarSystem of Object.values(data.map.entries)) {

        if (solarSystem.playerId === data.player.playerId) {

            if (closestConfig.excludeOutposts && solarSystem.claimType === SolarSystemClaimType.Outpost) {
                continue;
            }

            ownSystems.push(solarSystemWithFederation(solarSystem, data, ownFederation));
        }

        if (ownFederation.federationId !== undefined
            && ownFederation.federationId === solarSystem.federationId
            && solarSystem.playerId !== data.player.playerId) {

            if (closestConfig.excludeOutposts && solarSystem.claimType === SolarSystemClaimType.Outpost) {
                continue;
            }

            allies.push(solarSystemWithFederation(solarSystem, data, ownFederation));
        }

        if (solarSystem.federationId === config.federationId) {
            if (config.playerId !== undefined && solarSystem.playerId !== config.playerId) {
                continue;
            }

            targets.push(solarSystemWithFederation(solarSystem, data, targetFederation));
        }
    }

    const allAdditionalTargets: { [key: number]: { solarSystem: ISolarSystemMinimal, distance: number }[] } = {};

    // For each other neutral solar system find the closest target that has been identified and include it if within configured range
    if (config.additionalDistance > 0) {
        for (const solarSystem of Object.values(data.map.entries)) {
            if (solarSystem.playerId !== undefined && solarSystem.playerId !== null) {
                continue;
            }

            const closestTarget = findClosestTo(solarSystem, targets, x => x.distance <= config.additionalDistance);

            if (closestTarget !== undefined) {
                if (!(closestTarget.solarSystem.solarSystemId in allAdditionalTargets)) {
                    allAdditionalTargets[closestTarget.solarSystem.solarSystemId] = [];
                }

                allAdditionalTargets[closestTarget.solarSystem.solarSystemId] = [...allAdditionalTargets[closestTarget.solarSystem.solarSystemId], {
                    solarSystem,
                    distance: closestTarget.distance
                }];
            }
        }
    }

    return targets
        .map(x => {

            const additionalTargets = x.solarSystemId in allAdditionalTargets ? allAdditionalTargets[x.solarSystemId] : [];

            const closestOwn = findClosestTo(x, ownSystems, x => true);
            const closestAlly = findClosestTo(x, allies, x => true);
            const closestAbsolute = closestOwn === undefined ? closestAlly :
                closestAlly === undefined ? closestOwn :
                    closestAlly.distance < closestOwn.distance ? closestAlly : closestOwn;

            const closest = closestConfig.closest === "absolute" ? closestAbsolute :
                closestConfig.closest === "ally" ? closestAlly :
                    closestOwn;

            return {
                solarSystem: x,
                additionalTargets,
                nearestSolarSystem: closest
            }
        });
}

function solarSystemWithFederation(solarSystem: ISolarSystemMinimal, data: GalaxyMapData, federation: PlayerFederation): ISolarSystemMinimal {

    const player = data.map.players.find(x => x.playerId === solarSystem.playerId);

    const owner: IPlayerMinimal | undefined = player !== undefined ? {
        ...player,
        isDefeated: false,
        federation,
        factionTypeName: "",
        numberOfSolarSystems: 0,
        rank: 0
    } : undefined;

    return {
        ...solarSystem,
        owner: owner !== undefined ? new PlayerMinimal(owner) : undefined
    };
}

function findClosestTo<T extends { x: number, y: number }>(solarSystem: ISolarSystemMinimal, solarSystems: T[], filter: (x: { solarSystem: T, distance: number }) => boolean) {
    return solarSystems.map(x => {
        return {
            solarSystem: x,
            distance: MathHelper.distance(x.x, x.y, solarSystem.x, solarSystem.y)
        }
    })
        .filter(filter)
        .sort((a, b) => a.distance - b.distance)
        .find(x => true);
}