import React from "react";
import { Link } from "react-router-dom";
import { ManufactoryNode, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { SplitLayout } from "../../../../../Components/Base/Containers/SplitLayout";
import { ErrorMessageBox } from "../../../../../Components/Base/MessageBox";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { ProblemCategory } from "../../../../../Entities/SolarSystem/Problems/Problem";
import { CollectionHelper } from "../../../../../Helpers/CollectionHelper";
import { help_page } from "../../../../../Navigation/Routing/Help";
import { CelestialViewProps } from "../CelestialViewProps";
import { ConfigureByOutput } from "./ConfigureByOutput";
import { ManufactorySummary } from "./ManufactorySummary";
import { NodeControl } from "./NodeControl";
import { NodesControl } from "./NodesControl";

export const ManufactoryView = (props: CelestialViewProps) => {

    const [selectedManufactoryNodeId, setSelectedManufactoryNodeId] = React.useState<string | undefined>(undefined);
    const [selectedNode, setSelectedNode] = React.useState<ManufactoryNode | undefined>(undefined);
    const [configureByOutput, setConfigureByOutput] = React.useState(false);

    React.useEffect(() => {

        const allNodes = CollectionHelper.lazyConcat(props.celestial.manufactory && props.celestial.manufactory.inputNodes, props.celestial.manufactory && props.celestial.manufactory.outputNodes);

        setSelectedNode(allNodes.find(n => n.manufactoryNodeId === selectedManufactoryNodeId))

    }, [selectedManufactoryNodeId]);

    function reloadLocally(solarSystem: SolarSystemDetail) {
        setSelectedManufactoryNodeId(undefined);
        props.reloadLocallyCallback(solarSystem);
    }

    if (!props.celestial.manufactory) {
        return <ErrorMessageBox text={"There is no Manufactory at this celestial"} />;
    }

    if (configureByOutput) {
        return <ConfigureByOutput cancelCallback={() => setConfigureByOutput(false)}  {...props} {...props.celestial.manufactory}
            reloadCallback={(s) => {
                setConfigureByOutput(false);
                props.reloadLocallyCallback(s);
            }}
        />
    }

    const production = props.solarSystem.itemsDetail && props.celestial.celestialId in props.solarSystem.itemsDetail.manufactories.productionByCelestialId ?
        props.solarSystem.itemsDetail.manufactories.productionByCelestialId[props.celestial.celestialId] : undefined;

    return <SplitLayout
        left={
            <ManufactorySummary
                solarSystem={props.solarSystem}
                problems={props.solarSystemWrapper.problems.filter(x => x.category === ProblemCategory.Manufactories && x.celestialId === props.celestial.celestialId)}
                production={production}
                manufactory={props.celestial.manufactory}
                celestialId={props.celestial.celestialId}
                solarSystemId={props.solarSystem.solarSystemId}
                reloadCallback={props.reloadLocallyCallback} />
        }
        right={
            <>
                <NodesControl
                    solarSystem={props.solarSystem}
                    configureByCommodityCallback={() => setConfigureByOutput(true)}
                    inputNodes={props.celestial.manufactory.inputNodes}
                    outputNodes={props.celestial.manufactory.outputNodes}
                    setSelectedManufactoryNodeId={setSelectedManufactoryNodeId} selectedManufactoryNodeId={selectedManufactoryNodeId}
                    reloadCallback={props.reloadLocallyCallback}
                    celestialId={props.celestial.celestialId}
                />
                {selectedNode && <NodeControl
                    itemsDetail={props.solarSystem.itemsDetail}
                    key={selectedNode.manufactoryNodeId}
                    type={props.celestial.manufactory.inputNodes && props.celestial.manufactory.inputNodes.find(i => i.manufactoryNodeId === selectedNode.manufactoryNodeId) !== undefined ? "Input" : "Output"}
                    reloadCallback={reloadLocally}
                    closeCallback={() => setSelectedManufactoryNodeId(undefined)}
                    celestial={props.celestial}
                    node={selectedNode}
                    solarSystemId={props.solarSystem.solarSystemId} />}
                {selectedNode === undefined && <Paragraph>
                    See the <Link to={help_page("schematics")}>Schematics</Link> help page for further information.
                </Paragraph>}
            </>
        }
    />;
};