import { CelestialSize, FleetMovementType, IBasicSimulatedFleet, ISimulatedAttackingFleet, ISimulatedCelestial, ISimulatedFleet, ISimulatedInstallation, ISimulatedSolarSystem, ISimulationInput, SimulatedAttackingFleet } from '../../ApplicationState/ApiClient';

export const defaultAttacker: ISimulatedAttackingFleet = {
    fleetId: 0,
    megacityBonusPercent: 0,
    movementType: FleetMovementType.Attack,
    ships: {},
    tactics: {}
};

export const defaultInput: ISimulationInput = {
    attacker: SimulatedAttackingFleet.fromJS(defaultAttacker)
};

export const defaultDefendingFleet: ISimulatedFleet = {
    fleetId: 0,
    megacityBonusPercent: 0,
    movementType: FleetMovementType.Delivery,
    ships: {},
    tactics: {}
};

export const defaultDefendingSolarSystem: ISimulatedSolarSystem = {
    megacityBonusPercent: 0,
    ships: {},
    groundedShips: {},
    tactics: {},
    celestials: [],
    reinforcements: []
};

export const defaultReinforcingFleet: IBasicSimulatedFleet = {
    fleetId: 0,
    ships: {},
    tactics: {}
};

export const defaultCelestial: ISimulatedCelestial = {
    celestialId: 0,
    size: CelestialSize.Tiny,
    celestialTypeName: "Barren",
    name: "",
    installations: [],
    order: 0
}

export const defaultInstallation: ISimulatedInstallation = {
    celestialId: 0,
    installationId: 0,
    installationTypeName: "Stockpile",
    level: 1,
    damage: 0
}