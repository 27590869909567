import React from "react";
import { SolarSystemForObserver } from "../../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../../ApplicationState/ContextRoot";
import { Coordinate } from "../../../Entities/Shared";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Button } from "../../Base/Buttons/Button";
import { ControlHolder } from "../../Base/Containers/ControlHolder";
import { NumberInput } from "../../Base/Form/Input";

type Props = {
    initialTarget: number | Coordinate | undefined,
    solarSystemFoundCallback: (solarSystem: SolarSystemForObserver | undefined) => any,
}

export const FindSolarSystemByCoordinates = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const [inputTarget, setInputTarget] = React.useState<Coordinate>(typeof props.initialTarget !== "number" && props.initialTarget || { x: 0, y: 0 });
    const [target, setTarget] = React.useState<Coordinate | undefined>(typeof props.initialTarget !== "number" ? props.initialTarget : undefined);

    const [solarSystem, setSolarSystem] = React.useState<SolarSystemForObserver | undefined>(undefined);

    const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

    function changeTarget() {
        setTarget(inputTarget);
        return true;
    }

    React.useEffect(() => {
        if (typeof props.initialTarget === "number") {
            setIsButtonDisabled(true);
            apiState.SolarSystemClient.get(props.initialTarget).then(solarSystemResponse => {
                setSolarSystem(solarSystemResponse);
                setIsButtonDisabled(false);
            });
        }
    }, [props.initialTarget]);

    React.useEffect(() => {
        if (target) {
            setIsButtonDisabled(true);
            apiState.SolarSystemClient.getByCoordinate(target.x, target.y).then(solarSystemResponse => {
                setSolarSystem(solarSystemResponse);
                setIsButtonDisabled(false);
            });
        }
    }, [target]);

    React.useEffect(() => {
        if (solarSystem) {
            props.solarSystemFoundCallback(solarSystem);
        }
    }, [solarSystem]);

    return <ControlHolder hasAddons>
        <NumberInput
            placeholder="X"
            size={5}
            value={inputTarget.x}
            minValue={-1000}
            valueChanged={value => setInputTarget({
                x: Number(value),
                y: inputTarget.y
            })}
        />
        <NumberInput
            placeholder="Y"
            size={5}
            value={inputTarget.y}
            minValue={-1000}
            valueChanged={value => setInputTarget({
                x: inputTarget.x,
                y: Number(value)
            })} />
        <Button type="query" isDisabled={isButtonDisabled} icon={IconHelper.General.Find} action={() => changeTarget()} text="Search" />
    </ControlHolder>;
};