import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { selected_system } from '../../../Navigation/Routing/SolarSystem';
import { Button } from '../../Base/Buttons/Button';

type Props = {
    solarSystemId: number,
    text?: string | undefined
}

export const SelectAndGoToSolarSystemButton = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);

    function goTo() {
        if (props.solarSystemId !== appState.SolarSystemId) {
            appState.changeSolarSystemId(props.solarSystemId);
        }

        return appState.navigate(selected_system);
    }


    return <Button
        icon={IconHelper.General.Right}
        action={goTo}
        type="nav"
        text={props.text}
        hideTextOnMobile
    />;
});