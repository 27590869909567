import * as React from 'react';
import { Installation } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { InstallationImage } from '../../../../../Components/FusionShift/Images/InstallationImage';
import { BuildHelper } from '../../../../../Helpers/BuildHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { TimeHelper } from '../../../../../Helpers/TimeHelper';
import { InstallationStatsSummary } from '../Installations/StatsSummary';
import { SummaryTile } from './SummaryTile';
import { SummaryTileProps } from './SummaryTileProps';

type Props = SummaryTileProps & {
    installation: Installation,
    isConstructing: boolean
}

export const InstallationTile = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const installationType = props.installation.installationTypeName in props.installationTypeSettings.data ? props.installationTypeSettings.data[props.installation.installationTypeName] : undefined;

    if (!installationType) {
        return null;
    }

    function upgrade() {
        return apiState.InstallationClient.upgrade(
            props.solarSystem.solarSystemId,
            props.celestial.celestialId,
            props.installation.installationId)
            .then(solarSystemResponse => {
                if (solarSystemResponse.result !== undefined) {
                    props.reloadCallback(solarSystemResponse.result);
                }
            });
    }

    const upgradeDetails = BuildHelper.upgradeDetails(props.installation, props.celestial, props.solarSystem, props.installationTypeSettings, props.itemTypeSettings, props.gameSettings, props.worldEffects);

    const hours = upgradeDetails.nextCost && BuildHelper.calculateDurationInHours(upgradeDetails.nextCost, props.gameSettings)
    const timespan = hours && TimeHelper.createTimeSpanFromHours(hours);;
    const durationString = timespan && TimeHelper.formatTimeSpanByWords(timespan)

    return <SummaryTile
        {...props}
        isDanger={props.installation.damage > 0}
        feature={{ installationId: props.installation.installationId }}
        title={`${installationType.name} [${props.installation.level}]`}
        isMobileVertical
        thumbnail={
            <InstallationImage
                installationType={installationType}
                size="medium"
                installation={props.installation}
                celestialId={props.celestial.celestialId}
                isConstructing={props.isConstructing}
            />
        }
        additionalButtons={
            upgradeDetails.hasNextLevel &&
            <Button
                type="action"
                icon={IconHelper.Installations.Upgrade}
                text={`Upgrade (${durationString})`}
                action={() => upgrade()}
                isDisabled={!!upgradeDetails.upgradeError}
                hideTextOnMobile />
        }
    >
        {!!installationType && <InstallationStatsSummary {...props} installationType={installationType} />}
    </SummaryTile>;
};