import { FactionType, IClientSettings, IShipTypeSettings, ShipTypeDescription, FactionTypeDescription as TypeDescription } from "../../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../../../Components/Base/Loading/LoadingSpinner";
import { FactionTypeDescription } from "../../../../Components/FusionShift/Descriptions/FactionTypeDescription";
import { TypeDataVoice } from "../../../../Components/FusionShift/TypeDataVoice";

type Props = {
    factionType: FactionType,
    description: TypeDescription,
    shipTypeSettings: IShipTypeSettings,
    shipDescriptions: ShipTypeDescription[],
    clientSettings?: IClientSettings | undefined,
    disableLink?: boolean
}

export const FactionTypeControl = (props: Props) => {
    if (props.description === undefined) {
        return <LoadingSpinner />;
    }

    return <SubPanel
        id={props.factionType.typeName}
        heading={props.factionType.name}
        headingContent={<TypeDataVoice
            type="FactionType"
            typeData={props.factionType}
            clientSettings={props.clientSettings}
        />}
    >
        <FactionTypeDescription {...props} />
    </SubPanel>;
};
