import * as React from 'react';
import { AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from '../../Components/Base/Buttons/Button';
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { IconHelper } from '../../Helpers/IconHelper';
import { ChangeKey } from "./ChangeTypes";

type Props = {
    keys: ChangeKey[],
    setKey: (key: ChangeKey) => any,
    currentKey: ChangeKey
}

export const ArchiveButtons = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    const currentIndex = React.useMemo(() => {
        return props.keys.findIndex(x => x.year === props.currentKey.year && x.month === props.currentKey.month);
    }, [props.currentKey, props.keys])

    function canBack() {
        return currentIndex < props.keys.length - 1;
    }
    function canNext() {
        return currentIndex > 0;
    }

    function back() {
        if (canBack()) {
            props.setKey(props.keys[currentIndex + 1]);
            appState.scrollToTop();
        }
    }

    function next() {
        if (canNext()) {
            props.setKey(props.keys[currentIndex - 1]);
            appState.scrollToTop();
        }
    }

    return <ButtonHolder isPulledRight>
        <Button
            type="nav"
            icon={IconHelper.General.Previous}
            isDisabled={!canBack()}
            action={back}
            hideTextOnMobile
            text="Previous"
        />
        <Button
            type="nav"
            icon={IconHelper.General.Next}
            isDisabled={!canNext()}
            action={next}
            hideTextOnMobile
            text="Next"
        />
    </ButtonHolder>
}