import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CelestialDetail, CelestialSize, ICelestial, ICelestialDetail, ISolarSystem, ISolarSystemDetail, SolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { AppStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { celestial_summary } from '../../../Navigation/Routing/SolarSystem';
import { CelestialIcons } from './CelestialIcons';

type Props = {
    solarSystem: ISolarSystem | ISolarSystemDetail
    celestial: ICelestial | ICelestialDetail,
    order: number,
    isRight: boolean,
    isSelected: boolean,
    style?: any,
    allowClick?: boolean
}

export const CelestialRender = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const appState = React.useContext(AppStateContext);

    if (!worldState.CelestialTypeSettings) {
        return null;
    }

    function click() {
        if (props.allowClick) {
            appState.navigate(celestial_summary(props.celestial.celestialId))
        }
    }

    const order = `is-${props.order}`;

    const pop = props.celestial.population > 0 ? `(${ValueFormatter.formatPopulation(props.celestial.population)})` : "";

    const outerClasses = classNames(
        {
            "is-selected": props.isSelected
        },
        "planet",
        "celestial",
        "is-real",
        (props.celestial.celestialTypeName ?? "unknown").toLocaleLowerCase(),
        CelestialSize[props.celestial.size].toLocaleLowerCase(),
        order
    );

    return <div className={`orbit ${order}`} style={props.style}>
        <div className="pos">
            <div className={outerClasses}
                onClick={click}
            >
                <dl className={`infos is-${props.isRight ? "right" : "left"} ${props.isSelected ? "is-selected" : ""}`}>
                    <dt>{CelestialHelper.fullname(props.celestial, worldState.CelestialTypeSettings)} {pop}</dt>
                </dl>
            </div>
            {props.celestial instanceof CelestialDetail && props.solarSystem instanceof SolarSystemDetail &&
                <CelestialIcons celestial={props.celestial} solarSystem={props.solarSystem} />
            }
        </div>
    </div>;
});