import { observer } from "mobx-react-lite";
import * as React from "react";
import { ApiStateContext } from "../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { Paragraph } from "../../../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { EmblemEditorContext } from "../../State/EmblemEditorContext";

export const SavePanel = observer(() => {
  const apiState = React.useContext(ApiStateContext);

  const emblemEditorState = React.useContext(EmblemEditorContext);

  if (!emblemEditorState.emblem || !emblemEditorState.isDirty) {
    return null;
  }

  return (
    <>
      <hr />
      <Paragraph>
        Unsaved changes
        <Button
          isPulledRight
          type="action"
          text="Save"
          isDisabled={!emblemEditorState.isDirty}
          action={async () => {
            if (emblemEditorState.emblem === undefined) {
              return;
            }
            if (emblemEditorState.emblem.configuration) {
              if (emblemEditorState.emblem.emblemId === undefined || emblemEditorState.emblem.emblemId === 0) {
                await apiState.EmblemClient.create(emblemEditorState.emblem.configuration);
              } else {
                await apiState.EmblemClient.modify(
                  emblemEditorState.emblem.emblemId,
                  emblemEditorState.emblem.configuration
                );
              }
              emblemEditorState.dispatchEmblemSaved();
            }
          }}
          icon={IconHelper.General.Confirm}
        />
      </Paragraph>
    </>
  );
});
