import React from "react";
import { ItemType, RequestResponseOfSolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Button } from "../../Base/Buttons/Button";

type Props = {
    installationTypeName: string,
    solarSystemId: number,
    celestialId: number,
    buildError: string | ItemType[] | undefined,
    reloadCallback: (response: RequestResponseOfSolarSystemDetail) => any,
    isPulledRight?: boolean
}

export const BuildButton = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    function build() {
        return apiState.InstallationClient.build(
            props.solarSystemId,
            props.celestialId,
            props.installationTypeName)
            .then(props.reloadCallback);
    }

    return <Button type="action" icon={IconHelper.Installations.Build} text="Build" action={() => build()} isDisabled={!!props.buildError} {...props} />;
};