import { FleetMovementType } from "../../../../ApplicationState/ApiClient";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { militaryProblemBuilder } from "./MilitaryProblems";

export function incomingProblems(wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;

    if (solarSystem.fleets) {
        let recons = 0, attacks = 0;

        for (let m of solarSystem.fleets) {
            if (m.ownerPlayerId === wrapper.solarSystem.playerId) {
                continue;
            }

            if (m.movementType === FleetMovementType.Attack) {
                attacks++;
            } else if (m.movementType === FleetMovementType.Recon) {
                recons++;
            }
        }

        return [
            recons > 0 && militaryProblemBuilder.warningProblem(`${recons}x Incoming ${ValueFormatter.pluralize("Recon", recons)}`, `${wrapper.solarSystem.name} is the target of ${recons} recon ${ValueFormatter.pluralize("mission", recons)}`),
            attacks > 0 && militaryProblemBuilder.dangerProblem(`${attacks}x Incoming ${ValueFormatter.pluralize("Attack", attacks)}`, `${wrapper.solarSystem.name} is the target of ${attacks} attack ${ValueFormatter.pluralize("mission", attacks)}`)
        ];
    }

    return undefined;
}