import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AgentStatus, IAgentDetail } from '../../../ApplicationState/ApiClient';
import { SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ButtonLink } from '../../../Components/Base/Buttons/ButtonLink';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ContractHelper } from '../../../Helpers/ContractHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { contractById, contract_createRansom } from '../../../Navigation/Routing/Contracts';

type Props = {
    agent: IAgentDetail
}

export const RansomComponent = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!solarSystemState.SolarSystemsDetail) {
        return null;
    }

    const solarSystem = solarSystemState.SolarSystemsDetail.find(x => x.solarSystemId === props.agent.celestialPosition?.solarSystem?.solarSystemId)?.solarSystem;

    if (solarSystem === undefined) {
        return null;
    }

    const contract = ContractHelper.findRansomContract(solarSystem.contracts, props.agent);

    return <SubPanel heading={{ text: "Prisoner", icon: IconHelper.Agents.status(AgentStatus.Captured) }} footerContent={
        <ButtonHolder
            isPulledRight>
            {contract !== undefined && <ButtonLink icon={IconHelper.Economy.Contract} text="View Contract" to={contractById(contract.contractId)} />}
            {contract === undefined && <ButtonLink icon={IconHelper.Economy.ContractCreate} text="Public Ransom" to={contract_createRansom(props.agent.agentId, undefined)} />}
            {contract === undefined && <ButtonLink icon={IconHelper.Economy.ContractCreate} text={`Ransom to ${props.agent.player?.name}`} to={contract_createRansom(props.agent.agentId, props.agent.playerId)} />}
        </ButtonHolder>
    }>
        <Paragraph>
            {props.agent.name} is your prisoner and may be ransomed.
        </Paragraph>

    </SubPanel>;
});