import { observer } from "mobx-react-lite";
import React from "react";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { federation_view, federations, federations_own } from "../../Routing/Federation";
import { federationAccolades } from "../../Routing/Misc";
import { MenuLink } from "../MenuState";

export const FederationMenu = observer(() => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    return <>
        {!!playerState.Player.federation && playerState.Player.federation.federationId !== undefined &&
            <MenuLink className="navbar-item" to={federation_view(playerState.Player.federation.federationId)}>[{playerState.Player.federation.shortName}] {playerState.Player.federation.name}</MenuLink>
        }
        <MenuLink to={federations} className="navbar-item" end>Federations</MenuLink>
        <MenuLink to={federations_own} className={`navbar-item`}>
            Your Federation
        </MenuLink>
        <MenuLink to={federationAccolades} className="navbar-item">
            Federation Accolades
        </MenuLink>
    </>;
});