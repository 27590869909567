import { CombatReportStatus, ICombatReport, IPlayerForSelf } from '../../../ApplicationState/ApiClient';
import { FederationLink } from '../../../Components/FusionShift/Links/FederationLink';

type Props = {
    combatReport: ICombatReport,
    player: IPlayerForSelf
}

export const Visibility = (props: Props) => {

    if (props.combatReport.status === CombatReportStatus.Public) {
        return <>This combat report is visible to anyone with the link.</>;
    }

    if (props.player.federation === undefined) {
        return null;
    }

    return <>This combat report is visible to anyone in <FederationLink federation={props.player.federation} />.</>;
}