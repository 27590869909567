import { VictoryPie } from "victory"
import { graphTheme } from "./Theme"

type Data = {
    x: string,
    y: number
}

type Props = {
    data: Data[]
}

export const PieChart = (props: Props) => {

    return <VictoryPie
        theme={{
            ...graphTheme.bar,
        }}
        data={props.data}
        colorScale={graphTheme.line.map(x => x.data.stroke)}
        labels={_ => ""}
    />;
}