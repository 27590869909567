import { observer } from "mobx-react-lite";
import React from "react";
import { InGameProduct, InGameProductCategory, PremiumDetails, Product } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { Table } from "../../../../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../../../../Components/Base/Loading/LoadingSpinner";
import { PremiumIcon } from "../../../../../Components/FusionShift/Icons/PremiumIcon";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { checkout_subscription_success } from "../../../../../Navigation/Routing/Premium";

type SubscriptionInGameProductsList = {
    details: PremiumDetails
}

export const SubscriptionInGameProductsList = observer((props: SubscriptionInGameProductsList) => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    const [inGameProducts, setInGameProducts] = React.useState<InGameProduct[] | undefined>(undefined);
    const [purchasing, setPurchasing] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!inGameProducts) {
            apiState.PremiumClient.getInGameProductsByCategory(InGameProductCategory.Subscription).then(products => {
                if (products) {
                    setInGameProducts(products);
                }
            });
        }
    }, [inGameProducts]);

    function buy(inGameProductId: number) {
        setPurchasing(true);

        apiState.PremiumClient.buyInGameProduct(inGameProductId).then(() => {
            appState.reload("Refresh has active subcription after buying in game product subscription");
            appState.navigate(checkout_subscription_success)
        });
    }

    if (!inGameProducts || !appState.IsReady || purchasing) {
        return <LoadingSpinner />;
    }

    if (!appState.IsPremiumEnabled) {
        appState.navigate("/");
    }

    return <>
        <Table isFullWidth>
            {inGameProducts.map(p =>
                <tr key={p.inGameProductId}>
                    <td>
                        {p.name} - <PremiumIcon amount={p.unitCost} />
                    </td>
                    <td>
                        <Button
                            type="action"
                            text="Buy"
                            action={() => buy(p.inGameProductId)}
                            icon={IconHelper.Premium.Currency}
                            isDisabled={props.details.balance < p.unitCost} />
                    </td>
                </tr>)}
        </Table>
    </>
});