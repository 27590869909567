import { CombatReportIntel, ICelestialTypeSettings, IInstallationTypeSettings, InstallationInfo, InstallationType, ISolarSystemMinimal } from "../../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { CelestialList } from "../CelestialList";

type Props = {
    intel: CombatReportIntel,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    solarSystem: ISolarSystemMinimal | undefined
}

export const CombatReportSeenInstallations = (props: Props) => {

    if (!props.intel.installations || props.intel.installations.length === 0) {
        return null;
    }

    function extra(c: InstallationInfo, i: InstallationType) {

        return `[${c.level}]`;
    }

    return <SubPanel
        heading={{ text: "Seen Installations", icon: IconHelper.Combat.Recon }}
    >
        <CelestialList
            celestials={props.intel.installations}
            installationTypeSettings={props.installationTypeSettings}
            celestialTypeSettings={props.celestialTypeSettings}
            solarSystem={props.solarSystem}
            renderExtra={extra}
        />
    </SubPanel>;
};
