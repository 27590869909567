import { Button } from "../../../Components/Base/Buttons/Button";
import { ButtonChooser, choice } from "../../../Components/Base/Buttons/ButtonChooser";
import { IconHelper } from "../../../Helpers/IconHelper";
import { GalaxyMapPreferences, SolarSystemViewMode } from "./GalaxyMapPreferences";

type Props = {
    isTargetMode: boolean
    setTargetMode: (on: boolean) => any
    preferences: GalaxyMapPreferences,
    modifyPreferences: (p: GalaxyMapPreferences) => any
}

export const PreMapControls = (props: Props) => {

    function solarSystemValueChanged(value: SolarSystemViewMode | undefined) {
        props.modifyPreferences({
            ...props.preferences,
            solarSystemDisplay: value
        });
    }

    const solarSystemValues = [
        choice<SolarSystemViewMode | undefined>(undefined, "All", IconHelper.General.View),
        choice<SolarSystemViewMode | undefined>("explored", "Explored", IconHelper.Ships.Explore),
        choice<SolarSystemViewMode | undefined>("unexplored", "Unexplored", IconHelper.Ships.UnExplored),
    ];

    return <div className="galaxy-filters is-clearfix">
        {!props.isTargetMode &&
            <Button
                type="nav"
                hideTextOnMobile
                text="Target Mode"
                icon={IconHelper.Combat.Target}
                action={() => props.setTargetMode(true)}
                forceClip="top-left"
            />
        }
        {props.isTargetMode &&
            <Button
                type="nav"
                hideTextOnMobile
                text="Leave Target Mode"
                icon={IconHelper.General.Close}
                action={() => props.setTargetMode(false)}
                forceClip="top-left"
            />
        }

        <ButtonChooser
            isPulledRight
            value={props.preferences.solarSystemDisplay}
            values={solarSystemValues}
            valueChanged={solarSystemValueChanged}
        >
            <Button
                type="nav"
                isSelected={props.preferences.showFleets}
                icon={IconHelper.Ships.Ship}
                text="Fleets"
                action={() => {
                    props.modifyPreferences({
                        ...props.preferences,
                        showFleets: !props.preferences.showFleets
                    })
                }}
            />
        </ButtonChooser>
    </div>;
}