import * as React from "react";
import {
  Bar,
  VictoryAxis, VictoryBar,
  VictoryChart, VictoryGroup
} from "victory";
import { graphTheme } from "./Theme";

type AxisProps = {
  property: string;
  format?: (x?: any) => string;
  label?: string;
}

type BaseProps = {
  x: AxisProps;
  y: AxisProps;
  isHorizontal?: boolean;
  animateDuration?: number;
};

type Props = BaseProps & {
  data: any[];
  min?: number,
  max?: number
};

type BarProps = {
  data: any[],
  className?: string
}

export const BarChart = (props: Props) => {
  const tickValues = props.data.map((x) => {
    return x[props.x.property];
  });

  const yDomain: [number, number] | undefined = props.min !== undefined && props.max !== undefined ? [props.min, props.max] : undefined;

  return (
    <VictoryChart
      domainPadding={30}
      theme={graphTheme.bar}
      animate={graphTheme.animate}
      horizontal={props.isHorizontal}
    >
      <VictoryAxis
        tickFormat={props.x.format}
        tickValues={tickValues}
        label={props.x.label}
        style={graphTheme.axisStyle}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={props.y.format}
        label={props.y.label}
        style={graphTheme.axisStyle}
        domain={yDomain}
      />
      <VictoryBar
        data={props.data}
        x={props.x.property}
        y={props.y.property} />
    </VictoryChart>
  );
};

type MultiProps = BaseProps & {
  categories?: string[]
  bars: BarProps[];
};

export const MultiBarChart = (props: MultiProps) => {

  if (props.bars.length === 0) {
    return null;
  }

  const tickValues: any[] = [];

  for (let bar of props.bars) {
    for (let val of bar.data) {
      const tick = val[props.x.property];

      if (!tickValues.includes(tick)) {
        tickValues.push(tick);
      }
    }
  }

  props.bars.map((x) => {
    return x[props.x.property];
  });

  return (
    <VictoryChart
      domainPadding={30}
      theme={graphTheme.bar}
      animate={{ onLoad: { duration: 1 }, duration: 500 }}
      horizontal={props.isHorizontal}
    >
      <VictoryAxis
        tickFormat={props.x.format}
        tickValues={tickValues}
        label={props.x.label}
        style={graphTheme.axisStyle}
      />
      <VictoryAxis
        dependentAxis
        tickFormat={props.y.format}
        label={props.y.label}
        style={graphTheme.axisStyle}
      />
      <VictoryGroup
        categories={props.categories !== undefined ? { x: props.categories } : undefined}
        offset={6}
        colorScale={"qualitative"}
      >
        {props.bars.map((x, i) => <VictoryBar
          key={i}
          data={x.data}
          x={props.x.property}
          y={props.y.property}
          dataComponent={<Bar className={x.className} />}
        />)}
      </VictoryGroup>
    </VictoryChart>
  );
};

BarChart.defaultProps = {
  animateDuration: 5000
}
