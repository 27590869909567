import { Link } from 'react-router-dom';
import { Fleet, FleetMovementType, IShipTypeSettings } from '../../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { FleetArrivalDescription } from '../../../../../Components/FusionShift/FleetArrivalDescription';
import { FleetCargoIcons, FleetIcon } from '../../../../../Components/FusionShift/Icons/FleetIcon';
import { FleetSummaryIcons } from '../../../../../Components/FusionShift/Icons/FleetSummaryIcons';
import { ShipQuantityIcons } from '../../../../../Components/FusionShift/Icons/ShipQuantityIcons';
import { FleetLocationLink } from '../../../../../Components/FusionShift/Links/FleetLocationLink';
import { FleetHelper } from '../../../../../Helpers/FleetHelper';
import { TimeHelper } from '../../../../../Helpers/TimeHelper';
import { system_subView } from '../../../../../Navigation/Routing/SolarSystem';

type Props = {
    shipTypeSettings: IShipTypeSettings,
    fleets: Fleet[],
    type: "Arrivals" | "Departures"
};

type RowProps = {
    shipTypeSettings: IShipTypeSettings,
    fleet: Fleet,
    type: "Arrivals" | "Departures"
}

type SummaryRowProps = {
    shipTypeSettings: IShipTypeSettings,
    fleets: Fleet[]
}

const max = 4;

type Summary = {
    movementType: FleetMovementType,
    isPlayerOwner: boolean,
    isHostile: boolean,
    quantity: number
};

export const SummaryRow = (props: SummaryRowProps) => {

    if (props.fleets.length === 0) {
        return null;
    }

    return <tr>
        <td>
            More:
        </td>
        <td colSpan={3}>
            <FleetSummaryIcons fleets={props.fleets} showCount />
        </td>
    </tr>
};


export const FleetRow = (props: RowProps) => {

    return <tr>
        <td>
            <FleetIcon fleet={props.fleet} />
            <FleetCargoIcons fleet={props.fleet} />
        </td>
        <td>
            <ShipQuantityIcons ships={props.fleet.ships} shipTypeSettings={props.shipTypeSettings} />
        </td>
        <td>
            <FleetArrivalDescription fleet={props.fleet} />
        </td>
        <td className="is-pulled-right">
            <FleetLocationLink {...props} type={props.type === "Arrivals" ? "source" : "target"} allowWrap />
        </td>
    </tr >
};

export const MiniFleetView = (props: Props) => {

    const grouped: Fleet[][] = [[], []];

    props.fleets
        .sort((a, b) => {

            const isAArrivedAndPresent = a.movementType === FleetMovementType.Reinforce && FleetHelper.isArrivedAndPresentInSolarSystem(a);
            const isBArrivedAndPresent = b.movementType === FleetMovementType.Reinforce && FleetHelper.isArrivedAndPresentInSolarSystem(b);

            if (isAArrivedAndPresent && !isBArrivedAndPresent) {
                return 1;
            } else if (!isAArrivedAndPresent && isBArrivedAndPresent) {
                return -1;
            }

            return TimeHelper.sortDates(a.arrivalDate, b.arrivalDate);
        })
        .reduce((a, v, i) => {
            if (i < max) {
                a[0].push(v);
            } else {
                a[1].push(v);
            }

            return a;
        }, grouped);

    return <SubPanel
        heading={{ content: <Link to={`${system_subView("ships")}?tab=${props.type}`}>{props.type}</Link> }}
        isUnpadded
    >
        <Table isFullWidth>
            {grouped[0].map(f => <FleetRow key={f.fleetId} fleet={f} {...props} />)}
            <SummaryRow fleets={grouped[1]} shipTypeSettings={props.shipTypeSettings} />
        </Table>
    </SubPanel>;
};