import axios from "axios";
import { Manifest } from "../../../ApplicationState/ApiClient";

export const getManifestDataFromApi = async (baseUrl: string) => {
  try {
    var url = `${baseUrl}manifest.json`;

    const response = await axios.get(url);

    return Manifest.fromJS(response.data);
  } catch (error) {
    console.log(error);
  }
};
