import { useParams } from 'react-router-dom';
import { IGameSettings, IWorldEffects, SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { SolarSystemRender } from '../../../../Components/FusionShift/SolarSystems/SolarSystemRender';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { celestial_summary, system_subView } from '../../../../Navigation/Routing/SolarSystem';
import { CelestialRouter } from './CelestialRouter';
import { CelestialTable } from './CelestialTable';

type Props = {
    worldEffects: IWorldEffects,
    gameSettings: IGameSettings,
    solarSystemWrapper: SolarSystemWrapper,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    changeUrl: (string: string) => any
}

export const Celestials = (props: Props) => {

    const { celestialId } = useParams();

    function changeCelestialId(celestialId: number | undefined) {
        const toSet = celestialId !== undefined ? celestial_summary(celestialId) : system_subView("celestials");
        props.changeUrl(toSet);
    }

    return <SubPanel
        heading="Celestials"
        className="solar-system-panel"
        isUnpadded>
        <SolarSystemRender
            solarSystem={props.solarSystemWrapper.solarSystem}
            selectedCelestialId={Number(celestialId)}
            allowClick
        />
        {celestialId !== undefined && <CelestialRouter
            celestialId={Number(celestialId)}
            closeCallback={() => changeCelestialId(undefined)}
            {...props} />
        }
        {celestialId === undefined && <CelestialTable solarSystem={props.solarSystemWrapper.solarSystem} />}
    </SubPanel>;
}