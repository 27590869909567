import { SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../Components/Base/Buttons/ButtonHolder';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { IncomingAttacks } from '../../../Empire/Summary/IncomingAttacks';
import { SolarSystemSubView } from '../../SelectedSolarSystemView';
import { AdvisorControl } from './Advisor/AdvisorControl';
import { BuildQueueControl } from './BuildQueue/BuildQueueControl';
import { LosingControlMessage } from './LosingControlMessage';
import { RelicMessage } from './RelicMessage';
import { Ships } from './Ships/Ships';
import { SolarSystemStats } from './SolarSystemStats';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    changeUrl: (url: string) => any,
    changeSubView: (subView: SolarSystemSubView) => any
}

export const Summary = (props: Props) => {

    return <>
        <SolarSystemStats solarSystem={props.solarSystemWrapper.solarSystem} />
        <RelicMessage {...props} />
        <LosingControlMessage solarSystem={props.solarSystemWrapper.solarSystem} />
        <IncomingAttacks solarSystems={[props.solarSystemWrapper]} />
        <BuildQueueControl {...props} solarSystem={props.solarSystemWrapper.solarSystem} />
        <Ships {...props} />
        <AdvisorControl {...props} />
        <ButtonHolder isPulledRight>
            <Button type="nav" text="Settings" icon={IconHelper.Categories.Settings} action={() => props.changeSubView("settings")} />
        </ButtonHolder>
    </>;
};