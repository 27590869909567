import * as React from "react";
import { ISolarSystemDetail, MarketOrder } from "../../../ApplicationState/ApiClient";
import { Level, levelContentWithHeading } from "../../../Components/Base/Containers/Level";
import { SolarSystemLink } from "../../../Components/FusionShift/Links/SolarSystemLink";
import { MarketOrderHelper } from "../../../Helpers/MarketOrderHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type Props = {
    solarSystem: ISolarSystemDetail,
    marketOrders: MarketOrder[]
}

export const MarketOrderSolarSystemSummary = (props: Props) => {

    const content = [
        levelContentWithHeading("Location", <SolarSystemLink solarSystem={props.solarSystem} />),
        levelContentWithHeading("Orders", `${props.marketOrders.length} / ${props.solarSystem.marketOrderQuantity}`),
        levelContentWithHeading("Capacity", `${ValueFormatter.formatLocaleNumber(MarketOrderHelper.usedMarketQuantity(props.marketOrders))} / ${ValueFormatter.formatLocaleNumber(props.solarSystem.marketCapacity)}`),
    ];

    return <Level content={content} />;
};
