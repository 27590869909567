import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ShipClass } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { TabMenuWithChildren } from '../../../Components/Base/Containers/TabMenu';
import { IconHelper } from '../../../Helpers/IconHelper';
import { EmpireViewProps } from '../EmpireViewProps';
import { Components } from './Components';
import { Items } from './Items';
import { Manufactories } from './Manufactories';
import { Miners } from './Miners';

type EconomyTab = "Items" | "Components" | "Manufactories" | "Miners";

export const EconomyView = observer((props: EmpireViewProps) => {

    const worldState = React.useContext(WorldStateContext);

    const [tab, setTab] = React.useState<EconomyTab>("Items");

    const tabs = [
        {
            id: "Items",
            title: "Items",
            isAvailable: true,
            icons: IconHelper.Items.Item
        },
        {
            id: "Components",
            title: "Components",
            isAvailable: true,
            icons: IconHelper.Items.Component
        },
        {
            id: "Manufactories",
            title: "Manufactories",
            isAvailable: true,
            icons: IconHelper.Manufactories.Manufactory
        },
        {
            id: "Miners",
            title: "Miners",
            isAvailable: true,
            icons: IconHelper.Ships.shipClass(ShipClass.MiningBarge)
        }
    ];

    return <TabMenuWithChildren
        tabs={tabs}
        active={tab}
        changeTab={setTab}
    >
        {tab === "Items" && <Items {...props} />}
        {tab === "Components" && <Components {...props} />}
        {tab === "Manufactories" && <Manufactories {...props} />}
        {tab === "Miners" && <Miners {...props} />}
    </TabMenuWithChildren>;
});

