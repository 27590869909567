import { ActivateActionResponse, AgentStatus, IAgent } from '../../../ApplicationState/ApiClient';
import { MessageBoxWithIcon } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    agent: IAgent,
    response: ActivateActionResponse | undefined
}

export const ResponseMessage = (props: Props) => {

    if (props.response === undefined) {
        return null;
    }

    function duration() {
        if (props.response === undefined || props.response.effectExpiryDate === undefined) {
            return "";
        }

        return ` The effect will last until ${ValueFormatter.formatTimeOrDate(props.response.effectExpiryDate, true, true)}.`
    }

    if (props.response.wasCaptured) {
        return <MessageBoxWithIcon
            type="danger"
            icon={IconHelper.Agents.status(AgentStatus.Captured)}
            text={props.response.wasSuccess ? `The mission was a success but ${props.agent.name} was captured!` : `${props.agent.name} was captured trying to complete the mission`}
        />;
    }
    if (props.response.wasSuccess) {
        return <MessageBoxWithIcon
            type="success"
            icon={IconHelper.General.Confirm}
            text={`${props.agent.name} successfully completed the mission.${duration()}`}
        />;
    }

    return <MessageBoxWithIcon
        type="warning"
        icon={IconHelper.General.Close}
        text={`${props.agent.name} did not successfully complete the mission.`}
    />;
};