import * as React from 'react';
import { Manufactory, ManufactoryProduction } from '../../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { Icon } from '../../../../../Components/Base/Icon';
import { TextAndIcon } from '../../../../../Components/Base/Text/TextAndIcon';
import { CollectionHelper } from '../../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    manufactory: Manufactory,
    production: ManufactoryProduction | undefined
};

type RowProps = {
    icon: string | React.ReactNode,
    description: string,
    bonus: number | undefined,
    isTotal?: boolean
}

type CellProps = {
    bonus: number | undefined
}

const Cell = (props: CellProps) => {

    return <td className={(props.bonus ?? 0) < 0 ? "has-text-danger" : "has-text-success"}>
        {ValueFormatter.fromDecimalToDisplayPercent(props.bonus ?? 0)}
    </td>
}

const Row = (props: RowProps) => {

    if (!props.isTotal && (props.bonus === undefined || props.bonus === 0)) {
        return null;
    }

    return <tr className={props.isTotal ? "is-total" : undefined}>
        <td>
            <TextAndIcon
                icon={<>
                    {typeof props.icon === "string" && <Icon icon={props.icon} />}
                    {typeof props.icon !== "string" && props.icon}
                </>}
                text={props.description}
            />
        </td>
        <Cell {...props} />
    </tr>
}

export const Bonuses = (props: Props) => {

    if (!props.production) {
        return null;
    }

    const anyBonus = CollectionHelper.isAnyInDictionary(props.production.totalBonusesPercent);

    if (!anyBonus) {
        return null;
    }

    const key = Object.keys(props.production.totalBonusesPercent)[0];

    return <SubPanel
        heading={{
            text: "Output Bonuses",
            icon: IconHelper.Categories.Bonuses
        }}
        isUnpadded
    >
        <Table isFullWidth isFixed>
            <Row
                icon={IconHelper.Agents.Agent}
                description='Agent Effects'
                bonus={props.production.agentBonusPercent[key]}
            />
            <Row
                icon={IconHelper.Megacities.Megacity}
                description='Megacity Bonus'
                bonus={props.production.megacityBonusPercent[key]}
            />

            <Row
                icon={IconHelper.SolarSystems.Colony}
                description='Colony Penalty'
                bonus={props.production.colonyBonusPercent[key]}
            />
            <Row
                icon={IconHelper.Security.Exile}
                description='Exile Penalty'
                bonus={props.production.securityBonusPercent[key]}
            />

            <Row
                icon={IconHelper.Categories.Summary}
                description='Total'
                bonus={props.production.totalBonusesPercent[key]}
                isTotal
            />
        </Table>
    </SubPanel>
};