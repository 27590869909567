import * as React from 'react';
import { ActivePolitics, PresidentialTermWithWinningManifesto } from '../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../ApplicationState/ContextRoot';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { ButtonLink } from '../../Components/Base/Buttons/ButtonLink';
import { HelpButton } from '../../Components/Base/Buttons/HelpButton';
import { ViewButton } from '../../Components/Base/Buttons/ViewButton';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { politics_terms, politics_terms_detail } from '../../Navigation/Routing/Politics';
import { SimpleBaseView } from '../BaseView';
import { ActivePoliticsComponent } from './ActivePoliticsComponent';
import { CantVoteMessage } from './Components/CantVoteMessage';
import { NotVotedMessage } from './Components/NotVotedMessage';
import { OpenPolicyVote } from './OpenPolicyVote';

export const PoliticsIndex = () => {

    const apiState = React.useContext(ApiStateContext);

    const [activePolitics, setActivePolitics] = React.useState<ActivePolitics | undefined>(undefined);
    const [openForVotingTerm, setOpenForVotingTerm] = React.useState<PresidentialTermWithWinningManifesto | undefined>(undefined);

    React.useEffect(() => {
        if (openForVotingTerm === undefined) {
            apiState.PoliticsClient.getTermOpenForVoting().then(setOpenForVotingTerm);
        }
    }, [openForVotingTerm]);

    React.useEffect(() => {
        if (activePolitics === undefined) {
            apiState.PoliticsClient.getActive().then(activePolitics => {
                setActivePolitics(activePolitics);
            });
        }
    }, [activePolitics]);

    if (!activePolitics) {
        return <LoadingSpinner />;
    }

    const currentVotingTerm = openForVotingTerm;
    const currentTerm = activePolitics.activePresident ? activePolitics.activePresident.presidentialTerm : undefined;

    const currentVotingPolicy = activePolitics.policyOpenForVoting;

    return <SimpleBaseView
        heading={{ text: "Galactic Council", icon: IconHelper.Politics.Politics }}
        headingContent={
            <ButtonHolder>
                <ButtonLink to={politics_terms} text={"All Terms"} icon={IconHelper.Politics.Terms} />
                <HelpButton to={"politics"} />
            </ButtonHolder>}
    >
        <CantVoteMessage />
        <NotVotedMessage presidentialTermId={currentVotingTerm?.presidentialTermId ?? 0} />
        {activePolitics && <ActivePoliticsComponent activePolitics={activePolitics} />}
        <SplitLayout
            isLeftEmpty={!currentVotingPolicy}
            isRightEmpty={!currentVotingTerm}
            left={currentVotingPolicy &&
                <SubPanel heading="Open Policy Vote">
                    <OpenPolicyVote policy={currentVotingPolicy} reloadCallback={() => setActivePolitics(undefined)} />
                </SubPanel>
            }
            right={!!currentVotingTerm &&
                <SubPanel heading="Next Presidential Vote"
                    footerContent={
                        <ButtonHolder isPulledRight>
                            <ButtonLink icon={IconHelper.General.Edit} to={politics_terms_detail(currentVotingTerm.presidentialTermId) + "?submitManifesto=true"} text="Subit Manifesto" />
                            <ViewButton to={politics_terms_detail(currentVotingTerm.presidentialTermId)} />
                        </ButtonHolder>}>
                    There is an upcoming term which can be voted on. Votes and manifestos must be submitted before {ValueFormatter.formatShortDate(currentVotingTerm.startDate)}.
                </SubPanel>
            }
        />
    </SimpleBaseView >;
};