import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IClientSettings, TypeData } from '../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext, DescriptionStateContext } from '../../ApplicationState/ContextRoot';
import { doesUrlExist } from "../../Helpers/UrlHelper";
import { PlayAudioFromUrlButton } from "../Base/Buttons/PlayAudioFromUrlButton";
import { Paragraph } from '../Base/Text/Paragraph';

type TypeDataType = "MissionType" |
    "InstallationType" |
    "ItemType" |
    "AgentActionType" |
    "ShipType" |
    "SchematicType" |
    "FactionType" |
    "CelestialType" |
    "SectorType" |
    "PolicyType" |
    "HelpType";

type Props<TTypeData extends TypeData> = {
    typeData: TTypeData,
    type: TypeDataType,
    clientSettings?: IClientSettings | undefined
}

export const TypeDataVoice = observer(<TTypeData extends TypeData>(props: Props<TTypeData>) => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);
    const descriptionState = React.useContext(DescriptionStateContext);

    function findFileName(type: TypeDataType, typeData: TTypeData) {

        if ("voiceFileName" in typeData) {
            return typeData.voiceFileName as string;
        }

        switch (type) {
            case 'MissionType':
                return descriptionState.Missions?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'InstallationType':
                return descriptionState.Installations?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'ItemType':
                return descriptionState.Items?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'AgentActionType':
                return descriptionState.AgentActions?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'ShipType':
                return descriptionState.Ships?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'SchematicType':
                return descriptionState.Schematics?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'FactionType':
                return descriptionState.Factions?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'CelestialType':
                return descriptionState.Celestials?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'SectorType':
                return descriptionState.Sectors?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
            case 'PolicyType':
                return descriptionState.Policies?.find(x => x.typeName === typeData.typeName)?.voiceFileName;
        }

        return undefined;
    }

    const [url, setUrl] = React.useState<string | undefined>(undefined);
    const [urlExists, setUrlExists] = React.useState<boolean | undefined>(undefined);
    const [triedToCreate, setTriedToCreate] = React.useState<boolean>(false);

    const [voiceFileName, setVoiceFileName] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {

        const f = findFileName(props.type, props.typeData);

        setVoiceFileName(f);

    }, [props.type, props.typeData.typeName]);

    React.useEffect(() => {
        setUrl(undefined);
    }, [props.typeData, voiceFileName]);

    React.useEffect(() => {

        if (voiceFileName === undefined) {
            return;
        }

        const clientSettings = props.clientSettings ? props.clientSettings : appState.ClientSettings;

        if (url === undefined && clientSettings && voiceFileName !== undefined && voiceFileName.length > 0) {

            const url = clientSettings.voiceCdnUrl + voiceFileName;

            setUrl(url);
        }
    }, [url, appState.ClientSettings, voiceFileName, props.clientSettings]);

    function checkExists() {
        if (url !== undefined) {
            doesUrlExist(url).then(e => {
                setUrlExists(e);
                return true;
            });
        }

        return false;
    }

    React.useEffect(() => {
        checkExists();
    }, [url]);

    React.useEffect(() => {
        if (url !== undefined && urlExists === false && !triedToCreate) {

            if (appState.JoiningWorldId !== undefined) {
                setTriedToCreate(true);
                apiState.VoiceClient.createVoiceUrlForWorld(props.type, props.typeData.typeName, appState.JoiningWorldId).then(_ => {
                    setUrlExists(undefined);
                });
            }
            else if (appState.IsLoggedIn) {
                setTriedToCreate(true);
                apiState.VoiceClient.createVoiceUrl(props.type, props.typeData.typeName).then(_ => {
                    setUrlExists(undefined);
                });
            }
        }
    }, [url, urlExists, appState.JoiningWorldId, appState.IsLoggedIn]);

    React.useEffect(() => {
        if (triedToCreate && !urlExists) {
            const timeoutId = setInterval(() => {
                if (checkExists()) {
                    clearInterval(timeoutId);
                }
            }, 2000);

            return () => clearInterval(timeoutId);
        }
    }, [triedToCreate])

    if (voiceFileName === undefined) {
        return null;
    }

    if (voiceFileName.length === 0) {
        return <Paragraph className="is-pulled-right" type="prompt">No audio available</Paragraph>;
    }

    const clientSettings = props.clientSettings ? props.clientSettings : appState.ClientSettings;

    if (clientSettings === undefined || url === undefined || urlExists !== true) {
        return <Paragraph className="is-pulled-right" type="prompt">Loading audio...</Paragraph>;
    }

    return <PlayAudioFromUrlButton className="is-pulled-right" url={url} description={props.typeData.name} />;
});