import { observer } from "mobx-react-lite";
import * as React from "react";
import { PlayerStateContext, TutorialStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";

export const IntroHelp = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const tutorialState = React.useContext(TutorialStateContext);

    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setShow(playerState.IsNewRegistration || tutorialState.ShowIntroduction);
    }, [playerState.IsNewRegistration, tutorialState.ShowIntroduction]);

    function hide() {
        setShow(false);
        playerState.setNewRegistration(false);
        tutorialState.finishedIntroduction();
    }

    if (!show) {
        return null;
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Welcome to Fusion Shift</p>
            </header>
            <section className="modal-card-body content">
                <Paragraph>Fusion Shift is a massively-multiplayer-online strategy browser game. It keeps going even when you aren't logged in and playing.</Paragraph>
                <Paragraph>You are a Galactician and in command of a fledgling empire. You only control one solar system at the moment, but soon you will claim more and one day may rule the galaxy!</Paragraph>
                <Paragraph>It's best if you log in a few times a day to make sure your galactic empire is progressing well.</Paragraph>
            </section>
            <footer className="modal-card-foot">
                <Button type="action" isPulledRight text="Ok" icon={IconHelper.General.Confirm} action={() => { hide(); return true; }} />
            </footer>
        </div>
    </div>;
});