import { SolarSystemClaimType } from "../../ApplicationState/ApiClient";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class SolarSystemIcons {

    public get Capital() { return MaterialDesignIcons.star; }
    public get Colony() { return MaterialDesignIcons.home; }
    public get Outpost() { return MaterialDesignIcons.towerFire; }
    public get SelectAndGoTo() { return MaterialDesignIcons.arrowRight; }
    public get Automation() { return MaterialDesignIcons.cogOutline; }
    public get AutomationDisabled() { return MaterialDesignIcons.cogOutlineOff; }
    public get LosingControl() { return MaterialDesignIcons.delete; }
    public get SolarSystem() { return MaterialDesignIcons.orbit; }

    public forClaimType(claimType: SolarSystemClaimType | undefined) {

        if (claimType === undefined) {
            return "";
        }

        switch (claimType) {
            case SolarSystemClaimType.Capital:
                return this.Capital;

            case SolarSystemClaimType.Colony:
                return this.Colony;

            case SolarSystemClaimType.Outpost:
                return this.Outpost;
        }
    }
}