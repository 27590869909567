import React from "react";
import { ShipClass } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    shipClass: ShipClass,
    isGiant?: boolean
}

export const ShipClassIcon = (props: Props) => {

    return <Icon title={ShipClass[props.shipClass]} icon={IconHelper.Ships.shipClass(props.shipClass)} isGiant={props.isGiant} />;
};