import React from "react";
import { ShipType } from "../../../ApplicationState/ApiClient";
import { help_shipType } from "../../../Navigation/Routing/Help";
import { ContentWithLabel } from "../../Base/Containers/ContentWithLabel";
import { FlexDiv } from "../../Base/Containers/FlexDiv";
import { ShipTypeLink } from "../Links/ShipTypeLink";
import { SvgImage, SvgImageBaseProps } from "./SvgImage";

type Props = SvgImageBaseProps & {
    shipType: ShipType,
    disableLink?: boolean
}

type LinkProps = {
    shipType: ShipType,
    linkClassName?: string | undefined
}

type WithLabelProps = Props & {
    label?: React.ReactNode | undefined
}

export const ShipTypeImage = (props: Props) => {

    const to = props.disableLink ? undefined : help_shipType(props.shipType.typeName);

    return <SvgImage type="ship" filename={props.shipType.typeName} {...props} to={to} />;
};

export const ShipTypeImageAndLink = (props: LinkProps) => {

    return <FlexDiv isContentVerticallyCentered>
        <ShipTypeImage shipType={props.shipType} size="tiny" />
        <ShipTypeLink shipType={props.shipType} className={props.linkClassName} />
    </FlexDiv>;
};

export const ShipTypeImageWithLabel = (props: WithLabelProps) => {

    return <ContentWithLabel label={props.label !== undefined ? props.label : props.shipType.name}>
        <ShipTypeImage {...props} />
    </ContentWithLabel>;
};