import { observer } from "mobx-react-lite";
import React from "react";
import { CelestialSize, Cost, IInstallationType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { OperationLevelWarningIcon } from "../Icons/OperationLevelWarningIcon";
import { InstallationStatsBuilder, InstallationStatsDisplay } from "./InstallationStatsBuilder";
import { SectorTypeBonusForInstallationList } from "./SectorTypeBonusListForInstallation";

type Props = {
    availableItems?: { [key: string]: number } | undefined,
    factionTypeName: string,
    celestialSize: CelestialSize,
    installationType: IInstallationType,
    level: number,
    operationLevel: number,
    display: InstallationStatsDisplay
    cost?: Cost | undefined
}

export const InstallationTypeStats = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.SectorTypeSettings ||
        !worldState.ShipTypeSettings ||
        !worldState.SchematicTypeSettings ||
        !worldState.FactionTypeSettings ||
        !worldState.ItemTypeSettings ||
        !worldState.GameSettings ||
        !worldState.Effects) {
        return null;
    }

    const builtStats = InstallationStatsBuilder.buildInstallationStats(
        worldState.Effects,
        worldState.ItemTypeSettings,
        worldState.ShipTypeSettings,
        worldState.FactionTypeSettings,
        worldState.SchematicTypeSettings,
        worldState.SectorTypeSettings,

        worldState.GameSettings,
        props.celestialSize,
        props.installationType,
        props.factionTypeName,
        props.level,
        props.operationLevel,
        props.display,
        props.cost,
        props.availableItems
    );

    const sectorType = props.installationType.megacitySectorTypeName in worldState.SectorTypeSettings.data ?
        worldState.SectorTypeSettings.data[props.installationType.megacitySectorTypeName]
        : undefined;

    return <>
        <ul>
            {builtStats.map((s, i) => <li key={i}>
                {!s.isUnlabelled && `${s.label}: `}
                {s.content}
                {s.dependsOnOperationLevel && <OperationLevelWarningIcon {...props} />}
            </li>)}
        </ul>
        {sectorType !== undefined && <>
            <br />
            <SectorTypeBonusForInstallationList
                {...props}
                level={props.display.sectorBonusesForMaxLevel ? 10 : props.level}
                sectorType={sectorType}
                gameSettings={worldState.GameSettings}
            />
        </>}
    </>;
});