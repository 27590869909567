import { observer } from "mobx-react-lite";
import React from "react";
import TagManager from 'react-gtm-module';
import { Helmet } from "react-helmet";
import { ToastContainer } from 'react-toastify';
import "react-virtualized/styles.css";
import { ServiceWorkerStateContext } from ".";
import { AppContent } from "./AppContent";
import { AppStateContext } from "./ApplicationState/ContextRoot";
import { AudioPlayer } from "./Components/Base/AudioPlayer";
import { MessageBoxWithIcon } from "./Components/Base/MessageBox";
import { useOnlineStatus } from "./Components/Base/OnlineStatus";
import { BuildApplicationInsightsTracker } from './Components/FusionShift/ApplicationInsightsTracker';
import { ApiErrorMessage } from "./Components/FusionShift/Messages/ApiErrorMessage";
import { AppErrorMessage } from "./Components/FusionShift/Messages/AppErrorMessage.tsx";
import { UpdateMessage } from "./Components/FusionShift/Messages/UpdateMessage";
import { IconHelper } from "./Helpers/IconHelper";
import { Footer } from "./Navigation/Footer";
import { MobileNavBar } from "./Navigation/MobileMenu/MobileNavBar";
import { NavBar } from "./Navigation/NavBar";
import { CookieWarning } from "./Navigation/Popups/CookieWarning";
import { IntroHelp } from "./Navigation/Popups/IntroHelp";
import { ScrollToTop } from "./Navigation/ScrollToTop";
import { Tour } from "./Navigation/Tutorial/Tour";
import { AuthenticationWrapper } from "./Views/Authentication/AuthenticationWrapper";
import "./sass/style.scss";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
}

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs)
}

const App = observer(() => {

  const appState = React.useContext(AppStateContext);
  const serviceWorkerState = React.useContext(ServiceWorkerStateContext);

  const online = useOnlineStatus();

  const hasUpdate = serviceWorkerState.isUpdateAvailable; // || (appState.ApiVersion && appState.ApiVersion != buildVersion);

  if (process.env.NODE_ENV === 'production') {
    (window as any).dataLayer.push({
      event: 'pageview'
    });
  }

  function appContent() {

    return <>
      {appState.UserPrefs.isCookieConsentGiven() &&
        <Helmet>
          <script src="https://js.stripe.com/v3/"></script>
        </Helmet>
      }
      <NavBar />
      <div className="system-messages">
        {!online &&
          <MessageBoxWithIcon type="warning" icon={IconHelper.General.Offline} text={"You are offline. Fusion Shift requires an internet connection to play."} />
        }
        <ApiErrorMessage />
        <AppErrorMessage />
      </div>
      <AppContent />
    </>;
  }

  function updateContent() {
    return <AuthenticationWrapper>
      <UpdateMessage />
    </AuthenticationWrapper>;
  }

  if (!serviceWorkerState.isUpdateAvailable) {
    appState.initialLoad();
  }

  const ApplicationInsightsTracker = BuildApplicationInsightsTracker();

  return (
    <>
      <ApplicationInsightsTracker>
        <ScrollToTop />
        <div className="container site-content">
          {!hasUpdate && appContent()}
          {hasUpdate && updateContent()}
        </div>
      </ApplicationInsightsTracker>
      {!hasUpdate &&
        <MobileNavBar />
      }
      <Footer />
      <IntroHelp />
      <CookieWarning />
      <AudioPlayer />
      <Tour />
      <ToastContainer />
      <div id="modal-root"></div>
    </>
  );
});

export default App;
