import * as React from "react";
import { ShipType } from "../../../ApplicationState/ApiClient";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Table } from "../../Base/Containers/Table";

type Props = {
    shipType: ShipType
}

export const ShipTypeCombatStats = (props: Props) => {

    return <Table isFullWidth isNarrow heading={
        <>
            <td>Att</td>
            <td>Def</td>
            <td>Speed</td>
            <td>Cargo</td>
        </>
    }>
        <tr>
            <td>{ValueFormatter.formatLocaleNumber(props.shipType.attack)}</td>
            <td> {ValueFormatter.formatLocaleNumber(props.shipType.defence)}</td>
            <td>{ValueFormatter.formatSpeed(props.shipType.solarSystemsPerHour)}</td>
            <td>{ValueFormatter.formatLocaleNumber(props.shipType.cargoCapacity)}</td>
        </tr>
    </Table>;
}