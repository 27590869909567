import classNames from "classnames";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    type: "tick" | "cross" | boolean,
    className?: string,
    isGiant?: boolean,
    isCentered?: boolean
}

export const TickOrCrossIcon = (props: Props) => {

    const isTick = props.type === "tick" || (typeof props.type === "boolean" && props.type === true);

    const className = classNames(
        props.className,
        isTick ? "has-text-success" : "has-text-danger",
        props.isCentered && "ml-auto");

    return <Icon
        icon={isTick ? IconHelper.General.Confirm : IconHelper.General.Close}
        className={className}
        {...props}
    />;
};