import { CelestialFeatureIcon } from '../../../../../Components/FusionShift/Icons/CelestialFeatureIcon';
import { DateIcon } from '../../../../../Components/FusionShift/Icons/DateIcon';
import { ShipTypeLink } from '../../../../../Components/FusionShift/Links/ShipTypeLink';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { SummaryTile } from './SummaryTile';
import { SummaryTileProps, Summary_MaximumLines } from './SummaryTileProps';

export const ShipsTile = (props: SummaryTileProps) => {

    const ships = props.solarSystem.shipBuildQueueByCelestial && props.celestial.celestialId in props.solarSystem.shipBuildQueueByCelestial ?
        props.solarSystem.shipBuildQueueByCelestial[props.celestial.celestialId].map(s => {
            return {
                buildQueueItem: s,
                shipType: props.shipTypeSettings.data[s.typeName]
            }
        }) : [];

    const toRender = ships.filter((s, i) => i < Summary_MaximumLines - 1);
    const toSum = ships.filter((s, i) => i >= Summary_MaximumLines - 1);

    let sum = 0;
    let greatest: Date | undefined = undefined;

    for (let bqi of toSum) {
        sum += bqi.buildQueueItem.quantity;
        if (greatest === undefined || greatest < bqi.buildQueueItem.allCompletedDate) {
            greatest = bqi.buildQueueItem.allCompletedDate;
        }
    }

    const summary = sum > 0 && <li>
        {sum} more in queue {greatest !== undefined &&
            <>
                to be completed in
                <DateIcon date={greatest} ticksDown />
            </>}
    </li>;

    const canBeBuilt = props.celestial.shipTypeNamesThatCanBeBuilt ? Object.keys(props.celestial.shipTypeNamesThatCanBeBuilt).length : 0;
    const renderedRows = toRender.length + (toSum.length > 0 ? 1 : 0);

    return <SummaryTile
        {...props}
        buttonText="Build"
        buttonIcon={IconHelper.Ships.Build}
        title="Ship Construction"
        feature="Ships"
        thumbnail={<CelestialFeatureIcon celestialFeature={"Ships"} isGiant />}
    >
        <ul>
            {toRender.map(q => {
                return <li key={q.buildQueueItem.shipBuildQueueItemId}>
                    {`x${q.buildQueueItem.quantity}`}
                    <ShipTypeLink shipType={q.shipType} />
                    <DateIcon date={q.buildQueueItem.allCompletedDate} ticksDown />
                </li>
            })}
            {summary}
            {renderedRows < Summary_MaximumLines && canBeBuilt > 0 &&
                <li>
                    {canBeBuilt} ship types can be built here
                </li>}
        </ul>
    </SummaryTile>;
};