import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

export const discordLinkUrl = "https://discord.gg/qMdrPERwt8";

type Props = {
    text?: string,
    showIcon?: boolean
}

export const DiscordLink = (props: Props) => {

    const text = props.text ?? "Discord";

    return <a href={discordLinkUrl} target="_blank" rel="noopener noreferrer">
        {props.showIcon && <Icon icon={IconHelper.General.OpenInNewWindow} />}
        {text}
    </a >;
}