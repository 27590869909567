import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { FederationStatus, IFederationDetail } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { BackNavButton } from "../../Components/Base/Buttons/BackButton";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { InfoMessageBox, MessageBoxWithIcon } from "../../Components/Base/MessageBox";
import { MarkdownControl } from "../../Components/Base/Text/MarkdownControl";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { FederationLink } from "../../Components/FusionShift/Links/FederationLink";
import { DefeatMessage } from "../../Components/FusionShift/Messages/DefeatMessage";
import { FederationHelper } from "../../Helpers/FederationHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { federations } from "../../Navigation/Routing/Federation";
import { messages_send } from "../../Navigation/Routing/NotificationsEtc";
import { AccoladeList } from "../Accolades/AccoladesList";
import { SimpleBaseView } from "../BaseView";
import { FederationHeading } from "./FederationHeading";
import { FederationStats } from "./FederationStats";
import { DefeatedMemberList } from "./Players/DefeatedMembersList";
import { InviteList } from "./Players/InviteList";
import { LeadershipList } from "./Players/LeadershipList";
import { MemberList } from "./Players/MembersList";


export const FederationView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const { federationId } = useParams();

    const [federation, setFederation] = React.useState<IFederationDetail | undefined>(undefined);

    React.useEffect(() => {
        apiState.FederationClient.get(Number(federationId)).then(federation => {
            if (federation) {
                setFederation(federation);
            }
        });
    }, [federationId]);

    function disband() {
        return apiState.FederationClient.disband(Number(federationId)).then(setFederation);
    }

    function submitBio(markdown: string) {
        return apiState.FederationClient.setPublicBio(federation!.federationId, markdown).then(newFederation => {
            if (newFederation) {
                setFederation(newFederation);
            }
            return newFederation !== undefined;
        });
    }

    if (!federation || !playerState.Player || !worldState.GameSettings) {
        return <LoadingSpinner />;
    }


    const isLeaderOrOfficer = playerState.Player && FederationHelper.isLeaderOrOfficer(playerState.Player.federation, federation);
    const canDisband = federation.players.length === 1 && federation.players.find(x => x.playerId == playerState.Player?.playerId) !== undefined;

    const leadership = [federation.leader, ...federation.officers]
        .filter(x => x !== undefined && x !== null)
        .map(x => x!.playerId);

    const members = federation.players.filter(x => !leadership.includes(x.playerId));
    const nonDefeated = members.filter(x => !x.isDefeated);
    const defeated = members.filter(x => x.isDefeated);

    return <SimpleBaseView heading={federation.name}
        headingContent={
            <ButtonHolder>
                {isLeaderOrOfficer &&
                    <ButtonLink to={`${messages_send}?toFederation=true`} icon={IconHelper.General.Message} text="Send message to members" />
                }
                {canDisband &&
                    <Button type="danger" icon={IconHelper.Federations.Disband} text="Disband" action={disband} confirm="Are you sure you want to disband the federation? You won't be able to retrieve it later" />
                }
                <HelpButton to="federations" />
                <BackNavButton to={federations} />
            </ButtonHolder>
        }
    >
        {federation.status === FederationStatus.Defeated && <DefeatMessage text="Federation has been defeated" />}
        {federation.status === FederationStatus.Disbanded && <InfoMessageBox text="Federation has been disbanded" />}
        {federation.status === FederationStatus.Victorious && <MessageBoxWithIcon
            type="success"
            icon={IconHelper.General.Victory}
            text={<Paragraph type="unstyled">
                <FederationLink federation={worldState.VictoriousFederation} /> have been declared rulers of the galaxy! All hail  <FederationLink federation={worldState.VictoriousFederation} />!
            </Paragraph>}
        />}

        <FederationHeading federation={federation} />
        <MarkdownControl title="Public Bio" markdown={federation.publicBio} showEdit={isLeaderOrOfficer} hideIfEmpty={!isLeaderOrOfficer} submitCallback={submitBio} />
        <FederationStats federation={federation} world={worldState.World!} />
        <InviteList
            federation={federation}
            reloadCallback={setFederation}
        />
        <LeadershipList
            federation={federation}
            observingPlayer={playerState.Player}
            gameSettings={worldState.GameSettings}
            reloadCallback={setFederation}
        />
        {nonDefeated.length > 0 &&
            <MemberList
                players={nonDefeated}
                federation={federation}
                observingPlayer={playerState.Player}
                gameSettings={worldState.GameSettings}
                reloadCallback={setFederation}
            />
        }
        {defeated.length > 0 &&
            <DefeatedMemberList
                players={defeated}
                federation={federation}
                observingPlayer={playerState.Player}
                gameSettings={worldState.GameSettings}
                reloadCallback={setFederation}
            />
        }
        <AccoladeList accolades={federation.accolades} />
    </SimpleBaseView>;
});