import * as React from 'react';
import { PlayerCreditTransaction } from '../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../ApplicationState/ContextRoot';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Table } from '../../Components/Base/Containers/Table';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { PlayerLink } from '../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';

export const TransactionList = () => {

    const apiState = React.useContext(ApiStateContext);

    const [transactions, setTransactions] = React.useState<PlayerCreditTransaction[] | undefined>(undefined);

    React.useEffect(() => {

        if (transactions === undefined) {
            apiState.ContractsClient.creditTransactions().then(x => {
                setTransactions(x);
            });
        }
    }, []);

    return <SubPanel heading={{ text: "Transactions", icon: IconHelper.Economy.CreditTransaction }} isUnpadded={transactions !== undefined && transactions.length > 0}>
        {transactions === undefined && <LoadingSpinner />}
        {transactions !== undefined && transactions.length === 0 && <Paragraph>No transactions</Paragraph>}
        {transactions !== undefined && transactions.length > 0 && <Table
            isFullWidth
            heading={<>
                <th></th>
                <th></th>
                <th></th>
                <th>Income</th>
                <th>Amount</th>
                <th>Balance</th>
            </>}>
            {transactions.map(x => <React.Fragment key={x.playerCreditTransactionId}>
                <tr className="is-hidden-tablet">
                    <td colSpan={6}>
                        {ValueFormatter.formatTimeOrDate(x.date)}<br />
                        {x.reason}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className='is-hidden-tablet' />
                    <td className="is-hidden-mobile">
                        {ValueFormatter.formatTimeOrDate(x.date)}
                    </td>
                    <td className="is-hidden-mobile">
                        {x.reason}
                    </td>
                    <td>
                        {x.otherPlayer !== undefined && <PlayerLink player={x.otherPlayer} />}
                    </td>
                    <td>
                        {ValueFormatter.formatLocaleNumber(x.incomeConsolidation, 2)}
                    </td>
                    <td className={x.value < 0 ? "has-text-danger" : "has-text-success"}>
                        {ValueFormatter.formatLocaleNumber(x.value, 2)}
                    </td>
                    <td>
                        {ValueFormatter.formatLocaleNumber(x.newBalance, 2)}
                    </td>
                </tr>
            </React.Fragment>)}
        </Table>}
    </SubPanel>;
};