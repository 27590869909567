import { observer } from "mobx-react-lite";
import React from "react";
import { IItemTypeSettings, ItemCategory, ItemType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { ItemsHelper } from "../../../Helpers/ItemsHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { SubPanel } from "../../Base/Containers/SubPanel";
import { Table } from "../../Base/Containers/Table";
import { ProgressBar } from "../../Base/ProgressBar";
import { CommoditiesIcon } from "../Icons/Items/CommoditiesIcon";
import { ComponentIcon } from "../Icons/Items/ComponentIcon";
import { ItemCategoryIcon } from "../Icons/Items/ItemCategoryIcon";
import { RawResourceIcon } from "../Icons/Items/RawResourceIcon";
import { ItemTypeLink } from "../Links/Items/ItemTypeLink";

type InnerProps = {
    heading: string,
    items: { quantity: number, itemType: ItemType }[],
    production?: { [key: string]: number } | undefined,
    consumption?: { [key: string]: number } | undefined
}

type Props = {
    category?: ItemCategory | undefined,
    items: { [key: string]: number } | undefined,
    production?: { [key: string]: number } | undefined,
    consumption?: { [key: string]: number } | undefined
}

type WithHeadingProps = Props & {
    capacities?: { [key in keyof typeof ItemCategory]?: number; } | undefined
}

const ItemsTableInner = (props: InnerProps) => {

    const keys = props.items.map(r => r.itemType.typeName);

    const showConsumption = CollectionHelper.isAnyQuantityInDictionaryByKeys(props.consumption, keys);
    const showProduction = CollectionHelper.isAnyQuantityInDictionaryByKeys(props.production, keys);

    return <Table isHoverable heading={
        <>
            <td>{props.heading} </td>
            <td>Quantity</td>
            {showProduction && <td>Production</td>}
            {showConsumption && <td>Consumption</td>}
        </>
    }>
        {props.items.map(c => {

            const consumption = showConsumption ? ValueFormatter.formatLocaleNumberFromDictionary(c.itemType.typeName, props.consumption) : 0;

            return <tr key={c.itemType.typeName}>
                <td>
                    <ItemTypeLink itemType={c.itemType} />
                </td>
                <td>
                    {ValueFormatter.formatLocaleNumber(c.quantity)}
                </td>
                {showProduction && <td>{ValueFormatter.formatLocaleNumberFromDictionary(c.itemType.typeName, props.production)}</td>}
                {showConsumption && <td className={consumption !== "0" ? "has-text-danger" : ""}>{consumption}</td>}
            </tr>
        })}
    </Table>;
};

function buildItems(category: ItemCategory | undefined, items: { [key: string]: number } | undefined, itemTypeSettings: IItemTypeSettings) {
    if (!items) {
        return undefined;
    }

    return Object.keys(items)
        .map(r => {
            return {
                itemType: itemTypeSettings.data[r],
                quantity: items[r]
            }
        })
        .filter(r => category === undefined || r.itemType.category === category);
}

export const ItemsTable = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings) {
        return null;
    }

    const items = buildItems(props.category, props.items, worldState.ItemTypeSettings);

    const totalQuantity = CollectionHelper.sumOfDictionary(props.items);

    if (totalQuantity <= 0 || items === undefined || items.length === 0) {
        return null;
    }

    return <ItemsTableInner {...props} items={items} heading={props.category === undefined ? "Item" : ItemCategory[props.category]} />;
});

export const ItemsTableWithHeading = observer((props: WithHeadingProps) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings) {
        return null;
    }

    const capacity = ItemsHelper.nonResourceItemCategoryCapacity(props.category, props.capacities);

    const items = buildItems(props.category, props.items, worldState.ItemTypeSettings);

    const totalQuantity = CollectionHelper.sumOfDictionary(props.items);

    if (totalQuantity <= 0 || items === undefined || items.length === 0) {
        return null;
    }

    const heading = props.category === ItemCategory.Commodity ? { text: "Commodoties", icon: <CommoditiesIcon /> } :
        props.category === ItemCategory.RawResource ? { text: "Raw Resources", icon: <RawResourceIcon /> } :
            { text: "Components", icon: <ComponentIcon /> };

    return <SubPanel heading={heading}>
        {capacity !== undefined && <>
            <ProgressBar
                showMax
                invertThresholds
                value={totalQuantity}
                max={capacity}
                warning={capacity * .9}
                danger={capacity}
                leftIcon={props.category !== undefined ? <ItemCategoryIcon itemCategory={props.category} noText /> : undefined}
            />
            <br />
        </>
        }
        <ItemsTableInner {...props} items={items} heading={props.category === undefined ? "Item" : ItemCategory[props.category]} />
    </SubPanel>;
});