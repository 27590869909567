import * as React from "react";
import { useParams } from "react-router-dom";
import { ValidationResult } from "../../ApplicationState/ApiClient";
import { AppStateContext } from "../../ApplicationState/ContextRoot";
import { ApiState } from "../../ApplicationState/States/ApiState";
import { Button } from "../../Components/Base/Buttons/Button";
import { FormFooter } from "../../Components/Base/Form/FormFooter";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { ErrorMessageBox, SuccessMessageBox } from "../../Components/Base/MessageBox";
import { valueValid } from "../../Entities/Validation";
import { IconHelper } from "../../Helpers/IconHelper";
import { login } from "../../Navigation/Routing/AppRouteValues";
import { SimpleBaseView } from "../BaseView";
import { AuthenticationWrapper } from "./AuthenticationWrapper";

export const ResetPasswordView = () => {

    const { token } = useParams();

    const appState = React.useContext(AppStateContext);
    const apiState = new ApiState(() => token, () => { });

    const [password, setPassword] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [confPassword, setConfPassword] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [validation, setValidation] = React.useState(valueValid(false));
    const [serverHasError, setServerHasError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {
        setValidation(() => validate());
    }, [password.validText, confPassword.validText]);

    function validatePassword(value: string) {
        return apiState.AuthenticationClient.validatePassword(value);
    }

    function validate() {
        if (password.validText && confPassword.validText) {
            if (password.validText !== confPassword.validText) {
                return valueValid(false, "Passwords do not match");
            }
            return valueValid(true);
        }
        return valueValid(false);
    }

    function resetPassword() {
        if (!validation.valid) {
            return;
        }

        setServerHasError(false);

        return apiState.AuthenticationClient.resetPassword(password.validText!).then(result => {
            if (result && result.status === 200) {
                setSuccess(true);
            }
            else {
                setServerHasError(true);
            }
        })
    }

    if (success) {
        return <SuccessMessageBox text="Your password has been set" button={<Button type="action" text="Login" icon={IconHelper.General.Unlocked} action={() => appState.navigate(login)} />} />
    }

    const buttonDisabled = !(password.isValid && confPassword.isValid);

    return <AuthenticationWrapper>
        <SimpleBaseView heading="Reset Password">
            {serverHasError && <ErrorMessageBox text="Password could not be set" />}
            <ValidatingStringInput
                label="Password"
                icon={IconHelper.Account.Password}
                type="password"
                valueChanged={setPassword}
                validation={validatePassword}
            />
            <ValidatingStringInput
                label="Confirm Password"
                icon={IconHelper.Account.Password}
                type="password"
                valueChanged={setConfPassword}
            />
            <FormFooter validation={validation}>
                <Button type="action" text="Set Password" icon={IconHelper.General.Confirm} action={() => resetPassword()} isDisabled={buttonDisabled} isPulledRight />
            </FormFooter>
        </SimpleBaseView>
    </AuthenticationWrapper>;
};