import { Link } from 'react-router-dom';
import { CelestialFeatureIcon } from '../../../../../Components/FusionShift/Icons/CelestialFeatureIcon';
import { DateIcon } from '../../../../../Components/FusionShift/Icons/DateIcon';
import { InstallationBuildQueueItemIcon } from '../../../../../Components/FusionShift/Icons/InstallationBuildQueueItemIcon';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { celestial_installation } from '../../../../../Navigation/Routing/SolarSystem';
import { SummaryTile } from './SummaryTile';
import { SummaryTileProps } from './SummaryTileProps';

export const BuildTile = (props: SummaryTileProps) => {

    const installations = props.celestial.installations ? props.celestial.installations.filter(f => f.installationId !== undefined) : [];
    const allowedInstallations = props.celestial.size;

    const anyUnderConstruction = props.solarSystem.installationBuildQueue !== undefined ? props.solarSystem.installationBuildQueue.filter(d => d.celestialId === props.celestial.celestialId) : [];

    const canBuild = props.celestial.installationTypeNamesThatCanBeBuilt && props.celestial.installationTypeNamesThatCanBeBuilt.length > 0;

    return <SummaryTile
        title="Installation Construction"
        mobileTitle="Construction"
        buttonText="Build"
        buttonIcon={IconHelper.Installations.Build}
        feature="Build"
        thumbnail={<CelestialFeatureIcon celestialFeature={"Build"} isGiant />}
        hideButton={!canBuild}
        {...props}
    >
        <ul>
            <li>Used {installations.length} of {allowedInstallations} installation slots.</li>
            {anyUnderConstruction.map(q => {
                return <li key={q.installationBuildQueueItemId}>
                    <Link to={celestial_installation(props.celestial.celestialId, q.installationId)}>
                        {props.installationTypeSettings.data[q.typeName].typeName}{" "}
                        {`[${q.targetLevel}]`}
                    </Link>
                    <InstallationBuildQueueItemIcon installationBuildQueueItem={q} />
                    <DateIcon date={q.completedDate} ticksDown />
                </li>
            })}
        </ul>
    </SummaryTile>;
};