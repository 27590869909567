import * as React from 'react';
import { FleetMovementType, IFleet } from '../../ApplicationState/ApiClient';
import { FleetHelper } from '../../Helpers/FleetHelper';
import { TextHelper } from '../../Helpers/TextHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { DateIcon } from './Icons/DateIcon';

type Props = {
    fleet: IFleet,
    isCompact?: boolean
}

export const FleetArrivalDescription = (props: Props) => {

    if (!props.fleet.arrivalDate) {
        return null;
    }

    return <DateIcon date={props.fleet.arrivalDate} expiredContent={<FleetExpiredArrivalDescription {...props} />} alwaysCompressed />;
};

export const FleetExpiredArrivalDescription = (props: Props) => {

    const miningDate = props.fleet.movementType === FleetMovementType.Mine ? props.fleet.miningFinishDate : undefined;
    const dateText = miningDate === undefined ? undefined : <DateIcon date={miningDate} alwaysCompressed />;
    const efficiency = props.fleet.movementType === FleetMovementType.Mine && props.fleet.miningEfficiency ? props.fleet.miningEfficiency : undefined;

    return <>
        {FleetHelper.arrivedVerb(props.fleet.movementType)}
        {!!dateText && ` `}
        {!!dateText && dateText}
        {!props.isCompact && <>
            {efficiency !== undefined && " "}
            {efficiency !== undefined && <br className="is-hidden-tablet" />}
            {efficiency !== undefined && <span className={`has-keep-all ${TextHelper.classNameForPercentEfficiency(efficiency)}`}>
                {ValueFormatter.fromDecimalToDisplayPercent(efficiency, undefined, 0)} Efficient
            </span>}
        </>
        }
    </>;
};