import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../../ApplicationState/ContextRoot';
import { BottomMobileBar } from './BottomMobileBar';
import { OpenButton } from "./OpenButton";
import { OpenMenu } from './OpenMenu';
import { TopMobileBar } from './TopMobileBar';

export const MobileNavBar = observer(() => {

    const appState = React.useContext(AppStateContext);

    if (appState.ApplicationState !== "Ready") {
        return null;
    }

    return <>
        <OpenMenu />
        <div className="nav-holder mobile-navbar is-hidden-tablet">
            <OpenButton />
            <TopMobileBar />
            <BottomMobileBar />
        </div>
    </>;
});