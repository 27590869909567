import { observer } from "mobx-react-lite";
import React from "react";
import { SectorType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { MegacityHelper } from "../../../Helpers/MegacityHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { CreditsIconWithQuantity } from "../Icons/Items/CreditsIconWithQuantity";
import { ItemIconWithQuantity } from "../Icons/Items/ItemIconWithQuantity";
import { SectorTypeIcon } from "../Icons/SectorTypeIcon";

type Props = {
    bonus: number,
    assignedPopulation: number,
    sectorType: SectorType,
    isBrief?: boolean,
    isHorizontalOnMobile?: boolean
}

export const SectorTypeBonusList = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CommodityHelper || !worldState.GameSettings) {
        return null;
    }

    const itemBonuses = props.sectorType.itemProductionBonusesAtMaxLevel ?
        Object.keys(props.sectorType.itemProductionBonusesAtMaxLevel).map(itemTypeName => {
            return {
                item: worldState.ItemTypeSettings!.data[itemTypeName],
                bonus: ValueFormatter.fromDecimalToDisplayPercent(props.sectorType.itemProductionBonusesAtMaxLevel![itemTypeName] * props.bonus)
            }
        }) : undefined;

    function brief(brief: string, verbose: string): string {
        return props.isBrief ? brief : verbose;
    }

    return <ul className={props.isHorizontalOnMobile ? "is-horizontal-on-mobile" : ""}>
        {props.sectorType.generatedCredits > 0 &&
            <li>
                {brief("", "Generates: ")}<CreditsIconWithQuantity quantity={MegacityHelper.calculateGeneratedCredits(worldState.GameSettings, props.sectorType, props.assignedPopulation)} /> {brief("ph", "per hour")}
            </li>}
        {!!itemBonuses &&
            <>
                {itemBonuses.map((b) => <li key={b.item.typeName}>
                    {brief("", "Production Bonus: ")}<ItemIconWithQuantity itemType={b.item} />{b.bonus}
                </li>)}
            </>}
        {props.sectorType.manufacturingBonusAtMaxLevel > 0 &&
            <li>
                {brief("", "Manufacturing Bonus: ")}<SectorTypeIcon sectorTypeName="Manufacturing" />{ValueFormatter.fromDecimalToDisplayPercent(props.sectorType.manufacturingBonusAtMaxLevel * props.bonus)}
            </li>}
        {props.sectorType.militaryBonusAtMaxLevel > 0 &&
            <li>
                {brief("", "Military Bonus: ")}<SectorTypeIcon sectorTypeName="Military" />{ValueFormatter.fromDecimalToDisplayPercent(props.sectorType.militaryBonusAtMaxLevel * props.bonus)}
            </li>}
        {props.sectorType.scienceBonusAtMaxLevel > 0 &&
            <li>
                {brief("", "Science Bonus: ")}<SectorTypeIcon sectorTypeName="Scientific" />{ValueFormatter.fromDecimalToDisplayPercent(props.sectorType.scienceBonusAtMaxLevel * props.bonus)}
            </li>}
    </ul>;
});