import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, ItemCategory, ItemType } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Table } from '../../../../Components/Base/Containers/Table';
import { TextAbbreviator } from '../../../../Components/Base/Text/TextAbbreviator';
import { SendShipsBetweenSystemButtons } from '../../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { SelectSolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';

type Props = {
    solarSystems: SolarSystemWrapper[],
    category: ItemCategory,
    additionalFilter?: string[]
    headerCell?: React.ReactNode
}

type RowProps = {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem: SolarSystemWrapper,
    items: ItemType[]
}

const movementTypes = [
    FleetMovementType.Delivery,
    FleetMovementType.Collection
];

const Row = (props: RowProps) => {

    return <>
        <tr className="is-hidden-tablet">
            <td colSpan={props.items.length + 1}>
                <SelectSolarSystemLink
                    hideActionIcons
                    solarSystem={props.solarSystem.solarSystem}
                />
            </td>
        </tr>
        <tr>
            <td>
                <SelectSolarSystemLink
                    hideActionIcons
                    solarSystem={props.solarSystem.solarSystem}
                    isHiddenMobile
                />
            </td>
            {props.items.map(x => {

                const quantity = props.solarSystem.getItemQuantity(x.typeName);

                return <td key={x.typeName}>
                    {ValueFormatter.formatLocaleNumber(quantity)}
                </td>
            })}
            {props.showSendButtons &&
                <td className="is-double-button">
                    <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem.solarSystem} movementTypes={movementTypes} />
                </td>
            }
        </tr>
    </>;
};

export const ItemCategoryTable = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    const data = React.useMemo(() => {

        if (!worldState.ItemTypeSettings) {
            return undefined;
        }

        const items = Object.values(worldState.ItemTypeSettings.data)
            .filter(x => x.category === props.category && x.typeName !== "Credits")
            .filter(x => props.additionalFilter?.includes(x.typeName) ?? true)
            .sort((a, b) => a.order - b.order);

        const totals: { [key: string]: number } = {};

        for (const item of items) {
            let val = 0;

            for (const solarSystem of props.solarSystems) {
                val += solarSystem.getItemQuantity(item.typeName);
            }

            totals[item.typeName] = val;
        }

        return { items, totals };

    }, [worldState.ItemTypeSettings, props.solarSystems, props.additionalFilter]);

    if (!worldState.ItemTypeSettings || data === undefined) {
        return null;
    }


    return <Table
        isFullWidth
        className="items-summary"
        heading={<>
            <th >
                {props.headerCell !== undefined && props.headerCell}
            </th>
            {data.items.map(x => <th key={x.typeName}>
                <TextAbbreviator text={x.name} />
            </th>)}
            <th />
        </>}
    >
        {
            props.solarSystems.map(s => <Row
                key={s.solarSystem.solarSystemId}
                solarSystem={s}
                items={data.items}
                sourceSolarSystemId={sourceSolarSystemId}
                setSourceSolarSystemId={setSourceSolarSystemId}
                showSendButtons={props.solarSystems.length > 1}
            />)
        }
        <tr className='is-total'>
            <td>Total</td>
            {data.items.map(x => {

                const quantity = data.totals[x.typeName];

                return <td key={x.typeName}>
                    {ValueFormatter.formatLocaleNumber(quantity)}
                </td>
            })}
            <td />
        </tr>
    </Table >;
});