import { IFleet } from '../../../ApplicationState/ApiClient';
import { LocationLink } from './LocationLink';
import { ISolarSystemLink, SolarSystemLink } from './SolarSystemLink';

export type FleetLocationType = "source" | "target";

type Props = {
    fleet: IFleet,
    type: FleetLocationType,
    allowWrap?: boolean
}

type InnerProps = {
    solarSystem: ISolarSystemLink | undefined,
    x: number | undefined,
    y: number | undefined,
    isIntercept: boolean,
    allowWrap?: boolean
}

const FleetLocationLinkInner = (props: InnerProps) => {

    if (props.solarSystem) {
        return <SolarSystemLink solarSystem={props.solarSystem} dontShowAttack allowWrap={props.allowWrap} />
    }

    if (props.x === undefined ||
        props.x === null ||
        props.y === undefined ||
        props.y === null) {
        return <>Unknown</>;
    }

    if (props.isIntercept) {
        return <>Intercept: <LocationLink x={props.x} y={props.y} /></>
    }

    return <LocationLink x={props.x} y={props.y} />;
};

export const FleetLocationLink = (props: Props) => {

    if (props.type === "source") {
        return <FleetLocationLinkInner
            allowWrap={props.allowWrap}
            solarSystem={props.fleet.sourceSolarSystem}
            x={props.fleet.sourceX}
            y={props.fleet.sourceY}
            isIntercept={false} />
    } else if (props.type === "target") {
        return <FleetLocationLinkInner
            allowWrap={props.allowWrap}
            solarSystem={props.fleet.targetSolarSystem}
            x={props.fleet.targetX}
            y={props.fleet.targetY}
            isIntercept={props.fleet.targetFleetId === undefined} />
    }

    return <>Unknown</>;
};
