import * as React from 'react';
import { ShipType } from '../../../ApplicationState/ApiClient';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { Icon } from '../../Base/Icon';
import { UpkeepIcon } from '../Icons/Items/UpkeepIcon';

type Props = {
    shipType: ShipType,
    className?: string | undefined,
    showUpkeep?: boolean | undefined
}

export const ShipTypeShortStats = (props: Props) => {
    return <span className={props.className}>
        <Icon icon={IconHelper.Combat.Attack} /> {ValueFormatter.formatLocaleNumber(props.shipType.attack)} / <Icon icon={IconHelper.Combat.Defence} /> {ValueFormatter.formatLocaleNumber(props.shipType.defence)}
        {props.showUpkeep && <> / <UpkeepIcon quantity={props.shipType.upkeepPerHour} /></>}
    </span>;
}