import { ISolarSystemMapEntry } from "../../../../ApplicationState/ApiClient";
import { CanvasHelper } from "../CanvasHelper";
import { RenderBag } from "../Entities";

const systemColours = [
    "#fff57b",
    "#feae00",
    "#fc9515"
];

export class SolarSystemRenderer {

    public numRendered = 0;
    public renders = 0;

    constructor(public ctx: CanvasRenderingContext2D) {
    }

    public render(render: RenderBag) {

        this.renders++;
        this.numRendered = 0;

        const transform = this.ctx.getTransform();

        for (const solarSystem of Object.values(render.data.map.entries)) {
            this.renderSolarSystem(transform, render, solarSystem);
        }
    }

    private renderSolarSystem(transform: DOMMatrix, render: RenderBag, solarSystem: ISolarSystemMapEntry) {

        if (render.preferences.solarSystemDisplay !== undefined) {
            if (render.preferences.solarSystemDisplay === "unexplored" && solarSystem.isExplored) {
                return;
            }

            if (render.preferences.solarSystemDisplay === "explored" && !solarSystem.isExplored) {
                return;
            }
        }

        const { x, y } = render.perspective.transform(solarSystem.x, solarSystem.y, render.perspective.parsec / 2);

        if (!render.perspective.isParsecInView(transform, x, y, render.bounds)) {
            return;
        }

        if (render.camera.isDragging && render.camera.cameraZoom < 1 && (solarSystem.playerId === null || solarSystem.playerId === undefined)) {
            return;
        }

        this.numRendered++;

        const solarSystemGradient = this.ctx.createRadialGradient(x, y, 0, x, y, render.perspective.parsec * 0.25);
        solarSystemGradient.addColorStop(0, this.systemColour(solarSystem.solarSystemId));
        solarSystemGradient.addColorStop(1, "#00000000");

        CanvasHelper.fillCircle(this.ctx,
            x,
            y,
            (render.perspective.parsec / 2),
            solarSystemGradient
        );
    }

    private systemColour(solarSystemId: number) {
        while (solarSystemId >= systemColours.length) {
            solarSystemId -= systemColours.length;
        }

        return systemColours[solarSystemId];
    }
}