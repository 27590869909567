import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ActivePolitics } from '../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../ApplicationState/ContextRoot';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { NavButton } from '../../Components/Base/Buttons/NavButton';
import { ViewButton } from '../../Components/Base/Buttons/ViewButton';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { QuoteParagraph } from '../../Components/Base/Text/QuoteParagraph';
import { EmblemImage } from '../../Components/FusionShift/Images/EmblemImage';
import { PlayerLink } from '../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { politics_president, politics_terms_detail } from '../../Navigation/Routing/Politics';
import { ActivePoliciesComponent } from './ActivePoliciesComponent';

type Props = {
    activePolitics: ActivePolitics
};

export const ActivePoliticsComponent = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    const currentTerm = props.activePolitics.activePresident ? props.activePolitics.activePresident.presidentialTerm : undefined;

    if (!playerState.Player || !currentTerm) {
        return null;
    }

    const isPresident = currentTerm && playerState.Player && currentTerm.winningPresidentialManifesto && playerState.Player.playerId === currentTerm.winningPresidentialManifesto.playerId;

    const footer = currentTerm || isPresident ? <ButtonHolder isPulledRight>
        {currentTerm && <ViewButton to={politics_terms_detail(currentTerm.presidentialTermId)} />}
        {isPresident && <NavButton to={politics_president} icon={IconHelper.Politics.President} text="President" />}
    </ButtonHolder> : null;

    return <SplitLayout
        isRightEmpty={props.activePolitics.activePolicies.policies.length === 0}
        left={<SubPanel heading={"Sitting President"} footerContent={footer}>
            <EmblemImage filename={props.activePolitics.activePresident!.president.emblemFilename} isInline />
            <Paragraph>
                <PlayerLink player={props.activePolitics.activePresident!.president} /> is the sitting president and the current term will end {ValueFormatter.formatDateFromNowWithMoment(currentTerm.endDate)}.
            </Paragraph>
            {currentTerm.winningPresidentialManifesto !== undefined &&
                <SubPanel heading={"Manifesto"}>
                    <QuoteParagraph isTitle>
                        {currentTerm.winningPresidentialManifesto.title}
                    </QuoteParagraph>
                    <QuoteParagraph>
                        {currentTerm.winningPresidentialManifesto.description}
                    </QuoteParagraph>
                </SubPanel>
            }
        </SubPanel>}
        right={<SubPanel heading="Active Policies" isUnpadded>
            <ActivePoliciesComponent policies={props.activePolitics.activePolicies.policies} />
        </SubPanel>}
    />;
});