import { makeAutoObservable } from "mobx";
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

// Note: later version of Create React App has an updated service worker meaning the below link may not be 100% correct for this app
// https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a

export class ServiceWorkerState {

    serviceWorker: ServiceWorker | null = null;
    isUpdateAvailable = false;
    isUpdating = false;

    constructor() {
        makeAutoObservable(this);
    }

    public registerServiceWorker() {
        console.log("Registering service worker");
        serviceWorkerRegistration.register({
            immediate: true,
            onSuccess: success => {
                this.serviceWorkerChanged("success", success.active, false);
            },
            onUpdate: registration => {
                this.serviceWorkerChanged("registration", registration.waiting, true);
            },
            onWaiting: waiting => {
                this.serviceWorkerChanged("waiting", waiting, true);
            }
        });
    }

    private serviceWorkerChanged(reason: string, serviceWorker: ServiceWorker | null | undefined, isUpdateAvailable: boolean) {
        this.isUpdating = false;
        console.log("Service worker changed: " + reason);

        if (serviceWorker) {
            this.serviceWorker = serviceWorker;
        }
        if (isUpdateAvailable) {
            this.isUpdateAvailable = true;
        }
        // We setup an event listener to automatically reload the page
        // after the Service Worker has been updated, this will trigger
        // on all the open tabs of our application, so that we don't leave
        // any tab in an incosistent state
        if (this.serviceWorker) {
            this.serviceWorker.addEventListener('statechange', (event: any) => {
                if (event && event.target && event.target.state && event.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    }

    public updateAssets() {
        this.isUpdating = true;
        if (this.serviceWorker) {
            // We send the SKIP_WAITING message to tell the Service Worker
            // to update its cache and flush the old one
            console.log("Updating State service worker");
            this.serviceWorker.postMessage({ type: 'SKIP_WAITING' });
            // } else if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
            //     console.log("Updating Navigator service worker");

            //     navigator.serviceWorker.getRegistrations().then(registrations => {
            //         for (let r of registrations) {
            //             this.updateServiceWorker(r.active);
            //         }
            //     });
        }
        else {
            console.log("No service worker to update");
        }
    }

    private updateServiceWorker(serviceWorker: ServiceWorker | null) {
        console.log(serviceWorker);
        if (serviceWorker) {
            serviceWorker.addEventListener('statechange', (event: any) => {
                if (event && event.target && event.target.state && event.target.state === 'activated') {
                    window.location.reload();
                }
            });
            serviceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
    }
}