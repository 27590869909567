import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { InstallationCelestialsView } from '../../Views/Help/CustomViews/InstallationCelestialsView';
import { SchematicsHelpView } from '../../Views/Help/CustomViews/SchematicsHelpView';
import { HelpIndex } from "../../Views/Help/HelpIndex";
import { HelpView } from "../../Views/Help/HelpView";
import { AgentActionTypesView } from '../../Views/Help/TypeViews/AgentActionTypesView';
import { CelestialTypesView } from '../../Views/Help/TypeViews/CelestialTypesView';
import { CommodityTypesView } from '../../Views/Help/TypeViews/CommodityTypesView';
import { FactionTypesView } from '../../Views/Help/TypeViews/Factions/FactionTypesView';
import { InstallationTypesView } from '../../Views/Help/TypeViews/Installations/InstallationTypesView';
import { PolicyTypesView } from '../../Views/Help/TypeViews/PolicyTypesView';
import { SectorTypesView } from '../../Views/Help/TypeViews/SectorTypesView';
import { ShipTypesView } from '../../Views/Help/TypeViews/ShipTypesView';
import { MissionIndex } from "../../Views/Missions/MissionIndex";
import { MissionView } from "../../Views/Missions/MissionView";
import { route } from "./Root";

export const help = "/help";
export function help_page(page: string) {
    return help + "/" + page;
}
export function help_types(types: string) {
    return help_page(types + "Types");
}

export function helpLink(text: string): ReactNode {
    return <Link to={help}>{text}</Link>;
}

export const help_agentActionTypes = help_types("agentActions");
export const help_celestialTypes = help_types("celestial");
export const help_commodityTypes = help_types("commodity");

export const help_factionTypes = help_types("faction");
export const help_installationTypes = help_types("installation");
export const help_sectorTypes = help_types("sector");
export const help_shipTypes = help_types("ship");
export const help_policyTypes = help_types("policy");

export const help_schematics = help_page("schematics");
export const help_installationCelestials = help_page("installationCelestials");

export const missions = "/missions";
export function mission(missionTypeName: string) {
    return missions + "/" + missionTypeName;
}

export function help_installationType(installationTypeName: string) {
    return `${help_installationTypes}?installationTypeName=${installationTypeName}#${installationTypeName}`
}

export function help_shipType(shipTypeName: string) {
    return `${help_shipTypes}?shipTypeName=${shipTypeName}#${shipTypeName}`
}

export const helpRoutes = [

    route(help_agentActionTypes, AgentActionTypesView, "Help - Agent Actions"),
    route(help_celestialTypes, CelestialTypesView, "Help - Celestials"),
    route(help_factionTypes, FactionTypesView, "Help - Factions"),
    route(help_installationTypes, InstallationTypesView, "Help - Installations"),
    route(help_shipTypes, ShipTypesView, "Help - Ships"),
    route(help_sectorTypes, SectorTypesView, "Help - Sectors"),
    route(help_policyTypes, PolicyTypesView, "Help - Policies"),
    route(help_commodityTypes, CommodityTypesView, "Help - Commodities"),

    route(help_schematics, SchematicsHelpView, "Help - Schematics"),
    route(help_installationCelestials, InstallationCelestialsView, "Help - Installation Celestials"),

    route(help_page(":page"), HelpView, "Help"),
    route(help, HelpIndex, "Help"),

    route(mission(":missionTypeName"), MissionView, "Mission"),
    route(missions, MissionIndex, "Missions")
];

export const HelpTypesPages = [
    { to: help_agentActionTypes, title: "Agent Actions" },
    { to: help_celestialTypes, title: "Celestials" },
    { to: help_commodityTypes, title: "Commodities" },
    { to: help_factionTypes, title: "Factions" },
    { to: help_installationTypes, title: "Installations" },
    { to: help_installationCelestials, title: "Installations Celestials" },
    { to: help_policyTypes, title: "Policies" },
    { to: help_schematics, title: "Schematics" },
    { to: help_sectorTypes, title: "Sectors" },
    { to: help_shipTypes, title: "Ships" },
];
