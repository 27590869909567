import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FleetMovementType } from "../../../ApplicationState/ApiClient";
import { AppStateContext, PlayerStateContext, SolarSystemStateContext } from "../../../ApplicationState/ContextRoot";
import { Icon } from "../../../Components/Base/Icon";
import { LoadingSpinnerOnly } from "../../../Components/Base/Loading/LoadingSpinner";
import { SolarSystemClaimTypeIcon } from "../../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon";
import { SolarSystemDangerIcons, SolarSystemForListDangerIcons } from "../../../Components/FusionShift/Icons/SolarSystemDangerIcons";
import { IconHelper } from "../../../Helpers/IconHelper";
import { empire } from "../../Routing/Misc";
import { selected_system } from "../../Routing/SolarSystem";
import { TutorialIds } from "../../Tutorial/TutorialIds";
import { MenuLink, isActiveSubMenu } from "../MenuState";
import { CustomMenu } from "./CustomMenu";

export const SolarSystemMenu = observer(() => {

    const location = useLocation();
    const playerState = React.useContext(PlayerStateContext);
    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [isOpen, setIsOpen] = React.useState(false);

    function setSolarSystem(solarSystemId: number) {
        appState.changeSolarSystemId(solarSystemId);
        setIsOpen(false);
    }

    if (!solarSystemState.SolarSystems || solarSystemState.SolarSystems.length === 0 || !playerState.Player) {
        return null;
    }

    const hasAttack = solarSystemState.SolarSystem &&
        solarSystemState.SolarSystem.fleets &&
        solarSystemState.SolarSystem.fleets?.find(i => i.targetSolarSystem && i.targetSolarSystem.solarSystemId === solarSystemState.SolarSystem!.solarSystemId && i.movementType === FleetMovementType.Attack || i.movementType === FleetMovementType.Recon);

    const hasMultipleSystems = solarSystemState.SolarSystems.length > 1;

    const isSystemViewActive = isActiveSubMenu(location, selected_system);
    const isSubViewAcive = isActiveSubMenu(location, empire);

    const className = classNames(
        "is-arrowless",
        "navbar-link",
        "is-limited", {
        "has-attack": hasAttack
    });

    return <CustomMenu
        className={`solar-system-menu ${isSubViewAcive} ${isSystemViewActive}`}
        canBeOpened={hasMultipleSystems}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        primaryLink={<>
            <MenuLink className={className} to={selected_system} id={TutorialIds.SolarSystemLink}>
                <span className="is-hidden-mobile">
                    {!!solarSystemState.SolarSystem && solarSystemState.SolarSystem.name}
                </span>
                <span className="is-hidden-tablet">
                    System
                </span>
                {solarSystemState.SwitchState === "Loading" && <LoadingSpinnerOnly isSmall />}
                <SolarSystemDangerIcons solarSystem={solarSystemState.SolarSystem} isPulledRight />
            </MenuLink>
            {hasMultipleSystems && solarSystemState.SwitchState !== "Loading" && <Icon
                className="nav-dropdown-icon"
                icon={IconHelper.General.OpenDropdown}
                onClick={() => setIsOpen(!isOpen)}
            />}
        </>
        }>
        {solarSystemState.SolarSystems.map((s, i) => {

            const isSelected = !!solarSystemState.SolarSystem && s.solarSystemId === solarSystemState.SolarSystem.solarSystemId;
            const isActive = isSelected ? "active" : "";

            return <a key={i} className={`navbar-item ${isSelected ? " selected" : ""} ${isActive}`}
                onClick={() => {
                    setSolarSystem(s.solarSystemId);
                }}>
                <SolarSystemClaimTypeIcon solarSystem={s} />
                {s.name}
                <SolarSystemForListDangerIcons solarSystem={s} isPulledRight />
            </a>;
        }
        )}
        <hr className="navbar-divider" />
        <Link className="navbar-item" to={empire} onClick={() => setIsOpen(false)}>Empire Overview</Link>
    </CustomMenu>;
});
