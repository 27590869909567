import * as React from 'react';
import { FleetMovementType, ItemCategory, ItemType } from '../../../ApplicationState/ApiClient';
import { Table } from '../../../Components/Base/Containers/Table';
import { DateText } from '../../../Components/Base/Text/DateText';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { TextAbbreviator } from '../../../Components/Base/Text/TextAbbreviator';
import { SendShipsBetweenSystemButtons } from '../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { EmpireViewProps } from '../EmpireViewProps';
import { Cell } from './Shared';

type RowProps = {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem: SolarSystemWrapper,
    componentItemTypes: ItemType[]
}

const movementTypes = [
    FleetMovementType.Delivery,
    FleetMovementType.Collection
];

const Row = (props: RowProps) => {

    function getRate(itemTypeName: string) {

        if (props.solarSystem.solarSystem.itemsDetail?.netFinal && itemTypeName in props.solarSystem.solarSystem.itemsDetail.netFinal) {
            return props.solarSystem.solarSystem.itemsDetail.netFinal[itemTypeName];
        }

        return 0;
    }

    function getCurrent(itemTypeName: string) {

        if (props.solarSystem.solarSystem.items && itemTypeName in props.solarSystem.solarSystem.items) {
            return props.solarSystem.solarSystem.items[itemTypeName];
        }

        return 0;
    }

    const quantity = props.componentItemTypes.map(x => getCurrent(x.typeName)).reduce((a, b) => a + b);
    const capacity = props.solarSystem.solarSystem.itemsDetail?.otherCapacities.Component ?? 0;
    const net = props.componentItemTypes.map(x => getRate(x.typeName)).reduce((a, b) => a + b);

    const remainingUntilExpiry =
        net && net > 0 ?
            (quantity && capacity ? capacity - quantity : 0) :
            (quantity ? quantity : 0);
    const hours = remainingUntilExpiry > 0 && net ? remainingUntilExpiry / Math.abs(net) : undefined;
    const expiryDate = hours ? new Date() : undefined;

    if (hours && expiryDate) {
        expiryDate.setTime(expiryDate.getTime() + (hours * 60 * 60 * 1000));
    }

    return <tr>
        <td>
            <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
        </td>
        {props.componentItemTypes.map(x => <Cell key={x.typeName} value={getRate(x.typeName)} />)}
        <td>
            {expiryDate &&
                <span className={net !== undefined && net < 0 ? "has-text-danger" : ""}>
                    <DateText date={expiryDate} />
                </span>
            }
            {quantity !== undefined && net !== undefined && quantity <= 0 && net < 0 &&
                <span className="has-text-danger">
                    EMPTY
                </span>
            }
            {quantity !== undefined && quantity >= capacity &&
                <span className="has-text-danger">
                    FULL
                </span>
            }
        </td>
        <td className="is-double-button">
            <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem.solarSystem} movementTypes={movementTypes} />
        </td>
    </tr>;
};

export const Components = (props: EmpireViewProps) => {

    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    const data = React.useMemo(() => {

        const componentItemTypes = Object.values(props.itemTypeSettings.data).filter(x => x.category === ItemCategory.Component)
            .sort((a, b) => a.order - b.order);

        const totals: {
            [key: string]: number
        } = {};

        for (const component of componentItemTypes) {

            let produced = 0;

            const itemTypeName = component.typeName;

            for (const solarSystem of props.solarSystems) {

                const items = solarSystem.solarSystem.itemsDetail?.netFinal;

                if (items && itemTypeName in items) {

                    produced += items[itemTypeName];
                }
            }

            totals[component.typeName] = produced;
        }

        return { totals, componentItemTypes }

    }, [props.solarSystems]);


    return <>
        <Table isHoverable isFullWidth heading={
            <>
                <th>
                </th>
                {data.componentItemTypes.map(x => <th key={x.typeName}>
                    <TextAbbreviator text={x.name} />
                </th>)}
                <th>
                    Capacity Reached
                </th>
                <th></th>
            </>
        }>
            {props.solarSystems.map(s => <Row
                key={s.solarSystem.solarSystemId}
                solarSystem={s}
                componentItemTypes={data.componentItemTypes}
                sourceSolarSystemId={sourceSolarSystemId}
                setSourceSolarSystemId={setSourceSolarSystemId}
                showSendButtons={props.solarSystems.length > 1}
            />)}
            <tr className="is-total">
                <td>Total</td>
                {data.componentItemTypes.map(x => <Cell key={x.typeName} value={data.totals[x.typeName]} />)}
                <td />
                <td />
            </tr>
        </Table>
        <Paragraph type="prompt">
            Showing net production per hour. Does not account for consumption by ship production.
        </Paragraph>
    </>;
};

