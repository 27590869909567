import { PolicyCategory } from "../../ApplicationState/ApiClient";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class PoliticsIcons {

    public get Propose() { return MaterialDesignIcons.vote; }
    public get Vote() { return MaterialDesignIcons.vote; }
    public get RewardOnWeekNumber() { return MaterialDesignIcons.calendar; }
    public get President() { return MaterialDesignIcons.star; }
    public get Politics() { return MaterialDesignIcons.bank; }
    public get Terms() { return MaterialDesignIcons.listCheck; }

    public policyCategory(policyCategory: PolicyCategory): string {

        switch (policyCategory) {
            case PolicyCategory.Governmental:
                return MaterialDesignIcons.bank;

            case PolicyCategory.Constitutional:
                return MaterialDesignIcons.vote;

            case PolicyCategory.Domestic:
                return MaterialDesignIcons.city;

            case PolicyCategory.Economic:
                return MaterialDesignIcons.basket;

            case PolicyCategory.Industrial:
                return MaterialDesignIcons.anvil;

            case PolicyCategory.Military:
                return MaterialDesignIcons.castle;

            case PolicyCategory.Instant:
                return MaterialDesignIcons.star;
        }
    }
}