import { IconHelper } from '../../../Helpers/IconHelper';
import { Button, ButtonAction } from './Button';

type Props = {
    isPulledRight?: boolean,
    className?: string | undefined,
    action: ButtonAction
}

export const DismissButton = (props: Props) => {
    return <Button
        {...props}
        text='Dismiss'
        type="nav"
        icon={IconHelper.General.Close}
    />
};