import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { TextHelper } from '../../../Helpers/TextHelper';
import { MissionWrapper } from '../MissionWrapper';

type Props = {
    mission: MissionWrapper
}

export const Help = observer((props: Props) => {

    const hasHelp = CollectionHelper.isAnyInArray(props.mission.MissionType.relatedHelpLinks);

    if (!hasHelp) {
        return null;
    }

    return <SubPanel heading={{ text: "Related Help", icon: IconHelper.Categories.Help }}>
        <ul>
            {props.mission.MissionType.relatedHelpLinks!.map((h, i) => <li key={i}>
                <Link to={`/help/${h}`} >
                    {TextHelper.tidyText(h)}
                </Link>
            </li>)}
        </ul>
    </SubPanel>;
});