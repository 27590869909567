import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ActivePolitics, PolicyCategory, PolicyDuration } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Checkbox } from '../../../Components/Base/Form/Checkbox';
import { SimpleField } from '../../../Components/Base/Form/FieldHolder';
import { TextAreaInput } from '../../../Components/Base/Form/TextAreaInput';
import { ErrorMessageBox } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';
import { TimeHelper } from '../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { PolicyProposal } from '../ProposeView';

type Props = {
    policy: PolicyProposal | undefined,
    activePolitics: ActivePolitics,
    propose: (useExecutiveOrder: boolean, useDemocraticReformation: boolean, description: string) => any,
    error: string | undefined
}

type SummaryProps = {
    policy: PolicyProposal,
    activePolitics: ActivePolitics,
    useDemocraticReformation: boolean,
    useExecutiveOrder: boolean
}

const Summary = observer((props: SummaryProps) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.GameSettings) {
        return null;
    }

    const isRepeal = props.policy.activePolicy !== undefined;

    const now = new Date();
    const voteEndDate = props.useExecutiveOrder ? now : TimeHelper.addDays(now, worldState.GameSettings.politics.policyVoteLengthDays);
    const policyActiveEndDate = TimeHelper.addDays(voteEndDate, props.policy.policyType.durationDays !== undefined ? props.policy.policyType.durationDays : 0);
    const proposalVerb = !isRepeal ? "Propose" : "Repeal";

    const vote = props.useExecutiveOrder ? `Using your executive order will ${isRepeal ? "repeal" : "enact"} this policy immediately and without a vote` :
        props.useDemocraticReformation ? `Using your democratic reformation will ${isRepeal ? "repeal" : "enact"} this policy immediately and without a vote` :
            `Voting will last ${worldState.GameSettings.politics.policyVoteLengthDays} days, ending at ${ValueFormatter.formatTimeOrDate(voteEndDate)}`;

    const duration = props.policy.policyType.duration === PolicyDuration.Instant ?
        "This policy has an instant effect" :
        props.policy.policyType.duration === PolicyDuration.Indefinite || props.policy.policyType.durationDays === undefined ?
            "This policy will last indefinitely" :
            `This policy will last until ${ValueFormatter.formatTimeOrDate(policyActiveEndDate)}`;

    const ifPassed = props.useExecutiveOrder || props.useDemocraticReformation ? "" : "If passed, ";

    const toBeReplaced = props.activePolitics.activePolicies.policies.find(x => worldState.PolicyTypeSettings!.data[x.policyTypeName].category === props.policy.policyType.category);
    const toBeReplacedText = toBeReplaced !== undefined ? `Active policy "${worldState.PolicyTypeSettings!.data[toBeReplaced.policyTypeName].name}" will be replaced` : undefined;

    return <ul>
        <li>
            {proposalVerb} {props.policy.policyType.name}
        </li>
        <li>{vote}</li>
        {!isRepeal && <li>{ifPassed}{props.useExecutiveOrder || props.useDemocraticReformation ? duration : duration.toLowerCase()}</li>}
        {toBeReplacedText !== undefined && !isRepeal && <li className="has-text-warning">{toBeReplacedText}</li>}
    </ul>;
});

export const ProposeForm = (props: Props) => {

    const [useDemocraticReformation, setUseDemocraticReformation] = React.useState(false);
    const [useExecutiveOrder, setUseExecutiveOrder] = React.useState(false);
    const [description, setDescription] = React.useState("");

    React.useEffect(() => {
        setUseDemocraticReformation(false);
        setUseExecutiveOrder(false);
    }, [props.policy]);

    function propose() {
        return props.propose(useExecutiveOrder, useDemocraticReformation, description);
    }

    if (props.policy === undefined) {
        return null;
    }

    const type = useExecutiveOrder ? "Execute" : "Propose";
    const verb = props.policy.activePolicy !== undefined ? "Repeal" : "Policy";

    const canUseExecutiveOrder = props.activePolitics.activePresident !== undefined &&
        !props.activePolitics.activePresident.presidentialTerm.hasExecutiveOrderBeenUsed &&
        !props.policy.policyType.isVictory;

    const canUseDemocraticReformation = props.activePolitics.activePresident !== undefined &&
        !props.activePolitics.activePresident.presidentialTerm.hasDemocraticReformationBeenUsed &&
        props.policy.activePolicy === undefined &&
        props.policy.policyType.category !== PolicyCategory.Instant;

    return <SubPanel heading="Proposing Policy" footerContent={<ButtonHolder isPulledRight><Button type="action" text={`${type} ${verb}`} icon={IconHelper.General.Confirm} action={propose} /></ButtonHolder>}>
        <SplitLayout
            left={<>
                {canUseExecutiveOrder && !canUseDemocraticReformation &&
                    <Checkbox value={useExecutiveOrder} valueChanged={setUseExecutiveOrder} label="Use Executive Order" />
                }
                {canUseDemocraticReformation &&
                    <Checkbox value={useDemocraticReformation} valueChanged={setUseDemocraticReformation} label="Use Democratic Reformation" />
                }
                <SimpleField name="Describe your reasons for this policy">
                    <TextAreaInput
                        value={description}
                        valueChanged={setDescription}
                        rows={8}
                        characterLimit={1000}
                    />
                </SimpleField>
            </>}
            right={<Summary policy={props.policy!} activePolitics={props.activePolitics} useExecutiveOrder={useExecutiveOrder} useDemocraticReformation={useDemocraticReformation} />}
        />
        {props.error !== undefined && <ErrorMessageBox text={props.error} />}
    </SubPanel>;
};