import { ICelestialDetail, IInstallationTypeSettings } from '../../../../ApplicationState/ApiClient';
import { H4 } from '../../../../Components/Base/Text/H';
import { TextAndIcon } from '../../../../Components/Base/Text/TextAndIcon';
import { CelestialFeatureIcon } from '../../../../Components/FusionShift/Icons/CelestialFeatureIcon';
import { CelestialFeature, InstallationFeature } from '../../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';


type Props = {
    feature: CelestialFeature | undefined,
    celestial: ICelestialDetail,
    installationTypeSettings: IInstallationTypeSettings
};

export const CelestialFeatureTitle = (props: Props) => {

    if (props.feature === undefined) {
        return null;
    }

    function findInstallationName(installationFeature: InstallationFeature) {

        const installation = props.celestial.installations && props.celestial.installations.find(i => i.installationId === installationFeature.installationId);

        if (installation && props.installationTypeSettings && installation.installationTypeName in props.installationTypeSettings.data) {
            return `${props.installationTypeSettings.data[installation.installationTypeName].name} [${installation.level}]`;
        }

        return installationFeature.installationId;
    }

    function featureTitle(feature: CelestialFeature) {
        const text = typeof feature === "string" ? feature : findInstallationName(feature);
        return <TextAndIcon text={text} icon={<CelestialFeatureIcon celestialFeature={feature} />} />;
    }

    const title = props.feature ? featureTitle(props.feature) : <TextAndIcon text="Summary" icon={IconHelper.Celestials.celestialType(props.celestial.celestialTypeName)} />;

    return <H4>{title}</H4>
};