import { observer } from 'mobx-react-lite';
import { FleetMovementType } from '../../../ApplicationState/ApiClient';
import { Breadcrumbs } from '../../../Components/Base/Containers/Breadcrumb';
import { Icon } from '../../../Components/Base/Icon';
import { Steps } from '../../../Components/Base/Steps';
import { HoursIcon } from '../../../Components/FusionShift/Icons/HoursIcon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { DurationSteps, SendShipsHelper } from '../../../Helpers/SendShipsHelper';
import { TimeHelper } from '../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { SendShipsBag } from '../SendShipsBag';

type DateDuration = {
    date: Date,
    durationHours: number
}

type Props = {
    toTarget: DateDuration
    sendShipsBag: SendShipsBag
}

type InnerProps = {
    steps: DurationSteps
}

type RenderProps = {
    movementType: FleetMovementType,
    steps: DurationSteps
}

export const DurationStepsRender = (props: RenderProps) => {

    if (props.steps.length === 0) {
        return null;
    }

    let date = new Date();

    const steps = props.steps.map((x, i) => {

        if (!x || !x.durationHours) {
            return undefined;
        }

        date = TimeHelper.addHours(date, x.durationHours);

        const movementType = i === 0 || (i !== props.steps.length - 1) ? props.movementType : FleetMovementType.ReturnHome;

        return {
            id: x.label,
            title: <Icon icon={IconHelper.Ships.movementType(movementType)} />,
            heading: x.label,
            description: <>
                <HoursIcon quantity={x.durationHours} /> <br />
                {ValueFormatter.formatTimeOrDate(date, false, true, true)}
            </>
        };
    })
        .filter(x => x !== undefined)
        .map(x => x!);

    steps.unshift(
        {
            id: "Departure",
            title: <Icon icon={IconHelper.Ships.Send} />,
            heading: "Departure",
            description: <>
                {ValueFormatter.formatTimeOrDate(new Date(), false, true, true)}
            </>
        });

    return <Steps
        steps={steps}
        currentStep={steps[steps.length - 1]?.id}
        forceAllActive
    />;
}

export const DurationStepsBreadcrumbs = (props: InnerProps) => {

    let date = new Date();

    return <Breadcrumbs>
        {props.steps.map(x => {

            if (!x || !x.durationHours) {
                return null;
            }

            date = TimeHelper.addHours(date, x.durationHours);

            return <span key={x.label}>
                <span className="has-text-weight-bold">{x.label}</span><br />
                <HoursIcon quantity={x.durationHours} /><br />
                {ValueFormatter.formatTimeOrDate(date, false, true, true)}
            </span>;
        })}
    </Breadcrumbs>;
}

export const DurationStepsBreadcrumbsWrapper = observer((props: Props) => {

    const steps = SendShipsHelper.durationSteps(props.sendShipsBag);

    if (!steps) {
        return null;
    }

    return <DurationStepsBreadcrumbs steps={steps} />;
});