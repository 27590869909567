import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { SchematicType } from "../../../ApplicationState/ApiClient";
import { help_schematics } from "../../../Navigation/Routing/Help";

type Props = {
    schematicType?: SchematicType | undefined | false
}

export const SchematicTypeLink = observer((props: Props) => {

    if (!props.schematicType) {
        return null;
    }

    return <Link to={help_schematics}>
        {props.schematicType.name}
    </Link>;
});