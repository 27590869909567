import { IInstallationTypeSettings } from "../../../../ApplicationState/ApiClient";
import { InstallationHelper } from "../../../../Helpers/InstallationHelper";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { militaryProblemBuilder } from "./MilitaryProblems";

export function adminLevelProblems(installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;

    const adminLevel = InstallationHelper.sumOfStatPerLevelForSolarSystem(installationTypeSettings, solarSystem, i => i.solarSystemClaimType !== undefined && i.solarSystemClaimType !== null ? 1 : 0);

    if (adminLevel <= 1) {
        return militaryProblemBuilder.warningProblem("Low Control", `The Bureaucratic Hub, Sovreignty Office or Outpost Office at ${wrapper.solarSystem.name} is of low level and easily destroyed`);
    } else if (adminLevel <= 3) {
        return militaryProblemBuilder.infoProblem("Low Control", `The Bureaucratic Hub, Sovreignty Office or Outpost Office at ${wrapper.solarSystem.name} is of low level and easily destroyed`);
    }

    return undefined;
}