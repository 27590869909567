import React from "react";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type Props = {
    date: Date | undefined | null
}

export const DateText = (props: Props) => {

    if (!props.date) {
        return null;
    }

    return <span title={`${props.date.toTimeString()} ${props.date.toDateString()}`}>
        {ValueFormatter.formatDateFromNowWithMoment(props.date)}
    </span>;
};