import { ContractType, IContract } from '../../../ApplicationState/ApiClient';
import { CreditsIconWithQuantity } from '../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { ContractHelper } from '../../../Helpers/ContractHelper';

type Props = {
    contract: IContract,
    fleetWrapper: FleetWrapper
}

export const ContractPayout = (props: Props) => {

    function getAmount() {
        if ("payment" in props.contract.terms) {

            return Number(props.contract.terms.payment);
        }

        if (ContractHelper.isDefendTerms(props.contract.terms)) {
            return props.contract.terms.paymentPerDefencePower * props.fleetWrapper.TotalDefence;
        }

        if (ContractHelper.isCourierTerms(props.contract.terms)) {
            return props.contract.terms.paymentPerUnit * props.fleetWrapper.TotalCargoCapacity;
        }

        return 0;
    }

    const amount = getAmount();

    const amountToUse = Math.min(props.contract.remainingCredits, amount);

    const hideAvailable = props.contract.type === ContractType.Attack;

    return <>
        Potential payout: <CreditsIconWithQuantity className={amountToUse < amount ? "has-text-warning" : ""} quantity={amountToUse} /> {!hideAvailable && <>/ <CreditsIconWithQuantity quantity={props.contract.remainingCredits} /> available</>}
    </>;
};