import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { contract_browse, contract_create, contracts } from "../../Routing/Contracts";
import { market, market_browse, market_create, market_orders } from "../../Routing/Market";
import { MenuLink, isActiveSubMenu } from "../MenuState";

type Props = {
    className?: string | undefined
};

export const MarketMenu = observer((props: Props) => {

    const location = useLocation();

    const className = classNames(
        props.className,
        "navbar-item",
        "has-dropdown",
        "is-hoverable",
        isActiveSubMenu(location, market)
    );

    return <div className={className}>
        <a className="navbar-link">
            Market
        </a>
        <div className="navbar-dropdown">
            <MenuLink to={market_orders} className="navbar-item">Your Market Orders</MenuLink>
            <MenuLink to={market_create} className="navbar-item">Create Market Order</MenuLink>
            <MenuLink to={market_browse} className="navbar-item">Browse Market Orders</MenuLink>
            <hr className="navbar-divider" />
            <MenuLink to={contracts} className="navbar-item" end>Your Contracts</MenuLink>
            <MenuLink to={contract_create} className="navbar-item">Create Contract</MenuLink>
            <MenuLink to={contract_browse} className="navbar-item">Browse Contracts</MenuLink>
        </div>
    </div>;
});