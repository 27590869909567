import { Table } from '../../../Components/Base/Containers/Table';
import { EmpireCategoryIcon } from '../../../Components/FusionShift/Icons/EmpireCategoryIcon';
import { ProblemSeverityIcon } from '../../../Components/FusionShift/Icons/ProblemSeverityIcon';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { ProblemSolutionLink } from '../../../Components/FusionShift/ProblemSolutionLink';
import { ProblemCategory } from '../../../Entities/SolarSystem/Problems/Problem';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';

type Props = {
    solarSystems: SolarSystemWrapper[]
}


type RowProps = {
    solarSystem: SolarSystemWrapper
}

const ProblemsRows = (props: RowProps) => {

    const problems = props.solarSystem.problems.sort((a, b) => a.category - b.category);

    if (problems.length === 0) {
        return null;
    }

    let category: ProblemCategory | undefined = undefined;

    return <>
        {problems.map((p, i) => {

            const categoryToUse = p.category !== category ? p.category : undefined;
            category = p.category;

            return <tr key={i} >
                <td>
                    {i === 0 && <>
                        <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
                    </>}
                </td>
                <td>
                    {!!categoryToUse && <span><EmpireCategoryIcon category={categoryToUse} /> {ProblemCategory[categoryToUse]}</span>}
                </td>
                <td>
                    <ProblemSeverityIcon severity={p.severity} />
                    {p.title}
                </td>
                <td>
                    {p.description}
                </td>
                <td>
                    <ProblemSolutionLink solution={p.solution} />
                </td>
            </tr>
        })}
    </>;
};

export const AdvisorView = (props: Props) => {

    return <Table isFullWidth>
        {props.solarSystems.map(s => <ProblemsRows key={s.solarSystem.solarSystemId} solarSystem={s} />)}
    </Table>;
};