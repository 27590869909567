import { AutomationLogs, IInstallationTypeSettings, IShipTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SplitLayout } from "../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { Paragraph } from "../../../../Components/Base/Text/Paragraph";
import { InstallationTypeLink } from "../../../../Components/FusionShift/Links/InstallationTypeLink";
import { ShipTypeLink } from "../../../../Components/FusionShift/Links/ShipTypeLink";
import { CollectionHelper } from "../../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";

type Props = {
    automationLogs: AutomationLogs,
    shipTypeSettings: IShipTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    lastDate: Date | undefined
}

export const AutomationLogsRender = (props: Props) => {

    if (props.lastDate === undefined) {
        return null;
    }

    const ships = props.automationLogs.ships.map(x => {
        return {
            log: x,
            shipType: props.shipTypeSettings.data[x.shipTypeName]
        }
    });

    const installations = props.automationLogs.installations.map(x => {
        return {
            log: x,
            installationType: props.installationTypeSettings.data[x.installationTypeName]
        }
    });

    return <SubPanel heading={"Logs"}>
        <Paragraph>
            Automation was last processed {ValueFormatter.formatDateFromNowWithMoment(props.lastDate)}.
        </Paragraph>
        {CollectionHelper.isAnyInArray(props.automationLogs.lastProcessLogs) &&
            <ul>
                {props.automationLogs.lastProcessLogs!.map(x => <li key={x}>{x}</li>)}
            </ul>}
        {(ships.length > 0 || installations.length > 0) &&
            <>
                <br />
                <Paragraph>
                    Recently queued items:
                </Paragraph>
                <SplitLayout
                    isLeftEmpty={installations.length === 0}
                    isRightEmpty={ships.length === 0}
                    left={installations.length > 0 &&
                        <SubPanel heading={{ text: "Installations", icon: IconHelper.Installations.Installation }}>
                            <ul>
                                {installations.map((x, i) => <li key={i}>
                                    <InstallationTypeLink installationType={x.installationType} /> [{x.log.level}] - {ValueFormatter.formatDateFromNowWithMoment(x.log.date)}
                                </li>)}
                            </ul>
                        </SubPanel>}
                    right={ships.length > 0 &&
                        <SubPanel heading={{ text: "Ships", icon: IconHelper.Ships.Ship }}>
                            <ul>
                                {ships.map((x, i) => <li key={i}>
                                    <ShipTypeLink shipType={x.shipType} /> x{x.log.quantity} - {ValueFormatter.formatDateFromNowWithMoment(x.log.date)}
                                </li>)}
                            </ul>
                        </SubPanel>}
                />
            </>}
    </SubPanel>;
}