import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ShipClass } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { FlexDiv } from '../../../Components/Base/Containers/FlexDiv';
import { InstallationTypeImageWithLabel } from '../../../Components/FusionShift/Images/InstallationTypeImage';
import { ShipTypeImageWithLabel } from '../../../Components/FusionShift/Images/ShipTypeImage';
import { MissionWrapper } from '../MissionWrapper';

type Props = {
    mission: MissionWrapper
}

export const Images = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (!props.mission.HasObjectives || !playerState.Player || !worldState.InstallationTypeSettings || !worldState.ShipTypeSettings) {
        return null;
    }

    function shipBuild(shipClass: ShipClass) {
        const ship = Object.values(worldState.ShipTypeSettings!.data).find(x => x.class === shipClass && x.factionTypeName === playerState.Player!.factionTypeName);

        if (ship === undefined) {
            return null;
        }

        return <ShipTypeImageWithLabel shipType={ship} />;
    }

    const content = [

        props.mission.MissionType.installationRequired !== undefined && <InstallationTypeImageWithLabel installationType={worldState.InstallationTypeSettings!.data[props.mission.MissionType.installationRequired.installationTypeName]} />,
        props.mission.MissionType.shipBuildRequired !== undefined && shipBuild(props.mission.MissionType.shipBuildRequired.shipClass)

    ].filter(x => x !== false && x !== undefined)
        .map(x => x!);

    if (content.length === 0) {
        return null;
    }

    return <FlexDiv isContentHorizontallyCentered>
        {content}
    </FlexDiv>;
});