export const hypeImageConfig = {
  "Agents": 5,
  "Build": 4,
  "Conquer": 8,
  "Cooperate": 4,
  "Expand": 4,
  "Explore": 14,
  "Grow": 1,
  "Mine": 6,
  "Politics": 3,
  "Produce": 3,
  "Raid": 1,
  "Scout": 3,
  "Trade": 6
}