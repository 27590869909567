import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  UnreadStateContext
} from "../../../ApplicationState/ContextRoot";
import { NewIcon } from "../../../Components/Base/Icon";
import { federations } from "../../Routing/Federation";
import { missions } from "../../Routing/Help";
import { changeArchive, combatSimulator, empire, fleets, playerAccolades, victoryProgress } from "../../Routing/Misc";
import { combatReports, messages, playerNotifications } from "../../Routing/NotificationsEtc";
import { players } from "../../Routing/Player";
import { politics_home } from "../../Routing/Politics";
import { MenuLink, isActiveSubMenu } from "../MenuState";
import { FederationMenu } from "./FederationMenu";

type Props = {
  className?: string | undefined
};

export const InfoMenu = observer((props: Props) => {

  const unreadState = React.useContext(UnreadStateContext);
  const location = useLocation();

  const className = classNames(
    props.className,
    "navbar-item",
    "has-dropdown",
    "is-hoverable",
    isActiveSubMenu(location, combatReports, playerNotifications, messages, players, playerAccolades, federations, missions)
  );

  return <div className={className}>
    <a className={`navbar-link ${unreadState.HasUnread ? "unread" : ""}`}>
      {(unreadState.HasUnread) && <NewIcon isHiddenTablet />}
      Info
    </a>
    <div className="navbar-dropdown">
      <MenuLink className="navbar-item" to={empire} >Empire Overview</MenuLink>
      <MenuLink to={fleets} className={`navbar-item`}>
        Fleets
      </MenuLink>
      <hr className="navbar-divider" />
      <MenuLink to={missions} className={`navbar-item ${unreadState.HasUnreadMission ? "unread" : ""}`}>
        {unreadState.HasUnreadMission && <NewIcon isHiddenTablet />}
        Missions
      </MenuLink>
      <MenuLink to={combatReports} className={`navbar-item ${unreadState.HasUnreadCombatReport ? "unread" : ""}`}>
        {unreadState.HasUnreadCombatReport && <NewIcon />}
        Combat Reports
      </MenuLink>
      <MenuLink to={playerNotifications} className={`navbar-item ${unreadState.HasUnreadPlayerNotification ? "unread" : ""}`}>
        {unreadState.HasUnreadPlayerNotification && <NewIcon />}
        Notifications
      </MenuLink>
      <MenuLink to={messages} className={`navbar-item ${unreadState.HasUnreadMessage ? "unread" : ""}`}>
        {unreadState.HasUnreadMessage && <NewIcon />}
        Messages
      </MenuLink>
      <hr className="navbar-divider" />
      <MenuLink to={players} className="navbar-item">
        Players
      </MenuLink>
      <MenuLink to={playerAccolades} className="navbar-item">
        Player Accolades
      </MenuLink>
      <hr className="navbar-divider" />
      <FederationMenu />
      <hr className="navbar-divider" />
      <MenuLink to={politics_home} className="navbar-item">
        {unreadState.HasPendingPolicyVote && <NewIcon />}
        Galactic Council
      </MenuLink>
      <MenuLink to={victoryProgress} className="navbar-item">
        Victory Progress
      </MenuLink>
      <MenuLink to={combatSimulator} className="navbar-item">
        Combat Simulator
      </MenuLink>
      <MenuLink to={changeArchive} className="navbar-item">
        Change Archive
      </MenuLink>
    </div>
  </div>;
});
