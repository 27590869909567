import { ReactNode } from "react";
import { IItemsDetail, IManufactory } from "../../../../../ApplicationState/ApiClient";
import { DictionaryTable } from "../../../../../Components/Base/Containers/Table";
import { Icon } from "../../../../../Components/Base/Icon";
import { ProgressBarPercent } from "../../../../../Components/Base/ProgressBar";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ManufactoryHelper } from "../../../../../Helpers/ManufactoryHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";

type Props = {
    celestialId: number,
    itemsDetail: IItemsDetail | undefined,
    manufactory: IManufactory,
    hidePowerStatus?: boolean
}

export const ManufactoryStats = (props: Props) => {

    const inputEfficiency = ManufactoryHelper.sumNodeEfficiency(props.itemsDetail, props.celestialId, props.manufactory.inputNodes);
    const outputEfficiency = ManufactoryHelper.sumNodeEfficiency(props.itemsDetail, props.celestialId, props.manufactory.outputNodes);
    const overallEfficiency = (inputEfficiency + outputEfficiency) / 2;

    const content: { [key: string]: ReactNode } = {
        "Input": <ProgressBarPercent leftIcon={IconHelper.Manufactories.Input} value={inputEfficiency} />,
        "Output": <ProgressBarPercent leftIcon={IconHelper.Manufactories.Output} value={outputEfficiency} />,
        "Overall": <ProgressBarPercent leftIcon={IconHelper.Categories.Summary} value={overallEfficiency} />
    };

    if (props.manufactory.bonusFromMegacityPercent > 0) {
        content["Bonus"] = `${ValueFormatter.fromDecimalToDisplayPercent(props.manufactory.bonusFromMegacityPercent)} from Megacity`;
    }

    return <DictionaryTable
        isFullWidth
        content={content}
        footer={!props.hidePowerStatus && !props.manufactory.isActive && <Paragraph type={"warning"}>Powered off <Icon icon={IconHelper.General.Off} /></Paragraph>}
    />;
};