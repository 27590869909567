import * as React from 'react';
import { AgentStatus, IAgentDetail } from '../../../ApplicationState/ApiClient';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { handleOnClick } from '../../../Components/Base/Util';
import { AgentPortrait } from '../../../Components/FusionShift/Images/AgentPortrait';
import { AgentLink } from '../../../Components/FusionShift/Links/AgentLink';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { agentById } from '../../../Navigation/Routing/Agents';
import { AgentPositionSummary } from './AgentPositionSummary';

type Props = {
    agents: IAgentDetail[]
}

export const CapturedAgents = (props: Props) => {

    const appState = React.useContext(AppStateContext);
    const agents = props.agents;

    if (agents.length === 0) {
        return null;
    }

    return <SubPanel heading={{ text: "Your Prisoners", icon: IconHelper.Agents.status(AgentStatus.Captured) }} isUnpadded>
        <Table isFixed isFullWidth isClickable>
            {agents.map(x => <tr key={x.agentId} onClick={e => handleOnClick(e, () => appState.navigate(agentById(x.agentId)))}>
                <td>
                    <AgentPortrait agent={x} size='tiny' />
                </td>
                <td className="is-hidden-tablet">
                    <AgentLink agent={x} /><br />
                    <AgentPositionSummary agent={x} />
                </td>
                <td className="is-hidden-mobile">
                    <AgentLink agent={x} />
                </td>
                <td className="is-hidden-mobile">
                    <PlayerLink player={x.player} />
                </td>
                <td className="is-hidden-mobile">
                    <AgentPositionSummary agent={x} />
                </td>
            </tr>)}
        </Table>
    </SubPanel>;
};