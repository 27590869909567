import { observer } from "mobx-react-lite";
import { FleetMovementType, SolarSystemClaimType } from "../../../ApplicationState/ApiClient";
import { ButtonChooser, choice } from "../../../Components/Base/Buttons/ButtonChooser";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { IconHelper } from "../../../Helpers/IconHelper";
import { SendShipsBag } from "../SendShipsBag";

type Props = {
    sendShipsBag: SendShipsBag
}

export const ClaimType = observer((props: Props) => {

    if (props.sendShipsBag.MovementType !== FleetMovementType.Claim) {
        return null;
    }

    const solarSystems = (props.sendShipsBag.init.getAllSolarSystems() ?? []).map(x => x.solarSystem);
    const claimType = props.sendShipsBag.Orders.claimType ?? SolarSystemClaimType.Colony;

    let level = 0;
    for (const s of solarSystems) {
        for (const c of s.celestials) {
            if (c.installations) {
                for (const i of c.installations) {
                    if (i.installationTypeName in props.sendShipsBag.init.installationTypeSettings.data &&
                        props.sendShipsBag.init.installationTypeSettings.data[i.installationTypeName].solarSystemClaimType === SolarSystemClaimType.Capital) {
                        level += i.level;
                    }
                }
            }
        }
    }

    if (level < props.sendShipsBag.init.gameSettings.solarSystem.numberOfCapitalLevelsPerOutpost) {
        return null;
    }

    const choices = [
        choice<SolarSystemClaimType>(SolarSystemClaimType.Colony, "Colony", IconHelper.SolarSystems.Colony),
        choice<SolarSystemClaimType>(SolarSystemClaimType.Outpost, "Outpost", IconHelper.SolarSystems.Outpost)
    ]

    return <SubPanel
        heading={{ text: "Claim Type", icon: undefined }}
    >
        <ButtonChooser
            value={claimType}
            values={choices}
            valueChanged={x => props.sendShipsBag.modifyOrders(o => {
                return {
                    ...o,
                    claimType: x
                }
            })}
        />
    </SubPanel>;
});