import * as React from 'react';
import { ProblemSeverity, severityClassName } from '../../../Entities/SolarSystem/Problems/Problem';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Icon } from '../../Base/Icon';

type Props = {
    severity: ProblemSeverity
}

export const ProblemSeverityIcon = (props: Props) => {
    return <Icon className={severityClassName(props.severity)} icon={IconHelper.Statuses.Error} />;
};