import * as React from 'react';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { ProblemSeverityIcon } from '../../../../../Components/FusionShift/Icons/ProblemSeverityIcon';
import { ProblemSolutionLink } from '../../../../../Components/FusionShift/ProblemSolutionLink';
import { Problem, ProblemCategory } from '../../../../../Entities/SolarSystem/Problems/Problem';

type Props = {
    category?: ProblemCategory | undefined,
    problems: Problem[]
}

export const ProblemTable = (props: Props) => {

    const problems = props.problems.filter(c => c.category === props.category || props.category === undefined);

    if (problems.length === 0) {
        return null;
    }

    return <Table isHoverable isFullWidth numberOfColumns={3} isMobileCollapse>
        {problems.map((p, i) => <tr key={`${p.title}_${i}`}>
            <td className="has-nowrap">
                <ProblemSeverityIcon severity={p.severity} />
                {p.title}
            </td>
            <td>
                {p.description}
            </td>
            <td>
                <ProblemSolutionLink solution={p.solution} />
            </td>
        </tr>)}
    </Table>;
};