import * as React from 'react';
import { IPlayer, ISolarSystem, ITerms } from '../../ApplicationState/ApiClient';

type Props = {
    terms: ITerms,
    sourceSolarSystem?: ISolarSystem,
    targetSolarSystem?: ISolarSystem,
    forPlayer?: IPlayer
}

export const TermsSummary = (props: Props) => {

    // TODO: if input dtos are nullable but the values are set then get them from api

    return <></>;
}