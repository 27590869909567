import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AdminWorld } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../../ApplicationState/ContextRoot";
import { BackNavButton } from "../../../Components/Base/Buttons/BackButton";
import { Level, levelContentWithHeading } from "../../../Components/Base/Containers/Level";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { DateText } from "../../../Components/Base/Text/DateText";
import { admin_worlds } from "../../../Navigation/Routing/Admin";
import { SimpleBaseView } from "../../BaseView";
import { WorldButtons } from "./WorldButtons";
import { WorldTabs } from "./WorldTabs";

export const WorldView = observer(() => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const { worldId } = useParams();

    const [world, setWorld] = React.useState<AdminWorld | undefined>(undefined);

    React.useEffect(() => {
        apiState.AdminClient.getWorldByWorldId(Number(worldId)).then(world => {
            if (world) {
                setWorld(world);
            }
        });
    }, [worldId]);

    if (!appState.IsAdmin || !world) {
        return <LoadingSpinner />;
    }

    const content = [
        levelContentWithHeading("Ticks", world.ticks),
        levelContentWithHeading("Last Tick", <DateText date={world.lastTickDate} />),
        levelContentWithHeading("Tick Duration", `${world.lastTickDurationSec} sec`),
        levelContentWithHeading("Week", world.weekNumber),
    ];

    return <SimpleBaseView heading={world ? world.name : "World"} headingContent={<BackNavButton to={admin_worlds} />}>
        <Level content={content} />
        <WorldButtons world={world} />
        <WorldTabs world={world} reloadCallback={world => setWorld(world)} />
    </SimpleBaseView>;
});