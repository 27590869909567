import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SolarSystemDetail } from '../../ApplicationState/ApiClient';
import { AppStateContext, PlayerStateContext, SolarSystemStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { PanelWithTabs, TabProps } from '../../Components/Base/Containers/PanelWithTabs';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { WarningMessageBox } from '../../Components/Base/MessageBox';
import { SolarSystemClaimTypeIcon } from '../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon';
import { IconHelper } from '../../Helpers/IconHelper';
import { SolarSystemHelper } from '../../Helpers/SolarSystemHelper';
import { selected_system, system_subView } from '../../Navigation/Routing/SolarSystem';
import { BaseViewWrapper } from '../BaseView';
import { Agents } from './SubViews/Agents/Agents';
import { Automation } from './SubViews/Automation/Automation';
import { Celestials } from './SubViews/Celestials/Celestials';
import { Items } from './SubViews/Items/Items';
import { Settings } from './SubViews/Settings/Settings';
import { Ships } from './SubViews/Ships/Ships';
import { Summary } from './SubViews/Summary/Summary';

export type SolarSystemSubView = "summary" | "celestials" | "items" | "ships" | "settings" | "agents" | "automation";

export const SelectedSolarSystemView = observer(() => {

    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [subViewToRender, setSubViewToRender] = React.useState<SolarSystemSubView | undefined>(undefined);

    const { subView } = useParams();
    const location = useLocation();

    React.useEffect(() => {

        if (subView !== undefined) {
            setSubViewToRender(subView as SolarSystemSubView);
        } else if (location.pathname.includes(system_subView("celestials"))) {
            setSubViewToRender("celestials");
        } else {
            setSubViewToRender("summary");
        }
    }, [subView, location]);

    function changeUrl(subViewOrSystemUrl: string) {
        const toSet = subViewOrSystemUrl.includes(selected_system) ? subViewOrSystemUrl : system_subView(subViewOrSystemUrl);
        appState.navigate(toSet);
    }

    function changeSubView(subView: SolarSystemSubView) {
        changeUrl(subView);
        setSubViewToRender(subView);
    }

    function reload(solarSystemResult: SolarSystemDetail) {
        if (solarSystemState.SolarSystem && solarSystemResult.solarSystemId === solarSystemState.SolarSystem.solarSystemId) {
            solarSystemState.loadSolarSystem(solarSystemResult);
        }
    }

    function reloadAndHome(solarSystemResult: SolarSystemDetail) {
        changeSubView("summary");
        reload(solarSystemResult);
    }

    if (!worldState.GameSettings || !worldState.GameSettings || !worldState.Effects) {
        return <LoadingSpinner />;
    }

    const solarSystemWrapper = solarSystemState.CurrentSolarSystem;

    if (!solarSystemWrapper || !playerState.Player || subViewToRender === undefined) {

        if (!solarSystemState.SolarSystems || solarSystemState.SolarSystems.length > 0 || !solarSystemState.HasLoaded) {
            return <LoadingSpinner />;
        }

        return <WarningMessageBox text={"You have no solar systems"} />;
    }

    const solarSystem = solarSystemWrapper.solarSystem;

    const tabs: TabProps<SolarSystemSubView>[] = [
        {
            id: "summary",
            title: "Summary",
            icons: IconHelper.Categories.Summary,
            isAvailable: true
        },
        {
            id: "celestials",
            title: "Celestials",
            icons: IconHelper.Celestials.Habitable,
            isAvailable: true
        },
        {
            id: "ships",
            title: "Ships",
            icons: IconHelper.Ships.Ship,
            isAvailable: true
        },
        {
            id: "items",
            title: "Items",
            icons: IconHelper.Items.Item,
            isAvailable: true
        },
        {
            id: "agents",
            title: "Agents",
            icons: IconHelper.Agents.Agent,
            isAvailable: true
        },
        {
            id: "automation",
            title: "Automation",
            icons: solarSystem.automation?.isEnabled ?? false ? IconHelper.SolarSystems.Automation : IconHelper.SolarSystems.AutomationDisabled,
            isAvailable: true
        }
    ];

    const isOnCelestial = location.pathname.includes("celestials/");

    return <BaseViewWrapper>
        <PanelWithTabs
            heading={{
                text: solarSystem.name,
                icon: <SolarSystemClaimTypeIcon solarSystem={solarSystem} />
            }}
            backgroundImage={isOnCelestial ? undefined : SolarSystemHelper.solarSystemBackground(solarSystem)}
            tabs={tabs}
            active={subViewToRender}
            setActive={changeSubView}
            idPrefix="solar-system"
            key={solarSystem.solarSystemId}
        >
            {subViewToRender === "summary" && <Summary solarSystemWrapper={solarSystemWrapper} reloadCallback={reload} changeUrl={changeUrl} changeSubView={changeSubView} />}
            {subViewToRender === "ships" && <Ships solarSystemWrapper={solarSystemWrapper} reloadCallback={reload} />}
            {subViewToRender === "settings" && <Settings solarSystem={solarSystem} reloadCallback={reloadAndHome} />}
            {subViewToRender === "items" && <Items solarSystemWrapper={solarSystemWrapper} gameSettings={worldState.GameSettings} />}
            {subViewToRender === "celestials" && <Celestials solarSystemWrapper={solarSystemWrapper} gameSettings={worldState.GameSettings} worldEffects={worldState.Effects} changeUrl={changeUrl} reloadCallback={reload} />}
            {subViewToRender === "agents" && <Agents solarSystemWrapper={solarSystemWrapper} />}
            {subViewToRender === "automation" && <Automation solarSystemWrapper={solarSystemWrapper} />}
        </PanelWithTabs>
    </BaseViewWrapper>;
});