import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IFederation } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { FederationData, FederationsOverTimeResult } from '../../../ApplicationState/ReportingApiClient';
import { DateHelper } from '../../../Helpers/DateHelper';
import { maxLineSeries } from '../../Base/Charts/Theme';
import { LoadingSpinner } from '../../Base/Loading/LoadingSpinner';
import { FederationLink } from '../Links/FederationLink';
import { dataChoices } from './DataChooser';
import { DataGraph, DataGraphProps } from './DataGraph';
import { createDateControls } from './DateControls';

type Props = DataGraphProps & {
    federationIds: number[],
    federations?: IFederation[]
}

export const FederationGraph = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [data, setData] = React.useState<FederationsOverTimeResult | undefined>(undefined);
    const [date, setDate] = React.useState(new Date());

    React.useEffect(() => {

        if (worldState.World !== undefined) {
            apiState.ReportClient.getFederationsOverTime(worldState.World?.globalId, DateHelper.toDateKey(date), 10, props.federationIds).then(setData);
        }

    }, [worldState.World, date]);

    if (data === undefined || worldState.World === undefined) {
        return <LoadingSpinner />;
    }

    function includeData(datum: FederationData) {
        const federationDimension = data?.federations.find(x => x.federationKey === datum.federationKey);

        return props.federationIds.includes(federationDimension?.federationId ?? -1);
    }

    function renderFederationName(key) {
        const federationDimension = data?.federations.find(x => x.federationKey == key);

        if (federationDimension !== undefined) {
            return `[${federationDimension.shortName}] ${federationDimension.name}`;
        }

        return key;
    }

    function renderSeriesLabel(key) {
        const federationDimension = data?.federations.find(x => x.federationKey == key);
        const federation = props.federations?.find(x => x.federationId === federationDimension?.federationId);

        if (federation !== undefined) {
            return <FederationLink federation={federation} />;
        }

        return undefined;
    }

    const choices = [
        dataChoices.population,
        dataChoices.size,
        dataChoices.numberOfPlayers,
        dataChoices.numberOfSolarSystems];

    if (data.data.find(x => x.researchPoints > 0) !== undefined) {
        choices.push(dataChoices.researchPoints);
    }

    return <DataGraph
        {...props}
        dataChoices={choices}
        data={data}
        additionalButtons={createDateControls(worldState.World, date, setDate)}
        seriesFeature={{
            getter: "federationKey",
            includeData,
            format: renderFederationName,
            renderSeriesLabel
        }}
    />;
});

type WrapperProps = DataGraphProps & {
    federations: IFederation[]
}

export const FederationGraphWrapper = observer((props: WrapperProps) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    const federationIds: number[] = [];

    if (props.federations.length > 1 && playerState.Player.federation !== undefined && playerState.Player.federation.federationId !== undefined) {
        federationIds.push(playerState.Player.federation.federationId);
    }

    for (let i = 0; i < props.federations.length && federationIds.length < maxLineSeries; i++) {
        const federationId = props.federations[i].federationId;
        if (!federationIds.includes(federationId)) {
            federationIds.push(federationId);
        }
    }

    return <FederationGraph federationIds={federationIds} {...props} />
});