export class TutorialIds {

    public static SolarSystemLink = "solar-system-link";
    public static ResourcesBar = "resources-bar";
    public static SolarSystem_Celestials = "solar-system_celestials";
    public static SolarSystem_Ships = "solar-system_ships";
    public static SolarSystemRender = "solar-system-render";
    public static ShipsControl = "ships-control";
    public static MapLink = "map-link";
    public static MapOwnSystem = "own-system";
    public static TutorialMission = "missions";
}

export function asId(id: string) {
    return `#${id}`;
}