import * as React from 'react';
import { VictoryProgress } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { HelpButton } from '../../../Components/Base/Buttons/HelpButton';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { VictoryMessageWithEntity } from '../../../Components/FusionShift/Messages/VictoryMessage';
import { IconHelper } from '../../../Helpers/IconHelper';
import { SimpleBaseView } from '../../BaseView';
import { FederationVotes } from '../../Federation/FederationVotes';
import { PresidentHeading } from './PresidentHeading';
import { RelicTable } from './RelicTable';
import { ResearchPointTable } from './ResearchPointTable';

export const VictoryProgressView = () => {

    const apiState = React.useContext(ApiStateContext);

    const [victorProgress, setVictoryProgress] = React.useState<VictoryProgress | undefined>(undefined);

    React.useEffect(() => {
        if (victorProgress === undefined) {
            apiState.GameClient.getVictoryProgress().then(setVictoryProgress);
        }
    }, []);

    const presidentFederation = victorProgress !== undefined &&
        victorProgress.activePresident?.president?.federation !== undefined ?
        victorProgress.federations.find(x => x.federationId === victorProgress!.activePresident!.president.federation!.federationId) : undefined;

    return <SimpleBaseView
        heading={{ text: "Victory Progress", icon: IconHelper.General.Victory }}
        headingContent={
            <ButtonHolder>
                <HelpButton to={"endgame"} />
            </ButtonHolder>}
    >
        {victorProgress === undefined && <LoadingSpinner />}
        {victorProgress !== undefined && <>
            {victorProgress.victor.federation !== undefined && <VictoryMessageWithEntity victor={victorProgress.victor.federation} />}
            {victorProgress.activePresident !== undefined && <PresidentHeading player={victorProgress.activePresident.president} federation={presidentFederation} />}
            <SplitLayout
                left={
                    <RelicTable relics={victorProgress.lastSeenRelicLocations} />
                }
                right={
                    <ResearchPointTable federations={victorProgress.federations} />
                }
            />
            <SubPanel
                heading={{
                    text: "Federation Vote Proportion",
                    icon: IconHelper.Politics.Vote
                }}
            >
                <FederationVotes federations={victorProgress.federations} />
            </SubPanel>
        </>}
    </SimpleBaseView >;
};