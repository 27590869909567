import { observer } from "mobx-react-lite";
import { FactionType, IShipTypeSettings, ShipTypeDescription, FactionTypeDescription as TypeDescription } from "../../../ApplicationState/ApiClient";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { SplitLayout } from "../../Base/Containers/SplitLayout";
import { ParagraphArray } from "../../Base/Text/Paragraph";
import { ShipClassIcon } from "../Icons/ShipClassIcon";
import { FactionTypeImage } from "../Images/FactionTypeImage";
import { ShipTypeImage } from "../Images/ShipTypeImage";
import { ShipTypeLink } from "../Links/ShipTypeLink";
import { RichTextArray } from "../RichTextArray";

type Props = {
    shipTypeSettings: IShipTypeSettings,
    factionType: FactionType,
    disableLink?: boolean,
    description: TypeDescription,
    shipDescriptions: ShipTypeDescription[]
}

export const FactionTypeDescription = observer((props: Props) => {

    const ships = props.factionType.startingShips && Object.keys(props.factionType.startingShips)
        .map(m => {
            return {
                ship: props.shipTypeSettings.data![m],
                quantity: props.factionType.startingShips![m],
                description: props.shipDescriptions.find(x => x.typeName === m)
            }
        })
        .sort(((a, b) => a.ship.order > b.ship.order ? 1 : -1));;

    return <SplitLayout
        hasDivider
        fitContent
        left={
            <FactionTypeImage factionTypeName={props.factionType.typeName} disableLink={props.disableLink} />
        }
        right={
            <>
                <ParagraphArray content={props.description.description} />
                {CollectionHelper.isAnyInArray(ships) &&
                    <>
                        <h4 className="subtitle">Starting Ships</h4>
                        <table>
                            <tbody>
                                {ships!.map((s, i) => <tr key={i}>
                                    <td>
                                        <ShipTypeImage size="medium" shipType={s.ship} disableLink={props.disableLink} />
                                    </td>
                                    <td>
                                        <h4>
                                            {props.disableLink && <>
                                                <ShipClassIcon shipClass={s.ship.class} />
                                                {s.ship.name}
                                            </>}
                                            {!props.disableLink && <ShipTypeLink shipType={s.ship} />}
                                            {` x${s.quantity}`}
                                        </h4>
                                        <RichTextArray text={s.description?.description} className="is-prompt" />
                                        <RichTextArray text={[s.description?.factionDescription ?? ""]} className="is-prompt" />
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </>
                }
            </>
        }
    />;
});