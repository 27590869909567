import * as React from 'react';
import { MegacityCommodity } from '../../../../../ApplicationState/ApiClient';
import { ProgressBar } from '../../../../../Components/Base/ProgressBar';
import { CommodityUsage } from '../../../../../Helpers/CommodityHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    capacity: number,
    commodity: CommodityUsage,
    store: MegacityCommodity | undefined
}

export const CommodityStoreBar = (props: Props) => {

    if (!props.store) {
        return null;
    }

    if (props.capacity === 0) {
        return <>
            {ValueFormatter.formatLocaleNumber(props.store.quantity)}
        </>;
    }

    return <ProgressBar leftIcon={IconHelper.Items.Commodity} className="is-small" value={props.store.quantity} max={props.capacity} text={`${ValueFormatter.formatLocaleNumber(props.store.quantity)} / ${props.capacity}`} />;
};