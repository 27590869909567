import { ApocalypseShotStatus, IMapApocalypseShot } from "../../../../ApplicationState/ApiClient";
import { MapHelper } from "../../MapHelper";

type Props = {
    apocalypseShots: IMapApocalypseShot[],
    offsetX: number,
    offsetY: number
}

type ShotProps = {
    apocalypseShot: IMapApocalypseShot,
    offsetX: number,
    offsetY: number
}

const ShotLine = (props: ShotProps) => {

    return <>
        <line
            className={`apocalypse-shot ${ApocalypseShotStatus[props.apocalypseShot.status].toLowerCase()}`}
            x1={MapHelper.fixOffsetX(props.apocalypseShot.source.x, props.offsetX)}
            y1={MapHelper.fixOffsetY(props.apocalypseShot.source.y, props.offsetY)}
            x2={MapHelper.fixOffsetX(props.apocalypseShot.target.x, props.offsetX)}
            y2={MapHelper.fixOffsetY(props.apocalypseShot.target.y, props.offsetY)}
        />
    </>;
}

export const ApocalypseShotLayer = (props: Props) => {

    if (props.apocalypseShots.length === 0) {
        return null;
    }

    return <svg className="overlay top">
        {props.apocalypseShots
            .map(m => <ShotLine
                key={m.apocalypseShotId}
                apocalypseShot={m}
                {...props}
            />)}
    </svg>;
};