import { observer } from "mobx-react-lite";
import React from "react";
import { FleetMovementType, IFleet, ISolarSystem } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, SolarSystemStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { FleetWrapper } from "../../Entities/FleetWrapper";
import { Coordinate } from "../../Entities/Shared";
import { CelestialHelper } from "../../Helpers/CelestialHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { DurationSteps } from "../../Helpers/SendShipsHelper";
import { SolarSystemHelper } from "../../Helpers/SolarSystemHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { Summary } from "./Components/Summary";
import { TargetRender } from "./Components/Target/TargetRender";
import { Validation } from "./Components/Validation";
import { Send } from "./Send";
import { SendShipsBag, SendShipsBagInit } from "./SendShipsBag";
import { StageRender } from "./Stages/StageRender";
import { SendShipsStage, StagesControl } from "./Stages/StagesControl";

type Props = {
    initialTargetCoordinate: Coordinate | undefined,
    initialTargetCelestialId: number | undefined,
    initialTargetFleetId: number | undefined,
    initialFleetMovementType: FleetMovementType | undefined,
    initialMarketOrderId: number | undefined,
    initialContractId: number | undefined,
    initialRepeatFleetId: number | undefined,
    onSent: (result: SendShipsResult) => any
}

export type SendShipsResult = {
    wasSuccess: boolean,
    error?: string,
    fleets?: IFleet[],
    showRepeat: boolean,
    wasEditRepeat?: boolean,
    movementType: FleetMovementType,
    targetCoordinate: Coordinate | undefined,
    targetSolarSystem: ISolarSystem | undefined,
    duration: DurationSteps | undefined,
    sourceSolarSystemId: number,
    sendShipsBag: SendShipsBag
}

export const SendShipsForm = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [sendShipsBag, setSendShipsBag] = React.useState<SendShipsBag | undefined>(undefined);
    const [currentStage, setCurrentStage] = React.useState<SendShipsStage>("Target");
    const [isLoadingLocked, setIsLoadingLocked] = React.useState(props.initialFleetMovementType !== undefined || props.initialTargetCoordinate !== undefined || props.initialRepeatFleetId !== undefined);

    function unlockLoading() {
        setIsLoadingLocked(false);
    }

    React.useEffect(() => {

        if (
            solarSystemState.SolarSystem &&
            solarSystemState.SolarSystems &&
            worldState.ItemTypeSettings &&
            worldState.ShipTypeSettings &&
            worldState.InstallationTypeSettings &&
            worldState.CelestialTypeSettings &&
            worldState.GameSettings &&
            playerState.Player) {

            const init: SendShipsBagInit = {
                shipTypeSettings: worldState.ShipTypeSettings,
                itemTypeSettings: worldState.ItemTypeSettings,
                installationTypeSettings: worldState.InstallationTypeSettings,
                celestialTypeSettings: worldState.CelestialTypeSettings,


                gameSettings: worldState.GameSettings,
                player: playerState.Player,
                sourceSolarSystem: solarSystemState.SolarSystem,
                solarSystems: solarSystemState.SolarSystems,
                availableShips: new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, solarSystemState.SolarSystem.availableShips!, (_, q) => q > 0),

                initialContractId: props.initialContractId,

                initialTargetCelestialId: props.initialTargetCelestialId,

                initialFleetMovementType: props.initialFleetMovementType,

                initialMarketOrderId: props.initialMarketOrderId,

                world: worldState.World!,

                getAllSolarSystems: () => solarSystemState.SolarSystemsDetail,

                switchSolarSystem: (solarSystemId: number) => appState.changeSolarSystemId(solarSystemId)
            };
            if (!sendShipsBag) {
                setSendShipsBag(new SendShipsBag(init));
            } else if (sendShipsBag.init.sourceSolarSystem.solarSystemId !== init.sourceSolarSystem.solarSystemId) {
                sendShipsBag.reInit(init);
            }
        }
    }, [
        sendShipsBag,

        worldState.ItemTypeSettings,
        worldState.ShipTypeSettings,
        worldState.InstallationTypeSettings,
        worldState.CelestialTypeSettings,

        worldState.GameSettings,
        playerState.Player,
        solarSystemState.SolarSystem,
        solarSystemState.SolarSystems]);

    React.useEffect(() => {
        if (sendShipsBag) {
            if (props.initialTargetCoordinate) {
                apiState.SolarSystemClient.getByCoordinate(props.initialTargetCoordinate.x, props.initialTargetCoordinate.y).then(solarSystem => {
                    if (solarSystem) {
                        sendShipsBag.setTargetSolarSystem(solarSystem);
                        if (currentStage === "Target") {
                            setCurrentStage(sendShipsBag.MovementType !== undefined ? "Ships" : "Movement Type");
                        }
                    }
                })
                    .finally(unlockLoading);;
            } else if (props.initialTargetFleetId) {
                apiState.FleetClient.get(props.initialTargetFleetId).then(fleet => {
                    if (fleet) {
                        sendShipsBag.setTargetFleet(fleet);
                        if (currentStage === "Target") {
                            setCurrentStage(sendShipsBag.MovementType !== undefined ? "Ships" : "Movement Type");
                        }
                    }
                })
                    .finally(unlockLoading);
            }
        }
    }, [sendShipsBag, props.initialTargetCoordinate, props.initialTargetFleetId]);

    React.useEffect(() => {
        if (sendShipsBag) {
            if (props.initialContractId) {
                apiState.ContractsClient.getDetail(props.initialContractId).then(contract => {
                    if (contract) {
                        sendShipsBag.setContract(contract);
                    }
                })
                    .finally(unlockLoading);;
            }
        }
    }, [sendShipsBag, props.initialContractId]);

    React.useEffect(() => {
        if (sendShipsBag) {
            if (props.initialRepeatFleetId) {
                const repeatFleet = sendShipsBag.init.sourceSolarSystem.repeatFleets?.find(x => x.repeatFleetId === props.initialRepeatFleetId);

                if (repeatFleet) {

                    apiState.SolarSystemClient.get(repeatFleet.targetSolarSystemId).then(solarSystem => {

                        if (repeatFleet.contractId !== undefined && repeatFleet.contractId !== null) {
                            apiState.ContractsClient.getDetail(repeatFleet.contractId).then(contract => {
                                if (contract) {
                                    sendShipsBag.editRepeatFleet(repeatFleet, solarSystem, contract);
                                    setCurrentStage("Ships");
                                }
                            });
                        } else {
                            sendShipsBag.editRepeatFleet(repeatFleet, solarSystem, undefined);
                            setCurrentStage("Ships");
                        }
                    })
                        .finally(unlockLoading);
                }
            }
        }
    }, [sendShipsBag, props.initialRepeatFleetId]);

    function onSent(sendShipsResult: SendShipsResult) {

        if (sendShipsResult.targetSolarSystem !== undefined && solarSystemState.SolarSystems.find(x => x.solarSystemId === sendShipsResult.targetSolarSystem?.solarSystemId) !== undefined) {
            appState.reloadSolarSystemById(sendShipsResult.targetSolarSystem.solarSystemId);
        }

        appState.scrollToTop();
        props.onSent(sendShipsResult);

    }

    function send() {
        return Send.send(sendShipsBag, appState, apiState, solarSystemState, onSent);
    }

    if (!sendShipsBag) {
        return null;
    }

    const dontShowExtraSummary = !sendShipsBag.HasTarget ||
        sendShipsBag.MovementType === undefined ||
        currentStage === "Target" ||
        currentStage === "Movement Type" ||
        currentStage === "Summary";

    if (isLoadingLocked) {
        return <LoadingSpinner />;
    }

    const backgroundImage = CelestialHelper.celestialBackground(sendShipsBag.TargetCelestial, sendShipsBag.TargetSolarSystem?.owner !== undefined) ??
        SolarSystemHelper.solarSystemBackground(sendShipsBag.TargetSolarSystem);

    return <>
        <StagesControl
            currentStage={currentStage}
            changeStage={setCurrentStage}
            sendShipsBag={sendShipsBag}
        />
        <StageRender
            currentStage={currentStage}
            changeStage={setCurrentStage}
            sendShipsBag={sendShipsBag}
            send={send}
        />
        {!dontShowExtraSummary &&
            <SubPanel
                heading={{ text: `Sending ${ValueFormatter.friendlyNameForMovementType(sendShipsBag.MovementType!)}`, icon: IconHelper.Ships.movementType(sendShipsBag.MovementType!) }}
                backgroundImage={backgroundImage}
                isBackgroundFaded
            >
                <SplitLayout
                    left={<>
                        <Validation sendShipsBag={sendShipsBag} />
                        <Summary sendShipsBag={sendShipsBag} />
                    </>}
                    right={<TargetRender sendShipsBag={sendShipsBag} />}
                />
            </SubPanel>
        }
    </>;
});