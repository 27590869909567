import { AgentActionType, IAgentEffect } from "../../../ApplicationState/ApiClient";
import { TextHelper } from "../../../Helpers/TextHelper";
import { enumFromString } from "../../../Helpers/TypeHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

export enum ProblemCategory {
    Economy = 1,
    Military = 2,
    Ships = 3,
    Megacities = 4,
    Manufactories = 5
};

export enum ProblemSeverity {
    Info = 1,
    Warning = 2,
    Danger = 3
}

export type Problem = {
    celestialId?: number,
    category: ProblemCategory,
    severity: ProblemSeverity,
    title: string,
    description: string,
    solution: ProblemSolution | undefined
}

export type ProblemSolution = {

}

export type ProblemsMaybe = (Problem | false | undefined)[] | Problem | false | undefined;

export class ProblemBuilder {

    private _category: ProblemCategory;

    constructor(category: ProblemCategory) {
        this._category = category;
    }

    public agentPercentProblem(agentEffect: { agentEffect: IAgentEffect; agentActionType: AgentActionType; strength: number; }, description: string, getValue: (x: AgentActionType) => number | undefined, positivity: "positiveIsGood" | undefined): ProblemsMaybe {

        let value = getValue(agentEffect.agentActionType);

        if (value === undefined || value === null || value === 0) {
            return undefined;
        }

        value = value * agentEffect.strength;

        const title = `${TextHelper.upperCaseEachWord(description)} Modified`;

        if (positivity === undefined || value > 0) {
            return this.infoProblem(title, `${description} has been modified by ${ValueFormatter.fromDecimalToDisplayPercent(value)}`);

        }

        return this.warningProblem(title, `${description} has been modified by ${ValueFormatter.fromDecimalToDisplayPercent(value)}`)
    }


    public infoProblem(title: string, description: string, solutionTarget?: ProblemSolution): Problem {
        return {
            category: this._category, severity: ProblemSeverity.Info, title, description, solution: solutionTarget
        };
    }

    public warningProblem(title: string, description: string, solutionTarget?: ProblemSolution): Problem {
        return {
            category: this._category, severity: ProblemSeverity.Warning, title, description, solution: solutionTarget
        };
    }

    public dangerProblem(title: string, description: string, solutionTarget?: ProblemSolution): Problem {
        return {
            category: this._category, severity: ProblemSeverity.Danger, title, description, solution: solutionTarget
        };
    }

    public merge(...maybes: ProblemsMaybe[]): Problem[] {
        return mergeProblemMaybes(...maybes);
    }
}

export function mergeProblemMaybes(...maybes: ProblemsMaybe[]): Problem[] {
    const problems: Problem[] = [];

    for (let maybe of maybes) {
        if (maybe && maybe !== undefined) {
            if (Array.isArray(maybe)) {
                for (let problem of maybe) {
                    if (problem) {
                        problems.push(problem);
                    }
                }
            } else {
                problems.push(maybe);
            }
        }
    }

    return problems;
};

export function severityClassName(severity: ProblemSeverity, prefix: string = "has-text") {

    severity = typeof severity === "string" ? enumFromString(ProblemSeverity, severity) : severity;

    if (severity === ProblemSeverity.Warning) {
        return `${prefix}-warning`;
    }
    if (severity === ProblemSeverity.Danger) {
        return `${prefix}-danger`;
    }
    return `${prefix}-info`;
}