import * as React from 'react';
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";
import { RedemptionForm } from "./SecurityStatus/RedemptionForm";
import { SecurityStatusChangeTable } from "./SecurityStatus/SecurityStatusChangeTable";

export const SecurityStatusView = () => {

    const tableRef = React.useRef();

    return <SimpleBaseView
        heading={{ text: "Security Status", icon: IconHelper.Security.Security }}
        headingContent={<HelpButton to="security" />}
    >
        <RedemptionForm reloadCallback={() => {
            ((tableRef?.current) as any).reload();
        }} />
        <SecurityStatusChangeTable ref={tableRef} />
    </SimpleBaseView>;
};