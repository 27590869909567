import { observer } from 'mobx-react-lite';
import * as React from 'react';
import useMeasure from 'react-use-measure';
import { IItemsDetail, ManufactoryNode } from '../../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../../ApplicationState/ContextRoot';
import { ManufactoryHelper } from '../../../../../Helpers/ManufactoryHelper';

type Props = {
    celestialId: number,
    itemsDetail: IItemsDetail | undefined,
    selectedManufactoryNodeId: string | undefined,
    inputNodes: ManufactoryNode[],
    outputNodes: ManufactoryNode[]
}

type Line = {
    x1: number,
    x2: number,
    y1: number,
    y2: number,
    resourceTypeName: string,
    manufactoryNodeIds: string[],
    thoughput: number
}

type OutputNodeResources = {
    outputNodeId: string,
    throughput: number,
    outputNodeIndex: number,
    resourceTypeNames: string[]
}

type InputToOutputs = {
    inputNodeId: string,
    inputNodeIndex: number,
    throughput: number,
    outputNodes: OutputNodeResources[],
    resourceTypeName: string
}

export const ConnectingBackground = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [ref, bounds] = useMeasure();

    const data = React.useMemo(() => {

        if (!worldState.ItemTypeSettings || !worldState.SchematicTypeSettings || !props.inputNodes || !props.outputNodes || props.inputNodes.length == 0 || props.outputNodes.length == 0) {
            return undefined;
        }

        const outputNodeResources: OutputNodeResources[] = [];

        for (let n of props.outputNodes) {
            if (n.itemTypeName in worldState.ItemTypeSettings.data) {
                const commodity = worldState.ItemTypeSettings.data[n.itemTypeName];
                const schematic = Object.values(worldState.SchematicTypeSettings.data).find(s => s.producedItemTypeName === commodity.typeName);

                if (schematic && schematic.costPerRun) {

                    const actualThroughput = ManufactoryHelper.actualThroughput(props.itemsDetail, props.celestialId, n.manufactoryNodeId);

                    const resourceTypeNames = Object.keys(schematic.costPerRun.items);

                    const outputNodeConnection: OutputNodeResources = {
                        outputNodeId: n.manufactoryNodeId,
                        outputNodeIndex: props.outputNodes.indexOf(n),
                        resourceTypeNames,
                        throughput: actualThroughput / n.optimalThroughput
                    };
                    outputNodeResources.push(outputNodeConnection);
                }
            }
        }

        const inputToOutputs: InputToOutputs[] = [];

        for (let n of props.inputNodes) {
            if (n.itemTypeName !== undefined) {
                const outputNodes: OutputNodeResources[] = [];

                for (let o of outputNodeResources) {
                    if (o.resourceTypeNames.includes(n.itemTypeName)) {
                        outputNodes.push(o);
                    }
                }

                const actualThroughput = ManufactoryHelper.actualThroughput(props.itemsDetail, props.celestialId, n.manufactoryNodeId);

                inputToOutputs.push({
                    inputNodeId: n.manufactoryNodeId,
                    inputNodeIndex: props.inputNodes.indexOf(n),
                    resourceTypeName: n.itemTypeName,
                    outputNodes,
                    throughput: actualThroughput / n.optimalThroughput
                });
            }
        }

        const lines: Line[] = [];

        const yDist = 66;
        const yNudge = 7;
        const yNudges: number[][] = [
            [0],
            [-yNudge * 2, yNudge * 2],
            [-yNudge * 2, 0, yNudge * 2],
            [-yNudge * 2, -yNudge, yNudge, yNudge * 2],
            [-yNudge * 2, -yNudge, 0, yNudge, yNudge * 2]
        ];
        const xEnd = bounds.width;
        const xInputEnd = xEnd / 4;
        const xOutputStart = xEnd - xInputEnd;

        function inputY(input: InputToOutputs) {
            return input.inputNodeIndex * yDist;
        }

        function outputYForResource(resourceTypeName: string, outputNode: OutputNodeResources) {
            if (outputNode.resourceTypeNames.includes(resourceTypeName)) {
                const lengthIndex = outputNode.resourceTypeNames.length - 1;
                const resourceIndex = outputNode.resourceTypeNames.indexOf(resourceTypeName)
                const toNode = (yDist * outputNode.outputNodeIndex);

                return (toNode + yNudges[lengthIndex][resourceIndex]) + (yNudge / 2);
            }
            return undefined;
        }

        const maxInput = 10000;
        function inputThroughput(input: InputToOutputs) {
            return input.throughput;
        }

        const maxOutput = 1000;
        function outputThroughput(output: OutputNodeResources) {
            return output.throughput;
        }

        function combinedThroughput(input: InputToOutputs, output: OutputNodeResources) {
            return inputThroughput(input) * outputThroughput(output);
        }

        for (let input of inputToOutputs) {

            lines.push({
                x1: 0,
                x2: xInputEnd,
                y1: inputY(input),
                y2: inputY(input),
                resourceTypeName: input.resourceTypeName,
                manufactoryNodeIds: [input.inputNodeId, ...input.outputNodes.map(x => x.outputNodeId)],
                thoughput: inputThroughput(input)
            });

            for (let output of input.outputNodes) {

                const outputPosition = outputYForResource(input.resourceTypeName, output);

                if (outputPosition === undefined) {
                    continue;
                }

                lines.push({
                    x1: xInputEnd,
                    x2: xOutputStart,
                    y1: inputY(input),
                    y2: outputPosition,
                    resourceTypeName: input.resourceTypeName,
                    manufactoryNodeIds: [input.inputNodeId, output.outputNodeId],
                    thoughput: combinedThroughput(input, output)
                });
            }
        }

        for (let output of outputNodeResources) {
            for (let resourceTypeName of output.resourceTypeNames) {

                const outputPosition = outputYForResource(resourceTypeName, output);

                if (outputPosition === undefined) {
                    continue;
                }

                lines.push({
                    x1: xOutputStart,
                    x2: xEnd,
                    y1: outputPosition,
                    y2: outputPosition,
                    resourceTypeName: resourceTypeName,
                    manufactoryNodeIds: [output.outputNodeId, ...inputToOutputs.filter(x => x.resourceTypeName === resourceTypeName).map(x => x.inputNodeId)],
                    thoughput: outputThroughput(output)
                });
            }
        }

        return lines;

    }, [bounds, worldState.ItemTypeSettings, worldState.SchematicTypeSettings, props.inputNodes, props.outputNodes, props.selectedManufactoryNodeId]);

    const offsetX = 4;
    const offsetY = 27;

    const maxThroughputs = 10;

    function classNamesForLine(line: Line) {

        const selected = props.selectedManufactoryNodeId !== undefined &&
            props.selectedManufactoryNodeId.length > 0 &&
            line.manufactoryNodeIds.includes(props.selectedManufactoryNodeId) ? "selected-node" : "";

        let toUse = line.thoughput <= 0 ? 0 : Math.round(line.thoughput * maxThroughputs);

        if (toUse === 0 && line.thoughput > 0) {
            toUse = 1;
        }

        const throughput = `throughput-${toUse}`;

        return `connection ${line.resourceTypeName.toLowerCase()} ${selected} ${throughput}`;
    }

    if (data === undefined) {
        return null;
    }

    return <svg
        className={`connections ${props.selectedManufactoryNodeId !== undefined && props.selectedManufactoryNodeId.length > 0 ? "has-selected" : ""}`}
        ref={ref}
    >
        {data.map((l, i) => <line key={i}
            className={classNamesForLine(l)}
            style={{ strokeWidth: 1 }}
            x1={l.x1 + offsetX}
            x2={l.x2 + offsetX}
            y1={l.y1 + offsetY}
            y2={l.y2 + offsetY}
        />)}
    </svg>;
});