import { FleetMovementType, IconHint } from "../ApplicationState/ApiClient";
import { AccoladeIcons } from "./Icons/AccoladeIcons";
import { AccountIcons } from "./Icons/AccountIcons";
import { AgentIcons } from "./Icons/AgentIcons";
import { AudioIcons } from "./Icons/AudioIcons";
import { CategoryIcons } from "./Icons/CategoryIcons";
import { CelestialIcons } from "./Icons/CelestialIcons";
import { ChatIcons } from "./Icons/ChatIcons";
import { CombatIcons } from './Icons/CombatIcons';
import { EconomyIcons } from "./Icons/EconomyIcons";
import { FederationIcons } from "./Icons/FederationIcons";
import { GeneralIcons } from './Icons/GeneralIcons';
import { InstallationIcons } from "./Icons/InstallationIcons";
import { ItemIcons } from "./Icons/ItemIcons";
import { ManufactoriesIcons } from "./Icons/ManufactoriesIcons";
import { MapIcons } from "./Icons/MapIcons";
import { MarketIcons } from "./Icons/MarketIcons";
import { MegacityIcons } from "./Icons/MegacityIcons";
import { MissionIcons } from "./Icons/MissionIcons";
import { PlayerIcons } from "./Icons/PlayerIcons";
import { PoliticsIcons } from "./Icons/PoliticsIcons";
import { PremiumIcons } from "./Icons/PremiumIcons";
import { SecurityIcons } from "./Icons/SecurityIcons";
import { ShipIcons } from "./Icons/ShipIcons";
import { SolarSystemIcons } from "./Icons/SolarSystemIcons";
import { StatusIcons } from './Icons/StatusIcons';

export class IconHelper {

    public static Accolades = new AccoladeIcons();
    public static Account = new AccountIcons();
    public static Agents = new AgentIcons();
    public static Audio = new AudioIcons();
    public static Categories = new CategoryIcons();
    public static Celestials = new CelestialIcons();
    public static Chat = new ChatIcons();
    public static Combat = new CombatIcons();
    public static Economy = new EconomyIcons();
    public static Federations = new FederationIcons();
    public static General = new GeneralIcons();
    public static Installations = new InstallationIcons();
    public static Items = new ItemIcons();
    public static Manufactories = new ManufactoriesIcons();
    public static Map = new MapIcons();
    public static Market = new MarketIcons();
    public static Megacities = new MegacityIcons();
    public static Missions = new MissionIcons();
    public static Player = new PlayerIcons();
    public static Politics = new PoliticsIcons();
    public static Premium = new PremiumIcons();
    public static Security = new SecurityIcons();
    public static Ships = new ShipIcons();
    public static SolarSystems = new SolarSystemIcons();
    public static Statuses = new StatusIcons();

    public static iconHint(iconHint: IconHint | undefined): string {

        if (iconHint === undefined) {
            return "";
        }

        switch (iconHint) {
            case IconHint.Attack:
                return this.Combat.Attack;

            case IconHint.Defence:
                return this.Combat.Defence;

            case IconHint.Raid:
                return this.Combat.Raid;

            case IconHint.Installation:
                return this.Installations.Installation;

            case IconHint.Items:
            case IconHint.ItemType:
                return this.Items.Item;

            case IconHint.Megacity:
                return this.Megacities.Megacity;

            case IconHint.Resource:
                return this.Items.Resource;

            case IconHint.SecurityStatus:
                return this.Security.Security;

            case IconHint.Ships:
            case IconHint.ShipClass:
                return this.Ships.Ship;

            case IconHint.Claim:
                return this.Ships.movementType(FleetMovementType.Claim);

            case IconHint.Victory:
                return this.General.Victory;
        }
    }

    public static resolveIcon(icon: string) {

        for (let key of Object.keys(IconHelper)) {
            const prototype = Object.getPrototypeOf(IconHelper[key]);
            const properties = Object.getOwnPropertyNames(prototype);

            for (let property of properties) {
                if (property === icon) {
                    const desc = Object.getOwnPropertyDescriptor(prototype, property);
                    const propertyValue = desc !== undefined && desc.get !== undefined ? desc.get() : undefined;

                    if (propertyValue !== undefined && typeof propertyValue === "string") {
                        return propertyValue;
                    }
                }
            }
        }

        return icon;
    }
}