import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IPlayerMinimal } from '../../../ApplicationState/ApiClient';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { FlexDiv } from '../../Base/Containers/FlexDiv';
import { PlayerLink } from '../Links/PlayerLink';
import { ImageSize } from './SvgImage';

type Props = {
    filename: string | undefined,
    isInline?: boolean | undefined,
    isCentered?: boolean | undefined,
    size?: ImageSize,
    className?: string
}

type EmblemAndLinkProps = {
    player: IPlayerMinimal,
    hideFederationLink?: boolean
}

export const EmblemImage = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const [url, setUrl] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (appState.ClientSettings && props.filename && props.filename.length > 0) {
            setUrl(`${appState.ClientSettings.emblemsCdnUrl}${props.filename}`);
        }
    }, [props.filename, appState.ClientSettings]);

    const size = props.size ?? "medium";

    const className = classNames("emblem",
        props.className,
        `size-${size}`,
        {
            "is-inline": props.isInline,
            "is-centered": props.isCentered
        });

    if (url === undefined) {
        return <div className={className} />;
    }

    return <img className={className} src={url} />;
});

export const PlayerEmblemAndLink = (props: EmblemAndLinkProps) => {

    return <FlexDiv isContentVerticallyCentered>
        <EmblemImage size="tiny" filename={props.player.emblemFilename} />
        <PlayerLink {...props} />
    </FlexDiv>;
}