import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TypeData } from '../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../ApplicationState/ContextRoot';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { FactionTypeLink } from '../../Components/FusionShift/Links/FactionTypeLink';
import { InstallationTypeLink } from '../../Components/FusionShift/Links/InstallationTypeLink';
import { PolicyTypeLink } from '../../Components/FusionShift/Links/PolicyTypeLink';
import { SectorTypeLink } from '../../Components/FusionShift/Links/SectorTypeLink';
import { ShipTypeLink } from '../../Components/FusionShift/Links/ShipTypeLink';
import { IconHelper } from '../../Helpers/IconHelper';

type Props = {
    typeData: { [key: string]: string[] }
}

export const TypeDataRenderer = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ShipTypeSettings ||
        !worldState.FactionTypeSettings ||
        !worldState.PolicyTypeSettings ||
        !worldState.ItemTypeSettings ||
        !worldState.InstallationTypeSettings ||
        !worldState.FactionTypeSettings ||
        !worldState.SectorTypeSettings) {
        return null;
    }

    function getByKey(key: string) {
        if (key in props.typeData) {
            return props.typeData[key];
        }

        return [];
    }

    function getByTypeNames<T extends TypeData>(typeNames: string[], typeData: { [key: string]: T }) {

        return typeNames.map(x => x in typeData ? typeData[x] : undefined)
            .filter(x => x !== undefined)
            .map(x => x!);
    }

    function get<T extends TypeData>(key: string, typeData: { [key: string]: T }) {
        const typeNames = getByKey(key);
        return getByTypeNames(typeNames, typeData);
    }

    const shipTypes = get("ShipType", worldState.ShipTypeSettings.data);
    const factionTypes = get("FactionType", worldState.FactionTypeSettings.data);
    const policyTypes = get("PolicyType", worldState.PolicyTypeSettings.data);
    const sectorTypes = get("SectorType", worldState.SectorTypeSettings.data);
    const installationTypes = get("InstallationType", worldState.InstallationTypeSettings.data);

    return <>
        {installationTypes.length > 0 && <SubPanel heading={{ text: "Installations", icon: IconHelper.Installations.Installation }}>
            <ul>
                {installationTypes.map(x => <li key={x.typeName}>
                    <InstallationTypeLink installationType={x} />
                </li>)}
            </ul>
        </SubPanel>}
        {shipTypes.length > 0 && <SubPanel heading={{ text: "Ships", icon: IconHelper.Ships.Ship }}>
            <ul>
                {shipTypes.map(x => <li key={x.typeName}>
                    <ShipTypeLink shipType={x} />
                </li>)}
            </ul>
        </SubPanel>}
        {factionTypes.length > 0 && <SubPanel heading={{ text: "Factions", icon: IconHelper.Categories.Factions }}>
            <ul>
                {factionTypes.map(x => <li key={x.typeName}>
                    <FactionTypeLink factionType={x} />
                </li>)}
            </ul>
        </SubPanel>}
        {policyTypes.length > 0 && <SubPanel heading={{ text: "Policy", icon: IconHelper.Politics.Vote }}>
            <ul>
                {policyTypes.map(x => <li key={x.typeName}>
                    <PolicyTypeLink policyType={x} />
                </li>)}
            </ul>
        </SubPanel>}
        {sectorTypes.length > 0 && <SubPanel heading={{ text: "Sector", icon: IconHelper.Megacities.Sectors }}>
            <ul>
                {sectorTypes.map(x => <li key={x.typeName}>
                    <SectorTypeLink sectorType={x} />
                </li>)}
            </ul>
        </SubPanel>}
    </>;
});