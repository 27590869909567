import React from "react";
import { IFederation } from "../../ApplicationState/ApiClient";
import { TabMenuWithChildren } from "../../Components/Base/Containers/TabMenu";
import { FederationGraphWrapper } from "../../Components/FusionShift/Reports/FederationGraph";
import { IconHelper } from "../../Helpers/IconHelper";
import { FederationVotes } from "./FederationVotes";

type Props = {
    federations: IFederation[]
}

type Tabs = "Data" | "Votes";

export const FederationData = (props: Props) => {

    const [tab, setTab] = React.useState<Tabs>("Data");

    const tabs = [
        {
            id: "Data",
            title: "Data",
            isAvailable: true,
            icons: IconHelper.Categories.Statistics
        },
        {
            id: "Votes",
            title: "Vote Proportion",
            isAvailable: true,
            icons: IconHelper.Politics.Vote
        }];

    return <TabMenuWithChildren
        active={tab}
        changeTab={setTab}
        tabs={tabs}
    >
        {tab === "Data" && <FederationGraphWrapper federations={props.federations} />}
        {tab === "Votes" && <FederationVotes federations={props.federations} />}
    </TabMenuWithChildren>
}