import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, ISolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Table } from '../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { SendShipsBetweenSystemButtons } from '../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { UpkeepIconNoQuantity } from '../../../Components/FusionShift/Icons/Items/UpkeepIcon';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { TimeHelper } from '../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type Count = {
    ships: number,
    hangarSpace: number,
    net: number,
    income: number,
    final: number,
    expiryHours?: number
}

type BaseRowProps = Count & {
    id: string,
    label: React.ReactNode,
}

type RowProps = BaseRowProps & {

    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem?: ISolarSystemDetail | undefined
}

const movementTypes = [
    FleetMovementType.Rebase
];

const Row = (props: RowProps) => {

    return <tr className={props.id === "total" ? "is-total" : ""}>
        <td>
            {props.label}
        </td>
        <td>
            {ValueFormatter.formatLocaleNumber(props.ships)}
        </td>
        <td>
            {ValueFormatter.formatLocaleNumber(props.hangarSpace)}
        </td>
        <td className={props.net > 0 ? "has-text-danger" : ""}>
            {ValueFormatter.formatLocaleNumber(props.net)}
        </td>
        <td>
            {ValueFormatter.formatLocaleNumber(props.income)}
        </td>
        <td className={props.final < 0 ? "has-text-danger" : ""}>
            {ValueFormatter.formatLocaleNumber(props.final)}
        </td>
        <td className={props.expiryHours !== undefined ? "has-text-danger" : ""}>
            {props.expiryHours !== undefined && ValueFormatter.formatDateFromNowWithMoment(TimeHelper.addHours(new Date(), props.expiryHours))}
        </td>
        <td className="is-double-button">
            {props.showSendButtons && props.solarSystem !== undefined &&
                <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem} movementTypes={movementTypes} />
            }
        </td>
    </tr>;
};

function tryGet(dictionary: { [key: string]: number } | undefined, key: string) {
    if (dictionary !== undefined && dictionary !== null && key in dictionary) {
        return dictionary[key];
    }

    return 0;
}

export const Upkeep = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    const memoRows = React.useMemo(() => {

        if (!worldState.GameSettings) {
            return [];
        }

        const rows: BaseRowProps[] = [];

        for (let solarSystem of props.solarSystems) {

            const ships = tryGet(solarSystem.solarSystem.itemsDetail?.upkeep?.fromShips, worldState.GameSettings.solarSystem.shipUpkeepItemTypeName);
            const hangarSpace = tryGet(solarSystem.solarSystem.itemsDetail?.upkeep?.negatedByHangarSpace, worldState.GameSettings.solarSystem.shipUpkeepItemTypeName);
            const net = tryGet(solarSystem.solarSystem.itemsDetail?.upkeep?.netTotal, worldState.GameSettings.solarSystem.shipUpkeepItemTypeName);
            const income = tryGet(solarSystem.solarSystem.itemsDetail?.production.netTotal, worldState.GameSettings.solarSystem.shipUpkeepItemTypeName) + tryGet(solarSystem.solarSystem.itemsDetail?.harvested.netTotal, worldState.GameSettings.solarSystem.shipUpkeepItemTypeName);
            const final = tryGet(solarSystem.solarSystem.itemsDetail?.netFinal, worldState.GameSettings.solarSystem.shipUpkeepItemTypeName);

            const quantity = tryGet(solarSystem.solarSystem.items, worldState.GameSettings.solarSystem.shipUpkeepItemTypeName);

            const expiryHours = final < 0 && quantity > 0 ? quantity / Math.abs(final) : undefined;

            const row = {
                id: solarSystem.solarSystem.solarSystemId.toString(),
                solarSystem: solarSystem.solarSystem,
                label: <SelectSolarSystemLink solarSystem={solarSystem.solarSystem} hideActionIcons />,
                ships,
                hangarSpace,
                net,
                income,
                final,
                expiryHours
            };

            rows.push(row);
        }

        rows.push({
            id: "total",
            label: "Total",
            ships: CollectionHelper.sumOfArrayValue(rows, x => x.ships),
            hangarSpace: CollectionHelper.sumOfArrayValue(rows, x => x.hangarSpace),
            net: CollectionHelper.sumOfArrayValue(rows, x => x.net),
            income: CollectionHelper.sumOfArrayValue(rows, x => x.income),
            final: CollectionHelper.sumOfArrayValue(rows, x => x.final)
        });

        return rows;

    }, [props.solarSystems]);

    return <>
        <Paragraph>
            Negative hangar space values indicate spare hangar capacity. The final figure shows the net outcome <UpkeepIconNoQuantity /> in a system, accounting for harvesting, ship upkeep, manufactories, raw resource processing etc. The system value accounts for <UpkeepIconNoQuantity /> activity in the system unrelated to ships, which will include harvesting and other income but may also include consumption by manufactories.
        </Paragraph>
        <Table isFullWidth heading={
            <>
                <th>
                </th>
                <th>
                    Ships
                </th>
                <th>
                    Hangar Space
                </th>
                <th>
                    Net
                </th>
                <th>
                    System
                </th>
                <th>
                    Final
                </th>
                <th>
                    Expires
                </th>
                <th></th>
            </>
        }>
            {memoRows.map(x => <Row key={x.id}
                {...x}
                sourceSolarSystemId={sourceSolarSystemId}
                setSourceSolarSystemId={setSourceSolarSystemId}
                showSendButtons={props.solarSystems.length > 1}
            />)}
        </Table>
    </>;
});