import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { SolarSystemActionIcons } from '../../../Components/FusionShift/Icons/SolarSystemActionIcons';
import { SolarSystemClaimTypeIcon } from '../../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon';
import { SolarSystemForListDangerIcons } from '../../../Components/FusionShift/Icons/SolarSystemDangerIcons';
import { SolarSystemHelper } from '../../../Helpers/SolarSystemHelper';
import { empire } from '../../../Navigation/Routing/Misc';
import { selected_system } from '../../../Navigation/Routing/SolarSystem';

export const SolarSystemList = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const appState = React.useContext(AppStateContext);

    function setSolarSystem(solarSystemId: number) {
        appState.changeSolarSystemId(solarSystemId);
        appState.navigate(selected_system)
    }

    if (!solarSystemState.SolarSystems || !solarSystemState.HasLoaded) {
        return <LoadingSpinner />;
    }

    return <SubPanel
        heading="Your Systems"
        backgroundImage={SolarSystemHelper.solarSystemBackground(solarSystemState.SolarSystem)}
    >
        <ul>
            {solarSystemState.SolarSystems.map((s, i) => {

                const isSelected = !!solarSystemState.SolarSystem && s.solarSystemId === solarSystemState.SolarSystem.solarSystemId;

                return <li key={i}>
                    <a className={`${isSelected ? " selected" : ""}`}
                        onClick={() => {
                            setSolarSystem(s.solarSystemId);
                        }}>
                        <SolarSystemClaimTypeIcon solarSystem={s} />
                        {s.name}
                    </a>
                    <SolarSystemActionIcons solarSystem={s} hideSelectAndGoTo />
                    <SolarSystemForListDangerIcons solarSystem={s} isPulledRight={false} />
                </li>;
            }
            )}
            <li>
                <hr />
            </li>
            <li>
                <Link to={empire} >Empire Overview</Link>
            </li>
        </ul>
    </SubPanel>;
});