import * as React from 'react';
import { MobileMenuStateContext } from "../../ApplicationState/ContextRoot";
import { PendingAgentActionIcon, PendingPoliticsVoteIcon, UnreadCombatReportIcon, UnreadContractIcon, UnreadMessageIcon, UnreadMissionIcon, UnreadNotificationIcon } from "../../Components/FusionShift/Icons/UnreadIcons";
import { CreditsLink } from "../Menu/Submenus/CreditsLink";

export const IconTray = () => {

    const mobileMenuState = React.useContext(MobileMenuStateContext);

    return <div className="icon-tray" onClick={_ => mobileMenuState.close()}>

        <CreditsLink isMobile dontCountUp />

        <PendingPoliticsVoteIcon />
        <PendingAgentActionIcon />
        <UnreadContractIcon />

        <UnreadMissionIcon />
        <UnreadCombatReportIcon />
        <UnreadNotificationIcon />
        <UnreadMessageIcon />
    </div>;
}