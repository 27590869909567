import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../ApplicationState/ContextRoot';
import { BottomMenuBar } from './Menu/BottomMenuBar';
import { BrandImage } from './Menu/Controls/BrandImage';
import { CurrentSolarSystemResourceBar } from './Menu/Items/ResourceBar';
import { TopMenuBar } from './Menu/TopMenuBar';

export const NavBar = observer(() => {

    const appState = React.useContext(AppStateContext);

    if (appState.ApplicationState !== "Ready") {
        return null;
    }

    return <>
        <div className="nav-holder is-hidden-mobile">
            <BrandImage />
            <TopMenuBar />
            <BottomMenuBar />
        </div>
        <CurrentSolarSystemResourceBar />
    </>;
});