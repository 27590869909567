import React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { RawResourceIcon } from "../../Icons/Items/RawResourceIcon";

type Props = {
    rawResourceType?: ItemType | undefined | false,
    hideTextOnMobile?: boolean,
    noText?: boolean
}

export const RawResourceTypeLink = (props: Props) => {

    if (!props.rawResourceType) {
        return null;
    }

    return <>
        <RawResourceIcon />
        {!props.noText && <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {props.rawResourceType.name}
        </span>}
    </>;
};