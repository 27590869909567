import { observer } from 'mobx-react-lite';
import { IFleet, ISolarSystem } from '../../../../ApplicationState/ApiClient';
import { IconButton } from '../../../../Components/Base/Buttons/IconButton';
import { SplitLayout } from '../../../../Components/Base/Containers/SplitLayout';
import { H5 } from '../../../../Components/Base/Text/H';
import { FleetIcon } from '../../../../Components/FusionShift/Icons/FleetIcon';
import { EmblemImage } from '../../../../Components/FusionShift/Images/EmblemImage';
import { LocationLink } from '../../../../Components/FusionShift/Links/LocationLink';
import { PlayerLink } from '../../../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { SendShipsBag } from '../../SendShipsBag';
import { SendShipsPositionalRender } from '../SendShipsPositionalRender';

type Props = {
    sendShipsBag: SendShipsBag
}

type TargetFleetProps = {
    fleet: IFleet
}

const TargetFleet = (props: TargetFleetProps) => {

    return <ul>
        <li>
            {`Fleet `}
            <FleetIcon fleet={props.fleet} />
            {ValueFormatter.formatMass(props.fleet.mass, true)}

        </li>
        <li>
            {`Destination`}&nbsp;
            {!!props.fleet.targetSolarSystem && <SolarSystemLink solarSystem={props.fleet.targetSolarSystem} />}
            {!props.fleet.targetSolarSystem && <LocationLink x={props.fleet.targetX} y={props.fleet.targetY} />}
            {props.fleet.isTargetEstimated && ' (Estimated)'}
        </li>
        <li>
            {!!props.fleet.owner && <PlayerLink player={props.fleet.owner} />}
            {!props.fleet.owner && "Unknown Owner"}
        </li>
        {props.fleet.owner && props.fleet.owner.emblemFilename && props.fleet.owner.emblemFilename.length > 0 && <li className="image-wrapper">
            <EmblemImage filename={props.fleet.owner.emblemFilename} />
        </li>}
    </ul>
}

type TargetSolarSystemProps = {
    solarSystem: ISolarSystem
}

const TargetSolarSystem = (props: TargetSolarSystemProps) => {

    return <ul>
        <li>
            <SolarSystemLink solarSystem={props.solarSystem} />
        </li>
        <li>
            {!!props.solarSystem.owner && <PlayerLink player={props.solarSystem.owner} />}
            {!props.solarSystem.owner && "Unoccupied"}
        </li>
        {props.solarSystem.owner && props.solarSystem.owner.emblemFilename && props.solarSystem.owner.emblemFilename.length > 0 && <li className="image-wrapper">
            <EmblemImage filename={props.solarSystem.owner.emblemFilename} />
        </li>}
    </ul>;
}

export const TargetRender = observer((props: Props) => {

    if (!props.sendShipsBag.HasTarget) {
        return null;
    }

    function swapSourceAndTarget() {
        props.sendShipsBag.swapSourceAndTarget();
    }

    const isTargettingSelf = props.sendShipsBag.TargetSolarSystem !== undefined &&
        props.sendShipsBag.TargetSolarSystem.solarSystemId === props.sendShipsBag.init.sourceSolarSystem.solarSystemId;

    if (isTargettingSelf) {
        return null;
    }

    const canSwitch = props.sendShipsBag.TargetSolarSystem !== undefined && props.sendShipsBag.TargetSolarSystem.playerId === props.sendShipsBag.init.player.playerId;

    return <SplitLayout
        className="target-render"
        isFlipOnMobile
        horizontalAfter='mobile'
        isRightEmpty={!props.sendShipsBag.HasTarget}
        left={
            <SendShipsPositionalRender {...props} />
        }
        right={
            <div className='send-ships-target'>
                <div className="source">
                    <H5>Source</H5>
                    <TargetSolarSystem solarSystem={props.sendShipsBag.init.sourceSolarSystem} />
                </div>
                <div className="switch">
                    {canSwitch && <IconButton
                        isGiant
                        type='nav'
                        icon={IconHelper.General.Swap}
                        action={() => swapSourceAndTarget()}
                    />}
                </div>
                <div className="target">
                    {props.sendShipsBag.HasTarget && <>
                        <H5>Target</H5>
                        {!!props.sendShipsBag.TargetSolarSystem && <TargetSolarSystem solarSystem={props.sendShipsBag.TargetSolarSystem} />}
                        {!!props.sendShipsBag.TargetFleet && <TargetFleet fleet={props.sendShipsBag.TargetFleet} />}
                    </>}
                </div>
            </div>
        }
    />
});