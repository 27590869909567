import React from "react";
import { FleetMovementType, IRepeatFleet, ISolarSystemDetail, SolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../Components/Base/Buttons/ButtonHolder";
import { ExpandButton } from "../../../Components/Base/Buttons/ExpandButton";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { ErrorIcon } from "../../../Components/Base/Icon";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { FleetArrivalDescription } from "../../../Components/FusionShift/FleetArrivalDescription";
import { FleetMovementTypeIcon } from "../../../Components/FusionShift/Icons/FleetMovementTypeIcon";
import { ShipQuantityIcons } from "../../../Components/FusionShift/Icons/ShipQuantityIcons";
import { ItemsControl } from "../../../Components/FusionShift/Items/ItemsControl";
import { ItemTypeLink } from "../../../Components/FusionShift/Links/Items/ItemTypeLink";
import { SolarSystemLink } from "../../../Components/FusionShift/Links/SolarSystemLink";
import { ShipList } from "../../../Components/FusionShift/Ships/ShipList";
import { SolarSystemWrapper } from "../../../Entities/SolarSystem/SolarSystemWrapper";
import { CelestialHelper } from "../../../Helpers/CelestialHelper";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { FleetHelper } from "../../../Helpers/FleetHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { send_ships_edit_repeat } from "../../../Navigation/Routing/Misc";
import { CollectionText } from "../Components/CollectionText";
import { RepeatFleetItemIcons } from "../Components/RepeatFleetItemIcons";
import { ShipTabs } from "../FleetControl";
import { EditRepeatMinersForm } from "../Forms/EditMinersForm";

type ListProps = {
    showMinerEdit?: boolean,
    wrapper?: SolarSystemWrapper,
    solarSystem: ISolarSystemDetail,
    fleets: IRepeatFleet[] | undefined,
    reloadCallback: (solarSystem: SolarSystemDetail, goToTab?: ShipTabs | undefined) => any;
}

type Props = {
    solarSystem: ISolarSystemDetail,
    fleet: IRepeatFleet,
    reloadCallback: (solarSystem: SolarSystemDetail, goToTab?: ShipTabs | undefined) => any;
}

const RepeatFleetRow = (props: Props) => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [isExpanded, setIsExpanded] = React.useState(false);

    if (!props.fleet || !playerState.Player ||
        !worldState.ShipTypeSettings ||
        !worldState.CelestialTypeSettings ||
        !worldState.ItemTypeSettings
    ) {
        return null;
    }

    function reload(solarSystemResponse: SolarSystemDetail) {
        if (solarSystemResponse) {
            props.reloadCallback(solarSystemResponse, solarSystemResponse.repeatFleets && solarSystemResponse.repeatFleets.length > 0 ? "Repeats" : undefined);
        }
    }

    function delay() {
        return apiState.FleetClient.delayRepeatFleet(props.solarSystem.solarSystemId, props.fleet.repeatFleetId).then(reload);
    }

    function pause() {
        return apiState.FleetClient.pauseRepeatFleet(props.solarSystem.solarSystemId, props.fleet.repeatFleetId).then(reload);
    }

    function unpause() {
        return apiState.FleetClient.unpauseRepeatFleet(props.solarSystem.solarSystemId, props.fleet.repeatFleetId).then(reload);
    }

    function sendNow() {
        return apiState.FleetClient.sendRepeatFleetNow(props.solarSystem.solarSystemId, props.fleet.repeatFleetId).then(reload);
    }

    function stopRepeating() {
        return apiState.FleetClient.cancelRepeatFleet(props.solarSystem.solarSystemId, props.fleet.repeatFleetId).then(reload);
    }

    function edit() {

        if (appState.SolarSystemId !== props.solarSystem.solarSystemId) {
            appState.changeSolarSystemId(props.solarSystem.solarSystemId);
        }

        appState.navigate(send_ships_edit_repeat(props.fleet.repeatFleetId));
    }

    const hasError = props.fleet.lastFailureReason && props.fleet.lastFailureReason.length > 0;
    const date = props.fleet.isPaused ? "Paused" : ValueFormatter.formatTimeOrDate(props.fleet.nextLaunchDate, false, true, false);
    const hasCollection = CollectionHelper.isAnyQuantityInDictionary(props.fleet.collection?.items);

    const isDue = !props.fleet.isPaused && props.fleet.nextLaunchDate < new Date();
    const availability = FleetHelper.areEnoughShipsAvailable(props.solarSystem.availableShips, props.fleet.ships);

    const currentFleet = props.solarSystem.fleets && props.solarSystem.fleets.find(x => x.repeatFleetId === props.fleet.repeatFleetId);

    const itemType = CelestialHelper.itemTypeFromCelestial(worldState.CelestialTypeSettings, worldState.ItemTypeSettings, props.fleet.targetCelestial);

    return <>
        <div className="icons">
            <FleetMovementTypeIcon movementType={props.fleet.movementType} isPlayerOwner={true} />
            <RepeatFleetItemIcons fleet={props.fleet} celestialTypeSettings={worldState.CelestialTypeSettings} itemTypeSettings={worldState.ItemTypeSettings} />
            {hasError && <ErrorIcon title={props.fleet.lastFailureReason} />}
        </div>
        <div className="ship-icons">
            <ShipQuantityIcons shipTypeSettings={worldState.ShipTypeSettings} ships={props.fleet.ships} />
        </div>
        <div className="target">
            {props.fleet.targetSolarSystem && <SolarSystemLink solarSystem={props.fleet.targetSolarSystem} dontShowAttack extra={props.fleet.targetCelestial ? props.fleet.targetCelestial.name : null} />}
        </div>
        <div className="timing">
            {props.fleet.timesRemaining && `${props.fleet.timesRemaining}x, `}
            {`Every ${props.fleet.delayHours} ${ValueFormatter.pluralize("hour", props.fleet.delayHours)}`}
            <br />
            {isDue && "Due Now"}
            {!props.fleet.isPaused && !isDue && `Next: ${date}`}
            {props.fleet.isPaused && <span className="has-text-warning">Paused</span>}
        </div>
        <div className="current">
            {currentFleet !== undefined && <>
                <FleetMovementTypeIcon movementType={currentFleet.movementType} />
                <FleetArrivalDescription fleet={currentFleet} isCompact />
            </>
            }
        </div>
        <div className="buttons">
            <ButtonHolder isPulledRight>
                <Button
                    title={availability === "partial" ? "Not all of the configured ships are available at this time" : undefined}
                    type={availability === true ? "action" : "warning"}
                    hideTextOnMobile
                    isSmall
                    icon={IconHelper.Ships.Send}
                    action={sendNow}
                    text="Send"
                    isDisabled={availability === false}
                />
                <Button
                    type="danger"
                    hideTextOnMobile
                    isSmall
                    icon={IconHelper.Ships.RepeatOff}
                    action={stopRepeating}
                    text=""
                    title="Cancel repeat fleet"
                />
                <Button
                    type="nav"
                    hideTextOnMobile
                    isSmall
                    icon={IconHelper.General.Edit}
                    action={edit}
                    text=""
                    title="Edit repeat fleet"
                />
                {!props.fleet.isPaused &&
                    <Button
                        type="warning"
                        hideTextOnMobile
                        isSmall
                        icon={IconHelper.Ships.RepeatDelay}
                        action={delay}
                        title="Delay repeat by 1 hour"
                    />}
                {props.fleet.isPaused &&
                    <Button
                        type="action"
                        hideTextOnMobile
                        isSmall
                        icon={IconHelper.Ships.RepeatUnpause}
                        action={unpause}
                        title="Unpause repeat fleet"
                    />}
                {!props.fleet.isPaused &&
                    <Button
                        type="warning"
                        hideTextOnMobile
                        isSmall
                        icon={IconHelper.Ships.RepeatPause}
                        action={pause}
                        title="Pause repeat fleet"
                    />}
                <ExpandButton isSmall isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
            </ButtonHolder>
        </div>
        <div className="expanded">
            {isExpanded &&
                <SplitLayout
                    left={!!props.fleet.ships && Object.keys(props.fleet.ships).length > 0 && <ShipList ships={props.fleet.ships} />}
                    right={<>
                        {hasError && <Paragraph type="danger"><ErrorIcon /> Last attempt failed: {props.fleet.lastFailureReason}</Paragraph>}
                        {!!props.fleet.itemsToSend && <ItemsControl items={props.fleet.itemsToSend} expandDetails />}
                        {hasCollection && <CollectionText movementType={props.fleet.movementType} items={props.fleet.collection?.items} />}
                        {props.fleet.movementType === FleetMovementType.Mine && itemType !== undefined && <Paragraph>
                            <ItemTypeLink itemType={itemType} /> will be mined from {CelestialHelper.fullname(props.fleet.targetCelestial!, worldState.CelestialTypeSettings)}.
                        </Paragraph>}
                    </>}
                />
            }
        </div>
    </>;
};

export const RepeatFleetList = (props: ListProps) => {

    const [isEditingMiners, setIsEditingMiners] = React.useState(false);

    if (!props.fleets || props.fleets.length === 0) {
        return null;
    }


    if (isEditingMiners) {
        return <EditRepeatMinersForm {...props} fleets={props.fleets} cancelCallback={() => setIsEditingMiners(false)} />;
    }

    const showMinerEdit = props.showMinerEdit && props.fleets.find(x => x.movementType === FleetMovementType.Mine) !== undefined;

    return <>
        <div className="repeat-fleet-table">
            {props.fleets.map(q =>
                <RepeatFleetRow
                    key={q.repeatFleetId}
                    fleet={q}
                    {...props}
                />)}
        </div>
        {showMinerEdit &&
            <ButtonHolder isPulledRight>
                <Button type="nav" icon={IconHelper.Ships.movementType(FleetMovementType.Mine)} text="Edit Mining Fleets" action={() => setIsEditingMiners(true)} />
            </ButtonHolder>
        }
    </>;
};
