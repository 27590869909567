import { AgentStatus, IAgentDetail } from '../../../ApplicationState/ApiClient';
import { Table } from '../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { RelationHelper } from '../../../Helpers/RelationHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    agent: IAgentDetail
}

export const FleetPositionComponent = (props: Props) => {

    if (props.agent.fleetPosition === undefined) {
        return null;
    }

    function describeStatus() {
        switch (props.agent.status) {
            case AgentStatus.Captured:
                return "The agent is a prisoner and will be unloaded at the target. If the target belongs to the agent's owner then the agent will be freed.";
            case AgentStatus.Covert:
                return "They are preparing to deploy as a covert agent upon arrival to the target.";
            case AgentStatus.Overt:
                return "The agent is openly travelling as a diplomat.";
            case AgentStatus.Stowaway:
                const isAllied = RelationHelper.isAlliedWith(props.agent.player, props.agent.fleetPosition?.targetSolarSystem?.owner);
                const desc = isAllied ? "go overt" : "remain covert";
                return "The agent is stowing away aboard one of the ships in the fleet. The agent will " + desc + " upon arrival.";
        }

        return "";
    }

    return <>
        <Paragraph>
            {props.agent.name} is currently travelling as part of a fleet. {describeStatus()}
        </Paragraph>
        <Table >
            <tr>
                <td>
                    From
                </td>
                <td>
                    {props.agent.fleetPosition.sourceSolarSystem && <SolarSystemLink solarSystem={props.agent.fleetPosition.sourceSolarSystem} />}
                </td>
            </tr>
            <tr>
                <td>
                    Departed
                </td>
                <td>
                    {ValueFormatter.formatDateFromNowWithMomentOrTimeOrDate(props.agent.fleetPosition.departureDate)}
                </td>
            </tr>
            <tr>
                <td>
                    To
                </td>
                <td>
                    {props.agent.fleetPosition.targetSolarSystem && <SolarSystemLink solarSystem={props.agent.fleetPosition.targetSolarSystem} />}
                </td>
            </tr>
            <tr>
                <td>
                    Arrival
                </td>
                <td>
                    {ValueFormatter.formatDateFromNowWithMomentOrTimeOrDate(props.agent.fleetPosition.arrivalDate)}
                </td>
            </tr>
        </Table>
    </>;
}