import { IWorldMinimal } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Button } from "../../Base/Buttons/Button";

export function createDateControls(world: IWorldMinimal, date: Date, setDate: (d: Date) => any) {

    function modifyBy(days: number) {
        return new Date(date.getTime() + (1000 * 60 * 60 * 24 * days));
    }

    const next = modifyBy(7);
    const previous = modifyBy(-7);

    const canNext = next.getTime() < new Date().getTime();
    const canPrevious = previous.getTime() > world.createdDate.getTime() + 7 * 24 * 60 * 60 * 1000;

    return [
        <Button
            isDisabled={!canPrevious}
            type="nav"
            icon={IconHelper.General.Previous}
            action={() => setDate(previous)}
        />,
        <Button
            isDisabled={!canNext}
            type="nav"
            icon={IconHelper.General.Next}
            action={() => setDate(next)}
        />
    ];
}