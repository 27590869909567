import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MissionLogStateContext, UnreadStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { agents } from '../../../Navigation/Routing/Agents';
import { contracts } from '../../../Navigation/Routing/Contracts';
import { missions } from '../../../Navigation/Routing/Help';
import { combatReports, messages, playerNotifications } from '../../../Navigation/Routing/NotificationsEtc';
import { politics_home } from '../../../Navigation/Routing/Politics';
import { IconLink } from '../../Base/IconLink';

export const UnreadMissionIcon = observer(() => {

    const missionLogState = React.useContext(MissionLogStateContext);

    const hasUnread = !(missionLogState.MissionLog === undefined || missionLogState.MissionLog.rewardPendingMissions.length === 0);

    return <IconLink
        className={hasUnread ? "unread" : ""}
        icon={IconHelper.Missions.Missions}
        to={missions}
        title={hasUnread ? "You have 1 or more mission(s) pending reward" : "Missions"}
    />;
});

export const UnreadMessageIcon = observer(() => {

    const unreadState = React.useContext(UnreadStateContext);

    const hasUnread = unreadState.HasUnreadMessage;

    return <IconLink
        className={hasUnread ? "unread" : ""}
        icon={IconHelper.General.Message}
        to={messages}
        title={hasUnread ? "You have 1 or more unread message(s)" : "Messages"}
    />;
});

export const UnreadCombatReportIcon = observer(() => {

    const unreadState = React.useContext(UnreadStateContext);

    const hasUnread = unreadState.HasUnreadCombatReport;

    return <IconLink
        className={hasUnread ? "unread" : ""}
        icon={IconHelper.Combat.CombatReport}
        to={combatReports}
        title={hasUnread ? "You have 1 or more unread combat report(s)" : "Combat Reports"}
    />;
});

export const UnreadContractIcon = observer(() => {

    const unreadState = React.useContext(UnreadStateContext);

    const hasUnread = unreadState.HasPendingAssignedContract;

    return <IconLink
        className={hasUnread ? "unread" : ""}
        icon={IconHelper.Economy.Contract}
        to={contracts}
        title={hasUnread ? "You have 1 or more unread contract(s)" : "Contracts"}
    />;
});

export const UnreadNotificationIcon = observer(() => {

    const unreadState = React.useContext(UnreadStateContext);

    const hasUnread = unreadState.HasUnreadPlayerNotification;

    return <IconLink
        className={hasUnread ? "unread" : ""}
        icon={IconHelper.Categories.Notification}
        to={playerNotifications}
        title={hasUnread ? "You have 1 or more unread notification(s)" : "Notifications"}
    />;
});

export const PendingPoliticsVoteIcon = observer(() => {

    const unreadState = React.useContext(UnreadStateContext);

    const hasUnread = unreadState.HasPendingPolicyVote || unreadState.HasPendingPresidentialTermVote;

    return <IconLink
        className={hasUnread ? "unread" : ""}
        icon={IconHelper.Politics.Vote}
        to={politics_home}
        title={"Pending Policy Vote"}
    />;
});

export const PendingAgentActionIcon = observer(() => {

    const unreadState = React.useContext(UnreadStateContext);

    const hasUnread = unreadState.HasPendingAgentAction;

    return <IconLink
        className={hasUnread ? "unread" : ""}
        icon={IconHelper.Agents.Agent}
        to={agents}
        title={"Agent ready to act"}
    />;
});

export const UnreadIcons = observer(() => {

    return <>
        <UnreadMessageIcon />
        <UnreadCombatReportIcon />
        <UnreadNotificationIcon />
        <UnreadContractIcon />
        <PendingPoliticsVoteIcon />
    </>;
});