import { IPlayer } from '../../../ApplicationState/ApiClient';
import { Button, ButtonTypes } from '../../../Components/Base/Buttons/Button';

type Props = {
    player: IPlayer,
    type: ButtonTypes,
    text: string,
    icon: string,
    confirm: string,
    action: (playerId: number) => any
}

export const PlayerButton = (props: Props) => {

    return <Button
        key={`${props.text}_${props.player.playerId}`}
        type={props.type}
        text={props.text}
        icon={props.icon}
        action={() => props.action(props.player.playerId)}
        confirm={`Are you sure you want to ${props.confirm} ${props.player.name}?`}
    />;
};