import { ContractFleet, ContractFleetStatus } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { CreditsIconWithQuantity } from '../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ContractFleetStatusDescription } from './ContractFleetStatusDescription';

type SharedProps = {
    hideOwner?: boolean
}

type Props = SharedProps & {
    contractFleets: ContractFleet[]
}

type RowProps = SharedProps & {
    contractFleet: ContractFleet
}

const Row = (props: RowProps) => {

    return <tr >
        {!props.hideOwner && <td>
            <PlayerLink player={props.contractFleet.player} />
        </td>}
        <td>
            <ContractFleetStatusDescription contractFleetStatus={props.contractFleet.status} />
            {props.contractFleet.failureReason.length > 0 && ` - ${props.contractFleet.failureReason}`}
        </td>
        <td>
            {ValueFormatter.formatDateFromNowWithMomentOrTimeOrDate(props.contractFleet.arrivalDate)}
        </td>
        <td className={props.contractFleet.status === ContractFleetStatus.Failed ? "has-text-strikethrough" : ""}>
            {ValueFormatter.formatTimeOrDate(props.contractFleet.completedOrFailedDate)}
        </td>
        <td>
            <CreditsIconWithQuantity quantity={props.contractFleet.creditsPaid} />
        </td>
    </tr>;
}

export const ContractFleetTable = (props: Props) => {

    if (props.contractFleets.length === 0) {
        return null;
    }

    return <SubPanel heading={{ text: "Fleets", icon: IconHelper.Ships.Ship }} isUnpadded>
        <Table isFullWidth
            heading={
                <>
                    {!props.hideOwner &&
                        <th>
                            Owner
                        </th>}
                    <th>
                        Status
                    </th>
                    <th>
                        Arrival
                    </th>
                    <th>
                        Completed / Failed
                    </th>
                    <th>
                        Paid
                    </th>
                </>
            }>
            {props.contractFleets.map(x => <Row key={x.contractFleetId} {...props} contractFleet={x} />)}
        </Table>
    </SubPanel>;
}