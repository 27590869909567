import { IInstallationTypeSettings, IShipTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemBuilder, ProblemCategory, ProblemsMaybe } from "../Problem";
import { dronesProblems } from "./DronesProblems";
import { hangarSpaceProblems } from "./HangarSpaceProblems";
import { idleMinersProblems, notEnoughMinersProblems } from "./MinerProblems";

export const shipsProblemBuilder = new ProblemBuilder(ProblemCategory.Ships);

export function shipsProblems(shipTypeSettings: IShipTypeSettings, installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    return shipsProblemBuilder.merge(
        hangarSpaceProblems(wrapper),
        notEnoughMinersProblems(shipTypeSettings, wrapper),
        idleMinersProblems(shipTypeSettings, wrapper),
        dronesProblems(installationTypeSettings, wrapper)
    );
}