export type SolarSystemViewMode = "unexplored" | "explored";

export type GalaxyMapPreferences = {
    isFullHeight: boolean,
    solarSystemDisplay?: SolarSystemViewMode | undefined,
    showFleets: boolean
}

export const defaultGalaxyMapPreferences: GalaxyMapPreferences = {
    isFullHeight: true,
    showFleets: true
}