import * as React from 'react';
import { Cost, ItemType, IWorldEffects } from '../../../ApplicationState/ApiClient';
import { Paragraph } from '../../Base/Text/Paragraph';
import { CostControl } from '../Items/CostControl';
import { CostModifiedLink } from '../Links/CostModified';
import { BuildValidation } from './BuildValidation';

type Props = {
  buildError: string | ItemType[] | undefined,
  availableItems: { [key: string]: number } | undefined
  worldEffects: IWorldEffects,
  cost: Cost,
  allOnOneLine?: boolean
};

export const BuildSummary = (props: Props) => {

  return <>
    <Paragraph className="has-text-weight-bold">To Build:</Paragraph>
    <CostControl cost={props.cost} availableItems={props.availableItems} onePerLine={!props.allOnOneLine} />
    <CostModifiedLink modifier={props.worldEffects.installationCostModification} wrapped />
    <BuildValidation buildError={props.buildError} />
  </>;
};