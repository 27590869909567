import { Link } from 'react-router-dom';
import { IPagedOfCurrencyTransactionDetail } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { PremiumIcon } from '../../../Components/FusionShift/Icons/PremiumIcon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { premium_products } from '../../../Navigation/Routing/Premium';
import { CurrencyTransactionTable } from './CurrencyTransactionTable';

type Props = {
    balance: number,
    currencyTransactions: IPagedOfCurrencyTransactionDetail | undefined,
    onLinkClicked: (pageIndex: number) => void | Promise<void>
}

export const CurrencyTransactionDetails = (props: Props) => {
    return <SubPanel heading={{ text: "Premium Currency", icon: IconHelper.Premium.Currency }}>
        <Paragraph>
            Current balance: <PremiumIcon amount={props.balance} />. Visit the <Link to={premium_products}>products</Link> page to top up your balance.
        </Paragraph>
        {props.currencyTransactions && <CurrencyTransactionTable currencyTransactions={props.currencyTransactions} onLinkClicked={props.onLinkClicked} />}
    </SubPanel>;
};