import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { Icon } from '../../../Base/Icon';
import { ItemIconWithQuantity } from './ItemIconWithQuantity';

type Props = {
    quantity: number | undefined
}

type NoQuantityProps = {
    isUncoloured?: boolean
}

export const UpkeepIcon = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (props.quantity === undefined || props.quantity <= 0 || !worldState.GameSettings || !worldState.ItemTypeSettings) {
        return null;
    }

    return <ItemIconWithQuantity
        itemType={worldState.ItemTypeSettings.data[worldState.GameSettings.solarSystem.shipUpkeepItemTypeName]}
        quantity={props.quantity}
    />;
});

export const UpkeepIconNoQuantity = observer((props: NoQuantityProps) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.GameSettings || !worldState.ItemTypeSettings) {
        return null;
    }

    if (props.isUncoloured) {
        return <Icon icon={IconHelper.Items.resource(worldState.GameSettings.solarSystem.shipUpkeepItemTypeName)} />;
    }

    return <ItemIconWithQuantity itemType={worldState.ItemTypeSettings.data[worldState.GameSettings.solarSystem.shipUpkeepItemTypeName]} />;
});