import { SolarSystem } from "../../../ApplicationState/ApiClient";
import { Table } from "../../Base/Containers/Table";
import { SendShipsButton } from "../Buttons/SendShipsButton";
import { SolarSystemLink } from "../Links/SolarSystemLink";

type Props = {
    solarSystems: SolarSystem[] | undefined
}

export const SolarSystemsTable = (props: Props) => {
    if (!props.solarSystems) {
        return null;
    }

    return <Table isHoverable isFullWidth heading={
        <>
            <td>Solar System</td>
            <td>Position</td>
            <td>Size</td>
            <td></td>
        </>
    }>
        {props.solarSystems
            .sort((a, b) => (a.claimedDate?.getDate() ?? 0) - (b.claimedDate?.getDate() ?? 0))
            .map(s =>
                <tr key={s.solarSystemId}>
                    <td>
                        <SolarSystemLink solarSystem={s} />
                    </td>
                    <td>
                        {`${s.x}, ${s.y}`}
                    </td>
                    <td>
                        {s.size}
                    </td>
                    <td>
                        <SendShipsButton targetSolarSystem={s} hideTextOnMobile />
                    </td>
                </tr>)}
    </Table>;
};