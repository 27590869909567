import { ICombatReportShip, IShipTypeSettings } from "../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../Components/Base/Containers/Table";
import { Icon } from "../../../Components/Base/Icon";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { ShipTypeImage } from "../../../Components/FusionShift/Images/ShipTypeImage";
import { ItemsControl } from "../../../Components/FusionShift/Items/ItemsControl";
import { ShipTypeLink } from "../../../Components/FusionShift/Links/ShipTypeLink";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type Props = {
    isAttacker: boolean,
    shipTypeSettings: IShipTypeSettings,
    matchRowsTo: number
    ships: { [key: string]: ICombatReportShip; } | undefined,
}

const TotalRow = (props: Props) => {

    let totalParticipants = 0, totalSurvived = 0, totalDestroyed = 0;
    let anyMissing = false;

    let allCost: { [key: string]: number } = {};
    let destroyedCost: { [key: string]: number } = {};

    for (let key in props.ships!) {
        const ship = props.ships[key];

        totalParticipants += ship.participants === undefined ? 0 : ship.participants;
        totalSurvived += ship.survivors === undefined ? 0 : ship.survivors;

        anyMissing = anyMissing ||
            ship.participants === undefined || ship.survivors === undefined ||
            ship.participants === null || ship.survivors === null;

        if (ship.participants !== undefined) {

            const cost = key in props.shipTypeSettings.data ? props.shipTypeSettings.data[key].cost : undefined;

            if (cost !== undefined) {

                const thisCost = CollectionHelper.multiplyQuantityDictionary(cost.items, ship.participants!);
                allCost = CollectionHelper.mergeQuantityDictionaries([allCost, thisCost]);

                const destroyed = ship.participants !== undefined && ship.survivors !== undefined ? ship.participants - ship.survivors : 0;

                if (destroyed > 0) {

                    totalDestroyed += destroyed;

                    const thisDestroyedCost = CollectionHelper.multiplyQuantityDictionary(cost.items, destroyed);
                    destroyedCost = CollectionHelper.mergeQuantityDictionaries([destroyedCost, thisDestroyedCost]);
                }
            }
        }
    }

    return <>
        <tr className="has-text-weight-bold">
            <td>
                Total
            </td>
            <td></td>
            <td>
                {ValueFormatter.formatLocaleNumber(totalParticipants)}{anyMissing && "?"}
            </td>
            <td>
                {ValueFormatter.formatLocaleNumber(totalDestroyed)}{anyMissing && "?"}
            </td>
            <td>
                {ValueFormatter.formatLocaleNumber(totalSurvived)}{anyMissing && "?"}
            </td>
        </tr>
        <tr>
            <td colSpan={2}>
                All Value
            </td>
            <td colSpan={3}>
                <div className="is-pulled-right">
                    <ItemsControl items={allCost} /> {anyMissing && "?"}
                </div>
            </td>
        </tr>
        {totalParticipants - totalSurvived > 0 &&
            <tr>
                <td colSpan={2}>
                    Destroyed Value
                </td>
                <td colSpan={3}>
                    <div className="is-pulled-right">
                        <ItemsControl items={destroyedCost} /> {anyMissing && "?"}
                    </div>
                </td>
            </tr>
        }
    </>;
};

export const ShipsTable = (props: Props) => {

    if (props.ships === undefined) {
        return null;
    }

    const shipsToDisplay = Object.keys(props.ships)
        .map(s => {
            return {
                ship: props.shipTypeSettings!.data![s],
                item: props.ships![s]
            }
        })
        .sort(((a, b) => a.ship.order > b.ship.order ? 1 : -1));

    const additionalRowCount = props.matchRowsTo - shipsToDisplay.length;
    const additionalRows: number[] = [];
    for (var i = 0; i < additionalRowCount; i++) {
        additionalRows.push(i);
    }

    return <Table isHoverable isFixed isFullWidth heading={
        <>
            <td colSpan={2}>Ship</td>
            <td><Icon icon={IconHelper.Ships.CombatParticipant} title="Participants" /></td>
            <td><Icon icon={IconHelper.Ships.CombatDestroyed} title="Destroyed" /></td>
            <td><Icon icon={IconHelper.Ships.CombatSurvived} title="Survived" /></td>
        </>
    }>
        {shipsToDisplay.map(s => {
            return <tr key={s.ship.typeName}>
                <td>
                    <ShipTypeImage shipType={s.ship} size="small" />
                </td>
                <td>
                    <ShipTypeLink shipType={s.ship} hideTextOnMobile />
                </td>
                <td>
                    {ValueFormatter.formatLocaleNumber(s.item.participants !== undefined && s.item.participants !== null ? s.item.participants : "?")}
                </td>
                <td>
                    {ValueFormatter.formatLocaleNumber(s.item.survivors !== null && s.item.survivors !== undefined && s.item.participants !== null && s.item.participants !== undefined ? s.item.participants - s.item.survivors : "?")}
                </td>
                <td>
                    {ValueFormatter.formatLocaleNumber(s.item.survivors !== undefined && s.item.survivors !== null ? s.item.survivors : "?")}
                </td>
            </tr>
        })}
        {additionalRows.map(i => <tr key={i} className="is-hidden-mobile">
            <td colSpan={5}>
                <div className="placeholder">
                    ...
                </div>
            </td>
        </tr>)}
        <TotalRow {...props} />
    </Table>;
}

export const Ships = (props: Props) => {

    const title = props.isAttacker ? "Attackers" : "Defenders";

    if (!props.shipTypeSettings.data || !props.ships || Object.keys(props.ships).length === 0) {
        return <SubPanel heading={title}>
            <Paragraph type="prompt">No {props.isAttacker ? "attackers" : "defenders"} present in solar system</Paragraph>
        </SubPanel>;
    }

    return <SubPanel heading={title} isUnpadded>
        <ShipsTable {...props} />
    </SubPanel>;
};
