import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PlayerStateContext } from '../../../../ApplicationState/ContextRoot';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const FederationRequired = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!props.mission.MissionType.isFederationRequired || !playerState.Player) {
        return null;
    }

    const inFederation = playerState.Player.federation !== undefined;

    return <ObjectiveRow has={inFederation ? 1 : 0} requires={1} {...props}>
        Join a Federation
    </ObjectiveRow>;
});