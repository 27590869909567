import Axios from "axios";
import React, { useEffect } from "react";
import { EmblemLayer, Manifest } from "../../../../ApplicationState/ApiClient";
import { AppStateContext } from "../../../../ApplicationState/ContextRoot";
import { SvgHelper } from "../../../../Helpers/SvgHelper";

interface IProps {
  manifest: Manifest,
  layers: EmblemLayer[];
}

const generateSvg = (
  manifest: Manifest,
  element: string,
  fill: string,
  x: number,
  y: number,
  rotation: number,
  scale: number
) => {
  const replaced = element
    .replaceAll(manifest.placeholders.colour, fill)
    .replaceAll(manifest.placeholders.translationX, x.toString())
    .replaceAll(manifest.placeholders.translationY, y.toString())
    .replaceAll(manifest.placeholders.rotation, rotation.toString())
    .replaceAll(manifest.placeholders.scale, scale.toString());

  return SvgHelper.imageSrcFromSvgData(replaced);
};

function SvgRender(props: IProps) {
  const appState = React.useContext(AppStateContext);

  const emblemsCdnUrl = appState.ClientSettings?.emblemsCdnUrl;

  const [svgs, setSvgs] = React.useState<string[]>([]);

  const [cache, setCache] = React.useState<{ [key: string]: any }>({});

  useEffect(() => {
    (async () => {
      if (emblemsCdnUrl) {
        const urls = props.layers.map((x) => `${emblemsCdnUrl}${x.filename}`);

        const responses = await Promise.all(urls.map(async (x) => {
          if (!(x in cache)) {
            const result = await Axios.get(x);
            cache[x] = result;
          }

          return cache[x];
        }));

        const loadedSvgs = responses.map((response, index) => {

          let element = response.data;

          const currentLayer = props.layers[index];

          if (currentLayer && currentLayer.colourHex) {
            return generateSvg(
              props.manifest,
              element,
              currentLayer.colourHex,
              currentLayer.translationX,
              currentLayer.translationY,
              currentLayer.rotation,
              currentLayer.scale
            );
          }
          return "";
        });

        setSvgs(loadedSvgs);
      }
    })();
  }, [props.layers, emblemsCdnUrl]);

  return (
    <>
      {svgs.map((s, i) => (
        <img key={i} src={s} />
      ))}
    </>
  );
}

export default SvgRender;
