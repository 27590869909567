import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { StolenItems } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ClearFix } from '../../../Components/Base/ClearFix';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { TextAndIcon } from '../../../Components/Base/Text/TextAndIcon';
import { ResourceTypeLink } from '../../../Components/FusionShift/Links/Items/ResourceTypeLink';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ItemsHelper } from '../../../Helpers/ItemsHelper';
import { TimeHelper } from '../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';


export const Raiding = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [date, setDate] = React.useState(new Date());
    const [stolenItems, setStolenItems] = React.useState<StolenItems | undefined>(undefined);

    React.useEffect(() => {

        apiState.PlayerClient.getStolenItems(date).then(items => {
            setStolenItems(items);
        });

    }, [date]);

    const totals = React.useMemo(() => {

        if (stolenItems !== undefined && worldState.ItemTypeSettings !== undefined) {

            if (!CollectionHelper.isAnyInDictionary(stolenItems.totalItems)) {
                return "none";
            }

            const split = ItemsHelper.splitItems(stolenItems.totalItems, worldState.ItemTypeSettings.data);

            const resources = Object.keys(split.resources).map(x => {
                return {
                    itemType: worldState.ItemTypeSettings!.data[x],
                    quantity: split.resources[x]
                };
            })
                .sort((a, b) => a.itemType.order - b.itemType.order);

            return {
                resources,
                commodities: CollectionHelper.sumOfDictionary(split.commodities),
                rawResources: CollectionHelper.sumOfDictionary(split.rawResource),
            };
        }

    }, [stolenItems, worldState.ItemTypeSettings]);

    function adjustDate(days: number) {
        setDate(TimeHelper.addDays(date, days));
    }

    function isNextDisabled() {
        return stolenItems !== undefined && new Date() < stolenItems.end;
    }

    if (!worldState.ItemTypeSettings) {
        return null;
    }

    const isAny = !!stolenItems && !!totals && totals !== "none";

    return <>
        {stolenItems === undefined && <LoadingSpinner />}
        {stolenItems !== undefined && <ClearFix>
            <Paragraph>
                Showing stolen loot for the period {ValueFormatter.formatShortDate(stolenItems.start)} to {ValueFormatter.formatShortDate(stolenItems.end)}.
            </Paragraph>
            <ButtonHolder isPulledRight>
                <Button type="nav" text="Previous" icon={IconHelper.General.Previous} action={() => adjustDate(-7)} />
                <Button type="nav" text="Next" icon={IconHelper.General.Next} action={() => adjustDate(7)} isDisabled={isNextDisabled()} />
            </ButtonHolder>
        </ClearFix>}
        {totals === "none" && <Paragraph>No data available for that period</Paragraph>}
        {isAny &&
            <>
                {/* <DailyChart stolenItems={stolenItems!} typeSettings={worldState.TypeSettings} key={stolenItems!.start.toISOString()} /> */}
                <SubPanel heading={{ text: "Total", icon: IconHelper.Categories.Summary }} isUnpadded>
                    <Table>
                        {totals!.resources.map(x => <tr key={x.itemType.typeName}>
                            <td>
                                <ResourceTypeLink resourceType={x.itemType} />
                            </td>
                            <td>
                                {ValueFormatter.formatLocaleNumber(x.quantity)}
                            </td>
                        </tr>)}
                        {totals!.commodities > 0 && <tr key="commodities">
                            <td>
                                <TextAndIcon text="Commodities" icon={IconHelper.Items.Commodity} />
                            </td>
                            <td>
                                {ValueFormatter.formatLocaleNumber(totals!.commodities)}
                            </td>
                        </tr>}
                        {totals!.rawResources > 0 && <tr key="rawresources">
                            <td>
                                <TextAndIcon text="Raw Resources" icon={IconHelper.Items.RawResource} />
                            </td>
                            <td>
                                {ValueFormatter.formatLocaleNumber(totals!.rawResources)}
                            </td>
                        </tr>}
                    </Table>
                </SubPanel>
            </>
        }
    </>;
});