import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class GeneralIcons {

    public get Close() { return MaterialDesignIcons.close };
    public get View() { return MaterialDesignIcons.eye; }
    public get Find() { return MaterialDesignIcons.magnify; }
    public get Edit() { return MaterialDesignIcons.pencil; }
    public get Delete() { return MaterialDesignIcons.delete; }
    public get Exit() { return MaterialDesignIcons.exit; }
    public get OpenDropdown() { return MaterialDesignIcons.chevronDown };
    public get OpenInNewWindow() { return MaterialDesignIcons.openInNew; }

    public get InlineMenu() { return MaterialDesignIcons.menu };
    public get InlineMenuOff() { return MaterialDesignIcons.menuDown };

    public get Next() { return MaterialDesignIcons.chevronRight; };
    public get Previous() { return MaterialDesignIcons.chevronLeft; };

    public get Confirm() { return MaterialDesignIcons.tick; }
    public get Cancel() { return MaterialDesignIcons.cross; }

    public get On() { return MaterialDesignIcons.on; }
    public get Off() { return MaterialDesignIcons.off; }

    public get Plus() { return MaterialDesignIcons.plus; }
    public get Minus() { return MaterialDesignIcons.minus; }

    public get ZoomIn() { return MaterialDesignIcons.magnifyPlus; }
    public get ZoomOut() { return MaterialDesignIcons.magnifyMinus; }

    public get Up() { return MaterialDesignIcons.arrowUp; }
    public get Down() { return MaterialDesignIcons.arrowDown; }
    public get Left() { return MaterialDesignIcons.arrowLeft; }
    public get Right() { return MaterialDesignIcons.arrowRight; }

    // These icons are a bit out of place here and could do with being properly sorted so this file doesn't become general dump
    public get Offline() { return MaterialDesignIcons.offline };
    public get Time() { return MaterialDesignIcons.time; }
    public get Tag() { return MaterialDesignIcons.tag };
    public get Friend() { return MaterialDesignIcons.accountSupervisor };
    public get Flag() { return MaterialDesignIcons.flag; }
    public get Random() { return MaterialDesignIcons.question; }
    public get Help() { return MaterialDesignIcons.helpCircle; }
    public get Message() { return MaterialDesignIcons.email; }
    public get Missing() { return MaterialDesignIcons.missing; }
    public get Locked() { return MaterialDesignIcons.lock; }
    public get Unlocked() { return MaterialDesignIcons.lockOpen; }
    public get Search() { return MaterialDesignIcons.magnify; }
    public get Reload() { return MaterialDesignIcons.reload; }
    public get LandscapeToPortrait() { return MaterialDesignIcons.phoneRotateLandscape; }

    public get Victory() { return MaterialDesignIcons.trophy; }

    public get Chart() { return MaterialDesignIcons.chartLine; }

    public get ChangeArchive() { return MaterialDesignIcons.listCheck; }
    public get Swap() { return MaterialDesignIcons.swapHorizontal; }

    public onOrOff(on: boolean): string {
        return on ? this.On : this.Off;
    }

    public toggleExpanded(isExpanded: boolean): string {
        return !isExpanded ? MaterialDesignIcons.plus : MaterialDesignIcons.minus;
    }
}