import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { IPlayerMinimal, ISolarSystem, SolarSystemClaimType } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { RelationHelper } from "../../../Helpers/RelationHelper";
import { selected_system, systemById } from "../../../Navigation/Routing/SolarSystem";
import { SolarSystemActionIcons } from "../Icons/SolarSystemActionIcons";
import { SolarSystemClaimTypeIcon } from "../Icons/SolarSystemClaimTypeIcon";

type Props = {
    solarSystem: ISolarSystemLink,
    dontShowAttack?: boolean,
    hideActionIcons?: boolean,
    showClaimTypeIcon?: boolean,
    extra?: string | null | undefined,
    className?: string,
    isHiddenMobile?: boolean,
    allowWrap?: boolean,
    isMobileTitle?: boolean
}

export type ISolarSystemLink = {
    name: string,
    x: number,
    y: number,
    solarSystemId: number,
    owner?: IPlayerMinimal | undefined,
    claimType?: SolarSystemClaimType
}

export const SelectSolarSystemLink = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    function change() {
        appState.changeSolarSystemId(props.solarSystem.solarSystemId);
        appState.navigate(selected_system);
    }

    const className = classNames(
        "relation self",
        props.className,
        props.isHiddenMobile && "is-hidden-mobile",
        props.isMobileTitle && "is-mobile-title"
    );

    return <a className={className} onClick={change}>
        <SolarSystemClaimTypeIcon solarSystem={props.solarSystem} />
        {props.solarSystem.name}
        {!!props.extra && `: ${props.extra}`}
    </a>;
};

export const SolarSystemLink = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    const className = classNames(
        !props.hideActionIcons && "link-with-icons",
        props.allowWrap && "is-allow-wrap",
        props.isHiddenMobile && "is-hidden-mobile",
        props.isMobileTitle && "is-mobile-title"
    );

    return <span className={className}>
        <Link className={RelationHelper.relationClassName(props.solarSystem.owner, playerState.Player)} to={systemById(props.solarSystem.solarSystemId)}>
            {props.showClaimTypeIcon && <SolarSystemClaimTypeIcon solarSystem={props.solarSystem} />}
            {props.solarSystem.name}
            {!!props.extra && `: ${props.extra}`}
        </Link>
        {!props.hideActionIcons && <SolarSystemActionIcons solarSystem={props.solarSystem} />}
    </span>;
});

type IdProps = {
    solarSystemId: number | undefined
}

export const SolarSystemByIdLink = (props: IdProps) => {

    const apiState = React.useContext(ApiStateContext);

    const [solarSystem, setSolarSystem] = React.useState<ISolarSystem | undefined>(undefined);

    React.useEffect(() => {
        if (props.solarSystemId !== undefined) {
            apiState.SolarSystemClient.get(props.solarSystemId).then(setSolarSystem);
        }
    }, [props.solarSystemId]);

    if (!solarSystem) {
        return null;
    }

    return <SolarSystemLink solarSystem={solarSystem} />;
}