import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SecurityStatus } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { TimeHelper } from '../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { DismissButton } from '../../Base/Buttons/DismissButton';
import { MessageBoxWithIcon } from '../../Base/MessageBox';

type Props = {
    player: {
        name: string,
        playerId: number,
        securityStatus: SecurityStatus,
        beginnersProtectionUntil?: Date
    }
}

export const BeginnersProtectionMessage = (props: Props) => {

    if (props.player.securityStatus !== SecurityStatus.Protected) {
        return null;
    }

    return <MessageBoxWithIcon
        type="primary"
        icon={IconHelper.Security.BeginnersProtection}
        text={`${props.player.name} has beginner's protection${props.player.beginnersProtectionUntil ? ` until ${ValueFormatter.formatTimeOrDate(props.player.beginnersProtectionUntil, true)}` : ""}.`}
    />;
};

export const BeginnersProtectionEndingMessage = observer(() => {

    const [isDimissed, setIsDisimissed] = React.useState(false);

    const playerState = React.useContext(PlayerStateContext);

    if (isDimissed || !playerState.Player || !playerState.Player.beginnersProtectionUntil || !TimeHelper.isWithin24Hours(playerState.Player.beginnersProtectionUntil) || playerState.Player.securityStatus !== SecurityStatus.Protected) {
        return null;
    }

    const desc = TimeHelper.isSameDayAsToday(playerState.Player.beginnersProtectionUntil) ? "at" : "on";

    return <MessageBoxWithIcon
        type="warning"
        icon={IconHelper.Security.BeginnersProtection}
        button={<DismissButton action={() => setIsDisimissed(true)} />}
        text={`Your beginners protection will end ${desc} ${ValueFormatter.formatTimeOrDate(playerState.Player.beginnersProtectionUntil, true)}`}
    />;
});