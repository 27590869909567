import { observer } from "mobx-react-lite";
import * as React from 'react';
import { Policy, PolicyCategory, PolicyType } from "../../ApplicationState/ApiClient";
import { DescriptionStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { ExpandButton } from "../../Components/Base/Buttons/ExpandButton";
import { Table } from "../../Components/Base/Containers/Table";
import { PolicyCategoryIcon } from '../../Components/FusionShift/Icons/PolicyCategoryIcon';
import { PolicyTypeLink } from "../../Components/FusionShift/Links/PolicyTypeLink";
import { TypeDataDescriptionParagraph } from "../../Components/FusionShift/TypeDataDescriptionParagraph";
import { valuesFromEnum } from "../../Helpers/TypeHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";

type Props = {
    policies: Policy[]
}

type RowProps = {
    category: PolicyCategory,
    policy: { policy: Policy, policyType: PolicyType } | undefined
}

const PolicyRow = (props: RowProps) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const [isExpanded, setIsExpanded] = React.useState(false);

    return <>
        <tr>
            <td>
                <PolicyCategoryIcon policyCategory={props.category} />
                {ValueFormatter.friendlyNameForPolicyCategory(props.category)}
            </td>
            <td>
                {props.policy === undefined && "No policy"}
                {props.policy !== undefined && <PolicyTypeLink policyType={props.policy.policyType} />}
            </td>
            <td>
                {props.policy !== undefined && props.policy.policy.effectEndDate === undefined && "Continuous"}
                {props.policy !== undefined && props.policy.policy.effectEndDate !== undefined && ValueFormatter.formatDateFromNowWithMoment(props.policy.policy.effectEndDate)}
            </td>
            <td>
                {props.policy !== undefined && <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />}
            </td>
        </tr>
        {props.policy !== undefined && isExpanded && <tr>
            <td colSpan={4}>
                <TypeDataDescriptionParagraph description={descriptionState.Policies?.find(x => x.typeName === props.policy?.policyType.typeName)} />
            </td>
        </tr>}
    </>;
};

export const ActivePoliciesComponent = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (worldState.PolicyTypeSettings === undefined) {
        return null;
    }

    const policiesAndTypes = props.policies.map(x => {
        return {
            policy: x,
            policyType: worldState.PolicyTypeSettings!.data[x.policyTypeName]
        };
    });

    const policies = valuesFromEnum(PolicyCategory)
        .filter(x => x !== PolicyCategory.Instant && !isNaN(x))
        .map(x => {
            return {
                category: x,
                policy: policiesAndTypes.find(p => p.policyType.category === x)
            }
        });

    if (policies.length === 0) {
        return null;
    }


    return <Table isFullWidth>
        {policies.map(x => <PolicyRow key={x.category} {...x} />)}
    </Table>;
});