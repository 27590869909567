import { observer } from "mobx-react-lite";
import * as React from "react";
import { ISolarSystemForList } from "../../../../ApplicationState/ApiClient";
import { PlayerStateContext, SolarSystemStateContext } from "../../../../ApplicationState/ContextRoot";
import { MapHelper } from "../../MapHelper";
import { MapPreferences } from "../MapPreferences";

type Props = {
    offsetX: number,
    offsetY: number,
    preferences: MapPreferences
}

export const RadarLayer = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    React.useEffect(() => {
    }, [props.preferences.showRadarCoverage,
    props.preferences.showRadarCoverageSelf,
    props.preferences.showRadarCoverageAlly]);

    if (!solarSystemState.SolarSystems || !props.preferences.showRadarCoverage) {
        return null;
    }

    function checkPreferences(solarSystem: ISolarSystemForList): boolean {

        return props.preferences.showRadarCoverageSelf;
    }

    return <svg className="overlay bottom">
        {solarSystemState.SolarSystems
            .filter(s => !!s.radarRange && s.radarRange > 0 && checkPreferences(s))
            .map(s => <circle
                className={"radar"}
                key={s.solarSystemId}
                cx={MapHelper.fixOffsetX(s.x, props.offsetX)}
                cy={MapHelper.fixOffsetY(s.y, props.offsetY)}
                r={s.radarRange! * MapHelper.SolarSystemSize}
                fillOpacity="0.05" strokeDasharray="15, 10, 5, 10" x1="0" y1="10" x2="350" y2="10" style={{
                    fill: "#777",
                    strokeWidth: 1,
                    stroke: "#44b9e0"
                }}
            />)}
    </svg>;
});