import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, IPlayer, IPlayerMinimal } from '../../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../../ApplicationState/ContextRoot';
import { MathHelper } from '../../../../Helpers/MathHelper';
import { RelationHelper } from '../../../../Helpers/RelationHelper';
import { RenderContext } from '../RenderContext';
import { fleetSettings } from './Shared';

export type IRenderFleet = {
    initialMovementType: FleetMovementType;
    ownerPlayerId?: number | undefined;
    owner?: IPlayerMinimal | undefined;
    sourceX?: number | undefined;
    sourceY?: number | undefined;
    targetX?: number | undefined;
    targetY?: number | undefined;
    currentX?: number | undefined;
    currentY?: number | undefined;
}

type SharedProps = {
    renderContext: RenderContext
    scale: number,
    additionalFleets?: (IRenderFleet | undefined)[]
}

type AdditionalFleetProps = SharedProps & {
    additionalFleets?: (IRenderFleet | undefined)[]
}

type BackgroundFleetProps = SharedProps & {
    backgroundFleets?: IRenderFleet[]
}

const markerLength = fleetSettings.markerLength;
const angleOffset = fleetSettings.angleOffset;

type FleetProps = {
    player: IPlayer,
    renderContext: RenderContext
    fleet: IRenderFleet,
    scale: number,
    className?: string
}

type MarkerProps = {
    className: string,
    renderContext: RenderContext
    fleet: IRenderFleet,
    scale: number
}

const Marker = (props: MarkerProps) => {

    if (props.fleet.currentX === undefined ||
        props.fleet.currentY === undefined ||
        props.fleet.sourceX === undefined ||
        props.fleet.sourceY === undefined ||
        props.fleet.targetX === undefined ||
        props.fleet.targetY === undefined) {
        return null;
    }

    const angle = MathHelper.radiansAngleBetweenPoints(props.fleet.sourceX, props.fleet.sourceY, props.fleet.targetX, props.fleet.targetY);

    const { currentX, currentY } = props.fleet;
    const rc = props.renderContext;

    const rotatedA = MathHelper.rotatePoint(currentX, currentY, angle - angleOffset, currentX, currentY + (markerLength / props.scale));
    const rotatedB = MathHelper.rotatePoint(currentX, currentY, angle + angleOffset, currentX, currentY - (markerLength / props.scale));

    return <>
        <line
            className={`${props.className} is-marker`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={1}
            x1={rc.tX(currentX)}
            y1={rc.tY(currentY)}
            x2={rc.tX(rotatedA.x)}
            y2={rc.tY(rotatedA.y)}

        />
        <line
            className={`${props.className} is-marker`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={1}
            x1={rc.tX(currentX)}
            y1={rc.tY(currentY)}
            x2={rc.tX(rotatedB.x)}
            y2={rc.tY(rotatedB.y)}
        />
    </>;
};


export const Fleet = (props: FleetProps) => {

    if (props.fleet.sourceX === undefined ||
        props.fleet.sourceY === undefined ||
        props.fleet.targetX === undefined ||
        props.fleet.targetY === undefined) {
        return null;
    }

    const rc = props.renderContext;
    const relationClassName = RelationHelper.movementRelationClassName(props.fleet.owner, props.player, props.fleet);

    const className = classNames(
        relationClassName,
        props.className
    );

    return <>
        <line
            strokeWidth={1 / props.scale}
            className={className}
            strokeDasharray={5 / props.scale}
            x1={rc.tX(props.fleet.sourceX)}
            x2={rc.tX(props.fleet.targetX)}
            y1={rc.tY(props.fleet.sourceY)}
            y2={rc.tY(props.fleet.targetY)}
        />
        <Marker
            className={className}
            fleet={props.fleet}
            renderContext={props.renderContext}
            scale={props.scale}
        />
    </>;
};

export const BackgroundFleets = observer((props: BackgroundFleetProps) => {

    const playerState = React.useContext(PlayerStateContext);

    const fleets = (props.backgroundFleets ?? []).filter(x => x !== undefined).map(x => x!);

    if (playerState.Player === undefined || fleets.length === 0) {
        return null;
    }

    return <>
        {fleets.map((x, i) => <Fleet key={i}
            {...props}
            fleet={x}
            player={playerState.Player!}
            className='background-fleet'
        />)}
    </>;
});

export const AdditionalFleets = observer((props: AdditionalFleetProps) => {

    const playerState = React.useContext(PlayerStateContext);

    const fleets = (props.additionalFleets ?? []).filter(x => x !== undefined).map(x => x!);

    if (playerState.Player === undefined || fleets.length === 0) {
        return null;
    }

    return <>
        {fleets.map((x, i) => <Fleet key={i}
            {...props}
            fleet={x}
            player={playerState.Player!}
        />)}
    </>;
});