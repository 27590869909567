import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { ICelestialDetail, IItemsDetail, ItemCategory, ManufactoryNode, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext, WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { CloseButton } from "../../../../../Components/Base/Buttons/CloseButton";
import { SplitLayout } from "../../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { DictionaryTable } from "../../../../../Components/Base/Containers/Table";
import { FieldHolder } from "../../../../../Components/Base/Form/FieldHolder";
import { NumberInput } from "../../../../../Components/Base/Form/Input";
import { Slider } from "../../../../../Components/Base/Form/Slider";
import { ProgressBar } from "../../../../../Components/Base/ProgressBar";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { OperationLevelWarningIcon } from "../../../../../Components/FusionShift/Icons/OperationLevelWarningIcon";
import { InstallationImage } from "../../../../../Components/FusionShift/Images/InstallationImage";
import { CostControl } from "../../../../../Components/FusionShift/Items/CostControl";
import { ItemChooser } from "../../../../../Components/FusionShift/Items/ItemChooser";
import { ItemTypeLink } from "../../../../../Components/FusionShift/Links/Items/ItemTypeLink";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ManufactoryHelper } from "../../../../../Helpers/ManufactoryHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";
import { celestial_installation } from "../../../../../Navigation/Routing/SolarSystem";

type Props = {
    type: "Input" | "Output",
    solarSystemId: number,
    itemsDetail: IItemsDetail | undefined,
    celestial: ICelestialDetail,
    node: ManufactoryNode,
    closeCallback: () => any,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const NodeControl = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [showConfigure, setShowConfigure] = React.useState(props.node.itemTypeName === undefined);
    const [itemToChange, setItemToChange] = React.useState<string | undefined>(props.node.itemTypeName);
    const [throughputLimitRatio, setThroughputLimitRatio] = React.useState<number>(props.node.throughputLimitRatio);

    React.useEffect(() => {
        setShowConfigure(false);
    }, [props.node.manufactoryNodeId]);

    React.useEffect(() => {
        if (throughputLimitRatio > 1) {
            setThroughputLimitRatio(1);
        }
        if (throughputLimitRatio < 0) {
            setThroughputLimitRatio(0);
        }
    }, [throughputLimitRatio]);

    function describeItem() {
        return props.type === "Input" ? "Resource" : "Output";
    }

    if (!worldState.InstallationTypeSettings || !worldState.ItemTypeSettings || !worldState.SchematicTypeSettings) {
        return null;
    }

    function changeItemTypeName(itemTypeName: string | undefined) {
        setItemToChange(itemTypeName);
    }

    function changeItem() {

        const configuration = {};

        configuration[props.node.manufactoryNodeId] = {
            itemTypeName: itemToChange,
            throughputLimitRatio
        };

        const promise = itemToChange === "Unset" || itemToChange === undefined ? apiState.ManufactoryClient.clearNodeItemTypeName(props.celestial.celestialId, props.node.manufactoryNodeId) :
            apiState.ManufactoryClient.setNodeConfigurations(props.celestial.celestialId, configuration);

        return promise.then(solarSystem => {
            if (solarSystem) {
                props.reloadCallback(solarSystem);
            }
        });
    }

    const installation = props.celestial.installations ? props.celestial.installations.find(c => c.installationId === props.node.installationId) : undefined;
    const installationType = installation && installation.installationTypeName in worldState.InstallationTypeSettings.data ? worldState.InstallationTypeSettings.data[installation.installationTypeName] : undefined;
    const item = props.node.itemTypeName && props.node.itemTypeName in worldState.ItemTypeSettings.data ? worldState.ItemTypeSettings.data[props.node.itemTypeName!] : undefined;

    const schematic = props.type === "Output" && item && Object.values(worldState.SchematicTypeSettings.data).find(s => s.producedItemTypeName === item.typeName);

    const toChangeSchematic = props.type === "Output" && Object.values(worldState.SchematicTypeSettings.data).find(s => s.producedItemTypeName === itemToChange);

    const actualThroughput = ManufactoryHelper.actualThroughput(props.itemsDetail, props.celestial.celestialId, props.node.manufactoryNodeId);

    const efficiency = props.node.optimalThroughput > 0 ? actualThroughput / props.node.optimalThroughput : 0;

    const content = {
        "Efficiency": <ProgressBar leftIcon={IconHelper.Manufactories.Efficiency} value={efficiency} text={ValueFormatter.fromDecimalToDisplayPercent(efficiency)} />,
    };

    if (item) {
        const desc = `Current ${describeItem()}`;

        const throughputLimit = props.node.throughputLimitRatio < 1 ? ` (${ValueFormatter.fromDecimalToDisplayPercent(props.node.throughputLimitRatio, undefined, 0)} limit)` : '';

        content[desc] = <ItemTypeLink itemType={item} />;
        content["Potential Throughput"] = `${ValueFormatter.formatLocaleNumber(props.node.optimalThroughput)} per hour`;
        content["Actual Throughput"] = <span className={props.node.throughputLimitRatio < 1 ? "has-text-warning" : ""}>
            {ValueFormatter.formatLocaleNumber(actualThroughput)} per hour {throughputLimit}
        </span>;
    }

    const throughputToUse = installation?.level ?? 0 > 0 ? props.node.optimalThroughput :
        props.type === "Input" ?
            (installationType?.factoryInputThroughputPerLevel ?? 0) + (installationType?.factoryBaseInputThroughput ?? 0) :
            (installationType?.factoryOutputThroughputPerLevel ?? 0) + (installationType?.factoryBaseOutputThroughput ?? 0);

    if (schematic && schematic.costPerRun) {
        content["Optimally Consumes"] = <CostControl cost={schematic.costPerRun} multipliedBy={throughputToUse} hideDuration />;
        content["Actually Consuming"] = <CostControl cost={schematic.costPerRun} multipliedBy={actualThroughput} hideDuration />;
    }

    const allowedItems = React.useMemo(() => {

        if (worldState.ItemTypeSettings === undefined || !worldState.SchematicTypeSettings) {
            console.log(worldState.ItemTypeSettings);
            console.log(worldState.SchematicTypeSettings);
            return [];
        }

        return Object.values(worldState.ItemTypeSettings.data)
            .filter(x => {

                if (props.type === "Input") {
                    return x.category === ItemCategory.Resource;
                }

                const schematic = Object.values(worldState.SchematicTypeSettings!.data).find(s => s.producedItemTypeName === x.typeName);
                return ManufactoryHelper.isValidOutput(schematic);
            })
            .map(x => x.typeName);

    }, [worldState.ItemTypeSettings, worldState.SchematicTypeSettings, props.type]);

    return <SubPanel heading={`${props.type} Node`}
        headingContent={<CloseButton action={props.closeCallback} />}
        footerContent={<>
            {!showConfigure && <Button type="nav" className="is-pulled-right" icon={IconHelper.General.Edit} text="Configure" action={() => setShowConfigure(true)} />}
            {showConfigure &&
                <Button
                    type={"action"}
                    className="is-pulled-right"
                    icon={IconHelper.General.Confirm}
                    text={`Set Configuration`}
                    action={changeItem}
                />}
        </>
        }
    >
        {installation &&
            <SplitLayout
                fitContent
                left=
                {installationType && <InstallationImage
                    installationType={installationType}
                    size="medium"
                    celestialId={props.celestial.celestialId}
                    installation={installation}
                />}
                right={<ul>
                    <li>
                        <Link to={celestial_installation(props.celestial.celestialId, installation.installationId)}>
                            {installationType ? installationType.name : ""} {installation ? `[${installation.level}]` : ""}
                        </Link>
                    </li>
                    {installation.level === 0 && <li className="has-text-warning">
                        This installation is still under construction
                    </li>}
                    {installation.operationLevel < installation.level &&
                        <li className="has-text-warning">
                            Operation Level: {installation.operationLevel}<OperationLevelWarningIcon {...installation} />
                        </li>}
                </ul>}
            />
        }
        <DictionaryTable isFullWidth content={content} />
        {showConfigure && <>
            <h4 className="subtitle">Configuration</h4>
            <FieldHolder label={`${props.type} Item`}>
                <ItemChooser
                    forceHideItemPrompt={item !== undefined && props.type !== "Input"}
                    allowEmpty={item !== undefined}
                    defaultItemTypeName={item?.typeName}
                    key={props.node.manufactoryNodeId}
                    setItemTypeName={changeItemTypeName}
                    allowedItems={allowedItems}
                    categoryPrompt={item !== undefined ? "Clear Node" : undefined}
                    itemPrompt={item !== undefined && props.type === "Input" ? "Clear Node" : undefined}
                    hideCredits
                />
            </FieldHolder>
            <FieldHolder label="Throughput Limit" className="throughput-limit">
                <Slider
                    value={throughputLimitRatio}
                    valueChanged={setThroughputLimitRatio}
                    min={0} max={1}
                    valueFormatter={v => ValueFormatter.fromDecimalToDisplayPercent(v, undefined, 0)}
                    step={0.01}
                    hideValue
                />
                <NumberInput
                    size={2}
                    value={throughputLimitRatio * 100}
                    valueChanged={v => setThroughputLimitRatio(v / 100)}
                />
            </FieldHolder>
            <FieldHolder label={`Possible Throughput`}>
                <Paragraph>
                    {ValueFormatter.formatLocaleNumber(props.node.optimalThroughput * throughputLimitRatio)} per hour
                </Paragraph>
            </FieldHolder>
            {toChangeSchematic && toChangeSchematic.costPerRun &&
                <FieldHolder label={`Optimal Input Throughput`}>
                    <Paragraph>
                        <CostControl cost={toChangeSchematic.costPerRun} multipliedBy={props.node.optimalThroughput * throughputLimitRatio} hideDuration />
                    </Paragraph>
                </FieldHolder>
            }
        </>}
    </SubPanel>;
});