import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BackButton } from '../../../Components/Base/Buttons/BackButton';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { ErrorMessageBox } from '../../../Components/Base/MessageBox';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { FactionTypeImage } from '../../../Components/FusionShift/Images/FactionTypeImage';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { FactionTypeControl } from '../../Help/TypeViews/Factions/FactionTypeControl';
import { StageProps } from './StageRender';

type Props = StageProps & {
    hasBackButton: boolean
}

export const FactionStage = observer((props: Props) => {

    const factions = props.joinWorldBag.World !== undefined ? Object.values(props.joinWorldBag.World.settings.factionTypeSettings.data) : [];
    const factionType = props.joinWorldBag.FactionTypeName && factions.find(f => f.typeName === props.joinWorldBag.FactionTypeName);

    const nextBlocked = factionType === undefined;

    React.useEffect(() => {
        if (props.joinWorldBag.FactionTypeName === undefined && factions.length > 0) {
            const faction = CollectionHelper.randomElement(factions);
            props.joinWorldBag.setFactionTypeName(faction.typeName);
        }
    }, []);

    function setFaction(factionTypeName) {

        if (factions.findIndex(x => x.typeName === factionTypeName) !== -1) {
            props.joinWorldBag.setFactionTypeName(factionTypeName);
        }
    }

    return <SubPanel heading="Choose Faction" footerContent={
        <ButtonHolder isPulledRight>
            {props.hasBackButton &&
                <BackButton action={props.previousStage} />
            }
            <NextButton action={props.nextStage} isDisabled={nextBlocked} />
        </ButtonHolder>
    }>
        <Paragraph>Choose which Faction to play as. Each Faction has a different backstory, ship aesthetics and playstyle.</Paragraph>
        <div className="content">
            <ul>
                <li>All of the game's features are available to every Faction</li>
                <li>Every Faction has a version of each ship class but the statistics differ between Factions</li>
                <li>Your choice of Faction does not determine who you can make alliances (or war) with</li>
                <li>You can't change your Faction once you begin playing</li>
            </ul>
        </div>
        {factions.length === 0 && <ErrorMessageBox text="There are no available Factions" />}
        {factions.length > 0 && <SubPanel heading="Select Faction">
            <div className="faction-chooser">
                {factions.map(f => {

                    const isSelected = f.typeName === props.joinWorldBag.FactionTypeName;
                    const className = classNames("is-clickable",
                        {
                            "selected": isSelected
                        });

                    return <span key={f.typeName} className={className} onClick={() => setFaction(f.typeName)}>
                        <FactionTypeImage
                            factionTypeName={f.typeName} size={isSelected ? "large" : "medium"}
                            disableLink
                        />
                    </span>;
                })}
            </div>
        </SubPanel>}
        {factionType && props.joinWorldBag.World &&
            <FactionTypeControl
                disableLink
                shipTypeSettings={props.joinWorldBag.World.settings.shipTypeSettings!}
                factionType={factionType}
                clientSettings={props.joinWorldBag.World.clientSettings}
                description={props.joinWorldBag.World.factionDescriptions.find(x => x.typeName === factionType.typeName)!}
                shipDescriptions={props.joinWorldBag.World.shipTypeDescriptions}
            />}
    </SubPanel>;
});