import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CreditsStateContext } from "../../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { Icon } from "../../../Base/Icon";
import { CountingNumber } from "../../../Base/Text/CountingNumber";
import { QuantityIconProps } from "../QuantityIcon";

type Props = QuantityIconProps & {
    className?: string | undefined,
    isCounting?: boolean,
    isIncome?: boolean,
    hideDecimals?: boolean,
    isShorthand?: boolean
}

export const CreditsIconWithQuantity = (props: Props) => {

    const className = classNames(
        props.className,
        "has-nowrap"
    );

    const iconClassName = classNames(
        props.className,
        "quantity",
        "credits"
    );

    const value = props.isShorthand && props.quantity && props.quantity > 9999 ?
        ValueFormatter.toShorthand(props.quantity) :
        ValueFormatter.formatLocaleNumber(props.quantity, props.hideDecimals ? 0 : 2);

    return <span title="Credits" className={className}>
        <Icon icon={props.isIncome ? IconHelper.Economy.CreditIncome : IconHelper.Economy.Credits} />
        <span className={iconClassName}>
            {!props.isCounting && value}
            {props.isCounting && <CountingNumber
                value={props.quantity}
                precision={props.hideDecimals ? 0 : 2}
            />}
        </span>
    </span>;
};

export const CreditsIconWithQuantityAndAffordability = observer((props: Props) => {

    const creditsState = React.useContext(CreditsStateContext);

    const className = props.quantity !== undefined && props.quantity > creditsState.Balance ? "has-text-danger" : "";

    return <CreditsIconWithQuantity {...props} className={className} />;
});