import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { CelestialDetail, CelestialSize, ICelestialTypeSettings, IInstallationTypeSettings, ISolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { choice } from '../../../../Components/Base/Buttons/ButtonChooser';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { H3 } from '../../../../Components/Base/Text/H';
import { CelestialButtons } from '../../../../Components/FusionShift/Celestials/CelestialButtons';
import { CelestialIcons } from '../../../../Components/FusionShift/Celestials/CelestialIcons';
import { CelestialImage } from '../../../../Components/FusionShift/Celestials/CelestialImage';
import { CelestialHelper } from '../../../../Helpers/CelestialHelper';
import { CollectionHelper } from '../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { TextHelper } from '../../../../Helpers/TextHelper';
import { celestial_summary } from '../../../../Navigation/Routing/SolarSystem';
import { CelestialInstallations, CelestialInstallationsList } from './CelestialInstallations';

type Props = {
    solarSystem: ISolarSystemDetail
}

type RowProps = {
    celestialTypeSettings: ICelestialTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    solarSystem: ISolarSystemDetail,
    celestial: CelestialDetail
}

const CelestialRow = (props: RowProps) => {

    const isDamaged = CelestialHelper.isDamaged(props.celestial);
    const hasConstruct = CelestialHelper.hasConstruct(props.celestial, props.solarSystem);
    const hasDeconstruct = CelestialHelper.hasDeconstruct(props.celestial, props.solarSystem);
    const isDestroyed = props.celestial.celestialTypeName !== undefined && props.celestial.celestialTypeName in props.celestialTypeSettings.data && props.celestialTypeSettings.data[props.celestial.celestialTypeName].isDestroyed;


    const options = CelestialHelper.celestialFeatures(props.celestial, props.installationTypeSettings, true)
        .map(f => choice(f, typeof f === "string" ? f : "Installation", IconHelper.Celestials.celestialFeature(f)))
        .filter(x => x !== undefined);

    const numInstallations = props.celestial.size;
    const numOptions = options.length;

    const isTwoLine = numInstallations + numOptions > 5;

    const className = classNames("row",
        (props.celestial.celestialTypeName ?? "unknown").toLowerCase(),
        {
            "is-damaged": isDamaged,
            "has-construct": hasConstruct,
            "has-deconstruct": hasDeconstruct,
            "is-two-line": isTwoLine
        });

    const desktopName = CelestialHelper.celestialTypeName(props.celestial, props.celestialTypeSettings);
    const tabletName = TextHelper.abbreviateText(`${props.celestial.name}: ${desktopName}`, 8);

    return <div className={className}>
        <div className="celestial-name-wrapper">
            <span className="celestial-name">
                {props.celestial.name}
            </span>
        </div>
        <div className="celestial-image">
            <CelestialImage {...props} className="is-hidden-mobile" isLink />
            <CelestialIcons solarSystem={props.solarSystem} celestial={props.celestial} />
        </div>
        <div className="celestial-info">
            <H3 className="celestial-type">
                <Link to={celestial_summary(props.celestial.celestialId)}>
                    <span className="is-hidden-desktop">
                        {tabletName}
                    </span>
                    <span className="is-hidden-touch">
                        {desktopName}
                    </span>
                </Link>
            </H3>
            <H3 className="celestial-size">
                <Link to={celestial_summary(props.celestial.celestialId)}>
                    {CelestialSize[props.celestial.size]}
                </Link>
            </H3>
        </div>
        <div className="installation-wrapper">
            {!isDestroyed &&
                <CelestialInstallations {...props} />
            }
        </div>
        <div className="celestial-buttons">
            {!isDestroyed &&
                <CelestialButtons options={options} {...props} />
            }
        </div>
    </div>;
};

export const CelestialTable = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.InstallationTypeSettings || !worldState.CelestialTypeSettings || !CollectionHelper.isAnyInArray(props.solarSystem.celestials) || !props.solarSystem.celestials) {
        return null;
    }

    return <SubPanel className="celestial-table" heading="Installations">
        <div className="content">
            {props.solarSystem.celestials.map(c => <CelestialRow key={c.celestialId} {...props} celestial={c} celestialTypeSettings={worldState.CelestialTypeSettings!} installationTypeSettings={worldState.InstallationTypeSettings!} />)}
        </div>
        <div className="mobile-content">
            {props.solarSystem.celestials.map(c => <CelestialInstallationsList key={c.celestialId} {...props} celestial={c} installationTypeSettings={worldState.InstallationTypeSettings!} />)}
        </div>
    </SubPanel>;
});