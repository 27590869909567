import * as React from 'react';
import { ICelestialDetail, Installation, InstallationType, ISolarSystemDetail, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, WorldStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { SplitLayout } from '../../../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../../../Components/Base/Form/Select';
import { Paragraph } from '../../../../../Components/Base/Text/Paragraph';
import { InstallationTypeStats } from '../../../../../Components/FusionShift/Descriptions/InstallationTypeStats';
import { SchematicTypeDescription } from '../../../../../Components/FusionShift/Descriptions/SchematicTypeDescription';
import { IconHelper } from '../../../../../Helpers/IconHelper';

type Props = {
    installation: Installation,
    installationType: InstallationType,
    solarSystem: ISolarSystemDetail,
    celestial: ICelestialDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const SchematicAndOperationLevelControl = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [isChanging, setIsChanging] = React.useState(false);
    const [schematicTypeName, setSchematicTypeName] = React.useState(props.installation.runningSchematicTypeName);
    const [operationLevel, setOperationLevel] = React.useState(props.installation.level);

    const isInvalidInstallationType = props.installationType.canProduceFromSchematicTypeNames.length === 0 && !props.installationType.hasOperationLevel;

    if (!worldState.ItemTypeSettings || !worldState.SchematicTypeSettings || isInvalidInstallationType || !playerState.Player) {
        return null;
    }

    function changeSettings() {
        return apiState.InstallationClient.setOperationLevelAndRunningSchematicTypeName(props.solarSystem.solarSystemId, props.installation.installationId, operationLevel, schematicTypeName).then(s => {
            if (s) {
                setIsChanging(false);
                props.reloadCallback(s);
            }
        })
    }

    const schematic = props.installation.runningSchematicTypeName in worldState.SchematicTypeSettings.data ? worldState.SchematicTypeSettings.data[props.installation.runningSchematicTypeName] : undefined;

    const possibleSchematics = props.installationType.canProduceFromSchematicTypeNames.map(s => s in worldState.SchematicTypeSettings! ? worldState.SchematicTypeSettings![s] : undefined)
        .filter(s => s !== undefined)
        .map(s => s!);

    const maxSize: number[] = [];
    for (let i = 0; i <= props.installation.level; i++) {
        maxSize.push(i);
    }

    const isOptimal = props.installation.operationLevel === props.installation.level;

    const selectedSchematic = possibleSchematics.find(s => s.typeName === schematicTypeName);
    const canChange = possibleSchematics.length > 1 || selectedSchematic === undefined || props.installationType.hasOperationLevel;

    const title = props.installationType.canProduceFromSchematicTypeNames.length ? "Schematics" : "Operation Level";

    return <SubPanel heading={{ text: title, icon: IconHelper.Items.Schematic }}
        headingContent={
            !isChanging && canChange &&
            <Button type="nav" text="Change Configuration" icon={IconHelper.Categories.Settings} hideTextOnMobile action={() => {
                if (schematicTypeName.length === 0) {
                    setSchematicTypeName(props.installationType.canProduceFromSchematicTypeNames[0]);
                }
                setIsChanging(true);
            }} />
        }
        footerContent={isChanging &&
            <ButtonHolder isPulledRight>
                <Button type="danger" text="Cancel" icon={IconHelper.General.Cancel} action={() => setIsChanging(false)} hideTextOnMobile />
                <Button type="action" text="Confirm Configuration" icon={IconHelper.Items.Schematic} action={changeSettings} hideTextOnMobile />
            </ButtonHolder>
        }>
        {!isChanging &&
            <SplitLayout
                left={
                    <ul>
                        {props.installationType.hasOperationLevel &&
                            <li className={!isOptimal ? "has-text-warning" : ""}>
                                Operation Level: {props.installation.operationLevel} / {props.installation.level}
                            </li>
                        }
                        {props.installationType.canProduceFromSchematicTypeNames.length > 0 &&
                            <li className={schematic === undefined ? "has-text-warning" : ""}>
                                Currently Running: {schematic === undefined ? "Nothing" : schematic.name}
                            </li>
                        }
                    </ul>
                }
                right={
                    <>
                        {schematic !== undefined &&
                            <SchematicTypeDescription schematicType={schematic} itemTypeSettings={worldState.ItemTypeSettings} level={props.installation.operationLevel} />
                        }
                    </>
                }
            />
        }
        {isChanging &&
            <SplitLayout
                left={
                    <>

                        {possibleSchematics.length > 0 &&
                            <FieldHolder label="Schematic">
                                <Select valueChanged={e => setSchematicTypeName(e)} defaultValue={schematicTypeName}
                                    values={possibleSchematics.map(s => {
                                        return {
                                            label: s.name,
                                            value: s.typeName
                                        }
                                    })} />
                            </FieldHolder>
                        }
                        {props.installationType.hasOperationLevel &&
                            <FieldHolder label="Operation Level">
                                <Select valueChanged={e => setOperationLevel(Number(e))} defaultValue={operationLevel}
                                    values={maxSize.map((c, i) => {
                                        return {
                                            label: `Level [${i}]`,
                                            value: i
                                        }
                                    })} />

                            </FieldHolder>
                        }
                    </>
                }
                right={
                    <>
                        {selectedSchematic &&
                            <SchematicTypeDescription schematicType={selectedSchematic} itemTypeSettings={worldState.ItemTypeSettings} level={operationLevel} />
                        }
                        {props.installationType.hasOperationLevel &&
                            <>
                                <Paragraph>Operation level [{operationLevel}] will have the following stats:</Paragraph>
                                <InstallationTypeStats
                                    celestialSize={props.celestial.size}
                                    installationType={props.installationType}
                                    factionTypeName={playerState.Player.factionTypeName}
                                    level={props.installation.level}
                                    operationLevel={operationLevel}
                                    display={{
                                        shipBuildDisplay: "levelAndBelow",
                                        showAllows: false,
                                        showLimits: false,
                                        onlyOperationLevel: true,
                                        sectorBonusesForMaxLevel: false
                                    }}
                                />
                            </>
                        }
                    </>
                }
            />
        }
    </SubPanel >;
};