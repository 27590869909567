import { IFederationDetail, IGameSettings, IPlayer } from '../../../ApplicationState/ApiClient';
import { IconHelper } from '../../../Helpers/IconHelper';
import { MemberControls } from './MemberControls';
import { PlayerList } from './PlayerList';

type Props = {
    observingPlayer: IPlayer,
    gameSettings: IGameSettings,
    federation: IFederationDetail,
    players: IPlayer[],
    reloadCallback: (federation: IFederationDetail) => any
}

export const DefeatedMemberList = (props: Props) => {

    return <PlayerList
        controls={p => <MemberControls player={p} {...props} />}
        heading={{ text: "Defeated Members", icon: IconHelper.Combat.Defeated }}
        players={props.players}
        federation={props.federation}
        hideControls
    />;
};