import classNames from 'classnames';
import * as React from 'react';

type Props = {
    className?: string | undefined
}

const cells = 10;
const gridX = Array.from(Array(cells).keys());
const gridY = Array.from(Array(cells).keys());

export const Grid = (props: Props) => {

    const className = classNames("grid", props.className);

    return <svg className={className}>
        {gridY.map(y => {
            return gridX.map(x => <rect key={`${x}_${y}`} x={`${x * 10}%`} y={`${((cells - 1) - y) * 10}%`} />)
        })}
    </svg>;
};