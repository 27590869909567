import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext, SolarSystemStateContext } from '../../../../ApplicationState/ContextRoot';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type NodeType = "input" | "output";

type Props = {
    mission: MissionWrapper,
    nodeType: NodeType
}

export const ManufactoryNodesRequired = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!props.mission.MissionType.manufactoryInputNodesRequired || !solarSystemState.SolarSystem) {
        return null;
    }

    const required = props.mission.MissionType.manufactoryInputNodesRequired
    var has = 0;

    for (let c of solarSystemState.SolarSystem.celestials) {
        if (c.manufactory != null) {
            const thisHas = props.nodeType == "input" ? c.manufactory.inputNodes.length : c.manufactory.outputNodes.length;
            if (thisHas > has) {
                has = thisHas;
            }
        }
    }

    return <ObjectiveRow has={has} requires={required} {...props} >
        {`Build or upgrade a Manufactory to have ${required} ${props.nodeType} ${ValueFormatter.pluralize("node", required)}`}
    </ObjectiveRow>;
});