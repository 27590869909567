import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../ApplicationState/ContextRoot';
import { buildVersion } from "../Blobs/Config";

type Props = {
    clientOnly?: boolean
}

export const VersionNumbers = observer((props: Props) => {

    const [isExpanded, setIsExpanded] = React.useState(false);
    const appState = React.useContext(AppStateContext);

    if (props.clientOnly) {
        return <span className='is-pulled-right'>
            Fusion Shift Online{` ${buildVersion}`}
        </span>;
    }

    if (isExpanded) {
        return <>
            Fusion Shift Online  <a onClick={() => setIsExpanded(false)}>-</a>
            <ul>
                <li>Client: {buildVersion}</li>
                {appState.ApiVersion !== undefined &&
                    <li>Api: {appState.ApiVersion}</li>
                }
                {appState.GameVersion !== undefined &&
                    <li>Game: {appState.GameVersion}</li>
                }
                {appState.FactoryVersion !== undefined &&
                    <li>Data: {appState.FactoryVersion}</li>
                }
            </ul>
        </>;
    }

    return <>
        Fusion Shift Online{` ${buildVersion}`} <a onClick={() => setIsExpanded(true)}>+</a>
    </>;
});