import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Icon } from '../../../../Components/Base/Icon';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { EmblemEditorContext } from '../../State/EmblemEditorContext';

type Props = {
    defaultEmblemSize: number,
    size: number
}

const handleSize = 10;

export const DragHandle = observer((props: Props) => {

    const emblemEditorState = React.useContext(EmblemEditorContext);

    const [newX, setNewX] = React.useState<number | undefined>(undefined);
    const [newY, setNewY] = React.useState<number | undefined>(undefined);
    const [canApply, setCanApply] = React.useState(true);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setCanApply(true);
        }, 100);
        return () => clearInterval(timer);
    }, []);

    React.useEffect(() => {
        if (canApply && newX !== undefined && newY !== undefined) {
            applyChanges();
        }
    }, [canApply, newX, newY]);

    const currentLayer = emblemEditorState.editingLayer;

    if (currentLayer === undefined) {
        return null;
    }

    function applyChanges() {
        if (newX === undefined || newY === undefined || currentLayer === undefined) {
            return;
        }
        if (newX !== currentLayer.translationX || newY !== currentLayer.translationY) {
            emblemEditorState.dispatchAlterLayer(
                {
                    ...currentLayer,
                    translationX: newX ?? currentLayer.translationX,
                    translationY: newY ?? currentLayer.translationY
                },
                emblemEditorState.curentLayerIndex
            );
            setNewX(undefined);
            setNewY(undefined);
            setCanApply(false);
        }
    }

    function transformedHandlePosition(value: number) {
        const offset = (props.size / 2) - handleSize;
        const scale = props.size / props.defaultEmblemSize;

        return (value * scale) + offset;
    }

    function inverseTransformedHandlePosition(value: number) {
        const offset = (props.size / 2) - handleSize;
        const scale = props.size / props.defaultEmblemSize;

        return (value - offset) / scale;
    }

    function dragged(e: DraggableEvent, data: DraggableData) {

        const iX = inverseTransformedHandlePosition(data.x);
        const iY = inverseTransformedHandlePosition(data.y);

        setNewX(iX);
        setNewY(iY);
    }

    const x = transformedHandlePosition(currentLayer.translationX);
    const y = transformedHandlePosition(currentLayer.translationY);

    return <Draggable onDrag={(e, d) => dragged(e, d)} onStop={(_a, _b) => applyChanges()} position={{ x: x, y: y }}>
        <div className="drag-handle is-clickable" >
            <Icon icon={IconHelper.General.Plus} />
        </div>
    </Draggable>;
});