import classNames from 'classnames';
import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Icon } from '../Icon';
import { handleOnClick } from '../Util';

type HeaderProps<T> = {
    sorter?: Sorter<T> | undefined,
    label?: string | undefined,
    className?: string | undefined,
    isHiddenMobile?: boolean,
    isHiddenTouch?: boolean,
    isContentWidth?: boolean,
    colSpan?: number
}

type Props<T> = {
    data: T[] | undefined | null,
    isFullwidth?: boolean,
    isStriped?: boolean,
    isHoverable?: boolean,
    hasBreakAll?: boolean,
    className?: string | undefined,
    header: (HeaderProps<T> | undefined)[] | undefined,
    renderRow: (data: T) => React.ReactNode,
    rowClick?: ((data: T) => any) | undefined
}

type Sorter<T> = string | ((entity: T) => any);

export const FancyTable = <T extends {}>(props: Props<T>) => {

    const [data, setData] = React.useState(props.data);
    const [sortColumnIndex, setSortColumnIndex] = React.useState<number | undefined>(undefined);
    const [sortDesc, setSortDesc] = React.useState(true);
    const [iterations, setIterations] = React.useState(0);

    React.useEffect(() => {
        const newlySorted = props.data;

        if (newlySorted && newlySorted.length > 0 && sortColumnIndex !== undefined && props.header && sortColumnIndex < props.header.length) {
            const sorter = props.header[sortColumnIndex]?.sorter;

            if (sorter) {
                newlySorted.sort((a: any, b: any) => {

                    const aVal = typeof sorter === "string" ? a[sorter] : sorter(a);
                    const bVal = typeof sorter === "string" ? b[sorter] : sorter(b);

                    const compared = aVal === undefined && bVal === undefined ? 0 :
                        aVal === undefined ? 1 :
                            bVal === undefined ? -1 :
                                typeof aVal.localeCompare === "function" ? aVal.localeCompare(bVal) :
                                    aVal - bVal;

                    return compared * (sortDesc ? 1 : -1);
                });
            }
        }
        setData(newlySorted);

        setIterations(iterations + 1);
    }, [sortColumnIndex, sortDesc, props.data]);

    function toggleSort(newSortIndex: number) {
        if (sortColumnIndex !== newSortIndex) {
            setSortColumnIndex(newSortIndex);
            setSortDesc(true);
            return;
        }

        setSortDesc(!sortDesc);
    }

    if (!data) {
        return null;
    }

    const className = `table ${props.isStriped ? "is-striped" : ""} ${props.isFullwidth ? "is-fullwidth" : ""} ${props.isHoverable ? "is-hoverable" : ""} ${props.hasBreakAll ? "has-break-all" : ""} ${props.rowClick ? "is-clickable" : ""}`;

    return <table className={className}>
        {!!props.header && props.header.length > 0 && <thead>
            <tr>
                {props.header.map((h, i) => {

                    if (h === undefined) {
                        return null;
                    }

                    const text = h.label ? h.label : "";
                    const hasLink = !!h.sorter;
                    const className = classNames(
                        h.className,
                        {
                            "is-hidden-mobile": h.isHiddenMobile,
                            "is-hidden-touch": h.isHiddenTouch,
                            "is-content-width": h.isContentWidth,
                        });
                    return <th key={i} className={className} colSpan={h.colSpan}>
                        {!hasLink && text}
                        {hasLink && <a onClick={() => toggleSort(i)}>
                            {text}
                            {i === sortColumnIndex &&
                                <Icon icon={sortDesc ? IconHelper.General.Down : IconHelper.General.Up} />}
                        </a>}
                    </th>
                })}
            </tr>
        </thead>}
        <tbody>
            {data.map((d, i) => <tr key={`${i}_${iterations}}`} onClick={e => handleOnClick(e, () => { if (props.rowClick) props.rowClick(d) })} >
                {props.renderRow(d)}
            </tr>)}
        </tbody>
    </table>
};

export function thEmpty<T>(): HeaderProps<T> {
    return {
    };
};

export function th<T>(label: string): HeaderProps<T> {
    return {
        label: label
    };
};

export function thSort<T>(label: string, sorter: Sorter<T>, isContentWidth?: boolean): HeaderProps<T> {
    return {
        sorter: sorter,
        label: label,
        isContentWidth
    };
};

export function thSortHiddenMobile<T>(label: string, sorter: Sorter<T>, isContentWidth?: boolean): HeaderProps<T> {
    return {
        sorter: sorter,
        label: label,
        isHiddenMobile: true,
        isContentWidth
    };
};

export function thSortHiddenTouch<T>(label: string, sorter: Sorter<T>, isContentWidth?: boolean): HeaderProps<T> {
    return {
        sorter: sorter,
        label: label,
        isHiddenTouch: true,
        isContentWidth
    };
};

export function thHiddenMobile<T>(label: string, isContentWidth?: boolean): HeaderProps<T> {
    return {
        label: label,
        isHiddenMobile: true,
        isContentWidth
    };
};

export function thHiddenTouch<T>(label: string, isContentWidth?: boolean): HeaderProps<T> {
    return {
        label: label,
        isHiddenTouch: true,
        isContentWidth
    };
};