import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ButtonChooser, choice } from '../../Base/Buttons/ButtonChooser';

export type DataChoice = {
    label: string,
    propertyName: string,
    icon: string,
    format: (value) => string
}

type DataChooserProps = {
    dataChoice: DataChoice,
    setDataChoice: (dataChoice: DataChoice) => any,
    dataChoices: DataChoice[],
    additionalButtons?: React.ReactNode[]
}

export const dataChoices = {
    population: {
        label: "Pop.",
        propertyName: "population",
        icon: IconHelper.Megacities.Population,
        format: v => ValueFormatter.formatPopulation(v, 0)
    },
    size: {
        label: "Size",
        propertyName: "size",
        icon: IconHelper.Installations.Installation,
        format: v => ValueFormatter.formatLocaleNumber(v, 0)
    },
    numberOfSolarSystems: {
        label: "Systems",
        propertyName: "numberOfSolarSystems",
        icon: IconHelper.SolarSystems.Colony,
        format: v => ValueFormatter.formatLocaleNumber(v, 0)
    },
    numberOfPlayers: {
        label: "Players",
        propertyName: "numberOfPlayers",
        icon: IconHelper.Categories.Player,
        format: v => ValueFormatter.formatLocaleNumber(v, 0)
    },
    researchPoints: {
        label: "RP",
        propertyName: "researchPoints",
        icon: IconHelper.Federations.ResearchPoint,
        format: v => ValueFormatter.formatLocaleNumber(v, 0)
    }
}

export const DataChooser = (props: DataChooserProps) => {

    if (props.dataChoices.length === 0) {
        return null;
    }

    function valueChanged(propertyName: string) {
        const value = props.dataChoices.find(x => x.propertyName === propertyName);
        if (value !== undefined) {
            props.setDataChoice(value);
        }
    }

    const choices = props.dataChoices.map(x => choice<string>(
        x.propertyName,
        x.label,
        x.icon
    ));

    return <ButtonChooser
        valueChanged={valueChanged}
        value={props.dataChoice.propertyName}
        values={choices}
        hideTextOnMobile
        isPulledRight
        additionalButtons={props.additionalButtons} />;
};
