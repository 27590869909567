import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ISolarSystem, ISolarSystemForList } from '../../../ApplicationState/ApiClient';
import { SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { Button } from '../../Base/Buttons/Button';
import { Select } from '../../Base/Form/Select';
import { Paragraph } from '../../Base/Text/Paragraph';

type BaseProps = {
    prompt?: string | undefined,
    sourceSolarSystem?: ISolarSystem | undefined,
    solarSystemFoundCallback: (solarSystem: ISolarSystemForList | undefined) => any,
    showDistanceTo?: ISolarSystem,
    selectedSolarSystemId?: number
}

type Props = BaseProps & {
    solarSystems: ISolarSystemForList[]
}

type InnerProps = {
    solarSystems: ISolarSystemForList[],
    solarSystemFoundCallback: (solarSystem: ISolarSystemForList | undefined) => any,
    showDistanceTo?: ISolarSystem,
    selectedSolarSystemId?: number
}

const ChooseOwnSolarSystemInner = (props: InnerProps) => {

    if (props.solarSystems.length === 1) {
        return <Button
            type="nav"
            action={() => props.solarSystemFoundCallback(props.solarSystems[0])}
            text={props.solarSystems[0].name}
            icon={IconHelper.Combat.Target}
        />
    }

    return <Select className="is-small"
        value={props.selectedSolarSystemId}
        valueChanged={e => props.solarSystemFoundCallback(props.solarSystems.find(s => s.solarSystemId === Number(e)))}
        showUnset={{
            label: "Own solar system"
        }}
        values={
            props.solarSystems.map(s => {

                const extra = props.showDistanceTo !== undefined && s.solarSystemId !== props.showDistanceTo.solarSystemId ? ` - ${ValueFormatter.formatDistanceBetween(s, props.showDistanceTo)}` : "";

                return {
                    value: s.solarSystemId,
                    label: `${s.name}${extra}`
                };
            })}
    />;
};

export const ChooseOwnSolarSystem = (props: Props) => {

    const possibleSolarSystems = props.solarSystems.filter(s => s.solarSystemId !== props.sourceSolarSystem?.solarSystemId);

    if (possibleSolarSystems.length === 0) {
        return null;
    }

    const prompt = props.prompt ?? "Target one of your solar systems:";

    return <div className="field">
        <Paragraph type="prompt">
            {prompt}
        </Paragraph>
        <ChooseOwnSolarSystemInner {...props} solarSystems={possibleSolarSystems} />
    </div>;
};


export const ChooseOwnSolarSystemFromState = observer((props: BaseProps) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!solarSystemState.SolarSystems) {
        return null;
    }

    return <ChooseOwnSolarSystem {...props} solarSystems={solarSystemState.SolarSystems} />;
});