import { Link } from 'react-router-dom';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Icon } from '../../../Components/Base/Icon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { changeArchive, combatSimulator } from '../../../Navigation/Routing/Misc';

export const Info = () => {

    return <SubPanel heading="Info">
        <SplitLayout
            left={
                <ul>
                    <li>
                        <Link to={changeArchive}>Change Archive</Link><Icon icon={IconHelper.General.ChangeArchive} />
                    </li>
                </ul>
            }
            right={
                <ul>

                    <li>
                        <Link to={combatSimulator}>Combat Simulator</Link>
                    </li>
                </ul>
            } />
    </SubPanel>;
};