import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ActivePolitics, Policy, PolicyStatus } from '../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, UnreadStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { ThumbnailSlate } from '../../Components/Base/Containers/Slate';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { QuoteParagraph } from '../../Components/Base/Text/QuoteParagraph';
import { PolicyTypeDescription } from '../../Components/FusionShift/Descriptions/PolicyTypeDescription';
import { PolicyCategoryIcon } from '../../Components/FusionShift/Icons/PolicyCategoryIcon';
import { FederationLink } from '../../Components/FusionShift/Links/FederationLink';
import { PolicyLink } from '../../Components/FusionShift/Links/PolicyLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';

type Props = {
    policy: Policy,
    reloadCallback: () => any
};

export const OpenPolicyVote = observer((props: Props) => {

    const unreadState = React.useContext(UnreadStateContext);
    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [activePolitics, setActivePolitics] = React.useState<ActivePolitics | undefined>(undefined);

    React.useEffect(() => {
        apiState.PoliticsClient.getActive().then(setActivePolitics);
    }, []);

    if (!worldState.PolicyTypeSettings || !playerState.Player) {
        return null;
    }

    if (props.policy.status !== PolicyStatus.Voting) {
        return null;
    }

    function vote(vote: boolean | undefined) {
        if (vote === undefined) {
            return apiState.PoliticsClient.abstainFromPolicyVote(props.policy.policyId).then(_ => {
                unreadState.clearUnread("policyVote");
                props.reloadCallback();
            });
        }

        return apiState.PoliticsClient.voteForPolicy(props.policy.policyId, vote).then(_ => {
            unreadState.clearUnread("policyVote");
            props.reloadCallback();
        });
    }

    const policyType = worldState.PolicyTypeSettings.data[props.policy.policyTypeName];
    const toBeReplaced = activePolitics?.activePolicies.policies.find(x => worldState.PolicyTypeSettings!.data[x.policyTypeName].category === policyType.category);
    const toBeReplacedText = toBeReplaced !== undefined ? `Active policy "${worldState.PolicyTypeSettings!.data[toBeReplaced.policyTypeName].name}" will be replaced` : undefined;

    return <ThumbnailSlate
        title={<PolicyLink {...props} policyTypeSettings={worldState.PolicyTypeSettings} />}
        thumbnail={<PolicyCategoryIcon isGiant policyCategory={worldState.PolicyTypeSettings.data[props.policy.policyTypeName].category} />}
        footerContent={
            !playerState.Player.isDefeated &&
            !props.policy.hasObservingPlayerAcceptedBribe &&
            <ButtonHolder isPulledRight>
                <Button type="action" icon={IconHelper.Politics.Vote} action={() => vote(true)} text="Yea" isDisabled={props.policy.observingPlayersVote === true} />
                <Button type="danger" icon={IconHelper.Politics.Vote} action={() => vote(false)} text="Nay" isDisabled={props.policy.observingPlayersVote === false} />
                <Button type="warning" icon={IconHelper.Politics.Vote} action={() => vote(undefined)} text="Abstain" isDisabled={props.policy.hasObservingPlayerVoted && props.policy.observingPlayersVote === null} />
            </ButtonHolder>
        }
    >
        <QuoteParagraph>
            {props.policy.description}
        </QuoteParagraph>
        <PolicyTypeDescription policyType={policyType} />
        {policyType.isVictory && props.policy.federation !== undefined && <Paragraph>
            <FederationLink federation={props.policy.federation} /> will be declared rulers of the galaxy.
        </Paragraph>}
        {toBeReplacedText !== undefined && <Paragraph type="warning">{toBeReplacedText}</Paragraph>}
        <Paragraph>Voting ends {ValueFormatter.formatDateFromNowWithMoment(props.policy.votingEndDate)} ({ValueFormatter.formatTimeOrDate(props.policy.votingEndDate)})</Paragraph>
        {props.policy.hasObservingPlayerAcceptedBribe && <Paragraph type="warning">You accepted a bribe for this policy vote</Paragraph>}
    </ThumbnailSlate>;
});