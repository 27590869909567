import React from "react";
import { ICelestialTypeSettings, IInstallationTypeSettings, InstallationCategory, SolarSystemClaimType } from "../../../../ApplicationState/ApiClient";
import { Table } from "../../../../Components/Base/Containers/Table";
import { Select } from "../../../../Components/Base/Form/Select";
import { LoadingSpinner } from "../../../../Components/Base/Loading/LoadingSpinner";
import { TextAndIcon } from "../../../../Components/Base/Text/TextAndIcon";
import { SolarSystemClaimTypeIcon } from "../../../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon";
import { TickOrCrossIcon } from "../../../../Components/FusionShift/Icons/TickOrCrossIcon";
import { InstallationTypeImage } from "../../../../Components/FusionShift/Images/InstallationTypeImage";
import { InstallationTypeLink } from "../../../../Components/FusionShift/Links/InstallationTypeLink";
import { BuildHelper } from "../../../../Helpers/BuildHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";

type Props = {
    celestialTypeSettings: ICelestialTypeSettings,
    installationTypeSettings: IInstallationTypeSettings
}

export const CelestialList = (props: Props) => {

    const [celestialTypeName, setCelestialTypeName] = React.useState("");

    const celestials = React.useMemo(() => {

        return Object.values(props.celestialTypeSettings.data)
            .filter(x => x.typeName !== "Destroyed")
            .sort((a, b) => a.order < b.order ? -1 : 1);

    }, [props.celestialTypeSettings]);

    React.useEffect(() => {

        if (celestialTypeName.length === 0 && celestials !== undefined && celestials.length > 0) {
            setCelestialTypeName(celestials[0].typeName)
        }

    }, [celestialTypeName, celestials]);

    const installations = React.useMemo(() => {

        if (props.celestialTypeSettings === undefined) {
            return undefined;
        }

        return Object.values(props.installationTypeSettings.data)
            .filter(x => (x.researchPointsRequired ?? 0) === 0)
            .filter(x => BuildHelper.isCelestialTypeValid(x, celestialTypeName))
            .sort((a, b) => {

                if (a.category !== b.category) {
                    return a.category < b.category ? -1 : 1;
                }

                return a.order < b.order ? -1 : 1
            });

    }, [celestialTypeName, props.celestialTypeSettings]);

    if (installations === undefined) {
        return <LoadingSpinner />;
    }

    return <>
        <Select className="is-small" valueChanged={setCelestialTypeName} defaultValue={celestialTypeName || ""}
            values={celestials.map((c, i) => {
                return {
                    value: c.typeName,
                    label: c.name
                };
            })} />

        <Table isFixed isFullWidth
            heading={<>
                <th />
                <th />
                <th />
                <td>
                    <TextAndIcon
                        icon={<SolarSystemClaimTypeIcon solarSystem={{ claimType: SolarSystemClaimType.Capital }} />}
                        text={"Capital"}
                        hideTextOnMobile
                    />
                </td>
                <td>
                    <TextAndIcon
                        icon={<SolarSystemClaimTypeIcon solarSystem={{ claimType: SolarSystemClaimType.Colony }} />}
                        text={"Colony"}
                        hideTextOnMobile
                    />
                </td>
                <td>
                    <TextAndIcon
                        icon={<SolarSystemClaimTypeIcon solarSystem={{ claimType: SolarSystemClaimType.Outpost }} />}
                        text={"Outpost"}
                        hideTextOnMobile
                    />
                </td>
            </>}
        >
            {installations.map(x => <tr key={x.typeName}>
                <td>
                    <TextAndIcon text={InstallationCategory[x.category]} icon={IconHelper.Installations.installationCategory(x.category)} />
                </td>
                <td>
                    <InstallationTypeImage installationType={x} size="tiny" />
                </td>
                <td>
                    <InstallationTypeLink installationType={x} />
                </td>
                <td>
                    <TickOrCrossIcon type={BuildHelper.canBeBultAtSolarSystemClaimType(x, SolarSystemClaimType.Capital)} />
                </td>
                <td>
                    <TickOrCrossIcon type={BuildHelper.canBeBultAtSolarSystemClaimType(x, SolarSystemClaimType.Colony)} />
                </td>
                <td>
                    <TickOrCrossIcon type={BuildHelper.canBeBultAtSolarSystemClaimType(x, SolarSystemClaimType.Outpost)} />
                </td>
            </tr>)}
        </Table>
    </>;
}