import { CelestialFeature } from "../CelestialHelper";
import { FSOIcons } from "./FSOIcons";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class CelestialIcons {

    public get Apocalypse() { return MaterialDesignIcons.earthOff; }
    public get Habitable() { return MaterialDesignIcons.planetEarth; }

    public celestialType(celestialTypeName: string | undefined): string {

        if (celestialTypeName === undefined) {
            return MaterialDesignIcons.planet;
        }

        switch (celestialTypeName) {
            case "Habitable":
                return MaterialDesignIcons.planetEarth;
            case "Barren":
                return MaterialDesignIcons.planet;
            case "Vegetative":
                return MaterialDesignIcons.wheat;
            case "Gaseous":
                return MaterialDesignIcons.cloud;
            case "Ores":
                return MaterialDesignIcons.cog;
            case "Crystalline":
                return MaterialDesignIcons.star;
            case "Mineral":
                return MaterialDesignIcons.atom;
        }
        return MaterialDesignIcons.missing;
    }

    public celestialFeature(celestialFeature: CelestialFeature) {
        switch (celestialFeature) {
            case "Drones":
                return MaterialDesignIcons.bug;
            case "Megacity":
                return MaterialDesignIcons.city;
            case "Manufactory":
                return MaterialDesignIcons.factory;
            case "Build":
                return MaterialDesignIcons.hammer;
            case "Ships":
                return FSOIcons.buildShip;
        }
        if (!isNaN(celestialFeature.installationId)) {
            return MaterialDesignIcons.buildings;
        }
        return MaterialDesignIcons.missing;
    }
}