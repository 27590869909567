import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { celestial_installation } from '../../../Navigation/Routing/SolarSystem';

type Props = {
    installation: {
        celestialId: number,
        installationId: number,
        level: number,
        installationTypeName: string
    }
}

export const InstallationLink = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.InstallationTypeSettings) {
        return null;
    }

    const name = props.installation.installationTypeName in worldState.InstallationTypeSettings.data ? worldState.InstallationTypeSettings.data[props.installation.installationTypeName].name : props.installation.installationTypeName;

    return <Link to={celestial_installation(props.installation.celestialId, props.installation.installationId)}>
        {name} [{props.installation.level}]
    </Link>;
});