import * as React from 'react';
import { ShipClass } from '../../../ApplicationState/ApiClient';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ShipClassIcon } from '../Icons/ShipClassIcon';

type Props = {
    shipClass: ShipClass
}

export const ShipClassName = (props: Props) => {

    const name = ValueFormatter.spacesBeforeCapitals(ShipClass[props.shipClass]);

    return <span>
        <ShipClassIcon {...props} />
        {name}
    </span>;
};