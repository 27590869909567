import { AttackTerms, BribeForManifestoTerms, BribeForPolicyTerms, ContractType, CourierTerms, DefendTerms, ITerms, RansomTerms, ReconTerms, ValidationResult } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Configuration } from '../CreateContractView';
import { AttackForm } from './AttackForm';
import { BribeForManifestoForm } from './BribeForManifestoForm';
import { BribeForPolicyForm } from './BribeForPolicyForm';
import { CourierForm } from './CourierForm';
import { DefendForm } from './DefendForm';
import { RansomForm } from './RansomForm';
import { ReconForm } from './ReconForm';

type Props = {
    contractType: ContractType,
    terms: ITerms,
    configuration: Configuration,
    setTermsAndConfigure: (terms: ITerms, configuration: Configuration) => any,
    setValidation: (validation: ValidationResult | undefined) => any,
    changeType: () => any
}

export const TermsRouter = (props: Props) => {

    return <SubPanel
        heading={{ text: "Terms", icon: IconHelper.Economy.ContractTerms }}
        headingContent={<Button type="nav" text={"Change Type"} icon={IconHelper.General.Edit} action={props.changeType} />}
    >
        {props.terms instanceof AttackTerms && <AttackForm {...props} terms={props.terms} />}
        {props.terms instanceof DefendTerms && <DefendForm {...props} terms={props.terms} />}
        {props.terms instanceof ReconTerms && <ReconForm {...props} terms={props.terms} />}
        {props.terms instanceof CourierTerms && <CourierForm {...props} terms={props.terms} />}
        {props.terms instanceof BribeForManifestoTerms && <BribeForManifestoForm {...props} terms={props.terms} />}
        {props.terms instanceof BribeForPolicyTerms && <BribeForPolicyForm {...props} terms={props.terms} />}
        {props.terms instanceof RansomTerms && <RansomForm {...props} terms={props.terms} />}
    </SubPanel>;
};