import React from "react";
import { Link } from "react-router-dom";
import { IPlayerMinimal } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { RelationHelper } from "../../../Helpers/RelationHelper";
import { player_view } from "../../../Navigation/Routing/Player";
import { Icon } from "../../Base/Icon";
import { FederationRankIcon } from "../Icons/FederationRankIcon";
import { SecurityStatusIcon } from "../Icons/SecurityStatusIcon";
import { PlayerFederationLink } from "./PlayerFederationLink";

type Props = {
    player: IPlayerMinimal | undefined,
    className?: string | undefined,
    hideFederationLink?: boolean
}

export const PlayerLink = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!props.player) {
        return null;
    }

    const className = `${props.className ? `${props.className} ` : ""}${RelationHelper.relationClassName(props.player, playerState.Player)} ${props.player.isDefeated ? "is-defeated" : ""}`;

    return <>
        <SecurityStatusIcon securityStatus={props.player.securityStatus} />
        {props.player.isDefeated && <Icon icon={IconHelper.Combat.Defeated} />}
        {!props.hideFederationLink && <PlayerFederationLink federation={props.player.federation} />}
        {props.hideFederationLink && props.player.federation && <FederationRankIcon federationRank={props.player.federation.federationRank} />}
        {!!props.player.federation && " "}
        <Link className={className} to={player_view(props.player.playerId)}>{props.player.name}</Link>
    </>;
};