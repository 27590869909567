import { IconHelper } from '../../../Helpers/IconHelper';
import { Button, ClipPosition } from './Button';

type Props = {
    className?: string | undefined,
    isExpanded: boolean,
    isDisabled?: boolean,
    isSmall?: boolean,
    setIsExpanded: (isExpanded: boolean) => any,
    forceClip?: ClipPosition
}

export const ExpandButton = (props: Props) => {
    return <Button
        type="nav"
        title={props.isExpanded ? "Collapse" : "Expand"}
        {...props}
        icon={IconHelper.General.toggleExpanded(props.isExpanded)}
        action={() => props.setIsExpanded(!props.isExpanded)} isDisabled={props.isDisabled}
    />
};