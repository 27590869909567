import * as React from 'react';
import { StepData, Steps, stepWithIcon } from '../../../Components/Base/Steps';
import { IconHelper } from '../../../Helpers/IconHelper';
import { JoinWorldBag } from './JoinWorldBag';

export type JoinWorldStage = "World" | "Locked" | "Faction" | "Friend" | "Location" | "Confirm";

type Props = {
    currentStage: JoinWorldStage,
    changeStage: (stage: JoinWorldStage) => any,
    joinWorldBag: JoinWorldBag
}

export const StagesControl = (props: Props) => {

    const [steps, setSteps] = React.useState<StepData<JoinWorldStage>[] | undefined>(undefined);

    React.useEffect(() => {

        const allowedStages = props.joinWorldBag.AllowedStages;
        const asSteps = allowedStages.map(s => {
            switch (s) {
                case "World":
                    return stepWithIcon<JoinWorldStage>("World", IconHelper.Map.TacticalMap, "World", "Join a world");
                case "Faction":
                    return stepWithIcon<JoinWorldStage>("Faction", IconHelper.Categories.Factions, "Faction", "Choose your faction");
                case "Friend":
                    return stepWithIcon<JoinWorldStage>("Friend", IconHelper.General.Friend, "Friend", "Invited by a friend");
                case "Location":
                    return stepWithIcon<JoinWorldStage>("Location", IconHelper.Map.ShowOnMap, "Start Location", "Choose where to start");
                case "Locked":
                    return stepWithIcon<JoinWorldStage>("Locked", IconHelper.Account.Password, "Locked", "Enter admin password");
                case "Confirm":
                    return stepWithIcon<JoinWorldStage>("Confirm", IconHelper.General.Confirm, "Confirm", "Get ready to play");
            }
        });
        setSteps(asSteps)

    }, [props.joinWorldBag.AllowedStages]);

    if (steps === undefined) {
        return null;
    }

    const canSkip = props.joinWorldBag.IsValid;

    return <Steps
        steps={steps}
        currentStep={props.currentStage}
        onStepClicked={props.changeStage}
        allowSkippingToFutureSteps={canSkip}
    />;
};