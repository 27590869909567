import * as React from 'react';
import { LoadingSpinner } from './LoadingSpinner';

type Props = {
    isLoading: boolean,
    children: React.ReactNode
}

export const LoadingWrapper = (props: Props) => {

    if (props.isLoading) {
        return <div className="loading-wrapper">
            <div className="has-blur">
                {props.children}
            </div>
            <LoadingSpinner />
        </div>
    }

    return <>{props.children}</>;
}