import React from "react";
import { ItemCategory } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { ItemsHelper } from "../../../Helpers/ItemsHelper";
import { CommoditiesIcon } from "../Icons/Items/CommoditiesIcon";
import { CreditsIconWithQuantity } from "../Icons/Items/CreditsIconWithQuantity";
import { ItemIconWithQuantity } from "../Icons/Items/ItemIconWithQuantity";
import { RawResourceIcon } from "../Icons/Items/RawResourceIcon";

type Props = {
    items: { [key: string]: number; } | undefined,
    resourceCapacities?: { [key: string]: number; } | undefined,
    otherCapacities?: { [key in keyof typeof ItemCategory]?: number; } | undefined,
    hideCapacity?: boolean,
    dontColourText?: boolean,
    credits?: number | undefined
}

export const ItemsWithCapacityControl = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings || (!props.items && props.credits === undefined)) {
        return null;
    }

    const items = ItemsHelper.buildItemsFancyDetail(
        props.items ?? {},
        undefined,
        props.resourceCapacities,
        props.otherCapacities,
        "hide-by-typesettings",
        worldState.ItemTypeSettings);


    if (items.length === 0 && props.credits === undefined) {
        return null;
    }

    const resources = items.filter(i => i.itemType.category === ItemCategory.Resource);
    const commodities = CollectionHelper.sumOfArray(items.filter(i => i.itemType.category === ItemCategory.Commodity).map(c => c.quantity));
    const rawResources = CollectionHelper.sumOfArray(items.filter(i => i.itemType.category === ItemCategory.RawResource).map(c => c.quantity));

    const commodityDanger = props.otherCapacities && props.otherCapacities.Commodity && commodities >= props.otherCapacities.Commodity;
    const rawResourceDanger = props.otherCapacities && props.otherCapacities.RawResource && rawResources >= props.otherCapacities.RawResource;

    return <>
        {props.credits !== undefined && props.credits > 0 && <CreditsIconWithQuantity quantity={props.credits} />}
        {resources.map(c => <div className={`resource ${c.isDanger ? "is-danger" : ""}`} key={c.itemType.typeName}><ItemIconWithQuantity itemType={c.itemType} quantity={c.quantity} capacity={c.capacity} {...props} /></div>)}
        {commodities > 0 && <div className={`commodities ${commodityDanger ? "is-danger" : ""}`}> <CommoditiesIcon quantity={commodities} capacity={props.otherCapacities && props.otherCapacities.Commodity}  {...props} /></div>}
        {rawResources > 0 && <div className={`rawresources ${rawResourceDanger ? "is-danger" : ""}`}> <RawResourceIcon quantity={rawResources} capacity={props.otherCapacities && props.otherCapacities.RawResource}  {...props} /></div>}
    </>;
};