import * as React from 'react';
import { Contract, ContractType } from '../../../ApplicationState/ApiClient';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { ViewButton } from '../../../Components/Base/Buttons/ViewButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { Icon } from '../../../Components/Base/Icon';
import { handleOnClick } from '../../../Components/Base/Util';
import { CreditsIconWithQuantity } from '../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { contractById } from '../../../Navigation/Routing/Contracts';
import { ContractStatusDescription } from './ContractStatusDescription';

type SharedProps = {
    hideOwner?: boolean,
    hideAssignedTo?: boolean,
    atSolarSystemId?: number
}

type Props = SharedProps & {
    contracts: Contract[]
}

type RowProps = SharedProps & {
    hideAllLocations: boolean,
    contract: Contract
}

const Row = (props: RowProps) => {

    const appState = React.useContext(AppStateContext);

    const showTo = props.contract.sourceSolarSystemId && props.contract.targetSolarSystemId && props.contract.sourceSolarSystemId !== props.atSolarSystemId && props.contract.targetSolarSystemId !== props.atSolarSystemId;
    const showFrom = props.contract.sourceSolarSystemId && props.contract.targetSolarSystemId && props.contract.targetSolarSystemId === props.atSolarSystemId

    return <>
        <tr onClick={e => handleOnClick(e, () => appState.navigate(contractById(props.contract.contractId)))}>
            {!props.hideOwner && <td>
                <PlayerLink player={props.contract.player} />
            </td>}
            <td>
                <Icon icon={IconHelper.Economy.contractType(props.contract.type)} /> {ValueFormatter.friendlyNameForContractType(props.contract.type)}
            </td>
            <td>
                <CreditsIconWithQuantity quantity={props.contract.initialCredits} />
            </td>
            <td>
                <ContractStatusDescription contractStatus={props.contract.status} />
            </td>
            {!props.hideAssignedTo && <td className="is-hidden-mobile">
                {props.contract.forPlayer !== undefined && <PlayerLink player={props.contract.forPlayer} />}
            </td>}
            {!props.hideAllLocations &&
                <td className="is-hidden-mobile">
                    {showFrom && "From "}
                    {props.contract.sourceSolarSystem !== undefined && props.contract.sourceSolarSystemId !== props.atSolarSystemId && <SolarSystemLink solarSystem={props.contract.sourceSolarSystem} />}
                    {showTo && " to "}
                    {props.contract.targetSolarSystem !== undefined && props.contract.targetSolarSystemId !== props.atSolarSystemId && <SolarSystemLink solarSystem={props.contract.targetSolarSystem} />}
                </td>
            }
            <td className="is-hidden-mobile">
                <ViewButton to={contractById(props.contract.contractId)} />
            </td>
        </tr>
        <tr className="is-hidden-tablet">
            {!props.hideAssignedTo && <td >
                {props.contract.forPlayer !== undefined && <PlayerLink player={props.contract.forPlayer} />}
            </td>}
            {!props.hideAllLocations &&
                <td>
                    {showFrom && "From "}
                    {props.contract.sourceSolarSystem !== undefined && props.contract.sourceSolarSystemId !== props.atSolarSystemId && <SolarSystemLink solarSystem={props.contract.sourceSolarSystem} />}
                    {showTo && <br />}
                    {showTo && " to "}
                    {props.contract.targetSolarSystem !== undefined && props.contract.targetSolarSystemId !== props.atSolarSystemId && <SolarSystemLink solarSystem={props.contract.targetSolarSystem} />}
                </td>
            }
            <td>
                <ViewButton to={contractById(props.contract.contractId)} />
            </td>
        </tr>
    </>;
}

export const ContractTable = (props: Props) => {

    if (props.contracts.length === 0) {
        return null;
    }

    const hideAllLocations = props.atSolarSystemId !== undefined && props.contracts.find(x => x.type === ContractType.Courier) === undefined;

    return <Table
        isFullWidth
        isHoverable
        isClickable
        hideHeaderMobile
        isMobileMultirow
        heading={
            <>
                {!props.hideOwner && <th>
                    Owner
                </th>}
                <th>
                    Type
                </th>
                <th>
                    Credits
                </th>
                <th>
                    Status
                </th>
                {!props.hideAssignedTo && <th>
                    Assigned To
                </th>}
                {!hideAllLocations &&
                    <th>
                        Location
                    </th>
                }
                <th></th>
            </>
        }>
        {props.contracts.map(x => <Row key={x.contractId} {...props} contract={x} hideAllLocations={hideAllLocations} />)}
    </Table>;
}