import { FSOIcons } from "./FSOIcons";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class CategoryIcons {

    public get Home() { return MaterialDesignIcons.home };

    public get Player() { return MaterialDesignIcons.account; }
    public get Summary() { return MaterialDesignIcons.sigma; }
    public get Advisor() { return FSOIcons.advisor; }
    public get Help() { return MaterialDesignIcons.lifebuoy; }
    public get Factions() { return MaterialDesignIcons.accountGroup; }
    public get Settings() { return MaterialDesignIcons.cog; };
    public get Message() { return MaterialDesignIcons.email; }
    public get Notification() { return MaterialDesignIcons.alertRhombus; }
    public get Bonuses() { return MaterialDesignIcons.plus; }
    public get Statistics() { return MaterialDesignIcons.chartBar; }

    public get Empire() { return MaterialDesignIcons.crown; }
    public get Network() { return MaterialDesignIcons.network; }
}