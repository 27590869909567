import { IValidationResult, ValidationResult } from "../../../ApplicationState/ApiClient";

export enum ValidationState {
    None,
    Loading,
    Valid,
    Invalid
}

export class ValidationHelper {

    public static validatingPromise(value: string, validate: (value: string) => ValidationResult) {
        return new Promise<ValidationResult>(resolve => {
            const result = validate(value);
            resolve(result);
        });
    }

    public static validatingPromiseBoolean(value: string, validate: (value: string) => boolean) {
        return new Promise<ValidationResult>(resolve => {
            const result = validate(value);
            resolve(result ? this.valid(value) : this.invalid());
        });
    }

    public static validationStateClassName(state: ValidationState) {
        if (state === ValidationState.Loading) {
            return "is-loading";
        } else if (state === ValidationState.Valid) {
            return "is-success";
        } else if (state === ValidationState.Invalid) {
            return "is-danger";
        }

        return undefined;
    }

    public static empty() {
        const data: IValidationResult = {
            isValid: false,
            error: undefined,
            validText: undefined
        };
        return new ValidationResult(data);
    }

    public static invalid(error?: string | undefined) {
        const data: IValidationResult = {
            isValid: false,
            error: error,
            validText: undefined
        };
        return new ValidationResult(data);
    }

    public static valid(value: string) {
        const data: IValidationResult = {
            isValid: true,
            error: undefined,
            validText: value
        };
        return new ValidationResult(data);
    }
}