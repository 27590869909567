import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { SectorType } from "../../../ApplicationState/ApiClient";
import { help_sectorTypes } from "../../../Navigation/Routing/Help";
import { SectorTypeIcon } from "../Icons/SectorTypeIcon";

type Props = {
    sectorType: SectorType | undefined | false,
    hideTextOnMobile?: boolean
}

export const SectorTypeLink = (props: Props) => {

    if (!props.sectorType) {
        return null;
    }

    return <Link to={`${help_sectorTypes}?sectorTypeName=${props.sectorType.typeName}#${props.sectorType.typeName}`}>
        <SectorTypeIcon sectorTypeName={props.sectorType.typeName} />
        <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {props.sectorType.name}
        </span>
    </Link>;
};