import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { EmblemLayer, Manifest } from '../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { Checkbox } from '../../../../Components/Base/Form/Checkbox';
import { EmblemEditorContext } from '../../State/EmblemEditorContext';
import { DragHandle } from '../Components/DragHandle';
import { Gridlines } from '../Components/Gridlines';
import SvgRender from '../Components/SvgRender';
import { SavePanel } from './SavePanel';

type Props = {
}

type InnerProps = {
    manifest: Manifest,
    layers: EmblemLayer[]
}

const Inner = (props: InnerProps) => {

    const ref = React.useRef(null);
    const [dimensions, setDimensions] = React.useState({
        width: 0,
        height: 0,
    });
    React.useEffect(() => {
        if (ref.current) {
            const { offsetWidth, offsetHeight } = ref.current;

            setDimensions({
                width: offsetWidth,
                height: offsetHeight
            });
        }
    }, []);

    const [showControls, setShowControls] = React.useState(true);
    const [showGridlines, setShowGridlines] = React.useState(true);

    return <>
        <div className="preview-area" ref={ref}>
            {showGridlines &&
                <Gridlines size={dimensions.width} />
            }
            <SvgRender layers={props.layers} manifest={props.manifest} />
            {showControls &&
                <DragHandle size={dimensions.width} defaultEmblemSize={props.manifest.placeholders.uniformSize} />
            }
        </div>
        <br />
        <div className="is-flex">
            <Checkbox label="Show Controls" value={showControls} valueChanged={setShowControls} />
            <Checkbox label="Show Gridlines" value={showGridlines} valueChanged={setShowGridlines} />
        </div>
    </>;
}

export const EmblemRenderPanel = observer((props: Props) => {

    const emblemEditorState = React.useContext(EmblemEditorContext);

    if (emblemEditorState.emblemLayers === undefined || emblemEditorState.manifest === undefined) {
        return null;
    }

    return <SubPanel
        heading="Preview"
        className="preview"
    >
        <Inner layers={emblemEditorState.emblemLayers} manifest={emblemEditorState.manifest} />
        <SavePanel />
    </SubPanel>;
});