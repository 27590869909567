import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { ICelestial, ItemCategory, ItemsDetail } from "../../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { ButtonChooser, choice, maybeChoice } from "../../../../../Components/Base/Buttons/ButtonChooser";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../../../Components/Base/Containers/Table";
import { Icon } from "../../../../../Components/Base/Icon";
import { DateText } from "../../../../../Components/Base/Text/DateText";
import { ItemIconWithQuantity } from "../../../../../Components/FusionShift/Icons/Items/ItemIconWithQuantity";
import { CollectionHelper } from "../../../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";
import { celestial_manufactory } from "../../../../../Navigation/Routing/SolarSystem";
import { RowConfiguration, capacityRowConfiguration, currentRowConfiguration, finalRowConfiguration, harvestedRowConfigurations, manufactoryRowConfigurations, netAfterProductionRowConfiguration, netAfterUpkeepRowConfiguration, productionRowConfigurations, totalRowCssClasses, upkeepRowConfigurations } from "./RowConfigurations";

type Props = {
    celestials: ICelestial[],
    itemsDetail: ItemsDetail,
    items: { [key: string]: number }
}

function hideFor(resourceTypeName: string, selectedResourceTypeName: string) {
    return resourceTypeName === selectedResourceTypeName ? "" : "is-hidden-mobile";
}

function collectionFrom(object, configuration: RowConfiguration) {
    if (configuration.propertyName in object) {
        return object[configuration.propertyName];
    }

    return {};
}

type RowProps = RowConfiguration & {
    itemTypeNames: string[],
    values: { [key: string]: number } | undefined,
    selectedResourceTypeName: string
}

type SectionRowProps = {
    title: React.ReactNode,
    icon: string,
    itemTypeNames: string[],
    keysToDetermineDisplay: string[],
    selectedResourceTypeName: string,
    rowConfigurations: { valuesFrom: any, rowsConfigurations: RowConfiguration[] }[],
    totalRowConfiguration?: { valuesFrom: any, rowConfiguration: RowConfiguration } | undefined
}

const ResourceSectionRow = (props: SectionRowProps) => {

    if (!CollectionHelper.isAnyInArray(props.keysToDetermineDisplay)) {
        return null;
    }

    const sharedProps = {
        itemTypeNames: props.itemTypeNames,
        selectedResourceTypeName: props.selectedResourceTypeName
    };

    return <>
        <tr>
            <td>
                {props.title}<Icon icon={props.icon} />
            </td>
            {props.itemTypeNames.map(r => <td className={hideFor(r, props.selectedResourceTypeName)} key={r} />)}
        </tr>
        {props.rowConfigurations.map((c, i) => <React.Fragment key={i}>
            {c.rowsConfigurations.map(config => <ResourceDetailRow key={config.propertyName} values={collectionFrom(c.valuesFrom, config)} {...sharedProps} {...config} />)}
        </React.Fragment>
        )}
        {props.totalRowConfiguration !== undefined &&
            <ResourceDetailRow values={collectionFrom(props.totalRowConfiguration.valuesFrom, props.totalRowConfiguration.rowConfiguration)} {...sharedProps} {...props.totalRowConfiguration.rowConfiguration} />
        }
    </>
}

const ResourceDetailRow = (props: RowProps) => {

    const filteredValues = Object.keys(props.values ?? {})
        .filter(x => props.itemTypeNames.includes(x))
        .map(x => props.values![x])
        .filter(x => props.showEvenIfZero || x !== 0);

    if (!CollectionHelper.isAnyInArray(filteredValues)) {
        return null;
    }

    return <tr className={props.rowClassName}>
        <td>
            {props.label}
        </td>
        {props.itemTypeNames.map(r => {

            const value = props.values && r in props.values ? props.values[r] : undefined;
            const danger = value !== undefined &&
                ((value < 0 && props.dangerIf === "lessThanZero") ||
                    (value > 0 && props.dangerIf === "greaterThanZero")) ?
                "has-text-danger" : "";

            const formatted = value === undefined ? "" :
                props.format === "localeNegated" ? `-${ValueFormatter.formatLocaleNumber(value)}` :
                    props.format === "percentBonus" ? `${ValueFormatter.fromDecimalToDisplayPercent(value, false, 1)}` :
                        ValueFormatter.formatLocaleNumber(value);

            return <td key={r} className={`${danger} ${hideFor(r, props.selectedResourceTypeName)}`} >
                {formatted}
            </td>
        })}
    </tr>
};

export const ResourceDetailTable = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [selectedResourceTypeName, setSelectedResourceTypeName] = React.useState("Gas");
    const [selectedCategory, setSelectedCategory] = React.useState(ItemCategory.Resource);

    if (!worldState.ItemTypeSettings) {
        return null;
    }

    function keysFrom(collection: { [key: string]: any } | undefined): string[] {
        if (!collection) {
            return [];
        }
        return Object.keys(collection);
    }

    function valueFrom<T>(collection: { [key: string]: T } | undefined, key: string): T | undefined {
        if (!collection) {
            return undefined;
        }
        return key in collection ? collection[key] : undefined;
    }

    const { allKeys, items, fromHarvested, fromUpkeep, fromProduction, fromManufactory } = React.useMemo(() => {

        const fromHarvested = harvestedRowConfigurations.map(i => collectionFrom(props.itemsDetail.harvested, i))
            .map(i => keysFrom(i))
            .flat();

        const fromUpkeep = upkeepRowConfigurations.map(e => collectionFrom(props.itemsDetail.upkeep, e))
            .map(e => keysFrom(e))
            .flat();

        const fromProduction = productionRowConfigurations.map(e => collectionFrom(props.itemsDetail.production, e))
            .map(e => keysFrom(e))
            .flat();

        const fromManufactory = manufactoryRowConfigurations.map(e => collectionFrom(props.itemsDetail.manufactories, e))
            .map(e => keysFrom(e))
            .flat();

        const allKeys = keysFrom(props.items)
            .concat(keysFrom(collectionFrom(props.itemsDetail, netAfterUpkeepRowConfiguration)))
            .concat(keysFrom(collectionFrom(props.itemsDetail, capacityRowConfiguration)))
            .concat(fromHarvested)
            .concat(fromUpkeep)
            .concat(fromProduction)
            .concat(fromManufactory)
            .filter((v, i, s) => s.indexOf(v) === i);

        const items = allKeys
            .map(k => worldState.ItemTypeSettings!.data[k])
            .filter(k => k.category === selectedCategory)
            .map(itemType => {
                const resourceTypeName = itemType.typeName;
                const quantity = valueFrom(props.items, resourceTypeName);
                const capacity = valueFrom(props.itemsDetail.resourceCapacities, resourceTypeName);
                const net = valueFrom(props.itemsDetail!.netFinal, resourceTypeName);
                const remainingUntilExpiry =
                    net && net > 0 ?
                        (quantity && capacity ? capacity - quantity : 0) :
                        (quantity ? quantity : 0);
                const hours = remainingUntilExpiry > 0 && net ? remainingUntilExpiry / Math.abs(net) : undefined;
                const expiryDate = hours ? new Date() : undefined;

                if (hours && expiryDate) {
                    expiryDate.setTime(expiryDate.getTime() + (hours * 60 * 60 * 1000));
                }

                return {
                    itemType,

                    net,
                    capacity: capacity ? capacity : 0,
                    quantity,
                    expiryDate
                }
            }).sort(((a, b) => a.itemType.order > b.itemType.order ? 1 : -1));


        return {
            allKeys,
            items,
            fromHarvested,
            fromUpkeep,
            fromProduction,
            fromManufactory
        };

    }, [props.itemsDetail, props.items, worldState.ItemTypeSettings, selectedCategory]);

    const itemTypeNames = items.map(r => r.itemType.typeName);

    const options = items.map(r => choice(r.itemType.typeName, r.itemType.name, IconHelper.Items.itemType(r.itemType), r.itemType.typeName.toLocaleLowerCase()));

    const sharedProps = {
        itemTypeNames,
        selectedResourceTypeName
    };

    const celestialKeys = Object.keys(props.itemsDetail.manufactories.productionByCelestialId);
    const manufactoryCelestialsConfiguration = fromManufactory.length === 0 ? [] :
        celestialKeys
            .map(celestialId => {
                const celestial = props.celestials.find(c => c.celestialId.toString() === celestialId);
                const label = celestial ? celestial.name : celestial;
                const labelContent = <Link to={celestial_manufactory(celestialId)}>Manufactory at {label}</Link>;

                return {
                    celestialId,
                    title: labelContent,
                    icon: IconHelper.Manufactories.Manufactory,
                    valuesFrom: props.itemsDetail.manufactories.productionByCelestialId[celestialId],
                    rowConfigurations: manufactoryRowConfigurations.map(r => {
                        return {
                            ...r,
                            rowClassName: `${r.rowClassName} ${celestialKeys.length > 1 ? "is-double-tabbed" : ""}`
                        }
                    })
                };
            })

    const hasRawResource = allKeys.find(x => x in worldState.ItemTypeSettings!.data && worldState.ItemTypeSettings!.data[x].category === ItemCategory.RawResource) !== undefined;

    if (!hasRawResource && selectedCategory === ItemCategory.RawResource) {
        setSelectedCategory(ItemCategory.Resource);
    }

    const categoryOptions = [
        maybeChoice(true, ItemCategory.Resource, "Resources", IconHelper.Items.Resource),
        maybeChoice(hasRawResource, ItemCategory.RawResource, "Raw Resources", IconHelper.Items.RawResource)
    ];

    return <SubPanel
        isUnpadded
        heading={{ text: "Resources", icon: IconHelper.Items.Resource }}
        headingContent={hasRawResource && <ButtonChooser
            values={categoryOptions}
            value={selectedCategory}
            valueChanged={setSelectedCategory}
        />
        }
    >
        <ButtonChooser values={options} valueChanged={setSelectedResourceTypeName} value={selectedResourceTypeName} className="is-hidden-tablet" />
        <Table isHoverable isFullWidth heading={
            <>
                <td>
                </td>
                {items.map(r => <td key={r.itemType.typeName} className={hideFor(r.itemType.typeName, selectedResourceTypeName)} >
                    <ItemIconWithQuantity itemType={r.itemType} />  {r.itemType.name}
                </td>)}
            </>
        }>
            <ResourceSectionRow title="Income" icon={IconHelper.Items.Resource} keysToDetermineDisplay={fromUpkeep.concat(fromHarvested)} {...sharedProps}
                rowConfigurations={[{
                    valuesFrom: props.itemsDetail.harvested,
                    rowsConfigurations: harvestedRowConfigurations
                },
                {
                    valuesFrom: props.itemsDetail.upkeep,
                    rowsConfigurations: upkeepRowConfigurations
                }]}
                totalRowConfiguration={{
                    valuesFrom: props.itemsDetail,
                    rowConfiguration: netAfterUpkeepRowConfiguration
                }}
            />

            <ResourceSectionRow title="Production" icon={IconHelper.Items.Schematic} keysToDetermineDisplay={fromProduction} {...sharedProps}
                rowConfigurations={[{
                    valuesFrom: props.itemsDetail.production,
                    rowsConfigurations: productionRowConfigurations
                }]}
                totalRowConfiguration={{
                    valuesFrom: props.itemsDetail,
                    rowConfiguration: netAfterProductionRowConfiguration
                }}
            />

            {fromManufactory.length > 0 && <>
                {manufactoryCelestialsConfiguration.map(c => <ResourceSectionRow key={c.celestialId} {...c} keysToDetermineDisplay={fromManufactory} {...sharedProps}
                    rowConfigurations={[{
                        valuesFrom: c.valuesFrom,
                        rowsConfigurations: c.rowConfigurations
                    }]}
                />)}
                {celestialKeys.length > 1 && <ResourceSectionRow title="Manufactories Total" icon={IconHelper.Manufactories.Manufactory} keysToDetermineDisplay={fromManufactory} {...sharedProps}
                    rowConfigurations={[{
                        valuesFrom: props.itemsDetail.manufactories,
                        rowsConfigurations: manufactoryRowConfigurations
                    }]}
                />
                }
            </>}

            <ResourceDetailRow values={collectionFrom(props.itemsDetail, finalRowConfiguration)} {...sharedProps} {...finalRowConfiguration} />

            <ResourceDetailRow values={collectionFrom(props.itemsDetail, capacityRowConfiguration)} {...sharedProps} {...capacityRowConfiguration} />

            <ResourceDetailRow values={props.items} {...sharedProps} {...currentRowConfiguration} />
            <tr className={totalRowCssClasses}>
                <td>
                    Capacity Reached /<br />Fully Consumed
                </td>
                {items.map(r => <td key={r.itemType.typeName} className={hideFor(r.itemType.typeName, selectedResourceTypeName)} >
                    {r.expiryDate &&
                        <span className={r.net !== undefined && r.net < 0 ? "has-text-danger" : ""}>
                            <DateText date={r.expiryDate} />
                        </span>
                    }
                    {r.quantity !== undefined && r.net !== undefined && r.quantity <= 0 && r.net < 0 &&
                        <span className="has-text-danger">
                            EMPTY
                        </span>
                    }
                    {selectedCategory === ItemCategory.Resource && r.quantity !== undefined && r.quantity >= r.capacity &&
                        <span className="has-text-danger">
                            FULL
                        </span>
                    }
                </td>)}
            </tr>
        </Table>
    </SubPanel >;
});