import * as React from 'react';
import { AttackTerms, IContract } from '../../../../ApplicationState/ApiClient';
import { CreditsIconWithQuantity } from '../../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { SolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';

type Props = {
    contract: IContract,
    terms: AttackTerms
}

export const AttackTermsDetail = (props: Props) => {

    return <div className="content">
        <ul >
            <li>Attack <SolarSystemLink solarSystem={props.contract.targetSolarSystem!} /></li>
            <li>Arrive before {ValueFormatter.formatTimeOrDate(props.terms.arriveByDate, true)}</li>
            <li>Payment is <CreditsIconWithQuantity quantity={props.terms.payment} /></li>
            <li>Attack with at least {ValueFormatter.formatLocaleNumber(props.terms.minimumTotalAttack)}</li>
        </ul>
    </div>;
};