import * as React from 'react';
import { RangeByLevel } from '../../../ApplicationState/ApiClient';
import { TickOrCrossIcon } from '../../../Components/FusionShift/Icons/TickOrCrossIcon';

type Props = {
    level: number,
    levels: RangeByLevel
}

export const LevelRelation = (props: Props) => {

    if (props.level < props.levels.min) {
        return <span className="has-text-danger">
            Min: {props.levels.min}
        </span>;
    }

    if (props.level < props.levels.optimal) {
        return <span className="has-text-warning">
            Optimal: {props.levels.optimal}
        </span>;
    }

    if (props.level < props.levels.max) {
        return <span>
            Best: {props.levels.max}
        </span>;
    }

    return <TickOrCrossIcon type="tick" />;
};