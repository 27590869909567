import * as React from 'react';
import { CourierTerms, IContract } from '../../../../ApplicationState/ApiClient';
import { CreditsIconWithQuantity } from '../../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { ItemTypeLinkFromTypeName } from '../../../../Components/FusionShift/Links/Items/ItemTypeLink';
import { SolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';

type Props = {
    contract: IContract,
    terms: CourierTerms
}

export const CourierTermsDetail = (props: Props) => {

    return <div className="content">
        <ul >
            <li>Collect from <SolarSystemLink solarSystem={props.contract.sourceSolarSystem!} /></li>
            <li>Collect before {ValueFormatter.formatTimeOrDate(props.terms.expiryDate, true)}</li>
            <li>Collect <ItemTypeLinkFromTypeName itemTypeName={props.terms.itemTypeName} />, up to x{ValueFormatter.formatLocaleNumber(props.terms.quantity)}</li>
            <li>Payment is <CreditsIconWithQuantity quantity={props.terms.paymentPerUnit} /> per unit</li>
            <li>Deliver to <SolarSystemLink solarSystem={props.contract.targetSolarSystem!} /></li>
            <li>Distance: {ValueFormatter.formatDistanceBetween(props.contract.sourceSolarSystem!, props.contract.targetSolarSystem!)} </li>
            <li>Arrive before {ValueFormatter.formatTimeOrDate(props.terms.expiryDate, true)}</li>
            {props.terms.minimumTotalDefence > 0 && <li>Fleets are required to have at least {ValueFormatter.formatLocaleNumber(props.terms.minimumTotalDefence)} total defence</li>}
            {props.terms.refreshFrequencyHours !== undefined && props.terms.refreshFrequencyHours > 0 && <li>This contract refreshes every {ValueFormatter.formatLocaleNumber(props.terms.refreshFrequencyHours)} hours</li>}
        </ul>
    </div>;
};