import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { EmpireCategory } from '../../../Views/Empire/EmpireView';
import { Icon } from '../../Base/Icon';
import { ProblemCategoryIcon } from './ProblemCategoryIcon';

type Props = {
    category: EmpireCategory
}

export const EmpireCategoryIcon = (props: Props) => {

    if (typeof props.category !== "string") {
        return <ProblemCategoryIcon category={props.category} />
    }
    if (props.category === "Advisor") {
        return <Icon icon={IconHelper.Categories.Advisor} />;
    }
    if (props.category === "Summary") {
        return <Icon icon={IconHelper.Categories.Summary} />;
    }
    if (props.category === "Network") {
        return <Icon icon={IconHelper.Categories.Network} />;
    }
    return <Icon icon={IconHelper.Categories.Empire} />;
};