import * as React from 'react';
import { ISolarSystemForList } from '../../ApplicationState/ApiClient';
import { AppStateContext, MobileMenuStateContext } from "../../ApplicationState/ContextRoot";
import { SendShipsIconLink } from "../../Components/FusionShift/Buttons/SendShipsIconLink";
import { GoToMapLocationIcon } from "../../Components/FusionShift/Icons/GoToMapLocationIcon";
import { SolarSystemClaimTypeIcon } from '../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon';
import { SolarSystemForListDangerIcons } from "../../Components/FusionShift/Icons/SolarSystemDangerIcons";

type Props = {
    solarSystem: ISolarSystemForList,
    isSelected: boolean,
    setOpen: (open: boolean) => any,
    isOpen: boolean
}

export const SolarSystemMobileItem = (props: Props) => {

    const mobileMenuState = React.useContext(MobileMenuStateContext);
    const appState = React.useContext(AppStateContext);

    function navigate(to: string) {
        appState.changeSolarSystemId(props.solarSystem.solarSystemId, false, to);
        closeMenu();
    }

    function closeMenu() {
        mobileMenuState.close();
    }

    function select(e) {
        appState.changeSolarSystemId(props.solarSystem.solarSystemId);
        closeMenu();
    }

    return <div className="solar-system-mobile-item" >
        <div className={`header ${props.isSelected ? "selected" : ""}`}>
            <h2 onClick={select} >
                <SolarSystemClaimTypeIcon solarSystem={props.solarSystem} />
                {props.solarSystem.name}
                <SolarSystemForListDangerIcons solarSystem={props.solarSystem} />
            </h2>
            <span className="action-icons is-pulled-right" onClick={closeMenu}>
                <SendShipsIconLink solarSystem={props.solarSystem} />
                <GoToMapLocationIcon x={props.solarSystem.x} y={props.solarSystem.y} />
            </span>
        </div>
    </div>;
}