import classNames from 'classnames';
import * as React from 'react';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { CapacityBar } from './CapacityBar';
import { Icon } from './Icon';

type Props = {
    isDanger?: boolean,
    isWarning?: boolean,
    className?: string | undefined,
    leftIcon?: string | React.ReactNode | undefined,
    rightIcon?: string | React.ReactNode | undefined,
    appendToValue?: string | undefined,
    value: number,
    children?: React.ReactNode | undefined
    total?: number | undefined,
    title?: string | undefined
}

export const BarBlock = (props: Props) => {

    const className = classNames(
        "bar-block",
        props.className,
        {
            "is-danger": props.isDanger,
            "is-warning": props.isWarning && !props.isDanger
        }
    );

    const value = props.children !== undefined && typeof props.children !== "string" ? props.children :
        <span className="value">
            {props.children !== undefined && props.children}
            {props.children === undefined && ValueFormatter.formatLocaleNumber(props.value) + (props.appendToValue !== undefined ? ` ${props.appendToValue}` : "")}
        </span>;

    return <div title={props.title} className={className} >
        {props.leftIcon !== undefined && typeof props.leftIcon === "string" && <Icon icon={props.leftIcon} />}
        {props.leftIcon !== undefined && typeof props.leftIcon !== "string" && props.leftIcon}
        {value}
        {props.rightIcon !== undefined && typeof props.rightIcon === "string" && <Icon icon={props.rightIcon} />}
        {props.rightIcon !== undefined && typeof props.rightIcon !== "string" && props.rightIcon}
        <CapacityBar quantity={props.value} capacity={props.total !== undefined ? props.total : 1} />
    </div>;
}