import urlExists from 'url-exist';

export class UrlHelper {

    public static tryGetValue(parsedFromSearch: URLSearchParams, key: string, action: (value: string) => any) {
        const value = parsedFromSearch.get(key);
        if (value === null || value.length === 0) {
            return undefined;
        }

        return action(value);
    }

    public static tryGetNumberValue(parsedFromSearch: URLSearchParams, key: string, action: (value: number) => any) {
        const value = parsedFromSearch.get(key);
        if (value === null || value.length === 0) {
            return;
        }
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
            return;
        }

        return action(numberValue);
    }

    public static tryParseSearch(location: any, action: (parsedFromSearch: URLSearchParams) => any) {
        const parsedFromSearch = this.tryGetSearch(location);
        return action(parsedFromSearch);
    }

    public static tryGetValueFromSearch(location: any, key: string): string | undefined {
        return this.tryParseSearch(location, parsedFromSearch => {

            const value = parsedFromSearch.get(key);
            if (value === null || value.length === 0) {
                return undefined;
            }

            return value;
        });
    }

    public static tryGetNumberValueFromSearch(location: any, key: string): number | undefined {
        const value = this.tryGetValueFromSearch(location, key);

        if (value !== undefined) {
            const numberValue = Number(value);
            if (!isNaN(numberValue)) {
                return numberValue;
            }
        }

        return undefined;
    }

    private static tryGetSearch(location: any) {
        let search = location.search ?? "";
        if (search.startsWith('?')) {
            search = search.substring(1);
        }
        return new URLSearchParams(search);
    }
}

export const doesUrlExist = (url: string): Promise<boolean> => urlExists(url);