import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CelestialSize, ISolarSystemWithCelestials } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { Table } from '../../Base/Containers/Table';
import { CelestialImage } from './CelestialImage';
import { CelestialResourceBar } from './CelestialResourceBar';

type Props = {
    solarSystem: ISolarSystemWithCelestials
}

export const CelestialResourcesTable = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CelestialTypeSettings || !props.solarSystem.isExplored || props.solarSystem.owner !== undefined) {
        return null;
    }

    return <Table isFullWidth>
        {props.solarSystem.celestials.map(x => <tr key={x.celestialId}>
            <td className="celestial-image">
                <CelestialImage celestial={x} forcedSize={CelestialSize.Small} />
            </td>
            <td className="celestial-info">
                {CelestialHelper.fullname(x, worldState.CelestialTypeSettings!)}
            </td>
            <td>
                <CelestialResourceBar celestial={x} />
            </td>
        </tr>)}
    </Table>;
});