import * as React from "react";
import { Link } from "react-router-dom";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { forgotPassword, login, register } from "../../Navigation/Routing/AppRouteValues";

export const RegisterPrompt = () => {
    return <Paragraph>
        Need an account? <Link to={register}>Register</Link>
    </Paragraph>;
};

export const LoginPrompt = () => {
    return <Paragraph>
        Already have an account? <Link to={login}>Login</Link>
    </Paragraph>;
};

export const ForgotPasswordPrompt = () => {
    return <Paragraph>
        Forgot your password?{" "}
        <Link to={forgotPassword}>Reset</Link>
    </Paragraph>;
}