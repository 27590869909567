import { GalaxyMapView } from "../../Views/Map/Galaxy/GalaxyMapView";
import { TacticalMapView } from "../../Views/Map/Tactical/TacticalMap";
import { route } from "./Root";

export const map_tactical = "/map/tactical";
export function map_tactical_x_y(x: string | number, y: number | string) {
    return map_tactical + "/" + x + "/" + y;
}

export const map_galaxy = "/map/galaxy";
export function map_galaxy_x_y(x: string | number, y: number | string) {
    return map_galaxy + "/" + x + "/" + y;
}

export const mapRoutes = [
    route(map_tactical_x_y(":x?", ":y?"), TacticalMapView, "Tactical Map"),
    route(map_galaxy_x_y(":x?", ":y?"), GalaxyMapView, "Galaxy Map"),

    // Included for legacy links
    route("/map", TacticalMapView, "Tactical Map"),
];