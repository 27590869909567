import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as React from 'react';
import { FleetMovementType, ISolarSystemDetail, ISolarSystemForList } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { SolarSystemHelper } from "../../../Helpers/SolarSystemHelper";
import { Icon } from "../../Base/Icon";
import { FleetMovementTypeIcon } from "./FleetMovementTypeIcon";

type Props = {
    className?: string,
    solarSystem: ISolarSystemDetail | undefined,
    isPulledRight?: boolean
}

export const SolarSystemDangerIcons = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!props.solarSystem || !worldState.InstallationTypeSettings) {
        return null;
    }

    const danger = SolarSystemHelper.getSolarSystemDanger(props.solarSystem, worldState.InstallationTypeSettings);

    if (danger.incomingHostileMovements.length <= 0 && !danger.isLosingControl && !danger.hasApocalpyse) {
        return null;
    }

    const className = classNames(
        "solar-system-danger-icons",
        props.className,
        {
            "is-pulled-right": props.isPulledRight
        });

    return <span className={className}>
        {danger.hasApocalpyse && <Icon className="has-text-danger" icon={IconHelper.Celestials.Apocalypse} title="This system is the target of an apocalpyse shot" />}
        {danger.isLosingControl && <Icon className="has-text-danger" icon={IconHelper.SolarSystems.LosingControl} title="You are losing control of this system" />}
        {danger.incomingHostileMovements.map(m => <FleetMovementTypeIcon key={m} movementType={m} isPlayerOwner={false} isPlayerAllied={false} />)}
    </span>;
});


type ListProps = {
    className?: string,
    solarSystem: ISolarSystemForList | undefined,
    isPulledRight?: boolean
}

export const SolarSystemForListDangerIcons = (props: ListProps) => {

    if (!props.solarSystem) {
        return null;
    }

    const hasApocalpyse = props.solarSystem.numberOfApocalypseShots > 0;
    const hasRecon = props.solarSystem.numberOfRecons > 0;
    const hasAttack = props.solarSystem.numberOfAttacks > 0;

    if (!hasAttack && !hasRecon && !hasApocalpyse) {
        return null;
    }

    const className = classNames(
        "solar-system-danger-icons",
        props.className,
        {
            "is-pulled-right": props.isPulledRight
        });

    return <span className={className}>
        {hasApocalpyse && <Icon className="has-text-danger" icon={IconHelper.Celestials.Apocalypse} title="This system is the target of an apocalpyse shot" />}
        {hasAttack && <FleetMovementTypeIcon movementType={FleetMovementType.Attack} isPlayerOwner={false} isPlayerAllied={false} />}
        {hasRecon && <FleetMovementTypeIcon movementType={FleetMovementType.Recon} isPlayerOwner={false} isPlayerAllied={false} />}
    </span>;
};