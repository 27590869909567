import { IContract, IContractDetail, RansomTerms } from '../../../../ApplicationState/ApiClient';
import { SplitLayout } from '../../../../Components/Base/Containers/SplitLayout';
import { CreditsIconWithQuantity } from '../../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { AgentPortrait } from '../../../../Components/FusionShift/Images/AgentPortrait';
import { AgentLink } from '../../../../Components/FusionShift/Links/AgentLink';

type Props = {
    contract: IContract,
    terms: RansomTerms
}

export const RansomTermsDetail = (props: Props) => {

    const detail = props.contract as IContractDetail;
    const agent = detail?.agent;

    return <div className="content">
        <SplitLayout
            isLeftEmpty={agent === undefined}
            left={agent !== undefined && <AgentPortrait size="large" agent={agent} />}
            right={
                <ul>
                    {agent !== undefined && <li><AgentLink agent={agent} /> </li>}
                    <li>Payment is <CreditsIconWithQuantity quantity={props.terms.cost} /></li>
                </ul>
            }
        />
    </div>;
};