import { observer } from "mobx-react-lite";
import React from "react";
import { Celestial, CelestialSize, ICelestialTypeSettings } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { TileHolder } from "../../Base/Containers/TileHolder";
import { Paragraph } from "../../Base/Text/Paragraph";
import { CelestialImage } from "../Celestials/CelestialImage";

type Props = {
    celestials?: Celestial[] | undefined,
    forceSmall?: boolean
}

type InnerProps = Props & {
    celestialTypeSettings: ICelestialTypeSettings
}

type CellProps = {
    celestialTypeSettings: ICelestialTypeSettings,
    celestial: Celestial,
    isSelected: boolean,
    isPlayerOwned: boolean,
    index: number,
    forceSmall?: boolean
}

const CelestialRowCell = (props: CellProps) => {

    const celestialTypeName = props.celestial.celestialTypeName === undefined || props.celestial.celestialTypeName == null ? "unknown" : props.celestial.celestialTypeName in props.celestialTypeSettings.data ? props.celestialTypeSettings.data[props.celestial.celestialTypeName].name : props.celestial.celestialTypeName;

    const selected = props.isSelected ? "selected" : "";

    const gridStyle = {
        gridColumn: props.index + 1
    };

    return <>
        <Paragraph className={`heading ${selected}`}>
            {props.celestial.name}
        </Paragraph>
        <div className={`holder ${selected}`} style={gridStyle}>
        </div>
        <CelestialImage {...props} style={gridStyle} forcedSize={props.forceSmall ? CelestialSize.Small : undefined} />
        <Paragraph className={`${selected} info is-size-7`} >
            {`${CelestialSize[props.celestial.size]} ${celestialTypeName}`}
        </Paragraph>
    </>;
};

const CelestialRowInner = (props: InnerProps) => {

    if (!props.celestials) {
        return null;
    }

    return <div className={`celestial-row`}>
        {props.celestials.map((c, i) => <CelestialRowCell
            celestialTypeSettings={props.celestialTypeSettings}
            key={c.celestialId} celestial={c}
            index={i}
            isPlayerOwned={false}
            isSelected={false}
            forceSmall={props.forceSmall}
        />)}
    </div>;
};

export const SolarSystemCelestialsRow = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [groupings, setGroupings] = React.useState<(Celestial[])[]>([]);

    React.useEffect(() => {
        if (props.celestials) {
            const groupings: (Celestial[])[] = [];

            const length = props.celestials.length
            const numOfGroups = Math.ceil(length / 4);
            const rounding = length % 4 === 0 ? 0 : 4 - (length % 4);

            let cur: Celestial[] = [];
            groupings.push(cur);
            for (let c of props.celestials) {
                cur.push(c);
                const maxGroupLength = groupings.length <= numOfGroups - rounding ? 4 : 3;
                if (cur.length >= maxGroupLength) {
                    cur = [];
                    groupings.push(cur);
                }
            }

            setGroupings(groupings);
        }
    }, [props.celestials]);

    if (!props.celestials || !CollectionHelper.isAnyInArray(props.celestials) || !worldState.CelestialTypeSettings) {
        return null;
    }

    return <TileHolder>
        {groupings
            .filter(g => g.length > 0)
            .map((g, i) => <CelestialRowInner key={i} celestials={g} forceSmall={props.forceSmall} celestialTypeSettings={worldState.CelestialTypeSettings!} />)}
    </TileHolder>;
});