import * as React from 'react';
import { PresidentialManifesto } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { Table } from '../../../Components/Base/Containers/Table';
import { Icon } from '../../../Components/Base/Icon';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { handleOnClick } from '../../../Components/Base/Util';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    presidentialManifestos: PresidentialManifesto[],
    presidentialManifestoId: number | undefined,
    setPresidentialManifestoId: (presidentialManifestoId: number) => any
}

type RowProps = {
    presidentialManifesto: PresidentialManifesto,
    isSelected: boolean,
    setPresidentialManifestoId: (presidentialManifestoId: number) => any
}

const ManifestoRow = (props: RowProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);

    return <tr onClick={e => handleOnClick(e, () => setIsExpanded(!isExpanded))}>
        <td>
            <PlayerLink player={props.presidentialManifesto.player} />
        </td>
        <td>
            {props.presidentialManifesto.title}
        </td>
        <td>
            {props.presidentialManifesto.didObservingPlayerVoteForThis && <Icon icon={IconHelper.Politics.Vote} />}
        </td>
        <td>
            <Button type="nav" action={() => props.setPresidentialManifestoId(props.presidentialManifesto.presidentialManifestoId)} icon={IconHelper.Economy.Credits} text={props.isSelected ? "Selected" : "Bribe"} isDisabled={props.isSelected} />
        </td>
    </tr>;
};

export const ManifestoTable = (props: Props) => {

    if (props.presidentialManifestos.length === 0) {
        return <Paragraph type="prompt">There are currently no presidential manifestos for this term.</Paragraph>
    }

    return <Table
        isFullWidth
        isHoverable
        isClickable
        heading={<>
            <th>
                Player
            </th>
            <th>
                Manifesto
            </th>
            <th>
            </th>
            <th>
            </th>
        </>}>
        {props.presidentialManifestos.map(x => <ManifestoRow
            key={x.presidentialManifestoId}
            presidentialManifesto={x}
            {...props}
            isSelected={x.presidentialManifestoId === props.presidentialManifestoId}
        />)}
    </Table>;
};