import { makeAutoObservable } from "mobx";
import { UrlHelper } from '../../Helpers/UrlHelper';
import { router } from '../../Navigation/Router';
import { forgotPassword, join_world, login, register, resetPassword, verifyEmail } from '../../Navigation/Routing/AppRouteValues';
import { developedBy } from '../../Navigation/Routing/Misc';
import { selected_system } from '../../Navigation/Routing/SolarSystem';
import { AuthenticatedModel, IJoinWorldInput, ILoginInput, ISolarSystem, JoinWorldInput, LoginInput, ValidationResult, WorldForPlayer } from "../ApiClient";
import { HubManager } from '../Hubs/HubManager';
import { RecentSolarSystem, UserPrefs } from "../UserPrefs";
import { AgentState } from './AgentState';
import { ApiState } from './ApiState';
import { ApplicationInsightsState } from './ApplicationInsightsState';
import { AudioState } from './AudioState';
import { AuthenticateState } from "./AuthenticateState";
import { ChatState } from "./ChatState";
import { CreditsState } from './CreditsState';
import { DescriptionState } from "./DescriptionState";
import { HelpState } from './HelpState';
import { MissionLogState } from './MissionLogState';
import { PlayerState } from './PlayerState';
import { PremiumBalanceState } from './PremiumBalanceState';
import { SolarSystemState } from './SolarSystemState';
import { TutorialState } from './TutorialState';
import { UnreadState } from './UnreadState';
import { WorldState } from './WorldState';

export type ApplicationState = "Unauthenticated" | "Authenticating" | "PerformingInitialLoad" | "Ready" | "NoWorld" | "ErrorDuringInitialLoad";

export class AppState {

    constructor(private _loadAndPoll: boolean) {


        // this.ApiState = new ApiState(() => this._userPrefs.Token, () => {
        //     console.log("unauthorized request");
        // });

        this.ApiState = new ApiState(() => this._userPrefs.Token, () => this.clearUser());
        this.ChatState = new ChatState(this.ApiState.ChatClient);
        this.AudioState = new AudioState();
        this.PlayerState = new PlayerState();
        this.PremiumBalanceState = new PremiumBalanceState();


        this.TutorialState = new TutorialState();
        this.CreditsState = new CreditsState();

        this.DescriptionState = new DescriptionState(this.ApiState);
        this.MissionLogState = new MissionLogState();
        this.HelpState = new HelpState(this.ApiState);
        this.WorldState = new WorldState(this.ApiState);

        this.SolarSystemState = new SolarSystemState(this.WorldState, this.ApiState);
        this.UnreadState = new UnreadState(this.MissionLogState);

        this.AgentState = new AgentState(this.UnreadState);

        this.AuthenticateState = new AuthenticateState(this.ApiState);

        this._hubManager = new HubManager(this, () => this._userPrefs.Token!, _loadAndPoll);

        if (_loadAndPoll) {
            this.ApplicationInsightsState = new ApplicationInsightsState(
                process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY, null);
        } else {
            this.ApplicationInsightsState = new ApplicationInsightsState();

            console.log("App configured to not load or poll")
        }

        if (this.UserPrefs.isCookieConsentGiven()) {
            this.giveCookieConsent();
        }

        makeAutoObservable(this);
    }

    get Error() { return this._error; }
    private _error: { text: string } | undefined;

    get ClientSettings() { return this._worldForPlayer !== undefined ? this._worldForPlayer.clientSettings : undefined; }

    get UserPrefs() { return this._userPrefs; }
    private _userPrefs: UserPrefs = new UserPrefs();

    get GameVersion(): string | undefined { return this._worldForPlayer?.gameVersion; }
    get FactoryVersion(): string | undefined { return this._worldForPlayer?.factoryVersion; }
    get ApiVersion(): string | undefined { return this._worldForPlayer?.apiVersion; };

    get SolarSystemId(): number | undefined { return this._userPrefs.SelectedSolarSystemId; }

    private _worldForPlayer: WorldForPlayer | undefined;

    get ApplicationState() { return this._applicationState; }
    private _applicationState: ApplicationState = "Unauthenticated"

    get IsAuthenticatedButLoading(): boolean { return this._applicationState === "PerformingInitialLoad" && this.IsLoggedIn; }
    get IsReady(): boolean { return this._applicationState === "Ready"; }
    get IsLoggedIn(): boolean { return !!this._userPrefs.Token; }

    get HasVerifiedEmail(): boolean { return !!this._worldForPlayer && this._worldForPlayer.hasVerifiedEmail; }

    get HasActiveSubscription(): boolean { return !!this._worldForPlayer && this._worldForPlayer.hasActiveSubscription; }

    get IsPremiumEnabled(): boolean { return this.IsAdmin; }
    get IsAdmin(): boolean { return !!this._worldForPlayer && this._worldForPlayer.isAdmin; }
    get IsDebug(): boolean { return !!this._worldForPlayer && this._worldForPlayer.isDebug; }

    get RecentSolarSystems(): RecentSolarSystem[] | undefined { return this._userPrefs.RecentSolarSystems; }

    get JoiningWorldId() { return this._joiningWorldId; }
    private _joiningWorldId: number | undefined;

    private _hubManager: HubManager;
    private _lastLoad: Date | undefined;

    private _hasDoneInitialLoad = false;

    ApiState: ApiState;
    AudioState: AudioState;
    SolarSystemState: SolarSystemState;
    ChatState: ChatState;
    MissionLogState: MissionLogState;
    WorldState: WorldState;
    PlayerState: PlayerState;
    PremiumBalanceState: PremiumBalanceState;
    UnreadState: UnreadState;
    HelpState: HelpState;
    ApplicationInsightsState: ApplicationInsightsState;
    TutorialState: TutorialState;
    CreditsState: CreditsState;
    AgentState: AgentState;
    AuthenticateState: AuthenticateState;
    DescriptionState: DescriptionState;

    public initialLoad() {
        if (this._hasDoneInitialLoad) {
            return;
        }

        this._hasDoneInitialLoad = true;

        this.reload("Initial load");
        if (this._loadAndPoll) {
            window.setInterval(() => this.checkForReload(), 1000);
        }
        else {
            console.log("App configured to not poll")
        }
    }

    public currentPathName() {
        return window.location.pathname;
    }

    public navigateBack() {
        window.scroll(0, 0);
        router.navigate(-1);
    }

    public setWindowUrl(to: string) {
        // Sets the window URL without triggering a Router component re-render
        window.history.replaceState(null, "Fusion Shift Online", to);
    }

    public scrollToTop() {
        window.scroll(0, 0);
    }

    public scrollToBottom() {
        window.scroll(0, document.body.scrollHeight);
    }

    public navigateIfStillRelevant(to: string, dontResetScroll: boolean, rootPath: string) {
        if (this.currentPathName().startsWith(rootPath)) {
            this.navigate(to, dontResetScroll);
        }
    }

    public navigate(to: any, dontResetScroll?: boolean) {
        if (!dontResetScroll) {
            this.scrollToTop();
        }
        router.navigate(to);
    }

    public logout() {
        this.ApiState.UserSettingClient.logout().then(() => {
        }).finally(() => {
        });

        this.clearUser();
    }

    public clearUser() {

        this._applicationState = "Unauthenticated";

        this.ApiState.hasError = false;
        this._userPrefs.logout();
        this.changeSolarSystemId(undefined);
        this.SolarSystemState.clear();
        this.PlayerState.loadPlayer(undefined, undefined, undefined);
        this.UnreadState.clearAllUnread();
        this.MissionLogState.loadMissionLog(undefined);
        this.CreditsState.loadCredits(0, 0);
        this.AgentState.loadAgents(undefined);
        this.WorldState.loadVictoriousFederation(undefined);

        this._hubManager.closeHubs();

        if (!this.currentPathName().startsWith(login) && !this.currentPathName().startsWith(register)) {
            this.navigate("/");
        }
    }

    public authenticate(login: ILoginInput, validationCallback: (validationResult: ValidationResult | undefined) => any) {
        this._applicationState = "Authenticating";
        const model = new LoginInput(login);

        return this.ApiState.AuthenticationClient.authenticate(model).then(result => {

            if (result) {
                validationCallback(result.error);
                if (!result.error || result.error.isValid) {
                    this.setUser(result);
                } else {
                    this._applicationState = "Unauthenticated";
                }
            }
        })
    }

    public joinWorld(join: IJoinWorldInput, validationCallback: (validationResult: ValidationResult | undefined) => any) {
        this._applicationState = "Authenticating";
        const model = new JoinWorldInput(join);

        return this.ApiState.UserSettingClient.joinWorld(model).then(result => {
            if (result.worldForPlayer) {
                this.setWorld("Joined World", result.worldForPlayer);
                this.PlayerState.setNewRegistration(true);
                this.navigate({
                    pathname: selected_system
                });
            } else {
                validationCallback(result.error);
                this._applicationState = "NoWorld";
            }
        });
    }

    public setUser(user: AuthenticatedModel) {

        this.setAuthToken(user.token);

        if (user.worldForPlayer) {
            this._applicationState = "Ready";
            this.setWorld("authenticated", user.worldForPlayer);

            const urlSearch = new URLSearchParams(window.location.search);
            const redirect = UrlHelper.tryGetValue(urlSearch, "redirect", value => {
                return value;
            }) ?? selected_system;

            if (redirect) {
                this.navigate(redirect);
            }
        } else {
            this._applicationState = "NoWorld";
            this.navigate(join_world);
        }
    }

    public setAuthToken(token: string) {

        this._userPrefs.login(token);
    }

    public changeSolarSystemId(solarSystemId: number | undefined, reload?: boolean, navigateTo?: string) {
        const isDifferent = solarSystemId !== this.SolarSystemId;
        const isNotActive = this.SolarSystemState.SolarSystem?.solarSystemId !== solarSystemId;

        this._userPrefs.setSelectedSolarSystemId(solarSystemId);

        if (isDifferent || isNotActive) {
            this.reloadSolarSystem(reload);
        }

        if (navigateTo !== undefined) {
            this.navigate(navigateTo);
        }
    }

    public checkForReload() {
        const now = new Date();
        const canLoad = this._lastLoad === undefined || now.getTime() - this._lastLoad.getTime() >= 10000;

        if (document.hasFocus() && canLoad && this.SolarSystemState.CurrentSolarSystem) {

            const nextUpdate = this.SolarSystemState.CurrentSolarSystem.nextCompleteDate();

            if (nextUpdate !== undefined && nextUpdate <= now) {
                this.reloadSolarSystem(true);
            }
        }
    }

    public reloadSolarSystem(loadFromApi?: boolean) {
        if (this.SolarSystemId) {

            if (loadFromApi) {
                this.ApiState.SolarSystemClient.getDetail(this.SolarSystemId).then(solarSystem => {
                    if (solarSystem) {
                        this.SolarSystemState.loadSolarSystem(solarSystem);
                        this.SolarSystemState.switchSolarSystem(this.SolarSystemId);
                    }
                }).finally(() => {
                    this._lastLoad = new Date();
                });
            } else {
                this.SolarSystemState.switchSolarSystem(this.SolarSystemId);
            }
        } else {
            this.SolarSystemState.switchSolarSystem(undefined);
        }
    }

    public reloadPlayer() {
        if (this.PlayerState.Player) {
            this.ApiState.PlayerClient.getSelf().then(p => {

                this.PlayerState.loadPlayerOnly(p);
            });
        }
    }

    public reloadSolarSystemById(solarSystemId: number) {

        this.ApiState.SolarSystemClient.getDetail(solarSystemId).then(solarSystem => {
            if (solarSystem) {
                this.SolarSystemState.loadSolarSystem(solarSystem);
            }
        }).finally(() => {
            this._lastLoad = new Date();
        });
    }

    public reloadSolarSystems() {
        this.ApiState.SolarSystemClient.forOwner().then(solarSystems => {
            if (solarSystems) {
                this.SolarSystemState.loadSolarSystemList(solarSystems);
                if (this.SolarSystemId && !solarSystems.find(s => s.solarSystemId === this.SolarSystemId)) {
                    if (solarSystems.length > 0) {
                        this.changeSolarSystemId(solarSystems[0].solarSystemId);
                    } else {
                        this.changeSolarSystemId(undefined);
                    }
                }
            }
        });
    }

    public async reload(reason: string) {
        if (!this.IsLoggedIn) {

            this._applicationState = 'Unauthenticated';

            const pathName = this.currentPathName();

            if (pathName !== login
                && pathName !== register
                && pathName !== developedBy
                && pathName !== forgotPassword
                && !pathName.startsWith(resetPassword)
                && !pathName.startsWith(verifyEmail)
                && pathName !== "/"
                && pathName.length > 0) {
                this.navigate(login + `?redirect=${encodeURI(pathName)}`);
            }
            return;
        }

        if (this._applicationState === 'Unauthenticated') {
            this._applicationState = 'PerformingInitialLoad';
        }
        this.ApiState.GameClient.getWorld(this.SolarSystemId).then(world => {
            if (world) {
                this.setWorld(reason, world);
            }
        }).catch(() => {

            if (this._applicationState === "PerformingInitialLoad") {
                this._applicationState = "ErrorDuringInitialLoad";
            }

            if (process.env.NODE_ENV === "production") {
                //this.setUser(null);
            }
        }).finally(() => {
            this._lastLoad = new Date();
            if (this._applicationState === 'Unauthenticated' || this._applicationState === 'PerformingInitialLoad') {
                this._applicationState = this._worldForPlayer === undefined ? 'NoWorld' : 'Ready';
            }
        });
    }

    public setWorld(reason: string, world: WorldForPlayer) {

        this._joiningWorldId = undefined;

        if (world.player) {
            this._userPrefs.initForPlayerId(world.player.playerId);
        }

        const isMissing = this.SolarSystemId !== undefined && world.solarSystems.find(x => x.solarSystemId === this.SolarSystemId) === undefined;

        if ((this.SolarSystemId === undefined || isMissing) && world.solarSystems.length > 0) {
            this._userPrefs.setSelectedSolarSystemId(world.solarSystems[0].solarSystemId);
        }

        this.DescriptionState.factoryVersion = world.factoryVersion;
        this.HelpState.factoryVersion = world.factoryVersion;

        this._worldForPlayer = world;
        console.log([reason, this._worldForPlayer]);

        this.WorldState.loadWorld(world.world, world.factoryVersion)?.then(() => {

            this.PremiumBalanceState.loadBalance(world.balance);

            this.WorldState.loadVictoriousFederation(world.victoriousFederation);

            this.SolarSystemState.initialLoad(world.solarSystems, world.solarSystem, this.SolarSystemId);

            this.PlayerState.loadPlayer(world.player, world.playerSettings, world.playerSettingsPremiumInGameProductNames);
            this.MissionLogState.loadMissionLog(world.missionLog);
            this.UnreadState.loadUnread(world.notifications);
            this.CreditsState.loadCredits(world.credits.balance, world.credits.perHour);

            this.changeSolarSystemId(this.SolarSystemId);

            this._applicationState = "Ready";
        });

        this.ChatState.setChats(world.chats);
        this._hubManager.ensureHubConnections();
    }

    public sendChatMessage(chatId: number, message: string) {
        this._hubManager.sendChatMessage(chatId, message);
    }

    public addRecentSolarSystem(solarSystem: ISolarSystem | undefined) {
        if (!solarSystem) {
            return;
        }
        this._userPrefs.addRecentSolarSystem(solarSystem.solarSystemId, solarSystem.name);
    }

    public getUserPrefsObjectOrDefault<T>(name: string, df: T): T {
        return this._userPrefs.getUserPrefsObjectOrDefault(name, df);
    }

    public getUserPrefsObject<T>(name: string): T | undefined {
        return this._userPrefs.getUserPrefsObject(name);
    }

    public setUserPrefsObject<T>(name: string, value: T | undefined): T | undefined {
        return this._userPrefs.setUserPrefsObject(name, value);
    }

    public setError(errorText: string) {
        this._error = {
            text: errorText
        };
    }

    public clearError() {
        this._error = undefined;
    }

    public giveCookieConsent() {
        this.UserPrefs.setCookieConsent(true);

        this.ApplicationInsightsState.init();
    }

    public setJoiningWorldId(worldId: number | undefined) {
        this._joiningWorldId = worldId;
    }
};