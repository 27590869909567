import { IInstallationTypeSettings } from "../../../../ApplicationState/ApiClient";
import { DroneHelper } from "../../../../Helpers/DroneHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { shipsProblemBuilder } from "./ShipsProblems";

export function dronesProblems(installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;

    if (solarSystem.drones) {
        const summary = DroneHelper.droneSummary(installationTypeSettings, solarSystem);

        if (summary.currentActive < summary.maxActive) {
            const qty = summary.currentActive === 0 ? "none" : `only ${ValueFormatter.formatLocaleNumber(summary.currentActive)} ${ValueFormatter.pluralize("drone", summary.currentActive)} `;

            return shipsProblemBuilder.infoProblem("Idle Drones", `${solarSystem.name} has bandwith for ${ValueFormatter.formatLocaleNumber(summary.maxActive)} ${ValueFormatter.pluralize("drone", summary.maxActive)} but ${qty} are active`);
        }
    }

    return undefined;
}