import { IPlayerWithCapitalSolarSystem } from '../../ApplicationState/ApiClient';
import { Badge, Badges, FactionBadge } from '../../Components/Base/Badge';
import { SecurityStatusIcon } from '../../Components/FusionShift/Icons/SecurityStatusIcon';
import { EmblemImage } from '../../Components/FusionShift/Images/EmblemImage';
import { FederationLink } from '../../Components/FusionShift/Links/FederationLink';
import { SolarSystemLink } from '../../Components/FusionShift/Links/SolarSystemLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';

type Props = {
    player: IPlayerWithCapitalSolarSystem
}

export const PlayerHeading = (props: Props) => {

    const player = props.player;
    const hasEmblem = player.emblemFilename?.length ?? 0 > 0;

    return <div className='is-flex'>
        {hasEmblem &&
            <EmblemImage filename={player.emblemFilename} size='regular' className="is-hidden-mobile" />
        }
        <Badges>
            {hasEmblem &&
                <Badge label="Player" content={props.player.name} icon={<EmblemImage filename={player.emblemFilename} size='small' />} className="is-hidden-tablet" />
            }
            <Badge label="Security" content={ValueFormatter.friendlyNameForSecurityStatus(player.securityStatus)} icon={<SecurityStatusIcon securityStatus={player.securityStatus} />} />
            <Badge label="Rank" content={ValueFormatter.rank(player.rank)} icon={IconHelper.Player.Rank} />
            <Badge label="Size" content={ValueFormatter.formatSize(player.size)} icon={IconHelper.Player.Size} />
            <Badge label="Pop." content={ValueFormatter.formatPopulation(player.population)} icon={IconHelper.Megacities.Population} />
            <Badge label="Systems" content={player.numberOfSolarSystems} icon={IconHelper.SolarSystems.SolarSystem} />

            {player.capitalSolarSystem !== undefined && <Badge label="Capital" content={<SolarSystemLink solarSystem={player.capitalSolarSystem} hideActionIcons />} icon={IconHelper.SolarSystems.Capital} />}
            {player.federation !== undefined && <Badge label="Federation" content={<FederationLink shortNameOnly federation={player.federation} />} icon={IconHelper.Federations.Federation} />}

            <FactionBadge factionType={props.player.factionTypeName} />
        </Badges>
    </div>;
};