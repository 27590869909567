import * as React from 'react';
import { Problem, ProblemCategory, ProblemSeverity } from '../../../Entities/SolarSystem/Problems/Problem';
import { enumFromString } from '../../../Helpers/TypeHelper';
import { ProblemSeverityIcon } from './ProblemSeverityIcon';

type Props = {
    category?: ProblemCategory | undefined,
    problems: Problem[]
}

type SeverityCountProps = {
    severityCount: SeverityCount
}

export type SeverityCount = {
    [K in ProblemSeverity]: number
}

export function buildSeverityCount(problems: Problem[], category: ProblemCategory | undefined) {
    const initial: SeverityCount = {
        [ProblemSeverity.Danger]: 0,
        [ProblemSeverity.Warning]: 0,
        [ProblemSeverity.Info]: 0
    };

    return problems.reduce((v, p) => {
        if (category !== undefined && p.category !== category) {
            return v;
        }
        v[p.severity] = v[p.severity] + 1;
        return v;
    }, initial);
}

export const ProblemIconsFromSeverityCount = (props: SeverityCountProps) => {

    const counts = Object.keys(props.severityCount).map(k => {
        if (props.severityCount[k] <= 0) {
            return undefined;
        }
        return {
            severity: enumFromString(ProblemSeverity, k),
            count: props.severityCount[k]
        };
    }).filter(k => k !== undefined)
        .map(k => k!);

    return <>
        {counts.map(c => <span key={c.severity}>
            <ProblemSeverityIcon severity={c.severity} />
            {c.count}
        </span>)}
    </>;
};

export const ProblemIcons = (props: Props) => {

    const severityCount = buildSeverityCount(props.problems, props.category);

    return <ProblemIconsFromSeverityCount severityCount={severityCount} />;
};