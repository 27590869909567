import { IFactionTypeSettings, IGameSettings, IShipTypeSettings, ISimulatedFleet } from '../../ApplicationState/ApiClient';
import { Button } from '../../Components/Base/Buttons/Button';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../Helpers/IconHelper';
import { BonusSlider } from './Components/BonusSlider';
import { ShipsForm } from './Components/ShipForm';
import { StatsSummary } from './Components/StatsSummary';

type Props = {
    gameSettings: IGameSettings,
    shipTypeSettings: IShipTypeSettings,
    factionTypeSettings: IFactionTypeSettings,
    defender: ISimulatedFleet,
    setDefender: (defender: ISimulatedFleet | undefined) => any
}

export const DefenderFleetForm = (props: Props) => {

    return <SubPanel heading={{ text: "Defending Fleet", icon: IconHelper.Combat.Defence }}
        headingContent={<Button type="danger" text="Reset and Change" icon={IconHelper.General.Delete} action={() => props.setDefender(undefined)} confirm="Are you sure you want to change the defender? This will clear all your changes." />}
    >
        <SplitLayout
            left={
                <ShipsForm
                    shipTypeSettings={props.shipTypeSettings}
                    factionTypeSettings={props.factionTypeSettings}
                    gameSettings={props.gameSettings}
                    {...props.defender}
                    setShipsAndTactics={(ships, tactics) => props.setDefender({
                        ...props.defender,
                        ships,
                        tactics
                    })}
                />
            }
            right={<>
                <BonusSlider
                    value={props.defender.megacityBonusPercent}
                    valueChanged={v => props.setDefender({
                        ...props.defender,
                        megacityBonusPercent: v
                    })}
                />
                <StatsSummary {...props} ships={props.defender.ships} hideAttack />
            </>
            }
        />
    </SubPanel>;
};