import { makeAutoObservable } from "mobx";
import { World } from "../ApiClient";
import { ApiState } from "./ApiState";

export class AuthenticateState {

    Worlds: World[] | undefined;

    private _hasLoaded: boolean = false;

    constructor(private _apiState: ApiState) {
        makeAutoObservable(this);
    }

    public load() {
        if (this._hasLoaded) {
            return;
        }

        this._hasLoaded = true;

        this._apiState.AuthenticationClient.worlds().then(worlds => {
            this.Worlds = worlds;
        });
    }
}