import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ISolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { MessageBoxWithIcon } from '../../../../Components/Base/MessageBox';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { SolarSystemHelper } from '../../../../Helpers/SolarSystemHelper';

type Props = {
    solarSystem: ISolarSystemDetail
}

export const LosingControlMessage = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.InstallationTypeSettings) {
        return null;
    }

    const isLosingControl = SolarSystemHelper.isLosingControl(props.solarSystem, worldState.InstallationTypeSettings);

    if (!isLosingControl) {
        return null;
    }

    return <MessageBoxWithIcon
        icon={IconHelper.SolarSystems.LosingControl}
        type="danger"
        text={"The controlling installation at this system is scheduled to be deconstructed. When deconstruction is complete you will lose control of this system."}
    />;
});