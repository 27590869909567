import React from "react";
import { AgentActionType } from "../../../ApplicationState/ApiClient";
import { AgentHelper } from "../../../Helpers/AgentHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { SubPanel } from "../../Base/Containers/SubPanel";
import { Table } from "../../Base/Containers/Table";
import { ItemTypeIconFromItemTypeName } from "../Icons/Items/ItemTypeIcon";

type Props = {
    agentActionType: AgentActionType
}

type BaseRowProps = {
    name: React.ReactNode,
    isNotPercent?: boolean,
    noPlus?: boolean
}

type RowProps = BaseRowProps & {
    min: number,
    optimal: number,
    max: number,
}

type ResultProps = BaseRowProps & {
    value: number | null | undefined,
    agentActionType: AgentActionType
}

const Row = (props: RowProps) => {

    const val = props.isNotPercent ? v => v : v => ValueFormatter.fromDecimalToDisplayPercent(v, false, 0, !props.noPlus);

    return <tr>
        <td>{props.name}</td>
        <td>{val(props.min)}</td>
        <td>{val(props.optimal)}</td>
        <td>{val(props.max)}</td>
    </tr>
}

const ResultRow = (props: ResultProps) => {

    if (props.value === undefined || props.value === null) {
        return null;
    }

    const min = props.value * AgentHelper.calculateFromLevelModifier(props.agentActionType.level, props.agentActionType.strengthModifier, props.agentActionType.level.min);
    const optimal = props.value * AgentHelper.calculateFromLevelModifier(props.agentActionType.level, props.agentActionType.strengthModifier, props.agentActionType.level.optimal);
    const max = props.value * AgentHelper.calculateFromLevelModifier(props.agentActionType.level, props.agentActionType.strengthModifier, props.agentActionType.level.max);

    return <Row {...props} min={min} optimal={optimal} max={max} />;
}

export const AgentActionTypeStats = (props: Props) => {

    return <SubPanel heading={{ text: "Stats", icon: IconHelper.Categories.Statistics }} isUnpadded>
        <Table isFullWidth isFixed heading={
            <>
                <th />
                <th>Min</th>
                <th>Optimal</th>
                <th>Max</th>
            </>
        }>
            <Row name="Level" {...props.agentActionType.level} isNotPercent />
            <Row name="Success %" {...props.agentActionType.successChance} noPlus />
            {props.agentActionType.successCaptureChance &&
                <Row name="Success Capture %" {...props.agentActionType.successCaptureChance} noPlus />
            }
            {props.agentActionType.failureCaptureChance &&
                <Row name="Failure Capture %" {...props.agentActionType.failureCaptureChance} noPlus />
            }

            <ResultRow name="Agent Action Cost" {...props} value={props.agentActionType.agentActionCostModifierPercent} />
            <ResultRow name="Agent Capture %" {...props} value={props.agentActionType.agentActionCaptureChanceModifierPercent} />
            <ResultRow name="Agent Success %" {...props} value={props.agentActionType.agentActionSuccessChanceModifierPercent} />

            <ResultRow name="System Defence" {...props} value={props.agentActionType.solarSystemDefenceModifierPercent} />
            <ResultRow name="Radar Range" {...props} value={props.agentActionType.radarRangeModification} isNotPercent />
            <ResultRow name="Manufactory Output" {...props} value={props.agentActionType.manufactoryOutputModifierPercent} />

            {props.agentActionType.resourceHarvestingModifierPercent !== undefined && Object.keys(props.agentActionType.resourceHarvestingModifierPercent).map(x => <ResultRow key={x}
                value={props.agentActionType.resourceHarvestingModifierPercent![x]}
                {...props}
                name={<><ItemTypeIconFromItemTypeName itemTypeName={x} /> Harvesting</>}
            />)}

        </Table>
    </SubPanel >;
};