import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { ApiStateContext, AppStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../Components/Base/Buttons/ButtonHolder";
import { SuccessMessageBox, WarningMessageBox } from "../../../Components/Base/MessageBox";
import { IconHelper } from "../../../Helpers/IconHelper";
import { SimpleBaseView } from "../../BaseView";
import { AddItemsAndShipsForm } from "./AddItemsAndShipsForm";
import { AddItemsForm } from "./AddItemsForm";

export const CheatView = observer(() => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const { playerId } = useParams();

    const pid = Number(playerId);

    const [message, setMessage] = React.useState<string | undefined>(undefined);

    function tick() {
        return apiState.DebugClient.ticks(1).then(_ => {
            setMessage("Ticked once");
        });
    }

    function resetAllMissions() {
        return apiState.CheatClient.resetMissions(pid).then(_ => {
            setMessage("Reset all missions");
        });
    }

    function awardResearchPoint() {
        return apiState.CheatClient.awardResearchPoint(pid).then(_ => {
            setMessage("Awarded research point");
        });
    }

    function completeTutorialMission() {
        return apiState.CheatClient.completeTutorial(pid).then(_ => {
            setMessage("Completed tutorial");
        });
    }

    function resetAllAgents() {
        return apiState.CheatClient.resetAllAgents(pid).then(_ => {
            setMessage("Reset all agents");
        });
    }

    function completeAllInstallations() {
        return apiState.CheatClient.completeAllInstallationsNextTick(Number(playerId)).then(_ => {
            setMessage("Completed all installations");
        });
    }

    function addCredits() {
        return apiState.CheatClient.addCredits(Number(playerId)).then(_ => {
            setMessage("Added Credits");
        });
    }

    function completeAllShips() {
        return apiState.CheatClient.completeAllShipsNextTick(Number(playerId)).then(_ => {
            setMessage("Completed all ships");
        });
    }

    function completeAllFleets() {
        return apiState.CheatClient.completeAllFleetsNextTick(Number(playerId)).then(_ => {
            setMessage("Completed all ship movements");
        });
    }

    function completeAllDroneMovements() {
        return apiState.CheatClient.completeAllDroneMovements(Number(playerId)).then(_ => {
            setMessage("Completed all drone movements");
        });
    }

    function setAllInstallationsToMaxLevel() {
        return apiState.CheatClient.setAllInstallationsToMaxLevel(Number(playerId)).then(_ => {
            setMessage("Set all installations to max level");
        });
    }

    function growAllMegacitySectors() {
        return apiState.CheatClient.growAllMegacities(Number(playerId)).then(_ => {
            setMessage("Grew all megacities");
        });
    }

    function updateApocalypse() {
        return apiState.CheatClient.updateApocalypseShotsNextTick().then(_ => {
            setMessage("Updated Apocalypse Shots");
        });
    }

    function changeSecurityStatusToCivilian() {
        return apiState.CheatClient.changeSecurityStatusToCivilian(Number(playerId)).then(_ => {
            setMessage("Changed security status to civilian");
        });
    }

    if (!appState.WorldState.World) {
        return null;
    }

    const cheatsLocked = !appState.IsDebug;

    return <SimpleBaseView heading="Cheats">
        {!!message && <SuccessMessageBox text={message} />}
        {cheatsLocked && <WarningMessageBox text="Cheats are disabled while not in debug mode" />}
        <ButtonHolder>
            <Button type="warning" text="Tick Once" action={tick} icon={IconHelper.General.Time} isDisabled={cheatsLocked} />
            <Button type="action" text={"Add Credits"} icon={IconHelper.Economy.Credits} action={addCredits} isDisabled={cheatsLocked} />
            <Button type="action" text={"Reset all missions"} icon={IconHelper.Missions.Missions} action={resetAllMissions} isDisabled={cheatsLocked} />
            <Button type="action" text={"Complete tutorial"} icon={IconHelper.Missions.Missions} action={completeTutorialMission} isDisabled={cheatsLocked} />
            <Button type="action" text={"Grow all megacities"} icon={IconHelper.Megacities.Megacity} action={growAllMegacitySectors} isDisabled={cheatsLocked} />
        </ButtonHolder>
        <ButtonHolder>
            <Button type="action" text={"Complete all ship movements"} icon={IconHelper.Ships.Ship} action={completeAllFleets} isDisabled={cheatsLocked} />
            <Button type="action" text={"Complete all drone movements"} icon={IconHelper.Ships.Drone} action={completeAllDroneMovements} isDisabled={cheatsLocked} />
            <Button type="action" text={"Complete all ships"} icon={IconHelper.Ships.Build} action={completeAllShips} isDisabled={cheatsLocked} />
        </ButtonHolder>
        <ButtonHolder>
            <Button type="action" text={"Complete all installations"} icon={IconHelper.Installations.Build} action={completeAllInstallations} isDisabled={cheatsLocked} />
            <Button type="action" text={"Set all installations to max level"} icon={IconHelper.General.Up} action={setAllInstallationsToMaxLevel} isDisabled={cheatsLocked} />
        </ButtonHolder>
        <ButtonHolder>
            <Button type="action" text={"Change security status to civilian"} icon={IconHelper.Security.Civilian} action={changeSecurityStatusToCivilian} isDisabled={cheatsLocked} />
            <Button type="action" text={"Reset Agents"} icon={IconHelper.Agents.Agent} action={resetAllAgents} isDisabled={cheatsLocked} />
            <Button type="action" text={"Award RP"} icon={IconHelper.Federations.ResearchPoint} action={awardResearchPoint} isDisabled={cheatsLocked} />
            <Button type="action" text={"Update Apocalypse"} icon={IconHelper.Celestials.Apocalypse} action={updateApocalypse} isDisabled={cheatsLocked} />
        </ButtonHolder>
        {!cheatsLocked && <>
            <hr />
            <AddItemsAndShipsForm messageCallback={setMessage} playerId={Number(playerId)} />
            <hr />
            <AddItemsForm messageCallback={setMessage} playerId={Number(playerId)} />
        </>}
    </SimpleBaseView>;
});