import { observer } from "mobx-react-lite";
import React from "react";
import { SketchPicker } from "react-color";
import { ExpandButton } from "../../../../Components/Base/Buttons/ExpandButton";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { FieldHolder } from "../../../../Components/Base/Form/FieldHolder";
import { NumberInput } from "../../../../Components/Base/Form/Input";
import { EmblemEditorContext } from "../../State/EmblemEditorContext";

export const EditLayerPanel = observer(() => {

  const emblemEditorState = React.useContext(EmblemEditorContext);

  const [isExpanded, setIsExpanded] = React.useState(true);

  if (emblemEditorState.editingLayer === undefined || emblemEditorState.manifest === undefined) {
    return null;
  }

  const currentLayer = emblemEditorState.editingLayer;

  return (
    <SubPanel
      heading={`Edit Layer ${emblemEditorState.curentLayerIndex + 1}`}
      headingContent={<ExpandButton className="is-hidden-tablet" isExpanded={isExpanded} setIsExpanded={setIsExpanded} />}
    >
      {isExpanded && <>
        <SketchPicker
          disableAlpha
          width=""
          color={currentLayer.colourHex}
          onChangeComplete={(color) =>
            emblemEditorState.dispatchAlterLayer(
              {
                ...currentLayer,
                colourHex: color.hex,
              },
              emblemEditorState.curentLayerIndex
            )
          }
        />
        <div className="alter-template">
          <FieldHolder label={"Rotation"}>
            <NumberInput
              value={currentLayer.rotation}
              size={5}
              minValue={0}
              maxValue={360}
              valueChanged={(value) => {
                emblemEditorState.dispatchAlterLayer(
                  {
                    ...currentLayer,
                    rotation: value,
                  },
                  emblemEditorState.curentLayerIndex
                );
              }}
            ></NumberInput>
          </FieldHolder>
          <FieldHolder label={"Scale %"}>
            <NumberInput
              value={currentLayer.scale * 100}
              size={5}
              minValue={0}
              maxValue={1000}
              valueChanged={(value) => {
                emblemEditorState.dispatchAlterLayer(
                  {
                    ...currentLayer,
                    scale: value / 100,
                  },
                  emblemEditorState.curentLayerIndex
                );
              }}
            ></NumberInput>
          </FieldHolder>
          <FieldHolder label={"Position X"}>
            <NumberInput
              value={currentLayer.translationX}
              size={5}
              minValue={-emblemEditorState.manifest.placeholders.uniformSize}
              maxValue={emblemEditorState.manifest.placeholders.uniformSize}
              valueChanged={(value) => {
                emblemEditorState.dispatchAlterLayer(
                  {
                    ...currentLayer,
                    translationX: value,
                  },
                  emblemEditorState.curentLayerIndex
                );
              }}
            />
          </FieldHolder>
          <FieldHolder label={"Position Y"}>
            <NumberInput
              value={currentLayer.translationY}
              size={5}
              minValue={-emblemEditorState.manifest.placeholders.uniformSize}
              maxValue={emblemEditorState.manifest.placeholders.uniformSize}
              valueChanged={(value) => {
                emblemEditorState.dispatchAlterLayer(
                  {
                    ...currentLayer,
                    translationY: value,
                  },
                  emblemEditorState.curentLayerIndex
                );
              }}
            />
          </FieldHolder>
        </div>
      </>}
    </SubPanel>
  );
});
