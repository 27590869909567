import classNames from "classnames";
import * as React from "react";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Button } from "../Buttons/Button";
import { Icon } from "../Icon";
import { H3 } from "../Text/H";

export type Tab<T> = {
    id: T,
    title: string,
    hasHighlight?: boolean,
    icons?: React.ReactNode | string | undefined,
    isAvailable: boolean | undefined
}

type Props<T> = {
    tabs: Tab<T>[],
    active: T | undefined,
    className?: string | undefined,
    hideTabIfOnly1?: boolean,
    tabSelectedComparer?: (activeTab: T, toCompare: T) => boolean | undefined,
    idRender?: (tab: T) => string,
    changeTab: (id: any) => any,
    hasSmallTitleOnMobile?: boolean
}

export const TabMenu = <T extends {}>(props: Props<T>) => {

    const tabsToUse = props.tabs.filter(t => t.isAvailable);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    if (tabsToUse.length === 0) {
        return null;
    }

    if (tabsToUse.length === 1) {

        if (props.hideTabIfOnly1) {
            return null;
        }

        return <H3>{tabsToUse[0].title}</H3>
    }

    function isTabActive(id: T) {
        if (props.tabSelectedComparer !== undefined && props.active) {
            return props.tabSelectedComparer(props.active, id);
        }

        return id === props.active;
    }

    function tabId(id: T) {
        if (props.idRender) {
            return props.idRender(id);
        }

        return id.toString();
    }

    function renderTab(tab: Tab<T>) {
        const highlight = tab.hasHighlight ? "has-highlight-bg-success" : "";

        return <li key={tabId(tab.id)} className={`${isTabActive(tab.id) ? "is-active" : ""} ${highlight} is-clickable`}>
            <a onClick={() => props.changeTab(tab.id)}>
                {tab.icons !== undefined && <>
                    {typeof tab.icons === "string" && <Icon icon={tab.icons} />}
                    {typeof tab.icons !== "string" && tab.icons}
                </>}
                {tab.title}
            </a>
        </li>;
    }

    function renderMobileTab(tab: Tab<T>) {
        const highlight = tab.hasHighlight ? "has-highlight-bg-success" : "";

        return <a key={tabId(tab.id)} className={`dropdown-item ${isTabActive(tab.id) ? "is-active" : ""} ${highlight}`} onClick={() => {
            setIsMenuOpen(false);
            props.changeTab(tab.id);
        }}>
            {typeof tab.icons === "string" && <Icon icon={tab.icons} />}
            {typeof tab.icons !== "string" && tab.icons}
            {tab.title}
        </a>;
    }

    const activeTab = tabsToUse.find(t => t.id === props.active);
    const className = props.className ? props.className : "";

    const activeTabClassName = classNames(
        "active-tab",
        props.hasSmallTitleOnMobile && "has-small-title-on-mobile"
    );

    return <>
        {!!activeTab &&
            <>
                <div className={`mobile-tabs is-hidden-tablet ${className}`} >
                    <div className={activeTabClassName}>
                        {activeTab.title}
                        {typeof activeTab.icons === "string" && <Icon icon={activeTab.icons} />}
                        {typeof activeTab.icons !== "string" && activeTab.icons}
                    </div>
                    <div />
                    <div className="other-tabs">
                        <Button type="nav" icon={IconHelper.General.InlineMenu} action={() => setIsMenuOpen(!isMenuOpen)} />
                    </div>
                </div>
            </>
        }
        {isMenuOpen && <div className="tab-menu-mobile is-hidden-tablet">
            {tabsToUse.map(t => renderMobileTab(t))}
        </div>}
        <div className={`tabs is-hidden-mobile ${className}`}>
            <ul>
                {tabsToUse.map(t => renderTab(t))}
            </ul>
        </div>
    </>;
};

type WithChildrenProps<T> = Props<T> & {
    id?: string | undefined,
    children: React.ReactNode
};

export const TabMenuWithChildren = <T extends {}>(props: WithChildrenProps<T>) => {
    return <div id={props.id} className={`tab-menu ${props.className ? props.className : ""} ${props.active ? props.active.toString().toLowerCase() : ""}`}>
        <TabMenu {...props} />
        {props.children}
    </div>;
};
