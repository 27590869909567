import { IconHelper } from "../../../Helpers/IconHelper";
import { ButtonChooser, choice } from "./ButtonChooser";

type Props = {
    isOn: boolean,
    setIsOn: (x: boolean) => any,
    isPulledRight?: boolean,
    isDisabled?: boolean,
    onIcon?: string
}

export const OnOffButtons = (props: Props) => {

    const options = [
        choice(false, "Off", IconHelper.General.Off),
        choice(true, "On", props.onIcon ?? IconHelper.General.On),
    ];

    return <ButtonChooser
        values={options}
        value={props.isOn}
        valueChanged={props.setIsOn}
        hideTextOnMobile
        {...props}
    />;
}