import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BribeForPolicyTerms, Policy } from '../../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { ThumbnailSlate } from '../../../../Components/Base/Containers/Slate';
import { LoadingSpinner } from '../../../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { QuoteParagraph } from '../../../../Components/Base/Text/QuoteParagraph';
import { PolicyTypeDescription } from '../../../../Components/FusionShift/Descriptions/PolicyTypeDescription';
import { PolicyCategoryIcon } from '../../../../Components/FusionShift/Icons/PolicyCategoryIcon';
import { PolicyLink } from '../../../../Components/FusionShift/Links/PolicyLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { PolicyVoteDescription } from '../../../Politics/Components/PolicyVoteDescription';

type Props = {
    terms: BribeForPolicyTerms
}

export const BribeForPolicyTermsDetail = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [policy, setPolicy] = React.useState<Policy | undefined>(undefined);

    React.useEffect(() => {
        apiState.PoliticsClient.getPolicy(props.terms.policyId).then(setPolicy);

    }, []);

    if (policy === undefined || !worldState.PolicyTypeSettings) {
        return <LoadingSpinner />;
    }

    return <ThumbnailSlate title={<PolicyLink policy={policy} policyTypeSettings={worldState.PolicyTypeSettings} />} thumbnail={<PolicyCategoryIcon isGiant policyCategory={worldState.PolicyTypeSettings.data[policy.policyTypeName].category} />}>
        <QuoteParagraph>
            {policy.description}
        </QuoteParagraph>
        <PolicyTypeDescription policyType={worldState.PolicyTypeSettings.data[policy.policyTypeName]} />
        <Paragraph>Voting ends {ValueFormatter.formatDateFromNowWithMoment(policy.votingEndDate)}</Paragraph>
        <Paragraph>
            The bribe is for: <PolicyVoteDescription vote={props.terms.vote} />
        </Paragraph>
        {policy.hasObservingPlayerVoted && <>
            <hr />
            {"You voted: "}
            <PolicyVoteDescription vote={policy.observingPlayersVote} />
        </>}
    </ThumbnailSlate>;
});