import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { LastSeenRelicLocation } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { FederationLink } from '../../../Components/FusionShift/Links/FederationLink';
import { ItemTypeLink } from '../../../Components/FusionShift/Links/Items/ItemTypeLink';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    relics: LastSeenRelicLocation[]
}

export const RelicTable = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (worldState.ItemTypeSettings === undefined) {
        return null;
    }

    return <SubPanel
        heading={{
            text: "Relics",
            icon: IconHelper.Items.Relic
        }}
        isUnpadded>
        <Table
            isFullWidth
            isFixed
        >
            {props.relics
                .sort((a, b) => a.relicItemTypeName > b.relicItemTypeName ? -1 : 1)
                .map(x => <tr key={x.relicItemTypeName}>
                    <td>
                        <ItemTypeLink itemType={worldState.ItemTypeSettings!.data[x.relicItemTypeName]} />
                    </td>
                    <td>
                        {x.federation !== undefined && <FederationLink federation={x.federation} />}
                        {x.federation === undefined && x.description}
                    </td>
                </tr>)}
        </Table>
    </SubPanel>
});