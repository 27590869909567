import * as React from 'react';
import { IRepeatFleet, SolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { CloseButton } from '../../../Components/Base/Buttons/CloseButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { SolarSystemClaimTypeIcon } from '../../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { RepeatFleetList } from '../../Fleets/SubParts/RepeatFleetList';

type Props = {
    solarSystem: SolarSystemWrapper | undefined,
    solarSystems: SolarSystemWrapper[],
    close: () => any
}

export const SelectedSolarSystem = (props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (props.solarSystem === undefined) {
        return null;
    }

    function reloadCallback(solarSystem: SolarSystemDetail, _: any) {
        solarSystemState.loadSolarSystem(solarSystem);
    }

    const solarSystemFleets = props.solarSystems.map(x => {

        const fleets: IRepeatFleet[] = [];

        if (x.solarSystem.repeatFleets !== undefined) {
            for (let fleet of x.solarSystem.repeatFleets) {
                if (fleet.targetSolarSystemId === props.solarSystem!.solarSystemId || x.solarSystemId === props.solarSystem!.solarSystemId) {
                    fleets.push(fleet);
                }
            }
        }
        return {
            solarSystem: x,
            fleets
        }
    })
        .filter(x => x.fleets.length > 0);

    const ownRepeatFleets = solarSystemFleets.find(x => x.solarSystem.solarSystemId === props.solarSystem?.solarSystemId);

    return <SubPanel heading={{
        text: props.solarSystem.solarSystem.name,
        icon: <SolarSystemClaimTypeIcon solarSystem={props.solarSystem.solarSystem} />
    }}
        headingContent={<CloseButton
            action={props.close}
        />}
    >
        {solarSystemFleets.length === 0 && <Paragraph>No repeat fleets</Paragraph>}
        {solarSystemFleets.length > 0 && <>
            {ownRepeatFleets !== undefined && <RepeatFleetList solarSystem={ownRepeatFleets.solarSystem.solarSystem} fleets={ownRepeatFleets.fleets} reloadCallback={reloadCallback} />}
            {ownRepeatFleets === undefined && <Paragraph>No repeat fleets at {props.solarSystem.solarSystem.name}</Paragraph>}
            {solarSystemFleets
                .filter(x => x.solarSystem.solarSystemId !== props.solarSystem?.solarSystemId)
                .map(x => <SubPanel heading={{
                    text: x.solarSystem.solarSystem.name,
                    icon: <SolarSystemClaimTypeIcon solarSystem={x.solarSystem.solarSystem} />
                }} isUnpadded>
                    <RepeatFleetList solarSystem={x.solarSystem.solarSystem} fleets={x.fleets} reloadCallback={reloadCallback} />
                </SubPanel>
                )}
        </>}
    </SubPanel>;
};