import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ISolarSystemWithCelestials } from '../../../../ApplicationState/ApiClient';
import { AgentStateContext, AppStateContext, PlayerStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { ButtonLink } from '../../../../Components/Base/Buttons/ButtonLink';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { handleOnClick } from '../../../../Components/Base/Util';
import { CelestialImage } from '../../../../Components/FusionShift/Celestials/CelestialImage';
import { AgentPortrait } from '../../../../Components/FusionShift/Images/AgentPortrait';
import { AgentLink } from '../../../../Components/FusionShift/Links/AgentLink';
import { CelestialHelper } from '../../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { agentById, agents } from '../../../../Navigation/Routing/Agents';

type Props = {
    solarSystem: ISolarSystemWithCelestials | undefined,
    showNoAgents?: boolean
}

export const SolarSystemAgents = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const appState = React.useContext(AppStateContext);
    const worldState = React.useContext(WorldStateContext);
    const agentState = React.useContext(AgentStateContext);

    const celestialsWithAgents = React.useMemo(() => {

        return (props.solarSystem?.celestials ?? []).filter(x => x.agents.length > 0).sort((a, b) => a.order - b.order);

    }, [props.solarSystem]);

    if (props.solarSystem === undefined || playerState.Player === undefined) {
        return null;
    }

    const current = agentState.AgentSummary?.agents.length ?? 0;
    const capacity = agentState.AgentSummary?.agentCapacity ?? 0;
    const showHire = current < capacity && props.solarSystem.playerId === playerState.Player.playerId;

    if (celestialsWithAgents.length === 0) {

        if (props.showNoAgents) {
            return <SubPanel
                heading={{ text: "Agents", icon: IconHelper.Agents.Agent }}
                headingContent={
                    showHire && <ButtonLink to={agents} text='Hire' icon={IconHelper.Agents.Hire} />
                }
            >
                <Paragraph>There are no agents at this system.</Paragraph>
            </SubPanel>;
        }

        return null;
    }

    if (!worldState.CelestialTypeSettings) {
        return null;
    }

    return <SubPanel heading={{ text: "Agents", icon: IconHelper.Agents.Agent }}
        isUnpadded
        headingContent={
            showHire && <ButtonLink to={agents} text='Hire' icon={IconHelper.Agents.Hire} />
        }>
        <Table isClickable isFullWidth>
            {celestialsWithAgents.map(c => <React.Fragment key={c.celestialId}>
                {c.agents.map((x, i) => <tr key={x.agentId} onClick={e => handleOnClick(e, () => appState.navigate(agentById(x.agentId)))}>
                    <td>
                        <AgentPortrait agent={x} size='tiny' />
                    </td>
                    <td><AgentLink agent={x} /></td>
                    <td>
                        {i === 0 && <CelestialImage celestial={c} />}
                    </td>
                    <td>
                        {i === 0 && CelestialHelper.fullname(c, worldState.CelestialTypeSettings!)}
                    </td>

                </tr>)}
            </React.Fragment>)}
        </Table>
    </SubPanel>;
});