import { SecurityStatus } from "../../ApplicationState/ApiClient";
import { FSOIcons } from "./FSOIcons";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class SecurityIcons {

    public get Redemption() { return MaterialDesignIcons.accountBoxEdit; }

    public get Security() { return MaterialDesignIcons.lock; }

    public get BeginnersProtection() { return FSOIcons.protected; }
    public get Civilian() { return FSOIcons.civilian; }
    public get CombatActive() { return FSOIcons.combatActive; }
    public get Exile() { return FSOIcons.exile; }

    public securityStatus(securityStatus: SecurityStatus): string {
        switch (securityStatus) {
            case SecurityStatus.Protected:
                return this.BeginnersProtection;
            case SecurityStatus.Civilian:
                return this.Civilian;
            case SecurityStatus.CombatActive:
                return this.CombatActive;
            case SecurityStatus.Exile:
                return this.Exile;
        }
    }
}