import * as React from 'react';
import { ISolarSystem, ISolarSystemForList, PlayerMinimal, SolarSystem, WorldRegion } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { ChooseOwnSolarSystemFromState } from '../../../Components/FusionShift/SolarSystems/ChooseOwnSolarSystem';
import { ChooseRecentSolarSystem } from '../../../Components/FusionShift/SolarSystems/ChooseRecentSolarSystem';
import { FindSolarSystemByCoordinates } from '../../../Components/FusionShift/SolarSystems/FindSolarSystemByCoordinates';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    solarSystem: ISolarSystem | undefined,
    initialSolarSystemId: number | undefined,
    clearTarget: () => any,
    solarSystemChanged: (solarSystem: ISolarSystem | undefined) => any,
    showOwnSystems?: boolean,
    label?: string | undefined
}

export const ChooseSolarSystem = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    function ownSolarSystemFound(solarSystem: ISolarSystemForList | undefined) {
        if (!solarSystem) {
            props.solarSystemChanged(undefined);
        } else {
            props.solarSystemChanged(new SolarSystem({
                ...solarSystem,
                isExplored: true,
                playerId: playerState.Player?.playerId,
                owner: new PlayerMinimal(playerState.Player),
                region: WorldRegion.Core
            }));
        }
    }

    return <SubPanel heading={{ text: props.label !== undefined ? props.label : "Target", icon: IconHelper.Combat.Target }}>
        {props.solarSystem === undefined && <>
            <FindSolarSystemByCoordinates initialTarget={props.initialSolarSystemId} solarSystemFoundCallback={props.solarSystemChanged} />
            <ChooseRecentSolarSystem solarSystemFoundCallback={props.solarSystemChanged} />
            {props.showOwnSystems && <ChooseOwnSolarSystemFromState {...props} solarSystemFoundCallback={ownSolarSystemFound} />}
        </>}
        {props.solarSystem !== undefined && <>
            <SolarSystemLink solarSystem={props.solarSystem} />
            <Button type="nav" text="Change" icon={IconHelper.Combat.Target} action={() => props.clearTarget()} isPulledRight />
        </>}
    </SubPanel>;
}