import { FleetMovementType } from "../../../ApplicationState/ApiClient"

export type MapPreferences = {
    showOverlay: boolean,
    showRadarCoverage: boolean,
    showRadarCoverageAlly: boolean,
    showRadarCoverageSelf: boolean,
    showFleets: boolean,
    showFleetsAlly: boolean,
    showFleetsSelf: boolean,
    showFleetsEnemy: boolean,
    showFleetsHostileOnly: boolean,
    hideFleetMovementTypes: FleetMovementType[]
}

export const defaultPreferences: MapPreferences = {
    showOverlay: true,
    showRadarCoverage: true,
    showRadarCoverageAlly: true,
    showRadarCoverageSelf: true,
    showFleets: true,
    showFleetsAlly: true,
    showFleetsSelf: true,
    showFleetsEnemy: true,
    showFleetsHostileOnly: false,
    hideFleetMovementTypes: []
}