import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, ISendShipsToSolarSystemInput, ISolarSystem, SendShipsToSolarSystemInput, ShipClass } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, SolarSystemStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button } from '../../Base/Buttons/Button';

type Props = {
    solarSystem: ISolarSystem,
    reloadCallback?: (() => any) | undefined
}

export const ExploreButton = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const [hasSent, setHasSent] = React.useState(false);

    React.useEffect(() => {
        setHasSent(false);
    }, [props.solarSystem]);

    if (!worldState.GameSettings || !solarSystemState.CurrentSolarSystem) {
        return null;
    }

    const availableShips = solarSystemState.CurrentSolarSystem.availableShips;

    function explore() {
        const reconShip = availableShips.Ships.find(x => x.ship.class === ShipClass.Recon && x.quantity >= 1);

        if (reconShip !== undefined) {

            setHasSent(true);

            const ships: { [key: string]: number } = {};
            ships[reconShip.ship.typeName] = 1;

            const input: ISendShipsToSolarSystemInput = {
                movementType: FleetMovementType.Explore,
                targetSolarSystemId: props.solarSystem.solarSystemId,
                ships
            };

            return apiState.FleetClient.sendShipsToSolarSystem(solarSystemState.SolarSystem!.solarSystemId, new SendShipsToSolarSystemInput(input)).then(response => {
                if (response.result !== undefined) {
                    solarSystemState.loadSolarSystem(response.result);
                }
                if (props.reloadCallback !== undefined) {
                    props.reloadCallback();
                }
            });
        }
    }

    const canExplore = FleetHelper.couldBeExplored(props.solarSystem);

    if (!canExplore) {
        return null;
    }

    return <Button
        hideTextOnMobile
        type="action"
        text="Explore"
        title={!availableShips.HasRecon ? "No recon ships available" : "Explore"}
        icon={IconHelper.Ships.Explore}
        action={explore}
        isDisabled={hasSent || !availableShips.HasRecon}
    />;
});