import { login, register } from '../../Routing/AppRouteValues';
import { MenuLink } from '../MenuState';

export const AuthenticateButtons = () => {

    return <>
        <MenuLink to={login} className="navbar-item">
            Login
        </MenuLink>
        <MenuLink to={register} className="navbar-item">
            Register
        </MenuLink>
    </>;
};