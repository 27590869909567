import * as React from 'react';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';

type Props = {
    percent: number,
    higherIsWorse?: boolean
}

export const PercentComponent = (props: Props) => {

    function className() {

        const val = props.higherIsWorse ? 1 - props.percent : props.percent;

        if (val >= 0.95) {
            return "has-text-success"
        } else if (val < 0.65 && val > 0.25) {
            return "has-text-warning";
        }
        else if (val <= 0.25) {
            return "has-text-danger";
        }

        return "";
    }

    return <span className={className()}>
        {ValueFormatter.fromDecimalToDisplayPercent(props.percent)}
    </span>
};