import { ForgotPasswordView } from "../../Views/Authentication/ForgotPasswordView";
import { JoinWorldView } from "../../Views/Authentication/JoinWorldView";
import { LoginView } from "../../Views/Authentication/LoginView";
import { RegisterView } from "../../Views/Authentication/RegisterView";
import { ResetPasswordView } from "../../Views/Authentication/ResetPasswordView";
import { VerifyEmailView } from "../../Views/Authentication/VerifyEmailView";
import { HomeView } from "../../Views/Home/HomeView";
import { ErrorPage } from "../ErrorPage";
import { forgotPassword, home, join_world, login, register, resetPassword, verifyEmail } from "./AppRouteValues";
import { route, routeNonExact } from "./Root";

export const appRoutes = [

    route(register, RegisterView, "Register"),
    route(join_world, JoinWorldView, "Join World"),
    route(login, LoginView, "Login"),
    route(home, HomeView, "Home"),
    route(resetPassword + "/:token", ResetPasswordView, "Reset Password"),
    route(forgotPassword, ForgotPasswordView, "Forgot Password"),
    route(verifyEmail + "/:token", VerifyEmailView, "Verify Email"),
    routeNonExact("*", ErrorPage, "Not found")
];