import * as React from 'react';
import { TabMenuWithChildren } from '../../../Components/Base/Containers/TabMenu';
import { UpkeepIconNoQuantity } from '../../../Components/FusionShift/Icons/Items/UpkeepIcon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { EmpireViewProps } from '../EmpireViewProps';
import { ControlView } from './ControlView';
import { Exploration } from './Exploration';
import { Raiding } from './Raiding';
import { Ships } from './Ships';
import { SummaryTable } from './SummaryTable';
import { TravelTime } from './TravelTime';
import { Upkeep } from './Upkeep';

type MilitaryTab = "Summary" | "Ships" | "Upkeep" | "Travel" | "Raiding" | "Control" | "Exploration";


export const MilitaryView = (props: EmpireViewProps) => {

    const [tab, setTab] = React.useState<MilitaryTab>("Summary");

    const tabs = [
        {
            id: "Summary",
            title: "Summary",
            isAvailable: true,
            icons: IconHelper.Categories.Summary
        },
        {
            id: "Ships",
            title: "Ships",
            isAvailable: true,
            icons: IconHelper.Ships.Ship
        },
        {
            id: "Upkeep",
            title: "Upkeep",
            isAvailable: true,
            icons: <UpkeepIconNoQuantity isUncoloured />
        },
        {
            id: "Travel",
            title: "Travel",
            isAvailable: props.solarSystems.length > 1,
            icons: IconHelper.Ships.Travel
        },
        {
            id: "Control",
            title: "Control",
            isAvailable: props.solarSystems.length > 1,
            icons: IconHelper.SolarSystems.Colony
        },
        {
            id: "Exploration",
            title: "Exploration",
            icons: IconHelper.Ships.Explore,
            isAvailable: true
        },
        {
            id: "Raiding",
            title: "Raiding",
            icons: IconHelper.Combat.Raid,
            isAvailable: true
        }
    ];

    return <TabMenuWithChildren
        tabs={tabs}
        active={tab}
        changeTab={setTab}
    >
        {tab === "Summary" && <SummaryTable {...props} />}
        {tab === "Ships" && <Ships {...props} />}
        {tab === "Upkeep" && <Upkeep {...props} />}
        {tab === "Travel" && <TravelTime {...props} />}
        {tab === "Control" && <ControlView {...props} />}
        {tab === "Raiding" && <Raiding />}
        {tab === "Exploration" && <Exploration />}
    </TabMenuWithChildren>;
};

