import * as React from 'react';
import Countdown from 'react-countdown';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { Paragraph } from '../Base/Text/Paragraph';

export const gameStartDate = new Date('2024-04-01T17:00:00');

type Props = {
    children: React.ReactNode
}

export const GameStartBlocker = (props: Props) => {

    const [isBlocked, setIsBlocked] = React.useState(checkBlocked());

    function checkBlocked() {
        return new Date().getTime() <= gameStartDate.getTime() - 500;
    }

    if (isBlocked) {
        return <div className="game-start-blocker">
            <Paragraph className='has-text-centered is-size-4'>
                The game begins<br className="is-hidden-tablet" /> {ValueFormatter.formatTimeOrDate(gameStartDate, true, true)}
            </Paragraph>
            <Paragraph className='has-text-centered is-size-1 is-countdown'>
                <Countdown date={gameStartDate} onComplete={() => {
                    setIsBlocked(checkBlocked());
                }} />
            </Paragraph>
        </div>
    }

    return <>
        {props.children}
    </>;
}