import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, IFleet } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { FormFooter } from '../../../Components/Base/Form/FormFooter';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ShipInputList, ShipTypeQuantity } from '../../SendShips/Components/ShipInputList';

type Props = {
    fleet: IFleet,
    joinSolarSystemCallback: (ships: { [key: string]: number }) => any
}

export const JoinSolarSystemForm = observer((props: Props) => {

    const [shipQuantities, setShipQuantities] = React.useState<{ [key: string]: number }>({});

    const [availableFleetWrapper, setAvailableFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);
    const [selectedFleetWrapper, setSelectedFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);
    const [willAllJoinSolarSystem, setWillAllJoinSolarSystem] = React.useState(true);

    const worldState = React.useContext(WorldStateContext);

    React.useEffect(() => {
        if (!availableFleetWrapper && worldState.ShipTypeSettings && worldState.GameSettings && props.fleet.ships) {
            setAvailableFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, props.fleet.ships));
        }
    }, [worldState.ShipTypeSettings, worldState.GameSettings, props.fleet.ships])

    React.useEffect(() => {
        if (worldState.ShipTypeSettings && worldState.GameSettings) {
            setSelectedFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities));
        }
    }, [worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities]);

    function setShipQuantity(ship: ShipTypeQuantity, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }
        if (quantity > ship.quantity) {
            quantity = ship.quantity;
        }
        const newObject = {
            ...shipQuantities
        };
        newObject[ship.ship.typeName || ""] = quantity;
        setShipQuantities(newObject);

        const newSum = CollectionHelper.sumOfDictionary(newObject);
        setWillAllJoinSolarSystem(newSum === 0 || newSum === CollectionHelper.sumOfArray(availableFleetWrapper!.Ships.map(s => s.quantity)));
    }

    if (!worldState.ShipTypeSettings || !availableFleetWrapper || !selectedFleetWrapper) {
        return null;
    }

    return <>
        <Paragraph type="prompt">Choose which ships to join solar system. Leave blank for all ships.</Paragraph>
        <ShipInputList
            shipTypeSettings={worldState.ShipTypeSettings}
            availableShips={availableFleetWrapper}
            selectedShips={selectedFleetWrapper}
            movementType={FleetMovementType.ReturnHome}
            setShipQuantity={(s, q) => setShipQuantity(s, q)}
            setShipTactic={(s, t) => { }}
            shipQuantities={shipQuantities}
            showTactics={false}
            tactics={{}}
        />
        <FormFooter extra={willAllJoinSolarSystem ? "All ships will join the solar system." : undefined}>
            <Button type="warning" icon={IconHelper.Ships.movementType(FleetMovementType.Rebase)} text="Join solar system" action={() => props.joinSolarSystemCallback(shipQuantities)} className="is-pulled-right" />
        </FormFooter>
    </>;
});