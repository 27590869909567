export type BackgroundImage = {
    type: "solarSystem" | "celestial" | "agentAction",
    variationSource: string,
    variant: number
}

export class BackgroundImageHelper {

    public static className(backgroundImage: BackgroundImage | undefined) {

        if (backgroundImage === undefined) {
            return undefined;
        }

        const { type, variationSource, variant } = backgroundImage;

        return `has-image-background ${type}-background ${variationSource}_${variant}`;
    }
}