import { observer } from "mobx-react-lite";
import React from "react";
import { DevStateContext } from "../../ApplicationState/ContextRoot";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Checkbox } from "../../Components/Base/Form/Checkbox";
import { IconHelper } from "../../Helpers/IconHelper";

export const DevSettings = observer(() => {

    const devState = React.useContext(DevStateContext);

    return <SubPanel
        heading={{ text: "Developer Settings", icon: IconHelper.General.Chart }} >
        <ul>
            <li><Checkbox label="Hide Celestial Render"
                value={devState.data.hideCelestialRender ?? false}
                valueChanged={v => devState.setData({
                    ...devState.data,
                    hideCelestialRender: v
                })}
            />
            </li>
        </ul>
    </SubPanel>
});