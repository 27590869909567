import { makeAutoObservable } from "mobx";
import { IPlayerNotifications } from "../ApiClient";
import { MissionLogState } from "./MissionLogState";

export type UnreadType = "message" | "combat" | "playernotification" | "mission" | "policyVote" | "presidentialTermVote" | "contract" | "agentAction";

export class UnreadState {

    get HasUnread() {
        return this.HasUnreadCombatReport ||
            this.HasUnreadMessage ||
            this.HasUnreadPlayerNotification ||
            this.HasPendingPolicyVote ||
            this.HasPendingAssignedContract;
    }

    get HasPendingAssignedContract() { return this._unreadTypes.includes("contract"); }

    get HasPendingPolicyVote() { return this._unreadTypes.includes("policyVote"); }

    get HasPendingPresidentialTermVote() { return this._unreadTypes.includes("presidentialTermVote"); }

    get HasPendingAgentAction() { return this._unreadTypes.includes("agentAction"); }

    get HasUnreadMessage() { return this._unreadTypes.includes("message"); }

    get HasUnreadCombatReport() { return this._unreadTypes.includes("combat"); }

    get HasUnreadPlayerNotification() { return this._unreadTypes.includes("playernotification"); }

    get HasUnreadMission() {
        return this._unreadTypes.includes("mission")
            || (this._missionLogState.MissionLog &&
                this._missionLogState.MissionLog.rewardPendingMissions.length > 0);
    }

    private _unreadTypes: UnreadType[] = [];

    private _missionLogState: MissionLogState;

    constructor(missionLogState: MissionLogState) {
        this._missionLogState = missionLogState;

        makeAutoObservable(this);
    }

    public clearUnread(unread: UnreadType) {
        this._unreadTypes = this._unreadTypes.filter(t => t != unread);
    }

    public addUnread(unread: UnreadType) {
        this._unreadTypes.push(unread);
    }

    public setUnread(unread: UnreadType, isUnread: boolean) {
        if (isUnread) {
            this.addUnread(unread);
        } else {
            this.clearUnread(unread);
        }
    }

    public loadUnread(notifications: IPlayerNotifications) {
        this.setUnread("contract", notifications.hasUnreadContract);
        this.setUnread("presidentialTermVote", notifications.hasUnreadPresidentialTermVote);
        this.setUnread("policyVote", notifications.hasUnreadPolicyVote);
        this.setUnread("message", notifications.hasUnreadMessage);
        this.setUnread("combat", notifications.hasUnreadCombatReport);
        this.setUnread("playernotification", notifications.hasUnreadNotification);
        this.setUnread("agentAction", notifications.hasPendingAgentAction);
    }

    public clearAllUnread() {
        this._unreadTypes = [];
    }
}