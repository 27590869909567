import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SecurityStatus, WorldRegion } from '../../../ApplicationState/ApiClient';
import { MessageBoxWithIcon } from '../../../Components/Base/MessageBox';
import { SecurityStatusChange } from '../../../Components/FusionShift/Messages/SecurityStatusChange';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { SendShipsBag } from '../SendShipsBag';

type Props = {
    sendShipsBag: SendShipsBag
}

export const StatusWarnings = observer((props: Props) => {

    function beginnersProtectionWarning() {

        if (props.sendShipsBag.MovementType && FleetHelper.isHostileMovementType(props.sendShipsBag.MovementType) &&
            props.sendShipsBag.TargetSolarSystem &&
            props.sendShipsBag.TargetSolarSystem.owner &&
            props.sendShipsBag.init.player.hasBeginnersProtection) {

            return <MessageBoxWithIcon type="warning" icon={IconHelper.Security.BeginnersProtection} text={`Sending this ${ValueFormatter.friendlyNameForMovementType(props.sendShipsBag.MovementType)} will end your beginner's protection.`} />;
        }
    }

    function securityStatusWarning() {

        if (props.sendShipsBag.MovementType && props.sendShipsBag.TargetSolarSystem) {

            if (props.sendShipsBag.hasCivilianCasualties() && props.sendShipsBag.TargetSolarSystem.region === WorldRegion.Core && props.sendShipsBag.init.player.securityStatus !== SecurityStatus.Exile) {
                return <MessageBoxWithIcon type="danger" icon={IconHelper.Security.securityStatus(SecurityStatus.Exile)} text={`Causing civilian casualties in the core will change your security status to ${ValueFormatter.friendlyNameForSecurityStatus(SecurityStatus.Exile)}.`} />;
            }

            const newSecurityStatus = FleetHelper.securityStatusChange(props.sendShipsBag.MovementType, props.sendShipsBag.init.player, props.sendShipsBag.TargetSolarSystem);

            if (newSecurityStatus !== undefined) {

                return <SecurityStatusChange
                    reason={`Sending this ${ValueFormatter.friendlyNameForMovementType(props.sendShipsBag.MovementType)}`}
                    newSecurityStatus={newSecurityStatus}
                />;
            }
        }
    }

    return <>
        {beginnersProtectionWarning()}
        {securityStatusWarning()}
    </>;
});