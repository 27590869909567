import { TextAndIcon } from "../Text/TextAndIcon";

export type HeadingContent = string |
{
    text: string,
    mobileText?: string,
    icon: string | React.ReactNode
} |
{ content: React.ReactNode }

type Props = {
    content: HeadingContent;
}

export const PanelHeading = (props: Props) => {

    const content = props.content;

    if (typeof content === "string") {
        return <>{content}</>;
    }

    if ("content" in content) {
        return <>{content.content}</>;
    }

    return <TextAndIcon {...content} />;
}