import React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { QuantityIcon, QuantityIconProps } from "../QuantityIcon";

type Props = QuantityIconProps & {
    componentType?: ItemType | undefined | false,
    isGiant?: boolean,
    label?: string | undefined
}

export const ComponentIcon = (props: Props) => {

    const name = props.componentType ? props.componentType.name : "Components";
    const label = props.label ? props.label : name;

    return <QuantityIcon name={label} icon={IconHelper.Items.Component} className="components" {...props} />;
};

