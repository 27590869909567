import * as React from 'react';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    value: number
}

export const LocaleNumber = (props: Props) => {

    return <>{ValueFormatter.formatLocaleNumber(props.value)}</>;
};

type CollectionProps = {
    id: string,
    values: { [key: string]: number }
}

export const LocaleNumberFromCollection = (props: CollectionProps) => {

    const value = props.id in props.values ? props.values[props.id] : 0;

    return <>{ValueFormatter.formatLocaleNumber(value)}</>;
};