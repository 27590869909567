import { makeAutoObservable } from "mobx";

export class TutorialState {

    ShowIntroduction: boolean = false;
    TutorialRunning: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public showIntroduction() {
        this.ShowIntroduction = true;
    }

    public finishedIntroduction() {
        this.ShowIntroduction = false;
        this.TutorialRunning = true;
    }
}