import { CelestialDetail, Megacity } from "../../../../ApplicationState/ApiClient";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemBuilder, ProblemCategory, ProblemsMaybe } from "../Problem";

export const megacityProblemBuilder = new ProblemBuilder(ProblemCategory.Megacities);

export function populationProblems(wrapper: SolarSystemWrapper, megacity: Megacity, celestial: CelestialDetail): ProblemsMaybe {

    return [
        megacity.currentPopulation >= megacity.maximumPopulation && megacityProblemBuilder.infoProblem("Maximum Population Reached", `The Megacity at ${wrapper.solarSystem.name} has reached its maximum population of ${ValueFormatter.formatPopulation(megacity.maximumPopulation)}`),
        megacity.unassignedPopulation > 0.1 && megacityProblemBuilder.infoProblem("Population Unassigned", `There are ${ValueFormatter.formatPopulation(megacity.unassignedPopulation)} citizens not assigned to work in a sector`)
    ];
}