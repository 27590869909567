import classNames from 'classnames';
import * as React from 'react';
import { H3 } from '../Text/H';
import { handleOnClick } from '../Util';

type Props = {
    className?: string | undefined,
    onClick?: (() => any) | undefined,
    isDanger?: boolean,
    isSelected?: boolean,
    title: React.ReactNode,
    mobileTitle?: React.ReactNode,
    children: React.ReactNode,
    hasInnerFlex?: boolean,
    footerContent?: React.ReactNode
}

type ThumbnailProps = Props & {
    underThumbnail?: React.ReactNode | undefined,
    thumbnail: React.ReactNode,
    isMobileVertical?: boolean
}

export const ThumbnailSlate = (props: ThumbnailProps) => {

    const thumbClasses = classNames({
        "has-text-danger": props.isDanger
    }, "thumbnail");

    const slateClasses = classNames(
        "has-thumbnail",
        props.isMobileVertical && "is-mobile-vertical",
        props.className
    );

    return <Slate {...props} className={slateClasses} >
        <div className={thumbClasses}>
            {props.thumbnail}
        </div>
        {props.underThumbnail !== undefined && <div className="under-thumbnail">
            {props.underThumbnail}
        </div>}
        <div className="children">
            {props.children}
        </div>
    </Slate>;
}

export const Slate = (props: Props) => {

    const classes = classNames({
        "is-danger": props.isDanger,
        "is-selected": props.isSelected,
        "is-clickable": props.onClick !== undefined,
        "has-inner-flex": props.hasInnerFlex,
    }, "slate", props.className);

    return <div className={classes} onClick={props.onClick != undefined ? e => handleOnClick(e, props.onClick!) : e => { }}>
        <H3>
            {props.mobileTitle === undefined && props.title}
            {props.mobileTitle !== undefined && <>
                <span className="is-hidden-mobile">{props.title}</span>
                <span className="is-hidden-tablet">{props.mobileTitle}</span>
            </>}
        </H3>
        <div className="inner">
            {props.children}
        </div>
        {props.footerContent !== undefined && <div className="slate-footer">
            {props.footerContent}
        </div>}
    </div>;
};