import * as React from 'react';
import { Link } from 'react-router-dom';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { UnreadContractIcon } from '../../../Components/FusionShift/Icons/UnreadIcons';
import { contracts, contract_browse, contract_create } from '../../../Navigation/Routing/Contracts';
import { market_browse, market_create, market_orders } from '../../../Navigation/Routing/Market';

export const Market = () => {

    return <SubPanel heading="Market and Contracts">
        <SplitLayout
            left={
                <ul>
                    <li>
                        <Link to={market_orders}>Your Orders</Link>
                    </li>
                    <li>
                        <Link to={market_create}>Create Order</Link>
                    </li>
                    <li>
                        <Link to={market_browse}>Browse Market</Link>
                    </li>
                </ul>
            }
            right={
                <ul>
                    <li>
                        <Link to={contracts}>Your Contracts</Link> <UnreadContractIcon />
                    </li>
                    <li>
                        <Link to={contract_create}>Create Contract</Link>
                    </li>
                    <li>
                        <Link to={contract_browse}>Browse Contracts</Link>
                    </li>
                </ul>
            } />
    </SubPanel>;
};