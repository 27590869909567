import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';

type Props = {
    isMobileOnly?: boolean
}


export const LoadingBar = observer((props: Props) => {

    return <div className={`loading-bar is-expanded ${props.isMobileOnly ? "is-hidden-desktop" : ""}`}>
        <progress className="progress is-info is-small" style={{ minWidth: "300px" }} max="100">0</progress>
    </div>;
});

export const AutomaticLoadingBar = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);

    if (appState.ApplicationState !== 'PerformingInitialLoad') {
        return null;
    }

    return <LoadingBar {...props} />;
});