import { observer } from 'mobx-react-lite';
import { FleetMovementType, ShipClass } from '../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { CelestialSelect } from '../../../Components/FusionShift/Celestials/CelestialSelect';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { UpkeepSummary } from '../../Fleets/Components/UpkeepSummary';
import { AgentInputList } from '../Agents/AgentInputList';
import { AvailableContracts } from '../Components/AvailableContracts';
import { ClaimType } from '../Components/ClaimType';
import { ShipInputList } from '../Components/ShipInputList';
import { TargetTextDescription } from '../Components/Target/TargetTextDescription';
import { SendNowButton } from '../SendNowButton';
import { StageProps } from './StageRender';

export const ShipsStage = observer((props: StageProps) => {

    function allFactionShips() {

        const ships = Object.values(props.sendShipsBag.init.shipTypeSettings.data)
            .filter(x => x.factionTypeName === props.sendShipsBag.init.player.factionTypeName && x.class !== ShipClass.Drone)
            .map(x => {
                return {
                    key: x.typeName,
                    value: props.sendShipsBag.init.availableShips.shipQuantity(x.typeName)
                }
            });

        return new FleetWrapper(props.sendShipsBag.init.shipTypeSettings, props.sendShipsBag.init.gameSettings, CollectionHelper.arrayToDictionary(ships))
    }

    const showTactics = props.sendShipsBag.allowedConfigurations.tactics !== false;

    const noShips = CollectionHelper.sumOfDictionary(props.sendShipsBag.shipQuantities) <= 0;
    const noCelestial = props.sendShipsBag.MovementType === FleetMovementType.Mine && props.sendShipsBag.TargetCelestialId === undefined;

    const nextDisabled = noShips || noCelestial;

    const availableShips = !props.sendShipsBag.IsEditingRepeatFleet ? props.sendShipsBag.init.availableShips : allFactionShips();

    const agents = props.sendShipsBag.availableAgentsInSource.filter(x => x.isAvailable);
    const showAgents = props.sendShipsBag.MovementType == FleetMovementType.Attack && agents.length > 0;

    const showUpkeep = props.sendShipsBag.MovementType === FleetMovementType.Rebase && props.sendShipsBag.TargetSolarSystem !== undefined;

    const upkeepQuantityCost = showUpkeep ? CollectionHelper.sumOfDictionaryValue(props.sendShipsBag.shipQuantities, (shipTypeName, quantity) => shipTypeName in props.sendShipsBag.init.shipTypeSettings.data ? props.sendShipsBag.init.shipTypeSettings.data[shipTypeName].upkeepPerHour * quantity : 0)
        : 0;

    return <SubPanel heading="Ships"
        headingContent={<SendNowButton {...props} isSendNow />}
        footerContent={
            <ButtonHolder isPulledRight>
                <PreviousButton action={props.previousStage} />
                <NextButton action={props.nextStage} isDisabled={nextDisabled} />
            </ButtonHolder>
        }>
        <Paragraph>
            Select which ships to send to <TargetTextDescription sendShipsBag={props.sendShipsBag} isShort />. {showTactics && "Configure which combat round each ship type will enter combat. Usually it is best for all combat ships to enter on the first round and for non-combat ships to wait until the last round."}
        </Paragraph>
        <ShipInputList
            shipTypeSettings={props.sendShipsBag.init.shipTypeSettings}
            availableShips={availableShips}
            selectedShips={props.sendShipsBag.fleetWrapper}
            movementType={props.sendShipsBag.MovementType}
            setShipQuantity={(s, q) => props.sendShipsBag.setShipQuantity(s, q)}
            setShipTactic={(s, t) => props.sendShipsBag.setShipTactic(s, t)}
            shipQuantities={props.sendShipsBag.shipQuantities}
            showTactics={showTactics}
            tactics={props.sendShipsBag.Orders.tactics ?? {}}
            isIntercept={props.sendShipsBag.IsIntercept}
        />
        {props.sendShipsBag.TargetSolarSystem && props.sendShipsBag.showCelestialSelection && props.sendShipsBag.TargetSolarSystem.celestials && (props.sendShipsBag.MovementType === FleetMovementType.Attack || props.sendShipsBag.MovementType === FleetMovementType.Mine || props.sendShipsBag.MovementType === FleetMovementType.Claim || props.sendShipsBag.MovementType === FleetMovementType.CommoditySell) &&
            <CelestialSelect
                celestialId={props.sendShipsBag.TargetCelestialId}
                setCelestialId={c => props.sendShipsBag.setTargetCelestialId(c)}
                celestials={props.sendShipsBag.TargetSolarSystem.celestials}
                promptMovementType={props.sendShipsBag.MovementType}
                extra={<ClaimType {...props} />}
            />
        }
        {showAgents && <AgentInputList
            current={props.sendShipsBag.agentIdsToSend}
            setValue={(agentId, send) => props.sendShipsBag.setAgentIdToSend(agentId, send)}
            available={props.sendShipsBag.availableAgentsInSource}
            sendShipsBag={props.sendShipsBag}
        />}
        {showUpkeep &&
            <SubPanel heading={{ text: `Upkeep at ${props.sendShipsBag.TargetSolarSystem!.name}`, icon: IconHelper.Items.resource("Gas") }}>
                <UpkeepSummary
                    additionalUpkeep={upkeepQuantityCost}
                    additionalUpkeepIcon={IconHelper.Ships.movementType(FleetMovementType.Rebase)}
                    additionalUpkeepLabel="Rebased Ships"
                    items={props.sendShipsBag.TargetSolarSystem!.items}
                    itemsDetail={props.sendShipsBag.TargetSolarSystem!.itemsDetail}
                    itemTypeSettings={props.sendShipsBag.init.itemTypeSettings}
                />
            </SubPanel>}
        <AvailableContracts {...props} />
    </SubPanel>;
});