import { makeAutoObservable } from "mobx";
import { Chat, ChatClient, ChatMessage } from "../ApiClient";

export class ChatChannel {

    HasUnread: boolean = false;

    get Messages() { return this._messages; }
    private _messages: ChatMessage[] = [];

    get Chat() { return this._chat; }
    private _chat: Chat;

    constructor(chat: Chat, chatClient: ChatClient) {

        makeAutoObservable(this);

        this._chat = chat;

        chatClient.getMessages(this.Chat.chatId).then(m => {
            if (m) {
                this._messages = m;
            }
        })
    }

    public appendMessage(chatMessage: ChatMessage) {
        this.HasUnread = true;
        this._messages.push(chatMessage);
    }

    public markAsRead() {
        this.HasUnread = false;
    }
}

export class ChatState {

    private _chatClient: ChatClient;

    get HasUnread() { return this.Chats && this.Chats.find(c => c.HasUnread); }

    get Chats() { return this._chats };
    private _chats: ChatChannel[] | undefined | null;

    constructor(chatClient: ChatClient) {
        this._chatClient = chatClient;

        makeAutoObservable(this);
    }

    setChats(chats: Chat[] | undefined | null) {
        this._chats = null;

        if (chats) {
            this._chats = chats.map(c => new ChatChannel(c, this._chatClient));
        }
    }

    messageReceived(msg: any) {
        if (this.Chats) {
            const chatMessage = ChatMessage.fromJS(msg);
            const chat = this.Chats.find(c => c.Chat.chatId === chatMessage.chatId);
            if (chat) {
                chat.appendMessage(chatMessage);
            }
        }
    }
}