import { observer } from "mobx-react-lite";
import * as React from "react";
import { IMessageInput, IPlayerFederationDetail, MessageInput, Player, ValidationResult } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidatingTextInput } from "../../Components/Base/Form/ValidatingTextInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { InfoMessageBox } from "../../Components/Base/MessageBox";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { FederationLink } from "../../Components/FusionShift/Links/FederationLink";
import { FederationHelper } from "../../Helpers/FederationHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { messages } from "../../Navigation/Routing/NotificationsEtc";
import { SimpleBaseView } from "../BaseView";

export const SendMessageView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [playerId, setPlayerId] = React.useState<number | undefined>(undefined);
    const [toFederation, setToFederation] = React.useState<boolean | undefined>(undefined);
    const [subject, setSubject] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [body, setBody] = React.useState<ValidationResult>(ValidationHelper.empty());

    const [federation, setFederation] = React.useState<IPlayerFederationDetail | undefined>(undefined);
    const [player, setPlayer] = React.useState<Player | undefined>(undefined);

    React.useEffect(() => {
        if (!toFederation && !playerId) {
            let search = window.location.search;
            if (search.startsWith('?')) {
                search = search.substring(1);
            }

            const parsedFromSearch = new URLSearchParams(search);
            if (parsedFromSearch.has("toFederation")) {
                setToFederation(true);
            } else if (parsedFromSearch.has("playerId")) {
                setPlayerId(Number(parsedFromSearch.get("playerId")!.toString()));
            }

            if (parsedFromSearch.has("subject")) {
                const parsedSubject = parsedFromSearch.get("subject")!.toString();
                if (parsedSubject.length > 0) {
                    setSubject(ValidationHelper.valid(parsedSubject));
                }
            }
        }
    }, []);

    React.useEffect(() => {
        if (playerId) {
            apiState.PlayerClient.get(playerId).then(player => {
                if (player) {
                    setPlayer(player);
                }
            });
        } else if (toFederation && playerState.Player && playerState.Player.federation) {
            if (FederationHelper.isLeaderOrOfficer(playerState.Player.federation)) {
                setFederation(playerState.Player.federation)
            }
        }
    }, [playerId, toFederation, playerState.Player]);

    function send() {
        const data: IMessageInput = {
            subject: subject.validText!,
            body: body.validText!
        };
        const message = new MessageInput(data);

        if (player) {
            return apiState.MessageClient.sendToPlayer(player.playerId, message).then(response => {
                if (response) {
                    appState.navigate(messages);
                }
            });
        } else if (federation) {
            return apiState.MessageClient.sendToFederation(federation.federationId, message).then(response => {
                if (response) {
                    appState.navigate(messages);
                }
            });
        }
    }

    function validateSubject(subject: string) {
        return apiState.MessageClient.validateSubject(subject);
    }

    function validateBody(body: string) {
        return apiState.MessageClient.validateBody(body);
    }


    if (!player && !federation) {
        return null;
    }

    const to = player !== undefined ? player.name : federation !== undefined ? federation.name : "";
    const heading = `Message To: ${to}`;

    return <SimpleBaseView heading="Send Message">
        {federation !== undefined && <InfoMessageBox >
            <Paragraph>
                You are writing a message to all of the members of <FederationLink federation={federation} />
            </Paragraph>
        </InfoMessageBox>}
        <SubPanel
            heading={heading}
            footerContent={
                <Button type="action" text="Send" icon={IconHelper.General.Confirm} action={send} isDisabled={!body.isValid || !subject.isValid} isPulledRight />
            }>
            <ValidatingStringInput
                label="Subject"
                icon={IconHelper.General.Message}
                initialValue={subject.validText}
                validation={validateSubject}
                valueChanged={setSubject}
            />
            <ValidatingTextInput
                placeholder={"Your message here"}
                label="Message"
                validation={validateBody}
                valueChanged={setBody}
            />
        </SubPanel>
    </SimpleBaseView>
});