import { Diplomacy } from './MenuItems/Diplomacy';
import { Help } from './MenuItems/Help';
import { Info } from './MenuItems/Info';
import { Links } from './MenuItems/Links';
import { Market } from './MenuItems/Market';
import { Personal } from './MenuItems/Personal';
import { SolarSystemList } from './MenuItems/SolarSystemList';

export const FlatMenu = () => {

    return <div className="flat-menu">
        <SolarSystemList />
        <Links />
        <Diplomacy />
        <Market />
        <Personal />
        <Info />
        <Help />
    </div>;
};