import { AgentAction, AgentActionCategory, AgentStatus } from "../../ApplicationState/ApiClient";
import { FSOIcons } from "./FSOIcons";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class AgentIcons {

    public get Agent() { return FSOIcons.statusOvert; }

    public get Hire() { return MaterialDesignIcons.cardAccountDetailsStar; }

    public get Experience() { return MaterialDesignIcons.school; }

    public get Overt() { return FSOIcons.statusOvert; }
    public get Covert() { return FSOIcons.statusCovert; }
    public get Stowaway() { return MaterialDesignIcons.hail; }
    public get Captured() { return MaterialDesignIcons.handcuffs; }

    public status(status: AgentStatus): string {
        switch (status) {
            case AgentStatus.Captured:
                return this.Captured;

            case AgentStatus.Overt:
                return this.Overt;

            case AgentStatus.Covert:
                return this.Covert;

            case AgentStatus.Deceased:
                return MaterialDesignIcons.grave;

            case AgentStatus.Stowaway:
                return this.Stowaway;
        }
    }

    public action(action: AgentAction): string {

        switch (action) {
            case AgentAction.ApplyEffect:
                return MaterialDesignIcons.flash;

            case AgentAction.Train:
                return this.Experience;

            case AgentAction.ChangeCelestial:
            case AgentAction.ChangeCelestialWhileCovert:
                return MaterialDesignIcons.earthArrowRight;

            case AgentAction.CounterEspionage:
                return MaterialDesignIcons.findReplace;

            case AgentAction.CancelStowawayToCovert:
            case AgentAction.GoCovert:
                return FSOIcons.goCovert;

            case AgentAction.CancelStowawayToOvert:
            case AgentAction.GoOvert:
                return FSOIcons.goOvert;

            case AgentAction.StowawayOnShip:
            case AgentAction.PrepareForStowawayWhileCovert:
            case AgentAction.PrepareForStowawayWhileOvert:
                return MaterialDesignIcons.hail;

            case AgentAction.Rest:
                return MaterialDesignIcons.bed;

            case AgentAction.ArrestAgent:
                return MaterialDesignIcons.handcuffs;

            case AgentAction.LocateRelic:
                return MaterialDesignIcons.floppy;
        }
    }

    public actionCategory(category: AgentActionCategory) {
        switch (category) {
            case AgentActionCategory.Admin:
                return MaterialDesignIcons.security;
            case AgentActionCategory.Harvesting:
                return MaterialDesignIcons.basket;
            case AgentActionCategory.Megacity:
                return MaterialDesignIcons.city;
            case AgentActionCategory.Military:
                return MaterialDesignIcons.sword;
            case AgentActionCategory.Manufactory:
                return MaterialDesignIcons.factory;
            case AgentActionCategory.Utility:
                return MaterialDesignIcons.cog;
        }
    }
}