
import { observer } from "mobx-react-lite";
import * as React from "react";
import { AppStateContext, ChatStateContext } from "../../ApplicationState/ContextRoot";
import { ChatChannel } from "../../ApplicationState/States/ChatState";
import { PanelWithTabs } from "../../Components/Base/Containers/PanelWithTabs";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { DiscordLink } from "../../Components/FusionShift/Links/DiscordLink";
import { IconHelper } from "../../Helpers/IconHelper";
import { BaseViewWrapper } from "../BaseView";
import { ChatView } from "./ChatView";

export const ChatIndex = observer(() => {

    const appState = React.useContext(AppStateContext);
    const chatState = React.useContext(ChatStateContext);
    const [chatId, setChatId] = React.useState<number | undefined>(undefined);
    const [chat, setChat] = React.useState<ChatChannel | undefined>(undefined);

    React.useEffect(() => {
        if (chatState.Chats && chatState.Chats.length > 0 && !chatId) {
            setChatId(chatState.Chats[0].Chat.chatId);
        }

    }, [chatState.Chats]);

    React.useEffect(() => {
        const value = appState.getUserPrefsObject("chatId");
        if (value !== undefined) {
            setChatId(Number(value));
        }
    }, []);

    React.useEffect(() => {
        if (chatId !== undefined && chatState.Chats) {
            appState.setUserPrefsObject("chatId", chatId);
        }
    }, [chatId, chatState.Chats]);

    React.useEffect(() => {
        if (chatId && chatState.Chats) {
            const chat = chatState.Chats.find(c => c.Chat.chatId === chatId);
            setChat(chat);
        }
    }, [chatId, chatState.Chats]);

    if (!chatState.Chats) {
        return <LoadingSpinner />;
    }

    const tabs = chatState.Chats.map(c => {
        return {
            id: c.Chat.chatId,
            title: c.Chat.subject,
            isAvailable: true,
            icons: undefined
        }
    });

    return <BaseViewWrapper>
        <PanelWithTabs
            heading={{ text: "Chat ", icon: IconHelper.Chat.Chat }}
            tabs={tabs}
            active={chatId ?? 0}
            setActive={setChatId}
        >
            {!chat && <Paragraph type="missing">No chats</Paragraph>}
            {!!chat &&
                <ChatView channel={chat} />
            }
            <br />
            <Paragraph>
                Carry on the discussion in the Fusion Shift Online <DiscordLink /> server.
            </Paragraph>
        </PanelWithTabs>
    </BaseViewWrapper>;
});