import { observer } from "mobx-react-lite";
import React from "react";
import { Emblem, InGameProductCategory } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../../ApplicationState/ContextRoot";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { defaultEmblem } from "./EmblemEditActionReducer";
import { EmblemEditorState } from "./EmblemEditorState";
import { getManifestDataFromApi } from "./GetManfiestApi";


export const EmblemEditorContext = React.createContext<EmblemEditorState>({} as EmblemEditorState);

export const EmblemEditorStateProvider = observer(({ children }) => {

  const apiState = React.useContext(ApiStateContext);
  const { ClientSettings } = React.useContext(AppStateContext);

  const [emblemEditorState, setEmblemEditorState] = React.useState<EmblemEditorState | undefined>(undefined);

  React.useEffect(() => {
    (async () => {

      if (emblemEditorState === undefined && ClientSettings !== undefined) {

        const data = await apiState.EmblemClient.getActive();
        const premiumEmblemsProducts = await apiState.PremiumClient.getInGameProductsByCategory(InGameProductCategory.Emblem);
        const manifest = await getManifestDataFromApi(ClientSettings?.emblemsCdnUrl);

        const layerStateManager = new EmblemEditorState(
          apiState,
          manifest!,
          premiumEmblemsProducts
        );

        if (data) {
          layerStateManager.dispatchGetAll(data);
        } else {
          layerStateManager.dispatchGetAll(Emblem.fromJS(defaultEmblem));
        }

        setEmblemEditorState(layerStateManager);
      }
    })();
  }, [emblemEditorState, apiState.EmblemClient, ClientSettings]);

  if (emblemEditorState === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <EmblemEditorContext.Provider value={emblemEditorState}>
      {children}
    </EmblemEditorContext.Provider>
  );
});
