import { ICelestialDetail, IInstallationTypeSettings, IItemTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { Problem, ProblemBuilder, ProblemCategory, ProblemsMaybe } from "../Problem";
import { allWastedProblems } from "./AllWastedProblems";
import { noNodeProblems } from "./NoNodeProblems";
import { outputNodeProblems } from "./OutputNodeProblems";
import { subOptimalOperationLevelProblems } from "./SubOptimalOperationLevelProblems";
import { subOptimalThroughputLimitProblems } from "./SubOptimalThroughputLimitProblems";
import { unconfiguredNodeProblems } from "./UnconfiguredNodeProblems";

export const manufactoryProblemBuilder = new ProblemBuilder(ProblemCategory.Manufactories);

export function manufactoryProblems(itemTypeSettings: IItemTypeSettings, installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const problems: Problem[] = [];

    const celestialsWithManufactory = wrapper.solarSystem.celestials.filter(c => c.manufactory !== undefined);

    if (celestialsWithManufactory.length === 0) {
        return undefined;
    }

    for (let celestial of celestialsWithManufactory) {

        problems.push(...manufactoryProblemsForCelestial(itemTypeSettings, installationTypeSettings, wrapper, celestial));
    }

    return problems;
}

export function manufactoryProblemsForCelestial(itemTypeSettings: IItemTypeSettings, installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper, celestial: ICelestialDetail) {
    const problems: Problem[] = [];

    if (!celestial.manufactory!.isActive) {
        problems.push(manufactoryProblemBuilder.infoProblem("Manufactory Off", `The Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} has been turned off`));
    } else {
        problems.push(...manufactoryProblemBuilder.merge(
            allWastedProblems(itemTypeSettings, wrapper, celestial),
            noNodeProblems(wrapper, celestial),
            unconfiguredNodeProblems(itemTypeSettings, wrapper, celestial),
            outputNodeProblems(itemTypeSettings, wrapper, celestial),
            subOptimalOperationLevelProblems(installationTypeSettings, wrapper, celestial),
            subOptimalThroughputLimitProblems(wrapper, celestial)
        ));

    }

    for (let i = 0; i < problems.length; i++) {
        problems[i] = {
            ...problems[i],
            celestialId: celestial.celestialId
        };
    }

    return problems;
}