import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Celestial, FleetMovementType } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { SplitLayout } from '../../Base/Containers/SplitLayout';
import { SubPanel } from '../../Base/Containers/SubPanel';
import { FieldHolder } from '../../Base/Form/FieldHolder';
import { Select } from '../../Base/Form/Select';
import { CelestialResourceBar } from './CelestialResourceBar';

type Props = {
    celestialId: number | undefined,
    setCelestialId: (celestialId: number) => any,
    celestials: Celestial[],
    promptMovementType?: FleetMovementType | undefined,
    extra?: React.ReactNode
}

export const CelestialSelectOnly = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CelestialTypeSettings) {
        return null;
    }

    return <Select className="is-small" valueChanged={(e) => props.setCelestialId(Number(e))} defaultValue={props.celestialId || ""}
        values={props.celestials.map((c, i) => {
            return {
                value: c.celestialId,
                label: CelestialHelper.fullname(c, worldState.CelestialTypeSettings!)
            };
        })}
        showUnset={{
            value: undefined,
            label: "None"
        }} />;
});

export const CelestialSelect = (props: Props) => {

    const [celestialId, setCelestialId] = React.useState<number | undefined>(props.celestialId);

    function changeCelestialId(celestialId: number) {
        setCelestialId(celestialId);
        props.setCelestialId(celestialId);
    }

    function promptText() {
        if (props.promptMovementType === FleetMovementType.Attack) {
            return "for Siege Damage";
        }
        if (props.promptMovementType === FleetMovementType.Claim) {
            return "for Claim";
        }
        if (props.promptMovementType === FleetMovementType.CommoditySell) {
            return "for Sale of Commodities";
        }
        if (props.promptMovementType === FleetMovementType.Mine) {
            return "for Mining";
        }

        return "";
    }

    function icon() {
        if (props.promptMovementType === undefined) {
            return undefined;
        }

        if (props.promptMovementType === FleetMovementType.Attack) {
            return IconHelper.Combat.Siege;
        }

        return IconHelper.Ships.movementType(props.promptMovementType);
    }

    const text = "Target " + promptText();

    const celestial = props.celestials.find(x => x.celestialId === celestialId);

    return <SubPanel
        heading={{ text: text, icon: icon() }}
    >
        <SplitLayout
            left={
                <FieldHolder label="Celestial">
                    <CelestialSelectOnly {...props} setCelestialId={changeCelestialId} />
                </FieldHolder>
            }
            right={celestial !== undefined && props.promptMovementType === FleetMovementType.Mine &&
                <FieldHolder label="Resources">
                    <CelestialResourceBar celestial={celestial} />
                </FieldHolder>
            }
        />
        {props.extra !== undefined && props.extra}
    </SubPanel>;
};