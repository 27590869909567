import { adminRoutes } from "./Admin";
import { agentRoutes } from "./Agents";
import { appRoutes } from "./App";
import { contractRoutes } from "./Contracts";
import { federationRoutes } from "./Federation";
import { helpRoutes } from "./Help";
import { mapRoutes } from "./Map";
import { marketRoutes } from "./Market";
import { miscRoutes } from "./Misc";
import { notificationsEtcRoutes } from "./NotificationsEtc";
import { personalRoutes } from "./Personal";
import { playerRoutes } from "./Player";
import { politicsRoutes } from "./Politics";
import { premiumRoutes } from "./Premium";
import { solarSystemRoutes } from "./SolarSystem";

export const routes = [

    ...mapRoutes,
    ...solarSystemRoutes,
    ...notificationsEtcRoutes,
    ...marketRoutes,
    ...miscRoutes,
    ...playerRoutes,
    ...federationRoutes,
    ...politicsRoutes,
    ...personalRoutes,
    ...helpRoutes,
    ...contractRoutes,
    ...premiumRoutes,
    ...agentRoutes,
    ...adminRoutes,
    ...appRoutes
];