import { observer } from "mobx-react-lite";
import * as React from "react";
import { AppStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { NavButton } from "../../Components/Base/Buttons/NavButton";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { GameStartBlocker } from "../../Components/FusionShift/GameStartBlocker";
import { IconHelper } from "../../Helpers/IconHelper";
import { login, register } from "../../Navigation/Routing/AppRouteValues";
import { LoginPrompt, RegisterPrompt } from "../Authentication/AuthenticationContent";
import { HypeSection } from "./HypeSection";

export const WelcomeSection = observer(() => {

    const appState = React.useContext(AppStateContext);

    return <>
        <Paragraph>Fusion Shift is a free to play browser based massively multiplayer online space strategy game.</Paragraph>
        <div className="content">
            <ul>
                <li>Play in your browser for free - no app to install or credit card required</li>
                <li>Join a galaxy filled with other players - cooperate and compete to survive and thrive</li>
                <li>The galaxy doesn't stop when you're not logged in - come back often to check on the progress of your empire</li>
                <li>Master tactics and strategy to maintain your power - build, mine, grow, expand, explore, invade, conquer</li>
            </ul>
        </div>
        <GameStartBlocker>
            <Paragraph className="has-text-centered">
                Start building your interstellar empire!
            </Paragraph>
            <ButtonHolder isCentered>
                <NavButton
                    to={register}
                    text="Register"
                    icon={IconHelper.General.Friend}
                    type="action"
                />
                <NavButton
                    to={login}
                    text="Login"
                    icon={IconHelper.General.Confirm}
                    type="action"
                />
            </ButtonHolder>
        </GameStartBlocker>
        <HypeSection />
        {
            !appState.IsLoggedIn && <>
                <hr />
                <RegisterPrompt />
                <LoginPrompt />
            </>
        }
    </>;
});