import { makeAutoObservable } from "mobx";

export class MobileMenuState {

    public IsOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public close() {
        this.IsOpen = false;
    }
}