import React from "react";
import { FleetWrapper } from "../../../Entities/FleetWrapper";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { ProgressBar } from "../../Base/ProgressBar";

type Props = {
    fleetWrapper: FleetWrapper,
    cargo: { [key: string]: number } | undefined,
    className?: string
}

export const FleetCargoCapacityBar = (props: Props) => {

    const items = CollectionHelper.sumOfDictionary(props.cargo);

    return <ProgressBar
        leftIcon={IconHelper.Items.Item}
        className={props.className}
        min={0}
        max={props.fleetWrapper.TotalCargoCapacity}
        value={items}
        warning={0}
        danger={0}
        text={`Cargo: ${ValueFormatter.formatLocaleNumber(items)} / ${ValueFormatter.formatLocaleNumber(props.fleetWrapper.TotalCargoCapacity)}`}
    />;
};