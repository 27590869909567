import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { Icon } from '../../../../../Components/Base/Icon';
import { ProblemCategoryIcon } from '../../../../../Components/FusionShift/Icons/ProblemCategoryIcon';
import { buildSeverityCount, ProblemIconsFromSeverityCount, SeverityCount } from '../../../../../Components/FusionShift/Icons/ProblemIcons';
import { ProblemCategory } from '../../../../../Entities/SolarSystem/Problems/Problem';
import { SolarSystemWrapper } from '../../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { valuesFromEnum } from '../../../../../Helpers/TypeHelper';
import { ProblemTable } from './ProblemTable';
import { Upgrades } from './Upgrades';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type AdvisorTab = ProblemCategory | "Upgrades";

type BaseHeaderProps = {
    isSelected: boolean,
    onSelect: () => any
}

type HeaderProps = BaseHeaderProps & {
    children: React.ReactNode
}

type ProblemHeaderProps = BaseHeaderProps & {
    category: ProblemCategory,
    severityCount: SeverityCount
}

export const AdvisorHeader = (props: HeaderProps) => {

    return <li className={`${props.isSelected ? "is-active" : ""} is-clickable`}>
        <a onClick={() => props.onSelect()} >
            {props.children}
        </a>
    </li>;
};

export const ProblemHeader = (props: ProblemHeaderProps) => {

    return <AdvisorHeader {...props}>
        <span>
            <ProblemCategoryIcon category={props.category} />
            <span className="is-hidden-touch">
                {ProblemCategory[props.category]}
            </span>
        </span>
        <span>
            <ProblemIconsFromSeverityCount severityCount={props.severityCount} />
        </span>
    </AdvisorHeader>;
};

export const AdvisorControl = observer((props: Props) => {

    const [tab, setTab] = React.useState<AdvisorTab | undefined>(props.solarSystemWrapper.upgrades.length > 0 ? "Upgrades" : undefined);

    const hasUpgrades = props.solarSystemWrapper.upgrades.length > 0;

    if (props.solarSystemWrapper.problems.length === 0 && !hasUpgrades) {
        return null;
    }

    const groupedProblems = valuesFromEnum(ProblemCategory).map(c => {
        return {
            category: c,
            severityCount: buildSeverityCount(props.solarSystemWrapper.problems, c)
        }
    }).filter(c =>
        c.severityCount[1] + c.severityCount[2] + c.severityCount[3] > 0);


    return <SubPanel heading={{ text: "Advisor", icon: IconHelper.Categories.Advisor }} className="solar-system-advisor">
        <div>
            <div className="tabs">
                <ul>
                    {hasUpgrades && <AdvisorHeader
                        onSelect={() => setTab("Upgrades")}
                        isSelected={tab === "Upgrades"}
                    >
                        <Icon icon={IconHelper.Installations.Upgrade} />
                        <span className="is-hidden-touch">
                            Upgrades
                        </span>
                    </AdvisorHeader>
                    }
                    {groupedProblems.map((g, i) => <ProblemHeader
                        key={`${g.category}_${i}`}
                        {...g}
                        onSelect={() => setTab(g.category)}
                        isSelected={g.category === tab} />
                    )}
                </ul>
            </div>
        </div>
        {tab !== undefined && tab === "Upgrades" && <Upgrades {...props} />}
        {tab !== undefined && tab !== "Upgrades" && <ProblemTable category={tab} problems={props.solarSystemWrapper.problems} />}
    </SubPanel >;
});