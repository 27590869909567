import { FederationIndex } from "../../Views/Federation/FederationIndex";
import { FederationView } from "../../Views/Federation/FederationView";
import { OwnFederationView } from "../../Views/Federation/OwnFederationView";
import { FederationIncomingFleets } from "../../Views/Fleets/FederationIncomingFleets";
import { route } from "./Root";

export const federations = "/federations";
export const federation_incomingFleets = federations + "/incomingfleets";
export const federations_own = federations + "/own";
const federations_own_from_help = "/yourfederation";
export function federation_view(federationId: number | string) {
    return federations + "/" + federationId;
}

export const federationRoutes = [

    route(federations_own, OwnFederationView, "Federation"),
    route(federations_own_from_help, OwnFederationView, "Federation"),
    route(federation_view(":federationId"), FederationView, "Federation"),
    route(federations, FederationIndex, "Federations"),
    route(federation_incomingFleets, FederationIncomingFleets, "Federation Incoming Fleets")
];