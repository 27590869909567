import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext, SolarSystemStateContext } from '../../../../ApplicationState/ContextRoot';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const MarketOrdersRaisedRequired = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!props.mission.MissionType.marketOrdersRaisedRequired || !solarSystemState.SolarSystem) {
        return null;
    }

    const required = props.mission.MissionType.marketOrdersRaisedRequired
    const has = solarSystemState.SolarSystem.marketOrders.length;

    return <ObjectiveRow has={has} requires={required} {...props} >
        {`Raise ${required} market ${ValueFormatter.pluralize("order", required)}`}
    </ObjectiveRow>;
});