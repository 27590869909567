import * as React from "react";
import { ReactNode } from "react";

type Props = {
    isNoWrap?: boolean
    children: ReactNode,
    className?: string,
    childClassName?: string
}

export const TileHolder = (props: Props) => {

    const children = React.Children.toArray(props.children);

    if (!children || children.length === 0) {
        return null;
    }

    return <div className={`tile is-ancestor flex-wrap ${props.className}`}>
        {children.filter(c => c !== null).map((c, i) => {
            return <div key={i} className={`tile is-parent ${props.childClassName && props.childClassName.length > 0 ? props.childClassName : ""}`}>
                <div className={`tile is-child ${props.isNoWrap ? "no-wrap" : ""}`}>
                    {c}
                </div>
            </div>;
        })}
    </div>;
};

TileHolder.defaultProps = {
    isNoWrap: false,
    className: ""
};