import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { mergeProblemMaybes, ProblemsMaybe } from "../Problem";
import { militaryProblemBuilder } from "./MilitaryProblems";

export function agentProblems(wrapper: SolarSystemWrapper): ProblemsMaybe {

    const problems: ProblemsMaybe[] = [];

    for (let agentEffect of wrapper.agentEffects) {

        problems.push(militaryProblemBuilder.agentPercentProblem(agentEffect, "Agent action cost", x => x.agentActionCostModifierPercent, undefined));
        problems.push(militaryProblemBuilder.agentPercentProblem(agentEffect, "Agent capture chance", x => x.agentActionCaptureChanceModifierPercent, "positiveIsGood"));
        problems.push(militaryProblemBuilder.agentPercentProblem(agentEffect, "Agent action success chance", x => x.agentActionSuccessChanceModifierPercent, undefined));
        problems.push(militaryProblemBuilder.agentPercentProblem(agentEffect, "System defence", x => x.solarSystemDefenceModifierPercent, "positiveIsGood"));

        if (agentEffect.agentActionType.radarRangeModification !== undefined && agentEffect.agentActionType.radarRangeModification < 0) {
            problems.push(militaryProblemBuilder.warningProblem("Radar Range Reduced", `Radar range at ${wrapper.solarSystem.name} is being reduced by ${agentEffect.agentActionType.radarRangeModification * agentEffect.strength} by an agent effect`));
        }
    }

    return mergeProblemMaybes(...problems);
}