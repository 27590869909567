import { makeAutoObservable } from "mobx";
import { Emblem, EmblemLayer, IEmblemLayer, InGameProduct, InGameProductCategory, Manifest } from "../../../ApplicationState/ApiClient";
import { ApiState } from "../../../ApplicationState/States/ApiState";
import emblemEditActionReducer, {
  CREATE_NEW_LAYER,
  Direction,
  EmblemEditActions,
  GET_LAYERS_FROM_API,
  IEmblemEditingState,
  MOVE_LAYER,
  REMOVE_LAYER,
  SAVED_EMBLEM,
  UPDATE_CURRENT_LAYER,
  initialState
} from "./EmblemEditActionReducer";

export class EmblemEditorState {

  apiState: ApiState;
  curentLayerIndex = 0;
  manifest: Manifest;
  layerState: IEmblemEditingState;
  premiumEmblemInGameProducts: InGameProduct[];

  constructor(
    apiState: ApiState,
    manifest: Manifest,
    premiumEmblemInGameProducts: InGameProduct[]
  ) {
    this.apiState = apiState;
    this.manifest = manifest;
    this.layerState = initialState;
    this.premiumEmblemInGameProducts = premiumEmblemInGameProducts;

    makeAutoObservable(this);
  }

  get emblem() { return this.layerState.emblem; }
  get emblemLayers() { return this.layerState.emblem?.configuration.layers; }
  get editingLayer() { return this.layerState.emblem !== undefined ? this.layerState.emblem.configuration.layers[this.curentLayerIndex] : undefined; }
  get isDirty() { return this.layerState.isDirty; }

  public setCurrentLayerIndex(curentLayerIndex: number) {
    this.curentLayerIndex = curentLayerIndex;
  }

  public dispatch(action: EmblemEditActions) {
    this.layerState = emblemEditActionReducer(this.layerState, action);
  }

  public dispatchGetAll(emblem: Emblem) {
    this.dispatch({
      type: GET_LAYERS_FROM_API,
      emblem,
    });
  }

  public dispatchAlterLayer(layer: IEmblemLayer, index: number) {
    this.dispatch({
      type: UPDATE_CURRENT_LAYER,
      layer,
      index,
    });
  }

  public dispatchAddLayer(layer: EmblemLayer) {
    this.dispatch({
      type: CREATE_NEW_LAYER,
      layer,
    });

    const newLength = this.layerState.emblem?.configuration?.layers?.length;
    if (newLength) {
      this.setCurrentLayerIndex(newLength - 1);
    }
  }

  public dispatchRemoveLayer(layerIndex: number) {
    const indexToUse = this.layerState.emblem?.configuration?.layers?.length;
    const shouldReduceIndex = this.curentLayerIndex === (indexToUse ?? 0) - 1;

    this.dispatch({
      type: REMOVE_LAYER,
      layerIndex,
    });

    if (shouldReduceIndex) {
      const newIndex = indexToUse !== undefined ? indexToUse - 2 : 0;
      this.setCurrentLayerIndex(newIndex);
    }
  }

  public dispatchEmblemSaved() {
    this.dispatch({
      type: SAVED_EMBLEM,
    });
  }

  public dispatchMoveLayer(index: number, direction: Direction) {
    this.dispatch({
      type: MOVE_LAYER,
      direction,
      index,
    });
  }

  public async refreshPremiumEmblems() {
    await this.apiState.PremiumClient.getInGameProductsByCategory(InGameProductCategory.Emblem);
  }
}