import { CombatReportIntel } from "../../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { ItemsControl } from "../../../../Components/FusionShift/Items/ItemsControl";
import { CollectionHelper } from "../../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";

type Props = {
    intel: CombatReportIntel
}

export const CombatReportSeenLoot = (props: Props) => {

    if (!CollectionHelper.isAnyQuantityInDictionary(props.intel.items)) {
        return null;
    }

    return <SubPanel heading={{ text: "Seen Items", icon: IconHelper.Combat.Recon }}>
        <ItemsControl items={props.intel.items} expandDetails />
    </SubPanel>;
};
