import { IFleet, IMapApocalypseShot } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../../../Entities/SolarSystem/SolarSystemWrapper";
import { FleetHelper } from "../../../../Helpers/FleetHelper";
import { CanvasHelper } from "../CanvasHelper";
import { RenderBag } from "../Entities";

const systemColours = {
    self: "#56c3e3",
    friend: "#8fc95d",
    neutral: "white",
    enemy: "#e35656"
};

const fleetColours = {
    self: "#56c3e3",
    friend: "#8fc95d",
    neutral: "#808080",
    enemy: "#e35656"
};

const apocalpyseShotColour = "#feae00";

export class FleetRenderer {

    public numRendered = 0;
    public renders = 0;

    public transform: DOMMatrix = new DOMMatrix();

    constructor(public ctx: CanvasRenderingContext2D) {
    }

    public render(render: RenderBag) {

        this.renders++;
        this.numRendered = 0;

        const transform = this.ctx.getTransform();

        for (const solarSystem of render.data.solarSystems) {

            this.renderRadar(render, solarSystem);

            if (render.preferences.showFleets) {
                for (const fleet of solarSystem.fleets.allFleets) {
                    this.renderFleet(transform, render, fleet);
                }

                for (const apocalpyseShot of render.data.map.apocalypseShots) {
                    this.renderApocalpyseShot(transform, render, apocalpyseShot);
                }
            }
        }
    }

    private renderRadar(render: RenderBag, solarSystem: SolarSystemWrapper) {

        if (solarSystem.solarSystem.radarRange === undefined || solarSystem.solarSystem.radarRange === null) {
            return;
        }

        const { x, y } = render.perspective.transform(solarSystem.solarSystem.x, solarSystem.solarSystem.y, render.perspective.parsec / 2);

        CanvasHelper.strokeCircle(this.ctx, x, y, solarSystem.solarSystem.radarRange * render.perspective.parsec, systemColours.self);
    }

    private renderFleet(
        transform: DOMMatrix,
        render: RenderBag,
        fleet: IFleet) {

        if (fleet.sourceX === undefined ||
            fleet.sourceY === undefined ||
            fleet.targetX === undefined ||
            fleet.targetY === undefined) {
            return;
        }

        const isOwned = fleet.ownerPlayerId === render.data.player.playerId;
        const isAllied = render.data.player.federation !== undefined && fleet.owner?.federation === render.data.player.federation.federationId;

        const isHostileMovementType = FleetHelper.isHostileMovementType(fleet.movementType);

        const colour = isOwned ? fleetColours.self :
            isAllied ? fleetColours.friend :
                isHostileMovementType ? fleetColours.enemy :
                    fleetColours.neutral;

        const source = render.perspective.transform(fleet.sourceX, fleet.sourceY);
        const target = render.perspective.transform(fleet.targetX, fleet.targetY);

        const hr = render.perspective.parsec / 2;

        CanvasHelper.strokeLine(this.ctx, source.x - hr, source.y - hr, target.x - hr, target.y - hr, colour);
    }

    public renderApocalpyseShot(transform: DOMMatrix, render: RenderBag, apocalpyseShot: IMapApocalypseShot) {

        const source = render.perspective.transform(apocalpyseShot.source.x, apocalpyseShot.source.y);
        const target = render.perspective.transform(apocalpyseShot.target.x, apocalpyseShot.target.y);

        const hr = render.perspective.parsec / 2;

        CanvasHelper.strokeLine(this.ctx, source.x - hr, source.y - hr, target.x - hr, target.y - hr, apocalpyseShotColour);
    }
}