import { observer } from 'mobx-react-lite';
import { Icon } from '../../../Components/Base/Icon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { home } from '../../Routing/AppRouteValues';
import { help } from '../../Routing/Help';
import { MenuLink } from '../MenuState';
import { SearchButton } from './SearchButton';

export const BurgerButtons = observer(() => {

    return <div className="burger-holder">
        <SearchButton className="is-hidden-desktop" />
        <MenuLink role="button" className={"navbar-burger"} to={help}>
            <Icon icon={IconHelper.General.Help} />
        </MenuLink>
        <MenuLink role="button" className={"navbar-burger"} to={home} end>
            <Icon icon={IconHelper.General.InlineMenu} />
        </MenuLink>
    </div>;
});