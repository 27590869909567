import * as React from 'react';
import { UnauthenticatedInitialLoad } from '../../Navigation/UnauthenticatedNavBar';

type Props = {
    children: React.ReactNode
}

export const AuthenticationWrapper = (props: Props) => {

    return <>
        <UnauthenticatedInitialLoad />
        <div className="authentication">
            {props.children}
        </div>
    </>;
}