import { observer } from "mobx-react-lite";
import React from "react";
import { SectorType } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext } from "../../../ApplicationState/ContextRoot";
import { H4 } from "../../Base/Text/H";
import { RichTextArray } from "../RichTextArray";
import { SectorTypeBonusList } from "./SectorTypeBonusList";

type Props = {
    sectorType: SectorType
}

export const SectorTypeDescription = observer((props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const description = descriptionState.Sectors?.find(x => x.typeName === props.sectorType.typeName);

    return <>
        <RichTextArray text={description?.description} />
        <H4>Maximum Bonuses</H4>
        <SectorTypeBonusList bonus={1} sectorType={props.sectorType} assignedPopulation={1} />
    </>;
});