
import { observer } from "mobx-react-lite";
import React from "react";
import { IPlayerMinimal } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { messages_send } from "../../../Navigation/Routing/NotificationsEtc";
import { ButtonLink } from "../../Base/Buttons/ButtonLink";

type Props = {
    player: IPlayerMinimal | undefined,
    subject?: string | undefined
    overrideText?: string | undefined
}

export const SendMessageButton = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player || !props.player || playerState.Player.playerId === props.player.playerId) {
        return null;
    }

    return <ButtonLink
        icon={IconHelper.General.Message}
        to={`${messages_send}?playerId=${props.player.playerId}&subject=${props.subject !== undefined ? encodeURIComponent(props.subject) : ""}`}
        text={props.overrideText !== undefined ? props.overrideText : "Send message"} />;
});