import * as React from 'react';
import { Installation, InstallationType, ISolarSystemDetail, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { Paragraph } from '../../../../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../../../../Helpers/IconHelper';

type Props = {
    solarSystem: ISolarSystemDetail,
    installation: Installation,
    installationType: InstallationType,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const ContinualUpgrade = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    if (!props.installationType.canContinuallyUpgrade) {
        return null;
    }

    function toggle() {
        return apiState.InstallationClient.setContinuallyUpgrade(props.solarSystem.solarSystemId, props.installation.installationId, !props.installation.continuallyUpgrade).then(props.reloadCallback);
    }

    return <Paragraph>
        Continual upgrade is {props.installation.continuallyUpgrade ? "enabled" : "disabled"}.
        <Button type="action" icon={IconHelper.General.onOrOff(!props.installation.continuallyUpgrade)} text={!props.installation.continuallyUpgrade ? "Enable" : "Disable"} action={toggle} isPulledRight />
    </Paragraph>;
};