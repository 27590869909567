import * as React from 'react';
import { AccoladeType } from '../../ApplicationState/ApiClient';
import { AccoladeTypes } from '../../Blobs/Descriptions/AccoladeTypes';
import { Paragraph } from '../../Components/Base/Text/Paragraph';

type Props = {
    accoladeType: AccoladeType
}

export const AccoladeTypeDescription = (props: Props) => {

    const key = AccoladeType[props.accoladeType];

    if (!(key in AccoladeTypes)) {
        return null;
    }

    return <Paragraph>
        {AccoladeTypes[key]}
    </Paragraph>;
};