import { IItemsDetail, ManufactoryNode, SchematicCategory, SchematicType } from "../ApplicationState/ApiClient";

export class ManufactoryHelper {

    public static isValidOutput(schematicType: SchematicType | undefined) {
        return schematicType && (schematicType.category === SchematicCategory.Commodity || schematicType.category === SchematicCategory.Component);
    }

    public static sumNodeEfficiency(itemsDetail: IItemsDetail | undefined, celestialId: number, nodes: ManufactoryNode[] | undefined): number {
        if (!nodes || nodes.length === 0 || !itemsDetail) {
            return 0;
        }

        let optimal = 0;
        let actual = 0;
        for (let n of nodes) {
            if (n.optimalThroughput > 0) {
                actual += this.actualThroughput(itemsDetail, celestialId, n.manufactoryNodeId);
                optimal += n.optimalThroughput;
            }
        }

        return optimal > 0 ? actual / optimal : 0;
    }

    public static actualThroughput(itemsDetail: IItemsDetail | undefined, celestialId: number, nodeId: string) {

        return itemsDetail !== undefined &&
            celestialId in itemsDetail.manufactories.productionByCelestialId &&
            nodeId in itemsDetail.manufactories.productionByCelestialId[celestialId].nodeActualThroughputs
            ? itemsDetail.manufactories.productionByCelestialId[celestialId].nodeActualThroughputs[nodeId]
            : 0;
    }
}