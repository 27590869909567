import { ISimulatedFleet, ISimulatedSolarSystem } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../../Helpers/IconHelper';
import { defaultDefendingFleet, defaultDefendingSolarSystem } from '../Data';

type Props = {
    setDefendingSolarSystem: (defender: ISimulatedSolarSystem) => any,
    setDefendingFleet: (defender: ISimulatedFleet) => any
}

export const ChooseDefenderType = (props: Props) => {

    return <SubPanel heading={{ text: "Defender Type", icon: IconHelper.Combat.Defence }}>
        <SubPanel
            heading={{ text: "Fleet", icon: IconHelper.Combat.Intercept }}
            footerContent={<Button type="nav" text="Choose" icon={IconHelper.General.Confirm} action={() => { props.setDefendingFleet(defaultDefendingFleet) }} isPulledRight />}
        >
            <Paragraph>
                Simulated combat against a fleet. This simulates an interception type combat.
            </Paragraph>
        </SubPanel>
        <SubPanel
            heading={{ text: "Solar System", icon: IconHelper.SolarSystems.Colony }}
            footerContent={<Button type="nav" text="Choose" icon={IconHelper.General.Confirm} action={() => { props.setDefendingSolarSystem(defaultDefendingSolarSystem) }} isPulledRight />}
        >
            <Paragraph>
                Simulate an attack against a solar system. You can specify celestials, installations, reinforcements and other things which may be present at a solar system. Siege damage can also be simulated.
            </Paragraph>
        </SubPanel>
    </SubPanel>;
};