import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CelestialDetail, ICelestialTypeSettings, IInstallation, IInstallationTypeSettings, InstallationBuildQueueItem, InstallationBuildQueueType, InstallationType, ISolarSystemDetail, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { ThumbnailSlate } from "../../../../../Components/Base/Containers/Slate";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../../../Components/Base/Containers/Table";
import { ProgressBar } from "../../../../../Components/Base/ProgressBar";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { handleOnClick } from "../../../../../Components/Base/Util";
import { ConstructionIcon, DeconstructIcon } from "../../../../../Components/FusionShift/Celestials/CelestialIcons";
import { CelestialImageBase } from "../../../../../Components/FusionShift/Celestials/CelestialImage";
import { DateIcon } from "../../../../../Components/FusionShift/Icons/DateIcon";
import { InstallationBuildQueueItemIcon } from "../../../../../Components/FusionShift/Icons/InstallationBuildQueueItemIcon";
import { InstallationImage } from "../../../../../Components/FusionShift/Images/InstallationImage";
import { TimeSpan } from "../../../../../Entities/TimeSpan";
import { CelestialHelper } from "../../../../../Helpers/CelestialHelper";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";
import { celestial_installation } from "../../../../../Navigation/Routing/SolarSystem";

type QueueItem = {
    queueItem: InstallationBuildQueueItem,
    celestial: CelestialDetail,
    installation?: IInstallation,
    installationType: InstallationType
}

type Props = {
    solarSystem: ISolarSystemDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type CurrentProps = {
    queueItem: QueueItem,
    removeFromQueue: (installationBuildQueueItemId: number) => any,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings
}

const CurrentQueueItem = (props: CurrentProps) => {

    const appState = React.useContext(AppStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [barData, setBarData] = React.useState(getBarData());

    function getBarData() {

        const level = props.queueItem.queueItem.type === InstallationBuildQueueType.Deconstruct ? props.queueItem.installation?.level ?? 1 : props.queueItem.queueItem.targetLevel;
        const cost = props.queueItem.installationType.costPerLevel[level];

        const costDuration = TimeSpan.fromString(cost.duration);

        const start = props.queueItem.queueItem.completedDate.getTime() - (costDuration.totalMilliseconds * (1 + (worldState.Effects?.installationCostModification ?? 0)));
        const end = props.queueItem.queueItem.completedDate.getTime();
        const remaining = new Date().getTime() - start;

        return { start, end, remaining };
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            setBarData(getBarData);
        }, 250);
        return () => clearInterval(interval);
    }, [props.queueItem]);

    function createDescription() {

        if (props.queueItem.queueItem.type === InstallationBuildQueueType.ChangeCapital) {
            return "The capital is being relocated";
        }

        const installationDescription = `${ValueFormatter.aOrAn(props.queueItem.installationType.name, true)} ${props.queueItem.installationType.name}`;
        const verb = props.queueItem.queueItem.type === InstallationBuildQueueType.Deconstruct ? "deconstructed" :
            props.queueItem.queueItem.targetLevel === 1 ? "built" : `upgraded to level ${props.queueItem.queueItem.targetLevel}`;

        const celestialDescrption = CelestialHelper.fullname(props.queueItem.celestial, props.celestialTypeSettings);

        return `${installationDescription} is being ${verb} at ${celestialDescrption}`;
    }

    const to = celestial_installation(props.queueItem.celestial.celestialId, props.queueItem.queueItem.installationId);

    const description = createDescription();

    const isComplete = props.queueItem.queueItem.completedDate.getTime() < new Date().getTime();

    return <ThumbnailSlate
        title="Building"
        className="current-build-item"
        onClick={() => appState.navigate(to)}
        footerContent={
            <Button
                isDisabled={isComplete}
                type="danger"
                text="Cancel"
                isPulledRight
                icon={IconHelper.General.Delete}
                action={() => props.removeFromQueue(props.queueItem.queueItem.installationBuildQueueItemId)}
                confirm={`Are you sure you want to remove ${props.queueItem.installationType ? props.queueItem.installationType.name + " " : ""}from the queue?`}
            />
        }
        thumbnail={
            <InstallationImage
                size="medium"
                installationType={props.queueItem.installationType}
                celestialId={props.queueItem.celestial.celestialId}
                installation={{ damage: 0, installationId: props.queueItem.queueItem.installationId, level: props.queueItem.installation?.level }}
                isConstructing
            />
        }
    >
        <>
            <ProgressBar
                text={<DateIcon date={props.queueItem.queueItem.completedDate} ticksDown isPulledRight className="build-queue-timer" expiredContent="Complete" />}
                leftIcon={props.queueItem.queueItem.type === InstallationBuildQueueType.Deconstruct ? <DeconstructIcon /> : <ConstructionIcon />}
                value={barData.remaining}
                min={0}
                max={barData.end - barData.start}
                danger={0}
                warning={0}
            />
            <br />
            <Paragraph>
                {description}
            </Paragraph>
        </>
    </ThumbnailSlate>;
}

export const InstallationBuildQueue = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    if (!worldState.InstallationTypeSettings || !worldState.CelestialTypeSettings || !worldState.GameSettings) {
        return null;
    }

    function removeFromQueue(installationBuildQueueItemId: number) {
        return apiState.InstallationClient.removeFromInstallationBuildQueue(props.solarSystem.solarSystemId, installationBuildQueueItemId).then(solarSystem => {
            if (solarSystem) {
                props.reloadCallback(solarSystem);
            }
        });
    }

    const queueToRender: QueueItem[] = (props.solarSystem.installationBuildQueue ? props.solarSystem.installationBuildQueue.map(q => {
        const celestial = props.solarSystem.celestials!.find(c => c.celestialId === q.celestialId)!;
        const installationType = worldState.InstallationTypeSettings!.data![q.typeName!];

        return {
            queueItem: q,
            celestial: celestial,
            installationType: installationType,
            installation: celestial.installations?.find(x => x.installationId === q.installationId)
        };
    }) : [])
        .sort((a, b) => a.queueItem.completedDate < b.queueItem.completedDate ? -1 : 1);

    if (queueToRender.length === 0) {
        return <Paragraph type="prompt">Build queue is empty. Choose a celestial and then upgrade an existing installation or build in an empty slot.</Paragraph>;
    }

    return <>
        <CurrentQueueItem
            key={queueToRender[0].queueItem.installationBuildQueueItemId}
            queueItem={queueToRender[0]}
            removeFromQueue={removeFromQueue}
            installationTypeSettings={worldState.InstallationTypeSettings}
            celestialTypeSettings={worldState.CelestialTypeSettings}
        />
        <SubPanel heading={"In Queue"} isUnpadded={queueToRender.length > 1} className="installation-queue-others">
            {queueToRender.length > 1 &&
                <Table isHoverable isFullWidth>
                    {queueToRender.map((q, i) => {
                        if (i === 0) {
                            return null;
                        }

                        const rowClassName = classNames("is-clickable",
                            {
                                "has-highlight-bg-success": q.queueItem.isNew
                            });

                        return <tr key={q.queueItem.installationBuildQueueItemId} className={rowClassName} onClick={e => handleOnClick(e, () => appState.navigate(celestial_installation(q.celestial.celestialId, q.queueItem.installationId)))}>
                            <td>
                                {!!q.celestial && <span className="subtitle celestial-name">{q.celestial.name} </span>}
                            </td>
                            <td className="is-relative is-hidden-mobile">
                                {!!q.celestial && <CelestialImageBase variationSource={q.celestial.celestialId.toString()} celestialTypeName={q.celestial.celestialTypeName} />}
                            </td>
                            <td>
                                <InstallationBuildQueueItemIcon installationBuildQueueItem={q.queueItem} />
                                <DateIcon date={q.queueItem.completedDate} ticksDown />
                            </td>
                            <td>
                                <InstallationImage
                                    size="tiny"
                                    installationType={q.installationType}
                                    celestialId={q.celestial.celestialId}
                                    installation={{ damage: 0, installationId: q.queueItem.installationId, level: q.installation?.level }}
                                />
                            </td>
                            <td>
                                {!!q.installationType && q.installationType.name}{" "}
                                {`[${q.queueItem.targetLevel}]`}
                            </td>
                            <td>
                                <Button type="danger" className="is-small" icon={IconHelper.Installations.Deconstruct} confirm={`Are you sure you want to remove ${q.installationType ? q.installationType.name + " " : ""}from the queue?`} action={() => removeFromQueue(q.queueItem.installationBuildQueueItemId)} />
                            </td>
                        </tr>;
                    })}
                </Table>
            }
            {queueToRender.length === 1 && <Paragraph>No other items in queue. Up to {worldState.GameSettings.installation.buildQueueCapacity - 1} more {ValueFormatter.pluralize("item", worldState.GameSettings.installation.buildQueueCapacity - 1)} can be queued.</Paragraph>}
        </SubPanel>
    </>;
});
