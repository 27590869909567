import { observer } from 'mobx-react-lite';
import { IItemsDetail, IItemTypeSettings } from '../../../ApplicationState/ApiClient';
import { Icon } from '../../../Components/Base/Icon';
import { DateText } from '../../../Components/Base/Text/DateText';
import { TextAndIcon } from '../../../Components/Base/Text/TextAndIcon';
import { ResourceIcon } from '../../../Components/FusionShift/Icons/Items/ResourceIcon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    items: { [key: string]: number } | undefined,
    itemsDetail: IItemsDetail | undefined,
    itemTypeSettings: IItemTypeSettings,
    className?: string | undefined,
    additionalUpkeep?: number,
    additionalUpkeepLabel?: string,
    additionalUpkeepIcon?: string,
}

export const UpkeepSummary = observer((props: Props) => {

    if (props.itemsDetail === undefined) {
        return null;
    }

    const itemTypeName = Object.keys(props.itemsDetail.upkeep.fromShips).find(x => true);

    if (itemTypeName === undefined) {
        return null;
    }

    const itemType = props.itemTypeSettings.data[itemTypeName];

    const fromShips = props.itemsDetail.upkeep.fromShips[itemTypeName];
    const hangarSpace = props.itemsDetail.upkeep.negatedByHangarSpace[itemTypeName];

    const fromShipsAndAdditional = fromShips + (props.additionalUpkeep ?? 0);

    const shipUse = itemTypeName in props.itemsDetail.upkeep.netTotal ? props.itemsDetail.upkeep.netTotal[itemTypeName] : 0;
    const rawIncome = itemTypeName in props.itemsDetail.netFinal ? props.itemsDetail.netFinal[itemTypeName] : 0;
    // Remove the actual amount used by ships from the final amount 
    const income = rawIncome + shipUse;
    const totalAvailable = hangarSpace + income;

    const using = fromShipsAndAdditional;
    const spareHangarSpace = Math.max(hangarSpace - using, 0);
    const netIncome = spareHangarSpace > 0 ? income : income - (using - hangarSpace);

    const has = props.items !== undefined ? props.items[itemTypeName] ?? 0 : 0;

    const hours = netIncome <= 0 ? has / Math.abs(netIncome) : undefined;
    const expiryDate = hours !== undefined && hours !== Infinity ? new Date() : undefined;

    if (hours && expiryDate) {
        expiryDate.setTime(expiryDate.getTime() + (hours * 60 * 60 * 1000));
    }

    const type = using > totalAvailable ? "danger" :
        using > hangarSpace ? "warning" : "";

    const barMax = using > totalAvailable ? using : totalAvailable;

    function widthFor(value: number, total: number) {
        let percent = total === 0 ? 0 : value / total;

        if (percent >= 1) {
            percent = 1;
        }

        return {
            width: `${percent * 100}%`
        }
    }

    return <div className={`upkeep-control ${props.className ?? ""}`}>
        <h3 className="subtitle is-5">
            <TextAndIcon
                text={props.additionalUpkeepLabel !== undefined ? props.additionalUpkeepLabel : "Upkeep"}
                icon={IconHelper.Items.itemType(itemType)}
            />
        </h3>
        <div className="upkeep-bar">
            <div className="label-bar">
                {hangarSpace > 0 &&
                    <div className={`label-start ${type}`}>
                        <span className="label-text">
                            {ValueFormatter.toShorthand(hangarSpace)} Hangar Space
                        </span>
                    </div>
                }
                {income > 0 &&
                    <div className="label-end">
                        <span className="label-text">
                            {ValueFormatter.toShorthand(income)} Income
                        </span>
                    </div>
                }
            </div>
            <div className="available-bar">
                {hangarSpace > 0 &&
                    <div className={`available hangar-space ${type}`} style={widthFor(hangarSpace, barMax)}>
                        <span className="bar-icon">
                            <Icon icon={IconHelper.Ships.Grounded} />
                        </span>
                    </div>
                }
                {income > 0 &&
                    <div className="available income" style={widthFor(income, barMax)} >
                        <span className="bar-icon">
                            <ResourceIcon resourceType={itemType} />
                        </span>
                    </div>
                }
            </div>
            <div className={`using-bar`}>
                {using > 0 &&
                    <div className={`using ships ${type}`} style={widthFor(using, barMax)}>
                        <span className="bar-icon">
                            <Icon icon={IconHelper.Ships.Ship} />
                        </span>
                    </div>
                }
                {spareHangarSpace > 0 &&
                    <div className="using spare-hangar-space" style={widthFor(spareHangarSpace, barMax)} >
                        <span className="bar-icon">
                            <Icon icon={IconHelper.Ships.Grounded} />
                        </span>
                    </div>
                }
                {netIncome > 0 &&
                    <div className="using net-income" style={widthFor(netIncome, barMax)} >
                        <span className="bar-icon">
                            <ResourceIcon resourceType={itemType} />
                        </span>
                    </div>
                }
            </div>
            <div className="label-bar">
                {using > 0 &&
                    <div className={`label-start ${type}`}>
                        <span className="label-text">
                            {ValueFormatter.toShorthand(using)} Ships
                        </span>
                    </div>
                }
                {spareHangarSpace > 0 &&
                    <div className="label-middle">
                        <span className="label-text">
                            {ValueFormatter.toShorthand(spareHangarSpace)} Unused <span className="is-hidden-mobile">Hangar Space</span>
                        </span>
                    </div>
                }
                {netIncome > 0 &&
                    <div className="label-end"  >
                        <span className="label-text">
                            {ValueFormatter.toShorthand(netIncome)} Net
                        </span>
                    </div>
                }
                {(expiryDate !== undefined || hours === 0) &&
                    <div className="label-end">
                        <span className={`label-text hours has-text-danger`}>
                            {hours !== undefined && hours > 0 &&
                                <span>
                                    Consumed <DateText date={expiryDate} />
                                </span>
                            }
                            {(hours === undefined || hours <= 0) &&
                                <span>
                                    Fully Consumed
                                </span>
                            }
                        </span>
                    </div>}
            </div>
        </div>
    </div>;
});