import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ItemCategory } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext, MissionLogStateContext, SolarSystemStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ItemsControl } from '../../../Components/FusionShift/Items/ItemsControl';
import { ItemsWithCapacityControl } from '../../../Components/FusionShift/Items/ItemsWithCapacityControl';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { RichTextArray } from '../../../Components/FusionShift/RichTextArray';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ItemsHelper } from '../../../Helpers/ItemsHelper';
import { missions } from '../../../Navigation/Routing/Help';
import { MissionWrapper } from '../MissionWrapper';

type Props = {
    mission: MissionWrapper,
    redirectOnComplete?: boolean
}

export const Complete = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const apiState = React.useContext(ApiStateContext);
    const missionLogState = React.useContext(MissionLogStateContext);
    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    function acceptReward() {
        return apiState.PlayerMissionLogClient.acceptReward(props.mission.MissionType.typeName, solarSystemState.SolarSystem!.solarSystemId).then(response => {
            if (response) {
                if (response.solarSystem && solarSystemState.SolarSystem && response.solarSystem.solarSystemId === solarSystemState.SolarSystem.solarSystemId) {
                    solarSystemState.loadSolarSystem(response.solarSystem);
                }
                if (response.playerMissionLog) {
                    missionLogState.loadMissionLog(response.playerMissionLog);
                }
                if (props.redirectOnComplete) {
                    appState.navigate(missions);
                }
                appState.scrollToTop();
            }
        });
    }

    if (!solarSystemState.SolarSystem || !worldState.ItemTypeSettings) {
        return null;
    }

    const canClaim = props.mission.Status === "Reward Pending";

    const relevantItems: { [key: string]: number } = {};

    let anyExceeds = false;

    const solarSystem = solarSystemState.SolarSystem;

    if (canClaim && solarSystem.items && solarSystem.itemsDetail && props.mission.MissionType.itemReward) {

        const solarSystemItems = ItemsHelper.splitItems(solarSystem.items ?? {}, worldState.ItemTypeSettings.data);

        let commodities = (solarSystem.itemsDetail?.otherCapacities.Commodity ?? 0) - CollectionHelper.sumOfDictionary(solarSystemItems.commodities);
        let rawResources = (solarSystem.itemsDetail?.otherCapacities.RawResource ?? 0) - CollectionHelper.sumOfDictionary(solarSystemItems.rawResource);
        let components = (solarSystem.itemsDetail?.otherCapacities.Component ?? 0) - CollectionHelper.sumOfDictionary(solarSystemItems.components);

        for (const rewardItem of Object.keys(props.mission.MissionType.itemReward)) {

            const rewardQuantity = props.mission.MissionType.itemReward![rewardItem];

            // Handle resources on a per item basis
            if (rewardItem in solarSystem.itemsDetail.resourceCapacities) {

                const valAfterReward = (solarSystem!.items![rewardItem] ?? 0) + rewardQuantity;

                relevantItems[rewardItem] = valAfterReward;

                const capacity = solarSystem.itemsDetail?.resourceCapacities![rewardItem];

                if (valAfterReward > capacity) {
                    anyExceeds = true;
                }
            }
            else {
                // Otherwise handle the shared capacity of the item category
                const item = worldState.ItemTypeSettings.data[rewardItem];

                switch (item.category) {
                    case ItemCategory.Commodity:
                        commodities -= rewardQuantity;
                        break;
                    case ItemCategory.RawResource:
                        rawResources -= rewardQuantity;
                        break;
                    case components:
                        components -= rewardQuantity;
                        break;
                }
            }
        }

        anyExceeds = anyExceeds || commodities < 0 || rawResources < 0 || components < 0;
    }

    const title = props.mission.IsCompleteOrRewardPending ? { text: "Mission Complete", icon: IconHelper.General.Confirm } : { text: "Rewards", icon: IconHelper.Missions.Reward };

    const hasReward = Object.keys(relevantItems).length > 0 || props.mission.MissionType.creditsReward > 0;

    return <SubPanel heading={title}
        footerContent={
            canClaim &&
            <Button
                isPulledRight
                confirm={anyExceeds ? `Claiming the reward at ${solarSystem.name} will exceed storage capacity. Are you sure you want to claim the reward here?` : undefined}
                type={anyExceeds ? "warning" : "action"}
                icon={IconHelper.General.Confirm}
                action={acceptReward}
                text="Claim Reward"
                forceClip="bottom-right"
            />
        }
    >
        {props.mission.IsCompleteOrRewardPending && <RichTextArray text={props.mission.MissionType.completedText} />}
        <div className="is-clearfix">
            {props.mission.IsCompleteOrRewardPending && "Reward: "}
            <ItemsControl items={props.mission.MissionType.itemReward} credits={props.mission.MissionType.creditsReward} expandDetails />
            {canClaim &&
                <div className="is-pulled-right">
                    <Paragraph style={{ textAlign: "right" }} >Accept reward in <SolarSystemLink solarSystem={solarSystemState.SolarSystem} hideActionIcons /></Paragraph>
                    {!!hasReward &&
                        <div>
                            <span className="is-pulled-right">
                                <ItemsWithCapacityControl
                                    resourceCapacities={solarSystemState.SolarSystem.itemsDetail!.resourceCapacities}
                                    otherCapacities={solarSystemState.SolarSystem.itemsDetail!.otherCapacities}
                                    items={relevantItems}
                                />
                            </span>
                        </div>
                    }

                </div>
            }
            {props.mission.Status === "Complete" &&
                <Paragraph type="prompt" className="is-pulled-right">
                    Reward has already been claimed
                </Paragraph>
            }
        </div>
    </SubPanel>;
});