import React from "react";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    amount: number
}

export const PremiumIcon = (props: Props) => {

    return <span>
        <Icon icon={IconHelper.Premium.Currency} />
        {props.amount}
    </span>;
};