import { observer } from "mobx-react-lite";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { InstallationCategory } from "../../../../ApplicationState/ApiClient";
import { PlayerStateContext, SolarSystemStateContext, WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { TabMenu } from "../../../../Components/Base/Containers/TabMenu";
import { LoadingSpinner } from "../../../../Components/Base/Loading/LoadingSpinner";
import { InstallationCategoryDescription } from "../../../../Components/FusionShift/Descriptions/InstallationCategoryDescription";
import { InstallationCategoryIcon } from "../../../../Components/FusionShift/Icons/InstallationCategoryIcon";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { UrlHelper } from "../../../../Helpers/UrlHelper";
import { help_page } from "../../../../Navigation/Routing/Help";
import { TypeDataBaseView } from "../TypeDataBaseView";
import { InstallationTypeControl } from "./InstallationTypeControl";

const InstallationTypesContent = observer(() => {

    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [category, setCategory] = React.useState<InstallationCategory | undefined>(undefined);
    const [availableCategories, setAvailableCategories] = React.useState<InstallationCategory[] | undefined>(undefined);

    const location = useLocation();

    if (!worldState.InstallationTypeSettings || !worldState.ItemTypeSettings || !playerState.Player) {
        return <LoadingSpinner />;
    }

    const installationTypeToFind = UrlHelper.tryGetValueFromSearch(location, "installationTypeName");

    let categoriesToUse: InstallationCategory[] = [];
    const allInstallations = Object.keys(worldState.InstallationTypeSettings.data).map(k => {
        const installationType = worldState.InstallationTypeSettings!.data![k];

        if (!category && installationType.typeName === installationTypeToFind) {
            setCategory(installationType.category);
        }

        if (!availableCategories && !categoriesToUse.includes(installationType.category)) {
            categoriesToUse.push(installationType.category);
        }

        return installationType;
    });

    if (!availableCategories && categoriesToUse.length > 0) {
        setAvailableCategories(categoriesToUse);
        if ((!category && !installationTypeToFind) || (category && !categoriesToUse.includes(category))) {
            setCategory(categoriesToUse[0]);
        }
    }

    const installations = allInstallations.filter(i => i.category === category)
        .filter(x => x.researchPointsRequired === undefined || x.researchPointsRequired === null || ((playerState.Player?.federation?.researchPoints ?? 0) >= x.researchPointsRequired))
        .sort(((a, b) => a.order > b.order ? 1 : -1));

    return <>
        <TabMenu
            active={category}
            changeTab={t => setCategory(t)}
            tabs={availableCategories ? availableCategories.map(c => {
                return {
                    id: c,
                    title: InstallationCategory[c],
                    icons: <InstallationCategoryIcon installationCategory={c} />,
                    isAvailable: true
                };
            }) : []}
        />
        {category &&
            <InstallationCategoryDescription installationCategory={category} />
        }
        {installations.map(i => <InstallationTypeControl
            key={i.typeName}
            solarSystem={solarSystemState.SolarSystem}
            installationType={i}
            installationTypeSettings={worldState.InstallationTypeSettings!}
            itemTypeSettings={worldState.ItemTypeSettings!}
            gameSettings={worldState.GameSettings!}
            factionTypeName={playerState.Player!.factionTypeName}
        />)}
    </>;
});

export const InstallationTypesView = () => {
    return <TypeDataBaseView title="Installations" icon={IconHelper.Installations.Installation}
        links={[
            { icon: IconHelper.Installations.Installation, to: help_page("installations"), text: "Installations" }
        ]}>
        <InstallationTypesContent />
    </TypeDataBaseView>
};