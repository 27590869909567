import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ISolarSystemForList } from '../../../ApplicationState/ApiClient';
import { SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ChooseOwnSolarSystem } from '../../../Components/FusionShift/SolarSystems/ChooseOwnSolarSystem';
import { ChooseRecentSolarSystem } from '../../../Components/FusionShift/SolarSystems/ChooseRecentSolarSystem';
import { FindSolarSystemByCoordinates } from '../../../Components/FusionShift/SolarSystems/FindSolarSystemByCoordinates';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ExistingRepeats } from '../Components/ExistingRepeats';
import { TargetRender } from '../Components/Target/TargetRender';
import { StageProps } from './StageRender';

export const TargetStage = observer((props: StageProps) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    async function ownSolarSystemChosen(s: ISolarSystemForList | undefined) {

        await solarSystemState.getSolarSystemDetailById(s?.solarSystemId).then(x => {
            props.sendShipsBag.setTargetSolarSystem(x?.solarSystem);
        });
    }

    return <>
        <SubPanel heading={{ text: "Choose Target ", icon: IconHelper.Combat.Target }} footerContent={
            <ButtonHolder isPulledRight>
                <NextButton action={props.nextStage} isDisabled={!props.sendShipsBag.HasTarget} />
            </ButtonHolder>
        }>
            <SplitLayout
                left={<>
                    <Paragraph type="prompt">
                        Enter coordinates to find a solar system to send ships to. It might be easier to use the <Link to="/map">map view</Link> to find a target.
                    </Paragraph>
                    <FindSolarSystemByCoordinates
                        initialTarget={props.sendShipsBag.TargetSolarSystem !== undefined ? { x: props.sendShipsBag.TargetSolarSystem.x, y: props.sendShipsBag.TargetSolarSystem.y } : undefined}
                        solarSystemFoundCallback={s => props.sendShipsBag.setTargetSolarSystem(s)}
                    />
                    <ChooseRecentSolarSystem solarSystemFoundCallback={s => props.sendShipsBag.setTargetSolarSystem(s)} />
                    <ChooseOwnSolarSystem
                        solarSystemFoundCallback={ownSolarSystemChosen}
                        sourceSolarSystem={props.sendShipsBag.init.sourceSolarSystem}
                        solarSystems={props.sendShipsBag.init.solarSystems}
                        showDistanceTo={props.sendShipsBag.init.sourceSolarSystem}
                    />
                </>
                }
                right={
                    <TargetRender sendShipsBag={props.sendShipsBag} />
                }
            />
        </SubPanel>
        <ExistingRepeats sendShipsBag={props.sendShipsBag} />
    </>;
});