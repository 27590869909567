import { ISimulationInput } from "../../ApplicationState/ApiClient";
import { CollectionHelper } from "../../Helpers/CollectionHelper";

export function validateSimulation(simulation: ISimulationInput | undefined) {

    const validDefender = simulation !== undefined && (simulation.defendingFleet !== undefined || simulation.defendingSolarSystem !== undefined);
    const validAttacker = simulation !== undefined && CollectionHelper.isAnyQuantityInDictionary(simulation.attacker?.ships);
    const isValid = validAttacker && validDefender;

    return { validAttacker, validDefender, isValid };
}