import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { Federation, FederationDetail } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, SolarSystemStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Table } from "../../Components/Base/Containers/Table";
import { ErrorMessageBox, WarningMessageBox } from "../../Components/Base/MessageBox";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { FederationLink } from "../../Components/FusionShift/Links/FederationLink";
import { InstallationTypeLink } from "../../Components/FusionShift/Links/InstallationTypeLink";
import { CollectionHelper } from "../../Helpers/CollectionHelper";
import { FederationHelper } from "../../Helpers/FederationHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { federation_incomingFleets, federation_view as federationUrl } from "../../Navigation/Routing/Federation";
import { messages_send } from "../../Navigation/Routing/NotificationsEtc";
import { SimpleBaseView } from "../BaseView";
import { NewFederationForm } from "./NewFederationForm";

export const OwnFederationView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [invitations, setInvitations] = React.useState<Federation[] | undefined>(undefined);
    const [federation, setFederation] = React.useState<FederationDetail | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (playerState.Player && !federation && playerState.Player.federation && playerState.Player.federation.federationId !== undefined) {
            apiState.FederationClient.get(playerState.Player.federation.federationId).then(federation => {
                if (federation) {
                    setFederation(federation);
                }
            })
        }
    }, [playerState.Player, federation]);

    React.useEffect(() => {
        if (playerState.Player) {
            apiState.FederationClient.getPendingInvitations().then(invitations => {
                if (invitations) {
                    setInvitations(invitations);
                }
            })
        }
    }, [playerState.Player, federation]);

    function reload() {
        setFederation(undefined);
        setInvitations(undefined);
    }

    function leave() {
        if (playerState.Player && playerState.Player.federation && playerState.Player.federation.federationId !== undefined) {
            return apiState.FederationClient.quit(playerState.Player.federation.federationId).then(_ => {
                appState.reload("Left federation");
            })
        }
    }

    function accept(federationId: number) {
        if (playerState.Player) {
            return apiState.FederationClient.accept(federationId).then(response => {
                if (response.wasSuccess) {
                    appState.reload("Joined federation");
                    appState.navigate(federationUrl(federationId))
                }
                else {
                    setError(response.error ? response.error : "Unable to accept federation invitation");
                }
            })
        }
    }

    function decline(federationId: number) {
        if (playerState.Player) {
            return apiState.FederationClient.decline(federationId).then(_ => {
                appState.reload("Declined federation invite");
            });
        }
    }

    if (!playerState.Player || !worldState.InstallationTypeSettings) {
        return null;
    }

    const hasFederation = !!playerState.Player.federation;
    const hasInvites = CollectionHelper.isAnyInArray(invitations);
    const canFound = FederationHelper.canCreateFederation(worldState.GameSettings, worldState.InstallationTypeSettings, playerState.Player, solarSystemState.SolarSystem);
    const federationInstallationType = FederationHelper.federationInstallationType(worldState.InstallationTypeSettings);

    return <SimpleBaseView heading={"Your Federation"} headingContent={<HelpButton to="federations" />}>
        {!playerState.Player.federation && !canFound && federationInstallationType && <Paragraph type="prompt">Build a higher level <InstallationTypeLink installationType={federationInstallationType} /> to found a Federation.</Paragraph>}
        {hasFederation &&
            <SubPanel heading="Current Federation" headingContent={
                FederationHelper.canLeave(playerState.Player, federation, playerState.Player.federation) &&
                <Button isPulledRight type="danger" icon={IconHelper.Federations.Leave} action={() => leave()} text="Leave Federation" confirm={`Are you sure you want to leave ${playerState.Player.federation!.name}?`} />
            }>
                <Paragraph>
                    You are a member of <FederationLink federation={playerState.Player.federation} />
                </Paragraph>
                <Paragraph>
                    <Link to={federation_incomingFleets}>Federation Incoming Fleets</Link>
                </Paragraph>
                {FederationHelper.isLeaderOrOfficer(playerState.Player.federation) &&
                    <div>
                        <ButtonLink to={`${messages_send}?toFederation=true`} icon={IconHelper.General.Message} text="Send message to members" />
                    </div>
                }
            </SubPanel>
        }
        {canFound && <NewFederationForm reloadCallback={reload} />}
        {(hasFederation || canFound) && hasInvites &&
            <hr />
        }
        {!!error && error.length > 0 && <ErrorMessageBox text={error} />}
        {invitations && CollectionHelper.isAnyInArray(invitations) &&
            <SubPanel heading="Federation Invitations" isUnpadded={!playerState.Player.federation}>
                {!!playerState.Player.federation &&
                    <WarningMessageBox text={`Accepting an invitation to a new federation will cause you to leave ${playerState.Player.federation.name}.`} />
                }
                <Table isHoverable isFullWidth heading={
                    <>
                        <td>Federation</td>
                        <td></td>
                    </>
                }>
                    {invitations.map(f =>
                        <tr key={f.federationId}>
                            <td>
                                <FederationLink federation={f} />
                            </td>
                            <td>
                                <ButtonHolder>
                                    <Button type="warning" icon={IconHelper.General.Cancel} action={() => decline(f.federationId)} text="Decline" confirm={`Are you sure you want to decline the invitation to join ${f.name}?`} />
                                    {playerState.Player && (!playerState.Player.federation || FederationHelper.canLeave(playerState.Player, federation, playerState.Player.federation)) &&
                                        <Button type="action" icon={IconHelper.General.Confirm} action={() => accept(f.federationId)} text="Accept" confirm={`Are you sure you want to join ${f.name}?`} />
                                    }
                                </ButtonHolder>
                            </td>
                        </tr>)
                    }
                </Table>
            </SubPanel>
        }
    </SimpleBaseView>;
});