import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Countdown from 'react-countdown';
import { ApocalypseShot, ApocalypseShotStatus } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { MessageBoxWithIcon } from '../../Base/MessageBox';
import { SolarSystemLink } from '../Links/SolarSystemLink';

type Props = {
    apocalypseShot: ApocalypseShot,
    belongsToPlayer: boolean
}

const ApocalypseShotText = (props: Props) => {

    if (props.apocalypseShot.status === ApocalypseShotStatus.Arming) {
        return <>
            <SolarSystemLink solarSystem={props.apocalypseShot.sourceSolarSystem} hideActionIcons /> Apocalypse weapon is ARMING, aimed at <SolarSystemLink solarSystem={props.apocalypseShot.targetSolarSystem} hideActionIcons />. <Countdown date={props.apocalypseShot.nextUpdateDate} />
        </>;
    }
    if (props.apocalypseShot.status === ApocalypseShotStatus.Firing) {
        return <>
            <SolarSystemLink solarSystem={props.apocalypseShot.sourceSolarSystem} hideActionIcons /> Apocalypse weapon is FIRING at <SolarSystemLink solarSystem={props.apocalypseShot.targetSolarSystem} hideActionIcons />
        </>;
    }
    if (props.apocalypseShot.status === ApocalypseShotStatus.Interrupted) {
        return <>
            Apocalypse weapon at <SolarSystemLink solarSystem={props.apocalypseShot.sourceSolarSystem} hideActionIcons /> INTERRUPTED
        </>;
    }

    return null;
};

const ApocalypseShotMessage = (props: Props) => {

    const type = props.belongsToPlayer ?
        props.apocalypseShot.status !== ApocalypseShotStatus.Interrupted ? "info" : "warning" :
        props.apocalypseShot.status !== ApocalypseShotStatus.Interrupted ? "danger" : "info";

    return <MessageBoxWithIcon
        type={type}
        icon={IconHelper.Celestials.Apocalypse}
        text={<ApocalypseShotText {...props} />}
    />;
};

export const ApocalypseShotMessages = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    const shots = solarSystemState.ApocalypseShots.map(x => {
        return {
            apocalypseShot: x,
            belongsToPlayer: x.sourceSolarSystem.playerId === playerState.Player?.playerId
        };
    });

    if (shots.length === 0) {
        return null;
    }

    return <>
        {shots.map(x => <ApocalypseShotMessage key={x.apocalypseShot.apocalypseShotId} {...x} />)}
    </>;
});