import { FleetMovementType } from "../../../ApplicationState/ApiClient";
import { FleetHelper } from "../../../Helpers/FleetHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { RelationHelper } from "../../../Helpers/RelationHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Icon } from "../../Base/Icon";

type Props = {
    movementType: FleetMovementType,
    isPlayerOwner: boolean,
    isPlayerAllied: boolean,
    setColour?: boolean,
    className?: string | undefined,
    isHostile?: boolean | undefined
    isGiant?: boolean
}

export const FleetMovementTypeIcon = (props: Props) => {

    function classNameForType(fleet: FleetMovementType) {
        if (!props.setColour) {
            return "";
        }

        if (props.isPlayerOwner) {
            return RelationHelper.Relation_Self;
        }

        if (props.isPlayerAllied) {
            return RelationHelper.Relation_Friend;
        }

        if (FleetHelper.isHostileMovementType(fleet) || props.isHostile) {
            return RelationHelper.Relation_Enemy;
        }

        return RelationHelper.Relation_Neutral;
    }

    return <Icon
        isGiant={props.isGiant}
        title={ValueFormatter.friendlyNameForMovementType(props.movementType)}
        icon={IconHelper.Ships.movementType(props.movementType)}
        className={`${classNameForType(props.movementType)} ${props.className ? props.className : ""}`} />;
};

FleetMovementTypeIcon.defaultProps = {
    isPlayerOwner: false,
    isPlayerAllied: false,
    setColour: true
};