import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PlayerAccoladeResult } from '../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { H2 } from '../../Components/Base/Text/H';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../Helpers/IconHelper';
import { TimeHelper } from '../../Helpers/TimeHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { SimpleBaseView } from '../BaseView';
import { PlayerAccoladesTables } from './AccoladesTables';

export const PlayerAccoladesView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [date, setDate] = React.useState(defaultDate());
    const [accolades, setAccolades] = React.useState<PlayerAccoladeResult | undefined>(undefined);
    const [weekNumber, setWeekNumber] = React.useState(0);
    const [todaysWeekNumber, setTodaysWeekNumber] = React.useState(0);

    function defaultDate() {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return date;
    }

    React.useEffect(() => {
        if (worldState.World) {
            setTodaysWeekNumber(TimeHelper.weekNumber(worldState.World.createdDate, new Date()))
        }
    }, [worldState.World]);

    React.useEffect(() => {
        if (worldState.World) {
            setWeekNumber(TimeHelper.weekNumber(worldState.World.createdDate, date));
            apiState.AccoladeClient.getPlayerAccolades(date).then(accolades => {
                if (accolades) {
                    setAccolades(accolades);
                }
            });
        }
    }, [date, worldState.World]);

    function changeDate(direction: number) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + (7 * direction));
        setDate(newDate);
    }

    if (!worldState.World || !accolades) {
        return <LoadingSpinner />;
    }

    return <SimpleBaseView
        heading={{ text: "Player Accolades", icon: IconHelper.Accolades.Accolade }}
        headingContent={
            <ButtonHolder >
                <Button type="nav" icon={IconHelper.General.Previous} text={"Previous"} isDisabled={weekNumber <= 1} action={() => changeDate(-1)} />
                <Button type="nav" icon={IconHelper.General.Next} text={"Next"} isDisabled={weekNumber >= todaysWeekNumber} action={() => changeDate(1)} />
            </ButtonHolder >
        }
    >
        <H2>
            Week {weekNumber}: {ValueFormatter.formatShortDate(accolades.start)} to  {ValueFormatter.formatShortDate(accolades.end)}
        </H2>
        {weekNumber >= todaysWeekNumber && accolades.accolades.length === 0 &&
            <Paragraph type="prompt">
                This week is still in progress. Fight hard and check back later to see if you earned any accolades.
            </Paragraph>
        }
        {weekNumber < todaysWeekNumber && accolades.accolades.length === 0 &&
            <Paragraph type="prompt">
                No player accolades were awarded this week.
            </Paragraph>
        }
        <PlayerAccoladesTables accolades={accolades.accolades} />
    </SimpleBaseView>;
});