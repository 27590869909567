import { observer } from "mobx-react-lite";
import * as React from "react";
import { DescriptionStateContext, WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { LoadingSpinner } from "../../../../Components/Base/Loading/LoadingSpinner";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { TypeDataBaseView } from "../TypeDataBaseView";
import { FactionTypeControl } from "./FactionTypeControl";

export const FactionTypesView = observer(() => {

    const descriptionState = React.useContext(DescriptionStateContext);
    const worldState = React.useContext(WorldStateContext);

    if (!worldState.FactionTypeSettings || !worldState.ShipTypeSettings || !descriptionState.Ships || !descriptionState.Factions) {
        return <LoadingSpinner />;
    }

    const factions = Object.keys(worldState.FactionTypeSettings.data!).map(c => worldState.FactionTypeSettings!.data![c]);

    return <TypeDataBaseView title="Factions" icon={IconHelper.Categories.Factions}>
        {factions.map(c => <FactionTypeControl
            description={descriptionState.Factions!.find(x => x.typeName === c.typeName)!}
            shipDescriptions={descriptionState.Ships!}
            key={c.typeName}
            factionType={c}
            shipTypeSettings={worldState.ShipTypeSettings!}
        />)}
    </TypeDataBaseView>;
});