import { observer } from "mobx-react-lite";
import * as React from "react";
import { FunctionComponent } from "react";
import { ISolarSystemDetail, MarketOrder } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, SolarSystemStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";

export type MarketOrderProps = {
    marketOrders: MarketOrder[],
    solarSystem: ISolarSystemDetail,
    reloadCallback: () => any
}

type Props = {
    title: string,
    icon?: string | undefined,
    extraHeadingContent?: React.ReactNode | undefined,
    content: FunctionComponent<MarketOrderProps>
}

export const MarketOrderBasePage = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [marketOrders, setMarketOrders] = React.useState<MarketOrder[] | undefined>(undefined);

    React.useEffect(() => {
        if (!marketOrders && solarSystemState.SolarSystem) {
            apiState.MarketOrderClient.getBySolarSystemId(solarSystemState.SolarSystem.solarSystemId).then(marketOrders => {
                if (marketOrders) {
                    setMarketOrders(marketOrders);
                }
            });
        }
    }, [marketOrders, solarSystemState.SolarSystem]);

    React.useEffect(() => {

        setMarketOrders(undefined);

    }, [solarSystemState.SolarSystem?.solarSystemId]);

    if (!playerState.Player || !marketOrders || !solarSystemState.SolarSystem) {
        return <LoadingSpinner />;
    }

    const headingContent = props.extraHeadingContent !== undefined ? <ButtonHolder>
        <HelpButton to="marketplace" />
        {props.extraHeadingContent}
    </ButtonHolder>
        : <HelpButton to="marketplace" />;

    return <SimpleBaseView heading={{ text: props.title, icon: props.icon !== undefined ? props.icon : IconHelper.Market.MarketOrder }} headingContent={headingContent}>
        <props.content marketOrders={marketOrders} solarSystem={solarSystemState.SolarSystem} {...props} reloadCallback={() => setMarketOrders(undefined)} />
    </SimpleBaseView>;
});