import { FleetMovementType } from '../../../ApplicationState/ApiClient';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ItemsControl } from '../../../Components/FusionShift/Items/ItemsControl';

type Props = {
    movementType: FleetMovementType,
    items: { [key: string]: number } | undefined,
}

export const CollectionText = (props: Props) => {

    if (props.movementType !== FleetMovementType.Collection) {
        return null;
    }

    return <>
        <Paragraph>
            Will attempt to collect:
        </Paragraph>
        <ItemsControl items={props.items} compressOnMobile />
    </>;
};