import React from "react";
import { Link, useRouteError } from "react-router-dom";
import { AppStateContext } from "../ApplicationState/ContextRoot";
import { Paragraph } from "../Components/Base/Text/Paragraph";
import { LoginPrompt, RegisterPrompt } from "../Views/Authentication/AuthenticationContent";
import { SimpleBaseView } from "../Views/BaseView";
import { ErrorApology } from "./ErrorApology";
import { home } from "./Routing/AppRouteValues";
import { VersionNumbers } from "./VersionNumbers";

export const ErrorPage = () => {

    const appState = React.useContext(AppStateContext);

    const error = useRouteError();

    function findError(error) {
        let errorText = "An unknown error occurred";
        let errorDetail = "";

        const castAsError = error as Error;

        if (castAsError !== undefined && castAsError !== null) {
            errorText = `${castAsError.name} - ${castAsError.message}`;
        } else if (typeof error === "string") {
            errorText = error;
        }

        if (!errorText.endsWith(".")) {
            errorText += ".";
        }

        return { errorText, errorDetail };
    }

    const { errorText, errorDetail } = findError(error);

    return <div className="authentication">
        <br />
        <br />
        <SimpleBaseView heading="Error" isPaddedMobile>
            <Paragraph type="danger">
                An error occurred: {errorText}
            </Paragraph>
            <ErrorApology />
            <hr />
            {!appState.IsLoggedIn && <>
                <RegisterPrompt />
                <LoginPrompt />
            </>}
            {appState.IsLoggedIn && <>
                <Link to={home} >Home</Link>
            </>}
            <br />
            <VersionNumbers />
        </SimpleBaseView>
    </div>
};
