import { ContractType } from "../../ApplicationState/ApiClient";

export class ContractTypes {

    public static describe(contractType: ContractType): string {

        switch (contractType) {

            case ContractType.Attack:
                return "An attack contract requires the accepting player to send ships to attack the target system.";
            case ContractType.Recon:
                return "Recon contracts are completed by performing a succesfull recon mission against the target system.";
            case ContractType.Defend:
                return "Defend contracts are fulfilled by sending reinforcements to the target system until a set date.";
            case ContractType.Courier:
                return "Courier contracts are used to configure the transfer of items between 2 systems.";
            case ContractType.BribeForManifesto:
                return "If the assigned player accepts the bribe then they must vote for the chosen manifesto.";
            case ContractType.BribeForPolicy:
                return "If the assigned player accepts the bribe then they must vote the specified way for the chosen policy.";
            case ContractType.Ransom:
                return "A contract to facilitate the sale and collection of a captured agent."
        }
    }
}