import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CombatRoundType, ICombatReport } from '../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { CollectionHelper } from '../../Helpers/CollectionHelper';
import { AllShips } from './ViewParts/AllShips';
import { CapturedAgents } from './ViewParts/CapturedAgents';
import { DestroyedGroundedShips } from './ViewParts/DestroyedGroundedShips';
import { CombatReportSeenInstallations } from './ViewParts/Intel/CombatReportSeenInstallations';
import { CombatReportSeenLoot } from './ViewParts/Intel/CombatReportSeenLoot';
import { Loot } from './ViewParts/Loot';
import { Round } from './ViewParts/Round';
import { SiegeResult } from './ViewParts/SiegeResult';
import { Summary } from './ViewParts/Summary';

type Props = {
    combatReport: ICombatReport
}

export const CombatReportRender = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.GameSettings || !worldState.InstallationTypeSettings || !worldState.ShipTypeSettings || !worldState.CelestialTypeSettings) {
        return <LoadingSpinner />;
    }

    const combatReport = props.combatReport;

    const groundedRound = combatReport.detail?.rounds?.find(x => x.type === CombatRoundType.Grounded);
    const hasLoot = CollectionHelper.isAnyQuantityInDictionary(combatReport.detail?.items, true);
    const hasAgents = (combatReport.detail?.capturedAgents?.length ?? 0) > 0;

    const hasRight = hasAgents || groundedRound !== undefined;

    const propsBag = {
        gameSettings: worldState.GameSettings,
        shipTypeSettings: worldState.ShipTypeSettings,
        installationTypeSettings: worldState.InstallationTypeSettings,
        celestialTypeSettings: worldState.CelestialTypeSettings
    }

    return <>
        <Summary combatReport={combatReport} />
        {!!combatReport.detail &&
            <>
                <AllShips combatReport={combatReport.detail} shipTypeSettings={worldState.ShipTypeSettings} />
                <SplitLayout
                    isLeftEmpty={!hasLoot}
                    isRightEmpty={!hasRight}
                    left={
                        <Loot combatReport={combatReport} combatReportDetail={combatReport.detail} {...propsBag} />

                    }
                    right={hasRight && <>
                        {groundedRound !== undefined &&
                            <DestroyedGroundedShips {...propsBag} combatRound={groundedRound} />
                        }
                        <CapturedAgents combatReportDetail={combatReport.detail} />
                    </>
                    }
                />
                {combatReport.detail.siegeDamage && <SiegeResult attackingPlayer={combatReport.attackingPlayer} siegeDamage={combatReport.detail.siegeDamage} {...propsBag} solarSystem={combatReport.defendingSolarSystem} />}
                {combatReport.detail.intel && <CombatReportSeenLoot intel={combatReport.detail.intel} {...propsBag} />}
                {combatReport.detail.intel && <CombatReportSeenInstallations intel={combatReport.detail.intel} {...propsBag} solarSystem={combatReport.defendingSolarSystem} />}
                {combatReport.detail.rounds.map((r, i) => <Round key={i}  {...propsBag} combatRound={r} number={i + 1} />)}
            </>
        }
    </>;
});