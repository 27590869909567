import classNames from 'classnames';
import * as React from 'react';
import { BackgroundImage, BackgroundImageHelper } from '../../../Helpers/BackgroundImageHelper';
import { H3 } from '../Text/H';
import { HeadingContent, PanelHeading } from './PanelHeading';

type Props = {
    heading: HeadingContent,
    backgroundImage?: BackgroundImage
    children: React.ReactNode,
    headingContent?: React.ReactNode | undefined,
    footerContent?: React.ReactNode | undefined,
    className?: string | undefined,
    id?: string | undefined,
    hasBorder?: boolean,
    isUnpadded?: boolean,
    isBackgroundFaded?: boolean
}

export const SubPanel = (props: Props) => {

    const className = classNames({
        "is-unpadded": props.isUnpadded,
        "has-border": props.hasBorder,
        "has-footer": props.footerContent !== undefined,
    },
        "sub-panel",
        props.className,
        props.backgroundImage && BackgroundImageHelper.className(props.backgroundImage),
        props.isBackgroundFaded && "faded");

    return <div className={className}>
        <div className="header">
            <H3 id={props.id}><PanelHeading content={props.heading} /></H3>
            {props.headingContent !== undefined &&
                <div className="heading-content">
                    {props.headingContent}
                </div>
            }
        </div>
        <div className="inner">
            {props.children}
        </div>
        {props.footerContent !== undefined &&
            <div className="footer-content">
                {props.footerContent}
            </div>}
    </div>;
};
