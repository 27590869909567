import CountUp from 'react-countup';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';


const duration = 1.5;

type Props = {
    value: number | undefined,
    precision?: number
};

export const CountingNumber = (props: Props) => {

    if (props.value === undefined) {
        return null;
    }

    return <CountUp
        end={props.value}
        duration={duration}
        useEasing={false} decimals={props.precision !== undefined ? props.precision : 0}
        formattingFn={ValueFormatter.formatLocaleNumber}
    />;
};