import { ContractType, IContract } from "../../../ApplicationState/ApiClient";
import { ButtonLink } from "../../../Components/Base/Buttons/ButtonLink";
import { InfoMessageBox, WarningMessageBox } from "../../../Components/Base/MessageBox";
import { CreditsIconWithQuantity } from "../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity";
import { PlayerLink } from "../../../Components/FusionShift/Links/PlayerLink";
import { IconHelper } from "../../../Helpers/IconHelper";
import { contractById } from "../../../Navigation/Routing/Contracts";

type Props = {
    contract: IContract | undefined
}

export const RelevantContractMessageBox = (props: Props) => {


    if (props.contract === undefined) {
        return null;
    }

    if (props.contract.type === ContractType.BribeForManifesto || props.contract.type === ContractType.BribeForPolicy) {
        return <WarningMessageBox
            text={<>
                <PlayerLink player={props.contract.player} /> has already assigned you a bribe. If you accept this bribe then you will refund <CreditsIconWithQuantity quantity={props.contract.initialCredits} /> to <PlayerLink player={props.contract.player} />.
            </>}
            button={<ButtonLink className="is-pulled-right" icon={IconHelper.Economy.Contract} to={contractById(props.contract.contractId)} text="View Contract" />}
        />
    }

    return <InfoMessageBox
        text={<>
            <PlayerLink player={props.contract.player} /> has already assigned you a contract.
        </>}
        button={<ButtonLink className="is-pulled-right" icon={IconHelper.Economy.Contract} to={contractById(props.contract.contractId)} text="View Contract" />}
    />;
}