import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Drone, DroneStatus, ICelestialTypeSettings, IInstallationTypeSettings, IItemTypeSettings, IShipTypeSettings, ISolarSystemDetail, ItemType, ShipType, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { ItemTypeIcon } from '../../../../../Components/FusionShift/Icons/Items/ItemTypeIcon';
import { ShipTypeLink } from '../../../../../Components/FusionShift/Links/ShipTypeLink';
import { CelestialHelper } from '../../../../../Helpers/CelestialHelper';
import { CollectionHelper } from '../../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    solarSystem: ISolarSystemDetail,
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type TableProps = {
    solarSystem: ISolarSystemDetail,
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    drones: Drone[],
    droneStatus: DroneStatus,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type RowPropsBase = {
    rowKey: string,
    solarSystemId: number,
    droneIds: string[],
    shipType: ShipType,
    droneStatus: DroneStatus,
    completedDate: Date | undefined,
    itemType: ItemType | undefined
}

type RowProps = RowPropsBase & {
    isDeactivating: boolean,
    quantity: number,
    setQuantity: (quantity: number) => any
}

const DroneRow = (props: RowProps) => {

    return <tr>
        <td><ShipTypeLink shipType={props.shipType} /> x{props.droneIds.length}</td>
        <td>
            {!!props.itemType && props.droneStatus !== DroneStatus.Deactivating && <ItemTypeIcon itemType={props.itemType} showName />}
        </td>
        <td>
            {props.droneStatus != DroneStatus.Active &&
                props.completedDate &&
                ValueFormatter.formatDateFromNowWithMoment(props.completedDate)}
            {props.isDeactivating &&
                <div className="control">
                    <input
                        className="input is-small input-5"
                        type="number"
                        size={5}
                        onFocus={e => e.target.select()}
                        value={props.quantity}
                        onChange={e => props.setQuantity(Number(e.target.value))}
                    />
                </div>
            }
        </td>
    </tr>;
};

const DroneTable = observer((props: TableProps) => {

    const apiState = React.useContext(ApiStateContext);

    const [isDeactivating, setIsDeactivating] = React.useState(false);
    const [deactivations, setDeactivations] = React.useState<{ [key: string]: number }>({});
    const [rows, setRows] = React.useState<RowPropsBase[]>([]);

    React.useEffect(() => {
        const groupedDrones: {
            droneIds: string[],
            completedDate: Date | undefined,
            celestialId: number | undefined,
            shipTypeName: string,
            droneStatus: DroneStatus,
            rowKey: string
        }[] = [];

        for (let drone of props.drones.filter(x => x.status === props.droneStatus)) {

            const celestialId = drone.celestialId;
            const shipTypeName = drone.shipTypeName;
            const droneStatus = drone.status;
            const completedDate = drone.completedDate;

            let found = false;

            for (let i = 0; i < groupedDrones.length; i++) {

                const existingEntry = groupedDrones[i];

                if (existingEntry.celestialId === celestialId &&
                    (existingEntry.completedDate === undefined && completedDate === undefined ||
                        (existingEntry.completedDate !== undefined && completedDate !== undefined &&
                            existingEntry.completedDate.getTime() === completedDate.getTime())) &&
                    existingEntry.droneStatus === droneStatus &&
                    existingEntry.shipTypeName === shipTypeName) {
                    found = true;

                    groupedDrones[i] = {
                        ...existingEntry,
                        droneIds: [drone.droneId, ...existingEntry.droneIds]
                    };

                    break;
                }

            }

            if (!found) {
                const newEntry = {
                    droneIds: [drone.droneId],
                    completedDate,
                    celestialId,
                    shipTypeName,
                    droneStatus,
                    rowKey: `${shipTypeName}_${droneStatus}_${celestialId ? celestialId : "NONE"}_${completedDate ? completedDate.getTime().toString() : "COMPLETE"}`
                };
                groupedDrones.push(newEntry);
            }
        }

        const drones: RowPropsBase[] = groupedDrones.map(d => {
            return {
                solarSystemId: props.solarSystem.solarSystemId,
                itemType: CelestialHelper.resourceTypeFromCelestials(props.celestialTypeSettings, props.itemTypeSettings, d.celestialId, props.solarSystem.celestials),
                shipType: props.shipTypeSettings.data[d.shipTypeName],
                ...d,
                ...props
            }
        });

        setRows(drones);

    }, [props.drones]);

    function setQuantity(rowKey: string, quantity: number) {
        deactivations[rowKey] = quantity;

        setDeactivations({
            ...deactivations
        });
    }

    function deactivateDrones() {

        const droneIds: string[] = [];

        for (let rowKey in deactivations) {
            const droneRow = rows.find(r => r.rowKey === rowKey);

            if (droneRow) {
                for (let i = 0; i < droneRow.droneIds.length && i < deactivations[rowKey]; i++) {
                    droneIds.push(droneRow.droneIds[i]);
                }
            }
        }

        return apiState.DroneClient.makeDronesInactive(props.solarSystem.solarSystemId, droneIds).then(solarSystem => {
            if (solarSystem) {
                setDeactivations({});
                setIsDeactivating(false);
                props.reloadCallback(solarSystem);
            }
        });
    }

    if (rows.length == 0) {
        return null;
    }

    const count = CollectionHelper.sumOfArrayValue(rows, x => x.droneIds.length);

    return <SubPanel heading={`${DroneStatus[props.droneStatus]} (${ValueFormatter.formatLocaleNumber(count)})`}
        isUnpadded
        headingContent={props.droneStatus === DroneStatus.Active && !isDeactivating &&
            <Button hideTextOnMobile type="nav" text="De-Activate" action={() => setIsDeactivating(true)} icon={IconHelper.Ships.droneStatus(DroneStatus.Deactivating)} />
        }
        footerContent={props.droneStatus === DroneStatus.Active && isDeactivating &&
            <ButtonHolder isPulledRight>
                <Button hideTextOnMobile type="nav" text="Cancel" action={() => setIsDeactivating(false)} icon={IconHelper.General.Cancel} />
                <Button hideTextOnMobile text="De-activate" isDisabled={!CollectionHelper.isAnyQuantityInDictionary(deactivations)} icon={IconHelper.Ships.droneStatus(DroneStatus.Deactivating)} action={() => deactivateDrones()} type="warning" />
            </ButtonHolder>
        }
    >
        <Table isFullWidth heading={
            <>
                <th>Drone</th>
                <th></th>
                <th>{props.droneStatus != DroneStatus.Active && "Arriving"}</th>
            </>
        }>
            {rows.map(d => <DroneRow
                key={d.droneIds[0]}
                isDeactivating={isDeactivating}
                setQuantity={q => setQuantity(d.rowKey, q)}
                quantity={d.rowKey in deactivations ? deactivations[d.rowKey] : 0}
                {...d} />)}
        </Table>
    </SubPanel>;
});

export const ActiveTables = (props: Props) => {

    if (!props.solarSystem.drones) {
        return null;
    }

    return <>
        <DroneTable {...props} droneStatus={DroneStatus.Active} drones={props.solarSystem.drones.activeDrones} />
        <DroneTable {...props} droneStatus={DroneStatus.Deactivating} drones={props.solarSystem.drones.activeDrones} />
        <DroneTable {...props} droneStatus={DroneStatus.Returning} drones={props.solarSystem.drones.activeDrones} />
        <DroneTable {...props} droneStatus={DroneStatus.Activating} drones={props.solarSystem.drones.activeDrones} />
    </>;
};