import classNames from 'classnames';
import * as React from 'react';
import { IItemTypeSettings, IShipTypeSettings } from '../../../ApplicationState/ApiClient';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { availableSpace, createSolarSystem, middle } from './Entities';
import { createItemsRender } from './ItemsRender';
import { PositionedSolarSystem } from './PositionedSolarSystem';
import { createShipsRender } from './ShipsRender';

export type NetworkRenderType = "Items" | "Ships";

type Props = {
    renderType: NetworkRenderType,
    solarSystems: SolarSystemWrapper[],
    itemTypeSettings: IItemTypeSettings,
    shipTypeSettings: IShipTypeSettings
    selectedSolarSystemId: number | undefined,
    setSelectedSolarSystemId: (solarSystemId: number | undefined) => any
}


export const NetworkRender = (props: Props) => {

    const solarSystems = React.useMemo(() => {

        return props.solarSystems.map((x, i) => createSolarSystem(x, i, props.solarSystems.length));

    }, [props.solarSystems]);

    const lines = React.useMemo(() => {

        if (props.renderType === 'Items') {
            return createItemsRender(solarSystems, props.itemTypeSettings, props.selectedSolarSystemId);
        }

        if (props.renderType === 'Ships') {
            return createShipsRender(solarSystems, props.selectedSolarSystemId);
        }

        return [];

    }, [solarSystems, props.itemTypeSettings, props.renderType, props.selectedSolarSystemId])


    const className = classNames("empire-network",
        {
            "has-selected": props.selectedSolarSystemId !== undefined
        });

    return <div className={className}>
        <svg width={availableSpace} height={availableSpace}>
            {lines.map((x, i) => <line key={i}
                {...x}
            />
            )}
        </svg>
        <div className="solar-systems" style={{
            top: middle.y,
            left: middle.x
        }}>
            {solarSystems.map(x => <PositionedSolarSystem
                key={x.solarSystem.solarSystem.solarSystemId}
                solarSystem={x.solarSystem.solarSystem}
                position={x.position}
                isSelected={x.solarSystem.solarSystemId === props.selectedSolarSystemId}
                onClick={() => props.setSelectedSolarSystemId(x.solarSystem.solarSystemId)}
            />)}
        </div>
    </div>;
};