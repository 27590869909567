import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FederationVoteProportion, IFederation, SecurityStatus } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonChooser, choice } from '../../Components/Base/Buttons/ButtonChooser';
import { PieChart } from '../../Components/Base/Charts/PieChart';
import { graphTheme } from '../../Components/Base/Charts/Theme';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { Table } from '../../Components/Base/Containers/Table';
import { FieldHolder } from '../../Components/Base/Form/FieldHolder';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { FederationLink } from '../../Components/FusionShift/Links/FederationLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';

type Props = {
    federations: IFederation[]
}

type FederationRow = {
    federation: string | IFederation | undefined,
    numberOfPlayers: number,
    population: number,
    proportion: number
}

const numberOfSegements = 5;

export const FederationVotes = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [federationVoteProportions, setFederationVoteProportions] = React.useState<FederationVoteProportion[] | undefined>(undefined);
    const [securityStatusCantVote, setSecurityStatusCantVote] = React.useState<SecurityStatus | undefined>(undefined);

    React.useEffect(() => {
        apiState.FederationClient.getVoteProportion(securityStatusCantVote).then(x => {
            setFederationVoteProportions(x.sort((a, b) => b.population - a.population));
        });
    }, [securityStatusCantVote]);

    if (!playerState.Player || !worldState.PolicyTypeSettings) {
        return null;
    }

    if (!federationVoteProportions) {
        return <LoadingSpinner />;
    }

    const federationIds: number[] = [];

    if (playerState.Player.federation?.federationId !== undefined) {
        federationIds.push(playerState.Player.federation.federationId);
    }

    for (let i = 0; i < federationVoteProportions.length; i++) {

        if (federationVoteProportions[i].federationId !== undefined &&
            federationVoteProportions[i].federationId !== null &&
            !federationIds.includes(federationVoteProportions[i].federationId!)) {
            federationIds.push(federationVoteProportions[i].federationId!);
        }

        if (federationIds.length >= numberOfSegements - 1) {
            break;
        }
    }

    let population = 0, numberOfPlayers = 0, proportion = 0;

    for (const federationVoteProportion of federationVoteProportions) {
        if (federationVoteProportion.federationId !== undefined &&
            federationVoteProportion.federationId !== null &&
            federationIds.includes(federationVoteProportion.federationId!)) {
            continue;
        }

        population += federationVoteProportion.population;
        numberOfPlayers += federationVoteProportion.numberOfPlayers;
        proportion += federationVoteProportion.proportion;
    }

    const federations: FederationRow[] = federationIds.map(x => {
        const vote = federationVoteProportions.find(y => y.federationId === x);
        const federation = props.federations.find(y => y.federationId === x);

        return {
            federation,
            numberOfPlayers: vote?.numberOfPlayers ?? 0,
            population: vote?.population ?? 0,
            proportion: vote?.proportion ?? 0
        }
    })

    federations.push({
        federation: "Remainder",
        numberOfPlayers,
        population,
        proportion
    });

    const securityStatuses: SecurityStatus[] = [];

    for (const policyType of Object.values(worldState.PolicyTypeSettings.data)) {
        if (policyType.securityStatusCantVote !== undefined &&
            policyType.securityStatusCantVote !== null &&
            !securityStatuses.includes(policyType.securityStatusCantVote)) {
            securityStatuses.push(policyType.securityStatusCantVote);
        }
    }

    const securityStatusChoices = [
        choice<SecurityStatus | undefined>(undefined, "None", IconHelper.Categories.Summary),
        ...securityStatuses.map(x => choice<SecurityStatus | undefined>(x, `${SecurityStatus[x]}s`, IconHelper.Security.securityStatus(x)))
    ];

    const asData = federations.map(x => {
        return {
            x: typeof x.federation === "string" ? x.federation : x.federation?.name ?? "Unknown",
            y: x.proportion
        }
    });

    return <>
        <FieldHolder label="Exclude votes from">
            <ButtonChooser
                value={securityStatusCantVote}
                valueChanged={setSecurityStatusCantVote}
                values={securityStatusChoices}
            />
        </FieldHolder>
        <SplitLayout
            horizontalAfter='mobile'
            sizing='30-70'
            left={<PieChart data={asData} key={securityStatusCantVote} />}
            right={
                <Table
                    isFixed
                    isFullWidth
                    heading={<>
                        <th>Federation</th>
                        <th>Players</th>
                        <th>Pop.</th>
                        <th>%</th>
                    </>
                    }>
                    {federations.map((x, i) => {
                        const key = typeof x.federation === "string" ? x.federation : x.federation?.federationId;

                        return <tr key={key}>
                            <td>
                                <span className="legend-box" style={{ background: graphTheme.line[i].data.stroke }} />
                                {typeof x.federation === "string" && x.federation}
                                {typeof x.federation !== "string" && x !== undefined && <FederationLink federation={x.federation} />}
                            </td>
                            <td>
                                {ValueFormatter.formatLocaleNumber(x.numberOfPlayers)}
                            </td>
                            <td>
                                {ValueFormatter.formatPopulation(x.population)}
                            </td>
                            <td>
                                {ValueFormatter.fromDecimalToDisplayPercent(x.proportion)}
                            </td>
                        </tr>;
                    })}
                </Table>
            }
        />
    </>;
});