import { ApocalypseShotMessages } from "./ApocalypseShotMessage";
import { BeginnersProtectionEndingMessage } from "./BeginnersProtectionMessage";
import { PlayerDefeatedMessage } from "./DefeatMessage";
import { FeedbackReminderMessage } from "./FeedbackReminderMessage";
import { SubscriptionTrialMessage } from "./SubscriptionTrialMessage";
import { VeryifyEmailMessage } from "./VerifyEmailMessage";
import { VictoryMessage } from "./VictoryMessage";

export const MessageRoot = () => {

    return <>
        <VeryifyEmailMessage />
        <SubscriptionTrialMessage />
        <FeedbackReminderMessage />
        <ApocalypseShotMessages />
        <PlayerDefeatedMessage />
        <BeginnersProtectionEndingMessage />
        <VictoryMessage />
    </>;
};