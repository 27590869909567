type Props = {
    image: string | undefined
}

export const NotificationImage = (props: Props) => {

    if (props.image === undefined || props.image.length === 0) {
        return null;
    }

    return <img src={`/images/notification/${props.image}.jpg`} className="notification-image" />
}