import classNames from "classnames";
import React from "react";

type Props = {
    className?: string | undefined,
    primaryLink: React.ReactNode,
    children: React.ReactNode,
    canBeOpened: boolean,
    isOpen: boolean,
    setIsOpen: (open: boolean) => any
}

export const CustomMenu = (props: Props) => {

    const className = classNames("custom-menu",
        "navbar-item",
        props.className);

    return <div className={className}
        onMouseLeave={() => props.setIsOpen(false)}
        onMouseEnter={() => props.setIsOpen(true)}
    >
        {props.primaryLink}
        {props.canBeOpened &&
            <div className={`dropdown ${props.isOpen ? "is-open" : ""}`}>
                {props.children}
            </div>
        }
    </div>;
};
