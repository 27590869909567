import { WorldStatus } from "../ApplicationState/ApiClient";
import { JoinWorldBag } from "../Views/Authentication/JoinWorld/JoinWorldBag";
import { JoinWorldStage } from "../Views/Authentication/JoinWorld/StagesControl";

export class JoinWorldHelper {

    public static allowedStages(joinWorldBag: JoinWorldBag) {
        const stages: JoinWorldStage[] = [];

        if (joinWorldBag.Worlds.length > 1) {
            stages.push("World");
        }

        stages.push("Faction");

        if (joinWorldBag.World !== undefined && joinWorldBag.World.invitations.length > 1) {
            stages.push("Friend");
        }

        stages.push("Location");

        if (joinWorldBag.World !== undefined && joinWorldBag.World.status === WorldStatus.LockedToAdmins) {
            stages.push("Locked");
        }

        stages.push("Confirm");

        return stages;
    }

    public static nextStageFor(currentStage: JoinWorldStage, joinWorldBag: JoinWorldBag) {

        const allowedStages = this.allowedStages(joinWorldBag);

        for (var i = 0; i < allowedStages.length - 1; i++) {
            if (allowedStages[i] === currentStage) {
                return allowedStages[i + 1];
            }
        }

        return currentStage;
    }

    public static previousStageFor(currentStage: JoinWorldStage, joinWorldBag: JoinWorldBag) {

        const allowedStages = this.allowedStages(joinWorldBag);

        for (var i = allowedStages.length - 1; i < allowedStages.length; i--) {
            if (allowedStages[i] === currentStage) {
                return allowedStages[i - 1];
            }
        }

        return currentStage;
    }

}