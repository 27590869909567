import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FormFooterValidation } from '../../../Components/Base/Form/FormFooter';
import { ErrorIcon } from '../../../Components/Base/Icon';
import { SendShipsBag } from '../SendShipsBag';

type Props = {
    sendShipsBag: SendShipsBag
}

export const Validation = observer((props: Props) => {

    const validation = !props.sendShipsBag.validation.valid ? {
        valid: false,
        content: <>
            <ErrorIcon />
            {props.sendShipsBag.validation.value}
        </>
    } : props.sendShipsBag.validation;

    return <FormFooterValidation validation={validation} />;
});