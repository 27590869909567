import * as React from 'react';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button } from './Button';
import { NavButton } from './NavButton';

type NavProps = {
    to: string,
    isPulledRight?: boolean
}

type Props = {
    action: () => any,
    isPulledRight?: boolean
}

export const BackNavButton = (props: NavProps) => {
    return <NavButton {...props} icon={IconHelper.General.Previous} text="Back" hideTextOnMobile />
};

export const BackButton = (props: Props) => {
    return <Button type="nav" {...props} icon={IconHelper.General.Previous} text="Back" hideTextOnMobile />
};

export const HistoryBackButton = () => {

    const appState = React.useContext(AppStateContext);

    if (window.history.state.idx === 0) {
        return null;
    }

    return <Button type="nav" icon={IconHelper.General.Previous} text="Back" hideTextOnMobile action={() => appState.navigateBack()} />
};