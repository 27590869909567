import { FleetMovementType } from '../../../../../ApplicationState/ApiClient';
import { Level, levelContentWithHeading } from '../../../../../Components/Base/Containers/Level';
import { TextAndIcon } from '../../../../../Components/Base/Text/TextAndIcon';
import { SolarSystemWrapper } from '../../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    solarSystemWrapper: SolarSystemWrapper
}

export const Stats = (props: Props) => {

    const content = [
        levelContentWithHeading("Total Attack",
            <TextAndIcon text={ValueFormatter.formatLocaleNumber(props.solarSystemWrapper.militaryStats.totalAttack)} icon={IconHelper.Combat.Attack} />
        ),
        levelContentWithHeading("Available Defence",
            <TextAndIcon
                text={
                    <span className={props.solarSystemWrapper.militaryStats.availableDefence <= 0 || props.solarSystemWrapper.militaryStats.totalDefenceBonus < 0 ? "has-text-danger" : ""}>
                        {ValueFormatter.formatLocaleNumber(props.solarSystemWrapper.militaryStats.availableDefence)}
                    </span>}
                icon={IconHelper.Combat.Defence}
            />
        ),
        props.solarSystemWrapper.militaryStats.totalDefenceBonus !== 0 && levelContentWithHeading("Defence Bonus",
            <span className={props.solarSystemWrapper.militaryStats.totalDefenceBonus < 0 ? "has-text-danger" : ""}>
                {ValueFormatter.fromDecimalToDisplayPercent(props.solarSystemWrapper.militaryStats.totalDefenceBonus)}
            </span>
        ),
        props.solarSystemWrapper.militaryStats.defenceFromShips > 0 && levelContentWithHeading("Defence from Ships",
            <TextAndIcon text={ValueFormatter.formatLocaleNumber(props.solarSystemWrapper.militaryStats.defenceFromShips)} icon={IconHelper.Ships.Ship} />
        ),
        props.solarSystemWrapper.militaryStats.defenceFromDrones > 0 && levelContentWithHeading("Defence from Drones",
            <TextAndIcon text={ValueFormatter.formatLocaleNumber(props.solarSystemWrapper.militaryStats.defenceFromDrones)} icon={IconHelper.Ships.Drone} />
        ),
        props.solarSystemWrapper.militaryStats.defenceFromInstallations > 0 && levelContentWithHeading("Defence from Installations",
            <TextAndIcon text={ValueFormatter.formatLocaleNumber(props.solarSystemWrapper.militaryStats.defenceFromInstallations)} icon={IconHelper.Installations.Installation} />
        ),
        props.solarSystemWrapper.militaryStats.defenceFromReinforcements > 0 && levelContentWithHeading("Defence from Reinforcements",
            <TextAndIcon text={ValueFormatter.formatLocaleNumber(props.solarSystemWrapper.militaryStats.defenceFromReinforcements)} icon={IconHelper.Ships.movementType(FleetMovementType.Reinforce)} />
        )
    ];

    return <Level content={content} />;
};