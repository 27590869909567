import { observer } from "mobx-react-lite";
import React from "react";
import { AgentStatus, FleetMovementType, IFleet, IItemTypeSettings } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CelestialHelper } from "../../../Helpers/CelestialHelper";
import { FleetHelper } from "../../../Helpers/FleetHelper";
import { RelationHelper } from "../../../Helpers/RelationHelper";
import { AgentStatusIcon } from "./AgentStatusIcon";
import { FleetMovementTypeIcon } from "./FleetMovementTypeIcon";
import { ItemsIcons } from "./Items/ItemsIcons";
import { ItemTypeIcon } from "./Items/ItemTypeIcon";

type Props = {
    fleet: IFleet
}

type ResourceIconProps = Props & {
    itemTypeSettings: IItemTypeSettings
}

export const FleetIcon = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    return <FleetMovementTypeIcon
        movementType={props.fleet.movementType}
        isPlayerOwner={props.fleet.ownerPlayerId === playerState.Player.playerId}
        isPlayerAllied={RelationHelper.isAlliedWith(playerState.Player, props.fleet.owner)}
        isHostile={FleetHelper.isHostileMovement(props.fleet)}
    />;
});

const FleetItemIcons = (props: ResourceIconProps) => {

    return <ItemsIcons {...props} items={props.fleet.itemsCargo} />;
}

const FleetAgentIcons = (props: Props) => {

    if (props.fleet.agents.length === 0) {
        return null;
    }

    const statuses: AgentStatus[] = [];

    for (let agent of props.fleet.agents) {

        if (agent.status === null || agent.status === undefined) {
            continue;
        }

        if (!statuses.includes(agent.status)) {
            statuses.push(agent.status);
        }
    }

    return <>
        {statuses.map(x => <AgentStatusIcon key={x} status={x} />)}
    </>;
}

export const FleetCargoIcons = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CelestialTypeSettings || !worldState.ItemTypeSettings) {
        return null;
    }

    if (props.fleet.targetCelestial && props.fleet.movementType === FleetMovementType.Mine) {
        const itemType = CelestialHelper.itemTypeFromCelestial(worldState.CelestialTypeSettings, worldState.ItemTypeSettings, props.fleet.targetCelestial);
        if (itemType === undefined) {
            return null;
        }
        return <ItemTypeIcon itemType={itemType} />
    };

    return <>
        <FleetItemIcons {...props} itemTypeSettings={worldState.ItemTypeSettings} />
        <FleetAgentIcons {...props} />
    </>;
})