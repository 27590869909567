import * as React from 'react';
import { FleetMovementType, ISolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { send_ships_to } from '../../../Navigation/Routing/Misc';
import { Button } from '../../Base/Buttons/Button';
import { ButtonHolder } from '../../Base/Buttons/ButtonHolder';

type Props = {
    solarSystem: ISolarSystemDetail,
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    movementTypes: FleetMovementType[]
}

export const SendShipsBetweenSystemButtons = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    function send(movementType: FleetMovementType) {

        if (props.sourceSolarSystemId === undefined) {
            return;
        }

        appState.changeSolarSystemId(props.sourceSolarSystemId);
        const url = send_ships_to(props.solarSystem.x, props.solarSystem.y, movementType);
        appState.navigate(url);
    }

    if (props.sourceSolarSystemId === undefined) {
        if (!CollectionHelper.isAnyQuantityInDictionary(props.solarSystem.availableShips)) {
            return null;
        }
        return <Button type="nav" icon={IconHelper.Ships.Send} title={`Send ships from ${props.solarSystem.name}`} action={() => props.setSourceSolarSystemId(props.solarSystem.solarSystemId)} />;
    }
    if (props.sourceSolarSystemId === props.solarSystem.solarSystemId) {
        return <Button type="nav" icon={IconHelper.General.Cancel} title={`Cancel`} action={() => props.setSourceSolarSystemId(undefined)} />;
    }

    return <ButtonHolder>
        {props.movementTypes.map(x => <Button
            key={x}
            type="nav"
            icon={IconHelper.Ships.movementType(x)}
            title={`Send ${ValueFormatter.friendlyNameForMovementType(x)} to ${props.solarSystem.name}`} action={() => send(x)}
        />)}
    </ButtonHolder>
};