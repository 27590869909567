import { makeAutoObservable } from "mobx";
import { IGameSettings, IShipTypeSettings, ShipClass, ShipType } from "../ApplicationState/ApiClient";

export type ShipQuantity = {
    ship: ShipType,
    quantity: number
}

export function onlyCombatShips(shipType: ShipType) {
    return shipType.class !== ShipClass.ColonyPod &&
        shipType.class !== ShipClass.Hauler &&
        shipType.class !== ShipClass.MiningBarge &&
        shipType.class !== ShipClass.Recon &&
        shipType.class !== ShipClass.OrbitalSiege;
}

export class FleetWrapper {

    private _shipTypeSettings: IShipTypeSettings;
    private _gameSettings: IGameSettings;

    constructor(shipTypeSettings: IShipTypeSettings, gameSettings: IGameSettings, ships: { [key: string]: number } | undefined, filter?: (shipType: ShipType, quantity: number) => boolean) {
        this._shipTypeSettings = shipTypeSettings;
        this._gameSettings = gameSettings;
        if (ships) {
            this.setShips(ships, filter ? filter : _ => true);
        }

        makeAutoObservable(this);
    }

    public static empty() {
        return new FleetWrapper(null!, null!, undefined);
    }

    public setShips(ships: { [key: string]: number; }, filter: (shipType: ShipType, quantity: number) => boolean) {
        this._ships = Object.keys(ships).map(s => {
            return {
                ship: this._shipTypeSettings.data![s],
                quantity: ships[s]
            };
        })
            .filter(s => filter(s.ship, s.quantity))
            .sort(((a, b) => a.ship.order > b.ship.order ? 1 : -1));
    }

    public shipQuantity(shipTypeName: string) {
        const ship = this.Ships.find(x => x.ship.typeName === shipTypeName);

        return ship?.quantity ?? 0;
    }

    public filteredCargoCapacity(filter: (x: ShipQuantity) => boolean): number {
        let capacity = 0;

        for (let ship of this._ships) {
            if (filter(ship)) {
                capacity += ship.quantity * ship.ship.cargoCapacity;
            }
        }

        return capacity;
    }

    get HasAnyShips(): boolean {
        for (let s of this.Ships) {
            if (s.quantity > 0) {
                return true;
            }
        }
        return false;
    }

    get CanAnyShipsIntercept(): boolean {
        for (let s of this.Ships) {
            if (s.quantity > 0 && (s.ship.canIntercept || s.ship.class === ShipClass.Recon)) {
                return true;
            }
        }
        return false;
    }

    get Ships(): ShipQuantity[] { return this._ships; }
    private _ships: ShipQuantity[] = [];

    get CanClaim(): boolean {
        for (let s of this.Ships) {
            if (s.ship.canClaim && s.quantity > 0) {
                return true;
            }
        }
        return false;
    }

    public TotalAttack(isIntercept: boolean): number {
        let attack = 0;
        for (let s of this.Ships) {

            let attackToUse = s.ship.attack;

            if (isIntercept) {
                if (s.ship.class === ShipClass.Interceptor) {
                    attackToUse = s.ship.defence;
                } else if (s.ship.class === ShipClass.Raider) {
                    attackToUse = s.ship.attack * this._gameSettings.combat.raidersInterceptModification;
                }
            }

            attack += s.quantity * attackToUse;
        }
        return attack;
    }

    get TotalDefence(): number {
        let defence = 0;
        for (let s of this.Ships) {
            defence += s.quantity * s.ship.defence;
        }
        return defence;
    }

    get TotalCargoCapacity(): number {
        let cargo = 0;
        for (let s of this.Ships) {
            cargo += s.quantity * s.ship.cargoCapacity;
        }
        return cargo;
    }

    get MiningCargoCapacity(): number {
        let cargo = 0;
        for (let s of this.Ships) {
            if (s.ship.miningStrength > 0) {
                cargo += s.quantity * s.ship.cargoCapacity;
            }
        }
        return cargo;
    }

    get TotalSiegeDamage(): number {
        let attack = 0;
        for (let s of this.Ships) {
            attack += s.quantity * s.ship.siegeDamage;
        }
        return attack;
    }

    get TotalMiningStrength(): number {
        let mining = 0;
        for (let s of this.Ships) {
            mining += s.quantity * s.ship.miningStrength;
        }
        return mining;
    }

    get TotalMass(): number {
        let mass = 0;
        for (let s of this.Ships) {
            mass += s.quantity * s.ship.mass;
        }
        return mass;
    }

    get SolarSystemsPerHour(): number {
        let moveSpeed = Number.MAX_SAFE_INTEGER;
        for (let s of this.Ships) {
            if (s.ship.solarSystemsPerHour < moveSpeed && s.quantity > 0) {
                moveSpeed = s.ship.solarSystemsPerHour;
            }
        }
        return moveSpeed;
    }

    get HasRecon(): boolean {
        for (let s of this.Ships) {
            if (s.ship.class === ShipClass.Recon && s.quantity > 0) {
                return true;
            }
        }

        return false;
    }

    get HasOnlyRecon(): boolean {
        for (let s of this.Ships) {
            if (s.ship.class !== ShipClass.Recon && s.quantity > 0) {
                return false;
            }
        }

        return this.HasRecon;
    }

    get NumberOfDrones(): number {
        let drones = 0;
        for (let s of this.Ships) {
            if (s.ship.class === ShipClass.Drone) {
                drones += s.quantity;
            }
        }

        return drones;
    }
}