import * as React from 'react';
import { ISetManifestoInput, PresidentialManifesto, SetManifestoInput } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, UnreadStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { SimpleField } from '../../../Components/Base/Form/FieldHolder';
import { StringInput } from '../../../Components/Base/Form/Input';
import { TextAreaInput } from '../../../Components/Base/Form/TextAreaInput';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    presidentialTermId: number,
    presidentialManifesto: PresidentialManifesto | undefined,
    reloadCallback: () => any
};

export const ManifestoForm = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const unreadState = React.useContext(UnreadStateContext);

    const [title, setTitle] = React.useState<string>(props.presidentialManifesto !== undefined ? props.presidentialManifesto.title : "");
    const [description, setDescription] = React.useState<string>(props.presidentialManifesto !== undefined ? props.presidentialManifesto.description : "");

    function save() {
        const data: ISetManifestoInput = {
            title: title,
            description: description
        };
        const input = new SetManifestoInput(data);

        return apiState.PoliticsClient.setManifesto(props.presidentialTermId, input).then(_ => {
            unreadState.clearUnread("presidentialTermVote");
            props.reloadCallback();
        });
    }

    return <SubPanel heading={"Your Manifesto"}
        footerContent={
            <ButtonHolder className="is-pulled-right">
                <Button type="danger" action={props.reloadCallback} icon={IconHelper.General.Cancel} text="Cancel" />
                <Button type="action" action={save} icon={IconHelper.General.Confirm} text="Save Manifesto" />
            </ButtonHolder>
        }
    >
        <SimpleField name="Title">
            <StringInput value={title} valueChanged={setTitle} />
        </SimpleField>
        <SimpleField name="Description">
            <TextAreaInput
                value={description}
                valueChanged={setDescription}
                characterLimit={1000}
            />
        </SimpleField>
    </SubPanel>;
};