import * as React from 'react';
import { ItemType } from '../../../ApplicationState/ApiClient';
import { Validation, ValidationWithContent } from '../../../Entities/Validation';
import { FormFooter } from '../../Base/Form/FormFooter';
import { ResourceIcon } from '../Icons/Items/ResourceIcon';

type Props = {
  buildError: string | ItemType[] | undefined
};

export const BuildValidation = (props: Props) => {

  function error(buildError: string | ItemType[] | undefined): Validation | ValidationWithContent {
    if (!buildError) {
      return {
        valid: true,
        value: ""
      };
    }
    if (typeof buildError === "string") {
      return {
        valid: false,
        value: buildError
      };
    }
    return {
      valid: false,
      content: <>
        Not enough
        {buildError.map(i => <ResourceIcon key={i.typeName} resourceType={i} />)}
      </>
    };
  }

  return <FormFooter
    validation={error(props.buildError)}
    validationPosition="before"
    className="is-aligned-bottom-right"
  />;
};