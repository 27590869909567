import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { IItemsDetail, ItemCategory } from "../../../ApplicationState/ApiClient";
import { SolarSystemStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../Components/Base/Buttons/ButtonHolder";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Icon } from "../../../Components/Base/Icon";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { ItemIconWithQuantity } from "../../../Components/FusionShift/Icons/Items/ItemIconWithQuantity";
import { CommoditySummaryTable } from "../../../Components/FusionShift/Items/CommoditySummaryTable";
import { ItemCapacityBar } from "../../../Components/FusionShift/Items/ItemCapacityBar";
import { RelicTypeLink } from "../../../Components/FusionShift/Links/Items/RelicTypeLink";
import { IconHelper } from "../../../Helpers/IconHelper";
import { FancyItem } from "../../../Helpers/ItemsHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { empire } from "../../Routing/Misc";
import { selected_system_items } from "../../Routing/SolarSystem";
import { AllSystemsResources } from "./AllSystemsResources";
import { ComponentCapacityBar } from "./ComponentCapacityBar";

type Props = {
    itemsDetail: IItemsDetail,
    items: { [key: string]: number },
    fancyItems: FancyItem[],
    otherCapacities: { [key in keyof typeof ItemCategory]?: number; },
    closeCallback: () => any
}

type ItemProps = {
    item: FancyItem,
    hideCapacity?: boolean
}

const Item = (props: ItemProps) => {
    const i = props.item;
    const dangerClass = i.isDanger ? "has-text-danger" : i.isWarning ? "has-text-warning" : "";

    return <>
        <ItemIconWithQuantity itemType={i.itemType} quantity={i.quantity ?? 0} capacity={props.hideCapacity ? undefined : i.capacity} quantityPerSec={i.balancePerSec} showName {...props} />
        {i.balancePerSec !== 0 && <span className={i.balancePerSec < 0 ? `per-hour ${dangerClass}` : "per-hour "}>
            ({ValueFormatter.formatLocaleNumber(i.balancePerSec * 60 * 60)} per hour)
        </span>}
    </>;
}

const ItemsPeekDetail = (props: Props) => {
    const items = props.fancyItems;

    const resources = items.filter(x => x.itemType.category === ItemCategory.Resource);

    const rawResources = items.filter(x => x.itemType.category === ItemCategory.RawResource);
    const rawResourcesCount = rawResources.reduce((v, i) => v + i.quantity, 0);

    const components = items.filter(x => x.itemType.category === ItemCategory.Component);
    const componentsCount = items.filter(x => x.itemType.category === ItemCategory.Component).reduce((v, i) => v + i.quantity, 0);

    const commoditiesCount = items.filter(x => x.itemType.category === ItemCategory.Commodity).reduce((v, i) => v + i.quantity, 0);

    const relics = items.filter(x => x.itemType.category === ItemCategory.Relic);
    const relicsCount = relics.reduce((v, i) => v + i.quantity, 0);

    const nonCommodityContent = <>
        <SubPanel heading={{ text: "Raw Resources", icon: IconHelper.Items.RawResource }}>
            <ItemCapacityBar itemCategory={ItemCategory.RawResource} quantity={rawResourcesCount} capacity={props.otherCapacities && props.otherCapacities.RawResource} />
            <br />
            <ul>
                {rawResources.map(x => <li key={x.itemType.typeName}>
                    <Item item={x} hideCapacity />
                </li>)}
            </ul>
        </SubPanel>
        <SubPanel heading={{ text: "Components", icon: IconHelper.Items.Component }}>
            <ComponentCapacityBar capacity={(props.otherCapacities && props.otherCapacities.Component) ?? 0} components={components} />
            <ul>
                {components.map(x => <li key={x.itemType.typeName}>
                    <Item item={x} hideCapacity />
                </li>)}
            </ul>
        </SubPanel>
        {relicsCount > 0 && <SubPanel heading={{ text: "Relics", icon: IconHelper.Items.Relic }}>
            <Paragraph>Relics will generate <Icon icon={IconHelper.Federations.ResearchPoint} /> Research Points over time. Holding multiple relics in the same system does not increase research speed.</Paragraph>
            <br />
            <ul>
                {relics.map(x => <li key={x.itemType.typeName}>
                    <RelicTypeLink relicType={x.itemType} />
                </li>)}
            </ul>
        </SubPanel>}
    </>;

    return <SplitLayout
        left={<>
            <SubPanel heading={{ text: "Resources", icon: IconHelper.Items.Resource }}>
                <ul>
                    {resources.map(r => <li key={r.itemType.typeName}>
                        <Item item={r} />
                    </li>)}
                </ul>
            </SubPanel>
            {commoditiesCount > 0 && nonCommodityContent}
        </>}
        right={<>
            <SubPanel heading={{ text: "Commodities", icon: IconHelper.Items.Commodity }}>
                <ItemCapacityBar itemCategory={ItemCategory.Commodity} quantity={commoditiesCount} capacity={props.otherCapacities && props.otherCapacities.Commodity} />
                <br />
                <CommoditySummaryTable commodities={props.items} production={props.itemsDetail?.manufactories.produced} />
            </SubPanel>
            {commoditiesCount === 0 && nonCommodityContent}
        </>}
    />;
};

export const ItemsPeek = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [view, setView] = React.useState<"System" | "Empire">("System");

    function empireView() {
        setView("Empire");
    }
    function systemView() {
        setView("System");
    }

    const canEmpire = solarSystemState?.SolarSystems?.length ?? 0 > 1;

    return <div className="items-peek panel">
        <div className="wrapper">
            <div className="inner">
                <ButtonHolder isPulledRight>
                    {view === "Empire" &&
                        <Button type="nav" text="Detail" icon={IconHelper.SolarSystems.Colony} action={systemView} />
                    }
                    {view === "System" && canEmpire &&
                        <Button type="nav" text="Empire" icon={IconHelper.Categories.Empire} action={empireView} />
                    }
                    <Button type="nav" text="Close" icon={IconHelper.General.Close} action={props.closeCallback} />
                </ButtonHolder>
                {view === "System" &&
                    <Paragraph>
                        View more information at the <Link onClick={props.closeCallback} to={selected_system_items}>resource detail view</Link>.
                    </Paragraph>
                }
                {view === "Empire" &&
                    <Paragraph>
                        View more information at the <Link onClick={props.closeCallback} to={empire}>empire view</Link>.
                    </Paragraph>
                }
                {view === "System" &&
                    <ItemsPeekDetail {...props} />
                }
                {view === "Empire" && canEmpire &&
                    <>
                        {solarSystemState.SolarSystemsDetail === undefined && <LoadingSpinner />}
                        {solarSystemState.SolarSystemsDetail !== undefined && <AllSystemsResources solarSystems={solarSystemState.SolarSystemsDetail} />}
                    </>
                }
            </div>
        </div>
    </div>;
});