import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext, PremiumBalanceStateContext } from '../../../ApplicationState/ContextRoot';
import { QuantityIcon } from '../../../Components/FusionShift/Icons/QuantityIcon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { premium } from '../../Routing/Premium';
import { MenuLink } from '../MenuState';

export const PremiumLink = observer(() => {

    const appState = React.useContext(AppStateContext);
    const premiumBalanceState = React.useContext(PremiumBalanceStateContext);

    if (!appState.IsPremiumEnabled) {
        return null;
    }

    return <MenuLink to={premium} className="navbar-item">
        <span>
            {"Premium "}
        </span>
        <QuantityIcon name="Fusion Beans" icon={IconHelper.Premium.Currency} quantity={premiumBalanceState.Balance} />
    </MenuLink>;
});