import * as React from 'react';
import { SendShipsHelper } from '../../../Helpers/SendShipsHelper';
import { SendShipsBag } from '../SendShipsBag';
import { CargoStage } from './CargoStage';
import { CollectionStage } from './CollectionStage';
import { MovementTypeStage } from './MovementTypeStage';
import { ShipsStage } from './ShipsStage';
import { SendShipsStage } from './StagesControl';
import { SummaryStage } from './SummaryStage';
import { TargetStage } from './TargetStage';
import { TradeStage } from './TradeStage';

export type StageProps = {
    send: () => any,
    previousStage: () => any,
    nextStage: () => any,
    sendShipsBag: SendShipsBag
}

type Props = {
    currentStage: SendShipsStage,
    changeStage: (stage: SendShipsStage) => any,
    sendShipsBag: SendShipsBag,
    send: () => any
}

export const StageRender = (props: Props) => {

    function createNextStage(stage: SendShipsStage) {
        return () => props.changeStage(SendShipsHelper.nextStageFor(stage, props.sendShipsBag));
    }

    function createPreviousStage(stage: SendShipsStage) {
        return () => props.changeStage(SendShipsHelper.previousStageFor(stage, props.sendShipsBag));
    }

    function render(stage: SendShipsStage, toRender: (p: StageProps) => React.ReactNode) {
        if (props.currentStage !== stage) {
            return null;
        }

        const propsHere = {
            previousStage: createPreviousStage(stage),
            nextStage: createNextStage(stage),
            sendShipsBag: props.sendShipsBag,
            send: props.send
        };

        return toRender(propsHere);
    }

    return <>
        {render("Target", p => <TargetStage {...p} />)}
        {render("Movement Type", p => <MovementTypeStage {...p} />)}
        {render("Ships", p => <ShipsStage {...p} />)}
        {render("Cargo", p => <CargoStage {...p} />)}
        {render("Collection", p => <CollectionStage {...p} />)}
        {render("Trade", p => <TradeStage {...p} />)}
        {render("Summary", p => <SummaryStage {...p} />)}
    </>;
};