import * as React from 'react';
import { MissionWrapper } from '../MissionWrapper';
import { Help } from './Help';
import { Objectives } from './Objectives';
import { Prerequisites } from './Prerequisites';

type Props = {
    mission: MissionWrapper
}

export const Info = (props: Props) => {

    return <>
        <Objectives mission={props.mission} />
        <Help mission={props.mission} />
        <Prerequisites mission={props.mission} />
    </>;
};

