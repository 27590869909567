import { MissionType, PlayerMissionLog } from "../../ApplicationState/ApiClient";

export type MissionStatus = "Active" | "Reward Pending" | "Complete" | "Locked";

export class MissionWrapper {

    private _missionType: MissionType;
    private _status: MissionStatus = "Locked";
    private _playerMissionLog: PlayerMissionLog;

    constructor(playerMissionLog: PlayerMissionLog, missionType: MissionType) {
        this._missionType = missionType;
        this._playerMissionLog = playerMissionLog;

        if (playerMissionLog.activeMissions && missionType.typeName in playerMissionLog.activeMissions) {
            this._status = "Active";
        } else if (playerMissionLog.rewardPendingMissions.includes(missionType.typeName)) {
            this._status = "Reward Pending";
        } else if (playerMissionLog.completedMissions.includes(missionType.typeName)) {
            this._status = "Complete";
        }
    }

    public get MissionType() { return this._missionType; }
    public get Status() { return this._status; }

    public get IsCompleteOrRewardPending() { return this._status === "Complete" || this._status === "Reward Pending"; }

    public get Progress() { return this._playerMissionLog.activeMissions && this._missionType.typeName in this._playerMissionLog.activeMissions && this._playerMissionLog.activeMissions[this._missionType.typeName]; }

    public get HasObjectives() {
        if (this._missionType.installationRequired !== undefined) {
            return true;
        }
        if (this._missionType.isFederationRequired === true) {
            return true;
        }
        if (this._missionType.isCapitalSystemRenameRequired === true) {
            return true;
        }
        if (!!this._missionType.manufactoryInputNodesRequired) {
            return true;
        }
        if (!!this._missionType.manufactoryOutputNodesRequired) {
            return true;
        }
        if (!!this._missionType.marketOrdersRaisedRequired) {
            return true;
        }
        if (!!this._missionType.megacityDesignatedAssignedPopulationRequired) {
            return true;
        }
        if (!!this._missionType.shipBuildRequired) {
            return true;
        }
        if (!!this._missionType.fleetMovementTypeRequired) {
            return true;
        }
        if (!!this._missionType.solarSystemsRequired) {
            return true;
        }
        if (!!this._missionType.droneStatusRequired) {
            return true;
        }
        return false;
    }
}
