import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { WorldStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { SplitLayout } from '../../../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { SolarSystemWrapper } from '../../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { system_subView } from '../../../../../Navigation/Routing/SolarSystem';
import { UpkeepSummary } from '../../../../Fleets/Components/UpkeepSummary';
import { MiniFleetView } from './MiniFleetView';
import { Problems } from './Problems';
import { Stats } from './Stats';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
    changeUrl: (url: string) => any
}

export const Ships = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ShipTypeSettings || !worldState.ItemTypeSettings) {
        return null;
    }

    const ships = props.solarSystemWrapper.fleets;

    return <SubPanel heading={{ text: "Ships", icon: IconHelper.Ships.Ship }} headingContent={<Button type="nav" icon={IconHelper.Ships.Ship} action={() => props.changeUrl(system_subView("ships"))} text="Ships" hideTextOnMobile />} >
        <Problems {...props} />
        <Stats {...props} />
        <UpkeepSummary
            itemTypeSettings={worldState.ItemTypeSettings}
            items={props.solarSystemWrapper.solarSystem.items}
            itemsDetail={props.solarSystemWrapper.solarSystem.itemsDetail}
        />
        <SplitLayout
            isLeftEmpty={!ships.showDepartures}
            isRightEmpty={!ships.showArrivals}
            left={ships.showDepartures && <MiniFleetView type="Departures" fleets={ships.departures} shipTypeSettings={worldState.ShipTypeSettings} />}
            right={ships.showArrivals && <MiniFleetView type="Arrivals" fleets={ships.arrivals} shipTypeSettings={worldState.ShipTypeSettings} />}
        />
    </SubPanel>;
});