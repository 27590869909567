import * as React from 'react';
import { IGameSettings, IShipTypeSettings } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { DictionaryTable } from '../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    gameSettings: IGameSettings,
    shipTypeSettings: IShipTypeSettings,
    ships: { [key: string]: number; },
    hideAttack?: boolean
}

export const StatsSummary = (props: Props) => {

    const fleetWrapper = React.useMemo(() => {

        return new FleetWrapper(props.shipTypeSettings, props.gameSettings, props.ships);

    }, [props.gameSettings, props.shipTypeSettings, props.ships]);

    const content: {
        Attack?: string,
        Defence: string,
        Mass: string
    } = {
        "Attack": ValueFormatter.formatLocaleNumber(fleetWrapper.TotalAttack(false)),
        "Defence": ValueFormatter.formatLocaleNumber(fleetWrapper.TotalDefence),
        "Mass": ValueFormatter.formatMass(fleetWrapper.TotalMass, false)
    };

    if (props.hideAttack) {
        delete content["Attack"];
    }

    return <SubPanel heading="Stats">
        <Paragraph>Showing raw stats for selected ships, not accounting for bonuses or situational combat modifiers.</Paragraph>
        <DictionaryTable content={content} />
    </SubPanel>;
}