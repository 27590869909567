import classNames from 'classnames';
import * as React from 'react';
import { BackgroundImage, BackgroundImageHelper } from '../../../Helpers/BackgroundImageHelper';
import { H1 } from '../Text/H';
import { HeadingContent, PanelHeading } from './PanelHeading';

export type Props = {
    heading: HeadingContent,
    headingContent?: React.ReactNode | undefined,
    backgroundImage?: BackgroundImage
    children: React.ReactNode,
    className?: string | undefined,
    isClipped?: boolean,
    isPaddedMobile?: boolean
}

export const Panel = (props: Props) => {

    const className = classNames(
        "panel",
        props.className,
        {
            "clipped": props.isClipped,
            "is-padded-mobile": props.isPaddedMobile
        },
        props.backgroundImage && BackgroundImageHelper.className(props.backgroundImage));

    return <div className={className}>
        <div className="header">
            <H1><PanelHeading content={props.heading} /></H1>
            {props.headingContent !== undefined && <div className="heading-content">{props.headingContent}</div>}
        </div>
        <div className="wrapper">
            <div className="inner">
                {props.children}
            </div>
        </div>
    </div>;
};
