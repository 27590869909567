import { observer } from "mobx-react-lite";
import React from "react";
import { ISolarSystemDetail, SolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../Components/Base/Containers/Table";
import { DateIcon } from "../../../Components/FusionShift/Icons/DateIcon";
import { ShipList, ShipListInner, buildShips } from "../../../Components/FusionShift/Ships/ShipList";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ActivatingPromptText, GroundedPromptText } from "../Components/Prompts";
import { ActivateShipsForm } from "../Forms/ActivateShipsForm";

type Props = {
    hideExtras?: boolean,
    isCompressed?: boolean,
    solarSystem: ISolarSystemDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const GroundedShipsControl = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [showActivating, setShowActivating] = React.useState(false);

    const hasGrounded = props.solarSystem && CollectionHelper.isAnyQuantityInDictionary(props.solarSystem.groundedShips);
    const hasActivating = props.solarSystem && CollectionHelper.isAnyInArray(props.solarSystem.activatingShips);

    if ((!hasActivating && !hasGrounded) || !worldState.ShipTypeSettings) {
        return null;
    }

    function activateShips(ships: { [key: string]: number }) {
        return apiState.ShipClient.activateShips(props.solarSystem.solarSystemId, ships).then(solarSystem => {
            props.reloadCallback(solarSystem);
            setShowActivating(false);
        });
    }

    function cancelActivation(activatingShipsId: string) {
        return apiState.ShipClient.cancelActivation(props.solarSystem.solarSystemId, activatingShipsId).then(solarSystem => {
            props.reloadCallback(solarSystem);
        });
    }

    if (showActivating && hasGrounded) {
        return <ActivateShipsForm
            closeCallback={() => setShowActivating(false)}
            activateShipsCallback={activateShips}
            ships={props.solarSystem.groundedShips!}
            solarSystem={props.solarSystem}
        />;
    }

    const ships = buildShips(worldState.ShipTypeSettings, props.solarSystem.groundedShips);

    return <SplitLayout
        isLeftEmpty={!hasGrounded}
        isRightEmpty={!hasActivating}
        left={hasGrounded && <SubPanel
            heading={{ text: "Grounded", icon: IconHelper.Ships.Grounded }}
            isUnpadded={props.isCompressed}
            headingContent={
                hasGrounded && !props.hideExtras && <Button type="nav" text="Activate Ships" icon={IconHelper.Ships.Activate} action={() => setShowActivating(true)} isPulledRight />
            }
        >
            {!props.isCompressed &&
                <GroundedPromptText />
            }
            <ShipListInner ships={ships} />
        </SubPanel>
        }
        right={hasActivating &&
            <SubPanel
                heading={{ text: "Activating", icon: IconHelper.Ships.Activate }}
                isUnpadded={props.isCompressed}
            >
                {!props.isCompressed &&
                    <ActivatingPromptText />
                }
                <Table isHoverable isFullWidth>
                    {props.solarSystem.activatingShips!.map(s => <tr key={s.activatingShipsId}>
                        <td>
                            <DateIcon date={s.activationCompletedDate} /><br />
                            <Button
                                type="warning"
                                icon={IconHelper.Ships.Grounded}
                                text="Cancel"
                                action={() => cancelActivation(s.activatingShipsId)}
                            />
                        </td>
                        <td>
                            <ShipList ships={s.ships} isOnePerLineOnMobile />
                        </td>
                    </tr>)}
                </Table>
            </SubPanel>
        }
    />
});