import { CelestialDetail, CelestialSize, ICelestial, ICelestialDetail, ICelestialTypeSettings, IInstallationTypeSettings, IItemTypeSettings, Installation, InstallationBuildQueueType, ISolarSystemDetail } from "../ApplicationState/ApiClient";
import { celestialsImageConfig } from "../images/CelestialsImageConfig";
import { BackgroundImage } from "./BackgroundImageHelper";
import { CollectionHelper } from "./CollectionHelper";
import { DroneHelper } from "./DroneHelper";
import { MathHelper } from "./MathHelper";
import { TextHelper } from "./TextHelper";

export type InstallationFeature = { installationId: number };
export type CelestialFeature = "Drones" | "Megacity" | "Manufactory" | "Build" | "Ships" | InstallationFeature;

export class CelestialHelper {

    public static celestialBackground(celestial: { celestialId: number, celestialTypeName?: string | undefined | null } | undefined, isOccupied: boolean): BackgroundImage | undefined {

        const isUnknown = celestial === undefined || celestial.celestialTypeName === undefined || celestial.celestialTypeName === null || celestial.celestialTypeName.length === 0;
        let key = "Unknown";

        if (!isUnknown) {
            key = isOccupied && celestial.celestialTypeName === "Habitable" ? "HabitableOccupied" : celestial.celestialTypeName!;
        }

        if (!(key in celestialsImageConfig)) {
            return undefined;
        }

        let variant = celestial?.celestialId ?? 0;
        const num = celestialsImageConfig[key];

        while (variant >= num) {
            variant -= num;
        }

        return {
            variationSource: key,
            variant,
            type: "celestial"
        };
    }

    public static celestialTypeName(celestial: CelestialDetail, celestialTypeSettings: ICelestialTypeSettings) {
        if (celestial.celestialTypeName !== undefined && celestial.celestialTypeName !== null && celestial.celestialTypeName in celestialTypeSettings.data) {
            return celestialTypeSettings.data[celestial.celestialTypeName].name;
        }

        return "Unknown";
    }

    public static solarSystemStarClassNames(solarSystem: { solarSystemId: number, size: number, x: number; y: number }) {

        const sizeNumber = Math.floor(solarSystem.size / 50);

        const size = `size-${MathHelper.clamp(sizeNumber, 1, 5)}`;
        const a = `variation-a-${TextHelper.hashTextToNumber(solarSystem.solarSystemId, 5)}`;
        const b = `variation-b-${TextHelper.hashTextToNumber(solarSystem.solarSystemId + solarSystem.x, 5)}`;
        const c = `variation-c-${TextHelper.hashTextToNumber(solarSystem.solarSystemId - solarSystem.y, 5)}`;

        return `${a} ${b} ${c} ${size}`;
    }

    public static celestialFeatures(celestial: ICelestialDetail, installationTypeSettings: IInstallationTypeSettings, noInstallations?: boolean) {
        const features: CelestialFeature[] = [];

        if (celestial.installations && !noInstallations) {
            for (let i of celestial.installations) {
                if (i !== undefined && i.installationId !== undefined) {
                    features.push({ installationId: i.installationId });
                }
            }
        }
        if (DroneHelper.hasDronesAtCelestial(installationTypeSettings, celestial)) {
            features.push("Drones");
        }
        if (celestial.megacity) {
            features.push("Megacity");
        }
        if (celestial.manufactory) {
            features.push("Manufactory");
        }
        if (!!celestial.installationTypeNamesThatCanBeBuilt && celestial.installationTypeNamesThatCanBeBuilt.length > 0) {
            features.push("Build");
        }
        if (CollectionHelper.isAnyInDictionary(celestial.shipTypeNamesThatCanBeBuilt)) {
            features.push("Ships");
        }

        return features;
    }

    public static renderCelestialTypeName(celestialTypeSettings: ICelestialTypeSettings, celestials: ICelestial[]) {
        if (!celestials || celestials.length === 0) {
            return "";
        }

        let returnData: ICelestial | undefined = undefined;

        for (const celestial of celestials) {
            const celestialType = celestial.celestialTypeName === null || celestial.celestialTypeName !== undefined && celestial.celestialTypeName in celestialTypeSettings.data ? celestialTypeSettings.data[celestial.celestialTypeName] : undefined;
            if (celestialType && celestialType.isHabitable) {
                return celestialType.typeName;
            }
            if (!returnData || celestial.size > returnData.size) {
                returnData = celestial;
            }
        }

        if (returnData) {
            return returnData.celestialTypeName;
        }
        return "";
    }

    public static fullname(celestial: { name: string, size: CelestialSize, celestialTypeName?: string }, celestialTypeSettings: ICelestialTypeSettings): string {

        const celestialTypeName = celestial.celestialTypeName === null || celestial.celestialTypeName === undefined ? "Unknown" : celestial.celestialTypeName in celestialTypeSettings.data ? celestialTypeSettings.data[celestial.celestialTypeName].name : celestial.celestialTypeName;

        const size = typeof celestial.size === "string" ? celestial.size : CelestialSize[celestial.size];

        return `${celestial.name}: ${size} ${celestialTypeName}`;
    }

    public static mapInstallationsForSolarSystem<T>(solarSystem: ISolarSystemDetail, map: (installation: Installation, celestial: ICelestialDetail) => T): T[] {
        if (!solarSystem.celestials) {
            return [];
        }

        return solarSystem.celestials.map(c => this.mapInstallations(c, i => map(i, c))).flat();
    }

    public static mapInstallations<T>(celestial: ICelestialDetail, map: (installation: Installation) => T): T[] {
        if (!celestial.installations) {
            return [];
        }

        return celestial.installations.filter(i => !!i && i.installationTypeName !== undefined).map(map);
    }

    public static buildInstallationForSolarSystem(installationTypeSettings: IInstallationTypeSettings, solarSystem: ISolarSystemDetail) {
        return this.mapInstallationsForSolarSystem(solarSystem, (i, c) => {
            return {
                installationType: installationTypeSettings.data[i.installationTypeName],
                installation: i,
                celestial: c
            };
        });
    }

    public static resourceTypeFromCelestials(celestialTypeSettings: ICelestialTypeSettings, itemTypeSettings: IItemTypeSettings, celestialId: number | undefined, celestials: ICelestial[]) {
        const celestial = celestials.find(c => c.celestialId == celestialId);
        return this.itemTypeFromCelestial(celestialTypeSettings, itemTypeSettings, celestial);
    }

    public static itemTypeFromCelestial(celestialTypeSettings: ICelestialTypeSettings, itemTypeSettings: IItemTypeSettings, celestial: ICelestial | undefined) {
        if (celestial) {
            if (celestial.celestialTypeName !== undefined && celestial.celestialTypeName in celestialTypeSettings.data) {
                const celestialType = celestialTypeSettings.data[celestial.celestialTypeName];
                if (celestialType.generatedItemTypeName in itemTypeSettings.data) {
                    return itemTypeSettings.data[celestialType.generatedItemTypeName];
                }
            }
        }
        return undefined;
    }

    private static installationFilter(installation: { installationId: number }, forInstallationId?: number | undefined) {
        if (forInstallationId === undefined) {
            return true;
        }

        return installation.installationId === forInstallationId;
    }

    public static isDamaged(celestial: ICelestialDetail, forInstallationId?: number | undefined) {
        return celestial.installations && celestial.installations.findIndex(i => i !== null && i.damage > 0 && this.installationFilter(i, forInstallationId)) > -1;
    }

    public static hasConstruct(celestial: ICelestialDetail, solarSystem: ISolarSystemDetail, forInstallationId?: number | undefined) {
        return solarSystem.installationBuildQueue && solarSystem.installationBuildQueue.findIndex(f => f.celestialId === celestial.celestialId && f.type !== InstallationBuildQueueType.Deconstruct && this.installationFilter(f, forInstallationId)) > -1;
    }

    public static hasDeconstruct(celestial: ICelestialDetail, solarSystem: ISolarSystemDetail, forInstallationId?: number | undefined) {
        return solarSystem.installationBuildQueue && solarSystem.installationBuildQueue.findIndex(f => f.celestialId === celestial.celestialId && f.type === InstallationBuildQueueType.Deconstruct && this.installationFilter(f, forInstallationId)) > -1;
    }

    public static isBuildingShips(celestial: ICelestialDetail, solarSystem: ISolarSystemDetail) {
        return solarSystem.shipBuildQueueByCelestial && Object.keys(solarSystem.shipBuildQueueByCelestial).findIndex(f => f === celestial.celestialId.toString() && CollectionHelper.isAnyInArray(solarSystem.shipBuildQueueByCelestial![f])) > -1;
    }
}