import * as React from 'react';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { TickOrCrossIcon } from '../../../Components/FusionShift/Icons/TickOrCrossIcon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { MissionWrapper } from '../MissionWrapper';
import { CapitalSystemRenameRequired } from './Objectives/CapitalSystemRenameRequired';
import { DroneStatusRequired } from './Objectives/DroneStatusRequired';
import { EmblemRequired } from './Objectives/EmblemRequired';
import { FederationRequired } from './Objectives/FederationRequired';
import { FleetMovementTypeRequired } from './Objectives/FleetMovementTypeRequired';
import { InstallationRequired } from './Objectives/InstallationRequired';
import { ManufactoryNodesRequired } from './Objectives/ManufactoryNodesRequired';
import { MarketOrdersRaisedRequired } from './Objectives/MarketOrdersRaisedRequired';
import { MegacityDesignatedAssignedPopulationRequired } from './Objectives/MegacityDesignatedAssignedPopulationRequired';
import { ShipBuildRequired } from './Objectives/ShipBuildRequired';
import { SolarSystemsExploredRequired } from './Objectives/SolarSystemsExploredRequired';
import { SolarSystemsRequired } from './Objectives/SolarSystemsRequired';

type Props = {
    mission: MissionWrapper
}

type RowProps = {
    mission: MissionWrapper,
    children: React.ReactNode,
    has?: number,
    requires?: number
}

export const ObjectiveRow = (props: RowProps) => {

    const ticksAndCrosses: ("tick" | "cross")[] = [];

    let ticks = props.has ? props.has : 0;
    let crosses = props.requires ? props.requires - ticks : 0;

    if (props.requires && props.mission.IsCompleteOrRewardPending) {
        crosses = 0;
        ticks = props.requires;
    }

    if (ticks) {
        for (var i = 0; i < ticks; i++) {
            ticksAndCrosses.push("tick");
        }
    }
    if (crosses) {
        for (var i = 0; i < crosses; i++) {
            ticksAndCrosses.push("cross");
        }
    }

    return <li className="mission-objective">
        <span>
            {props.children}
        </span>
        <span className="tick-or-crosses">
            {ticksAndCrosses.map((c, i) => <TickOrCrossIcon key={i} type={c} />)}
        </span>
    </li>;
}

export const Objectives = (props: Props) => {

    if (!props.mission.HasObjectives) {
        return null;
    }

    return <SubPanel heading={{ text: "Objectives", icon: IconHelper.Missions.Objective }}>
        <ul>
            <SolarSystemsExploredRequired {...props} />
            <InstallationRequired {...props} />
            <CapitalSystemRenameRequired {...props} />
            <EmblemRequired {...props} />
            <FederationRequired {...props} />
            <ManufactoryNodesRequired {...props} nodeType="input" />
            <ManufactoryNodesRequired {...props} nodeType="output" />
            <MarketOrdersRaisedRequired {...props} />
            <MegacityDesignatedAssignedPopulationRequired {...props} />
            <ShipBuildRequired {...props} />
            <FleetMovementTypeRequired {...props} />
            <SolarSystemsRequired {...props} />
            <DroneStatusRequired {...props} />
        </ul>
    </SubPanel>;
};