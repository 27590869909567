import { makeAutoObservable } from "mobx";
import { AppState } from "./AppState";

const key = "DEVSETTINGS";

type Data = {
    hideCelestialRender?: boolean
}

export class DevState {

    public data: Data = {};

    constructor(private _appState: AppState) {
        makeAutoObservable(this);

        this.data = this._appState.UserPrefs.getUserPrefsObjectOrDefault(key, {});
    }

    public setData(data: Data) {
        this.data = data;

        this._appState.UserPrefs.setUserPrefsObject(key, data);
    }
}