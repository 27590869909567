import classNames from "classnames";
import * as React from 'react';
import { Link } from "react-router-dom";

export type SvgImageBaseProps = {
    className?: string,
    size?: ImageSize,
    children?: React.ReactNode | undefined,
    to?: string | undefined
}

type Props = SvgImageBaseProps & {
    filename: string,
    type: string
}

export type ImageSize = "large" | "regular" | "medium" | "small" | "tiny";

export const SvgImage = (props: Props) => {

    const className = classNames("image-holder",
        props.type,
        `size-${props.size}`,
        props.className
    );

    const content = <>
        <img src={`/images/${props.type}s/${props.filename}.svg`} />
        {props.children !== undefined && props.children}
    </>;

    return <div className={className}>
        {props.to !== undefined && <Link to={props.to}>{content}</Link>}
        {props.to === undefined && content}
    </div>;
}

SvgImage.defaultProps = {
    size: "large"
};