import classNames from 'classnames';
import * as React from 'react';

type Props = {
    children: React.ReactNode,
    isContentVerticallyCentered?: boolean,
    isContentHorizontallyCentered?: boolean,
    className?: string | undefined
}

export const FlexDiv = (props: Props) => {

    const className = classNames(
        "is-flex",
        "flex-wrap",
        props.className,
        {
            "is-content-vertically-centered": props.isContentVerticallyCentered,
            "is-content-horizontally-centered": props.isContentHorizontallyCentered
        });

    return <div className={className}>
        {props.children}
    </div>
};