import { ContractType, Fleet, FleetMovementType, IContract, IFleet, IGameSettings, IPlayer, IPlayerMinimal, ISolarSystem, ISolarSystemDetail, ISolarSystemForObserver, SecurityStatus, WorldRegion } from "../ApplicationState/ApiClient";
import { FleetWrapper } from "../Entities/FleetWrapper";
import { Coordinate } from "../Entities/Shared";
import { AgentHelper } from "./AgentHelper";
import { CollectionHelper } from "./CollectionHelper";
import { RelationHelper } from "./RelationHelper";

export type SolarSystemFleets = {
    allFleets: Fleet[],
    arrivals: Fleet[],
    departures: Fleet[],
    presentForeign: Fleet[],
    showArrivals: boolean,
    showDepartures: boolean,
    showAvailable: boolean,
    showGrounded: boolean,
    showPresentForeign: boolean,
    showRepeats: boolean
}

type FleetLike = {
    ships?: { [key: string]: number; }
}

export class FleetHelper {

    public static sumOfShipsFromFleets<T extends FleetLike>(fleets: (T | undefined)[] | undefined, filter?: ((fleet: T) => boolean) | undefined) {
        if (fleets) {

            const nonUndefinedFleets = fleets.filter(x => x !== undefined).map(x => x!);
            const ships = filter !== undefined ? nonUndefinedFleets.filter(filter).map(s => s.ships) : nonUndefinedFleets.map(s => s.ships);

            return CollectionHelper.mergeQuantityDictionaries(ships);
        }
        return {};
    };

    public static solarSystemFleets(solarSystem: ISolarSystemDetail): SolarSystemFleets {

        const fleets = solarSystem.fleets ?? [];

        const arrivals = fleets.filter(x => x.targetSolarSystem && x.targetSolarSystem.solarSystemId === solarSystem.solarSystemId && !this.isArrivedAndPresentInSolarSystem(x));
        const departures = fleets.filter(x => x.homeSolarSystemId === solarSystem.solarSystemId && x.movementType !== FleetMovementType.ReturnHome);
        const presentForeign = fleets.filter(x => x.targetSolarSystem && x.targetSolarSystem.solarSystemId === solarSystem.solarSystemId && this.isArrivedAndPresentInSolarSystem(x));

        const showPresentForeign = presentForeign.length > 0;
        const showArrivals = arrivals && arrivals.length > 0;
        const showDepartures = departures && departures.length > 0;

        const showAvailable = CollectionHelper.isAnyQuantityInDictionary(solarSystem.availableShips);
        const showGrounded = CollectionHelper.isAnyQuantityInDictionary(solarSystem.groundedShips) || CollectionHelper.isAnyInArray(solarSystem.activatingShips);

        const showRepeats = !!solarSystem.repeatFleets && solarSystem.repeatFleets.length > 0;

        return {
            allFleets: fleets,
            arrivals,
            departures,
            presentForeign,
            showArrivals,
            showDepartures,
            showAvailable,
            showGrounded,
            showPresentForeign,
            showRepeats
        };
    }

    public static canIntercept(targetFleet: IFleet, player: IPlayerMinimal, availableShips: FleetWrapper) {

        if (targetFleet.movementType === FleetMovementType.Recon) {
            return false;
        }

        if (!targetFleet.isInRadarRange) {
            return false;
        }

        if (!availableShips.HasAnyShips || !availableShips.CanAnyShipsIntercept) {
            return false;
        }

        if (targetFleet.ownerPlayerId === player.playerId) {
            return false;
        }

        if (RelationHelper.isAlliedWith(player, targetFleet.owner)) {
            return false;
        }

        if (targetFleet.owner && targetFleet.owner && targetFleet.owner.securityStatus === SecurityStatus.Protected) {
            return false;
        }

        if (targetFleet.arrivalDate === undefined || new Date() >= targetFleet.arrivalDate) {
            return false;
        }

        return true;
    }

    public static couldBeExplored(targetSolarSystem: ISolarSystem | undefined | null) {
        return targetSolarSystem !== undefined && targetSolarSystem != null &&
            (targetSolarSystem.owner === undefined || targetSolarSystem.owner === null) &&
            !targetSolarSystem.isExplored;
    }

    public static allowedMovementTypes(
        sourceSolarSystem: ISolarSystemDetail,
        availableShips: FleetWrapper,
        targetSolarSystem: ISolarSystemForObserver | undefined | null,
        targetFleet: IFleet | undefined | null,
        player: IPlayerMinimal | undefined,
        contract: IContract | undefined,
        isEditingRepeat: boolean) {

        let targetOptions: FleetMovementType[] = [];

        if (!!targetSolarSystem) {

            const agents = AgentHelper.agentAvailability(targetSolarSystem, player?.playerId ?? 0);

            const hasAgent = agents.find(x => x.isAvailable) !== undefined;
            const ownsTarget = player && targetSolarSystem && targetSolarSystem.owner && availableShips.TotalCargoCapacity > 0 && targetSolarSystem.owner.playerId === player.playerId;
            const isCourier = !!contract && (contract.type === ContractType.Courier || contract.type === ContractType.Ransom);

            if (availableShips.TotalAttack(false) > 0 && !RelationHelper.isAlliedWith(player, targetSolarSystem.owner)) targetOptions.push(FleetMovementType.Attack);
            if (availableShips.HasRecon && !RelationHelper.isAlliedWith(player, targetSolarSystem.owner)) targetOptions.push(FleetMovementType.Recon);
            if (availableShips.HasRecon && !targetSolarSystem.owner && !targetSolarSystem.isExplored) targetOptions.push(FleetMovementType.Explore);
            if (isEditingRepeat || availableShips.TotalDefence > 0) targetOptions.push(FleetMovementType.Reinforce);
            if (isEditingRepeat || availableShips.TotalCargoCapacity > 0 && targetSolarSystem.marketOrders.length > 0 && !ownsTarget) targetOptions.push(FleetMovementType.Trade);
            if (isEditingRepeat || availableShips.TotalCargoCapacity > 0) targetOptions.push(FleetMovementType.Delivery);
            if (isEditingRepeat || ownsTarget || isCourier || hasAgent) targetOptions.push(FleetMovementType.Collection);
            if (isEditingRepeat || CollectionHelper.isAnyInDictionary(targetSolarSystem.commodityBuyOffersByCelestialId) && CollectionHelper.isAnyQuantityInDictionary(sourceSolarSystem.items)) targetOptions.push(FleetMovementType.CommoditySell);
            if (isEditingRepeat || availableShips.TotalMiningStrength > 0 && !targetSolarSystem.owner && targetSolarSystem.isExplored) targetOptions.push(FleetMovementType.Mine);
            if (availableShips.CanClaim && !targetSolarSystem.owner) targetOptions.push(FleetMovementType.Claim);
            if (isEditingRepeat || player && player.playerId === targetSolarSystem.playerId) targetOptions.push(FleetMovementType.Rebase);

        } else if (!!targetFleet) {

            if (availableShips.TotalAttack(true) > 0 && !RelationHelper.isAlliedWith(player, targetFleet.owner)) targetOptions.push(FleetMovementType.Attack);
            if (availableShips.HasRecon && !RelationHelper.isAlliedWith(player, targetFleet.owner)) targetOptions.push(FleetMovementType.Recon);
        }

        return targetOptions;
    }

    public static isHostileMovement(fleet: { initialMovementType: FleetMovementType }): boolean {
        return this.isHostileMovementType(fleet.initialMovementType);
    }

    public static isHostileMovementType(movementType: FleetMovementType): boolean {
        return movementType === FleetMovementType.Attack || movementType === FleetMovementType.Recon;
    }

    public static isArrivedAndPresentInSolarSystem(fleet: IFleet): boolean {
        const correctType = fleet.movementType === FleetMovementType.Reinforce || fleet.movementType === FleetMovementType.Mine;
        const arrived = !!fleet.arrivalDate && fleet.arrivalDate.getTime() <= Date.now();
        return correctType && arrived;
    }

    public static canSendHome(fleet: IFleet, playerId: number): boolean {
        if (fleet.isOnContract) {
            return false;
        }
        if (fleet.movementType === FleetMovementType.ReturnHome) {
            return false;
        }
        if (fleet.isInRadarRange && fleet.ownerPlayerId === playerId) {
            return true;
        }
        if (!FleetHelper.isArrivedAndPresentInSolarSystem(fleet)) {
            return false;
        }
        if (fleet.movementType === FleetMovementType.Reinforce) {
            return true;
        }
        if (fleet.targetSolarSystem && fleet.targetSolarSystem.playerId === playerId) {
            return true;
        }
        if (fleet.sourceSolarSystem && fleet.sourceSolarSystem.playerId === playerId) {
            return true;
        }
        return false;
    }

    public static canReinforceJoinSystem(fleet: IFleet, playerId: number): boolean {
        if (fleet.isOnContract) {
            return false;
        }
        if (fleet.movementType !== FleetMovementType.Reinforce) {
            return false;
        }
        if (fleet.ownerPlayerId !== playerId) {
            return false;
        }
        if (!FleetHelper.isArrivedAndPresentInSolarSystem(fleet)) {
            return false;
        }
        if (fleet.targetSolarSystem && fleet.targetSolarSystem.playerId === playerId) {
            return true;
        }
        return false;
    }

    public static areEnoughShipsAvailable(availableShips: { [key: string]: number } | undefined, desiredShips: { [key: string]: number } | undefined): boolean | "partial" {
        if (!CollectionHelper.isAnyQuantityInDictionary(availableShips) || availableShips === undefined || desiredShips === undefined) {
            return false;
        }

        let anyUnsatisfied = false;
        let anySatisfied = false;
        const keys = Object.keys(desiredShips);

        for (let key of keys) {
            if (key in availableShips) {
                if (availableShips[key] >= desiredShips[key]) {
                    anySatisfied = true;
                } else {
                    // Some but not all of this ship is available
                    return "partial"
                }
            } else if (anySatisfied) {
                // Another ship was available but this one isn't
                return "partial";
            } else {
                anyUnsatisfied = true;
            }
        }

        if (anySatisfied && anyUnsatisfied) {
            return "partial";
        }

        return !anyUnsatisfied;
    }

    public static distance(a: Coordinate, b: Coordinate) {
        const x = Math.pow(b.x - a.x, 2);
        const y = Math.pow(b.y - a.y, 2);

        return Math.sqrt(x + y);
    }

    public static hasReturnJourney(movementType: FleetMovementType | undefined) {

        if (movementType === FleetMovementType.Claim ||
            movementType === FleetMovementType.Rebase ||
            movementType === FleetMovementType.Reinforce ||
            movementType === FleetMovementType.ReturnHome) {
            return false;
        }

        return true;
    }

    public static estimateHoursJourneyDuration(solarSystemsPerHour: number, a: Coordinate | undefined, b: Coordinate | undefined, gameSettings: IGameSettings) {
        if (!a || !b) {
            return undefined;
        }

        const distance = this.distance(a, b);
        return ((distance / solarSystemsPerHour) / gameSettings.gameSpeed);
    }

    public static arrivedVerb(movementType: FleetMovementType) {
        if (movementType === FleetMovementType.Mine) {
            return "Mining";
        } else if (movementType === FleetMovementType.Reinforce) {
            return "Reinforcing";
        }
        return "Arrived";
    }

    public static securityStatusChange(movementType: FleetMovementType, player: IPlayer, targetSolarSystem: ISolarSystem): SecurityStatus | undefined {
        if (!this.isHostileMovementType(movementType)) {
            return undefined;
        }

        const targetPlayer = targetSolarSystem.owner;

        if (targetPlayer == undefined || player.securityStatus == SecurityStatus.Exile) {
            return undefined;
        }

        if (movementType == FleetMovementType.Recon) {
            if (player.securityStatus == SecurityStatus.Protected) {
                return SecurityStatus.Civilian;
            }

            return undefined;
        }

        if (targetPlayer.securityStatus == SecurityStatus.Exile) {
            if (player.securityStatus == SecurityStatus.Protected) {
                return SecurityStatus.Civilian;
            }

            return undefined;
        }

        var isCivilianOrProtected = targetPlayer.securityStatus == SecurityStatus.Civilian || targetPlayer.securityStatus == SecurityStatus.Protected;

        if (targetSolarSystem.region == WorldRegion.Core && isCivilianOrProtected) {
            return SecurityStatus.Exile;
        }

        if (player.securityStatus != SecurityStatus.CombatActive) {
            return SecurityStatus.CombatActive;
        }

        return undefined;
    }

    public static calculateCostToSendShips(gameSettings: IGameSettings, fleet: FleetWrapper, distance: number, movementType: FleetMovementType) {
        var totalUpkeepPerHour = fleet.Ships
            .map(s => s.quantity * s.ship.upkeepPerHour)
            .reduce((s, v) => s + v);

        var totalHours = distance / fleet.SolarSystemsPerHour;

        if (movementType != FleetMovementType.Claim && movementType != FleetMovementType.Rebase) {
            totalHours *= 2;
        }

        if (movementType == FleetMovementType.Mine) {
            totalHours += gameSettings.fleets.miningDurationHours;
        }

        const val = totalUpkeepPerHour * totalHours;

        return val > 1 ? val : 1;
    }
}