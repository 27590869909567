import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { InstallationType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";
import { InstallationCategoryIcon } from "./InstallationCategoryIcon";
import { ItemTypeIcon } from "./Items/ItemTypeIcon";

type Props = {
    installationType: InstallationType
}

export const InstallationTypePurposeIcon = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (worldState.CelestialTypeSettings === undefined || worldState.ItemTypeSettings === undefined || worldState.ShipTypeSettings === undefined) {
        return null;
    }

    if (props.installationType.builtOnCelestialTypeNames && props.installationType.resourceProduced.length > 0) {
        for (let celestialTypeName of props.installationType.builtOnCelestialTypeNames) {
            const item = celestialTypeName in worldState.CelestialTypeSettings.data ? worldState.CelestialTypeSettings.data[celestialTypeName].generatedItemTypeName : undefined;

            if (item) {
                return <ItemTypeIcon itemType={worldState.ItemTypeSettings.data[item]} />;
            }
        }
    }

    for (let shipTypeName in worldState.ShipTypeSettings.data) {
        const shipType = worldState.ShipTypeSettings.data[shipTypeName];
        if (shipType.builtAtInstallationTypeName === props.installationType.typeName) {
            return <Icon icon={IconHelper.Ships.Build} />;
        }
    }

    return <InstallationCategoryIcon installationCategory={props.installationType.category} />;
});