import { IItemTypeSettings, SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { HelpButton } from '../../../../Components/Base/Buttons/HelpButton';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { help_page } from '../../../../Navigation/Routing/Help';
import { UpkeepSummary } from '../../../Fleets/Components/UpkeepSummary';
import { Problems } from '../Summary/Ships/Problems';
import { Stats } from '../Summary/Ships/Stats';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
    itemTypeSettings: IItemTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const Summary = (props: Props) => {

    return <SubPanel heading="Summary" headingContent={<HelpButton to={help_page("ships")} />}>
        <Problems {...props} />
        <Stats {...props} />
        <UpkeepSummary
            itemTypeSettings={props.itemTypeSettings}
            items={props.solarSystemWrapper.solarSystem.items}
            itemsDetail={props.solarSystemWrapper.solarSystem.itemsDetail}
        />
    </SubPanel>;
}