import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";

type ActiveTempoaryPaidSubscriptionControlProps = {
    expires: Date
}

export const ActiveTemporaryPaidSubscriptionControl = (props: ActiveTempoaryPaidSubscriptionControlProps) => {
    function describe() {
        return "You have an active pre-paid temporary subscription that will expire on " + ValueFormatter.formatShortDate(props.expires);
    }

    return <Paragraph>
        {describe()}
    </Paragraph>;
}
