import { observer } from "mobx-react-lite";
import * as React from "react";
import { GameSettings, ICelestialDetail, InstallationType, ISolarSystemDetail, RequestResponseOfSolarSystemDetail } from "../../../../ApplicationState/ApiClient";
import { AppStateContext, SolarSystemStateContext, WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { FieldHolder } from "../../../../Components/Base/Form/FieldHolder";
import { Select } from "../../../../Components/Base/Form/Select";
import { BuildButton } from "../../../../Components/FusionShift/Build/BuildButton";
import { BuildSummary } from "../../../../Components/FusionShift/Build/BuildSummary";
import { CelestialShipInstallationWarning } from "../../../../Components/FusionShift/Build/CelestialShipInstallationWarning";
import { RequestResponseMessage } from "../../../../Components/FusionShift/Messages/RequestResponseMessage";
import { BuildHelper } from "../../../../Helpers/BuildHelper";
import { CelestialHelper } from "../../../../Helpers/CelestialHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { selected_system } from "../../../../Navigation/Routing/SolarSystem";

type Props = {
    solarSystem: ISolarSystemDetail | undefined
    gameSettings: GameSettings,
    installationType: InstallationType,
    factionTypeName: string
}

export const BuildAtCelestialControl = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const appState = React.useContext(AppStateContext);

    const [celestials, setCelestials] = React.useState<ICelestialDetail[]>([]);
    const [celestial, setCelestial] = React.useState<ICelestialDetail | undefined>(undefined);
    const [response, setResponse] = React.useState<RequestResponseOfSolarSystemDetail | undefined>(undefined);

    React.useEffect(() => {
        if (props.solarSystem !== undefined) {
            const celestials = props.solarSystem.celestials.filter(x => x.installationTypeNamesThatCanBeBuilt && x.installationTypeNamesThatCanBeBuilt.includes(props.installationType.typeName));

            setCelestials(celestials);
        }
    }, [props.solarSystem]);

    React.useEffect(() => {
        if (celestials.length > 0) {
            setCelestial(celestials[0]);
        } else {
            setCelestial(undefined);
        }
    }, [celestials]);

    function findAndSetCelestial(celestialIdString: string) {
        const celestialId = Number(celestialIdString);

        const celestial = celestials.find(x => x.celestialId === celestialId);

        setCelestial(celestial);
    }

    function reloadCallback(response: RequestResponseOfSolarSystemDetail) {
        setResponse(response);
        if (response.wasSuccess && response.result !== undefined) {
            solarSystemState.loadSolarSystem(response.result);
            appState.navigate(selected_system)
        }
    }

    if (celestials.length === 0 ||
        !props.solarSystem ||
        worldState.Effects === undefined ||
        worldState.InstallationTypeSettings === undefined ||
        worldState.ItemTypeSettings === undefined ||
        worldState.ShipTypeSettings === undefined) {
        return null;
    }

    const buildError = celestial && BuildHelper.buildError(props.installationType.typeName, celestial, props.solarSystem, worldState.GameSettings, worldState.InstallationTypeSettings, worldState.ItemTypeSettings, worldState.Effects);
    const cost = celestial && BuildHelper.costForLevel(worldState.Effects, worldState.InstallationTypeSettings, props.installationType.typeName, 1, worldState.GameSettings, celestial.size);

    return <SubPanel
        heading={{ text: `Build at ${props.solarSystem.name}`, icon: IconHelper.Installations.Build }}
        footerContent={celestial && cost !== undefined && <BuildButton
            solarSystemId={props.solarSystem.solarSystemId}
            celestialId={celestial.celestialId}
            installationTypeName={props.installationType.typeName}
            buildError={buildError}
            reloadCallback={reloadCallback}
            isPulledRight
        />
        }
    >
        <RequestResponseMessage response={response} />
        <SplitLayout
            fitContent
            isLeftEmpty={celestials.length <= 1}
            left={celestials.length > 1 && <FieldHolder label="Celestial">
                <Select values={celestials.map(x => {
                    return {
                        value: x.celestialId.toString(),
                        label: CelestialHelper.fullname(x, worldState.CelestialTypeSettings!)
                    }
                })}
                    valueChanged={e => findAndSetCelestial(e)}
                    value={celestial && celestial.celestialId.toString()}
                />
            </FieldHolder>}
            isRightEmpty={cost === undefined}
            right={cost !== undefined &&
                <BuildSummary
                    cost={cost}
                    buildError={buildError}
                    worldEffects={worldState.Effects}
                    availableItems={props.solarSystem.items}
                    allOnOneLine
                />
            } />
        <CelestialShipInstallationWarning
            installationType={props.installationType}
            otherInstallations={celestial?.installations ?? []}
            installationTypeSettings={worldState.InstallationTypeSettings}
            shipTypeSettings={worldState.ShipTypeSettings}
        />
    </SubPanel>;
});