import { IMessage } from "../../ApplicationState/ApiClient";
import { FlexDiv } from "../../Components/Base/Containers/FlexDiv";
import { EmblemImage, PlayerEmblemAndLink } from "../../Components/FusionShift/Images/EmblemImage";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { MessageHelper } from "../../Helpers/MessageHelper";

type Props = {
    message: IMessage
}

export const MessageSenderImage = (props: Props) => {

    if (props.message.fromPlayer) {
        return <PlayerEmblemAndLink player={props.message.fromPlayer} />;
    }

    const isGbc = MessageHelper.isGbcMessage(props.message);

    const logo = isGbc ? "gbclogo" : "main_logo_notext";

    return <FlexDiv isContentVerticallyCentered>
        <img src={`/images/${logo}.png`} className="image-holder size-tiny is-inline" />
    </FlexDiv>;
}

export const MessageSenderText = (props: Props) => {

    if (props.message.fromPlayer) {
        return <PlayerLink player={props.message.fromPlayer} />;
    }

    const isGbc = MessageHelper.isGbcMessage(props.message);

    const from = isGbc ? "Galactic Broadcasting Corporation" : "Admin";

    return <>{from}</>;
}

export const MessageSenderImageOnly = (props: Props) => {

    if (props.message.fromPlayer) {
        return <EmblemImage filename={props.message.fromPlayer && props.message.fromPlayer.emblemFilename} isInline />;
    }

    const isGbc = MessageHelper.isGbcMessage(props.message);
    const logo = isGbc ? "gbclogo" : "main_logo_notext";

    return <img src={`/images/${logo}.png`} className="image-holder size-medium is-inline" style={{ float: "left", margin: "0.5rem" }} />;
}