import { observer } from 'mobx-react-lite';
import { FleetMovementType, ItemCategory } from '../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { AgentInputList } from '../Agents/AgentInputList';
import { FleetCapacityBar } from '../Components/Items/FleetCapacityBar';
import { ItemInputList } from '../Components/Items/ItemInputList';
import { RelicInputList } from '../Components/Items/RelicInputList';
import { SendNowButton } from '../SendNowButton';
import { StageProps } from './StageRender';

export const CollectionStage = observer((props: StageProps) => {

    const remainingCargoToCollect = props.sendShipsBag.fleetWrapper.TotalCargoCapacity - props.sendShipsBag.totalToCollectCargo;

    const noExtraItems = !props.sendShipsBag.availableItemsInTarget || props.sendShipsBag.availableItemsInTarget.find(r => r.itemType.category === ItemCategory.Relic && r.quantity > 0) === undefined;
    const noExtraAgents = props.sendShipsBag.availableAgentsInTarget.length === 0;

    const noExtras = noExtraItems && noExtraAgents;

    return <SubPanel
        heading={{ text: "Collection", icon: IconHelper.Ships.movementType(FleetMovementType.Collection) }}
        headingContent={<SendNowButton {...props} isSendNow />}
        footerContent={
            <ButtonHolder isPulledRight>
                <PreviousButton action={props.previousStage} />
                <NextButton action={props.nextStage} isDisabled={CollectionHelper.sumOfDictionary(props.sendShipsBag.shipQuantities) <= 0} />
            </ButtonHolder>
        }>
        <FleetCapacityBar
            type={FleetMovementType.Collection}
            current={props.sendShipsBag.fleetWrapper.TotalCargoCapacity - remainingCargoToCollect}
            capacity={props.sendShipsBag.fleetWrapper.TotalCargoCapacity}
        />
        {props.sendShipsBag.allowedConfigurations.itemsToCollect &&
            <SplitLayout
                horizontalAfter="widescreen"
                left={<ItemInputList
                    itemTypeSettings={props.sendShipsBag.init.itemTypeSettings}
                    itemsAtTarget={props.sendShipsBag.init.sourceSolarSystem?.itemsDetail}
                    current={props.sendShipsBag.itemsToCollect}
                    setValue={(r, q) => props.sendShipsBag.setItemQuantityToCollect(r, q)}
                    available={props.sendShipsBag.availableItemsInTarget}
                    cargoCapacityRemaining={remainingCargoToCollect}
                    categories={[
                        ItemCategory.Component,
                        ItemCategory.RawResource,
                        ItemCategory.Resource
                    ]}
                />
                }
                right={<ItemInputList
                    itemTypeSettings={props.sendShipsBag.init.itemTypeSettings}
                    itemsAtTarget={props.sendShipsBag.init.sourceSolarSystem?.itemsDetail}
                    current={props.sendShipsBag.itemsToCollect}
                    setValue={(r, q) => props.sendShipsBag.setItemQuantityToCollect(r, q)}
                    available={props.sendShipsBag.availableItemsInTarget}
                    cargoCapacityRemaining={remainingCargoToCollect}
                    categories={[
                        ItemCategory.Commodity
                    ]}
                />

                }
            />
        }
        <SplitLayout
            isLeftEmpty={noExtraItems}
            isRightEmpty={!props.sendShipsBag.allowedConfigurations.agentsToCollect || noExtraAgents}
            left={<RelicInputList
                current={props.sendShipsBag.itemsToSend}
                setValue={(r, q) => props.sendShipsBag.setItemQuantityToSend(r, q)}
                available={props.sendShipsBag.availableItemsInTarget}
                cargoCapacityRemaining={remainingCargoToCollect}
            />}
            right={props.sendShipsBag.allowedConfigurations.agentsToCollect &&
                <AgentInputList
                    current={props.sendShipsBag.agentIdsToCollect}
                    setValue={(agentId, send) => props.sendShipsBag.setAgentIdToCollect(agentId, send)}
                    available={props.sendShipsBag.availableAgentsInTarget}
                    sendShipsBag={props.sendShipsBag}
                />
            }
        />
    </SubPanel>;
});