import * as React from 'react';

type Props = {
    parentRef: any,
    children: React.ReactNode,
    dontWrapChildren?: boolean,
    handleShouldOpen: (event: any) => boolean
}

export const ContextMenu = (props: Props) => {

    const [isVisible, setVisibility] = React.useState(false);

    const [style, setStyle] = React.useState({});

    React.useEffect(() => {
        const parent = props.parentRef.current;
        if (!parent) {
            return;
        }

        const showMenu = (event) => {
            event.preventDefault();

            const parentRect = parent.getBoundingClientRect();

            const clickX = event.clientX;
            const clickY = event.clientY;
            const parentW = parentRect.width;
            const parentH = parentRect.height;
            const parentX = parentRect.x;
            const parentY = parentRect.y;

            const localClickX = clickX - parentX;
            const localClickY = clickY - parentY;

            const displayToRight = localClickX < parentW / 2;
            const displayFromTop = localClickY < parentH / 2;

            let left: string | undefined;
            let right: string | undefined;
            let top: string | undefined;
            let bottom: string | undefined;

            if (displayToRight) {
                left = `${localClickX + 5}px`;
            } else {
                right = `${parentW - localClickX - 5}px`;
            }

            if (displayFromTop) {
                top = `${localClickY + 5}px`;
            } else {
                bottom = `${parentH - localClickY - 5}px`;
            }

            setStyle({
                left: left,
                right: right,
                top: top,
                bottom: bottom
            });

            setVisibility(props.handleShouldOpen(event));
        };

        const closeMenu = () => {
            setVisibility(false);
        };

        parent.addEventListener('contextmenu', showMenu);
        window.addEventListener('click', closeMenu);

        return function cleanup() {
            parent.removeEventListener('contextmenu', showMenu);
            window.removeEventListener('click', closeMenu);
        };
    });

    if (!isVisible) {
        return null;
    }

    return isVisible ? (
        <div className='context-menu' style={style}>
            {props.children}
        </div>
    ) : null;
};
