import { FleetMovementType, ISolarSystemMinimal } from "../../../../ApplicationState/ApiClient";
import { ButtonHolder } from "../../../../Components/Base/Buttons/ButtonHolder";
import { SendShipsWithMovementTypeButton } from "../../../../Components/FusionShift/Buttons/SendShipsButton";

type Props = {
    solarSystem: ISolarSystemMinimal
}

export const TargetButtons = (props: Props) => {

    return <ButtonHolder>
        <SendShipsWithMovementTypeButton
            movementType={FleetMovementType.Recon}
            solarSystem={props.solarSystem}
            noText
        />
        <SendShipsWithMovementTypeButton
            movementType={FleetMovementType.Attack}
            solarSystem={props.solarSystem}
            noText
        />
    </ButtonHolder>;
}