import { observer } from 'mobx-react-lite';
import { FleetMovementType } from '../../ApplicationState/ApiClient';
import { Button } from '../../Components/Base/Buttons/Button';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { SendShipsBag } from './SendShipsBag';

type Props = {
    isSendNow?: boolean
    sendShipsBag: SendShipsBag,
    send: () => any,
    hideTextOnMobile?: boolean
}

export const SendNowButton = observer((props: Props) => {

    if (!props.sendShipsBag.MovementType) {
        return null;
    }

    function submitConfirmation() {

        if (props.sendShipsBag && props.sendShipsBag.validation.valid) {
            if (props.sendShipsBag.MovementType === FleetMovementType.Attack && props.sendShipsBag.fleetWrapper.HasOnlyRecon) {
                return "Only Recon ships have been selected for this attack. If you want to scout the target then send a recon mission instead. Send the attack anyway?";
            }
        }

        return undefined;
    }

    const buttonText = props.isSendNow ?
        props.sendShipsBag.IsEditingRepeatFleet ?
            "Save Now" : "Send Now"
        :
        props.sendShipsBag.IsEditingRepeatFleet ? "Edit Repeat" : `Send ${ValueFormatter.friendlyNameForMovementType(props.sendShipsBag.MovementType)}`;

    return <Button
        type="action"
        icon={IconHelper.Ships.movementType(props.sendShipsBag.MovementType)}
        action={props.send}
        text={buttonText}
        isDisabled={!props.sendShipsBag.validation.valid}
        confirm={submitConfirmation()}
        hideTextOnMobile={props.hideTextOnMobile}
    />;
});