import { ICelestialDetail } from "../../../../ApplicationState/ApiClient";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { manufactoryProblemBuilder } from "./ManufactoryProblems";

export function subOptimalThroughputLimitProblems(wrapper: SolarSystemWrapper, celestial: ICelestialDetail): ProblemsMaybe {

    if (!celestial.manufactory) {
        return undefined;
    }

    const numInput = celestial.manufactory.inputNodes.filter(x => x.throughputLimitRatio < 1).length;
    const numOutput = celestial.manufactory.outputNodes.filter(x => x.throughputLimitRatio < 1).length;

    return [
        numInput > 0 ? manufactoryProblemBuilder.infoProblem("Input Node Throughput Limit", `There are ${numInput} input ${ValueFormatter.pluralize("node", numInput)} with a throughput limit at the Manufactory at ${wrapper.solarSystem.name}: ${celestial.name}`) : undefined,
        numOutput > 0 ? manufactoryProblemBuilder.infoProblem("Output Node Throughput Limit", `There are ${numOutput} output ${ValueFormatter.pluralize("node", numOutput)} with a throughput limit at the Manufactory at ${wrapper.solarSystem.name}: ${celestial.name}`) : undefined
    ];
}