import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { AgentEffects } from './AgentEffects';
import { SolarSystemAgents } from './SolarSystemAgents';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
}

export const Agents = (props: Props) => {

    return <>
        <SolarSystemAgents solarSystem={props.solarSystemWrapper.solarSystem} showNoAgents />
        <AgentEffects {...props} />
    </>;
};