import React from "react";
import { ISolarSystemDetail, Manufactory, ManufactoryProduction, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { Problem, ProblemSeverity } from "../../../../../Entities/SolarSystem/Problems/Problem";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ProblemList } from "../../Summary/Advisor/ProblemList";
import { Items } from "./Items";
import { ManufactoryStats } from "./ManufactoryStats";

type Props = {
    solarSystemId: number,
    solarSystem: ISolarSystemDetail,
    celestialId: number,
    manufactory: Manufactory,
    production: ManufactoryProduction | undefined,
    problems: Problem[],
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const ManufactorySummary = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    function setActive(isActive: boolean) {
        return apiState.ManufactoryClient.setManufactoryActive(props.celestialId, isActive).then(solarSystem => {
            if (solarSystem) {
                props.reloadCallback(solarSystem);
            }
        })
    }

    return <SubPanel heading={{ text: "Summary ", icon: IconHelper.General.onOrOff(props.manufactory.isActive) }}
        headingContent={<>
            {props.manufactory.isActive && <Button type="danger" text="Turn Off" action={() => setActive(false)} icon={IconHelper.General.Off} />}
            {!props.manufactory.isActive && <Button type="action" text="Turn On" action={() => setActive(true)} icon={IconHelper.General.On} />}
        </>
        }>
        <ManufactoryStats
            celestialId={props.celestialId}
            itemsDetail={props.solarSystem.itemsDetail}
            manufactory={props.manufactory}
            hidePowerStatus
        />
        <Items {...props} />
        {props.problems.length > 0 && <SubPanel heading={{ text: "Problems", icon: IconHelper.Statuses.Error }}>
            <ProblemList {...props} colourAsMinimum={ProblemSeverity.Warning} />
        </SubPanel>
        }
    </SubPanel>;
};