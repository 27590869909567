import { FleetMovementType, ISolarSystem } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { AgentPortrait } from '../../../Components/FusionShift/Images/AgentPortrait';
import { AgentLink } from '../../../Components/FusionShift/Links/AgentLink';
import { AgentAvailability } from '../../../Helpers/AgentHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { AgentMovementOutcome } from './AgentMovementOutcome';

type Props = {
    agentIds: number[],
    agents: AgentAvailability[],
    targetSolarSystem: ISolarSystem | undefined,
    playerId: number,
    movementType: FleetMovementType
}

export const AgentsSummary = (props: Props) => {

    const agents = props.agents.filter(x => props.agentIds.includes(x.agent.agentId)).map(x => x.agent);

    if (agents.length === 0) {
        return null;
    }

    return <SubPanel heading={{ text: "Agents", icon: IconHelper.Agents.Agent }} isUnpadded>
        <Table>
            {agents.map(x => <tr key={x.agentId}>
                <td>
                    <AgentPortrait agent={x} size='tiny' />
                </td>
                <td>
                    <AgentLink agent={x} />
                </td>
                <td>
                    <AgentMovementOutcome {...props} agent={x} />
                </td>
            </tr>)}
        </Table>

    </SubPanel>
}