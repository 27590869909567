import classNames from "classnames";
import * as React from "react";
import { CelestialSize, IPlayerMinimal } from "../../../ApplicationState/ApiClient";
import { AppStateContext } from "../../../ApplicationState/ContextRoot";
import { CelestialHelper } from "../../../Helpers/CelestialHelper";
import { systemById } from "../../../Navigation/Routing/SolarSystem";
import { SecurityStatusIcon } from "../Icons/SecurityStatusIcon";

type ISolarSystemDuck = {
    solarSystemId: number,
    name: string,
    owner?: IPlayerMinimal | undefined,
    size: number,
    x: number,
    y: number,
    isExplored: boolean
}

type Props = {
    solarSystem: ISolarSystemDuck,
    style?: any,
    className?: string | undefined,
    isReal?: boolean,
    isLink?: boolean
}

export const SolarSystemImage = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    const onClick = props.isLink ? () => appState.navigate(systemById(props.solarSystem.solarSystemId)) : undefined;

    const className = classNames(
        props.className,
        CelestialHelper.solarSystemStarClassNames(props.solarSystem),
        {
            "is-unexplored": !props.solarSystem.isExplored
        }
    );

    return <SolarSystemImageBase
        style={props.style}
        className={className}
        isReal={props.isReal}
        onClick={onClick}
        name={props.solarSystem.name}
    >
        {props.solarSystem.owner !== undefined && <SecurityStatusIcon securityStatus={props.solarSystem.owner.securityStatus} />}
    </SolarSystemImageBase>;
};

type BaseProps = {
    style?: any,
    className?: string | undefined,
    isReal?: boolean,
    name: string,
    onClick?: (() => any) | undefined,
    children?: React.ReactNode | undefined
}

export const SolarSystemImageBase = (props: BaseProps) => {

    const sizeClassName = CelestialSize[CelestialSize.Tiny].toLocaleLowerCase();

    const className = classNames(
        "celestial",
        "star",
        sizeClassName,
        props.className,
        {
            "is-real": props.isReal,
            "is-clickable": props.onClick !== undefined
        });

    return <div
        onClick={props.onClick}
        title={props.name}
        style={props.style}
        className={className}
    >
        <div className="doodad" />
        {props.children !== undefined && props.children}
    </div>;
};
