import classNames from "classnames";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { MobileMenuStateContext, SolarSystemStateContext } from "../../ApplicationState/ContextRoot";
import { IconLink } from "../../Components/Base/IconLink";
import { SolarSystemDangerIcons } from "../../Components/FusionShift/Icons/SolarSystemDangerIcons";
import { IconHelper } from "../../Helpers/IconHelper";
import { help } from "../Routing/Help";
import { search, send_ships } from "../Routing/Misc";
import { SolarSystemSwitcher } from "./SolarSystemSwitcher";

type ChildProps = {
    className?: string | undefined,
    children?: React.ReactNode | undefined
};

const Child = (props: ChildProps) => {

    const className = classNames("child", props.className);

    return <div className={className}>
        {props.children && props.children}
    </div>
}

export const BottomMobileBar = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const mobileMenuState = React.useContext(MobileMenuStateContext);

    return <div className="navbar bottombar" onClick={() => mobileMenuState.close()}>
        <Child>
            <SolarSystemSwitcher />
            <div className="icons">
                {solarSystemState.SolarSystem &&
                    <SolarSystemDangerIcons
                        className="solar-system-attack-icons"
                        solarSystem={solarSystemState.SolarSystem}
                    />
                }
                <IconLink to={send_ships} icon={IconHelper.Ships.Send} title="Send ships" />
                <IconLink to={search} icon={IconHelper.General.Search} />
                <IconLink to={help} icon={IconHelper.General.Help} />
                <IconLink to={"/"} icon={IconHelper.General.InlineMenu} />
            </div>
        </Child>
    </div>;
});
