import { SolarSystemClaimType } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    solarSystem: { claimType?: SolarSystemClaimType }
}

export const SolarSystemClaimTypeIcon = (props: Props) => {

    if (props.solarSystem.claimType === undefined || props.solarSystem.claimType === null) {
        return null;
    }

    return <Icon
        icon={IconHelper.SolarSystems.forClaimType(props.solarSystem.claimType)}
        title={SolarSystemClaimType[props.solarSystem.claimType]}
    />;
}