import { FleetMovementType, IContract } from '../../../ApplicationState/ApiClient';
import { MovementTypes } from '../../../Blobs/Descriptions/MovementTypes';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { ThumbnailSlate } from '../../../Components/Base/Containers/Slate';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { FleetMovementTypeIcon } from '../../../Components/FusionShift/Icons/FleetMovementTypeIcon';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { StageProps } from './StageRender';

type Props = {
    contracts: IContract[] | undefined,
    movementType: FleetMovementType,
    onSelected: () => any,
    isSelected: boolean
};

const MovementTypeSelectionSlate = (props: Props) => {

    return <ThumbnailSlate
        onClick={props.onSelected}
        isSelected={props.isSelected}
        isDanger={FleetHelper.isHostileMovementType(props.movementType)}
        title={ValueFormatter.friendlyNameForMovementType(props.movementType)}
        thumbnail={<FleetMovementTypeIcon movementType={props.movementType} isGiant />}
        footerContent={<Button
            type="nav"
            isPulledRight
            icon={props.isSelected ? IconHelper.General.Confirm : IconHelper.Ships.movementType(props.movementType)}
            text={props.isSelected ? "Selected" : "Select  "}
            action={props.onSelected}
            isSelected={props.isSelected} />}
    >
        <Paragraph>
            {MovementTypes.describe(props.movementType)}
        </Paragraph>
        {props.contracts !== undefined && props.contracts.length > 0 && <Paragraph>
            There {ValueFormatter.pluralizeAndWriteValue("contract", props.contracts.length)} available here.
        </Paragraph>}
    </ThumbnailSlate>;
}

export const MovementTypeStage = (props: StageProps) => {

    if (props.sendShipsBag.movementTypeOptions.length == 0) {
        return <SubPanel heading="Movement Types">
            No valid movement types available. Do you have any ships?
        </SubPanel>;
    }

    const hostile = props.sendShipsBag.movementTypeOptions.filter(m => FleetHelper.isHostileMovementType(m));
    const nonHostile = props.sendShipsBag.movementTypeOptions.filter(m => !FleetHelper.isHostileMovementType(m));

    return <SubPanel heading="Movement Type" footerContent={
        <ButtonHolder isPulledRight>
            <PreviousButton action={props.previousStage} />
            <NextButton action={props.nextStage} isDisabled={props.sendShipsBag.MovementType === undefined} />
        </ButtonHolder>
    }>
        <SplitLayout
            isLeftEmpty={hostile.length === 0}
            isRightEmpty={nonHostile.length === 0}
            left={
                hostile.map(m => <MovementTypeSelectionSlate
                    key={m}
                    movementType={m}
                    isSelected={m === props.sendShipsBag.MovementType}
                    onSelected={() => props.sendShipsBag.setMovementType(m)}
                    contracts={m in props.sendShipsBag.AvailableContractsInTarget ? props.sendShipsBag.AvailableContractsInTarget[m] : undefined}
                />
                )
            }
            right={
                nonHostile.map(m => <MovementTypeSelectionSlate
                    key={m}
                    movementType={m}
                    isSelected={m === props.sendShipsBag.MovementType}
                    onSelected={() => props.sendShipsBag.setMovementType(m)}
                    contracts={m in props.sendShipsBag.AvailableContractsInTarget ? props.sendShipsBag.AvailableContractsInTarget[m] : undefined}
                />
                )
            }
        />
    </SubPanel>;
};