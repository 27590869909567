import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MobileMenuStateContext, SolarSystemStateContext } from '../../ApplicationState/ContextRoot';
import { IconTray } from './IconTray';
import { LinksTray } from './LinksTray';
import { SolarSystemMobileItem } from './SolarSystemMobileItem';

export const OpenMenu = observer(() => {

    const mobileMenuState = React.useContext(MobileMenuStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [openSolarSystemId, setOpenSolarSystemId] = React.useState<number | undefined>()

    function setOpen(solarSystemId: number, open: boolean) {
        setOpenSolarSystemId(open ? solarSystemId : undefined);
    }

    if (!mobileMenuState.IsOpen) {
        return null;
    }

    return <div className="mobile-menu is-hidden-tablet">
        <div className="inner-wrapper">
            <span className="open-menu-header">
                Menu
            </span>
            <div className="inner">
                {solarSystemState.SolarSystems.map(x => <SolarSystemMobileItem
                    key={x.solarSystemId}
                    solarSystem={x}
                    isSelected={x.solarSystemId === solarSystemState.SolarSystem?.solarSystemId}
                    isOpen={x.solarSystemId === openSolarSystemId}
                    setOpen={b => setOpen(x.solarSystemId, b)}
                />)}
                <LinksTray showEmpire={(solarSystemState.SolarSystems?.length ?? 0) > 1} />
                <IconTray />
            </div>
        </div>
    </div>;
});