import { IInstallationTypeSettings, IShipTypeSettings, ISolarSystemAutomationConfiguration, ISolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { InstallationAutomationEditor } from './InstallationAutomationEditor';
import { ShipConstructionAutomationEditor } from './ShipConstructionAutomationEditor';

type Props = {
    isEnabled: boolean,
    save: (x: ISolarSystemAutomationConfiguration) => any,
    configuration: ISolarSystemAutomationConfiguration,
    setConfiguration: (x: ISolarSystemAutomationConfiguration) => any,
    installationTypeSettings: IInstallationTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    solarSystem: ISolarSystemDetail,
    isSaving: boolean
}

export const AutomationConfigurationEditor = (props: Props) => {

    return <>
        <InstallationAutomationEditor {...props} />
        <ShipConstructionAutomationEditor {...props} />
    </>;
};