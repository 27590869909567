import { observer } from 'mobx-react-lite';
import { IconHelper } from '../../Helpers/IconHelper';
import { SimpleBaseView } from '../BaseView';
import { Summary } from './Summary';
import { TransactionList } from './TransactionList';

export const CreditsView = observer(() => {

    return <SimpleBaseView heading={{ text: "Credits", icon: IconHelper.Economy.Credits }}>
        <Summary />
        <TransactionList />
    </SimpleBaseView>
});