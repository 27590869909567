import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { DismissButton } from '../../Base/Buttons/DismissButton';
import { ErrorMessageBox } from '../../Base/MessageBox';

export const ApiErrorMessage = observer(() => {

    const apiState = React.useContext(ApiStateContext);

    if (!apiState.hasError) {
        return null;
    }

    return <ErrorMessageBox text={"A server error occurred - please refresh the page and try again."} button={
        <DismissButton className="is-pulled-right" action={() => apiState.hasError = false} />
    } />;
});