import React from "react";
import { CelestialCategory, CelestialType, ICelestialTypeSettings, IInstallationTypeSettings, InstallationCategory, InstallationType, SolarSystemClaimType } from "../../../../ApplicationState/ApiClient";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { ButtonChooser, choice } from "../../../../Components/Base/Buttons/ButtonChooser";
import { TabMenuWithChildren } from "../../../../Components/Base/Containers/TabMenu";
import { Table } from "../../../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../../../Components/Base/Loading/LoadingSpinner";
import { CelestialTypeImageWithLabel } from "../../../../Components/FusionShift/Celestials/CelestialImage";
import { TickOrCrossIcon } from "../../../../Components/FusionShift/Icons/TickOrCrossIcon";
import { InstallationTypeImageWithLabel } from "../../../../Components/FusionShift/Images/InstallationTypeImage";
import { InstallationTypeLink } from "../../../../Components/FusionShift/Links/InstallationTypeLink";
import { BuildHelper } from "../../../../Helpers/BuildHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { valuesFromEnum } from "../../../../Helpers/TypeHelper";

type Props = {
    celestialTypeSettings: ICelestialTypeSettings,
    installationTypeSettings: IInstallationTypeSettings
}

export const InstallationTable = (props: Props) => {

    const [installationCategory, setInstallationCategory] = React.useState(InstallationCategory.Economy);
    const [celestialGroup, setCelestialGroup] = React.useState<"planet" | "rarePlanet">("planet");
    const [isForOutpost, setIsForOutpost] = React.useState(false);

    const data = React.useMemo(() => {

        if (props.celestialTypeSettings === undefined) {
            return undefined;
        }

        const celestials = Object.values(props.celestialTypeSettings.data)
            .filter(x => x.category === (celestialGroup === "rarePlanet" ? CelestialCategory.RarePlanet : CelestialCategory.Planet))
            .filter(x => x.typeName !== "Destroyed")
            .sort((a, b) => a.order < b.order ? -1 : 1);

        const installations = Object.values(props.installationTypeSettings.data)
            .filter(x => x.category === installationCategory && (x.researchPointsRequired ?? 0) === 0)
            .sort((a, b) => a.order < b.order ? -1 : 1);

        return {
            celestials, installations
        };

    }, [installationCategory, celestialGroup, props.celestialTypeSettings]);

    if (data === undefined) {
        return <LoadingSpinner />;
    }

    const choices = [
        choice<"planet" | "rarePlanet">("planet", "Planets"),
        choice<"planet" | "rarePlanet">("rarePlanet", "Rare Planets")
    ];

    const installationTabs = valuesFromEnum(InstallationCategory)
        .filter(x => x !== InstallationCategory.Misc)
        .map(x => {
            return {
                id: x,
                title: InstallationCategory[x],
                icons: IconHelper.Installations.installationCategory(x),
                isAvailable: true
            }
        })

    function checkBuild(installationType: InstallationType, celiestalType: CelestialType) {
        const can = BuildHelper.isCelestialTypeValid(installationType, celiestalType.typeName);

        const canClaimType = BuildHelper.canBeBultAtSolarSystemClaimType(installationType, isForOutpost ? SolarSystemClaimType.Outpost : SolarSystemClaimType.Colony);

        return can && canClaimType ? "tick" : "cross";
    }

    return <>
        <ButtonChooser
            values={choices}
            value={celestialGroup}
            valueChanged={setCelestialGroup}
            additionalButtons={
                [<Button type="nav"
                    isSelected={!isForOutpost}
                    icon={IconHelper.SolarSystems.Colony}
                    text="Colony"
                    hideTextOnMobile
                    action={() => setIsForOutpost(false)}
                />,
                <Button type="nav"
                    isSelected={isForOutpost}
                    icon={IconHelper.SolarSystems.Outpost}
                    text="Outpost"
                    hideTextOnMobile
                    action={() => setIsForOutpost(true)}
                />]
            }
        />
        <TabMenuWithChildren
            tabs={installationTabs}
            active={installationCategory}
            changeTab={setInstallationCategory}
        >
            <Table isFixed isFullWidth
                heading={<>
                    <th>
                        Installation
                    </th>
                    {data.celestials.map((x, i) => <th key={x.typeName}>
                        <CelestialTypeImageWithLabel celestialType={x} />
                    </th>)
                    }
                </>}
            >
                {data.installations.map(x => <tr key={x.typeName}>
                    <td>
                        <InstallationTypeImageWithLabel
                            installationType={x}
                            label={<InstallationTypeLink installationType={x} />}
                            size="tiny"
                        />
                    </td>
                    {data.celestials.map((c) => <td key={c.typeName}>
                        <div className="is-flex is-justify-content-center">
                            <TickOrCrossIcon type={checkBuild(x, c)} isCentered />
                        </div>
                    </td>)}
                </tr>)}
            </Table>
        </TabMenuWithChildren>
    </>;
}