import { observer } from "mobx-react-lite";
import * as React from "react";
import { AppStateContext } from "../../ApplicationState/ContextRoot";
import { AutomaticLoadingBar } from "../../Components/Base/Loading/LoadingBar";
import { LogoutButton } from "./Controls/LogoutButton";
import { SearchButton } from "./Controls/SearchButton";
import { AlwaysShown } from "./Parts/AlwaysShown";
import { DesktopLinks } from "./Parts/DesktopLinks";

export const TopMenuBar = observer(() => {

    const appState = React.useContext(AppStateContext);

    return <nav className={`navbar topbar ${appState.IsDebug ? "debug" : ""}`} role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <AutomaticLoadingBar isMobileOnly />
            <AlwaysShown />
        </div>
        <div className={"navbar-menu"}>
            <AutomaticLoadingBar isMobileOnly={false} />
            <div className="navbar-start">
                <DesktopLinks />
            </div>
            <div className="navbar-end">
                <SearchButton />
                {appState.IsLoggedIn && <LogoutButton />}
            </div>
        </div>
    </nav>;
});