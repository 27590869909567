import { ISolarSystemMapEntry } from "../../../../ApplicationState/ApiClient";
import { CanvasHelper } from "../CanvasHelper";
import { GalaxyMapData, RenderBag } from "../Entities";

const colours = {
    selected: "#feae00",
    sameOwner: "#feae00",
    sameFederation: "#feae00"
}

const systemColours = {
    self: "#56c3e3",
    friend: "#8fc95d",
    neutral: "white",
    enemy: "#e35656"
}

type SelectionPerspective = {
    playerId: number | undefined,
    federationId: number | undefined
};

export class OverlayRenderer {

    public numRendered = 0;
    public renders = 0;

    public transform: DOMMatrix = new DOMMatrix();

    constructor(public ctx: CanvasRenderingContext2D) {
    }

    public render(render: RenderBag) {

        this.renders++;
        this.numRendered = 0;

        this.transform = this.ctx.getTransform();
        const selectionPerspective = this.createSelectionPerspective(render);

        for (const solarSystem of Object.values(render.data.map.entries)) {
            this.renderSolarSystem(this.transform, render, solarSystem, selectionPerspective);
        }

        if (render.selection.hoverCoordinate !== undefined) {
            const hoverSolarSystem = Object.values(render.data.map.entries).find(x => x.x === render.selection.hoverCoordinate!.x && x.y === render.selection.hoverCoordinate!.y);

            if (hoverSolarSystem !== undefined) {

                const { x, y } = render.perspective.transform(hoverSolarSystem.x, hoverSolarSystem.y, render.perspective.parsec / 2);

                CanvasHelper.drawText(this.ctx,
                    x,
                    y - render.perspective.parsec,
                    hoverSolarSystem.name,
                    "white",
                    10
                );
            }
        }
    }

    private renderSolarSystem(
        transform: DOMMatrix,
        render: RenderBag,
        solarSystem: ISolarSystemMapEntry,
        selectionPerspective: SelectionPerspective) {

        const { x, y } = render.perspective.transform(solarSystem.x, solarSystem.y, render.perspective.parsec / 2);

        if (!render.perspective.isParsecInView(transform, x, y, render.bounds)) {
            return;
        }

        this.numRendered++;

        const isSelected = render.selection.solarSystemId === solarSystem.solarSystemId;
        const isHover = render.selection.hoverCoordinate?.x === solarSystem.x && render.selection.hoverCoordinate?.y === solarSystem.y;

        const isSelectedPlayer = selectionPerspective.playerId !== undefined && solarSystem.playerId === selectionPerspective.playerId;
        const isSelectedSolarSystem = selectionPerspective.federationId !== undefined && solarSystem.federationId === selectionPerspective.federationId;

        let colour = this.solarSystemColour(render.data, solarSystem, isSelected);

        if (colour === undefined && !isHover && !isSelected) {
            return;
        }

        if (isSelected) {
            const solarSystemGradient = this.ctx.createRadialGradient(x, y, 0, x, y, render.perspective.parsec * 0.75);
            solarSystemGradient.addColorStop(0, "#00000000");
            solarSystemGradient.addColorStop(1, colours.selected);

            CanvasHelper.fillCircle(this.ctx,
                x,
                y,
                (render.perspective.parsec / 2),
                solarSystemGradient
            );

            CanvasHelper.strokeCross(this.ctx,
                x,
                y,
                (render.perspective.parsec * .75),
                colours.selected,
                1
            );
        }

        if (isSelectedSolarSystem) {

            const fedColour = solarSystem.federationId === render.data.player.federation?.federationId ? systemColours.friend : systemColours.enemy;

            CanvasHelper.strokeTriangle(this.ctx,
                x,
                y,
                (render.perspective.parsec * .75),
                fedColour,
                1
            );
        }

        if (isSelectedPlayer) {
            CanvasHelper.strokeCircle(this.ctx,
                x,
                y,
                (render.perspective.parsec * .75),
                colours.selected,
                1
            );
        }

        CanvasHelper.strokeCircle(this.ctx,
            x,
            y,
            (render.perspective.parsec * .5),
            isHover ? colours.selected : colour ?? "white",
            1
        );
    }

    private solarSystemColour(data: GalaxyMapData, solarSystem: ISolarSystemMapEntry, isSelected: boolean) {

        if (solarSystem.playerId === undefined || solarSystem.playerId === null) {
            return isSelected ? systemColours.neutral : undefined;
        }

        if (solarSystem.playerId === data.player.playerId) {
            return systemColours.self;
        }

        if (solarSystem.federationId !== undefined &&
            solarSystem.federationId !== null &&
            data.player.federation?.federationId === solarSystem.federationId) {
            return systemColours.friend;
        }

        return systemColours.enemy;
    }

    private createSelectionPerspective(render: RenderBag): SelectionPerspective {
        const solarSystem = render.selection.solarSystemId !== undefined ? Object.values(render.data.map.entries).find(x => x.solarSystemId === render.selection.solarSystemId) : undefined;

        return {
            playerId: this.valOrUndefined(solarSystem?.playerId),
            federationId: this.valOrUndefined(solarSystem?.federationId)
        }
    }

    private valOrUndefined(val: number | null | undefined) {
        if (val !== undefined && val !== null) {
            return val;
        }

        return undefined;
    }
}