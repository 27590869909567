export class TextHelper {


    public static tidyText(text: string | undefined | null): string {
        if (!text || text.length === 0) {
            return "";
        }

        text = text.replace("/", " ").trim();

        let result = text.replace(/([A-Z])/g, " $1");
        let finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        return finalResult.trim();
    }

    public static upperCaseEachWord(text: string) {
        const words = text.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(" ");
    }

    public static hashTextToNumber(text: string | number | undefined | null, max: number | undefined): number {

        if (typeof text === "number") {
            text = text.toString();
        }

        if (!text || text.length === 0) {
            return Math.floor(Math.random() * (max || 1000));
        }

        let value = 0;

        for (let c of text) {
            value += c.charCodeAt(0);
        }

        if (max) {
            while (value > max) {
                value -= max;
            }
        }

        return value;
    }

    public static classNameForPercentEfficiency(efficiency: number): string | undefined {
        if (efficiency >= 0.9) {
            return "has-text-success";
        }
        if (efficiency <= 0.25) {
            return "has-text-danger";
        }
        if (efficiency <= 0.5) {
            return "has-text-warning";
        }
        return "";
    }

    public static abbreviateText(text: string, maxLength: number = 5) {
        const abbreviatedPieces = text.split(' ')
            .map(x => {
                if (x.length <= maxLength) {
                    return x;
                }

                return `${x.substring(0, maxLength - 2)}.`
            });

        return abbreviatedPieces.join(" ");
    }

    public static abbreviateTextEvenly(text: string, maxLength: number = 15) {

        const splits = text.split(' ');
        const spaces = splits.length - 1;

        const perWord = (maxLength - spaces) / splits.length;

        const abbreviatedPieces = splits
            .map(x => {
                if (x.length <= perWord) {
                    return x;
                }

                return `${x.substring(0, perWord - 1)}.`
            });

        return abbreviatedPieces.join(" ");
    }
}