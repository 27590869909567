import { observer } from "mobx-react-lite";
import React from "react";
import { PolicyType, ShipClass } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { Icon } from "../../../Components/Base/Icon";
import { TextAndIcon } from "../../../Components/Base/Text/TextAndIcon";
import { ItemIconWithQuantity } from "../../../Components/FusionShift/Icons/Items/ItemIconWithQuantity";
import { ShipClassIcon } from "../../../Components/FusionShift/Icons/ShipClassIcon";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type Props = {
    policyType: PolicyType
}

export const PolicyExtras = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.World || !worldState.ItemTypeSettings) {
        return null;
    }

    const content = [
        props.policyType.requiresVote && <TextAndIcon text="Requires a vote" icon={IconHelper.Politics.Vote} />,
        props.policyType.researchPointsRequired !== undefined && props.policyType.researchPointsRequired !== null && props.policyType.researchPointsRequired > 0 && <>
            Requires {props.policyType.researchPointsRequired} <Icon icon={IconHelper.Federations.ResearchPoint} /> Research Points
        </>,
        props.policyType.require2ThirdsToPass && <>Requires 2/3rds majority to pass</>,
        props.policyType.voteWithPopulation && <>Votes are counted by population rather than 1 vote per player</>
    ]
        .filter(x => x !== false)
        .map(x => x as JSX.Element);

    if (props.policyType.rewardBasedOnWeekNumber) {
        content.push(<TextAndIcon text="Reward based on week number" icon={IconHelper.Politics.RewardOnWeekNumber} />);

        if (props.policyType.shipClassInCapital) {
            for (const key of Object.keys(props.policyType.shipClassInCapital)) {
                const shipClass = ShipClass[key as keyof typeof ShipClass];
                const text = `${ValueFormatter.friendlyNameForShipClass(shipClass)} ${ValueFormatter.formatLocaleNumber(worldState.World.weekNumber * props.policyType.shipClassInCapital[key])}`

                content.push(<TextAndIcon text={text} icon={<ShipClassIcon shipClass={shipClass} />} />);
            }
        }

        if (props.policyType.itemTypeInCapital) {
            for (const key of Object.keys(props.policyType.itemTypeInCapital)) {
                const itemType = key in worldState.ItemTypeSettings.data ? worldState.ItemTypeSettings.data[key] : undefined;

                if (itemType === undefined) {
                    continue;
                }

                content.push(<ItemIconWithQuantity quantity={worldState.World.weekNumber * props.policyType.itemTypeInCapital[key]} itemType={itemType} showFullName />);
            }
        }
    }

    if (content.length === 0) {
        return null;
    }

    return <ul className="content">
        {content.map((x, i) => <li key={i}>
            {x}
        </li>)}
    </ul>;
});