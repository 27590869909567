import { observer } from "mobx-react-lite";
import React from "react";
import { IItemTypeSettings, SchematicType } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../Base/Containers/SplitLayout";
import { ItemIconWithQuantity } from "../Icons/Items/ItemIconWithQuantity";
import { CostControl } from "../Items/CostControl";
import { RichTextArray } from "../RichTextArray";
import { TypeDataVoice } from "../TypeDataVoice";

type Props = {
    itemTypeSettings: IItemTypeSettings,
    schematicType: SchematicType,
    level?: number,
    showVoice?: boolean
}

export const SchematicTypeDescription = observer((props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const generatedItem = props.schematicType.producedItemTypeName in props.itemTypeSettings.data ? props.itemTypeSettings.data[props.schematicType.producedItemTypeName] : undefined;

    const levelToUse = props.level !== undefined ? props.level : 1;

    const description = descriptionState.Schematics?.find(x => x.typeName === props.schematicType.typeName);

    return <>
        <RichTextArray text={description?.description} />
        {props.showVoice && <TypeDataVoice type="SchematicType" typeData={props.schematicType} />}
        <SplitLayout
            hasDivider
            fitContent
            left={!!props.schematicType.costPerRun && <>
                Consumes: <CostControl cost={props.schematicType.costPerRun} multipliedBy={levelToUse} />
            </>}
            right={!!generatedItem && <>
                Produces: <br /><ItemIconWithQuantity itemType={generatedItem} quantity={props.schematicType.producedPerRun * levelToUse} />
            </>}
        />
    </>;
});
