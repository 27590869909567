import * as React from 'react';
import { RenderContext } from '../RenderContext';

type Props = {
    renderContext: RenderContext,
    gridLines: number,
    className: string,
    isHidden?: boolean
}

export const Grid = (props: Props) => {

    const rc = props.renderContext;

    const lines = React.useMemo(() => {

        const l: {
            key: string,
            x1: number,
            x2: number,
            y1: number,
            y2: number
        }[] = [];

        const pY = (rc.props.maxY - rc.props.minY) / props.gridLines;
        const pX = (rc.props.maxX - rc.props.minX) / props.gridLines;

        for (let i = 0; i < props.gridLines; i++) {

            l.push({
                key: `x_${i}`,
                x1: rc.tX(rc.props.minX),
                x2: rc.tX(rc.props.maxX),
                y1: rc.tY((i * pY) + rc.props.minY),
                y2: rc.tY((i * pY) + rc.props.minY),
            });

            l.push({
                key: `y_${i}`,
                y1: rc.tY(rc.props.minY),
                y2: rc.tY(rc.props.maxY),
                x1: rc.tX((i * pX) + rc.props.minX),
                x2: rc.tX((i * pX) + rc.props.minX),
            });
        }

        return l;

    }, [props.renderContext]);

    return <>
        {lines.map(x => <line
            strokeWidth={props.isHidden ? 0 : 1}
            className={`grid ${props.className}`}
            vectorEffect="non-scaling-stroke"
            {...x}
        />)}
    </>;
};