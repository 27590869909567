import React from "react";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    className?: string,
    showIcon?: boolean
}

export const BugReportLink = (props: Props) => {

    return <a className={props.className} href="https://www.surveymonkey.co.uk/r/GYGLQLC" target="_blank" rel="noopener noreferrer">
        {props.showIcon && <Icon icon={IconHelper.General.OpenInNewWindow} />}
        Bug Report
    </a>;
};

export const FeedbackLink = (props: Props) => {

    return <a className={props.className} href="https://www.surveymonkey.co.uk/r/GMDM6MR" target="_blank" rel="noopener noreferrer">
        {props.showIcon && <Icon icon={IconHelper.General.OpenInNewWindow} />}
        Feedback Survey
    </a>;
};