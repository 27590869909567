import { ICelestialTypeSettings, IFactionTypeSettings, IGameSettings, IShipTypeSettings, ISimulatedAttackingFleet, ISimulatedCelestial } from '../../ApplicationState/ApiClient';
import { Button } from '../../Components/Base/Buttons/Button';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../Components/Base/Form/FieldHolder';
import { Select } from '../../Components/Base/Form/Select';
import { IconHelper } from '../../Helpers/IconHelper';
import { BonusSlider } from './Components/BonusSlider';
import { simulatedCelestialName } from './Components/CelestialsForm';
import { MovementTypePicker } from './Components/MovementTypePicker';
import { ShipsForm } from './Components/ShipForm';
import { StatsSummary } from './Components/StatsSummary';
import { defaultAttacker } from './Data';

type Props = {
    celestials: ISimulatedCelestial[] | undefined,
    shipTypeSettings: IShipTypeSettings,
    factionTypeSettings: IFactionTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    gameSettings: IGameSettings,
    attacker: ISimulatedAttackingFleet,
    setAttacker: (attacker: ISimulatedAttackingFleet) => any
}

export const AttackerForm = (props: Props) => {

    return <SubPanel heading={{ text: "Attacker", icon: IconHelper.Combat.Attack }}
        headingContent={<Button type="danger" text="Reset" icon={IconHelper.General.Delete} action={() => props.setAttacker(defaultAttacker)} confirm="Are you sure you want to reset the attacker? This will clear all your changes." />}
    >
        <SplitLayout
            left={
                <ShipsForm
                    shipTypeSettings={props.shipTypeSettings}
                    factionTypeSettings={props.factionTypeSettings}
                    gameSettings={props.gameSettings}
                    {...props.attacker}
                    setShipsAndTactics={(ships, tactics) => props.setAttacker({
                        ...props.attacker,
                        ships,
                        tactics
                    })}
                />
            }
            right={<>
                <FieldHolder label="Movement Type">
                    <MovementTypePicker movementType={props.attacker.movementType} setMovementType={e => props.setAttacker({
                        ...props.attacker,
                        movementType: e
                    })} />
                </FieldHolder>
                <BonusSlider
                    value={props.attacker.megacityBonusPercent}
                    valueChanged={v => props.setAttacker({
                        ...props.attacker,
                        megacityBonusPercent: v
                    })}
                />
                {props.celestials !== undefined &&
                    <FieldHolder label="Target Celestial">
                        <Select value={props.attacker.targetCelestialId} valueChanged={e => props.setAttacker({
                            ...props.attacker,
                            targetCelestialId: e === undefined || e.length === 0 ? undefined : Number(e)
                        })}
                            showUnset
                            values={props.celestials.map(x => {
                                return {
                                    label: simulatedCelestialName(x, props.celestialTypeSettings),
                                    value: x.celestialId
                                }
                            })}
                        />
                    </FieldHolder>
                }
                <StatsSummary {...props} ships={props.attacker.ships} />
            </>
            }
        />
    </SubPanel>;
};