import * as React from 'react'
import { AppStateContext } from "../../ApplicationState/ContextRoot"
import { Button } from "../../Components/Base/Buttons/Button"
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder"
import { Paragraph } from "../../Components/Base/Text/Paragraph"
import { IconHelper } from "../../Helpers/IconHelper"
import { ErrorApology } from "../../Navigation/ErrorApology"
import { SimpleBaseView } from "../BaseView"

export const InitialLoadError = () => {

    const appState = React.useContext(AppStateContext);

    return <div className="authentication">
        <br />
        <br />
        <SimpleBaseView
            heading={{ text: "Error Loading", icon: IconHelper.Statuses.Error }}
        >
            <Paragraph type="danger">An error occurred during the initial load.</Paragraph>
            <ErrorApology />
            <hr />
            <ButtonHolder isPulledRight>
                <Button type="warning" action={() => appState.logout()} text="Logout" icon={IconHelper.General.Exit} />
                <Button type="action" action={() => window.location.reload()} text="Reload" icon={IconHelper.General.Reload} />
            </ButtonHolder>
        </SimpleBaseView>
    </div>
}