import { ItemCategory } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { ProgressBar } from "../../Base/ProgressBar";

type Props = {
    itemCategory: ItemCategory,
    quantity: number | undefined,
    capacity: number | undefined
}

export const ItemCapacityBar = (props: Props) => {

    const capacity = props.capacity ?? 0;
    const value = props.quantity ?? 0;

    return <ProgressBar
        leftIcon={IconHelper.Items.itemCategory(props.itemCategory)}
        text={`Capacity: ${ValueFormatter.formatLocaleNumber(value)} / ${capacity}`}
        max={capacity}
        value={value}
        invertThresholds
        danger={capacity}
        warning={.9 * capacity}
    />;
}