import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext, PlayerStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { PlayerHelper } from '../../../Helpers/PlayerHelper';
import { DismissButton } from '../../Base/Buttons/DismissButton';
import { MessageBoxWithIcon } from '../../Base/MessageBox';
import { FeedbackLink } from '../Links/SurveyLinks';

const key = "Hide_Survey_Prompt";

export const FeedbackReminderMessage = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const appState = React.useContext(AppStateContext);

    const [isHidden, setIsHidden] = React.useState(appState.UserPrefs.isUserPrefsObjectSet(key));

    if (isHidden || !playerState.Player || PlayerHelper.isNew(playerState.Player, solarSystemState.SolarSystems)) {
        return null;
    }

    function dismiss() {
        appState.UserPrefs.setUserPrefsObject(key, true);
        setIsHidden(true);
    }

    return <MessageBoxWithIcon type="info" icon={IconHelper.General.Help}
        button={<DismissButton action={dismiss} />}
        text={<>Enjoying Fusion Shift? Please take a few minutes to fill out our <FeedbackLink />.</>}
    />;
});