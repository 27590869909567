import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ItemCategory } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Select } from '../../../../Components/Base/Form/Select';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { ItemCategoryTable } from './ItemCategoryTable';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

export const Commodities = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [sectorTypeName, setSectorTypeName] = React.useState<string | undefined>();
    const [itemTypeNames, setItemTypeNames] = React.useState<string[]>([]);

    React.useEffect(() => {

        if (worldState.SectorTypeSettings && sectorTypeName === undefined) {
            setSectorTypeName(Object.keys(worldState.SectorTypeSettings.data)[0]);
        }

    }, [worldState.SectorTypeSettings, sectorTypeName]);

    React.useEffect(() => {

        if (worldState.SectorTypeSettings && sectorTypeName !== undefined) {
            const sectorType = worldState.SectorTypeSettings.data[sectorTypeName];
            const demandedKeys = Object.values(sectorType.demandedItemTypeNames);
            setItemTypeNames(demandedKeys);
        }

    }, [worldState.SectorTypeSettings, sectorTypeName]);

    if (!worldState.SectorTypeSettings) {
        return null;
    }

    return <ItemCategoryTable
        category={ItemCategory.Commodity}
        {...props}
        additionalFilter={itemTypeNames}
        headerCell={<Select
            value={sectorTypeName}
            valueChanged={setSectorTypeName}
            values={Object.keys(worldState.SectorTypeSettings.data).map(x => {
                return {
                    value: x,
                    label: worldState.SectorTypeSettings!.data[x].name
                }
            })}
        />}
    />
});