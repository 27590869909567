import { observer } from "mobx-react-lite";
import * as React from "react";
import { ILoginInput, ValidationResult, World } from "../../ApplicationState/ApiClient";
import { AppStateContext, AuthenticateStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { NavButton } from "../../Components/Base/Buttons/NavButton";
import { Panel } from "../../Components/Base/Containers/Panel";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { Icon } from "../../Components/Base/Icon";
import { ErrorMessageBox, InfoMessageBox } from "../../Components/Base/MessageBox";
import { IconHelper } from "../../Helpers/IconHelper";
import { home } from "../../Navigation/Routing/AppRouteValues";
import { VersionNumbers } from "../../Navigation/VersionNumbers";
import { ForgotPasswordPrompt, RegisterPrompt } from "./AuthenticationContent";
import { AuthenticationWrapper } from "./AuthenticationWrapper";

export const LoginView = observer(() => {

    const appState = React.useContext(AppStateContext);
    const authenticateState = React.useContext(AuthenticateStateContext);

    const [world, setWorld] = React.useState<World | undefined>(undefined);

    const [emailAddress, setEmailAddress] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [password, setPassword] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [validation, setValidation] = React.useState<ValidationResult | undefined>(undefined);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        setIsSubmitting(false);
    }, [appState.IsLoggedIn]);

    React.useEffect(() => {
        if (authenticateState.Worlds && authenticateState.Worlds.length > 0 && world === undefined) {
            setWorld(authenticateState.Worlds[0]);
        }
    }, [authenticateState.Worlds, world]);

    function isValid() {
        return password.isValid && emailAddress.isValid && appState.ApplicationState !== "PerformingInitialLoad";
    }

    function login(e: any) {

        if (!isValid()) {
            return;
        }

        setValidation(undefined);
        setIsSubmitting(true);

        e.preventDefault();

        const modelData: ILoginInput = {
            password: password.validText!,
            emailAddress: emailAddress.validText!
        };

        return appState.authenticate(modelData, setValidation).finally(() => {
            setIsSubmitting(false);
        });
    }

    if (appState.IsReady) {
        appState.navigate(home);
        return null;
    }

    authenticateState.load();

    const buttonEnabled = isValid() && !isSubmitting &&
        appState.ApplicationState !== "Authenticating" &&
        appState.ApplicationState !== "PerformingInitialLoad";

    return <AuthenticationWrapper>
        <Panel
            heading="Login"
            isPaddedMobile
            headingContent={<NavButton to={home} text="Home" icon={IconHelper.Categories.Home} />}
        >
            {world && world.welcomeMessage.length > 0 && <InfoMessageBox text={world.welcomeMessage} />}
            <form onSubmit={login}>
                <ValidatingStringInput
                    label="Email Address"
                    icon={IconHelper.Account.Email}
                    valueChanged={setEmailAddress}
                    validateDelay={0}
                />
                <ValidatingStringInput
                    label="Password"
                    icon={IconHelper.Account.Password}
                    type="password"
                    valueChanged={setPassword}
                    validateDelay={0}
                />
                <ButtonHolder isPulledRight>
                    <button className="button is-success" type="submit" disabled={!buttonEnabled}>
                        <Icon icon={IconHelper.General.Confirm} title="Login" />
                        <span className="is-button-text">
                            Login
                        </span>
                    </button>
                </ButtonHolder>
            </form>
            <hr />
            <ForgotPasswordPrompt />
            <RegisterPrompt />
            {!!validation && !validation.isValid && <ErrorMessageBox text={validation.error!} />}
            <VersionNumbers clientOnly />
        </Panel>
    </AuthenticationWrapper>;
});