import { IFederation, IPlayerMinimal } from "../../../ApplicationState/ApiClient";
import { Badge, Badges, FactionBadge } from "../../../Components/Base/Badge";
import { SecurityStatusIcon } from "../../../Components/FusionShift/Icons/SecurityStatusIcon";
import { EmblemImage } from "../../../Components/FusionShift/Images/EmblemImage";
import { FederationLink } from "../../../Components/FusionShift/Links/FederationLink";
import { PlayerLink } from "../../../Components/FusionShift/Links/PlayerLink";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";


type Props = {
    player: IPlayerMinimal,
    federation: IFederation | undefined
}

export const PresidentHeading = (props: Props) => {

    const player = props.player;

    return <Badges>
        <Badge label="President" content={<PlayerLink player={player} />} icon={<EmblemImage filename={player.emblemFilename} size='small' />} />

        <Badge label="Security" content={ValueFormatter.friendlyNameForSecurityStatus(player.securityStatus)} icon={<SecurityStatusIcon securityStatus={player.securityStatus} />} />
        <Badge label="Rank" content={ValueFormatter.rank(player.rank)} icon={IconHelper.Player.Rank} />
        <Badge label="Systems" content={player.numberOfSolarSystems} icon={IconHelper.SolarSystems.SolarSystem} />

        {player.federation !== undefined && <Badge label="Federation" content={<FederationLink shortNameOnly federation={player.federation} />} icon={IconHelper.Federations.Federation} />}

        <FactionBadge factionType={props.player.factionTypeName} />

    </Badges>;
};