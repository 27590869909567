export class CanvasHelper {

    public static fillCircle(context: CanvasRenderingContext2D, x: number, y: number, radius: number,
        fill: string | CanvasGradient | CanvasPattern,
        strokeWidth?: number,
        stroke?: string | CanvasGradient | CanvasPattern) {

        context.beginPath();
        context.arc(x, y, radius, 0, 2 * Math.PI, false);
        context.fillStyle = fill;
        context.fill();

        if (strokeWidth !== undefined && strokeWidth > 0) {
            context.lineWidth = strokeWidth;
            context.strokeStyle = stroke ?? "black";
            context.stroke();
        }
    }

    public static strokeCircle(context: CanvasRenderingContext2D, x: number, y: number, radius: number,
        stroke: string | CanvasGradient | CanvasPattern,
        strokeWidth?: number,
    ) {

        context.beginPath();
        context.arc(x, y, radius, 0, 2 * Math.PI, false);

        context.lineWidth = strokeWidth ?? 1;
        context.strokeStyle = stroke;
        context.stroke();
    }

    public static dashLine(context: CanvasRenderingContext2D,
        xA: number, yA: number,
        xB: number, yB: number,
        stroke: string | CanvasGradient | CanvasPattern,
        strokeWidth?: number,
        lineDash?: number[]
    ) {
        this.strokeLine(context, xA, yA, xB, yB, stroke, strokeWidth, [5, 15]);
    }

    public static strokeLine(context: CanvasRenderingContext2D,
        xA: number, yA: number,
        xB: number, yB: number,
        stroke: string | CanvasGradient | CanvasPattern,
        strokeWidth?: number,
        lineDash?: number[]
    ) {

        if (lineDash !== undefined) {
            context.save();
            context.setLineDash(lineDash);
        }

        context.beginPath();
        context.moveTo(xA, yA);
        context.lineTo(xB, yB);
        context.lineWidth = strokeWidth ?? 1;
        context.strokeStyle = stroke;
        context.stroke();

        if (lineDash !== undefined) {
            context.restore();
        }
    }

    public static strokeCross(context: CanvasRenderingContext2D, x: number, y: number, radius: number,
        stroke: string | CanvasGradient | CanvasPattern,
        strokeWidth?: number,
    ) {

        this.strokeLine(context, x, y - radius, x, y + radius, stroke, strokeWidth);
        this.strokeLine(context, x - radius, y, x + radius, y, stroke, strokeWidth);
    }

    public static strokeSquare(context: CanvasRenderingContext2D, x: number, y: number, radius: number,
        stroke: string | CanvasGradient | CanvasPattern,
        strokeWidth?: number,
    ) {

        this.strokeLine(context, x - radius, y - radius, x - radius, y + radius, stroke, strokeWidth);
        this.strokeLine(context, x - radius, y - radius, x + radius, y - radius, stroke, strokeWidth);

        this.strokeLine(context, x + radius, y - radius, x + radius, y + radius, stroke, strokeWidth);
        this.strokeLine(context, x - radius, y + radius, x + radius, y + radius, stroke, strokeWidth);
    }


    public static strokeTriangle(context: CanvasRenderingContext2D, x: number, y: number, radius: number,
        stroke: string | CanvasGradient | CanvasPattern,
        strokeWidth?: number,
    ) {
        const hr = (radius / 2);

        this.strokeLine(context, x - radius, y + hr, x + radius, y + hr, stroke, strokeWidth);
        this.strokeLine(context, x - radius, y + hr, x, y - radius, stroke, strokeWidth);
        this.strokeLine(context, x + radius, y + hr, x, y - radius, stroke, strokeWidth);
    }

    public static drawText(context: CanvasRenderingContext2D, x: number, y: number, text: string,
        stroke: string | CanvasGradient | CanvasPattern,
        size: number,
    ) {
        context.save();

        context.font = `${size}pt Roboto`;
        context.textAlign = "center";
        context.fillStyle = stroke;
        context.strokeStyle = stroke;

        context.fillText(text, x, y);

        context.restore();
    }
}