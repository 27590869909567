import * as React from 'react';
import { ProblemCategory } from '../../../Entities/SolarSystem/Problems/Problem';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Icon } from '../../Base/Icon';

type Props = {
    category: ProblemCategory
}

export const ProblemCategoryIcon = (props: Props) => {

    if (props.category === ProblemCategory.Military) {
        return <Icon icon={IconHelper.Combat.Combat} />;
    }
    if (props.category === ProblemCategory.Megacities) {
        return <Icon icon={IconHelper.Megacities.Megacity} />;
    }
    if (props.category === ProblemCategory.Economy) {
        return <Icon icon={IconHelper.Items.Resource} />;
    }
    if (props.category === ProblemCategory.Manufactories) {
        return <Icon icon={IconHelper.Manufactories.Manufactory} />;
    }
    if (props.category === ProblemCategory.Ships) {
        return <Icon icon={IconHelper.Ships.Ship} />;
    }
    return <Icon icon={IconHelper.General.Missing} />;
};