// import { MaterialDesignIcons } from "./MaterialDesignIcons";

// export const FSOIcons = {

//     statusCovert: MaterialDesignIcons.incognito,
//     statusOvert: MaterialDesignIcons.podcast,
//     goCovert: MaterialDesignIcons.incognitoCircle,
//     goOvert: MaterialDesignIcons.incognitoCircleOff,

//     creditIncome: "fso-icon icon-FS_Credit_income",
//     creditTransaction: "fso-icon icon-FS_Credit_transaction",
//     credits: "fso-icon icon-FS_Credits",

//     subscription: "fso-icon icon-FS_Subscription",
//     currency: "fso-icon icon-FS_Currency",

//     civilian: MaterialDesignIcons.face,
//     combatActive: MaterialDesignIcons.pirate,
//     protected: MaterialDesignIcons.shieldSpecial,
//     exile: MaterialDesignIcons.skull,

//     buildShip: MaterialDesignIcons.airport,
//     activateShip: MaterialDesignIcons.airplaneTakeoff,
//     groundShip: MaterialDesignIcons.airplaneLanding,
//     sendShip: MaterialDesignIcons.airplaneTakeoff,
//     ship: MaterialDesignIcons.airplane,

//     advisor: "fso-icon icon-FS_Advisor"
// };

export const FSOIcons = {


    statusCovert: "fso-icon icon-FS_Status_covert",
    statusOvert: "fso-icon icon-FS_Status_overt",
    goCovert: "fso-icon icon-FS_Go_covert",
    goOvert: "fso-icon icon-FS_Go_overt",

    creditIncome: "fso-icon icon-FS_Credit_income",
    creditTransaction: "fso-icon icon-FS_Credit_transaction",
    credits: "fso-icon icon-FS_Credits",

    subscription: "fso-icon icon-FS_Subscription",
    currency: "fso-icon icon-FS_Currency",

    civilian: "fso-icon icon-FS_Civilian",
    combatActive: "fso-icon icon-FS_Combat_active",
    protected: "fso-icon icon-FS_Protected",
    exile: "fso-icon icon-FS_Exile",

    buildShip: "fso-icon icon-FS_Build_ship",
    activateShip: "fso-icon icon-FS_Activate_ships",
    groundShip: "fso-icon icon-FS_Ground_ships",
    sendShip: "fso-icon icon-FS_Send_ship",
    ship: "fso-icon icon-FS_Ship",

    advisor: "fso-icon icon-FS_Advisor"
};