import { observer } from "mobx-react-lite";
import React from "react";
import { SolarSystemForObserver } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../../ApplicationState/ContextRoot";
import { Select } from "../../Base/Form/Select";
import { Paragraph } from "../../Base/Text/Paragraph";

type Props = {
    prompt?: string | undefined,
    solarSystemFoundCallback: (solarSystem: SolarSystemForObserver | undefined) => any
}

export const ChooseRecentSolarSystem = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [solarSystemId, setSolarSystemId] = React.useState<number | undefined>(undefined);

    const [solarSystem, setSolarSystem] = React.useState<SolarSystemForObserver | undefined>(undefined);

    React.useEffect(() => {
        if (solarSystemId) {
            apiState.SolarSystemClient.get(solarSystemId).then(solarSystemResponse => {
                setSolarSystem(solarSystemResponse);
            });
        }
    }, [solarSystemId]);

    React.useEffect(() => {
        if (solarSystem) {
            props.solarSystemFoundCallback(solarSystem);
        }
    }, [solarSystem]);

    if (appState.RecentSolarSystems === undefined) {
        return null;
    }

    const prompt = props.prompt ?? "Target a recent solar systems:";

    return <div className="field">
        <Paragraph type="prompt">
            {prompt}
        </Paragraph>
        <Select className="is-small" valueChanged={e => setSolarSystemId(Number(e))} showUnset={{
            label: "Recent solar systems"
        }}
            values={
                appState.RecentSolarSystems.map(s => {

                    return {
                        value: s.solarSystemId,
                        label: s.name
                    };
                })
            }
        />
    </div>;
});