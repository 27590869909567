import { FederationRank, IFederation, IFederationDetail, IGameSettings, IInstallationTypeSettings, IPlayer, IPlayerFederation, IPlayerMinimal, ISolarSystemDetail } from "../ApplicationState/ApiClient";

export class FederationHelper {

    public static federationInstallationType(installationTypeSettings: IInstallationTypeSettings | undefined) {
        if (!installationTypeSettings) {
            return undefined;
        }

        for (let i in installationTypeSettings.data) {
            if (installationTypeSettings.data[i].allowsFederations) {
                return installationTypeSettings.data[i]
            }
        }

        return undefined;
    }

    public static hasOfficerCapacity(federation: IFederationDetail | undefined, gameSettings: IGameSettings | undefined): boolean {
        if (!federation || !gameSettings || !federation.maximumOfficers) {
            return false;
        }

        return federation.maximumOfficers > federation.officers.length;
    }

    public static canCreateFederation(gameSettings: IGameSettings | undefined, installationTypeSettings: IInstallationTypeSettings | undefined, player: IPlayerMinimal | null | undefined, solarSystem: ISolarSystemDetail | null | undefined): boolean {

        if (!installationTypeSettings || !gameSettings || !player || !solarSystem || player.federation || !solarSystem.celestials) {
            return false;
        }

        for (const c of solarSystem.celestials) {
            if (c.installations) {
                for (const i of c.installations) {
                    if (i && i.installationTypeName && i.installationTypeName.length > 0) {
                        const installationType = installationTypeSettings.data![i.installationTypeName || ""];
                        if (installationType.allowsFederations && i.level >= gameSettings.federation.embassyLevelToFoundFederation) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    public static canLeave(player: IPlayer | undefined, federation: IFederationDetail | undefined, playerFederation: IPlayerFederation | undefined): boolean {
        if (!player || !federation || !playerFederation || !playerFederation.federationId || playerFederation.federationId !== federation.federationId) {
            return false;
        }

        if (player.isDefeated) {
            return false;
        }

        if (playerFederation.federationRank === FederationRank.Leader && federation.officers.length === 0) {
            return false;
        }
        return true;
    }

    public static isLeader(playerFederation: IPlayerFederation | undefined) {
        if (playerFederation && playerFederation.federationId !== undefined) {
            return playerFederation.federationRank === FederationRank.Leader;
        }

        return false;
    }

    public static isOfficer(playerFederation: IPlayerFederation | undefined) {
        if (playerFederation && playerFederation.federationId !== undefined) {
            return playerFederation.federationRank === FederationRank.Officer;
        }

        return false;
    }

    public static isLeaderOrOfficer(playerFederation: IPlayerFederation | undefined, federation?: IFederation | undefined) {

        if (playerFederation && playerFederation.federationId !== undefined) {
            const isOfficer = playerFederation.federationRank === FederationRank.Leader || playerFederation.federationRank === FederationRank.Officer;

            return isOfficer && (federation === undefined || federation.federationId === playerFederation.federationId);
        }

        return false;
    }
}