import { GameSettings, IGameSettings, MegacitySector, SectorType } from "../ApplicationState/ApiClient";

export class MegacityHelper {

    public static calculateBonusForSector(gameSettings: IGameSettings, sector: MegacitySector, sectorType: SectorType, assigned: number): number {
        if (assigned <= 0) {
            return 0;
        }

        const commodityKeys = Object.values(sectorType.demandedItemTypeNames);
        let numSatisfied = 0;

        for (let c of commodityKeys) {
            if (c in sector.commodityStores && sector.commodityStores[c].quantity > 0) {
                numSatisfied++;
            }
        }

        return this.calculateBonus(gameSettings, sectorType, assigned, numSatisfied);
    }

    public static calculateBonus(gameSettings: IGameSettings, sectorType: SectorType, assigned: number, commoditiesSatisfied: number): number {
        if (assigned <= 0) {
            return 0;
        }

        const commodityKeys = Object.values(sectorType.demandedItemTypeNames);
        const total = commodityKeys.length;

        const population = assigned / gameSettings.megacity.sectorMaximumAssigned;
        const commodities = commoditiesSatisfied / total;

        return (0.5 * population) + (0.5 * commodities);
    }

    public static calculateBonusAssumingAllCommodities(gameSettings: IGameSettings, sectorType: SectorType, assigned: number): number {
        if (assigned <= 0) {
            return 0;
        }

        const commodityKeys = Object.values(sectorType.demandedItemTypeNames);
        return this.calculateBonus(gameSettings, sectorType, assigned, commodityKeys.length);

    }

    public static calculateGeneratedCredits(gameSettings: IGameSettings, sectorType: SectorType, assigned: number) {
        return gameSettings.gameSpeed * sectorType.generatedCredits * assigned;
    }

    public static calculateConsumption(sector: MegacitySector, gameSettings: GameSettings) {
        return sector.assignedPopulation * gameSettings.megacity.commodityConsumptionPerPopulationPerHour * gameSettings.gameSpeed;
    }
}