import * as React from 'react';
import { FleetMovementType } from '../../../ApplicationState/ApiClient';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { Select } from '../../../Components/Base/Form/Select';
import { Icon } from '../../../Components/Base/Icon';
import { NumberAbbreviator } from '../../../Components/Base/Text/NumberAbbreviator';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { handleOnClick } from '../../../Components/Base/Util';
import { SendShipsBetweenSystemButtons } from '../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { FleetSummaryIcons } from '../../../Components/FusionShift/Icons/FleetSummaryIcons';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { TextHelper } from '../../../Helpers/TextHelper';
import { empire } from '../../../Navigation/Routing/Misc';
import { ShipControl, ShipTabs } from '../../Fleets/FleetControl';

const movementTypes = [
    FleetMovementType.Reinforce,
    FleetMovementType.Rebase
];

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type RowProps = {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem: SolarSystemWrapper,
    expandTo: ShipTabs | undefined
}

type BaseRowProps = {
    children: React.ReactNode,
    totalDefence: number,
    totalAttack: number,
    availableDefence: number
}


const BaseRow = (props: BaseRowProps) => {

    return <>
        <td>
            {props.children}
        </td>
        <td>
            <NumberAbbreviator value={props.availableDefence} />
        </td>
        <td className='is-hidden-mobile'>
            <NumberAbbreviator value={props.totalDefence} />
        </td>
        <td>
            <NumberAbbreviator value={props.totalAttack} />
        </td>
    </>;
};


const SummaryRow = (props: RowProps) => {

    const appState = React.useContext(AppStateContext);
    const allFleets = props.solarSystem.solarSystem.fleets ?? [];
    const [isExpanded, setIsExpanded] = React.useState(false);
    const hasExpand = allFleets.length > 0;

    React.useEffect(() => {
        if (props.expandTo === undefined) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
        }
    }, [props.expandTo]);

    return <>
        <tr onClick={e => handleOnClick(e, () => setIsExpanded(ex => hasExpand && !ex))}>
            <BaseRow {...props.solarSystem.militaryStats}>
                <SelectSolarSystemLink
                    solarSystem={props.solarSystem.solarSystem}
                    hideActionIcons
                    isMobileTitle
                />
            </BaseRow>
            <td className='is-hidden-mobile'>
                <FleetSummaryIcons fleets={allFleets} showCount />
            </td>
            <td>
                <ButtonHolder>
                    {props.showSendButtons &&
                        <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem.solarSystem} movementTypes={movementTypes} />
                    }
                    <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} isDisabled={!hasExpand} />
                </ButtonHolder>
            </td>
        </tr>
        <tr className="is-hidden-tablet">
            <td />
            <td colSpan={3}>
                <FleetSummaryIcons fleets={allFleets} showCount />
            </td>
        </tr>
        {isExpanded &&
            <tr>
                <td colSpan={6}>
                    <ShipControl
                        initialTab={props.expandTo}
                        isCompressed hideIfDontHaveInitial
                        solarSystem={props.solarSystem}
                        reloadCallback={() => appState.navigate(empire)}
                        hideExtras
                        hasSmallTitleOnMobile
                    />
                </td>
            </tr>
        }
    </>;
};

export const SummaryTable = (props: Props) => {

    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    const [expandTo, setExpandTo] = React.useState<ShipTabs | undefined>(undefined);

    const totalAttack = CollectionHelper.sumOfArrayValue(props.solarSystems, x => x.militaryStats.totalAttack);
    const totalDefence = CollectionHelper.sumOfArrayValue(props.solarSystems, x => x.militaryStats.totalDefence);
    const availableDefence = CollectionHelper.sumOfArrayValue(props.solarSystems, x => x.militaryStats.availableDefence);

    const expandToOptions = [
        "Stationed", "Grounded", "PresentForeign", "Departures", "Arrivals", "Repeats"
    ].map(x => {
        return {
            label: TextHelper.tidyText(x),
            value: x
        }
    });

    function changeExpandTo(value: string) {
        setExpandTo(value as ShipTabs);
    }

    return <>
        <Table isHoverable isFullWidth
            isMobileMultirow
            heading={
                <>
                    <th>
                    </th>
                    <th>
                        <span className='is-hidden-mobile'>Available  Defence</span> <Icon icon={IconHelper.Combat.Defence} />
                    </th>
                    <th className='is-hidden-mobile'>
                        <span className='is-hidden-mobile'>Total Defence</span> <Icon icon={IconHelper.Combat.Defence} />
                    </th>
                    <th>
                        <span className='is-hidden-mobile'>Total Attack</span> <Icon icon={IconHelper.Combat.Attack} />
                    </th>
                    <th className='is-hidden-mobile'>
                        Movements
                    </th>
                    <th>
                        <Select value={expandTo} valueChanged={changeExpandTo} values={expandToOptions} className="is-pulled-right" showUnset={{ label: "Expand to" }} />
                    </th>
                </>
            }>
            {props.solarSystems.map(s => <SummaryRow
                sourceSolarSystemId={sourceSolarSystemId}
                setSourceSolarSystemId={setSourceSolarSystemId}
                showSendButtons={props.solarSystems.length > 1}
                key={s.solarSystem.solarSystemId}
                solarSystem={s}
                expandTo={expandTo}
            />)}
            <tr key="total" className="is-total">
                <BaseRow
                    totalAttack={totalAttack}
                    totalDefence={totalDefence}
                    availableDefence={availableDefence}
                >
                    Total
                </BaseRow>
                <td></td>
                <td></td>
            </tr>
        </Table>
        <Paragraph>
            Available defence shows the sum of combat ships, installations, drones and reinforcements that are stationed and ready to defend the system. Total defence shows available defence plus the defence of fleets that are out on movements.
        </Paragraph>
    </>;
};

