// https://materialdesignicons.com/
// icons to use:
// android-studio (geometric compass)
// cogs
// atom variant
// barrel
// brain
// flash
// flask
// screwdriver
// recycle
// snowflake
// spider-web
// leaf
// nuke
// diamond
// biohazard
// grave-stone
// location-enter, location-exit

// Mapping from descriptive names of icon to material icons. It's ok to rename if the material design classname makes no sense out of context
export const MaterialDesignIcons = {
    sword: "sword",
    shield: "shield",
    axe: "axe",
    bomb: "bomb",
    compass: "compass",
    compassOff: "compass-off",
    skullAndCrossBones: "skull-crossbones",
    missing: "crop-square",
    intercept: "vector-point-select",
    tactics: "gamepad-circle-right",
    binoculars: "binoculars",
    roman1: "roman-numeral-1",
    roman2: "roman-numeral-2",
    roman3: "roman-numeral-3",
    roman4: "roman-numeral-4",
    time: "clock-outline",
    tick: "check",
    phoneRotateLandscape: "phone-rotate-landscape",
    cross: "cancel",
    magnify: "magnify",
    magnifyPlus: "magnify-plus",
    magnifyMinus: "magnify-minus",
    alertRhombus: "alert-rhombus",
    alertCircle: "alert-circle",
    helpCircle: "help-circle",
    shieldSpecial: "shield-sun-outline",
    lock: "lock",
    lockOpen: "lock-open",
    lockTick: "lock-check",
    lockQuestion: "lock-question",
    face: "face-outline",
    pirate: "pirate",
    skull: "skull",
    city: "city",
    account: "account",
    accountBoxEdit: "account-box-edit-outline",
    accountSupervisor: "account-supervisor",
    accountCircle: "account-circle-outline",
    accountGroup: "account-group",
    accountStar: "account-star",
    accountSwitch: "account-switch",
    grid: "view-grid",
    bed: "bed",
    bedEmpty: "bed-empty",
    wheat: "barley",
    anvil: "anvil",
    factory: "factory",
    medal: "medal",
    cart: "cart",
    castle: "castle",
    computer: "desktop-classic",
    triangle: "triangle",
    triangleOutline: "triangle-outline",
    star: "star",
    starOutline: "star-outline",
    shovel: "shovel",
    home: "home",
    homePlus: "home-plus",
    basket: "basket",
    basketFill: "basket-fill",
    basketUnfill: "basket-unfill",
    bug: "bug",
    fire: "fire",
    heart: "heart",
    arrowLeft: "arrow-left-bold",
    arrowRight: "arrow-right-bold",
    arrowUp: "arrow-up-bold",
    arrowDown: "arrow-down-bold",
    on: "flash",
    off: "flash-off",
    chessKing: "chess-king",
    chessRook: "chess-rook",
    debug: "debug-step-into",
    cubeOutline: "cube-outline",
    cube: "cube",
    cubeSend: "cube-send",
    cashRefund: "cash-refund",
    cash: "cash",
    cashMultiple: "cash-multiple",
    delete: "delete",
    cloud: "cloud",
    cog: "cog",
    cogOutline: "cog-outline",
    cogOutlineOff: "cog-off-outline",
    atom: "atom",
    group: "group",
    barrel: "barrel",
    package: "package-variant",
    currency: "currency-usd-circle",
    buffer: "buffer",
    bank: "bank",
    airplane: "airplane",
    airport: "airport",
    airplaneTakeoff: "airplane-takeoff",
    airplaneLanding: "airplane-landing",
    hammer: "hammer",
    headCog: "head-cog",
    planet: "radiobox-blank",
    planetEarth: "earth",
    earthOff: "earth-off",
    buildings: "home-city",
    abacus: "abacus",
    clock: "clock-outline",
    play: "play",
    stop: "stop",
    pause: "pause",
    volumeHigh: "volume-high",
    trophy: "trophy",
    map: "map",
    mapMarkerRadius: "map-marker-radius-outline",
    mapSearch: "map-search-outline",
    vote: "vote",
    karate: "karate",
    eye: "eye",
    plus: "plus",
    minus: "minus",
    exit: "exit-run",
    calendar: "calendar-month",
    currencyINR: "currency-inr",
    professional: "professional-hexagon",
    repeat: "repeat",
    repeatOff: "repeat-off",
    chartBar: "chart-bar",
    chartLine: "chart-line",
    pencil: "pencil",
    email: "email",
    chevronDown: "chevron-down",
    chevronLeft: "chevron-left",
    chevronRight: "chevron-right",
    expandAll: "expand-all",
    expandAllArrow: "arrow-expand-all",
    tag: "tag",
    menu: "menu",
    menuDown: "menu-down",
    offline: "lan-disconnect",
    target: "target",
    lifebuoy: "lifebuoy",
    listCheck: "format-list-checks",
    crown: "crown",
    sigma: "sigma",
    openInNew: "open-in-new",
    close: "close",
    message: "message",
    messageAlert: "message-alert",
    messageDraw: "message-draw",
    question: "help",
    flag: "flag",
    import: "import",
    speedometer: "speedometer",
    export: "export",
    fileAlert: "file-alert-outline",
    fileCheck: "file-check-outline",
    fileCancel: "file-cancel-outline",
    fileEdit: "file-edit-outline",
    fileCog: "file-cog-outline",
    applicationCog: "application-cog",
    gridPlus: "view-grid-plus",
    chemicalWeapon: "chemical-weapon",
    microChip: "chip",
    quadcopter: "quadcopter",
    orbit: "orbit",
    flask: "flask",
    floppy: "floppy",
    incognito: "incognito",
    handcuffs: "handcuffs",
    cardAccountDetailsStar: "card-account-details-star-outline",
    earthArrowRight: "earth-arrow-right",
    findReplace: "find-replace",
    flash: "flash",
    formatVerticalAlignBottom: "format-vertical-align-bottom",
    formatVerticalAlignTop: "format-vertical-align-top",
    hail: "hail",
    incognitoCircleOff: "incognito-circle-off",
    incognitoCircle: "incognito-circle",
    podcast: "podcast",
    school: "school",
    grave: "grave-stone",
    network: "hubspot",
    reload: "reload",
    security: "security",
    towerFire: "tower-fire",
    swapHorizontal: "swap-horizontal",
};