import { observer } from "mobx-react-lite";
import React from "react";
import { FleetMovementType } from "../../../ApplicationState/ApiClient";
import { Button } from "../../../Components/Base/Buttons/Button";
import { Checkbox } from "../../../Components/Base/Form/Checkbox";
import { IconHelper } from "../../../Helpers/IconHelper";
import { valuesFromEnum } from "../../../Helpers/TypeHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { MapPreferences } from "./MapPreferences";

type Props = {
    preferences: MapPreferences,
    modifyPreferences: (action: (prefs: MapPreferences) => any) => any
}

type InputProps = {
    name: string,
    initialValue: boolean,
    modifyPreference: (value: boolean) => any
}

const MapPreferenceInput = observer((props: InputProps) => {

    const [value, setValue] = React.useState(props.initialValue);

    function toggle() {
        const toSet = !value;
        props.modifyPreference(toSet);
        setValue(toSet);
    }

    return <Checkbox value={value} valueChanged={toggle} label={props.name} />;
});

export const PreferencesControl = (props: Props) => {

    const [open, setOpen] = React.useState(false);

    const movementTypes = valuesFromEnum(FleetMovementType);

    return <div className={`map-preferences dropdown ${open ? "is-active" : ""}`}>
        <div className="dropdown-trigger">
            <Button type="nav" text="Overlay" icon={IconHelper.General.OpenDropdown} action={() => setOpen(!open)} />
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
                <div className="dropdown-item">
                    <MapPreferenceInput key="overlay" name="Show Overlay" initialValue={props.preferences.showOverlay} modifyPreference={v => props.modifyPreferences(p => p.showOverlay = v)} />
                    <div className="dropdown-item">
                        <MapPreferenceInput key="overlay" name="Radar Coverage" initialValue={props.preferences.showRadarCoverage} modifyPreference={v => props.modifyPreferences(p => p.showRadarCoverage = v)} />
                        <div className="dropdown-item">
                            <MapPreferenceInput key="overlay" name="Own" initialValue={props.preferences.showRadarCoverageSelf} modifyPreference={v => props.modifyPreferences(p => p.showRadarCoverageSelf = v)} />
                        </div>
                        <div className="dropdown-item">
                            <MapPreferenceInput key="overlay" name="Ally" initialValue={props.preferences.showRadarCoverageAlly} modifyPreference={v => props.modifyPreferences(p => p.showRadarCoverageAlly = v)} />
                        </div>
                    </div>
                    <div className="dropdown-item">
                        <MapPreferenceInput key="overlay" name="Ship Movements" initialValue={props.preferences.showFleets} modifyPreference={v => props.modifyPreferences(p => p.showFleets = v)} />
                        <div className="dropdown-item">
                            <MapPreferenceInput key="overlay" name="Own" initialValue={props.preferences.showRadarCoverageSelf} modifyPreference={v => props.modifyPreferences(p => p.showFleetsSelf = v)} />
                        </div>
                        <div className="dropdown-item">
                            <MapPreferenceInput key="overlay" name="Ally" initialValue={props.preferences.showFleetsAlly} modifyPreference={v => props.modifyPreferences(p => p.showFleetsAlly = v)} />
                        </div>
                        <div className="dropdown-item">
                            <MapPreferenceInput key="overlay" name="Enemy" initialValue={props.preferences.showFleetsEnemy} modifyPreference={v => props.modifyPreferences(p => p.showFleetsEnemy = v)} />
                        </div>
                        <div className="dropdown-item">
                            <MapPreferenceInput key="overlay" name="Hostile Only" initialValue={props.preferences.showFleetsHostileOnly} modifyPreference={v => props.modifyPreferences(p => p.showFleetsHostileOnly = v)} />
                        </div>
                    </div>
                    <div className="dropdown-item">
                        <span>Movement Types</span>
                        {movementTypes.map(m => <div key={m} className="dropdown-item">
                            <MapPreferenceInput
                                name={ValueFormatter.friendlyNameForMovementType(m)}
                                initialValue={props.preferences.hideFleetMovementTypes === undefined || !props.preferences.hideFleetMovementTypes.includes(m)}
                                modifyPreference={v => props.modifyPreferences(p => {
                                    if (p.hideFleetMovementTypes === undefined) {
                                        p.hideFleetMovementTypes = [];
                                    }
                                    if (v) {
                                        p.hideFleetMovementTypes = p.hideFleetMovementTypes.filter(t => t != m);
                                    } else if (!p.hideFleetMovementTypes.includes(m)) {
                                        p.hideFleetMovementTypes.push(m)
                                    }
                                })}
                            />
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    </div>;
};