import { ChangeBlob } from './ChangeBlob';

export const archiveChanges : ChangeBlob[] = [
	{
		date: new Date('2019-07-11'),
		introduction: [
			'This is the very first version of Fusion Shift Online that has been deployed.',
			'It contains the following features:'
		],
		changes: [
			'* User registration and player creation',
			'* System and celestial implementation',
			'* Building of installations and ships',
			'* Sending of ships: Reinforcements, deliveries, attacks, mining and colonization',
			'* Creating, joining and managing Federations',
			'* Scripted and dockerized build'
		],
	},
	{
		date: new Date('2019-07-12'),
		introduction: [
			'Worked on introducing new civilizations and balancing ship types. There are now 3 civilizations to play as with subtle differences between them.',
			'Added a variety of small features and code improvements that make the application easier to develop and play.'
		],
		changes: [
			'* Added a section of the build script that will compile changes into the application',
			'* Implemented a cookie warning banner. Thanks EU.',
			'* Refactored all Icon literal strings to properties of the IconHelper',
			'* Displayed ticks as time rather than as an arbitrary integer',
			'* Clear cookies properly on logout',
			'* Simplified database calls by removing world id from queries',
			'* Defaulted map size to 50x50',
			'* Show estimate of ship movement duration when planning a ship movement',
			'* Default map view to current solar system',
			'* Fixed hauler so it doesn\'t mine',
			'* Created an admin screen that lists users',
			'* Added a field and MVC filter to track the last seen date of users',
			'* Added admin cheats to add ships and resources to a solar system',
			'* Restyled buttons in table rows',
			'* Added a tile holder class to improve layout. Used it on the ships control',
			'* Show capacity when mining or raiding or colonizing or trading. Not when reinforcing',
			'* Several layout tweaks to solar system view',
			'* Added civilization starting ships',
			'* Renamed Centauri ships',
			'* Added a build tool that validates ship balancing',
			'* Added a new ship - Advanced Mining Barge ',
			'* Added 2 new civilizations',
			'* Generation of non-player solar system is more random',
			'* At world generation more NPC players will be generated',
			'* Limit player name to 15 characters',
			'* Simplified Player spawning and creation so it is a single step',
			'* civilization description control. Used at register screen',
			'* Everyone starts with 5 fighters',
			'* Made recon ships very fast but weak',
			'* Allowed access to home if not logged in. Show login / register at top menu',
			'* Added "None" and "All" buttons to the send ship form',
			'* Worked on improving top menu when on mobile viewports',
			'* Added menu hints for unread notifications and combat reports',
			'* Fixed a combat bug where excessive ships on the winning side were being destroyed',
			'* Added an admin tool to reload the world\'s type data mid-game'
		],
	},
	{
		date: new Date('2019-07-13'),
		introduction: [
			'Various tweaks and improvements after a bit of testing. Things like renaming of solar systems, deleting of build queue items and a bit of rebalancing.'
		],
		changes: [
			'* Fixed admin cheat screen which wasn\'t giving ships properly',
			'* The fleet\'s mission will be inferred from the first selected ship. E.g if a mining ship is selected first then it is assumed to be a mining mission.',
			'* Default send ships coordinate search to coordinate of current system',
			'* Show player civilization type on player menu, player view and federation screens',
			'* Started fixing React key index attributes where a natural key is available',
			'* Show the capacity of the attacker\'s surviving fleet',
			'* Resource generating installations will add 250 capacity per level',
			'* Fixed a bug in the data generator where properties were being overriden with incorrect values',
			'* Recon ships have 0 cargo capacity',
			'* Siege units have 0 cargo capacity, attack and defence',
			'* Defenders who are allied with the attacker will not take part in combat',
			'* Show details of incoming ships if allied',
			'* Fixed reinforcements display',
			'* Show the source solar system on the send ships button',
			'* Show unread notification on hamburger',
			'* Fiddled with navigation on mobile',
			'* Added notification for when installation build queue is empty',
			'* Added notification for when ship build queue is empty',
			'* Mark all notifications / combat reports as read',
			'* Delete all notifications / combat reports ',
			'* Fixed a few cases where the notification index or view won\'t load',
			'* Remove installations from build queue',
			'* Remove ships from ship build queue',
			'* Allow renaming of solar system',
			'* Updated UI to account for the fact that people might rename solar systems',
			'* When spawning enemies they will not always have so many ships',
			'* Fixed several bugs with ship limitations per level',
			'* Show no entities messages when loading tables without content',
			'* Fixed map cell sizes',
			'* Trying to fix a BsonId error in prod. Added temporary filter against BuildQueueItem to MongoDb conventions',
			'* Made build errors into a React node so the resource icons can be displayed',
			'* Added cheat to complete all build queue items next tick',
			'* Buildings can provide defence that is added to the defence power when the system is attacked',
			'* Bureaucratic hubs offer an additional 250 defence per level',
			'* Added SSM battery. It adds combat strength to the defenders, like a turret',
			'* Combat reports will now contain information about the installations at the target solar system. You get more information depending on the result of the fight',
			'* Added a few more descriptions to some installations',
			'* Added an admin cheat to complete all ship movements next tick',
			'* Added a send ships icon to all solar system links',
			'* Added shortcut to cheat self page from admin menu',
			'* Fiddled Fusion Shift brand logo on menu to be smaller on mobile',
			'* Fixed a bug where you can\'t join a federation if you aren\'t already in a federation',
			'* Fixed the admin cheat screen',
			'* Fixed a bug that would cause the player view to not load',
			'* Hide send ships if there are no available ships in the current solar system',
			'* Made solar system stats not collapse on mobile',
			'* Moved solar system buttons to directly under the title',
			'* Fixed burger menu so it pushes the page content down. It is highlighted yellow when open, instead of rotating the icon',
			'* Fixed a bug that stopped federation page from displaying'
		],
	},
	{
		date: new Date('2019-07-14'),
		introduction: [
			'Demonstrating the deploy capabilities of the Fusion Shift Project. ',
			'I\'ve also done some changes to do with federations.'
		],
		changes: [
			'* Added some serverside helpers to better join player and federation data',
			'* Made a serverside helper for solar system models too',
			'* Show player federation ticker and rank icon in name',
			'* Demonstrating the changes features of the build tool'
		],
	},
	{
		date: new Date('2019-07-15'),
		introduction: [
			'Implemented a few changes that make scouting and recon more useful in the game. ',
			'When attacking, information about the defenders may be blanked out depending on how much you lost the fight by. This means when you attack or recon you don\'t necessarily find out all the information. An attack where at least 1 attacker survives will always contain all the information about ships and installations.',
			'Recon ships can go on recon missions to try to gather intel about the ships present in a system. They will not apply any attacking damage but will only face 5% of the defending strength and turrets / installations will not contribute towards this. This means they have a high chance of surviving and revealing intel about the system.',
			'Ships can now have probes and initially the only ship with probes is the recon ships for each race. A ship with probes in a foreign system can see any incoming movements or present foreign ships.',
			'Additionally you can now set your bio and federation bio. Markdown is supported. You can also send messages to players, with the option of sending it federation wide if you are an officer or leader. '
		],
		changes: [
			'* Gave the non-Centauri a few more starting ships',
			'* Added previous and next solar system buttons to mobile nav menu',
			'* Collapsed the navbar a bit so it is not as tall',
			'* Implemented "HasProbes" for ships (initially on recon)',
			'* Added recon mission types',
			'* Made it so that combat reports may have uncertain numbers of ships',
			'* Implemented public bio for players',
			'* Added an admin cheat to set all installations to max level. It also clears all the build queues',
			'* Made the celestial lists into a table. Shows icons to reflect state (damage, building, building ships)',
			'* Deconstruct installations',
			'* Recent solar systems will show in a dropdown in the send ship control',
			'* Sending and receiving of messages',
			'* Made the solar system detail view change to the newly selected solar system when you change selection',
			'* Fixed some icon hover tooltips that were not displaying',
			'* Moved text area to its own component'
		],
	},
	{
		date: new Date('2019-07-19'),
		introduction: [
			'Working on making NPCs do stuff such as build installations and send ships to recon, mine and raid. NPCs can be regenerated from the admin screens. This will work in line with existing worlds and means that worlds should not have to be regenerated. ',
			'Added functionality to federations where the maximum members, invites and officers are limited by the total embassy level among all members.',
			'As always a variety of interface updates and bug fixes.'
		],
		changes: [
			'##### New features',
			'* Federation capacities are now limited by the total embassy levels within them',
			'* Initial NPC implementation',
			'* NPCs will form random federations when generated',
			'* NPCs know how to respond to federation invitations',
			'* NPCs will invite nearby players to their federation',
			'* NPCs will promote players within their federation',
			'* NPCs build and upgrade installations',
			'* NPCs build ships according to their configured desired ship class compositions',
			'* NPCs will build ships and resources according to a balanced ratio',
			'* NPCs track information about nearby solar systems to better inform their decisions',
			'* NPCs will send out recon missions to gather information about their surrounding solar systems',
			'* NPCs will mine unoccupied systems according to which resources they need',
			'* NPCs will raid systems where they think they can win',
			'##### UI changes and fixes',
			'* Using a different way to load the CSS that should mean it automatically reloads if changed (no need for force refresh)',
			'* Hide "FSO" brand name on mobile to save space',
			'* Added a loading bar on menu',
			'* Burger menu will close when clicking a link',
			'* Don\'t show the rank of players who are pending invitations',
			'* Put build queue controls into tables',
			'* Combined outgoing and returning ships into a single view',
			'* When inferring which mission type to use it won\'t selected from options that are not available',
			'* Validate attacking strength when sending an attack mission',
			'* Fixed the way solar system resources are rendered. This should mean that zero resources are visible on solar systems rather than just having nothing visible at all',
			'* Fixed an issue where the cost for an upgrade would not display if you can\'t afford it',
			'* Got rid of the breadcrumbs at top of the page. Wasn\'t adding anything',
			'* Refactored how the possible ships that can be an installation are calculated. This means the maximum quantity can be recommended on the UI. Added buttons for zero and max quantities',
			'##### Balancing',
			'* Made the stockpile also add to gas. The gas silo will be a more specialist building',
			'* Changed stockpile and gas silo so they are built on barren instead',
			'* Increased the density of solar systems in the world',
			'* Increased the number of celestials when spawning solar systems',
			'* Decreased the installation repair per tick from 10 to 1',
			'* Advanced ship assembly plants now cost crystal',
			'* Destroyers now cost crystal',
			'* Added some defence to barges, pods and haulers so they won\'t be completely wiped out by a single attack',
			'* NPCs will no longer spawn with random ships',
			'* Players will spawn with 10 fighters and 10 recon ships in addition to their civilization\'s starting ships',
			'* Available installation intel when attacking a system is reduced to 25% of its previous value. If you want to see more then use recons or attack with more ships',
			'##### Admin and dev',
			'* Refactored the generate world command so it can be used to regenerate an existing world. This command will ensure there are the proper amount of solar systems and will regenerate any unoccupied solar systems that do not have any ships going to or from them',
			'* Refactored command / query pattern to be based out of a single package',
			'* Added an admin command to reload game and generation settings for a world',
			'* Don\'t include Swagger unless debug',
			'* Refactored some game logic to be more reusable across different entities',
			'* Store the tick duration for a world and display it on the admin screen',
			'* Display the menu in a different colour to indicate it is a debug build',
			'* Display last error on admin world list',
			'* Split admin worlds from regular worlds so only admins can see the detail',
			'* Made the changelog tool support subheading',
			'* Refactored properties of several entities into interfaces so it is easier to perform game logic operations on multiple types',
			'* Sorted out a problem in the tpye data generator where Dictionary and generic parameters were not being added to the namespaces in the syntax output',
			'##### Bug fixes',
			'* Fixed a bug that occured when changing solar systems while the send ship form was open',
			'* Fixed a bug where you couldn\'t bring your reinforcements home',
			'* Fixed a bug with stealing loot from a solar system',
			'* Fixed a possible data loss issue to do with stale entities in the tick process. This might fix issues such as when users register to find the system has not been claimed in their name',
			'* Fixed a UI issue where reinforcements couldn\'t be sent home'
		],
	},
	{
		date: new Date('2019-08-16'),
		introduction: [
			'Notfications, messages and combat reports will trigger a client-side icon for the target player',
			'Implemented changes to celestial sizes so they are more varied and can support multiple installations per celestial.',
			'Initial implementation of radar on the map screen',
			'Added a bit more stability to the tick so that one error doesn\'t bring the whole thing crashing down.'
		],
		changes: [
			'##### Installation and Celestial Changes',
			'* Added perpetual installation upgrades for some installations.',
			'* Installations that produce ships can be built on barren or habitable planets',
			'* Other installations can be built anywhere',
			'* Celestials have sizes and multiple installations can be built at each one, depending on size',
			'* Starting to work on making habitable celestials more interesting. They will have populations that need catering for to offer benefits to the solar system.',
			'* Added a Sovereignty Office which is the equivalent of a Bureaucratic Hub in your colonies. If it is destroyed then the solar system is lost',
			'* Deleted dummy providers',
			'##### Radar and Map screen',
			'* Added radar distance as a stat on solar systems and installations',
			'* Radar ranges show on map screen',
			'* Ship movements shown on map screen',
			'* Added map preferences and UI to change them',
			'##### UI Changes',
			'* Made tick icons tick down in real time',
			'* SignalR to give realtime updates to notifications on connected clients',
			'* Highlight unread notifications etc with a colour in addition to an icon',
			'* Show celestial name with installations when combat reports contain intel',
			'* All buttons have icon',
			'* All butons have relevant colouring',
			'* Show all reinforcing players on combat reports',
			'* Added installation category to group installations on the UI',
			'* Redesigned the Own Federation screen to look slightly better',
			'##### Admin and Refactoring',
			'* Changed the world admin screen so that you can view more detail about each world',
			'* Record more information about the ticking of worlds to help with debugging',
			'* Secured mongo db with username and password through dockerfile',
			'* Refactored tracking of user preferences etc through cookies to a separate wrapper',
			'* Refactored how validation of strings works',
			'##### Balancing and tweaks',
			'* NPCs will wait at least 12 hours to recon each target',
			'* Changed ship movespeed stat to be solar systems per 100 ticks so it is clearer and easier to tune',
			'* Balanced ship movement speeds to be based around a fighter moving 1 solar system every 10 minutes',
			'##### Bug fixes',
			'* Fixed the problem where the URL appears in the solar system menu anchor tag',
			'* Installations won\'t show build ships form if they can\'t build any ships',
			'* Fixed an error when processing attacks',
			'* Added better error handling if the player\'s info fails to load'
		],
	},
	{
		date: new Date('2019-08-21'),
		changes: [
			'##### Solar system detail view',
			'* Rendering of celestials in a more pretty manner',
			'* Changed layout so there is a lot less shown on the page at once',
			'* Solar system detail view should reload more reliably after changes',
			'* Added some highlighting to solar system view so action results are easier to follow',
			'* Resources will tick up as appropriate',
			'* Move send ships form to separate page',
			'* Added "send another" option to send ships which will stay on the same page after submitting the form',
			'##### Help screens',
			'* Added help screens that render information about loaded data',
			'##### UI',
			'* Ship movements will move every tick on the map screen ',
			'##### Balancing',
			'* Added basic descriptions for all installations',
			'* Modified the costs of some installations',
			'##### Code changes and Admin',
			'* Large refactoring of how ticking works. Should be a lot more performant',
			'* Refactored model locations to live with the requests',
			'* Show more information on the admin world view',
			'* Broke tick traces into separate entity provider so they are not stored (and thus read) with the world'
		],
	},
	{
		date: new Date('2019-08-22'),
		changes: [
			'* MongoDB will use a singleton client which should help things on the live server',
			'* Fixes relating to the display and timings of installation build and upgrades',
			'* Moved build of new installations into a new tab',
			'* Show the number of installation slots available on a celestial',
			'* Show limits (celestial, solar system, player) on installation stats'
		],
	},
	{
		date: new Date('2019-08-27'),
		changes: [
			'* Hide reinforced by label on combat reports if the array is empty',
			'* Combat report attackers not wrapping down on mobile',
			'* Fixed gamespeed bug where times would be calculated incorrectly',
			'* Hid the build queue completed notification',
			'* Players will start with 3 of each resource producer',
			'* Rebalanced resource cost of installations'
		],
	},
	{
		date: new Date('2019-09-07'),
		changes: [
			'* Improved map coordinate handling on initial load',
			'* Added a "home" button to the map screen',
			'* Updated to properly update size of federations when installations change',
			'* Fiddled with Nginx config to allow subdomains',
			'* Hide tab headings on celestials that only have one option',
			'* Rebalanced resource production so it is non-linear and is instead exponential',
			'* Display stats for installations at next level',
			'* Playing around with some page transitions',
			'* Refactored main view pages to use a base component to allow consistent rendering and features',
			'* Show a new icon and highlight on Your Federation menu link if you have any pending invites',
			'* Created a rich text renderer that can render text with various othe content such as links and images'
		],
	},
	{
		date: new Date('2019-10-29'),
		changes: [
			'##### Chat',
			'* Initial implementation of instant messaging chat',
			'* Global chat per world ',
			'##### Commodities',
			'* Type data and loading of commodities from file',
			'* Help screens to display information about commodities',
			'* Commodities have a separate storage to resources',
			'* Ships can transport, steal and trade commodities',
			'* Commodities can be made at Manufactories',
			'* Commodities are demanded by megacities',
			'##### Sectors',
			'* Type data and loading of sector types from file',
			'* Help screens to display information about sector types',
			'##### Megacities',
			'* Added Megacities to habitable planets',
			'* Players can manage their megacity sectors and gain bonuses by satisfying them with demanded commodities',
			'* Megacities can be upgraded to allow more sector blocks',
			'* NPCs will designate megacity sectors',
			'##### Manufactories',
			'* Added a range of installations that can be built to create Manufactories',
			'* Manufactories are made up of input nodes and output nodes',
			'* Input nodes take in a single resource',
			'* Output nodes output a single commodity and consume the resources through input nodes',
			'##### Commodities',
			'* Type data and loading of commodities from file',
			'* Help screens to display information about commodities',
			'* Commodities have a separate storage to resources',
			'* Ships can transport, steal and trade commodities',
			'* Commodities can be made at Manufactories',
			'* Commodities are demanded by megacities',
			'##### Combat Reports',
			'* Implemented statuses for combat reports: Private (default), Archived and Public',
			'* Anyone with the link to a public combat report can see it',
			'* Archived and public reports are not deleted when running a "delete all" operation',
			'##### Upgraded to Netcore 3.0',
			'* Fixed all Resharper warnings',
			'* Upgraded all .net runtimes and packages',
			'* Made use of C Sharp 8 static nullable type checking',
			'##### Help screens',
			'* Wrote a content processor for help screens',
			'* Added a navigation builder for help',
			'* Wrote some initial help information',
			'* Added new player welcome message',
			'* Display cost of all levels on installation view',
			'##### Map Screen',
			'* Improved map coordinate handling on initial load',
			'* Added a "home" button to the map screen',
			'##### UI Changes',
			'* Show a new icon and highlight on Your Federation menu link if you have any pending invites',
			'* Created a rich text renderer that can render text with various othe content such as links and images',
			'* Display stats for installations at next level',
			'* Playing around with some page transitions',
			'* Hide tab headings on celestials that only have one option',
			'* Refactored main view pages to use a base component to allow consistent rendering and features',
			'* Clicking icon or FSO links in nav bar brand won\'t refresh the entire page',
			'* Several fixes of the solar system view to make it take up less space at the top of the screen',
			'* Added a screen that will display the breakdown of resource incomes. Click on the resources from a solar system to access it',
			'* Put lots of icons in various headers',
			'* Refactored the send ships form to be many smaller components so it is easier to work with',
			'* Tweaked styles on icons appearing next to each other so there is less gap',
			'* Disabled buttons when clicked and waiting for response',
			'* Created a re-usable date rendering component that displays exact date when hovered',
			'* Split home page in to two columns',
			'* Showing "hype" content on homescreen',
			'* Split register page in to two columns',
			'* Fixed an issue with the "Send another" function of the send ships form',
			'##### Balancing and Type Data',
			'* Rebalanced resource production so it is non-linear and is instead exponential',
			'* Added new stat to installation types that will limit their max level based on the size of the celestial they are constructed on',
			'* Added new stat of commodity storage',
			'* Added new installation (Warehouse) that is used to increase commodity storage',
			'* Changed Cost type to take a TimeSpan duration',
			'* Costs of InstallationTypes are generated using a non-linear curve',
			'##### Tech Debt',
			'* Updated to properly update size of federations when installations change',
			'* Refactored settings into smaller objects',
			'* Fiddled with Nginx config to allow subdomains',
			'* Refactored reload type data request to be used on world generation',
			'* Moved cheats to another controller',
			'* Made the value formatter static',
			'* Started unit testing',
			'* Started refactoring to make full testable'
		],
	},
	{
		date: new Date('2019-11-11'),
		changes: [
			'##### UI Tidying',
			'* New icons for chat',
			'* Display unread icon on chat',
			'* Wrapped UI in box divs to give a more consistent look',
			'* Added "send ships" button to ship movements index',
			'* Made a ViewButton for consisten links to detail views',
			'* Made lots of table rows clickable where appropriate',
			'* Changed the send ships form to be better (slightly)'
		],
	},
	{
		date: new Date('2019-11-15'),
		changes: [
			'##### Rebasing',
			'* Implemented rebase ship movement to move ships from 1 solar system to another',
			'* Removed the limits of haulers and mining barges so as many as desired can be built',
			'##### UI',
			'* Display habitable celestials on the map view if the solar system contains at least 1 habitable celestial',
			'* Display Megacity manufactory bonus on the manufactory screen',
			'* Added buttons to return to the index pages of combat reports, notifications and messages',
			'* Added buttons to delete individual combat reports, notifications and messages',
			'* Better validation of the server response when attempting to accept a federation invitation',
			'* Display the ship movement table on the map and allow highlighting of ship movements',
			'* Refactored the ship movement list to use tabs instead of a table',
			'* Display the time it takes to fill resource capacities on the resource detail table',
			'* More fiddling of the solar system UI to make it more compact and easier to use',
			'##### General',
			'* NPCs will withdraw Federation invidations when at capacity'
		],
	},
	{
		date: new Date('2019-12-04'),
		changes: [
			'##### Combat Rounds',
			'* Added support for rounds of combat to increase the variety of tactics available',
			'* Refactored the combat logic to be in more distinct chunks so that it is easier to test and modify',
			'* Tidied up combat report views to display the information more clearly',
			'##### Marketplace',
			'* Added new installations to support market places',
			'* Implemented market places and market orders',
			'* Added new ship movement type of Trade where ships can be sent to accept market orders',
			'* NPCs will raise market orders or send trade missions if they have an abundance of a particular resource',
			'##### UI',
			'* Headers now upper-case and central',
			'* Fixed icon spacing when many icons next to each other',
			'* Centered page content and fixed gutters',
			'* Included links to feedback surveys',
			'* Added new installation category for Resource production'
		],
	},
	{
		date: new Date('2020-01-07'),
		changes: [
			'##### UI Tweaks',
			'* Renamed Processing Node heading to Output Node',
			'* Changed Build queue things to show render of celestial rather than icon',
			'* Seen installations on combat reports will be ordered by celestial index',
			'* Can select sectors from the sector summary table on megacity screen',
			'* Market order validation simplified',
			'* Help button on Manufactory and Megacity page',
			'* Don\'t show send ships button on map view when a solar system is selected',
			'* Lots of work to make it play better on mobile'
		],
	},
	{
		date: new Date('2020-01-21'),
		changes: [
			'* Updated build pipeline to properly handle changes',
			'* Disabled map pan buttons when at edges of map',
			'* Work on automatically refreshing views when installations are complete or ship movements arrive',
			'* Added SSL support',
			'* Refactor of the display of the celestial table on the system page',
			'* Made the combat report stats table better on mobile',
			'* Added a mission system to teach players how to play',
			'* Simplified the view of combat reports so the important information is shown at the top',
			'* Refactored the application navigate so that the view resets scroll when changing pages',
			'* Split various application contexts into more clearly defined chunks',
			'* Show a message to players who have been defeated',
			'* Send a notification to a player and their federation when they are defeated',
			'* Display skulls and strikethroughs when players and federations are defeated',
			'* Tidied up various small issues on the nav menu',
			'* Moved resources view to its own url',
			'* Implemented beginner\'s protection and user interface to report as such'
		],
	},
	{
		date: new Date('2020-02-01'),
		changes: [
			'##### Accolades',
			'* Added a weekly accolades leaderboard system',
			'* Combat data is saved and used to calculate weekly medals to award players',
			'* Attacker - killed highest value of ships while attacking',
			'* Defender - killed highest value of ships while defending',
			'* Raider - stolen highest value of resources while attacking',
			'* Devastator - destroyed highes total number of installation level',
			'##### Megacities and Sectors',
			'* Simplified how megacities and sectors work.',
			'* Construct the sector\'s corresponding installation at your habitable celestial',
			'* Assign population to work in the sector and earn a bonus',
			'* Provide the sector with commodities to increase the bonus',
			'##### Radar and Intel',
			'* Increased the default system radar range',
			'* Radar ranges of allies are no longer used in calculating a player\'s radar range',
			'* Ship movements that are considered non-hostile will show source and target and appear on the map view when they target the player\'s systems',
			'* An overview of ship movements in radar range is shown on the map view',
			'* Clicking on a system from the map view will show related ship movements',
			'* Ship movement icons are shown on systems on the map view',
			'* Added a "hostile only" filter to the map overlay',
			'##### Combat Reports',
			'* Surviving recon missions will reveal resources at the defending system',
			'* The overall intel ratio is calculated by total combat strength over all rounds rather than just the last round',
			'* Added starting level of damaged installations',
			'##### App Changes',
			'* Added support for progressive web apps',
			'* Added an offline warning box that detects if the browser is offline',
			'* Added an API error message box if the app gets an error response from server',
			'* Added an "Update available" style message if an update is found',
			'##### Send Ships UI',
			'* Refactored send ships form to be less unwieldy and support more flexibility in UI design',
			'* Send ships form shows what resource will be mined',
			'* Added text highlighting to ship types when sending ships. Text colour will reflect the required fleet compositions',
			'* Show clearer send ship buttons on solar system and map views',
			'* Added buttons to choose what view to show after sending ships',
			'##### Celestial and Resources UI',
			'* Manufactory nodes are now linked which helps visualize resource usage',
			'* Resource costs that can\'t be met will be highlighted in red ',
			'* Show resources on celestial sub-views where needed',
			'* Show system\'s resources on market screens',
			'* Show links to other celestial pages from each celestial view',
			'##### Mobile UI',
			'* Various interface improvements to improve mobile support',
			'* Resource list will collapse on mobile to take less space',
			'##### Other UI',
			'* New style for buttons',
			'* Show a summary of each installation category on the build screen',
			'* Added sortable table',
			'* Used sortable table on players, federations and market orders browse',
			'* The drop down for selecting active system now navigates to the selected system',
			'* Nav drop down menus neatened',
			'* Show text on market order accept button',
			'* Show market orders on map view',
			'* Better use of space on solar system view',
			'##### Misc',
			'* Increased hauler cargo capacity to 2500',
			'* Tracking a founded and claimed date for solar systems'
		],
	},
	{
		date: new Date('2020-02-12'),
		changes: [
			'##### Drones',
			'* Added a new drone mechanic. Drones work like ships except they can\'t leave the solar system',
			'* Manage drones to provide bonuses to resource generation, combat and more',
			'##### Map',
			'* The map will now resize horizontally to fit the screen width',
			'* The ship movements list on the map is now all on a single line rather than having an expansion feature',
			'* Clicking a solar system on the map view will fade all ship movements that do not relate to that system',
			'##### Other changes',
			'* Players and Federations will have rankings which are recalculated every 12 hours',
			'* Added Federation chat',
			'* Clicking the "Show on map" buttons will also select the target solar system'
		],
	},
	{
		date: new Date('2020-02-15'),
		changes: [
			'##### Map',
			'* Added overlay filters for each movement type',
			'* Show owner of solar systems on map view',
			'##### Sending ships ',
			'* Some movement types can be set to repeat. Initially this is just for certain non-hostile movement types',
			'* Show total attack, defence and/or siege damage when sending ships, depending on selected movement type',
			'* Show a hint on the select celestial drop down to explain why (e.g mine, siege, colonize)',
			'##### Other',
			'* Added the concept of mass to ships and fleets. When viewing incoming ships the approximate mass is shown. The mass also includes any cargo the fleet may be carrying',
			'* Support for redirecting to original URL after login',
			'* Made accolade details exandable',
			'* Added locale formatting to many large quantities',
			'* Added more filters to combat reports'
		],
	},
	{
		date: new Date('2020-02-17'),
		changes: [
			'##### Map and Celestials',
			'* Shiny new map and colouring',
			'* Updated celestial renders with more distinction between types',
			'* Above changes courtesy of Charlie',
			'##### Other changes',
			'* Accepting a mission reward will deposit the reward in the selected system. UI updated to reflect this ',
			'* Destroyed installations are grouped on combat reports instead of being listed separately',
			'* Fixed a bug where missions are always set to repeat even when only sent once',
			'* Fixed table column miscounts on missions and create ships views',
			'* Links to help screens will scroll to the relevant item after navigation',
			'* The update prompt will show a loading bar',
			'* Fixed the issue where the send ships buttons don\'t show'
		],
	},
	{
		date: new Date('2020-03-02'),
		changes: [
			'##### UI',
			'* Fancy new resource bar',
			'* Always display the resources information',
			'* Ordered solar systems in top menu by date claimed. Note that this will only work on solar systems claimed or founded since I implemented the tracking of claim dates ',
			'* List of available solar systems will reload without needing to refresh (e.g after colonising)',
			'* Using the map controls to pan won\'t reset the scroll',
			'* Updated the celestial table to look sexier',
			'* Fixed issue where clicking links in table rows is overriden by the row click',
			'* New Chat styles',
			'* URLs in chat and messages will be rendered as links',
			'##### Ship Movements',
			'* Implemented collection movement type',
			'* Allowed reinforce and rebase ship movements to be set to repeat',
			'* Added a failure reason on repeat ship movements',
			'* Show the population killed as the result of siege attacks on combat reports',
			'* Added a warning and accolade for becoming a murderer',
			'* Show a confirmation message if sending only scouts to attack',
			'* Ship movements on map and solar system will be ordered by arrival date',
			'* Ship movements on map will show blobs at source and/or target if the source/target is not estimated',
			'* Fixed a radar display bug where ship movement lines would be drawn incorrectly',
			'##### Balance and data changes',
			'* Allowed deconstruction of Sovereignty Office to abandon colonies',
			'##### Other tweaks',
			'* Show target resource on mine missions in the system view',
			'* Show all missing resource errors on installations',
			'* Various changes to the communication between server and client which should result in earlier notifications of an available update and a smoother update process',
			'* Send a notification to a player when their beginner\'s protection expires',
			'* Siege combat now reports the quantity of population killed',
			'* New accolade: Murderer - for killing population. It is not good to get it',
			'* NPCs will upgrade their Beurocratic hubs and Sovereignty offices'
		],
	},
	{
		date: new Date('2020-03-16'),
		changes: [
			'##### Intercept Ship Movements',
			'* It is now possible to send ships to intercept other ship movements from the map view',
			'* A ship movement can be intercepted with either an attack or recon mission',
			'* Combat will take place as normal with the intercepting ships as the attacker and the target as the defender',
			'* If the target is destroyed then it will not reach its destination',
			'* Interception depends on the speed of the ships chosen to intercept - slower ships might not be able to intercept at all',
			'##### Ship movements',
			'* Refactored how ship movements work to now include a tracked home system id  ',
			'* Can recall a shipmovement if it is within radar range',
			'* When reinforcements arrive at a solar system they will automatically merge with any existing reinforcements from the same home system',
			'* Returning reinforcements to home allows you to choose which reinforcements come home',
			'##### Combat',
			'* The attacker is victorious in combat if any ships survive, otherwise the defender is the victor',
			'* Combat is given an adjective to describe the scale of the victory or defeat',
			'* The cargo of all defending fleets is included in the intel section of the combat report',
			'##### Major UI',
			'* New colour scheme and other widespread UI improvements',
			'* New menu styling',
			'* Added a red highlight to the system menu item when the system is under attack',
			'* Better styling on navbar',
			'* Sticky resource bar',
			'* Lots of new effects on map',
			'* Brought back send ships button and a drop-down of your own systems on the choose target of send ships form',
			'* Added a build ships button to the present ships control',
			'##### Minor UI',
			'* Format accolade scores',
			'* Tidied up some market order text and headings',
			'* Clicking the available space link will take you to the build screen',
			'* Show current ships on the build ships view',
			'* Improved solar system name validation',
			'* Better handling of ship movement icons',
			'* Improved the Drone control UI by compressing similar rows',
			'* Render building icon over the celestial in the build queue',
			'* Replicated radar logic on the solar system screen',
			'* Show more planets on map view',
			'##### Notifications and Reports',
			'* Disabled lots of notifications that were coming through too frequently without conveying any useful information',
			'* Delete combat reports and notifications that are older than 7 days. Public and archived combat reports won\'t be deleted',
			'* When a defender is reconned the combat report will be only be marked as unread if the recon succeeded against the player',
			'##### Balance',
			'* Any Installations with build times greater than 10 minutes are rounded up to have the seconds component removed',
			'* Battleships now have more defence than offence so they will win in defence in an equally matched battle',
			'* Increased storage capacities of solar systems. A level 20 stockpile will hold enough resources to be able to afford a level 15 Bureaucratic hub',
			'##### Other bits',
			'* Drones have an additional status of Returning that doesn\'t count as Active which gives the player more flexibility when recalling drones',
			'* Added a text prompt when colonization can\'t be sent',
			'* Update missions properly when completed without requiring browser refresh '
		],
	},
	{
		date: new Date('2020-03-23'),
		changes: [
			'##### Upkeep',
			'* Ships now cost upkeep per hour in gas. This is deducted from the solar system\'s income',
			'* Some installations have "Hangar space" which negates the upkeep of ships',
			'* Details of upkeep costs are shown on the resource detail view (click the resource bar to view)',
			'* In this release there is no penalty for consuming more gas than is available but in future releases ships will be destroyed if you are unable to pay their upkeep',
			'* Added an Orbital Hangar installation which provides a lot of hangar space to offset upkeep costs of large fleets',
			'##### UI',
			'* Fixed a problem where resouces would tick up in excess of capacity',
			'* Added a fill cargo button when loading ships with resouces and commodities'
		],
	},
	{
		date: new Date('2021-02-02'),
		changes: [
			'##### Politics',
			'* Show an unread icon if there is a pending policy vote',
			'* Link to the policy type description in notifications relating to policies',
			'* Politics notifications will link to the politics homepage',
			'* Policies were enacted through Executive Order will be marked as such',
			'##### System View',
			'* Installation and ship build queues will be show side by side',
			'* Ship build queue shows a build ships button if empty',
			'* Show an "Upgrades" tab in the advisor view to easily allow installations to be upgraded',
			'##### Fleets',
			'* Repeat Fleet: hovering over the error icon will display the error message in a tooltip',
			'* Repeat Fleet: "Send Now" button',
			'* Next button disabled when sending a mining fleet if no celestial is selected',
			'* Added a "fill" button when sending a trade fleet',
			'##### Authentication',
			'* Fixed an issue which caused the login screen to flicker when pressing Login',
			'* Show logo on Forgot password and Reset password views so they match Login / Register',
			'* Login and Register pages will redirect to home if user is already logged in',
			'##### Other',
			'* Fixed a link from the Megacity view to the Megacity help page ',
			'* Added change list to homepage'
		],
	},
	{
		date: new Date('2021-02-03'),
		changes: [
			'##### Politics',
			'* Moved the manifesto vote button to the row rather than the expanded detail',
			'##### System View',
			'* Added a ships section to the summary page',
			'* Moved build ships button to header of ships build queue',
			'* Removed "Summary" heading from celestial selection',
			'* Show installation upgrade duration on upgrade advisor and celestial summary',
			'##### Other',
			'* Reduced spacing around panels which should reduce scrolling',
			'* Tidied target selection of send ships slightly',
			'* Can only edit bio of a Federation if you have permission',
			'* Hide text on mobile on audio buttons'
		],
	},
	{
		date: new Date('2021-02-04'),
		changes: [
			'##### Bugs',
			'* Fixed an issue where collection missions would not work if the fleet attempts to collect a full cargo load of items',
			'* Fixed Firefox login form. Means that for now enter won\'t work to submit login form',
			'* Fixed a bug with calling ships home when they are in transit',
			'* Fixed an issue where the de-activate drone form was closing every few seconds',
			'##### Resources bar',
			'* Hovering over each cell gives a full description',
			'* Added a line to indicate capacity',
			'##### System',
			'* Departures / Arrivals headings will link to the corresponding ships detail tab ',
			'* Installation image on upgrade advisor will link to the installation',
			'* Show resource icons in fleet rows (on summary and ships tabs)',
			'* Added icons to ship upkeep detail buttons',
			'* Renamed "Available" to "Stationed" in ship upkeep view of items tab',
			'##### Send Ships',
			'* Fixed grammar on send ships confirmation message and added arrival time',
			'* Updated the warning on repeat send ships to reflect more accurate timings e.g mining',
			'* Allow entry of decimal values into repeat duration hours',
			'##### Other',
			'* Simplified the order description on market tables',
			'* Show mission rewards before complete'
		],
	},
	{
		date: new Date('2021-02-05'),
		changes: [
			'##### Bugs',
			'* Fixed issue so that firefox users can submit login form via button or pressing enter button',
			'* Fixed megacity population bug',
			'##### Chat',
			'* Default chat to federation if available',
			'* Links will open in new window',
			'##### Mobile',
			'* Hide celestial name on upgrade advisor',
			'* Hide text on mobile for upgrade buttons',
			'* Hide text on mobile for ship buttons on system ship view',
			'* Tactics configuration show compressed',
			'##### Other',
			'* Improved styling and layout of commodity selection form of send ships',
			'* Fixed some missions that were incorrectly linked. This might mean that some missions can be replayed',
			'* The "Send Now" button on repeat missions will change based on the availability of ships',
			'* Fleet movement icons will be shown as green if the owner is in your Federation',
			'* Can only partially return a reinforcing fleet if it has already arrived',
			'* Added a total row to military page of empire view',
			'* Show what ships will eventually be available on later upgrades at installations',
			'* Made a map link more prominent on the system view',
			'* Changed styling of resource bar so full is less prominent'
		],
	},
	{
		date: new Date('2021-02-06'),
		changes: [
			'##### Email Verification',
			'* Implemented an email verification system. Players will be prompted to verify their email address by clicking on a link in an email - a fairly standard practice. Eventually game features will be locked for players who have not verified their emails as part of an effort to protect against spam and fake accounts.',
			'##### Mobile',
			'* Hide text on ship build button on solar system summary',
			'* Fixed updated resource bar height',
			'##### Other',
			'* Show policy description on the open vote panel',
			'##### Bugs',
			'* Fixed rebase missions so they will actually take configured items  with them to the target',
			'* Fixed raw resource icon',
			'* Fixed pending mission reward icon so it is coloured correctly based on state',
			'* Fixed spacing of incoming attack icons on system menu',
			'* Fixed installation construction time reduction from active policies'
		],
	},
	{
		date: new Date('2021-02-09'),
		changes: [
			'##### Installations',
			'* Allow queueing of multiple installations per celestial',
			'* Tidied up the schematic configuration view',
			'* Show sector type bonuses on installation screens',
			'* Display the cost to change capital',
			'##### Ships',
			'* Numeric formatting on ship stats summary',
			'* Arrived reinforcements shown as low priority on the departures summary on system view',
			'* Can specify quantity of ships when cancelling ships from build queue',
			'* Show upkeep summary on ship build screens',
			'##### Manufactory and Megacity',
			'* Megacity advisor will format population to correct decimal places',
			'* Sorted out megacity assignment slider',
			'* Added warnings to manufactory screen so it is easier to tell what is going on',
			'##### Bugs',
			'* Fixed an issue where the solar system menu wasn\'t reloading on colonization',
			'##### Other',
			'* Moved the empire view to page tabs',
			'* Tweaked layout of mission objective ticks and crosses',
			'* Updated numeric inputs so they should properly accept negative numbers and decimals',
			'* Reduced solar system name length to 30 characters',
			'* Added a "Submit Manifesto" button to the politics home screen',
			'* Combat reports in neutral systems won\'t include the designated defender in the reinforcements',
			'* Down arrow in resource bar on resources that are depleting',
			'* Removed Build Queues Wrapper from queues on summary',
			'* Corrected trade complete notification',
			'* Using the solar system dropdown menu won\'t move to solar system view'
		],
	},
	{
		date: new Date('2021-02-10'),
		changes: [
			'##### Bugs',
			'* Fixed resource bar capacity display',
			'##### Other',
			'* Show federation ticket on bottom nav bar',
			'* Show player security status on bottom nav bar',
			'* Don\'t show multiple reinforce icons on ships tab',
			'* Show a star next to capital instead of home on new solar system drop down',
			'* Show breakdown of raw resources on resource expand'
		],
	},
	{
		date: new Date('2021-02-11'),
		changes: [
			'##### Other',
			'* Show totals and costs on combat reports',
			'* Show ship images on upkeep table',
			'* Show commodities summary in items expand',
			'* More prominent warning about sending fleets that will cause a security change',
			'* Won\'t repeat exile notifications for causing civilian casualties',
			'* Fixed an issue where grounded ships were not appearing as survivors in combat report',
			'* Added show on world map on map right click and selected system',
			'* Increased emblem maximum layers to 15',
			'* Show better breakdown of times on send ships summary',
			'* Made tool tips on all button',
			'* Adjusted buttons on commodity selection of send ships',
			'* Tidied up some more on manufactory screen'
		],
	},
	{
		date: new Date('2021-02-15'),
		changes: [
			'##### Colony Penalty',
			'* Resource production in your colonies will receive a 10% penalty per level below the number of systems you control. For example, if you have 5 systems then a colony with a level 3 Sovereignty Office will receive a 20% penalty to resource production.',
			'* This penalty is visible from the Items view',
			'* An advisor warning has been added as well',
			'##### Empire views',
			'* Added a summary page which will eventually be like the summary view of the solar system',
			'* Show resources & capacities per system',
			'* Show total ships on empire view',
			'* Renamed Problems to Advisor',
			'##### Other',
			'* Cancelling departures will stay on the departures screen',
			'* Fixed an issue where mining time wasn\'t taken in to account for return journeys',
			'* Fixed ship counts when fleets are returning to the system',
			'* Show total bonuses (or penalties) on items detail view'
		],
	},
	{
		date: new Date('2021-02-16'),
		changes: [
			'##### Empire views',
			'* Show build queues on the summary view',
			'* Show total bonuses (or penalties) per system on the resource view',
			'##### Other',
			'* Show mining completed date on fleets'
		],
	},
	{
		date: new Date('2021-02-17'),
		changes: [
			'##### Empire views',
			'* Buttons to easily send delivery or collection missions between systems from the resource table',
			'* Buttons to easily send reinforce or rebase missions between systems from the military views',
			'* Added Empire link at end of send ships',
			'##### Mining',
			'* Mining barges now have half cargo space and half mining strength. This means they will yield half as many resources in the same time per trip',
			'##### Other',
			'* Added "Build at" buttons on installation help screen',
			'* Show total embassy levels on federation screen if leader or officer',
			'* More icons in help sections',
			'* Changed progress bars to better match the rest of the game',
			'* Added an animation for damaged installations',
			'* Tidied up game logic relating to losing a system which is a player\'s capital',
			'* Abstain on voting is not selected by default',
			'* Show a drones summary on the solar system ships summary view',
			'* Stopped market orders from being raised over capacity'
		],
	},
	{
		date: new Date('2021-02-24'),
		changes: [
			'##### Empire views',
			'* Added a ships build queue table',
			'* Added a travel time matrix',
			'##### Other',
			'* Adjusted main menu underline skew',
			'* Fixed new progress bar rendering'
		],
	},
	{
		date: new Date('2021-03-09'),
		changes: [
			'##### Credits and Contracts',
			'* Credits are now stored at the player level',
			'* Contracts can be raised to get other players to do stuff for you',
			'##### Map',
			'* Map views will render shiny stars instead of planets',
			'* Combined map and world map into a single tabbed page',
			'* Renamed map to tactical map',
			'* Renamed world map to galaxy map',
			'##### Other',
			'* Added Control tab of Empire Military view which shows sovereignty hub levels',
			'* Show commodity and raw resoure capacities in the resource bar peek view even when no commodities or raw resources are present',
			'* Installation help pages now have a dropdown to choose which level to display',
			'* Show upkeep savings on grounding ships form',
			'* Show upkeep costs on activating ships form',
			'* Improved total defence stats so they are counted separately from available defences',
			'* Fixed the issue where the build ships dropdown doesn\'t always display the expected ship building installations',
			'* Show fleet attack / defence when expanding',
			'* Format mass numbers better',
			'* Removed the rebase success notification',
			'* Display player federations in the politics views',
			'* Split politics view so that past terms are on a different screen',
			'* Added date and time to policy time ending',
			'* Fixed the display of the total inactive drones',
			'* Show combat icons on solar system dropdown',
			'* Quicker switching of solar system',
			'* Show fleet travel times on submit of send ships',
			'* Chat will remember last chat channel rather than always switching to federation',
			'* Sorted how help pages are managed so the lists are more consistent',
			'* Added an incoming attack table to empire view summary',
			'* Fixed celestial ordering in views, lists, dropdowns expected',
			'* Gracefully catch authentication error when attempting to view a combat report that you don\'t have permission to view'
		],
	},
	{
		date: new Date('2021-03-12'),
		changes: [
			'##### Combat',
			'* Refactored the code behind combat so that it is a lot easier to extend in the future',
			'* Added a combat simulator to allow experimentation with tactics'
		],
	},
	{
		date: new Date('2021-03-13'),
		changes: [
			'##### Empire View',
			'* Made the solar system links change the selected solar system instead of just viewing the solar system',
			'* Replaced many of the "select and go to" buttons on the empire view with the send ships between systems buttons',
			'##### Map',
			'* Pan 3 squares per click',
			'* Added a tool to help find celestials at systems',
			'##### Other',
			'* Changed the back button on installations etc to go back to the celestial table',
			'* Resources show as orange if depleting or red if depleted within an hour',
			'* Default repeat duration to the journey time',
			'* Default combat simulator view to results'
		],
	},
	{
		date: new Date('2021-03-14'),
		changes: [
			'##### Repeat fleets',
			'* Option to only send a repeat if all ships are available',
			'* Can edit repeats',
			'* Can pause / unpause repeats',
			'* Can delay repeats',
			'* Repeat fleets are linked to actual fleets so progress can be seen'
		],
	},
	{
		date: new Date('2021-03-15'),
		changes: [
			'##### Installations',
			'* Support installations such as warehouses and SSM batteries can now be built anywhere except habitable planets',
			'##### Exploration',
			'* Solar systems now need to be visited by a fleet before you can see what celestials are present'
		],
	},
	{
		date: new Date('2021-03-23'),
		changes: [
			'##### Components',
			'* Advanced ships now require components',
			'* Components are built in manufactories alongside commodities',
			'##### Other',
			'* Celestial table restyled for ease of use and visual appeal',
			'* Added a manufactories empire view',
			'* Show raw resource detail on solar system items table',
			'* Repeats can now be edited in systems with no ships',
			'* Disable accept contract button on hostile contracts where the target is allied',
			'* Show more detail on repeat fleet expand',
			'* Show "select and go to" icon in solar system links that you own'
		],
	},
	{
		date: new Date('2021-03-25'),
		changes: [
			'##### Ships',
			'* Interceptors will use their defence stat when intercepting other fleets',
			'* Battleships, haulers, colony pods and miners can no longer defend against recon missions',
			'* Deconstructing an installation will remove and refund all ship build queues relating to that installation',
			'* Ships can\'t be built at installations which are under deconstruction',
			'* Destryoing an installation (through siege) will delete all related queued ship builds',
			'##### Other',
			'* Empire Megacities: added buttons to assign population',
			'* Small tweaks to drone controls',
			'* Added an upkeep view to empire military',
			'* Fiddled resource bar to show the correct things'
		],
	},
	{
		date: new Date('2021-03-29'),
		changes: [
			'##### Other',
			'* Show operation level controls for manufactory installations',
			'* Fixed an issue where components and raw resources can\'t be collected',
			'* Fixed issue where repeat delay was not being initially set to the optimal time'
		],
	},
	{
		date: new Date('2021-06-07'),
		changes: [
			'* Welcome to the 3rd Fusion Shift test! While many base game concepts have remained the same, there are lots of new features, tweaks and balances which will lead to a more involved and strategic game experience.',
			'##### Credits',
			'* Credits are no longer an item type and are instead a player level resource. You can see your current balance in the top right',
			'* You can earn credits through trading with other players but the main source of income is to tax populations at your megacities',
			'* Tax is automatically set and earned as the megacities grow but you may find that some sectors offer higher tax yields',
			'##### Contracts',
			'* You can create contracts which are used to pay other players to do jobs on your behalf',
			'* Contracts can be for a range of things including attack, recon, defend and courier missions',
			'* If you\'re looking for work you can find it in the Contracts page under Market',
			'##### Agents',
			'* Agents are characters which you can hire and move around the galaxy to do various actions',
			'* Actions usually provide a bonus or penalty to a system',
			'* Agents can operate overtly where they are working as diplomats to boost a system, or covertly where they are operating as spies, undermining and saboutaging the system',
			'##### Exploration',
			'* By default you won\'t be able to see which celestials are at a given system. You\'ll need to explore it first by sending a fleet there',
			'* Any fleet which arrives at a system, and survives, will explore for you',
			'* Once you have explored a system you will always be able to see which celestials are present',
			'##### Mining Changes',
			'* Celestials now have a limited amount of resources available for mining which slowly regenerates over time ',
			'* If you have explored a system you will be able to see how much is available',
			'* Mining a celestial will deplete the resources. If the system is being over-mined then the resources may run out, making subsequent mining missions less efficient',
			'##### Relics',
			'* Rumour has it that the expanse region of the galaxy is home to some mysterious artifacts',
			'* No one is sure what they are for - capture them and return them to your system to study and earn research points for your federation',
			'##### Politics',
			'* Several new policies have been added, as well as a few new policy categories',
			'* Once per term the president can wield the power of democratic restoration (in addition to their executive order)',
			'* Democratic restoration allows the president to instantly enact a policy in a policy category which does not have an active policy',
			'* For example, if there is no active economic policy then the president may instantly enact any economic policy of their choosing',
			'##### Combat Simulator',
			'* Under the info menu you can find a combat simulator',
			'* There are many configuration options so you can carefully plan your attacks',
			'##### Components',
			'* Advanced ships now require components which are advanced item constructed in manufactories',
			'##### Balancing Changes',
			'* A larger world is generated, with some tweaks to the generation algorithm to increase the frequency of useful and interesting systems',
			'* Players now only start off with a tiny celestial of each resource type. The resource generating installations generate more to make up for this. Hopefully this will mean less repetetive clicking and make larger sized resource planets more valuable',
			'* Support installations (such as stockpiles, warehouses, SSMs and hangars) can now be built on any planet (except habitable)',
			'* Ship costs, upkeep and combat stats have been rebalanced. Generally speaking, the advanced ships are very expensive but the most efficient in terms of upkeep',
			'* Interceptors will use their defence stat when intercepting other fleets',
			'* Battleships, haulers, colony pods and miners can no longer defend against recon missions',
			'* Tutorial missions give more resources',
			'##### Empire View',
			'* Added buttons to assign population on the megacities view',
			'* Added an upkeep view to empire military',
			'* Added a manufactories view',
			'##### General UI Fixes',
			'* Improved celestial table for enhanced visual appeal and usability',
			'* "Select and go to" arrow icon link in solar system links',
			'* A dropdown on the map to highlight systems with a selected planet type',
			'* Edit repeat fleets',
			'* Can pause / unpause repeats',
			'* Can delay repeats',
			'* Set repeat fleets to only send if all the configured ships are available'
		],
	},
	{
		date: new Date('2021-06-09'),
		changes: [
			'##### Agents',
			'* Reduced credits cost of many agent missions',
			'* Fixed bug where cost of missions was credited to player rather than deducted',
			'* Inform player which agent missions reward exprerience',
			'* Added a new action type to train the agent',
			'##### Map',
			'* Unexplored systems appear faded'
		],
	},
	{
		date: new Date('2021-06-10'),
		changes: [
			'##### Balance',
			'* Increased hauler cargo capacity to 25,000',
			'* Changed biomass refining to yield 500 gas (1 to 1 ratio)',
			'##### UI',
			'* Adjusted padding on federation detail screen',
			'* Adjusted padding on build queues',
			'* Fixed an issue where market orders weren\'t displaying properly after a refresh',
			'##### Mobile',
			'* Changed placement of credits to preserve width',
			'* Moved the labels of planet type / size on celestial table',
			'* Fixed width when rendering 5 installations on celestial table',
			'* Fixed icon display in navbar',
			'* Fiddled with numeric inputs on send ship screens to show fewer buttons',
			'##### Bugs',
			'* Fixed a bug where agents sent with colonize missions get lost'
		],
	},
	{
		date: new Date('2021-06-14'),
		changes: [
			'##### Balance',
			'* Increased hangar space of ship assembly plants (basic and advanced)',
			'* Decreased reward for drone activation missions',
			'* Added a series of missions for crystal excavators',
			'* Added a range of installations for harvesting raw resources',
			'* Added a corresponding range of missions for the raw resource installations',
			'* Each system can have 2 market orders (up to 10,000 item quantity) without any market installations',
			'* Market installations offer a lot more per level',
			'##### UI',
			'* Adjusted padding on agents overview',
			'* Adjusted padding on combat reports',
			'* Added minimum row height to combat report rows',
			'* Adjusted padding on empire view',
			'* Adjusted padding on build queues',
			'* Fixed incorrect text on agent failure popup',
			'* Show agent response message next to the action button',
			'* Don\'t show decimal places on credits in status bar',
			'* Explore button is disabled if no recon available (rather than hidden)',
			'* Button tooltips pass to the icon in the button which should make them display more frequently',
			'* Added tooltips to repeat fleet buttons',
			'##### Bugs',
			'* Fixed market order browse item selection',
			'* Fixed market order create'
		],
	},
	{
		date: new Date('2021-06-15'),
		changes: [
			'##### Search',
			'* Added a global search feature',
			'* You can search help, type data such as installations and items, as well as game data (players, federations, systems)',
			'##### Balance',
			'* Self-contained factory now has 2 input nodes so it can be used to make most components',
			'* Added better descriptions to manufactory buildings',
			'##### UI',
			'* Added return to repeats after editing a repeat',
			'* Added Expand To drop down to military summary of empire view',
			'* Moved missions to info menu',
			'* Changed market order summary section',
			'* Added return to repeats after editing a repeat',
			'* Did some tweaks to the manufactory screen which should make information clearer',
			'* Wrapped up send ships success to better match the rest of the application',
			'* Scroll to top after sending a fleet',
			'* Adjusted padding on megacity sector description',
			'* Adjusted padding on megacity population assignment',
			'* Adjusted padding on contract tables',
			'* Adjusted padding on agents list',
			'* Fixed icon positioning on dropdowns',
			'* Changed target selection control to fit better in smaller spaces',
			'##### Mobile',
			'* Render post send ships actions as vertical list',
			'* Fixed an issue with combat report ship tables on mobile',
			'* Fixed a padding issue which should yield more space in lots of places'
		],
	},
	{
		date: new Date('2021-06-16'),
		changes: [
			'##### UI',
			'* Show ship icons with quantity in repeat row',
			'* Disabled the upgrade button on the empire military control view if upgrade is not possible',
			'* Added some ship information to the empire military travel view',
			'* Added a send ships link to the notification bar',
			'##### Mobile',
			'* Fixed agent table',
			'* Tidied up map controls',
			'* Sorted row spacing and sizing on list of activating ships',
			'##### Bugs',
			'* Own fleets that go a long distance will now render properly on the map',
			'* Fixed an issue where repeat fleets weren\'t properly linking to actual fleets',
			'* Fixed input node item selection'
		],
	},
	{
		date: new Date('2021-06-17'),
		changes: [
			'##### Manufactory',
			'* Can set nodes to no item',
			'* Can set a throughput limit per node',
			'##### UI ',
			'* Added ship count icons to fleet tables',
			'* Updated installations for raw resource harvesters and processors',
			'##### Balance',
			'* Increased storage of warehouse and manufacturing depot to 10,000 per level',
			'##### Bugs',
			'* Fixed an issue where a newly constructed droneport causes an error on the ships screen'
		],
	},
	{
		date: new Date('2021-06-21'),
		changes: [
			'##### Bots',
			'* Will promote the largest player to the leader of the federation',
			'* Will now try to mine crystalline planets',
			'* Will use credits for some market order situations',
			'##### Politics ',
			'* Send a president changed notification to all players',
			'##### Empire',
			'* Added turn on/off manufactory button',
			'* Allow expanding even when there is only 1 installation',
			'##### Balance',
			'* Changed research point generation to 12 hours',
			'##### UI',
			'* Show commodity capacity on megacities commodity page',
			'* Fixed padding on solar system agents list',
			'* Fixed padding on politics tables',
			'* Fixed padding on megacities commodities table',
			'* Fixed width of celestial type name on celestial table',
			'* Show resource icons on repeat list for delivery and collections',
			'* Adjusted send ships success messages to be more generic'
		],
	},
	{
		date: new Date('2021-06-22'),
		changes: [
			'##### Credits',
			'* Added a "new balance" field to transactions to help with accounting',
			'* Moved income consolidation to a field of the transaction rather than storing a separate row',
			'##### UI',
			'* Fixed "fill" button on send ships trade screen to fill to available trade amount, cargo capacity or available in system',
			'* Show repeat button after send ships if the fleet sent was configured to repeat',
			'* Show ship cargo when selecting cargo for a trade fleet',
			'* Show repeat button after send ships if the fleet sent was configured to repeat',
			'* Fixed an issue where the repeat edit didn\'t show repeat times if all the repeats in the system were used',
			'* Fleets show component detail when expanded'
		],
	},
	{
		date: new Date('2021-06-23'),
		changes: [
			'##### UI',
			'* Order ship summary icons consistently'
		],
	},
	{
		date: new Date('2021-06-25'),
		changes: [
			'##### Victory Conditions',
			'* The galactic president can now propose a policy vote to declare their federation as the rulers of the galaxy and winners of the game',
			'* The president\'s federation must have 100 research points',
			'* Votes are counted by player population size as opposed to 1 vote per player',
			'* The motion must be passed with a 2/3rds majority',
			'##### Politics',
			'* Defeated players can cast a vote but their votes are not counted',
			'##### Empire',
			'* Added a tab to military to show exploration stats',
			'##### UI',
			'* Show correct build ships icon on installation screens',
			'* Don\'t say "edited repeat fleet" when it\'s not actually an edit',
			'* Added up arrows to resource bars '
		],
	},
	{
		date: new Date('2021-06-28'),
		changes: [
			'##### Balance',
			'* Increased resource cost of basic ships',
			'* Increased battleship attack',
			'* Raiders sent to intercept will only be able to use 25% of their attacking strength',
			'##### UI',
			'* Show icon in bar if any agent is ready to act',
			'##### Bugs',
			'* Fleets outside radar range will not show mass',
			'* Restored fleet markers on map',
			'* Fixed search so that systems will now appear correctly'
		],
	},
	{
		date: new Date('2021-06-29'),
		changes: [
			'##### UI',
			'* Show up arrows on commodity and components in resource bars',
			'* Show foreign reinforcing fleets which have arrived (system / ships)',
			'* Change node throughput limit granularity to 1%',
			'* Added page title to combat simulator',
			'* Added stats summary to combat simulator',
			'* Fixed text when capital is being upgraded',
			'* Fixed display of emblem icon when viewing a contract',
			'* Fixed icon padding in nav on tablet',
			'* Fixed padding on federation invitations',
			'* Split empire view installation build queue times over 2 columns',
			'* Changed incoming attacks screen on empire summary to be more compact',
			'* Added some colour to the bonuses of combat reports',
			'* Can set a generic bonus of -100% to 100% in the combat simulator',
			'* Updated sliders to be more useful and look better',
			'##### Bugs',
			'* Fixed an issue where the market orders view doesn\'t change if the system is changed'
		],
	},
	{
		date: new Date('2021-07-05'),
		changes: [
			'##### Balance',
			'* Only interceptors and destroyers can intercept',
			'* Recon missions can\'t be intercepted',
			'* Buffed destroyer stats',
			'* Increased cruiser defence from 750 to 800',
			'##### UI',
			'* Show required input materials when changing manufactory node output',
			'* Group commodity selection by sector type',
			'* Empire view fixed columns on installation expansion',
			'* Empire view expand ships by clicking row instead of button',
			'* Empire view don\'t allow expand if no ships are available for construction',
			'* Expanded resource view no longer sticky so that you can scroll to the close button',
			'* Hide population of an unexplored system but give a generic warning if you might become a murderer',
			'* Message box clip top right',
			'* Show mass on incoming fleets',
			'* Show market orders on solar system by id view',
			'* Fixed footer',
			'* Can edit repeat number of times to indefinite',
			'##### Mobile',
			'* Collapse credits when over certain values',
			'##### Bugs',
			'* When a fleet is called home any intercepting fleets will redirect to intercept or return home if unable to intercept the target on its changed course'
		],
	},
	{
		date: new Date('2021-07-06'),
		changes: [
			'##### Ships',
			'* Reinforcements will be called home if a system runs out of gas',
			'* Reinforcing fleets can be configured to join the target system if owned by the player',
			'* Most fleets can be configured to ground upon arrival',
			'* Destroyed grounded ships now appear near the totals at the top of combat reports',
			'##### UI',
			'* Resource bar expand shows empire resource summary',
			'* Changed how manufactory lines are rendered',
			'* Resource bar expand is stick again',
			'* Fixed an issue where ships won\'t show if the system only has grounded ships',
			'* Show incoming attack summary on solar system overview page',
			'* Render ships steps better on post-send ships',
			'* Hide ship type name on empire view ships when on smaller screens',
			'* Don\'t show "any" value when configuring manufactory nodes',
			'* Fixed an issue where combat report loot capacity was displayed incorrectly',
			'##### Other',
			'* Only show target celestial (e.g for mining) if the fleet is owned by the player',
			'* Hide agent status and level if location is unknown'
		],
	},
	{
		date: new Date('2021-07-07'),
		changes: [
			'##### Empire Network View',
			'* Added a new tab to the empire view which allows for a visual relation of the fleet connections between systems in your empire ',
			'* It\'s a simple work in progress but take a look and see what you think. Any feedback is greatly appreciated',
			'##### Fleets',
			'* Added notify on arrival where you can configure a fleet to trigger a notification when it arrives',
			'##### Balance',
			'* Increased hangar space of Sovreignty Office and Bureaucratic Hub',
			'* Buffed combat drone defence',
			'* Fixed crystal cost of radar array',
			'##### UI',
			'* Hide agent level if not known',
			'* Fixed an issue with combat reports showing negative numbers',
			'* Fixed an issue where agents weren\'t being sent with attacks',
			'##### Mobile ',
			'* Small tweak to the display of ship lists and quantity'
		],
	},
	{
		date: new Date('2021-07-14'),
		changes: [
			'##### Federation',
			'* Show a button to promote officers to leaders',
			'##### Notifications',
			'* Agent captured notification should show location',
			'* Fixed capitalisation of federation notifications',
			'##### UI',
			'* New upkeep control which makes it clearer how changes effect ship upkeep in a system',
			'* Fixed offline message to be more of a warning than a lock',
			'* Better colouring of manufactory nodes',
			'* Combat report list default to all',
			'* Can clear input node of manufactory',
			'* Fixed issue where unknown celestials would display "null" instead of "unknown"',
			'* Adjusted padding on contract tables',
			'* Added summary paragraph to military control view',
			'* Hide notify / ground on return for colonize',
			'* Fixed capitalisation of "Collect" in send ship configuration',
			'* Fixed count of drone tables in various states',
			'* Fixed spacing between credits icon and quantity on nav',
			'* Show mass when intercepting a fleet on send ships',
			'* Can configure tactics when intercepting',
			'* Can send recon to intercept',
			'* Fixed an issue where intercepting fleets can\'t be called home',
			'* Show all captured agents on the agents screen',
			'* Fixed text on notify on return of rebase fleets',
			'* More details on policy description',
			'* Updated combat simulator to match interception changes',
			'* Improved how app initial load is handled'
		],
	},
	{
		date: new Date('2021-07-15'),
		changes: [
			'##### Combat Reports',
			'* Combat reports are now visible to federation when in private. You don\'t need to make reports public to share with your federation',
			'* Added message on UI to show this',
			'##### Repeat Fleets',
			'* Can configure to notify on failure',
			'* Added a tool to more easily manage mining ships in repeats',
			'##### UI',
			'* Show fleet markers for own fleet even when not in radar range',
			'* Fixed the display of X axis on voting bar charts',
			'* Fixed colour on message boxes which appeared too faded',
			'* Fixed an issue where manufactory node connections wouldn\'t show if the throughput was very low',
			'* Added clipped corner to build ships button',
			'##### Mobile',
			'* Hide the steps control on send ships to take up less vertical space',
			'* Made the login box slightly prettier'
		],
	},
	{
		date: new Date('2021-07-18'),
		changes: [
			'##### Bugs',
			'* Fixed an issue where it wasn\'t possible to chase returning attacking fleets with an interception',
			'##### UI',
			'* Fix padding on agents selection form of send ships',
			'* Default ships help page to the player\'s faction',
			'* Show the ship upkeep information on the main solar system summary',
			'* Added tooltip select and go to icon',
			'* Emblem chooser cursor shows as clickable',
			'* Added a linebreak between weeks on accolade views',
			'* Show total size, population and systems on player index',
			'* Clicking installation notification goes to the system instead of the installation'
		],
	},
	{
		date: new Date('2021-07-22'),
		changes: [
			'##### UI',
			'* Combined build queues of empire summary into tabbed control',
			'* Futher improvements to what information can be seen about foreign agents',
			'* Added further clarification on reasons why some agents can\'t be moved',
			'* Warning on politics page for security statuses being limited from voting',
			'* Fixed an issue where the map returns to focus even if navigated away from ',
			'##### Other',
			'* Corrected the descriptive text of agent actions pertaining to AdmiNet',
			'* Fixed some text on an apocalypse notification',
			'* Corrected notification text when attacking civilian in core'
		],
	},
	{
		date: new Date('2021-08-03'),
		changes: [
			'##### UI',
			'* Use a cookie to hide the victory message',
			'* Increase icon size on victory message',
			'* Show player emblem on agent detail',
			'* Agents effects table unpadded'
		],
	},
	{
		date: new Date('2021-08-04'),
		changes: [
			'##### UI',
			'* Report API version when connected',
			'* Updated text of apocalypse weapon to say arm instead of aim',
			'* Show loading spinner on send ships',
			'* Combat simulator show defender stats before submit',
			'* Put errors and offline in sticky container',
			'* Tidied up the upkeep control',
			'* Added cancel button to ground ships',
			'* Added cancel button to activate ships',
			'* Show upkeep summary when rebasing',
			'* Moved notification hint link to the panel footer to improve alignment',
			'* Changed population numbers from million to billion',
			'##### Other',
			'* Skip processing of bots with no solar systems',
			'* Added a section to the help page to explain victory'
		],
	},
	{
		date: new Date('2021-08-05'),
		changes: [
			'##### UI',
			'* Added a positional render component to help visualize spatial relationships when sending ships'
		],
	},
	{
		date: new Date('2021-08-06'),
		changes: [
			'##### UI',
			'* Made switching solar system more stable',
			'* Tidied up the cargo page of send ships',
			'##### Notifications',
			'* Returning delivery fleets won\'t notify (they should only notify when they deliver)',
			'* Repeat fleets can be configured to notify when they have completed their configured quantity'
		],
	},
	{
		date: new Date('2021-08-18'),
		changes: [
			'##### Reporting',
			'* Implemented a daily transfer of snapshot data ',
			'* This data is used to generate various reports and graphs which can be used to see how the universe is progressing',
			'* Keep an eye out in various sections of the application to see the data in use'
		],
	},
	{
		date: new Date('2021-08-19'),
		changes: [
			'##### UI',
			'* Further improvements to the layout of the target selection of send ships',
			'* Expanded the positional render to work with intercepting fleets',
			'* Render radars on the positional render',
			'* Changed the layout of the send ships summary step',
			'* Added icons to ship stats summar in system views',
			'* Added a quick "send now" button to send ships stages'
		],
	},
	{
		date: new Date('2021-08-21'),
		changes: [
			'##### UI',
			'* Graceful handling of upgrading installations from the upgrade table',
			'* Can cancel activating ships',
			'* Added an error handler on send ships',
			'* Show newly created fleet after sending ships',
			'* Tidied up send ships summary (again)'
		],
	},
	{
		date: new Date('2021-08-22'),
		changes: [
			'##### UI',
			'* Use a new library for handling notifications',
			'* Show distance on select system dropdowns'
		],
	},
	{
		date: new Date('2021-08-23'),
		changes: [
			'##### UI',
			'* Improved the performance of the tactical map',
			'* Slightly improved the performance of the galaxy map'
		],
	},
	{
		date: new Date('2021-08-24'),
		changes: [
			'##### UI',
			'* Show all parts of a courier contract on send ships view',
			'* Added a fleets view where all of your fleets can be viewed in one place',
			'* Filter map view fleets list by type'
		],
	},
	{
		date: new Date('2024-02-26'),
		introduction: [
			'Preparing the game for another round of battles. Various pieces of infrastructure have been upgraded, as well as development package dependencies.'
		],
		changes: [
			'##### UI',
			'* Changed verify email to info box from warning box',
			'* Fixed galaxy stats graph control buttons on homepage',
			'* Tidied up settings page',
			'* Various adjustments to help texts',
			'* Fixed linebreaks in Faction description pages',
			'* Adjusted mobile display of button holders in page headings',
			'* Split Federation officer controls over 2 rows',
			'* Made the verify email box dismissable',
			'* Made a neater error handling page',
			'* Show agents on send ships cargo lists even if they are on cooldown'
		],
	},
	{
		date: new Date('2024-02-27'),
		changes: [
			'##### UI',
			'* Improved caching on emblem editor for faster rendering',
			'* Added a loading spinner to combat simulator view',
			'* Hide graphs if not enough data'
		],
	},
	{
		date: new Date('2024-02-28'),
		changes: [
			'##### Automation',
			'* Players can now configure installation and ship building to be automated per solar system',
			'##### UI',
			'* Updated upkeep summary to use clearer phrasing',
			'* Only show the option to activate ships which are actually present',
			'* Switching solar system while the ground ships form is open will correctly reload it',
			'* Agents view in system will show a link to the hire agent screen if the player has capacity for more agents',
			'* Added dismiss button to beginners protection message',
			'* Updated relic message to warn if the player is not part of a federation and won\'t get research points'
		],
	},
	{
		date: new Date('2024-02-29'),
		changes: [
			'##### UI',
			'* Added a screen to further explain recipes for manufactories'
		],
	},
	{
		date: new Date('2024-03-01'),
		changes: [
			'##### UI',
			'* Disabled stickiness of resources bar',
			'* Fixed an issue with component quantity display on resources bar',
			'* Reduced font size of message boxes on mobile',
			'* Adjusted display of buttons in message boxes on mobile',
			'* Use a different method for detecting if the user has no internet connection',
			'##### Missions',
			'* Added resource production build missions up to level 20',
			'* Added 2 missions relating to the construction of orbital siege ships',
			'* Added mission for sending ships to collect',
			'* Added missions for colonizing up to 20 total systems',
			'##### Balance',
			'* Added 50 to the cost of Orbital siege ships',
			'* Doubled the effectiveness of raw resource processing',
			'* Increased number of colony pods on the new colonists policy to 3',
			'* Added a policy to award 30 mining barges to each player',
			'* Added negative economy policies for each resource',
			'##### Help',
			'* Added link from manufactories page to the schematics page',
			'* Added link from the combat page to the security page'
		],
	},
	{
		date: new Date('2024-03-02'),
		changes: [
			'##### UI',
			'* Added hype sections with images to the homepage when not logged in',
			'* Tweaked spacing of buttons in message boxes'
		],
	},
	{
		date: new Date('2024-03-03'),
		changes: [
			'##### UI',
			'* Added a help page table to show which installations can be built on each celestial',
			'* Set the schematic view to display the information in a tabbed view',
			'* Added a link from interstellar embassy to the agents view',
			'* Show a progress bar of number of complete missions per category on missions page',
			'* Made resource bar sticky again',
			'* Made homepage login / register CTA more prominent',
			'* Added a hype section for agents',
			'* Added images to help pages',
			'* First draft of solar system image backgrounds',
			'* Change PWA splash image to be logo only ',
			'* Added better visibility of contracts when sending ships to a system',
			'##### Balance',
			'* Changed Bureaucratic Hub to cost more than Sovereignty Office',
			'* Removed the 1 per system limit on Radar Arrays'
		],
	},
	{
		date: new Date('2024-03-04'),
		changes: [
			'##### UI',
			'* Added a changelog archive page to view entire history of changelogs',
			'* Display political data entered by presidents and candidates in a special quote format',
			'* Added more information to region description of solar systems',
			'* Intercepts which won\'t reach target are now made clearer on the minimap of send ships',
			'* Resource bar don\'t show decimal points when rendering values that are greater than 9999',
			'* Fixed an issue in slate controls where button holders can cause overflow on mobile',
			'* Fixed an issue where installations or ships being added to queues caused the queue to be displayed in the wrong order',
			'* Fixed an issue on Firefox mobile where the site overflows the width of the device',
			'* Corrected display of the close button on the tutorial tour',
			'##### Automation',
			'* Added specific logs for installation and ship construction'
		],
	},
	{
		date: new Date('2024-03-05'),
		changes: [
			'##### UI',
			'* Added loading placeholder during initial app load',
			'* Better handling of errors during initial load',
			'* Sped up the selection of systems on the tactical map',
			'* Added a home link on login and register screens'
		],
	},
	{
		date: new Date('2024-03-06'),
		changes: [
			'##### General',
			'* Renamed Resources installation category to Harvesting',
			'##### UI',
			'* Adjusted the display of header buttons in the main panel on mobile',
			'* Fixing of buttons shown in headers for mobile',
			'* Fixed icon padding on solar system dropdown on mobile',
			'* Fixed closing of solar system dropdown on mobile',
			'* Render icons to the left of text',
			'* Added icons to some federation table headings',
			'* Added clear all nodes button to manufactory'
		],
	},
	{
		date: new Date('2024-03-07'),
		changes: [
			'##### UI',
			'* Shortened title of Schematics and Operations on the installations screen',
			'* Fixed an issue with mobile width on empire view ships build queue',
			'* Added links to Discord'
		],
	},
	{
		date: new Date('2024-03-08'),
		changes: [
			'##### Agents',
			'* Can send agents as part of a rebase fleet',
			'##### UI',
			'* Further fixing of display of buttons in panels on mobile',
			'* Display a clear message when messaging all players of a federation'
		],
	},
	{
		date: new Date('2024-03-09'),
		changes: [
			'##### UI',
			'* Show a progress bar on the current item of the installation build queue',
			'* Show the changes view full width under the other info on the home page',
			'* Modified the display of the current installationb build queue item',
			'* Changed slate display for movement type selection'
		],
	},
	{
		date: new Date('2024-03-10'),
		changes: [
			'##### UI',
			'* Initial release of an experimental new menu, designed only for mobile'
		],
	},
	{
		date: new Date('2024-03-11'),
		changes: [
			'##### Politics',
			'* Added a policy to reduce presidential term lengths to 7 days',
			'##### Agents',
			'* Added agent action category',
			'##### UI',
			'* Show celestial background images on celestial pages',
			'* Tweaked the display of the mobile nav menu',
			'* Made titles on help pages shorter on mobile',
			'* Added safe area checking to mobile nav menu to improve usability on iOS devices',
			'* Fixed app flow during authentication',
			'* Added icons to politics panel titles',
			'* Fixed opacity on disabled buttons',
			'##### Balance',
			'* Added some commodity storage to stockpiles'
		],
	},
	{
		date: new Date('2024-03-12'),
		changes: [
			'##### UI',
			'* Added agent actions help page and related links',
			'* Various fixes to the empire view to improve mobile display',
			'* Clicking the links in the resource bar peek will close the peek',
			'* Made the installation construction progress bars update in realtime',
			'* Disable the rename solar system button while the form is submitting'
		],
	},
	{
		date: new Date('2024-03-13'),
		changes: [
			'##### Performance',
			'* Greatly increased performance of map right click',
			'* Map performance improvements',
			'* Initial load performance improvements',
			'##### UI',
			'* Show a cargo bar at the top of send ships form',
			'* Fixed map display on mobile',
			'* Moved the galaxy map away from the tactical map',
			'* Fixed an issue with activating mining drones in a system'
		],
	},
	{
		date: new Date('2024-03-14'),
		changes: [
			'##### Performance',
			'* Improving the initial load time via various server-side efficiencies',
			'* Store all of the type data and type data descriptions on separate server endpoints so it can be cached on the client\'s browser',
			'* More map performance improvements',
			'##### Map',
			'* Added a fade / static effect to areas of the map which haven\'t been loaded'
		],
	},
	{
		date: new Date('2024-03-15'),
		changes: [
			'##### Performance',
			'* More improvements to the initial load time',
			'##### UI',
			'* Trying a new layout for the celestial table on mobile'
		],
	},
	{
		date: new Date('2024-03-17'),
		changes: [
			'##### UI',
			'* If nothing building on empire view then don\'t show "0 more"',
			'* Adjusted padding on celestials table',
			'* Fit 4 per row on celestial table mobile',
			'* Count up on credits in nav bar',
			'* Removed mobile only bits from top nav bar',
			'* Fixed display of voting tables for mobile',
			'* Styled datepicker',
			'* Fixed an issue where the "bring home" button wasn\'t showing on reinforcements outside of radar range'
		],
	},
	{
		date: new Date('2024-03-18'),
		changes: [
			'##### UI',
			'* Added a train all agents button',
			'* Implemented new range of background images',
			'* Added agent portraits'
		],
	},
	{
		date: new Date('2024-03-19'),
		changes: [
			'##### Contracts',
			'* Completing an attack or recon contract will notify the player',
			'##### Bots',
			'* Fixed an issue where bots from the same federation would try to recon each other',
			'##### UI',
			'* Created a Rebase Many empire view',
			'* Improved the stability of target map rendering on send ships form',
			'* Better display of credit transactions on mobile',
			'* Improved display of empire military control table on mobile',
			'* Adjusted duration display on mobile to be more compressed'
		],
	},
	{
		date: new Date('2024-03-21'),
		changes: [
			'##### UI',
			'* Improved display on combat reports and combat report index on mobile',
			'* Improved display of player list on mobile',
			'* Improved display of fleet lists on mobile',
			'* Clicking the system name or an icon will close the mobile menu if open',
			'* Changed the expand system to an icon instead of a button on the mobile menu'
		],
	},
	{
		date: new Date('2024-03-22'),
		changes: [
			'##### UI',
			'* Added warnings on systems that are scheduled to lose control via deconstruction',
			'* Improved display of mission list on mobile',
			'* Corrected the mission completion bar on each mission table to correctly show the number completed',
			'* Improved display of available ships on mobile',
			'* Show version numbers on settings page',
			'* Faded background images on some sub panels',
			'* Added backgrounds to the system by id view',
			'* Hide text on drone control buttons on mobile',
			'* Added system names to error messages on rebase many view',
			'* Sorted out loading display on rebase many',
			'* Reduced the size of emblem piece previews on mobile',
			'* Sorted out display of arriving fleets on mobile',
			'* Made dropdown lists 100% width on mobile'
		],
	},
	{
		date: new Date('2024-03-23'),
		changes: [
			'##### UI',
			'* Improved mobile display of credit income table',
			'* Improved mobile display of megacity empire view',
			'* Improved mobile display of present foreign ships',
			'* Improved mobile display of resources in fleet expand',
			'* Fixed issue with long solar system names on mobile'
		],
	},
	{
		date: new Date('2024-03-25'),
		changes: [
			'##### UI',
			'* Added a message to the politics page to prompt the player to vote on a manifesto or submit one',
			'* Improved display of departures and arrivals on system summary for mobile',
			'* Removed blue from the theme for line graphs so it doesn\'t get confused with the player\'s blue',
			'* Megacity empire view disable assignment buttons when there is nothing to assign',
			'* Show warning for apocalypse shots in the same way attack icons are shown',
			'* Improved map initial load',
			'* Fixed megacity celestial subview on mobile'
		],
	},
	{
		date: new Date('2024-03-26'),
		changes: [
			'##### UI',
			'* Added empire items summary for components and commodities'
		],
	},
	{
		date: new Date('2024-03-27'),
		changes: [
			'##### UI',
			'* Improved display of messages list on mobile',
			'* Show agent portraits of agents present at same celestial',
			'* Added date paging to graphs',
			'* Client will try to refresh automatically when ships have finished activating'
		],
	},
	{
		date: new Date('2024-03-28'),
		changes: [
			'##### Performance',
			'* Better utilisation of database resources when determining which fleets to update',
			'* Assorted indices on frequently hit database tables to reduce resource demand',
			'##### Agents',
			'* Added an arrest action that can be used to capture foreign overt agents at the same celestial',
			'* Made the go covert action usable from level 1',
			'##### UI',
			'* Added an automation control tab to the advisor page of empire view',
			'* Fixed an issue with icon colouring on combat reports',
			'* Show agent profile picture on ransom contract'
		],
	},
	{
		date: new Date('2024-03-29'),
		changes: [
			'##### Performance',
			'* Better loading of player\'s solar systems on login'
		],
	},
	{
		date: new Date('2024-04-02'),
		changes: [
			'##### UI',
			'* Removed the alpha slider from emblem colour picker because transparency is not supported',
			'* Reduced length of "Installation Build Queue" title on mobile',
			'* Fixed a typo on automation view',
			'* Fixed map links on home page',
			'* Fixed contract table display on mobile',
			'* Don\'t show the defeated messsage box until the solar system list has loaded',
			'##### Help',
			'* Fixed the broken map links in missions',
			'* Removed the broken component types links in help pages'
		],
	},
	{
		date: new Date('2024-04-03'),
		changes: [
			'##### UI',
			'* Changed icon for departure on send ships steps',
			'* Hide text on map solar system buttons on mobile ',
			'* Removed duplicate celestial render on map solar system mobile',
			'* Fixed installation types help page on mobile',
			'* Improved display of selected celestials on mobile',
			'* Display drone stats on installation information',
			'* Hide map fleets table header on mobile',
			'* Fixed the "Target one of your solar systems" button when sending ships'
		],
	},
	{
		date: new Date('2024-04-04'),
		changes: [
			'##### UI',
			'* Highlight and prompt when mission reward exceeds available storage ',
			'* Display keypad on number inputs on mobile',
			'* Show celestials of owned solar systems on map view mobile',
			'* Fixed how celestial sub view URLs are handled',
			'* Added a warning when building a second ship producing installation at a celestial',
			'* Added icons to solar systems link on home page'
		],
	},
	{
		date: new Date('2024-04-05'),
		changes: [
			'##### UI',
			'* Added stripes to the ships table of automation view',
			'* Added stripes to the ships table of send ships view',
			'* Adjusted cell layout of various tables including ship selection of send ships',
			'* Show character limit and current count on manifesto and policy',
			'* Added info to megacities commodity tab to show the consumption rate',
			'* Show "Reconned" instead of "Defeat" on combat reports',
			'* Fixed an issue on the automation view where installation automation was incorrect described as enabled',
			'* Trying different cache strategy on map load'
		],
	},
	{
		date: new Date('2024-04-06'),
		changes: [
			'##### Automation',
			'* Added the ability to configure automation to ignore specific installations',
			'* Installation automation will be processed immediately after an installation finishes construction',
			'##### UI',
			'* Fixed an issue where descriptions of game entities would not show ',
			'* Fixed an issue where the resources bar would not update when switching systems or the current system was updated',
			'##### Reporting',
			'* Added response caching to the reporting API'
		],
	},
	{
		date: new Date('2024-04-07'),
		changes: [
			'##### Mining',
			'* Corrected the resource recharge rate calculation for celestials. This will reduce the amount of miners supported to the intended amount (10 miners per hour per size)',
			'##### UI',
			'* Fixed issue with combat report which incorrectly reported as "Reconned"',
			'* Don\'t allow agent selection when intercepting',
			'* Show a warning when sending a colonize without any resources',
			'* Close the resource bar peek if the user\'s current screen changes'
		],
	},
	{
		date: new Date('2024-04-08'),
		changes: [
			'##### UI',
			'* Added an indicator when switching solar system causes loading',
			'* Improved display of tutorial message box on mobile',
			'* Styled map coordinate buttons',
			'* Fixed an issue where display of agents in fleets is duplicated',
			'* Added explanation text to failed training message of "Train all" agent function',
			'* Hide headings on mobile for rebase many fleet results',
			'* Fixed empire view manufactories screen for mobile',
			'* Use new hype images on help pages',
			'* Fixed an issue with celestial routing where refreshing the page would bounce to the celestial summary for a system',
			'* Added some icons to ship stats display',
			'* Fixed a mobile height issue on progress bars',
			'* Hide text on mobile of final top right send ships button',
			'* Moved federation rank icon outside of square brackets'
		],
	},
	{
		date: new Date('2024-04-09'),
		changes: [
			'##### UI',
			'* Improved coordinate entry on tactical map'
		],
	},
	{
		date: new Date('2024-04-11'),
		changes: [
			'##### UI',
			'* Pre-released more performant version of galaxy map',
			'* Added more features to new galaxy map',
			'* Disable back button on graphs when there isn\'t enough data to navigate back',
			'* Fixed right hand padding on tactical map on mobile',
			'* Show radar ranges on galaxy map',
			'* Fixed a bug that caused an error when choose celestial type filter when tactical map was loading',
			'* Fixed a typo when saving automation configurations from the empire view',
			'* More work on the solar system switching resource bar issue',
			'##### Balance',
			'* Lowered optimal levels for agent missions',
			'* Allow building of Biofuel refineries on vegetative planets'
		],
	},
	{
		date: new Date('2024-04-15'),
		changes: [
			'##### UI',
			'* Order agent utility actions at the top of the action selection list',
			'* Fixed an issue with editing manufactory throughput limits',
			'* Disable ground ships button when no ships are selected to ground',
			'* Tweaked display of celestial table for better use of space (e.g on landscape mobile)',
			'* Added clarification to agent action text when there is already an existing effect at the system',
			'* Added padding to the "No sectors have been constructed" message of megacity view',
			'* Hide text on mobile for manifesto vote buttons',
			'* Show return times when calling ships home from reinforcements',
			'* Disable hire agent button when player can\'t afford',
			'* Display the intercept button when there are recon ships available even when there are no interceptors',
			'* Installation build queue progress bar will correctly represent changes to duration due to active policies'
		],
	},
	{
		date: new Date('2024-04-16'),
		changes: [
			'##### Reload Notifications',
			'* Added more notifications to reload data automatically on connected clients',
			'* Sending attacks / recons will reload the target\'s system',
			'* Combat being processed will reload the defending system',
			'* Security status changes will reload the player',
			'* Federation membership changes will reload the player',
			'##### UI',
			'* Adjusted panel footer spacing to match header spacing',
			'* Fixed some height issues with the updated celestial table',
			'* Added total row to economy resource summaries in empire view',
			'* Show bonus on empire economy manufactories view',
			'* Added an advisor warning for when the number of miners used by repeats exceeds the number of miners in the system',
			'* Show ship problems on the ship summary tab',
			'* Fixed an issue where commodities could not be sold to a target system'
		],
	},
	{
		date: new Date('2024-04-17'),
		changes: [
			'##### UI',
			'* Added a totals row to the items tables on empire view resource summary',
			'* Added a components tab to the empire economy view',
			'* Added warning when viewing a manufactory node that is still under construction and show the optimal consumption for level 1 instead of level 0',
			'* Show tier of component on manufactory nodes',
			'* Show resource defecit table on manufactory view',
			'* Better mobile display of nodes list for manufactory view',
			'* Don\'t show repeat time warning when configuring rebase fleets',
			'* Show existing repeats if sending ships between own systems'
		],
	},
	{
		date: new Date('2024-04-18'),
		changes: [
			'##### UI',
			'* Added a mode to network view to show rebases',
			'* Fixed "deficit" typo',
			'* Non-hostile movements on galaxy map will show as neutral instead of enemy',
			'* Resource coloured borders on mobile celestial view buttons',
			'* Show on movements on minimap of send ships',
			'* Fixed commodities items summary on empire view'
		],
	},
	{
		date: new Date('2024-04-19'),
		changes: [
			'##### UI',
			'* Es wurde ein problem behoben, das das anvisieren eines neu kolonisierten systems verhinderte'
		],
	},
	{
		date: new Date('2024-04-21'),
		changes: [
			'##### UI',
			'* Chat view will no longer scroll the whole window down to fit most recent message in view',
			'* Don\'t show "send another" when editing repeat fleets',
			'* Improved display of repeat fleet lists on target selection stage of send ships',
			'* Show totals of ships when editing mining repeats',
			'* Show more accurate mining ships as available in system when editing mining repeats'
		],
	},
	{
		date: new Date('2024-04-22'),
		changes: [
			'##### UI',
			'* Select all / none installations when configuring automation',
			'* Sort solar systems on player solar systems table',
			'* Fixed button positions on manifesto edit',
			'* Show character limits on chat messages',
			'* Show a number entry for manufactory throughput limits',
			'* Better sizing of empire military summary for mobile',
			'* Fixed an issue where the Y coordinate of the map view was initially set to the X coordinate',
			'* Bolded text of totals cell on empire views'
		],
	},
	{
		date: new Date('2024-04-23'),
		changes: [
			'##### UI',
			'* Fixed the display of attack icons on mobile menu',
			'* Sort output items of manufactory page'
		],
	},
	{
		date: new Date('2024-04-26'),
		changes: [
			'##### UI',
			'* Fixed an issue with the mission reward warning on component capacity',
			'* Added advisor warnings for capacity of raw resources and components',
			'* Only show federation short name on bottom bar',
			'* Hide text on mobile for combat report index status filters',
			'* Corrected colour of foreign systems display on galaxy map'
		],
	},
	{
		date: new Date('2024-05-04'),
		changes: [
			'##### UI',
			'* Fixed display of send between system buttons on resource peek and empire view',
			'* Show apocalpyse shots on galaxy map',
			'* Galaxy map filter for explored solar systems',
			'* Fixed display of contract payouts when sending ships',
			'* Show a loading spinner on homepage solar systems list while still loading',
			'* Hide select and go to arrow icon on homepage solar systems list',
			'* Handle long names on mobile celestial tiles',
			'* Space before plus on edit miners form rebase count',
			'* Adjust text on rebasing reinforcements',
			'* Only show relevant manufactory problems on the manufactory view',
			'* Cost of ships reflects number of ships chosen when building ships from the celestial view',
			'* Added an advisor problem for idle miners which are not assigned to repeat fleets',
			'* Added empire economy view for mining ships',
			'* If sending a fleet to own system then reload the target system',
			'##### Automation',
			'* Correctly count constructed drones when automating ship construction',
			'##### API',
			'* Added week number to world'
		],
	},
	{
		date: new Date('2024-05-05'),
		changes: [
			'##### Security Status',
			'* Added a way to pay for redemption from exile security status',
			'* Costs 500 times world week number times number of times you\'ve been redeemed',
			'* Accessed via the player drop down menu or the home page'
		],
	},
	{
		date: new Date('2024-05-06'),
		changes: [
			'##### UI',
			'* Added a new view to show incoming federation fleets'
		],
	},
	{
		date: new Date('2024-05-07'),
		changes: [
			'##### Outposts',
			'* Released the new outposts mechanic',
			'* See the expansino help page for more information ',
			'##### UI',
			'* Fixed a typo in a no cargo warning when sending a fleet to claim systems',
			'* Adjusted text of idle miners warning when the problem was caused by rebasing miners',
			'* Prevent line breaking between credits symbol and quantity',
			'* Show combined component, commodity or raw resource storage when sending ships',
			'* Show a warning icon when sending too many items to a target',
			'* Fixed an issue to do with handling of solar system claim types '
		],
	},
	{
		date: new Date('2024-05-08'),
		changes: [
			'##### UI',
			'* Fixed the idle miners warning when there are 0 repeats',
			'* Don\'t show cargo warning when there is no capacity information at the target',
			'* Show numeral tier with commodity on manufactory nodes',
			'* Quote style text on manifesto subject in manifesto table',
			'* Show total upkeep more clearly when building ships',
			'* When sending a limited number of repeats show the total duration'
		],
	},
	{
		date: new Date('2024-05-11'),
		changes: [
			'##### UI',
			'* New chart to show vote share by federation',
			'* Show long manifesto titles on own line',
			'* Further fixes to idle miners warning'
		],
	},
	{
		date: new Date('2024-05-12'),
		changes: [
			'##### UI',
			'* Fixed an issue where the app would crash when switching to the targetted system on the send ships screen',
			'* Added a table of resource processing schematics to the schematics help page'
		],
	},
	{
		date: new Date('2024-05-13'),
		changes: [
			'##### Security Status',
			'* Changes of security status will be logged and can be viewed from the redemption screen',
			'* Security status changes from before this feature was introduced will not be visible',
			'##### Politics',
			'* Fixed the display of policies on previous presidential terms',
			'##### UI',
			'* Added a new screen to show progress towards victory',
			'* Tidied up the flat menu on the home screen',
			'* When viewing a bribery contract it will show any existing accepted bribes',
			'* On mobile show a collapsed horizontal stage selector for send ships',
			'* Added switch source and target button to send ships',
			'* Better highlighting of each system row on the empire military summary view'
		],
	},
	{
		date: new Date('2024-05-14'),
		changes: [
			'##### UI',
			'* Added a prototype target acquisition mode to the galaxy map',
			'* Added pan controls to the galaxy map',
			'* Added the option to filter display of fleets on galaxy map',
			'* Moving the cursor out of the map while dragging on the galaxy map will end the pan',
			'* Touch interaction on the galaxy map will no longer interact with the browser page (e.g scroll or trigger refresh)',
			'* Market search will now search on item category as well as item type',
			'* Improved numeric entry fields so that a zero is not forced when the field is cleared',
			'##### Agents',
			'* An agent returning to own system will always be set to overt'
		],
	},
];

