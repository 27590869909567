export const notificationsImageConfig = {
  "Children": {
    "Agent": {
      "Variants": 4,
      "Children": {
        "Arrested": {
          "Variants": 2
        },
        "Arrived": {
          "Variants": 1
        },
        "LevelledUp": {
          "Variants": 2
        }
      }
    },
    "Apocalypse": {
      "Variants": 3
    },
    "Contract": {
      "Variants": 4
    },
    "Federation": {
      "Variants": 4
    },
    "Fleet": {
      "Variants": 3
    },
    "Politics": {
      "Variants": 1
    },
    "Relic": {
      "Variants": 2
    },
    "Security": {
      "Variants": 2,
      "Children": {
        "LostControlOfSystem": {
          "Variants": 3
        }
      }
    },
    "Trading": {
      "Variants": 6
    }
  }
}