import * as React from 'react';
import { FleetMovementType, IGameSettings, IShipTypeSettings, ShipClass, ShipType } from '../../../ApplicationState/ApiClient';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ShipInputList, ShipTypeQuantity } from '../../SendShips/Components/ShipInputList';

type Props = {
    factionTypeName: string | undefined,
    shipTypeSettings: IShipTypeSettings,
    gameSettings: IGameSettings,
    groundedShips: { [key: string]: number },
    hintShips: { [key: string]: number },
    setGroundedShips: (groundedShips: { [key: string]: number }) => any
}


export const GroundedShipsForm = (props: Props) => {

    const [availableShips, setAvailableShips] = React.useState<FleetWrapper | undefined>(undefined);

    React.useEffect(() => {
        if (props.factionTypeName !== undefined) {

            const newShips: { [key: string]: number } = {};

            for (let key in props.groundedShips) {
                const existing = key in props.shipTypeSettings.data ? props.shipTypeSettings.data[key] : undefined;

                if (existing !== undefined) {

                    let alternate: ShipType | undefined = undefined;

                    if (existing.class === ShipClass.Drone) {
                        alternate = Object.values(props.shipTypeSettings.data).find(x => x.name === existing.name && x.factionTypeName === props.factionTypeName);
                    } else {
                        alternate = Object.values(props.shipTypeSettings.data).find(x => x.class === existing.class && x.factionTypeName === props.factionTypeName);
                    }

                    if (alternate !== undefined) {
                        newShips[alternate.typeName] = props.groundedShips[existing.typeName];
                    }
                }
            }

            props.setGroundedShips(newShips);

            const ships = Object.values(props.shipTypeSettings.data).filter(x => x.factionTypeName === props.factionTypeName && (x.class !== ShipClass.Drone)).map(x => { return { key: x.typeName, value: 0 }; });
            setAvailableShips(new FleetWrapper(props.shipTypeSettings, props.gameSettings, CollectionHelper.arrayToDictionary(ships)));
        }
    }, [props.factionTypeName]);


    function setShipQuantity(ship: ShipTypeQuantity, quantity: number) {
        props.groundedShips[ship.ship.typeName] = quantity;
        props.setGroundedShips(props.groundedShips);
    }

    if (availableShips === undefined) {
        return null;
    }

    return <ShipInputList
        heading={{ text: "Grounded Ships", icon: IconHelper.Ships.Grounded }}
        selectedShips={new FleetWrapper(props.shipTypeSettings, props.gameSettings, props.groundedShips)}
        shipQuantities={props.groundedShips}
        availableShips={availableShips}
        hideAvailableQuantityButton
        movementType={FleetMovementType.Reinforce}
        shipTypeSettings={props.shipTypeSettings}
        setShipQuantity={setShipQuantity}
        hideStats
        setShipTactic={_ => { }}
        showTactics={false}
        tactics={{}}
    />;
};