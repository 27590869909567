import { observer } from "mobx-react-lite";
import React from "react";
import { FleetMovementType, IFleet, ISolarSystem } from "../../../ApplicationState/ApiClient";
import { AppStateContext, PlayerStateContext, SolarSystemStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { FieldHolder } from "../../../Components/Base/Form/FieldHolder";
import { Select } from "../../../Components/Base/Form/Select";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { SolarSystemLink } from "../../../Components/FusionShift/Links/SolarSystemLink";
import { FleetWrapper } from "../../../Entities/FleetWrapper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { selected_system } from "../../../Navigation/Routing/SolarSystem";
import { FleetTable } from "../../Fleets/SubParts/FleetTable";

type Props = {
    selectedSolarSystem: ISolarSystem | undefined,
    setSelectedFleetId?: ((fleetId: number) => any) | undefined,
    selectedFleetId?: number | undefined,
    fleets: IFleet[] | undefined
}

const MapFleetTable = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [fleetMovementType, setFleetMovementType] = React.useState<FleetMovementType | undefined>(undefined);

    if (!solarSystemState.SolarSystem || !worldState.ShipTypeSettings || !playerState.Player || !worldState.GameSettings) {
        return null;
    }

    if (!props.fleets || props.fleets.length === 0) {
        return <Paragraph type="prompt">Nothing on radar</Paragraph>;
    }

    const fmt: FleetMovementType[] = [];

    for (let f of props.fleets) {
        if (!fmt.includes(f.movementType)) {
            fmt.push(f.movementType);
        }
    }

    const availableShips = new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, solarSystemState.SolarSystem.availableShips);

    const filteredFleets = props.fleets.filter(x => x.movementType === fleetMovementType || fleetMovementType === undefined);

    return <>
        <div className="is-pulled-right">
            <FieldHolder label="Filter">
                <Select
                    showUnset={{ label: "All movement types", value: "ANY" }}
                    value={fleetMovementType}
                    valueChanged={v => setFleetMovementType(v === "ANY" ? undefined : Number(v))}
                    values={fmt.map(x => {
                        return {
                            label: ValueFormatter.friendlyNameForMovementType(x),
                            value: x
                        }
                    })}
                />
            </FieldHolder>
        </div>
        <FleetTable
            availableShips={availableShips}
            display={{
                headings: "hide on mobile",
                arrivalDate: true,
                source: true,
                target: true,
                owner: "with linebreak",
                mass: true,
                countPerPage: 15
            }}
            {...props}
            fleets={filteredFleets}
            reloadCallback={s => {
                if (s && s.solarSystemId === solarSystemState.SolarSystem!.solarSystemId) {
                    solarSystemState.loadSolarSystem(s);
                    appState.navigate(selected_system);
                }
            }}
        />
    </>
});

export const MapFleetList = (props: Props) => {

    return <>
        <div style={{ float: "left" }}>
            {!props.selectedSolarSystem && <Paragraph type="prompt">Showing ship movements in the local area. </Paragraph>}
            {!!props.selectedSolarSystem && <Paragraph type="prompt">Showing ship movements going to and from <SolarSystemLink solarSystem={props.selectedSolarSystem} />. </Paragraph>}
        </div>
        <MapFleetTable {...props} />
    </>
}

