import * as React from 'react';

type Props = {
    value: boolean,
    valueChanged: (value: boolean) => any,
    label: string
}

export const Checkbox = (props: Props) => {

    return <div className="field">
        <label className="checkbox">
            <input type="checkbox" checked={props.value} onChange={e => props.valueChanged(e.target.checked)} />
            <span>{props.label}</span>
        </label>
    </div>
};