import * as React from 'react';
import { IImportDateTime } from '../../../ApplicationState/ReportingApiClient';
import { DateLineChart, SeriesFeatureProps } from '../../Base/Charts/DateLineChart';
import { Paragraph } from '../../Base/Text/Paragraph';
import { DataChoice, DataChooser } from './DataChooser';

type Data = {
    dates: IImportDateTime[];
    data: any,
}

export type DataGraphProps = {
    hideIfInsufficientData?: boolean
}

type Props = DataGraphProps & {
    dataChoices: DataChoice[],
    additionalButtons?: React.ReactNode[],
    data: Data,
    seriesFeature?: SeriesFeatureProps
}

export const DataGraph = (props: Props) => {

    const [dataChoice, setDataChoice] = React.useState(props.dataChoices[0]);

    function xFormat(v) {
        return v.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric"
        });
    }

    function xGet(v) {
        return props.data.dates.find(x => x.importDateTimeKey === v.importDateTimeKey)?.date;
    }

    function categoriesSorter(a, b) {
        const aDate = props.data.dates.find(x => x.importDateTimeKey === a);
        const bDate = props.data.dates.find(x => x.importDateTimeKey === b);

        if (aDate !== undefined && bDate !== undefined) {
            return aDate.date.getTime() - bDate.date.getTime();
        }

        return 0;
    }

    if (props.data.dates.length < 7) {

        if (props.hideIfInsufficientData) {
            return null;
        }

        return <Paragraph>
            Not enough data... come back later.
        </Paragraph>
    }

    return <DateLineChart
        {...props}
        xSorter={categoriesSorter}
        x={{
            getter: xGet,
            format: xFormat
        }}
        y={{
            getter: dataChoice.propertyName,
            format: dataChoice.format
        }}
        data={props.data.data}
        controls={
            <DataChooser
                dataChoice={dataChoice}
                setDataChoice={setDataChoice}
                dataChoices={props.dataChoices}
                additionalButtons={props.additionalButtons}
            />
        }
    />;
};