import { observer } from 'mobx-react-lite';
import { FleetMovementType, ItemCategory } from '../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../Helpers/IconHelper';
import { AgentInputList } from '../Agents/AgentInputList';
import { FleetCapacityBar } from '../Components/Items/FleetCapacityBar';
import { ItemInputList } from '../Components/Items/ItemInputList';
import { RelicInputList } from '../Components/Items/RelicInputList';
import { SendNowButton } from '../SendNowButton';
import { StageProps } from './StageRender';

export const CargoStage = observer((props: StageProps) => {

    const remainingCargoToSend = props.sendShipsBag.fleetWrapper.TotalCargoCapacity - props.sendShipsBag.totalToSendCargo;

    const mustSelectCommoditiesOrResources = (props.sendShipsBag.allowedConfigurations.commoditiesToSend === "Required" ||
        props.sendShipsBag.allowedConfigurations.itemsToSend === "Required");

    const hasSelectedAgents = props.sendShipsBag.agentIdsToSend.length > 0;

    const nextBlocked = !hasSelectedAgents && props.sendShipsBag.totalToSendCargo === 0 && mustSelectCommoditiesOrResources;

    const noExtraAgents = props.sendShipsBag.availableAgentsInSource.length === 0;
    const noExtraItems = !props.sendShipsBag.availableItemsInSource || props.sendShipsBag.availableItemsInSource.find(r => r.itemType.category === ItemCategory.Relic && r.quantity > 0) === undefined;

    return <SubPanel
        heading={{ text: "Cargo", icon: IconHelper.Ships.movementType(FleetMovementType.Delivery) }}
        headingContent={<SendNowButton {...props} isSendNow />}
        footerContent={
            <ButtonHolder isPulledRight>
                <PreviousButton action={props.previousStage} />
                <NextButton action={props.nextStage} isDisabled={nextBlocked} />
            </ButtonHolder>
        }>
        <FleetCapacityBar
            type={FleetMovementType.Delivery}
            current={props.sendShipsBag.fleetWrapper.TotalCargoCapacity - remainingCargoToSend}
            capacity={props.sendShipsBag.fleetWrapper.TotalCargoCapacity}
        />
        <SplitLayout
            horizontalAfter="widescreen"
            isLeftEmpty={!props.sendShipsBag.allowedConfigurations.itemsToSend}
            isRightEmpty={!props.sendShipsBag.allowedConfigurations.commoditiesToSend}
            left={props.sendShipsBag.allowedConfigurations.itemsToSend &&
                <ItemInputList
                    itemTypeSettings={props.sendShipsBag.init.itemTypeSettings}
                    itemsAtTarget={props.sendShipsBag.TargetSolarSystem?.itemsDetail}
                    current={props.sendShipsBag.itemsToSend}
                    setValue={(r, q) => props.sendShipsBag.setItemQuantityToSend(r, q)}
                    available={props.sendShipsBag.availableItemsInSource}
                    cargoCapacityRemaining={remainingCargoToSend}
                    categories={[
                        ItemCategory.Component,
                        ItemCategory.RawResource,
                        ItemCategory.Resource
                    ]}
                />
            }
            right={props.sendShipsBag.allowedConfigurations.commoditiesToSend &&
                <ItemInputList
                    itemTypeSettings={props.sendShipsBag.init.itemTypeSettings}
                    isCommoditySell={props.sendShipsBag.MovementType === FleetMovementType.CommoditySell}
                    commodityBuyOffers={props.sendShipsBag.CommodityBuyOffers}
                    itemsAtTarget={props.sendShipsBag.TargetSolarSystem?.itemsDetail}
                    current={props.sendShipsBag.itemsToSend}
                    setValue={(r, q) => props.sendShipsBag.setItemQuantityToSend(r, q)}
                    available={props.sendShipsBag.availableItemsInSource}
                    cargoCapacityRemaining={remainingCargoToSend}
                    categories={[
                        ItemCategory.Commodity
                    ]}
                />

            }
        />
        <SplitLayout
            isLeftEmpty={!props.sendShipsBag.allowedConfigurations.itemsToSend || noExtraItems}
            isRightEmpty={!props.sendShipsBag.allowedConfigurations.agentsToSend || noExtraAgents}
            left={props.sendShipsBag.allowedConfigurations.itemsToSend &&
                <RelicInputList
                    current={props.sendShipsBag.itemsToSend}
                    setValue={(r, q) => props.sendShipsBag.setItemQuantityToSend(r, q)}
                    available={props.sendShipsBag.availableItemsInSource}
                    cargoCapacityRemaining={remainingCargoToSend}
                />}
            right={props.sendShipsBag.allowedConfigurations.agentsToSend &&
                <AgentInputList
                    current={props.sendShipsBag.agentIdsToSend}
                    setValue={(agentId, send) => props.sendShipsBag.setAgentIdToSend(agentId, send)}
                    available={props.sendShipsBag.availableAgentsInSource}
                    sendShipsBag={props.sendShipsBag}
                />
            }
        />
    </SubPanel>;
});