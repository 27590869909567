import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type Props = {
    value: number
}

export const NumberAbbreviator = (props: Props) => {

    return <>
        <span className="is-hidden-tablet">
            {ValueFormatter.toShorthand(props.value)}
        </span>
        <span className="is-hidden-mobile">
            {ValueFormatter.formatLocaleNumber(props.value)}
        </span>
    </>;
}