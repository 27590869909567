import { CelestialDetail, ISectorTypeSettings, Megacity, MegacityCommodityUse } from "../../../../ApplicationState/ApiClient";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { Problem, ProblemBuilder, ProblemCategory, ProblemsMaybe } from "../Problem";

export const megacityProblemBuilder = new ProblemBuilder(ProblemCategory.Megacities);

export function commodityProblems(wrapper: SolarSystemWrapper, megacity: Megacity, celestial: CelestialDetail, sectorTypeSettings: ISectorTypeSettings): ProblemsMaybe {

    const problems: Problem[] = [];

    for (let s in megacity.sectors) {
        const sector = megacity.sectors[s];
        let empty = 0, low = 0;

        for (let c in sector.commodityStores) {
            const commodity = sector.commodityStores[c];
            if (commodity.use === MegacityCommodityUse.None) {
                continue;
            }
            if (commodity.quantity <= 0) {
                empty++;
            } else if (commodity.quantity > 0 && commodity.quantity <= sector.commodityCapacity * 0.15) {
                low++;
            }
        }

        const sectorType = sectorTypeSettings.data[s];
        if (empty > 0) {
            problems.push(megacityProblemBuilder.warningProblem(`${empty}x Empty Stocks (${sectorType.name})`, `The ${sectorType.name} sector has no stocks of ${empty} ${ValueFormatter.pluralize("commodity", empty)}`));
        }
        if (low > 0) {
            problems.push(megacityProblemBuilder.infoProblem(`${low}x Low Stocks (${sectorType.name})`, `The ${sectorType.name} sector has low stocks of ${low} ${ValueFormatter.pluralize("commodity", low)}`));
        }
    }

    return problems;
}