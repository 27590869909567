import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ShipClass } from '../../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { ShipTypeLink } from '../../../../Components/FusionShift/Links/ShipTypeLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const ShipBuildRequired = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    if (!props.mission.MissionType.shipBuildRequired || !worldState.ShipTypeSettings || !playerState.Player) {
        return null;
    }

    const classRequired = props.mission.MissionType.shipBuildRequired.shipClass;
    const shipTypes = Object.values(worldState.ShipTypeSettings.data)
        .filter(s => s.class == classRequired &&
            s.factionTypeName == playerState.Player!.factionTypeName);

    const shipType = shipTypes.length == 1 ? shipTypes[0] : undefined;

    const progress = props.mission.Progress;
    const required = props.mission.MissionType.shipBuildRequired.quantity;
    const has = shipType && progress && shipType.typeName in progress ? progress[shipType.typeName] : 0;

    return <ObjectiveRow has={has} requires={required} {...props} >
        {"Build "}
        {required > 1 && `${required}x `}
        {!!shipType && <>
            {required === 1 && shipType && `${ValueFormatter.aOrAn(shipType.name)} `}
            <ShipTypeLink shipType={shipType} />
        </>
        }
        {!shipType && `Any ${ShipClass[classRequired]}`}
    </ObjectiveRow>;
});