import { observer } from "mobx-react-lite";
import * as React from "react";
import { IItemTypeSettings, SectorType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { SectorTypeDescription } from "../../../Components/FusionShift/Descriptions/SectorTypeDescription";
import { SectorTypeIcon } from "../../../Components/FusionShift/Icons/SectorTypeIcon";
import { CommodityTypeLink } from "../../../Components/FusionShift/Links/Items/CommodityTypeLink";
import { TypeDataVoice } from "../../../Components/FusionShift/TypeDataVoice";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { CommodityHelper } from "../../../Helpers/CommodityHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { help_page } from "../../../Navigation/Routing/Help";
import { TypeDataBaseView } from "./TypeDataBaseView";

type Props = {
    itemTypeSettings: IItemTypeSettings,
    sectorType: SectorType,
    commodityHelper: CommodityHelper
}

const SectorTypeControl = (props: Props) => {

    const commodities = props.commodityHelper.commoditiesForSectorType(props.sectorType.typeName);

    return <SubPanel
        id={props.sectorType.typeName}
        heading={{ text: props.sectorType.typeName, icon: <SectorTypeIcon sectorTypeName={props.sectorType.typeName} /> }}
        headingContent={<TypeDataVoice type="SectorType" typeData={props.sectorType} />}
    >
        <SplitLayout
            horizontalAfter="tablet"
            left={
                <SectorTypeDescription {...props} />
            }
            right={
                <div>
                    {CollectionHelper.isAnyInArray(commodities) && <>
                        <Paragraph>Demanded commodities:</Paragraph>
                        <ul>
                            {commodities!.map((s, i) => <li key={i}>
                                <CommodityTypeLink commodityType={s.itemType} />
                            </li>)}
                        </ul>
                    </>
                    }
                </div>
            }
        />
    </SubPanel>;
};

const SectorTypesContent = observer(() => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.SectorTypeSettings || !worldState.CommodityHelper || !worldState.ItemTypeSettings) {
        return <LoadingSpinner />;
    }

    const sectors = Object.keys(worldState.SectorTypeSettings.data).map(c => worldState.SectorTypeSettings!.data![c]);

    return <>
        {sectors.map(c => <SectorTypeControl key={c.typeName} sectorType={c} itemTypeSettings={worldState.ItemTypeSettings!} commodityHelper={worldState.CommodityHelper!} />)}
    </>;
});

export const SectorTypesView = () => {
    return <TypeDataBaseView title="Sectors" icon={IconHelper.Megacities.Sectors}
        links={[
            { icon: IconHelper.Megacities.Megacity, to: help_page("megacities"), text: "Megacities" },
            { icon: IconHelper.Manufactories.Manufactory, to: help_page("manufactories"), text: "Manufactories" }
        ]}>
        <SectorTypesContent />
    </TypeDataBaseView>
};