export type BulmaTypes = "primary" | "success" | "info" | "warning" | "danger";

export const allBulmaTypes: BulmaTypes[] = ["primary", "success", "info", "warning", "danger"];

export type BulmaBreakpoint = "mobile" | "tablet" | "desktop" | "widescreen" | "fullhd";

export const BulmaSizes = {
    tablet: 769,
    desktop: 1024,
    widescreen: 1216,
    fullHd: 1408
};