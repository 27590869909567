import { observer } from "mobx-react-lite";
import React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { ResourceIcon } from "../../Icons/Items/ResourceIcon";

type Props = {
    resourceType?: ItemType | undefined | false,
    resourceTypeName?: string | undefined,
    hideTextOnMobile?: boolean,
    noText?: boolean
}

export const ResourceTypeLink = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings) {
        return null;
    }

    const resourceType = props.resourceType ? props.resourceType :
        props.resourceTypeName ? worldState.ItemTypeSettings.data[props.resourceTypeName]
            : undefined;

    if (!resourceType) {
        return null;
    }

    return <>
        <ResourceIcon resourceType={resourceType} />
        {!props.noText && <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {resourceType.name}
        </span>}
    </>;
});