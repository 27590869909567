import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { InstallationType } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { ButtonChooser, choice } from '../../../../Components/Base/Buttons/ButtonChooser';
import { Table } from '../../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { ResourceIcon } from '../../../../Components/FusionShift/Icons/Items/ResourceIcon';
import { InstallationHelper } from '../../../../Helpers/InstallationHelper';
import { TimeHelper } from '../../../../Helpers/TimeHelper';
import { SimpleBaseView } from '../../../BaseView';
import { BalanceCell } from './Components/BalanceCell';

type TargetType = "All" | "Single Installation";
type DisplayType = "Cost at level" | "Total cost to level" | "Time to produce at level" | "Time to produce total" | "Estimated Production Per Hour"

export const CostOfUpgradesView = observer(() => {

    const [targetType, setTargetType] = React.useState<TargetType>("All");
    const [displayType, setDisplayType] = React.useState<DisplayType>("Cost at level");
    const [singleInstallationTypeName, setSingleInstallationTypeName] = React.useState("Stockpile");

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.InstallationTypeSettings || !worldState.ItemTypeSettings || !worldState.GameSettings) {
        return null;
    }

    const stockpile = worldState.InstallationTypeSettings.data["Stockpile"];

    const resourceProducers = [
        worldState.InstallationTypeSettings.data["GasRefinery"],
        worldState.InstallationTypeSettings.data["AgriculturalPlant"],
        worldState.InstallationTypeSettings.data["OreFoundry"],
        worldState.InstallationTypeSettings.data["MineralAssimilator"]
    ]

    const resources = [
        worldState.ItemTypeSettings.data["Gas"],
        worldState.ItemTypeSettings.data["Biomass"],
        worldState.ItemTypeSettings.data["Metal"],
        worldState.ItemTypeSettings.data["Mineral"],
        worldState.ItemTypeSettings.data["Crystal"]
    ]

    const levels: number[] = [];
    for (let i = 1; i <= 20; i++) {
        levels.push(i);
    }
    const expectedNumberOfProducers = 3;

    const displayTypeOptions = [
        choice<DisplayType>("Cost at level", "Cost at level"),
        choice<DisplayType>("Total cost to level", "Total cost to level"),
        choice<DisplayType>("Time to produce at level", "Time to produce at level"),
        choice<DisplayType>("Time to produce total", "Time to produce total"),
        choice<DisplayType>("Estimated Production Per Hour", "Estimated Production Per Hour")

    ];
    const targetTypeOptions = [
        choice<TargetType>("All", "All"),
        choice<TargetType>("Single Installation", "Single Installation")
    ];
    const singleInstallationOptions = [
        stockpile.typeName,
        ...resourceProducers.map(r => r.typeName)
    ].map(r => choice(r, r));

    function costForInstallation(installationType: InstallationType, level: number, resourceTypeName: string) {
        if (!installationType.costPerLevel || !(level in installationType.costPerLevel) || !(resourceTypeName in installationType.costPerLevel[level].items)) {
            return 0;
        }

        return installationType.costPerLevel[level].items[resourceTypeName];
    }

    function costAtLevel(level: number, resourceTypeName: string) {

        if (targetType === "Single Installation") {
            return costForInstallation(worldState.InstallationTypeSettings!.data[singleInstallationTypeName], level, resourceTypeName);
        }

        let value = costForInstallation(stockpile, level, resourceTypeName);

        for (const installation of resourceProducers) {
            value += expectedNumberOfProducers * costForInstallation(installation, level, resourceTypeName);
        }

        return value;
    }

    function totalCostToLevel(level: number, resourceTypeName: string) {
        let cost = 0;

        while (level > 0) {
            cost += costAtLevel(level, resourceTypeName);
            level--;
        }

        return cost;
    }

    function productionPerHour(level: number) {
        return InstallationHelper.resourceProductionPerHour(level, worldState.GameSettings!) * 3;
    }

    function totalProductionPerHour(level: number) {
        return (productionPerHour(level - 1) * 0.3) + (productionPerHour(level) * 0.7);
    }

    function hoursToProduceAtLevel(level: number, resourceTypeName: string) {
        if (resourceTypeName === "Crystal") {
            return undefined;
        }

        const needed = costAtLevel(level, resourceTypeName);
        const production = totalProductionPerHour(level);

        if (production > 0) {
            return needed / production;
        }
        return undefined;
    }

    function timeToProduceAtLevel(level: number, resourceTypeName: string) {
        const hours = hoursToProduceAtLevel(level, resourceTypeName);
        if (hours === undefined) {
            return "-"
        }
        return TimeHelper.formatTimeSpanByWords(TimeHelper.createTimeSpanFromHours(hours));
    }

    function timeToProduceTotal(level: number, resourceTypeName: string) {
        let hours = 0;

        while (level > 0) {
            const hoursHere = hoursToProduceAtLevel(level, resourceTypeName);
            hours += hoursHere !== undefined ? hoursHere : 0;
            level--;
        }
        if (hours === 0) {
            return "-";
        }
        return TimeHelper.formatTimeSpanByWords(TimeHelper.createTimeSpanFromHours(hours));
    }

    function dataAtLevel(level: number, resourceTypeName: string) {
        if (displayType === "Cost at level") {
            return costAtLevel(level, resourceTypeName);
        } else if (displayType === "Total cost to level") {
            return totalCostToLevel(level, resourceTypeName);
        } else if (displayType === "Time to produce at level") {
            return timeToProduceAtLevel(level, resourceTypeName);
        }
        else if (displayType === "Time to produce total") {
            return timeToProduceTotal(level, resourceTypeName);
        } else if (displayType === "Estimated Production Per Hour") {
            return totalProductionPerHour(level);
        }
        return undefined;
    }


    return <SimpleBaseView heading={"Balance - Cost of Upgrades"}    >
        <ButtonChooser value={displayType} values={displayTypeOptions} valueChanged={setDisplayType} />
        <ButtonChooser value={targetType} values={targetTypeOptions} valueChanged={setTargetType} />
        {targetType === "Single Installation" && <ButtonChooser value={singleInstallationTypeName} values={singleInstallationOptions} valueChanged={setSingleInstallationTypeName} />}
        <Paragraph type="prompt">
            {targetType === "All" && `Showing stats for ${expectedNumberOfProducers} of each ${resourceProducers.map(i => i.name).join(", ")} and a ${stockpile.name}. `}
            {targetType === "Single Installation" && `Showing stats for ${singleInstallationTypeName}. `}
            Crystal is assumed to be provided for so is excluded from any "time to produce" stats, but is included in the total count.
            Production is assumed to be as if 2 of the 3 resource generators are upgraded to that level.
        </Paragraph>
        <Table isHoverable isFullWidth heading={
            <>
                <th>Level</th>
                {resources.map(r => <th key={r.typeName}>
                    <ResourceIcon resourceType={r} />
                </th>)}
            </>
        }>
            {levels.map(level => <tr key={level}>
                <td>
                    {level}
                </td>
                {resources.map(r => <BalanceCell key={r.typeName} value={dataAtLevel(level, r.typeName)} />)}
            </tr>)}
        </Table>
    </SimpleBaseView>;
});
