import * as React from "react";
import { ISolarSystem, SolarSystemDetail, ValidationResult } from "../../../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { ValidatingStringInput } from "../../../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../../../Components/Base/Form/ValidationHelper";
import { Paragraph } from "../../../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../../../Helpers/IconHelper";

type Props = {
    solarSystem: ISolarSystem,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const Rename = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [name, setName] = React.useState(ValidationHelper.empty);

    function submit() {
        return rename(name.validText, name.isValid);
    }

    function rename(name: string | undefined, valid: boolean): boolean {
        if (!valid) {
            return false;
        }

        setIsSubmitting(true);

        apiState.SolarSystemClient.rename(props.solarSystem.solarSystemId, name!).then(solarSystem => {
            if (solarSystem) {
                props.reloadCallback(solarSystem);
            }
        }).finally(() => {
            setIsSubmitting(false);
        });

        return true;
    }

    function validate(s) {
        if (s === props.solarSystem.name) {
            return new Promise<ValidationResult>(() => ValidationResult.fromJS({ error: "Must specify a new name", isValid: false }));
        }
        return apiState.SolarSystemClient.validateName(props.solarSystem.solarSystemId, s);
    }

    return <form onSubmit={submit}>
        <SubPanel heading={{ text: `Rename ${props.solarSystem.name}`, icon: IconHelper.Combat.Tactics }}
            footerContent={
                <Button
                    type="action"
                    icon={IconHelper.General.Confirm}
                    text="Confirm" action={submit}
                    isDisabled={!name.isValid || isSubmitting}
                    isPulledRight
                />}>
            <Paragraph type="prompt">
                Enter a new name for the solar system.
            </Paragraph>
            <ValidatingStringInput
                label="Name"
                icon={IconHelper.General.Edit}
                initialValue={props.solarSystem.name}
                validation={validate}
                valueChanged={setName}
            />
        </SubPanel>
    </form>;
};