import { ICelestialDetail, IInstallationTypeSettings, Installation, IShipTypeSettings } from '../../../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { ButtonLink } from '../../../../../Components/Base/Buttons/ButtonLink';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { InstallationHelper } from '../../../../../Helpers/InstallationHelper';
import { celestial_feature } from '../../../../../Navigation/Routing/SolarSystem';

type Props = {
    installation: Installation,
    celestial: ICelestialDetail,
    installationTypeSettings: IInstallationTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    children?: React.ReactNode
}

export const FeaturesControl = (props: Props) => {

    const features = InstallationHelper.celestialFeatures(props.installation, props.celestial, props.installationTypeSettings, props.shipTypeSettings);

    if (features.length === 0) {

        if (props.children === undefined) {
            return null;
        }

        return <>
            {props.children}
        </>;
    }

    return <ButtonHolder className="is-pulled-right">
        {features.map(f => <ButtonLink
            hideTextOnMobile
            key={typeof f === "string" ? f : "Installation"}
            text={typeof f === "string" ? f : "Installation"}
            icon={IconHelper.Celestials.celestialFeature(f)}
            to={celestial_feature(props.celestial.celestialId, f)}
        />)}
        {props.children && props.children}
    </ButtonHolder>;
};