import { FleetMovementType, ICelestialTypeSettings, IItemTypeSettings, IRepeatFleet } from '../../../ApplicationState/ApiClient';
import { ItemsIcons } from '../../../Components/FusionShift/Icons/Items/ItemsIcons';
import { ItemTypeIcon } from '../../../Components/FusionShift/Icons/Items/ItemTypeIcon';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';

type Props = {
    fleet: IRepeatFleet,
    celestialTypeSettings: ICelestialTypeSettings,
    itemTypeSettings: IItemTypeSettings
}

export const RepeatFleetItemIcons = (props: Props) => {


    if (props.fleet.movementType === FleetMovementType.Mine) {

        const itemType = CelestialHelper.itemTypeFromCelestial(props.celestialTypeSettings, props.itemTypeSettings, props.fleet.targetCelestial);

        if (itemType === undefined) {
            return null;
        }

        return <ItemTypeIcon itemType={itemType} />;
    }

    if (props.fleet.movementType === FleetMovementType.Collection) {
        return <ItemsIcons {...props} items={props.fleet.collection?.items ?? {}} />;
    }

    return <ItemsIcons {...props} items={props.fleet.itemsToSend} />;
}
