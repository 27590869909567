import * as React from 'react';
import { IFederationDetail, IGameSettings, IPlayer } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { FederationHelper } from '../../../Helpers/FederationHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { PlayerButton } from './PlayerButton';

type Props = {
    gameSettings: IGameSettings,
    observingPlayer: IPlayer,
    player: IPlayer,
    federation: IFederationDetail,
    reloadCallback: (federation: IFederationDetail) => any
}

export const MemberControls = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    function demote(playerId: number) {
        return apiState.FederationClient.demote(props.federation.federationId, playerId).then(federation => {
            if (federation) {
                props.reloadCallback(federation);
            }
        })
    }

    function promote(playerId: number) {
        return apiState.FederationClient.promote(props.federation.federationId, playerId).then(federation => {
            if (federation) {
                props.reloadCallback(federation);
            }
        })
    }

    function kick(playerId: number) {
        return apiState.FederationClient.kick(props.federation.federationId, playerId).then(federation => {
            if (federation) {
                props.reloadCallback(federation);
            }
        })
    }

    if (props.observingPlayer.playerId === props.player.playerId) {
        return null;
    }

    const player = props.player;

    const isSelfLeader = props.observingPlayer && props.observingPlayer.federation && props.observingPlayer.federation.federationId === props.federation.federationId && FederationHelper.isLeader(props.observingPlayer.federation);

    const isOtherLeader = FederationHelper.isLeader(player.federation);
    const isOtherOfficer = FederationHelper.isOfficer(player.federation);

    const hasOfficerCapacity = isSelfLeader && FederationHelper.hasOfficerCapacity(props.federation, props.gameSettings);

    const canDemote = isSelfLeader && !isOtherLeader && isOtherOfficer;
    const canPromote = isSelfLeader && (hasOfficerCapacity || isOtherOfficer);
    const canKick = isSelfLeader && !isOtherLeader;

    if (!canDemote && !canPromote && !canKick) {
        return null;
    }

    return <ButtonHolder>
        {canDemote && <PlayerButton
            player={player}
            type={"danger"}
            text={"Demote"}
            icon={IconHelper.Federations.Demote}
            confirm={"demote"}
            action={demote}
        />}
        {canPromote && <PlayerButton
            player={player}
            type={"action"}
            text={isOtherOfficer ? "Make Leader" : "Promote"}
            icon={IconHelper.Federations.Promote}
            confirm={`promote`}
            action={promote}
        />}
        {canKick && <PlayerButton
            player={player}
            type={"danger"}
            text={"Kick"}
            icon={IconHelper.Federations.Kick}
            confirm={"kick"}
            action={kick}
        />}
    </ButtonHolder>
}