import * as React from 'react';
import { AccountSubscriptionLevelResponse, PremiumDetails, Subscription, SubscriptionLevel } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../../../../Components/Base/Loading/LoadingSpinner';
import { InfoMessageBox } from '../../../../../Components/Base/MessageBox';
import { Paragraph } from '../../../../../Components/Base/Text/Paragraph';
import buySubscription from '../../../../../Helpers/BuySubscription';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';
import { SubscriptionInGameProductsList } from '../InGameProducts/SubscriptionInGameProductsList';
import { ActiveFreeSubscriptionControl } from './ActiveFreeSubscriptionControl';
import { ActiveOngoingPaidSubscriptionControl } from './ActiveOngoingPaidSubscriptionControl';
import { ActiveTemporaryPaidSubscriptionControl } from './ActiveTemporaryPaidSubscriptionControl';
declare var Stripe: any;

type SubscriptionControlProps = {
    accountSubscriptionLevel: AccountSubscriptionLevelResponse,
    details: PremiumDetails
}

export const SubscriptionControl = (props: SubscriptionControlProps) => {
    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);
    const [isRedirecting, setIsRedirecting] = React.useState(false);
    const [message, setMessage] = React.useState<string | undefined>(undefined);

    const [checkoutSubscription, setCheckoutSubscription] = React.useState<Subscription | undefined>(undefined);

    React.useEffect(() => {
        if (props.accountSubscriptionLevel.subscriptionLevel === SubscriptionLevel.OngoingPaid && checkoutSubscription === undefined) {
            apiState.PremiumClient.getCheckoutSubscriptionDetails().then(subscription => {
                if (subscription) {
                    setCheckoutSubscription(subscription);
                }
            });
        }
    }, [checkoutSubscription, props.accountSubscriptionLevel.subscriptionLevel]);

    if (props.accountSubscriptionLevel.subscriptionLevel === SubscriptionLevel.OngoingPaid && checkoutSubscription === undefined) {
        return <LoadingSpinner />;
    }

    return <SubPanel heading={{ text: "Subscription", icon: IconHelper.Premium.Subscription }}>
        {isRedirecting && <>
            Please wait while you are redirected to the payment processor
            <LoadingSpinner />
        </>}
        {message !== undefined && <InfoMessageBox text={message} />}
        {!isRedirecting && message === undefined && <>
            {props.accountSubscriptionLevel.subscriptionLevel === SubscriptionLevel.OngoingPaid
                && checkoutSubscription !== undefined && <ActiveOngoingPaidSubscriptionControl subscription={checkoutSubscription} reloadCallback={m => setMessage(m)} />}
            {props.accountSubscriptionLevel.subscriptionLevel === SubscriptionLevel.OngoingFree
                && <ActiveFreeSubscriptionControl />}
            {props.accountSubscriptionLevel.subscriptionLevel === SubscriptionLevel.TemporaryPaid
                && <ActiveTemporaryPaidSubscriptionControl expires={props.accountSubscriptionLevel.expires!} />}
            {props.accountSubscriptionLevel.subscriptionLevel === SubscriptionLevel.None && <>
                <Paragraph>
                    You don't have an active subscription.
                    <br /><br />
                    {props.details.isEligibleForSubscriptionTrial
                        ? `You are eligible for a premium subscription trial. Get 7 days free, then ${ValueFormatter.formatCurrency(props.details.subscriptionProduct.unitCost)} per month.`
                        : `Subscribe for ${ValueFormatter.formatCurrency(props.details.subscriptionProduct.unitCost)} per month.`}
                    <Button
                        className="is-pulled-right"
                        type="nav"
                        action={() => { setIsRedirecting(true); buySubscription(apiState.PremiumClient, props.details.isEligibleForSubscriptionTrial); }}
                        icon={IconHelper.General.Confirm} text="Subscribe" />
                </Paragraph>
                <Paragraph>
                    Or buy a pre-paid temporary subscription:
                </Paragraph>
                <SubscriptionInGameProductsList details={props.details} />
            </>}
        </>}
    </SubPanel>;
};