import { ItemCategoryIcon } from "../../../Components/FusionShift/Icons/Items/ItemCategoryIcon";
import { ItemTypeLinkFromTypeName } from "../../../Components/FusionShift/Links/Items/ItemTypeLink";
import { MarketSearch } from "../BrowseView";

type Props = {
    item?: MarketSearch,
    hideTextOnMobile?: boolean,
    noText?: boolean
}

export const MarketSearchRender = (props: Props) => {


    if (props.item === undefined) {

        return <>anything</>;
    }

    if (typeof props.item !== "string") {
        return <ItemCategoryIcon itemCategory={props.item} />;
    }
    return <ItemTypeLinkFromTypeName {...props} itemTypeName={props.item} />;
};