export function handleOnClick(e: React.MouseEvent, action: () => any) {
    if (!isA(e.target)) {
        action();
    }
}

export function isA(e: any) {
    const element = e as Element;

    if (element.tagName === "TR") {
        return false;
    }

    if (element.tagName === "A") {
        return true;
    }
    if (element.tagName === "BUTTON") {
        return true;
    }
    if (element.tagName === "INPUT") {
        return true;
    }
    if (element.tagName === "LABEL" && element) {
        return true;
    }

    if (element.parentElement) {
        return isA(element.parentElement);
    }
    return false;
};