import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { DismissButton } from '../../Base/Buttons/DismissButton';
import { ErrorMessageBox } from '../../Base/MessageBox';

export const AppErrorMessage = observer(() => {

    const appState = React.useContext(AppStateContext);

    const [error, setError] = React.useState(appState.Error);

    React.useEffect(() => {
        setError(appState.Error);
    }, [appState.Error]);

    if (error === undefined) {
        return null;
    }

    return <ErrorMessageBox text={error.text} button={
        <DismissButton className="is-pulled-right" action={() => appState.clearError()} />
    } />;
});