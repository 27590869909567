import { observer } from "mobx-react-lite";
import * as React from "react";
import { IPlayer, ISolarSystem, MarketOrder, MarketOrderWithSolarSystem } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, SolarSystemStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { FancyTable, thEmpty, thSort, thSortHiddenTouch } from "../../../Components/Base/Containers/FancyTable";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { AcceptMarketOrderButton } from "../../../Components/FusionShift/Buttons/AcceptMarketOrderButton";
import { SolarSystemLink } from "../../../Components/FusionShift/Links/SolarSystemLink";
import { FleetHelper } from "../../../Helpers/FleetHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { nameof } from "../../../Helpers/TypeHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { market_orders } from "../../../Navigation/Routing/Market";
import { MarketSearchRender } from "./MarketTradeRender";

type BaseProps = {
    showLocation: boolean,
    solarSystemForForcedAcceptButton?: ISolarSystem | undefined,
    reloadCallback?: () => any,
    customMarketOrderSelectedAction?: (marketOrder: MarketOrder) => any
}

type Props = BaseProps & {
    marketOrders: MarketOrder[]
}

type RowProps = BaseProps & {
    marketOrder: MarketOrder,
    solarSystem: ISolarSystem | undefined,
    player: IPlayer | undefined
}

const MarketOrderRow = observer((props: RowProps) => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    function cancel(marketOrder: MarketOrder) {
        return apiState.MarketOrderClient.cancel(marketOrder.solarSystemId, marketOrder.marketOrderId).then(_ => {
            appState.navigate(market_orders);
            if (props.reloadCallback) {
                props.reloadCallback();
            }
        })
    }

    const m = props.marketOrder;

    return <>
        <td>
            <MarketSearchRender item={m.desiredItemTypeName} hideTextOnMobile />
            {" for "}
            <MarketSearchRender item={m.offeredItemTypeName} hideTextOnMobile />
            {` x${m.ratioOfferedToDesired.toFixed(1)}`}
        </td>
        <td> {ValueFormatter.formatLocaleNumber(m.quantityRemaining)}</td>
        {props.showLocation && m instanceof MarketOrderWithSolarSystem &&
            <>
                <td className="is-flexible-width is-hidden-touch">{!!m.solarSystem && <SolarSystemLink solarSystem={m.solarSystem} />}</td>
                <td>{!!m.solarSystem && props.solarSystem && ValueFormatter.formatDistance(FleetHelper.distance(m.solarSystem, props.solarSystem))}</td>
            </>
        }
        <td>
            {props.solarSystem && props.solarSystem.solarSystemId === m.solarSystemId &&
                <Button type="danger" icon={IconHelper.Market.Cancel} text="Cancel" className="is-small" action={() => cancel(m)} confirm="Are you sure you want to cancel this order? Any remaining quantity will be returned to the solar system" />
            }
            {props.showLocation && m instanceof MarketOrderWithSolarSystem && !!m.solarSystem && !!m.solarSystem.owner && props.player && m.solarSystem.owner.playerId !== props.player.playerId &&
                <AcceptMarketOrderButton solarSystem={m.solarSystem} marketOrderId={m.marketOrderId} />
            }
            {props.solarSystemForForcedAcceptButton &&
                <AcceptMarketOrderButton solarSystem={props.solarSystemForForcedAcceptButton} marketOrderId={m.marketOrderId} />
            }
            {props.customMarketOrderSelectedAction !== undefined &&
                <Button type="nav" icon={IconHelper.Market.Trade} text="Trade" action={() => props.customMarketOrderSelectedAction!(m)} hideTextOnMobile />
            }
        </td>
    </>;
});

export const MarketOrderTable = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const header = React.useMemo(() => {
        const h = [
            thSort<MarketOrder>("Trade", nameof<MarketOrder>("ratioOfferedToDesired")),
            thSort<MarketOrder>("Qty", nameof<MarketOrder>("quantityRemaining"))
        ];
        if (props.showLocation) {
            h.push(thSortHiddenTouch("Location", (m: MarketOrder) => m instanceof MarketOrderWithSolarSystem && m.solarSystem && m.solarSystem.name));
            h.push(thSort("Distance", (m: MarketOrder) => m instanceof MarketOrderWithSolarSystem && m.solarSystem && m.solarSystem.name && solarSystemState.SolarSystem && FleetHelper.distance(m.solarSystem, solarSystemState.SolarSystem)));
        };
        h.push(thEmpty());
        return h;
    }, [props, solarSystemState.SolarSystem]);

    if (!playerState.Player) {
        return <LoadingSpinner />;
    }

    return <FancyTable isStriped isHoverable isFullwidth hasBreakAll
        header={header}
        data={props.marketOrders}
        renderRow={(m: MarketOrder) => <MarketOrderRow
            {...props}
            marketOrder={m}
            player={playerState.Player}
            solarSystem={solarSystemState.SolarSystem}
        />}
    />;
});