import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { BugReportLink, FeedbackLink } from "../../../Components/FusionShift/Links/SurveyLinks";
import { HelpTypesPages, help } from "../../Routing/Help";
import { MenuLink, isActiveSubMenu } from "../MenuState";

export const HelpMenu = observer(() => {

    const location = useLocation();

    return <div className={`navbar-item has-dropdown is-hoverable is-hidden-touch${isActiveSubMenu(location, help)}`}>
        <a className="navbar-link">
            Help
        </a>
        <div className="navbar-dropdown">
            <MenuLink to={help} className="navbar-item" end>Help Index</MenuLink>
            <hr className="navbar-divider" />
            {HelpTypesPages.map(x => <MenuLink
                key={x.to}
                to={x.to}
                className="navbar-item"
            >
                {x.title}
            </MenuLink>
            )}
            <hr className="navbar-divider" />
            <FeedbackLink className="navbar-item" showIcon />
            <BugReportLink className="navbar-item" showIcon />
        </div>
    </div>;
});