import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext, SolarSystemStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { CollectionHelper } from '../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { InstallationHelper } from '../../../../Helpers/InstallationHelper';
import { celestial_ships } from '../../../../Navigation/Routing/SolarSystem';

type BuildShipsOption = {
    celestialId: number
}

type Props = {
    isExpanded: boolean,
    setExpanded: (expand: boolean) => any,
    isPulledRight?: boolean
}


export const BuildShipsInSolarSystemButton = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [buildShipsOptions, setBuildShipsOptions] = React.useState<BuildShipsOption[]>([]);

    function navigateToBuildShips(celestialId: number) {
        appState.navigate(celestial_ships(celestialId));
    }

    function initialPress() {
        if (buildShipsOptions.length > 1) {
            props.setExpanded(!props.isExpanded);
        } else {
            navigateToBuildShips(buildShipsOptions[0].celestialId);
        }
    }

    React.useEffect(() => {

        if (solarSystemState.SolarSystem && worldState.ShipTypeSettings && worldState.InstallationTypeSettings) {
            const bso: BuildShipsOption[] = [];

            for (let celestial of solarSystemState.SolarSystem.celestials) {
                if (CollectionHelper.isAnyInDictionary(celestial.shipTypeNamesThatCanBeBuilt)) {
                    const installations = InstallationHelper.installationsWhichBuildShips(worldState.InstallationTypeSettings, worldState.ShipTypeSettings, celestial);
                    if (installations && installations.length > 0) {
                        bso.push({
                            celestialId: celestial.celestialId
                        });
                    }
                }
            }

            setBuildShipsOptions(bso);
        }
    }, [solarSystemState.SolarSystem, worldState.ShipTypeSettings, worldState.InstallationTypeSettings]);

    if (!solarSystemState.SolarSystem || !worldState.ShipTypeSettings || !worldState.InstallationTypeSettings || buildShipsOptions.length === 0) {
        return null;
    }

    return <Button
        isSelected={props.isExpanded}
        type="nav"
        icon={IconHelper.Ships.Build}
        text="Build"
        action={() => initialPress()}
        {...props}
        hideTextOnMobile
    />
});