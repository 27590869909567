import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PlayerStateContext, SolarSystemStateContext } from '../../../../ApplicationState/ContextRoot';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const CapitalSystemRenameRequired = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (!props.mission.MissionType.isCapitalSystemRenameRequired || !playerState.Player) {
        return null;
    }

    const prefix = playerState.Player.name.endsWith("s") ? "" : "s";
    const capitalSolarSystemId = playerState.Player.capitalSolarSystemId;
    const capitalSolarSystem = capitalSolarSystemId && solarSystemState.SolarSystems ? solarSystemState.SolarSystems.find(s => s.solarSystemId === capitalSolarSystemId) : undefined;
    const defaultName = `${playerState.Player.name}'${prefix} System`;

    const hasRenamed = !(capitalSolarSystem === undefined || capitalSolarSystem.name === defaultName);

    const systemName = hasRenamed || capitalSolarSystem === undefined ? " your capital" : capitalSolarSystem.name;

    return <ObjectiveRow has={hasRenamed ? 1 : 0} requires={1} {...props}>
        Rename {systemName}
    </ObjectiveRow>;
});