import { IFederation } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { ProgressBar } from '../../../Components/Base/ProgressBar';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { FederationLink } from '../../../Components/FusionShift/Links/FederationLink';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    federations: IFederation[]
}

export const ResearchPointTable = (props: Props) => {

    const federations = props.federations
        .filter(x => x.researchPoints !== undefined && x.researchPoints !== null && x.researchPoints > 0)
        .sort((a, b) => b.researchPoints - a.researchPoints);

    const any = federations.length > 0;

    return <SubPanel
        heading={{
            text: "Research Points",
            icon: IconHelper.Federations.ResearchPoint
        }}
        isUnpadded={any}>
        {!any && <Paragraph>
            No federations have earned any research points yet.
        </Paragraph>}
        <Table
            isFullWidth
            isFixed
        >
            {federations
                .map(x => <tr key={x.federationId}>
                    <td>
                        <FederationLink federation={x} />
                    </td>
                    <td>
                        <ProgressBar
                            max={100}
                            value={x.researchPoints}
                            text={`${x.researchPoints} / 100`}
                            leftIcon={IconHelper.Federations.ResearchPoint}
                            danger={0}
                            warning={0}
                        />
                    </td>
                </tr>)}
        </Table>
    </SubPanel>
};