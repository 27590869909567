import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AgentActionType } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext } from "../../../ApplicationState/ContextRoot";
import { RichTextArray } from "../RichTextArray";

type Props = {
    agentActionType: AgentActionType
}

export const AgentActionTypeDescription = observer((props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const description = descriptionState.AgentActions?.find(x => x.typeName === props.agentActionType.typeName);

    return <>
        <RichTextArray text={description?.description} />
    </>;
});