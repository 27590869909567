import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IAgentDetail } from '../../../ApplicationState/ApiClient';
import { AppStateContext, PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { handleOnClick } from '../../../Components/Base/Util';
import { AgentPortrait } from '../../../Components/FusionShift/Images/AgentPortrait';
import { AgentLink } from '../../../Components/FusionShift/Links/AgentLink';
import { agentById } from '../../../Navigation/Routing/Agents';
import { ActionReadiness } from './ActionReadiness';
import { AgentPositionSummary } from './AgentPositionSummary';

type Props = {
    title: string,
    agents: IAgentDetail[] | undefined,
    headingContent?: React.ReactNode
}

export const AgentTable = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (props.agents === undefined || props.agents.length === 0 || playerState.Player === undefined) {
        return null;
    }

    return <SubPanel
        heading={props.title}
        headingContent={props.headingContent}
        isUnpadded>
        <Table isClickable>
            {props.agents.map(x => <tr key={x.agentId} onClick={e => handleOnClick(e, () => appState.navigate(agentById(x.agentId)))}>
                <td>
                    <AgentPortrait agent={x} size='tiny' />
                </td>
                <td className="is-hidden-tablet">
                    <AgentLink agent={x} /><br />
                    <AgentPositionSummary agent={x} />
                    {x.playerId === playerState.Player?.playerId &&
                        <>
                            <br />
                            <ActionReadiness agent={x} />
                        </>
                    }
                </td>
                <td className="is-hidden-mobile">
                    <AgentLink agent={x} />
                </td>
                <td className="is-hidden-mobile">
                    <AgentPositionSummary agent={x} />
                </td>
                <td className="is-hidden-mobile">
                    {x.playerId === playerState.Player?.playerId &&
                        <ActionReadiness agent={x} />
                    }
                </td>
            </tr>)}
        </Table>
    </SubPanel>;
});