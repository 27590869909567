import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "../Icon";

type Props = {
    to: string,
    icon: string,
    text: string,
    className?: string | undefined,
    hideTextOnMobile?: boolean,
    isPulledRight?: boolean
}

export const ButtonLink = (props: Props) => {

    const className = classNames(
        "button",
        props.className,
        {
            "is-pulled-right": props.isPulledRight
        },
        props.text.length === 0 && "has-no-text"
    );

    return <Link className={className} to={props.to}>
        <Icon icon={props.icon} />
        <span className={`is-button-text ${props.hideTextOnMobile ? "is-hidden-mobile" : ""}`}>
            {props.text}
        </span>
    </Link>;
};
