import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { HelpStateContext } from "../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { IconHelper } from "../../Helpers/IconHelper";
import { TextHelper } from "../../Helpers/TextHelper";
import { HelpTypesPages, help_page } from "../../Navigation/Routing/Help";
import { SimpleBaseView } from "../BaseView";

export const HelpIndex = observer(() => {

    const helpState = React.useContext(HelpStateContext);

    if (!helpState.Help) {
        return <LoadingSpinner />;
    }

    return <SimpleBaseView heading={{ text: "Help", icon: IconHelper.General.Help }}>
        <SplitLayout
            left={
                <SubPanel heading="Help Topics">
                    <ul>
                        {helpState.Help.map(d => <li key={d.name}>
                            <Link to={help_page(d.typeName.toLocaleLowerCase())}>{TextHelper.tidyText(d.name)}</Link>
                        </li>)}
                    </ul>
                </SubPanel>
            }
            right={
                <SubPanel heading="Game Entities">
                    <ul>
                        {HelpTypesPages.map(x => <li
                            key={x.to}>
                            <Link to={x.to}>
                                {x.title}
                            </Link>
                        </li>
                        )}
                    </ul>
                </SubPanel>
            }
        />
    </SimpleBaseView>;
});