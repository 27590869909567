import { IWorldEffects, ShipClass } from "../ApplicationState/ApiClient";

export class ActiveEffectsHelper {

    public static installationCostModification(worldEffects: IWorldEffects): number {

        return 1 + worldEffects.installationCostModification;
    }

    public static costModifierForShipClass(worldEffects: IWorldEffects, shipClass: ShipClass) {

        return 1 + this.rawCostModifierForShipClass(worldEffects, shipClass);
    }

    public static rawCostModifierForShipClass(worldEffects: IWorldEffects, shipClass: ShipClass) {

        const value = worldEffects.shipClassCostModification[ShipClass[shipClass]];

        return value !== undefined ? value : 0;
    }
}