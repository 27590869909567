import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IAgentDetail } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    agent: IAgentDetail
}

export const AgentPositionSummary = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CelestialTypeSettings) {
        return null;
    }

    if (props.agent.celestialPosition !== undefined) {
        return <SolarSystemLink solarSystem={props.agent.celestialPosition.solarSystem} extra={CelestialHelper.fullname(props.agent.celestialPosition.celestial, worldState.CelestialTypeSettings)} />;
    }

    if (props.agent.fleetPosition !== undefined) {
        return <>
            Flying to {props.agent.fleetPosition.targetSolarSystem && <SolarSystemLink solarSystem={props.agent.fleetPosition.targetSolarSystem} />}
            {props.agent.fleetPosition.targetSolarSystem === undefined && "unknown"}
            {`. Estimated arrival: ${ValueFormatter.formatDateFromNowWithMomentOrTimeOrDate(props.agent.fleetPosition.arrivalDate)}.`}
        </>;
    }

    return <>Unknown</>;
});