import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { AgentStatus, CapturedAgent, IAgentMinimial } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { RelationHelper } from "../../../Helpers/RelationHelper";
import { agentById } from "../../../Navigation/Routing/Agents";
import { AgentStatusIcon } from "../Icons/AgentStatusIcon";

type Props = {
    agent: IAgentMinimial,
    hideIcons?: boolean
}

export const AgentLink = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    const agentPlayer = props.agent instanceof CapturedAgent ? {
        playerId: props.agent.playerId
    } : props.agent.player;

    const className = RelationHelper.relationClassName(agentPlayer, playerState.Player);

    const level: number | undefined | null = "level" in props.agent ? props.agent["level"] as number : undefined;
    const status: AgentStatus | undefined | null = "status" in props.agent ? props.agent["status"] as AgentStatus : undefined;

    return <span className={!props.hideIcons ? "link-with-icons" : ""}>
        {!props.hideIcons && status !== undefined && status !== null && <AgentStatusIcon status={status} />}
        <Link className={className} to={agentById(props.agent.agentId)}>
            {props.agent.name}
        </Link>
        {level !== undefined && level !== null && ` [${level}]`}
    </span>;
});