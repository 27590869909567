import { ValidationState } from "../../Components/Base/Form/ValidationHelper";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class StatusIcons {

    public get Success() { return MaterialDesignIcons.tick; }
    public get Warning() { return MaterialDesignIcons.alertRhombus; }
    public get Error() { return MaterialDesignIcons.alertCircle; }
    public get Info() { return MaterialDesignIcons.helpCircle; }
    public get Unread() { return MaterialDesignIcons.alertRhombus; }

    public validationState(state: ValidationState | undefined) {
        if (state === ValidationState.Valid) {
            return this.Success;
        } else if (state === ValidationState.Invalid) {
            return this.Error;
        }
        return undefined;
    }
}