import { observer } from 'mobx-react-lite';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../Components/Base/Form/Select';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { StageProps } from './StageRender';

export const FriendStage = observer((props: StageProps) => {

    const nextBlocked = props.joinWorldBag.World === undefined || (props.joinWorldBag.World.invitations.length > 1 && props.joinWorldBag.InvitationId === undefined);

    const player = props.joinWorldBag.Invitation && props.joinWorldBag.Invitation.player;

    function setInviteId(inviteId: number) {
        if (!isNaN(inviteId)) {
            props.joinWorldBag.setInvitationId(inviteId);
        }
    }

    return <SubPanel heading="Friend" footerContent={
        <ButtonHolder isPulledRight>
            <PreviousButton action={props.previousStage} />
            <NextButton action={props.nextStage} isDisabled={nextBlocked} />
        </ButtonHolder>
    }>
        <Paragraph>
            You have been invited to this world by multiple players. Who should get the reward for inviting you?
        </Paragraph>
        <FieldHolder label="Player">
            <Select className="is-fullwidth" valueChanged={e => setInviteId(Number(e))} defaultValue={props.joinWorldBag.InvitationId}
                showUnset={{ label: "Choose Player..." }}
                values={props.joinWorldBag.World!.invitations.map(w => {
                    return {
                        label: w.player.name,
                        value: w.friendInviteId
                    }
                })} />
        </FieldHolder>
        <br />
        {player !== undefined &&
            <Paragraph>
                {player.name} is rank {player.rank} and {player.federation === undefined ? "not a member of a Federation" : `a member of [${player.federation.shortName}]`}.
                {player.capitalSolarSystem !== undefined && ` Their capital solar system is "${player.capitalSolarSystem.name}" at ${player.capitalSolarSystem.x}, ${player.capitalSolarSystem.y}.`}
            </Paragraph>}
    </SubPanel>;
});