import classNames from "classnames";
import { IAgent } from "../../../ApplicationState/ApiClient";
import { agentPortraitsImageConfig } from '../../../images/AgentPortraitsImageConfig';
import { ImageSize } from "./SvgImage";

type Props = {
    agent: IAgent,
    size?: ImageSize
}

const singleKey = "Agents";

export const AgentPortrait = (props: Props) => {

    let variant = props.agent.agentId;

    while (variant >= agentPortraitsImageConfig[singleKey]) {
        variant -= agentPortraitsImageConfig[singleKey];
    }

    const src = `/images/agentPortrait/Agents_${variant}.jpg`;

    const className = classNames("image-holder",
        "agent-portrait",
        `size-${props.size}`
    );

    return <div className={className}>
        <img src={src} />
    </div>;
}