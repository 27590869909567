import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { PlayerDetail } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { BackNavButton } from "../../Components/Base/Buttons/BackButton";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { H2 } from "../../Components/Base/Text/H";
import { MarkdownControl } from "../../Components/Base/Text/MarkdownControl";
import { ContractForPlayerButton } from "../../Components/FusionShift/Buttons/ContractForPlayerButton";
import { InvitePlayerButton } from "../../Components/FusionShift/Buttons/InvitePlayerButton";
import { SendMessageButton } from "../../Components/FusionShift/Buttons/SendMessageButton";
import { SolarSystemsTable } from "../../Components/FusionShift/SolarSystems/SolarSystemsTable";
import { players } from "../../Navigation/Routing/Player";
import { AccoladeList } from "../Accolades/AccoladesList";
import { SimpleBaseView } from "../BaseView";
import { PlayerHeading } from "./PlayerHeading";
import { PlayerMessageBox } from "./PlayerMessageBox";
import { PlayerStats } from "./PlayerStats";

export const PlayerView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const { playerId } = useParams();

    const [player, setPlayer] = React.useState<PlayerDetail | undefined>(undefined);

    React.useEffect(() => {
        apiState.PlayerClient.getDetail(Number(playerId)).then(player => {
            if (player) {
                setPlayer(player);
            }
        });
    }, [playerId]);

    if (!player || !playerState.Player) {
        return <LoadingSpinner />;
    }

    function submitBio(markdown: string) {
        return apiState.PlayerClient.setPublicBio(markdown).then(player => {
            if (player) {
                setPlayer(player);
            }
            return player !== undefined;
        });
    }

    const isSelf = player.playerId === playerState.Player.playerId;

    return <SimpleBaseView heading={player.name}
        headingContent={
            <ButtonHolder>
                <ContractForPlayerButton player={player} />
                <InvitePlayerButton player={player} />
                <SendMessageButton player={player} />
                <BackNavButton to={players} />
            </ButtonHolder>
        }    >
        <PlayerMessageBox player={player} />
        <PlayerHeading player={player} />
        <MarkdownControl title="Public Bio" markdown={player.publicBio} showEdit={isSelf} hideIfEmpty={!isSelf} submitCallback={submitBio} />
        <PlayerStats player={player} world={worldState.World!} />
        {player.solarSystems && player.solarSystems.length > 0 &&
            <>
                <H2>Solar Systems</H2>
                <SolarSystemsTable solarSystems={player.solarSystems} />
            </>
        }
        <AccoladeList accolades={player.accolades} />
    </SimpleBaseView>;
});