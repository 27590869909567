import * as React from 'react';
import { ContractStatus } from '../../../ApplicationState/ApiClient';

type Props = {
    contractStatus: ContractStatus
}

function statusClassName(contractStatus: ContractStatus) {

    if (contractStatus === ContractStatus.Declined || contractStatus === ContractStatus.Expired || contractStatus === ContractStatus.Withdrawn) {
        return "has-text-danger";
    }

    if (contractStatus === ContractStatus.Accepted) {
        return "has-text-warning";
    }

    if (contractStatus === ContractStatus.Completed) {
        return "has-text-success";
    }

    return undefined;
}

export const ContractStatusDescription = (props: Props) => {

    const className = statusClassName(props.contractStatus)

    return <span className={className}>
        {ContractStatus[props.contractStatus]}
    </span>;
}