import React from "react";
import { ContractType } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { BaseProps, Icon } from "../../Base/Icon";

type Props = BaseProps & {
    contractType: ContractType
}

export const ContractTypeIcon = (props: Props) => {
    return <Icon icon={IconHelper.Economy.contractType(props.contractType)} {...props} />;
};