import * as React from 'react';
import { ExploreStats, WorldQuandrant, WorldRegion } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { Table } from '../../../Components/Base/Containers/Table';
import { TextHelper } from '../../../Helpers/TextHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    stats: ExploreStats,
    quadrant: WorldQuandrant | string,
    region: WorldRegion | string
}

const Cell = (props: Props) => {

    const stats: { explored: number, total: number } = React.useMemo(() => {

        let explored = 0;
        let total = 0;

        const quadrant = typeof props.quadrant !== "string" ? WorldQuandrant[props.quadrant] : props.quadrant;;
        const region = typeof props.region !== "string" ? WorldRegion[props.region] : props.region;

        if (quadrant in props.stats.quadrantRegionStats && region in props.stats.quadrantRegionStats[quadrant]!) {

            const stat = props.stats.quadrantRegionStats[quadrant]![region];

            if (stat !== undefined) {
                explored = stat.explored;
                total = stat.total;
            }
        } else if (quadrant in props.stats.quadrantRegionStats && !(region in props.stats.quadrantRegionStats[quadrant]!)) {
            const stats = props.stats.quadrantRegionStats[WorldQuandrant[props.quadrant]]!;

            for (let stat of Object.values(stats)) {
                explored += stat?.explored ?? 0;
                total += stat?.total ?? 0;
            }
        } else {
            if (region !== "Total") {

                for (let row of Object.values(props.stats.quadrantRegionStats)) {
                    const stat = region in row! ? row![region] : undefined;
                    total += stat?.total;
                    explored += stat?.explored;
                }
            } else {

                for (let row of Object.values(props.stats.quadrantRegionStats)) {
                    for (let stat of Object.values(row!)) {
                        total += stat!.total;
                        explored += stat!.explored;
                    }
                }
            }
        }

        return {
            explored,
            total
        };
    }, [props.stats, props.quadrant, props.region]);

    return <td className={props.region === "Total" ? "is-total" : ""}>
        <span className="is-hidden-mobile">
            {ValueFormatter.formatLocaleNumber(stats.explored)} / {ValueFormatter.formatLocaleNumber(stats.total)}{" - "}
        </span>
        {ValueFormatter.fromDecimalToDisplayPercent(stats.explored / stats.total)}
    </td>;
};

export const Exploration = () => {

    const apiState = React.useContext(ApiStateContext);

    const [exploreStats, setExploreStats] = React.useState<ExploreStats | undefined>(undefined);

    React.useEffect(() => {

        apiState.SolarSystemClient.getExploredStats().then(setExploreStats);

    }, []);

    if (exploreStats === undefined) {
        return null;
    }

    const rows = [
        WorldQuandrant.TopLeft,
        WorldQuandrant.TopRight,
        WorldQuandrant.BottomLeft,
        WorldQuandrant.BottomRight,
        "Total"
    ];

    const columns = [
        WorldRegion.Core,
        WorldRegion.Outer,
        WorldRegion.Expanse,
        "Total"
    ];

    return <>
        <Table isFullWidth heading={<>
            <th></th>
            {columns.map(x => <th key={x}>
                {typeof x === "string" && x}
                {typeof x !== "string" && WorldRegion[x]}
            </th>)}
        </>}>
            {rows.map(x => <tr key={x} className={x === "Total" ? "is-total" : ""}>
                <td>
                    {typeof x === "string" && x}
                    {typeof x !== "string" && TextHelper.tidyText(WorldQuandrant[x])}
                </td>
                {columns.map(y => <Cell key={y} quadrant={x} region={y} stats={exploreStats} />)}
            </tr>)}
        </Table>
    </>;
};