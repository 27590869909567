import { observer } from "mobx-react-lite";
import * as React from 'react';
import { PlayerMapEntry } from "../../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { FieldHolder } from "../../../../Components/Base/Form/FieldHolder";
import { Select } from "../../../../Components/Base/Form/Select";
import { Slider } from "../../../../Components/Base/Form/Slider";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { AcquireTargetClosestConfiguration, GalaxyMapData, TargetsRender } from "../Entities";
import { ClosestConfigurer } from "./ClosestConfigurer";
import { acquireTargets, createTargetsRender } from "./TargetAcquirer";
import { TargetTable } from "./TargetTable";

type Props = {
    data: GalaxyMapData,
    setRender: (render: TargetsRender | undefined) => any
}

export const TargetMode = observer((props: Props) => {

    const [federationId, setFederationId] = React.useState<number | undefined>(undefined);
    const [playerId, setPlayerId] = React.useState<number | undefined>(undefined);

    const [additionalDistance, setAdditionalDistance] = React.useState(0);

    const [closestConfig, setClosestConfig] = React.useState<AcquireTargetClosestConfiguration>({
        closest: "absolute"
    });

    React.useEffect(() => {

        setPlayerId(undefined);

    }, [federationId]);

    const players = React.useMemo(() => {

        const federation = props.data.map.federations.find(x => x.federationId === federationId);

        if (federation === undefined) {
            props.setRender(undefined);
            return undefined;
        }

        const p: PlayerMapEntry[] = [];

        for (const player of props.data.map.players) {
            if (federation.playerIds.includes(player.playerId)) {
                p.push(player);
            }
        }

        return p.sort((a, b) => a.name.localeCompare(b.name));

    }, [props.data, federationId]);

    const targets = React.useMemo(() => {

        if (federationId === undefined) {
            return undefined;
        }

        const config = {
            additionalDistance,
            federationId,
            playerId
        };

        const data = acquireTargets(props.data, config, closestConfig);

        const render = createTargetsRender(data, config);
        props.setRender(render);

        return data;

    }, [props.data, federationId, playerId, additionalDistance, closestConfig]);

    return <SubPanel
        heading={{
            text: "Target Acquisition",
            icon: IconHelper.Combat.Target
        }}>
        <div className="is-flex mobile-no-flex">
            <FieldHolder label="Federation">
                <Select
                    value={federationId}
                    valueChanged={s => setFederationId(Number(s))}
                    values={[
                        {
                            label: "Please select...",
                            value: "ANY"
                        },
                        ...props.data.map.federations
                            .sort((a, b) => a.shortName.localeCompare(b.shortName))
                            .map(x => {
                                return {
                                    value: x.federationId,
                                    label: `[${x.shortName}] ${x.name}`
                                }
                            })]
                    }
                />
            </FieldHolder>
            {players !== undefined &&
                <FieldHolder label="Player">
                    <Select
                        value={playerId}
                        valueChanged={s => setPlayerId(s === "ANY" ? undefined : Number(s))}
                        values={[
                            {
                                label: "Any",
                                value: "ANY"
                            },
                            ...players
                                .map(x => {
                                    return {
                                        value: x.playerId,
                                        label: x.name
                                    }
                                })]
                        }
                    />
                </FieldHolder>
            }
            <FieldHolder label="Additional Distance">
                <Slider
                    max={5}
                    min={0}
                    value={additionalDistance}
                    valueChanged={setAdditionalDistance}
                    hideValue
                />
            </FieldHolder>
        </div>
        <ClosestConfigurer config={closestConfig} setConfig={setClosestConfig} />
        {targets !== undefined &&
            <TargetTable targets={targets} />
        }
    </SubPanel>;
});