import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetTrade, IFleetTrade } from '../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { MarketOrderHelper } from '../../../Helpers/MarketOrderHelper';
import { MathHelper } from '../../../Helpers/MathHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { MarketOrderTable } from '../../Marketplace/Components/MarketOrderTable';
import { MarketTradeSummary } from '../../Marketplace/Components/MarketTradeSummary';
import { SetQuantityButtons } from '../Components/Items/SetQuantityButtons';
import { SendNowButton } from '../SendNowButton';
import { StageProps } from './StageRender';

export const TradeStage = observer((props: StageProps) => {

    React.useEffect(() => {
        if (props.sendShipsBag.TargetMarketOrder) {
            const data: IFleetTrade = {
                quantity: 0,
                lowestRatioAccepted: props.sendShipsBag.TargetMarketOrder.ratioOfferedToDesired,
                offeredItemTypeName: props.sendShipsBag.TargetMarketOrder.desiredItemTypeName,
                desiredItemTypeName: props.sendShipsBag.TargetMarketOrder.offeredItemTypeName
            };

            props.sendShipsBag.setTrade(new FleetTrade(data));
        }
    }, [props.sendShipsBag.TargetMarketOrder])

    React.useEffect(() => {
        const offered = props.sendShipsBag.Orders.trade ? props.sendShipsBag.Orders.trade.offeredItemTypeName : undefined;
        let max = 0;
        if (offered) {
            max = !!props.sendShipsBag.init.sourceSolarSystem.items && offered in props.sendShipsBag.init.sourceSolarSystem.items ? props.sendShipsBag.init.sourceSolarSystem.items[offered] : 0;
        }
        setMaxQuantity(max);
    }, [props.sendShipsBag.Orders.trade, props.sendShipsBag.TargetMarketOrder, props.sendShipsBag.Orders.trade && props.sendShipsBag.Orders.trade.offeredItemTypeName]);

    function changeRatio(value: number) {
        if (props.sendShipsBag.Orders.trade) {
            const clamped = MathHelper.clamp(value, props.sendShipsBag.init.gameSettings.marketplace.minimumRatio, props.sendShipsBag.init.gameSettings.marketplace.maximumRatio)
            const data: IFleetTrade = {
                quantity: props.sendShipsBag.Orders.trade.quantity,
                lowestRatioAccepted: clamped,
                offeredItemTypeName: props.sendShipsBag.Orders.trade.offeredItemTypeName,
                desiredItemTypeName: props.sendShipsBag.Orders.trade.desiredItemTypeName
            };
            props.sendShipsBag.setTrade(new FleetTrade(data));
        }
    }

    function changeQuantity(value: number) {
        if (props.sendShipsBag.Orders.trade) {
            const data: IFleetTrade = {
                quantity: value,
                lowestRatioAccepted: props.sendShipsBag.Orders.trade.lowestRatioAccepted,
                offeredItemTypeName: props.sendShipsBag.Orders.trade.offeredItemTypeName,
                desiredItemTypeName: props.sendShipsBag.Orders.trade.desiredItemTypeName
            };
            props.sendShipsBag.setTrade(new FleetTrade(data));
        }
    }

    const [maxQuantity, setMaxQuantity] = React.useState(0);

    const hypotheticalAmount = MarketOrderHelper.hypotheticallyAcceptMarketOrders(props.sendShipsBag.Orders.trade, props.sendShipsBag.TargetSolarSystem!.marketOrders!);
    const availableAmount = MarketOrderHelper.availableQuantityInMarketOrders(props.sendShipsBag.Orders.trade, props.sendShipsBag.TargetSolarSystem!.marketOrders!);

    const remainingCargoToSend = props.sendShipsBag.fleetWrapper.TotalCargoCapacity - props.sendShipsBag.totalToSendCargo;

    const fillAmount = availableAmount > remainingCargoToSend ? remainingCargoToSend : availableAmount;

    return <SubPanel heading="Trade"
        headingContent={<SendNowButton {...props} isSendNow />}
        footerContent={
            <ButtonHolder isPulledRight>
                <PreviousButton action={props.previousStage} />
                <NextButton action={props.nextStage} isDisabled={props.sendShipsBag.Orders.trade === undefined || props.sendShipsBag.Orders.trade.quantity <= 0} />
            </ButtonHolder>
        }>
        <MarketOrderTable marketOrders={props.sendShipsBag.TargetSolarSystem!.marketOrders} showLocation={false} customMarketOrderSelectedAction={m => props.sendShipsBag.setTargetMarketOrder(m)} />
        {!props.sendShipsBag.Orders.trade && <Paragraph type="prompt">Select a market order to use as the basis for trade.</Paragraph>}
        {props.sendShipsBag.Orders.trade &&
            <>
                <SplitLayout
                    left={<FieldHolder label="Trade Quantity" hasAddons>
                        <div className="control">
                            <NumberInput
                                size={5}
                                value={props.sendShipsBag.Orders.trade ? props.sendShipsBag.Orders.trade.quantity : 0}
                                valueChanged={v => changeQuantity(v)}
                            />
                        </div>
                        {maxQuantity > 0 && <>
                            <SetQuantityButtons
                                available={maxQuantity}
                                current={props.sendShipsBag.Orders.trade ? props.sendShipsBag.Orders.trade.quantity : 0}
                                cargoCapacityRemaining={fillAmount}
                                setValue={v => changeQuantity(v)}
                            />
                            <div className="control">
                                <div className="button is-static is-small">
                                    {ValueFormatter.formatLocaleNumber(maxQuantity)} available
                                </div>
                            </div>
                        </>}
                    </FieldHolder>
                    }
                    right={
                        <FieldHolder label="Ratio">
                            <div className="control">
                                <NumberInput
                                    size={5}
                                    precision={1}
                                    value={props.sendShipsBag.Orders.trade ? props.sendShipsBag.Orders.trade.lowestRatioAccepted : 0}
                                    valueChanged={value => changeRatio(value)}
                                />
                            </div>
                        </FieldHolder>
                    }
                />
                {!!hypotheticalAmount &&
                    <div>
                        <MarketTradeSummary quantityOfOffered={props.sendShipsBag.Orders.trade.quantity} offeredItemTypeName={props.sendShipsBag.Orders.trade.offeredItemTypeName} quantityOfDesired={hypotheticalAmount} desiredItemTypeName={props.sendShipsBag.Orders.trade.desiredItemTypeName} />
                    </div>
                }
                {hypotheticalAmount === 0 && props.sendShipsBag.Orders.trade.quantity > 0 &&
                    <div className="has-text-warning">
                        No market orders will trade at that ratio
                    </div>
                }
                {!!hypotheticalAmount && props.sendShipsBag.fleetWrapper.TotalCargoCapacity > 0 && hypotheticalAmount > props.sendShipsBag.fleetWrapper.TotalCargoCapacity &&
                    props.sendShipsBag.Orders.trade.desiredItemTypeName !== props.sendShipsBag.init.gameSettings.economy.creditsPlaceholderItemTypeName &&
                    <div className="has-text-warning">
                        Cargo capacity would be exceeded on return journey
                    </div>
                }
            </>
        }
    </SubPanel>;
});