import { ContractType } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    contractType: ContractType
}

const attack = [
    "Request that a target solar system is attacked",
    "Only one player may accept",
    "The contractor is paid when the fleet is dispatched",
    "The contractor is paid even if the attack is defeated",
    "The contractor is paid even if the attack is intercepted",
    "The owner will receieve a copy of the attacker's combat report",
    "The contract can't be withdrawn if someone has accepted it",
    "Fleets assigned to a contract can't be called home"
];

const recon = [
    "Request that a target solar system is reconned",
    "Many players can attempt the contract but only the first player to succesfully recon the target is paid",
    "The owner will receieve a copy of the successful attacker's combat report",
    "The contract can't be withdrawn if someone has accepted it",
    "Fleets assigned to a contract can't be called home"
];

const defend = [
    "Request that a target solar system is reinforced",
    "Many players can accept the contract and are paid proportionally to the defence they provide",
    "Contractors are paid when their fleets are dispatched",
    "Contractors are paid even if their fleets is intercepted",
    "Contractors are paid even if their fleets are destroyed in defence",
    "Fleets on contract will defend against allied attackers",
    "All defenders will be sent home at the end date",
    "The contract can't be withdrawn if someone has accepted it",
    "Fleets assigned to a contract can't be called home"
];

const courier = [
    "Request that goods are moved from one solar systems to another",
    "Many players can attempt the contract",
    "Contractors are paid upon collection of the items from the source system",
    "Contractor payment is limited by your available credits, the items in the source system, the cargo space of the collecting fleet and the quantity remaining on the contract at the time of collection",
    "The contract can be configured to periodically refresh the quantity to be transported",
    "Contractors can configure repeat fleets",
    "The contract can be withdrawn at any time but any fleets en-route to the source will finish their journey"
];

const ransom = [
    "Offer to buy a captured agent",
    "The contract can be offered to players other than the owner of the agent",
    "The ransom is paid upon collection of the agent from the target system",
    "The contract is completed upon collection of the agent from the target system",
    "The owner can withdraw the contract if it has not been completed"
];

const bribeForPolicy = [
    "Request that a player votes for a policy in a given way",
    "A specific player must be assigned the contract",
    "If you accept then your vote is cast immediately",
    "If you accept then you can't change your vote",
    "If you accept then you can accept another bribe for the same policy, returning the original bribe to the owner",
    "The owner can withdraw the contract if it has not been accepted"
];

const bribeForManifesto = [
    "Request that a player votes for a presidential manifesto",
    "A specific player must be assigned the contract",
    "If you accept then your vote is cast immediately",
    "If you accept then you can't change your vote",
    "If you accept then you can accept another bribe in the same presidential term, returning the original bribe to the owner",
    "The owner can withdraw the contract if it has not been accepted"
];

export const ContractTypeSpecificsForContractor = (props: Props) => {

    function getSpecifics() {
        switch (props.contractType) {
            case ContractType.Attack:
                return attack;
            case ContractType.Recon:
                return recon;
            case ContractType.Defend:
                return defend;
            case ContractType.Courier:
                return courier;
            case ContractType.BribeForManifesto:
                return bribeForManifesto;
            case ContractType.BribeForPolicy:
                return bribeForPolicy;
            case ContractType.Ransom:
                return ransom;
        }
    }

    const specifics = getSpecifics();

    return <SubPanel
        heading={{ text: ValueFormatter.friendlyNameForContractType(props.contractType), icon: IconHelper.Economy.contractType(props.contractType) }}
    >
        <div className="content">
            <ul>
                {specifics.map((x, i) => <li key={`${props.contractType}_${i}`}>
                    {x}
                </li>)}
            </ul>
        </div>
    </SubPanel>;
}