import { observer } from "mobx-react-lite";
import { reduce } from "ramda";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { EmblemLayer, EmblemPiece, InGameProduct } from "../../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../../Components/Base/Buttons/ButtonHolder";
import { SplitLayout } from "../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { Tab, TabMenuWithChildren } from "../../../../Components/Base/Containers/TabMenu";
import { Icon } from "../../../../Components/Base/Icon";
import { LoadingSpinner } from "../../../../Components/Base/Loading/LoadingSpinner";
import { ErrorMessageBox, WarningMessageBox } from "../../../../Components/Base/MessageBox";
import { Modal } from "../../../../Components/Base/Portal/Modal";
import { Paragraph } from "../../../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { premium_products } from "../../../../Navigation/Routing/Premium";
import { EmblemEditorContext } from "../../State/EmblemEditorContext";
import { BundleEmblemCards } from "../Components/BundleEmblemCards";
import { CategoryChooser } from "../Components/CategoryChooser";
import { CategoryEmblemCards } from "../Components/CategoryEmblemCards";
import { PremiumBundleChooser } from "../Components/PremiumBundleChooser";

enum BadgeTab {
  Categories,
  PremiumBundles
}

export const EmblemPickerPanel = observer(() => {

  const appState = React.useContext(AppStateContext);
  const apiState = React.useContext(ApiStateContext);

  const [purchasingState, setPurchasingState] = React.useState<"requesting" | "failed">();

  const [purchasingPremiumEmblem, setPurchasingPremiumEmblem] = React.useState<{ inGameProduct: InGameProduct, emblemPiece: EmblemPiece } | undefined>(undefined);
  const [purchasingPremiumBundle, setPurchasingPremiumBundle] = React.useState<InGameProduct | undefined>(undefined);

  const [selectedCategoryName, setSelectedCategoryName] = useState<string>("Basic");
  const [selectedPremiumBundleId, setSelectedPremiumBundleId] = useState<number | undefined>(undefined);

  const badgesTabs: Tab<BadgeTab>[] = appState.IsPremiumEnabled ?
    [
      { id: BadgeTab.Categories, title: "Categories", isAvailable: true },
      { id: BadgeTab.PremiumBundles, title: "Premium Bundles", isAvailable: true }
    ] :
    [
      { id: BadgeTab.Categories, title: "Categories", isAvailable: true }
    ];

  const [badgesTab, setBadgesTab] = React.useState<BadgeTab>(BadgeTab.Categories);

  const emblemEditorState = React.useContext(EmblemEditorContext);

  if (emblemEditorState.editingLayer === undefined) {
    return null;
  }

  if (!emblemEditorState.manifest) {
    return <SubPanel heading="Badges">
      <ErrorMessageBox text="Unable to load badges" />
    </SubPanel>;
  }

  const currentLayer = emblemEditorState.editingLayer;

  const getPremiumBundles = () =>
    emblemEditorState.premiumEmblemInGameProducts?.filter(x => !x.name!.toLowerCase().endsWith(".svg")) ?? [];

  const getPremiumBundle = (inGameProductId: number) =>
    getPremiumBundles().filter(x => x.inGameProductId == inGameProductId)[0];

  const getPremiumBundleEmblemPieces = (inGameProductId: number) => {
    const bundle = getPremiumBundle(inGameProductId);

    const bundleEmblemFilenames: string[] = JSON.parse(bundle.value!);

    return reduce(
      (emblemsAccumulator, emblemsValue) => emblemsAccumulator.concat(emblemsValue.filter(x => bundleEmblemFilenames.includes(x.fileName))),
      ([] as EmblemPiece[]),
      Object.values(emblemEditorState.manifest!.categories));
  }

  const hasPurchasedPremiumBundle = (inGameProductId: number) => {
    const premiumBundle = getPremiumBundle(inGameProductId);
    const premiumEmblems = getPremiumBundleEmblemPieces(inGameProductId);

    return premiumBundle.hasPurchased || premiumEmblems.every(x => hasPurchasedPremiumEmblem(x.fileName));
  };

  const buyPremiumBundle = async () => {
    if (appState.PremiumBalanceState.Balance >= purchasingPremiumBundle!.unitCost) {
      setPurchasingState("requesting");

      try {
        await apiState.PremiumClient.buyInGameProduct(purchasingPremiumBundle!.inGameProductId);
      }
      catch {
        setPurchasingState("failed");
        return;
      }

      await emblemEditorState.refreshPremiumEmblems();

      setPurchasingPremiumBundle(undefined);
      setPurchasingState(undefined);
    }
  };

  const getPremiumEmblems = () =>
    emblemEditorState.premiumEmblemInGameProducts?.filter(x => x.name!.toLowerCase().endsWith(".svg")) ?? [];

  const getPremiumEmblem = (filename: string) =>
    getPremiumEmblems().filter(x => x.name!.toLowerCase() === filename.toLowerCase())[0];

  const hasPurchasedPremiumEmblem = (filename: string) => {
    const premiumEmblem = getPremiumEmblem(filename);

    if (!premiumEmblem) {
      return undefined;
    }

    return premiumEmblem.hasPurchased || getPremiumBundles()
      .map(x => ({
        bundle: x,
        emblems: getPremiumBundleEmblemPieces(x.inGameProductId)
      }))
      .filter(x => x.emblems.some(x => x.fileName === filename))
      .some(x => x.bundle.hasPurchased);
  };

  const buyPremiumEmblem = async () => {
    if (appState.PremiumBalanceState.Balance >= purchasingPremiumEmblem!.inGameProduct.unitCost) {
      setPurchasingState("requesting");

      try {
        await apiState.PremiumClient.buyInGameProduct(purchasingPremiumEmblem!.inGameProduct.inGameProductId);
      }
      catch {
        setPurchasingState("failed");
        return;
      }

      await emblemEditorState.refreshPremiumEmblems();

      emblemEditorState.dispatchAlterLayer(
        EmblemLayer.fromJS({
          ...currentLayer,
          filename: purchasingPremiumEmblem!.emblemPiece.fileName,
        }),
        emblemEditorState.curentLayerIndex
      );

      setPurchasingPremiumEmblem(undefined);
      setPurchasingState(undefined);
    }
  }

  const getPremiumBundlePercentageSaving = (inGameProductId: number, emblems: EmblemPiece[]) => {
    const bundle = getPremiumBundle(inGameProductId);

    const unpurchasedEmblemsTotalCost = reduce(
      (premiumEmblemCostAccumulator, premiumEmblemCostValue) => premiumEmblemCostAccumulator + premiumEmblemCostValue,
      0,
      emblems.map(x => {
        const premiumEmblem = getPremiumEmblem(x.fileName);
        return premiumEmblem && !hasPurchasedPremiumEmblem(x.fileName) ? premiumEmblem.unitCost : 0
      }));

    const percentageSaving = !hasPurchasedPremiumBundle(bundle.inGameProductId) && unpurchasedEmblemsTotalCost !== 0
      ? (unpurchasedEmblemsTotalCost - bundle.unitCost) / unpurchasedEmblemsTotalCost * 100
      : 0;

    return percentageSaving;
  }

  const onEmblemBadgeClick = async (emblemPiece: EmblemPiece) => {
    const premiumEmblem = getPremiumEmblem(emblemPiece.fileName);

    if (premiumEmblem) {
      if (!hasPurchasedPremiumEmblem(emblemPiece.fileName)) {
        setPurchasingPremiumEmblem({ inGameProduct: premiumEmblem, emblemPiece: emblemPiece });
        appState.scrollToTop();
        return;
      }
    }

    emblemEditorState.dispatchAlterLayer(
      EmblemLayer.fromJS({
        ...currentLayer,
        filename: emblemPiece.fileName,
      }),
      emblemEditorState.curentLayerIndex
    );
  }

  return <>
    <SubPanel heading="Badges">
      <TabMenuWithChildren tabs={badgesTabs} changeTab={setBadgesTab} active={badgesTab}>
        {badgesTab === BadgeTab.Categories && <SplitLayout
          fitContent
          horizontalAfter="mobile"
          left={
            <CategoryChooser
              selectedCategoryName={selectedCategoryName}
              setSelectedCategory={setSelectedCategoryName}
              manifest={emblemEditorState.manifest}
            />
          }
          right={
            <div className={"template-selector"}>
              <div className={"badge-template-view"}>
                {currentLayer && (
                  <CategoryEmblemCards
                    manifest={emblemEditorState.manifest}
                    onEmblemBadgeClick={onEmblemBadgeClick}
                    getPremiumEmblem={getPremiumEmblem}
                    hasPurchasedPremiumEmblem={hasPurchasedPremiumEmblem}
                    selectedCategoryName={selectedCategoryName}
                    selectedEmblemFilename={currentLayer.filename}
                    emblemsCdnUrl={appState.ClientSettings?.emblemsCdnUrl} />)}
              </div>
            </div>
          }
        />}
        {badgesTab === BadgeTab.PremiumBundles && <SplitLayout
          fitContent
          horizontalAfter="mobile"
          left={
            <PremiumBundleChooser
              selectedPremiumBundleId={selectedPremiumBundleId ?? emblemEditorState.premiumEmblemInGameProducts?.filter(x => !x.name!.toLowerCase().endsWith(".svg"))[0]?.inGameProductId ?? 0}
              setSelectedPremiumBundleId={setSelectedPremiumBundleId}
              manifest={emblemEditorState.manifest}
              premiumBundles={getPremiumBundles()}
              hasPurchasedPremiumBundle={hasPurchasedPremiumBundle}
            />
          }
          right={<>
            <div className={"template-selector"}>
              <div className={"badge-template-view"}>
                {currentLayer && (
                  <BundleEmblemCards
                    manifest={emblemEditorState.manifest}
                    onEmblemBadgeClick={onEmblemBadgeClick}
                    getPremiumEmblem={getPremiumEmblem}
                    setPurchasingPremiumBundle={(inGameProduct: InGameProduct) => { setPurchasingPremiumBundle(inGameProduct); appState.scrollToTop(); }}
                    getPremiumBundlePercentageSaving={getPremiumBundlePercentageSaving}
                    premiumBundle={selectedPremiumBundleId ? getPremiumBundle(selectedPremiumBundleId) : getPremiumBundles()[0]}
                    hasPurchasedPremiumBundle={hasPurchasedPremiumBundle}
                    hasPurchasedPremiumEmblem={hasPurchasedPremiumEmblem}
                    getPremiumBundleEmblemPieces={getPremiumBundleEmblemPieces}
                    selectedEmblemFilename={currentLayer.filename}
                    emblemsCdnUrl={appState.ClientSettings?.emblemsCdnUrl} />
                )}
              </div>
            </div>
          </>}
        />}
      </TabMenuWithChildren>
    </SubPanel>
    {purchasingPremiumEmblem && <Modal
      title="Buy Premium Emblem"
      bodyTextAlign="left"
      bodyContent={() => {
        const containingPurchasableBundles = getPremiumBundles()
          .filter(x => !hasPurchasedPremiumBundle(x.inGameProductId))
          .map(x => ({
            bundle: x,
            emblems: getPremiumBundleEmblemPieces(x.inGameProductId)
          }))
          .filter(x => x.emblems.some(x => x.fileName === purchasingPremiumEmblem.emblemPiece.fileName))
          .map(x => ({
            bundle: x.bundle,
            percentageSaving: getPremiumBundlePercentageSaving(x.bundle.inGameProductId, x.emblems)
          }))
          .filter(x => x.percentageSaving > 0)
          .sort((x, y) => x.percentageSaving > y.percentageSaving ? -1 : 1);

        return <>
          {purchasingState === "requesting" && <LoadingSpinner />}
          {purchasingState === "failed" && <ErrorMessageBox text="Failed to purchase premium emblem" />}
          {!purchasingState && <>
            {containingPurchasableBundles.length > 0 && <Paragraph>
              Some bundles contain this emblem:<br />
              {containingPurchasableBundles.map(x =>
                <span key={x.bundle.inGameProductId}>Save {Math.round(x.percentageSaving)}% when you buy the "{x.bundle.name}" bundle<br /></span>)}
            </Paragraph>}
            <Paragraph>
              Name: {purchasingPremiumEmblem.emblemPiece.displayName}<br />
              Cost: <Icon icon={IconHelper.Premium.Currency} /> {purchasingPremiumEmblem.inGameProduct.unitCost}<br />
              Current balance: <Icon icon={IconHelper.Premium.Currency} /> {appState.PremiumBalanceState.Balance}
            </Paragraph>
            <img
              src={`${appState.ClientSettings?.emblemsCdnUrl}${purchasingPremiumEmblem.emblemPiece.fileName}`}
              alt={purchasingPremiumEmblem.emblemPiece.fileName} />
            {appState.PremiumBalanceState.Balance < purchasingPremiumEmblem.inGameProduct.unitCost && <>
              <WarningMessageBox text="You don't have enough balance to purchase this emblem." />
              <Paragraph>Visit the <Link to={premium_products} target="_blank" rel="noopener noreferrer">products</Link> page to top up your balance.</Paragraph>
            </>}
          </>}
        </>
      }}
      footerContent={() => <>
        <ButtonHolder isPulledRight>
          <Button
            type="action"
            isPulledRight
            text="Buy"
            icon={IconHelper.General.Confirm}
            action={() => buyPremiumEmblem()}
            isDisabled={!purchasingState && appState.PremiumBalanceState.Balance < purchasingPremiumEmblem.inGameProduct.unitCost} />
          <Button
            type="danger"
            isPulledRight
            text="Cancel"
            icon={IconHelper.General.Cancel}
            action={() => setPurchasingPremiumEmblem(undefined)}
            isDisabled={purchasingState === "requesting"} />
        </ButtonHolder>
      </>}
    />}
    {purchasingPremiumBundle && <Modal
      title="Buy Premium Bundle"
      bodyTextAlign="left"
      bodyContent={() => <>
        {purchasingState === "requesting" && <LoadingSpinner />}
        {purchasingState === "failed" && <ErrorMessageBox text="Failed to purchase premium emblem bundle" />}
        {!purchasingState && <>
          <Paragraph>
            Name: {purchasingPremiumBundle.name}<br />
            Cost: <Icon icon={IconHelper.Premium.Currency} /> {purchasingPremiumBundle.unitCost}<br />
            Current balance: <Icon icon={IconHelper.Premium.Currency} /> {appState.PremiumBalanceState.Balance}
          </Paragraph>
          {appState.PremiumBalanceState.Balance < purchasingPremiumBundle.unitCost && <>
            <WarningMessageBox text="You don't have enough balance to purchase this emblem bundle." />
            <Paragraph>Visit the <Link to={premium_products} target="_blank" rel="noopener noreferrer">products</Link> page to top up your balance.</Paragraph>
          </>}
        </>}
      </>}
      footerContent={() => <>
        <ButtonHolder isPulledRight>
          <Button
            type="action"
            isPulledRight
            text="Buy"
            icon={IconHelper.General.Confirm}
            action={() => buyPremiumBundle()}
            isDisabled={!purchasingState && appState.PremiumBalanceState.Balance < purchasingPremiumBundle.unitCost} />
          <Button
            type="danger"
            isPulledRight
            text="Cancel"
            icon={IconHelper.General.Cancel}
            action={() => setPurchasingPremiumBundle(undefined)}
            isDisabled={purchasingState === "requesting"} />
        </ButtonHolder>
      </>}
    />}
  </>;
});
