import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IFederation } from '../../../ApplicationState/ApiClient';
import { AppStateContext, PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { DismissButton } from '../../Base/Buttons/DismissButton';
import { MessageBoxWithIcon } from '../../Base/MessageBox';
import { Paragraph } from '../../Base/Text/Paragraph';
import { FederationLink } from '../Links/FederationLink';

function hideVictoryKey(worldId: number) {
    return `Hide_Victory_${worldId}`;
};

type InnerProps = {
    isVictiorious: boolean,
    victoriousFederation: IFederation
}

const InnerContent = (props: InnerProps) => {

    if (props.isVictiorious) {

        return <>
            <Paragraph type="unstyled">
                <FederationLink federation={props.victoriousFederation} /> have been declared rulers of the galaxy! Congratulations on your victory!
            </Paragraph>
            <Paragraph type="unstyled">Thank you for playing Fusion Shift.</Paragraph>
        </>;
    }

    return <>
        <Paragraph type="unstyled">
            <FederationLink federation={props.victoriousFederation} /> have been declared rulers of the galaxy! All hail <FederationLink federation={props.victoriousFederation} />!
        </Paragraph>
        <Paragraph type="unstyled">Better luck next time and thank you for playing Fusion Shift.</Paragraph>
    </>
};

type Props = {
    victor: IFederation,
    dismissCallback?: () => any
}

export const VictoryMessageWithEntity = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    const isVictiorious = playerState.Player.federation?.federationId === props.victor.federationId;

    return <MessageBoxWithIcon
        hasSuperGiantIcon
        type={isVictiorious ? "success" : "danger"}
        button={props.dismissCallback !== undefined && <DismissButton action={props.dismissCallback} isPulledRight />}
        text={
            <InnerContent isVictiorious={isVictiorious} victoriousFederation={props.victor} />
        }
        icon={IconHelper.General.Victory}>

    </MessageBoxWithIcon>;
});

export const VictoryMessage = observer(() => {

    const appState = React.useContext(AppStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [isHidden, setIsHidden] = React.useState(false);

    React.useEffect(() => {

        if (worldState.World !== undefined && worldState.World !== null) {
            setIsHidden(appState.getUserPrefsObjectOrDefault(hideVictoryKey(worldState.World.worldId), () => false))
        }
    }, [worldState.World?.worldId]);

    function dismiss() {
        appState.setUserPrefsObject(hideVictoryKey(worldState.World!.worldId), true);
        setIsHidden(true);
    }

    if (!worldState.VictoriousFederation || isHidden) {
        return null;
    }

    return <VictoryMessageWithEntity
        victor={worldState.VictoriousFederation}
        dismissCallback={dismiss}
    />;
});