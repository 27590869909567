import { ActivePresident, IPlayer, IPresidentialTerm, PolicyStatus } from "../ApplicationState/ApiClient";

export class PoliticsHelper {
    public static termStatus(presidentialTerm: IPresidentialTerm) {
        if (!presidentialTerm.hasVotesBeenCounted) {
            return "Voting";
        }

        if (new Date() < presidentialTerm.endDate) {
            return "Active";
        }

        return "Finished";
    }

    public static canProposePolicy(player: IPlayer | undefined, activePresident: ActivePresident) {
        if (player === undefined) {
            return null;
        }

        if (player.playerId !== activePresident.president.playerId) {

            return false;
        }

        return !activePresident.presidentialTerm.hasExecutiveOrderBeenUsed ||
            !activePresident.presidentialTerm.hasDemocraticReformationBeenUsed ||
            activePresident.presidentialTerm.policies.find(x => x.status === PolicyStatus.Voting) === undefined;
    }
}