import * as React from "react";
import { ValidationResult } from "../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { SuccessMessageBox } from "../../Components/Base/MessageBox";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";
import { AuthenticationWrapper } from "./AuthenticationWrapper";

export const ForgotPasswordView = () => {

    const apiState = React.useContext(ApiStateContext);

    const [emailAddress, setEmailAddress] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [submitted, setSubmitted] = React.useState(false);

    function forgotPassword() {
        return apiState.AuthenticationClient.forgotPassword(emailAddress.validText!).then(result => {
            setSubmitted(true);
        });
    }

    if (submitted) {
        return <SuccessMessageBox text="You should recieve an email with a link to reset your password in a few moments" />
    }

    return <AuthenticationWrapper>
        <SimpleBaseView heading="Forgot Password">
            <ValidatingStringInput
                label="Email Address"
                icon={IconHelper.Account.Email}
                valueChanged={setEmailAddress}
                validateDelay={0}
            />
            <div className="control">
                <Button type="action" text="Submit" icon={IconHelper.General.Confirm} action={() => forgotPassword()} isDisabled={!emailAddress.isValid} isPulledRight />
            </div>
        </SimpleBaseView>
    </AuthenticationWrapper>;
};