import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { HelpStateContext } from '../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { DiscordLink } from '../../../Components/FusionShift/Links/DiscordLink';
import { BugReportLink, FeedbackLink } from "../../../Components/FusionShift/Links/SurveyLinks";
import { TextHelper } from '../../../Helpers/TextHelper';
import { HelpTypesPages, help, help_page } from '../../../Navigation/Routing/Help';

export const Help = observer(() => {

    const helpState = React.useContext(HelpStateContext);

    if (!helpState.Help) {
        return <LoadingSpinner />;
    }

    return <SubPanel heading="Help">
        <Link to={help} >Help Index</Link>
        <hr />
        <SplitLayout
            left={
                <ul>
                    {HelpTypesPages.map(x => <li
                        key={x.to}>
                        <Link to={x.to} >
                            {x.title}
                        </Link>
                    </li>
                    )}
                </ul>
            }
            right={
                <ul>
                    {helpState.Help.map(d => <li key={d.name}>
                        <Link to={help_page(d.typeName.toLocaleLowerCase())}>{TextHelper.tidyText(d.name)}</Link>
                    </li>)}
                </ul>
            } />
        <hr />
        <ul>
            <li>
                <DiscordLink showIcon /></li>
            <li><FeedbackLink showIcon /></li>
            <li><BugReportLink showIcon /></li>
        </ul>
    </SubPanel>;
});