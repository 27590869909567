import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { FactionType } from "../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../ApplicationState/ContextRoot";
import { FactionTypeImage } from "../FusionShift/Images/FactionTypeImage";
import { FactionTypeLink } from "../FusionShift/Links/FactionTypeLink";
import { Icon } from "./Icon";

type Props = {
    label: string,
    content: React.ReactNode,
    icon: string | React.ReactNode,
    className?: string
}

type FactionProps = {
    factionType: FactionType | string
}

export const Badge = (props: Props) => {

    const className = classNames(
        "badge has-text-centered",
        props.className
    );

    const icon = typeof props.icon === "string" ? <Icon icon={props.icon} /> : props.icon;

    return <div className={className}>
        <div className="badge-container">
            <span className="icon">
                {icon}
            </span>
            <div className="text">
                <p className="heading">{props.label}</p>
                {props.content}
            </div>
        </div>
    </div>;
}

export const FactionBadge = observer((props: FactionProps) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.FactionTypeSettings) {
        return null;
    }

    const factionType = typeof props.factionType === "string" ? worldState.FactionTypeSettings.data[props.factionType] : props.factionType;

    return <Badge
        label="Faction"
        content={<FactionTypeLink factionType={factionType} />}
        icon={<FactionTypeImage
            factionTypeName={factionType.typeName}
            onlyLogo
            size="small"
        />}
    />
});

type BadgesProps = {
    children: React.ReactNode
}

export const Badges = (props: BadgesProps) => {

    return <div className="badges">
        {props.children}
    </div>
};