import { IItemTypeSettings, ISectorTypeSettings, ItemType, SectorType } from "../ApplicationState/ApiClient";

export type CommodityUsage = {
    itemType: ItemType,
    sectorType: SectorType,
    tier: number
}

export class CommodityHelper {

    _itemTypeSettings: IItemTypeSettings;
    _sectorTypeSettings: ISectorTypeSettings;

    constructor(itemTypeSettings: IItemTypeSettings, sectorTypeSettings: ISectorTypeSettings) {
        this._itemTypeSettings = itemTypeSettings;
        this._sectorTypeSettings = sectorTypeSettings;
    }

    private _isLoaded: boolean = false;
    private _parsed: CommodityUsage[] = [];

    private load(): CommodityUsage[] {
        if (this._isLoaded) {
            return this._parsed;
        }
        this._isLoaded = true;
        this._parsed = [];

        for (let itemTypeName of Object.keys(this._itemTypeSettings.data)) {
            const itemType = this._itemTypeSettings.data[itemTypeName];
            const found = this.findForCommodityType(itemType);
            if (found) {
                this._parsed.push(found);
            }
        }

        return this._parsed;
    }

    private findForCommodityType(itemType: ItemType): CommodityUsage | undefined {
        for (let sectorTypeName of Object.keys(this._sectorTypeSettings.data)) {
            const sectorType = this._sectorTypeSettings.data[sectorTypeName];

            if (sectorType.demandedItemTypeNames) {
                for (let tier of Object.keys(sectorType.demandedItemTypeNames)) {
                    if (sectorType.demandedItemTypeNames[tier] === itemType.typeName) {
                        return {
                            itemType: itemType,
                            sectorType: sectorType,
                            tier: Number(tier)
                        };
                    }
                }
            }
        }
        return undefined;
    }

    public commodityTypeUsage(itemTypeName: string): CommodityUsage | undefined {
        return this.load().find(c => c.itemType.typeName === itemTypeName);
    }

    public commoditiesForSectorType(sectorTypeName: string): CommodityUsage[] {
        return this.load().filter(c => c.sectorType.typeName === sectorTypeName)
            .sort((a, b) => a.tier > b.tier ? 1 : -1);
    }

    public allCommodities(): CommodityUsage[] {
        return this.load();
    }
}