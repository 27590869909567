import classNames from "classnames";
import React from "react";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SimpleTimeSpan } from "../../../Entities/Shared";
import { IconHelper } from "../../../Helpers/IconHelper";
import { TimeHelper } from "../../../Helpers/TimeHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    date: Date,
    ticksDown?: boolean,
    alwaysCompressed?: boolean,
    expiredContent?: React.ReactNode | undefined,
    isPulledRight?: boolean,
    className?: string
}

export const DateIcon = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [timespan, setSimpleTimeSpan] = React.useState<SimpleTimeSpan | undefined>(undefined);

    React.useEffect(() => {
        if (props.ticksDown && props.date.getTime() > Date.now()) {
            const interval = setInterval(() => {
                setSimpleTimeSpan(TimeHelper.createTimeSpanFromNow(props.date));
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [worldState]);

    if (timespan === undefined) {
        setSimpleTimeSpan(TimeHelper.createTimeSpanFromNow(props.date));
    }

    const classname = classNames(
        props.className,
        props.isPulledRight && "is-pulled-right"
    );

    if (props.date.getTime() <= Date.now()) {
        if (props.expiredContent) {
            return <span className={classname}>{props.expiredContent}</span>
        }

        return null;
    }

    const compress = props.alwaysCompressed || props.ticksDown;

    return <span className={classname}>
        <Icon icon={IconHelper.General.Time} className="is-hidden-mobile" />
        {!compress && TimeHelper.formatTimeSpanByWords(timespan)}
        {compress && TimeHelper.formatTimeSpanByNumbers(timespan)}
    </span>;
};

DateIcon.defaultProps = {
    ticksDown: true
};