import classNames from "classnames";
import * as React from "react";
import { ReactNode } from "react";

type Props = {
    children: ReactNode,
    className?: string | undefined,
    hasAddons?: boolean
}

export const ControlHolder = (props: Props) => {

    const children = React.Children.toArray(props.children);

    if (!children || children.length === 0) {
        return null;
    }

    const classes = classNames(
        "field",
        props.className,
        {
            "has-addons": props.hasAddons
        });

    return <div className={classes}>
        {children.map((c, i) =>
            <div key={i} className="control">{c}</div>
        )}
    </div>;
};
