import React from 'react';
import { AgentActionType } from '../../../../ApplicationState/ApiClient';
import { ItemTypeIconFromItemTypeName } from '../../../../Components/FusionShift/Icons/Items/ItemTypeIcon';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';

type Props = {
    strength: number,
    actionType: AgentActionType
}

function makeEffect(label: string, value: number | undefined,
    render?: (value: string) => React.ReactNode,
    format?: (value: number) => string) {
    return {
        label,
        value,
        format: format === undefined ? x => ValueFormatter.fromDecimalToDisplayPercent(x, false, 2, true) : format,
        render: render === undefined ? x => x : render
    };
}

export function calculateEffects(actionType: AgentActionType, strength: number) {

    const fromResources = actionType.resourceHarvestingModifierPercent === undefined ? [] :
        Object.keys(actionType.resourceHarvestingModifierPercent).map(x => makeEffect(
            "Resource Harvesting",
            actionType.resourceHarvestingModifierPercent![x],
            value => <>
                <ItemTypeIconFromItemTypeName itemTypeName={x} />
                {value}
            </>
        ));


    return [
        ...fromResources,
        makeEffect("Agent Action Cost", actionType.agentActionCostModifierPercent),
        makeEffect("Agent Capture Chance", actionType.agentActionCaptureChanceModifierPercent),
        makeEffect("Agent Action Success Chance", actionType.agentActionSuccessChanceModifierPercent),
        makeEffect("Manufactory Output", actionType.manufactoryOutputModifierPercent),
        makeEffect("Radar Range", actionType.radarRangeModification, undefined, ValueFormatter.formatLocaleNumber),
        makeEffect("System Defence", actionType.solarSystemDefenceModifierPercent),

    ].filter(x => x.value !== null && x.value !== undefined && x.value != 0)
        .map(x => {
            return {
                label: x.label,
                effect: x.render(x.format(x.value! * strength)),
                value: x.value
            }
        });
}

export const EffectDetails = (props: Props) => {

    const effects = React.useMemo(() => {

        return calculateEffects(props.actionType, props.strength);

    }, [props.strength, props.actionType]);

    if (effects.length === 0) {
        return null;
    }

    return <>
        {effects.map(x => <tr key={x.label}>
            <td>
                {x.label}
            </td>
            <td className={props.actionType.strengthModifier !== undefined && props.strength < props.actionType.strengthModifier.optimal ? "has-text-warning" : ""}>
                {x.effect}
            </td>
        </tr>)}
    </>;
};