import { CelestialFeatureIcon } from '../../../../../Components/FusionShift/Icons/CelestialFeatureIcon';
import { PopulationSummary } from '../Megacity/PopulationSummary';
import { SummaryTile } from './SummaryTile';
import { SummaryTileProps } from './SummaryTileProps';

export const MegacityTile = (props: SummaryTileProps) => {

    if (!props.celestial.megacity) {
        return null;
    }

    return <SummaryTile
        {...props}
        title="Megacity"
        feature="Megacity"
        thumbnail={<CelestialFeatureIcon celestialFeature={"Megacity"} isGiant />}
    >
        <PopulationSummary megacity={props.celestial.megacity} sectorTypeSettings={props.sectorTypeSettings} />
    </SummaryTile>;
};