import * as React from 'react';
import { ValidationResult } from '../../../ApplicationState/ApiClient';
import { Paragraph } from '../Text/Paragraph';
import { FieldHolder } from './FieldHolder';
import { TextAreaInput } from './TextAreaInput';
import { ValidationHelper, ValidationState } from './ValidationHelper';

type Props = {
    label: string,
    initialValue?: string | undefined,
    rows?: number,
    placeholder?: string | undefined,
    validateDelay?: number,
    validation?: (value: string) => Promise<ValidationResult>,
    valueChanged: (value: ValidationResult) => any,
    maxLength?: number
}

export const ValidatingTextInput = (props: Props) => {

    const [timeoutRef, setTimeoutRef] = React.useState<NodeJS.Timeout | null>(null);
    const [validationState, setValidationState] = React.useState(ValidationState.None);
    const [value, setValue] = React.useState(props.initialValue || "");
    const [error, setError] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (value.length > 0) {
            setValidationState(ValidationState.Loading);
            if (props.validateDelay && props.validateDelay > 0) {
                if (timeoutRef) {
                    clearTimeout(timeoutRef);
                }
                setTimeoutRef(global.setTimeout(validate, props.validateDelay));
            } else {
                validate();
            }
        } else {
            setValidationState(ValidationState.None);
        }
    }, [value]);

    function validate() {
        if (props.validation === undefined) {
            isValid();
        } else {
            const promise = props.validation(value);
            promise.then(result => {
                if ((result.error && result.error.length > 0) || !result.isValid) {
                    isInvalid(result.error);
                } else {
                    isValid();
                }
            });
        }
    }

    function isValid() {
        setError(undefined);
        setValidationState(ValidationState.Valid);
        props.valueChanged(ValidationHelper.valid(value));
    }

    function isInvalid(error: string | undefined) {
        setError(error);
        setValidationState(ValidationState.Invalid);
        props.valueChanged(ValidationHelper.invalid(error));
    }

    const className = ValidationHelper.validationStateClassName(validationState);

    return <FieldHolder label={props.label} growToFitContent>
        <TextAreaInput
            value={value}
            valueChanged={v => setValue(v)}
            className={className}
            rows={props.rows}
            characterLimit={props.maxLength}
        />
        {!!error && <Paragraph type="danger">{error}</Paragraph>}
    </FieldHolder>;
};

ValidatingTextInput.defaultProps = {
    validateDelay: 1000,
    rows: 20
};