import * as React from 'react';
import { DefendTerms, IContract } from '../../../../ApplicationState/ApiClient';
import { CreditsIconWithQuantity } from '../../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { SolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';

type Props = {
    contract: IContract,
    terms: DefendTerms
}

export const DefendTermsDetail = (props: Props) => {

    return <div className="content">
        <ul >
            <li>Reinforce <SolarSystemLink solarSystem={props.contract.targetSolarSystem!} /></li>
            <li>Arrive before {ValueFormatter.formatTimeOrDate(props.terms.arriveByDate, true)}</li>
            <li>Payment is <CreditsIconWithQuantity quantity={props.terms.paymentPerDefencePower} /> per defence power</li>
            <li>Surviving reinforcing fleets will be sent home on  {ValueFormatter.formatTimeOrDate(props.terms.untilDate, true)}</li>
            <li>A total of {ValueFormatter.formatLocaleNumber(props.terms.defencePowerRequired)} defence is required</li>
        </ul>
    </div>;
};