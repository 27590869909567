import * as React from 'react';
import { ISectorTypeSettings, Megacity } from '../../../../../ApplicationState/ApiClient';
import { DictionaryTable } from '../../../../../Components/Base/Containers/Table';
import { ProgressBar } from '../../../../../Components/Base/ProgressBar';
import { TextAndIcon } from '../../../../../Components/Base/Text/TextAndIcon';
import { SectorTypeIcon } from '../../../../../Components/FusionShift/Icons/SectorTypeIcon';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    sectorTypeSettings: ISectorTypeSettings
    megacity: Megacity,
    hideSectors?: boolean
}

export const PopulationSummary = (props: Props) => {

    const content: { [key: string]: React.ReactNode } = {
        "Population": <ProgressBar
            leftIcon={IconHelper.Megacities.Population}
            uncoloured
            max={props.megacity.maximumPopulation}
            value={props.megacity.currentPopulation}
            text={`${ValueFormatter.formatPopulation(props.megacity.currentPopulation)} / ${ValueFormatter.formatPopulation(props.megacity.maximumPopulation)}`}
        />,
        "Unassigned": ValueFormatter.formatPopulation(props.megacity.unassignedPopulation)
    };

    if (!props.hideSectors && props.megacity.sectors) {
        for (let s of Object.keys(props.megacity.sectors)) {
            content[props.sectorTypeSettings.data[s].typeName] = <TextAndIcon text={`${ValueFormatter.formatPopulation(props.megacity.sectors[s].assignedPopulation)} / ${ValueFormatter.formatPopulation(props.megacity.sectors[s].maximumPopulation)}`}
                icon={<SectorTypeIcon sectorTypeName={s} />} />;
        }
    }

    return <DictionaryTable
        isFullWidth
        content={content}
    />;
};