import classNames from "classnames";
import * as React from "react";
import { CelestialSize, CelestialType } from "../../../ApplicationState/ApiClient";
import { AppStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { TextHelper } from "../../../Helpers/TextHelper";
import { celestial_summary } from "../../../Navigation/Routing/SolarSystem";
import { ContentWithLabel } from "../../Base/Containers/ContentWithLabel";

type ICelestialDuck = {
    celestialId: number;
    name: string;
    celestialTypeName?: string | undefined;
    size: CelestialSize | "image-medium";
}

type Props = {
    celestial: ICelestialDuck,
    style?: any,
    forcedSize?: CelestialSize | "image-medium" | undefined,
    className?: string | undefined,
    isReal?: boolean,
    isLink?: boolean,
    actualSize?: boolean
}

type CelestialTypeImageProps = MinimalBaseProps & {
    celestialType: CelestialType
}

export const CelestialImage = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    const onClick = props.isLink ? () => appState.navigate(celestial_summary(props.celestial.celestialId)) : undefined;

    return <CelestialImageBase
        variationSource={props.celestial.celestialId.toString()}
        style={props.style}
        celestialTypeName={props.celestial.celestialTypeName}
        size={props.forcedSize ? props.forcedSize : props.celestial.size}
        className={props.className}
        isReal={props.isReal}
        onClick={onClick}
    />;
};

type MinimalBaseProps = {
    size?: CelestialSize | "image-medium" | undefined,
    style?: any,
    className?: string | undefined,
    forcedVariation?: number | undefined,
    isReal?: boolean,
}

type BaseProps = MinimalBaseProps & {
    celestialTypeName: string | undefined,
    variationSource: string,
    onClick?: (() => any) | undefined
}

export const CelestialImageBase = (props: BaseProps) => {

    const variation = props.forcedVariation != undefined ? props.forcedVariation : TextHelper.hashTextToNumber(props.variationSource, 5);

    const sizeClassName = props.size === "image-medium" ? "image-medium" : CelestialSize[props.size ? props.size : CelestialSize.Tiny].toLocaleLowerCase();
    const variationClassName = `variation-${variation}`;

    const className = classNames(
        "celestial",
        sizeClassName,
        (props.celestialTypeName ?? "unknown").toLocaleLowerCase(),
        variationClassName,
        props.className,
        {
            "is-real": props.isReal,
            "is-clickable": props.onClick !== undefined
        });

    return <div
        onClick={props.onClick}
        title={props.celestialTypeName}
        style={props.style}
        className={className}
    />;
};

export const CelestialImageWithLabel = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const c = props.celestial;
    const typeName = c.celestialTypeName === undefined ? "Unknown" :
        worldState.CelestialTypeSettings === undefined ? c.celestialTypeName : worldState.CelestialTypeSettings.data[c.celestialTypeName].name;


    return <ContentWithLabel label={`${c.name}: ${CelestialSize[c.size]} ${typeName}`}>
        <CelestialImageBase
            variationSource={c.celestialId.toString()}
            celestialTypeName={c.celestialTypeName}
            size={!props.actualSize ? "image-medium" : c.size}
        />
    </ContentWithLabel>;
};

export const CelestialTypeImageWithLabel = (props: CelestialTypeImageProps) => {

    return <ContentWithLabel label={props.celestialType.name}>
        <CelestialImageBase {...props} celestialTypeName={props.celestialType.typeName} variationSource={props.celestialType.typeName} />
    </ContentWithLabel>;
}