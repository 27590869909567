import { IInstallationTypeSettings, IItemTypeSettings, ISchematicTypeSettings, SchematicCategory } from "../../../../ApplicationState/ApiClient"
import { Table } from "../../../../Components/Base/Containers/Table"
import { Paragraph } from "../../../../Components/Base/Text/Paragraph"
import { ItemIconWithQuantity } from "../../../../Components/FusionShift/Icons/Items/ItemIconWithQuantity"
import { InstallationTypeImage } from "../../../../Components/FusionShift/Images/InstallationTypeImage"
import { CostControl } from "../../../../Components/FusionShift/Items/CostControl"
import { InstallationTypeLink } from "../../../../Components/FusionShift/Links/InstallationTypeLink"
import { helpLink } from "../../../../Navigation/Routing/Help"

type Props = {
    itemTypeSettings: IItemTypeSettings,
    schematicTypeSettings: ISchematicTypeSettings,
    installationTypeSettings: IInstallationTypeSettings
}

export const ResourcesSchematicsTable = (props: Props) => {

    const schematics = Object.values(props.schematicTypeSettings.data)
        .filter(x => x.category === SchematicCategory.ResourceReaction)
        .map(x => {

            return {
                schematic: x,
                input: x.costPerRun,
                output: props.itemTypeSettings.data[x.producedItemTypeName],
                installations: Object.values(props.installationTypeSettings.data)
                    .filter(y => y.canProduceFromSchematicTypeNames.includes(x.typeName))
            };
        });

    return <>
        <Paragraph>
            Raw resources can be processed into usable resources via schematics at certain installation. See the {helpLink("installations")} help page for more information.
        </Paragraph>
        <Table
            isFullWidth
            heading={<>
                <th>
                    Schematic
                </th>
                <th>
                    Input
                </th>
                <th>
                    Output
                </th>
                <th colSpan={2}>
                    Installation
                </th>
            </>}
        >
            {schematics.map(x => <tr key={x.schematic.typeName}>
                <td>
                    {x.schematic.name}
                </td>
                <td>
                    <CostControl
                        cost={x.input}
                        showFullName
                    />
                </td>
                <td>
                    <ItemIconWithQuantity
                        itemType={x.output}
                        quantity={x.schematic.producedPerRun}
                        showFullName
                    />
                </td>
                <td>
                    {x.installations.map(y => <InstallationTypeLink installationType={y} />)}
                </td>
                <td>

                    {x.installations.map(y => <InstallationTypeImage size="tiny" installationType={y} />)}
                </td>
            </tr>
            )}
        </Table>
    </>;
}