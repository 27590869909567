import { toast } from 'react-toastify';
import { missions } from "../../Navigation/Routing/Help";
import { combatReport, message, playerNotification } from "../../Navigation/Routing/NotificationsEtc";
import { selected_system } from '../../Navigation/Routing/SolarSystem';
import { ApocalypseShot, BalanceChanged, CombatReportNotification, CreditBalanceChangedNotification, FederationVictoriousNotification, InstallationBuildQueueItemCompleteNotification, MessageNotification, MissionCompleteNotification, Notification, PlayerChangedNotification, PlayerNotificationNotification, PlayerSettingsUpdated, SolarSystemChangedNotification, SolarSystemsChangedNotification, SubscriptionStateChanged } from "../ApiClient";
import { AppState } from "../States/AppState";
import { NotificationRender } from './NotificationRender';

function map<T>(deserialize: (json: any) => T, then: (data: T) => any) {
    return args => {
        const data = deserialize(args);

        then(data);
    };
};

function mapWithNotification<T extends Notification>(appState: AppState, deserialize: (json: any) => T, then: (data: T) => any, target: (data: T) => string) {

    return map(deserialize, data => {

        then(data);

        if (data !== undefined) {

            const onClick = () => {
                const t = target(data);
                appState.navigate(t);
            };

            const options = {
                onClick,
                autoClose: 8000
            };

            toast(<NotificationRender notification={data} />, options);
        }
    });
};

export function createMappings(appState: AppState) {

    return {
        "CombatReportNotification": mapWithNotification<CombatReportNotification>(appState, CombatReportNotification.fromJS,
            _ => appState.UnreadState.addUnread("combat"),
            x => combatReport(x.combatReportId)),

        "PlayerNotificationNotification": mapWithNotification<PlayerNotificationNotification>(appState, PlayerNotificationNotification.fromJS,
            _ => appState.UnreadState.addUnread("playernotification"),
            x => playerNotification(x.playerNotificationId)),

        "MessageNotification": mapWithNotification<MessageNotification>(appState, MessageNotification.fromJS,
            _ => appState.UnreadState.addUnread("message"),
            x => message(x.messageId)),

        "MissionCompleteNotification": mapWithNotification<MissionCompleteNotification>(appState, MissionCompleteNotification.fromJS,
            _ => {
                appState.UnreadState.addUnread("mission");
                appState.ApiState.PlayerMissionLogClient.get().then(missionLog => {
                    if (missionLog) {
                        appState.MissionLogState.loadMissionLog(missionLog);
                    }
                });
            },
            _ => missions),

        "InstallationBuildQueueItemCompleteNotification": mapWithNotification<InstallationBuildQueueItemCompleteNotification>(appState, InstallationBuildQueueItemCompleteNotification.fromJS,
            _ => { },
            x => {
                appState.changeSolarSystemId(x.solarSystemId);
                return selected_system;
            }),

        "BalanceChanged": map<BalanceChanged>(BalanceChanged.fromJS, p => {
            appState.PremiumBalanceState.loadBalance(p.balance);
        }),

        "SubscriptionStateChanged": map<SubscriptionStateChanged>(SubscriptionStateChanged.fromJS, p => {
            // handle this
        }),

        "PlayerChangedNotification": map<PlayerChangedNotification>(PlayerChangedNotification.fromJS, p => {
            appState.reloadPlayer();
        }),

        "SolarSystemsChangedNotification": map<SolarSystemsChangedNotification>(SolarSystemsChangedNotification.fromJS, p => {
            appState.reloadSolarSystems();
        }),

        "SolarSystemChangedNotification": map<SolarSystemChangedNotification>(SolarSystemChangedNotification.fromJS, p => {
            appState.reloadSolarSystemById(p.solarSystemId);
        }),

        "CreditBalanceChangedNotification": map<CreditBalanceChangedNotification>(CreditBalanceChangedNotification.fromJS, p => {
            appState.CreditsState.loadCredits(p.balance, p.perHour);
        }),

        "ApocalypseShot": map<ApocalypseShot>(ApocalypseShot.fromJS, p => {
            appState.SolarSystemState.addApocalypseShot(p)
        }),

        "PlayerSettingsUpdated": map<PlayerSettingsUpdated>(PlayerSettingsUpdated.fromJS, p => {
            appState.PlayerState.PlayerSettings = p.playerSettings;
        }),

        "FederationVictoriousNotification": map<FederationVictoriousNotification>(FederationVictoriousNotification.fromJS, p => {
            appState.WorldState.loadVictoriousFederation(p.federation);
        })
    };
};