import { observer } from "mobx-react-lite";
import * as React from "react";
import { RequestResponseOfPlayerForSelf, SecurityStatus } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, CreditsStateContext, PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { ErrorMessageBox, SuccessMessageBox } from "../../../Components/Base/MessageBox";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { CreditsIconWithQuantityAndAffordability } from "../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity";
import { IconHelper } from "../../../Helpers/IconHelper";
import { PlayerHelper } from "../../../Helpers/PlayerHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type Props = {
    reloadCallback: () => any
}

export const RedemptionForm = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);
    const creditsState = React.useContext(CreditsStateContext);

    const [result, setResult] = React.useState<RequestResponseOfPlayerForSelf | undefined>(undefined);

    function redeem() {
        apiState.PlayerClient.redeemSecurityStatus().then(x => {
            setResult(x);
            if (x.wasSuccess && x.result) {
                playerState.loadPlayerOnly(x.result);
                props.reloadCallback();
            }
        })
    }

    if (!playerState.Player || !worldState.World || !worldState.GameSettings) {
        return null;
    }

    const cost = PlayerHelper.redemptionCost(playerState.Player, worldState.World, worldState.GameSettings);

    const canAfford = cost <= creditsState.Balance;
    const isCorrectStatus = playerState.Player.securityStatus === SecurityStatus.Exile;

    const can = canAfford && isCorrectStatus;

    return <SubPanel
        heading={{
            text: "Redeem Security Status",
            icon: IconHelper.Security.Redemption
        }}
        footerContent={<Button
            type="action"
            action={redeem}
            icon={IconHelper.General.Confirm}
            text="Redeem security status"
            isDisabled={!can}
            isPulledRight
        />}
    >
        {result && result.wasSuccess && <SuccessMessageBox text="Security status redeemed" />}
        {result && !result.wasSuccess && <ErrorMessageBox text={`Failed to redeem security status: ${result.error}`} />}

        <Paragraph>
            Redeeming security status in week {worldState.World.weekNumber} will cost you <CreditsIconWithQuantityAndAffordability quantity={cost} />. You have paid for redemption {playerState.Player.numberOfTimesRedeemed} {ValueFormatter.pluralize("time", playerState.Player.numberOfTimesRedeemed)}.
        </Paragraph>
        {!isCorrectStatus && <Paragraph type="danger">
            You must be an exile to pay for security status redemption.
        </Paragraph>}
    </SubPanel>;
});