import { makeAutoObservable } from "mobx";
import { IPlayerForSelf, PlayerSettings, PlayerSettingsPremiumInGameProductNames } from "../ApiClient";

export class PlayerState {

    Player: IPlayerForSelf | undefined;
    PlayerSettings: PlayerSettings | undefined;
    PlayerSettingsPremiumInGameProductNames: PlayerSettingsPremiumInGameProductNames | undefined;

    IsNewRegistration: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public loadPlayer(
        player: IPlayerForSelf | undefined,
        playerSettings: PlayerSettings | undefined,
        playerSettingsPremiumInGameProductNames: PlayerSettingsPremiumInGameProductNames | undefined) {

        this.Player = player;
        this.PlayerSettings = playerSettings;
        this.PlayerSettingsPremiumInGameProductNames = playerSettingsPremiumInGameProductNames;

        if (player === undefined) {
            this.IsNewRegistration = false;
        }
    }

    public loadPlayerOnly(player: IPlayerForSelf | undefined) {

        this.Player = player;
    }

    public setNewRegistration(isNew: boolean) {
        this.IsNewRegistration = isNew;
    }
}