import React from "react"
import { Link } from "react-router-dom"
import { ItemType, SchematicType, ShipType } from "../../../../ApplicationState/ApiClient"
import { Table } from "../../../../Components/Base/Containers/Table"
import { Paragraph } from "../../../../Components/Base/Text/Paragraph"
import { ComponentTypeLink } from "../../../../Components/FusionShift/Links/Items/ComponentTypeLink"
import { ItemTypeLink } from "../../../../Components/FusionShift/Links/Items/ItemTypeLink"
import { ShipTypeLink } from "../../../../Components/FusionShift/Links/ShipTypeLink"
import { help_shipTypes } from "../../../../Navigation/Routing/Help"

type Props = {
    componentSchematics: {
        schematic: SchematicType,
        outputItem: ItemType,
        inputItems: ItemType[],
        ships: ShipType[]
    }[]
}

export const ComponentsSchematicsTable = (props: Props) => {

    return <>
        <Paragraph>
            Some advanced ship types require complicated components. See the <Link to={help_shipTypes}>ships</Link> help pages for more information.
        </Paragraph>
        <Table isFixed isFullWidth
            heading={<>
                <th>
                    Resources
                </th>
                <th>
                    Produces
                </th>
                <th>
                    Ships
                </th>
            </>}
        >
            {props.componentSchematics.map(x => <React.Fragment key={x.schematic.typeName}>
                <tr>
                    <td>
                        <ul>
                            {x.inputItems.map(y => <li key={y.typeName}>
                                <ItemTypeLink itemType={y} /> x{x.schematic.costPerRun.items[y.typeName]}
                            </li>)}
                        </ul>
                    </td>
                    <td>
                        <ComponentTypeLink componentType={x.outputItem} />
                    </td>
                    <td>
                        <ul>
                            {x.ships.map(y => <li key={y.typeName}>
                                <ShipTypeLink shipType={y} /> x{y.cost.items[x.outputItem.typeName]}
                            </li>)}
                        </ul>
                    </td>
                </tr>
            </React.Fragment>
            )}
        </Table>
    </>;
}