import classNames from 'classnames';
import * as React from 'react';
import { Icon } from '../Icon';

type Props = {
    text: React.ReactNode,
    mobileText?: React.ReactNode,
    icon: string | React.ReactNode,
    hideTextOnMobile?: boolean,
    noWrap?: boolean,
}

export const TextAndIcon = (props: Props) => {

    const className = classNames(
        props.noWrap && "has-nowrap"
    );

    return <span className={className}>
        {typeof props.icon === "string" && <Icon icon={props.icon} />}
        {typeof props.icon !== "string" && props.icon}

        {props.mobileText !== undefined && <>
            <span className="is-hidden-tablet">
                {props.mobileText}
            </span>
            <span className="is-hidden-mobile">
                {props.text}
            </span>
        </>}
        {props.mobileText === undefined &&
            <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
                {props.text}
            </span>
        }
    </span>;
};