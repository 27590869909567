import { PoliticsIndex } from "../../Views/Politics/PoliticsIndex";
import { PresidentsView } from "../../Views/Politics/PresidentsView";
import { ProposeView } from "../../Views/Politics/ProposeView";
import { TermsIndex } from "../../Views/Politics/TermsIndex";
import { TermView } from "../../Views/Politics/TermView";
import { route } from "./Root";

const politics = "/politics";
export const politics_terms = politics + "/terms";
export const politics_home = politics;
export const politics_propose = politics + "/propose";
export function politics_terms_detail(presidentialTermId: number | string) {
    return politics_terms + "/" + presidentialTermId;
}

export const politics_president = politics + "/president";

export const politicsRoutes = [

    route(politics_propose, ProposeView, "Propose Policy"),
    route(politics_president, PresidentsView, "Presidential View"),
    route(politics_home, PoliticsIndex, "Galatic Council"),
    route(politics_terms, TermsIndex, "Presidential Terms"),
    route(politics_terms_detail(":presidentialTermId"), TermView, "Presidential Term")
];