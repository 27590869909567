import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IItemTypeSettings, ISectorTypeSettings, MegacitySector, SolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { Table } from '../../../Components/Base/Containers/Table';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { SectorTypeBonusList } from '../../../Components/FusionShift/Descriptions/SectorTypeBonusList';
import { SectorTypeLink } from '../../../Components/FusionShift/Links/SectorTypeLink';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { help_sectorTypes } from '../../../Navigation/Routing/Help';

type Props = {
    solarSystems: SolarSystemWrapper[],
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type RowProps = {
    sectorTypeSettings: ISectorTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    solarSystem: SolarSystemWrapper,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type Assignment = "all" | "available" | "none";

const Row = (props: RowProps) => {

    const apiState = React.useContext(ApiStateContext);
    const megacityCelestial = props.solarSystem.solarSystem.celestials.find(s => s.megacity !== null && s.megacity !== undefined);
    const megacity = megacityCelestial?.megacity;

    if (!megacity || Object.keys(megacity.sectors).length === 0) {
        return null;
    }

    const sectors = Object.keys(megacity.sectors).map(s => {
        return {
            sectorType: props.sectorTypeSettings.data[s],
            sector: megacity.sectors[s]
        }
    });

    function assign(sectorTypeNameToModify: string, sector: MegacitySector, assignment: Assignment) {

        const assignments: { [key: string]: number } = {};

        for (let sectorTypeName in megacity!.sectors) {
            let toSet = megacity!.sectors[sectorTypeName].assignedPopulation;

            if (sectorTypeName === sectorTypeNameToModify) {
                if (assignment === "none") {
                    toSet = 0;
                } else if (assignment === "available") {
                    toSet = toSet + megacity!.unassignedPopulation;
                }
            }

            assignments[sectorTypeName] = toSet;
        }

        if (assignment === "all") {

            let all = megacity!.unassignedPopulation;
            const max = megacity!.maximumPopulation > sector.maximumPopulation ? sector.maximumPopulation : megacity!.maximumPopulation;

            for (let sectorTypeName in assignments) {
                const remaining = max - all;
                const here = assignments[sectorTypeName];
                const toTake = remaining > here ? here : remaining;

                all = all + toTake;
                assignments[sectorTypeName] = assignments[sectorTypeName] - toTake;
            }

            assignments[sectorTypeNameToModify] = all;
        }

        return apiState.MegacityClient.assignPopulation(megacityCelestial!.celestialId, assignments).then(props.reloadCallback);
    }

    return <>
        {sectors.map((s, i) => {

            return <React.Fragment key={i}>
                {i === 0 && <tr className='is-hidden-tablet'>
                    <td colSpan={3}>
                        <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
                        <br />
                        {ValueFormatter.formatPopulation(megacity.currentPopulation)} / {ValueFormatter.formatPopulation(megacity.maximumPopulation)} / {ValueFormatter.formatPopulation(megacity.unassignedPopulation)}
                    </td>
                </tr>}
                <tr>
                    <td className='is-hidden-mobile'>
                        {i === 0 && <>
                            <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
                        </>}
                    </td>
                    <td className='is-hidden-mobile'>
                        {i === 0 && <>
                            {ValueFormatter.formatPopulation(megacity.currentPopulation)} / {ValueFormatter.formatPopulation(megacity.maximumPopulation)} / {ValueFormatter.formatPopulation(megacity.unassignedPopulation)}
                        </>}
                    </td>
                    <td>
                        <SectorTypeLink sectorType={s.sectorType} />
                    </td>
                    <td>
                        {`${ValueFormatter.formatPopulation(s.sector.assignedPopulation)} / ${ValueFormatter.formatPopulation(s.sector.maximumPopulation)}`}
                    </td>
                    <td className='is-hidden-mobile'>
                        <SectorTypeBonusList sectorType={s.sectorType} bonus={s.sector.currentBonus} isBrief assignedPopulation={s.sector.assignedPopulation} />
                    </td>
                    <td>
                        <ButtonHolder>
                            <Button
                                type="action"
                                icon={IconHelper.Megacities.PopulationAssignNone}
                                isDisabled={s.sector.assignedPopulation <= 0}
                                action={() => assign(s.sectorType.typeName, s.sector, "none")}
                                title="Clear assigned population"
                            />
                            <Button
                                type="action"
                                icon={IconHelper.Megacities.PopulationAssignment}
                                isDisabled={megacity.unassignedPopulation < 0.075}
                                action={() => assign(s.sectorType.typeName, s.sector, "available")}
                                title="Assign available population"
                            />
                            <Button
                                type="action"
                                icon={IconHelper.Megacities.PopulationAssignAll}
                                isDisabled={s.sector.assignedPopulation >= s.sector.maximumPopulation || s.sector.assignedPopulation >= megacity.currentPopulation}
                                action={() => assign(s.sectorType.typeName, s.sector, "all")}
                                title="Assign maximum population"
                            />
                        </ButtonHolder>
                    </td>
                </tr>
                <tr className='is-hidden-tablet'>
                    <td colSpan={3}>
                        <SectorTypeBonusList
                            sectorType={s.sectorType}
                            bonus={s.sector.currentBonus}
                            isBrief
                            assignedPopulation={s.sector.assignedPopulation}
                            isHorizontalOnMobile
                        />
                    </td>
                </tr>
            </React.Fragment>
        })}
    </>;
};

export const MegacitiesView = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.SectorTypeSettings || !worldState.ItemTypeSettings) {
        return <LoadingSpinner />;
    }

    const sectors = props.solarSystems.map(x => x.solarSystem.celestials.filter(c => c.megacity).map(c => c.megacity)
        .map(c => CollectionHelper.isAnyInDictionary(c!.sectors)));
    const any = sectors.find(c => c.find(x => x));

    if (!any) {
        return <Paragraph type="prompt">
            None of your Megacities have any sectors. Construct some <Link to={help_sectorTypes}>sectors</Link> to view Megacity summary.
        </Paragraph>
    }

    return <Table
        isHoverable
        isFullWidth
        hideHeaderMobile
        heading={
            <>
                <th></th>
                <th>Pop. / Max / Unassigned</th>
                <th>Sector</th>
                <th>Assigned</th>
                <th>Effects</th>
                <th></th>
            </>
        }>
        {props.solarSystems.map(s => <Row
            key={s.solarSystem.solarSystemId}
            solarSystem={s}
            sectorTypeSettings={worldState.SectorTypeSettings!}
            itemTypeSettings={worldState.ItemTypeSettings!}
            {...props} />)}
    </Table>;
});