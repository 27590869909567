import { makeAutoObservable } from "mobx";
import { IFleet, IMapApocalypseShot, IMapEntities, IMapSolarSystem, IPlayerForSelf, ISolarSystemForList, MapSolarSystem, PlayerMinimal, WorldRegion } from "../../../ApplicationState/ApiClient";

type Rect = {
    minX: number;
    minY: number;
    width: number;
    height: number;
}

type Map = Rect & {
    solarSystems: MapSolarSystem[]
}

export class GridStore {

    cells: { [key: number]: { [key: number]: MapSolarSystem } };

    fleets: { [key: number]: IFleet };
    apocalypseShots: { [key: number]: IMapApocalypseShot };

    private rects: Rect[] = [];

    constructor(player: IPlayerForSelf, solarSystems: ISolarSystemForList[]) {

        this.cells = {};
        this.fleets = {};
        this.apocalypseShots = {};

        const mapSolarSystems = solarSystems.map(x => {

            const data: IMapSolarSystem = {
                ...x,
                celestialTypeNames: [],
                owner: new PlayerMinimal(player),
                isExplored: true,
                playerId: player.playerId,
                region: WorldRegion.Core
            };

            const mapSolarSystem = new MapSolarSystem(data);

            return mapSolarSystem;
        });

        for (const s of mapSolarSystems) {
            this.setSolarSystem(s);
        }

        makeAutoObservable(this);
    }

    public setSolarSystems(map: Map) {
        for (const solarSystem of map.solarSystems) {
            this.setSolarSystem(solarSystem);
        }

        const rect: Rect = {
            minX: map.minX,
            minY: map.minY,
            width: map.width,
            height: map.height
        };

        if (this.rects.find(x => x.minX === rect.minX &&
            x.minY === rect.minY &&
            x.width === rect.width &&
            x.height === rect.height) === undefined) {
            this.rects.push(rect);
        }
    }

    public setEntities(mapEntities: IMapEntities) {
        for (const fleet of mapEntities.fleets) {
            this.fleets[fleet.fleetId] = fleet;
        }

        for (const apocalypseShot of mapEntities.apocalypseShots) {
            this.apocalypseShots[apocalypseShot.apocalypseShotId] = apocalypseShot;
        }
    }

    public isLoaded(x: number, y: number) {
        const rect = this.rects.find(r => this.contains(r, x, y));

        return rect !== undefined;
    }

    public setSolarSystem(solarSystem: MapSolarSystem) {

        if (!(solarSystem.x in this.cells)) {
            this.cells[solarSystem.x] = {};
        }

        this.cells[solarSystem.x][solarSystem.y] = solarSystem;
    }

    private contains(rect: Rect, x: number, y: number) {
        return rect.minX <= x && x <= rect.minX + rect.width &&
            rect.minY <= y && y <= rect.minY + rect.height;
    }
}