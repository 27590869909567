import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SolarSystemStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { InstallationTypeLink } from '../../../../Components/FusionShift/Links/InstallationTypeLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const InstallationRequired = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    if (!props.mission.MissionType.installationRequired || !worldState.InstallationTypeSettings || !solarSystemState.SolarSystem) {
        return null;
    }

    const installationTypeName = props.mission.MissionType.installationRequired.installationTypeName;
    const installationType = worldState.InstallationTypeSettings.data[installationTypeName];
    const level = props.mission.MissionType.installationRequired.level;
    const required = props.mission.MissionType.installationRequired.quantity;
    var has = 0;

    for (let c of solarSystemState.SolarSystem.celestials) {
        if (c.installations) {
            for (let i of c.installations) {
                if (i.installationTypeName === installationTypeName && i.level >= level) {
                    has++;
                }
            }
        }
    }

    return <ObjectiveRow has={has} requires={required} {...props} >
        {level > 1 ? "Upgrade " : "Build "}
        {required > 1 && `${required}x `}
        {required == 1 && `${ValueFormatter.aOrAn(installationType.name)} `}
        <InstallationTypeLink installationType={installationType} />
        {level > 1 && ` to level ${level}`}
    </ObjectiveRow>;
});