import { CelestialSize, FleetMovementType, IGameSettings, IInstallationTypeSettings, ISectorTypeSettings, ISolarSystem, ISolarSystemDetail, ISolarSystemWithCelestials, InstallationBuildQueueType, WorldRegion } from "../ApplicationState/ApiClient";
import { solarSystemsImageConfig } from '../images/SolarSystemsImageConfig';
import { BackgroundImage } from "./BackgroundImageHelper";
import { FleetHelper } from "./FleetHelper";

export type SolarSystemDanger = {
    isLosingControl: boolean,
    hasApocalpyse: boolean,
    incomingHostileMovements: FleetMovementType[]
}

export class SolarSystemHelper {

    public static megacityCombatBonus(sectorTypeSettings: ISectorTypeSettings, solarSystem: ISolarSystemDetail) {
        let bonus = 0;

        for (const celestial of solarSystem.celestials) {
            if (celestial.megacity !== undefined) {
                for (const sectorTypeName of Object.keys(celestial.megacity.sectors)) {
                    const sector = sectorTypeName in sectorTypeSettings.data ? sectorTypeSettings.data[sectorTypeName] : undefined;

                    if (sector !== undefined) {
                        if (sector.manufacturingBonusAtMaxLevel > 0) {
                            bonus += sector.militaryBonusAtMaxLevel * celestial.megacity.sectors[sectorTypeName].currentBonus;
                        }
                    }
                }
            }
        }

        return bonus;
    }

    public static coordinateDescription(x: number, y: number) {
        if (x === 0 && y === 0) {
            return "C";
        }

        const px = x < 0 ? "L" : "R";
        const py = y < 0 ? "T" : "B";

        return `${py}${px}`;
    }

    public static solarSystemBackground(solarSystem: ISolarSystem | undefined): BackgroundImage | undefined {

        if (solarSystem === undefined) {
            return undefined;
        }

        const variation = this.solarSystemVariation(solarSystem.solarSystemId, solarSystem.region);

        if (variation === undefined) {
            return undefined;
        }

        return {
            variant: variation.variant,
            variationSource: variation.regionName,
            type: "solarSystem"
        }
    }

    private static solarSystemVariation(solarSystemId: number, region: WorldRegion) {
        const regionName = WorldRegion[region];

        if (!(regionName in solarSystemsImageConfig)) {
            return undefined;
        }

        let variant = solarSystemId;
        const num = solarSystemsImageConfig[regionName];
        while (variant >= num) {
            variant -= num;
        }

        return {
            regionName,
            variant
        };
    }

    private static findRepresentativeCelestialTypeName(solarSystem: ISolarSystemWithCelestials) {

        if (solarSystem.owner !== undefined) {
            const habitable = solarSystem.celestials.find(x => x.celestialTypeName === "Habitable");

            if (habitable !== undefined) {
                return "HabitableOccupied";
            }
        }

        let best: { celestialTypeName: string, size: CelestialSize } | undefined = undefined;

        for (const celestial of solarSystem.celestials) {
            if (celestial.celestialTypeName !== undefined && (best === undefined ||
                best.size < celestial.size)) {
                best = {
                    size: celestial.size,
                    celestialTypeName: celestial.celestialTypeName!
                };
            }
        }

        return best?.celestialTypeName ?? "Unknown";
    }

    public static isLosingControl(solarSystem: ISolarSystemDetail | undefined, installationTypeSettings: IInstallationTypeSettings | undefined) {

        if (solarSystem === undefined || installationTypeSettings === undefined) {
            return false;
        }

        const isLosingControl = solarSystem.installationBuildQueue?.find(x => {

            if (x.type !== InstallationBuildQueueType.Deconstruct) {
                return false;
            }

            const installationType = installationTypeSettings.data[x.typeName];

            return installationType.solarSystemClaimType !== undefined && installationType.solarSystemClaimType !== null;
        });

        return isLosingControl !== undefined;
    }

    public static getSolarSystemDanger(solarSystem: ISolarSystemDetail, installationTypeSettings: IInstallationTypeSettings): SolarSystemDanger {

        const hasApocalpyse = solarSystem.apocalypseShots.find(x => x.targetSolarSystem.solarSystemId === solarSystem?.solarSystemId) !== undefined;
        const isLosingControl = SolarSystemHelper.isLosingControl(solarSystem, installationTypeSettings);

        const types: FleetMovementType[] = [];
        for (let m of solarSystem.fleets ?? []) {
            if (m.ownerPlayerId !== solarSystem.playerId && !types.includes(m.movementType) && FleetHelper.isHostileMovementType(m.movementType)) {
                types.push(m.movementType);
            }
        }

        return {
            hasApocalpyse,
            isLosingControl,
            incomingHostileMovements: types
        }
    }

    public static maximumOutposts(installationLevel: number, gameSettings: IGameSettings) {
        return Math.floor(installationLevel / gameSettings.solarSystem.numberOfCapitalLevelsPerOutpost);
    }
}