import * as React from 'react';
import { ISolarSystemDetail, SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { Rename } from './Rename';
import { Tactics } from './Tactics';

type Props = {
    solarSystem: ISolarSystemDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const Settings = (props: Props) => {

    return <>
        <Tactics {...props} />
        <Rename {...props} />
    </>;
}