import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";

export const ActiveFreeSubscriptionControl = () => {
    function describe() {
        return "You have an active free subscription";
    }

    return <Paragraph>
        {describe()}
    </Paragraph>;
}