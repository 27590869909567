import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CelestialSize, ICelestial, InstallationType, ISolarSystemDetail, ShipType } from '../../../../ApplicationState/ApiClient';
import { AppStateContext, PlayerStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { Table } from '../../../../Components/Base/Containers/Table';
import { H3 } from '../../../../Components/Base/Text/H';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { CelestialImage } from '../../../../Components/FusionShift/Celestials/CelestialImage';
import { ShipClassIcon } from '../../../../Components/FusionShift/Icons/ShipClassIcon';
import { InstallationTypeImage } from '../../../../Components/FusionShift/Images/InstallationTypeImage';
import { ShipTypeImage } from '../../../../Components/FusionShift/Images/ShipTypeImage';
import { InstallationTypeLink } from '../../../../Components/FusionShift/Links/InstallationTypeLink';
import { CollectionHelper } from '../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { InstallationHelper } from '../../../../Helpers/InstallationHelper';
import { celestial_ships } from '../../../../Navigation/Routing/SolarSystem';

type BuildShipsOption = {
    celestial: ICelestial,
    installations: {
        installationType: InstallationType,
        level: number,
        ships: ShipType[]
    }[]
}

type Props = {
    solarSystem: ISolarSystemDetail,
    supportsLarge?: boolean
}


export const ChooseWhereToBuildShips = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const worldState = React.useContext(WorldStateContext);

    const [buildShipsOptions, setBuildShipsOptions] = React.useState<BuildShipsOption[]>([]);

    function navigateToBuildShips(celestialId: number) {
        appState.navigate(celestial_ships(celestialId));
    }

    React.useEffect(() => {

        if (worldState.InstallationTypeSettings && worldState.ShipTypeSettings && playerState.Player) {
            const bso: BuildShipsOption[] = [];

            for (let celestial of props.solarSystem.celestials) {
                if (CollectionHelper.isAnyInDictionary(celestial.shipTypeNamesThatCanBeBuilt)) {
                    const installations = InstallationHelper.installationsWhichBuildShips(worldState.InstallationTypeSettings, worldState.ShipTypeSettings, celestial, playerState.Player.factionTypeName);
                    if (installations && installations.length > 0) {
                        bso.push({
                            celestial,
                            installations
                        });
                    }
                }
            }

            setBuildShipsOptions(bso);
        }
    }, [props.solarSystem, worldState.ShipTypeSettings, worldState.InstallationTypeSettings, playerState.Player]);

    if (!worldState.ShipTypeSettings || !worldState.InstallationTypeSettings || buildShipsOptions.length === 0 || !playerState.Player) {
        return null;
    }

    return <div className="choose-where-to-build-ships">
        <div className="prompt-holder">
            <Paragraph type='prompt'>
                Multiple installations can build ships. Choose where to build:
            </Paragraph>
        </div>
        <Table
            isFullWidth
            isClickable
            isHoverable
            className='choose-where-to-build-ships'
        >
            {buildShipsOptions.map(x => <React.Fragment key={x.celestial.celestialId}>
                {x.installations.map((y, i) => <tr
                    key={`${x.celestial.celestialId}_${y.installationType.typeName}`}
                    onClick={() => navigateToBuildShips(x.celestial.celestialId)}
                >
                    <td className="is-content-width">
                        {i === 0 && <H3 className="celestial-name">{x.celestial.name} </H3>}
                    </td>
                    <td className="has-image-tiny">
                        {i === 0 && <CelestialImage celestial={x.celestial} forcedSize={CelestialSize.Tiny} isLink />}
                    </td>
                    <td className="has-image-tiny">
                        <InstallationTypeImage
                            size='tiny'
                            installationType={y.installationType}
                        />
                    </td>
                    <td className={`is-hidden-mobile ${!props.supportsLarge ? "is-hidden-desktop-only" : ""}`}>
                        <InstallationTypeLink installationType={y.installationType} /> [{y.level}]
                    </td>
                    <td className={props.supportsLarge ? "is-hidden-desktop" : ""}>
                        <div className="is-flex">
                            {y.ships
                                .sort((a, b) => a.order - b.order)
                                .map(s => <ShipClassIcon key={s.typeName} shipClass={s.class} />)}
                        </div>
                    </td>
                    {props.supportsLarge && <td className="is-hidden-touch">
                        <div className="is-flex">
                            {y.ships
                                .sort((a, b) => a.order - b.order)
                                .map(s => <ShipTypeImage key={s.typeName} shipType={s} size='tiny' />)}
                        </div>
                    </td>}
                    <td className='is-content-width'>
                        <Button
                            type="nav"
                            action={() => navigateToBuildShips(x.celestial.celestialId)}
                            icon={IconHelper.Ships.Build}
                        />

                    </td>
                </tr>)}
            </React.Fragment>)}
        </Table>
    </div>;
});