import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ActivePolitics, IProposeInput, Policy, PolicyCategory, PolicyProposalType, PolicyType, ProposeInput } from '../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext, PlayerStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { BackNavButton } from '../../Components/Base/Buttons/BackButton';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { TabMenu } from '../../Components/Base/Containers/TabMenu';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { ErrorMessageBox } from '../../Components/Base/MessageBox';
import { IconHelper } from '../../Helpers/IconHelper';
import { PoliticsHelper } from '../../Helpers/PoliticsHelper';
import { politics_home, politics_president } from '../../Navigation/Routing/Politics';
import { SimpleBaseView } from '../BaseView';
import { ProposeForm } from './Propose/ProposeForm';
import { SplitProposeList } from './Propose/ProposeList';

export type PolicyProposal = {
    policyType: PolicyType,
    activePolicy?: Policy
};

export const ProposeView = observer(() => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [error, setError] = React.useState<string | undefined>(undefined);
    const [policyTypeName, setPolicyTypeName] = React.useState<string | undefined>(undefined);
    const [activePolitics, setActivePolitics] = React.useState<ActivePolitics | undefined>(undefined);
    const [policyCategory, setPolicyCategory] = React.useState<PolicyCategory>(1);
    const [allPolicies, setAllPolicies] = React.useState<PolicyProposal[] | undefined>(undefined);

    React.useEffect(() => {
        setError(undefined);
    }, [policyTypeName, policyCategory]);

    React.useEffect(() => {
        if (!activePolitics) {
            apiState.PoliticsClient.getActive().then(t => {
                setActivePolitics(t);
            });
        }
    }, [activePolitics]);

    React.useEffect(() => {
        if (worldState.PolicyTypeSettings && activePolitics) {
            const ap: PolicyProposal[] = Object.keys(worldState.PolicyTypeSettings.data).map(x => {
                return {
                    policyType: worldState.PolicyTypeSettings!.data[x],
                    activePolicy: activePolitics.activePolicies.policies.find(p => p.policyTypeName === x)
                };
            });

            setAllPolicies(ap);
        }
    }, [worldState.PolicyTypeSettings, activePolitics]);

    function propose(useExecutiveOrder: boolean, useDemocraticReformation: boolean, description: string) {
        if (!policyTypeName || !allPolicies) {
            return;
        }

        const policy = allPolicies.find(x => x.policyType.typeName === policyTypeName);

        if (!policy) {
            return;
        }

        const data: IProposeInput = {
            policyTypeName,
            isRepeal: policy.activePolicy !== undefined,
            proposalType: useDemocraticReformation ? PolicyProposalType.DemocraticReformation : useExecutiveOrder ? PolicyProposalType.ExecutiveOrder : PolicyProposalType.Vote,
            description
        };

        setError(undefined);

        return apiState.PoliticsClient.proposePolicy(new ProposeInput(data)).then(result => {
            if (result.wasSuccess) {
                appState.navigate(politics_home);
            } else {
                setError(result.message);
            }
        });
    }

    if (activePolitics === undefined || playerState.Player === undefined || worldState.PolicyTypeSettings === undefined || allPolicies === undefined) {
        return <LoadingSpinner />;
    }

    if (activePolitics.activePresident === undefined || activePolitics.activePresident.president.playerId !== playerState.Player.playerId) {
        return <ErrorMessageBox text={"There is no active presidential term or you are not the active president"} />;
    }

    const policiesInCategory: PolicyProposal[] = allPolicies.filter(x => x.policyType.category === policyCategory);

    const canPropose = PoliticsHelper.canProposePolicy(playerState.Player, activePolitics.activePresident) && policiesInCategory.length > 0;

    if (!canPropose) {
        return <ErrorMessageBox text={"You can't propose a policy at this time"} />;
    }

    const policyCategories: PolicyCategory[] = [];

    for (let k of Object.keys(worldState.PolicyTypeSettings.data)) {
        if (!policyCategories.includes(worldState.PolicyTypeSettings.data[k].category)) {
            policyCategories.push(worldState.PolicyTypeSettings.data[k].category);
        }
    }

    const tabs = policyCategories.sort((a, b) => a - b).map(c => {
        return {
            id: c,
            title: PolicyCategory[c],
            icons: IconHelper.Politics.policyCategory(c),
            isAvailable: true
        };
    })

    return <SimpleBaseView
        heading={{ text: `Propose Policy`, icon: IconHelper.Politics.Propose }}
        headingContent={<ButtonHolder isPulledRight>
            <BackNavButton to={politics_president} />
        </ButtonHolder>}>
        <TabMenu active={policyCategory} tabs={tabs} changeTab={setPolicyCategory} />
        <SplitProposeList policies={policiesInCategory} setPolicyTypeName={setPolicyTypeName} policyTypeName={policyTypeName} />
        <ProposeForm policy={allPolicies.find(x => x.policyType.typeName === policyTypeName)} activePolitics={activePolitics} propose={propose} error={error} />
    </SimpleBaseView>;
});