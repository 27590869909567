import classNames from "classnames";
import { FleetMovementType, IItemTypeSettings, ItemCategory } from "../../../ApplicationState/ApiClient";
import { Fleet, Line, SolarSystem as SolarSystemPosition, solarSystemPosition, solarSystemPositionToRenderPosition } from "./Entities";

export function createItemsRender(
    solarSystems: SolarSystemPosition[],
    itemTypeSettings: IItemTypeSettings,
    selectedSolarSystemId: number | undefined) {
    const sanitizedItems = getSanitizedItems(itemTypeSettings);

    return getFleetLines(
        solarSystems,
        sanitizedItems,
        selectedSolarSystemId);
}

function sanitizeItems(itemTypeNames: string[], sanitizedItems: { [key: string]: string }) {
    return itemTypeNames.map(x => {
        if (!(x in sanitizedItems)) {
            return undefined;
        }

        return sanitizedItems[x];
    })
        .filter(x => x !== undefined)
        .map(x => x!);
}

function getSanitizedItems(itemTypeSettings: IItemTypeSettings) {
    const items: { [key: string]: string } = {};

    for (let itemTypeName in itemTypeSettings.data) {
        let toUse = itemTypeName;

        const category = itemTypeSettings.data[itemTypeName].category;

        if (category !== ItemCategory.Resource) {
            toUse = ItemCategory[category];
        }

        items[itemTypeName] = toUse.toLowerCase();
    }

    return items;
}

function getFleetLines(solarSystems: SolarSystemPosition[],
    sanitizedItems: { [key: string]: string },
    selectedSolarSystemId: number | undefined) {

    function solarSystemById(solarSystemId: number) {
        return solarSystems.find(x => x.solarSystem.solarSystemId === solarSystemId)!;
    }

    const fleets: Fleet[] = [];

    for (let solarSystem of solarSystems) {

        const solarSystemPosition = solarSystemById(solarSystem.solarSystem.solarSystemId);

        if (solarSystem.solarSystem.solarSystem.repeatFleets) {
            for (let repeatFleet of solarSystem.solarSystem.solarSystem.repeatFleets) {

                let sourceSolarSystem = solarSystemPosition;
                let targetSolarSystem = solarSystemById(repeatFleet.targetSolarSystemId);

                if (targetSolarSystem !== undefined) {
                    if (repeatFleet.movementType === FleetMovementType.Collection) {
                        sourceSolarSystem = targetSolarSystem;
                        targetSolarSystem = solarSystemPosition;
                    }

                    const items = repeatFleet.movementType === FleetMovementType.Collection ? Object.keys(repeatFleet.collection?.items ?? {}) : Object.keys(repeatFleet.itemsToSend ?? {});

                    fleets.push({
                        sourceSolarSystem,
                        targetSolarSystem,
                        itemTypeNames: sanitizeItems(items, sanitizedItems)
                    });
                }
            }
        }
    }

    const lines: Line[] = [];

    for (let fleet of fleets) {

        for (let item of fleet.itemTypeNames) {

            const a = solarSystemPositionToRenderPosition(solarSystemPosition(fleet.sourceSolarSystem));
            const b = solarSystemPositionToRenderPosition(solarSystemPosition(fleet.targetSolarSystem));

            const fleetClassName = classNames("fleet",
                item,
                {
                    "selected-system": fleet.sourceSolarSystem.solarSystem.solarSystemId === selectedSolarSystemId || fleet.targetSolarSystem.solarSystem.solarSystemId === selectedSolarSystemId
                });

            lines.push({
                x1: a.x,
                y1: a.y,
                x2: b.x,
                y2: b.y,
                className: fleetClassName
            });
        }
    }

    return lines;
}