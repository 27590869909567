import { AgentAction, AgentActionType, ArrestAgentParameters, BaseParameters, ChangeCelestialParameters, IAgentDetail } from '../../../../ApplicationState/ApiClient';
import { FieldHolder } from '../../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../../Components/Base/Form/Select';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { CelestialSelectOnly } from '../../../../Components/FusionShift/Celestials/CelestialSelect';
import { AgentHelper } from '../../../../Helpers/AgentHelper';

type Props = {
    agent: IAgentDetail,
    actionType: AgentActionType,
    parameters: BaseParameters | undefined,
    setParameters: (parameters: BaseParameters | undefined) => any
}

export const ParametersComponent = (props: Props) => {

    if (props.actionType.action === AgentAction.ChangeCelestial || props.actionType.action === AgentAction.ChangeCelestialWhileCovert) {

        const celestials = props.agent.celestialPosition?.otherCelestials?.filter(x => x.celestialId !== props.agent.celestialPosition?.celestial.celestialId);

        if (celestials === undefined || celestials?.length === 0) {
            return <Paragraph type="danger">No valid celestials at current location.</Paragraph>
        }

        const celestialParameters = props.parameters as ChangeCelestialParameters;

        return <FieldHolder label="Celestial">
            <CelestialSelectOnly
                celestials={celestials}
                celestialId={celestialParameters?.celestialId ?? 0}
                setCelestialId={c => props.setParameters(ChangeCelestialParameters.fromJS({
                    celestialId: c
                }))} />
        </FieldHolder>;
    }

    if (props.actionType.action === AgentAction.ArrestAgent) {

        const agents = props.agent.celestialPosition?.celestial.agents.filter(x => AgentHelper.canBeArrested(x, props.agent.player)) ?? [];

        if (agents.length === 0) {
            return <Paragraph type="warning">
                There are no valid arrest targets here.
            </Paragraph>
        }

        const arrestAgentParemeters = props.parameters as ArrestAgentParameters;

        if (arrestAgentParemeters !== undefined && arrestAgentParemeters.agentId <= 0) {
            props.setParameters(ArrestAgentParameters.fromJS({
                agentId: agents[0].agentId
            }));
        }

        return <FieldHolder label="Target Agent">
            <Select
                value={arrestAgentParemeters?.agentId}
                values={agents.map(x => {
                    return {
                        value: x.agentId,
                        label: x.name
                    }
                })}
                valueChanged={v => props.setParameters(ArrestAgentParameters.fromJS({
                    agentId: v
                }))} />
        </FieldHolder>;
    }

    return null;
}