import * as React from 'react';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type ValueProps = {
    value: string | number | undefined,
    decimals?: number
}

export const BalanceCell = (props: ValueProps) => {
    return <td>
        {ValueFormatter.formatLocaleNumber(props.value, props.decimals)}
    </td>;
};