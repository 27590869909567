
import { ICelestialDetail, IGameSettings, IInstallation, IInstallationTypeSettings, Installation, InstallationType, IShipTypeSettings, ISolarSystemDetail, ShipType } from "../ApplicationState/ApiClient";
import { CelestialFeature } from "./CelestialHelper";
import { DroneHelper } from "./DroneHelper";

export class InstallationHelper {

    public static celestialFeatures(installation: IInstallation, celestial: ICelestialDetail, installationTypeSettings: IInstallationTypeSettings, shipTypeSettings: IShipTypeSettings) {

        const features: CelestialFeature[] = [];

        if (!(installation.installationTypeName in installationTypeSettings.data)) {
            return features;
        }

        const installationType = installationTypeSettings.data[installation.installationTypeName];

        if (DroneHelper.hasDronesAtInstallation(installationTypeSettings, installation)) {
            features.push("Drones");
        }
        if (celestial.megacity && (
            installationType.megacityPopulationAssignmentPerLevel ||
            installationType.megacityPopulationCapacityPerLevel ||
            installationType.megacitySectorTypeName.length > 0
        )) {
            features.push("Megacity");
        }
        if (celestial.manufactory && (
            installationType.factoryBaseInputNodes ||
            installationType.factoryBaseInputThroughput ||
            installationType.factoryBaseOutputNodes ||
            installationType.factoryBaseOutputThroughput ||
            installationType.factoryInputNodesPerLevel ||
            installationType.factoryInputThroughputPerLevel ||
            installationType.factoryOutputNodesPerLevel ||
            installationType.factoryOutputThroughputPerLevel
        )) {
            features.push("Manufactory");
        }

        if (celestial.shipTypeNamesThatCanBeBuilt) {
            for (let k in celestial.shipTypeNamesThatCanBeBuilt) {
                if (k in shipTypeSettings.data && shipTypeSettings.data[k].builtAtInstallationTypeName === installationType.typeName) {
                    features.push("Ships");
                    break;
                }
            }
        }

        return features;
    }

    public static totalInstallationHealthAtLevel(level: number, healthPerLevel: number) {
        let health = 0;

        while (level > 0) {
            health += this.healthForLevel(level, healthPerLevel);
            level--;
        }

        return health;
    }

    public static healthForLevel(level: number, healthPerLevel: number) {
        return level * healthPerLevel;
    }

    public static resourceProductionPerHour(level: number, gameSettings: IGameSettings) {
        return (level * level) * gameSettings!.gameSpeed * gameSettings!.solarSystem.resourceProduction;
    }

    public static storageAtLevel(level: number, capacityPerLevel: number) {
        const factor = level === 1 ? 0.5 : Math.pow(level / 2, 2);
        return capacityPerLevel * factor;
    }

    public static installationsWhichBuildShips(installationTypeSettings: IInstallationTypeSettings, shipTypeSettings: IShipTypeSettings, celestail: ICelestialDetail, factionTypeName?: string) {

        if (!celestail.installations) {
            return undefined;
        }

        const installations: {
            installationType: InstallationType,
            level: number,
            ships: ShipType[]
        }[] = [];

        for (let installation of celestail.installations) {
            if (!installation) {
                continue;
            }
            if (installation.level > 0 && installation.installationTypeName in installationTypeSettings.data) {
                const installationType = installationTypeSettings.data[installation.installationTypeName];
                const ships = Object.values(shipTypeSettings.data)
                    .filter(s => s.builtAtInstallationTypeName === installationType.typeName && s.builtAtMinimumLevel <= installation.level)
                    .filter(s => factionTypeName === undefined || factionTypeName.length === 0 || s.factionTypeName === factionTypeName);

                if (ships.length > 0) {
                    installations.push({
                        installationType: installationType,
                        level: installation.level,
                        ships
                    });
                }
            }
        }

        return installations;
    }

    public static sumOfStatPerLevelForSolarSystem(installationTypeSettings: IInstallationTypeSettings, solarSystem: ISolarSystemDetail | undefined, valueSelector: (i: InstallationType) => number | undefined) {
        if (!solarSystem) {
            return 0;
        }

        const installations = solarSystem.celestials
            .filter(c => !!c.installations)
            .map(c => c.installations!)
            .flat();

        return this.sumOfStatPerLevel(installationTypeSettings, installations, valueSelector);
    }

    public static sumOfStatPerLevel(installationTypeSettings: IInstallationTypeSettings, installations: Installation[] | undefined, valueSelector: (i: InstallationType) => number | undefined) {
        let sum = 0;

        if (installations) {
            for (let i of installations) {
                if (i.level > 0 && i.installationTypeName in installationTypeSettings.data) {
                    const valuePerLevel = valueSelector(installationTypeSettings.data[i.installationTypeName]);
                    if (valuePerLevel) {
                        sum += i.level * valuePerLevel;
                    }
                }
            }
        }

        return sum;
    }
}