import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { WorldQuandrant } from '../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../Components/Base/Form/Select';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { valuesFromEnum } from '../../../Helpers/TypeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { StartLocation } from './JoinWorldBag';
import { QuadrantSummary } from './QuadrantSummary';
import { StageProps } from './StageRender';

export const LocationStage = observer((props: StageProps) => {

    const invitedByPlayer = props.joinWorldBag.Invitation && props.joinWorldBag.Invitation.player;

    const availableLocations: { value: string, label: string }[] = [];

    function byPlayerQuadrant(x: number, y: number) {
        return x > 0 ?
            (y > 0 ? WorldQuandrant.BottomRight : WorldQuandrant.TopRight) :
            (y > 0 ? WorldQuandrant.BottomLeft : WorldQuandrant.TopLeft);
    }

    function describeAsQuadrant(x: number, y: number) {
        const quadrant = byPlayerQuadrant(x, y);

        return ValueFormatter.spacesBeforeCapitals(WorldQuandrant[quadrant]);
    }

    function quadrantToUse(startLocation: StartLocation | undefined) {
        if (startLocation === "random") {
            return undefined;
        }
        if (startLocation === "byFriend") {
            if (invitedByPlayer && invitedByPlayer.capitalSolarSystem) {
                return byPlayerQuadrant(invitedByPlayer.capitalSolarSystem.x, invitedByPlayer.capitalSolarSystem.y);
            }
            return undefined;
        }

        return startLocation;
    }

    if (invitedByPlayer !== undefined) {
        availableLocations.push({
            label: `By ${invitedByPlayer.name}${invitedByPlayer.capitalSolarSystem !== undefined ? `, near ${invitedByPlayer.capitalSolarSystem.x}, ${invitedByPlayer.capitalSolarSystem.x} (${describeAsQuadrant(invitedByPlayer.capitalSolarSystem.x, invitedByPlayer.capitalSolarSystem.y)})` : ""}`,
            value: "byFriend"
        });
    }

    availableLocations.push({
        value: "random",
        label: "Anywhere"
    });

    const fromEnums = valuesFromEnum(WorldQuandrant).map(c => {
        return {
            value: c.toString(),
            label: ValueFormatter.spacesBeforeCapitals(WorldQuandrant[c])
        };
    });

    availableLocations.push(...fromEnums);

    function setLocation(value: string) {
        const numeric = Number(value);
        const location = !isNaN(numeric) ? numeric as WorldQuandrant : value as StartLocation;
        props.joinWorldBag.setStartLocation(location);
    }

    const quadrant = quadrantToUse(props.joinWorldBag.StartLocation);

    return <SubPanel heading="Start Location" footerContent={
        <ButtonHolder isPulledRight>
            <PreviousButton action={props.previousStage} />
            <NextButton action={props.nextStage} />
        </ButtonHolder>
    }>
        <SplitLayout
            isRightEmpty={props.joinWorldBag.World === undefined}
            left={<>
                <Paragraph>
                    Where would you like your capital Solar System to be placed?
            </Paragraph>
                <div className="content">
                    <ul>
                        <li>All players start in the core region of space where security is higher</li>
                        <li>New players can't be attacked for {props.joinWorldBag.World!.settings.gameSettings.security.beginnersProtectionHours / 24} days</li>
                        {invitedByPlayer !== undefined && <li>If you choose to start by {invitedByPlayer.name} then you will start near them in the core region</li>}
                        <li>As you progress through the game you can claim more Solar Systems</li>
                    </ul>
                </div>
                <FieldHolder label="Start Location">
                    <Select
                        className="is-fullwidth"
                        valueChanged={e => setLocation(e)}
                        value={props.joinWorldBag.StartLocation}
                        showUnset={{ label: "Choose Start Location..." }}
                        values={availableLocations} />
                </FieldHolder>
            </>}
            right={props.joinWorldBag.World &&
                <QuadrantSummary
                    selectedWorldQuadrant={quadrant}
                    playerCounts={props.joinWorldBag.World.playerCounts}
                    setQuadrant={q => setLocation(q === undefined ? "random" : q.toString())}
                />
            }
        />
    </SubPanel>;
});