import { Button } from "../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../Components/Base/Buttons/ButtonHolder";
import { IconHelper } from "../../../Helpers/IconHelper";
import { GalaxyMapPreferences } from "./GalaxyMapPreferences";

type Props = {
    preferences: GalaxyMapPreferences,
    panBy: (x: number, y: number) => any,
    zoomBy: (val: number) => any,
    goHome: () => any,
    toggleLength: () => any
}

export const Controls = (props: Props) => {

    return <div className="galaxy-controls">
        <ButtonHolder>
            <Button
                type="nav"
                hideTextOnMobile
                action={props.toggleLength}
                isSelected={!props.preferences.isFullHeight}
                icon={IconHelper.General.LandscapeToPortrait}
            />
            <Button
                type="nav"
                hideTextOnMobile
                action={props.goHome}
                icon={IconHelper.Categories.Home}
                text="Home"
            />
            <Button
                type="nav"
                hideTextOnMobile
                action={() => props.panBy(-1, 0)}
                icon={IconHelper.General.Left}
            />
            <Button
                type="nav"
                hideTextOnMobile
                action={() => props.panBy(0, -1)}
                icon={IconHelper.General.Up}
            />
            <Button
                type="nav"
                hideTextOnMobile
                action={() => props.panBy(0, 1)}
                icon={IconHelper.General.Down}
            />
            <Button
                type="nav"
                hideTextOnMobile
                action={() => props.panBy(1, 0)}
                icon={IconHelper.General.Right}
            />
            <Button
                type="nav"
                hideTextOnMobile
                action={() => props.zoomBy(-.5)}
                icon={IconHelper.General.ZoomOut}
            />
            <Button
                type="nav"
                hideTextOnMobile
                action={() => props.zoomBy(.5)}
                icon={IconHelper.General.ZoomIn}
            />
        </ButtonHolder>
    </div>;
}