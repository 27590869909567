import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { PolicyType } from "../../../ApplicationState/ApiClient";
import { help_policyTypes } from "../../../Navigation/Routing/Help";
import { PolicyCategoryIcon } from "../Icons/PolicyCategoryIcon";

type Props = {
    policyType: PolicyType
    isRepeal?: boolean
}

export const PolicyTypeLink = (props: Props) => {

    return <Link to={`${help_policyTypes}?policyTypeName=${props.policyType.typeName}#${props.policyType.typeName}`}>
        <PolicyCategoryIcon policyCategory={props.policyType.category} />
        {`${props.isRepeal ? "Repeal " : ""}${props.policyType.name}`}
    </Link>;
};