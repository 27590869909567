import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IPlayer } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { PlayerData, PlayersOverTimeResult } from '../../../ApplicationState/ReportingApiClient';
import { DateHelper } from '../../../Helpers/DateHelper';
import { maxLineSeries } from '../../Base/Charts/Theme';
import { LoadingSpinner } from '../../Base/Loading/LoadingSpinner';
import { PlayerLink } from '../Links/PlayerLink';
import { dataChoices } from './DataChooser';
import { DataGraph, DataGraphProps } from './DataGraph';
import { createDateControls } from './DateControls';

type Props = DataGraphProps & {
    playerIds: number[],
    players?: IPlayer[]
}

export const PlayerGraph = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [data, setData] = React.useState<PlayersOverTimeResult | undefined>(undefined);
    const [date, setDate] = React.useState(new Date());

    React.useEffect(() => {

        if (worldState.World !== undefined) {
            apiState.ReportClient.getPlayersOverTime(worldState.World?.globalId, DateHelper.toDateKey(date), 10, props.playerIds).then(x => {
                setData(x);
            });
        }

    }, [worldState.World, date]);

    if (data === undefined || worldState.World === undefined) {
        return <LoadingSpinner />;
    }

    function includeData(datum: PlayerData) {
        const playerDimension = data?.players.find(x => x.playerKey === datum.playerKey);

        return props.playerIds.includes(playerDimension?.playerId ?? -1);
    }

    function renderPlayerName(key) {
        const playerDimension = data?.players.find(x => x.playerKey == key);

        if (playerDimension !== undefined) {
            return playerDimension.name;
        }

        return key;
    }

    function renderSeriesLabel(key) {
        const playerDimension = data?.players.find(x => x.playerKey == key);
        const player = props.players?.find(x => x.playerId === playerDimension?.playerId);

        if (player !== undefined) {
            return <PlayerLink player={player} />;
        }

        return undefined;
    }

    const choices = [
        dataChoices.population,
        dataChoices.size,
        dataChoices.numberOfSolarSystems];

    return <DataGraph
        {...props}
        dataChoices={choices}
        additionalButtons={createDateControls(worldState.World, date, setDate)}
        data={data}
        seriesFeature={{
            getter: "playerKey",
            includeData,
            format: renderPlayerName,
            renderSeriesLabel
        }}
    />;
});

type WrapperProps = DataGraphProps & {
    players: IPlayer[]
}

export const PlayerGraphWrapper = observer((props: WrapperProps) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    const playerIds: number[] = [];

    if (props.players.length > 1) {
        playerIds.push(playerState.Player.playerId);
    }

    for (let i = 0; i < props.players.length && playerIds.length < maxLineSeries; i++) {
        const playerId = props.players[i].playerId;
        if (!playerIds.includes(playerId)) {
            playerIds.push(playerId);
        }
    }

    return <PlayerGraph playerIds={playerIds} {...props} />
});