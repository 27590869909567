import * as React from 'react';
import { IFactionTypeSettings, IShipTypeSettings } from '../../../ApplicationState/ApiClient';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../Components/Base/Form/Select';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';

type Props = {
    factionTypeName: string | undefined,
    setFactionTypeName: (factionTypeName: string) => any,
    factionTypeSettings: IFactionTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    ships?: { [key: string]: number }
}

export function findFactionTypeNameFromShips(shipTypeSettings: IShipTypeSettings, ships?: { [key: string]: number }) {
    if (CollectionHelper.isAnyInDictionary(ships)) {
        for (let s in ships) {
            if (s in shipTypeSettings.data) {
                return shipTypeSettings.data[s].factionTypeName;
            }
        }
    }

    return undefined;
}

export const FactionSelect = (props: Props) => {

    React.useEffect(() => {

        if (props.factionTypeName === undefined) {
            const factionTypeName = findFactionTypeNameFromShips(props.shipTypeSettings, props.ships);

            if (factionTypeName !== undefined) {
                props.setFactionTypeName(factionTypeName);
            } else {
                props.setFactionTypeName(Object.keys(props.factionTypeSettings.data)[0]);
            }
        }
    }, [props.factionTypeSettings, props.factionTypeName, props.ships]);

    return <FieldHolder label="Faction">
        <Select value={props.factionTypeName} valueChanged={e => props.setFactionTypeName(e)} values={Object.values(props.factionTypeSettings.data).map(x => {
            return {
                label: x.name,
                value: x.typeName
            }
        })} />
    </FieldHolder>;
}