import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { observable } from "mobx";

const remoteDependencyTargetFilters = [
    "https://ipv4.icanhazip.com/",
    process.env.REACT_APP_OFFLINE_POLLING_URL!,
    "https://www.google-analytics.com/"
];

export class ApplicationInsightsState {

    @observable applicationInsights?: ApplicationInsights | null;
    @observable reactPlugin?: ReactPlugin | null;

    private _instrumentationKey: string | null | undefined;
    private _history: History | null | undefined;

    constructor(instrumentationKey?: string | null, history?: History | null) {

        this._instrumentationKey = instrumentationKey;
        this._history = history;
    }

    public init() {

        const enabled = process.env.REACT_APP_APPLICATION_INSIGHTS_ENABLED === 'enabled';

        if (!this._instrumentationKey || !this._history || !enabled) {
            this.applicationInsights = null;
            this.reactPlugin = null;
            return;
        }

        this.reactPlugin = new ReactPlugin();

        this.applicationInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this._instrumentationKey!,
                loggingLevelTelemetry: 2,
                correlationHeaderExcludedDomains: [
                    "*.icanhazip.com",
                    "*.azureedge.net",
                    "*.googleapis.com",
                    "*.google-analytics.com",
                    "*.stripe.com",
                    "*.materialdesignicons.com"
                ],
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                disableFetchTracking: false,
                enableAjaxErrorStatusText: true,
                enableAjaxPerfTracking: true,
                enableUnhandledPromiseRejectionTracking: true,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                disableDataLossAnalysis: false,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: {
                        history: this._history
                    }
                }
            }
        });

        this.applicationInsights.loadAppInsights();

        this.applicationInsights.addTelemetryInitializer((telemetryItem: ITelemetryItem) => {

            if (telemetryItem.baseType === "RemoteDependencyData") {
                for (let filter of remoteDependencyTargetFilters) {
                    if (telemetryItem.baseData!.target.startsWith(filter)) {
                        return false;
                    }
                }
            }

            return true;
        });
    };
}