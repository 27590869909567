import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { MessageBoxWithIcon } from '../../Base/MessageBox';

type Props = {
    text: string
}

export const DefeatMessage = (props: Props) => {

    return <MessageBoxWithIcon type="danger" icon={IconHelper.Combat.Defeated} {...props} />;
}

export const PlayerDefeatedMessage = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const appState = React.useContext(AppStateContext);

    if (!appState.IsReady ||
        !solarSystemState.HasLoaded ||
        !solarSystemState.SolarSystems ||
        (solarSystemState.SolarSystems && solarSystemState.SolarSystems.length > 0)) {
        return null;
    }

    return <DefeatMessage />;
});

DefeatMessage.defaultProps = {
    text: "Defeated: You have lost control of all of your solar systems and your empire has been wiped out."
};