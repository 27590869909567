import * as React from 'react';
import { IGameSettings, IItemTypeSettings, IShipTypeSettings, ISolarSystemDetail, ShipType } from '../../../../ApplicationState/ApiClient';
import { ButtonChooser, choice } from '../../../../Components/Base/Buttons/ButtonChooser';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { ShipTypeImage } from '../../../../Components/FusionShift/Images/ShipTypeImage';
import { ResourceTypeLink } from '../../../../Components/FusionShift/Links/Items/ResourceTypeLink';
import { ShipTypeLink } from '../../../../Components/FusionShift/Links/ShipTypeLink';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { UpkeepSummary } from '../../../Fleets/Components/UpkeepSummary';

type Props = {
    solarSystem: ISolarSystemDetail,
    shipTypeSettings: IShipTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    gameSettings: IGameSettings
}

type ShipRow = {
    available: number,
    moving: number,
    grounded: number,
    activating: number,
    total: number
}

type ShipRowProps = ShipRow & {
    shipType: ShipType,
    display: Display
}

type Display = "Upkeep" | "Quantity";

const ShipRow = (props: ShipRowProps) => {

    function value(qty: number) {
        const qtyToUse = props.display === 'Quantity' ? qty : qty * props.shipType.upkeepPerHour;

        return ValueFormatter.formatLocaleNumber(qtyToUse);
    }

    return <tr>
        <td>
            <ShipTypeImage size="tiny" shipType={props.shipType} />
        </td>
        <td>
            <ShipTypeLink shipType={props.shipType} />
        </td>
        <td>
            {value(props.available)}
        </td>
        <td>
            {value(props.moving)}
        </td>
        <td className={props.display === "Upkeep" ? "is-italic" : ""}>
            {value(props.grounded)}
        </td>
        <td>
            {value(props.activating)}
        </td>
        <td>
            {value(props.total)}
        </td>
    </tr>
};

export const ShipUpkeepTable = (props: Props) => {

    const [display, setDisplay] = React.useState<Display>("Upkeep");

    const shipTypeNameRows: { [key: string]: ShipRow }[] = [];

    function add(ships: { [key: string]: number } | undefined, append: (row: ShipRow, qty: number) => ShipRow) {

        if (!ships) {
            return;
        }

        for (let key in ships) {
            const quantity = ships[key];
            const row = key in shipTypeNameRows ? shipTypeNameRows[key]
                : {
                    available: 0,
                    moving: 0,
                    grounded: 0,
                    activating: 0,
                    total: 0
                };
            const newRow = append(row, quantity);

            shipTypeNameRows[key] = {
                ...newRow,
                total: newRow.available + newRow.moving + newRow.activating + (display === "Quantity" ? newRow.grounded : 0)
            };
        }
    }

    if (props.solarSystem.fleets) {
        for (let fleet of props.solarSystem.fleets.filter(x => x.homeSolarSystemId === props.solarSystem.solarSystemId)) {
            add(fleet.ships, (r, q) => {
                return {
                    ...r,
                    moving: r.moving + q
                }
            });
        }
    }
    if (props.solarSystem.activatingShips) {
        for (let activating of props.solarSystem.activatingShips) {
            add(activating.ships, (r, q) => {
                return {
                    ...r,
                    moving: r.moving + q
                }
            });
        }
    }

    add(props.solarSystem.availableShips, (r, q) => {
        return {
            ...r,
            available: r.available + q
        }
    });

    add(props.solarSystem.groundedShips, (r, q) => {
        return {
            ...r,
            grounded: r.grounded + q
        }
    });

    const choices = [
        choice<Display>("Upkeep", "Upkeep", IconHelper.Items.resource(props.gameSettings.solarSystem.shipUpkeepItemTypeName)),
        choice<Display>("Quantity", "Quantity", IconHelper.Ships.Ship)
    ];

    return <SubPanel
        heading={{ text: "Ship Upkeep", icon: IconHelper.Ships.Ship }}
        headingContent={<ButtonChooser values={choices} valueChanged={setDisplay} value={display} />}
    >
        <Paragraph>
            Ships cost upkeep even when they are not out on a mission. The upkeep costs are offset by the total hangar space available in a system.
        </Paragraph>
        <br />
        {display === "Upkeep" &&
            <Paragraph>
                Showing total ship upkeep in <ResourceTypeLink resourceTypeName={props.gameSettings.solarSystem.shipUpkeepItemTypeName} /> per hour. The upkeep costs for grounded ships do not count towards the actual total upkeep.
            </Paragraph>}
        {display === "Quantity" &&
            <Paragraph>
                Showing total ships
            </Paragraph>}
        <br />
        <Table isFullWidth heading={<>
            <th colSpan={2}>
                Ship
            </th>
            <th>
                Stationed
            </th>
            <th>
                Moving
            </th>
            <th>
                Grounded
            </th>
            <th>
                Activating
            </th>
            <th>
                Total
            </th>
        </>}>
            {Object.keys(shipTypeNameRows).map(s => <ShipRow key={s} display={display} {...shipTypeNameRows[s]} shipType={props.shipTypeSettings.data[s]} />)}
        </Table>
        {props.solarSystem.itemsDetail && <UpkeepSummary
            items={props.solarSystem.items}
            itemsDetail={props.solarSystem.itemsDetail}
            itemTypeSettings={props.itemTypeSettings}
        />}
    </SubPanel>;
}