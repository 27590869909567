import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { map_tactical, map_tactical_x_y } from '../../Routing/Map';
import { TutorialIds } from '../../Tutorial/TutorialIds';
import { MenuLink } from '../MenuState';

export const MapLink = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (solarSystemState.SolarSystem) {
        return <MenuLink
            id={TutorialIds.MapLink}
            to={map_tactical_x_y(solarSystemState.SolarSystem.x, solarSystemState.SolarSystem.y)}
            className="navbar-item is-capitalized"
        >
            Map
        </MenuLink>;
    }
    return <MenuLink
        id={TutorialIds.MapLink}
        to={map_tactical}
        className="navbar-item is-capitalized"
    >
        Map
    </MenuLink>;
});