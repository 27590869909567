import { animated, useSpring } from 'react-spring';
import { ISolarSystemMinimal } from '../../../../ApplicationState/ApiClient';
import { PanAndScale, RenderContext } from '../RenderContext';
import { AdditionalFleets, BackgroundFleets, IRenderFleet } from './AdditionalFleets';
import { Axis } from './Axis';
import { Grid } from './Grid';
import { AdditionalSolarSystems, OwnSolarSystems } from './SolarSystems';
import { SourceAndTargets } from './SourceAndTargets';

type Props = {
    renderContext: RenderContext,
    previous: PanAndScale,
    override: PanAndScale,
    additionalSolarSystems?: (ISolarSystemMinimal | undefined)[],
    additionalFleets?: (IRenderFleet | undefined)[],
    backgroundFleets?: IRenderFleet[]
}

type AnimatedProps = {
    renderContext: RenderContext,
    scale: number,
    positionX: number,
    positionY: number,
    additionalSolarSystems?: (ISolarSystemMinimal | undefined)[],
    additionalFleets?: (IRenderFleet | undefined)[],
    backgroundFleets?: IRenderFleet[]
}

const AnimatedPositionSvg = animated((props: AnimatedProps) => {

    const offset = (props.renderContext.size / 2) - ((props.renderContext.size / 2) * props.scale);

    const transform = "translate(" + offset + ", " + offset + ") translate(" + -props.positionX * props.scale + ", " + -props.positionY * props.scale + ") scale(" + props.scale + ") ";

    return <svg width={props.renderContext.size} height={props.renderContext.size} viewBox={`0 0 ${props.renderContext.size} ${props.renderContext.size}`}>
        <g transform={transform}>
            <Grid  {...props} gridLines={props.renderContext.worldSize} className="small" isHidden={props.scale < 10} />
            <Grid  {...props} gridLines={props.renderContext.worldSize / 10} className="big" />
            <Axis  {...props} />
            <OwnSolarSystems  {...props} />
            <AdditionalSolarSystems  {...props} />
            <BackgroundFleets {...props} />
            <AdditionalFleets {...props} />
            <SourceAndTargets  {...props} />
        </g>
    </svg>
});

const duration = 500;

export const PositionalSvg = (props: Props) => {

    const scaleSpring = useSpring({
        config: { duration },
        to: [
            //{ scale: props.previous.scale + 5 },
            { scale: props.renderContext.initialScale }
        ],
        from: {
            scale: props.previous.scale
        }
    });

    const xSpring = useSpring({
        config: { duration: duration },
        to: [
            { x: props.renderContext.firstCoordinate?.x ?? props.renderContext.boundingBox.centerX },
            { x: props.renderContext.boundingBox.centerX }
        ],
        from: {
            x: props.previous.x
        }
    });

    const ySpring = useSpring({
        config: { duration: duration },
        to: [
            { y: props.renderContext.firstCoordinate?.y ?? props.renderContext.boundingBox.centerY },
            { y: props.renderContext.boundingBox.centerY }
        ],
        from: {
            y: props.previous.y
        }
    });

    return <AnimatedPositionSvg
        {...props}
        scale={scaleSpring.scale}
        positionX={xSpring.x}
        positionY={ySpring.y}
    />;
}