import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { WorldStatus } from '../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../Components/Base/Form/Select';
import { ErrorMessageBox, WarningMessageBox } from '../../../Components/Base/MessageBox';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { StageProps } from './StageRender';

export const WorldStage = observer((props: StageProps) => {

    const nextBlocked = props.joinWorldBag.World === undefined || props.joinWorldBag.World.status === WorldStatus.Generating;

    function setWorld(worldId: number) {
        const world = props.joinWorldBag.Worlds.find(w => w.worldId === worldId);

        if (world !== undefined) {
            props.joinWorldBag.setWorld(world);
        }
    }

    return <SubPanel heading="Select World" footerContent={
        <ButtonHolder isPulledRight>
            <NextButton action={props.nextStage} isDisabled={nextBlocked} />
        </ButtonHolder>
    }>
        <Paragraph>
            Which world would you like to join?
        </Paragraph>
        <FieldHolder label="World">
            <Select className="is-fullwidth" valueChanged={e => setWorld(Number(e))} defaultValue={props.joinWorldBag.World && props.joinWorldBag.World.worldId}
                values={props.joinWorldBag.Worlds.map(w => {
                    return {
                        label: w.name,
                        value: w.worldId
                    }
                })} />
        </FieldHolder>
        <br />
        {props.joinWorldBag.World && <>
            {props.joinWorldBag.World.status === WorldStatus.Generating && <ErrorMessageBox text={"That world is still being generated and so can't be joined at this time. Please try again later."} />}
            {props.joinWorldBag.World.status === WorldStatus.LockedToAdmins && <WarningMessageBox text={"The selected world is locked to admins and you will need to provide an admin password to join."} />}
        </>}
    </SubPanel>;
});