import { SubPanel } from "../../../../Components/Base/Containers/SubPanel"
import { Table } from "../../../../Components/Base/Containers/Table"
import { Paragraph } from "../../../../Components/Base/Text/Paragraph"
import { PlayerLink } from "../../../../Components/FusionShift/Links/PlayerLink"
import { SolarSystemLink } from "../../../../Components/FusionShift/Links/SolarSystemLink"
import { IconHelper } from "../../../../Helpers/IconHelper"
import { ValueFormatter } from "../../../../Helpers/ValueFormatter"
import { AcquiredTarget } from "../Entities"
import { TargetButtons } from "./TargetButtons"

type Props = {
    targets: AcquiredTarget[]
}

type RowProps = {
    target: AcquiredTarget
}

const TargetRow = (props: RowProps) => {

    const closest = props.target.nearestSolarSystem;

    return <>
        <tr>
            <td>
                <PlayerLink player={props.target.solarSystem.owner} hideFederationLink />
            </td>
            <td>
                <SolarSystemLink
                    solarSystem={props.target.solarSystem}
                    showClaimTypeIcon
                    hideActionIcons />
            </td>
            <td>
                {closest !== undefined &&
                    ValueFormatter.formatDistance(closest.distance)
                }
            </td>
            <td>
                {closest !== undefined &&
                    <SolarSystemLink
                        solarSystem={closest.solarSystem}
                        showClaimTypeIcon
                        hideActionIcons
                    />
                }
            </td>
            <td>
                <TargetButtons solarSystem={props.target.solarSystem} />
            </td>
        </tr>
        {props.target.additionalTargets
            .sort((a, b) => a.distance - b.distance)
            .map(x => <tr key={x.solarSystem.solarSystemId}>
                <td />
                <td>
                    <SolarSystemLink
                        solarSystem={x.solarSystem}
                        hideActionIcons
                    />
                </td>
                <td>
                    {ValueFormatter.formatDistance(x.distance)}
                </td>
                <td />
                <td>
                    <TargetButtons solarSystem={x.solarSystem} />
                </td>
            </tr>)}
    </>
}

export const TargetTable = (props: Props) => {

    const isAny = props.targets.length > 0;

    const targets = props.targets.sort((a, b) => {

        return (a.nearestSolarSystem?.distance ?? 0) - (b.nearestSolarSystem?.distance ?? 0);
    });

    return <SubPanel
        heading={{
            text: "Targets",
            icon: IconHelper.Combat.Target
        }}
        isUnpadded={isAny}
    >
        {!isAny && <Paragraph>
            No targets found.
        </Paragraph>}
        {isAny && <Table
            isFullWidth
        >
            {targets.map(x => <TargetRow
                key={x.solarSystem.solarSystemId}
                target={x}
            />)}
        </Table>}
    </SubPanel>
}