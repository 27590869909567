import { ICelestialDetail, IInstallationTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { manufactoryProblemBuilder } from "./ManufactoryProblems";

export function subOptimalOperationLevelProblems(installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper, celestial: ICelestialDetail): ProblemsMaybe {

    if (!celestial.manufactory) {
        return undefined;
    }

    const subOptimalInstallations = [
        ...celestial.manufactory.inputNodes,
        ...celestial.manufactory.outputNodes
    ].map(x => {
        return {
            node: x,
            installation: celestial.installations ? celestial.installations.find(i => i.installationId === x.installationId) : undefined
        }
    }).filter(x => x.installation && x.installation.operationLevel < x.installation.level)
        .map(x => x.installation!);

    const filtered = subOptimalInstallations.filter((x, i) => subOptimalInstallations.indexOf(x) === i);

    return filtered.map(x => manufactoryProblemBuilder.infoProblem("Suboptimal Operation Level",
        `The ${installationTypeSettings.data[x.installationTypeName].name} [${x.level}] at the Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} is running at operation level ${x.operationLevel}`
    ));
}