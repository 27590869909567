import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Countdown from 'react-countdown';
import { FleetMovementType, IApocalypseShot, IFleet, IPlayerForSelf, ISolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { Icon } from '../../../Components/Base/Icon';
import { SolarSystemInterceptFleetButton } from '../../../Components/FusionShift/Buttons/InterceptFleetButton';
import { FleetArrivalDescription } from '../../../Components/FusionShift/FleetArrivalDescription';
import { FleetIcon } from '../../../Components/FusionShift/Icons/FleetIcon';
import { FleetLocationLink } from '../../../Components/FusionShift/Links/FleetLocationLink';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { SelectSolarSystemLink, SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    showSolarSystemLink?: boolean,
    solarSystems: SolarSystemWrapper[]
}

type TableProps = {
    player: IPlayerForSelf,
    showSolarSystemLink?: boolean,
    solarSystem: {
        solarSystem: ISolarSystemDetail,
        attacks: IFleet[],
        apocalypseShots: IApocalypseShot[]
    },
    solarSystems: SolarSystemWrapper[]
}

type InterceptProps = {
    fleet: IFleet,
    player: IPlayerForSelf,
    solarSystems: {
        solarSystem: SolarSystemWrapper,
        distance: number
    }[]
}

const InterceptRows = (props: InterceptProps) => {

    return <Table
        isFixed isFullWidth
        heading={<>
            <th>
                Intercept From
            </th>
            <th>
                Distance
            </th>
            <th />
        </>}
    >
        {props.solarSystems.map(x => <tr key={x.solarSystem.solarSystemId}>
            <td>
                <SelectSolarSystemLink solarSystem={x.solarSystem.solarSystem} />
            </td>
            <td>
                {ValueFormatter.formatDistance(x.distance)}
            </td>
            <td>
                <SolarSystemInterceptFleetButton
                    targetFleet={props.fleet}
                    availableShips={x.solarSystem.availableShips}
                    solarSystemId={x.solarSystem.solarSystemId}
                />
            </td>
        </tr>)}
    </Table>;
}

const SolarSystemAttackRows = (props: TableProps) => {

    const [expandedFleetIds, setExpandedFleetIds] = React.useState<number[]>([]);

    function toggle(fleetId: number) {
        if (expandedFleetIds.includes(fleetId)) {
            setExpandedFleetIds(expandedFleetIds.filter(x => x !== fleetId));
        } else {
            setExpandedFleetIds([...expandedFleetIds, fleetId]);
        }
    }

    return <>
        {props.solarSystem.attacks.map((x, i) => {

            const interceptRows = props.solarSystems.map(s => {

                return {
                    solarSystem: s,
                    canIntercept: FleetHelper.canIntercept(x, props.player, s.availableShips),
                    distance: FleetHelper.distance({ x: x.currentX ?? 0, y: x.currentY ?? 0 }, s.solarSystem)
                }
            })
                .filter(x => x.canIntercept)
                .sort((a, b) => a.distance - b.distance);

            const canIntercept = interceptRows.length > 0;
            const isExpanded = expandedFleetIds.includes(x.fleetId);

            return <React.Fragment key={x.fleetId}>
                <tr>
                    {props.showSolarSystemLink &&
                        <td>
                            {i === 0 && <SelectSolarSystemLink hideActionIcons solarSystem={props.solarSystem.solarSystem} />}
                        </td>
                    }
                    <td>
                        <FleetIcon fleet={x} />
                    </td>
                    <td>
                        <FleetArrivalDescription fleet={x} />
                    </td>
                    <td>
                        <FleetLocationLink fleet={x} type="source" /> <br />
                    </td>
                    <td>
                        {x.owner && <PlayerLink player={x.owner} />}
                        {!x.owner && "Unknown"}
                    </td>
                    <td>
                        {ValueFormatter.formatMass(x.mass, true)}
                    </td>
                    <td>
                        {canIntercept &&
                            <ExpandButton
                                isExpanded={isExpanded}
                                setIsExpanded={v => toggle(x.fleetId)}
                            />}
                    </td>
                </tr>
                {isExpanded && <tr>
                    <td colSpan={6 + (props.showSolarSystemLink ? 1 : 0)}>
                        <InterceptRows
                            {...props}
                            fleet={x}
                            solarSystems={interceptRows}
                        />
                    </td>
                </tr>}
            </React.Fragment>;
        })}
        {props.solarSystem.apocalypseShots.map((x, i) => <tr key={x.apocalypseShotId}>
            {props.showSolarSystemLink &&
                <td>
                    {i === 0 && props.solarSystem.attacks.length === 0 && <SelectSolarSystemLink hideActionIcons solarSystem={props.solarSystem.solarSystem} />}
                </td>
            }
            <td>
                <Icon className="has-text-danger" icon={IconHelper.Celestials.Apocalypse} />
            </td>
            <td>
                <Countdown date={x.nextUpdateDate} />
            </td>
            <td>
                <SolarSystemLink solarSystem={x.sourceSolarSystem} />
            </td>
            <td colSpan={3}>
                <PlayerLink player={x.sourceSolarSystem.owner} />
            </td>
        </tr>)}
    </>;
};

export const IncomingAttacks = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const solarSystems = React.useMemo(() => {

        if (worldState.GameSettings === undefined) {
            return [];
        }

        return props.solarSystems.map(x => {
            return {
                solarSystem: x.solarSystem,
                attacks: x.fleets.arrivals
                    .filter(y => y.ownerPlayerId !== x.solarSystem.playerId && (y.movementType === FleetMovementType.Attack || y.movementType === FleetMovementType.Recon))
                    .sort((a, b) => (a.arrivalDate?.getTime() ?? 0) - (b.arrivalDate?.getTime() ?? 0))
                ,
                apocalypseShots: x.solarSystem.apocalypseShots
                    .filter(y => y.sourceSolarSystem.solarSystemId !== x.solarSystem.solarSystemId)
                    .sort((a, b) => a.nextUpdateDate.getTime() - b.nextUpdateDate.getTime())
            }
        })
            .filter(x => x.attacks.length > 0 || x.apocalypseShots.length > 0);

    }, [props.solarSystems, worldState.GameSettings]);

    if (solarSystems.length === 0 || !playerState.Player) {
        return null;
    }

    return <SubPanel heading={{ text: "Incoming Attacks", icon: IconHelper.Combat.Attack }} isUnpadded>
        <Table isHoverable isFullWidth
            heading={
                <>
                    {props.showSolarSystemLink &&
                        <th></th>
                    }
                    <th></th>
                    <th>Arrives</th>
                    <th>Source</th>
                    <th>Owner</th>
                    <th>Mass</th>
                    <th></th>
                </>
            }
        >
            {solarSystems.map(x => <SolarSystemAttackRows
                key={x.solarSystem.solarSystemId}
                solarSystem={x}
                showSolarSystemLink={props.showSolarSystemLink}
                player={playerState.Player!}
                solarSystems={props.solarSystems}
            />
            )}
        </Table>
    </SubPanel>;
});