import * as React from 'react';
import { ProblemSolution } from '../../Entities/SolarSystem/Problems/Problem';
import { Paragraph } from '../Base/Text/Paragraph';

type Props = {
    solution: ProblemSolution | undefined
}

export const ProblemSolutionLink = (props: Props) => {

    if (true) {
        return null;
    }

    return <Paragraph>Problem</Paragraph>;
};