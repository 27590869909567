import React from "react";
import { EmblemPiece, InGameProduct, Manifest } from "../../../../ApplicationState/ApiClient";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { Icon } from "../../../../Components/Base/Icon";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { EmblemCard } from "./EmblemCard";

type Props = {
  manifest: Manifest;
  onEmblemBadgeClick: (emblemPiece: EmblemPiece) => any;
  getPremiumEmblem: (filename: string) => InGameProduct | undefined;
  setPurchasingPremiumBundle: (inGameProduct: InGameProduct) => any;
  getPremiumBundlePercentageSaving: (inGameProductId: number, emblems: EmblemPiece[]) => number;
  premiumBundle: InGameProduct | undefined;
  hasPurchasedPremiumBundle: (inGameProductId: number) => boolean;
  hasPurchasedPremiumEmblem: (filename: string) => boolean | undefined;
  getPremiumBundleEmblemPieces: (inGameProductId: number) => EmblemPiece[];
  selectedEmblemFilename: string | undefined;
  emblemsCdnUrl: string | undefined;
}

export const BundleEmblemCards = (props: Props) => {
  if (!props.premiumBundle) {
    return null;
  }

  const emblems = props.getPremiumBundleEmblemPieces(props.premiumBundle.inGameProductId);

  const hasPurchased = props.hasPurchasedPremiumBundle(props.premiumBundle.inGameProductId);

  const premiumBundlePercentageSaving = !hasPurchased ? props.getPremiumBundlePercentageSaving(props.premiumBundle.inGameProductId, emblems) : 0;

  return <>
    {!hasPurchased && <>
      {premiumBundlePercentageSaving > 0 && <span>Save {Math.round(premiumBundlePercentageSaving)}% and buy the bundle for</span>}
      {premiumBundlePercentageSaving <= 0 && <span>Buy the bundle for</span>}
      <span><Icon icon={IconHelper.Premium.Currency} />{props.premiumBundle.unitCost}</span>&nbsp;&nbsp;
      <Button
        type="action"
        text="Buy Bundle"
        icon={IconHelper.General.Confirm}
        action={() => props.setPurchasingPremiumBundle(props.premiumBundle!)} />
    </>}
    <div className={"horizontal-scroll-wrapper"}>
      {(emblems &&
        emblems.length > 0 &&
        emblems.map(x => <EmblemCard
          key={x.fileName}
          emblemsCdnUrl={props.emblemsCdnUrl}
          emblem={x}
          premiumEmblem={props.getPremiumEmblem(x.fileName)}
          hasPurchased={props.hasPurchasedPremiumEmblem(x.fileName)}
          isSelected={props.selectedEmblemFilename == x.fileName}
          onEmblemBadgeClick={() => props.onEmblemBadgeClick(x)} />
        )) || <p>No badge found</p>}
    </div>
  </>;
}
