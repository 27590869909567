import { observer } from "mobx-react-lite";
import React from "react";
import { PolicyType } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext } from "../../../ApplicationState/ContextRoot";
import { PolicyExtras } from "../../../Views/Politics/Components/PolicyExtras";
import { RichTextArray } from "../RichTextArray";
import { PolicyTypeDuration } from "./PolicyTypeDuration";

type Props = {
    policyType: PolicyType
}

export const PolicyTypeDescription = observer((props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const description = descriptionState.Policies?.find(x => x.typeName === props.policyType.typeName);

    return <>
        <RichTextArray text={description?.description} />
        <br />
        <PolicyTypeDuration {...props} />
        <br />
        <PolicyExtras policyType={props.policyType} />
    </>;
});