import * as React from "react";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { ArrayHelper } from "../../Helpers/ArrayHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { hypeImageConfig } from "../../images/HypeImageConfig";

function piece(icon: string, title: string, content: string) {
    return {
        icon, title, content
    }
}

const allHypes = [
    piece("wrench", "Build", "Build and upgrade installations on planets to harvest and store resources, construct fleets and defend yourself against aggressors."),
    piece("city", "Grow", "Manage Megacities on habitable planets to provide special bonuses to your systems. Strong Agricultural and Industrial sectors will provide boosts to resource harvesting. A satisfied Military sector will bolster your defences while catering to your Science sectors will lead to faster research breakthroughs."),
    piece("link-variant", "Produce", "A well planned supply chain will ensure your Manufacturies are always working at full capacity. The commodities produced will satisfy your Megacities and any surpluses can be sold to neighbours for a profit."),
    piece("shovel", "Mine", "Send your intrepid mining barges to unoccupied solar systems to harvest resources that would otherwise be out of your grasp. It is often advisable to send an escort with them because they can be a juicy target if left undefended."),
    piece("sword-cross", "Raid", "Stealing resources from your poorly defended neighbours or their mining fleets is a great supplement to your stockpiles. Or you can completely indulge in your piracy streak and have looting be your main source of income."),
    piece("expand-all", "Expand", "Conquer unoccupied solar systems to spread your influence. More solar systems mean more wealth and more power!"),
    piece("crown", "Conquer", "Sometimes anything other than complete obliteration of your foes just won't do. Destroy their forces and then lay siege to their infrastructure. Send your orbital siege ships to remove them from their planets - it's like they never existed..."),
    piece("binoculars", "Scout", "Fail to prepare and prepare to fail. Send your recon ships to gather valuable intelligence on potential threats or victims in your vicinity."),
    piece("account-multiple", "Cooperate", "There's strength in numbers! Join or start a Federation and coordinate your plans with other players."),
    piece("gift", "Trade", "Money is power. Trade your excess resources and commodities with other players and use the profits to expand your influence."),
    piece("vote", "Politics", "Ask not what your galaxy can do for you, but what you can do for your galaxy. Vote on issues that all players or even run for the Galactic Presidency yourself."),
    piece("compass", "Explore", "Eventually the Core region will start to feel too safe and lacking in adventure. Explore and settle the Outer and Expanse regions for rare resources. Be warned - more reward comes at the cost of more risk."),
    piece("fso-icon icon-FS_Status_overt", "Agents", "Hire and train agents to act as managers, diplomats and covert operatives. Agents can act in clandestine ways to disrupt your foes.")
];

type ImageProps = {
    type: string
}

const HypeImage = (props: ImageProps) => {

    const reducer = (v: number, mod: number | "increment") => {
        if (typeof mod === "number") {
            return mod;
        }

        const max = hypeImageConfig[props.type];

        if (v + 1 >= max) {
            return 0;
        }

        return v + 1;
    }

    function getRandom() {
        const r = Math.floor(Math.random() * (hypeImageConfig[props.type] - 1))

        return r;
    }

    const [variant, dispatcher] = React.useReducer(reducer, 0)

    React.useEffect(() => {
        dispatcher(getRandom());
    }, [props.type]);

    React.useEffect(() => {

        const interval = setInterval(() => {

            dispatcher("increment");

        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return <img src={`/images/hype/${props.type}_${variant}.jpg`} className="hype-image" />
}

export const HypeSection = () => {

    const [index, setIndex] = React.useState(0);

    const hypes = React.useMemo(() => {
        return ArrayHelper.shuffle(allHypes);
    }, []);

    function moveNext() {
        setIndex(index < hypes.length - 1 ? index + 1 : 0);
    }

    function moveBack() {
        setIndex(index === 0 ? hypes.length - 1 : index - 1);
    }

    const hype = hypes[index];

    return <SubPanel
        heading={{ text: hype.title, icon: hype.icon }}
        isUnpadded
        headingContent={<ButtonHolder isPulledRight>
            <Button
                hideTextOnMobile
                type="nav"
                icon={IconHelper.General.Previous}
                text="Back"
                action={moveBack}
            />
            <Button
                hideTextOnMobile
                type="nav"
                icon={IconHelper.General.Next}
                text="Next"
                action={moveNext}
            />
        </ButtonHolder>}
    >
        <SplitLayout
            horizontalAfter="mobile"
            className="hype-holder"
            left={
                <div className="is-flex">
                    <HypeImage type={hype.title} />
                </div>
            }
            right={
                <Paragraph>{hype.content}</Paragraph>
            }
        />
    </SubPanel>;
};