import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { PlayerHeading } from '../../Player/PlayerHeading';
import { EmpireViewProps } from '../EmpireViewProps';
import { Advisor } from './Advisor';
import { BuildQueues } from './BuildQueues/BuildQueues';
import { IncomingAttacks } from './IncomingAttacks';
import { Items } from './Items/Items';

export const SummaryView = observer((props: EmpireViewProps) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    return <>
        <PlayerHeading player={playerState.Player} />
        <IncomingAttacks {...props} showSolarSystemLink />
        <Items {...props} />
        <hr />
        <BuildQueues {...props} />
        <hr />
        <Advisor {...props} />
    </>;
});
