import * as React from "react";
import { CreateMarketOrderInput, ICreateMarketOrderInput } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Checkbox } from "../../Components/Base/Form/Checkbox";
import { FieldHolder } from "../../Components/Base/Form/FieldHolder";
import { FormFooterValidation } from "../../Components/Base/Form/FormFooter";
import { NumberInput } from "../../Components/Base/Form/Input";
import { Icon } from "../../Components/Base/Icon";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { ItemChooser } from "../../Components/FusionShift/Items/ItemChooser";
import { valueValid } from "../../Entities/Validation";
import { IconHelper } from "../../Helpers/IconHelper";
import { MarketOrderHelper } from "../../Helpers/MarketOrderHelper";
import { MathHelper } from "../../Helpers/MathHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { market_orders } from "../../Navigation/Routing/Market";
import { MarketOrderSolarSystemSummary } from "./Components/MarketOrderSolarSystemSummary";
import { MarketTradeSummary } from "./Components/MarketTradeSummary";
import { MarketOrderBasePage, MarketOrderProps } from "./MarketOrderBasePage";

const MarketOrderForm = (props: MarketOrderProps) => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [quantityOffered, setQuantityOffered] = React.useState(0);
    const [quantityDesired, setQuantityDesired] = React.useState(0);
    const [ratio, setRatio] = React.useState(1);
    const [desiredItemTypeName, setDesiredItemTypeName] = React.useState<string | undefined>(undefined);
    const [offeredItemTypeName, setOfferedItemTypeName] = React.useState<string | undefined>(undefined);
    const [federationOnly, setFederationOnly] = React.useState(false);
    const [maxQuantity, setMaxQuantity] = React.useState(0);

    const [validation, setValidation] = React.useState(valueValid(false, ""));

    React.useEffect(() => {
        setValidation(validate());
    }, [quantityOffered, desiredItemTypeName, offeredItemTypeName, ratio, maxQuantity, quantityDesired]);

    React.useEffect(() => {
        let max = 0;
        if (offeredItemTypeName) {
            max = props.solarSystem.items && offeredItemTypeName in props.solarSystem.items ? props.solarSystem.items[offeredItemTypeName] : 0;
        }
        setMaxQuantity(max);
    }, [offeredItemTypeName]);

    const usedMarketOrderQuantity = MarketOrderHelper.usedMarketQuantity(props.marketOrders);
    const capacityOk = usedMarketOrderQuantity < props.solarSystem.marketCapacity;
    const quantityOk = props.marketOrders.length < props.solarSystem.marketOrderQuantity;
    const canMakeMarketOrder = capacityOk && quantityOk;

    function validate() {
        if (desiredItemTypeName === undefined || offeredItemTypeName === undefined) {
            return valueValid(false, "Select something to trade")
        }
        if (desiredItemTypeName === offeredItemTypeName) {
            return valueValid(false, "Offer and desire can't be the same good")
        }
        if (quantityOffered <= 0) {
            return valueValid(false, "Quantity must be greather than zero")
        }
        if (quantityDesired <= 0) {
            return valueValid(false, "Quantity must be greather than zero")
        }
        if (quantityOffered > maxQuantity && offeredItemTypeName !== worldState.GameSettings!.economy.creditsPlaceholderItemTypeName) {
            return valueValid(false, "Quantity exceeds available amount")
        }
        if (ratio < worldState.GameSettings!.marketplace.minimumRatio) {
            return valueValid(false, `Ratio must be greather than ${worldState.GameSettings!.marketplace.minimumRatio}`)
        }
        if (usedMarketOrderQuantity + quantityOffered > props.solarSystem.marketCapacity) {
            return valueValid(false, "Quantity would exceed market order capacity")
        }
        return valueValid(true);
    }

    function changeRatio(newRatio: number) {
        const clamped = MathHelper.clamp(newRatio, 0, worldState.GameSettings!.marketplace.maximumRatio)
        setRatio(Number(clamped.toFixed(worldState.GameSettings!.marketplace.ratioPrecisionDecimalPlaces)));
    }

    function changeDesired(itemTypeNaem: string | undefined) {
        setDesiredItemTypeName(itemTypeNaem);
    }

    function changeOffered(itemTypeName: string | undefined) {
        setOfferedItemTypeName(itemTypeName);
    }

    function changeQuantityOffered(quantity: number) {
        setQuantityOffered(quantity);
        setQuantityDesired(quantity);
        setRatio(1);
    }

    function changeQuantityDesired(quantity: number) {
        if (quantity > 0) {
            setQuantityDesired(quantity);
            setRatio(quantityOffered / quantity);
        }
    }

    function create() {
        if (!validation.valid) {
            return false;
        }

        const data: ICreateMarketOrderInput = {
            ratioOfferedToDesired: ratio,
            quantity: quantityOffered,
            desiredItemTypeName: desiredItemTypeName!,
            offeredItemTypeName: offeredItemTypeName!,
            federationOnly: federationOnly
        };

        return apiState.MarketOrderClient.create(props.solarSystem.solarSystemId, new CreateMarketOrderInput(data)).then(_ => {
            appState.navigate(market_orders);
        })
    }

    function createOrderSummary() {
        if (!desiredItemTypeName || !offeredItemTypeName || !validation.valid) {
            return null;
        }

        return <>
            <MarketTradeSummary verbText="You are offering" isWrapped
                offeredItemTypeName={offeredItemTypeName} quantityOfOffered={quantityOffered}
                desiredItemTypeName={desiredItemTypeName} quantityOfDesired={quantityOffered / ratio}
            />
            {ratio > 1 && <div className="has-text-warning">
                <Icon icon={IconHelper.Statuses.Warning} />
                This trade offers more than will be received
            </div>}
        </>;
    }

    const availableItems = props.solarSystem.items ? Object.keys(props.solarSystem.items)
        .filter(k => props.solarSystem.items![k] > 0)
        : [];

    const errors = [
        !capacityOk ? "capacity" : undefined,
        !quantityOk ? "order quantity" : undefined
    ]
        .filter(e => e != undefined)
        .map(e => e!);
    const errorText = errors.join(" and ");

    const finalErrorText = errors.length >= 2 ? `Market ${errorText} limits are reached.` :
        errors.length === 1 ? `Market ${errorText} limit is reached.` : "";

    return <>
        <MarketOrderSolarSystemSummary solarSystem={props.solarSystem} marketOrders={props.marketOrders} />
        <SubPanel heading={"New Market Order"}
            footerContent={canMakeMarketOrder && <Button type="action" icon={IconHelper.Market.Create} action={() => create()} text={"Create Market Order"} isDisabled={!validation.valid} isPulledRight />}
        >
            {errors.length > 0 && <Paragraph type="danger">{finalErrorText} Build or upgrade market installations to increase {errorText}.</Paragraph>}
            {canMakeMarketOrder && <>
                <SplitLayout
                    left={
                        <FieldHolder label="Offer">
                            <ItemChooser setItemTypeName={changeOffered} allowedItems={availableItems} />
                        </FieldHolder>
                    }
                    right={
                        <FieldHolder label="Desire">
                            <ItemChooser setItemTypeName={changeDesired} />
                        </FieldHolder>
                    } />
                <SplitLayout
                    left={
                        <FieldHolder label="Quantity Offered" hasAddons
                            extra={maxQuantity > 0 && `${ValueFormatter.formatLocaleNumber(maxQuantity)} available`}
                        >
                            <NumberInput size={8} value={quantityOffered} valueChanged={changeQuantityOffered} />
                        </FieldHolder>
                    }
                    right={
                        <FieldHolder label="Quantity Desired" hasAddons>
                            <NumberInput size={8} value={quantityDesired} valueChanged={changeQuantityDesired} />
                        </FieldHolder>
                    } />
                <div>
                    <Checkbox label="Federation only" value={federationOnly} valueChanged={f => setFederationOnly(f)} />
                </div>
                <br />
                <FormFooterValidation validation={validation} extra={createOrderSummary()} />
            </>
            }
        </SubPanel>
    </>;
};

export const MarketOrderCreate = () => {
    return <MarketOrderBasePage content={MarketOrderForm} title={"Create Market Order"} icon={IconHelper.Market.Create} />;
};