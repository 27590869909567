import * as React from 'react';
import { IMessageInput, MessageInput, ValidationResult } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { ValidatingStringInput } from '../../../Components/Base/Form/ValidatingStringInput';
import { ValidatingTextInput } from '../../../Components/Base/Form/ValidatingTextInput';
import { ValidationHelper } from '../../../Components/Base/Form/ValidationHelper';
import { SuccessMessageBox } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    worldId: number
}

export const SendMessageToAllControl = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const [isSent, setIsSent] = React.useState(false);
    const [subject, setSubject] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [body, setBody] = React.useState<ValidationResult>(ValidationHelper.empty());

    function send() {
        const data: IMessageInput = {
            subject: subject.validText!,
            body: body.validText!
        };
        const message = new MessageInput(data);

        return apiState.AdminClient.messageToAllPlayers(message).then(_ => {
            setIsSent(true);
        });
    }

    function validateSubject(subject: string) {
        return apiState.MessageClient.validateSubject(subject);
    }

    function validateBody(body: string) {
        return Promise.resolve(new ValidationResult({
            isValid: true,
            validText: body
        }));
    }

    if (isSent) {
        return <SuccessMessageBox text={"Message sent"} />;
    }

    return <SubPanel heading={"To: All players in the world"}
        footerContent={
            <Button type="action" isPulledRight text="Send To All" icon={IconHelper.General.Confirm} action={send} isDisabled={!body.isValid || !subject.isValid} />
        }>
        <ValidatingStringInput
            label="Subject"
            icon={IconHelper.General.Message}
            validation={validateSubject}
            valueChanged={setSubject}
        />
        <ValidatingTextInput
            placeholder={"Your message here"}
            label="Message"
            validation={validateBody}
            valueChanged={setBody}
            maxLength={4000}
        />
    </SubPanel>;
};