import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IMapGrid, IWorldMinimal } from "../../../ApplicationState/ApiClient";
import { SolarSystemStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { Select } from '../../../Components/Base/Form/Select';
import { Coordinate } from '../../../Entities/Shared';
import { IconHelper } from '../../../Helpers/IconHelper';
import { mapSize } from '../MapSize';
import { MapPreferences } from './MapPreferences';
import { PreferencesControl } from './PreferencesControl';

type Props = {
    celestialTypeName: string | undefined,
    setCelestialTypeName: (celestialTypeName: string | undefined) => any,
    map: IMapGrid,
    coord: Coordinate,
    setCoord: (coord: Coordinate, instant: boolean) => any,
    preferences: MapPreferences,
    modifyPreferences: (action: (prefs: MapPreferences) => any) => any
}

type PanProps = {
    isDisabled?: boolean,
    icon: string,
    direction: Coordinate,
    map: IMapGrid,
    coord: Coordinate,
    world: IWorldMinimal,
    setCoord: (coord: Coordinate) => any
}

type CoordinateInputProps = {
    value: number,
    valueChanged: (newVal: number) => any
}

const CoordinateInputProps = (props: CoordinateInputProps) => {

    const [valText, setValText] = React.useState<string>(props.value.toString());

    function valueChanged(e: any) {
        const val = e.target.value;

        setValText(val);

        const value = Number(val);
        if (!isNaN(value)) {
            props.valueChanged(value);
        }
    }

    const handleFocus = (event) => event.target.select();

    return <input
        className="form-control input"
        type="text"
        inputMode="numeric"
        size={4}
        maxLength={4}
        value={valText}
        onChange={valueChanged}
        onFocus={handleFocus}
    />
}

const PanButton = (props: PanProps) => {

    const borderTolerance = ((mapSize.fullhd - mapSize.mobile) / 2) + 1;

    const newTarget = { x: props.coord.x + (3 * props.direction.x), y: props.coord.y + (3 * props.direction.y) };

    const width = props.map.width / 2;
    const height = props.map.height / 2;

    const tooNorth = props.direction.y < 0 && newTarget.y - height < props.world.minY - 1;
    const tooSouth = props.direction.y > 0 && newTarget.y + height > props.world.maxY + 1;
    const tooEast = props.direction.x > 0 && newTarget.x + width > props.world.maxX + borderTolerance;
    const tooWest = props.direction.x < 0 && newTarget.x - width < props.world.minX - borderTolerance;

    const disabled = tooEast || tooWest || tooNorth || tooSouth;

    return <Button type="nav" icon={props.icon} action={() => props.setCoord(newTarget)} isDisabled={disabled || props.isDisabled} />;
}

export const HeaderButtons = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [x, setX] = React.useState(props.coord.x);
    const [y, setY] = React.useState(props.coord.y);
    const [hasChanged, setHasChanged] = React.useState(false);

    React.useEffect(() => {
        setHasChanged(x !== props.coord.x || y !== props.coord.x);
    }, [x, y, props.coord]);

    React.useEffect(() => {
        setX(props.coord.x);
    }, [props.coord.x]);

    React.useEffect(() => {
        setY(props.coord.y);
    }, [props.coord.y]);

    function teleport() {
        props.setCoord({ x, y }, true);
    }

    function pan(coord: Coordinate) {
        props.setCoord(coord, false);
    }

    if (!worldState.World || !worldState.CelestialTypeSettings) {
        return null;
    }

    const values = [
        {
            label: "Find Celestials...",
            value: "ANY"
        },
        ...Object.values(worldState.CelestialTypeSettings.data)
            .sort((a, b) => a.order - b.order)
            .map(x => {
                return {
                    value: x.typeName,
                    label: x.name
                };
            })];

    return <div className="map-buttons-holder">
        <div className="map-buttons">
            <Select value={props.celestialTypeName ?? "ANY"} valueChanged={e => props.setCelestialTypeName(e !== "ANY" ? e : undefined)} values={values} isCompact />
            <div className="navigate">
                <CoordinateInputProps value={x} valueChanged={setX} />
                <CoordinateInputProps value={y} valueChanged={setY} />
                <Button type="nav" icon={IconHelper.General.Confirm} isDisabled={!hasChanged} action={teleport} />
            </div>
            <ButtonHolder className="pan-buttons">
                <PanButton icon={IconHelper.General.Left} direction={{ x: -1, y: 0 }} world={worldState.World} {...props} setCoord={pan} />
                <PanButton icon={IconHelper.General.Up} direction={{ x: 0, y: -1 }} world={worldState.World}  {...props} setCoord={pan} />
                <PanButton icon={IconHelper.General.Down} direction={{ x: 0, y: 1 }} world={worldState.World} {...props} setCoord={pan} />
                <PanButton icon={IconHelper.General.Right} direction={{ x: 1, y: 0 }} world={worldState.World}{...props} setCoord={pan} />
                {solarSystemState.SolarSystem &&
                    <Button type="nav" icon={IconHelper.Categories.Home} action={() => props.setCoord({ x: solarSystemState.SolarSystem!.x, y: solarSystemState.SolarSystem!.y }, true)} />
                }
                <PreferencesControl preferences={props.preferences} modifyPreferences={props.modifyPreferences} />
            </ButtonHolder>
        </div>
    </div>;
});