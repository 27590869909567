import * as React from 'react';
import { IShipConstructionAutomation, IShipTypeSettings, ISolarSystemAutomationConfiguration, ShipConstructionAutomation } from '../../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { OnOffButtons } from '../../../../Components/Base/Buttons/OnOffButtons';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { LoadingWrapper } from '../../../../Components/Base/Loading/LoadingWrapper';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { SimpleShipInputList } from '../../../SendShips/Components/SimpleShipInputList';

type Props = {
    isEnabled: boolean,
    save: (x: ISolarSystemAutomationConfiguration) => any,
    configuration: ISolarSystemAutomationConfiguration,
    setConfiguration: (x: ISolarSystemAutomationConfiguration) => any,
    shipTypeSettings: IShipTypeSettings,
    isSaving: boolean
}

export const ShipConstructionAutomationEditor = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const [isDirty, setIsDirty] = React.useState(false);

    function edit(save: boolean, modify: (e: IShipConstructionAutomation) => IShipConstructionAutomation) {

        if (!save) {
            setIsDirty(true);
        }

        const edited = modify(props.configuration.shipConstruction);
        const c = {
            ...props.configuration,
            shipConstruction: new ShipConstructionAutomation(edited)
        };

        props.setConfiguration(c);

        if (save) {
            props.save(c);
        }
    }

    function save() {
        edit(true, x => x);
    }

    const shipConstructionConfiguration = props.configuration.shipConstruction;

    const enabled = props.isEnabled && props.configuration.shipConstruction.isEnabled;

    return <SubPanel
        heading={{ text: "Ship Construction", icon: IconHelper.Ships.Build }}
        headingContent={<OnOffButtons
            isOn={shipConstructionConfiguration.isEnabled}
            setIsOn={e => edit(true, x => {
                return { ...x, isEnabled: e }
            })}
            isDisabled={!props.isEnabled}
        />}
    >
        <LoadingWrapper isLoading={props.isSaving}>
            <Paragraph>Automated construction of ships is {enabled ? "enabled" : "disabled"}.</Paragraph>
            <Paragraph>
                If ship construction is automated then the system will try to maintain a set number of each ship type. Available ships, ships being assembled, grounded ships and ships in fleets are all counted when determining how many to enqueue.
            </Paragraph>
            {props.configuration.shipConstruction.isEnabled &&
                <SimpleShipInputList
                    isDisabled={!props.isEnabled}
                    factionTypeName={playerState.Player?.factionTypeName ?? ""}
                    shipTypeSettings={props.shipTypeSettings}
                    shipQuantities={props.configuration.shipConstruction.shipTypeNamesToMaintain}
                    setShipQuantity={(s, q) => {
                        edit(false, e => {
                            e.shipTypeNamesToMaintain[s.ship.typeName] = q;
                            return {
                                ...e
                            };
                        })
                    }}
                    headingContent={
                        <Button
                            type='action'
                            text="Save"
                            isDisabled={!isDirty}
                            icon={IconHelper.General.Edit}
                            action={save}
                            isPulledRight
                            hideTextOnMobile={false}
                        />
                    }
                    footerContent={
                        <Button
                            type='action'
                            text="Save"
                            isDisabled={!isDirty}
                            icon={IconHelper.General.Edit}
                            action={save}
                            isPulledRight
                            hideTextOnMobile={false}
                        />
                    }
                />
            }
        </LoadingWrapper>
    </SubPanel>;
};