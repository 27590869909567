import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ApiStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { AggregatedOverTimeResult } from '../../../ApplicationState/ReportingApiClient';
import { DateHelper } from '../../../Helpers/DateHelper';
import { LoadingSpinner } from '../../Base/Loading/LoadingSpinner';
import { dataChoices } from './DataChooser';
import { DataGraph } from './DataGraph';
import { createDateControls } from './DateControls';

export const AggregatedGraph = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [data, setData] = React.useState<AggregatedOverTimeResult | undefined>(undefined);
    const [date, setDate] = React.useState(new Date());

    React.useEffect(() => {

        if (worldState.World !== undefined) {
            apiState.ReportClient.getAggregatedOverTime(worldState.World?.globalId, DateHelper.toDateKey(date), 10).then(setData);
        }

    }, [worldState.World, date]);

    if (data === undefined || worldState.World === undefined) {
        return <LoadingSpinner />;
    }

    return <DataGraph
        dataChoices={[
            dataChoices.population,
            dataChoices.size,
            dataChoices.numberOfPlayers,
            dataChoices.numberOfSolarSystems]
        }
        data={data}
        additionalButtons={createDateControls(worldState.World, date, setDate)}
    />;
});