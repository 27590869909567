import * as React from 'react';
import { ActivateActionResponse, AgentAction, AgentStatus, AgentSummary } from '../../ApplicationState/ApiClient';
import { AgentStateContext, ApiStateContext, PlayerStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { HelpButton } from '../../Components/Base/Buttons/HelpButton';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { MessageBoxWithIcon } from '../../Components/Base/MessageBox';
import { AgentHelper } from '../../Helpers/AgentHelper';
import { IconHelper } from '../../Helpers/IconHelper';
import { SimpleBaseView } from '../BaseView';
import { AgentTable } from './Components/AgentTable';
import { CapturedAgents } from './Components/CapturedAgents';
import { HireAgent } from './HireAgent';

export const AgentsIndex = () => {

    const apiState = React.useContext(ApiStateContext);
    const agentState = React.useContext(AgentStateContext);
    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [agentSummary, setAgentSummary] = React.useState<AgentSummary | undefined>();

    const [trainAllResponse, setTrainAllResponse] = React.useState<ActivateActionResponse[] | undefined>();

    const [isTrainingAll, setIsTrainingAll] = React.useState(false);

    const trainableAgentIds = React.useMemo(() => {

        if (agentState.Agents === undefined || worldState.AgentActionTypeSettings === undefined) {
            return [];
        }

        const agentIds = agentState.Agents.filter(x => {

            if (!AgentHelper.readyForAction(x)) {
                return false;
            }

            const actions = AgentHelper.availableActions(x, worldState.AgentActionTypeSettings!)

            const train = actions?.find(y => y.action === AgentAction.Train);

            return train !== undefined;
        }).map(x => x.agentId);

        return agentIds;

    }, [agentState.Agents, worldState.AgentActionTypeSettings]);

    function trainAll() {
        setIsTrainingAll(true);
        apiState.AgentClient.trainMany(trainableAgentIds).then(x => {

            for (const response of x) {
                agentState.loadAgent(response.agent);
            }

            setTrainAllResponse(x);
        }).finally(() => {
            setIsTrainingAll(false);
        });
    }

    React.useEffect(() => {

        if (agentSummary === undefined) {
            apiState.AgentClient.forPlayer().then(s => {
                setAgentSummary(s);
                agentState.loadAgents(s);
            });
        }

    }, []);


    const trainAllSuccesses = trainAllResponse === undefined ? [] : trainAllResponse?.filter(x => x.wasSuccess).map(x => x.agent.name);
    const trainAllFailures = trainAllResponse === undefined ? [] : trainAllResponse?.filter(x => !x.wasSuccess).map(x => x.agent.name);

    const agentsToShow = agentSummary?.agents.filter(x => x.status !== AgentStatus.Captured || x.playerId === playerState?.Player?.playerId);
    const capturedAgentsToShow = agentSummary?.agents.filter(x => x.status === AgentStatus.Captured && x.playerId !== playerState?.Player?.playerId);

    return <SimpleBaseView heading={{ text: "Agents", icon: IconHelper.Agents.Agent }}
        headingContent={<HelpButton to="agents" />}>
        {agentSummary === undefined && <LoadingSpinner />}
        {agentSummary !== undefined && <>

            {trainAllSuccesses.length > 0 && <MessageBoxWithIcon
                type="success"
                icon={IconHelper.General.Confirm}
                text={`The following agents successfully trained: ${trainAllSuccesses.join(", ")}`}
            />}
            {trainAllFailures.length > 0 && <MessageBoxWithIcon
                type="warning"
                icon={IconHelper.General.Close}
                text={`The following agents failed to train: ${trainAllFailures.join(", ")}. They can attempt to train again after their action cooldown passes.`}
            />}
            <HireAgent />
            <AgentTable
                title="Your Agents"
                agents={agentsToShow}
                headingContent={trainableAgentIds.length > 0 && <Button
                    type="action"
                    action={trainAll}
                    icon={IconHelper.Agents.action(AgentAction.Train)}
                    text='Train All'
                    isDisabled={isTrainingAll}
                />}
            />
            <CapturedAgents agents={capturedAgentsToShow ?? []} />
        </>}
    </SimpleBaseView>;
};