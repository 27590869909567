import * as React from "react";
import { FriendInvitationResponse, ValidationResult } from "../../ApplicationState/ApiClient";
import { useApiState } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { IconHelper } from "../../Helpers/IconHelper";

type Props = {
    reload: (result: FriendInvitationResponse) => void
}

export const NewInviteForm = (props: Props) => {

    const apiState = useApiState();
    const [emailAddress, setEmailAddress] = React.useState<ValidationResult>(ValidationHelper.empty());

    function isValid() {
        return emailAddress.isValid;
    }

    function invite() {
        if (!isValid()) {
            return;
        }

        (async function inviteCore() {
            const result = await apiState.FriendInvitationClient.inviteEmailAddress(emailAddress.validText!);
            setEmailAddress(ValidationHelper.empty());
            props.reload(result);
        })();
    }

    const buttonDisabled = !isValid();

    return <SubPanel heading="Invite a new friend" footerContent={
        <Button isPulledRight
            type="action"
            text="Invite"
            icon={IconHelper.General.Confirm}
            action={() => invite()}
            isDisabled={buttonDisabled}
        />}>
        <ValidatingStringInput
            label="Email Address"
            icon={IconHelper.Account.Email}
            valueChanged={setEmailAddress}
        />
    </SubPanel>;
}
