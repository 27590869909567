import { observer } from "mobx-react-lite";
import * as React from "react";
import { ApiStateContext, AppStateContext, UnreadStateContext } from "../../ApplicationState/ContextRoot";
import { ViewButton } from "../../Components/Base/Buttons/ViewButton";
import { Table } from "../../Components/Base/Containers/Table";
import { NewIcon } from "../../Components/Base/Icon";
import { handleOnClick } from "../../Components/Base/Util";
import { InboxContainer } from "../../Components/FusionShift/InboxContainer";
import { IconHelper } from "../../Helpers/IconHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { message } from "../../Navigation/Routing/NotificationsEtc";
import { MessageSenderImage } from "./MessageSender";

export const MessageIndex = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const unreadState = React.useContext(UnreadStateContext);

    function list() {
        return apiState.MessageClient.list().then(messages => {
            unreadState.clearUnread("message");
            return messages;
        });
    }

    return <InboxContainer
        entityName="Message"
        icon={IconHelper.General.Message}
        list={list}
        markAllAsRead={() => apiState.MessageClient.markAllRead()}
        deleteAll={() => apiState.MessageClient.deleteAll()}
        render={messages => {
            return <Table
                isHoverable
                isFullWidth
                isMobileMultirow
                hideHeaderMobile
                heading={
                    <>
                        <td></td>
                        <td>From</td>
                        <td>Subject</td>

                        <td>Date</td>
                        <td className="is-hidden-mobile"></td>
                    </>
                }>
                {messages.map(s => <React.Fragment key={s.messageId}>
                    <tr className="is-hidden-tablet" onClick={e => handleOnClick(e, () => appState.navigate(message(s.messageId)))}>
                        <td colSpan={3}>
                            {s.isUnread && <NewIcon />}
                            {s.subject}
                        </td>
                    </tr>
                    <tr className="is-clickable" onClick={e => handleOnClick(e, () => appState.navigate(message(s.messageId)))}>
                        <td className="is-hidden-mobile">
                            {s.isUnread && <NewIcon />}
                        </td>
                        <td>
                            <MessageSenderImage message={s} />
                        </td>
                        <td className="is-hidden-mobile">
                            {s.subject}
                        </td>
                        <td className="is-mobile-right-align">
                            {ValueFormatter.formatDateFromNowWithMoment(s.date)}
                        </td>
                        <td className="is-hidden-mobile">
                            <ViewButton to={message(s.messageId)} />
                        </td>
                    </tr>
                </React.Fragment>)}
            </Table>;
        }}
    />;
});