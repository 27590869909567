import * as React from "react";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { CreateMarketOrderButton } from "../../Components/FusionShift/Buttons/CreateMarketOrderButton";
import { MarketOrderSolarSystemSummary } from "./Components/MarketOrderSolarSystemSummary";
import { MarketOrderTable } from "./Components/MarketOrderTable";
import { MarketOrderBasePage, MarketOrderProps } from "./MarketOrderBasePage";

const MarketOrderIndexContent = (props: MarketOrderProps) => {

    return <>
        <MarketOrderSolarSystemSummary {...props} />
        {props.marketOrders.length === 0 && <Paragraph type="missing">No market orders</Paragraph>}
        {props.marketOrders.length > 0 && <MarketOrderTable marketOrders={props.marketOrders} showLocation={false} reloadCallback={props.reloadCallback} />}
    </>;
};

export const MarketOrderIndex = () => {
    return <MarketOrderBasePage content={MarketOrderIndexContent} title="Market Orders" extraHeadingContent={<CreateMarketOrderButton />} />;
};