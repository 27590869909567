import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { IFleet, IMapGrid, IMapSolarSystem, SolarSystemForObserver } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, SolarSystemStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { ButtonLink } from "../../../Components/Base/Buttons/ButtonLink";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { LoadingSpinner, LoadingSpinnerOnly } from "../../../Components/Base/Loading/LoadingSpinner";
import { Coordinate } from "../../../Entities/Shared";
import { FleetHelper } from "../../../Helpers/FleetHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { map_galaxy, map_tactical_x_y } from "../../../Navigation/Routing/Map";
import { SimpleBaseView } from "../../BaseView";
import { mapSize } from "../MapSize";
import { SolarSystemDetail } from "../SolarSystemDetail";
import { MapFleetList } from "./FleetList";
import { Grid } from "./Grid";
import { GridStore } from "./GridStore";
import { HeaderButtons } from "./HeaderButtons";
import { MapPreferences, defaultPreferences } from "./MapPreferences";
import { Overlay } from "./Overlay/Overlay";

type LoadingState = "Clean" | "Ready" | "Pending" | "Loading";

type Props = {
    coord: Coordinate,
    gridStore: GridStore
}

const defaultSize = 5;

const TacticalMap = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [selectedFleetId, setSelectedFleetId] = React.useState<number | undefined>(undefined);

    // Solar system loaded whenever grid is clicked - entire object is used to preload certain views
    const [selectedSolarSystem, setSelectedSolarSystem] = React.useState<IMapSolarSystem | undefined>(undefined);
    // Full detail of solar system which has been clicked and loaded from the API
    const [selectedAndLoadedSolarSystem, setSelectedAndLoadedSolarSystem] = React.useState<SolarSystemForObserver | undefined>(undefined);

    const [celestialTypeName, setCelestialTypeName] = React.useState<string | undefined>(undefined);
    const [preferences, setPreferences] = React.useState<MapPreferences>(appState.getUserPrefsObjectOrDefault<MapPreferences>("MapPreferences", defaultPreferences));
    const [iter, setIter] = React.useState(0);

    function modifyPreferences(action: (prefs: MapPreferences) => any) {
        action(preferences);
        appState.setUserPrefsObject<MapPreferences>("MapPreferences", preferences);
        setPreferences(preferences);
        setIter(iter + 1);
    }

    React.useEffect(() => {

        setIsLoadingGrid("Pending");
        const gridTimeout = setTimeout(() => {
            reloadGrid();
        }, mapGrid === undefined && isLoadingGrid === "Ready" ? 0 : 1000);

        setIsLoadingEntities("Pending");
        const entitiedTimeout = setTimeout(() => {
            reloadEntities();
        }, isLoadingEntities === "Clean" ? 1000 : 3000);

        return () => {
            clearTimeout(gridTimeout);
            clearTimeout(entitiedTimeout);
        };

    }, [props.coord])

    const [mapGrid, setMapGrid] = React.useState<IMapGrid>({
        centerX: props.coord.x,
        centerY: props.coord.y - 1,
        minX: props.coord.x - defaultSize,
        minY: props.coord.y - defaultSize,
        width: (defaultSize * 2) + 1,
        height: (defaultSize * 2) - 1,
        solarSystems: []
    });

    const [isLoadingGrid, setIsLoadingGrid] = React.useState<LoadingState>("Ready");
    const [isLoadingEntities, setIsLoadingEntities] = React.useState<LoadingState>("Clean");

    const [selectedCoord, setSelectedCoord] = React.useState<Coordinate | undefined>(undefined);

    const [inProgressMovements, setInProgressMovements] = React.useState<IFleet[]>([]);

    // If a solar system is selected then update the selected coords if the selection is different
    React.useEffect(() => {
        if (selectedSolarSystem !== undefined &&
            (selectedCoord === undefined ||
                selectedSolarSystem.x != selectedCoord.x ||
                selectedSolarSystem.y != selectedCoord.y)) {

            setSelectedAndLoadedSolarSystem(undefined);
            setSelectedCoord({ x: selectedSolarSystem.x, y: selectedSolarSystem.y });
        }
    }, [selectedSolarSystem]);

    React.useEffect(() => {
        if (selectedCoord) {

            apiState.SolarSystemClient.getByCoordinate(selectedCoord.x, selectedCoord.y).then(solarSystem => {
                // Only update the state if the desired solar system was loaded
                if (selectedCoord !== undefined && solarSystem &&
                    selectedCoord.x == solarSystem.x && selectedCoord.y == solarSystem.y) {
                    setSelectedAndLoadedSolarSystem(solarSystem);
                    // The detailed solar system has been loaded from the API
                    // Selected coord will remain set
                    setSelectedSolarSystem(undefined);
                }
            });
        } else {
            setSelectedAndLoadedSolarSystem(undefined);
        }
    }, [selectedCoord]);

    React.useEffect(() => {
        if (mapGrid !== undefined) {
            props.gridStore.setSolarSystems(mapGrid);
        }

    }, [mapGrid]);

    function reload() {
        reloadGrid();
        reloadEntities();
    }

    const [loadingFor, setLoadingFor] = React.useState<Coordinate | undefined>();
    function reloadGrid() {

        if (loadingFor !== undefined && loadingFor.x === props.coord.x && loadingFor.y === props.coord.y) {
            return;
        }

        setLoadingFor(props.coord);
        setIsLoadingGrid("Loading");

        const toUse = isNaN(props.coord.x) || isNaN(props.coord.y) ? { x: 0, y: 0 } : props.coord;

        apiState.MapClient.getGrid(toUse.x, toUse.y, mapSize.fullhd, undefined, ((mapSize.fullhd - mapSize.mobile) / 2) + 1).then(map => {
            if (map) {
                setMapGrid(map);
            }
        }).finally(() => {
            setIsLoadingGrid("Ready");
            setLoadingFor(undefined);
        });
    }

    function reloadEntities() {

        setIsLoadingEntities("Loading");

        const toUse = isNaN(props.coord.x) || isNaN(props.coord.y) ? { x: 0, y: 0 } : props.coord;

        apiState.MapClient.getEntities(toUse.x, toUse.y, mapSize.fullhd, undefined, ((mapSize.fullhd - mapSize.mobile) / 2) + 1).then(map => {
            if (map) {
                props.gridStore.setEntities(map);
                const fleets = Object.values(props.gridStore.fleets).filter(m => !FleetHelper.isArrivedAndPresentInSolarSystem(m));
                setInProgressMovements(fleets);
            }
        }).finally(() => {
            setIsLoadingEntities("Ready");
        });
    }

    function changeCoord(changeTo: Coordinate, instant: boolean) {

        appState.navigateIfStillRelevant(map_tactical_x_y(changeTo.x, changeTo.y), true, "/map");
    }

    if (!mapGrid || !mapGrid.solarSystems) {
        return <LoadingSpinner />;
    }



    const noSolarSystem = selectedSolarSystem === undefined && selectedAndLoadedSolarSystem === undefined;

    return <>
        <HeaderButtons
            map={mapGrid}
            coord={props.coord}
            setCoord={changeCoord}
            preferences={preferences}
            modifyPreferences={modifyPreferences}
            celestialTypeName={celestialTypeName}
            setCelestialTypeName={setCelestialTypeName}
        />
        <div className="space">
            <div className="stars-box">
                <Grid
                    grid={props.gridStore}
                    perspective={mapGrid}
                    fleets={inProgressMovements}
                    coord={props.coord}
                    selectedCoord={selectedCoord}
                    setSelectedSolarSystem={setSelectedSolarSystem}
                    celestialTypeName={celestialTypeName}
                    reloadCallback={reload}
                />
                <Overlay
                    apocalypseShots={Object.values(props.gridStore.apocalypseShots)}
                    fleets={inProgressMovements}
                    perspective={mapGrid}
                    coord={props.coord}
                    preferences={preferences}
                    selectedFleetId={selectedFleetId}
                    setSelectedFleetId={setSelectedFleetId}
                    selectedSolarSystemId={selectedAndLoadedSolarSystem ? selectedAndLoadedSolarSystem.solarSystemId : undefined}
                />
                {(isLoadingGrid !== "Ready" || isLoadingEntities !== "Ready") && <div className="map-loading">
                    <LoadingSpinnerOnly isSmall />
                </div>}
            </div>
        </div>
        {noSolarSystem &&
            <SubPanel heading={{ text: "Ship Movements ", icon: IconHelper.Ships.Ship }}>
                <MapFleetList fleets={inProgressMovements} selectedFleetId={selectedFleetId} setSelectedFleetId={setSelectedFleetId} selectedSolarSystem={selectedAndLoadedSolarSystem} />
            </SubPanel>
        }
        {(selectedSolarSystem !== undefined || selectedAndLoadedSolarSystem !== undefined) &&
            <SolarSystemDetail
                solarSystem={selectedAndLoadedSolarSystem ?? selectedSolarSystem}
                fleets={inProgressMovements}
                closeCallback={() => setSelectedCoord(undefined)}
                selectedFleetId={selectedFleetId}
                setSelectedFleetId={setSelectedFleetId}
                reloadCallback={reload}
            />
        }
    </>;
});

export const TacticalMapView = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [hasDoneInitialLoad, setHasDoneInitialLoad] = React.useState(false);
    const [coord, setCoord] = React.useState<Coordinate>({ x: 0, y: 0 });

    const [gridStore, setGridStore] = React.useState<GridStore | undefined>();

    React.useEffect(() => {
        if (solarSystemState.SolarSystems &&
            playerState.Player &&
            gridStore === undefined) {
            setGridStore(new GridStore(playerState.Player, solarSystemState.SolarSystems));
        }
    }, [solarSystemState.SolarSystems, playerState.Player, gridStore]);

    const { x, y } = useParams();

    React.useEffect(() => {
        if (!hasDoneInitialLoad) {
            if (x !== undefined && y !== undefined) {
                setHasDoneInitialLoad(true);
                const c = {
                    x: Number(x),
                    y: Number(y)
                };
                setCoord(c);
            } else if (solarSystemState.SolarSystem) {

                setHasDoneInitialLoad(true);
                const c = {
                    x: solarSystemState.SolarSystem.x,
                    y: solarSystemState.SolarSystem.y
                };
                setCoord(c);
            }
        }
    }, [x, y, solarSystemState.SolarSystem, hasDoneInitialLoad]);

    React.useEffect(() => {
        if (hasDoneInitialLoad) {
            if (x && y) {
                setHasDoneInitialLoad(true);
                const c = {
                    x: Number(x),
                    y: Number(y)
                };
                if (c.x !== coord.x || c.y !== coord.y) {
                    setCoord(c);
                }
            }
        }
    }, [x, y, hasDoneInitialLoad]);


    return <SimpleBaseView
        heading={{ text: "Tactical Map", icon: IconHelper.Map.TacticalMap }}
        headingContent={<ButtonLink
            to={map_galaxy}
            icon={IconHelper.Map.GalaxyMap}
            text="Galaxy Map"
        />}>
        {gridStore === undefined && <LoadingSpinner />}
        {hasDoneInitialLoad && gridStore !== undefined &&
            <TacticalMap
                coord={coord}
                gridStore={gridStore}
            />}
    </SimpleBaseView>
});