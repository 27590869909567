import classNames from 'classnames';
import * as React from 'react';
import { BulmaBreakpoint } from '../BulmaTypes';

type Props = {
    left: React.ReactNode,
    right: React.ReactNode,
    horizontalAfter?: BulmaBreakpoint,
    isLeftEmpty?: boolean,
    isRightEmpty?: boolean,
    isLeftExpand?: boolean,
    isRightExpand?: boolean,
    isFlipOnMobile?: boolean,
    hasDivider?: boolean,
    fitContent?: boolean,
    hasRightImage?: boolean,
    isMobileOnly?: boolean,
    sizing?: "70-30" | "30-70" | undefined,
    className?: string | undefined
}

export const SplitLayout = (props: Props) => {

    const size = props.sizing !== undefined ? "is-" + props.sizing : undefined;

    const classes = classNames(
        props.className,
        size,
        {
            "left-empty": props.isLeftEmpty,
            "right-empty": props.isRightEmpty,
            "is-fit-content": props.fitContent,
            "is-left-expand": props.isLeftExpand,
            "is-right-expand": props.isRightExpand,
            "flip-on-mobile": props.isFlipOnMobile,
            "is-mobile-only": props.isMobileOnly,
            "has-right-image": props.hasRightImage
        });

    return <div className={`split-layout horizontal-after-${props.horizontalAfter !== undefined ? props.horizontalAfter : "desktop"} ${classes}`}>
        <div className={`left ${props.hasDivider ? "has-divider" : ""}`}>
            {props.left}
        </div>
        {!props.isRightEmpty &&
            <div className="right">
                {props.right}
            </div>
        }
    </div>
};