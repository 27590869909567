import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IAgentDetail } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { Table } from '../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { AgentPortrait } from '../../../Components/FusionShift/Images/AgentPortrait';
import { InstallationImage } from '../../../Components/FusionShift/Images/InstallationImage';
import { AgentLink } from '../../../Components/FusionShift/Links/AgentLink';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';

type Props = {
    agent: IAgentDetail
}

export const CelestialPositionExtras = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    if (props.agent.celestialPosition === undefined || worldState.InstallationTypeSettings === undefined || !playerState.Player) {
        return null;
    }

    const isOwnAgent = props.agent.playerId === playerState.Player.playerId;

    const installations = isOwnAgent && props.agent.celestialPosition.celestial.installations ? props.agent.celestialPosition.celestial.installations
        .filter(x => x.installationTypeName in worldState.InstallationTypeSettings!.data)
        .map(x => {
            return {
                installation: x,
                installationType: worldState.InstallationTypeSettings!.data[x.installationTypeName]
            }
        }) : [];

    const agents = isOwnAgent ? props.agent.celestialPosition.celestial.agents : [];

    if (agents.length === 0 && installations.length === 0) {
        return null;
    }

    return <>
        <br />
        <SplitLayout
            isLeftEmpty={installations.length === 0}
            isRightEmpty={agents.length === 0}
            left={installations.length > 0 &&
                <>
                    <Paragraph>
                        The following installations can be seen:
                    </Paragraph>
                    <Table>
                        {installations.map(x => <tr key={x.installation.installationId}>
                            <td>
                                <InstallationImage noLink celestialId={props.agent.celestialPosition!.celestial.celestialId} {...x} size='tiny' />
                            </td>
                            <td>
                                {x.installationType.name} [{x.installation.level}]
                            </td>
                        </tr>)}
                    </Table>
                </>
            }
            right={agents.length > 0 &&
                <>
                    <Paragraph>
                        The following agents can be seen:
                    </Paragraph>
                    <Table>
                        {agents.map(x => <tr key={x.agentId}>
                            <td>
                                <AgentPortrait size='tiny' agent={x} />
                            </td>
                            <td>
                                <AgentLink agent={x} /> <br />
                                <PlayerLink player={x.player} />
                            </td>
                        </tr>)}
                    </Table>
                </>
            }
        />
    </>;
});