import { ICelestialDetail, IItemTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { manufactoryProblemBuilder } from "./ManufactoryProblems";

export function allWastedProblems(itemTypeSettings: IItemTypeSettings, wrapper: SolarSystemWrapper, celestial: ICelestialDetail): ProblemsMaybe {

    if (!celestial.manufactory) {
        return undefined;
    }

    const production = wrapper.solarSystem.itemsDetail && celestial.celestialId in wrapper.solarSystem.itemsDetail.manufactories.productionByCelestialId ? wrapper.solarSystem.itemsDetail.manufactories.productionByCelestialId[celestial.celestialId] : undefined;

    return (production ? Object.keys(production.consumedByInputNodes).filter(i => {

        const consumed = production.consumedByInputNodes[i];
        const used = i in production.consumedByOutputNodes ? production.consumedByOutputNodes[i] : 0;
        const waste = i in production.wastage ? production.wastage[i] : 0;

        return consumed > 0 && (used === 0 || waste >= consumed);
    }) : []).map(k => manufactoryProblemBuilder.warningProblem(`Wasted ${itemTypeSettings.data[k].typeName}`, `None of the ${itemTypeSettings.data[k].typeName} consumed by the Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} is being used`));
}