import { observer } from 'mobx-react-lite';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FleetMovementTypeIcon } from '../../../Components/FusionShift/Icons/FleetMovementTypeIcon';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { SolarSystemHelper } from '../../../Helpers/SolarSystemHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ContractTypeSpecificsForContractor } from '../../Contracts/Components/ContractTypeSpecificsForContractor';
import { ConfigControl } from '../Components/Config/ConfigControl';
import { Details } from '../Components/Details';
import { StatusWarnings } from '../Components/StatusWarnings';
import { Summary } from '../Components/Summary';
import { TargetRender } from '../Components/Target/TargetRender';
import { Validation } from '../Components/Validation';
import { SendNowButton } from '../SendNowButton';
import { StageProps } from './StageRender';

export const SummaryStage = observer((props: StageProps) => {

    if (!props.sendShipsBag.MovementType) {
        return null;
    }

    const text = `Send ${ValueFormatter.friendlyNameForMovementType(props.sendShipsBag.MovementType)}`;

    const backgroundImage = CelestialHelper.celestialBackground(props.sendShipsBag.TargetCelestial, props.sendShipsBag.TargetSolarSystem?.owner !== undefined) ??
        SolarSystemHelper.solarSystemBackground(props.sendShipsBag.TargetSolarSystem);

    return <SubPanel
        heading={{ text, icon: <FleetMovementTypeIcon movementType={props.sendShipsBag.MovementType} setColour={false} /> }}
        backgroundImage={backgroundImage}
        isBackgroundFaded
        headingContent={<SendNowButton hideTextOnMobile {...props} />}
        footerContent={
            <ButtonHolder isPulledRight>
                <PreviousButton action={props.previousStage} />
                <SendNowButton {...props} />
            </ButtonHolder>
        }>
        <StatusWarnings sendShipsBag={props.sendShipsBag} />
        <SplitLayout
            left={<>
                <Validation sendShipsBag={props.sendShipsBag} />
                <Summary sendShipsBag={props.sendShipsBag} />
                <Details sendShipsBag={props.sendShipsBag} />
                {props.sendShipsBag.Contract !== undefined &&
                    <ContractTypeSpecificsForContractor contractType={props.sendShipsBag.Contract.type} />
                }
            </>
            }
            right={<>
                <TargetRender {...props} />
                <ConfigControl {...props} />
            </>
            }
        />
    </SubPanel>;
});