import React from "react";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ButtonBaseProps } from "./Button";
import { NavButton } from "./NavButton";

type Props = ButtonBaseProps & {
    text?: string,
    to: string,
    icon?: string | undefined
}

export const HelpButton = (props: Props) => {

    const to = props.to.startsWith("/help") ? props.to : `/help/${props.to}`;

    return <NavButton
        to={to}
        icon={props.icon === undefined ? IconHelper.General.Help : props.icon}
        text={props.text}
        hideTextOnMobile
    />;
};

HelpButton.defaultProps = {
    text: "Help",
    icon: IconHelper.General.Help
};