import * as React from 'react';
import { CelestialSize, IInstallationTypeSettings, InstallationType, ISimulatedCelestial, ISimulatedInstallation, SimulatedInstallation } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { Select } from '../../../Components/Base/Form/Select';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { BuildHelper } from '../../../Helpers/BuildHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { defaultInstallation } from '../Data';

type Props = {
    installationTypeSettings: IInstallationTypeSettings,
    installations: ISimulatedInstallation[],
    celestials: ISimulatedCelestial[],
    celestial: ISimulatedCelestial,
    setInstallations: (installations: ISimulatedInstallation[]) => any
}

type RowProps = {
    installationTypeSettings: IInstallationTypeSettings,
    celestial: ISimulatedCelestial,
    installation: ISimulatedInstallation,
    setInstallation: (reinforcements: ISimulatedInstallation) => any,
    removeInstallation: () => void,
}

const promptValue = "PROMPT";

const InstallationRow = (props: RowProps) => {

    const [installationType, setInstallationType] = React.useState<InstallationType | undefined>(undefined);
    const [maxLevel, setMaxLevel] = React.useState(1);

    React.useEffect(() => {
        const installationType = props.installation.installationTypeName in props.installationTypeSettings.data ? props.installationTypeSettings.data[props.installation.installationTypeName] : undefined;
        setInstallationType(installationType);

        const maxLevel = installationType !== undefined ? BuildHelper.effectiveMaxLevel(installationType, props.celestial.size) : 1;
        setMaxLevel(maxLevel);

    }, [props.installation.installationTypeName, props.installationTypeSettings, props.celestial, props.celestial.celestialTypeName, props.celestial.size]);

    const levels: any[] = [];

    for (let i = 0; i < maxLevel; i++) {
        levels.push({
            label: `Level ${i + 1}`,
            value: i + 1
        });
    }

    const installationTypes = Object.values(props.installationTypeSettings.data)
        .filter(x => x.researchPointsRequired === undefined || x.researchPointsRequired === null)
        .filter(x => BuildHelper.isCelestialTypeValid(x, props.celestial.celestialTypeName))
        .sort((a, b) => a.order - b.order)
        .map(x => {
            return {
                label: x.name,
                value: x.typeName
            };
        });

    return <tr>
        <td>
            <Select
                value={props.installation.installationTypeName}
                valueChanged={installationTypeName => {
                    if (installationTypeName !== promptValue) {
                        props.setInstallation({
                            ...props.installation,
                            installationTypeName
                        })
                    }
                }}
                values={[{
                    label: "Select installation...",
                    value: promptValue
                },
                ...installationTypes]}
            />
        </td>
        <td>
            <Select value={props.installation.level} valueChanged={level => props.setInstallation({
                ...props.installation,
                level: Number(level)
            })}
                values={levels}
            />
        </td>
        <td>
            <Button type="danger" icon={IconHelper.General.Delete} action={props.removeInstallation} />
        </td>
    </tr>;
}

export const InstallationsForm = (props: Props) => {

    function add() {
        let installationId = 0;
        while (props.celestials.find(x => x.installations.find(y => y.installationId === installationId) !== undefined) !== undefined) {
            installationId++;
        }

        const added = SimulatedInstallation.fromJS({
            ...defaultInstallation,
            celestialId: props.celestial.celestialId,
            installationId: installationId
        });

        props.installations.push(added);
        props.setInstallations(props.installations);
    }

    function remove(installation: ISimulatedInstallation) {
        const index = props.installations.findIndex(x => x.installationId === installation.installationId);
        if (index !== undefined) {
            props.installations.splice(index, 1);
            props.setInstallations(props.installations);
        }
    }

    function modify(installation: ISimulatedInstallation) {
        const index = props.installations.findIndex(x => x.installationId === installation.installationId);

        if (index !== undefined) {
            props.installations[index] = installation;
            props.setInstallations(props.installations);
        }
    }

    const cantAdd = props.installations.length >= (CelestialSize[props.celestial.size] as any);

    return <SubPanel
        isUnpadded={props.installations.length !== 0}
        heading={{ text: "Installations", icon: IconHelper.Installations.Installation }}
        headingContent={<ButtonHolder>
            <Button type="danger" text="Clear all" icon={IconHelper.General.Delete} action={() => props.setInstallations([])} confirm="Are you sure you want to clear all installations?" isDisabled={props.installations.length === 0} />
            <Button type="nav" text="Add" icon={IconHelper.General.Plus} action={add} isDisabled={cantAdd} />
        </ButtonHolder>}
    >
        {props.installations.length === 0 && <Paragraph>No installations configured</Paragraph>}
        {props.installations.length !== 0 &&
            <Table isFullWidth isHoverable
                heading={<>
                    <th>
                        Installation
                    </th>
                    <th>
                        Level
                    </th>
                    <th></th>
                </>}>
                {props.installations.map(x => <InstallationRow
                    key={x.installationId}
                    installation={x}
                    installationTypeSettings={props.installationTypeSettings}
                    removeInstallation={() => remove(x)}
                    setInstallation={modify}
                    celestial={props.celestial}
                />)}
            </Table>
        }
    </SubPanel>;
};