import * as React from 'react';
import { ICelestial } from '../../../../../ApplicationState/ApiClient';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { ThumbnailSlate } from '../../../../../Components/Base/Containers/Slate';
import { CelestialFeature } from '../../../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';

type Props = {
    celestial: ICelestial,
    title: string,
    mobileTitle?: React.ReactNode,
    thumbnail: React.ReactNode,
    feature: CelestialFeature,
    children: React.ReactNode,
    additionalButtons?: React.ReactNode | undefined,
    hideButton?: boolean,
    buttonText?: string | undefined,
    buttonIcon?: string | undefined,
    isDanger?: boolean,
    isMobileVertical?: boolean,
    setFeature: (feature: CelestialFeature | undefined) => any
}


const maxLength = 23;

export const SummaryTile = (props: Props) => {

    const navButton = props.hideButton ? undefined : <Button type="nav" icon={props.buttonIcon !== undefined ? props.buttonIcon : IconHelper.General.View} action={() => props.setFeature(props.feature)} text={props.buttonText !== undefined ? props.buttonText : "View"} />;

    const title = props.title.length > maxLength ? props.title.substring(0, maxLength) : props.title;

    return <ThumbnailSlate
        {...props}
        title={<>
            <span className="is-hidden-tablet">
                {title}
            </span>
            <span className="is-hidden-mobile">
                {props.title}
            </span>
        </>}
        onClick={!props.hideButton ? () => props.setFeature(props.feature) : undefined}
    >
        {props.children}
        {!props.hideButton && <ButtonHolder isPulledRight>
            {props.additionalButtons !== undefined && props.additionalButtons}
            {navButton}
        </ButtonHolder>}
    </ThumbnailSlate>;
};