import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IFleet } from '../../../ApplicationState/ApiClient';
import { PositionalRender } from '../../../Components/FusionShift/PositionalRender/PositionalRender';
import { SendShipsBag } from '../SendShipsBag';

type Props = {
    sendShipsBag: SendShipsBag
}

export const SendShipsPositionalRender = observer((props: Props) => {

    const { coordinates, additionalSolarSystems, additionalFleets } = React.useMemo(() => {

        const s = { x: props.sendShipsBag.init.sourceSolarSystem.x, y: props.sendShipsBag.init.sourceSolarSystem.y, owner: props.sendShipsBag.init.sourceSolarSystem.owner };

        if (props.sendShipsBag.TargetSolarSystem !== undefined) {

            const t = { x: props.sendShipsBag.TargetSolarSystem.x, y: props.sendShipsBag.TargetSolarSystem.y, owner: props.sendShipsBag.TargetSolarSystem.owner };
            const a = [props.sendShipsBag.TargetSolarSystem];

            const coordinates = props.sendShipsBag.Contract?.targetSolarSystem !== undefined && props.sendShipsBag.Contract?.targetSolarSystem?.solarSystemId !== props.sendShipsBag.TargetSolarSystem.solarSystemId ?
                [s, t, { x: props.sendShipsBag.Contract?.targetSolarSystem?.x!, y: props.sendShipsBag.Contract?.targetSolarSystem?.y, owner: props.sendShipsBag.Contract?.targetSolarSystem?.owner }] :
                [s, t];

            return { coordinates, additionalSolarSystems: a, additionalFleets: undefined }
        }

        if (props.sendShipsBag.TargetFleet !== undefined) {

            const t = props.sendShipsBag.TargetFleetInterception !== undefined ?
                { x: props.sendShipsBag.TargetFleetInterception.coordinate.x, y: props.sendShipsBag.TargetFleetInterception.coordinate.y, owner: props.sendShipsBag.TargetFleet.owner }
                :
                { x: props.sendShipsBag.TargetFleet.currentX ?? 0, y: props.sendShipsBag.TargetFleet.currentY ?? 0, owner: props.sendShipsBag.TargetFleet.owner };
            const a = [props.sendShipsBag.TargetFleet.sourceSolarSystem, props.sendShipsBag.TargetFleet.targetSolarSystem];
            return { coordinates: [s, t], additionalSolarSystems: a, additionalFleets: [props.sendShipsBag.TargetFleet] };
        }

        return { coordinates: [s], target: undefined };
    }, [
        props.sendShipsBag.TargetSolarSystem?.solarSystemId,
        props.sendShipsBag.TargetFleet?.fleetId,
        props.sendShipsBag.init.sourceSolarSystem?.solarSystemId
    ]);

    const backgroundFleets = React.useMemo(() => {

        const fleets: IFleet[] = props.sendShipsBag.init.sourceSolarSystem.fleets ?? [];

        if (props.sendShipsBag.TargetSolarSystem !== undefined) {
            const otherfleets = "fleets" in props.sendShipsBag.TargetSolarSystem ? props.sendShipsBag.TargetSolarSystem.fleets as IFleet[] : [];
            if (otherfleets !== undefined) {
                for (const fleet of otherfleets) {
                    if (fleet.targetSolarSystem?.solarSystemId !== props.sendShipsBag.init.sourceSolarSystem.solarSystemId) {
                        fleets.push(fleet);
                    }
                }
            }
        }
        return fleets;

    }, [props.sendShipsBag.init.sourceSolarSystem, props.sendShipsBag.TargetSolarSystem]);

    const invalidIntercept = props.sendShipsBag.TargetFleetInterception === undefined && props.sendShipsBag.TargetFleet !== undefined;

    return <PositionalRender
        {...props.sendShipsBag.init.world}
        coordinatesToRender={coordinates}
        initialCoordinate={props.sendShipsBag.init.sourceSolarSystem}
        additionalSolarSystems={additionalSolarSystems}
        additionalFleets={additionalFleets}
        backgroundFleets={backgroundFleets}
        overlayText={invalidIntercept ? "Unable to plot intercept course" : undefined}
    />;
});