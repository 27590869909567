import React from "react";
import { CelestialSize, IGameSettings, IInstallationType, SectorType } from "../../../ApplicationState/ApiClient";
import { MegacityHelper } from "../../../Helpers/MegacityHelper";
import { SectorTypeBonusList } from "./SectorTypeBonusList";

type Props = {
    level: number,
    celestialSize: CelestialSize,
    installationType: IInstallationType,
    sectorType: SectorType,
    isBrief?: boolean,
    gameSettings: IGameSettings
}

export const SectorTypeBonusForInstallationList = (props: Props) => {

    const [withCommodities, setWithCommodities] = React.useState(true);

    const assigned = props.level * props.installationType.megacityPopulationAssignmentPerLevel;
    const bonus = withCommodities ?
        MegacityHelper.calculateBonusAssumingAllCommodities(props.gameSettings, props.sectorType, assigned) :
        MegacityHelper.calculateBonus(props.gameSettings, props.sectorType, assigned, 0);

    return <>
        <b>Maximum Sector Bonuses</b><br />
        At level {props.level} - <a onClick={() => setWithCommodities(!withCommodities)}>{ } {withCommodities ? "With " : "Without "} commodities</a>
        <SectorTypeBonusList
            assignedPopulation={assigned}
            bonus={bonus}
            {...props}
        />
    </>;
};