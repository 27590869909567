import * as React from 'react';
import { FleetMovementType } from '../../../ApplicationState/ApiClient';
import { ButtonChooser, choice } from '../../../Components/Base/Buttons/ButtonChooser';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    movementType: FleetMovementType,
    setMovementType: (movementType: FleetMovementType) => any
}

export const MovementTypePicker = (props: Props) => {

    const movementTypes = [FleetMovementType.Attack, FleetMovementType.Recon]
        .map(x => choice(x, ValueFormatter.friendlyNameForMovementType(x), IconHelper.Ships.movementType(x)));

    return <ButtonChooser value={props.movementType} valueChanged={props.setMovementType} values={movementTypes} />;
};