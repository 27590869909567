import { IItemTypeSettings, ISolarSystem } from "../../../../ApplicationState/ApiClient";
import { CollectionHelper } from "../../../../Helpers/CollectionHelper";
import { ItemsHelper } from "../../../../Helpers/ItemsHelper";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { economyProblemBuilder } from "./EconomyProblems";

export function otherItemsProblems(itemTypeSettings: IItemTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;

    if (!solarSystem.items) {
        return undefined;
    }

    const items = ItemsHelper.splitItems(solarSystem.items, itemTypeSettings.data);

    return [
        problemFor(solarSystem, "Commodities", items.commodities, solarSystem.itemsDetail && solarSystem.itemsDetail.otherCapacities.Commodity ? solarSystem.itemsDetail.otherCapacities.Commodity : 0),
        problemFor(solarSystem, "Components", items.components, solarSystem.itemsDetail && solarSystem.itemsDetail.otherCapacities.Component ? solarSystem.itemsDetail.otherCapacities.Component : 0),
        problemFor(solarSystem, "Raw Resources", items.rawResource, solarSystem.itemsDetail && solarSystem.itemsDetail.otherCapacities.RawResource ? solarSystem.itemsDetail.otherCapacities.RawResource : 0),
    ];
}

function problemFor(solarSystem: ISolarSystem, name: string, items: { [key: string]: number }, capacity: number | undefined) {

    const commoditites = CollectionHelper.sumOfDictionary(items);
    capacity ??= 0;

    if (commoditites >= capacity) {
        return economyProblemBuilder.warningProblem(`Full ${name}`, `${solarSystem.name} has full stocks of ${name.toLowerCase()}`);
    } else if (commoditites >= capacity * 0.95) {
        return economyProblemBuilder.infoProblem(`Nearly Full ${name}`, `${solarSystem.name}'s stocks of ${name.toLowerCase()} are nearly full`)
    }

    return undefined;
}