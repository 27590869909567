import * as React from 'react';
import { IPlayer } from '../../ApplicationState/ApiClient';
import { BeginnersProtectionMessage } from '../../Components/FusionShift/Messages/BeginnersProtectionMessage';
import { DefeatMessage } from '../../Components/FusionShift/Messages/DefeatMessage';

type Props = {
    player: IPlayer
}

export const PlayerMessageBox = (props: Props) => {

    if (props.player.isDefeated) {
        return <DefeatMessage text="Player has been defeated" />;
    }

    if (props.player.hasBeginnersProtection) {
        return <BeginnersProtectionMessage {...props} />;
    }

    return null;
};