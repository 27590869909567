import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ApiStateContext, PlayerStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonLink } from '../../../Components/Base/Buttons/ButtonLink';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { IconHelper } from '../../../Helpers/IconHelper';
import { map_tactical } from '../../../Navigation/Routing/Map';
import { SimpleBaseView } from '../../BaseView';
import { GalaxyMapData } from './Entities';
import { MapRender } from './MapRender';

export const GalaxyMapView = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const apiState = React.useContext(ApiStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [data, setData] = React.useState<GalaxyMapData | undefined>();

    React.useEffect(() => {
        if (data === undefined && playerState.Player !== undefined && solarSystemState.SolarSystemsDetail !== undefined) {
            apiState.MapClient.getGalaxyOverview().then((w) => {
                setData(new GalaxyMapData(w, playerState.Player!, solarSystemState.SolarSystemsDetail!));
            });
        }
    }, [data, playerState.Player, solarSystemState.SolarSystemsDetail]);

    React.useEffect(() => {
        // If data is already loaded then recreate it with the modified state entries
        if (data !== undefined && playerState.Player !== undefined && solarSystemState.SolarSystemsDetail !== undefined) {
            setData(new GalaxyMapData(data.map, playerState.Player!, solarSystemState.SolarSystemsDetail!));
        }
    }, [playerState.Player, solarSystemState.SolarSystemsDetail]);

    return <SimpleBaseView
        heading={{ text: "Galaxy Map", icon: IconHelper.Map.GalaxyMap }}
        headingContent={<ButtonLink
            to={map_tactical}
            icon={IconHelper.Map.TacticalMap}
            text="Tactical Map"
        />}>
        {data === undefined && <LoadingSpinner />}
        {data !== undefined && <MapRender
            data={data}
            initialSolarSystem={solarSystemState.SolarSystem}
        />}
    </SimpleBaseView>
});