import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PlayerStateContext } from '../../../../ApplicationState/ContextRoot';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const SolarSystemsRequired = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!props.mission.MissionType.solarSystemsRequired || !playerState.Player) {
        return null;
    }

    const required = props.mission.MissionType.solarSystemsRequired;
    const has = playerState.Player.numberOfSolarSystems;

    return <ObjectiveRow has={has} requires={required} {...props} >
        {`Control ${required} solar ${ValueFormatter.pluralize("system", required)}`}
    </ObjectiveRow>;
});