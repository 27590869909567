import * as React from 'react';
import { PresidentialTermWithWinningManifesto } from '../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext } from '../../ApplicationState/ContextRoot';
import { BackNavButton } from '../../Components/Base/Buttons/BackButton';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { HelpButton } from '../../Components/Base/Buttons/HelpButton';
import { ViewButton } from '../../Components/Base/Buttons/ViewButton';
import { Table } from '../../Components/Base/Containers/Table';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { handleOnClick } from '../../Components/Base/Util';
import { PlayerLink } from '../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { politics_home, politics_terms_detail } from '../../Navigation/Routing/Politics';
import { SimpleBaseView } from '../BaseView';

type Props = {
    presidentialTerms: PresidentialTermWithWinningManifesto[]
}

const TermsTable = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    if (props.presidentialTerms.length === 0) {
        return <Paragraph type="prompt">There are currently no presidential terms.</Paragraph>
    }

    function winner(term: PresidentialTermWithWinningManifesto) {
        if (!term.hasVotesBeenCounted) {
            return "Undeclared";
        }
        if (!term.winningPresidentialManifesto) {
            return "No winner";
        }
        return <PlayerLink player={term.winningPresidentialManifesto.player} />;
    }

    return <Table
        isFullWidth
        isHoverable
        isClickable
        heading={<>
            <th>
                Start
            </th>
            <th>
                End
            </th>
            <th>
                Winner
            </th>
            <th>
            </th>
        </>}>
        {props.presidentialTerms.map(x => <tr key={x.presidentialTermId} onClick={e => handleOnClick(e, () => appState.navigate(politics_terms_detail(x.presidentialTermId)))}>
            <td>
                {ValueFormatter.formatShortDate(x.startDate)}
            </td>
            <td>
                {ValueFormatter.formatShortDate(x.endDate)}
            </td>
            <td>
                {winner(x)}
            </td>
            <td>
                <ViewButton to={politics_terms_detail(x.presidentialTermId)} />
            </td>
        </tr>)}
    </Table>
};

export const TermsIndex = () => {

    const apiState = React.useContext(ApiStateContext);

    const [terms, setTerms] = React.useState<PresidentialTermWithWinningManifesto[] | undefined>(undefined);

    React.useEffect(() => {
        if (terms === undefined) {
            apiState.PoliticsClient.listTerms().then(terms => {
                setTerms(terms.sort((a, b) => a.endDate > b.endDate ? 1 : -1));
            });
        }
    }, [terms]);


    if (!terms) {
        return <LoadingSpinner />;
    }

    return <SimpleBaseView
        heading={{ text: `Presidential Terms`, icon: IconHelper.Politics.President }}
        headingContent={
            <ButtonHolder>
                <BackNavButton to={politics_home} />
                <HelpButton to={"politics"} />
            </ButtonHolder>
        }    >
        <TermsTable presidentialTerms={terms} />
    </SimpleBaseView >;
};