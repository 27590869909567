import * as React from 'react';
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import { graphTheme } from './Theme';

export type FeatureProps = {
    getter: string | { (data: any): string | Date | undefined };
    format?: (v?: any) => string;
    label?: string;
}

export type SeriesFeatureProps = FeatureProps & {
    includeData?: (v: any) => boolean,
    renderSeriesLabel?: (v: any) => React.ReactNode | undefined
}

type Props = {
    controls?: React.ReactNode
    xSorter?: (a, b) => number,
    x: FeatureProps;
    y: FeatureProps;
    seriesFeature?: SeriesFeatureProps,
    data: any[]
}

const NO_SERIES = "NO_SERIES";

export const DateLineChart = (props: Props) => {

    const configured = React.useMemo(() => {

        let xValues: any[] = [];
        const data: { [key: string]: any } = {};
        let min = 0, max = 0;

        // Data comes in un-ordered and un-grouped
        // Extract relevant groups (series) and the required datapoints
        for (let datum of props.data) {

            if (props.seriesFeature !== undefined && props.seriesFeature.includeData !== undefined) {
                if (!props.seriesFeature.includeData(datum)) {
                    continue;
                }
            }

            const series = props.seriesFeature === undefined ? NO_SERIES : getData(datum, props.seriesFeature);

            const xValue = getData(datum, props.x);
            const yValue = getData(datum, props.y);

            if (xValue === undefined || yValue === undefined) {
                continue;
            }

            if (yValue < min) {
                min = yValue;
            }
            if (yValue > max) {
                max = yValue;
            }

            if (!(series in data)) {
                data[series] = [];
            }
            data[series].push({
                ...datum,
                x: xValue,
                y: yValue,
                series
            });


            if (!xValues.includes(xValue)) {
                xValues.push(xValue);
            }
        }

        // If a sorter is provided then sort the axis labels and then the data within each series
        if (props.xSorter) {
            xValues = xValues.sort(props.xSorter);

            for (let series in data) {

                data[series] = data[series].sort((a, b) => {

                    return xValues.indexOf(a.x) - xValues.indexOf(b.x);
                });
            }
        }

        return {
            data,
            xValues,
            range: { min, max }
        };

    }, [props]);

    function getData(datum, axis: FeatureProps) {
        if (typeof axis.getter === "string") {
            return datum[axis.getter]
        }

        return axis.getter(datum);
    }

    function seriesLabel(series: any) {
        const fromProps = props.seriesFeature?.renderSeriesLabel !== undefined ? props.seriesFeature.renderSeriesLabel(series) : undefined;

        if (fromProps !== undefined) {
            return fromProps;
        }

        return props.seriesFeature!.format !== undefined ? props.seriesFeature!.format(series) : series;
    }

    const hasSeries = Object.keys(configured.data).length > 1;

    return <div className="line-chart-holder">
        <VictoryChart
            minDomain={{ y: 0 }}
            animate={graphTheme.animate}
            padding={graphTheme.padding}
            scale={{ x: "time" }}
            containerComponent={
                <VictoryVoronoiContainer
                    labels={({ datum }) => {

                        const yLabel = props.y.format !== undefined ? props.y.format(datum.y) : datum.y;
                        const xLabel = props.x.format !== undefined ? props.x.format(datum.x) : datum.x;

                        if (props.seriesFeature !== undefined) {
                            const seriesLabel = props.seriesFeature.format !== undefined ? props.seriesFeature.format(datum.series) : datum.series;

                            return `${seriesLabel}\n${xLabel}\n${yLabel}`;
                        }

                        return `${xLabel}\n${yLabel}`;
                    }}
                    labelComponent={<VictoryTooltip
                        {...graphTheme.tooltip.flyout}
                        labelComponent={<VictoryLabel {...graphTheme.tooltip.label} />}
                    />}
                />
            }
        >
            <VictoryAxis
                tickCount={configured.xValues.length}
                tickFormat={props.x.format}
                label={props.x.label}
                style={graphTheme.axisStyle}
            />
            <VictoryAxis
                tickCount={configured.xValues.length / 2}
                dependentAxis
                tickFormat={props.y.format}
                label={props.y.label}
                style={graphTheme.axisStyle}
            />
            {Object.keys(configured.data).map((x, i) => <VictoryLine
                key={x}
                animate={graphTheme.animate}
                style={graphTheme.line[i]}
                data={configured.data[x]}
                x={"x"}
                y={"y"}
            />)}
        </VictoryChart>
        <div className="controls">
            {props.controls}
        </div>
        {hasSeries && <ul className="line-chart-legend">
            {Object.keys(configured.data).map((x, i) => <li key={x}>
                <span className="legend-box" style={{ background: graphTheme.line[i].data.stroke }} />
                {seriesLabel(x)}
            </li>)}
        </ul>}
    </div>;
}