import { PremiumClient } from "../ApplicationState/ApiClient";
declare var Stripe: any;

export default (premiumClient: PremiumClient, isTrialEligible: boolean) => {
    premiumClient.startCheckoutSubscription(isTrialEligible).then(session => {
        const stripe = Stripe("pk_test_zauDpSTddxYgTsDb8XpLLv0e00f3kY6e1d");
        stripe.redirectToCheckout({
            sessionId: session.checkoutSessionId
        });
    })
}