import { IInstallationTypeSettings, IItemTypeSettings, ISolarSystemDetail, IWorldEffects, ShipType } from "../../../../../ApplicationState/ApiClient";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { Filler } from "../../../../../Components/Base/Filler";
import { FieldHolder } from "../../../../../Components/Base/Form/FieldHolder";
import { NumberInput } from "../../../../../Components/Base/Form/Input";
import { ShipTypeDescription } from "../../../../../Components/FusionShift/Descriptions/ShipTypeDescription";
import { UpkeepIcon } from "../../../../../Components/FusionShift/Icons/Items/UpkeepIcon";
import { CostControl } from "../../../../../Components/FusionShift/Items/CostControl";
import { ShipCostModifiedLink } from "../../../../../Components/FusionShift/Links/CostModified";
import { ShipTypeLink } from "../../../../../Components/FusionShift/Links/ShipTypeLink";
import { TypeDataVoice } from "../../../../../Components/FusionShift/TypeDataVoice";
import { ActiveEffectsHelper } from "../../../../../Helpers/ActiveEffectsHelper";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";
import { UpkeepSummary } from "../../../../Fleets/Components/UpkeepSummary";
import { ExistingShips, ExistingShipsProps } from "./ExistingShips";

type Props = ExistingShipsProps & {
    quantity: number,
    maxQuantity: number,
    solarSystem: ISolarSystemDetail,
    shipType: ShipType,
    worldEffects: IWorldEffects,
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    build: () => any,
    setQuantity: (quantity: number) => any
}

export const BuildControl = (props: Props) => {

    const quantityForCost = props.quantity <= 1 ? 1 : props.quantity;

    return <SubPanel heading={{ content: <ShipTypeLink shipType={props.shipType} /> }}
        headingContent={<TypeDataVoice type="ShipType" typeData={props.shipType} />}>
        <ShipTypeDescription shipType={props.shipType} />
        <div className="flex-column-right">
            <ExistingShips {...props} />
            <Filler />
            {!!props.shipType.cost && <CostControl
                cost={props.shipType.cost}
                availableItems={props.solarSystem.items}
                multipliedBy={quantityForCost * ActiveEffectsHelper.costModifierForShipClass(props.worldEffects, props.shipType.class)}
            />}
            <ShipCostModifiedLink
                worldEffects={props.worldEffects} shipType={props.shipType}
                wrapped
            />
            {props.shipType.upkeepPerHour &&
                <>
                    <div>Upkeep <UpkeepIcon quantity={quantityForCost * props.shipType.upkeepPerHour} /></div>
                    <br />
                    <UpkeepSummary
                        className="is-hidden-mobile"
                        itemTypeSettings={props.itemTypeSettings}
                        items={props.solarSystem.items}
                        itemsDetail={props.solarSystem.itemsDetail}
                        additionalUpkeep={props.quantity * props.shipType.upkeepPerHour}
                        additionalUpkeepIcon={IconHelper.Ships.shipClass(props.shipType.class)}
                        additionalUpkeepLabel={props.quantity > 0 ? `${ValueFormatter.formatLocaleNumber(props.quantity)}x ${props.shipType.name}` : undefined}
                    />
                </>}
            <FieldHolder label="" hasAddons >
                <NumberInput
                    size={5}
                    value={props.quantity}
                    maxValue={props.maxQuantity}
                    valueChanged={e => props.setQuantity(e)}
                />
                <Button type="nav" icon="" text="0" action={() => props.setQuantity(0)} isDisabled={props.quantity === 0} />
                <Button type="nav" icon="" text={ValueFormatter.formatLocaleNumber(props.maxQuantity)} action={() => props.setQuantity(props.maxQuantity)} isDisabled={props.quantity === props.maxQuantity} />
                <Button type="action" icon={IconHelper.Ships.Build} text="Build" action={props.build} isDisabled={props.quantity <= 0 || props.maxQuantity === 0} forceClip="bottom-right" />
            </FieldHolder>
        </div>
    </SubPanel>;
};