import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Icon } from '../../Base/Icon';

type Props = {
    level: number,
    operationLevel: number
}

export const OperationLevelWarningIcon = (props: Props) => {

    if (props.operationLevel >= props.level) {
        return null;
    }

    return <Icon icon={IconHelper.Statuses.Warning} className={"has-text-warning"} title="This stat is lowered by the current operation level." />;
};
