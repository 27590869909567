import React from "react";
import { InstallationType } from "../../../ApplicationState/ApiClient";
import { help_installationType } from "../../../Navigation/Routing/Help";
import { ContentWithLabel } from "../../Base/Containers/ContentWithLabel";
import { SvgImage, SvgImageBaseProps } from "./SvgImage";

type Props = SvgImageBaseProps & {
    installationType: InstallationType,
    level?: number | undefined
}

type WithLabelProps = Props & {
    label?: React.ReactNode | undefined
}

export const InstallationTypeImage = (props: Props) => {

    function getFilename() {

        if (props.installationType.levelImages !== undefined && props.installationType.levelImages !== null && props.installationType.levelImages.length > 0 && props.level !== undefined) {
            for (let x = props.installationType.levelImages.length - 1; x >= 0; x--) {
                const imageLevel = props.installationType.levelImages[x];

                if (imageLevel <= props.level) {
                    return `${props.installationType.typeName}_${imageLevel}`;
                }
            }
        }

        return props.installationType.typeName;
    }

    const filename = getFilename();

    return <SvgImage type="installation" filename={filename} {...props} to={props.to !== undefined ? props.to : help_installationType(props.installationType.typeName)} />;
};

export const InstallationTypeImageWithLabel = (props: WithLabelProps) => {

    return <ContentWithLabel label={props.label !== undefined ? props.label : props.installationType.name}>
        <InstallationTypeImage {...props} />
    </ContentWithLabel>;
};