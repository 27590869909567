import { observer } from "mobx-react-lite";
import * as React from "react";
import { CelestialSize, CelestialType, IItemTypeSettings } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { CelestialImageBase } from "../../../Components/FusionShift/Celestials/CelestialImage";
import { CelestialTypeDescription } from "../../../Components/FusionShift/Descriptions/CelestialTypeDescription";
import { CelestialTypeIcon } from "../../../Components/FusionShift/Icons/CelestialTypeIcon";
import { TypeDataVoice } from "../../../Components/FusionShift/TypeDataVoice";
import { IconHelper } from "../../../Helpers/IconHelper";
import { TypeDataBaseView } from "./TypeDataBaseView";

type Props = {
    itemTypeSettings: IItemTypeSettings,
    celestialType: CelestialType
}

const CelestialTypeControl = (props: Props) => {

    return <SubPanel
        id={props.celestialType.typeName}
        heading={{ text: props.celestialType.name, icon: <CelestialTypeIcon celestialTypeName={props.celestialType.typeName} /> }}
        headingContent={<TypeDataVoice type="CelestialType" typeData={props.celestialType} />}
    >
        <SplitLayout left={
            <CelestialTypeDescription {...props} />
        }
            right={
                <CelestialImageBase variationSource="1" celestialTypeName={props.celestialType.typeName} size={CelestialSize.Giant} />
            } />
    </SubPanel>;
};

const CelestialTypesContent = observer(() => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CelestialTypeSettings || !worldState.ItemTypeSettings) {
        return <LoadingSpinner />;
    }

    const celestials = Object.keys(worldState.CelestialTypeSettings.data!)
        .map(c => worldState.CelestialTypeSettings!.data![c])
        .sort(((a, b) => a.order > b.order ? 1 : -1));

    return <>
        {celestials.map(c => <CelestialTypeControl key={c.typeName} celestialType={c} itemTypeSettings={worldState.ItemTypeSettings!} />)}
    </>;
});

export const CelestialTypesView = () => {
    return <TypeDataBaseView title="Celestials" icon={IconHelper.Celestials.Habitable}>
        <CelestialTypesContent />
    </TypeDataBaseView>;
};