import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button } from './Button';

type Props = {
    action: () => any
}

export const CancelButton = (props: Props) => {
    return <Button type="nav" icon={IconHelper.General.Close} text="Cancel" hideTextOnMobile action={props.action} />
};