import * as React from "react";
import { FriendInviteStatus, IFriendInvitationResponse, Invitation } from "../../ApplicationState/ApiClient";
import { useApiState } from "../../ApplicationState/ContextRoot";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Table } from "../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { ErrorMessageBox, SuccessMessageBox } from "../../Components/Base/MessageBox";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { SimpleBaseView } from "../BaseView";
import { NewInviteForm } from "./NewInviteForm";

type Props = {
    invitations?: Invitation[]
}

export const InviteFriends = () => {

    const apiState = useApiState();
    const [invitations, setInvitations] = React.useState<Invitation[] | undefined>();
    const [result, setResult] = React.useState<IFriendInvitationResponse | undefined>(undefined);

    async function getInvitations() {
        const currentInvitations = await apiState.FriendInvitationClient.getInvitations();
        setInvitations(currentInvitations);
    }

    React.useEffect(() => {
        if (!result || result.wasSuccess) {
            getInvitations();
        }
    }, [result]);

    return <SimpleBaseView heading="Friend Invites">
        {result && result.wasSuccess && <SuccessMessageBox text={"An invitation was sent to that email address"} />}
        {result && !result.wasSuccess && <ErrorMessageBox text={result.error} />}
        <NewInviteForm reload={v => setResult(v)} />
        <InvitationsList invitations={invitations} />
    </SimpleBaseView>;
}

const InvitationsList = (props: Props) => {
    if (props.invitations === undefined) {
        return <LoadingSpinner />
    }

    if (props.invitations.length === 0) {
        return null;
    }

    return <SubPanel heading="Invitations">
        <Table>
            {props.invitations.map(x => <tr key={x.toJSON()}>
                <td>{x.emailAddress}</td>
                {x.status === FriendInviteStatus.Accepted && <td><PlayerLink player={x.player} /></td>}
                {x.status !== FriendInviteStatus.Accepted && <td>{FriendInviteStatus[x.status]}</td>}
            </tr>)}
        </Table>
    </SubPanel>;
}
