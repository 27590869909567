import * as React from 'react';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { handleOnClick } from '../../../Components/Base/Util';
import { EmpireCategoryIcon } from '../../../Components/FusionShift/Icons/EmpireCategoryIcon';
import { ProblemIcons } from '../../../Components/FusionShift/Icons/ProblemIcons';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { ProblemCategory } from '../../../Entities/SolarSystem/Problems/Problem';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ProblemList } from '../../SolarSystem/SubViews/Summary/Advisor/ProblemList';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type RowProps = {
    solarSystem: SolarSystemWrapper
}

const Row = (props: RowProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);
    const hasExpand = props.solarSystem.problems.length > 0;

    return <>
        <tr key={props.solarSystem.solarSystem.solarSystemId} onClick={e => handleOnClick(e, () => setIsExpanded(ex => hasExpand && !ex))}>
            <td>
                <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
            </td>
            <td>
                <ProblemIcons problems={props.solarSystem.problems} category={ProblemCategory.Military} />
            </td>
            <td>
                <ProblemIcons problems={props.solarSystem.problems} category={ProblemCategory.Economy} />
            </td>
            <td>
                <ProblemIcons problems={props.solarSystem.problems} category={ProblemCategory.Ships} />
            </td>
            <td>
                <ProblemIcons problems={props.solarSystem.problems} category={ProblemCategory.Megacities} />
            </td>
            <td>
                <ProblemIcons problems={props.solarSystem.problems} category={ProblemCategory.Manufactories} />
            </td>
            <td>
                <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} isDisabled={!hasExpand} />
            </td>
        </tr>
        {isExpanded &&
            <tr>
                <td></td>
                <td><ProblemList problems={props.solarSystem.problems} category={ProblemCategory.Military} /></td>
                <td><ProblemList problems={props.solarSystem.problems} category={ProblemCategory.Economy} /></td>
                <td><ProblemList problems={props.solarSystem.problems} category={ProblemCategory.Ships} /></td>
                <td><ProblemList problems={props.solarSystem.problems} category={ProblemCategory.Megacities} /></td>
                <td><ProblemList problems={props.solarSystem.problems} category={ProblemCategory.Manufactories} /></td>
                <td></td>
            </tr>}
    </>;
};

export const Advisor = (props: Props) => {

    return <SubPanel heading={{ text: "Advisor", icon: IconHelper.Categories.Advisor }} isUnpadded>
        <Table isHoverable isFullWidth heading={
            <>
                <th></th>
                <th>Military <EmpireCategoryIcon category={ProblemCategory.Military} /> </th>
                <th>Economy <EmpireCategoryIcon category={ProblemCategory.Economy} /> </th>
                <th>Ships <EmpireCategoryIcon category={ProblemCategory.Ships} /> </th>
                <th>Megacities <EmpireCategoryIcon category={ProblemCategory.Megacities} /> </th>
                <th>Manufactories <EmpireCategoryIcon category={ProblemCategory.Manufactories} /> </th>
                <th></th>
            </>
        }>
            {props.solarSystems.map(s => <Row key={s.solarSystem.solarSystemId} solarSystem={s} />)}
        </Table>
    </SubPanel>;
};