import * as React from 'react';
import { IconHelper } from '../../Helpers/IconHelper';
import { Icon } from './Icon';

type Props = {
    to: string,
    text?: string | undefined
}

export const ExternalLink = (props: Props) => {

    const content = props.text === undefined ? props.to : props.text;

    return <a target="_blank" href={props.to}>{content} <Icon icon={IconHelper.General.OpenInNewWindow} /></a>
};