import * as React from "react";
import { IFleet, IMapApocalypseShot } from "../../../../ApplicationState/ApiClient";
import { Coordinate } from "../../../../Entities/Shared";
import { MapHelper } from "../../MapHelper";
import { MapPerspective } from "../MapEntities";
import { MapPreferences } from "../MapPreferences";
import { ApocalypseShotLayer } from "./ApocalypseShotLayer";
import { FleetLayer } from "./FleetLayer";
import { RadarLayer } from "./RadarLayer";

type SharedProps = {
    selectedFleetId: number | undefined,
    setSelectedFleetId: (fleetId: number) => void,
    fleets: IFleet[] | undefined | null,
    apocalypseShots: IMapApocalypseShot[],
    preferences: MapPreferences,
    selectedSolarSystemId: number | undefined
}

type Props = SharedProps & {
    perspective: MapPerspective,
    coord: Coordinate
}

export const Overlay = (props: Props) => {

    React.useEffect(() => {
    }, [props.preferences.showOverlay]);

    if (!props.preferences.showOverlay) {
        return null;
    }

    const diff = MapHelper.coordinateDiff(props.coord, props.perspective);

    const offset = {
        offsetX: props.perspective.minX + diff.x,
        offsetY: props.perspective.minY + diff.y
    };

    return <>
        <RadarLayer {...props} {...offset} />
        <FleetLayer {...props} {...offset} />
        <ApocalypseShotLayer {...props} {...offset} />
    </>;
};
