import classNames from 'classnames';

type Props = {
    className?: string | undefined
    defaultValue?: string | number | string[] | undefined,
    value?: string | number | string[] | undefined,
    valueChanged: (value: string) => any,
    values: { value: string | number, label: string, group?: string | undefined }[],
    showUnset?: boolean | { value?: string | number | undefined, label: string },
    isCompact?: boolean,
    isNotFullWidthMobile?: boolean
}

export const Select = (props: Props) => {

    function group() {
        const g: { [key: string]: { value: string | number, label: string }[] } = {};

        for (let value of props.values) {
            const name = value.group === undefined ? "Ungrouped" : value.group;

            if (!(name in g)) {
                g[name] = [];
            }

            g[name].push(value);
        }

        return g;
    }

    const className = classNames("select",
        props.className,
        {
            "is-compact": props.isCompact,
            "is-not-fullwidth-mobile": props.isNotFullWidthMobile
        });

    const anyGroups = props.values.find(x => x.group !== undefined);

    const grouped = anyGroups && group();

    return <div className={className}>
        <select
            defaultValue={props.defaultValue}
            onChange={e => props.valueChanged(e.target.value)}
            value={props.value}
        >
            {props.showUnset === true && <option>
                Unset
            </option>}
            {props.showUnset && typeof props.showUnset !== "boolean" && <option value={props.showUnset.value}>
                {props.showUnset.label}
            </option>}
            {grouped === undefined && props.values.map(v => <option key={v.value} value={v.value}>
                {v.label}
            </option>)}
            {grouped && Object.keys(grouped).map(x => <optgroup key={x} label={x}>
                {grouped[x].map(v => <option key={v.value} value={v.value}>
                    {v.label}
                </option>)}
            </optgroup>)}
        </select>
    </div>;
};
