import * as React from 'react';
import { SecurityStatus } from '../../../ApplicationState/ApiClient';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { MessageBoxWithIcon } from '../../Base/MessageBox';

type Props = {
    newSecurityStatus: SecurityStatus,
    reason: string
}

export const SecurityStatusChange = (props: Props) => {

    const reason = `${props.reason} will change your security status to ${ValueFormatter.friendlyNameForSecurityStatus(props.newSecurityStatus)}.`;

    return <MessageBoxWithIcon
        type={props.newSecurityStatus === SecurityStatus.Exile ? "danger" : "warning"}
        icon={IconHelper.Security.securityStatus(props.newSecurityStatus)}
        text={reason}
    />;
}