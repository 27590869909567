import { observer } from "mobx-react-lite";
import React from "react";
import { IFleet } from "../../../ApplicationState/ApiClient";
import { AppStateContext, PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { FleetWrapper } from "../../../Entities/FleetWrapper";
import { FleetHelper } from "../../../Helpers/FleetHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { send_ships } from "../../../Navigation/Routing/Misc";
import { Button } from "../../Base/Buttons/Button";
import { ButtonLink } from "../../Base/Buttons/ButtonLink";

type Props = {
    availableShips: FleetWrapper,
    targetFleet: IFleet,
    additionalParams?: string | undefined
    noText?: boolean,
    text?: string | undefined,
    className?: string | undefined
}

type SolarSystemProps = Props & {
    solarSystemId: number
}

export const InterceptFleetButton = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }


    if (!FleetHelper.canIntercept(props.targetFleet, playerState.Player, props.availableShips)) {
        return null;
    }

    return <ButtonLink
        className={props.className}
        icon={IconHelper.Combat.Intercept}
        to={`${send_ships}?targetFleetId=${props.targetFleet.fleetId}${props.additionalParams && props.additionalParams.length > 0 ? `&${props.additionalParams}` : ""}`}
        text={props.noText ? "" : props.text ? props.text : "Intercept"}
        hideTextOnMobile
    />;
});

export const SolarSystemInterceptFleetButton = observer((props: SolarSystemProps) => {

    const playerState = React.useContext(PlayerStateContext);
    const appState = React.useContext(AppStateContext);

    if (!playerState.Player) {
        return null;
    }

    if (!FleetHelper.canIntercept(props.targetFleet, playerState.Player, props.availableShips)) {
        return null;
    }

    function switchAndIntercept() {
        appState.changeSolarSystemId(props.solarSystemId);
        return appState.navigate(`${send_ships}?targetFleetId=${props.targetFleet.fleetId}${props.additionalParams && props.additionalParams.length > 0 ? `&${props.additionalParams}` : ""}`);
    }

    return <Button
        type="nav"
        className={props.className}
        icon={IconHelper.Combat.Intercept}
        action={switchAndIntercept}
        text={props.noText ? "" : props.text ? props.text : "Intercept"}
        hideTextOnMobile
    />;
});