import { AttackTerms, BribeForManifestoTerms, BribeForPolicyTerms, ContractStatus, ContractType, CourierTerms, DefendTerms, FleetMovementType, GameSettings, IAgent, IContract, IPlayer, ITerms, RansomTerms, ReconTerms } from "../ApplicationState/ApiClient";
import { RelationHelper } from "./RelationHelper";

export type ContractCost = {
    amount: number,
    isTakenImmediately: boolean,
    isPaidByContractor?: boolean
}

export class ContractHelper {

    static isCalloffContract(type: ContractType) {
        return type === ContractType.Courier || type === ContractType.Defend;
    }

    public static findRansomContract(contracts: IContract[], agent: IAgent) {

        for (let contract of contracts) {

            if (contract.status !== ContractStatus.Accepted && contract.status !== ContractStatus.Open) {
                continue;
            }

            if (this.isRansomTerms(contract.terms) && contract.terms.agentId === agent.agentId) {
                return contract;
            }
        }

        return undefined;
    }

    public static availableContractTypes(forPlayerId: number | undefined) {
        const types = [
            ContractType.Attack,
            ContractType.Recon,
            ContractType.Defend,
            ContractType.Courier
        ];

        if (forPlayerId !== undefined) {
            types.push(ContractType.BribeForManifesto);
            types.push(ContractType.BribeForPolicy);
        }

        return types;
    }

    public static instantiateTerms(contractType: ContractType, agentId?: number) {

        switch (contractType) {
            case ContractType.Attack:
                return new AttackTerms();
            case ContractType.Defend:
                return new DefendTerms();
            case ContractType.Recon:
                return new ReconTerms();
            case ContractType.Courier:
                return new CourierTerms();
            case ContractType.BribeForPolicy:
                return new BribeForPolicyTerms();
            case ContractType.BribeForManifesto:
                return new BribeForManifestoTerms();
            case ContractType.Ransom:
                return RansomTerms.fromJS({
                    agentId: agentId
                });
        }
    }

    public static calculateAmountAndProcessingFee(terms: ITerms, gameSettings: GameSettings) {

        const fee = this.calculateAmount(terms);

        if (fee === undefined || fee.amount === undefined) {
            return undefined;
        }

        const processingFee = fee.amount * gameSettings.economy.contractProcessingFeePercent;

        return {
            ...fee,
            processingFee,
            total: processingFee + (fee.isTakenImmediately ? fee.amount : 0)
        };
    }

    public static calculateAmount(terms: ITerms): ContractCost | undefined {
        if (terms instanceof AttackTerms) {
            return {
                amount: terms.payment,
                isTakenImmediately: true
            };
        }
        if (terms instanceof ReconTerms) {
            return {
                amount: terms.payment,
                isTakenImmediately: true
            };
        }
        if (terms instanceof BribeForManifestoTerms) {
            return {
                amount: terms.payment,
                isTakenImmediately: true
            };
        }
        if (terms instanceof BribeForPolicyTerms) {
            return {
                amount: terms.payment,
                isTakenImmediately: true
            };
        }
        if (terms instanceof DefendTerms) {
            return {
                amount: terms.defencePowerRequired * terms.paymentPerDefencePower,
                isTakenImmediately: true
            };
        }
        if (terms instanceof CourierTerms) {
            return {
                amount: terms.paymentPerUnit * terms.quantity,
                isTakenImmediately: false
            };
        }
        if (terms instanceof RansomTerms) {
            return {
                amount: terms.cost,
                isTakenImmediately: false,
                isPaidByContractor: true
            };
        }
        return undefined;
    }

    public static canDecline(contract: IContract, playerId: number) {

        if (contract.playerId === playerId) {
            return false;
        }

        if (contract.forPlayerId !== undefined && contract.forPlayerId !== null && contract.forPlayerId === playerId) {
            return contract.status === ContractStatus.Open;
        }

        return false;
    }

    public static canAccept(contract: IContract, player: IPlayer) {

        const playerId = player.playerId;

        if (contract.playerId === playerId) {
            return false;
        }

        if (contract.type === ContractType.Recon || contract.type === ContractType.Attack) {
            if (contract.targetSolarSystem !== undefined && contract.targetSolarSystem.playerId === playerId) {
                return false;
            }
            if (RelationHelper.isAlliedWith(player, contract.targetSolarSystem?.owner)) {
                return false;
            }
        }

        if (contract.forPlayerId !== undefined && contract.forPlayerId !== null && contract.forPlayerId !== playerId) {
            return false;
        }
        if (contract.status === ContractStatus.Accepted) {
            return contract.type === ContractType.Courier || contract.type === ContractType.Defend || contract.type === ContractType.Recon;
        }

        return contract.status === ContractStatus.Open;
    }

    public static canWithdraw(contract: IContract, playerId: number) {
        if (playerId !== contract.playerId) {
            return false;
        }

        if (contract.status === ContractStatus.Accepted && contract.type === ContractType.Courier) {
            return true;
        }

        return contract.status === ContractStatus.Open;
    }

    public static isBribeForManifestoTerms(object: any): object is BribeForManifestoTerms {
        return "type" in object && object.type === "BribeForManifestoTerms";
    }

    public static isBribeForPolicyTerms(object: any): object is BribeForPolicyTerms {
        return "type" in object && object.type === "BribeForPolicyTerms";
    }

    public static isAttackTerms(object: any): object is AttackTerms {
        return "type" in object && object.type === "AttackTerms";
    }

    public static isReconTerms(object: any): object is ReconTerms {
        return "type" in object && object.type === "ReconTerms";
    }

    public static isDefendTerms(object: any): object is DefendTerms {
        return "type" in object && object.type === "DefendTerms";
    }

    public static isCourierTerms(object: any): object is CourierTerms {
        return "type" in object && object.type === "CourierTerms";
    }

    public static isRansomTerms(object: any): object is RansomTerms {
        return "type" in object && object.type === "RansomTerms";
    }

    public static browsableContractTypes() {
        return [
            ContractType.Courier,
            ContractType.Defend,
            ContractType.Recon,
            ContractType.Attack,
            ContractType.Ransom,
        ];
    }

    public static fleetMovementTypeForContract(contract: IContract) {

        if (contract.type === ContractType.Attack) {
            return FleetMovementType.Attack;
        }
        if (contract.type === ContractType.Defend) {
            return FleetMovementType.Reinforce;
        }
        if (contract.type === ContractType.Recon) {
            return FleetMovementType.Recon;
        }
        if (contract.type === ContractType.Courier) {
            return FleetMovementType.Collection;
        }
        if (contract.type === ContractType.Ransom) {
            return FleetMovementType.Collection;
        }

        return undefined;
    }
}