import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router-dom";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { federation_incomingFleets, federation_view, federations_own } from "../../Routing/Federation";
import { MenuLink, isActiveSubMenu } from "../MenuState";
import { CustomMenu } from "./CustomMenu";

export const YourFederationMenu = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const location = useLocation();

    const [isOpen, setIsOpen] = React.useState(false);

    if (!playerState.Player || !playerState.Player.federation) {
        return null;
    }

    const federation = playerState.Player.federation;

    const isSubViewActive = isActiveSubMenu(location, federations_own, federation_incomingFleets, federation_view(federation.federationId ?? 0));

    return <CustomMenu
        className={isSubViewActive}
        canBeOpened
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        primaryLink={
            <MenuLink className="navbar-link" to={federation_view(federation.federationId ?? 0)}>
                [{playerState.Player.federation.shortName}]
            </MenuLink>
        }
    >
        <MenuLink to={federation_view(federation.federationId ?? 0)} className="navbar-item">
            {playerState.Player.federation.name}
        </MenuLink>
        <MenuLink to={federation_incomingFleets} className="navbar-item">
            Incoming Fleets
        </MenuLink>
    </CustomMenu>;
});