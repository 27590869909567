import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IPlayerMinimal, PresidentialManifesto, PresidentialTermDetail } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, UnreadStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { Icon } from '../../../Components/Base/Icon';
import { InfoMessageBox } from '../../../Components/Base/MessageBox';
import { CountingNumber } from '../../../Components/Base/Text/CountingNumber';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { QuoteParagraph } from '../../../Components/Base/Text/QuoteParagraph';
import { handleOnClick } from '../../../Components/Base/Util';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    areVotesCounted: boolean,
    presidentialTerm: PresidentialTermDetail,
    reloadCallback: () => any
}

type RowProps = {
    player: IPlayerMinimal,
    hasAcceptedBribe: boolean,
    areVotesCounted: boolean,
    presidentialManifesto: PresidentialManifesto,
    reloadCallback: () => any
}

const ManifestoRow = (props: RowProps) => {

    const apiState = React.useContext(ApiStateContext);
    const unreadState = React.useContext(UnreadStateContext);

    const [isExpanded, setIsExpanded] = React.useState(false);

    function vote() {
        return apiState.PoliticsClient.voteForManifesto(props.presidentialManifesto.presidentialManifestoId).then(_ => {
            unreadState.clearUnread("presidentialTermVote");
            props.reloadCallback();
        });
    }

    return <>
        <tr onClick={e => handleOnClick(e, () => setIsExpanded(!isExpanded))}>
            {props.areVotesCounted && <td>{ValueFormatter.formatLocaleNumber(props.presidentialManifesto.rank)}</td>}
            <td>
                <PlayerLink player={props.presidentialManifesto.player} />
            </td>
            {props.areVotesCounted && <td> <CountingNumber value={props.presidentialManifesto.votes} />  </td>}
            <td className="is-hidden-mobile">
                <QuoteParagraph>
                    {props.presidentialManifesto.title}
                </QuoteParagraph>
            </td>
            <td>
                {props.presidentialManifesto.didObservingPlayerVoteForThis && <span><span className="is-hidden-mobile">You voted for this</span><Icon icon={IconHelper.Politics.Vote} /> </span>}
            </td>
            <td>
                <ButtonHolder isPulledRight>
                    {!props.hasAcceptedBribe &&
                        !props.areVotesCounted &&
                        !props.presidentialManifesto.didObservingPlayerVoteForThis &&
                        !props.player.isDefeated &&
                        <Button
                            type="action"
                            action={vote}
                            icon={IconHelper.Politics.Vote}
                            text={"Vote for this"}
                            hideTextOnMobile
                        />}
                    <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </ButtonHolder>
            </td>
        </tr>
        <tr className='is-hidden-tablet'>
            <td colSpan={4}>
                <QuoteParagraph>
                    {props.presidentialManifesto.title}
                </QuoteParagraph>
            </td>
        </tr>
        {isExpanded && <tr>
            <td colSpan={4 + (props.areVotesCounted ? 2 : 0)}>
                <QuoteParagraph>
                    {props.presidentialManifesto.description}
                </QuoteParagraph>
            </td>
        </tr>}
    </>;
};

export const ManifestosTable = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    if (props.presidentialTerm.presidentialManifestos.length === 0) {
        return <Paragraph type="prompt">There are currently no presidential manifestos for this term.</Paragraph>
    }

    return <>
        {props.presidentialTerm.hasObservingPlayerAcceptedManifestoBribe && <InfoMessageBox text="You have accepted a bribe for this presidential term" />}
        <Table
            hideHeaderMobile
            isFullWidth
            isHoverable
            isClickable
            isMobileMultirow
            heading={<>
                {props.areVotesCounted && <th>Rank</th>}
                <th>
                    Player
                </th>
                {props.areVotesCounted && <th>Votes</th>}
                <th>
                    Manifesto
                </th>
                <th>
                </th>
                <th>
                </th>
            </>}>
            {props.presidentialTerm.presidentialManifestos.map(x => <ManifestoRow
                key={x.presidentialManifestoId}
                player={playerState.Player!}
                presidentialManifesto={x}
                {...props}
                hasAcceptedBribe={props.presidentialTerm.hasObservingPlayerAcceptedManifestoBribe}
            />)}
        </Table>
    </>;
});