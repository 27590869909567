import { CelestialSize, Cost, ICelestial, ICelestialDetail, ICost, IGameSettings, IInstallation, IInstallationType, IInstallationTypeSettings, IItemTypeSettings, InstallationType, ISolarSystemDetail, ItemType, IWorldEffects, SolarSystemClaimType } from "../ApplicationState/ApiClient";
import { TimeSpan } from "../Entities/TimeSpan";
import { ActiveEffectsHelper } from "./ActiveEffectsHelper";
import { CollectionHelper } from "./CollectionHelper";
import { TimeHelper } from "./TimeHelper";

export type UpgradeDetails = {
    installation: IInstallation,
    celestial: ICelestialDetail,
    nextLevel: number,
    nextCost: Cost | undefined,
    hasNextLevel: boolean,
    upgradeError: string | ItemType[] | undefined,
    canUpgrade: boolean
}

export class BuildHelper {

    public static canBeBultAtSolarSystemClaimType(installationType: IInstallationType, claimType: SolarSystemClaimType) {

        if ((installationType.builtOnSolarSolarSystemClaimTypes?.length ?? 0) === 0) {
            return true;
        }

        return installationType.builtOnSolarSolarSystemClaimTypes?.includes(claimType) ?? false;
    }

    public static calculateDurationInHours(cost: Cost, gameSettings: IGameSettings, multipliedBy?: number) {
        return (TimeSpan.fromString(cost.duration).totalHours * (multipliedBy !== undefined ? multipliedBy : 1)) / gameSettings.gameSpeed;
    }

    public static upgradeDetails(installation: IInstallation, celestial: ICelestialDetail, solarSystem: ISolarSystemDetail,
        installationTypeSettings: IInstallationTypeSettings,
        itemTypeSettings: IItemTypeSettings,
        gameSettings: IGameSettings, worldEffects: IWorldEffects, level?: number | undefined): UpgradeDetails {

        const nextLevel = (level === undefined ? installation.level : level) + 1;
        const nextCost = BuildHelper.costForLevel(worldEffects, installationTypeSettings, installation.installationTypeName, nextLevel, gameSettings, celestial.size);
        const hasNextLevel = !!nextCost;
        const upgradeError = hasNextLevel ? BuildHelper.upgradeError(installation, celestial, solarSystem, gameSettings, installationTypeSettings, itemTypeSettings, worldEffects) : undefined;

        const canUpgrade = hasNextLevel && !upgradeError;

        return {
            installation,
            celestial,
            nextLevel,
            nextCost,
            hasNextLevel,
            upgradeError,
            canUpgrade
        };
    }

    public static isCelestialTypeValid(installationType: InstallationType, celestialTypeName: string | undefined) {

        if (!installationType.builtOnCelestialTypeNames || installationType.builtOnCelestialTypeNames.length === 0) {
            return true;
        }

        for (const type of installationType.builtOnCelestialTypeNames) {
            if (type === celestialTypeName) {
                return true;
            }
        }

        return false;
    }

    public static canAddToBuildQueue(solarSystem: ISolarSystemDetail, celestialId: number, installationId: number | undefined, gameSettings: IGameSettings): boolean {
        const installationBuildQueue = solarSystem.installationBuildQueue;
        if (installationBuildQueue) {
            if (installationBuildQueue.length + 1 > gameSettings.installation.buildQueueCapacity) {
                return false;
            }
            if (installationBuildQueue.findIndex(c => c.installationId === installationId) >= 0) {
                return false;
            }
        }

        return true;
    }

    public static canDeconstruct(installation: IInstallation, celestial: ICelestial, solarSystem: ISolarSystemDetail, gameSettings: IGameSettings | undefined, installationTypeSettings: IInstallationTypeSettings | undefined, overrideCanBeDeconstructed: boolean): boolean {
        if (!gameSettings || !installationTypeSettings) {
            return false;
        }

        if (!this.canAddToBuildQueue(solarSystem, celestial.celestialId, installation.installationId, gameSettings)) {
            return false;
        }

        const deconstruct = overrideCanBeDeconstructed || installationTypeSettings.data![installation.installationTypeName].canBeDeconstructed;

        return installation.level > 0 && deconstruct;
    }

    public static upgradeError(installation: IInstallation, celestial: ICelestial, solarSystem: ISolarSystemDetail, gameSettings: IGameSettings | undefined,
        installationTypeSettings: IInstallationTypeSettings | undefined,
        itemTypeSettings: IItemTypeSettings | undefined,
        worldEffects: IWorldEffects | undefined): string | ItemType[] | undefined {

        return this.addToBuildQueueError(installation.installationId, installation.installationTypeName, installation.level + 1, celestial, solarSystem, gameSettings, installationTypeSettings, itemTypeSettings, worldEffects);
    }

    public static buildError(installationTypeName: string, celestial: ICelestial, solarSystem: ISolarSystemDetail, gameSettings: IGameSettings | undefined,
        installationTypeSettings: IInstallationTypeSettings | undefined,
        itemTypeSettings: IItemTypeSettings | undefined,
        worldEffects: IWorldEffects | undefined): string | ItemType[] | undefined {
        return this.addToBuildQueueError(undefined, installationTypeName, 1, celestial, solarSystem, gameSettings, installationTypeSettings, itemTypeSettings, worldEffects);
    }

    private static addToBuildQueueError(installationId: number | undefined, installationTypeName: string, level: number, celestial: ICelestial, solarSystem: ISolarSystemDetail, gameSettings: IGameSettings | undefined,
        installationTypeSettings: IInstallationTypeSettings | undefined,
        itemTypeSettings: IItemTypeSettings | undefined,
        worldEffects: IWorldEffects | undefined): string | ItemType[] | undefined {
        if (!gameSettings || !installationTypeSettings || !worldEffects) {
            return "";
        }

        const installationBuildQueue = solarSystem.installationBuildQueue;

        if (installationBuildQueue) {
            if (installationBuildQueue.length + 1 > gameSettings!.installation.buildQueueCapacity) {
                return "Installation build queue is already full";
            } if (installationBuildQueue.findIndex((c) => c.installationId === installationId) >= 0) {
                return "Something is already under construction here";
            }
        }

        const cost = BuildHelper.costForLevel(
            worldEffects,
            installationTypeSettings,
            installationTypeName,
            level,
            gameSettings,
            celestial.size
        );

        if (!cost) {
            return "Already at maximum level";
        }

        return BuildHelper.itemsError(itemTypeSettings, solarSystem, cost.items);
    }

    public static effectiveMaxLevel(installationType: IInstallationType, celestialSize: CelestialSize): number {
        let maxLevel = 0;

        if (installationType.maxLevel) {
            maxLevel = installationType.maxLevel;
        } else if (installationType.costPerLevel) {
            const levels = Object.keys(installationType.costPerLevel).map(k => Number(k));
            maxLevel = Math.max(...levels);
        }

        if (installationType.maxLevelsPerCelestialSize) {
            const maxFromCelestial = installationType.maxLevelsPerCelestialSize * celestialSize;
            if (maxFromCelestial < maxLevel) {
                return maxFromCelestial;
            }
        }

        return maxLevel;
    }

    public static costForLevel(worldEffects: IWorldEffects | undefined, installationTypeSettings: IInstallationTypeSettings | undefined, installationTypeName: string, level: number, gameSettings: IGameSettings | undefined, celestialSize: CelestialSize): Cost | undefined {

        const cost = this.costForLevelWithoutActiveEffects(installationTypeSettings, installationTypeName, level, gameSettings, celestialSize);

        if (cost === undefined || worldEffects === undefined) {
            return undefined;
        }

        const modifier = ActiveEffectsHelper.installationCostModification(worldEffects);

        if (modifier === 1) {
            return cost;
        }

        const sts = TimeHelper.createTimeSpanFromTimeSpanString(cost.duration);
        const ts = TimeSpan.fromTimeStartingFromDays(sts.days ?? 0, sts.hours ?? 0, sts.minutes ?? 0, sts.seconds, 0);
        const modified = new TimeSpan(ts.totalMilliseconds * modifier);

        const flat = Object.keys(cost.items).map(i => {
            return {
                key: i,
                value: modifier * cost.items[i]
            }
        });

        const data: ICost = {
            duration: modified.toString(),
            items: CollectionHelper.arrayToDictionary(flat)
        };

        return new Cost(data);
    }

    public static costForLevelWithoutActiveEffects(installationTypeSettings: IInstallationTypeSettings | undefined, installationTypeName: string, level: number, gameSettings: IGameSettings | undefined, celestialSize: CelestialSize): Cost | undefined {

        if (!installationTypeSettings || !gameSettings) {
            return undefined;
        }

        const installationType = installationTypeSettings.data![installationTypeName];

        const effectiveMaxLevel = this.effectiveMaxLevel(installationType, celestialSize);

        if (level > effectiveMaxLevel) {
            return undefined;
        }

        if (installationType.costPerLevel!.hasOwnProperty(level.toString())) {
            const cost = installationType.costPerLevel![level.toString()];

            return cost;
        }

        return undefined;
    }

    public static canAfford(cost: Cost, solarSystem: ISolarSystemDetail) {

        if (solarSystem.items) {

            for (let item of Object.keys(cost.items)) {

                if (!(item in solarSystem.items) || cost.items[item] > solarSystem.items[item]) {

                    return false;
                }
            }

            return true;
        }

        return false;
    }

    public static itemsError(itemTypeSettings: IItemTypeSettings | undefined, solarSystem: ISolarSystemDetail, items: { [key: string]: number } | undefined): string | ItemType[] | undefined {

        if (items) {
            if (!solarSystem.items) {
                return "Not enough resources";
            }

            const itemErrors: ItemType[] = [];

            for (let item of Object.keys(items)) {

                if (!(item in solarSystem.items) || items[item] > solarSystem.items[item]) {

                    const itemType = itemTypeSettings!.data![item];
                    itemErrors.push(itemType);
                }
            }

            if (itemErrors.length > 0) {
                return itemErrors;
            }
        }

        return undefined;
    }
}