import { TickOrCrossIcon } from '../../Components/FusionShift/Icons/TickOrCrossIcon';
import { MissionWrapper } from './MissionWrapper';

type Props = {
    mission: MissionWrapper
}

export const MissionStatus = (props: Props) => {

    return <span>
        <span className="is-hidden-mobile">
            {props.mission.Status}
        </span>
        {props.mission.IsCompleteOrRewardPending && <TickOrCrossIcon type="tick" />}
    </span>;
};