import { observer } from "mobx-react-lite";
import React from "react";
import { ICelestialDetail, Megacity } from "../../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Table } from "../../../../../Components/Base/Containers/Table";
import { ProgressBar } from "../../../../../Components/Base/ProgressBar";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { SectorTypeBonusList } from "../../../../../Components/FusionShift/Descriptions/SectorTypeBonusList";
import { SectorTypeIcon } from "../../../../../Components/FusionShift/Icons/SectorTypeIcon";
import { SectorTypeLink } from "../../../../../Components/FusionShift/Links/SectorTypeLink";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";

type Props = {
    celestial: ICelestialDetail,
    megacity: Megacity
}

export const SectorSummary = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.SectorTypeSettings) {
        return null;
    }

    const sectors = Object.keys(props.megacity.sectors)
        .map(k => {
            const installation = props.celestial.installations &&
                props.celestial.installations.find(i => i !== undefined &&
                    i.installationTypeName in worldState.InstallationTypeSettings!.data &&
                    worldState.InstallationTypeSettings!.data[i.installationTypeName].megacitySectorTypeName === k);

            return {
                sector: props.megacity.sectors[k],
                sectorType: worldState.SectorTypeSettings!.data[k],
                installationLevel: installation ? installation.level : undefined
            };
        });

    if (sectors.length === 0) {
        return <Paragraph type="missing">No sectors have been constructed. Build Megacity sector installations to assign population to sectors.</Paragraph>;
    }

    return <Table isFullWidth heading={
        <>
            <td>Type</td>
            <td>Assigned</td>
            <td>Effects</td>
        </>
    }>
        {sectors.map(s => <tr key={s.sectorType.typeName}>
            <td>
                <SectorTypeLink sectorType={s.sectorType} hideTextOnMobile /> [{s.installationLevel}]
            </td>
            <td>
                <ProgressBar
                    leftIcon={<SectorTypeIcon sectorTypeName={s.sectorType.typeName} />}
                    value={s.sector.assignedPopulation}
                    max={s.sector.maximumPopulation}
                    uncoloured
                    text={`${ValueFormatter.formatPopulation(s.sector.assignedPopulation)} / ${ValueFormatter.formatPopulation(s.sector.maximumPopulation)}`}
                />
            </td>
            <td>
                <SectorTypeBonusList sectorType={s.sectorType} bonus={s.sector.currentBonus} isBrief assignedPopulation={s.sector.assignedPopulation} />
            </td>
        </tr>)}
    </Table>;
});