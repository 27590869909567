import * as React from 'react';
import { DescriptionStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ThumbnailSlate } from '../../../Components/Base/Containers/Slate';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { PolicyTypeDuration } from '../../../Components/FusionShift/Descriptions/PolicyTypeDuration';
import { PolicyTypeIcon } from '../../../Components/FusionShift/Icons/PolicyTypeIcon';
import { PolicyTypeLink } from '../../../Components/FusionShift/Links/PolicyTypeLink';
import { TypeDataDescriptionParagraph } from '../../../Components/FusionShift/TypeDataDescriptionParagraph';
import { IconHelper } from '../../../Helpers/IconHelper';
import { PolicyExtras } from '../Components/PolicyExtras';
import { PolicyProposal } from '../ProposeView';

type Props = {
    policies: PolicyProposal[],
    policyTypeName: string | undefined,
    setPolicyTypeName: (policyTypeName: string) => any
}

export const ProposeList = (props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    return <>
        {props.policies.map(x => <ThumbnailSlate key={x.policyType.typeName}
            title={<PolicyTypeLink policyType={x.policyType} />}
            thumbnail={<PolicyTypeIcon isGiant policyType={x.policyType} />}
            isSelected={x.policyType.typeName === props.policyTypeName}
            onClick={() => props.setPolicyTypeName(x.policyType.typeName)}
            footerContent={
                <Button
                    type="nav"
                    icon={x.activePolicy === undefined ? IconHelper.General.Confirm : IconHelper.General.Cancel}
                    action={() => props.setPolicyTypeName(x.policyType.typeName)} text={x.activePolicy === undefined ? "Propose" : "Repeal"}
                    isSelected={x.policyType.typeName === props.policyTypeName}
                    isPulledRight
                />
            } >
            <TypeDataDescriptionParagraph description={descriptionState.Policies?.find(y => y.typeName === x.policyType.typeName)} />
            <PolicyTypeDuration policyType={x.policyType} />
            <PolicyExtras policyType={x.policyType} />
        </ThumbnailSlate>)}
    </>;
};

export const SplitProposeList = (props: Props) => {

    const policies = props.policies.sort((a, b) => a.policyType.order - b.policyType.order);

    const mid = Math.floor(props.policies.length / 2);
    const a = policies.slice(0, mid);
    const b = policies.slice(mid, props.policies.length);

    return <SplitLayout
        isLeftEmpty={a.length === 0}
        isRightEmpty={b.length === 0}
        left={<ProposeList  {...props} policies={a} />}
        right={<ProposeList  {...props} policies={b} />}
    />
};
