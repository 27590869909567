import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AgentStatus, IAgentDetail } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';

type Props = {
    agent: IAgentDetail
}

export const CelestialPositionComponent = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (props.agent.celestialPosition === undefined || worldState.CelestialTypeSettings === undefined) {
        return null;
    }

    function describeStatus() {
        switch (props.agent.status) {
            case AgentStatus.Captured:
                const extra = props.agent.celestialPosition!.solarSystem.owner ? <> and their fate is in the hands of <PlayerLink player={props.agent.celestialPosition!.solarSystem.owner} /></> : null;
                return <>The agent is a prisoner{extra}.</>;
            case AgentStatus.Covert:
                return "The agent is working covertly and can attempt to perform hostile actions.";
            case AgentStatus.Overt:
                return "The agent is working openly as a diplomat.";
            case AgentStatus.Stowaway:
                return "The agent is preparing to stow away aboard ships departing from the system.";
        }

        return "";
    }

    return <>
        <Paragraph>
            {props.agent.name} is currently at <SolarSystemLink solarSystem={props.agent.celestialPosition.solarSystem} extra={CelestialHelper.fullname(props.agent.celestialPosition.celestial, worldState.CelestialTypeSettings)} />.
        </Paragraph>
        <Paragraph>
            {describeStatus()}
        </Paragraph>
    </>;
});