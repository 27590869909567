import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Accolade } from '../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../ApplicationState/ContextRoot';
import { FlexDiv } from '../../Components/Base/Containers/FlexDiv';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { AccoladeBadge } from './AccoladesBadge';

type Props = {
    accolades: Accolade[] | undefined
}

export const AccoladeList = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.World || !props.accolades || props.accolades.length === 0) {
        return null;
    }

    const grouped: { [key: number]: Accolade[] } = props.accolades.reduce(
        (g, a) => {

            const d = a.date.toISOString();

            if (!(d in g)) {
                g[d] = [];
            }
            g[d].push(a);
            return g;
        }, {});

    return <SubPanel className="accolades" heading="Accolades">
        {Object.keys(grouped).sort((a, b) => a > b ? -1 : 1).map(x => <FlexDiv key={x}>
            {grouped[x].map((a, i) => <AccoladeBadge key={i} accolade={a} world={worldState.World!} />)}
        </FlexDiv>)}
    </SubPanel>;
});