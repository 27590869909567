import { CelestialDetail, Megacity } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemBuilder, ProblemCategory, ProblemsMaybe } from "../Problem";

export const megacityProblemBuilder = new ProblemBuilder(ProblemCategory.Megacities);

export function undevelopedSectorProblems(wrapper: SolarSystemWrapper, megacity: Megacity, celestial: CelestialDetail): ProblemsMaybe {

    if (!megacity.sectors) {
        return undefined;
    }

    const possibleSectors = celestial.size - 1;
    const actualSectors = Object.keys(megacity.sectors).length;

    if (possibleSectors > actualSectors) {
        const issue = actualSectors === 0 ? "none" : `only ${actualSectors}`;

        return megacityProblemBuilder.infoProblem(`Undeveloped Sectors`, `Celestial ${celestial.name} has space for ${possibleSectors} megacity sectors but ${issue} have been constructed`);
    }

    return undefined;
}