import { CelestialSize, Cost, GameSettings, IInstallationTypeSettings, IItemTypeSettings, InstallationType } from "../../../../ApplicationState/ApiClient";
import { CloseButton } from "../../../../Components/Base/Buttons/CloseButton";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../../Components/Base/Containers/Table";
import { Icon } from "../../../../Components/Base/Icon";
import { ResourceIcon } from "../../../../Components/FusionShift/Icons/Items/ResourceIcon";
import { BuildHelper } from "../../../../Helpers/BuildHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { TimeHelper } from "../../../../Helpers/TimeHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";

type Props = {
    itemTypeSettings: IItemTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    gameSettings: GameSettings,
    installationType: InstallationType,
    factionTypeName: string,
    closeCallback: () => any
}

export const InstallationCostLevelTable = (props: Props) => {

    const allLevels: { [key: string]: Cost } = {};
    const allResources: string[] = [];

    for (let i = 1; i <= BuildHelper.effectiveMaxLevel(props.installationType, CelestialSize.Giant); i++) {
        const cost = BuildHelper.costForLevelWithoutActiveEffects(props.installationTypeSettings, props.installationType.typeName, i, props.gameSettings, CelestialSize.Giant);
        if (cost) {
            allLevels[i] = cost;

            for (let resource in cost.items) {
                if (!allResources.includes(resource)) {
                    allResources.push(resource);
                }
            }
        }
    }

    return <SubPanel heading="All Costs"
        isUnpadded
        footerContent={
            <CloseButton action={props.closeCallback} isPulledRight />
        }>
        <Table
            isHoverable
            isFullWidth
            numberOfColumns={2 + allResources.length}
            heading={
                <>
                    <td>Level</td>
                    <td><Icon icon={IconHelper.General.Time} /></td>
                    {allResources.map(r => <td>
                        <ResourceIcon key={r} resourceType={props.itemTypeSettings.data[r]} />
                    </td>)}
                </>
            }
        >
            {Object.keys(allLevels).map(l => <tr key={l}>
                <td>{l}</td>
                <td>{TimeHelper.formatTimeSpanByWords(TimeHelper.createTimeSpanFromTimeSpanString(allLevels[l].duration))}</td>
                {allResources.map(r => <td>
                    {r in allLevels[l].items && ValueFormatter.formatLocaleNumber(allLevels[l].items[r])}
                </td>)}
            </tr>
            )}
        </Table>
    </SubPanel>;
};
