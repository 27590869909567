import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';
import { observer } from 'mobx-react-lite';
import * as React from "react";
import { useLocation } from 'react-router-dom';
import { ISimulationInput, SimulatedAttackingFleet, SimulationInput, SimulationResult } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { TabMenu } from '../../Components/Base/Containers/TabMenu';
import { IconHelper } from "../../Helpers/IconHelper";
import { UrlHelper } from '../../Helpers/UrlHelper';
import { combatSimulator } from '../../Navigation/Routing/Misc';
import { SimpleBaseView } from "../BaseView";
import { AttackerForm } from './AttackerForm';
import { defaultInput } from './Data';
import { DefenderForm } from "./DefenderForm";
import { Result } from "./Result";
import { validateSimulation } from './Validation';

type Tabs = "Attacker" | "Defender" | "Result";

export function compressCombatSimulation(simulationInput?: ISimulationInput) {
    return compressToEncodedURIComponent(JSON.stringify(simulationInput));
}

export const CombatSimulatorView = observer(() => {

    const worldState = React.useContext(WorldStateContext);
    const appState = React.useContext(AppStateContext);

    const location = useLocation();

    const apiState = React.useContext(ApiStateContext);

    const [result, setResult] = React.useState<SimulationResult | undefined>(undefined);
    const [tab, setTab] = React.useState<Tabs>("Attacker");
    const [simulation, setSimulation] = React.useState<ISimulationInput>(defaultInput);
    const [isDirty, setIsDirty] = React.useState(false);
    const [lastRunString, setLastRunString] = React.useState("");
    const [isSimulating, setIsSimulating] = React.useState(false);
    const [pendingSimulation, setPendingSimulation] = React.useState<ISimulationInput | undefined>();

    React.useEffect(() => {
        // Only load from string on initial view load
        const simulationFromSearch = UrlHelper.tryGetValueFromSearch(location, "simulation");

        if (simulationFromSearch !== undefined) {
            loadFromString(simulationFromSearch);
        }
    }, []);

    React.useEffect(() => {
        if (isSimulating && appState.IsLoggedIn) {
            setResult(undefined);
            const compressed = compressToEncodedURIComponent(JSON.stringify(simulation));
            setLastRunString(compressed);

            apiState.CombatReportClient.simulate(new SimulationInput(pendingSimulation ?? simulation)).then(result => {
                setResult(result);
                setIsDirty(false);
            }).finally(() => {
                setIsSimulating(false);
            });
        }
    }, [isSimulating, appState.IsLoggedIn]);

    function simulate(simulationInput?: ISimulationInput) {

        // This simulate method gets called with simulationInput parameter when loading from url string
        // In other cases it is actually the simulation from state which gets used

        const { isValid } = validateSimulation(simulationInput ?? simulation);

        if (!isValid) {
            return;
        }

        if (simulationInput !== undefined) {
            setPendingSimulation(simulationInput);
        }
        setIsSimulating(true);
    }

    function loadFromString(simulationString: string) {

        const decompressed = decompressFromEncodedURIComponent(simulationString);
        const parsed = JSON.parse(decompressed ?? "");

        const sim = SimulationInput.fromJS(parsed);

        changeSimulation(sim);

        simulate(sim);
        setTab("Result");
    }

    function changeSimulation(simulationInput: ISimulationInput) {

        const compressed = compressToEncodedURIComponent(JSON.stringify(simulationInput));

        window.history.pushState(null, "Combat Simulator", `${combatSimulator}?simulation=${compressed}`);

        setSimulation(simulationInput);
        setIsDirty(compressed !== lastRunString);
    }

    if (!worldState.ShipTypeSettings || !worldState.GameSettings || !worldState.InstallationTypeSettings || !worldState.CelestialTypeSettings || !worldState.ItemTypeSettings || !worldState.FactionTypeSettings) {
        return null;
    }

    const tabs = [
        {
            id: "Attacker",
            title: "Attacker",
            isAvailable: true,
            icon: IconHelper.Combat.Attack
        },
        {
            id: "Defender",
            title: "Defender",
            isAvailable: true,
            icon: IconHelper.Combat.Defence
        },
        {
            id: "Result",
            title: "Result",
            isAvailable: true,
            icon: IconHelper.Categories.Summary
        }
    ];

    return <SimpleBaseView heading={{ text: "Combat Simulator", icon: IconHelper.Combat.Simulation }}>
        <TabMenu tabs={tabs} active={tab} changeTab={setTab} />
        {tab === "Attacker" &&
            <AttackerForm
                shipTypeSettings={worldState.ShipTypeSettings}
                factionTypeSettings={worldState.FactionTypeSettings}
                celestialTypeSettings={worldState.CelestialTypeSettings}
                gameSettings={worldState.GameSettings} attacker={simulation.attacker} celestials={simulation.defendingSolarSystem?.celestials} setAttacker={attacker => changeSimulation({
                    ...simulation,
                    attacker: SimulatedAttackingFleet.fromJS(attacker)
                })} />
        }
        {tab === "Defender" &&
            <DefenderForm simulation={simulation} setSimulation={changeSimulation} gameSettings={worldState.GameSettings}
                installationTypeSettings={worldState.InstallationTypeSettings}
                celestialTypeSettings={worldState.CelestialTypeSettings}
                factionTypeSettings={worldState.FactionTypeSettings}
                shipTypeSettings={worldState.ShipTypeSettings}
            />
        }
        {tab === "Result" && <Result
            isDirty={isDirty}
            isSimulating={isSimulating}
            simulate={simulate}
            simulation={simulation}
            result={result}
        />
        }
    </SimpleBaseView>;
});