import { observer } from 'mobx-react-lite';
import { FleetMovementType } from '../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { SendShipsBag } from '../../SendShipsBag';
import { ConfigForm } from './ConfigForm';
import { RepeatControl } from './RepeatControl';

type Props = {
    sendShipsBag: SendShipsBag,
}

export const ConfigControl = observer((props: Props) => {

    if (props.sendShipsBag.MovementType === FleetMovementType.Claim) {
        return null;
    }

    return <SubPanel heading={{ text: "Config", icon: IconHelper.Categories.Settings }}>
        <ConfigForm {...props} />
        {(props.sendShipsBag.allowedConfigurations.repeat || props.sendShipsBag.IsEditingRepeatFleet) && <RepeatControl sendShipsBag={props.sendShipsBag} />}
    </SubPanel>;
});