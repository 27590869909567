import { observer } from "mobx-react-lite";
import React from "react";
import { CommodityBuyOffer, FleetMovementType, ISolarSystemForObserver, ItemType } from "../../../ApplicationState/ApiClient";
import { SolarSystemStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { CommodityUsage } from "../../../Helpers/CommodityHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { send_ships } from "../../../Navigation/Routing/Misc";
import { ButtonLink } from "../../Base/Buttons/ButtonLink";
import { SubPanel } from "../../Base/Containers/SubPanel";
import { Table } from "../../Base/Containers/Table";
import { TileHolder } from "../../Base/Containers/TileHolder";
import { CelestialTypeIcon } from "../Icons/CelestialTypeIcon";
import { CreditsIconWithQuantity } from "../Icons/Items/CreditsIconWithQuantity";
import { CommodityTypeLink } from "../Links/Items/CommodityTypeLink";

type Props = {
    solarSystem: ISolarSystemForObserver
}

type CelestialProps = {
    solarSystem: ISolarSystemForObserver,
    celestialId: number
}

type CommodityProps = CelestialProps & {
    commodities: {
        commodity: ItemType,
        buy: CommodityBuyOffer,
        usage: CommodityUsage
    }[]
}

const CelestialCommoditySaleTable = (props: CommodityProps) => {
    if (props.commodities.length === 0) {
        return null;
    }

    return <Table isHoverable heading={
        <>
            <td>Commodity</td>
            <td>Price</td>
            <td>Quantity</td>
        </>
    }>
        {props.commodities.map(c => <tr key={c.commodity.typeName}>
            <td>
                <CommodityTypeLink commodityType={c.commodity} />
            </td>
            <td>
                <CreditsIconWithQuantity quantity={c.buy.price} />
            </td>
            <td>
                {c.buy.quantity}
            </td>
        </tr>)}
    </Table>;
};

const CelestialCommoditySaleControl = observer((props: CelestialProps) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const celestial = props.solarSystem.celestials ? props.solarSystem.celestials.find(c => c.celestialId === props.celestialId) : undefined;
    const commodityBuys = props.solarSystem.commodityBuyOffersByCelestialId && props.celestialId in props.solarSystem.commodityBuyOffersByCelestialId ? props.solarSystem.commodityBuyOffersByCelestialId[props.celestialId] : undefined;

    if (!celestial || !commodityBuys || !worldState.ItemTypeSettings || !worldState.CommodityHelper) {
        return null;
    }

    const initial: { [key: string]: any } = {};
    const commodities = Object.keys(commodityBuys).map(c => {
        return {
            commodity: worldState.ItemTypeSettings!.data[c],
            buy: commodityBuys[c],
            usage: worldState.CommodityHelper!.commodityTypeUsage(c)
        }
    }).filter(c => c.usage)
        .reduce((g, c) => {
            g[c.usage!.sectorType.typeName] = (g[c.usage!.sectorType.typeName] || []).concat(c);
            return g;
        }, initial);

    const commodityKeys = Object.keys(commodities);

    const canSell = solarSystemState.SolarSystem && CollectionHelper.isAnyQuantityInDictionary(solarSystemState.SolarSystem.availableShips);

    return <SubPanel heading={{ text: `${celestial.name} Will Buy`, icon: <CelestialTypeIcon celestialTypeName={celestial.celestialTypeName ?? "Habitable"} /> }}
        headingContent={
            canSell && <ButtonLink
                icon={IconHelper.Ships.movementType(FleetMovementType.CommoditySell)}
                text="Sell Commodities"
                hideTextOnMobile
                to={`${send_ships}?targetX=${props.solarSystem.x}&targetY=${props.solarSystem.y}&celestialId=${props.celestialId}&type=${FleetMovementType.CommoditySell}`}
            />
        }>
        <TileHolder>
            {commodityKeys.map(c => <CelestialCommoditySaleTable key={c} {...props} commodities={commodities[c]} />)}
        </TileHolder>
    </SubPanel>;
});

export const CelestialCommoditySaleList = (props: Props) => {

    const celestials = props.solarSystem.commodityBuyOffersByCelestialId ? Object.keys(props.solarSystem.commodityBuyOffersByCelestialId) : [];
    if (celestials.length === 0) {
        return null;
    }

    return <>
        {celestials.map(c => <CelestialCommoditySaleControl key={c} solarSystem={props.solarSystem} celestialId={Number(c)} />)}
    </>;
};