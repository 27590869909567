import * as React from 'react';
import Helmet from 'react-helmet';

const baseTitle = "Fusion Shift";

function toTitle(subTitle: string | undefined) {
    const title = subTitle
        ? `${subTitle}: ${baseTitle}`
        : baseTitle;

    return title;
}

export function routeNonExact(path: string, Component: React.FunctionComponent<{}>, subTitle?: string) {
    return {
        path: path,
        element: <>
            <Helmet>
                <title>{toTitle(subTitle)}</title>
            </Helmet>
            <Component />
        </>,
        exact: "false",
    };
}

export function route(path: string, Component: React.FunctionComponent<{}>, subTitle?: string) {
    return {
        path: path,
        element: <>
            <Helmet>
                <title>{toTitle(subTitle)}</title>
            </Helmet>
            <Component />
        </>,
        exact: "true",
    };
}
