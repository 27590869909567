import React from "react";
import { IFleet, SolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { Table } from "../../../Components/Base/Containers/Table";
import { PagedLinks } from "../../../Components/Base/Paging/PagedLinks";
import { handleOnClick } from "../../../Components/Base/Util";
import { FleetArrivalDescription } from "../../../Components/FusionShift/FleetArrivalDescription";
import { FleetCargoIcons, FleetIcon } from "../../../Components/FusionShift/Icons/FleetIcon";
import { ShipQuantityIcons } from "../../../Components/FusionShift/Icons/ShipQuantityIcons";
import { FleetLocationLink } from "../../../Components/FusionShift/Links/FleetLocationLink";
import { PlayerLink } from "../../../Components/FusionShift/Links/PlayerLink";
import { FleetWrapper } from "../../../Entities/FleetWrapper";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { TimeHelper } from "../../../Helpers/TimeHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { FleetButtons } from "../Components/FleetButtons";
import { FleetConfigControl } from "../Components/FleetConfigControl";
import { FleetDetail } from "../Components/FleetDetail";
import { JoinSolarSystemForm } from "../Forms/JoinSolarSystemForm";
import { ReturnHomeForm } from "../Forms/ReturnHomeForm";

type DisplayMode = "none" | "own column" | "with linebreak";

type DisplayConfig = {
    headings?: boolean | "hide on mobile",
    source?: boolean,
    target?: boolean,
    owner?: DisplayMode,
    targetOwner?: "with linebreak",
    mass?: boolean,
    arrivalDate?: boolean,
    countPerPage?: number
}

type BaseProps = {
    selectedFleetId?: number | undefined,
    display: DisplayConfig,
    availableShips: FleetWrapper,
    solarSystemId?: number | undefined,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    setSelectedFleetId?: ((fleetId: number) => any) | undefined,
    buttons?: (props: RowProps) => React.ReactNode
}

type Props = BaseProps & {
    fleets: IFleet[] | undefined,
    isFixed?: boolean
}

type RowProps = BaseProps & {
    fleet: IFleet
}

const FleetRow = (props: RowProps) => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [isDetailExpanded, setIsDetailExpanded] = React.useState(false);
    const [isSendHomeExpanded, setIsSendHomeExpanded] = React.useState(false);
    const [isJoinSolarSystemExpanded, setIsJoinSolarSystemExpanded] = React.useState(false);

    React.useEffect(() => {
        if (isDetailExpanded) {
            setIsSendHomeExpanded(false);
            setIsJoinSolarSystemExpanded(false);
        }
    }, [isDetailExpanded]);

    React.useEffect(() => {
        if (isSendHomeExpanded) {
            setIsDetailExpanded(false);
            setIsJoinSolarSystemExpanded(false);
        }
    }, [isSendHomeExpanded]);

    React.useEffect(() => {
        if (isSendHomeExpanded) {
            setIsDetailExpanded(false);
            setIsSendHomeExpanded(false);
        }
    }, [isJoinSolarSystemExpanded]);

    if (!props.fleet || !playerState.Player || !worldState.ShipTypeSettings) {
        return null;
    }

    function sendHome(fleetId: number, shipsToSendHome: { [key: string]: number }) {
        return apiState.FleetClient.sendShipsHome(fleetId, props.fleet.ownerPlayerId === playerState.Player!.playerId, shipsToSendHome).then(solarSystemRespose => {
            if (solarSystemRespose) {
                setIsSendHomeExpanded(false);
                props.reloadCallback(solarSystemRespose);
            }
        });
    }

    function joinSolarSystem(fleetId: number, fromSolarSystemId: number, shipsToSendHome: { [key: string]: number }) {
        return apiState.FleetClient.reinforcementsJoinSolarSystem(fromSolarSystemId, fleetId, props.solarSystemId !== props.fleet.targetSolarSystem?.solarSystemId, shipsToSendHome).then(solarSystemRespose => {
            if (solarSystemRespose) {
                props.reloadCallback(solarSystemRespose);
                setIsJoinSolarSystemExpanded(false);
            }
        });
    }

    const hasDetail = CollectionHelper.isAnyQuantityInDictionary(props.fleet.itemsCargo) ||
        CollectionHelper.isAnyQuantityInDictionary(props.fleet.ships);

    const colSpan = 3 +
        (props.display.arrivalDate ? 1 : 0) +
        (props.display.source ? 1 : 0) +
        (props.display.target ? 1 : 0) +
        (props.display.mass ? 1 : 0) +
        (props.display.owner === "own column" ? 1 : 0);

    console.log(props);

    return <>
        <tr className={`${props.fleet.isNew ? "has-highlight-bg-success" : ""}${props.setSelectedFleetId !== undefined ? " is-clickable" : ""}${props.fleet.fleetId === props.selectedFleetId ? " selected" : ""}`}
            data-fleetId={props.fleet.fleetId}
            onClick={e => handleOnClick(e, () => {
                if (props.setSelectedFleetId) {
                    props.setSelectedFleetId(props.fleet.fleetId);
                }
            })
            }>
            <td>
                <FleetIcon fleet={props.fleet} />
                <FleetCargoIcons fleet={props.fleet} />
            </td>
            <td className="ship-quantities">
                <ShipQuantityIcons ships={props.fleet.ships} shipTypeSettings={worldState.ShipTypeSettings} />
            </td>
            {props.display.arrivalDate &&
                <td>
                    <div className={`${props.display.source ? "is-hidden-mobile" : ""}`}>
                        <FleetArrivalDescription fleet={props.fleet} />
                        {props.display.target && <span className="is-hidden-tablet">
                            <br />
                            <FleetLocationLink {...props} type="target" allowWrap />
                        </span>
                        }
                    </div>
                </td>
            }
            {props.display.source &&
                <td>
                    {props.display.arrivalDate &&
                        <div className="is-hidden-tablet">
                            <FleetArrivalDescription fleet={props.fleet} />
                        </div>
                    }
                    <FleetLocationLink {...props} type="source" allowWrap />
                    {props.fleet.owner &&
                        <div className={props.display.owner !== "with linebreak" ? "is-hidden-tablet" : ""}>
                            <PlayerLink player={props.fleet.owner} />
                        </div>
                    }
                </td>}
            {props.display.target &&
                <td className={`${props.display.source ? "" : "is-hidden-mobile"}`}>
                    <FleetLocationLink {...props} type="target" />
                    {props.fleet.targetSolarSystem?.owner &&
                        <div className={props.display.targetOwner !== "with linebreak" ? "is-hidden-tablet" : ""}>
                            <PlayerLink player={props.fleet.targetSolarSystem?.owner} hideFederationLink />
                        </div>
                    }
                </td>
            }
            {props.display.owner === "own column" &&
                <td className="is-hidden-mobile">
                    {props.fleet.owner && <PlayerLink player={props.fleet.owner} />}
                    {!props.fleet.owner && "Unknown"}
                </td>
            }
            {props.display.mass &&
                <td>
                    <span className="is-hidden-mobile">
                        {ValueFormatter.formatMass(props.fleet.mass, props.fleet.owner ? props.fleet.owner.playerId !== playerState.Player.playerId : true)}
                    </span>
                </td>
            }
            <td>
                {props.buttons !== undefined && props.buttons(props)}
                {props.buttons === undefined &&
                    <FleetButtons
                        fleet={props.fleet}
                        availableShips={props.availableShips}
                        player={playerState.Player}

                        sendHome={() => sendHome(props.fleet.fleetId, {})}
                        isSendHomeExpanded={isSendHomeExpanded}
                        setIsSendHomeExpanded={setIsSendHomeExpanded}

                        isJoinSolarSystemExpanded={isJoinSolarSystemExpanded}
                        setIsJoinSolarSystemExpanded={setIsJoinSolarSystemExpanded}

                        hasDetailExpand={hasDetail}
                        setIsDetailExpanded={setIsDetailExpanded}
                        isDetailExpanded={isDetailExpanded}
                    />
                }
            </td>
        </tr>
        {isDetailExpanded && <>
            <tr>
                <td colSpan={colSpan}>
                    <FleetDetail fleet={props.fleet} />
                </td>
            </tr>
            {props.fleet.config !== undefined && <tr>
                <td colSpan={colSpan}>
                    <FleetConfigControl {...props} />
                </td>
            </tr>}
        </>}
        {isSendHomeExpanded &&
            <tr>
                <td colSpan={colSpan}>
                    <ReturnHomeForm
                        fleet={props.fleet}
                        sendHomeCallback={s => sendHome(props.fleet.fleetId, s)}
                    />
                </td>
            </tr>
        }
        {isJoinSolarSystemExpanded &&
            <tr>
                <td colSpan={colSpan}>
                    <JoinSolarSystemForm
                        fleet={props.fleet}
                        joinSolarSystemCallback={s => joinSolarSystem(props.fleet.fleetId, props.fleet.targetSolarSystem!.solarSystemId, s)}
                    />
                </td>
            </tr>
        }
    </>;
};

export const FleetTable = (props: Props) => {

    const [currentPageFleets, setPageFleets] = React.useState<IFleet[]>([]);
    const [currentPageCount, setPageCount] = React.useState<number>(0);
    const [currentPageIndex, setPageIndex] = React.useState<number>(0);
    const defaultCountPerPage = 10;

    const countPerPage = props.display.countPerPage ?? defaultCountPerPage;

    React.useEffect(() => {
        if (!props.fleets) {
            return;
        }

        var pageCount = Math.floor(props.fleets.length / countPerPage + (props.fleets.length % countPerPage != 0 ? 1 : 0));

        const from = currentPageIndex * countPerPage;
        const to = from + countPerPage;

        const fleets = props.fleets.sort((a, b) => TimeHelper.sortDates(a.arrivalDate, b.arrivalDate)).slice(from, to);

        setPageCount(pageCount);
        setPageFleets(fleets);
    }, [currentPageIndex, props.fleets]);

    if (!currentPageFleets || currentPageFleets.length === 0) {
        return null;
    }

    return <>
        <Table
            isHoverable
            isFullWidth
            isFixed={props.isFixed}
            className="fleet-table"
            hideHeaderMobile={props.display.headings === "hide on mobile"}
            heading={props.display.headings &&
                <>
                    <th></th>
                    <th></th>
                    {props.display.arrivalDate && <th>Arrives</th>}
                    {props.display.source && <th>Source</th>}
                    {props.display.target && <th>Target</th>}
                    {props.display.owner === "own column" && <th className="is-hidden-mobile">Owner</th>}
                    {props.display.mass && <th>Mass</th>}
                    <th></th>
                </>
            }
        >
            {currentPageFleets.map((q, i) => {

                return <FleetRow
                    key={q.fleetId}
                    fleet={q}
                    {...props}
                />;
            })}
        </Table>
        <PagedLinks pageIndex={currentPageIndex} pageCount={currentPageCount} countPerPage={countPerPage} onLinkClicked={(pageIndex) => setPageIndex(pageIndex)} />
    </>;
};