import * as React from 'react';
import { CelestialSize, ICelestialTypeSettings, IInstallationTypeSettings, IShipTypeSettings, ISimulatedCelestial, SimulatedCelestial, SimulatedInstallation } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../Components/Base/Form/Select';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { BuildHelper } from '../../../Helpers/BuildHelper';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { enumFromString, valuesFromEnum } from '../../../Helpers/TypeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { defaultCelestial } from '../Data';
import { InstallationsForm } from './InstallationsForm';

type Props = {
    shipTypeSettings: IShipTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    celestials: ISimulatedCelestial[],
    setCelestials: (celestials: ISimulatedCelestial[]) => any
}

type RowProps = {
    shipTypeSettings: IShipTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    celestials: ISimulatedCelestial[],
    celestial: ISimulatedCelestial,
    setCelestial: (celestial: ISimulatedCelestial) => any,
    removeCelestial: () => void,
}

export function simulatedCelestialName(celestial: ISimulatedCelestial, celestialTypeSettings: ICelestialTypeSettings) {
    const asICelestial = {
        name: celestial.name,
        celestialTypeName: celestial.celestialTypeName,
        size: celestial.size
    };

    return CelestialHelper.fullname(asICelestial, celestialTypeSettings)
}

const CelestialRow = (props: RowProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);

    const canHaveInstallations = Object.values(props.installationTypeSettings.data).find(x => BuildHelper.isCelestialTypeValid(x, props.celestial.celestialTypeName)) !== undefined;

    return <>
        <tr>
            <td>
                {simulatedCelestialName(props.celestial, props.celestialTypeSettings)}
            </td>
            <td>
                {props.celestial.installations.length} Installations
            </td>
            <td>
                <ButtonHolder>
                    <Button type="danger" icon={IconHelper.General.Delete} action={props.removeCelestial} confirm="Are you sure you want to remove this celestial?" />
                    <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </ButtonHolder>
            </td>
        </tr>
        {isExpanded && <tr>
            <td colSpan={3}>
                <SplitLayout
                    left={
                        <FieldHolder label="Size">
                            <Select value={CelestialSize[props.celestial.size]} valueChanged={celestialSize => props.setCelestial({
                                ...props.celestial,
                                size: enumFromString(CelestialSize, celestialSize)
                            })}
                                values={valuesFromEnum(CelestialSize).map(x => {
                                    return {
                                        label: CelestialSize[x],
                                        value: x
                                    }
                                })}
                            />
                        </FieldHolder>}
                    right={
                        <FieldHolder label="Type">
                            <Select value={props.celestial.celestialTypeName} valueChanged={celestialTypeName => props.setCelestial({
                                ...props.celestial,
                                celestialTypeName
                            })}
                                values={Object.values(props.celestialTypeSettings.data).map(x => {
                                    return {
                                        label: x.name,
                                        value: x.typeName
                                    }
                                })}
                            />
                        </FieldHolder>
                    }
                />
                {canHaveInstallations &&
                    <InstallationsForm {...props} installations={props.celestial.installations} setInstallations={installations => props.setCelestial({
                        ...props.celestial,
                        installations: installations.map(x => SimulatedInstallation.fromJS(x))
                    })} />
                }
            </td>
        </tr>}
    </>;
}

export const CelestialsForm = (props: Props) => {

    function add() {
        let celestialId = 0;
        while (props.celestials.find(x => x.celestialId === celestialId) !== undefined) {
            celestialId++;
        }

        const added = SimulatedCelestial.fromJS({
            ...defaultCelestial,
            celestialId,
            name: ValueFormatter.toRomanNumeral(celestialId + 1)
        });

        props.celestials.push(added);
        props.setCelestials(props.celestials);
    }

    function remove(celestial: ISimulatedCelestial) {
        const index = props.celestials.findIndex(x => x.celestialId === celestial.celestialId);
        if (index !== undefined) {
            props.celestials.splice(index, 1);
            props.setCelestials(props.celestials);
        }
    }

    function modify(celestial: ISimulatedCelestial) {
        const index = props.celestials.findIndex(x => x.celestialId === celestial.celestialId);

        if (index !== undefined) {
            props.celestials[index] = celestial;
            props.setCelestials(props.celestials);
        }
    }

    const canAdd = props.celestials.length < 15;

    return <SubPanel
        isUnpadded={props.celestials.length !== 0}
        heading={{ text: "Celestials", icon: IconHelper.Celestials.Habitable }}
        headingContent={<ButtonHolder>
            <Button type="danger" text="Clear all" icon={IconHelper.General.Delete} action={() => props.setCelestials([])} confirm="Are you sure you want to clear all celestials?" isDisabled={props.celestials.length === 0} />
            <Button type="nav" text="Add" icon={IconHelper.General.Plus} action={add} isDisabled={!canAdd} />
        </ButtonHolder>}
    >
        {props.celestials.length === 0 && <Paragraph>No celestials configured</Paragraph>}
        {props.celestials.length !== 0 &&
            <Table isFullWidth isHoverable isFixed>
                {props.celestials.map(x => <CelestialRow
                    key={x.celestialId}
                    celestial={x}
                    {...props}

                    removeCelestial={() => remove(x)}
                    setCelestial={modify}
                />)}
            </Table>
        }
    </SubPanel>;
};