import { recentChanges } from '../../Blobs/Changes/RecentChangeStore';
import { ButtonLink } from '../../Components/Base/Buttons/ButtonLink';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../Helpers/IconHelper';
import { changeArchive } from '../../Navigation/Routing/Misc';
import { ChangeRender } from './ChangeRender';

export const RecentChangesPanel = () => {

    return <SubPanel
        heading={{ text: "Latest Changes ", icon: IconHelper.General.Up }}
        headingContent={<ButtonLink to={changeArchive} icon={IconHelper.General.ChangeArchive} text='Archive' />}
        footerContent={<ButtonLink to={changeArchive} icon={IconHelper.General.ChangeArchive} text='Archive' isPulledRight />}
    >
        {recentChanges
            .sort((a, b) => a.date > b.date ? -1 : 1)
            .map((d, i) => <ChangeRender key={i} changeBlob={d} />)}
    </SubPanel>
};