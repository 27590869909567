
type Props = {
    min?: number,
    max: number,
    value: number,
    step?: number,
    valueChanged: (value: number) => any,
    valueFormatter?: (value: number) => any,
    hideValue?: boolean
}

export const Slider = (props: Props) => {

    function format(v: number) {
        if (props.valueFormatter) {
            return props.valueFormatter(v);
        }
        return v;
    }

    function onChange(e) {
        const asNumber = Number(e.target.value);

        valueChanged(asNumber);
    }

    function valueChanged(value: number) {
        if (value > props.max) {
            value = props.max;
        }
        if (props.min !== undefined && value < props.min) {
            value = props.min;
        }

        props.valueChanged(value);
    }

    function setToMin() {
        props.valueChanged(props.min ?? 0);
    }
    function setToMax() {
        props.valueChanged(props.max);
    }

    return <div className="slider">
        <span className="is-clickable" onClick={setToMin}>
            {format(props.min ? props.min : 0)}
        </span>
        <input
            type="range"
            step={props.step}
            min={props.min}
            max={props.max}
            value={props.value}
            onChange={onChange}
        />
        <span className="is-clickable" onClick={setToMax}>
            {format(props.max)}
        </span>
        {!props.hideValue &&
            <span className="value">
                {format(props.value)}
            </span>
        }
    </div>
};

Slider.defaultProps = {
    min: 0,
    step: 1
};