import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { useObserver } from 'mobx-react-lite';
import * as React from 'react';
import { ApplicationInsightsStateContext } from '../../ApplicationState/ContextRoot';

export const BuildApplicationInsightsTracker = () => {
    const applicationInsightsState = React.useContext(ApplicationInsightsStateContext);

    return useObserver(() => {
        if (applicationInsightsState.reactPlugin === null || applicationInsightsState.reactPlugin === undefined) {
            return (props: any) => props.children;
        }

        return withAITracking(applicationInsightsState.reactPlugin!, (props: any) => {
            return (
                <>
                    {props.children}
                </>
            );
        });
    });
};