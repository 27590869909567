import classNames from "classnames";
import { AgentActionType, IAgentDetail } from "../../../ApplicationState/ApiClient";
import { AgentHelper } from "../../../Helpers/AgentHelper";
import { ImageSize } from "./SvgImage";

type Props = {
    actionType: AgentActionType,
    agent: IAgentDetail,
    size?: ImageSize
}

export const AgentActionImage = (props: Props) => {

    const image = AgentHelper.agentImageVariation(props.agent, props.actionType)

    if (image === undefined) {
        return null;
    }

    const src = `/images/agentAction/${image.variantKey}_${image.variant}.jpg`;

    const className = classNames("image-holder",
        "agent-action",
        `size-${props.size}`
    );

    return <div className={className}>
        <img src={src} />
    </div>;
}