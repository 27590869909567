import { observer } from "mobx-react-lite";
import * as React from "react";
import { ItemType } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SectorTypeLink } from "../Links/SectorTypeLink";
import { RichTextArray } from "../RichTextArray";
import { TypeDataVoice } from "../TypeDataVoice";

type Props = {
    commodityType: ItemType
}

export const CommodityTypeDescription = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const descriptionState = React.useContext(DescriptionStateContext);

    if (!worldState.CommodityHelper) {
        return null;
    }

    const usage = worldState.CommodityHelper.commodityTypeUsage(props.commodityType.typeName);
    const description = descriptionState.Items?.find(x => x.typeName === props.commodityType.typeName);

    return <>
        <RichTextArray text={description?.description} />
        <TypeDataVoice type="ItemType" typeData={props.commodityType} />
        <br />
        <ul>
            {!!usage &&
                <li>
                    Demanded by: <SectorTypeLink sectorType={usage.sectorType} />
                </li>}
        </ul>
    </>;
});