import * as React from "react";
import { ServiceWorkerStateContext } from "../..";
import { Button } from "../../Components/Base/Buttons/Button";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";

export const ForceUpdate = () => {

    const serviceWorkerState = React.useContext(ServiceWorkerStateContext);

    function update() {
        serviceWorkerState.updateAssets();
        return false;
    }

    return <SubPanel heading={{ text: "Force Update", icon: IconHelper.Categories.Settings }}>
        <Paragraph>
            Force your browser to update to the latest version of Fusion Shift Online. This update should usually be identified by your browser automatically. Only use this button if you are having trouble updating.
        </Paragraph>
        <Button type="action" isPulledRight action={update} icon={IconHelper.General.Confirm} text="Update" />
    </SubPanel>;
};