import classNames from "classnames";
import * as React from "react";
import NumericInput from "react-numeric-input";
// Props on all input types
type SharedProps = {
  controlClassName?: string | undefined,
  className?: string | undefined,
  placeholder?: string | undefined;
  size?: number | undefined;
  highlightOnClick?: boolean;
  children?: React.ReactNode | undefined
  onSubmit?: () => any | undefined;
  isDisabled?: boolean;
};

// Props on just the base input (private)
type Props = SharedProps & {
  type: string;
  value: string | undefined;
  onChange: (value: string) => any;
  appendString?: string | undefined;
};

type StringProps = SharedProps & {
  value: string,
  valueChanged: (value: string) => any,
  type?: string | undefined
};

type NumberProps = SharedProps & {
  value: number | undefined;
  precision?: number;
  valueCleared?: (() => any) | undefined;
  valueChanged: (value: number) => any;
  minValue: number;
  maxValue?: number | undefined;
  appendString?: string;
  required?: boolean;
  isUnstyled?: boolean;
};

const Input = (props: Props) => {
  const inputClassName = classNames(
    "input",
    props.className,
    props.size !== undefined ? "input-" + props.size : undefined
  );
  const controlClassName = classNames(
    "control",
    props.controlClassName
  );

  return (
    <div className={controlClassName}>
      <input
        disabled={props.isDisabled}
        className={inputClassName}
        type={props.type}
        placeholder={props.placeholder}
        size={props.size}
        onFocus={props.highlightOnClick ? (e) => e.target.select() : undefined}
        onSubmit={props.onSubmit}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />
      {props.appendString && <span>{props.appendString}</span>}
      {props.children !== undefined && props.children}
    </div>
  );
};

export const StringInput = (props: StringProps) => {
  return <Input {...props} onChange={props.valueChanged} type={props.type !== undefined ? props.type : "text"} />;
};

export const NumberInput = (props: NumberProps) => {
  return (
    <NumericInput
      disabled={props.isDisabled}
      mobile={false}
      inputMode="numeric"
      className={props.isUnstyled ? "" : "form-control input"}
      value={props.value}
      min={props.minValue}
      max={props.maxValue}
      format={(num: any) => {
        return props.appendString ? num + props.appendString : num;
      }}
      precision={props.precision}
      onFocus={(e: any) => e.target.select ? e.target.select() : undefined}
      size={props.size}
      onBlur={(e: any) => {
        if ((e.target.value?.length ?? 0) === 0) {
          props.valueChanged(0);
        }
      }}
      onChange={(value, stringValue) => {
        if (value !== null) {
          if (value < props.minValue || props.maxValue && value > props.maxValue) {
            props.valueChanged(0);
          } else {
            props.valueChanged(value);
          }
        } else if (props.required && stringValue.length === 0) {
          props.valueChanged(0);
        } else if (props.valueCleared) {
          props.valueCleared();
        }
      }}
    />
  );
};

NumberInput.defaultProps = {
  precision: 0,
  minValue: 0,
  required: false
}

