import { ICelestialTypeSettings, IFactionTypeSettings, IGameSettings, IInstallationTypeSettings, IShipTypeSettings, ISimulationInput, SimulatedFleet, SimulatedSolarSystem } from '../../ApplicationState/ApiClient';
import { ChooseDefenderType } from './Components/ChooseDefenderType';
import { DefenderFleetForm } from './DefenderFleetForm';
import { DefenderSolarSystemForm } from './DefenderSolarSystemForm';

type Props = {
    shipTypeSettings: IShipTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    factionTypeSettings: IFactionTypeSettings,
    gameSettings: IGameSettings,
    simulation: ISimulationInput,
    setSimulation: (simulation: ISimulationInput) => any
}

export const DefenderForm = (props: Props) => {

    return <>
        {props.simulation.defendingFleet === undefined && props.simulation.defendingSolarSystem === undefined && <ChooseDefenderType
            setDefendingFleet={defender => props.setSimulation({
                ...props.simulation,
                defendingFleet: SimulatedFleet.fromJS(defender),
                defendingSolarSystem: undefined
            })}
            setDefendingSolarSystem={defender => props.setSimulation({
                ...props.simulation,
                defendingSolarSystem: SimulatedSolarSystem.fromJS(defender),
                defendingFleet: undefined
            })} />}
        {props.simulation.defendingSolarSystem !== undefined && <DefenderSolarSystemForm
            gameSettings={props.gameSettings}

            factionTypeSettings={props.factionTypeSettings}
            shipTypeSettings={props.shipTypeSettings}
            installationTypeSettings={props.installationTypeSettings}
            celestialTypeSettings={props.celestialTypeSettings}

            defender={props.simulation.defendingSolarSystem}
            setDefender={defender => props.setSimulation({
                ...props.simulation,
                defendingSolarSystem: defender !== undefined ? SimulatedSolarSystem.fromJS(defender) : undefined
            })}
        />}
        {props.simulation.defendingFleet !== undefined && <DefenderFleetForm
            gameSettings={props.gameSettings}
            shipTypeSettings={props.shipTypeSettings}
            factionTypeSettings={props.factionTypeSettings}
            defender={props.simulation.defendingFleet}
            setDefender={defender => props.setSimulation({
                ...props.simulation,
                defendingFleet: defender !== undefined ? SimulatedFleet.fromJS(defender) : undefined
            })} />}

    </>;
};