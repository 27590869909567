import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { ItemCategory, ManufactoryNode } from "../../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Icon } from "../../../../../Components/Base/Icon";
import { TextAndIcon } from "../../../../../Components/Base/Text/TextAndIcon";
import { ItemTypeIcon } from "../../../../../Components/FusionShift/Icons/Items/ItemTypeIcon";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { ItemsHelper } from "../../../../../Helpers/ItemsHelper";

type Props = {
    node: ManufactoryNode,
    isSelected: boolean,
    onClick: () => any
}

export const NodeButton = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings || !worldState.SectorTypeSettings) {
        return null;
    }

    const item = props.node.itemTypeName && props.node.itemTypeName in worldState.ItemTypeSettings.data ? worldState.ItemTypeSettings.data[props.node.itemTypeName!] : undefined;

    const className = classNames("node",
        props.node.itemTypeName?.toLocaleLowerCase(),
        {
            "selected": props.isSelected
        });

    const extra = item && (item.category === ItemCategory.Component || item.category === ItemCategory.Commodity) ? ItemsHelper.findNumeral(worldState.ItemTypeSettings!, worldState.SectorTypeSettings!, item) : undefined;

    return < div className={className} onClick={props.onClick} >
        {item && extra === undefined && <ItemTypeIcon itemType={item} isUnwrapped />}
        {item && extra &&
            <div className="tier-icon">
                <span>
                    <TextAndIcon text={extra} icon={<ItemTypeIcon itemType={item} />} />
                </span>
            </div>
        }
        {!item && <Icon icon={IconHelper.General.Edit} />}
    </div >;
});