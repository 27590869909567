import { CelestialFeatureIcon } from '../../../../../Components/FusionShift/Icons/CelestialFeatureIcon';
import { ManufactoryStats } from '../Manufactory/ManufactoryStats';
import { SummaryTile } from './SummaryTile';
import { SummaryTileProps } from './SummaryTileProps';

export const ManufactoryTile = (props: SummaryTileProps) => {

    if (!props.celestial.manufactory) {
        return null;
    }

    return <SummaryTile
        {...props}
        title="Manufactory"
        feature="Manufactory"
        thumbnail={<CelestialFeatureIcon celestialFeature={"Manufactory"} isGiant />}
    >
        <ManufactoryStats
            itemsDetail={props.solarSystem.itemsDetail}
            celestialId={props.celestial.celestialId}
            manufactory={props.celestial.manufactory}
        />
    </SummaryTile>;
};