import React from "react";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { BarBlock } from "./BarBlock";

export type Props = {
    leftIcon: string | React.ReactNode,
    rightIcon?: string | React.ReactNode | undefined,
    className?: string,
    value: number,
    max?: number,
    min?: number,
    warning?: number,
    danger?: number,
    text?: React.ReactNode | undefined,
    uncoloured?: boolean,
    scaleThresholdsToMinMax?: boolean,
    invertThresholds?: boolean,
    showMax?: boolean
}

export const ProgressBar = (props: Props) => {

    let className = (props.className ?? "") + " " + (props.uncoloured ? "" : "is-success");

    if (props.uncoloured === undefined || !props.uncoloured) {

        let dangerToUse = props.danger !== undefined ? props.danger : dangerDefault;
        let warningToUse = props.warning !== undefined ? props.warning : warningDefault;

        if (props.min !== undefined && props.max !== undefined && props.scaleThresholdsToMinMax) {

            const range = props.max - props.min;

            if (dangerToUse === dangerDefault) {
                dangerToUse = range * (props.invertThresholds ? 1 - dangerDefault : dangerDefault);
            }
            if (warningToUse === warningDefault) {
                warningToUse = range * (props.invertThresholds ? 1 - warningDefault : warningDefault);
            }
        }

        if (props.invertThresholds) {

            if (dangerToUse && props.value >= dangerToUse) {
                className = "is-danger";
            } else if (warningToUse && props.value >= warningToUse) {
                className = "is-warning";
            }
        } else {
            if (dangerToUse && props.value <= dangerToUse) {
                className = "is-danger";
            } else if (warningToUse && props.value <= warningToUse) {
                className = "is-warning";
            }
        }
    }

    return <BarBlock
        value={props.value}
        className={className}
        total={props.max}
        leftIcon={props.leftIcon}
        rightIcon={props.rightIcon}
        children={props.text}
        appendToValue={props.showMax ? ` / ${ValueFormatter.formatLocaleNumber(props.max)}` : undefined}
    />;
};

export const ProgressBarPercent = (props: Props) => {

    const text = ValueFormatter.fromDecimalToDisplayPercent(props.value);

    return <ProgressBar {...props} text={text} />;
}

const warningDefault = 0.6;
const dangerDefault = 0.35;

ProgressBar.defaultProps = {
    className: "",
    max: 1,
    min: 0,
    warning: warningDefault,
    danger: dangerDefault,
    scaleThresholdsToMinMax: true
};
