import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { militaryProblemBuilder } from "./MilitaryProblems";

export function combatStatsProblems(wrapper: SolarSystemWrapper): ProblemsMaybe {

    if (wrapper.militaryStats.availableDefence <= 0) {
        return militaryProblemBuilder.dangerProblem("No Defences", `There are no defenders, drones, turrets or reinforcements at ${wrapper.solarSystem.name}`);
    } else if (wrapper.militaryStats.availableDefence - wrapper.militaryStats.defenceFromReinforcements <= 0) {
        return militaryProblemBuilder.warningProblem("Limited Defences", `${wrapper.solarSystem.name} is only defended by reinforcements from other systems`);
    }

    return undefined;
}