import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Icon } from '../../../Components/Base/Icon';
import { PendingPoliticsVoteIcon } from '../../../Components/FusionShift/Icons/UnreadIcons';
import { FederationLink } from '../../../Components/FusionShift/Links/FederationLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { federation_incomingFleets, federations, federations_own } from '../../../Navigation/Routing/Federation';
import { victoryProgress } from '../../../Navigation/Routing/Misc';
import { players } from '../../../Navigation/Routing/Player';
import { politics_home } from '../../../Navigation/Routing/Politics';

export const Diplomacy = observer(() => {

    const playerState = React.useContext(PlayerStateContext);

    return <SubPanel heading="Diplomacy">
        <SplitLayout
            left={
                <ul>
                    <li>
                        <Link to={players}>Players <Icon icon={IconHelper.Categories.Player} /></Link>
                    </li>
                    <li>
                        <Link to={politics_home}>Galactic Council</Link><PendingPoliticsVoteIcon />
                    </li>
                    <li>
                        <Link to={victoryProgress}>Victory Progress</Link>
                    </li>
                </ul>
            }
            right={
                <ul>
                    <li>
                        <Link to={federations}>Federations </Link>
                    </li>
                    <li>
                        <Link to={federations_own}>Your Federation</Link>
                    </li>
                    {playerState.Player?.federation !== undefined && <>
                        <li>
                            <FederationLink federation={playerState.Player.federation} />
                        </li>
                        <li>
                            <Link to={federation_incomingFleets}>Federation Incoming Fleets</Link>
                        </li>
                    </>}
                </ul>
            } />
    </SubPanel>;
});