import { observer } from "mobx-react-lite";
import React from "react";
import { FederationStatus, Player } from "../../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { FederationHelper } from "../../../Helpers/FederationHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { federation_view } from "../../../Navigation/Routing/Federation";
import { Button } from "../../Base/Buttons/Button";

type Props = {
    player: Player
}

export const InvitePlayerButton = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    function invite() {
        if (playerState.Player && playerState.Player.federation && playerState.Player.federation.federationId) {
            return apiState.FederationClient.invite(playerState.Player.federation.federationId, props.player.playerId).then(response => {

                if (response.wasSuccess) {
                    if (playerState.Player!.federation!.federationId) {
                        appState.navigate(federation_view(playerState.Player!.federation!.federationId));
                    }
                } else {
                    appState.setError(response.error);
                }
            });
        }
    }

    if (!playerState.Player ||
        !playerState.Player.federation ||
        playerState.Player.federation.status !== FederationStatus.Active ||
        (props.player.federation?.federationId === playerState.Player.federation.federationId) ||
        !FederationHelper.isLeaderOrOfficer(playerState.Player.federation)) {
        return null;
    }

    return <Button
        type="action"
        icon={IconHelper.Federations.Invite}
        action={() => invite()}
        text={`Invite to ${playerState.Player.federation.shortName}`} />;
});