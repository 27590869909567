import classNames from 'classnames';
import * as React from 'react';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { StringInput } from '../../../Components/Base/Form/Input';
import { IconLink } from '../../../Components/Base/IconLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { search_with_term } from '../../Routing/Misc';

type Props = {
    className?: string
}

export const SearchButton = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    const [searchTerm, setSearchTerm] = React.useState("");

    const className = classNames(
        props.className,
        "nav-search",
        "navbar-item"
    );

    function search(e) {
        e.preventDefault();
        const url = search_with_term(searchTerm);
        clear();
        appState.navigate(url);
        return false;
    }

    function clear() {
        setSearchTerm("");
    }

    return <span className={className}>
        <form onSubmit={search} >
            <StringInput value={searchTerm} valueChanged={setSearchTerm} size={10} className="is-hidden-mobile" />
        </form>
        <IconLink to={search_with_term(searchTerm)} icon={IconHelper.General.Search} onClick={clear} />
    </span>;
};