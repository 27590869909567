import { ReactNode } from "react";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Icon } from "../../Base/Icon";

export type QuantityIconDisplayProps = {
    hideCapacity?: boolean,
    dontColourText?: boolean,
    showName?: boolean,
    compressOnMobile?: boolean,
    decimalDigits?: number | undefined,
    isUnwrapped?: boolean
}

export type QuantityIconProps = QuantityIconDisplayProps & {
    quantity?: number | undefined,
    capacity?: number | undefined
}

type Props = QuantityIconProps & {
    name?: string,
    icon: string | ReactNode,
    className?: string | undefined,
}

export const QuantityIcon = (props: Props) => {

    const quantity = props.quantity !== undefined ? Math.floor(props.quantity) : undefined;
    const capacity = props.capacity;

    const classNameFromProps = props.className !== undefined ? props.className : "";
    const className = !props.dontColourText && !!props.quantity && !!props.capacity && props.quantity >= props.capacity ? "has-text-danger" : "";

    const values = `${quantity ? quantity.toLocaleString() : ""}${capacity ? ` / ${capacity.toLocaleString()}` : ""}`

    const compressionCss = props.compressOnMobile ? "is-hidden-touch" : "";

    const content = <>
        {typeof props.icon === "string" && <Icon icon={props.icon} />}
        {typeof props.icon !== "string" && props.icon}
        {props.showName &&
            <span className={`quantity ${className} ${classNameFromProps}`}>
                {props.name + ' '}
            </span>
        }
        {quantity !== undefined &&
            <span>
                <span className={`quantity ${className} ${classNameFromProps} ${compressionCss}`}>
                    {ValueFormatter.formatLocaleNumber(quantity, props.decimalDigits)}
                </span>
                {props.compressOnMobile &&
                    <span className={`quantity ${className} ${classNameFromProps} is-hidden-desktop`}>
                        {ValueFormatter.toShorthand(quantity)}
                    </span>
                }
            </span>
        }
        {!!capacity && !props.hideCapacity &&
            <span>
                <span className={`capacity ${className} ${classNameFromProps} ${compressionCss}`}>
                    {` / ${ValueFormatter.formatLocaleNumber(capacity, props.decimalDigits)}`}
                </span>
                {props.compressOnMobile &&
                    <span className={`capacity ${className} ${classNameFromProps} is-hidden-desktop`}>
                        {ValueFormatter.toShorthand(capacity)}
                    </span>
                }
            </span>
        }
    </>;

    if (props.isUnwrapped) {
        return content;
    }

    return <span title={`${props.name}: ${values}`}>
        {content}
    </span>;
};