import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { ItemCategory } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { TabMenuWithChildren } from "../../../Components/Base/Containers/TabMenu";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ManufactoryHelper } from "../../../Helpers/ManufactoryHelper";
import { help_page } from "../../../Navigation/Routing/Help";
import { SimpleBaseView } from "../../BaseView";
import { HelpNavButtons } from "../HelpNavButtons";
import { HelpTitle } from "../HelpTitle";
import { CommoditiesSchematicsTable } from "./SchematicTables/CommoditiesSchematicsTable";
import { ComponentsSchematicsTable } from "./SchematicTables/ComponentsSchematicsTable";
import { ResourcesSchematicsTable } from "./SchematicTables/ResourcesSchematicsTable";

export const SchematicsHelpView = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [tab, setTab] = React.useState<"commodities" | "components" | "resources">("commodities");

    const recipes = React.useMemo(() => {

        if (playerState.Player === undefined ||
            worldState.ItemTypeSettings === undefined ||
            worldState.SchematicTypeSettings === undefined ||
            worldState.InstallationTypeSettings === undefined ||
            worldState.ShipTypeSettings === undefined ||
            worldState.SectorTypeSettings === undefined
        ) {
            return undefined;
        }

        const resources = Object.values(worldState.ItemTypeSettings.data)
            .filter(x => x.category === ItemCategory.Resource);

        const schematics = Object.values(worldState.SchematicTypeSettings.data)
            .filter(ManufactoryHelper.isValidOutput);

        const outputItems = Object.values(worldState.ItemTypeSettings.data)
            .filter(x => schematics.find(y => y.producedItemTypeName === x.typeName) !== undefined);

        const ships = Object.values(worldState.ShipTypeSettings.data)
            .filter(x => x.factionTypeName === playerState.Player!.factionTypeName);

        const megacityInstallations = Object.values(worldState.InstallationTypeSettings.data)
            .filter(x => x.megacitySectorTypeName?.length ?? 0 > 0)
            .map(x => {
                return {
                    installation: x,
                    megacitySector: worldState.SectorTypeSettings?.data[x.megacitySectorTypeName]!
                }
            });

        const schematicsWithItems = schematics.map(x => {

            const inputItems = Object.keys(x.costPerRun.items)
                .map(c => resources.find(y => y.typeName === c)!)
                .sort((a, b) => a.order < b.order ? -1 : 1);

            const outputItem = outputItems.find(y => y.typeName === x.producedItemTypeName)!;

            return {
                schematic: x,
                inputItems,
                outputItem
            }
        })
            .sort((a, b) => a.outputItem.order < b.outputItem.order ? -1 : 1);

        const commoditySchematics = schematicsWithItems.filter(x => x.outputItem.category === ItemCategory.Commodity)
            .map(x => {

                const installation = megacityInstallations.find(y => {
                    return Object.values(y.megacitySector.demandedItemTypeNames).find(z => z === x.outputItem.typeName) !== undefined;
                })!;

                return {
                    ...x,
                    installation
                }
            });

        const shipSchematics = schematicsWithItems.filter(x => x.outputItem.category === ItemCategory.Component)
            .map(x => {

                const costedShips = ships.filter(y => x.outputItem.typeName in y.cost.items)
                    .sort((a, b) => a.order < b.order ? -1 : 1);

                return {
                    ...x,
                    ships: costedShips
                }
            });

        return {
            commoditySchematics, componentSchematics: shipSchematics
        };

    }, [playerState.Player,
    worldState.SchematicTypeSettings,
    worldState.InstallationTypeSettings,
    worldState.ItemTypeSettings,
    worldState.ShipTypeSettings,
    worldState.SectorTypeSettings
    ]);

    if (recipes === undefined) {
        return <LoadingSpinner />;
    }

    const tabs = [
        {
            id: "commodities",
            title: "Commodities",
            icons: IconHelper.Items.Commodity,
            isAvailable: true
        },
        {
            id: "components",
            title: "Components",
            icons: IconHelper.Items.Component,
            isAvailable: true
        },
        {
            id: "resources",
            title: "Resources",
            icons: IconHelper.Items.RawResource,
            isAvailable: true
        }
    ];

    return <SimpleBaseView
        heading={HelpTitle({ text: "Schematics", icon: IconHelper.Manufactories.Schematic })}
        headingContent={<HelpNavButtons />}
    >
        <SubPanel heading={{ text: "Overview", icon: IconHelper.Categories.Summary }}>
            <Paragraph>
                Schematics are recipes which process raw resources or combine resources into advanced items such as commodities and components. See the <Link to={help_page("manufactories")}>Manufactories</Link> help page for more information.
            </Paragraph>
        </SubPanel>
        <TabMenuWithChildren
            active={tab}
            tabs={tabs}
            changeTab={setTab}>
            {tab === "commodities" && <CommoditiesSchematicsTable commoditySchematics={recipes.commoditySchematics} />}
            {tab === "components" && <ComponentsSchematicsTable componentSchematics={recipes.componentSchematics} />}
            {tab === "resources" && <ResourcesSchematicsTable itemTypeSettings={worldState.ItemTypeSettings!} schematicTypeSettings={worldState.SchematicTypeSettings!} installationTypeSettings={worldState.InstallationTypeSettings!} />}
        </TabMenuWithChildren>
    </SimpleBaseView>;
});