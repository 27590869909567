import { GoToMapLocationIcon } from '../Icons/GoToMapLocationIcon';

type Props = {
    x: number | undefined,
    y: number | undefined
}

export const LocationLink = (props: Props) => {

    if (props.x === undefined || props.y === undefined) {
        return <span>Unknown</span>;
    }

    return <span>
        {`${props.x.toFixed(1)} / ${props.y.toFixed(1)}`}
        <GoToMapLocationIcon {...props} x={props.x} y={props.y} />
    </span>;
};