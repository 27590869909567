import { observer } from "mobx-react-lite";
import * as React from "react";
import { Product } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { Table } from "../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";
declare var Stripe: any;

export const ProductsIndex = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    const [products, setProducts] = React.useState<Product[] | null>(null);
    const [isRedirecting, setIsRedirecting] = React.useState(false);

    React.useEffect(() => {
        if (!products) {
            apiState.PremiumClient.listProducts().then(products => {
                if (products) {
                    setProducts(products);
                }
            });
        }
    }, [products]);

    function buy(productId: number) {
        setIsRedirecting(true);
        return apiState.PremiumClient.buyProduct(productId).then(session => {
            const stripe = Stripe("pk_test_zauDpSTddxYgTsDb8XpLLv0e00f3kY6e1d");
            stripe.redirectToCheckout({
                sessionId: session.checkoutSessionId
            }).then((r: any) => {
            });
        })
    }

    if (!products || !appState.IsReady) {
        return <LoadingSpinner />;
    }

    if (!appState.IsPremiumEnabled) {
        appState.navigate("/");
    }

    return <SimpleBaseView heading="Premium Products">
        {products.length === 0 &&
            <Paragraph type="missing">No products</Paragraph>
        }
        {isRedirecting && <>
            Please wait while you are redirected to the payment processor
            <LoadingSpinner />
        </>}
        {products.length > 0 && !isRedirecting && <>
            <Paragraph>Unlock bonuses and premium features by spending Fusion Beans. Buy your Fusion Beans now!</Paragraph>
            <Table isFullWidth>
                {products.map(p =>
                    <tr key={p.productId}>
                        <td>
                            {p.description}
                        </td>
                        <td>
                            <Button type="action" text="Buy" action={() => buy(p.productId)} icon={IconHelper.Premium.Currency} />
                        </td>
                    </tr>)}
            </Table>
        </>
        }
    </SimpleBaseView>;
});