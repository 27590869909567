import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PremiumDetails } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext, PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import buySubscription from '../../../Helpers/BuySubscription';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { Button } from '../../Base/Buttons/Button';
import { ButtonHolder } from '../../Base/Buttons/ButtonHolder';
import { DismissButton } from '../../Base/Buttons/DismissButton';
import { SuccessMessageBox } from '../../Base/MessageBox';

const hideSubscriptionKey = "Hide_Subscription_Trial";

export const SubscriptionTrialMessage = observer(() => {
    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [isRedirecting, setIsRedirecting] = React.useState(false);
    const [baseDetails, setDetails] = React.useState<PremiumDetails | undefined>(undefined);

    const [isHidden, setIsHidden] = React.useState(appState.getUserPrefsObjectOrDefault(hideSubscriptionKey, () => false));

    React.useEffect(() => {
        if (appState.IsPremiumEnabled && !baseDetails && appState.IsReady) {
            apiState.PremiumClient.getDetails().then(details => {
                if (details) {
                    setDetails(details);
                }
            });
        }
    }, [baseDetails, appState.IsPremiumEnabled, appState.IsReady]);

    function dismiss() {
        appState.setUserPrefsObject(hideSubscriptionKey, true);
        setIsHidden(true);
    }

    if (isHidden || !appState.IsReady || !appState.IsPremiumEnabled || appState.HasActiveSubscription || !playerState.Player || !baseDetails || !baseDetails.isEligibleForSubscriptionTrial) {
        return null;
    }

    if (isRedirecting) {
        return <SuccessMessageBox
            text={<>Please wait while you are redirected to the payment processor</>}
        />
    }

    return <SuccessMessageBox
        text={`You are eligible for a premium subscription trial. Get 7 days free, then ${ValueFormatter.formatCurrency(baseDetails.subscriptionProduct.unitCost)} per month.`}
        button={<ButtonHolder isPulledRight>
            <Button
                type="action"
                text="Subscribe"
                icon={IconHelper.General.Confirm}
                action={() => { setIsRedirecting(true); buySubscription(apiState.PremiumClient, baseDetails.isEligibleForSubscriptionTrial); }} />
            <DismissButton action={dismiss} />
        </ButtonHolder>}
    />
});