import * as React from "react";
import { Federation } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { FancyTable, thSort, thSortHiddenMobile } from "../../Components/Base/Containers/FancyTable";
import { Icon } from "../../Components/Base/Icon";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { InfoMessageBox } from "../../Components/Base/MessageBox";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { FederationLink } from "../../Components/FusionShift/Links/FederationLink";
import { IconHelper } from "../../Helpers/IconHelper";
import { nameof } from "../../Helpers/TypeHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { federation_view } from "../../Navigation/Routing/Federation";
import { SimpleBaseView } from "../BaseView";
import { FederationData } from "./FederationData";

export const FederationIndex = () => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [federations, setFederations] = React.useState<Federation[] | null>(null);

    React.useEffect(() => {
        if (!federations) {
            apiState.FederationClient.list().then(federations => {
                if (federations) {
                    setFederations(federations);
                }
            });
        }
    }, [federations]);

    if (!federations) {
        return <LoadingSpinner />;
    }

    const anyResearchPoints = federations.find(x => x.researchPoints > 0) !== undefined;

    const header = [
        thSort("Rank", (f: Federation) => f.rank <= 0 ? Number.MAX_SAFE_INTEGER : f.rank),
        anyResearchPoints ? thSort("RP", nameof<Federation>("researchPoints")) : undefined,
        thSort("Federation", nameof<Federation>("name")),
        thSort("Players", nameof<Federation>("numberOfPlayers")),
        thSort("Size", nameof<Federation>("size")),
        thSortHiddenMobile("Pop.", nameof<Federation>("population")),
        thSortHiddenMobile("Systems", nameof<Federation>("numberOfSolarSystems")),
    ];

    return <SimpleBaseView heading={{
        text: "Federations",
        icon: IconHelper.Federations.Federation
    }}
        headingContent={<HelpButton to="federations" />
        }>
        {federations.length === 0 && <Paragraph type="missing">No federations</Paragraph>}
        {federations.length > 0 &&
            <FederationData federations={federations} />
        }
        {anyResearchPoints && <>
            <InfoMessageBox text={<>
                One or more Federations are generating <Icon icon={IconHelper.Federations.ResearchPoint} /> Research Points (RP) from relics they have captured.
            </>} />
        </>}
        {federations.length > 0 &&
            <FancyTable isStriped isHoverable isFullwidth hasBreakAll
                header={header}
                data={federations}
                rowClick={(f: Federation) => appState.navigate(federation_view(f.federationId))}
                renderRow={(f: Federation) =>
                    <>
                        <td>
                            {ValueFormatter.rank(f.rank)}
                        </td>
                        {anyResearchPoints && <td>
                            {ValueFormatter.formatLocaleNumber(f.researchPoints)}
                        </td>}
                        <td>
                            <FederationLink federation={f} />
                        </td>
                        <td>
                            {f.numberOfPlayers}
                        </td>
                        <td>
                            {ValueFormatter.formatSize(f.size)}
                        </td>
                        <td className="is-hidden-mobile">
                            {ValueFormatter.formatPopulation(f.population)}
                        </td>
                        <td className="is-hidden-mobile">
                            {f.numberOfSolarSystems}
                        </td>
                    </>
                } />
        }
    </SimpleBaseView>;
}