import { observer } from "mobx-react-lite";
import React from "react";
import { ISolarSystem } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { map_galaxy_x_y, map_tactical_x_y } from "../../../Navigation/Routing/Map";
import { ButtonHolder } from "../../Base/Buttons/ButtonHolder";
import { ButtonLink } from "../../Base/Buttons/ButtonLink";
import { SelectAndGoToOwnedSolarSystem } from "../SolarSystems/SelectAndGoToOwnedSolarSystem";
import { ContractForTargetSolarSystem } from "./ContractForTargetSolarSystemButton";
import { ExploreButton } from "./ExploreButton";

type Props = {
    solarSystem: ISolarSystem,
    children?: React.ReactNode,
    showWorldMapLink?: boolean,
    reloadCallback?: (() => any) | undefined
}

export const SolarSystemButtons = observer((props: Props) => {

    return <ButtonHolder>
        <ContractForTargetSolarSystem solarSystemId={props.solarSystem.solarSystemId} />
        <SelectAndGoToOwnedSolarSystem solarSystem={props.solarSystem} />
        {!props.showWorldMapLink && <ButtonLink icon={IconHelper.Map.ShowOnMap} text="Show on Map" hideTextOnMobile to={map_tactical_x_y(props.solarSystem.x, props.solarSystem.y)} />}
        {props.showWorldMapLink && <ButtonLink icon={IconHelper.Map.ShowOnMap} text="Galaxy Map" hideTextOnMobile to={map_galaxy_x_y(props.solarSystem.x, props.solarSystem.y)} />}
        <ExploreButton solarSystem={props.solarSystem} reloadCallback={props.reloadCallback} />
        {props.children !== undefined && props.children}
    </ButtonHolder>;
});