import React, { ReactNode } from "react";
import { Validation, ValidationWithContent } from "../../../Entities/Validation";

type ValidationProps = {
    extra?: ReactNode | null,
    className?: string | undefined,
    validation?: Validation | ValidationWithContent | undefined
}

type Props = ValidationProps & {
    children?: ReactNode | undefined,

    validationPosition?: "after" | "before"
}

export const FormFooterValidation = (props: ValidationProps) => {
    return <div className={`control ${props.className !== undefined ? props.className : ""}`}>
        {props.validation &&
            <span className={!props.validation.valid ? "has-text-danger" : ""}>
                {"value" in props.validation && props.validation.value}
                {"content" in props.validation && props.validation.content}
            </span>
        }
        {props.extra}
    </div>;
};

export const FormFooter = (props: Props) => {
    return <>
        {props.validationPosition === "before" && <FormFooterValidation {...props} />}
        <div className={`control ${props.className !== undefined ? props.className : ""}`}>
            {props.children}
        </div>
        {props.validationPosition === "after" && <FormFooterValidation {...props} />}
    </>;
};

FormFooter.defaultProps = {
    extra: null,
    validationPosition: "after"
};