import React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { ComponentIcon } from "../../Icons/Items/ComponentIcon";

type Props = {
    componentType?: ItemType | undefined | false,
    hideTextOnMobile?: boolean,
    noText?: boolean
}

export const ComponentTypeLink = (props: Props) => {

    if (!props.componentType) {
        return null;
    }

    return <>
        <ComponentIcon />
        {!props.noText && <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {props.componentType.name}
        </span>}
    </>;
};