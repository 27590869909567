import { AgentActionType } from "../../../ApplicationState/ApiClient";
import { AgentActionIcon } from "../Icons/AgentActionIcon";

type Props = {
    actionType: AgentActionType,
    hideIcon?: boolean
}

export const AgentActionTypeLink = (props: Props) => {

    return <>
        {!props.hideIcon && <AgentActionIcon action={props.actionType.action} {...props} />}
        {props.actionType.name}
    </>
};