import * as React from 'react';
import { Button } from '../../../../Components/Base/Buttons/Button';

type Props = {
    additionalClassName?: string
    current: number,
    available: number,
    cargoCapacityRemaining: number
    setValue: (quantity: number) => any
}

export const SetQuantityButtons = (props: Props) => {

    // capacity passed in will include the quantity of this resource
    const availableCargo = props.cargoCapacityRemaining > 0 ? props.cargoCapacityRemaining + props.current : 0;
    const isFillEnabled = availableCargo > 0;
    const fillQuantity = availableCargo < props.available ? availableCargo : props.available;

    const noZero = props.current === 0;
    const noMax = props.current > 0;

    const additionalClassName = props.additionalClassName ?? "";

    return <>
        {!noZero &&
            <div className={`control ${additionalClassName}`}>
                <Button type="nav" icon="" className="is-small" text={"0"} action={() => props.setValue(0)} isDisabled={noZero} />
            </div>
        }
        {!noMax &&
            <div className={`control ${additionalClassName}`}>
                <Button type="nav" icon="" className="is-small" text={"+"} action={() => props.setValue(fillQuantity)} isDisabled={!isFillEnabled} />
            </div>
        }
    </>;
};