import { ICelestialDetail, IItemTypeSettings } from "../../../../ApplicationState/ApiClient";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { manufactoryProblemBuilder } from "./ManufactoryProblems";

export function unconfiguredNodeProblems(itemTypeSettings: IItemTypeSettings, wrapper: SolarSystemWrapper, celestial: ICelestialDetail): ProblemsMaybe {

    if (!celestial.manufactory) {
        return undefined;
    }

    const inputs = celestial.manufactory.inputNodes.filter(n => !(n.itemTypeName in itemTypeSettings.data)).length;
    const outputs = celestial.manufactory.outputNodes.filter(n => !(n.itemTypeName in itemTypeSettings.data)).length;

    return [
        inputs > 0 && manufactoryProblemBuilder.infoProblem(inputs + "x Unconfigured Input Nodes", `The Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} has ${inputs} input ${ValueFormatter.pluralize("node", inputs)} which ${inputs > 1 ? "have" : "has"} not been configured`),
        outputs > 0 && manufactoryProblemBuilder.infoProblem(outputs + "x Unconfigured Output Nodes", `The Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} has ${outputs} output ${ValueFormatter.pluralize("node", outputs)} which ${outputs > 1 ? "have" : "has"} not been configured`)
    ];
}