import { AgentsIndex } from "../../Views/Agents/AgentsIndex";
import { AgentView } from "../../Views/Agents/AgentView";
import { route } from "./Root";

export const agents = "/agents";

export function agentById(agentId: number | string) {
    return agents + "/" + agentId;
}

export const agentRoutes = [
    route(agentById(":agentId"), AgentView, "Agent Detail"),
    route(agents, AgentsIndex, "Agents")
];