import { observer } from "mobx-react-lite";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { FleetMovementType } from "../../ApplicationState/ApiClient";
import { AppStateContext, SolarSystemStateContext } from "../../ApplicationState/ContextRoot";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { SolarSystemLink } from "../../Components/FusionShift/Links/SolarSystemLink";
import { Coordinate } from "../../Entities/Shared";
import { CollectionHelper } from "../../Helpers/CollectionHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { UrlHelper } from "../../Helpers/UrlHelper";
import { map_tactical_x_y } from "../../Navigation/Routing/Map";
import { empire } from "../../Navigation/Routing/Misc";
import { selected_system, selected_system_repeats } from "../../Navigation/Routing/SolarSystem";
import { SimpleBaseView } from "../BaseView";
import { PostSendShipsControl } from "./Components/PostSendShipsControl";
import { PostSendShipTarget } from "./SendShipsBag";
import { SendShipsForm, SendShipsResult } from "./SendShipsForm";

export const SendShipsView = observer(() => {

    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [result, setResult] = React.useState<SendShipsResult | undefined>(undefined);

    const [fleetType, setFleetMovementType] = React.useState<FleetMovementType | undefined>(undefined);

    const [contractId, setContractId] = React.useState<number | undefined>(undefined);
    const [targetCoordinate, setTargetCoordinate] = React.useState<Coordinate | undefined>(undefined);
    const [targetCelestialId, setTargetCelestialId] = React.useState<number | undefined>(undefined);

    const [targetFleetId, setTargetFleetId] = React.useState<number | undefined>(undefined);

    const [marketOrderId, setMarketOrderId] = React.useState<number | undefined>(undefined);

    const [repeatFleetId, setRepeatFleetId] = React.useState<number | undefined>(undefined);

    const location = useLocation();

    if (!targetCoordinate && !targetFleetId && !repeatFleetId) {
        UrlHelper.tryParseSearch(location, parsedFromSearch => {

            UrlHelper.tryGetNumberValue(parsedFromSearch, "targetX", x => {
                UrlHelper.tryGetNumberValue(parsedFromSearch, "targetY", y => {
                    setTargetCoordinate({
                        x,
                        y
                    });
                });
            });

            UrlHelper.tryGetNumberValue(parsedFromSearch, "celestialId", value => {
                setTargetCelestialId(value);
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "targetFleetId", value => {
                setTargetFleetId(value);
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "type", value => {
                setFleetMovementType(value);
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "movementType", value => {
                setFleetMovementType(value);
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "fleetType", value => {
                setFleetMovementType(value);
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "contractId", value => {
                setContractId(value);
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "repeatFleetId", value => {
                setRepeatFleetId(value);
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "marketOrderId", value => {
                setMarketOrderId(value);
                setFleetMovementType(FleetMovementType.Trade);
            });
        });
    }

    React.useEffect(() => {
        setTargetCoordinate(undefined);
    }, [location]);

    function reload(target: PostSendShipTarget) {

        if (target === "Map at Target" && result) {
            if (result.targetCoordinate) {
                appState.navigate(map_tactical_x_y(result.targetCoordinate.x, result.targetCoordinate.y));
                return;
            } else if (result.targetSolarSystem) {
                appState.navigate(map_tactical_x_y(result.targetSolarSystem.x, result.targetSolarSystem.y));
                return;
            }
        }

        if (target === "Repeats") {
            appState.navigate(selected_system_repeats);
            return;
        }

        if (target === "System") {
            appState.navigate(selected_system);
            return;
        }
        if (target === "Empire") {
            appState.navigate(empire);
            return;
        }

        setResult(undefined);
    }

    function onSent(result: SendShipsResult) {
        setResult(result);
    }

    if (!solarSystemState.SolarSystem) {
        return <LoadingSpinner />;
    }

    const canSend = repeatFleetId !== undefined || CollectionHelper.isAnyQuantityInDictionary(solarSystemState.SolarSystem.availableShips);

    return <SimpleBaseView heading={{ text: "Send Ships", icon: IconHelper.Ships.Send }}
        headingContent={<HelpButton to="/help/fleets" />}>
        {result !== undefined && <PostSendShipsControl result={result} thenGoTo={reload} />}
        {!result && canSend && <SendShipsForm
            onSent={onSent}
            initialTargetCoordinate={targetCoordinate}
            initialTargetCelestialId={targetCelestialId}
            initialTargetFleetId={targetFleetId}
            initialFleetMovementType={fleetType}
            initialMarketOrderId={marketOrderId}
            initialContractId={contractId}
            initialRepeatFleetId={repeatFleetId}
        />}
        {
            !canSend && <Paragraph type="prompt">
                No available ships in <SolarSystemLink solarSystem={solarSystemState.SolarSystem} />
            </Paragraph>
        }
    </SimpleBaseView >;
});