import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CourierTerms, ISolarSystem, ITerms, ValidationResult } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { DateTimeInput } from '../../../Components/Base/Form/DateTimeInput';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { ValidationHelper } from '../../../Components/Base/Form/ValidationHelper';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { ItemChooser } from '../../../Components/FusionShift/Items/ItemChooser';
import { Configuration } from '../CreateContractView';
import { ChooseSolarSystem } from './ChooseSolarSystem';

type Props = {
    terms: CourierTerms,
    configuration: Configuration,
    setTermsAndConfigure: (terms: ITerms, configuration: Configuration) => any,
    setValidation: (validation: ValidationResult | undefined) => any,
}

export const CourierForm = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const [sourceSolarSystem, setSourceSolarSystem] = React.useState<ISolarSystem | undefined>(undefined);
    const [targetSolarSystem, setTargetSolarSystem] = React.useState<ISolarSystem | undefined>(undefined);
    const [expiryDate, setExpiryDate] = React.useState(new Date());
    const [paymentPerUnit, setPaymentPerUnit] = React.useState(0);
    const [minimumTotalDefence, setMinimumTotalDefence] = React.useState(0);
    const [quantity, setQuantity] = React.useState(0);
    const [refreshFrequencyHours, setRefreshFrequencyHours] = React.useState<number | undefined>(undefined);
    const [itemTypeName, setItemTypeName] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        props.setValidation(validate());

        const terms = createTerms();

        props.setTermsAndConfigure(terms, {
            ...props.configuration,
            sourceSolarSystemId: sourceSolarSystem?.solarSystemId,
            targetSolarSystemId: targetSolarSystem?.solarSystemId
        });
    }, [expiryDate, refreshFrequencyHours, itemTypeName, paymentPerUnit, quantity, sourceSolarSystem, targetSolarSystem, minimumTotalDefence]);

    function createTerms() {
        return CourierTerms.fromJS({
            expiryDate,
            refreshFrequencyHours,
            itemTypeName,
            paymentPerUnit,
            quantity,
            minimumTotalDefence,
            type: "CourierTerms"
        });
    }

    function clearSource() {
        props.setTermsAndConfigure(createTerms(), {
            ...props.configuration,
            sourceSolarSystemId: undefined
        })
        setSourceSolarSystem(undefined);
    }

    function clearTarget() {
        props.setTermsAndConfigure(createTerms(), {
            ...props.configuration,
            targetSolarSystemId: undefined
        })
        setTargetSolarSystem(undefined);
    }

    function validate() {
        if (sourceSolarSystem === undefined) {
            return ValidationHelper.invalid("Pick a valid source solar system");
        }
        if (sourceSolarSystem.playerId !== playerState.Player!.playerId) {
            return ValidationHelper.invalid("You must be the owner of the source solar system");
        }
        if (targetSolarSystem === undefined) {
            return ValidationHelper.invalid("Pick a valid target solar system");
        }
        if (sourceSolarSystem.solarSystemId === targetSolarSystem.solarSystemId) {
            return ValidationHelper.invalid("Source and target can't be the same solar system");
        }
        if (expiryDate <= new Date()) {
            return ValidationHelper.invalid("Choose an expiry date in the future");
        }
        if (itemTypeName === undefined) {
            return ValidationHelper.invalid("Choose a valid item");
        }
    }

    if (!playerState.Player) {
        return <LoadingSpinner />;
    }

    return <>
        <ChooseSolarSystem
            initialSolarSystemId={props.configuration.sourceSolarSystemId}
            clearTarget={clearSource}
            solarSystemChanged={setSourceSolarSystem}
            solarSystem={sourceSolarSystem}
            showOwnSystems
            label="Source"
        />
        <ChooseSolarSystem
            initialSolarSystemId={props.configuration.targetSolarSystemId}
            clearTarget={clearTarget}
            solarSystemChanged={setTargetSolarSystem}
            solarSystem={targetSolarSystem}
            showOwnSystems
        />
        <FieldHolder label="Item">
            <ItemChooser
                hideCredits
                setItemTypeName={setItemTypeName}
            />
        </FieldHolder>
        <SplitLayout
            left={
                <FieldHolder label="Payment per unit">
                    <NumberInput value={paymentPerUnit} valueChanged={setPaymentPerUnit} precision={1} />
                </FieldHolder>
            }
            right={
                <FieldHolder label="Quantity">
                    <NumberInput value={quantity} valueChanged={setQuantity} />
                </FieldHolder>
            }
        />
        <SplitLayout
            left={
                <FieldHolder label="Expiry date">
                    <DateTimeInput value={expiryDate} valueChanged={setExpiryDate} />
                </FieldHolder>
            }
            right={
                <FieldHolder label="Refresh frequency (hours)">
                    <NumberInput value={refreshFrequencyHours} valueChanged={setRefreshFrequencyHours} />
                </FieldHolder>
            }
        />
        <FieldHolder label="Minimum defence power">
            <NumberInput value={minimumTotalDefence} valueChanged={setMinimumTotalDefence} />
        </FieldHolder>
    </>;
});