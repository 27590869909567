import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType } from '../../../ApplicationState/ApiClient';
import { TabMenuWithChildren } from '../../../Components/Base/Containers/TabMenu';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../../Helpers/IconHelper';
import { EmpireViewProps } from '../EmpireViewProps';
import { NetworkSummary } from './NetworkSummary';
import { RebaseMany } from './RebaseMany';

type NetworkTab = "Summary" | "Rebase";

export const NetworkView = observer((props: EmpireViewProps) => {

    const [tab, setTab] = React.useState<NetworkTab>("Summary");


    if (props.solarSystems.length <= 1) {
        return <Paragraph>
            Network view requires at least 2 solar system.
        </Paragraph>
    }

    const tabs = [
        {
            id: "Summary",
            title: "Summary",
            isAvailable: true,
            icons: IconHelper.Categories.Summary
        },
        {
            id: "Rebase",
            title: "Rebase",
            isAvailable: true,
            icons: IconHelper.Ships.movementType(FleetMovementType.Rebase)
        }
    ];

    return <TabMenuWithChildren
        tabs={tabs}
        active={tab}
        changeTab={setTab}
    >
        {tab === "Summary" && <NetworkSummary {...props} />}
        {tab === "Rebase" && <RebaseMany {...props} />}
    </TabMenuWithChildren>;
});