import * as React from "react";
import { HelpButton } from "../../../Components/Base/Buttons/HelpButton";
import { SimpleBaseView } from "../../BaseView";
import { HelpNavButtons } from "../HelpNavButtons";
import { HelpTitle } from "../HelpTitle";

type Link = {
    to: string,
    text: string,
    icon: string
}

type Props = {
    title: string,
    icon: string,
    children: React.ReactNode,
    links?: Link[] | undefined
}

export const TypeDataBaseView = (props: Props) => {

    return <SimpleBaseView
        heading={HelpTitle({ text: `${props.title} Types`, icon: props.icon })}
        headingContent={<HelpNavButtons>
            {props.links !== undefined && props.links.map(x => <HelpButton key={x.to} {...x} />)}
        </HelpNavButtons>}
    >
        {props.children}
    </SimpleBaseView >;
};