import { observer } from "mobx-react-lite";
import React from "react";
import { ShipType } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../Base/Containers/SplitLayout";
import { ShipTypeImage } from "../Images/ShipTypeImage";
import { RichTextArray } from "../RichTextArray";
import { ShipTypeCombatStats } from "./ShipTypeCombatStats";
import { ShipTypeStats } from "./ShipTypeStats";

type Props = {
    shipType: ShipType
}

export const ShipTypeDescription = observer((props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.GameSettings || !worldState.InstallationTypeSettings) {
        return null;
    }

    const description = descriptionState.Ships?.find(x => x.typeName === props.shipType.typeName);

    return <SplitLayout
        fitContent
        left={
            <ShipTypeImage {...props} />
        }
        right={
            <>
                <RichTextArray text={description?.description} />
                <RichTextArray text={[description?.factionDescription ?? ""]} />
                <br />
                <SplitLayout
                    left={<ShipTypeStats installationTypeSettings={worldState.InstallationTypeSettings} gameSettings={worldState.GameSettings} {...props} />}
                    right={<ShipTypeCombatStats {...props} />}
                />
            </>
        }
    />;
});