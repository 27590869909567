import { makeAutoObservable } from "mobx";

export type AudioPlayState = "Nothing" | "Playing" | "Paused";

export class AudioState {

    State: AudioPlayState = "Nothing";

    Url: string | undefined;
    UrlDescription: string | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    public playUrl(url: string, description: string) {
        this.Url = url;
        this.UrlDescription = description;
        this.State = "Playing";
    }

    public pause() {
        if (this.State === "Playing") {
            this.State = "Paused";
        }
    }

    public resume() {
        if (this.State === "Paused") {
            this.State = "Playing";
        }
    }

    public stop() {
        this.Url = undefined;
        this.UrlDescription = undefined;
        this.State = "Nothing";
    }
}