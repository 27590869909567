import classNames from "classnames";
import * as React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { ItemsHelper } from "../../../../Helpers/ItemsHelper";
import { ItemTypeLink } from "../../Links/Items/ItemTypeLink";
import { QuantityIcon, QuantityIconProps } from "../QuantityIcon";
import { ItemTypeIcon } from "./ItemTypeIcon";

type Props = QuantityIconProps & {
    icon?: React.ReactNode | undefined,
    itemType: ItemType,
    quantityPerSec?: number | undefined,
    className?: string | undefined,
    showFullName?: boolean
}

export const ItemIconWithQuantity = (props: Props) => {

    const expiresSoon = ItemsHelper.expiresInLessThanAnHour(props.quantity ?? 0, props.quantityPerSec ?? 0);

    const className = classNames(
        props.className,
        {
            "has-text-warning": !props.dontColourText && !expiresSoon && props.quantityPerSec && props.quantityPerSec < 0,
            "has-text-danger": !props.dontColourText && expiresSoon
        }
    );

    return <QuantityIcon
        name={props.itemType.name}
        icon={props.icon !== undefined ? props.icon : props.showFullName ? <><ItemTypeLink itemType={props.itemType} /> </> : <ItemTypeIcon itemType={props.itemType} />}
        className={className}
        {...props}
    />;
};