import * as React from 'react';
import { ItemCategory, ItemType } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { Icon } from '../../../Base/Icon';
import { QuantityIconDisplayProps } from '../QuantityIcon';
import { CommodityIcon } from './CommodityIcon';
import { ComponentIcon } from './ComponentIcon';
import { RawResourceIcon } from './RawResourceIcon';
import { ResourceIcon } from './ResourceIcon';

type Props = QuantityIconDisplayProps & {
    className?: string | undefined,
    itemType: ItemType,
    isGiant?: boolean
}

type FromTypeNameProps = QuantityIconDisplayProps & {
    itemTypeName: string,
    isGiant?: boolean
}

export const ItemTypeIcon = (props: Props) => {

    if (props.itemType.category === ItemCategory.Resource) {
        return <ResourceIcon resourceType={props.itemType} {...props} />
    }

    if (props.itemType.category === ItemCategory.Commodity) {
        return <CommodityIcon commodityType={props.itemType} {...props} />
    }

    if (props.itemType.category === ItemCategory.RawResource) {
        return <RawResourceIcon rawResourceType={props.itemType} {...props} />
    }

    if (props.itemType.category === ItemCategory.Component) {
        return <ComponentIcon componentType={props.itemType} {...props} />
    }

    if (props.itemType.category === ItemCategory.Relic) {
        return <Icon icon={IconHelper.Items.Relic} {...props} />
    }

    return null;
};

export const ItemTypeIconFromItemTypeName = (props: FromTypeNameProps) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings || !(props.itemTypeName in worldState.ItemTypeSettings.data)) {
        return null;
    }

    return <ItemTypeIcon {...props} itemType={worldState.ItemTypeSettings.data[props.itemTypeName]} />;
}