import { AccoladeType, IAccolade, IWorldMinimal } from '../../ApplicationState/ApiClient';
import { H3 } from '../../Components/Base/Text/H';
import { AccoladeTypeIcon } from '../../Components/FusionShift/Icons/AccoladeTypeIcon';
import { TimeHelper } from '../../Helpers/TimeHelper';

type Props = {
    world: IWorldMinimal,
    accolade: IAccolade
}

export const AccoladeBadge = (props: Props) => {

    return <div className={`accolade rank-${props.accolade.rank}`}>
        <H3>
            WK {TimeHelper.weekNumber(props.world.createdDate, props.accolade.date)}: #{props.accolade.rank}
        </H3>
        <div className="has-icon">
            <AccoladeTypeIcon accoladeType={props.accolade.accoladeType} isGiant />
        </div>
        <div className="description">
            {AccoladeType[props.accolade.accoladeType]}
        </div>
    </div>
}
