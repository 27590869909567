import * as React from 'react';
import { BasicSimulatedFleet, FleetMovementType, ICelestialTypeSettings, IFactionTypeSettings, IGameSettings, IInstallationTypeSettings, IShipTypeSettings, ISimulatedSolarSystem, SimulatedCelestial } from '../../ApplicationState/ApiClient';
import { Button } from '../../Components/Base/Buttons/Button';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { CollectionHelper } from '../../Helpers/CollectionHelper';
import { IconHelper } from '../../Helpers/IconHelper';
import { BonusSlider } from './Components/BonusSlider';
import { CelestialsForm } from './Components/CelestialsForm';
import { DefenderSystemFromOwnSolarSystem } from './Components/DefenderSolarSystemFromOwnSolarSystem';
import { GroundedShipsForm } from './Components/GroundedShipsForm';
import { ReinforcementsForm } from './Components/ReinforcementsForm';
import { ShipsForm } from './Components/ShipForm';
import { StatsSummary } from './Components/StatsSummary';

type Props = {
    gameSettings: IGameSettings,
    shipTypeSettings: IShipTypeSettings,
    factionTypeSettings: IFactionTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    defender: ISimulatedSolarSystem,
    setDefender: (defender: ISimulatedSolarSystem | undefined) => any
}

export const DefenderSolarSystemForm = (props: Props) => {

    const [factionTypeName, setFactionTypeName] = React.useState<string | undefined>(undefined);

    const allShips = React.useMemo(() => {

        return CollectionHelper.mergeQuantityDictionaries([
            props.defender.ships,
            ...props.defender.reinforcements.map(x => x.ships)
        ]);

    }, [props.defender, props.defender.reinforcements]);

    return <SubPanel heading={{ text: "Defending Solar System", icon: IconHelper.Combat.Defence }}
        headingContent={<Button type="danger" text="Reset and Change" icon={IconHelper.General.Delete} action={() => props.setDefender(undefined)} confirm="Are you sure you want to change the defender? This will clear all your changes." />}
    >
        <SplitLayout
            left={
                <>
                    <DefenderSystemFromOwnSolarSystem {...props} />
                    <hr />
                    <ShipsForm
                        setFactionTypeName={setFactionTypeName}
                        allowDrones
                        movementType={FleetMovementType.Reinforce}
                        shipTypeSettings={props.shipTypeSettings}
                        factionTypeSettings={props.factionTypeSettings}
                        gameSettings={props.gameSettings}
                        {...props.defender}
                        setShipsAndTactics={(ships, tactics) => props.setDefender({
                            ...props.defender,
                            ships,
                            tactics
                        })}
                    />
                    <BonusSlider
                        value={props.defender.megacityBonusPercent}
                        valueChanged={v => props.setDefender({
                            ...props.defender,
                            megacityBonusPercent: v
                        })}
                    />
                    <GroundedShipsForm shipTypeSettings={props.shipTypeSettings} gameSettings={props.gameSettings} factionTypeName={factionTypeName} hintShips={props.defender.ships} groundedShips={props.defender.groundedShips} setGroundedShips={groundedShips => props.setDefender({
                        ...props.defender,
                        groundedShips
                    })} />
                </>
            }
            right={<>
                <StatsSummary {...props} ships={allShips} hideAttack />
                <CelestialsForm {...props} celestials={props.defender.celestials} setCelestials={celestials => props.setDefender({
                    ...props.defender,
                    celestials: celestials.map(x => SimulatedCelestial.fromJS(x))
                })} />
                <ReinforcementsForm {...props} gameSettings={props.gameSettings} reinforcements={props.defender.reinforcements} setReinforcements={reinforcements => props.setDefender({
                    ...props.defender,
                    reinforcements: reinforcements.map(x => BasicSimulatedFleet.fromJS(x))
                })} />
            </>
            }
        />
    </SubPanel>;
};