import { makeAutoObservable, observable } from "mobx";
import { AccoladeClient, AdminClient, AgentClient, AuthenticationClient, ChatClient, CheatClient, CombatReportClient, ContractsClient, DebugClient, DescriptionClient, DevClient, DroneClient, EmblemClient, FederationClient, FleetClient, FriendInvitationClient, GameClient, HelpClient, InstallationClient, ManufactoryClient, MapClient, MarketOrderClient, MegacityClient, MessageClient, PlayerClient, PlayerMissionLogClient, PlayerNotificationClient, PoliticsClient, PremiumClient, SearchClient, ShipClient, SolarSystemClient, UserSettingClient, VoiceClient, WorldSettingsClient } from "../ApiClient";
import { DevicesClient } from "../NotificationsApiClient";
import { ReportClient } from "../ReportingApiClient";

export class ApiState {

    hasError = false;

    constructor(getToken: () => string | undefined,
        unauthorizedRequest: () => any) {

        const baseUrl = process.env.REACT_APP_API_URL;
        const notificationsBaseUrl = process.env.REACT_APP_NOTIFICATIONS_API_URL;
        const reportingBaseUrl = process.env.REACT_APP_REPORTING_API_URL;

        const http = {
            getToken: getToken,
            unauthorizedRequest: unauthorizedRequest,
            hasError: () => this.hasError = true,
            fetch(input: RequestInfo, init?: RequestInit) {
                init = init ? init : {};
                const token = this.getToken();
                if (token) {
                    if (init.headers instanceof Headers) {
                        init.headers!.append("Authorization", "Bearer " + token);
                    } else {
                        (init.headers! as any)["Authorization"] = "Bearer " + token;
                    }
                }
                return window.fetch(input, init)
                    .then(r => {
                        if (!r.ok) {
                            if (r.status === 401 || r.status === 403) {
                                this.unauthorizedRequest();
                            } else {
                                this.hasError();
                            }
                        }
                        return r;
                    });
            }
        };

        this.AdminClient = new AdminClient(baseUrl, http);
        this.DebugClient = new DebugClient(baseUrl, http);
        this.DevClient = new DevClient(baseUrl, http);
        this.GameClient = new GameClient(baseUrl, http);
        this.MapClient = new MapClient(baseUrl, http);
        this.ContractsClient = new ContractsClient(baseUrl, http);
        this.SolarSystemClient = new SolarSystemClient(baseUrl, http);
        this.PlayerClient = new PlayerClient(baseUrl, http);
        this.CombatReportClient = new CombatReportClient(baseUrl, http);
        this.PlayerNotificationClient = new PlayerNotificationClient(baseUrl, http);
        this.FederationClient = new FederationClient(baseUrl, http);
        this.AuthenticationClient = new AuthenticationClient(baseUrl, http);
        this.MessageClient = new MessageClient(baseUrl, http);
        this.CheatClient = new CheatClient(baseUrl, http);
        this.ChatClient = new ChatClient(baseUrl, http);
        this.MarketOrderClient = new MarketOrderClient(baseUrl, http);
        this.PlayerMissionLogClient = new PlayerMissionLogClient(baseUrl, http);
        this.AccoladeClient = new AccoladeClient(baseUrl, http);
        this.PremiumClient = new PremiumClient(baseUrl, http);
        this.FleetClient = new FleetClient(baseUrl, http);
        this.UserSettingClient = new UserSettingClient(baseUrl, http);
        this.InstallationClient = new InstallationClient(baseUrl, http);
        this.ShipClient = new ShipClient(baseUrl, http);
        this.DroneClient = new DroneClient(baseUrl, http);
        this.MegacityClient = new MegacityClient(baseUrl, http);
        this.PoliticsClient = new PoliticsClient(baseUrl, http);
        this.ManufactoryClient = new ManufactoryClient(baseUrl, http);
        this.VoiceClient = new VoiceClient(baseUrl, http);
        this.EmblemClient = new EmblemClient(baseUrl, http);
        this.HelpClient = new HelpClient(baseUrl, http);
        this.FriendInvitationClient = new FriendInvitationClient(baseUrl, http);
        this.AgentClient = new AgentClient(baseUrl, http);
        this.SearchClient = new SearchClient(baseUrl, http);
        this.DescriptionClient = new DescriptionClient(baseUrl, http);
        this.WorldSettingsClient = new WorldSettingsClient(baseUrl, http);

        this.DevicesClient = new DevicesClient(notificationsBaseUrl, http);
        this.ReportClient = new ReportClient(reportingBaseUrl, http);

        makeAutoObservable(this, {
            hasError: observable
        });
    }

    AgentClient: AgentClient;
    ShipClient: ShipClient;
    ContractsClient: ContractsClient;
    InstallationClient: InstallationClient;
    MegacityClient: MegacityClient;
    DroneClient: DroneClient;
    ManufactoryClient: ManufactoryClient;
    AccoladeClient: AccoladeClient;
    AdminClient: AdminClient;
    DebugClient: DebugClient;
    DevClient: DevClient;
    GameClient: GameClient;
    MarketOrderClient: MarketOrderClient;
    SolarSystemClient: SolarSystemClient;
    MapClient: MapClient;
    PlayerClient: PlayerClient;
    CombatReportClient: CombatReportClient;
    PlayerNotificationClient: PlayerNotificationClient;
    FederationClient: FederationClient;
    AuthenticationClient: AuthenticationClient;
    MessageClient: MessageClient;
    CheatClient: CheatClient;
    ChatClient: ChatClient;
    PoliticsClient: PoliticsClient;
    PlayerMissionLogClient: PlayerMissionLogClient;
    PremiumClient: PremiumClient;
    FleetClient: FleetClient;
    UserSettingClient: UserSettingClient;
    DescriptionClient: DescriptionClient;
    VoiceClient: VoiceClient;
    EmblemClient: EmblemClient;
    HelpClient: HelpClient;
    FriendInvitationClient: FriendInvitationClient;
    WorldSettingsClient: WorldSettingsClient;
    DevicesClient: DevicesClient;
    SearchClient: SearchClient;
    ReportClient: ReportClient;
}