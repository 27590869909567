import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SecurityStatus } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { MessageBoxWithIcon } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';

export const CantVoteMessage = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    if (!playerState.Player || !worldState.Effects) {
        return null;
    }

    if (worldState.Effects.securityStatusCantVote === null || worldState.Effects.securityStatusCantVote === undefined) {
        return null;
    }

    return <MessageBoxWithIcon
        icon={IconHelper.Security.securityStatus(worldState.Effects.securityStatusCantVote)}
        text={`Due to current policies the votes of players with security status ${SecurityStatus[worldState.Effects.securityStatusCantVote]} will not be counted towards election results`}
        type={playerState.Player.securityStatus === worldState.Effects.securityStatusCantVote ? "warning" : "info"}
    />;
});