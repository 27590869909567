import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { RequestResponseOfSearchResult } from '../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { ControlHolder } from '../../Components/Base/Containers/ControlHolder';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { StringInput } from '../../Components/Base/Form/Input';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../Helpers/IconHelper';
import { UrlHelper } from '../../Helpers/UrlHelper';
import { SimpleBaseView } from '../BaseView';
import { ResultRender } from './ResultRender';

export const SearchView = () => {

    const location = useLocation();

    const apiState = React.useContext(ApiStateContext);

    const [result, setResult] = React.useState<RequestResponseOfSearchResult | undefined>(undefined);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        // Only load from string on initial view load
        const searchTermFromSearch = UrlHelper.tryGetValueFromSearch(location, "searchTerm");
        if (searchTermFromSearch !== undefined) {
            setSearchTerm(searchTermFromSearch);
            search(searchTermFromSearch);
        }
    }, []);

    React.useEffect(() => {
        const searchTermFromSearch = UrlHelper.tryGetValueFromSearch(location, "searchTerm");
        if (searchTermFromSearch !== undefined) {
            setSearchTerm(searchTermFromSearch);
            search(searchTermFromSearch);
        }
    }, [location]);

    function searchByForm(e) {
        e.preventDefault();
        search();
    }

    function search(searchOn?: string | undefined) {

        searchOn = searchOn ?? searchTerm;

        if (searchOn.length > 0) {

            setIsLoading(true);
            apiState.SearchClient.search(searchOn).then(x => {
                setResult(x);
                setIsLoading(false);
            })
        }
    }

    return <SimpleBaseView heading={{ text: "Search", icon: IconHelper.General.Search }}>
        <ControlHolder hasAddons>
            <form onSubmit={searchByForm} >
                <StringInput value={searchTerm} valueChanged={setSearchTerm} />
            </form>
            <Button type="query" isDisabled={isLoading} icon={IconHelper.General.Search} action={search} text="Search" />
        </ControlHolder>
        {(searchTerm.length === 0 || !result?.wasSuccess) && <SubPanel heading="Result">
            <Paragraph type="prompt">
                {result !== undefined && result.error}
                {searchTerm.length === 0 && result === undefined && "Please enter a valid search term"}
            </Paragraph>
        </SubPanel>
        }
        {isLoading && <LoadingSpinner />}
        {result !== undefined && result.result !== undefined && <ResultRender key={searchTerm} result={result.result} />}
    </SimpleBaseView >;
};