import { observer } from "mobx-react-lite";
import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { TextHelper } from "../../../../Helpers/TextHelper";
import { help_commodityTypes } from "../../../../Navigation/Routing/Help";
import { CommodityIcon } from "../../Icons/Items/CommodityIcon";

type Props = {
    commodityType?: ItemType | undefined | false,
    commodityTypeName?: string | undefined,
    hideTextOnMobile?: boolean,
    noText?: boolean,
    maxLength?: number
}

export const CommodityTypeLink = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings || !worldState.CommodityHelper) {
        return null;
    }

    const commodityType = props.commodityType ? props.commodityType :
        props.commodityTypeName ? worldState.ItemTypeSettings.data[props.commodityTypeName]
            : undefined;

    if (!commodityType) {
        return null;
    }

    const usage = worldState.CommodityHelper.commodityTypeUsage(commodityType.typeName);

    const text = props.maxLength === undefined ? commodityType.name : TextHelper.abbreviateTextEvenly(commodityType.name, props.maxLength);

    return <Link to={`${help_commodityTypes}?commodityTypeName=${commodityType.typeName}#${commodityType.typeName}`}>
        <CommodityIcon commodityType={commodityType} usage={usage} />
        {!props.noText && <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {text}
        </span>}
    </Link>;
});