import React, { ReactNode } from "react";
import { ICelestialDetail, ISolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { CelestialHelper } from "../../../Helpers/CelestialHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    celestial: ICelestialDetail,
    solarSystem: ISolarSystemDetail
}

export const DamagedIcon = () => <Icon icon={IconHelper.Installations.Damaged} className="has-text-danger" title="Damaged" />;
export const DeconstructIcon = () => <Icon icon={IconHelper.Installations.Deconstruct} title="Under deconstruction" />;
export const ConstructionIcon = () => <Icon icon={IconHelper.Installations.Build} title="Under construction" />;
export const BuildingShipsIcon = () => <Icon icon={IconHelper.Ships.Build} title="Building ships" />;

export const CelestialIcons = (props: Props) => {

    const [intervalRef, setIntervalRef] = React.useState<NodeJS.Timeout | undefined>(undefined);
    const [statuses, setStatuses] = React.useState<ReactNode[]>([]);
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {

        const extras: ReactNode[] = [];

        if (CelestialHelper.isDamaged(props.celestial)) {
            extras.push(<DamagedIcon key="d" />);
        }

        if (CelestialHelper.hasDeconstruct(props.celestial, props.solarSystem)) {
            extras.push(<DeconstructIcon key="ud" />);
        }

        if (CelestialHelper.hasConstruct(props.celestial, props.solarSystem)) {
            extras.push(<ConstructionIcon key="uc" />);
        }

        if (CelestialHelper.isBuildingShips(props.celestial, props.solarSystem)) {
            extras.push(<BuildingShipsIcon key="bs" />);
        }

        setStatuses(extras);
        if (intervalRef !== undefined) {
            clearInterval(intervalRef);
        }
        setIntervalRef(undefined);

    }, [props.solarSystem, props.celestial]);

    React.useEffect(() => {
        if (intervalRef !== undefined || statuses.length === 0) {
            return;
        }
        const ref = global.setInterval(() => {
            setIndex(i => {
                const newIndex = i + 1;
                return newIndex < statuses.length ? newIndex : 0;
            });
        }, 1500);
        setIntervalRef(ref);
        return () => clearInterval(intervalRef);
    }, [intervalRef, index, statuses]);

    if (statuses.length == 0) {
        return null;
    }

    return <div className="celestial-icon">
        {statuses[index]}
    </div>
};