import { IItemTypeSettings, ItemCategory } from "../../../../ApplicationState/ApiClient";
import { CollectionHelper } from "../../../../Helpers/CollectionHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { Problem, ProblemsMaybe } from "../Problem";
import { economyProblemBuilder } from "./EconomyProblems";

export function resourceProblems(itemTypeSettings: IItemTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;
    const problems: Problem[] = [];

    for (let c in itemTypeSettings.data) {
        const item = itemTypeSettings.data[c];

        if (item.category !== ItemCategory.Resource) {
            continue;
        }

        const quantity = solarSystem.items && c in solarSystem.items ? solarSystem.items[c] : 0;
        const capacity = solarSystem.itemsDetail && c in solarSystem.itemsDetail.resourceCapacities ? solarSystem.itemsDetail.resourceCapacities[c] : 0;

        if (quantity <= 0 && !item.hideIfZero) {
            problems.push(economyProblemBuilder.warningProblem(`No ${item.name}`, `${solarSystem.name} has no stocks of ${item.name}`));
        } else if (quantity >= capacity && capacity > 0) {
            problems.push(economyProblemBuilder.warningProblem(`Full ${item.name}`, `${solarSystem.name} has full stocks of ${item.name}`));
        } else if (quantity > 0 && capacity > 0 && quantity <= capacity * 0.15) {
            problems.push(economyProblemBuilder.infoProblem(`Low ${item.name}`, `${solarSystem.name} has low stocks of ${item.name}`));
        } else if (quantity > 0 && capacity > 0 && quantity >= capacity * 0.95) {
            problems.push(economyProblemBuilder.infoProblem(`Nearly Full ${item.name}`, `${solarSystem.name}'s stocks of ${item.name} are nearly full`));
        }
    }

    if (wrapper.solarSystem.itemsDetail && CollectionHelper.isAnyInDictionary(wrapper.solarSystem.itemsDetail.harvested.colonyBonusPercent)) {

        const penalty = Object.values(wrapper.solarSystem.itemsDetail.harvested.colonyBonusPercent)[0];

        problems.push(economyProblemBuilder.warningProblem(`Colony Penalty`, `${solarSystem.name} is suffering a colony penalty of ${ValueFormatter.fromDecimalToDisplayPercent(penalty, false, 0)} to resource production. Upgrade the Sovereignty Office here to reduce the penalty.`));
    }

    return problems;
}