import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AppStateContext } from "../../ApplicationState/ContextRoot";
import { ApiState } from "../../ApplicationState/States/ApiState";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { ErrorMessageBox, SuccessMessageBox } from "../../Components/Base/MessageBox";
import { SimpleBaseView } from "../BaseView";
import { AuthenticationWrapper } from "./AuthenticationWrapper";

export const VerifyEmailView = observer(() => {

    const { token } = useParams();

    const appState = React.useContext(AppStateContext);

    const [verificationStatus, setVerificationStatus] = React.useState<"success" | "failed">();

    React.useEffect(() => {
        if (appState.ApplicationState !== "PerformingInitialLoad" && !verificationStatus) {
            const verifyEmailApiState = new ApiState(() => token, () => { });
            verifyEmailApiState.AuthenticationClient.setVerifiedEmail().then(accessToken => {
                if (accessToken) {
                    setVerificationStatus("success");
                    if (appState.ApplicationState == "Ready") {
                        appState.setAuthToken(accessToken);
                        appState.reload("Email verified");
                    }
                }
                else {
                    setVerificationStatus("failed");
                }
            });
        }
    }, [token, appState.ApplicationState, verificationStatus]);

    return <AuthenticationWrapper>
        < SimpleBaseView heading="Verify Email" >
            {verificationStatus === "success" && <SuccessMessageBox text="Thank you, your email address has been verified." />
            }
            {verificationStatus === "failed" && <ErrorMessageBox text="Could not verify your email address at this time. This could be because the link has expired or already been used." />}
            {!verificationStatus && <LoadingSpinner />}
        </SimpleBaseView >
    </AuthenticationWrapper >;
});