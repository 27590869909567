import { observer } from 'mobx-react-lite';
import { FleetMovementType } from '../../../../ApplicationState/ApiClient';
import { Checkbox } from '../../../../Components/Base/Form/Checkbox';
import { FieldHolder } from '../../../../Components/Base/Form/FieldHolder';
import { StringInput } from '../../../../Components/Base/Form/Input';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { SolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { SendShipsBag } from '../../SendShipsBag';

type Props = {
    sendShipsBag: SendShipsBag,
}

export const ConfigForm = observer((props: Props) => {

    const willGroundOnReturn = props.sendShipsBag.MovementType !== FleetMovementType.Rebase;
    const willNotifyOnReturn = props.sendShipsBag.MovementType !== FleetMovementType.Rebase &&
        props.sendShipsBag.MovementType !== FleetMovementType.Delivery &&
        props.sendShipsBag.MovementType !== FleetMovementType.Reinforce;

    return <>
        <Checkbox
            label={`Ground on ${willGroundOnReturn ? "return" : "arrival"}`}
            value={props.sendShipsBag.Config.groundOnArrival}
            valueChanged={v => props.sendShipsBag.setConfig(c => {
                return {
                    ...c,
                    groundOnArrival: v
                };
            })}
        />
        {props.sendShipsBag.Config.groundOnArrival && <Paragraph>
            {!willGroundOnReturn && <>
                The ships will become grounded when they reach {props.sendShipsBag.TargetSolarSystem && <SolarSystemLink solarSystem={props.sendShipsBag.TargetSolarSystem} />}.
            </>}
            {willGroundOnReturn && <>
                The ships will become grounded when they return home to <SolarSystemLink solarSystem={props.sendShipsBag.init.sourceSolarSystem!} />.
            </>}
        </Paragraph>}
        <Checkbox
            label={`Notify on ${willNotifyOnReturn ? "return" : "arrival"}`}
            value={props.sendShipsBag.Config.notifyOnArrival}
            valueChanged={v => props.sendShipsBag.setConfig(c => {
                return {
                    ...c,
                    notifyOnArrival: v
                };
            })}
        />
        {props.sendShipsBag.Config.notifyOnArrival && <>
            <FieldHolder label="Notification Message (optional)">
                <StringInput
                    value={props.sendShipsBag.Config.notifyOnArrivalMessage ?? ""}
                    valueChanged={v => props.sendShipsBag.setConfig(c => {
                        return {
                            ...c,
                            notifyOnArrivalMessage: v
                        };
                    })}
                />
            </FieldHolder>
            <Paragraph>
                {!willNotifyOnReturn && <>
                    The notification will be sent when the fleet reaches {props.sendShipsBag.TargetSolarSystem && <SolarSystemLink solarSystem={props.sendShipsBag.TargetSolarSystem} />}.
                </>}
                {willNotifyOnReturn && <>
                    The notification will be sent when the fleet returns home to <SolarSystemLink solarSystem={props.sendShipsBag.init.sourceSolarSystem!} />.
                </>}
            </Paragraph>
        </>}
        {(props.sendShipsBag.IsEditingRepeatFleet || props.sendShipsBag.repeat) &&
            <>
                <Checkbox
                    label={`Notify on repeat failure`}
                    value={props.sendShipsBag.RepeatConfiguration.notifyOnFailure}
                    valueChanged={v => props.sendShipsBag.setRepeatConfiguration(c => {
                        return {
                            ...c,
                            notifyOnFailure: v
                        };
                    })}
                />
                {props.sendShipsBag.RepeatConfiguration.numberOfTimes !== undefined && props.sendShipsBag.RepeatConfiguration.numberOfTimes > 0 &&
                    <Checkbox
                        label={`Notify on repeat completion`}
                        value={props.sendShipsBag.RepeatConfiguration.notifyOnCompletion}
                        valueChanged={v => props.sendShipsBag.setRepeatConfiguration(c => {
                            return {
                                ...c,
                                notifyOnCompletion: v
                            };
                        })}
                    />}
            </>}
    </>;
});