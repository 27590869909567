import { observer } from "mobx-react-lite";
import React from "react";
import { FleetMovementType, ISolarSystemMinimal } from "../../../ApplicationState/ApiClient";
import { SolarSystemStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { send_ships } from "../../../Navigation/Routing/Misc";
import { ButtonLink } from "../../Base/Buttons/ButtonLink";

type Props = {
    targetSolarSystem?: ISolarSystemMinimal | undefined,
    icon?: string | undefined
    additionalParams?: string | undefined
    hideTextOnMobile?: boolean,
    text?: string | undefined,
    noText?: boolean
}

type FromMovementTypeProps = {
    solarSystem: ISolarSystemMinimal,
    hideTextOnMobile?: boolean,
    movementType: FleetMovementType,
    noText?: boolean
}

export const SendShipsWithMovementTypeButton = (props: FromMovementTypeProps) => {

    return <SendShipsButton
        noText={props.noText}
        text={ValueFormatter.friendlyNameForMovementType(props.movementType)}
        icon={IconHelper.Ships.movementType(props.movementType)}
        targetSolarSystem={props.solarSystem}
        additionalParams={`movementType=${props.movementType}`}
        hideTextOnMobile={props.hideTextOnMobile}
    />;
};

export const SendShipsButton = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    function defaultText() {
        if (props.noText) {
            return "";
        }
        return `Send ships`;
    }

    if (props.targetSolarSystem && solarSystemState.SolarSystem && props.targetSolarSystem.solarSystemId === solarSystemState.SolarSystem.solarSystemId) {
        return null;
    }

    const target = props.targetSolarSystem ? `targetX=${props.targetSolarSystem.x}&targetY=${props.targetSolarSystem.y}` : "";

    return <ButtonLink
        icon={props.icon && props.icon.length > 0 ? props.icon : IconHelper.Ships.Send}
        to={`${send_ships}?${target}${props.additionalParams && props.additionalParams.length > 0 ? `&${props.additionalParams}` : ""}`}
        text={props.noText ? "" : props.text ? props.text : defaultText()}
        hideTextOnMobile={props.hideTextOnMobile} />;
});