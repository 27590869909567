import { IPlayerMinimal } from "../../../ApplicationState/ApiClient";

export type RenderContextProps = {
    minX: number,
    maxX: number,
    minY: number,
    maxY: number
};

export type OwnedCoordinate = {
    owner?: IPlayerMinimal,
    x: number,
    y: number
};

export type PanAndScale = {
    scale: number,
    x: number,
    y: number
}

export class RenderContext {

    public size = 300;
    private boundingPadding = 2;

    public initialScale: number;
    public maxScale: number = 50;
    public props: RenderContextProps;
    public coordinatesToRender: OwnedCoordinate[];
    public firstCoordinate: OwnedCoordinate | undefined;

    public worldSize;

    public boundingBox: {
        x1: number,
        x2: number,
        y1: number,
        y2: number,
        centerX: number,
        centerY: number
    };

    constructor(props: RenderContextProps, coordinatesToRender: OwnedCoordinate[]) {
        this.props = props;
        this.coordinatesToRender = coordinatesToRender;

        this.firstCoordinate = this.coordinatesToRender.find(x => true);
        this.boundingBox = this.createBoundingBox(this.coordinatesToRender);
        this.initialScale = this.calculateInitialZoom(this.boundingBox.x2 - this.boundingBox.x1, this.boundingBox.y2 - this.boundingBox.y1);

        this.worldSize = this.props.maxX - props.minX;
    }

    public tX(x: number) {
        return x + Math.abs(this.props.minX);
    }

    public tY(y: number) {
        return y + Math.abs(this.props.minY);
    }

    private findInitialPosition(coordinates: OwnedCoordinate[]) {
        if (coordinates === undefined || coordinates.length === 0) {
            return { x: 0, y: 0 };
        }

        let x = 0, y = 0;

        for (let c of coordinates) {
            x += c.x;
            y += c.y;
        }

        return { x: x / coordinates.length, y: y / coordinates.length };
    }

    private createBoundingBox(coordinates: OwnedCoordinate[]) {

        const { x, y } = this.findInitialPosition(coordinates);

        let x1 = x, x2 = x, y1 = y, y2 = y;

        if (coordinates !== undefined) {
            for (let current of coordinates) {
                if (current !== undefined) {
                    if (current.x < x1) {
                        x1 = current.x;
                    }
                    if (current.x > x2) {
                        x2 = current.x;
                    }

                    if (current.y < y1) {
                        y1 = current.y;
                    }
                    if (current.y > y2) {
                        y2 = current.y;
                    }
                }
            }
        }

        return {
            centerX: x,
            centerY: y,
            x1: x1 - this.boundingPadding,
            x2: x2 + this.boundingPadding,
            y1: y1 - this.boundingPadding,
            y2: y2 + this.boundingPadding,
        };
    }

    private calculateInitialZoom(boundingBoxWidth: number, boundingBoxHeight: number) {
        var targetRatio = this.size / this.size;
        var boundingRatio = boundingBoxWidth / boundingBoxHeight;

        return targetRatio > boundingRatio
            ? this.size / boundingBoxHeight
            : this.size / boundingBoxWidth;
    }
};
