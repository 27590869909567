import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { shipsProblemBuilder } from "./ShipsProblems";

export function hangarSpaceProblems(wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;

    if (solarSystem.itemsDetail) {
        for (let resource in solarSystem.itemsDetail.upkeep.fromShips) {
            const negated = resource in solarSystem.itemsDetail.upkeep.negatedByHangarSpace ? solarSystem.itemsDetail.upkeep.negatedByHangarSpace[resource] : 0;
            const needs = solarSystem.itemsDetail.upkeep.fromShips[resource];
            if (negated < needs) {
                return shipsProblemBuilder.warningProblem("Hangar Space Exceeded", `${solarSystem.name} provides hangar space of ${ValueFormatter.formatLocaleNumber(negated)} but ships are using ${ValueFormatter.formatLocaleNumber(needs)}`);
            }
        }
    }

    return undefined;
}