import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AudioStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { AudioButtons } from './AudioButtons';
import { Button } from './Button';

type Props = {
    url: string,
    text?: string | undefined,
    description: string,
    className?: string | undefined
}

export const PlayAudioFromUrlButton = observer((props: Props) => {

    const audioState = React.useContext(AudioStateContext);

    function play() {
        audioState.playUrl(props.url, props.description);
    }

    const isPlaying = audioState.Url === props.url && audioState.State !== "Nothing";

    return <>
        {isPlaying && <AudioButtons />}
        {!isPlaying && <Button type="nav"
            data-disable-bubble-click="true"
            isDisabled={audioState.Url === props.url && audioState.State !== "Nothing"}
            action={play}
            icon={IconHelper.Audio.Play}
            hideTextOnMobile
            text={props.text === undefined ? "Read Aloud" : props.text}
        />}
    </>;
});