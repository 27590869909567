import * as React from "react";
import { useLocation } from "react-router-dom";

const excludedPathPrefixes = ["/map", "/worldmap"];
// Scroll restoration based on https://reacttraining.com/react-router/web/guides/scroll-restoration.
export const ScrollToTop = () => {

  const [prevLocation, setPrevLocation] = React.useState("");

  const location = useLocation();
  React.useLayoutEffect(() => {
    if (prevLocation !== location.pathname && !excludedPathPrefixes.some((p) => location.pathname.startsWith(p))
    ) {

      document.documentElement.scrollTo(0, 0);

      setPrevLocation(location.pathname);
    }
  }, [location.pathname]);

  return null;
}