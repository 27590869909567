import { ISolarSystemMinimal } from "../../../../ApplicationState/ApiClient";
import { CanvasHelper } from "../CanvasHelper";
import { RenderBag } from "../Entities";

const colours = {
    selected: "#feae00",
    sameOwner: "#feae00",
    sameFederation: "#feae00"
}

const systemColours = {
    self: "#56c3e3",
    friend: "#8fc95d",
    neutral: "white",
    enemy: "#e35656"
}

type SelectionPerspective = {
    playerId: number | undefined,
    federationId: number | undefined
};

export class TargetsRenderer {

    public numRendered = 0;
    public renders = 0;

    public transform: DOMMatrix = new DOMMatrix();

    constructor(public ctx: CanvasRenderingContext2D) {
    }

    public render(render: RenderBag) {

        if (render.targets === undefined) {
            return;
        }

        this.renders++;
        this.numRendered = 0;

        this.transform = this.ctx.getTransform();

        for (const solarSystem of render.targets.targets) {
            this.renderSolarSystem(this.transform, render, solarSystem);
        }
        for (const ally of render.targets.allies
        ) {
            this.renderAlly(this.transform, render, ally);
        }
    }

    private renderSolarSystem(
        transform: DOMMatrix,
        render: RenderBag,
        solarSystem: ISolarSystemMinimal) {

        const { x, y } = render.perspective.transform(solarSystem.x, solarSystem.y, render.perspective.parsec / 2);

        this.numRendered++;

        const radius = (render.targets?.targetRadius ?? 0) === 0 ? 0.75 : render.targets!.targetRadius + .25;

        CanvasHelper.strokeCircle(this.ctx,
            x,
            y,
            (render.perspective.parsec * radius),
            colours.selected,
            1
        );

        CanvasHelper.strokeCross(this.ctx,
            x,
            y,
            (render.perspective.parsec * .75),
            colours.selected,
            1
        );
    }

    private renderAlly(
        transform: DOMMatrix,
        render: RenderBag,
        ally: {
            solarSystem: ISolarSystemMinimal,
            targets: { x: number, y: number }[]
        }) {

        const solarSystem = ally.solarSystem;

        const { x, y } = render.perspective.transform(solarSystem.x, solarSystem.y, render.perspective.parsec / 2);

        this.numRendered++;

        const colour = solarSystem.playerId === render.data.player.playerId ? systemColours.self : systemColours.friend;

        CanvasHelper.strokeSquare(this.ctx,
            x,
            y,
            (render.perspective.parsec * .75),
            colour,
            1
        );

        for (const target of ally.targets) {
            const targetPosition = render.perspective.transform(target.x, target.y, render.perspective.parsec / 2);

            CanvasHelper.dashLine(this.ctx,
                x,
                y,
                targetPosition.x,
                targetPosition.y,
                colour,
                1
            );
        }
    }
}