export class CollectionHelper {

    public static randomElement<T>(items: T[]) {
        return items[Math.floor(Math.random() * items.length)];
    }

    public static randomKey<T>(items: { [key: string]: T }) {
        return this.randomElement(Object.keys(items));
    }

    public static randomValue<T>(items: { [key: string]: T }) {
        return this.randomElement(Object.values(items));
    }

    public static arrayToDictionary<T>(array: { key: string, value: T }[]) {
        const dictionary: { [key: string]: T } = {};

        array.reduce((d, v) => { d[v.key] = v.value; return d; }, dictionary);

        return dictionary;
    }

    public static lazyConcat<T>(a: T[] | undefined | false, b: T[] | undefined | false): T[] {
        if ((a === undefined || !a) && (b === undefined || !b)) {
            return [];
        }
        if (a === undefined || !a) {
            return b ? b : [];
        }
        if (b === undefined || !b) {
            return a;
        }

        return a.concat(b);
    }

    public static multiplyQuantityDictionary(collection: { [key: string]: number }, quantity: number) {
        const merged: { [key: string]: number } = {};

        for (let key in collection) {
            merged[key] = collection[key] * quantity;
        }

        return merged;
    }

    public static mergeQuantityDictionaries(collections: ({ [key: string]: number } | null | undefined)[]) {
        return this.mergeDictionaries(() => 0, (a, b) => a + b, collections);
    }

    public static mergeDictionaries<T>(empty: () => T, merge: (current: T, add: T) => T, collections: ({ [key: string]: T } | null | undefined)[]) {

        const merged: { [key: string]: T } = {};

        for (let collection of collections) {
            if (collection) {
                for (let key of Object.keys(collection)) {
                    if (!(key in merged)) {
                        merged[key] = empty();
                    }
                    merged[key] = merge(merged[key], collection[key]);
                }
            }
        }

        return merged;
    }

    public static firstFromDictionary<T>(collection: { [key: string]: T } | null | undefined): T | undefined {
        const keys = collection && Object.keys(collection);

        if (!keys || keys.length === 0) {
            return undefined;
        }

        return collection![keys[0]];;
    }

    public static isAnyInDictionary<T>(collection: { [key: string]: T } | null | undefined): boolean {

        if (!collection || Object.keys(collection).length === 0) {
            return false;
        }

        return true;
    }

    public static isAnyInDictionaryAndChildren<T>(collection: { [key: string]: T[] } | null | undefined): boolean {

        if (!collection || Object.keys(collection).length === 0) {
            return false;
        }

        for (let c of Object.keys(collection)) {
            if (this.isAnyInArray(collection[c])) {
                return true;
            }
        }

        return false;
    }

    public static sumOfDictionary(collection: { [key: string]: number } | null | undefined): number {
        if (!collection || Object.keys(collection).length === 0) {
            return 0;
        }

        let sum = 0;
        for (let c of Object.keys(collection)) {
            sum += collection[c];
        }

        return sum;
    }

    public static sumOfDictionaryValue(collection: { [key: string]: number } | null | undefined, value: (key: string, quantity: number) => number | undefined): number {
        if (!collection || Object.keys(collection).length === 0) {
            return 0;
        }

        let sum = 0;
        for (let c of Object.keys(collection)) {
            const v = value(c, collection[c]);
            sum += v ?? 0;
        }

        return sum;
    }

    public static isAnyQuantityInDictionaryByKeys(collection: { [key: string]: number } | null | undefined, keys: string[], roundDown?: boolean): boolean {

        if (!collection || Object.keys(collection).length === 0 || keys.length === 0) {
            return false;
        }

        for (let c of keys) {
            if (c in collection) {
                const value = roundDown ? Math.floor(collection[c]) : collection[c];
                if (value > 0) {
                    return true;
                }
            }
        }

        return false;
    }

    public static isAnyQuantityInDictionary(collection: { [key: string]: number } | null | undefined, roundDown?: boolean): boolean {

        if (!collection || Object.keys(collection).length === 0) {
            return false;
        }

        for (let c of Object.keys(collection)) {
            const value = roundDown ? Math.floor(collection[c]) : collection[c];
            if (value > 0) {
                return true;
            }
        }

        return false;
    }

    public static isAnyNonZeroQuantityInDictionary(collection: { [key: string]: number } | null | undefined, roundDown?: boolean): boolean {

        if (!collection || Object.keys(collection).length === 0) {
            return false;
        }

        for (let c of Object.keys(collection)) {
            const value = roundDown ? Math.floor(collection[c]) : collection[c];
            if (value !== 0) {
                return true;
            }
        }

        return false;
    }

    public static isAnyQuantityInDictionaries(collection: ({ [key: string]: number } | null | undefined)[], roundDown?: boolean): boolean {

        if (!collection || collection.length === 0) {
            return false;
        }

        for (let c of collection) {
            if (this.isAnyQuantityInDictionary(c)) {
                return true;
            }
        }

        return false;
    }

    public static sumOfArray(collection: number[] | null | undefined): number {

        if (!collection || collection.length === 0) {
            return 0
                ;
        }

        let sum = 0;
        for (let c = 0; c < collection.length; c++) {
            sum += collection[c];
        }

        return sum;
    }

    public static sumOfArrayValue<T>(collection: T[] | null | undefined, value: (entity: T) => number | undefined): number {

        if (!collection || collection.length === 0) {
            return 0;
        }

        let sum = 0;
        for (let c = 0; c < collection.length; c++) {
            const val = value(collection[c]);
            sum += val !== undefined ? val : 0;
        }

        return sum;
    }

    public static isAnyInArray<T>(collection: T[] | null | undefined): boolean {

        if (!collection || collection.length === 0) {
            return false;
        }

        return true;
    }

    public static groupBy<T>(data: T[], groupBy: (d: T) => string): { [key: string]: T[] } {
        return data.reduce((r, v, i, a, k = groupBy(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    }

    public static selectFromDictionary<Entity, Value>(collection: { [key: string]: Entity }, select: (entity: Entity) => Value): { [key: string]: Value } {
        const result: { [key: string]: Value } = {};

        for (let key in collection) {
            const value = select(collection[key]);
            result[key] = value;
        }

        return result;
    }
}