import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ICelestialDetail, IGameSettings, IWorldEffects, SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { AppStateContext, SolarSystemStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { BackNavButton } from '../../../../Components/Base/Buttons/BackButton';
import { ButtonHolder } from '../../../../Components/Base/Buttons/ButtonHolder';
import { CloseButton } from '../../../../Components/Base/Buttons/CloseButton';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../../../Components/Base/Loading/LoadingSpinner';
import { CelestialFeatureHelpButton } from '../../../../Components/FusionShift/Buttons/CelestialFeatureHelpButton';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { CelestialFeature, CelestialHelper } from '../../../../Helpers/CelestialHelper';
import { celestial_feature, celestial_summary, selected_system, selected_system_celestials } from '../../../../Navigation/Routing/SolarSystem';
import { BuildView } from './Build/BuildView';
import { CelestialFeatureTitle } from './CelestialFeatureTitle';
import { DronesView } from './Drones/DronesView';
import { InstallationView } from './Installations/InstallationView';
import { ManufactoryView } from './Manufactory/ManufactoryView';
import { MegacityView } from './Megacity/MegacityView';
import { ShipsView } from './Ships/ShipsView';
import { SummaryView } from './Summary/SummaryView';

type Props = {
    closeCallback: () => any,
    solarSystemWrapper: SolarSystemWrapper,
    celestialId: number,
    worldEffects: IWorldEffects,
    gameSettings: IGameSettings,
    changeUrl: (string: string) => any
};

export const CelestialRouter = observer((props: Props) => {

    const appState = React.useContext(AppStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [celestial, setCelestial] = React.useState<ICelestialDetail | undefined>(undefined);
    const [celestialFeature, setCelestialFeature] = React.useState<CelestialFeature | undefined>(undefined);

    const { feature, installationId } = useParams();

    React.useEffect(() => {
        if (feature !== undefined && feature.length > 0) {
            if (feature.toLowerCase() === "installation") {
                if (installationId !== undefined && installationId.length > 0) {
                    const installationFeature = { installationId: Number(installationId) };
                    setCelestialFeature(installationFeature);
                }
            } else {
                setCelestialFeature(feature as CelestialFeature);
            }
        }

    }, [feature, installationId]);

    function changeFeature(feature: CelestialFeature | undefined) {
        const toSet = feature === undefined ? celestial_summary(props.celestialId) : celestial_feature(props.celestialId, feature);
        props.changeUrl(toSet);
        setCelestialFeature(feature);
    }

    React.useEffect(() => {

        const newCelestial = props.solarSystemWrapper.solarSystem.celestials.find(c => c.celestialId == props.celestialId);
        if (newCelestial !== undefined) {
            // If there was a celestial and it has switched then reset the feature
            if (celestial !== undefined && celestial.celestialId !== newCelestial.celestialId) {
                setCelestialFeature(undefined);
            }
            setCelestial(newCelestial);
        } else {
            props.changeUrl(selected_system_celestials);
        }

    }, [props.solarSystemWrapper, props.celestialId]);

    function reloadCallback(solarSystem: SolarSystemDetail) {
        solarSystemState.loadSolarSystem(solarSystem);
        props.closeCallback();
        appState.navigate(selected_system);
    }

    function reloadLocallyCallback(solarSystem: SolarSystemDetail) {
        solarSystemState.loadSolarSystem(solarSystem);
    }

    if (!celestial ||
        !worldState.CelestialTypeSettings ||
        !worldState.InstallationTypeSettings ||
        !worldState.ItemTypeSettings ||
        !worldState.ShipTypeSettings ||
        !worldState.SectorTypeSettings) {
        return <LoadingSpinner />;
    }

    const childProps = {
        celestial: celestial,
        solarSystem: props.solarSystemWrapper.solarSystem,
        solarSystemWrapper: props.solarSystemWrapper,
        gameSettings: props.gameSettings,

        installationTypeSettings: worldState.InstallationTypeSettings,
        celestialTypeSettings: worldState.CelestialTypeSettings,
        itemTypeSettings: worldState.ItemTypeSettings,
        shipTypeSettings: worldState.ShipTypeSettings,
        sectorTypeSettings: worldState.SectorTypeSettings,

        worldEffects: props.worldEffects,
        reloadCallback: reloadCallback,
        reloadLocallyCallback: reloadLocallyCallback,
        setFeature: changeFeature
    };

    function featureContent() {

        if (celestialFeature !== undefined) {

            if (celestialFeature === "Build") {
                return <BuildView {...childProps} />;
            } else if (celestialFeature === "Drones") {
                return <DronesView {...childProps} />;
            } else if (celestialFeature === "Manufactory") {
                return <ManufactoryView {...childProps} />;
            } else if (celestialFeature === "Megacity") {
                return <MegacityView {...childProps} />;
            } else if (celestialFeature === "Ships") {
                return <ShipsView {...childProps} />;
            }

            return <InstallationView {...childProps} installationId={celestialFeature.installationId} />;
        }

        return <SummaryView {...childProps} />;
    }

    return <SubPanel heading={CelestialHelper.fullname(celestial, worldState.CelestialTypeSettings)}
        backgroundImage={CelestialHelper.celestialBackground(celestial, props.solarSystemWrapper.solarSystem?.owner !== undefined)}
        headingContent={
            <>
                {!!celestialFeature &&
                    <ButtonHolder>
                        <BackNavButton to={selected_system_celestials} />
                        <CelestialFeatureHelpButton celestialFeature={celestialFeature} />
                    </ButtonHolder>}
                {!celestialFeature && <CloseButton action={() => props.closeCallback()} />}
            </>
        }
    >
        <CelestialFeatureTitle feature={celestialFeature} {...props} {...childProps} />
        {featureContent()}
    </SubPanel >
});