import { IInstallationTypeSettings, IItemTypeSettings, ISectorTypeSettings, IShipTypeSettings } from "../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../SolarSystemWrapper";
import { economyProblems } from "./Economy/EconomyProblems";
import { manufactoryProblems } from "./Manufactory/ManufactoryProblems";
import { megacityProblems } from "./Megacity/MegacityProblems";
import { militaryProblems } from "./Military/MilitaryProblems";
import { mergeProblemMaybes, Problem } from "./Problem";
import { shipsProblems } from "./Ships/ShipsProblems";

export class ProblemHelper {

    public static calculateProblems(
        installationTypeSettings: IInstallationTypeSettings,
        shipTypeSettings: IShipTypeSettings,
        itemTypeSettings: IItemTypeSettings,
        sectorTypeSettings: ISectorTypeSettings,

        wrapper: SolarSystemWrapper): Problem[] {
        return mergeProblemMaybes(
            militaryProblems(installationTypeSettings, wrapper),
            economyProblems(itemTypeSettings, wrapper),
            shipsProblems(shipTypeSettings, installationTypeSettings, wrapper),
            megacityProblems(sectorTypeSettings, wrapper),
            manufactoryProblems(itemTypeSettings, installationTypeSettings, wrapper));
    }
}