import { observer } from "mobx-react-lite";
import React from "react";
import { ICelestial, ISolarSystemDetail, Megacity, MegacityCommodityUse, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext, WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../../../Components/Base/Buttons/ButtonHolder";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../../../Components/Base/Containers/Table";
import { FormFooter } from "../../../../../Components/Base/Form/FormFooter";
import { Select } from "../../../../../Components/Base/Form/Select";
import { DateText } from "../../../../../Components/Base/Text/DateText";
import { TickOrCrossIcon } from "../../../../../Components/FusionShift/Icons/TickOrCrossIcon";
import { CommodityTypeLink } from "../../../../../Components/FusionShift/Links/Items/CommodityTypeLink";
import { SectorTypeLink } from "../../../../../Components/FusionShift/Links/SectorTypeLink";
import { valueValid } from "../../../../../Entities/Validation";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { MegacityHelper } from "../../../../../Helpers/MegacityHelper";
import { TextHelper } from "../../../../../Helpers/TextHelper";
import { ValueFormatter } from "../../../../../Helpers/ValueFormatter";
import { CommodityStoreBar } from "./CommodityStoreBar";

type Props = {
    solarSystem: ISolarSystemDetail,
    celestial: ICelestial,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    cancelCallback: () => any,
    megacity: Megacity
}

export const SectorCommodityList = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [isEdit, setIsEdit] = React.useState(false);
    const [commodityUsages, setCommodityUsages] = React.useState<{ [key: string]: MegacityCommodityUse } | undefined>(undefined);

    if (!worldState.CommodityHelper || !props.megacity.sectors || !worldState.GameSettings) {
        return null;
    }

    function saveChanges() {
        if (commodityUsages) {
            return apiState.MegacityClient.setCommodityUsages(props.celestial.celestialId, commodityUsages)
                .then(solarSystem => {
                    if (solarSystem) {
                        props.reloadCallback(solarSystem);
                        setIsEdit(false);
                    }
                })
        }
    }

    function updateCommodityUsage(commodityTypeName: string, use: string) {
        if (commodityUsages) {
            commodityUsages[commodityTypeName] = MegacityCommodityUse[use as keyof typeof MegacityCommodityUse];
            setCommodityUsages(commodityUsages);
        }
    }

    const commodities = worldState.CommodityHelper.allCommodities()
        .filter(c => c.sectorType.typeName in props.megacity.sectors!)
        .map(c => {

            const sector = props.megacity.sectors![c.sectorType.typeName];
            return {
                commodity: c,
                store: sector.commodityStores && c.itemType.typeName in sector.commodityStores ? sector.commodityStores[c.itemType.typeName] : undefined,
                sector
            }
        });

    if (!commodityUsages) {
        const commodityUsagesToUse: { [key: string]: MegacityCommodityUse } = {};
        for (let c of commodities) {
            if (c.store) {
                commodityUsagesToUse[c.commodity.itemType.typeName] = c.store.use;
            }
        }
        setCommodityUsages(commodityUsagesToUse);
    }

    let lastSector: string | undefined = undefined;

    return <SubPanel heading={{ text: "Commodities ", icon: IconHelper.Items.Commodity }}
        isUnpadded
        headingContent={
            !isEdit &&
            <Button
                type="nav"
                text="Change Usage"
                icon={IconHelper.General.Edit}
                action={() => setIsEdit(true)}
                hideTextOnMobile
            />

        }
        footerContent={
            <FormFooter validation={valueValid(true)}>
                {!isEdit && <Button type="nav" icon={IconHelper.General.Previous} action={props.cancelCallback} text="Back" />}
                {isEdit &&
                    <ButtonHolder isPulledRight>
                        <Button type="danger" icon={IconHelper.General.Cancel} action={props.cancelCallback} text="Cancel" />
                        <Button type="action" className="is-pulled-right" icon={IconHelper.General.Confirm} text="Save changes" action={saveChanges} />
                    </ButtonHolder>
                }
            </FormFooter>
        }
    >
        <Table
            isHoverable
            isFullWidth
            hideHeaderMobile
            isMobileMultirow
            heading={
                <>
                    <td className="is-hidden-mobile">Sector</td>
                    <td>Commodity</td>
                    {!isEdit &&
                        <>
                            <td className="is-hidden-touch">Stores</td>
                            <td>Consumed</td>
                        </>
                    }
                    <td>Usage</td>
                </>
            }>
            {commodities.map(c => {
                const showSectorName = lastSector != c.commodity.sectorType.typeName;
                lastSector = c.commodity.sectorType.typeName;
                return <React.Fragment key={c.commodity.itemType.typeName} >
                    <tr className="is-hidden-tablet">
                        <td colSpan={isEdit ? 1 : 3}>
                            {!isEdit &&
                                <TickOrCrossIcon type={c.store && c.store.quantity > 0 ? "tick" : "cross"} />
                            }
                            <CommodityTypeLink commodityType={c.commodity.itemType} />
                            {!isEdit && <><br />
                                <CommodityStoreBar store={c.store} commodity={c.commodity} capacity={lastSector in props.megacity.sectors ? props.megacity.sectors[lastSector].commodityCapacity : 0} />
                            </>}
                        </td>
                    </tr>
                    <tr>
                        <td className="is-hidden-mobile">
                            {showSectorName && <>
                                <SectorTypeLink sectorType={c.commodity.sectorType} />
                                {c.sector.assignedPopulation > 0 &&
                                    <span>
                                        : {ValueFormatter.formatPopulation(c.sector.assignedPopulation)} consumes {ValueFormatter.formatLocaleNumber(MegacityHelper.calculateConsumption(c.sector, worldState.GameSettings!) * 24, 2)} per day
                                    </span>
                                }
                            </>
                            }
                        </td>
                        <td className="is-hidden-mobile">
                            {!isEdit &&
                                <TickOrCrossIcon type={c.store && c.store.quantity > 0 ? "tick" : "cross"} />
                            }
                            <CommodityTypeLink commodityType={c.commodity.itemType} />
                        </td>
                        {!isEdit &&
                            <>
                                <td className="is-hidden-tablet">
                                    {c.sector.assignedPopulation > 0 &&
                                        <span>
                                            {ValueFormatter.formatPopulation(c.sector.assignedPopulation)} consumes {ValueFormatter.formatLocaleNumber(MegacityHelper.calculateConsumption(c.sector, worldState.GameSettings!) * 24, 2)} per day
                                        </span>
                                    }
                                </td>
                                <td className="is-hidden-touch">
                                    <CommodityStoreBar store={c.store} commodity={c.commodity} capacity={lastSector in props.megacity.sectors ? props.megacity.sectors[lastSector].commodityCapacity : 0} />
                                </td>
                                <td>
                                    {c.store && <DateText date={c.store.expiryDate} />}
                                </td>
                            </>
                        }
                        <td className="is-content-width">
                            {c.store && <>
                                {!isEdit &&
                                    TextHelper.tidyText(MegacityCommodityUse[c.store.use])
                                }
                                {isEdit &&
                                    <Select defaultValue={MegacityCommodityUse[c.store.use]} valueChanged={e => updateCommodityUsage(c.commodity.itemType.typeName, e)}
                                        values={Object.keys(MegacityCommodityUse)
                                            .filter(k => isNaN(Number(k)))
                                            .map(u => {
                                                return {
                                                    value: u,
                                                    label: TextHelper.tidyText(u)
                                                }
                                            })} />
                                }
                            </>
                            }
                        </td>
                    </tr>
                </React.Fragment>
            })}
        </Table>
    </SubPanel>;
});