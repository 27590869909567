import { MarketOrderBrowse } from "../../Views/Marketplace/BrowseView";
import { MarketOrderCreate } from "../../Views/Marketplace/CreateView";
import { MarketOrderIndex } from "../../Views/Marketplace/IndexView";
import { route } from "./Root";

export const market = "/market";
export const market_orders = market + "/orders";
export const market_create = market + "/create";
export const market_browse = market + "/browse";

export const marketRoutes = [

    route(market_browse, MarketOrderBrowse, "Browse Market Orders"),
    route(market_create, MarketOrderCreate, "Create Market Order"),
    route(market_orders, MarketOrderIndex, "Market orders")
];