import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MissionLogStateContext, UnreadStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../Helpers/IconHelper';
import { SimpleBaseView } from '../BaseView';
import { MissionTable } from './MissionTable';
import { MissionStatus, MissionWrapper } from './MissionWrapper';

type View = "Active" | "Complete";

export const MissionIndex = observer(() => {

    const missionLogState = React.useContext(MissionLogStateContext);
    const unreadState = React.useContext(UnreadStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [view, setView] = React.useState<View>("Active");
    const [missions, setMissions] = React.useState<MissionWrapper[]>([]);
    const [allMissions, setAllMissions] = React.useState<MissionWrapper[] | undefined>(undefined);

    React.useEffect(() => {
        if (missionLogState.MissionLog && worldState.MissionTypeSettings) {

            const missionTypeNames: string[] = [];
            missionTypeNames.push(...missionLogState.MissionLog.rewardPendingMissions);
            if (missionLogState.MissionLog.activeMissions) {
                missionTypeNames.push(...Object.keys(missionLogState.MissionLog.activeMissions));
            }
            missionTypeNames.push(...missionLogState.MissionLog.completedMissions);

            const wrapped = missionTypeNames
                .map(m => new MissionWrapper(missionLogState.MissionLog!, worldState.MissionTypeSettings![m]));
            setAllMissions(wrapped);
        }
    }, [missionLogState.MissionLog, worldState.MissionTypeSettings]);

    React.useEffect(() => {

        if (allMissions) {

            unreadState.clearUnread("mission");

            const statuses: MissionStatus[] = view === "Active" ? ["Active", "Reward Pending"] : ["Complete"];
            setMissions(allMissions.filter(m => statuses.includes(m.Status)));
        }
    }, [allMissions, view]);

    if (!missionLogState.MissionLog || !worldState.MissionTypeSettings) {
        return <LoadingSpinner />;
    }

    const missionCategories: { [key: string]: MissionWrapper[] } = {};

    for (let mission of missions) {
        if (!(mission.MissionType.category in missionCategories)) {
            missionCategories[mission.MissionType.category] = [];
        }
        missionCategories[mission.MissionType.category].push(mission);
    }

    const categories = Object.keys(missionCategories);

    return <SimpleBaseView heading={{ text: "Missions", icon: IconHelper.Missions.Missions }}
        headingContent={
            <ButtonHolder>
                <Button type="nav" text="Active" isDisabled={view === "Active"} action={() => setView("Active")} icon={IconHelper.General.Time} />
                <Button type="nav" text="Complete" isDisabled={view === "Complete"} action={() => setView("Complete")} icon={IconHelper.General.Confirm} />
            </ButtonHolder>
        }>
        {categories.length == 0 && <Paragraph type="missing">No missions</Paragraph>}
        {categories.map(c => <React.Fragment key={c}>
            <SubPanel heading={c} isUnpadded>
                <MissionTable
                    category={c}
                    missions={missionCategories[c]}
                    missionTypes={worldState.MissionTypeSettings!.data}
                    missionLog={missionLogState.MissionLog!}
                />
            </SubPanel>
        </React.Fragment>)}
    </SimpleBaseView>;
});