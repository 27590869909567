import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UnreadStateContext } from '../../../ApplicationState/ContextRoot';
import { MessageBoxWithIcon } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';
import { politics_terms_detail } from '../../../Navigation/Routing/Politics';

type Props = {
    presidentialTermId: number
}

export const NotVotedMessage = observer((props: Props) => {

    const unreadState = React.useContext(UnreadStateContext);

    if (!unreadState.HasPendingPresidentialTermVote) {
        return null;
    }

    return <MessageBoxWithIcon
        type="info"
        icon={IconHelper.Politics.President}
        text={<>
            You have not voted for president in the upcoming <Link to={politics_terms_detail(props.presidentialTermId)}>presidential term</Link>. You could also consider running for president yourself by submitting a manifesto.
        </>
        }
    />;
});