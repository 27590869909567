import classNames from "classnames";
import React, { ReactNode } from "react";

type Props = {
  isExpand?: boolean,
  label: ReactNode;
  className?: string | undefined;
  children: ReactNode;
  hasAddons?: boolean;
  fieldBodyClassName?: string | undefined;
  fieldClassName?: string | undefined;
  labelClassName?: string | undefined;
  extra?: ReactNode | undefined;
  growToFitContent?: boolean | undefined
};

export const FieldHolder = (props: Props) => {

  const className = classNames("field", props.className);

  const fieldClassName = classNames(
    "field",
    "is-small",
    props.fieldClassName,
    {
      "no-height": props.growToFitContent,
      "has-addons": props.hasAddons,
      "is-expand": props.isExpand
    });

  return <div className={className}>
    <div
      className={`label is-small ${props.labelClassName ? props.labelClassName : ""
        }`}
    >
      <label className="label">{props.label}</label>
    </div>
    <div
      className={`field-body ${props.fieldBodyClassName ? props.fieldBodyClassName : ""
        }`}
    >
      <div className={fieldClassName}>
        {props.children}
      </div>
      {props.extra && (
        <div className="field extra">
          <span>{props.extra}</span>
        </div>
      )}
    </div>
  </div>;
};

type SimpleProps = {
  name: string;
  children: ReactNode;
};

export const SimpleField = (props: SimpleProps) => {
  return (
    <div className="field">
      <label className="label">{props.name}</label>
      {props.children}
    </div>
  );
};
