import * as React from 'react';
import { BribeForManifestoTerms, PresidentialManifestoPair } from '../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../../../Components/Base/Loading/LoadingSpinner';
import { H3 } from '../../../../Components/Base/Text/H';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { QuoteParagraph } from '../../../../Components/Base/Text/QuoteParagraph';
import { EmblemImage } from '../../../../Components/FusionShift/Images/EmblemImage';
import { PlayerLink } from '../../../../Components/FusionShift/Links/PlayerLink';

type Props = {
    terms: BribeForManifestoTerms
}

export const BribeForManifestoTermsDetail = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const [presidentialManifesto, setPresidentialManifesto] = React.useState<PresidentialManifestoPair | undefined>(undefined);

    React.useEffect(() => {
        apiState.PoliticsClient.getPresidentialManifesto(props.terms.presidentialManifestoId).then(setPresidentialManifesto);
    }, []);

    if (presidentialManifesto === undefined) {
        return <LoadingSpinner />;
    }

    const manifesto = presidentialManifesto.presidentialManifesto;
    const votedForManifesto = presidentialManifesto.presidentialManifestoObservingPlayerVotedFor && presidentialManifesto.presidentialManifestoObservingPlayerVotedFor.presidentialManifestoId !== manifesto.presidentialManifestoId && presidentialManifesto.presidentialManifestoObservingPlayerVotedFor;

    return <>
        <SubPanel heading={`Bribe For Candidate`}>
            <EmblemImage filename={manifesto.player.emblemFilename} isInline />
            <Paragraph>This bribe is to vote for Presidential Candidate <PlayerLink player={manifesto.player} />.</Paragraph>
            <H3>{manifesto.title}</H3>
            <QuoteParagraph>
                {manifesto.description}
            </QuoteParagraph>
        </SubPanel>
        {votedForManifesto &&
            <SubPanel heading={`Your Vote For Candidate`}>
                <EmblemImage filename={votedForManifesto.player.emblemFilename} isInline />
                <Paragraph>You have cast a vote for Presidential Candidate <PlayerLink player={votedForManifesto.player} />.</Paragraph>
                <H3>{votedForManifesto.title}</H3>
                <QuoteParagraph>
                    {votedForManifesto.description}
                </QuoteParagraph>
            </SubPanel>
        }
    </>
};