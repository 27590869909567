import * as React from 'react';
import { ISolarSystemDetail, ManufactoryNode, ManufactoryNodeConfiguration, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ConnectingBackground } from './ConnectingBackground';
import { NodeList } from './NodeList';

type Props = {
    celestialId: number,
    solarSystem: ISolarSystemDetail,
    inputNodes: ManufactoryNode[],
    outputNodes: ManufactoryNode[],
    selectedManufactoryNodeId: string | undefined,
    configureByCommodityCallback: () => any,
    setSelectedManufactoryNodeId: (manufactoryNodeId: string) => any,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const NodesControl = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    function clearAllNodes() {

        const configuration: { [key: string]: ManufactoryNodeConfiguration } = {};

        for (const input of props.inputNodes ?? []) {
            configuration[input.manufactoryNodeId] = new ManufactoryNodeConfiguration({
                itemTypeName: "",
                throughputLimitRatio: 1
            })
        }
        for (const output of props.outputNodes ?? []) {
            configuration[output.manufactoryNodeId] = new ManufactoryNodeConfiguration({
                itemTypeName: "",
                throughputLimitRatio: 1
            })
        }

        apiState.ManufactoryClient.setNodeConfigurations(props.celestialId, configuration).then(props.reloadCallback);
    }

    const hasOutput = props.outputNodes && props.outputNodes.find(x => x.itemTypeName !== undefined && x.itemTypeName.length > 0) != undefined
    const hasInput = props.inputNodes && props.inputNodes.find(x => x.itemTypeName !== undefined && x.itemTypeName.length > 0) != undefined

    const canConfigure = props.outputNodes && props.outputNodes.filter(x => x.itemTypeName === undefined || x.itemTypeName.length === 0).length > 0;
    const canClear = hasOutput || hasInput;

    return <SubPanel heading="Nodes"
        headingContent={(canConfigure || canClear) &&
            <ButtonHolder>
                {canConfigure &&
                    <Button type="nav" text="Configure by Output" icon={IconHelper.Items.Commodity} action={() => props.configureByCommodityCallback()} />}
                {canClear &&
                    <Button
                        type="danger"
                        confirm='Are you sure you want to clear all the nodes in this manufactory?'
                        text="Clear all"
                        icon={IconHelper.General.Delete}
                        action={clearAllNodes}
                        hideTextOnMobile
                    />}
            </ButtonHolder>
        }
    >
        <div className="manufactory-wrapper">
            <div className="manufactory-nodes">
                <NodeList nodes={props.inputNodes} {...props} type="Input" />
                <ConnectingBackground {...props} itemsDetail={props.solarSystem.itemsDetail} />
                <NodeList nodes={props.outputNodes} {...props} type="Output" />
            </div>
        </div>
    </SubPanel>;
};