import React from "react";
import { Installation } from "../../../../../ApplicationState/ApiClient";
import { LoadingSpinner } from "../../../../../Components/Base/Loading/LoadingSpinner";
import { CelestialInstallationProps } from "../CelestialViewProps";
import { ExistingInstallation } from "./ExistingInstallation";

export const InstallationView = (props: CelestialInstallationProps) => {

    const [installation, setInstallation] = React.useState<Installation | undefined>(undefined);

    React.useEffect(() => {
        if (props.celestial.installations) {
            setInstallation(props.celestial.installations.find(c => c.installationId == props.installationId));
        }
    }, [props.celestial, props.celestial.installations, props.installationId]);

    if (!installation) {
        return <LoadingSpinner />;
    }

    return <ExistingInstallation {...props} installation={installation} />;
};