import { observer } from 'mobx-react-lite';
import * as React from 'react';
import Joyride, { ACTIONS, CallBackProps, LIFECYCLE } from 'react-joyride';
import { useLocation } from 'react-router-dom';
import { AppStateContext, TutorialStateContext } from '../../ApplicationState/ContextRoot';
import { FusionShiftStep, tutorialSteps } from './Steps';

export const Tour = observer(() => {

    const appState = React.useContext(AppStateContext);
    const tutorialState = React.useContext(TutorialStateContext);
    const location = useLocation();
    const [isRunning, setIsRunning] = React.useState(false);
    const [awaitingStep, setAwaitingStep] = React.useState<FusionShiftStep | undefined>(undefined);
    const [stepIndex, setStepIndex] = React.useState(0);

    React.useEffect(() => {
        if (!isRunning && tutorialState.TutorialRunning) {
            setIsRunning(true);
        }
    }, [tutorialState.TutorialRunning]);

    React.useEffect(() => {
        if (awaitingStep && location.pathname) {
            if (awaitingStep && (awaitingStep.targetPage === undefined || location.pathname.startsWith(awaitingStep.targetPage))) {
                checkForTarget();
            }
        }
    }, [location.pathname, awaitingStep]);

    function checkForTarget() {
        setTimeout(() => {
            const targetExists = awaitingStep !== undefined && document.getElementById(awaitingStep.targetId) !== null;

            if (targetExists) {
                setAwaitingStep(undefined);
                setIsRunning(true);
            } else {
                checkForTarget();
            }
        }, 750);
    }

    function waitForStep(index: number) {
        const targetStep = tutorialSteps[index];

        if (targetStep.targetPage && location.pathname !== targetStep.targetPage) {
            setIsRunning(false);
            setAwaitingStep(targetStep);
            if (targetStep.navigate !== undefined) {
                targetStep.navigate(appState);
            } else {
                appState.navigate(targetStep.targetPage);
            }
        }
    }

    function joyrideCallback(data: CallBackProps) {

        const { action, index, lifecycle } = data;

        if (action === ACTIONS.NEXT) {
            if (lifecycle === LIFECYCLE.COMPLETE) {
                setStepIndex(stepIndex + 1);
            } else if (lifecycle === LIFECYCLE.INIT) {
                waitForStep(index);
            }
        } else if (action === ACTIONS.CLOSE) {
            setStepIndex(0);
            setIsRunning(false);
        } else if (action === ACTIONS.PREV) {
            if (lifecycle === LIFECYCLE.COMPLETE) {
                setStepIndex(stepIndex - 1);
            } else if (lifecycle === LIFECYCLE.INIT) {
                waitForStep(index);
            }
        }
    }

    return <Joyride
        steps={tutorialSteps}
        run={isRunning}
        callback={joyrideCallback}
        stepIndex={stepIndex}
        continuous
        showProgress
        disableOverlayClose
    />;
});
