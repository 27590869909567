import classNames from 'classnames';
import * as React from 'react';

type Props = {
    children: React.ReactNode,
    separator?: "arrow" | "bullet" | "dot" | "succeeds",
    className?: string | undefined
}

export const Breadcrumbs = (props: Props) => {

    const className = classNames(
        "breadcrumb",
        props.className,
        `has-${props.separator !== undefined ? props.separator : "arrow"}-separator`
    );

    const children = React.Children.toArray(props.children);

    return <nav className={className}>
        <ul>
            {children.map((c, i) => <li key={i}>
                {c}
            </li>)}
        </ul>
    </nav>;
};

