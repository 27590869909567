import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { ButtonChooser, choice } from '../../../Components/Base/Buttons/ButtonChooser';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../Helpers/IconHelper';
import { EmpireViewProps } from '../EmpireViewProps';
import { NetworkRender, NetworkRenderType } from './NetworkRender';
import { SelectedSolarSystem } from './SelectedSolarSystem';

export const NetworkSummary = observer((props: EmpireViewProps) => {

    const worldState = React.useContext(WorldStateContext);

    const [selectedSolarSystemId, setSelectedSolarSystemId] = React.useState<number | undefined>();
    const [renderType, setRenderType] = React.useState<NetworkRenderType>("Items");


    // TODO: select a solar system to highlight like on manufactory
    // TODO: search / filter item type names
    // TODO: switch between economic (ships with items), military (reinforce, rebase, attack, explore) and repeats
    // TOOD: how to handle additional solar systems outside of the empire
    // TODO: render list of fleets

    const choices = [
        choice<NetworkRenderType>("Items", "Items", IconHelper.Items.Item),
        choice<NetworkRenderType>("Ships", "Ships", IconHelper.Ships.Ship)
    ];

    return <>
        <SubPanel
            heading={{ text: "Network", icon: IconHelper.Categories.Network }}
            headingContent={<ButtonChooser
                value={renderType}
                valueChanged={setRenderType}
                values={choices}
            />
            }
        >
            <NetworkRender
                renderType={renderType}
                selectedSolarSystemId={selectedSolarSystemId}
                setSelectedSolarSystemId={setSelectedSolarSystemId}
                solarSystems={props.solarSystems}
                itemTypeSettings={props.itemTypeSettings}
                shipTypeSettings={props.shipTypeSettings}
            />
        </SubPanel>
        <SelectedSolarSystem
            close={() => setSelectedSolarSystemId(undefined)}
            solarSystem={props.solarSystems.find(x => x.solarSystemId === selectedSolarSystemId)}
            {...props}
        />
    </>;
});