import { ContractType } from "../../ApplicationState/ApiClient";
import { FSOIcons } from "./FSOIcons";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class EconomyIcons {

    public get Credits() { return FSOIcons.credits; }
    public get CreditTransaction() { return FSOIcons.creditTransaction; }
    public get CreditIncome() { return FSOIcons.creditIncome; }

    public get Contract() { return MaterialDesignIcons.fileAlert; }
    public get ContractAccept() { return MaterialDesignIcons.fileCheck; }
    public get ContractWithdraw() { return MaterialDesignIcons.fileCancel; }
    public get ContractDecline() { return MaterialDesignIcons.fileCancel; }
    public get ContractCreate() { return MaterialDesignIcons.fileEdit; }
    public get ContractTerms() { return MaterialDesignIcons.fileCog; }

    public contractType(contractType: ContractType): string {
        switch (contractType) {
            case ContractType.Attack:
                return MaterialDesignIcons.sword;
            case ContractType.Defend:
                return MaterialDesignIcons.shield;
            case ContractType.Recon:
                return MaterialDesignIcons.binoculars;
            case ContractType.Courier:
                return MaterialDesignIcons.basketFill;
            case ContractType.BribeForPolicy:
            case ContractType.BribeForManifesto:
                return MaterialDesignIcons.vote;
            case ContractType.Ransom:
                return MaterialDesignIcons.handcuffs;
        }
    }
}