import * as React from 'react';
import { ProblemSeverityIcon } from '../../../../../Components/FusionShift/Icons/ProblemSeverityIcon';
import { Problem, ProblemCategory, ProblemSeverity, severityClassName } from '../../../../../Entities/SolarSystem/Problems/Problem';

type Props = {
    category?: ProblemCategory | undefined,
    problems: Problem[],
    colourAsMinimum?: ProblemSeverity | undefined
}

export const ProblemList = (props: Props) => {

    const problems = props.problems.filter(c => c.category === props.category || props.category === undefined);

    if (problems.length === 0) {
        return null;
    }

    return <ul className="problems-list">
        {problems.map((p, i) => {
            const severityToUse = props.colourAsMinimum !== undefined && props.colourAsMinimum > p.severity ? props.colourAsMinimum : p.severity;
            return <li key={`${p.title}_${i}`} className={severityClassName(severityToUse)}>
                <ProblemSeverityIcon severity={severityToUse} />
                {`${p.title} - ${p.description}`}
            </li>;
        })}
    </ul>;
};