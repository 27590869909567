import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, IFleet } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { FormFooter } from '../../../Components/Base/Form/FormFooter';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { HoursIcon } from '../../../Components/FusionShift/Icons/HoursIcon';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ShipInputList, ShipTypeQuantity } from '../../SendShips/Components/ShipInputList';

type Props = {
    fleet: IFleet,
    sendHomeCallback: (ships: { [key: string]: number }) => any
}

export const ReturnHomeForm = observer((props: Props) => {

    const [shipQuantities, setShipQuantities] = React.useState<{ [key: string]: number }>({});

    const [availableFleetWrapper, setAvailableFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);
    const [selectedFleetWrapper, setSelectedFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);
    const [willAllBeSentHome, setWillAllBeSentHome] = React.useState(true);

    const [hours, setHours] = React.useState<number | undefined>();
    const [date, setDate] = React.useState<Date | undefined>();

    const worldState = React.useContext(WorldStateContext);

    React.useEffect(() => {
        if (!availableFleetWrapper && worldState.ShipTypeSettings && worldState.GameSettings && props.fleet.ships) {
            setAvailableFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, props.fleet.ships));
        }
    }, [worldState.ShipTypeSettings, worldState.GameSettings, props.fleet.ships])

    React.useEffect(() => {
        if (worldState.ShipTypeSettings && worldState.GameSettings) {
            setSelectedFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities));
        }
    }, [worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities]);

    React.useEffect(() => {
        calculateHours();
    }, [selectedFleetWrapper]);

    function setShipQuantity(ship: ShipTypeQuantity, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }
        if (quantity > ship.quantity) {
            quantity = ship.quantity;
        }
        const newObject = {
            ...shipQuantities
        };
        newObject[ship.ship.typeName || ""] = quantity;
        setShipQuantities(newObject);

        const newSum = CollectionHelper.sumOfDictionary(newObject);
        setWillAllBeSentHome(newSum === 0 || newSum === CollectionHelper.sumOfArray(availableFleetWrapper!.Ships.map(s => s.quantity)));

        calculateHours();
    }

    React.useEffect(() => {

        const interval = setInterval(() => {
            calculateDate();
        }, 1000);

        return () => clearInterval(interval);

    }, [worldState.GameSettings, hours]);

    function calculateHours() {

        const selected = (selectedFleetWrapper?.HasAnyShips ?? false) ? selectedFleetWrapper?.SolarSystemsPerHour : undefined;
        const all = availableFleetWrapper?.SolarSystemsPerHour ?? 0;

        const speed = selected === undefined || selected <= 0 ? all : selected;

        const hoursToUse = FleetHelper.estimateHoursJourneyDuration(speed, props.fleet.targetSolarSystem, props.fleet.sourceSolarSystem, worldState.GameSettings!);

        setHours(hoursToUse);
        calculateDate(hoursToUse);
    }

    function calculateDate(hoursToUse?: number) {

        hoursToUse ??= hours;

        const dateToUse = hoursToUse !== undefined ? new Date(new Date().getTime() + (hoursToUse * 60 * 60 * 1000)) : undefined;
        setDate(dateToUse);
    }

    if (!worldState.ShipTypeSettings || !worldState.GameSettings || !availableFleetWrapper || !selectedFleetWrapper) {
        return null;
    }

    if (hours === undefined) {
        calculateHours();
    }

    const shipDescription = willAllBeSentHome ? "All" : "Selected";

    return <>
        <Paragraph type="prompt">Choose which ships to send home. Leave blank for all ships.</Paragraph>
        <ShipInputList
            shipTypeSettings={worldState.ShipTypeSettings}
            availableShips={availableFleetWrapper}
            selectedShips={selectedFleetWrapper}
            movementType={FleetMovementType.ReturnHome}
            setShipQuantity={(s, q) => setShipQuantity(s, q)}
            setShipTactic={(s, t) => { }}
            shipQuantities={shipQuantities}
            showTactics={false}
            tactics={{}}
        />
        <FormFooter >
            <Paragraph>
                {shipDescription} ships will arrive back home{date !== undefined && <> at {ValueFormatter.formatTimeOrDate(date)}</>}{hours !== undefined && <> - <HoursIcon quantity={hours} /></>}.
            </Paragraph>
            <Button type="warning" icon={IconHelper.Ships.movementType(FleetMovementType.ReturnHome)} text="Send ships home" action={() => props.sendHomeCallback(shipQuantities)} className="is-pulled-right" />
        </FormFooter>
    </>;
});