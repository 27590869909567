import React from "react";
import { IShipTypeSettings, ShipType } from "../../../ApplicationState/ApiClient";
import { Button } from "../../../Components/Base/Buttons/Button";
import { FormTable } from "../../../Components/Base/Containers/FormTable";
import { HeadingContent } from "../../../Components/Base/Containers/PanelHeading";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { NumberInput } from "../../../Components/Base/Form/Input";
import { ShipTypeImageAndLink } from "../../../Components/FusionShift/Images/ShipTypeImage";
import { IconHelper } from "../../../Helpers/IconHelper";

export type ShipTypeQuantity = {
    ship: ShipType,
    quantity: number
}

type Props = {
    factionTypeName: string,
    shipTypeSettings: IShipTypeSettings,
    shipQuantities: { [key: string]: number },
    setShipQuantity(ship: ShipTypeQuantity, quantity: number),
    heading?: HeadingContent | undefined,
    headingContent?: React.ReactNode | undefined,
    footerContent?: React.ReactNode | undefined,
    isDisabled?: boolean
}

export const SimpleShipInputList = (props: Props) => {

    const ships = Object.values(props.shipTypeSettings.data)
        .filter(x => x.factionTypeName === props.factionTypeName)
        .map(x => {
            const ship: ShipTypeQuantity = {
                quantity: x.typeName in props.shipQuantities ? props.shipQuantities[x.typeName] : 0,
                ship: x
            };

            return ship;
        })
        .sort(((a, b) => a.ship.order > b.ship.order ? 1 : -1));

    const heading = props.heading ?? { text: "Ships", icon: IconHelper.Ships.Ship };

    return <SubPanel heading={heading}
        headingContent={props.headingContent}
        footerContent={props.footerContent}
    >
        <FormTable
            isSmall
            hasAddons
            isStriped
            data={ships}
            render={s => {

                const noZero = props.shipQuantities[s.ship.typeName || ""] === 0 || props.shipQuantities[s.ship.typeName || ""] === undefined;

                return {
                    key: s.ship.typeName,
                    label: <ShipTypeImageAndLink shipType={s.ship} />,
                    content: <>
                        <div className="control">
                            <NumberInput
                                isDisabled={props.isDisabled}
                                size={5}
                                value={s.ship.typeName in props.shipQuantities ? props.shipQuantities[s.ship.typeName] : 0}
                                valueChanged={value => props.setShipQuantity(s, value)}
                            />
                        </div>
                        <div className={`control ${noZero ? "is-hidden-mobile" : ""}`}>
                            <Button type="nav" icon="" className="is-small" text="0" action={() => props.setShipQuantity(s, 0)} isDisabled={noZero || props.isDisabled} />
                        </div>
                    </>
                }

            }}
        />
    </SubPanel>;
};