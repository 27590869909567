import { observer } from "mobx-react-lite";
import React from "react";
import { ICelestialDetail, InstallationType, ISolarSystemDetail, RequestResponseOfSolarSystemDetail, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { PlayerStateContext, WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { BuildButton } from "../../../../../Components/FusionShift/Build/BuildButton";
import { BuildSummary } from "../../../../../Components/FusionShift/Build/BuildSummary";
import { CelestialShipInstallationWarning } from "../../../../../Components/FusionShift/Build/CelestialShipInstallationWarning";
import { InstallationTypeDescription } from "../../../../../Components/FusionShift/Descriptions/InstallationTypeDescription";
import { RequestResponseMessage } from "../../../../../Components/FusionShift/Messages/RequestResponseMessage";
import { TypeDataVoice } from "../../../../../Components/FusionShift/TypeDataVoice";
import { BuildHelper } from "../../../../../Helpers/BuildHelper";

type Props = {
    solarSystem: ISolarSystemDetail,
    installationType: InstallationType,
    celestial: ICelestialDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const BuildDescription = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [response, setResponse] = React.useState<RequestResponseOfSolarSystemDetail | undefined>(undefined);

    function reloadCallback(response: RequestResponseOfSolarSystemDetail) {
        setResponse(response);
        if (response.wasSuccess && response.result !== undefined) {
            props.reloadCallback(response.result);
        }
    }

    if (!worldState.InstallationTypeSettings || !worldState.ItemTypeSettings || !worldState.ShipTypeSettings ||
        !playerState.Player || !worldState.Effects) {
        return null;
    }

    const buildError = BuildHelper.buildError(props.installationType.typeName, props.celestial, props.solarSystem, worldState.GameSettings, worldState.InstallationTypeSettings, worldState.ItemTypeSettings, worldState.Effects);
    const cost = BuildHelper.costForLevel(worldState.Effects, worldState.InstallationTypeSettings, props.installationType.typeName, 1, worldState.GameSettings, props.celestial.size);

    return <SubPanel heading={props.installationType.name}
        headingContent={<TypeDataVoice type="InstallationType" typeData={props.installationType} />}
        footerContent={
            <BuildButton
                buildError={buildError}
                solarSystemId={props.solarSystem.solarSystemId}
                celestialId={props.celestial.celestialId}
                installationTypeName={props.installationType.typeName}
                reloadCallback={reloadCallback}
                isPulledRight
            />
        }>
        <RequestResponseMessage response={response} />
        <SplitLayout
            isLeftExpand
            left={
                <InstallationTypeDescription
                    celestialSize={props.celestial.size}
                    installationType={props.installationType}
                    level={1}
                    operationLevel={1}
                    factionTypeName={playerState.Player!.factionTypeName}
                    shipBuildDisplay={"all"}
                    installationTypeSettings={worldState.InstallationTypeSettings}
                    preStatsContent={<CelestialShipInstallationWarning
                        installationType={props.installationType}
                        shipTypeSettings={worldState.ShipTypeSettings}
                        otherInstallations={props.celestial.installations ?? []}
                        installationTypeSettings={worldState.InstallationTypeSettings}
                    />}
                />
            }
            right={cost &&
                <BuildSummary
                    cost={cost}
                    buildError={buildError}
                    worldEffects={worldState.Effects}
                    availableItems={props.solarSystem.items}
                />
            }
        />
    </SubPanel>;
});