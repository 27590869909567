import { DroneStatus, FleetMovementType, ShipClass } from "../../ApplicationState/ApiClient";
import { FSOIcons } from "./FSOIcons";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class ShipIcons {

    public get Ship() { return FSOIcons.ship; }
    public get Build() { return FSOIcons.buildShip; };
    public get Activate() { return FSOIcons.activateShip; };
    public get Send() { return FSOIcons.sendShip; };
    public get Grounded() { return FSOIcons.groundShip; };
    public get Drone() { return MaterialDesignIcons.bug; };
    public get Travel() { return MaterialDesignIcons.debug; };

    public get Explore() { return MaterialDesignIcons.compass; };
    public get UnExplored() { return MaterialDesignIcons.compassOff; };

    public get Repeat() { return MaterialDesignIcons.repeat; };
    public get RepeatOff() { return MaterialDesignIcons.repeatOff; };
    public get RepeatPause() { return MaterialDesignIcons.pause; };
    public get RepeatUnpause() { return MaterialDesignIcons.play; };
    public get RepeatDelay() { return MaterialDesignIcons.clock; };

    public get CombatParticipant() { return FSOIcons.ship; }
    public get CombatDestroyed() { return MaterialDesignIcons.skull; }
    public get CombatSurvived() { return MaterialDesignIcons.medal; }

    public shipClass(shipClass: ShipClass): string {
        switch (shipClass) {
            case ShipClass.Fighter:
                return MaterialDesignIcons.sword;
            case ShipClass.Interceptor:
                return MaterialDesignIcons.shield;
            case ShipClass.Raider:
                return MaterialDesignIcons.axe;

            case ShipClass.Frigate:
                return MaterialDesignIcons.triangleOutline;
            case ShipClass.Destroyer:
                return MaterialDesignIcons.triangle;

            case ShipClass.Cruiser:
                return MaterialDesignIcons.starOutline;
            case ShipClass.Battleship:
                return MaterialDesignIcons.star;

            case ShipClass.Drone:
                return MaterialDesignIcons.bug;

            case ShipClass.OrbitalSiege:
                return MaterialDesignIcons.bomb;

            case ShipClass.ColonyPod:
                return MaterialDesignIcons.homePlus;
            case ShipClass.Hauler:
                return MaterialDesignIcons.basket;
            case ShipClass.AdvancedMiningBarge:
            case ShipClass.MiningBarge:
                return MaterialDesignIcons.shovel;
            case ShipClass.Recon:
                return MaterialDesignIcons.binoculars;
        }
    }

    public movementType(fleet: FleetMovementType): string {

        switch (fleet) {
            case FleetMovementType.Reinforce:
                return MaterialDesignIcons.shield;
            case FleetMovementType.ReturnHome:
                return MaterialDesignIcons.home;
            case FleetMovementType.Attack:
                return MaterialDesignIcons.sword;
            case FleetMovementType.Claim:
                return MaterialDesignIcons.homePlus;
            case FleetMovementType.Rebase:
                return MaterialDesignIcons.debug;
            case FleetMovementType.Collection:
                return MaterialDesignIcons.basketFill;
            case FleetMovementType.Delivery:
                return MaterialDesignIcons.basketUnfill;
            case FleetMovementType.Mine:
                return MaterialDesignIcons.shovel;
            case FleetMovementType.Recon:
                return MaterialDesignIcons.binoculars;
            case FleetMovementType.CommoditySell:
                return MaterialDesignIcons.cubeSend;
            case FleetMovementType.Trade:
                return MaterialDesignIcons.cashRefund;
            case FleetMovementType.Explore:
                return this.Explore;
        }
    }

    public droneStatus(status: DroneStatus): string {

        switch (status) {
            case DroneStatus.Returning:
            case DroneStatus.Deactivating:
                return MaterialDesignIcons.arrowDown;
            case DroneStatus.Activating:
                return MaterialDesignIcons.arrowUp;
        }

        return MaterialDesignIcons.missing;
    }
}