import classNames from 'classnames';
import * as React from 'react';

type BaseProps = {
    isFullWidth?: boolean,
    isHoverable?: boolean,
    isClickable?: boolean,
    isMobileCollapse?: boolean,
    isMobileMultirow?: boolean,
    isFixed?: boolean,
    isNarrow?: boolean,
    heading?: React.ReactNode | undefined,
    footer?: React.ReactNode | undefined,
    className?: string | undefined,
    isUnstyled?: boolean,
    isUnpadded?: boolean,
    hideHeaderMobile?: boolean
};

type Props = BaseProps & {
    numberOfColumns?: number,
    children: React.ReactNode,
}

type DictionaryProps = BaseProps & {
    content: { [key: string]: React.ReactNode | undefined }
}

export const Table = (props: Props) => {

    const pcn = props.className;
    const classes = classNames(
        pcn,
        {
            'is-mobile-collapse': props.isMobileCollapse,
            'is-clickable': props.isClickable,
            'is-fixed': props.isFixed,
            'is-narrow': props.isNarrow,
            'is-unpadded': props.isUnpadded,
            'is-hoverable': props.isHoverable,
            'is-fullwidth': props.isFullWidth,
            'table': !props.isUnstyled,
            'is-mobile-multirow': props.isMobileMultirow
        });

    return <table className={`is-striped ${classes}`}>
        {props.heading !== undefined && <thead>
            <tr className={props.hideHeaderMobile ? "is-hidden-mobile" : ""}>
                {props.heading}
            </tr>
        </thead>}
        <tbody>
            {props.children}
            {props.footer !== undefined &&
                <tr>
                    <td colSpan={props.numberOfColumns === 0 ? 1 : props.numberOfColumns}>
                        {props.footer}
                    </td>
                </tr>}
        </tbody>
    </table>;
};

export const DictionaryTable = (props: DictionaryProps) => {

    const keys = Object.keys(props.content);

    if (keys.length === 0) {
        return null;
    }

    const className = classNames(props.className, "dictionary-table");

    return <Table {...props} className={className} numberOfColumns={2} isUnstyled >
        {keys.map(k => <tr key={k}>
            <td className="is-content-width">{k}</td>
            <td>{props.content[k]}</td>
        </tr>)}
    </Table>;
};