import { ContractType } from '../../../ApplicationState/ApiClient';
import { ContractTypes } from '../../../Blobs/Descriptions/ContractTypes';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ThumbnailSlate } from '../../../Components/Base/Containers/Slate';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ContractTypeIcon } from '../../../Components/FusionShift/Icons/ContractTypeIcon';
import { ContractHelper } from '../../../Helpers/ContractHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    forPlayerId: number | undefined,
    setContractType: (contractType: ContractType) => any,
    contractType: ContractType | undefined
};

type SlateProps = {
    contractType: ContractType,
    onSelected: () => any,
    isSelected: boolean
};

const ContractTypeSelectionSlate = (props: SlateProps) => {

    return <ThumbnailSlate
        onClick={props.onSelected}
        title={ValueFormatter.friendlyNameForContractType(props.contractType)}
        thumbnail={<ContractTypeIcon contractType={props.contractType} isGiant />}
        footerContent={<Button type="nav"
            isPulledRight
            icon={props.isSelected ? IconHelper.General.Confirm : IconHelper.Economy.contractType(props.contractType)}
            text={props.isSelected ? "Selected" : "Select  "}
            action={props.onSelected}
            isSelected={props.isSelected} />}
    >
        <Paragraph>
            {ContractTypes.describe(props.contractType)}
        </Paragraph>
    </ThumbnailSlate>;
}

export const ContractTypeChooser = (props: Props) => {

    const availableTypes = ContractHelper.availableContractTypes(props.forPlayerId);

    const left = availableTypes.map((x, i) => i < availableTypes.length / 2 ? x : undefined).filter(x => x !== undefined).map(x => x!);
    const right = availableTypes.map((x, i) => i >= availableTypes.length / 2 ? x : undefined).filter(x => x !== undefined).map(x => x!);

    return <SubPanel heading="Contract Type" >
        <SplitLayout
            left={left.map(x => <ContractTypeSelectionSlate key={x}
                contractType={x}
                onSelected={() => props.setContractType(x)}
                isSelected={x === props.contractType}
            />)}
            right={right.map(x => <ContractTypeSelectionSlate key={x}
                contractType={x}
                onSelected={() => props.setContractType(x)}
                isSelected={x === props.contractType}
            />)}
        />
    </SubPanel>;
};