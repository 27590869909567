import React from "react";
import { ApiStateContext } from "../../../../../ApplicationState/ContextRoot";
import { ErrorMessageBox } from "../../../../../Components/Base/MessageBox";
import { Validation } from "../../../../../Entities/Validation";
import { CollectionHelper } from "../../../../../Helpers/CollectionHelper";
import { FleetHelper } from "../../../../../Helpers/FleetHelper";
import { CelestialViewProps } from "../CelestialViewProps";
import { BuildControl } from "./BuildControl";

export const ShipsView = (props: CelestialViewProps) => {

    const apiState = React.useContext(ApiStateContext);

    const [shipQuantities, setShipQuantites] = React.useState<{ [key: string]: number; }>({});

    const [validation, setValidation] = React.useState<Validation>({
        valid: false,
        value: "Select some ships to build"
    });

    function setShipQuantity(shipTypeName: string, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }

        shipQuantities[shipTypeName || ""] = quantity;
        setShipQuantites(shipQuantities);

        if (CollectionHelper.isAnyQuantityInDictionary(shipQuantities)) {
            setValidation({
                valid: true,
                value: ""
            });
        } else {
            setValidation({
                valid: false,
                value: "Select some ships to build"
            });
        }
    }

    function build() {
        return apiState.ShipClient.buildShips(props.celestial.celestialId, shipQuantities).then(solarSystem => {
            if (solarSystem) {
                props.reloadCallback(solarSystem);
            }
        });
    }

    if (!props.celestial.shipTypeNamesThatCanBeBuilt || Object.keys(props.celestial.shipTypeNamesThatCanBeBuilt).length == 0) {
        return <ErrorMessageBox text={"No ships can be built at this celestial"} />;
    }

    function existingBuilding(shipTypeName: string) {
        let value = 0;

        if (props.solarSystem.shipBuildQueueByCelestial) {
            for (let q of Object.values(props.solarSystem.shipBuildQueueByCelestial)) {
                for (let i of q) {
                    if (i.typeName === shipTypeName) {
                        value += i.quantity;
                    }
                }
            }
        }

        return value;
    }

    const ownFleets = (props.solarSystem.fleets ?? []).filter(x => x.homeSolarSystemId === props.solarSystem.solarSystemId);

    const activatingShips = FleetHelper.sumOfShipsFromFleets(props.solarSystem.activatingShips);
    const movingShips = FleetHelper.sumOfShipsFromFleets(ownFleets);

    const shipsToBuild = Object.keys(props.celestial.shipTypeNamesThatCanBeBuilt)
        .map(s => {
            return {
                shipType: props.shipTypeSettings.data![s],
                maxQuantity: props.celestial.shipTypeNamesThatCanBeBuilt![s],
                existingMoving: s in movingShips ? movingShips[s] : 0,
                existingPresent: props.solarSystem.availableShips && s in props.solarSystem.availableShips ? props.solarSystem.availableShips[s] : 0,
                existingGrounded: props.solarSystem.groundedShips && s in props.solarSystem.groundedShips ? props.solarSystem.groundedShips[s] : 0,
                existingActivating: s in activatingShips ? activatingShips[s] : 0,
                existingBuilding: existingBuilding(s)
            }
        })
        .sort(((a, b) => a.shipType.order > b.shipType.order ? 1 : -1));

    if (Object.keys(shipQuantities).length === 0) {
        shipsToBuild.forEach(s => shipQuantities[s.shipType.typeName || ""] = 0);
    }

    return <>
        {shipsToBuild.map(s => <BuildControl
            key={s.shipType.typeName}
            setQuantity={q => setShipQuantity(s.shipType.typeName, q)}
            solarSystem={props.solarSystem}
            installationTypeSettings={props.installationTypeSettings}
            itemTypeSettings={props.itemTypeSettings}
            worldEffects={props.worldEffects}
            quantity={s.shipType.typeName in shipQuantities ? shipQuantities[s.shipType.typeName] : 0}
            build={build}
            {...s}
        />
        )}
    </>;
};