import classNames from "classnames";
import React from "react";
import { IShipTypeSettings, ShipType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { H3 } from "../../Base/Text/H";
import { Paragraph } from "../../Base/Text/Paragraph";
import { ShipTypeImage } from "../Images/ShipTypeImage";
import { ShipTypeLink } from "../Links/ShipTypeLink";

type Props = {
    ships: { [key: string]: number } | undefined,
    title?: string | undefined,
    isOnePerLineOnMobile?: boolean,
    showEmptyText?: boolean
}

type InnerProps = {
    ships: ShipTypeQuantity[] | undefined,
    children?: React.ReactNode,
    isOnePerLineOnMobile?: boolean
}

type ShipTypeQuantity = {
    ship: ShipType,
    quantity: number
}

export const ShipListInner = (props: InnerProps) => {

    if (!props.ships) {
        return null;
    }

    const className = classNames("ship-list", {
        "is-one-per-line-on-mobile": props.isOnePerLineOnMobile
    });

    return <ul className={className}>
        {props.ships.map((q, i) =>
            <li key={i}>
                <ShipTypeImage shipType={q.ship} size="small" />
                <span className="name-and-quantity">
                    <ShipTypeLink shipType={q.ship} />
                    <br className="is-hidden-tablet" />
                    <span className="ship-quantity">
                        x{q.quantity.toLocaleString()}
                    </span>
                </span>
            </li>)}
        {props.children}
    </ul>;
};

export function buildShips(shipTypeSettings: IShipTypeSettings, ships: { [key: string]: number } | undefined): ShipTypeQuantity[] | undefined {
    if (!ships) {
        return undefined;
    }

    const shipsToDisplay = Object.keys(ships)
        .map(s => {
            return {
                ship: shipTypeSettings!.data![s],
                quantity: ships![s]
            }
        })
        .filter(s => s.quantity > 0)
        .sort(((a, b) => a.ship.order > b.ship.order ? 1 : -1));

    return shipsToDisplay;
};

export const ShipList = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!props.ships || !worldState.ShipTypeSettings) {
        return null;
    }

    const shipsToDisplay = buildShips(worldState.ShipTypeSettings, props.ships);

    if (!props.showEmptyText && (!shipsToDisplay || shipsToDisplay.length === 0)) {
        return null;
    }

    return <>
        {!!props.title && props.title.length > 0 &&
            <H3>{props.title}</H3>
        }
        {props.showEmptyText && (shipsToDisplay?.length ?? 0) === 0 && <Paragraph>
            There are no ships to display.
        </Paragraph>}
        <ShipListInner ships={shipsToDisplay} isOnePerLineOnMobile={props.isOnePerLineOnMobile} />
    </>;
};