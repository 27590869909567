export class SvgHelper {

    public static createPathDefinitionForCircle(cx: number, cy: number, r: number): string {
        //return `M (${x} - ${r}), ${y} a ${r},${r} 0 1,0 (${r} * 2),0 a ${r},${r} 0 1,0 -(${r} * 2),0`;
        // this doesnt actually work as expected
        return `M ${cx} ${cy}
        m -${r}, 0
        a ${r},${r} 0 1,0 (${r} * 2),0
        a ${r},${r} 0 1,0 -(${r} * 2),0`;
    }

    public static imageSrcFromSvgData(svgData: string) {
        return "data:image/svg+xml;base64," + btoa(svgData);
    }
}