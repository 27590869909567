import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ServiceWorkerStateContext } from '../../..';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button } from '../../Base/Buttons/Button';
import { MessageBoxWithIcon } from '../../Base/MessageBox';

export const UpdateMessage = observer(() => {

    const serviceWorkerState = React.useContext(ServiceWorkerStateContext);

    const hasUpdate = serviceWorkerState.isUpdateAvailable;//|| (appState.ApiVersion && appState.ApiVersion != buildVersion);

    function update() {
        serviceWorkerState.updateAssets();
        return false;
    }

    if (!hasUpdate || serviceWorkerState.isUpdating) {
        return null;
    }

    return <MessageBoxWithIcon icon={IconHelper.General.Up} type="warning" text={"An update is available. Please update to continue playing."} button={
        <Button type="action" action={update} icon={IconHelper.General.Confirm} text="Update" />
    }>
    </MessageBoxWithIcon>;
});