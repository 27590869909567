import { IItemTypeSettings } from "../../../../ApplicationState/ApiClient";
import { CollectionHelper } from "../../../../Helpers/CollectionHelper";
import { ItemsHelper } from "../../../../Helpers/ItemsHelper";
import { CommoditiesIcon } from "./CommoditiesIcon";
import { ComponentIcon } from "./ComponentIcon";
import { RawResourceIcon } from "./RawResourceIcon";
import { RelicIcon } from "./RelicIcon";
import { ResourceIcon } from "./ResourceIcon";

type Props = {
    items: { [key: string]: number } | undefined
}

type ResourceIconProps = Props & {
    itemTypeSettings: IItemTypeSettings
}

export const ItemsIcons = (props: ResourceIconProps) => {

    if (CollectionHelper.isAnyQuantityInDictionary(props.items)) {

        const items = ItemsHelper.splitItems(props.items!, props.itemTypeSettings.data);

        const commodities = CollectionHelper.sumOfDictionary(items.commodities);
        const rawResource = CollectionHelper.sumOfDictionary(items.rawResource);
        const components = CollectionHelper.sumOfDictionary(items.components);
        const relics = CollectionHelper.sumOfDictionary(items.relics);

        return <>
            {Object.keys(items.resources).map(x => <ResourceIcon key={x} resourceType={props.itemTypeSettings.data[x]} quantityForTitle={items.resources[x]} />)}
            {commodities > 0 && <CommoditiesIcon />}
            {rawResource > 0 && <RawResourceIcon />}
            {components > 0 && <ComponentIcon />}
            {relics > 0 && <RelicIcon />}
        </>;
    }

    return null;
}