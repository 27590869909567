import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { NextButton } from "../../../Components/Base/Buttons/NextButton";
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { StringInput } from '../../../Components/Base/Form/Input';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { StageProps } from './StageRender';

export const LockedStage = observer((props: StageProps) => {

    const [value, setValue] = React.useState(props.joinWorldBag.AdminPassword === undefined ? "" : props.joinWorldBag.AdminPassword);

    React.useEffect(() => {
        props.joinWorldBag.setAdminPassword(value);
    }, [value]);

    return <SubPanel heading="Locked To Admins" footerContent={
        <ButtonHolder isPulledRight>
            <PreviousButton action={props.previousStage} />
            <NextButton action={props.nextStage} />
        </ButtonHolder>
    }>
        <Paragraph>
            This world has been locked to admins and a password is required to join. Please enter the admin password below.
        </Paragraph>
        <FieldHolder label="Password">
            <StringInput type="password" className="is-fullwidth" value={value} valueChanged={setValue} />
        </FieldHolder>
    </SubPanel>;
});