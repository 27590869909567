import { makeAutoObservable } from "mobx";
import { CombatRoundType, CommodityBuyOffer, FleetConfig, FleetMovementType, FleetTrade, GameSettings, ICelestial, ICelestialTypeSettings, IContract, IFleet, IFleetConfig, IFleetOrders, IInstallationTypeSettings, IItemTypeSettings, IPlayerForSelf, IRepeatFleet, IRepeatFromSendInput, IShipTypeSettings, ISolarSystemDetail, ISolarSystemForList, ISolarSystemForObserver, IWorldMinimal, ItemType, MarketOrder, SolarSystemClaimType } from "../../ApplicationState/ApiClient";
import { FleetWrapper } from "../../Entities/FleetWrapper";
import { Coordinate } from "../../Entities/Shared";
import { SolarSystemWrapper } from "../../Entities/SolarSystem/SolarSystemWrapper";
import { valueValid } from "../../Entities/Validation";
import { AgentAvailability, AgentHelper } from "../../Helpers/AgentHelper";
import { CollectionHelper } from "../../Helpers/CollectionHelper";
import { ContractHelper } from "../../Helpers/ContractHelper";
import { FleetHelper } from "../../Helpers/FleetHelper";
import { MathHelper } from "../../Helpers/MathHelper";
import { AllowedConfigurations, SendShipsHelper } from "../../Helpers/SendShipsHelper";
import { TimeHelper } from "../../Helpers/TimeHelper";
import { ItemTypeQuantity } from "./Components/Items/ResourceInputList";
import { ShipTypeQuantity } from "./Components/ShipInputList";

export type SendShipsBagInit = {

    itemTypeSettings: IItemTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,

    gameSettings: GameSettings,
    player: IPlayerForSelf,
    sourceSolarSystem: ISolarSystemDetail,
    solarSystems: ISolarSystemForList[],
    availableShips: FleetWrapper,

    initialTargetCelestialId: number | undefined,

    initialContractId: number | undefined,

    initialFleetMovementType: FleetMovementType | undefined,
    initialMarketOrderId: number | undefined,

    world: IWorldMinimal,

    getAllSolarSystems: () => SolarSystemWrapper[] | undefined,
    switchSolarSystem: (solarSystemId: number) => any
}

export type PostSendShipTarget = "System" | "Send Another" | "Map at Target" | "Empire" | "Repeats";

type Interception = {
    coordinate: Coordinate,
    duration: number,
    time: Date
}

const defaultConfig: IFleetConfig = {
    groundOnArrival: false,
    notifyOnArrival: false
};

const defaultRepeatConfig: IRepeatFromSendInput = {
    onlySendIfAllShipsAvailable: false,
    notifyOnFailure: true,
    notifyOnCompletion: true
}

export class SendShipsBag {

    // Core properties
    public get MovementType() { return this.movementType; }
    private movementType: FleetMovementType | undefined;

    public get HasTarget() { return !!this.TargetSolarSystem || !!this.TargetFleet; }

    public get IsIntercept() { return !!this.TargetFleet; }

    public get TargetSolarSystem() { return this.targetSolarSystem; }
    private targetSolarSystem: ISolarSystemForObserver | undefined = undefined;

    public get TargetCelestialId() { return this.targetCelestialId; }
    private targetCelestialId: number | undefined;

    public get TargetCelestial() { return this.targetCelestial; }
    private targetCelestial: ICelestial | undefined;

    public get TargetFleet() { return this.targetFleet; }
    private targetFleet: IFleet | undefined = undefined;

    public get TargetMarketOrder() { return this.targetMarketOrder; }
    private targetMarketOrder: MarketOrder | undefined = undefined;

    public get TargetFleetInterception() { return this.targetFleetInterception; }
    private targetFleetInterception: Interception | undefined = undefined;

    public get TargetOwner() { return this.TargetSolarSystem ? this.TargetSolarSystem.owner : this.TargetFleet ? this.TargetFleet.owner : undefined; }

    public get Contract() { return this.contract; }
    private contract: IContract | undefined = undefined;

    public get AvailableContractsInTarget() { return this.availableContractsInTarget };
    private availableContractsInTarget: {
        [key in FleetMovementType]?: IContract[];
    } = {}

    // Configuration properties
    public get Config() { return this.config; }
    private config: IFleetConfig;

    public get Orders() { return this.orders; }
    private orders: IFleetOrders = {};

    public shipQuantities: { [key: string]: number; } = {};
    public itemsToSend: { [key: string]: number; } = {};
    public itemsToCollect: { [key: string]: number; } = {};

    public agentIdsToSend: number[] = [];
    public agentIdsToCollect: number[] = [];

    // Informative properties
    public get CommodityBuyOffers() { return this.commodityBuyOffers; }
    private commodityBuyOffers: { [key: string]: CommodityBuyOffer } | undefined;

    public availableItemsInSource: { itemType: ItemType; quantity: number; }[] = [];
    public availableItemsInTarget: { itemType: ItemType; quantity: number; }[] | undefined = undefined;

    public fleetWrapper: FleetWrapper;

    public totalToSendCargo: number = 0;
    public totalToCollectCargo: number = 0;

    public availableAgentsInSource: AgentAvailability[] = [];
    public availableAgentsInTarget: AgentAvailability[] = [];

    // UI and Status properties
    public validation = valueValid(false);

    public movementTypeOptions: FleetMovementType[] = [];

    public showCelestialSelection: boolean = false;
    public allowedConfigurations: AllowedConfigurations;

    public repeat: boolean = false;

    public get IsEditingRepeatFleet() { return this.editingRepeatFleetId !== undefined; }

    public editingRepeatFleetId: number | undefined;

    public get RepeatConfiguration() { return this.repeatConfiguration; }
    private repeatConfiguration: IRepeatFromSendInput;

    init: SendShipsBagInit;

    constructor(initialOptions: SendShipsBagInit) {

        this.init = initialOptions;

        this.targetCelestialId = this.init.initialTargetCelestialId;
        this.movementType = this.init.initialFleetMovementType;

        this.config = new FleetConfig(defaultConfig);
        this.repeatConfiguration = defaultRepeatConfig;

        this.allowedConfigurations = this.movementType ? SendShipsHelper.allowedConfigurationsForMovementType(this.IsIntercept, this.movementType, undefined) :
            {
                tactics: false,
                itemsToSend: false,
                commoditiesToSend: false,
                itemsToCollect: false,
                trades: false,
                repeat: false,
                agentsToSend: false,
                agentsToCollect: false
            };

        this.fleetWrapper = new FleetWrapper(this.init.shipTypeSettings, this.init.gameSettings, {});

        this.orders = {
            tactics: SendShipsHelper.defaultTactics(this.init.availableShips)
        };

        this.validate();

        if (process.env.NODE_ENV !== "production") {
            console.log(this);
        }

        makeAutoObservable(this);
    }

    public reInit(initialOptions: SendShipsBagInit) {
        // Only call validate again
        // Don't load any defaults from the init object because the previous state may be overwritten
        this.init = initialOptions;
        this.validate();
    }

    private checkQuantitiesInitialized() {
        if (Object.keys(this.shipQuantities).length === 0) {
            this.init.availableShips.Ships.forEach(s => this.shipQuantities[s.ship.typeName || ""] = 0);
        }

        this.availableItemsInSource = this.buildAvailableItems(this.init.sourceSolarSystem.items, this.itemsToSend);

        if (this.targetSolarSystem) {
            this.availableItemsInTarget = this.buildAvailableItems(this.targetSolarSystem.items, this.itemsToCollect);
        }
    }

    private buildAvailableItems(items: { [key: string]: number } | undefined, target: { [key: string]: number }): { itemType: ItemType; quantity: number }[] {
        const builtResources = !items ? [] : Object.keys(items)
            .filter(s => items![s] > 0)
            .map(s => {
                return {
                    itemType: this.init.itemTypeSettings.data![s],
                    quantity: items![s]
                };
            });

        if (Object.keys(builtResources).length === 0) {
            builtResources.forEach(s => target[s.itemType.typeName || ""] = 0);
        }

        return builtResources;
    }

    private calculateAvailableContracts(solarSystem: ISolarSystemForObserver | undefined) {
        if (solarSystem === undefined) {
            return {}
        }

        const val = {};

        for (const contract of solarSystem.contracts) {
            const movementType = ContractHelper.fleetMovementTypeForContract(contract);

            if (movementType !== undefined && ContractHelper.canAccept(contract, this.init.player)) {

                if (!(movementType in val) || val[movementType] === undefined) {
                    val[movementType] = [];
                }

                val[movementType].push(contract);
            }
        }

        return val;
    }

    public validate() {

        this.availableAgentsInSource = AgentHelper.agentAvailability(this.init.sourceSolarSystem, this.init.player.playerId);
        this.availableAgentsInTarget = AgentHelper.agentAvailability(this.targetSolarSystem, this.init.player.playerId);

        this.availableContractsInTarget = this.calculateAvailableContracts(this.targetSolarSystem);

        this.checkQuantitiesInitialized();

        this.fleetWrapper = new FleetWrapper(this.init.shipTypeSettings, this.init.gameSettings, this.shipQuantities);

        this.movementTypeOptions = FleetHelper.allowedMovementTypes(this.init.sourceSolarSystem, this.init.availableShips, this.TargetSolarSystem, this.TargetFleet, this.init.player, this.contract, this.IsEditingRepeatFleet);

        if (!this.MovementType || !this.movementTypeOptions.includes(this.MovementType)) {

            if (this.init.initialFleetMovementType && this.movementTypeOptions.includes(this.init.initialFleetMovementType)) {
                this.movementType = this.init.initialFleetMovementType;
            } else {
                this.movementType = undefined;
            }

            if (this.movementType !== undefined) {
                this.allowedConfigurations = SendShipsHelper.allowedConfigurationsForMovementType(this.IsIntercept, this.movementType, this.contract?.type);
            }
        }

        this.commodityBuyOffers = this.movementType === FleetMovementType.CommoditySell &&
            this.targetCelestialId &&
            this.targetSolarSystem &&
            this.targetSolarSystem.commodityBuyOffersByCelestialId &&
            this.targetCelestialId in this.targetSolarSystem.commodityBuyOffersByCelestialId ?
            this.targetSolarSystem.commodityBuyOffersByCelestialId[this.targetCelestialId] : undefined;

        if (this.movementType === FleetMovementType.CommoditySell) {
            this.setTargetCelestialIdFromCommodityBuyOffers();
        }

        if (this.repeatConfiguration.delayHours !== undefined) {
            if (this.repeatConfiguration.delayHours < this.init.gameSettings.fleets.repeatDelayHoursMinimum) {
                this.repeatConfiguration.delayHours = this.init.gameSettings.fleets.repeatDelayHoursMinimum;
            } else if (this.repeatConfiguration.delayHours > this.init.gameSettings.fleets.repeatDelayHoursMaximum) {
                this.repeatConfiguration.delayHours = this.init.gameSettings.fleets.repeatDelayHoursMaximum;
            }
        }

        if (!this.allowedConfigurations.repeat && this.editingRepeatFleetId === undefined) {
            this.repeat = false;
        }
        if (!this.allowedConfigurations.agentsToCollect) {
            this.agentIdsToCollect = [];
        }
        if (!this.allowedConfigurations.agentsToSend) {
            this.agentIdsToSend = [];
        }
        if (!this.allowedConfigurations.itemsToSend && !this.allowedConfigurations.commoditiesToSend) {
            this.itemsToSend = {};
        }
        if (!this.allowedConfigurations.itemsToCollect) {
            this.itemsToCollect = {};
        }

        if (this.movementType === FleetMovementType.Claim) {
            if (this.orders.claimType === undefined) {
                this.orders = {
                    ...this.orders,
                    claimType: SolarSystemClaimType.Colony
                }
            }
        }

        this.targetCelestial = this.targetSolarSystem?.celestials.find(x => x.celestialId === this.targetCelestialId);
        this.totalToSendCargo = CollectionHelper.sumOfDictionary(this.itemsToSend);
        this.totalToCollectCargo = CollectionHelper.sumOfDictionary(this.itemsToCollect);

        this.recalculateInterceptionPoint();

        this.validation = SendShipsHelper.validate(this);

        if (SendShipsHelper.alwaysShowCelestialFor(this.movementType)) {
            this.showCelestialSelection = true;
        }
    }

    public setRepeatConfiguration(modify: (previous: IRepeatFromSendInput) => IRepeatFromSendInput) {

        this.repeatConfiguration = modify(this.repeatConfiguration);

        this.validate();
    }

    public setRepeat(repeat: boolean, repeatDelayHours: number, repeatNumberOfTimes: number | undefined) {
        this.repeat = repeat;

        this.setRepeatConfiguration((previous) => {
            return {
                ...previous,
                delayHours: repeatDelayHours,
                numberOfTimes: repeatNumberOfTimes
            }
        });
    }

    public clearTargets() {
        this.setTargetCelestialId(undefined);
        this.setTargetSolarSystem(undefined);
        this.setTargetFleet(undefined);
    }

    public setTargetCelestialId(celestialId: number | undefined) {
        this.targetCelestialId = celestialId;
        this.validate();
    }

    public setTargetSolarSystem(solarSystem: ISolarSystemForObserver | undefined) {

        if (this.targetSolarSystem !== undefined && solarSystem !== undefined && this.targetSolarSystem.solarSystemId === solarSystem.solarSystemId) {
            return;
        }

        this.targetFleet = undefined;
        this.targetSolarSystem = solarSystem;
        if (this.init.initialMarketOrderId !== undefined) {
            this.targetMarketOrder = this.targetSolarSystem?.marketOrders.find(m => m.marketOrderId === this.init.initialMarketOrderId);
        }

        this.validate();
    }

    public setContract(contract: IContract | undefined) {
        this.contract = contract;

        this.validate();
    }

    public setTargetFleet(fleet: IFleet | undefined) {
        this.targetSolarSystem = undefined;
        this.targetCelestialId = undefined;
        this.targetFleet = fleet;
        this.validate();
    }

    public setTargetMarketOrder(marketOrder: MarketOrder) {
        this.targetMarketOrder = marketOrder;
    }

    public setTrade(trade: FleetTrade) {
        this.modifyOrders(o => {
            return {
                ...o,
                trade
            }
        });
    }

    public modifyOrders(modify: (orders: IFleetOrders) => IFleetOrders) {
        this.orders = modify(this.orders);
        this.validate();
    }

    public setItemQuantityToSend(item: ItemTypeQuantity, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }
        if (quantity > item.quantity) {
            quantity = item.quantity;
        }

        this.itemsToSend[item.itemType.typeName || ""] = quantity;
        this.itemsToSend = this.itemsToSend;

        this.validate();
    }

    public setItemQuantityToCollect(item: ItemTypeQuantity, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }
        if (quantity > item.quantity) {
            quantity = item.quantity;
        }
        this.itemsToCollect[item.itemType.typeName || ""] = quantity;
        this.itemsToCollect = this.itemsToCollect;
        this.validate();
    }

    public clearQuantities() {
        this.shipQuantities = {};
        this.itemsToSend = {};
        this.itemsToCollect = {};

        this.validate();
    }

    private setTargetCelestialIdFromCommodityBuyOffers() {
        if (this.targetCelestialId === undefined &&
            this.targetSolarSystem &&
            CollectionHelper.isAnyInDictionary(this.targetSolarSystem.commodityBuyOffersByCelestialId)) {
            this.targetCelestialId = Number(Object.keys(this.targetSolarSystem.commodityBuyOffersByCelestialId!)[0]);
        }
    }

    public setMovementType(movementType: FleetMovementType | undefined) {
        this.movementType = movementType;
        this.allowedConfigurations = SendShipsHelper.allowedConfigurationsForMovementType(this.IsIntercept, this.movementType, this.contract?.type);

        if (!this.allowedConfigurations.agentsToSend) {
            this.agentIdsToSend = [];
        }

        this.validate();
    }

    public setShipQuantity(ship: ShipTypeQuantity, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }
        if (!this.IsEditingRepeatFleet && quantity > ship.quantity) {
            quantity = ship.quantity;
        }

        this.shipQuantities[ship.ship.typeName || ""] = quantity;
        this.shipQuantities = this.shipQuantities;
        this.validate();

        for (let s of Object.keys(this.shipQuantities)) {
            if (this.shipQuantities[s] > 0 && this.init.shipTypeSettings.data![s].siegeDamage > 0) {
                this.showCelestialSelection = true;;
                return;
            }
        }

        this.showCelestialSelection = SendShipsHelper.alwaysShowCelestialFor(this.movementType);
    }


    public setShipTactic(shipTypeName: string, tactic: CombatRoundType) {
        if (!this.orders.tactics) {
            return;
        }
        if (shipTypeName in this.orders.tactics) {
            this.orders.tactics[shipTypeName] = tactic;
        }

        const tact: { [key: string]: CombatRoundType } = {};

        for (let k of Object.keys(this.orders.tactics)) {
            tact[k] = shipTypeName === k ? tactic : this.orders.tactics[k];
        }

        this.modifyOrders(o => {
            return {
                ...o,
                tactics: tact
            }
        });
    }

    public setAgentIdToSend(agentId: number, send: boolean) {

        const agentIds = !send ? this.agentIdsToSend.filter(x => x != agentId) : this.agentIdsToSend;

        if (send && !agentIds.includes(agentId)) {
            agentIds.push(agentId);
        }

        this.agentIdsToSend = agentIds;

        this.validate();
    }

    public setAgentIdToCollect(agentId: number, send: boolean) {

        const agentIds = !send ? this.agentIdsToCollect.filter(x => x != agentId) : this.agentIdsToCollect;

        if (send && !agentIds.includes(agentId)) {
            agentIds.push(agentId);
        }

        this.agentIdsToCollect = agentIds;

        this.validate();
    }

    public setConfig(setter: (config: IFleetConfig) => IFleetConfig) {

        const configObjs: IFleetConfig = {
            ...this.config
        };

        this.config = setter(configObjs);
    }

    private recalculateInterceptionPoint() {
        this.targetFleetInterception = undefined;

        if (this.targetFleet !== undefined &&
            this.targetFleet.currentX !== undefined && this.targetFleet.currentY !== undefined &&
            this.targetFleet.perSecondX !== undefined && this.targetFleet.perSecondY !== undefined &&
            this.fleetWrapper.SolarSystemsPerHour > 0) {

            const interceptorSpeed = ((this.fleetWrapper.SolarSystemsPerHour / 60) / 60) * this.init.gameSettings.gameSpeed;

            const targetPosition = {
                x: this.targetFleet.currentX,
                y: this.targetFleet.currentY
            };
            const targetVelocity = {
                x: this.targetFleet.perSecondX,
                y: this.targetFleet.perSecondY
            };

            const interceptPosition = MathHelper.calculateInterceptionPoint(targetPosition, targetVelocity, this.init.sourceSolarSystem, interceptorSpeed);

            if (interceptPosition) {

                const interceptDistance = FleetHelper.distance(this.init.sourceSolarSystem, interceptPosition);
                const interceptDuration = interceptDistance / interceptorSpeed;

                const now = new Date();
                const interceptTime = new Date(now.getTime() + (interceptDuration * 1000));

                this.targetFleetInterception = {
                    coordinate: interceptPosition,
                    duration: interceptDuration,
                    time: interceptTime
                };
            }
        }
    }

    public timeToTarget() {
        if (!this.fleetWrapper.HasAnyShips) {
            return undefined;
        }

        const target = this.TargetSolarSystem ? this.TargetSolarSystem : this.TargetFleetInterception ? this.TargetFleetInterception.coordinate : undefined;
        const durationHours = FleetHelper.estimateHoursJourneyDuration(this.fleetWrapper.SolarSystemsPerHour, this.init.sourceSolarSystem, target, this.init.gameSettings);

        if (durationHours) {

            const date = new Date();
            date.setTime(date.getTime() + (durationHours * 60 * 60 * 1000));

            return { date, durationHours };
        }

        return undefined;
    }

    public timeToMineCargoToFull() {
        const perHour = TimeHelper.tickValueToPerHour(this.init.gameSettings, this.fleetWrapper.TotalMiningStrength);
        const cargo = this.fleetWrapper.TotalCargoCapacity;
        return perHour > 0 && cargo > 0 ? cargo / perHour : undefined;
    }

    public hasCivilianCasualties() {
        if (!this.TargetSolarSystem || this.MovementType !== FleetMovementType.Attack) {
            return false;
        }

        const targetCelestial = this.TargetCelestialId && this.TargetSolarSystem ? this.TargetSolarSystem.celestials.find(c => c.celestialId === this.TargetCelestialId) : undefined;

        // If there is a celestial target and the system is unexplored then it could lead to civilian casualties
        if (targetCelestial !== undefined && !this.TargetSolarSystem.isExplored) {
            return this.fleetWrapper.TotalSiegeDamage > 0 && this.TargetSolarSystem.population > 0;
        }

        if (!targetCelestial || targetCelestial.population <= 0) {
            return false;
        }

        return this.fleetWrapper.TotalSiegeDamage > 0;
    }

    public editRepeatFleet(repeatFleet: IRepeatFleet, targetSolarSystem: ISolarSystemForObserver, contract: IContract | undefined) {

        this.editingRepeatFleetId = repeatFleet.repeatFleetId;
        this.repeat = true;
        this.repeatConfiguration = {
            ...repeatFleet.config,
            delayHours: repeatFleet.delayHours,
            numberOfTimes: repeatFleet.timesRemaining
        }

        this.itemsToCollect = repeatFleet.collection?.items ?? {};
        this.itemsToSend = repeatFleet.itemsToSend ?? {};
        this.movementType = repeatFleet.movementType;
        this.shipQuantities = repeatFleet.ships ?? {};
        this.orders = repeatFleet.orders;
        this.targetCelestialId = repeatFleet.targetCelestialId;
        this.targetSolarSystem = targetSolarSystem;
        this.contract = contract;
        this.allowedConfigurations = SendShipsHelper.allowedConfigurationsForMovementType(false, repeatFleet.movementType, contract?.type);
        this.setConfig(c => {
            return {
                ...c,
                ...repeatFleet.config,
            };
        });

        this.validate();
    }

    public swapSourceAndTarget() {

        const canSwitch = this.TargetSolarSystem !== undefined && this.TargetSolarSystem.playerId === this.init.player.playerId;

        if (canSwitch) {

            const ownSolarSystem = this.TargetSolarSystem as ISolarSystemDetail;

            if (ownSolarSystem !== undefined) {

                const oldSourceSolarSystem = this.init.sourceSolarSystem;

                this.init.switchSolarSystem(ownSolarSystem.solarSystemId);

                const newInit: SendShipsBagInit = {
                    ...this.init,
                    sourceSolarSystem: ownSolarSystem
                };

                this.reInit(newInit);
                this.setTargetSolarSystem(oldSourceSolarSystem);
            }
        }
    }
}