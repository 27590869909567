import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DefendTerms, ISolarSystem, ITerms, ValidationResult } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { DateTimeInput } from '../../../Components/Base/Form/DateTimeInput';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { ValidationHelper } from '../../../Components/Base/Form/ValidationHelper';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { Configuration } from '../CreateContractView';
import { ChooseSolarSystem } from './ChooseSolarSystem';

type Props = {
    terms: DefendTerms,
    configuration: Configuration,
    setTermsAndConfigure: (terms: ITerms, configuration: Configuration) => any,
    setValidation: (validation: ValidationResult | undefined) => any,
}

export const DefendForm = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const [solarSystem, setSolarSystem] = React.useState<ISolarSystem | undefined>(undefined);
    const [arriveByDate, setArriveByDate] = React.useState(new Date());
    const [untilDate, setUntilDate] = React.useState(new Date());
    const [paymentPerDefencePower, setPaymentPerDefencePower] = React.useState(0);
    const [defencePowerRequired, setDefencePowerRequired] = React.useState(0);

    React.useEffect(() => {
        props.setValidation(validate());

        const terms = createTerms();

        props.setTermsAndConfigure(terms, {
            ...props.configuration,
            targetSolarSystemId: solarSystem?.solarSystemId
        });
    }, [arriveByDate, untilDate, paymentPerDefencePower, defencePowerRequired, solarSystem]);

    function createTerms() {
        return DefendTerms.fromJS({
            arriveByDate,
            untilDate,
            paymentPerDefencePower,
            defencePowerRequired,
            type: "DefendTerms"
        });
    }

    function clearTarget() {
        props.setTermsAndConfigure(createTerms(), {
            ...props.configuration,
            targetSolarSystemId: undefined
        })
        setSolarSystem(undefined);
    }

    function validate() {
        if (solarSystem === undefined) {
            return ValidationHelper.invalid("Pick a valid target solar system");
        }
        if (arriveByDate <= new Date()) {
            return ValidationHelper.invalid("Choose an arrival date in the future");
        }
        if (untilDate <= new Date()) {
            return ValidationHelper.invalid("Choose an until date in the future");
        }
        if (untilDate <= arriveByDate) {
            return ValidationHelper.invalid("Until date can't be before arrival date");
        }
    }

    if (!playerState.Player) {
        return <LoadingSpinner />;
    }

    return <>
        <ChooseSolarSystem
            initialSolarSystemId={props.configuration.targetSolarSystemId}
            clearTarget={clearTarget}
            solarSystemChanged={setSolarSystem}
            solarSystem={solarSystem}
            showOwnSystems
        />
        <SplitLayout
            left={
                <FieldHolder label="Payment per defence">
                    <NumberInput value={paymentPerDefencePower} valueChanged={setPaymentPerDefencePower} precision={1} />
                </FieldHolder>
            }
            right={
                <FieldHolder label="Defence power required">
                    <NumberInput value={defencePowerRequired} valueChanged={setDefencePowerRequired} />
                </FieldHolder>
            }
        />
        <SplitLayout
            left={
                <FieldHolder label="Arrive by date">
                    <DateTimeInput value={arriveByDate} valueChanged={setArriveByDate} />
                </FieldHolder>
            }
            right={
                <FieldHolder label="Until date">
                    <DateTimeInput value={untilDate} valueChanged={setUntilDate} />
                </FieldHolder>
            }
        />
    </>;
});