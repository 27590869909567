import { FederationRank } from "../../ApplicationState/ApiClient";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class FederationIcons {

    public get Federation() { return MaterialDesignIcons.shield; }
    public get Leave() { return MaterialDesignIcons.exit; }
    public get Kick() { return MaterialDesignIcons.karate; }
    public get Invite() { return MaterialDesignIcons.accountStar; };
    public get Promote() { return MaterialDesignIcons.arrowUp; };
    public get Demote() { return MaterialDesignIcons.arrowDown; };
    public get Disband() { return MaterialDesignIcons.delete; };

    public get Leader() { return MaterialDesignIcons.chessKing; };
    public get Officer() { return MaterialDesignIcons.chessRook; };
    public get Member() { return MaterialDesignIcons.account; };

    public get ResearchPoint() { return MaterialDesignIcons.flask; };

    public rank(federationRank: FederationRank) {

        switch (federationRank) {
            case FederationRank.Leader:
                return this.Leader;
            case FederationRank.Officer:
                return this.Officer;
        }

        return undefined;
    }
}