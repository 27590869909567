import { observer } from "mobx-react-lite";
import * as React from "react";
import { CelestialSize, GameSettings, IInstallationTypeSettings, IItemTypeSettings, InstallationType, ISolarSystemDetail } from "../../../../ApplicationState/ApiClient";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { SplitLayout } from "../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { Select } from "../../../../Components/Base/Form/Select";
import { InstallationTypeDescription } from "../../../../Components/FusionShift/Descriptions/InstallationTypeDescription";
import { CostControl } from "../../../../Components/FusionShift/Items/CostControl";
import { TypeDataVoice } from "../../../../Components/FusionShift/TypeDataVoice";
import { BuildHelper } from "../../../../Helpers/BuildHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { BuildAtCelestialControl } from "./BuildAtCelestialControl";
import { InstallationCostLevelTable } from "./InstallationCostLevelTable";

type Props = {
    solarSystem: ISolarSystemDetail | undefined
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    gameSettings: GameSettings,
    installationType: InstallationType,
    factionTypeName: string
}

export const InstallationTypeControl = observer((props: Props) => {

    const [level, setLevel] = React.useState(1);
    const [isShowingAllCosts, setIsShowingAllCosts] = React.useState(false);

    const effectiveMaxLevel = BuildHelper.effectiveMaxLevel(props.installationType, CelestialSize.Giant);

    const maxLevel = BuildHelper.effectiveMaxLevel(props.installationType, CelestialSize.Giant);
    const levels: any[] = [];

    for (let i = 0; i < maxLevel; i++) {
        levels.push({
            label: `Level ${i + 1}`,
            value: i + 1
        });
    }

    return <SubPanel
        id={props.installationType.typeName}
        heading={props.installationType.name}
        headingContent={<TypeDataVoice type="InstallationType" typeData={props.installationType} />}>
        <InstallationTypeDescription
            celestialSize={CelestialSize.Giant}
            {...props}
            level={level}
            operationLevel={level}
            shipBuildDisplay={"all"}
            imageAsMaxLevel
            preStatsContent={
                levels.length > 1 && <div>
                    <Select value={level} valueChanged={e => setLevel(Number(e))} values={levels} />
                </div>
            }
        />
        <hr />
        <div className="is-clearfix">
            {!isShowingAllCosts && <SplitLayout
                left={
                    <SubPanel
                        heading={level === 1 ? "Initial Cost" : `Cost to Level ${level}`}
                        footerContent={effectiveMaxLevel >= 2 &&
                            <Button type="nav" text="Show All Costs" icon={IconHelper.General.Plus} action={() => setIsShowingAllCosts(true)} isPulledRight />
                        }
                    >
                        <CostControl cost={BuildHelper.costForLevelWithoutActiveEffects(props.installationTypeSettings, props.installationType.typeName, level, props.gameSettings, CelestialSize.Giant)!} />
                    </SubPanel>
                }
                right={
                    <BuildAtCelestialControl {...props} />
                }
            />}
            {isShowingAllCosts &&
                <InstallationCostLevelTable key={props.installationType.typeName} {...props} closeCallback={() => setIsShowingAllCosts(false)} />
            }
        </div>
    </SubPanel>;
});