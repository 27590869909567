import { notificationsImageConfig } from "../images/NotificationsImageConfig";

export class NotificationHelper {

    public static imageUrl(notification: { playerNotificationId: number, image: string }) {
        const image = notification.image;

        if (image === null || image === undefined || image.length === 0) {
            return undefined;
        }

        const parts = image.split("_");

        let variants: number | undefined = undefined;
        let validPath: string | undefined;

        let currentPath: string | undefined;
        let current = notificationsImageConfig;

        for (const part of parts) {

            if ("Children" in current && part in current.Children) {
                current = current.Children[part];
                currentPath = currentPath === undefined ? part : `${currentPath}_${part}`;

                if ("Variants" in current) {
                    variants = current.Variants as number;
                    validPath = currentPath
                }

            } else {
                break;
            }
        }

        if (variants !== undefined && validPath !== undefined) {
            let variant = notification.playerNotificationId;

            while (variant >= variants) {
                variant -= variants;
            }

            return `${validPath}_${variant}`;
        }

        return undefined;
    }
}