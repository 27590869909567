import * as React from 'react';
import { FleetMovementType, ShipClass, SolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { SendShipsBetweenSystemButtons } from '../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper, SystemMiner } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { EditRepeatMinersForm } from '../../Fleets/Forms/EditMinersForm';
import { EmpireViewProps } from '../EmpireViewProps';

type RowProps = {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem: SolarSystemWrapper,
    t1MiningShipTypeNames: string[],
    t2MiningShipTypeNames: string[]
}

const movementTypes = [
    FleetMovementType.Rebase
];

type CellProps = {
    hasT2: boolean,
    t1MiningShipTypeNames: string[],
    t2MiningShipTypeNames: string[],
    data: { [key: string]: SystemMiner },
    accessor: (data: SystemMiner) => number
}

const Cell = (props: CellProps) => {

    let t1 = 0;
    let t2 = 0;

    for (const key of props.t1MiningShipTypeNames) {
        if (key in props.data) {
            t1 = props.accessor(props.data[key]);
            break;
        }

        if (props.hasT2) {
            for (const key of props.t2MiningShipTypeNames) {
                if (key in props.data) {
                    t2 = props.accessor(props.data[key]);
                    break;
                }
            }
        }
    }


    if (props.hasT2) {
        return <>{t1} / {t2}</>
    }

    return <>{t1}</>
}

const Row = (props: RowProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);
    const hasT2 = Object.keys(props.solarSystem.systemMiners).find(x => props.t2MiningShipTypeNames.includes(x)) !== undefined;

    return <>
        <tr>
            <td>
                <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
            </td>
            <td>
                <Cell
                    hasT2={hasT2}
                    data={props.solarSystem.systemMiners}
                    {...props}
                    accessor={d => d.has}
                />
            </td>
            <td>
                <Cell
                    hasT2={hasT2}
                    data={props.solarSystem.systemMiners}
                    {...props}
                    accessor={d => d.rebasing}
                />
            </td>
            <td>
                <Cell
                    hasT2={hasT2}
                    data={props.solarSystem.systemMiners}
                    {...props}
                    accessor={d => d.needs}
                />
            </td>
            <td>
                <Cell
                    hasT2={hasT2}
                    data={props.solarSystem.systemMiners}
                    {...props}
                    accessor={d => -(d.needs - (d.has + d.rebasing))}
                />
            </td>
            <td className="is-double-button">
                <ButtonHolder>
                    {props.solarSystem.solarSystem.repeatFleets?.find(x => x.movementType === FleetMovementType.Mine) !== undefined &&
                        <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                    }
                    <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem.solarSystem} movementTypes={movementTypes} />
                </ButtonHolder>
            </td>
        </tr>
        {isExpanded && <tr>
            <td colSpan={6}>
                <EditRepeatMinersForm
                    wrapper={props.solarSystem}
                    solarSystem={props.solarSystem.solarSystem}
                    fleets={props.solarSystem.solarSystem.repeatFleets?.filter(x => x.movementType === FleetMovementType.Mine) ?? []}
                    cancelCallback={() => setIsExpanded(false)}
                    reloadCallback={props.reloadCallback}
                />
            </td>
        </tr>}
    </>;
};

export const Miners = (props: EmpireViewProps) => {

    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    const t1Miners = Object.values(props.shipTypeSettings.data).filter(x => x.class === ShipClass.MiningBarge).map(x => x.typeName);
    const t2Miners = Object.values(props.shipTypeSettings.data).filter(x => x.class === ShipClass.AdvancedMiningBarge).map(x => x.typeName);

    const hasT2 = props.solarSystems.map(x => Object.keys(x.systemMiners).find(x => t2Miners.includes(x)) !== undefined).find(x => x) !== undefined;

    const totals: { [key: string]: SystemMiner } = {};

    for (const key of [...t1Miners, ...t2Miners]) {

        let total: SystemMiner = {
            has: 0,
            needs: 0,
            rebasing: 0
        }

        for (const solarSystem of props.solarSystems) {
            if (key in solarSystem.systemMiners) {
                const system = solarSystem.systemMiners[key];

                total = {
                    has: total.has + system.has,
                    needs: total.needs + system.needs,
                    rebasing: total.rebasing + system.rebasing
                }
            }
        }

        if (total.has !== 0 || total.needs !== 0 || total.rebasing !== 0) {
            totals[key] = total;
        }
    }

    return <>
        <Table
            isHoverable
            isFullWidth
            isFixed
            heading={
                <>
                    <th>
                    </th>
                    <th>
                        Has
                    </th>
                    <th>
                        Rebasing
                    </th>
                    <th>
                        Needs
                    </th>
                    <th>
                        Balance
                    </th>
                    <th></th>
                </>
            }>
            {props.solarSystems.map(s => <Row
                key={s.solarSystem.solarSystemId}
                solarSystem={s}
                t1MiningShipTypeNames={t1Miners}
                t2MiningShipTypeNames={t2Miners}
                sourceSolarSystemId={sourceSolarSystemId}
                setSourceSolarSystemId={setSourceSolarSystemId}
                showSendButtons={props.solarSystems.length > 1}
                reloadCallback={props.reloadCallback}
            />)}
            <tr className="is-total">
                <td>Total</td>
                <td>
                    <Cell
                        hasT2={hasT2}
                        data={totals}
                        t1MiningShipTypeNames={t1Miners}
                        t2MiningShipTypeNames={t2Miners}
                        {...props}
                        accessor={d => d.has}
                    />
                </td>
                <td>
                    <Cell
                        hasT2={hasT2}
                        data={totals}
                        t1MiningShipTypeNames={t1Miners}
                        t2MiningShipTypeNames={t2Miners}
                        {...props}
                        accessor={d => d.rebasing}
                    />
                </td>
                <td>
                    <Cell
                        hasT2={hasT2}
                        data={totals}
                        t1MiningShipTypeNames={t1Miners}
                        t2MiningShipTypeNames={t2Miners}
                        {...props}
                        accessor={d => d.needs}
                    />
                </td>
                <td>
                    <Cell
                        hasT2={hasT2}
                        data={totals}
                        t1MiningShipTypeNames={t1Miners}
                        t2MiningShipTypeNames={t2Miners}
                        {...props}
                        accessor={d => -(d.needs - (d.has + d.rebasing))}
                    />
                </td>
                <td />
            </tr>
        </Table>
    </>;
};

