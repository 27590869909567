import React from "react";
import { Button, ButtonAction } from "../Buttons/Button";
import { ButtonHolder } from "../Buttons/ButtonHolder";

type PagedProps = {
    pageIndex: number;
    pageCount: number;
    countPerPage: number;
    onLinkClicked: (pageIndex: number) => void | Promise<void>;
}

export const PagedLinks = (props: PagedProps) => {

    if (props.pageCount <= 1) {
        return null;
    }

    const buttons: { text: string, action: ButtonAction, isDisabled?: boolean, isSelected?: boolean }[] = [];

    buttons.push({ text: "<<", action: () => props.onLinkClicked(0), isDisabled: props.pageIndex === 0 });
    buttons.push({ text: "<", action: () => props.onLinkClicked(props.pageIndex - 1), isDisabled: props.pageIndex === 0 });

    for (let i = 0; i < props.pageCount; i++) {
        buttons.push({ text: `${i + 1}`, action: () => props.onLinkClicked(i), isSelected: i === props.pageIndex });
    }

    buttons.push({ text: ">", action: () => props.onLinkClicked(props.pageIndex + 1), isDisabled: props.pageIndex === props.pageCount - 1 });
    buttons.push({ text: ">>", action: () => props.onLinkClicked(props.pageCount - 1), isDisabled: props.pageIndex === props.pageCount - 1 });

    return <div className="is-pulled-right">
        <ButtonHolder isPulledRight>
            {buttons.map((x, i) => <Button key={i} {...x} type="nav" />)}
        </ButtonHolder>
        <br />
        <span className="is-pulled-right">Page {props.pageIndex + 1} of {props.pageCount}. {props.countPerPage} per page.</span>
    </div>
}