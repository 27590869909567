import * as React from 'react';
import { IFederationDetail, IPlayer } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { PlayerButton } from './PlayerButton';
import { PlayerList } from './PlayerList';

type Props = {
    federation: IFederationDetail,
    reloadCallback: (federation: IFederationDetail) => any
}

export const InviteList = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    function withdrawInvitation(playerId: number) {
        return apiState.FederationClient.withdraw(props.federation.federationId, playerId).then(federation => {
            if (federation) {
                props.reloadCallback(federation);
            }
        });
    }

    function withdrawInvitationControl(player: IPlayer) {
        return <PlayerButton
            player={player}
            type={"danger"}
            text={"Withdraw"}
            icon={IconHelper.General.Delete}
            confirm={"withdraw the invitation to"}
            action={withdrawInvitation}
        />;
    }

    return <PlayerList
        federation={props.federation}
        controls={withdrawInvitationControl}
        heading={{ text: "Pending Invitations", icon: IconHelper.Federations.Invite }}
        players={props.federation.pendingInvitedPlayers}
        showRank={false}
        showControlsForOfficers
    />;
};