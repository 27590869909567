import * as React from "react";
import { IJoinWorldInput } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { ErrorMessageBox } from "../../Components/Base/MessageBox";
import { IconHelper } from "../../Helpers/IconHelper";
import { home } from "../../Navigation/Routing/AppRouteValues";
import { SimpleBaseView } from "../BaseView";
import { JoinWorldBag } from "./JoinWorld/JoinWorldBag";
import { StageRender } from "./JoinWorld/StageRender";
import { JoinWorldStage, StagesControl } from "./JoinWorld/StagesControl";

export const JoinWorldView = () => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [joinWorldStage, setJoinWorldStage] = React.useState<JoinWorldStage>("World");
    const [joinWorldBag, setJoinWorldBag] = React.useState<JoinWorldBag | undefined>(undefined);

    React.useEffect(() => {
        if (joinWorldBag === undefined) {
            apiState.AuthenticationClient.worlds().then(worlds => {
                const joinWorldBag = new JoinWorldBag(worlds);
                setJoinWorldBag(joinWorldBag);
            });
        }
    }, []);

    function startPlaying() {
        if (!joinWorldBag || !joinWorldBag.World || !joinWorldBag.IsValid) {
            return;
        }

        const quadrant = joinWorldBag.StartLocation !== "byFriend" && joinWorldBag.StartLocation !== "random" ? joinWorldBag.StartLocation : undefined;

        const modelData: IJoinWorldInput = {
            registrationPassword: joinWorldBag.AdminPassword === undefined ? "" : joinWorldBag.AdminPassword,
            factionTypeName: joinWorldBag.FactionTypeName!,
            worldId: joinWorldBag.World.worldId,
            worldQuadrant: quadrant,
            startByFriend: joinWorldBag.StartLocation === "byFriend",
            friendInviteId: joinWorldBag.InvitationId,
        };

        return appState.joinWorld(modelData, error => joinWorldBag.setError(error ?? ValidationHelper.invalid("An error occured while trying to join the world")));
    }

    if (!joinWorldBag) {
        return <LoadingSpinner />;;
    }

    if (joinWorldBag.Worlds.length === 0) {
        return <ErrorMessageBox text="There are no worlds available at this time" />;
    }

    if (!joinWorldBag.AllowedStages.includes(joinWorldStage) && joinWorldBag.AllowedStages.length > 0) {
        setJoinWorldStage(joinWorldBag.AllowedStages[0]);
    }

    if (appState.ApplicationState === "Ready") {
        appState.navigate(home);
    }

    appState.setJoiningWorldId(joinWorldBag.World?.worldId);

    return <SimpleBaseView heading="Join World" headingContent={<Button type="nav" text="Logout" icon={IconHelper.General.Exit} action={() => appState.logout()} />}>
        <StagesControl
            currentStage={joinWorldStage}
            changeStage={setJoinWorldStage}
            joinWorldBag={joinWorldBag}
        />
        <StageRender
            currentStage={joinWorldStage}
            changeStage={setJoinWorldStage}
            joinWorldBag={joinWorldBag}
            startPlaying={startPlaying}
        />
    </SimpleBaseView>;
};