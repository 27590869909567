import { RichText } from './RichText';

type Props = {
    className?: string | undefined,
    text?: string[] | undefined | null
}

export const RichTextArray = (props: Props) => {

    if (props.text === null || props.text === undefined || props.text.length === 0) {
        return null;
    }

    return <>
        {props.text.map((t, i) => <RichText
            className={`${props.className ? props.className : ""}`}
            key={i}
            text={t}
        />)}
    </>;
};