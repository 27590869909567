import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";
import { EmblemEditorStateProvider } from "./State/EmblemEditorContext";
import { EditLayerPanel } from "./Views/Panels/EditLayerPanel";
import { EmblemPickerPanel } from "./Views/Panels/EmblemPickerPanel";
import { EmblemRenderPanel } from "./Views/Panels/EmblemRenderPanel";
import { LayerPickerPanel } from "./Views/Panels/LayerPickerPanel";

function EmblemView() {

  return (
    <SimpleBaseView heading={{ text: "Emblem Editor", icon: IconHelper.General.Edit }}    >
      <div className="emblem-editor">
        <LayerPickerPanel />
        <EmblemRenderPanel />
        <EditLayerPanel />
      </div>
      <EmblemPickerPanel />
    </SimpleBaseView>
  );
}

export default () => {
  return (
    <EmblemEditorStateProvider>
      <EmblemView />
    </EmblemEditorStateProvider>
  );
};
