import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Manufactory, ManufactoryProduction } from '../../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { ItemTypeLink } from '../../../../../Components/FusionShift/Links/Items/ItemTypeLink';
import { ResourceTypeLink } from '../../../../../Components/FusionShift/Links/Items/ResourceTypeLink';
import { CollectionHelper } from '../../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';
import { Bonuses } from './Bonuses';

type Props = {
    manufactory: Manufactory,
    production: ManufactoryProduction | undefined
};

export const Items = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!props.production || !worldState.SchematicTypeSettings) {
        return null;
    }

    const inputs = Object.keys(props.production.consumedByInputNodes)
        .concat(Object.keys(props.production.consumedByOutputNodes))
        .concat(Object.keys(props.production.wastage))
        .filter((v, i, s) => s.indexOf(v) === i)
        .map(r => worldState.ItemTypeSettings!.data[r]);

    const output = Object.keys(props.production.produced)
        .map(r => worldState.ItemTypeSettings!.data[r])
        .sort((a, b) => a.order - b.order);

    const anyBonus = CollectionHelper.isAnyNonZeroQuantityInDictionary(props.production.totalBonusesPercent);

    const demanded: { [key: string]: number } = {};

    for (const output of props.manufactory.outputNodes) {
        const schematic = output.itemTypeName in worldState.SchematicTypeSettings.data ? worldState.SchematicTypeSettings.data[output.itemTypeName] : undefined;

        if (schematic !== undefined) {
            const items = Object.keys(schematic.costPerRun.items).map(k => {
                return {
                    itemTypeName: k,
                    quantity: schematic.costPerRun.items[k] * (output.optimalThroughput * output.throughputLimitRatio)
                };
            });

            for (const item of items) {
                if (item.itemTypeName in demanded) {
                    demanded[item.itemTypeName] += item.quantity;
                } else {
                    demanded[item.itemTypeName] = item.quantity;
                }
            }
        }
    }

    const deficits: { [key: string]: number } = {};

    for (const itemTypeName of Object.keys(demanded)) {
        const desires = demanded[itemTypeName];
        const has = itemTypeName in props.production!.consumedByInputNodes ? props.production!.consumedByInputNodes[itemTypeName] : 0;

        if (desires > has) {
            deficits[itemTypeName] = desires - has;
        }
    }

    const deficitItemTypes = Object.keys(deficits).map(x => worldState.ItemTypeSettings!.data[x])
        .sort((a, b) => a.order - b.order);

    return <>
        {inputs.length > 0 &&
            <SubPanel
                heading={{ text: "Input Consumption", icon: IconHelper.Manufactories.Input }}
                isUnpadded
            >
                <Table isFullWidth heading={
                    <>
                        <th></th>
                        <th><span className="is-hidden-mobile">Consumed by </span>Inputs</th>
                        <th><span className="is-hidden-mobile">Used by  </span>Outputs</th>
                        <th>Waste</th>
                    </>
                }>
                    {inputs.map(r => {

                        const input = r.typeName in props.production!.consumedByInputNodes ? props.production!.consumedByInputNodes[r.typeName] : 0;
                        const output = r.typeName in props.production!.consumedByOutputNodes ? props.production!.consumedByOutputNodes[r.typeName] : 0;
                        const waste = r.typeName in props.production!.wastage ? props.production!.wastage[r.typeName] : 0;

                        return <tr key={r.typeName}>
                            <td>
                                <ResourceTypeLink resourceType={r} />
                            </td>
                            <td>
                                {ValueFormatter.formatLocaleNumber(input)}
                            </td>
                            <td className={`${input > 0 && output <= 0 ? "has-text-danger" : ""}`}>
                                {ValueFormatter.formatLocaleNumber(output)}
                            </td>
                            <td className={`${waste > 0 ? "has-text-danger" : ""}`}>
                                {ValueFormatter.formatLocaleNumber(waste)}
                            </td>
                        </tr>;
                    })}
                </Table>
            </SubPanel>
        }
        {deficitItemTypes.length > 0 && <>
            <SubPanel
                heading={{ text: "Resource Deficits", icon: IconHelper.Items.Resource }}
                isUnpadded
            >
                <Table isFullWidth heading={<>
                    <th>Resource</th>
                    <th>Demanded<span className="is-hidden-mobile"> by Outputs</span></th>
                    <th>Deficit</th>
                </>
                }>
                    {deficitItemTypes.map(x => {

                        return <tr key={x.typeName}>
                            <td>
                                <ItemTypeLink itemType={x} />
                            </td>
                            <td>
                                {ValueFormatter.formatLocaleNumberFromDictionary(x.typeName, demanded)}
                            </td>
                            <td className="has-text-danger">
                                {ValueFormatter.formatLocaleNumberFromDictionary(x.typeName, deficits)}
                            </td>
                        </tr>;
                    })}
                </Table>
            </SubPanel>
        </>}
        {output.length > 0 &&
            <SubPanel
                heading={{ text: "Output Production", icon: IconHelper.Manufactories.Input }}
                isUnpadded
            >
                <Table isFullWidth heading={<>
                    <th>Item</th>
                    <th>Quantity</th>
                    {anyBonus && <th>Bonus</th>}
                </>
                }>
                    {output.map(x => {

                        const bonus = x.typeName in props.production!.totalBonusesPercent ? props.production!.totalBonusesPercent[x.typeName] : 0;

                        return <tr key={x.typeName}>
                            <td>
                                <ItemTypeLink itemType={x} />
                            </td>
                            <td>
                                {ValueFormatter.formatLocaleNumberFromDictionary(x.typeName, props.production!.produced)}
                            </td>
                            {anyBonus && <td className={bonus < 0 ? "has-text-danger" : "has-text-success"}>
                                {ValueFormatter.fromDecimalToDisplayPercent(bonus)}
                            </td>}
                        </tr>;
                    })}
                </Table>
            </SubPanel>
        }
        <Bonuses {...props} />
    </>
});