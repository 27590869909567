import { GalaxyMapOverview, IPlayerForSelf, ISolarSystemMinimal } from "../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../../Entities/SolarSystem/SolarSystemWrapper";
import { CanvasCamera } from "./CanvasCamera";
import { GalaxyMapPreferences } from "./GalaxyMapPreferences";

export const galaxyMapParsecPixels: number = 20;

export type Bounds = {
    width: number,
    height: number
}

export class GalaxyMapData {

    constructor(public map: GalaxyMapOverview,
        public player: IPlayerForSelf,
        public solarSystems: SolarSystemWrapper[]) {
    }
}

export class GalaxyMapPerspective {

    public parsec = galaxyMapParsecPixels;

    public transform(x: number, y: number, offset?: number) {
        return {
            x: (x * galaxyMapParsecPixels) - (offset ?? 0),
            y: (y * galaxyMapParsecPixels) - (offset ?? 0),
        };
    }

    public isParsecInView(tf: DOMMatrix, xPos: number, yPos: number, bounds: Bounds) {

        const { x, y } = tf.transformPoint(new DOMPoint(xPos, yPos));

        if (x + galaxyMapParsecPixels < 0 || x - galaxyMapParsecPixels > bounds.width) {
            return false;
        }

        if (y + galaxyMapParsecPixels < 0 || y - galaxyMapParsecPixels > bounds.height) {
            return false;
        }

        return true;
    }
}

export type GalaxyMapSelection = {
    solarSystemId: number | undefined,
    hoverCoordinate?: { x: number, y: number }
}

export type RenderBag = {
    data: GalaxyMapData,
    perspective: GalaxyMapPerspective,
    selection: GalaxyMapSelection,
    camera: CanvasCamera,
    bounds: Bounds,
    preferences: GalaxyMapPreferences,
    targets?: TargetsRender
};

export type AcquireTargetsConfig = {
    federationId: number,
    playerId: number | undefined,
    additionalDistance: number
}

export type SolarSystemDistance = { solarSystem: ISolarSystemMinimal, distance: number };

export type ClosestType = "self" | "ally" | "absolute";

export type AcquireTargetClosestConfiguration = {
    excludeOutposts?: boolean,
    closest: ClosestType
}

export type AcquiredTarget = {
    solarSystem: ISolarSystemMinimal,
    nearestSolarSystem: SolarSystemDistance | undefined,
    additionalTargets: SolarSystemDistance[]
}

export type TargetsRender = {
    targets: ISolarSystemMinimal[]
    targetRadius: number,
    allies: {
        solarSystem: ISolarSystemMinimal,
        targets: { x: number, y: number }[]
    }[]
}