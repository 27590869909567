import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { IPlayerForSelf, ISolarSystemDetail } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext } from "../../ApplicationState/ContextRoot";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { SendShipsButtons } from "../../Components/FusionShift/Buttons/SendShipsButtons";
import { SolarSystemButtons } from "../../Components/FusionShift/Buttons/SolarSystemButtons";
import { CelestialCommoditySaleList } from "../../Components/FusionShift/Celestials/CelestialCommoditySaleList";
import { SolarSystemClaimTypeIcon } from "../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon";
import { BeginnersProtectionMessage } from "../../Components/FusionShift/Messages/BeginnersProtectionMessage";
import { SolarSystemRender } from "../../Components/FusionShift/SolarSystems/SolarSystemRender";
import { IconHelper } from "../../Helpers/IconHelper";
import { SolarSystemHelper } from "../../Helpers/SolarSystemHelper";
import { SimpleBaseView } from "../BaseView";
import { ContractTable } from "../Contracts/Components/ContractTable";
import { MarketOrderTable } from "../Marketplace/Components/MarketOrderTable";
import { SolarSystemAgents } from "./SubViews/Agents/SolarSystemAgents";
import { SolarSystemStats } from "./SubViews/Summary/SolarSystemStats";

type Props = {
    solarSystem: ISolarSystemDetail,
    player: IPlayerForSelf
}

export const SolarSystemByIdViewInner = (props: Props) => {

    const solarSystem = props.solarSystem;
    const isPlayerOwned = (solarSystem.owner && solarSystem.owner.playerId === props.player.playerId);

    const background = SolarSystemHelper.solarSystemBackground(props.solarSystem);

    return < SimpleBaseView
        heading={{
            text: solarSystem.name,
            icon: <SolarSystemClaimTypeIcon solarSystem={solarSystem} />
        }}
        headingContent={< SolarSystemButtons {...props} />}
        backgroundImage={background}
    >
        <SolarSystemStats solarSystem={solarSystem} />
        <SendShipsButtons solarSystem={solarSystem} />
        {!isPlayerOwned && solarSystem.owner && <BeginnersProtectionMessage player={solarSystem.owner} />}
        <SubPanel heading="Celestials" className="solar-system-panel">
            <SolarSystemRender solarSystem={solarSystem} />
        </SubPanel>
        {
            props.solarSystem.contracts.length > 0 &&
            <SubPanel heading={{ text: "Contracts", icon: IconHelper.Economy.Contract }} isUnpadded>
                <ContractTable atSolarSystemId={props.solarSystem.solarSystemId} contracts={props.solarSystem.contracts} />
            </SubPanel>
        }
        {
            !isPlayerOwned && solarSystem.marketOrders.length > 0 &&
            <SubPanel heading={{ text: "Market Orders ", icon: IconHelper.Market.MarketOrder }} isUnpadded>
                <MarketOrderTable marketOrders={solarSystem.marketOrders} solarSystemForForcedAcceptButton={solarSystem} showLocation={false} />
            </SubPanel>
        }
        {!isPlayerOwned && <CelestialCommoditySaleList solarSystem={solarSystem} />}
        <SolarSystemAgents {...props} />
    </SimpleBaseView >;
};

export const SolarSystemByIdView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const { solarSystemId } = useParams();

    const [solarSystem, setSolarSystem] = React.useState<ISolarSystemDetail | undefined>(undefined);

    React.useEffect(() => {
        if (solarSystemId) {
            apiState.SolarSystemClient.getDetail(Number(solarSystemId)).then(solarSystemResponse => {
                if (solarSystemResponse) {
                    setSolarSystem(solarSystemResponse);
                }
            });
        }
    }, [solarSystemId]);

    if (!solarSystem || !playerState.Player) {
        return <LoadingSpinner />;
    }

    return <SolarSystemByIdViewInner solarSystem={solarSystem} player={playerState.Player} />;
});