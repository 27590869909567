import * as React from 'react';
import { CelestialDetail } from '../../../ApplicationState/ApiClient';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { CelestialFeature } from '../../../Helpers/CelestialHelper';
import { celestial_installation, celestial_page } from '../../../Navigation/Routing/SolarSystem';
import { ButtonChooser, Choice } from '../../Base/Buttons/ButtonChooser';
import { ButtonHolder } from '../../Base/Buttons/ButtonHolder';

type Props = {
    isVerticalOnMobile?: boolean,
    options: Choice<CelestialFeature>[],
    celestial: CelestialDetail,
    activeView?: CelestialFeature | undefined,
    children?: React.ReactNode
}

export const CelestialButtons = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    function navigate(viewType: CelestialFeature | undefined) {
        if (!viewType) {
            return;
        }

        if (typeof viewType === "string") {
            appState.navigate(celestial_page(props.celestial.celestialId, viewType));
        } else {
            appState.navigate(celestial_installation(props.celestial.celestialId, viewType.installationId));
        }
    }

    if (props.options.length === 0 && props.children) {
        return <ButtonHolder className="is-pulled-right">
            {props.children}
        </ButtonHolder>;
    }

    return <ButtonChooser className="is-pulled-right" hideTextOnMobile={false} values={props.options} value={props.activeView} valueChanged={e => navigate(e)} isVerticalOnMobile={props.isVerticalOnMobile} >
        {props.children}
    </ButtonChooser>;
};