import classNames from "classnames";
import { FleetMovementType } from "../../../ApplicationState/ApiClient";
import { Line, SolarSystem as SolarSystemPosition, solarSystemPosition, solarSystemPositionToRenderPosition } from "./Entities";

export function createShipsRender(
    solarSystems: SolarSystemPosition[],
    selectedSolarSystemId: number | undefined) {

    return getFleetLines(
        solarSystems,
        selectedSolarSystemId);
}

function getFleetLines(solarSystems: SolarSystemPosition[], selectedSolarSystemId: number | undefined) {

    function solarSystemById(solarSystemId: number) {
        return solarSystems.find(x => x.solarSystem.solarSystemId === solarSystemId)!;
    }

    const fleets: {
        sourceSolarSystem: SolarSystemPosition,
        targetSolarSystem: SolarSystemPosition,
    }[] = [];

    for (let solarSystem of solarSystems) {

        const solarSystemPosition = solarSystemById(solarSystem.solarSystem.solarSystemId);

        if (solarSystem.solarSystem.solarSystem.repeatFleets) {

            for (let repeatFleet of solarSystem.solarSystem.solarSystem.repeatFleets) {

                if (repeatFleet.movementType !== FleetMovementType.Rebase) {
                    continue;
                }

                let sourceSolarSystem = solarSystemPosition;
                let targetSolarSystem = solarSystemById(repeatFleet.targetSolarSystemId);

                if (targetSolarSystem !== undefined) {

                    fleets.push({
                        sourceSolarSystem,
                        targetSolarSystem
                    });
                }
            }
        }
    }

    const lines: Line[] = [];

    for (let fleet of fleets) {

        const a = solarSystemPositionToRenderPosition(solarSystemPosition(fleet.sourceSolarSystem));
        const b = solarSystemPositionToRenderPosition(solarSystemPosition(fleet.targetSolarSystem));

        const fleetClassName = classNames(
            "fleet",
            "rebase",
            {
                "selected-system": fleet.sourceSolarSystem.solarSystem.solarSystemId === selectedSolarSystemId || fleet.targetSolarSystem.solarSystem.solarSystemId === selectedSolarSystemId
            });

        lines.push({
            x1: a.x,
            y1: a.y,
            x2: b.x,
            y2: b.y,
            className: fleetClassName
        });
    }

    return lines;
}