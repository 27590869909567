import classNames from 'classnames';
import * as React from 'react';
import { ICelestialDetail, IInstallationTypeSettings, InstallationType, ISolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { AppStateContext } from '../../../../ApplicationState/ContextRoot';
import { Icon } from '../../../../Components/Base/Icon';
import { ConstructionIcon, DamagedIcon, DeconstructIcon } from '../../../../Components/FusionShift/Celestials/CelestialIcons';
import { InstallationTypePurposeIcon } from '../../../../Components/FusionShift/Icons/InstallationTypePurposeIcon';
import { InstallationImage } from '../../../../Components/FusionShift/Images/InstallationImage';
import { CelestialHelper } from '../../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { celestial_build } from '../../../../Navigation/Routing/SolarSystem';

type Props = {
    installationTypeSettings: IInstallationTypeSettings,
    celestial: ICelestialDetail,
    solarSystem: ISolarSystemDetail
}

type CellProps = {
    installation: { label: string, installationId: number, installationType: InstallationType, level: number, damage: number } | undefined,
    celestial: ICelestialDetail,
    installationTypeSettings: IInstallationTypeSettings,
    solarSystem: ISolarSystemDetail
}

const InstallationCell = (props: CellProps) => {

    const appState = React.useContext(AppStateContext);

    const celestialTypeClass = `is-${props.celestial.celestialTypeName?.toLowerCase() ?? "unknown"}`;

    if (props.installation === undefined || props.installation === null) {
        return <div className={`empty-slot is-clickable ${props.celestial.celestialTypeName?.toLowerCase() ?? ""}`} onClick={() => appState.navigate(celestial_build(props.celestial.celestialId))}>
            <div className="doodads">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <Icon icon={IconHelper.Installations.EmptySlot} className="empty-slot" title="Empty slot" />
            </div>
            <p className={`bottom-tile ${celestialTypeClass}`}>Empty</p>
        </div>;
    }

    const isDamaged = CelestialHelper.isDamaged(props.celestial, props.installation.installationId);
    const hasConstruct = CelestialHelper.hasConstruct(props.celestial, props.solarSystem, props.installation.installationId);
    const hasDeconstruct = CelestialHelper.hasDeconstruct(props.celestial, props.solarSystem, props.installation.installationId);

    const className = classNames("installation",
        {
            "is-damaged": isDamaged,
            "has-construct": hasConstruct,
            "has-deconstruct": hasDeconstruct
        });

    return <div className={className}>
        <InstallationImage
            size="medium"
            installation={props.installation}
            isConstructing={hasConstruct || hasDeconstruct}
            installationType={props.installation.installationType}
            celestialId={props.celestial.celestialId}
        />
        <div className={`summary-icons bottom-tile ${celestialTypeClass}`}>
            <InstallationTypePurposeIcon installationType={props.installation.installationType} />
            <span>
                {props.installation.level}
            </span>
            {isDamaged && <DamagedIcon />}
            {hasConstruct && <ConstructionIcon />}
            {hasDeconstruct && <DeconstructIcon />}
        </div>
    </div>
};
export const CelestialInstallationsList = (props: Props) => {

    const installations = CelestialHelper.mapInstallations(props.celestial, installation => {
        return {
            label: `${props.installationTypeSettings.data![installation.installationTypeName].name} [${installation!.level}]`,
            installationId: installation.installationId,
            installationType: props.installationTypeSettings.data[installation.installationTypeName],
            level: installation.level,
            damage: installation.damage
        }
    });

    const anyUsingAllSlots = installations.find(x => x.installationType.requiresAllCelestialSlots) !== undefined;

    const cells = anyUsingAllSlots ? [0] : [0, 1, 2, 3, 4];

    return <>
        {cells.filter(c => c < props.celestial.size).map(i => <InstallationCell
            key={i}
            installation={installations.length > i ? installations[i] : undefined}
            {...props}
        />
        )}
    </>;
}

export const CelestialInstallations = (props: Props) => {

    return <div className="installations">
        <CelestialInstallationsList {...props} />
    </div>;
};