import { observer } from "mobx-react-lite";
import * as React from "react";
import { FleetMovementType, SolarSystemDetail } from "../../ApplicationState/ApiClient";
import { PlayerStateContext, SolarSystemStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { FieldHolder } from "../../Components/Base/Form/FieldHolder";
import { Select } from "../../Components/Base/Form/Select";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { FleetWrapper } from "../../Entities/FleetWrapper";
import { IconHelper } from "../../Helpers/IconHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { send_ships } from "../../Navigation/Routing/Misc";
import { SimpleBaseView } from "../BaseView";
import { FleetTable } from "./SubParts/FleetTable";

export const FleetsIndex = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [fleetMovementType, setFleetMovementType] = React.useState<FleetMovementType | undefined>(undefined);

    const configured = React.useMemo(() => {

        const fmt: FleetMovementType[] = [];

        if (solarSystemState.SolarSystemsDetail === undefined) {
            return { fleets: [], fleetMovementTypes: fmt };
        }

        const f = solarSystemState.SolarSystemsDetail.flatMap(x => (x.solarSystem.fleets ?? []).filter(y => {
            if (!fmt.includes(y.movementType)) {
                fmt.push(y.movementType);
            }

            return (y.homeSolarSystemId === x.solarSystemId || y.ownerPlayerId !== x.solarSystem.playerId) &&
                (fleetMovementType === undefined || y.movementType === fleetMovementType);
        }
        ));

        return { fleets: f, fleetMovementTypes: fmt };

    }, [solarSystemState.SolarSystemsDetail, fleetMovementType]);

    function reloadCallback(solarSystemDetail: SolarSystemDetail) {
        solarSystemState.loadSolarSystem(solarSystemDetail);
    }

    if (!playerState.Player) {
        return null;
    }

    const fleets = configured.fleets;

    return <SimpleBaseView heading={{ text: "Fleets", icon: IconHelper.Ships.Ship }}
        headingContent={
            <ButtonLink to={send_ships} text="Send Ships" icon={IconHelper.Ships.Send} />
        }>
        {fleets.length === 0 && <Paragraph>No active fleets</Paragraph>}
        {fleets.length > 0 && <>
            <div className="is-pulled-right">
                <FieldHolder label="Filter">
                    <Select
                        showUnset={{ label: "All movement types", value: "ANY" }}
                        value={fleetMovementType}
                        valueChanged={v => setFleetMovementType(v === "ANY" ? undefined : Number(v))}
                        values={configured.fleetMovementTypes.map(x => {
                            return {
                                label: ValueFormatter.friendlyNameForMovementType(x),
                                value: x
                            }
                        })}
                    />
                </FieldHolder>
            </div>
            <FleetTable
                fleets={fleets}
                reloadCallback={reloadCallback}
                availableShips={FleetWrapper.empty()}
                display={{
                    arrivalDate: true,
                    source: true,
                    target: true,
                    mass: true,
                    headings: true,
                    countPerPage: 50
                }}
            />
        </>}
    </SimpleBaseView>;
});