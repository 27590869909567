import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SolarSystemSample, WorldRegion } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { SolarSystemCelestialsRow } from '../../../Components/FusionShift/SolarSystems/SolarSystemCelestialsRow';
import { IconHelper } from '../../../Helpers/IconHelper';
import { SimpleBaseView } from '../../BaseView';

export const SolarSystemSamples = observer(() => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [samples, setSamples] = React.useState<undefined | SolarSystemSample[]>(undefined);

    React.useEffect(() => {

        if (!samples) {
            apiState.DevClient.solarSystemSamples().then(s => {
                setSamples(s);
            })
        }

    }, [samples]);

    React.useEffect(() => {
        const timer = setInterval(() => {
            generate();
        }, 10000);
        return () => clearInterval(timer);
    }, []);

    function generate() {
        setSamples(undefined);
    }

    if (!appState.IsAdmin) {
        return null;
    }

    if (!samples || !worldState.CelestialTypeSettings) {
        return <LoadingSpinner />;
    }

    return <SimpleBaseView
        heading={"Solar System Samples"}
        headingContent={<Button type="nav" icon={IconHelper.Categories.Settings} text="Generate" isDisabled={!samples} action={generate} />}
    >
        <>
            {samples.map(s => <SubPanel key={s.region} heading={WorldRegion[s.region]} >
                <Table isHoverable isFullWidth>
                    {s.solarSystems && s.solarSystems.map((s2, i) => {

                        const className = s2.find(c => c.celestialTypeName !== undefined && c.celestialTypeName in worldState.CelestialTypeSettings!.data && worldState.CelestialTypeSettings!.data[c.celestialTypeName].isHabitable) !== undefined ? "is-info" : "";

                        return <tr key={i} >
                            <td className={className}>{i + 1}</td>
                            <td>
                                <SolarSystemCelestialsRow celestials={s2} />
                            </td>
                        </tr>
                    })}
                </Table>
            </SubPanel>)}
        </>
    </SimpleBaseView>
});