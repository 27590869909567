import React from "react";
import { ValidationResult } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { ErrorMessageBox } from "../../Components/Base/MessageBox";
import { IconHelper } from "../../Helpers/IconHelper";

type Props = {
    reloadCallback: () => any
}

export const NewFederationForm = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [federationName, setFederationName] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [federationShortName, setFederationShortName] = React.useState(ValidationHelper.empty());
    const [validation] = React.useState<ValidationResult | undefined>(undefined);

    function create() {
        if (playerState.Player && !playerState.Player.federation && !isInvalid()) {
            return apiState.FederationClient.create(federationName.validText!, federationShortName.validText!).then(_ => {
                appState.reload("Created a new federation");
                props.reloadCallback();
            })
        }
    }

    function isInvalid() {
        return !federationName.isValid || !federationShortName.isValid;
    }

    function validateFederationName(value: string) {
        return apiState.FederationClient.validateName(worldState.World!.worldId, value);
    }

    function validateFederationShortName(value: string) {
        return apiState.FederationClient.validateShortName(worldState.World!.worldId, value);
    }

    const buttonDisabled = isInvalid();

    return <SubPanel heading="Found new Federation" footerContent={
        <Button type="action" icon={IconHelper.General.Friend} action={() => create()} text="Create new Federation" isDisabled={buttonDisabled} isPulledRight />
    }>
        <ValidatingStringInput
            label="Name"
            icon={IconHelper.General.Edit}
            valueChanged={setFederationName}
            validation={validateFederationName}
        />
        <ValidatingStringInput
            label="Short Name"
            icon={IconHelper.General.Edit}
            valueChanged={setFederationShortName}
            validation={validateFederationShortName}
        />
        {!!validation && !validation.isValid && <ErrorMessageBox text={validation.error!} />}
    </SubPanel>;
}