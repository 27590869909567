import * as React from 'react';
import { InstallationCategory } from '../../../ApplicationState/ApiClient';
import { InstallationCategories } from '../../../Blobs/Descriptions/InstallationCategories';
import { Paragraph } from '../../Base/Text/Paragraph';

type Props = {
    installationCategory: InstallationCategory
}

export const InstallationCategoryDescription = (props: Props) => {

    const key = InstallationCategory[props.installationCategory];
    const text = key in InstallationCategories && InstallationCategories[key];

    if (!text) {
        return null;
    }

    return <Paragraph type="content">
        {text}
    </Paragraph>;
};