import { CombatRoundType, FleetMovementType, IShipTypeSettings, ShipType } from "../../../ApplicationState/ApiClient";
import { Button } from "../../../Components/Base/Buttons/Button";
import { FormTable } from "../../../Components/Base/Containers/FormTable";
import { HeadingContent } from "../../../Components/Base/Containers/PanelHeading";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { NumberInput } from "../../../Components/Base/Form/Input";
import { Select } from "../../../Components/Base/Form/Select";
import { Icon } from "../../../Components/Base/Icon";
import { ShipTypeImageAndLink } from "../../../Components/FusionShift/Images/ShipTypeImage";
import { ShipTypeShortStats } from "../../../Components/FusionShift/Ships/ShipTypeShortStats";
import { FleetWrapper } from "../../../Entities/FleetWrapper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { SendShipsHelper } from "../../../Helpers/SendShipsHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

export type ShipTypeQuantity = {
    ship: ShipType,
    quantity: number
}

type Props = {
    shipTypeSettings: IShipTypeSettings,
    availableShips: FleetWrapper,
    selectedShips: FleetWrapper,
    shipQuantities: { [key: string]: number },
    tactics: { [key: string]: CombatRoundType },
    setShipTactic(shipTypeName: string, tactic: CombatRoundType)
    setShipQuantity(ship: ShipTypeQuantity, quantity: number),
    movementType: FleetMovementType | undefined,
    showTactics?: boolean | undefined,
    showUpkeep?: boolean | undefined,
    hideAvailableQuantityButton?: boolean,
    hideStats?: boolean,
    heading?: HeadingContent | undefined,
    isIntercept?: boolean;
    onlyShowAvailable?: boolean;
}

export const ShipInputList = (props: Props) => {

    function setTactic(shipTypeName: string, tactic: string) {
        props.setShipTactic(shipTypeName, CombatRoundType[tactic as keyof typeof CombatRoundType]);
        return true;
    }

    function shipUsageClassName(shipType: ShipType) {
        switch (SendShipsHelper.shipUsageForShipType(props.movementType, props.selectedShips, shipType, props.isIntercept ?? false)) {
            case "error":
                return "has-text-danger";
            case "disabled":
                return "has-text-grey-light";
            case "required":
                return "has-text-warning";
            case "satisfying":
                return "has-text-success";
            default:
                return "";
        }
    }

    function tacticsValue(shipTypeName: string) {
        const val = shipTypeName in props.tactics ? props.tactics[shipTypeName] : props.shipTypeSettings.data[shipTypeName].defaultCombatRoundType;

        if (typeof val === "string") {
            const typed = val as keyof typeof CombatRoundType;

            return CombatRoundType[typed];
        }

        return val;
    }

    const text = `Ships${props.showTactics ? " and Tactics" : ""}`;
    const heading = props.heading ?? { text, icon: <Icon icon={IconHelper.Ships.Ship} /> };

    return <SubPanel
        heading={heading}
        isUnpadded
    >
        <FormTable
            isSmall
            hasAddons
            isStriped
            data={props.availableShips.Ships}
            filter={x => !props.onlyShowAvailable || x.quantity > 0}
            render={s => {

                const noZero = props.shipQuantities[s.ship.typeName || ""] === 0 || props.shipQuantities[s.ship.typeName || ""] === undefined;
                const noMax = props.shipQuantities[s.ship.typeName || ""] === s.quantity;

                return {
                    key: s.ship.typeName,
                    label: <ShipTypeImageAndLink shipType={s.ship} linkClassName={shipUsageClassName(s.ship)} />,
                    content: <>
                        <div className="control">
                            <NumberInput
                                size={5}
                                value={s.ship.typeName in props.shipQuantities ? props.shipQuantities[s.ship.typeName] : 0}
                                valueChanged={value => props.setShipQuantity(s, value)}
                            />
                        </div>
                        <div className={`control ${noZero ? "is-hidden-mobile" : ""}`}>
                            <Button type="nav" icon="" className="is-small" text="0" action={() => props.setShipQuantity(s, 0)} isDisabled={noZero} />
                        </div>
                        {!props.hideAvailableQuantityButton &&
                            <div className={`control ${noMax ? "is-hidden-mobile" : ""}`}>
                                <Button type="nav" icon="" className="is-small" text={ValueFormatter.formatLocaleNumber(s.quantity)} action={() => props.setShipQuantity(s, s.quantity)} isDisabled={noMax} />
                            </div>
                        }
                    </>,
                    extra: [
                        props.showTactics &&
                        <Select
                            className="is-small"
                            value={tacticsValue(s.ship.typeName)}
                            valueChanged={v => setTactic(s.ship.typeName, v)}
                            values={Object.keys(CombatRoundType)
                                .filter(k => isNaN(Number(k)))
                                .filter(k => k !== CombatRoundType[CombatRoundType.Grounded])
                                .map(k => {
                                    return {
                                        label: k,
                                        value: CombatRoundType[k]
                                    }
                                })}
                        />,
                        !props.hideStats &&
                        <ShipTypeShortStats shipType={s.ship} className="is-hidden-mobile" showUpkeep={props.showUpkeep} />
                    ]
                }

            }}
        />
    </SubPanel>;
};