import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { ContractDetail } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext } from "../../ApplicationState/ContextRoot";
import { Badge, Badges } from "../../Components/Base/Badge";
import { HistoryBackButton } from "../../Components/Base/Buttons/BackButton";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { EmblemImage } from "../../Components/FusionShift/Images/EmblemImage";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { ContractHelper } from "../../Helpers/ContractHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { combatReport } from "../../Navigation/Routing/NotificationsEtc";
import { SimpleBaseView } from "../BaseView";
import { ContractButtons } from "./Components/ContractButtons";
import { ContractFleetTable } from "./Components/ContractFleetTable";
import { ContractStatusDescription } from "./Components/ContractStatusDescription";
import { ContractTypeSpecificsForContractor } from "./Components/ContractTypeSpecificsForContractor";
import { ContractTypeSpecificsForOwner } from "./Components/ContractTypeSpecificsForOwner";
import { RelevantContractMessageBox } from "./Components/RelevantContractMessageBox";
import { TermsDetail } from "./Components/TermsDetail";

type Props = {
    reloadCallback: () => any,
    contract: ContractDetail
}

const ContractInner = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return <LoadingSpinner />;
    }

    const contract = props.contract;

    return <SubPanel
        heading={{ text: ValueFormatter.friendlyNameForContractType(contract.type), icon: IconHelper.Economy.contractType(contract.type) }}>
        <Badges>
            <Badge label="Owner" content={<PlayerLink player={contract.player} />} icon={<EmblemImage filename={contract.player?.emblemFilename} size='small' />} />
            <Badge label={ContractHelper.isCalloffContract(contract.type) ? "Credits Remaining" : "Credits"} content={ValueFormatter.formatLocaleNumber(contract.remainingCredits)} icon={IconHelper.Economy.Credits} />
            {contract.initialCredits !== contract.remainingCredits &&
                <Badge label={"Initial Credits"} content={ValueFormatter.formatLocaleNumber(contract.initialCredits)} icon={IconHelper.Economy.Credits} />
            }
            <Badge label="Status" content={<ContractStatusDescription contractStatus={props.contract.status} />} icon={IconHelper.Economy.Contract} />
            {contract.forPlayer &&
                <Badge label="Assigned To" content={<PlayerLink player={contract.forPlayer} />} icon={<EmblemImage filename={contract.forPlayer?.emblemFilename} size='small' />} />
            }
            {contract.combatReportId !== undefined && contract.combatReportId !== null &&
                <Badge label="Combat Report" content={<Link to={combatReport(contract.combatReportId)}>View</Link>} icon={IconHelper.Combat.CombatReport} />
            }
        </Badges>
        <RelevantContractMessageBox contract={contract.relevantContract} />
        <SplitLayout
            left={<>
                <TermsDetail contract={contract} />
                <ContractFleetTable contractFleets={contract.fleets} hideOwner={contract.forPlayerId !== undefined && contract.forPlayerId === playerState.Player.playerId} />
            </>}
            right={
                <>
                    {contract.playerId === playerState.Player.playerId ? <ContractTypeSpecificsForOwner contractType={contract.type} /> : <ContractTypeSpecificsForContractor contractType={contract.type} />}
                    <ContractButtons {...props} />
                </>
            }
        />
    </SubPanel>
});

export const ContractView = () => {

    const apiState = React.useContext(ApiStateContext);

    const { contractId } = useParams();

    const [contract, setContract] = React.useState<ContractDetail | undefined>(undefined);

    React.useEffect(() => {
        const contractIdNum = Number(contractId);

        if (contract === undefined || contractIdNum !== contract.contractId) {
            apiState.ContractsClient.getDetail(contractIdNum).then(contract => {
                if (contract) {
                    setContract(contract);
                }
            });
        }
    }, [contractId, contract]);


    return <SimpleBaseView
        heading={{ text: "Contract", icon: IconHelper.Economy.Contract }}
        headingContent={
            <HistoryBackButton />
        }>
        {contract === undefined && <LoadingSpinner />}
        {contract !== undefined && <ContractInner contract={contract} reloadCallback={() => setContract(undefined)} />}
    </SimpleBaseView>
};