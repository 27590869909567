import { observer } from "mobx-react-lite";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { IItemsDetail, IItemTypeSettings, ItemCategory } from "../../../ApplicationState/ApiClient";
import { SolarSystemStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { FancyItemsWithCapacityControl } from "../../../Components/FusionShift/Items/FancyItemsWithCapacityControl";
import { FancyItem, ItemsHelper } from "../../../Helpers/ItemsHelper";
import { TutorialIds } from "../../Tutorial/TutorialIds";
import { ItemsPeek } from "./ItemsPeek";

type Props = {
    itemsDetail: IItemsDetail,
    items: { [key: string]: number },
    allowExpand?: boolean,
    itemTypeSettings: IItemTypeSettings,
    renderKey?: string
}

export const ResourceBar = observer((props: Props) => {

    const location = useLocation();

    const [expandedPathName, setExpandedLocation] = React.useState<string | undefined>(undefined);
    const [isExpanded, setIsExpanded] = React.useState(false);

    const [intPtr, setIntPtr] = React.useState<NodeJS.Timeout | undefined>(undefined);
    const [items, setItems] = React.useState<FancyItem[]>([]);

    if (!props.itemsDetail) {
        return null;
    }

    React.useEffect(() => {

        if (props.items) {

            const itemsToUse = ItemsHelper.buildItemsFancyDetail(
                props.items,
                props.itemsDetail.netFinal,
                props.itemsDetail.resourceCapacities,
                props.itemsDetail.otherCapacities,
                "hide-by-typesettings",
                props.itemTypeSettings);

            setItems(itemsToUse);
        }
    }, [props.renderKey, props.items, props.itemsDetail.netFinal, props.itemsDetail.resourceCapacities, props.itemTypeSettings]);

    React.useEffect(() => {
        if (intPtr) {
            clearTimeout(intPtr);
            setIntPtr(undefined);
        }
    }, [items]);

    React.useEffect(() => {

        if (location.pathname !== expandedPathName) {
            setIsExpanded(false);
        }

    }, [location.pathname]);

    React.useEffect(() => {

        const interval = setInterval(() => {

            const updatedItems = items.map(i => {

                const newQuantity = i.quantity + i.balancePerSec;
                return {
                    ...i,
                    quantity: newQuantity > i.capacity ? i.capacity : newQuantity,
                    isDanger: ItemsHelper.isDanger(newQuantity, i.balancePerSec, i.capacity),
                    isWarning: i.balancePerSec < 0
                };
            });

            if (updatedItems.length > 0) {
                setItems(updatedItems);
            }
        }, 1000);

        return () => clearInterval(interval);

    }, []);

    function toggleExpand() {
        if (!props.allowExpand) {
            return;
        }

        const toExpand = !isExpanded;
        setIsExpanded(toExpand);
        if (toExpand) {
            setExpandedLocation(location.pathname);
        }
    }

    let has = 0;
    let hasCommodity = false;
    let hasRaw = false;
    let hasComponent = false;

    for (let item of items) {
        if (item.itemType.category === ItemCategory.Resource) {
            has++;
        } else if (item.itemType.category === ItemCategory.Commodity && hasCommodity === false) {
            hasCommodity = true;
            has++;
        } else if (item.itemType.category === ItemCategory.RawResource && hasRaw === false) {
            hasRaw = true;
            has++;
        }
        else if (item.itemType.category === ItemCategory.Component && hasComponent === false) {
            hasComponent = true;
            has++;
        }
    }

    const expand = isExpanded ? " is-expanded" : "";

    return <div className={`resource-bar has-${has}${expand}`} id={TutorialIds.ResourcesBar}>
        {!isExpanded && <div className={`holder ${props.allowExpand !== undefined ? "is-clickable" : ""}`} onClick={toggleExpand}>
            <FancyItemsWithCapacityControl
                items={items}
                otherCapacities={props.itemsDetail.otherCapacities}
                dontColourText
                hideCapacity
                compressOnMobile
            />
        </div>}
        {isExpanded && <ItemsPeek {...props} fancyItems={items} otherCapacities={props.itemsDetail.otherCapacities} closeCallback={() => setIsExpanded(false)} />}
    </div>;
});

export const CurrentSolarSystemResourceBar = observer(() => {

    const worldState = React.useContext(WorldStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!solarSystemState.SolarSystem || !solarSystemState.CurrentSolarSystem || !worldState.ItemTypeSettings) {
        return null;
    }

    const items = solarSystemState.SolarSystem.items;
    const itemsDetail = solarSystemState.SolarSystem.itemsDetail;

    if (!items || !itemsDetail) {
        return null;
    }

    return <ResourceBar
        renderKey={`${solarSystemState.CurrentSolarSystem.solarSystemId}_${solarSystemState.CurrentSolarSystem.loadNumber}`}
        itemsDetail={itemsDetail}
        items={items}
        itemTypeSettings={worldState.ItemTypeSettings}
        allowExpand
    />;
});
