import { makeAutoObservable } from "mobx";

export class PremiumBalanceState {

    Balance: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    public loadBalance(balance: number) {
        this.Balance = balance;
    }
}