import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ServiceWorkerStateContext } from '..';
import { AppStateContext } from '../ApplicationState/ContextRoot';
import { LoadingBar } from '../Components/Base/Loading/LoadingBar';

export const UnauthenticatedInitialLoad = observer(() => {

    const appState = React.useContext(AppStateContext);
    const serviceWorkerState = React.useContext(ServiceWorkerStateContext);

    const isLoading = appState.ApplicationState === "Authenticating" ||
        appState.ApplicationState === "PerformingInitialLoad" ||
        serviceWorkerState.isUpdating;

    if (appState.ApplicationState !== "Ready" || serviceWorkerState.isUpdateAvailable) {
        return <div className={`authentication-logo ${appState.ApplicationState.toLowerCase()}`}>
            <img src="/images/main_logo.png" />
            {isLoading && <LoadingBar />}
        </div>;
    }

    return null;
});