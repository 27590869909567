import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, IItemTypeSettings, ItemCategory } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Table } from '../../../../Components/Base/Containers/Table';
import { SendShipsBetweenSystemButtons } from '../../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { ResourceBarBlock } from '../../../../Components/FusionShift/Items/ItemBarBlock';
import { SelectSolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { ItemsHelper } from '../../../../Helpers/ItemsHelper';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type RowProps = {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem: SolarSystemWrapper,
    resourceTypeNames: string[],
    itemTypeSettings: IItemTypeSettings
}

const movementTypes = [
    FleetMovementType.Delivery,
    FleetMovementType.Collection
];

const Row = (props: RowProps) => {

    const fancyItems = React.useMemo(() => {

        if (!props.solarSystem.solarSystem.items) {
            return [];
        }

        return ItemsHelper.buildItemsFancyDetail(
            props.solarSystem.solarSystem.items,
            props.solarSystem.solarSystem.itemsDetail?.netFinal ?? {},
            props.solarSystem.solarSystem.itemsDetail?.resourceCapacities ?? {},
            props.solarSystem.solarSystem.itemsDetail?.otherCapacities ?? {},
            "hide-by-typesettings",
            props.itemTypeSettings);

    }, [props.solarSystem, props.itemTypeSettings]);

    return <>
        <tr className="is-hidden-tablet">
            <td colSpan={props.resourceTypeNames.length + 1}>
                <SelectSolarSystemLink
                    hideActionIcons
                    solarSystem={props.solarSystem.solarSystem}
                />
            </td>
        </tr>
        <tr>
            <td className='is-hidden-mobile'>
                <SelectSolarSystemLink
                    hideActionIcons
                    solarSystem={props.solarSystem.solarSystem}
                />
            </td>
            {props.resourceTypeNames.map(x => {

                const item = fancyItems.find(i => i.itemType.typeName === x);

                return <td key={x}>
                    {item !== undefined && <ResourceBarBlock item={item} hideCapacity compressOnMobile />}
                </td>
            })}
            {props.showSendButtons &&
                <td className="is-double-button">
                    <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem.solarSystem} movementTypes={movementTypes} />
                </td>
            }
        </tr>
    </>;
};

export const Resources = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    if (!worldState.ItemTypeSettings) {
        return null;
    }

    const resources = Object.values(worldState.ItemTypeSettings.data)
        .filter(x => x.category === ItemCategory.Resource && x.typeName !== "Credits")
        .sort((a, b) => a.order - b.order);

    const resourceTypeNames = resources.map(x => x.typeName);

    return <Table isFullWidth isFixed className="resources-summary">
        {props.solarSystems.map(s => <Row
            key={s.solarSystem.solarSystemId}
            solarSystem={s}
            resourceTypeNames={resourceTypeNames}
            itemTypeSettings={worldState.ItemTypeSettings!}
            sourceSolarSystemId={sourceSolarSystemId}
            setSourceSolarSystemId={setSourceSolarSystemId}
            showSendButtons={props.solarSystems.length > 1}
        />)}
    </Table>;
});