import { ItemType } from "../../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { Icon } from "../../../Base/Icon";

type Props = {
    relicType: ItemType,
    hideTextOnMobile?: boolean,
    noText?: boolean
}

export const RelicTypeLink = (props: Props) => {

    return <>
        <Icon icon={IconHelper.Items.Relic} />
        {!props.noText && <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {props.relicType.name}
        </span>}
    </>;
};