import { IGameSettings, ISolarSystem, IWorldEffects, ShipClass } from "../ApplicationState/ApiClient";
import { FleetHelper } from "./FleetHelper";
import { TimeHelper } from "./TimeHelper";

export class ApocalypseHelper {

    public static armedDate(gameSettings: IGameSettings, source: ISolarSystem, target: ISolarSystem): Date {

        const distance = FleetHelper.distance(source, target);
        const hoursToArm = gameSettings.gameSpeed * gameSettings.apocalypse.shotArmingHoursPerDistance * distance;

        const now = new Date();

        return TimeHelper.addHours(now, hoursToArm);
    }

    public static costModifierForShipClass(worldEffects: IWorldEffects, shipClass: ShipClass) {

        return 1 + this.rawCostModifierForShipClass(worldEffects, shipClass);
    }

    public static rawCostModifierForShipClass(worldEffects: IWorldEffects, shipClass: ShipClass) {

        const value = worldEffects.shipClassCostModification[ShipClass[shipClass]];

        return value !== undefined ? value : 0;
    }
}