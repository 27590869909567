import { makeAutoObservable } from "mobx";
import { HelpType } from "../ApiClient";
import { ApiState } from "./ApiState";

export class HelpState {

    public factoryVersion: string = "";

    get Help() {
        if (this._help === undefined) {
            this.loadHelpState();
        }

        return this._help;
    }

    private _help: HelpType[] | undefined;

    constructor(private apiState: ApiState) {
        makeAutoObservable(this);
    }

    private loadHelpState() {
        if (this._help === undefined) {
            this._help = [];
            this.apiState.HelpClient.get(this.factoryVersion).then(h => {
                this._help = h;
            });
        }
    }
}