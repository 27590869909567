import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetIcon } from '../../../../Components/FusionShift/Icons/FleetIcon';
import { LocationLink } from '../../../../Components/FusionShift/Links/LocationLink';
import { PlayerLink } from '../../../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { SendShipsBag } from '../../SendShipsBag';

type Props = {
    sendShipsBag: SendShipsBag,
    isShort?: boolean
}

export const TargetTextDescription = observer((props: Props) => {

    if (!props.sendShipsBag.HasTarget) {
        return null;
    }

    return <>
        {!props.isShort && "Target: "}
        {!!props.sendShipsBag.TargetSolarSystem &&
            <>
                <SolarSystemLink solarSystem={props.sendShipsBag.TargetSolarSystem} />
                {!props.isShort &&
                    <>
                        {" - "}
                        {!!props.sendShipsBag.TargetSolarSystem.owner && <PlayerLink player={props.sendShipsBag.TargetSolarSystem.owner} />}
                        {!props.sendShipsBag.TargetSolarSystem.owner && "Unoccupied"}
                    </>
                }
            </>
        }
        {!!props.sendShipsBag.TargetFleet &&
            <>
                <FleetIcon fleet={props.sendShipsBag.TargetFleet} />
                {ValueFormatter.formatMass(props.sendShipsBag.TargetFleet.mass, true)}
                {` fleet owned by `}
                {!!props.sendShipsBag.TargetFleet.owner && <PlayerLink player={props.sendShipsBag.TargetFleet.owner} />}
                {!props.sendShipsBag.TargetFleet.owner && "Unknown Owner"}
                {` targeting `}
                {!!props.sendShipsBag.TargetFleet.targetSolarSystem && <SolarSystemLink solarSystem={props.sendShipsBag.TargetFleet.targetSolarSystem} />}
                {!props.sendShipsBag.TargetFleet.targetSolarSystem && <LocationLink x={props.sendShipsBag.TargetFleet.targetX} y={props.sendShipsBag.TargetFleet.targetY} />}
                {props.sendShipsBag.TargetFleet.isTargetEstimated && ' (Estimated)'}
            </>
        }
    </>;
});