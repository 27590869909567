import { TypeDataDescription } from "../../ApplicationState/ApiClient"
import { Paragraph } from "../Base/Text/Paragraph"

type Props = {
    description: TypeDataDescription | undefined
}

export const TypeDataDescriptionParagraph = (props: Props) => {

    if (props.description === undefined) {
        return null;
    }

    return <Paragraph>
        {props.description.description}
    </Paragraph>;
}