import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from "react-router-dom";
import { ICelestialDetail, ISolarSystemDetail, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../../../Components/Base/Containers/SplitLayout";
import { ErrorMessageBox } from "../../../../../Components/Base/MessageBox";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { DroneHelper } from "../../../../../Helpers/DroneHelper";
import { celestial_ships } from "../../../../../Navigation/Routing/SolarSystem";
import { ActiveTables } from "./ActiveTables";
import { InactiveControl } from "./InactiveControl";
import { SummaryControl } from "./Summary";

type Props = {
    celestial: ICelestialDetail,
    solarSystem: ISolarSystemDetail,
    reloadLocallyCallback: (solarSystem: SolarSystemDetail) => any
}

export const DronesView = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);


    if (!worldState.InstallationTypeSettings || !worldState.ItemTypeSettings || !worldState.CelestialTypeSettings || !worldState.ShipTypeSettings) {
        return null;
    }

    if (!DroneHelper.hasDronesAtCelestial(worldState.InstallationTypeSettings, props.celestial)) {
        return <ErrorMessageBox text={"There is no Drone control at this celestial"} />;
    }

    const summary = DroneHelper.droneSummary(worldState.InstallationTypeSettings, props.solarSystem);

    const canActivateAny = summary.currentActive < summary.maxActive;

    return <>
        <SummaryControl {...summary} />
        {summary.currentActive + summary.currentInactive + summary.currentReturning === 0 &&
            <Paragraph type="prompt">
                There are no drones at {props.solarSystem.name}. Use the <Link to={celestial_ships(props.celestial.celestialId)}>ships</Link> view to build some.
            </Paragraph>}
        <SplitLayout
            left={
                <InactiveControl
                    reloadCallback={props.reloadLocallyCallback}
                    solarSystem={props.solarSystem}
                    installationTypeSettings={worldState.InstallationTypeSettings}
                    shipTypeSettings={worldState.ShipTypeSettings}
                    celestialTypeSettings={worldState.CelestialTypeSettings}
                    itemTypeSettings={worldState.ItemTypeSettings}
                    canActivateAny={canActivateAny} />
            }
            right={
                <ActiveTables
                    reloadCallback={props.reloadLocallyCallback}
                    solarSystem={props.solarSystem}
                    installationTypeSettings={worldState.InstallationTypeSettings}
                    shipTypeSettings={worldState.ShipTypeSettings}
                    celestialTypeSettings={worldState.CelestialTypeSettings}
                    itemTypeSettings={worldState.ItemTypeSettings}
                />
            }
        />
    </>;
});