import { CombatRoundType, ContractType, FleetMovementType, FleetTrade, IGameSettings, IInstallationTypeSettings, ISolarSystemDetail, SecurityStatus, ShipClass, ShipType, SolarSystemClaimType } from "../ApplicationState/ApiClient";
import { FleetWrapper } from "../Entities/FleetWrapper";
import { Validation, valueValid } from "../Entities/Validation";
import { SendShipsBag } from "../Views/SendShips/SendShipsBag";
import { SendShipsStage } from "../Views/SendShips/Stages/StagesControl";
import { BuildHelper } from "./BuildHelper";
import { CollectionHelper } from "./CollectionHelper";
import { ContractHelper } from "./ContractHelper";
import { FleetHelper } from "./FleetHelper";
import { SolarSystemHelper } from "./SolarSystemHelper";
import { ValueFormatter } from "./ValueFormatter";

export type ConfigurationType = "Required" | "Optional" | false;

export type AllowedConfigurations = {
    tactics: ConfigurationType,
    itemsToSend: ConfigurationType,
    agentsToSend: ConfigurationType,
    agentsToCollect: ConfigurationType,
    commoditiesToSend: ConfigurationType,
    itemsToCollect: ConfigurationType,
    trades: ConfigurationType,
    repeat: boolean
};

export type ShipUsage = "disabled" | "normal" | "required" | "satisfying" | "error";

export type DurationSteps = ({ label: string, durationHours: number | undefined } | undefined | false)[];

export class SendShipsHelper {

    public static durationSteps(sendShipsBag: SendShipsBag): DurationSteps | undefined {

        const time = sendShipsBag.timeToTarget();

        if (!time) {
            return undefined;
        }

        const miningDuration = sendShipsBag.MovementType === FleetMovementType.Mine ? sendShipsBag.init.gameSettings.fleets.miningDurationHours * sendShipsBag.init.gameSettings.gameSpeed : undefined;

        const destination = sendShipsBag.TargetSolarSystem ? sendShipsBag.TargetSolarSystem : sendShipsBag.TargetFleetInterception ? sendShipsBag.TargetFleetInterception.coordinate : undefined;

        const contractDuration = sendShipsBag.Contract !== undefined && sendShipsBag.Contract.sourceSolarSystem !== undefined && sendShipsBag.Contract.targetSolarSystem !== undefined ?
            FleetHelper.estimateHoursJourneyDuration(sendShipsBag.fleetWrapper.SolarSystemsPerHour, sendShipsBag.Contract.sourceSolarSystem, sendShipsBag.Contract.targetSolarSystem, sendShipsBag.init.gameSettings)
            : undefined;

        const returnFrom = sendShipsBag.Contract !== undefined && sendShipsBag.Contract.targetSolarSystem !== undefined ? sendShipsBag.Contract.targetSolarSystem : destination;

        const hasReturnJourney = FleetHelper.hasReturnJourney(sendShipsBag.MovementType);
        const returnTime = hasReturnJourney ? FleetHelper.estimateHoursJourneyDuration(sendShipsBag.fleetWrapper.SolarSystemsPerHour, sendShipsBag.init.sourceSolarSystem, returnFrom, sendShipsBag.init.gameSettings) : undefined;

        return [
            {
                label: "Target",
                durationHours: time.durationHours
            },
            {
                label: "Mining",
                durationHours: miningDuration
            },
            {
                label: "Contract",
                durationHours: contractDuration
            },
            {
                label: "Return",
                durationHours: returnTime
            }
        ];

    }

    public static allowedStages(sendShipsBag: SendShipsBag) {
        const possible: (SendShipsStage | boolean)[] = [

            "Target",
            (!sendShipsBag.IsEditingRepeatFleet) && "Movement Type",
            "Ships",

            (sendShipsBag.allowedConfigurations.trades) && "Trade",

            (sendShipsBag.allowedConfigurations.commoditiesToSend || sendShipsBag.allowedConfigurations.itemsToSend) && "Cargo",

            (sendShipsBag.allowedConfigurations.itemsToCollect) && "Collection",

            "Summary"
        ];
        return possible.filter((s): s is SendShipsStage => typeof s !== "boolean");
    }

    public static nextStageFor(currentStage: SendShipsStage, sendShipsBag: SendShipsBag) {

        const allowedStages = this.allowedStages(sendShipsBag);

        for (var i = 0; i < allowedStages.length - 1; i++) {
            if (allowedStages[i] === currentStage) {
                return allowedStages[i + 1];
            }
        }

        return currentStage;
    }

    public static previousStageFor(currentStage: SendShipsStage, sendShipsBag: SendShipsBag) {

        const allowedStages = this.allowedStages(sendShipsBag);

        for (var i = allowedStages.length - 1; i < allowedStages.length; i--) {
            if (allowedStages[i] === currentStage) {
                return allowedStages[i - 1];
            }
        }

        return currentStage;
    }

    public static canRepeat(movementType: FleetMovementType | undefined) {
        return movementType === FleetMovementType.Collection ||
            movementType === FleetMovementType.CommoditySell ||
            movementType === FleetMovementType.Delivery ||
            movementType === FleetMovementType.Mine ||
            movementType === FleetMovementType.Trade ||
            movementType === FleetMovementType.Rebase ||
            movementType === FleetMovementType.Reinforce;
    }

    public static shipUsageForShipType(movementType: FleetMovementType | undefined, fleetWrapper: FleetWrapper, shipType: ShipType, isIntercept: boolean): ShipUsage {

        const qty = shipType.typeName in fleetWrapper.Ships ? fleetWrapper.Ships[shipType.typeName] : 0;

        if (isIntercept) {

            if (movementType === FleetMovementType.Recon) {

                if (shipType.class === ShipClass.Recon) {
                    return fleetWrapper.HasRecon ? "satisfying" : "required";
                }

                return qty > 0 ? "error" : "disabled";

            } else if (!shipType.canIntercept) {
                return qty > 0 ? "error" : "disabled";
            }

        } else if (movementType) {
            if (movementType === FleetMovementType.Recon || movementType === FleetMovementType.Explore) {
                if (shipType.class === ShipClass.Recon) {
                    return fleetWrapper.HasRecon ? "satisfying" : "required";
                }

                return qty > 0 ? "error" : "disabled";
            }

            if (movementType === FleetMovementType.Mine) {
                if (shipType.miningStrength > 0) {
                    return fleetWrapper.TotalMiningStrength > 0 ? "satisfying" : "required";
                }
            }

            if (movementType === FleetMovementType.Claim) {
                if (shipType.canClaim) {
                    return fleetWrapper.CanClaim ? "satisfying" : "required";
                }
            }
        }


        return "normal";
    }

    private static configurationType(movementType: FleetMovementType | undefined, required: FleetMovementType[] | undefined, optional: FleetMovementType[] | undefined): ConfigurationType {
        if (movementType !== undefined) {

            if (required !== undefined && required.includes(movementType)) {
                return "Required";
            }
            if (optional !== undefined && optional.includes(movementType)) {
                return "Optional";
            }
        }

        return false;
    }

    public static allowedConfigurationsForMovementType(isIntercept: boolean, movementType: FleetMovementType | undefined, contractType: ContractType | undefined): AllowedConfigurations {
        return {
            tactics: this.configurationType(movementType, undefined, [FleetMovementType.Attack, FleetMovementType.Reinforce, FleetMovementType.Mine]),
            itemsToSend: contractType === undefined && this.configurationType(movementType, [FleetMovementType.Delivery], [FleetMovementType.Claim, FleetMovementType.Rebase]),
            agentsToSend: !isIntercept && contractType === undefined && this.configurationType(movementType, [FleetMovementType.Delivery], [FleetMovementType.Claim, FleetMovementType.Rebase, FleetMovementType.Attack]),
            agentsToCollect: !isIntercept && contractType === undefined && this.configurationType(movementType, [FleetMovementType.Collection], undefined),
            commoditiesToSend: contractType === undefined && this.configurationType(movementType, [FleetMovementType.Delivery, FleetMovementType.CommoditySell], [FleetMovementType.Claim, FleetMovementType.Rebase]),
            trades: contractType === undefined && this.configurationType(movementType, [FleetMovementType.Trade], undefined),
            itemsToCollect: contractType === undefined && this.configurationType(movementType, [FleetMovementType.Collection], undefined),
            repeat: this.canRepeat(movementType)
        }
    }

    public static alwaysShowCelestialFor(fleetType: FleetMovementType | undefined) {
        if (!fleetType) {
            return false;
        }
        return fleetType === FleetMovementType.Mine || fleetType === FleetMovementType.Claim || fleetType === FleetMovementType.CommoditySell;
    }

    private static validateCapacity(fleet: FleetWrapper, items: { [key: string]: number } | undefined, trade: FleetTrade | undefined) {

        const totalCargoCapacity = fleet.TotalCargoCapacity;
        const sum = CollectionHelper.sumOfDictionary(items) + (trade ? trade.quantity : 0);

        return valueValid(sum <= totalCargoCapacity, sum > totalCargoCapacity ? `${ValueFormatter.formatLocaleNumber(sum)} / ${ValueFormatter.formatLocaleNumber(totalCargoCapacity)} capacity` : "");
    }

    public static validate(sendShipsBag: SendShipsBag): Validation {

        if (!!sendShipsBag.TargetSolarSystem && sendShipsBag.TargetSolarSystem.solarSystemId === sendShipsBag.init.sourceSolarSystem.solarSystemId) {
            return valueValid(false, "Can't target source solar system");
        }

        if (sendShipsBag.MovementType === undefined || !sendShipsBag.init.player) {
            return valueValid(false, "");
        }
        if (!sendShipsBag.HasTarget) {
            return valueValid(false, "Invalid target");
        }
        if (!CollectionHelper.isAnyQuantityInDictionary(sendShipsBag.shipQuantities)) {
            return valueValid(false, "Select some ships to send");
        }
        if (FleetHelper.isHostileMovementType(sendShipsBag.MovementType) && sendShipsBag.TargetOwner &&
            sendShipsBag.TargetOwner.securityStatus === SecurityStatus.Protected) {
            return valueValid(false, `${sendShipsBag.TargetOwner.name} has beginner's protection`);
        }

        if (sendShipsBag.TargetFleet && sendShipsBag.TargetFleetInterception === undefined) {
            return valueValid(false, "Unable to intercept the target at that speed");
        }
        if (sendShipsBag.TargetFleet && sendShipsBag.TargetFleet.arrivalDate &&
            sendShipsBag.TargetFleetInterception && sendShipsBag.TargetFleetInterception.time.getTime() >= sendShipsBag.TargetFleet.arrivalDate.getTime()) {
            return valueValid(false, "Unable to intercept the target before it arrives");
        }

        const fleetWrapper = sendShipsBag.fleetWrapper;

        const target = sendShipsBag.TargetFleetInterception !== undefined ? sendShipsBag.TargetFleetInterception.coordinate : sendShipsBag.TargetSolarSystem;

        if (target !== undefined) {
            const key = sendShipsBag.init.gameSettings.solarSystem.shipUpkeepItemTypeName;
            const distance = FleetHelper.distance(sendShipsBag.init.sourceSolarSystem, target);
            const cost = FleetHelper.calculateCostToSendShips(sendShipsBag.init.gameSettings, sendShipsBag.fleetWrapper, distance, sendShipsBag.MovementType);

            if (sendShipsBag.init.sourceSolarSystem.items === undefined ||
                !(key in sendShipsBag.init.sourceSolarSystem.items) ||
                cost > sendShipsBag.init.sourceSolarSystem.items[key]) {
                return valueValid(false, "Unable to afford cost to send fleet");
            }
        }

        if (sendShipsBag.TargetFleet && sendShipsBag.MovementType === FleetMovementType.Attack) {
            for (let ship of fleetWrapper.Ships) {
                if (!ship.ship.canIntercept && ship.quantity > 0) {
                    return valueValid(false, `${ship.ship.name} can't be sent to intercept and attack`);
                }
            }
        }

        if (sendShipsBag.Contract !== undefined) {
            let expectedDate: Date | undefined = undefined;

            if (ContractHelper.fleetMovementTypeForContract(sendShipsBag.Contract) !== sendShipsBag.MovementType) {
                return valueValid(false, "Configured fleet type does not match the contract");
            }

            if (ContractHelper.isAttackTerms(sendShipsBag.Contract.terms)) {
                if (sendShipsBag.fleetWrapper.TotalAttack(sendShipsBag.IsIntercept) < sendShipsBag.Contract.terms.minimumTotalAttack) {
                    return valueValid(false, "Total attack does not meet the contract terms");
                }

                expectedDate = sendShipsBag.Contract.terms.arriveByDate;
            }
            if (ContractHelper.isCourierTerms(sendShipsBag.Contract.terms)) {
                if (sendShipsBag.fleetWrapper.TotalCargoCapacity < sendShipsBag.Contract.terms.minimumTotalDefence) {
                    return valueValid(false, "Total defence does not meet the contract terms");
                }
                expectedDate = sendShipsBag.Contract.terms.expiryDate;
            }

            if (ContractHelper.isReconTerms(sendShipsBag.Contract.terms)) {
                expectedDate = sendShipsBag.Contract.terms.arriveByDate;
            }
            if (ContractHelper.isDefendTerms(sendShipsBag.Contract.terms)) {
                expectedDate = sendShipsBag.Contract.terms.arriveByDate;
            }

            if (expectedDate !== undefined) {

                if (typeof expectedDate === "string") {
                    expectedDate = new Date(expectedDate);
                }
                const arrival = sendShipsBag.timeToTarget();

                if (arrival === undefined || arrival.date > expectedDate) {
                    return valueValid(false, "Fleet will not arrive in time");
                }
            }
        }

        if (sendShipsBag.MovementType === FleetMovementType.Attack) {
            if (fleetWrapper.TotalAttack(sendShipsBag.IsIntercept) <= 0) {
                return valueValid(false, "Select some ships with attack strength");
            }
            return valueValid(true);
        }
        if (sendShipsBag.MovementType === FleetMovementType.Rebase) {
            if (!!sendShipsBag.TargetSolarSystem && sendShipsBag.TargetSolarSystem.playerId !== sendShipsBag.init.player!.playerId) {
                return valueValid(false, "Can only target a system that you own");
            }
            return this.validateCapacity(sendShipsBag.fleetWrapper, sendShipsBag.itemsToSend, sendShipsBag.Orders.trade);
        }
        if (sendShipsBag.MovementType === FleetMovementType.Delivery) {

            if (!CollectionHelper.isAnyQuantityInDictionary(sendShipsBag.itemsToSend) && sendShipsBag.agentIdsToSend.length === 0) {
                return valueValid(false, "Select some items to send");
            }
            return this.validateCapacity(sendShipsBag.fleetWrapper, sendShipsBag.itemsToSend, sendShipsBag.Orders.trade);
        }
        if (sendShipsBag.MovementType === FleetMovementType.Collection && sendShipsBag.Contract === undefined) {
            if (!CollectionHelper.isAnyQuantityInDictionary(sendShipsBag.itemsToCollect) && sendShipsBag.agentIdsToCollect.length === 0) {
                return valueValid(false, "Select some items to collect");
            }
            return this.validateCapacity(sendShipsBag.fleetWrapper, sendShipsBag.itemsToCollect, undefined);
        }
        if (sendShipsBag.MovementType === FleetMovementType.CommoditySell) {
            if (!sendShipsBag.TargetSolarSystem) {
                return valueValid(false, "Select a solar system as the target");
            }
            if (!CollectionHelper.isAnyQuantityInDictionary(sendShipsBag.itemsToSend)) {
                return valueValid(false, "Select some commodities to sell");
            }
            if (sendShipsBag.TargetCelestialId === undefined || !sendShipsBag.TargetSolarSystem.celestials) {
                return valueValid(false, "Select a celestial to trade with");
            }
            if (!sendShipsBag.TargetSolarSystem.commodityBuyOffersByCelestialId || !(sendShipsBag.TargetCelestialId in sendShipsBag.TargetSolarSystem.commodityBuyOffersByCelestialId)) {
                return valueValid(false, "Select a celestial that is buying commodities");
            }
            return this.validateCapacity(sendShipsBag.fleetWrapper, sendShipsBag.itemsToSend, sendShipsBag.Orders.trade);
        }
        if (sendShipsBag.MovementType === FleetMovementType.Mine) {
            if (!sendShipsBag.TargetSolarSystem) {
                return valueValid(false, "Select a solar system as the target");
            }
            if (fleetWrapper.TotalMiningStrength <= 0) {
                return valueValid(false, "Select some mining ships to send");
            }
            if (sendShipsBag.TargetCelestialId === undefined || !sendShipsBag.TargetSolarSystem.celestials) {
                return valueValid(false, "Select a celestial to mine");
            }
            const celestial = sendShipsBag.TargetSolarSystem.celestials.find(c => c.celestialId === sendShipsBag.TargetCelestialId);
            const celestialType = celestial && celestial.celestialTypeName && celestial.celestialTypeName in sendShipsBag.init.celestialTypeSettings.data ? sendShipsBag.init.celestialTypeSettings.data[celestial.celestialTypeName] : null;
            const valid = celestialType && celestialType.generatedItemTypeName in sendShipsBag.init.itemTypeSettings.data;

            if (!valid) {
                return valueValid(false, "Select a celestial that generates resources");
            }
        }
        if (sendShipsBag.MovementType === FleetMovementType.Claim) {
            if (!sendShipsBag.TargetSolarSystem) {
                return valueValid(false, "Select a solar system as the target");
            }

            const solarSystems = (sendShipsBag.init.getAllSolarSystems() ?? []).map(x => x.solarSystem);
            const claimType = sendShipsBag.Orders.claimType ?? SolarSystemClaimType.Colony;

            if (!this.hasAvailableSolarSystemCapacity(solarSystems, sendShipsBag.init.gameSettings, sendShipsBag.init.installationTypeSettings, claimType)) {

                const desc = claimType === SolarSystemClaimType.Colony ? "colonies" : "outposts";

                return valueValid(false, `No available capacity for more ${desc}. Upgrade your Bureaucratic Hub to increase capacity`);
            }
            if (!fleetWrapper.CanClaim) {
                return valueValid(false, "Select at least 1 colonization ship to send")
            }
            if (sendShipsBag.TargetCelestialId === undefined || !sendShipsBag.TargetSolarSystem.celestials) {
                return valueValid(false, "Select a celestial");
            }

            const celestial = sendShipsBag.TargetSolarSystem.celestials.find(c => c.celestialId === sendShipsBag.TargetCelestialId);
            let invalid = true;
            if (celestial) {
                for (let r of Object.keys(sendShipsBag.init.installationTypeSettings.data)) {
                    if (sendShipsBag.init.installationTypeSettings.data[r].solarSystemClaimType === claimType && BuildHelper.isCelestialTypeValid(sendShipsBag.init.installationTypeSettings.data[r], celestial.celestialTypeName)) {
                        invalid = false;
                        break;
                    }
                }
            }
            if (invalid) {
                return valueValid(false, claimType === SolarSystemClaimType.Colony ? "Select a habitable celestial" : "Select a valid celestial");
            }
            return this.validateCapacity(sendShipsBag.fleetWrapper, sendShipsBag.itemsToSend, sendShipsBag.Orders.trade);
        }
        if (sendShipsBag.MovementType === FleetMovementType.Recon) {
            if (!fleetWrapper.HasRecon) {
                return valueValid(false, "Select at least 1 recon ship to send")
            }
            if (!fleetWrapper.HasOnlyRecon) {
                return valueValid(false, "Select only recon ships")
            }
            return valueValid(true);
        }
        if (sendShipsBag.MovementType === FleetMovementType.Explore) {
            if (!fleetWrapper.HasRecon) {
                return valueValid(false, "Select at least 1 recon ship to send")
            }
            if (!fleetWrapper.HasOnlyRecon) {
                return valueValid(false, "Select only recon ships")
            }
            return valueValid(true);
        }
        if (sendShipsBag.MovementType === FleetMovementType.Trade) {
            if (!sendShipsBag.TargetSolarSystem) {
                return valueValid(false, "Select a solar system as the target");
            }
            if (!sendShipsBag.Orders.trade) {
                return valueValid(false, "Select something to trade");
            }
            if (sendShipsBag.Orders.trade.quantity <= 0) {
                return valueValid(false, "Trade quantity can't be zero")
            }
            const availableOfOffered = this.getAvailableOfOffered(sendShipsBag.Orders.trade.offeredItemTypeName, sendShipsBag.init.sourceSolarSystem.items, sendShipsBag.init.gameSettings);
            if (sendShipsBag.Orders.trade.quantity > availableOfOffered) {
                return valueValid(false, "Trade quantity exceeds available goods in system");
            }
            return this.validateCapacity(sendShipsBag.fleetWrapper, sendShipsBag.itemsToSend, sendShipsBag.Orders.trade);
        }

        return valueValid(true, "");
    }

    private static getAvailableOfOffered(itemTypeName: string, items: { [key: string]: number } | undefined, gameSettings: IGameSettings) {

        if (itemTypeName === gameSettings.economy.creditsPlaceholderItemTypeName) {
            return Number.MAX_SAFE_INTEGER;
        }

        if (!items) {
            return 0;
        }

        if (itemTypeName in items) {
            return items[itemTypeName];
        }

        return 0;
    }

    public static hasAvailableSolarSystemCapacity(solarSystems: ISolarSystemDetail[], gameSettings: IGameSettings, installationTypeSettings: IInstallationTypeSettings, claimType: SolarSystemClaimType) {
        let level = 0;
        for (const s of solarSystems) {
            for (const c of s.celestials) {
                if (c.installations) {
                    for (const i of c.installations) {
                        if (i.installationTypeName in installationTypeSettings.data &&
                            installationTypeSettings.data[i.installationTypeName].solarSystemClaimType === SolarSystemClaimType.Capital) {
                            level += i.level;
                        }
                    }
                }
            }
        }

        if (claimType === SolarSystemClaimType.Outpost) {
            const outposts = solarSystems.filter(x => x.claimType === SolarSystemClaimType.Outpost).length;
            return outposts < SolarSystemHelper.maximumOutposts(level, gameSettings);
        }

        const otherSystems = solarSystems.filter(x => x.claimType !== SolarSystemClaimType.Outpost).length;

        return level > otherSystems;
    }

    public static defaultTactics(availableShips: FleetWrapper) {
        const tactic: { [key: string]: CombatRoundType; } = {};
        for (let s of availableShips.Ships) {
            tactic[s.ship.typeName] = s.ship.defaultCombatRoundType;
        }
        return tactic;
    }
}