import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ICelestial, ISolarSystem, MegacitySector, SectorType } from '../../ApplicationState/ApiClient';
import { CreditsStateContext, SolarSystemStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { Level, levelContentWithHeading } from '../../Components/Base/Containers/Level';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Table } from '../../Components/Base/Containers/Table';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { H3 } from '../../Components/Base/Text/H';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { CelestialImage } from '../../Components/FusionShift/Celestials/CelestialImage';
import { CreditsIconWithQuantity } from '../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { SectorTypeLink } from '../../Components/FusionShift/Links/SectorTypeLink';
import { SolarSystemLink } from '../../Components/FusionShift/Links/SolarSystemLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { MegacityHelper } from '../../Helpers/MegacityHelper';

export const Summary = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const creditsState = React.useContext(CreditsStateContext);
    const worldState = React.useContext(WorldStateContext);

    if (worldState.SectorTypeSettings === undefined || solarSystemState.SolarSystems === undefined || !worldState.GameSettings) {
        return null;
    }

    const content = [
        levelContentWithHeading("Balance", <CreditsIconWithQuantity quantity={creditsState.Balance} />),
        levelContentWithHeading("Per Hour", <CreditsIconWithQuantity quantity={creditsState.PerHour} isIncome />)
    ];

    const sectors = React.useMemo(() => {

        if (solarSystemState.SolarSystemsDetail === undefined ||
            worldState.SectorTypeSettings === undefined ||
            worldState.GameSettings === undefined) {
            return undefined;
        }

        const data: {
            solarSystem: ISolarSystem,
            celestial: ICelestial,
            sector: MegacitySector,
            sectorType: SectorType,
            perHour: number
        }[] = [];

        for (const solarSystem of solarSystemState.SolarSystemsDetail) {
            for (const celestial of solarSystem.solarSystem.celestials) {
                if (celestial.megacity !== undefined) {
                    for (const s in celestial.megacity.sectors) {
                        if (s in worldState.SectorTypeSettings.data) {
                            const sectorType = worldState.SectorTypeSettings.data[s];

                            if (sectorType.generatedCredits > 0) {

                                const sector = celestial.megacity.sectors[s];

                                const perHour = MegacityHelper.calculateGeneratedCredits(worldState.GameSettings, sectorType, sector.assignedPopulation);

                                data.push({
                                    solarSystem: solarSystem.solarSystem,
                                    celestial,
                                    sector,
                                    sectorType,
                                    perHour
                                });
                            }
                        }
                    }
                }
            }
        }

        return data;
    }, [solarSystemState.SolarSystemsDetail, worldState.SectorTypeSettings, worldState.GameSettings]);

    let lastCelestialId: number | undefined = undefined;

    return <SubPanel heading={{ text: "Summary", icon: IconHelper.Categories.Summary }}>
        <Level content={content} />
        {sectors === undefined && <LoadingSpinner />}
        {sectors !== undefined && sectors.length === 0 && <Paragraph>No income</Paragraph>}
        {sectors !== undefined && sectors.length > 0 &&
            <Table
                isFixed
                hideHeaderMobile
                heading={
                    <>
                        <th>
                            Solar System
                        </th>
                        <th colSpan={2}>
                            Celestial
                        </th>
                        <th>
                            Sector
                        </th>
                        <th>
                            Per Hour
                        </th>
                    </>
                }>
                {sectors.map(x => {
                    const isNewCelestial = lastCelestialId !== x.celestial.celestialId;
                    lastCelestialId = x.celestial.celestialId;

                    return <React.Fragment key={`${x.celestial.celestialId}_${x.sectorType.typeName}`}>
                        {isNewCelestial &&
                            <tr className='is-hidden-tablet'>
                                <td >
                                    <SolarSystemLink solarSystem={x.solarSystem} />
                                </td>
                                <td>
                                    <H3 className="celestial-name is-pulled-right">
                                        {x.celestial.name}
                                    </H3></td>
                            </tr>}
                        <tr>
                            <td className='is-hidden-mobile' >
                                {isNewCelestial && <SolarSystemLink solarSystem={x.solarSystem} isHiddenMobile />}
                            </td>
                            <td className='is-hidden-mobile' >
                                {isNewCelestial && <H3 className="celestial-name">
                                    {x.celestial.name}
                                </H3>}
                            </td>
                            <td className='is-hidden-mobile' >
                                {isNewCelestial &&
                                    <CelestialImage celestial={x.celestial} />
                                }
                            </td>
                            <td>
                                <SectorTypeLink sectorType={x.sectorType} />
                            </td>
                            <td>
                                <CreditsIconWithQuantity quantity={x.perHour} isIncome className='is-pulled-right' />
                            </td>
                        </tr>
                    </React.Fragment>
                })}
            </Table>}
    </SubPanel>;
});