import * as React from "react";
import { CelestialInfo, CelestialSize, ICelestialTypeSettings, IInstallationTypeSettings, InstallationType, ISolarSystemMinimal } from "../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../Components/Base/Containers/Table";
import { InstallationTypeImage } from "../../../Components/FusionShift/Images/InstallationTypeImage";
import { InstallationTypeLink } from "../../../Components/FusionShift/Links/InstallationTypeLink";
import { CelestialHelper } from "../../../Helpers/CelestialHelper";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";

type CelestialInfoWithInstallationTypeName = CelestialInfo & {
    installationTypeName: string
}

type Props<T extends CelestialInfo> = {
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    celestials: T[],
    renderExtra: (c: T, i: InstallationType) => React.ReactNode,
    solarSystem: ISolarSystemMinimal | undefined
}

export const CelestialList = <T extends CelestialInfoWithInstallationTypeName>(props: Props<T>) => {

    const grouped = CollectionHelper.groupBy(props.celestials, c => c.celestialName);
    const keys = Object.keys(grouped);

    return <>
        {keys.map(x => {

            const data = grouped[x];

            if (data.length === 0) {
                return null;
            }

            const c = data[0];

            const typeName = c.celestialTypeName === undefined ? "Unknown" :
                props.installationTypeSettings === undefined ? c.celestialTypeName : props.celestialTypeSettings.data[c.celestialTypeName].name;

            const title = `${c.celestialName}: ${CelestialSize[c.size]} ${typeName}`;

            const backgroundImage = CelestialHelper.celestialBackground(c, props.solarSystem?.playerId !== undefined && props.solarSystem?.playerId !== null);

            return <SubPanel key={x}
                heading={{
                    text: title,
                    icon: undefined
                }}
                isUnpadded
                backgroundImage={backgroundImage}
            >
                <Table isFullWidth isFixed>
                    {data.map((d, i) => {

                        const installationType = props.installationTypeSettings.data[d.installationTypeName];

                        return <tr key={i}>
                            <td>
                                <InstallationTypeImage size="medium" installationType={installationType} />
                            </td>
                            <td>
                                <InstallationTypeLink installationType={installationType} />
                            </td>
                            <td>
                                {props.renderExtra(d, installationType)}
                            </td>
                        </tr>;
                    })}
                </Table>
            </SubPanel>;
        })}
    </>;
};
