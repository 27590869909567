import classNames from 'classnames';
import * as React from 'react';

type Props = {
    value: number,
    max: number,
    classNameCallback?: ((value: number) => string | undefined) | undefined
}

export const BlockProgress = (props: Props) => {

    const values: number[] = [];

    for (let i = 1; i <= props.max; i++) {
        values.push(i);
    }

    return <div className="block-progress">
        {values.map(i => {
            const className = classNames(
                props.value >= i ? "is-complete progress-block" : "progress-block",
                props.classNameCallback !== undefined ? props.classNameCallback(i) : undefined
            );

            return <span key={i} className={className}>
                {i}
            </span>
        }
        )}
    </div>;
};