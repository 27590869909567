import classNames from "classnames";
import React from "react";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { QuantityIcon, QuantityIconProps } from "../QuantityIcon";

type Props = QuantityIconProps & {
    label?: string | undefined,
    className?: string | undefined
}

export const CommoditiesIcon = (props: Props) => {

    const name = props.label ? props.label : "Commodities";

    const className = classNames("commodities", props.className);

    return <QuantityIcon name={name} icon={IconHelper.Items.Commodity} className={className} {...props} />;
};

