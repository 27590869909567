import { makeAutoObservable } from "mobx";
import { SolarSystemWrapper } from "../../Entities/SolarSystem/SolarSystemWrapper";
import { SolarSystemHelper } from "../../Helpers/SolarSystemHelper";
import { ApocalypseShot, FleetMovementType, ISolarSystemDetail, ISolarSystemForList, SolarSystemDetail } from "../ApiClient";
import { ApiState } from "./ApiState";
import { WorldState } from "./WorldState";

type LoadingSolarSystem = "Pending" | "Loading" | SolarSystemWrapper;
type SwitchState = "Ready" | "Loading";

export class SolarSystemState {

    public SwitchState: SwitchState = "Ready";

    public HasLoaded: boolean = false;

    public get SolarSystem() { return this.CurrentSolarSystem?.solarSystem; }
    public CurrentSolarSystem: SolarSystemWrapper | undefined;

    public SolarSystems: ISolarSystemForList[] = [];

    public ApocalypseShots: ApocalypseShot[] = [];

    private _loadedSolarSystems: { [key: number]: LoadingSolarSystem } = {};

    private _log = false;

    constructor(private _worldState: WorldState, private _apiState: ApiState) {

        makeAutoObservable(this);
    }

    private createWrapper(solarSystemDetail: ISolarSystemDetail) {

        const existing = solarSystemDetail.solarSystemId in this._loadedSolarSystems ? this._loadedSolarSystems[solarSystemDetail.solarSystemId] : undefined;

        const loadNumber = existing !== undefined && typeof existing !== "string" ? existing.loadNumber : 0;

        return new SolarSystemWrapper(
            this._worldState.ItemTypeSettings!,
            this._worldState.ShipTypeSettings!,
            this._worldState.InstallationTypeSettings!,
            this._worldState.SectorTypeSettings!,
            this._worldState.AgentActionTypeSettings!,

            this._worldState.GameSettings!,
            this._worldState.Effects!,
            solarSystemDetail,
            loadNumber + 1
        );
    }

    private replaceMenuItem(wrapped: SolarSystemWrapper) {
        const index = this.SolarSystems.findIndex(x => x.solarSystemId === wrapped.solarSystem.solarSystemId);

        if (index >= 0) {

            const danger = SolarSystemHelper.getSolarSystemDanger(wrapped.solarSystem, this._worldState.InstallationTypeSettings!);

            const oldMenuItem = this.SolarSystems[index];

            const newMenuItem: ISolarSystemForList = {
                ...oldMenuItem,
                ...wrapped.solarSystem,
                numberOfApocalypseShots: danger.hasApocalpyse ? 1 : 0,
                numberOfAttacks: danger.incomingHostileMovements.find(x => x === FleetMovementType.Attack) !== undefined ? 1 : 0,
                numberOfRecons: danger.incomingHostileMovements.find(x => x === FleetMovementType.Recon) !== undefined ? 1 : 0
            };

            this.SolarSystems[index] = newMenuItem;
        }
    }

    public loadSolarSystemIfRelevant(solarSystemDetail: SolarSystemDetail) {
        if (this.SolarSystems.find(x => x.solarSystemId === solarSystemDetail.solarSystemId)) {
            this.loadSolarSystem(solarSystemDetail);
        }
    }

    public loadSolarSystem(solarSystemDetail: ISolarSystemDetail) {

        this.logSystem(solarSystemDetail, "loadSolarSystem");

        const wrapped = this.createWrapper(solarSystemDetail);

        this._loadedSolarSystems[solarSystemDetail.solarSystemId] = wrapped;

        this.replaceMenuItem(wrapped);

        for (const apoc of solarSystemDetail.apocalypseShots) {
            if (this.ApocalypseShots.find(x => x.apocalypseShotId === apoc.apocalypseShotId) === undefined) {
                this.ApocalypseShots.push(apoc);
            }
        }

        if (this.SolarSystem !== undefined && this.SolarSystem.solarSystemId === wrapped.solarSystemId) {

            this.logSystem(solarSystemDetail, "loadSolarSystem - replaced this.CurrentSolarSystem");

            this.CurrentSolarSystem = wrapped;
        }

        return wrapped;
    }

    public loadSolarSystemList(solarSystems: ISolarSystemForList[]) {

        const loaded: { [key: number]: LoadingSolarSystem } = {};

        for (const solarSystem of solarSystems) {
            loaded[solarSystem.solarSystemId] = "Pending";
        }

        this._loadedSolarSystems = loaded;
        this.SolarSystems = solarSystems;
        this.HasLoaded = true;
    }

    public switchSolarSystem(solarSystemId: number | undefined) {

        if (solarSystemId === undefined) {
            this.CurrentSolarSystem = undefined;
            this.SwitchState = "Ready";
            return;
        }

        if (solarSystemId in this._loadedSolarSystems) {
            const solarSystem = this._loadedSolarSystems[solarSystemId];

            if (solarSystem === "Pending") {
                this.SwitchState = "Loading";
                this._apiState.SolarSystemClient.getDetail(solarSystemId).then(x => {
                    this.CurrentSolarSystem = this.loadSolarSystem(x);
                    this.SwitchState = "Ready";
                });
            } else if (solarSystem !== "Loading") {
                this.CurrentSolarSystem = solarSystem;
                this.SwitchState = "Ready";
            }
        }
    }

    public get SolarSystemsDetail(): SolarSystemWrapper[] | undefined {
        const toLoad: number[] = [];
        let isValid = true;

        for (const solarSystemId of Object.keys(this._loadedSolarSystems)) {
            const solarSystem = this._loadedSolarSystems[solarSystemId];

            if (solarSystem === "Pending") {
                toLoad.push(Number(solarSystemId));
            } else if (solarSystem === "Loading") {
                isValid = false;
            }
        }

        if (toLoad.length > 0) {
            isValid = false;

            for (const solarSystemId of toLoad) {
                this._loadedSolarSystems[solarSystemId] = "Loading";
            }

            console.log("Loading " + toLoad.length);

            this._apiState.SolarSystemClient.bySolarSystemIds(toLoad).then(x => {
                for (const solarSystem of x) {
                    this.loadSolarSystem(solarSystem);
                }
            })
        }

        return isValid ? Object.values(this._loadedSolarSystems).map(x => {
            if (!(typeof x === "string")) {
                return x;
            }

            return undefined;
        }).filter(x => x != undefined)
            .map(x => x!)
            .sort((a, b) => (a.solarSystem.claimedDate ?? new Date()) < (b.solarSystem.claimedDate ?? new Date()) ? -1 : 1) : undefined;
    }

    public initialLoad(solarSystems: ISolarSystemForList[], solarSystem: ISolarSystemDetail | undefined, solarSystemId: number | undefined) {

        this.loadSolarSystemList(solarSystems);
        if (solarSystem !== undefined) {
            this.loadSolarSystem(solarSystem);
        }
        this.switchSolarSystem(solarSystemId);
    }

    public addApocalypseShot(apocalypseShot: ApocalypseShot) {
        const apocalypseShots = this.ApocalypseShots.map(x => x.apocalypseShotId === apocalypseShot.apocalypseShotId ? apocalypseShot : x);
        if (apocalypseShots.find(x => x.apocalypseShotId === apocalypseShot.apocalypseShotId) === undefined) {
            apocalypseShots.push(apocalypseShot);
        }

        this.ApocalypseShots = apocalypseShots;
    }

    public clear() {
        this.CurrentSolarSystem = undefined;
        this.SolarSystems = [];
        this._loadedSolarSystems = {};
    }

    public getSolarSystemDetailById(solarSystemId: number | undefined): Promise<SolarSystemWrapper | undefined> {
        if (solarSystemId === undefined) {
            return new Promise((resolve, reject) => undefined);
        }

        this.log(`getSolarSystemDetailById ${solarSystemId}`);

        if (solarSystemId in this._loadedSolarSystems) {
            if (typeof this._loadedSolarSystems[solarSystemId] === "string") {

                this.log(`getSolarSystemDetailById ${solarSystemId} calling API`);

                return this._apiState.SolarSystemClient.getDetail(solarSystemId).then(s => {
                    return this.loadSolarSystem(s);
                });
            }
            else {

                this.log(`getSolarSystemDetailById ${solarSystemId} already loaded`);

                const alreadyLoadedSolarSystem = this._loadedSolarSystems[solarSystemId];

                return new Promise((resolve, reject) => {
                    resolve(alreadyLoadedSolarSystem as any);
                });
            }
        } else {
            this.log(`getSolarSystemDetailById ${solarSystemId} not in list of expected solarSystems`);
        }

        return new Promise((resolve, reject) => undefined);
    }

    private logSystem(solarSystem: { solarSystemId: number, name: string }, message: string) {
        this.log(`${solarSystem.solarSystemId} - ${solarSystem.name}: ${message}`);
    }

    private log(message: string) {
        if (this._log) {
            console.log(message);
        }
    }
}