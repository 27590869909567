import React from "react";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    sectorTypeName: string,
    isGiant?: boolean
}

export const SectorTypeIcon = (props: Props) => {

    return <Icon title={props.sectorTypeName} icon={IconHelper.Megacities.sectorType(props.sectorTypeName)} isGiant={props.isGiant} />;
};