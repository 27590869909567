import React from "react"
import { Link } from "react-router-dom"
import { InstallationType, ItemType, SchematicType, SectorType } from "../../../../ApplicationState/ApiClient"
import { ExpandButton } from "../../../../Components/Base/Buttons/ExpandButton"
import { SplitLayout } from "../../../../Components/Base/Containers/SplitLayout"
import { Table } from "../../../../Components/Base/Containers/Table"
import { Paragraph } from "../../../../Components/Base/Text/Paragraph"
import { SectorTypeDescription } from "../../../../Components/FusionShift/Descriptions/SectorTypeDescription"
import { InstallationTypeImage } from "../../../../Components/FusionShift/Images/InstallationTypeImage"
import { CommodityTypeLink } from "../../../../Components/FusionShift/Links/Items/CommodityTypeLink"
import { ItemTypeLink } from "../../../../Components/FusionShift/Links/Items/ItemTypeLink"
import { SectorTypeLink } from "../../../../Components/FusionShift/Links/SectorTypeLink"
import { help_commodityTypes, help_page, help_sectorTypes } from "../../../../Navigation/Routing/Help"

type Props = {
    commoditySchematics: {
        schematic: SchematicType,
        outputItem: ItemType,
        inputItems: ItemType[],
        installation: {
            installation: InstallationType,
            megacitySector: SectorType
        }
    }[]
}

export const CommoditiesSchematicsTable = (props: Props) => {

    const [expandedSchematic, setExpandedSchematic] = React.useState("");

    return <>
        <Paragraph>
            Providing your Megacity sectors with commodities can greatly increase the bonuses they offer.  See the <Link to={help_page("megacities")}>Megacities</Link>, <Link to={help_sectorTypes}>sectors</Link> and <Link to={help_commodityTypes}>commodities</Link> help pages for more information.
        </Paragraph>
        <Table isFixed isFullWidth
            heading={<>
                <th>
                    Resources
                </th>
                <th>
                    Produces
                </th>
                <th>
                    Sector
                </th>
                <th />
            </>}
        >
            {props.commoditySchematics.map(x => <React.Fragment key={x.schematic.typeName}>
                <tr>
                    <td>
                        <ul>
                            {x.inputItems.map(y => <li key={y.typeName}>
                                <ItemTypeLink itemType={y} /> x{x.schematic.costPerRun.items[y.typeName]}
                            </li>)}
                        </ul>
                    </td>
                    <td>
                        <CommodityTypeLink commodityType={x.outputItem} />
                    </td>
                    <td>
                        <SectorTypeLink sectorType={x.installation.megacitySector} />
                    </td>
                    <td>
                        <ExpandButton isExpanded={x.schematic.typeName === expandedSchematic} setIsExpanded={e => setExpandedSchematic(e ? x.schematic.typeName : "")} />
                    </td>
                </tr>
                {expandedSchematic === x.schematic.typeName && <tr>
                    <td colSpan={4}>
                        <SplitLayout
                            horizontalAfter="tablet"
                            left={
                                <InstallationTypeImage installationType={x.installation.installation} />
                            }
                            right={
                                <SectorTypeDescription sectorType={x.installation.megacitySector} />
                            }
                        />
                    </td>
                </tr>}
            </React.Fragment>
            )}
        </Table>
    </>;
}