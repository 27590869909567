import { ChangeBlob } from './ChangeBlob';

export const recentChanges : ChangeBlob[] = [
	{
		date: new Date('2024-05-15'),
		changes: [
			'##### UI',
			'* Show target celestial size and time to reach target on edit mining repeats form'
		],
	},
	{
		date: new Date('2024-05-16'),
		changes: [
			'##### Notifications',
			'* Added a notification for when one of your fleets is intercepted. This notification is only available if the source system of the intercept is within your radar range'
		],
	},
	{
		date: new Date('2024-05-17'),
		changes: [
			'##### UI',
			'* Added clear indication when ships are added to the build queue',
			'* Replaced drop shadow with border, improving rendering performance',
			'* Added empire view link to top of info menu',
			'* Added intercept filter to combat reports',
			'* Option to create a repeat after the fleet is sent',
			'* Edit active fleet config',
			'##### Notifications',
			'* Added a notification for when an attacking fleet turns home because the target system is allied',
			'* Added a notification for when a system is colonized within your radar range',
			'##### Server',
			'* Restored mission log processing and increased efficiency',
			'* Increased performance of automation processing'
		],
	},
	{
		date: new Date('2024-05-18'),
		changes: [
			'##### UI',
			'* Added a new visual upkeep renderer',
			'* Restored background images on subpanels',
			'* Used updated number entry in rest of application',
			'* Tidied up the empire view military control screen',
			'* Replaced capacity text in items tables with capacity bar',
			'* Align dates right on mobile messages and notifications tables',
			'* Improved striped styling on tables',
			'* Added resource colouring to celestial table on desktop',
			'* Added a better way of choosing where to build ships',
			'##### Agents',
			'* Cancel stowaway action skips the cooldown timer but still adds fatigue and triggers another cooldown'
		],
	},
	{
		date: new Date('2024-05-19'),
		changes: [
			'##### UI',
			'* More work on the upkeep rendering',
			'* Show apocalypse shot on galaxy map to all players',
			'* Show apocalypse shot on federation incoming fleets'
		],
	},
	{
		date: new Date('2024-05-20'),
		changes: [
			'##### Balancing - Ships',
			'* Increase speed of Astrien frigate from 10 to 11',
			'* Increase attack of Astrien frigate from 300 to 340',
			'* Increase attack and defence of Victoris destroyer from 350 to 400',
			'##### Balancing - Installations',
			'* Increase health per level of manufactory installations from 100 to 150',
			'* Increase health per level of Advanced ship assembly plant from 100 to 150',
			'* Increase gas storage rate per level of Gas Silo from 10,000 to 25,000 (will store 625k gas at level 10 as opposed to 250k)',
			'* Allow building of interstellar embassy on habitable planets',
			'* Increase market building power greatly ',
			'* Increased SSM defence per level from 2,500 to 5,000 (each level would become the equivalent of 100 interceptors)',
			'##### Balancing - Economy',
			'* Increase base number of market orders from 2 to 5',
			'* Increase base market order capacity per system from 10k to 25k',
			'* Increase gas produced from biofuel refining from 500 to 2,500 per level (biomass input remains 500)',
			'##### UI',
			'* Fixed the map jerky glitch when panning too fast',
			'##### Other',
			'* The system with the highest level Sovereignty Office will be used as replacement capital'
		],
	},
	{
		date: new Date('2024-05-27'),
		changes: [
			'##### Agents',
			'* Added locate relic action',
			'* Agent actions of the same type can no longer be stacked',
			'* Improved performance and accuracy of agent summary page',
			'##### Items',
			'* 5% capacity will be reserved for each component type',
			'##### UI',
			'* Added images to player notifications. These images won\'t all be available immediately',
			'* Added celestial background images to combat reports',
			'* Panning the tactical map will keep loaded fleets in view',
			'* Don\'t show expand buttons on federation you aren\'t a leader of',
			'* Possibly fixed the defeated message popping up on load',
			'* Tweaks to celestial table styles',
			'##### Politics',
			'* Most instant policies will now offer a reward based on the current week number'
		],
	},
	{
		date: new Date('2024-06-02'),
		changes: [
			'##### Agents',
			'* Agent names and links can now be shown in notifications',
			'* Succesfully attacking a system will steal all the captured agents and 1 non-allied overt agent',
			'* Correctly show captured and recovered agents on combat reports',
			'##### UI',
			'* Re-implemented the mobile menu for better scrolling behaviour',
			'* Improved the incoming attacks table on the empire view',
			'* Fixed an issue with switching celestials where the newly switched celestial loads indefinitely',
			'* Always show basic resources on resource bar',
			'##### Performance',
			'* Implemented solar system stats caching for faster server processing',
			'* Implemented model caching for faster server responses',
			'##### Other',
			'* Outposts can\'t be used to generate research points'
		],
	},
	{
		date: new Date('2024-06-10'),
		changes: [
			'##### UI ',
			'* Fixed an issue with empty installation build queue display',
			'* Move manufactory bonuses to separate table',
			'##### Performance',
			'* Split type and game settings so each part of the data can be loaded individually'
		],
	},
	{
		date: new Date('2024-06-12'),
		changes: [
			'##### Upkeep and Gas',
			'* Sending ships now costs gas',
			'* Gas cost is equivalent to paying upfront for the upkeep costs for the duration of the fleet\'s journey',
			'* Available ships and arrived reinforcements still cost upkeep per hour',
			'* Upkeep costs of all ships have been reduced by 90%',
			'##### UI',
			'* Show federation research points on federation view',
			'* Always show the advisor even if there are no problems',
			'* Improved display of federation incoming fleets on mobile',
			'* Improved filtering on fleets view'
		],
	},
];

