import * as React from 'react';
import { Link } from 'react-router-dom';
import { CelestialSize, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { H3 } from '../../../../../Components/Base/Text/H';
import { CelestialImage } from '../../../../../Components/FusionShift/Celestials/CelestialImage';
import { InstallationImage } from '../../../../../Components/FusionShift/Images/InstallationImage';
import { CostDurationIcon } from '../../../../../Components/FusionShift/Items/CostControl';
import { SolarSystemWrapper } from '../../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { celestial_installation } from '../../../../../Navigation/Routing/SolarSystem';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const Upgrades = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);
    const [isUpgrading, setIsUpgrading] = React.useState(false);

    function upgrade(installationId: number, celestialId: number, solarSystemId: number) {

        setIsUpgrading(true);

        return apiState.InstallationClient.upgrade(
            solarSystemId,
            celestialId,
            installationId)
            .then(response => {
                setIsUpgrading(false);

                if (response.result !== undefined) {
                    props.reloadCallback(response.result);
                }
            });
    }

    if (props.solarSystemWrapper.upgrades.length === 0 || worldState.InstallationTypeSettings === undefined || worldState.GameSettings === undefined) {
        return null;
    }

    let celestialId: number | undefined = undefined;

    return <Table isFullWidth isHoverable>
        {props.solarSystemWrapper.upgrades.map(q => {

            const installationType = worldState.InstallationTypeSettings!.data[q.installation.installationTypeName]
            const renderCelestial = celestialId === undefined || celestialId !== q.celestial.celestialId;
            celestialId = q.celestial.celestialId;

            return <tr key={q.installation.installationId}>
                <td className="is-hidden-mobile">
                    {renderCelestial && <H3 className="celestial-name">{q.celestial.name} </H3>}
                </td>
                <td>
                    {renderCelestial && <CelestialImage celestial={q.celestial} forcedSize={CelestialSize.Tiny} isLink />}
                </td>
                <td className="is-hidden-mobile">
                    <Link to={celestial_installation(q.celestial.celestialId, q.installation.installationId)}>
                        {installationType.name} [{q.installation.level}]
                    </Link>
                </td>
                <td>
                    <InstallationImage size="tiny" installationType={installationType} celestialId={q.celestial.celestialId} installation={q.installation} />
                </td>
                <td>
                    {q.nextCost !== undefined && <CostDurationIcon cost={q.nextCost} gameSettings={worldState.GameSettings!} />}
                </td>
                <td>
                    <Button type="action"
                        icon={IconHelper.Installations.Upgrade}
                        text={`Upgrade to level ${q.nextLevel}`}
                        action={() => upgrade(q.installation.installationId, q.celestial.celestialId, props.solarSystemWrapper.solarSystem.solarSystemId)}
                        isDisabled={!!q.upgradeError || isUpgrading}
                        isPulledRight
                        hideTextOnMobile
                    />
                </td>
            </tr>
        })}
    </Table>;
};