import { IFederationDetail } from '../../ApplicationState/ApiClient';
import { Badge, Badges } from '../../Components/Base/Badge';
import { Level, levelContentWithHeading } from '../../Components/Base/Containers/Level';
import { IconHelper } from '../../Helpers/IconHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';

type Props = {
    federation: IFederationDetail
}

export const FederationHeading = (props: Props) => {

    const federation = props.federation;

    const detailContent = [
        (federation.totalEmbassyLevel !== undefined && federation.totalEmbassyLevel !== null) &&
        levelContentWithHeading("Total Embassy Level", federation.totalEmbassyLevel),
        (federation.maximumMembers !== undefined && federation.maximumMembers !== null) &&
        levelContentWithHeading("Members", `${federation.numberOfPlayers} / ${federation.maximumMembers}`),
        (federation.maximumOfficers !== undefined && federation.maximumOfficers !== null) &&
        levelContentWithHeading("Officers", `${federation.officers ? federation.officers.length : "?"} / ${federation.maximumOfficers}`),
        (federation.maximumInvitations !== undefined && federation.maximumInvitations !== null) &&
        levelContentWithHeading("Invites", `${federation.pendingInvitedPlayers ? federation.pendingInvitedPlayers.length : "?"} / ${federation.maximumInvitations}`)
    ];

    return <>
        <Badges>
            <Badge label="Rank" content={ValueFormatter.rank(federation.rank)} icon={IconHelper.Player.Rank} />

            {federation.researchPoints > 0 && <Badge label="RP" content={ValueFormatter.formatLocaleNumber(federation.researchPoints)} icon={IconHelper.Federations.ResearchPoint} />}

            <Badge label="Size" content={ValueFormatter.formatSize(federation.size)} icon={IconHelper.Player.Size} />
            <Badge label="Players" content={ValueFormatter.formatLocaleNumber(federation.numberOfPlayers)} icon={IconHelper.Megacities.Population} />
            <Badge label="Pop." content={ValueFormatter.formatPopulation(federation.population)} icon={IconHelper.Megacities.Population} />
            <Badge label="Systems" content={federation.numberOfSolarSystems} icon={IconHelper.SolarSystems.SolarSystem} />
        </Badges>
        <Level content={detailContent} />
    </>;
};