import { HashLink as Link } from 'react-router-hash-link';
import { InstallationType } from "../../../ApplicationState/ApiClient";
import { help_installationType } from "../../../Navigation/Routing/Help";

type Props = {
    installationType: InstallationType | undefined | false,
    className?: string
}

export const InstallationTypeLink = (props: Props) => {

    if (!props.installationType) {
        return null;
    }

    return <Link className={props.className} to={help_installationType(props.installationType.typeName)}>{props.installationType.name}</Link>;
};