import { observer } from "mobx-react-lite";
import * as React from "react";
import { ApiStateContext, AppStateContext, UnreadStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { ViewButton } from "../../Components/Base/Buttons/ViewButton";
import { Table } from "../../Components/Base/Containers/Table";
import { NewIcon } from "../../Components/Base/Icon";
import { handleOnClick } from "../../Components/Base/Util";
import { InboxContainer } from "../../Components/FusionShift/InboxContainer";
import { RichText } from "../../Components/FusionShift/RichText";
import { IconHelper } from "../../Helpers/IconHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { playerNotification } from "../../Navigation/Routing/NotificationsEtc";

export const PlayerNotificationIndex = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const unreadState = React.useContext(UnreadStateContext);
    const worldState = React.useContext(WorldStateContext);

    function list() {
        return apiState.PlayerNotificationClient.list().then(playerNotifications => {
            unreadState.clearUnread("playernotification");
            return playerNotifications;
        }
        )
    };

    return <InboxContainer
        entityName={"Notification"}
        icon={IconHelper.Categories.Notification}
        markAllAsRead={() => apiState.PlayerNotificationClient.markAllRead()}
        deleteAll={() => apiState.PlayerNotificationClient.deleteAll()}
        list={list}
        render={playerNotifications => {
            return <Table isHoverable isFullWidth heading={
                <>
                    <td></td>
                    <td>Subject</td>
                    <td>Date</td>
                    <td className="is-hidden-mobile"></td>
                </>
            }>
                {playerNotifications.map(s =>
                    <tr key={s.playerNotificationId} className="is-clickable" onClick={e => handleOnClick(e, () => appState.navigate(playerNotification(s.playerNotificationId)))}>
                        <td>
                            {s.isUnread && <NewIcon />}
                        </td>
                        <td>
                            <RichText text={s.subject} knownEnrichables={s.enrichables} />
                        </td>
                        <td className="is-mobile-align-right">
                            {ValueFormatter.formatDateFromNowWithMoment(s.date)}
                        </td>
                        <td className="is-hidden-mobile">
                            <ViewButton to={playerNotification(s.playerNotificationId)} />
                        </td>
                    </tr>)}
            </Table>;
        }
        }
    />;
});