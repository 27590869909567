import { observer } from "mobx-react-lite";
import React from "react";
import { AppStateContext } from "../ApplicationState/ContextRoot";
import { Panel, Props as PanelProps } from "../Components/Base/Containers/Panel";
import { LoadingSpinner } from "../Components/Base/Loading/LoadingSpinner";

type Props = {
    mainContent: PanelProps,
    additionalContent?: React.ReactNode | undefined
}

type WrapperProps = {
    children: React.ReactNode
}

export const BaseViewWrapper = observer((props: WrapperProps) => {
    const appState = React.useContext(AppStateContext);

    if (appState.IsAuthenticatedButLoading) {
        return <LoadingSpinner />;
    }

    return <div>
        {props.children}
    </div>;
});

export const BaseView = (props: Props) => {

    return <BaseViewWrapper>
        <Panel {...props.mainContent} />
        {props.additionalContent !== undefined && props.additionalContent}
    </BaseViewWrapper>;
};

export const SimpleBaseView = (props: PanelProps) => {

    return <BaseView mainContent={props} />;
};