import { observer } from "mobx-react-lite";
import * as React from "react";
import { AdminWorld, EnvironmentVariable } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { DictionaryTable, Table } from "../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { DateText } from "../../Components/Base/Text/DateText";
import { CollectionHelper } from "../../Helpers/CollectionHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { admin_world } from "../../Navigation/Routing/Admin";
import { SimpleBaseView } from "../BaseView";

export const WorldIndex = observer(() => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [worlds, setWorlds] = React.useState<AdminWorld[] | null>(null);
    const [environmentVariables, setEnvironmentVariables] = React.useState<EnvironmentVariable[] | undefined>(undefined);

    React.useEffect(() => {
        if (!worlds && appState.IsAdmin) {
            apiState.AdminClient.listWorlds().then(worlds => {
                if (worlds) {
                    setWorlds(worlds);
                }
            });
        }
        if (!environmentVariables && appState.IsAdmin) {
            apiState.AdminClient.listEnvironmentVariables().then(environmentVariables => {
                if (environmentVariables) {
                    setEnvironmentVariables(environmentVariables);
                }
            });
        }
    }, [worlds, environmentVariables, appState.IsAdmin]);

    if (!worlds) {
        return <LoadingSpinner />;
    }

    return <SimpleBaseView heading="Worlds">
        <Table isHoverable isFullWidth heading={
            <>
                <td>World</td>
                <td>Ticks</td>
                <td>Last Tick</td>
                <td>Week</td>
                <td></td>
            </>
        }>
            {worlds.map(p =>
                <tr key={p.worldId} onClick={() => appState.navigate(admin_world(p.worldId))}>
                    <td>
                        {p.name}
                    </td>
                    <td>
                        {p.ticks}
                    </td>
                    <td>
                        <DateText date={p.lastTickDate} />
                        {` (${p.lastTickDurationSec.toFixed(2)} sec)`}
                    </td>
                    <td>
                        {p.weekNumber}
                    </td>
                    <td>
                        <ButtonLink icon={IconHelper.General.View} text="View" to={admin_world(p.worldId)} />
                    </td>
                </tr>)}
        </Table>
        {!!environmentVariables &&
            <SubPanel heading="Environment Variables">
                <DictionaryTable isHoverable isFullWidth content={CollectionHelper.arrayToDictionary(environmentVariables)} heading={
                    <>
                        <td>Key</td>
                        <td>Value</td>
                    </>
                } />
            </SubPanel>
        }
    </SimpleBaseView>;
});