import { AccoladeType } from "../../ApplicationState/ApiClient";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class AccoladeIcons {

    public get Accolade() { return MaterialDesignIcons.medal; }

    public accoladeType(accoladeType: AccoladeType): string {

        switch (accoladeType) {
            case AccoladeType.Attacker:
                return MaterialDesignIcons.sword;
            case AccoladeType.Defender:
                return MaterialDesignIcons.shield;
            case AccoladeType.Raider:
                return MaterialDesignIcons.axe;
            case AccoladeType.Devastator:
                return MaterialDesignIcons.bomb;
            case AccoladeType.Murderer:
                return MaterialDesignIcons.skullAndCrossBones;
        }
    }
}