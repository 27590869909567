import React from "react";
import { IInstallationBuildQueueItem, InstallationBuildQueueType } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { BaseProps, Icon } from "../../Base/Icon";

type Props = BaseProps & {
    installationBuildQueueItem: IInstallationBuildQueueItem
}

export const InstallationBuildQueueItemIcon = (props: Props) => {

    const isDeconstruct = props.installationBuildQueueItem.type === InstallationBuildQueueType.Deconstruct;

    return <Icon icon={isDeconstruct ? IconHelper.Installations.Deconstruct : IconHelper.Installations.Build} title={isDeconstruct ? "Deconstructing" : "Constructing"} />;
};