import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ISolarSystem } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { SelectAndGoToSolarSystemButton } from '../Buttons/SelectAndGoToSystemButton';

type Props = {
    solarSystem: ISolarSystem | undefined
}

export const SelectAndGoToOwnedSolarSystem = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!playerState.Player || !solarSystemState.SolarSystem || !props.solarSystem) {
        return null;
    }

    if (props.solarSystem.playerId !== playerState.Player.playerId || props.solarSystem.solarSystemId === solarSystemState.SolarSystem.solarSystemId) {
        return null;
    }

    return <SelectAndGoToSolarSystemButton
        solarSystemId={props.solarSystem.solarSystemId}
        text="Select and go to"
    />;
});