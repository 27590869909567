import { observer } from 'mobx-react-lite';
import { FleetMovementType } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { ItemList } from '../../../Components/FusionShift/Items/ItemList';
import { ShipList } from '../../../Components/FusionShift/Ships/ShipList';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { AgentsSummary } from '../Agents/AgentSummary';
import { SendShipsBag } from '../SendShipsBag';

type Props = {
    sendShipsBag: SendShipsBag
}

export const Details = observer((props: Props) => {


    const agents = props.sendShipsBag.MovementType === FleetMovementType.Collection ? {
        agentIds: props.sendShipsBag.agentIdsToCollect,
        agents: props.sendShipsBag.availableAgentsInTarget
    } : {
        agentIds: props.sendShipsBag.agentIdsToSend,
        agents: props.sendShipsBag.availableAgentsInSource
    };

    return <>
        <SubPanel heading={{ text: "Ships", icon: IconHelper.Ships.Ship }}>
            <ShipList ships={props.sendShipsBag.shipQuantities} />
        </SubPanel>
        <AgentsSummary {...agents}
            targetSolarSystem={props.sendShipsBag.MovementType === FleetMovementType.Collection ? props.sendShipsBag.init.sourceSolarSystem : props.sendShipsBag.TargetSolarSystem}
            playerId={props.sendShipsBag.init.player.playerId}
            movementType={props.sendShipsBag.MovementType!}
        />
        {CollectionHelper.isAnyQuantityInDictionary(props.sendShipsBag.itemsToSend) &&
            <SubPanel heading={{ text: "Items to Send", icon: IconHelper.Ships.movementType(FleetMovementType.Delivery) }}>
                <ItemList items={props.sendShipsBag.itemsToSend} />
            </SubPanel>
        }
        {CollectionHelper.isAnyQuantityInDictionary(props.sendShipsBag.itemsToCollect) &&
            <SubPanel heading={{ text: "Items to Collect", icon: IconHelper.Ships.movementType(FleetMovementType.Collection) }}>
                <ItemList items={props.sendShipsBag.itemsToCollect} />
            </SubPanel>
        }
    </>;
});