import classNames from 'classnames';
import * as React from 'react';
import { ValidationResult } from '../../../ApplicationState/ApiClient';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Icon } from '../Icon';
import { Paragraph } from '../Text/Paragraph';
import { FieldHolder } from './FieldHolder';
import { StringInput } from './Input';
import { ValidationHelper, ValidationState } from './ValidationHelper';

type Props = {
    label: string,
    initialValue?: string | undefined,
    icon?: string | undefined,
    type?: string,
    placeholder?: string | undefined,
    validateDelay?: number,
    validation?: (value: string) => Promise<ValidationResult>,
    valueChanged: (value: ValidationResult) => any,
    onSubmit?: () => any | undefined
}

export const ValidatingStringInput = (props: Props) => {

    const [timeoutRef, setTimeoutRef] = React.useState<NodeJS.Timeout | null>(null);
    const [validationState, setValidationState] = React.useState(ValidationState.None);
    const [value, setValue] = React.useState(props.initialValue || "");
    const [error, setError] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (value.length > 0) {
            setValidationState(ValidationState.Loading);
            if (props.validateDelay && props.validateDelay > 0) {
                if (timeoutRef) {
                    clearTimeout(timeoutRef);
                }
                setTimeoutRef(global.setTimeout(validate, props.validateDelay));
            } else {
                validate();
            }
        } else {
            setValidationState(ValidationState.None);
        }
    }, [value]);

    function validate() {
        if (props.validation === undefined) {
            isValid();
        } else {
            const promise = props.validation(value);
            promise.then(result => {
                if ((result.error && result.error.length > 0) || !result.isValid) {
                    isInvalid(result.error);
                } else {
                    isValid();
                }
            });
        }
    }

    function isValid() {
        setError(undefined);
        setValidationState(ValidationState.Valid);
        props.valueChanged(ValidationHelper.valid(value));
    }

    function isInvalid(error: string | undefined) {
        setError(error);
        setValidationState(ValidationState.Invalid);
        props.valueChanged(ValidationHelper.invalid(error));
    }

    const className = ValidationHelper.validationStateClassName(validationState);
    const validationIcon = IconHelper.Statuses.validationState(validationState);
    const controlClassName = classNames(
        "has-icons-right",
        {
            "has-icons-left": props.icon !== undefined
        });


    return <FieldHolder label={props.label} growToFitContent>
        <StringInput
            value={value}
            valueChanged={v => setValue(v)}
            type={props.type}
            controlClassName={controlClassName}
            className={className}
            placeholder={props.placeholder}
            onSubmit={props.onSubmit}
        >
            {props.icon !== undefined && <Icon className="is-small is-left" icon={props.icon} />}
            {validationIcon !== undefined && <Icon className="is-small is-right" icon={validationIcon} />}
        </StringInput>
        {!!error && <Paragraph type="danger">{error}</Paragraph>}
    </FieldHolder>;
};

ValidatingStringInput.defaultProps = {
    validateDelay: 1000,
    leftIcon: IconHelper.General.Edit,
    type: "text"
};