import { CombatReportRound, IShipTypeSettings } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ShipsTable } from './Ships';

type Props = {
    shipTypeSettings: IShipTypeSettings,
    combatRound: CombatReportRound
}

export const DestroyedGroundedShips = (props: Props) => {

    return <SubPanel heading={{ text: "Destroyed Grounded Ships", icon: IconHelper.Ships.Grounded }} isUnpadded>
        <ShipsTable matchRowsTo={0} ships={props.combatRound.defendingShips} shipTypeSettings={props.shipTypeSettings} isAttacker={false} />
    </SubPanel>
}