import { IFleet, IMapSolarSystem, ISolarSystemForObserver, SolarSystemForObserver } from "../../ApplicationState/ApiClient";
import { CloseButton } from "../../Components/Base/Buttons/CloseButton";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { SendShipsButtons } from "../../Components/FusionShift/Buttons/SendShipsButtons";
import { SolarSystemButtons } from "../../Components/FusionShift/Buttons/SolarSystemButtons";
import { CelestialCommoditySaleList } from "../../Components/FusionShift/Celestials/CelestialCommoditySaleList";
import { SolarSystemClaimTypeIcon } from "../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon";
import { ItemsWithCapacityControl } from "../../Components/FusionShift/Items/ItemsWithCapacityControl";
import { ShipList } from "../../Components/FusionShift/Ships/ShipList";
import { SolarSystemRenderOrRow } from "../../Components/FusionShift/SolarSystems/SolarSystemRenderOrRow";
import { CollectionHelper } from "../../Helpers/CollectionHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { SolarSystemHelper } from "../../Helpers/SolarSystemHelper";
import { ContractTable } from "../Contracts/Components/ContractTable";
import { MarketOrderTable } from "../Marketplace/Components/MarketOrderTable";
import { SolarSystemAgents } from "../SolarSystem/SubViews/Agents/SolarSystemAgents";
import { SolarSystemStats } from "../SolarSystem/SubViews/Summary/SolarSystemStats";
import { MapFleetList } from "./Tactical/FleetList";

type Props = {
    setSelectedFleetId?: ((fleetId: number) => void) | undefined,
    selectedFleetId?: number | undefined,
    fleets?: IFleet[] | undefined,
    solarSystem: ISolarSystemForObserver | IMapSolarSystem | undefined,
    closeCallback?: (() => any) | undefined,
    reloadCallback?: (() => any) | undefined
}

export const SolarSystemDetail = (props: Props) => {

    if (!props.solarSystem) {
        return null;
    }

    const fleets = props.fleets && props.fleets.filter(s =>
    ((s.sourceSolarSystem && s.sourceSolarSystem.solarSystemId === props.solarSystem!.solarSystemId) ||
        (s.targetSolarSystem && s.targetSolarSystem.solarSystemId === props.solarSystem!.solarSystemId)));

    const hasDetail = props.solarSystem instanceof SolarSystemForObserver && (
        CollectionHelper.isAnyInDictionary(props.solarSystem.items) ||
        CollectionHelper.isAnyQuantityInDictionary(props.solarSystem.availableShips));

    const hasCelestials = "celestials" in props.solarSystem ? props.solarSystem as ISolarSystemForObserver : undefined;

    return <>
        <SubPanel
            backgroundImage={SolarSystemHelper.solarSystemBackground(hasCelestials)}
            heading={{
                text: props.solarSystem.name,
                icon: <SolarSystemClaimTypeIcon solarSystem={props.solarSystem} />
            }}
            headingContent={<SolarSystemButtons solarSystem={props.solarSystem} reloadCallback={props.reloadCallback}>
                {props.closeCallback !== undefined && <CloseButton action={props.closeCallback} />}
            </SolarSystemButtons>}

        >
            <SolarSystemStats solarSystem={props.solarSystem} />
            {hasCelestials !== undefined && <>
                <SendShipsButtons solarSystem={hasCelestials} />
                {!!hasCelestials &&
                    <SubPanel heading="Celestials">
                        {hasCelestials.celestials.length === 0 && <Paragraph>
                            No available information on celestials at this system.
                        </Paragraph>}
                        <SolarSystemRenderOrRow solarSystem={hasCelestials} forceSmall showResources />
                    </SubPanel>
                }
                {props.solarSystem instanceof SolarSystemForObserver && hasDetail &&
                    <SplitLayout

                        left={props.solarSystem instanceof SolarSystemForObserver && props.solarSystem.items && props.solarSystem.itemsDetail &&
                            <SubPanel heading="Resources">
                                <ItemsWithCapacityControl
                                    items={props.solarSystem.items}
                                    resourceCapacities={props.solarSystem.itemsDetail.resourceCapacities}
                                    otherCapacities={props.solarSystem.itemsDetail.otherCapacities}
                                />
                            </SubPanel>
                        }
                        right={
                            <SubPanel heading="Stationed Ships">
                                <ShipList ships={props.solarSystem.availableShips} showEmptyText />
                            </SubPanel>
                        } />}
            </>}
            {hasCelestials === undefined && <LoadingSpinner />}
        </SubPanel>
        {hasCelestials !== undefined && <>
            {!!fleets && fleets.length > 0 &&
                <SubPanel heading={{ text: "Ship Movements ", icon: IconHelper.Ships.Ship }}>
                    <MapFleetList {...props} fleets={fleets} selectedSolarSystem={props.solarSystem} />
                </SubPanel>
            }
            {hasCelestials.contracts.length > 0 &&
                <SubPanel heading={{ text: "Contracts", icon: IconHelper.Economy.Contract }} isUnpadded>
                    <ContractTable atSolarSystemId={props.solarSystem.solarSystemId} contracts={hasCelestials.contracts} />
                </SubPanel>
            }
            {hasCelestials.marketOrders.length > 0 &&
                <SubPanel heading={{ text: "Market Orders ", icon: IconHelper.Market.MarketOrder }} isUnpadded>
                    <MarketOrderTable marketOrders={hasCelestials.marketOrders} solarSystemForForcedAcceptButton={props.solarSystem} showLocation={false} />
                </SubPanel>
            }
            <CelestialCommoditySaleList solarSystem={hasCelestials} />
            <SolarSystemAgents {...props} solarSystem={hasCelestials} />
        </>}
    </>
};