import { VictoryThemeDefinition } from "victory";

const theme: VictoryThemeDefinition = {
  bar: {
    style: {
      data: {
        fill: "#feae00",
      },
    },
  }
};

const axisStyle = {
  axisLabel: {
    fontFamily: "Helvetica, Arial, sans-serif"
  },
  tickLabels: {
    fontFamily: "Helvetica, Arial, sans-serif",
    fontSize: "6px",
    padding: 5
  },
  grid: {
    stroke: "#818e99",
    strokeWidth: 0.5
  }
};

const lineData = {
  strokeWidth: 1
};

const line = [
  {
    data: {
      ...lineData,
      stroke: "#feae00"
    },
  },
  {
    data: {
      ...lineData,
      stroke: "#c43a31"
    },
  },
  {
    data: {
      ...lineData,
      stroke: "#dcde5b"
    },
  },
  {
    data: {
      ...lineData,
      stroke: "#8fc462"
    },
  },
  {
    data: {
      ...lineData,
      stroke: "#7f45d1"
    },
  },
  {
    data: {
      ...lineData,
      stroke: "#7b7b7b"
    },
  }
];

export const maxLineSeries = line.length;

const flyout = {
  cornerRadius: 0,
  constrainToVisibleArea: true,
  flyoutPadding: { top: 0, left: 0, right: 0, bottom: 0 },
  flyoutStyle: {
    fill: "black",
    stroke: "#feae00", strokeWidth: 1
  },
  backgroundStyle: {
    fill: "black"
  }
}
const tooltipLabel = {
  style: {
    fontSize: 10,
    fontFamily: "Helvetica, Arial, sans-serif"
  }
}

const tooltip = {
  flyout,
  label: tooltipLabel
}

const animate = { onLoad: { duration: 1000 }, duration: 500 };

export const graphTheme = {
  padding: 25,
  bar: theme,
  line,
  axisStyle,
  animate,
  tooltip
};
