import * as React from 'react';
import { IconHelper } from '../../../Helpers/IconHelper';
import { map_tactical_x_y } from '../../../Navigation/Routing/Map';
import { IconLink } from '../../Base/IconLink';

type Props = {
    x: number,
    y: number
}

export const GoToMapLocationIcon = (props: Props) => {

    if (props.x === undefined || props.y === undefined) {
        return null;
    }

    return <IconLink
        icon={IconHelper.Map.ShowOnMap}
        to={map_tactical_x_y(props.x.toFixed(0), props.y.toFixed(0))}
        title="View on map" />;
};