import * as React from 'react';
import { SplitLayout } from '../../../../../Components/Base/Containers/SplitLayout';
import { CelestialFeature, CelestialHelper } from '../../../../../Helpers/CelestialHelper';
import { CelestialViewProps } from '../CelestialViewProps';
import { BuildTile } from './BuildTile';
import { DronesTile } from './DronesTile';
import { InstallationTile } from './InstallationTile';
import { ManufactoryTile } from './ManufactoryTile';
import { MegacityTile } from './MegacityTile';
import { ShipsTile } from './ShipsTile';
import { SummaryTileProps } from './SummaryTileProps';

type FeatureMaybeProps = {
    feature: CelestialFeature,
    features: CelestialFeature[],
    content: React.FunctionComponent<SummaryTileProps>,
    contentProps: SummaryTileProps
}

const FeatureMaybe = (props: FeatureMaybeProps) => {

    if (!props.features.includes(props.feature)) {
        return null;
    }

    return <props.content {...props.contentProps} />;
};

export const SummaryView = (props: CelestialViewProps) => {

    const features = CelestialHelper.celestialFeatures(props.celestial, props.installationTypeSettings, true);
    const installations = props.celestial.installations ? props.celestial.installations.filter(f => f.installationId !== undefined) : [];

    return <SplitLayout
        isLeftEmpty={features.length === 0}
        left={<>
            <FeatureMaybe features={features} contentProps={props} feature="Build" content={BuildTile} />
            <FeatureMaybe features={features} contentProps={props} feature="Ships" content={ShipsTile} />
            <FeatureMaybe features={features} contentProps={props} feature="Megacity" content={MegacityTile} />
            <FeatureMaybe features={features} contentProps={props} feature="Manufactory" content={ManufactoryTile} />
            <FeatureMaybe features={features} contentProps={props} feature="Drones" content={DronesTile} />
        </>
        }
        isRightEmpty={installations.length === 0}
        right={
            installations.map(i =>
                <div className="installations">
                    <InstallationTile key={i.installationId} installation={i} {...props} isConstructing={props.solarSystem.installationBuildQueue !== undefined && props.solarSystem.installationBuildQueue.find(x => x.installationId === i.installationId) !== undefined} />
                </div>)
        } />;
};