import * as React from 'react';
import { ConstructionAnimation } from './ConstructionAnimation';
import { DamagedAnimation } from './DamagedAnimiation';
import { Grid } from './Grid';

type Props = {
    damage: number,
    isConstructing: boolean | undefined
}

export const InstallationAnimations = (props: Props) => {

    return <>
        <Grid />
        {props.damage > 0 && <DamagedAnimation />}
        {props.isConstructing && <ConstructionAnimation />}
    </>;
};