import classNames from 'classnames';
import * as React from 'react';

type Props = {
    className?: string | undefined,
    children?: React.ReactNode,
    id?: string | undefined,
    isTitleSub?: boolean,
    isSubtitle?: boolean
}

function buildClasses(isTitle: boolean, props: Props, className?: string) {

    return classNames(
        props.className,
        {
            "title": isTitle,
            "subtitle": !isTitle,
            "title-sub": props.isTitleSub
        },
        className
    );
}

export const H1 = (props: Props) => {
    return <h1 id={props.id !== undefined ? props.id : ""} className={buildClasses(true, props)} >
        {props.children}
    </h1>;
};

export const H2 = (props: Props) => {
    return <h2 id={props.id !== undefined ? props.id : ""} className={buildClasses(false, props, "is-5")} >
        {props.children}
    </h2>;
};

export const H3 = (props: Props) => {
    return <h3 id={props.id !== undefined ? props.id : ""} className={buildClasses(false, props, "is-5")} >
        {props.children}
    </h3>;
};

export const H4 = (props: Props) => {
    return <h4 id={props.id !== undefined ? props.id : ""} className={buildClasses(false, props, "is-6")} >
        {props.children}
    </h4>;
};

export const H5 = (props: Props) => {
    return <h5 id={props.id !== undefined ? props.id : ""} className={buildClasses(false, props, "is-6")} >
        {props.children}
    </h5>;
};