import { TextHelper } from "../../../Helpers/TextHelper";

type Props = {
    text: string,
    length?: number,
    mode: "substring" | "abbreviate"
}

export const TextAbbreviator = (props: Props) => {

    const abbreviatedText = props.mode === "abbreviate" ? TextHelper.abbreviateText(props.text, props.length)
        : props.text.substring(0, props.length ?? 1);

    return <>
        <span className="is-hidden-mobile">
            {props.text}
        </span>
        <span className="is-hidden-tablet">
            {abbreviatedText}
        </span>
    </>;
}

TextAbbreviator.defaultProps = {
    mode: "substring"
};