import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ContractType, EnrichableModels, FleetMovementType, IFactionTypeSettings, IInstallationTypeSettings, IItemTypeSettings, IPolicyTypeSettings, IShipTypeSettings, SecurityStatus, ShipClass } from "../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../ApplicationState/ContextRoot";
import { IconHelper } from "../../Helpers/IconHelper";
import { ItemsHelper } from "../../Helpers/ItemsHelper";
import { TextHelper } from "../../Helpers/TextHelper";
import { help_page } from "../../Navigation/Routing/Help";
import { Icon } from "../Base/Icon";
import { H2, H3 } from "../Base/Text/H";
import { FleetMovementTypeIcon } from "./Icons/FleetMovementTypeIcon";
import { SecurityStatusIcon } from "./Icons/SecurityStatusIcon";
import { ShipClassIcon } from "./Icons/ShipClassIcon";
import { AgentLink } from "./Links/AgentLink";
import { FactionTypeLink } from "./Links/FactionTypeLink";
import { FederationLink } from "./Links/FederationLink";
import { InstallationTypeLink } from "./Links/InstallationTypeLink";
import { ItemTypeLink } from "./Links/Items/ItemTypeLink";
import { PlayerLink } from "./Links/PlayerLink";
import { PolicyTypeLink } from "./Links/PolicyTypeLink";
import { ShipTypeLink } from "./Links/ShipTypeLink";
import { SolarSystemLink } from "./Links/SolarSystemLink";

type Props = {
    text: string,
    className?: string | undefined
    knownEnrichables?: EnrichableModels,
    isTextOnly?: boolean
}

type RichTextTypeSettings = {
    installations: IInstallationTypeSettings,
    items: IItemTypeSettings,
    ships: IShipTypeSettings,
    policies: IPolicyTypeSettings,
    factions: IFactionTypeSettings
}

function parseRawCommand(rawCommand: string): [string, { [key: string]: string }] {
    const index = rawCommand.indexOf(":");
    if (index > -1) {
        const command = rawCommand.substring(0, index);
        const params = rawCommand.substring(index + 1);

        return [command, {
            all: params
        }];
    } else {
        return [rawCommand, { all: "" }];
    }
}

function enumFromString(any: any, text: string) {
    return any[text];
}

function parseRichContent(text: string, knownEnrichables: EnrichableModels | undefined, typeSettings: RichTextTypeSettings) {

    function parseCommandClassNames(rawCommand: string) {
        if (rawCommand.startsWith("image")) {
            return "has-image";
        }

        return "";
    }

    function parse(rawCommand: string, key: number) {

        const [command, params] = parseRawCommand(rawCommand);

        switch (command.toLocaleLowerCase()) {
            case "icon":
                return <Icon icon={IconHelper.resolveIcon(params.all)} />;
            case "link":
                return <Link to={`/${params.all}`} key={key}>{TextHelper.tidyText(params.all)}</Link>
            case "help":
            case "helplink":
                return <Link to={help_page(params.all)} key={key}>{TextHelper.tidyText(params.all)}</Link>

            case "installationtype":
                const installationType = params.all in typeSettings.installations!.data && typeSettings.installations.data[params.all];
                return <InstallationTypeLink installationType={installationType} key={key} />;

            case "securitystatus":
            case "securitystatusicon":
                const statusValue: SecurityStatus = enumFromString(SecurityStatus, params.all);
                return <SecurityStatusIcon securityStatus={statusValue} key={key} />;

            case "contracttype":
            case "contracttypeicon":
                const contractTypeValue: ContractType = enumFromString(ContractType, params.all);
                return <Icon icon={IconHelper.Economy.contractType(contractTypeValue)} key={key} />;

            case "shipclass":
            case "shipclassicon":
                const classValue: ShipClass = enumFromString(ShipClass, params.all);
                return <ShipClassIcon shipClass={classValue} key={key} />;

            case "fleetmovementtype":
            case "fleetmovementtypeicon":
                const movementValue: FleetMovementType = enumFromString(FleetMovementType, params.all);
                return <FleetMovementTypeIcon movementType={movementValue} key={key} setColour={false} isPlayerAllied={false} />;

            case "shiptype":
                const shipType = params.all in typeSettings!.ships!.data && typeSettings!.ships!.data[params.all];
                return <ShipTypeLink shipType={shipType} key={key} />;

            case "factiontype":
                const factionType = params.all in typeSettings!.factions!.data && typeSettings!.factions!.data[params.all];
                return <FactionTypeLink factionType={factionType} key={key} />;

            case "policytype":
                const policyType = params.all in typeSettings!.policies!.data && typeSettings!.policies!.data[params.all];
                return policyType && <PolicyTypeLink policyType={policyType} key={key} />;

            case "agent":
                const agentIndex = Number(params.all);
                const agent = knownEnrichables?.agents?.[agentIndex];
                if (agent === undefined) {
                    console.log(["Unable to find Solar System", agentIndex, "with available agents", knownEnrichables?.agents])
                    return undefined;
                }

                return <AgentLink agent={agent} />;

            case "solarsystem":
                const solarSystemIndex = Number(params.all);
                const solarSystem = knownEnrichables?.solarSystems?.[solarSystemIndex];
                if (solarSystem === undefined) {
                    console.log(["Unable to find Solar System", solarSystemIndex, "with available systems", knownEnrichables?.solarSystems])
                    return undefined;
                }

                return <SolarSystemLink solarSystem={solarSystem} />;

            case "player":
                const playerIndex = Number(params.all);
                const player = knownEnrichables?.players?.[playerIndex];
                if (player === undefined) {
                    console.log(["Unable to find Player", playerIndex, "with available players", knownEnrichables?.players])
                    return undefined;
                }

                return <PlayerLink player={player} />;

            case "federation":
                const federationIndex = Number(params.all);
                const federation = knownEnrichables?.federations?.[federationIndex];
                if (federation === undefined) {
                    console.log(["Unable to find Federation", federationIndex, "with available federations", knownEnrichables])
                    return undefined;
                }

                return <FederationLink federation={federation} />;

            case "item":
                const [itemName, quantity] = params.all.split(":");
                const itemType = ItemsHelper.getItemTypeFromName(itemName, typeSettings!.items!.data);

                if (itemType === undefined) {
                    return undefined;
                }

                return <span>{Number(quantity)}x <ItemTypeLink itemType={itemType} /></span>;

            case "image":

                const suffix = params.all.includes("hype") ? "_0" : "";

                return <div className="inline-image-holder is-flex">
                    <img src={`/images/${params.all}${suffix}.jpg`} className="inline-image" />
                </div>;
        }

        console.log(["Unable to parse:", rawCommand, command, params]);

        return undefined;
    }

    const additionalClassNames: string[] = [];
    const content: ReactNode[] = [];
    let buffer = text;
    let key = 0;

    let h = 0;

    while (buffer.startsWith("#")) {
        h++;
        buffer = buffer.substring(1);
    }

    while (buffer.length > 0) {
        const openIdx = buffer.indexOf("{");
        const closeIdx = buffer.indexOf("}");

        if (openIdx > -1 && closeIdx > -1) {
            if (openIdx > 0) {
                const preContent = buffer.substring(0, openIdx);
                content.push(preContent);
            }
            const richCommand = buffer.substring(openIdx + 1, closeIdx);
            const commandResult = parse(richCommand, key++);
            if (commandResult) {
                content.push(commandResult);

                const commandClassNames = parseCommandClassNames(richCommand);

                if (commandClassNames.length > 0) {
                    additionalClassNames.push(commandClassNames);
                }
            }

            buffer = buffer.substring(closeIdx + 1);
        } else {
            content.push(buffer);
            buffer = "";
        }
    }

    if (h > 0) {
        h++; // Never allow H1 - increase everything else by 1

        const className = `subtitle is-3`;

        switch (h) {
            case 2:
                // Only use typography helper on h2
                return <H2 className={className}>{content}</H2>
            case 3:
                return <H3>{content}</H3>
            case 4:
                return <h4 className="subtitle">{content}</h4>
            case 5:
                return <h5 className="subtitle">{content}</h5>
            case 6:
                return <h6 className="subtitle">{content}</h6>
        }
    }

    return {
        additionalClassNames: additionalClassNames.join(" "),
        content: <>
            {content}
        </>
    }
}

function parseRichContentTextOnly(text: string, knownEnrichables: EnrichableModels | undefined, typeSettings: RichTextTypeSettings) {

    function parse(rawCommand: string, key: number) {

        const [command, params] = parseRawCommand(rawCommand);

        switch (command.toLocaleLowerCase()) {
            case "link":
                return TextHelper.tidyText(params.all);
            case "help":
            case "helplink":
                return TextHelper.tidyText(params.all);

            case "policytype":
                const policyType = params.all in typeSettings!.policies!.data && typeSettings!.policies!.data[params.all];
                return policyType && policyType.name;
            case "installationtype":
                const installationType = params.all in typeSettings.installations.data && typeSettings.installations.data[params.all];
                return installationType && installationType.name;

            case "fleetmovementtype":
            case "fleetmovementtypeicon":
            case "shipclass":
            case "shipclassicon":
            case "securitystatus":
            case "securitystatusicon":
                return params.all;

            case "shiptype":
                const shipType = params.all in typeSettings!.ships!.data && typeSettings!.ships!.data[params.all];
                return shipType && shipType.name;

            case "factiontype":
                const factionType = params.all in typeSettings!.factions!.data && typeSettings!.factions!.data[params.all];
                return factionType && factionType.name;

            case "agent":
                const agentIndex = Number(params.all);
                const agent = knownEnrichables?.agents?.[agentIndex];
                if (agent === undefined) {
                    console.log(["Unable to find Agent", agentIndex, "with available agents", knownEnrichables?.agents])
                    return undefined;
                }

                return agent.name;

            case "solarsystem":
                const solarSystemIndex = Number(params.all);
                const solarSystem = knownEnrichables?.solarSystems?.[solarSystemIndex];
                if (solarSystem === undefined) {
                    console.log(["Unable to find Solar System", solarSystemIndex, "with available systems", knownEnrichables?.solarSystems])
                    return undefined;
                }

                return solarSystem.name;

            case "player":
                const playerIndex = Number(params.all);
                const player = knownEnrichables?.players?.[playerIndex];
                if (player === undefined) {
                    console.log(["Unable to find Player", playerIndex, "with available players", knownEnrichables?.players])
                    return undefined;
                }

                return player.name;

            case "federation":
                const federationIndex = Number(params.all);
                const federation = knownEnrichables?.federations?.[federationIndex];
                if (federation === undefined) {
                    console.log(["Unable to find Federation", federationIndex, "with available federations", knownEnrichables])
                    return undefined;
                }

                return federation.name;

            case "item":
                const [itemName, quantity] = params.all.split(":");
                const itemType = ItemsHelper.getItemTypeFromName(itemName, typeSettings!.items!.data);

                if (itemType === undefined) {
                    return undefined;
                }

                return `${Number(quantity)}x ${itemType.name}`;
        }

        console.log(["Unable to parse:", rawCommand, command, params]);

        return undefined;
    }

    const content: string[] = [];
    let buffer = text;
    let key = 0;

    let h = 0;

    while (buffer.startsWith("#")) {
        h++;
        buffer = buffer.substring(1);
    }

    while (buffer.length > 0) {
        const openIdx = buffer.indexOf("{");
        const closeIdx = buffer.indexOf("}");

        if (openIdx > -1 && closeIdx > -1) {
            if (openIdx > 0) {
                const preContent = buffer.substring(0, openIdx);
                content.push(preContent);
            }
            const richCommand = buffer.substring(openIdx + 1, closeIdx);
            const commandResult = parse(richCommand, key++);
            if (commandResult) {
                content.push(commandResult);
            }

            buffer = buffer.substring(closeIdx + 1);
        } else {
            content.push(buffer);
            buffer = "";
        }
    }

    return content.join(" ");
}

export const RichText = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const [content, setContent] = React.useState<any>(undefined);

    React.useEffect(() => {
        if (
            worldState.ItemTypeSettings &&
            worldState.FactionTypeSettings &&
            worldState.InstallationTypeSettings &&
            worldState.ShipTypeSettings &&
            worldState.PolicyTypeSettings) {

            const contentProps = {
                items: worldState.ItemTypeSettings,
                factions: worldState.FactionTypeSettings,
                installations: worldState.InstallationTypeSettings,
                ships: worldState.ShipTypeSettings,
                policies: worldState.PolicyTypeSettings
            }

            if (props.isTextOnly) {
                setContent(parseRichContentTextOnly(props.text, props.knownEnrichables, contentProps));
            } else {
                setContent(parseRichContent(props.text, props.knownEnrichables, contentProps));
            }
        }
    }, [props.text, props.knownEnrichables,

    worldState.ItemTypeSettings,
    worldState.FactionTypeSettings,
    worldState.InstallationTypeSettings,
    worldState.ShipTypeSettings,
    worldState.PolicyTypeSettings
    ]);

    if (content === undefined) {
        return null;
    }

    const className = classNames(
        "content",
        props.className,
        content.additionalClassNames
    );

    if (props.isTextOnly) {
        return <>{content}</>
    }

    return <p className={className}>
        {content.content}
    </p>;
});

type WrapperProps = {
    text: string[]
};

export const RichTextWrapper = (props: WrapperProps) => {
    return <>
        {props.text.map((t, i) => <RichText key={i} text={t} />)}
    </>;
};