import { FederationStatus, FleetMovementType, IPlayerFederation, IPlayerMinimal } from "../ApplicationState/ApiClient";
import { FleetHelper } from "./FleetHelper";

type MinimalRelationType = {
  playerId?: number | undefined;
  federationId?: number | undefined;
};

type MinimalPlayerForRelation = {
  playerId: number,
  federation?: IPlayerFederation
}

export class RelationHelper {
  public static get Relation_Neutral(): string {
    return "relation neutral";
  }
  public static get Relation_Self(): string {
    return "relation self";
  }
  public static get Relation_Friend(): string {
    return "relation friend";
  }
  public static get Relation_Enemy(): string {
    return "relation enemy";
  }
  public static get Relation_Victor(): string {
    return "relation victor";
  }

  public static movementRelationClassName(
    a: IPlayerMinimal | null | undefined,
    b: IPlayerMinimal | null | undefined,
    fleet: { initialMovementType: FleetMovementType }
  ): string {
    if (!a || !b) {
      return FleetHelper.isHostileMovement(fleet)
        ? this.Relation_Enemy
        : this.Relation_Neutral;
    }

    if (a.playerId === b.playerId) {
      return this.Relation_Self;
    }
    if (a.federation && b.federation && a.federation.federationId === b.federation.federationId) {
      return this.Relation_Friend;
    }

    return FleetHelper.isHostileMovement(fleet)
      ? this.Relation_Enemy
      : this.Relation_Neutral;
  }

  public static relationClassName(
    otherPlayer: MinimalPlayerForRelation | null | undefined,
    self: MinimalPlayerForRelation | null | undefined
  ): string {

    if (otherPlayer && self && otherPlayer.playerId !== self.playerId) {
      if (otherPlayer.federation?.status === FederationStatus.Victorious) {
        return this.Relation_Victor;
      }
    }

    return this.minimalRelationClassName(
      { playerId: otherPlayer?.playerId, federationId: otherPlayer?.federation?.federationId },
      { playerId: self?.playerId, federationId: self?.federation?.federationId }
    );
  }

  public static minimalRelationClassName(
    a: MinimalRelationType | null | undefined,
    b: MinimalRelationType | null | undefined
  ): string {
    if (
      !a ||
      !b ||
      (!a.playerId && !a.federationId) ||
      (!b.playerId && !b.federationId)
    ) {
      return this.Relation_Neutral;
    }

    if (a.playerId === b.playerId) {
      return this.Relation_Self;
    }
    if (a.federationId && b.federationId && a.federationId === b.federationId) {
      return this.Relation_Friend;
    }

    return this.Relation_Enemy;
  }

  public static isAlliedWith(
    a: IPlayerMinimal | null | undefined,
    b: IPlayerMinimal | null | undefined
  ): boolean {
    if (!a || !b) {
      return false;
    }

    // Allied if the same
    if (a.playerId === b.playerId) {
      return true;
    }

    // Not allied if either are not in a federation
    if (a.federation?.federationId === undefined ||
      a.federation?.federationId === null ||
      b.federation?.federationId === undefined ||
      b.federation?.federationId === null) {
      return false;
    }

    // Allied if the same federation Id
    if (a.federation && b.federation && a.federation.federationId === b.federation.federationId) {
      return true;
    }

    return false;
  }
}