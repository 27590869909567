import { ItemCategory } from "../../../../ApplicationState/ApiClient"
import { IconHelper } from "../../../../Helpers/IconHelper"
import { ValueFormatter } from "../../../../Helpers/ValueFormatter"
import { Icon } from "../../../Base/Icon"
import { TextAndIcon } from "../../../Base/Text/TextAndIcon"

type Props = {
    itemCategory: ItemCategory,
    noText?: boolean
}

export const ItemCategoryIcon = (props: Props) => {

    if (props.noText) {
        return <Icon icon={IconHelper.Items.itemCategory(props.itemCategory)} />;
    }

    return <TextAndIcon
        text={ValueFormatter.friendlyNameForItemCategory(props.itemCategory)}
        icon={IconHelper.Items.itemCategory(props.itemCategory)}
    />;
}