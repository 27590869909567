import { ICelestialTypeSettings, IInstallationTypeSettings, IPlayerMinimal, ISolarSystemMinimal, SiegeDamage } from '../../../ApplicationState/ApiClient';
import { MessageBoxWithIcon } from '../../../Components/Base/MessageBox';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { DamagedInstallations } from './DamagedInstallations';

type Props = {
    siegeDamage: SiegeDamage,
    celestialTypeSettings: ICelestialTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    attackingPlayer?: IPlayerMinimal | undefined,
    solarSystem: ISolarSystemMinimal | undefined
}

export const SiegeResult = (props: Props) => {

    return <>
        {!!props.siegeDamage.populationKilled && props.siegeDamage.populationKilled > 0 &&
            <MessageBoxWithIcon icon={IconHelper.Combat.Murder} type="danger" text={<>
                {ValueFormatter.formatPopulation(props.siegeDamage.populationKilled)} population murdered by{" "}
                <PlayerLink player={props.attackingPlayer} />
            </>
            }>
            </MessageBoxWithIcon>
        }
        <DamagedInstallations {...props} />
    </>;
};