import { NavLink } from "react-router-dom";

export const MenuLink = NavLink;

export function isActiveSubMenu(location, ...submenu: string[]) {

    if (!location) {
        return "";
    }

    for (let sub of submenu) {
        if (location.pathname.startsWith(sub)) {
            return " active";
        }
    }
    return "";
}