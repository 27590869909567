import * as React from 'react';
import { AgentAction } from '../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { AgentActionTypeLink } from '../../../../Components/FusionShift/Links/AgentActionTypeLink';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { calculateEffects } from '../../../Agents/Components/Actions/EffectDetails';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
}

export const AgentEffects = (props: Props) => {

    const activeEffects = React.useMemo(() => {

        return props.solarSystemWrapper.agentEffects.map(x => {
            return {
                agentEffect: x.agentEffect,
                agentActionType: x.agentActionType!,
                effects: calculateEffects(x.agentActionType!, x.strength)
            }
        });

    }, [props.solarSystemWrapper, props.solarSystemWrapper.agentEffects]);

    if (activeEffects.length === 0) {

        return <SubPanel heading={{ text: "Agent Effects", icon: IconHelper.Agents.action(AgentAction.ApplyEffect) }}>
            <Paragraph>There are no active agent effects at this system.</Paragraph>
        </SubPanel>;
    }

    return <SubPanel heading={{ text: "Agent Effects", icon: IconHelper.Agents.action(AgentAction.ApplyEffect) }} isUnpadded>
        <Table isFullWidth heading={
            <>
                <th>
                    Effect
                </th>
                <th>

                </th>
                <th>
                    Level
                </th>
                <th>
                    Expires
                </th>
            </>
        }>
            {activeEffects.map(x => <tr key={x.agentEffect.agentEffectId}>
                <td className={x.agentActionType.isHostile ? "has-text-danger" : "has-text-success"}>
                    <AgentActionTypeLink actionType={x.agentActionType} hideIcon />
                </td>
                <td>
                    {x.effects.length === 1 && <>
                        {x.effects[0].label}: {x.effects[0].effect}
                    </>}
                    {x.effects.length > 1 && <ul>
                        {x.effects.map((y, i) => <li key={i}>
                            {y.label}: {y.effect}
                        </li>)}
                    </ul>}
                </td>
                <td>
                    {x.agentEffect.level}
                </td>
                <td>
                    {ValueFormatter.formatDateFromNowWithMoment(x.agentEffect.expiryDate)}
                </td>
            </tr>)}
        </Table>
    </SubPanel>;
};