import { observer } from 'mobx-react-lite';
import { WorldQuandrant, WorldStatus } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { PreviousButton } from "../../../Components/Base/Buttons/PreviousButton";
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Icon } from '../../../Components/Base/Icon';
import { ErrorMessageBox } from '../../../Components/Base/MessageBox';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { StageProps } from './StageRender';

type Props = StageProps & {
    startPlaying: () => any
}

export const ConfirmStage = observer((props: Props) => {

    function formatAdminPassword() {
        if (props.joinWorldBag.AdminPassword === undefined || props.joinWorldBag.AdminPassword.length === 0) {
            return "(Blank)";
        }

        return props.joinWorldBag.AdminPassword.replace(/./g, '*');
    }

    function formatStartLocation() {
        if (props.joinWorldBag.StartLocation === undefined || props.joinWorldBag.StartLocation === "random") {
            return "Random";
        }
        if (props.joinWorldBag.StartLocation === "byFriend") {
            if (props.joinWorldBag.Invitation && props.joinWorldBag.Invitation.player) {
                const player = props.joinWorldBag.Invitation.player;
                return `By ${player.name}${player.capitalSolarSystem ? `, near ${player.capitalSolarSystem.x}, ${player.capitalSolarSystem.y}` : ""}`
            }
            return "By Friend";
        }
        return ValueFormatter.spacesBeforeCapitals(WorldQuandrant[props.joinWorldBag.StartLocation])
    }

    return <SubPanel heading="Confirm" footerContent={
        <ButtonHolder isPulledRight>
            <PreviousButton action={props.previousStage} />
            <Button
                type="action"
                icon={IconHelper.General.Confirm}
                action={() => props.startPlaying()}
                text="Start Playing"
                isDisabled={!props.joinWorldBag.IsValid}
            />
        </ButtonHolder>
    }>
        <Paragraph>
            You are now ready to play Fusion Shift! Check your choices here and then press Start Playing to begin.
        </Paragraph>
        <br />
        {props.joinWorldBag.World &&
            <ul>
                {props.joinWorldBag.FactionTypeName && <li>
                    <Icon icon={IconHelper.Categories.Factions} /> Faction: {props.joinWorldBag.World.settings.factionTypeSettings.data[props.joinWorldBag.FactionTypeName].name}
                </li>}
                <li>
                    <Icon icon={IconHelper.Map.ShowOnMap} /> Start Location: {formatStartLocation()}
                </li>
                {props.joinWorldBag.World.status === WorldStatus.LockedToAdmins && <li>
                    <Icon icon={IconHelper.Account.Password} /> Admin Password: {formatAdminPassword()}
                </li>}
            </ul>
        }
        {props.joinWorldBag.Error && <>
            <br />
            <ErrorMessageBox text={props.joinWorldBag.Error.error!} />
        </>}
    </SubPanel>;
});