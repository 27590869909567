import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetOrders, IRepeatCreateInput, IRepeatFleetConfig, RepeatCreateInput, RepeatFleetConfig } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SuccessMessageBox } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';
import { SendShipsHelper } from '../../../Helpers/SendShipsHelper';
import { SendShipsResult } from '../SendShipsForm';
import { RepeatControl } from './Config/RepeatControl';

type Props = {
    result: SendShipsResult
}

export const PostSendCreateRepeat = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [wasSuccess, setWasSuccess] = React.useState(false);

    function createRepeat() {

        const sendShipsBag = props.result.sendShipsBag;

        if (fleet &&
            sendShipsBag.repeat &&
            sendShipsBag.TargetSolarSystem &&
            sendShipsBag.MovementType !== undefined) {

            const config: IRepeatFleetConfig = {
                ...sendShipsBag.Config,
                ...fleet.config,
                ...sendShipsBag.RepeatConfiguration
            };

            const create: IRepeatCreateInput = {
                sourceSolarSystemId: sendShipsBag.init.sourceSolarSystem.solarSystemId,
                movementType: sendShipsBag.MovementType,
                targetSolarSystemId: sendShipsBag.TargetSolarSystem.solarSystemId,
                targetCelestialId: sendShipsBag.TargetCelestialId,
                ships: sendShipsBag.shipQuantities,

                itemsToSend: sendShipsBag.allowedConfigurations.itemsToSend || sendShipsBag.allowedConfigurations.commoditiesToSend ? sendShipsBag.itemsToSend : undefined,
                itemsToCollect: sendShipsBag.allowedConfigurations.itemsToCollect ? sendShipsBag.itemsToCollect : undefined,

                delayHours: sendShipsBag.RepeatConfiguration.delayHours ?? 1,
                numberOfTimes: sendShipsBag.RepeatConfiguration.numberOfTimes,

                contractId: sendShipsBag.Contract?.contractId,

                orders: new FleetOrders(sendShipsBag.Orders),
                config: new RepeatFleetConfig(config)
            };

            const input = new RepeatCreateInput(create);

            apiState.FleetClient.createRepeatFleet(sendShipsBag.init.sourceSolarSystem.solarSystemId, input).then(solarSystem => {
                solarSystemState.loadSolarSystem(solarSystem);
                setWasSuccess(true);
            });
        }
    }

    const fleet = props.result.fleets?.find(x => true);

    const wasRepeatMade = props.result.showRepeat;
    const canRepeat = fleet?.targetSolarSystem !== undefined;

    const shouldShowForm = fleet !== undefined &&
        SendShipsHelper.canRepeat(fleet.movementType) &&
        !wasRepeatMade &&
        canRepeat;

    if (!shouldShowForm) {
        return null;
    }

    if (props.result.sendShipsBag.RepeatConfiguration.delayHours === undefined) {
        props.result.sendShipsBag.setRepeatConfiguration(x => {
            return {
                ...x,
                delayHours: 0
            }
        });
    }

    if (wasSuccess) {
        return <>
            <hr />
            <SuccessMessageBox text="Created repeat fleet" />
        </>
    }

    return <>
        <RepeatControl
            sendShipsBag={props.result.sendShipsBag}
        />
        {props.result.sendShipsBag.repeat && <Button
            type="action"
            icon={IconHelper.Ships.Repeat}
            text="Create Repeat"
            action={createRepeat}
            isPulledRight
        />
        }
    </>
});