import * as React from 'react';
import { ContractFleetStatus } from '../../../ApplicationState/ApiClient';

type Props = {
    contractFleetStatus: ContractFleetStatus
}

function fleetStatusClassName(contractFleetStatus: ContractFleetStatus) {

    if (contractFleetStatus === ContractFleetStatus.Failed) {
        return "has-text-danger";
    }

    if (contractFleetStatus === ContractFleetStatus.EnRoute) {
        return "has-text-warning";
    }

    if (contractFleetStatus === ContractFleetStatus.Completed) {
        return "has-text-success";
    }

    return undefined;
}

export const ContractFleetStatusDescription = (props: Props) => {

    const className = fleetStatusClassName(props.contractFleetStatus)

    return <span className={className}>
        {ContractFleetStatus[props.contractFleetStatus]}
    </span>;
}