import React from "react";
import { InstallationType } from "../../../ApplicationState/ApiClient";
import { celestial_installation } from "../../../Navigation/Routing/SolarSystem";
import { InstallationAnimations } from "./InstallationAnimations";
import { InstallationTypeImage } from "./InstallationTypeImage";
import { SvgImageBaseProps } from "./SvgImage";

type Props = SvgImageBaseProps & {
    installationType: InstallationType,
    installation: { installationId: number, damage?: number, level?: number },
    isConstructing?: boolean,
    celestialId: number,
    noLink?: boolean
}

export const InstallationImage = (props: Props) => {

    const to = props.noLink ? undefined : celestial_installation(props.celestialId, props.installation.installationId);

    return <InstallationTypeImage {...props} to={to} level={props.installation.level} >
        <InstallationAnimations damage={props.installation.damage ?? 0} isConstructing={props.isConstructing} />
    </InstallationTypeImage>;
};
