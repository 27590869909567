import * as React from 'react';
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { QuantityIcon, QuantityIconProps } from "../QuantityIcon";

type Props = QuantityIconProps & {
    relicType?: ItemType | undefined | false,
    isGiant?: boolean,
    label?: string | undefined
}

export const RelicIcon = (props: Props) => {

    const name = props.relicType ? props.relicType.name : "Relic";
    const label = props.label ? props.label : name;

    return <QuantityIcon name={label} icon={IconHelper.Items.Relic} className="relics" {...props} />;
};