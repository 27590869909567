import React from "react";
import { AppStateContext } from "../../../ApplicationState/ContextRoot";
import { Button, ButtonBaseProps, ButtonTypes } from "./Button";

type Props = ButtonBaseProps & {
    to: string,
    type?: ButtonTypes
}

export const NavButton = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    function navigate() {
        appState.navigate(props.to);
        return true;
    }

    return <Button action={navigate} type={!props.type ? "nav" : props.type} {...props} />;
};

NavButton.defaultProps = {
    type: "nav"
};