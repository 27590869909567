import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { AppState } from "../States/AppState";
import { mapHub } from "./HubMappings";

export class HubManager {

    private _appState: AppState;

    private _chatHub: HubConnection | undefined;
    private _notificationsHub: HubConnection | undefined;
    private _accessTokenFactory: () => string;

    constructor(appState: AppState, accessTokenFactory: () => string, loadAndPoll: boolean) {
        this._appState = appState;
        this._accessTokenFactory = accessTokenFactory;

        if (loadAndPoll) {
            window.setInterval(() => this.hubPoll(), 30 * 1000);
            window.setInterval(() => this.notificationsHubPoll(), 30 * 1000);
        }
    }

    public closeHubs() {
        if (this._chatHub) {
            this._chatHub.stop();
            this._chatHub = undefined;
        }
        if (this._notificationsHub) {
            this._notificationsHub.stop();
            this._notificationsHub = undefined;
        }
    }

    public ensureHubConnections() {
        this.ensureChatHubConnection();
        this.ensureNotificationHubConnection();
    }

    private ensureChatHubConnection() {

        if (!this._chatHub && this._appState.ClientSettings && this._appState.ClientSettings.chatHubUrl !== "") {
            this._chatHub = new HubConnectionBuilder()
                .withUrl(this._appState.ClientSettings.chatHubUrl, { accessTokenFactory: this._accessTokenFactory })
                .build();

            this._chatHub.on("chatMessage", msg => {
                this._appState.ChatState.messageReceived(msg);
            });

            this._chatHub.on("chatsChanged", _ => {
                if (this._chatHub) {
                    this._chatHub.stop();
                }
                this._chatHub = undefined;
                this._appState.reload("Chats Changed");
            });

            this._chatHub.on("apiVersion", apiVersion => {
            });
        }

        if (this._chatHub && this._chatHub.state === HubConnectionState.Disconnected) {
            this._chatHub.start();
        }
    }

    private ensureNotificationHubConnection() {
        if (!this._notificationsHub && process.env.REACT_APP_NOTIFICATIONS_API_URL) {
            this._notificationsHub = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_NOTIFICATIONS_API_URL}/hubs/user`, { accessTokenFactory: this._accessTokenFactory })
                .build();

            mapHub(this._notificationsHub, this._appState);
        }

        if (this._notificationsHub && this._notificationsHub.state === HubConnectionState.Disconnected) {
            this._notificationsHub.start();
        }
    }

    private hubPoll() {
        if (this._chatHub && this._chatHub.state === HubConnectionState.Connected) {
            this._chatHub.send("RequestApiVersion");
        } else if (this._appState.PlayerState.Player) {
            this.ensureChatHubConnection();
        }
    }

    private notificationsHubPoll() {
        if ((!this._notificationsHub || this._notificationsHub.state !== HubConnectionState.Connected) && this._appState.PlayerState.Player) {
            this.ensureNotificationHubConnection();
        }
    }

    public sendChatMessage(chatId: number, message: string) {
        if (this._appState.ChatState.Chats && this._appState.ChatState.Chats.findIndex(c => c.Chat.chatId === chatId) > -1 && this._chatHub) {
            return this._chatHub.send("SendChatMessage", chatId, message);
        }
    }
}