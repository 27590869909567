import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ShipClass } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { ButtonChooser, choice } from '../../../../Components/Base/Buttons/ButtonChooser';
import { SplitLayout } from '../../../../Components/Base/Containers/SplitLayout';
import { Table } from '../../../../Components/Base/Containers/Table';
import { FieldHolder } from '../../../../Components/Base/Form/FieldHolder';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { ShipClassIcon } from '../../../../Components/FusionShift/Icons/ShipClassIcon';
import { InstallationHelper } from '../../../../Helpers/InstallationHelper';
import { TimeHelper } from '../../../../Helpers/TimeHelper';
import { SimpleBaseView } from '../../../BaseView';
import { BalanceCell } from './Components/BalanceCell';

export const ProductionView = observer(() => {

    const resourceKey = "Metal";

    const worldState = React.useContext(WorldStateContext);

    const [period, setPeriod] = React.useState(1);
    const [stockpileStoragePerLevel, setStockpileStoragePerLevel] = React.useState<number | undefined>(undefined);
    const [resourceStoragePerLevel, setResourceStoragePerLevel] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (worldState.InstallationTypeSettings) {
            if (stockpileStoragePerLevel === undefined) {
                setStockpileStoragePerLevel(worldState.InstallationTypeSettings.data["Stockpile"].resourceCapacitiesAdded[resourceKey]);
            }
            if (resourceStoragePerLevel === undefined) {
                setResourceStoragePerLevel(Object.values(worldState.InstallationTypeSettings!.data).find(i => i.resourceProduced === resourceKey)!.resourceCapacitiesAdded[resourceKey]);
            }
        }
    }, [worldState.InstallationTypeSettings]);

    if (!worldState.InstallationTypeSettings || !worldState.GameSettings || !stockpileStoragePerLevel || !resourceStoragePerLevel) {
        return null;
    }


    const figherCost = worldState.ShipTypeSettings!.data["Fighter_A"].cost!.items[resourceKey];
    const battleshipCost = worldState.ShipTypeSettings!.data["Battleship_A"].cost!.items[resourceKey];;

    const levels: number[] = [];
    for (let i = 1; i <= 20; i++) {
        levels.push(i);
    }
    const expectedNumberOfProducers = 3;

    function stockpileStorage(level: number) {
        return InstallationHelper.storageAtLevel(level, stockpileStoragePerLevel!);
    }

    function resourceProducerStorage(level: number) {
        return InstallationHelper.storageAtLevel(level, resourceStoragePerLevel!);
    }

    function totalStorage(level: number) {
        return (resourceProducerStorage(level) * 3) + stockpileStorage(level);
    }

    function totalProductionPerHour(level: number) {
        return InstallationHelper.resourceProductionPerHour(level, worldState.GameSettings!) * 3;
    }

    function totalProduction(level: number) {
        return totalProductionPerHour(level) * period;
    }

    const options = [
        choice(1, "Hour"),
        choice(24, "Day")
    ]

    return <SimpleBaseView heading={"Balance - Production"}    >
        <SplitLayout
            left={<FieldHolder label="Stockpile Storage">
                <input
                    className="input is-small input-5"
                    type="number"
                    size={5}
                    value={stockpileStoragePerLevel}
                    onChange={e => setStockpileStoragePerLevel(e.currentTarget.valueAsNumber)}
                />
            </FieldHolder>}
            right={<FieldHolder label="Resource Generator Storage">
                <input
                    className="input is-small input-5"
                    type="number"
                    size={5}
                    value={resourceStoragePerLevel}
                    onChange={e => setResourceStoragePerLevel(e.currentTarget.valueAsNumber)}
                />
            </FieldHolder>}
        />
        <ButtonChooser valueChanged={setPeriod} value={period} values={options} />
        <Paragraph type="prompt">
            Showing stats for {expectedNumberOfProducers} resource producing installations. The production is the same for each resource. All stats are per {period === 1 ? "hour" : "day"}
        </Paragraph>
        <Table isHoverable isFullWidth heading={
            <>
                <th>Level</th>
                <th>Stockpile</th>
                <th>Per Installation</th>
                <th>Total</th>
                <th>Production</th>
                <th>Time to fill</th>
                <th><ShipClassIcon shipClass={ShipClass.Fighter} /></th>
                <th><ShipClassIcon shipClass={ShipClass.Battleship} /></th>
            </>
        }>
            {levels.map(level => <tr key={level}>
                <td>
                    {level}
                </td>
                <BalanceCell value={stockpileStorage(level)} />
                <BalanceCell value={resourceProducerStorage(level)} />
                <BalanceCell value={totalStorage(level)} />
                <BalanceCell value={totalProduction(level)} />
                <td>
                    {TimeHelper.formatTimeSpanByWords(TimeHelper.createTimeSpanFromHours(totalStorage(level) / totalProductionPerHour(level)))}
                </td>
                <BalanceCell value={totalProduction(level) / figherCost} decimals={2} />
                <BalanceCell value={totalProduction(level) / battleshipCost} decimals={2} />
            </tr>)}
        </Table>
    </SimpleBaseView>;
});
