export function nameof<T>(key: keyof T, instance?: T): keyof T {
    return key;
}

export function enumFromString<T>(type: T, str: string): T[keyof T] {
    const casted = str as keyof T;
    return type[casted];
}

export function valuesFromEnum<T>(type: T): T[keyof T][] {
    const values: T[keyof T][] = [];

    for (let item in type) {
        const currentSizeAsNumber = Number(item);
        if (isNaN(currentSizeAsNumber)) {
            const val = enumFromString(type, item);
            values.push(val);
        }
    }
    return values;
}