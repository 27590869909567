import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { UnreadCombatReportIcon, UnreadMessageIcon, UnreadMissionIcon, UnreadNotificationIcon } from '../../../Components/FusionShift/Icons/UnreadIcons';
import { missions } from '../../../Navigation/Routing/Help';
import { combatReports, messages, playerNotifications } from '../../../Navigation/Routing/NotificationsEtc';
import { emblems, inviteFriends, settings } from '../../../Navigation/Routing/Personal';
import { player_securitystatus } from '../../../Navigation/Routing/Player';

export const Personal = observer(() => {

    const playerState = React.useContext(PlayerStateContext);

    return <SubPanel heading="Personal">
        <SplitLayout
            left={
                <ul>
                    <li>
                        <Link to={messages}>Messages </Link><UnreadMessageIcon />
                    </li>
                    <li>
                        <Link to={combatReports}>Combat Reports </Link> <UnreadCombatReportIcon />
                    </li>
                    <li>
                        <Link to={playerNotifications}>Notifications </Link><UnreadNotificationIcon />
                    </li>
                    <li>
                        <Link to={missions}>Missions </Link><UnreadMissionIcon />
                    </li>
                </ul>
            }
            right={
                <ul>
                    <li>
                        <Link to={player_securitystatus}>Security Status</Link>
                    </li>
                    <li>
                        <Link to={emblems}>Emblem Editor</Link>
                    </li>
                    <li>
                        <Link to={inviteFriends}>Invite Friends</Link>
                    </li>
                    <li>
                        <Link to={settings}>Settings</Link>
                    </li>
                </ul>
            } />
    </SubPanel>;
});