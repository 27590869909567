import { observer } from "mobx-react-lite";
import React from "react";
import { SolarSystemStateContext } from "../../../ApplicationState/ContextRoot";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { send_ships } from "../../../Navigation/Routing/Misc";
import { IconLink } from "../../Base/IconLink";
import { ISolarSystemLink } from "../Links/SolarSystemLink";

type Props = {
    solarSystem: ISolarSystemLink
}

export const SendShipsIconLink = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!solarSystemState.SolarSystem ||
        props.solarSystem.solarSystemId === solarSystemState.SolarSystem.solarSystemId ||
        !CollectionHelper.isAnyQuantityInDictionary(solarSystemState.SolarSystem.availableShips)) {
        return null;
    }

    return <IconLink icon={IconHelper.Ships.Send} to={`${send_ships}?targetX=${props.solarSystem.x}&targetY=${props.solarSystem.y}`} title={`Send ships from ${solarSystemState.SolarSystem.name}`} />;
});
