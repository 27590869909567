import { observer } from "mobx-react-lite";
import React from "react";
import { Portal } from 'react-portal';
import { DeleteInput } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Checkbox } from "../../Components/Base/Form/Checkbox";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { ErrorMessageBox, WarningMessageBox } from "../../Components/Base/MessageBox";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";

export const DeleteAccount = observer(() => {
    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    const [showModal, setShowModal] = React.useState(false);
    const [requestState, setRequestState] = React.useState<"requesting" | "failed">();
    const [accepted, setAccepted] = React.useState<boolean>(false);
    const [unsubscribeEmail, setUnsubscribeEmail] = React.useState<boolean>(false);
    const [password, setPassword] = React.useState<string>("");

    async function requestDeletion() {
        try {
            setRequestState("requesting");

            var response = await apiState.AuthenticationClient.delete(
                DeleteInput.fromJS({ password: password, unsubscribeEmail: unsubscribeEmail }));

            if (!response || response.status !== 200) {
                setRequestState("failed");
                return;
            }

            appState.clearUser();
        }
        catch {
            setRequestState("failed");
        }
    }

    function isLoading() {
        return requestState === "requesting"
    }

    return <>
        <SubPanel heading={{ text: "Delete your account", icon: IconHelper.General.Delete }}>
            <Paragraph>
                Permanently delete your account. This will remove your account and related information from all Fusion Shift Online servers and databases. Your player and systems will be renamed and taken over by a bot.
            </Paragraph>
            <Button type="danger" isPulledRight text="Delete account" icon={IconHelper.General.Delete} action={() => { setShowModal(true); }} isDisabled={showModal} />
        </SubPanel>
        {showModal && <Portal node={document && document.getElementById('modal-root')}>
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Delete your account</p>
                    </header>
                    <section className="modal-card-body content" style={{ textAlign: "left" }}>
                        {isLoading() && <LoadingSpinner />}
                        {!isLoading() && <>
                            <Paragraph>Are you sure you want to delete your account? Please consider the following:</Paragraph>
                            {appState.HasActiveSubscription && <WarningMessageBox text="You have an active subscription which will be cancelled if you delete your account" />}
                            <ul>
                                <li>A bot will be assigned to continue management of your empire</li>
                                <li>Any potential sensitive information will be permanently deleted. This includes but is not limited to:
                                    <ul>
                                        <li>Email address</li>
                                        <li>Display name</li>
                                        <li>Public and private messages</li>
                                    </ul>
                                </li>
                                <li>This is a non-reversable action and you won't ever be able to recover your account or game progress</li>
                            </ul>
                            <Checkbox value={accepted} valueChanged={setAccepted} label="I have read and understand the above information" />
                            <Checkbox value={unsubscribeEmail} valueChanged={setUnsubscribeEmail} label="Remove email address from mailing lists" />
                            <ValidatingStringInput
                                label="If you wish to proceed, please provide your password:"
                                icon={IconHelper.Account.Password}
                                type="password"
                                valueChanged={x => setPassword(x.validText ?? "")}
                                validateDelay={0} />
                            {requestState === "failed" && <ErrorMessageBox text="Delete account request failed" />}
                        </>}
                    </section>
                    <footer className="modal-card-foot">
                        {!isLoading() && <ButtonHolder isPulledRight>
                            <Button
                                type="danger"
                                isPulledRight
                                text="Delete"
                                icon={IconHelper.General.Delete}
                                action={requestDeletion}
                                isDisabled={!accepted} />
                            <Button
                                type="action"
                                isPulledRight text="Cancel"
                                icon={IconHelper.General.Cancel}
                                action={() => { setShowModal(false) }} />
                        </ButtonHolder>}
                    </footer>
                </div>
            </div>
        </Portal>}
    </>
});