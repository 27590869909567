import { makeAutoObservable } from "mobx";
import { PlayerMissionLog } from "../ApiClient";

export class MissionLogState {

    MissionLog: PlayerMissionLog | undefined;

    get isFirstMissionPending() {
        if (this.MissionLog && this.MissionLog.rewardPendingMissions.length == 1 && this.MissionLog.completedMissions.length == 0) {
            return true;
        }
        return false;
    }

    constructor() {
        makeAutoObservable(this);
    }

    public loadMissionLog(missionLog: PlayerMissionLog | undefined) {
        this.MissionLog = missionLog;
    }
}