import { observer } from "mobx-react-lite";
import React from "react";
import { useLocation } from "react-router-dom";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { Icon } from "../../../Components/Base/Icon";
import { IconHelper } from "../../../Helpers/IconHelper";
import { emblems, inviteFriends, settings } from "../../Routing/Personal";
import { player_securitystatus, player_view } from "../../Routing/Player";
import { MenuLink, isActiveSubMenu } from "../MenuState";
import { CustomMenu } from "./CustomMenu";
import { PremiumLink } from "./PremiumLink";

export const PersonalMenu = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const location = useLocation();

    const [isOpen, setIsOpen] = React.useState(false);

    if (!playerState.Player) {
        return null;
    }

    const isSubViewActive = isActiveSubMenu(location, emblems, settings, inviteFriends);

    return <CustomMenu
        className={isSubViewActive}
        canBeOpened
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        primaryLink={
            <MenuLink className="navbar-link" to={player_view(playerState.Player.playerId)}>
                <Icon icon={IconHelper.Security.securityStatus(playerState.Player.securityStatus)} />
                {playerState.Player.name}
            </MenuLink>
        }
    >
        <MenuLink to={emblems} className="navbar-item">
            Emblem
        </MenuLink>
        <MenuLink to={player_securitystatus} className="navbar-item">
            Security Status
        </MenuLink>
        <MenuLink to={inviteFriends} className="navbar-item">
            Invite Friends
        </MenuLink>
        <MenuLink to={settings} className="navbar-item">
            Settings
        </MenuLink>
        <PremiumLink />
    </CustomMenu>;
});