import 'moment/locale/en-gb';
import { AgentStatus, ContractType, FleetMovementType, ItemCategory, PolicyCategory, PolicyProposalType, SecurityStatus, ShipClass } from "../ApplicationState/ApiClient";
import { Coordinate } from '../Entities/Shared';
import { FleetHelper } from './FleetHelper';
import { TimeHelper } from './TimeHelper';


// Gross hack, can't seem to "import * as moment from 'moment'" as the docs say
let moment = require("moment");
if ("default" in moment) {
    moment = moment["default"];
    moment.locale(window.navigator.language);
}

const vowels = ["a", "e", "i", "o", "u"];

export class ValueFormatter {


    public static friendlyNameForPolicyCategory(category: PolicyCategory) {
        return PolicyCategory[category];
    }

    private static moment(date: Date) {
        return moment(date);
    }

    public static toShorthand(value: number) {
        if (value >= 1000000) {
            value /= 1000000;
            return `${value.toFixed(2).toLocaleString()}m`;
        }
        if (value >= 1000) {
            value /= 1000;
            const dp = value >= 10 ? 0 : 1;
            return `${value.toFixed(dp).toLocaleString()}k`;
        }
        return `${value.toFixed(0).toLocaleString()}`;
    }

    public static formatPopulation(value: number, decimalDigits?: number): string {
        if (value < 1000) {
            return `${ValueFormatter.formatLocaleNumber(value, decimalDigits)}bn`;
        }
        value /= 1000;
        if (value < 1000) {
            return `${ValueFormatter.formatLocaleNumber(value, decimalDigits)}tn`;
        }
        value /= 1000;
        return `${ValueFormatter.formatLocaleNumber(value, decimalDigits)}qn`;
    }

    public static formatSize(value: number, decimalDigits?: number): string {
        return `${ValueFormatter.formatLocaleNumber(value, decimalDigits)}`;
    }

    public static friendlyNameForContractType(m: ContractType): string {

        if (m === ContractType.BribeForManifesto) {
            return "Bribe for manifesto";
        }
        if (m === ContractType.BribeForPolicy) {
            return "Bribe for policy";
        }

        return ContractType[m];
    }

    public static friendlyNameForAgentStatus(status: AgentStatus | null | undefined) {
        if (status === null || status === undefined) {
            return "Unknown";
        }

        return AgentStatus[status];
    }

    public static friendlyNameForShipClass(shipClass: ShipClass) {
        if (shipClass === ShipClass.AdvancedMiningBarge) {
            return "Advanced Mining Barge";
        }

        if (shipClass === ShipClass.MiningBarge) {
            return "Mining Barge";
        }

        if (shipClass === ShipClass.OrbitalSiege) {
            return "Orbital Siege";
        }

        if (shipClass === ShipClass.ColonyPod) {
            return "Colony Pod";
        }

        return ShipClass[shipClass];
    }

    public static friendlyNameForPolicyProposalType(proposalType: PolicyProposalType): string {
        switch (proposalType) {
            case PolicyProposalType.DemocraticReformation:
                return "Democratic Reformation";
            case PolicyProposalType.ExecutiveOrder:
                return "Executive Order";
            case PolicyProposalType.Vote:
                return "Vote";
        }
    }

    public static friendlyNameForItemCategory(m: ItemCategory): string {

        if (m === ItemCategory.RawResource) {
            return "Raw Resource";
        }

        return ItemCategory[m];
    }

    public static friendlyDescriptionOfFleetDeparture(m: FleetMovementType): string {

        if (m === FleetMovementType.CommoditySell) {
            return "Sent a fleet to sell commodities";
        }
        if (m === FleetMovementType.Collection) {
            return "Sent a fleet to collect cargo";
        }
        if (m === FleetMovementType.Delivery) {
            return "Sent a fleet to deliver cargo";
        }

        return `Sent a fleet to ${FleetMovementType[m].toLocaleLowerCase()}`;
    }

    public static friendlyNameForMovementType(m: FleetMovementType): string {

        if (m === FleetMovementType.CommoditySell) {
            return "Sell Commodities";
        }
        if (m === FleetMovementType.ReturnHome) {
            return "Return Home";
        }
        return FleetMovementType[m];
    }

    public static friendlyNameForSecurityStatus(s: SecurityStatus): string {

        if (s === SecurityStatus.CombatActive) {
            return "Combat Active";
        }

        return SecurityStatus[s];
    }

    public static warningForDurationDefault(date: Date) {
        return this.warningForDuration(date, 10, 10 * 60 * 12, 10 * 60 * 24);
    }

    public static warningForDuration(date: Date, dangerMinutes: number, warningMinutes: number, infoMinutes: number): string {

        const duration = moment.utc().diff(date, 'seconds') / 60;

        if (duration <= dangerMinutes) {
            return "danger";
        } else if (duration <= warningMinutes) {
            return "warning";
        } else if (duration <= infoMinutes) {
            return "info";
        }

        return "";
    }

    public static formatDateAndFromNowWithMoment(date: Date | undefined) {
        if (date === undefined) {
            return "";
        }

        const fMoment = this.formatDateFromNowWithMoment(date);
        const fDate = this.formatTimeOrDate(date);

        return `${fDate} (${fMoment})`;
    }

    public static formatDateFromNowWithMomentOrTimeOrDate(date: Date | undefined) {
        if (date === undefined) {
            return "";
        }

        if (date > new Date()) {
            return this.formatDateFromNowWithMoment(date);
        }

        return this.formatTimeOrDate(date);
    }

    private static _tfWithSeconds = "HH:mm:ss";
    private static _tfWithoutSeconds = "HH:mm";
    public static formatTimeOrDate(date: Date | undefined, withToday: boolean = false, withTimeIfDate: boolean = true, withSeconds: boolean = true, dateFormat?: string | undefined): string {

        if (date === undefined) {
            return "";
        }

        if (typeof date === "string") {
            date = new Date(date);
        }
        const now = new Date();

        const isSameDay = TimeHelper.isSameDay(date, now);

        const dateFormatToUse = dateFormat === undefined ? "L" : dateFormat;
        const tf = withSeconds ? this._tfWithSeconds : this._tfWithoutSeconds;
        const dtf = withTimeIfDate ? `${dateFormatToUse} ${tf}` : dateFormatToUse;

        if (!isSameDay) {
            return this.moment(date).format(dtf);
        }

        return `${this.moment(date).format(tf)}${withToday ? " (today)" : ""}`;
    }

    public static formatShortDate(date: Date) {
        return this.moment(date).format("L");
    }

    public static formatMsDifference(a: Date | null | undefined, b: Date | null | undefined): string {
        if (!a || !b) {
            return "";
        }

        return Math.abs(a.getTime() - b.getTime()).toString();
    }

    public static formatDateFromNowWithMoment(date: Date): string {
        return this.moment(date).fromNow();
    }

    // Show a full ratio as a modifier %
    // E.g 0.8 => reduced by 20%
    // E.g 1.5 => increased by 50%
    public static fromDecimalToDisplayPercentAsModifierLonghand(value: number) {
        return `${value < 1 ? "reduced " : "increased "} by ${ValueFormatter.fromDecimalToDisplayPercent(value - 1, true, 0)}`;
    }

    // Show a modifier as a %
    // E.g 0.2 => increased by 20%
    // E.g -0.35 => reduced by 35%
    public static fromModifierToDisplayPercentLonghand(value: number) {
        return `${value < 0 ? "reduced " : "increased "} by ${ValueFormatter.fromDecimalToDisplayPercent(value, true, 0)}`;
    }

    public static fromDecimalToDisplayPercentAsModifier(value: number, noSign?: boolean, precision?: number | undefined): string {
        if (value < 1) {
            return `${noSign ? "" : "-"}${this.fromDecimalToDisplayPercent(1 - value, false, precision)}`;
        }
        return this.fromDecimalToDisplayPercent(value, false, precision);
    }

    public static fromDecimalToDisplayPercent(value: number, noSign?: boolean, precision?: number | undefined, addPlus?: boolean | undefined): string {


        const prefix = addPlus !== undefined && addPlus && value > 0 ? "+" : "";

        if (noSign) {
            value = Math.abs(value);
        }

        const asString = (value * 100).toFixed(precision !== undefined ? precision : 1).toLocaleString();

        return `${prefix}${asString}%`;
    }

    public static toRomanNumeral(value: number): string {
        if (value < 0 || value > 3999) throw Error();
        if (value < 1) return "";
        if (value >= 1000) return "M" + this.toRomanNumeral(value - 1000);
        if (value >= 900) return "CM" + this.toRomanNumeral(value - 900);
        if (value >= 500) return "D" + this.toRomanNumeral(value - 500);
        if (value >= 400) return "CD" + this.toRomanNumeral(value - 400);
        if (value >= 100) return "C" + this.toRomanNumeral(value - 100);
        if (value >= 90) return "XC" + this.toRomanNumeral(value - 90);
        if (value >= 50) return "L" + this.toRomanNumeral(value - 50);
        if (value >= 40) return "XL" + this.toRomanNumeral(value - 40);
        if (value >= 10) return "X" + this.toRomanNumeral(value - 10);
        if (value >= 9) return "IX" + this.toRomanNumeral(value - 9);
        if (value >= 5) return "V" + this.toRomanNumeral(value - 5);
        if (value >= 4) return "IV" + this.toRomanNumeral(value - 4);
        if (value >= 1) return "I" + this.toRomanNumeral(value - 1);
        throw Error();
    }

    private static isVowel(text: string) {
        return vowels.includes(text);
    }

    public static aOrAn(text: string, capitalize?: boolean): string {
        if (text.length > 0) {
            const first = text[0].toLowerCase();

            if (this.isVowel(first)) {
                return capitalize ? "An" : "an";
            }
        }

        return capitalize ? "A" : "a";
    }

    public static pluralizeAndWriteValue(text: string, value: number): string {
        return `${value === 1 ? "is" : "are"} ${value} ${this.pluralize(text, value)}`;
    }

    public static pluralize(text: string, value: number): string {
        if (value === 1) {
            return text;
        }
        if (text.endsWith("y") && !this.isVowel(text[text.length - 2])) {
            return text.substring(0, text.length - 1) + "ies";
        }

        return text + "s";
    }

    public static rank(rank: number | undefined): string {
        if (rank === undefined || rank <= 0) {
            return "-";
        }

        return rank.toLocaleString();
    }

    public static formatMass(mass: number | undefined | null, isEstimate: boolean) {

        if (mass === undefined || mass === null) {
            return "";
        }

        mass /= 10;
        return `${isEstimate ? "~" : ""}${this.formatLocaleNumber(mass)}t`
    }

    public static formatSpeed(speed: number) {
        return `${this.formatLocaleNumber(speed)}pc/h`;
    }

    public static formatLocaleNumberFromDictionary(key: string | undefined, dictionary: { [key: string]: string | number | undefined } | undefined, defaultToZero?: boolean, decimalDigits?: number | undefined) {
        let value = dictionary && key && key in dictionary ? dictionary[key] : undefined;
        if (value === undefined) {
            value = 0;
        }
        return this.formatLocaleNumber(value, decimalDigits);
    }

    public static formatLocaleNumber(value: string | number | undefined | null, decimalDigits?: number | undefined) {
        if (value === undefined || value === null) {
            return "";
        }
        if (typeof value === "string") {
            return value;
        }
        if (decimalDigits === undefined) {
            decimalDigits = 0;
        }
        return value.toLocaleString(undefined, {
            minimumFractionDigits: decimalDigits,
            maximumFractionDigits: decimalDigits
        })
    }

    public static spacesBeforeCapitals(value: string) {
        return value.replace(/([A-Z])/g, ' $1').trim();
    }

    public static formatCurrency(value: number) {
        return "£" + this.formatLocaleNumber(value, 2);
    }

    public static decimalHoursToText(hours: number) {
        const hrs = Math.floor(hours);
        const mins = 60 * (hours - hrs);

        const minText = mins > 0 ? `, ${mins} minutes` : "";

        return `${hrs} hours${minText}`;
    }

    public static formatDistanceBetween(a: Coordinate, b: Coordinate) {
        const distance = FleetHelper.distance(a, b);

        return this.formatDistance(distance);
    }

    public static formatDistance(distance: number | undefined) {

        if (distance === undefined) {
            return null;
        }

        return `${distance.toFixed(1).toString()}pc`;
    }
}