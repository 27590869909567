import React from "react";
import { PolicyDuration, PolicyType } from "../../../ApplicationState/ApiClient";
import { Paragraph } from "../../Base/Text/Paragraph";

type Props = {
    policyType: PolicyType
}

export const PolicyTypeDuration = (props: Props) => {

    return <Paragraph>
        {props.policyType.duration === PolicyDuration.Instant && "This policy has an instantaneous effect once enacted."}
        {props.policyType.duration === PolicyDuration.Indefinite && "Once enacted this policy lasts until repealed or replaced."}
        {props.policyType.duration === PolicyDuration.Fixed && `This policy's effect will last for ${props.policyType.durationDays} days after it is enacted.`}
    </Paragraph>;
}