import { observer } from "mobx-react-lite";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { PolicyCategory, PolicyType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { TabMenu } from "../../../Components/Base/Containers/TabMenu";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { PolicyTypeDescription } from "../../../Components/FusionShift/Descriptions/PolicyTypeDescription";
import { PolicyCategoryIcon } from "../../../Components/FusionShift/Icons/PolicyCategoryIcon";
import { TypeDataVoice } from "../../../Components/FusionShift/TypeDataVoice";
import { CommodityHelper } from "../../../Helpers/CommodityHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { UrlHelper } from "../../../Helpers/UrlHelper";
import { help_page } from "../../../Navigation/Routing/Help";
import { TypeDataBaseView } from "./TypeDataBaseView";

type Props = {
    policyType: PolicyType,
    commodityHelper: CommodityHelper
}

const PolicyTypeControl = (props: Props) => {

    return <SubPanel
        id={props.policyType.typeName}
        heading={{ text: props.policyType.name, icon: <PolicyCategoryIcon policyCategory={props.policyType.category} /> }}
        headingContent={<TypeDataVoice type="PolicyType" typeData={props.policyType} />}
    >
        <PolicyTypeDescription {...props} />
    </SubPanel >;
};

const PolicyTypesContent = observer(() => {

    const worldState = React.useContext(WorldStateContext);

    const [category, setCategory] = React.useState<PolicyCategory | undefined>(undefined);
    const [availableCategories, setAvailableCategories] = React.useState<PolicyCategory[] | undefined>(undefined);

    const location = useLocation();

    if (!worldState.PolicyTypeSettings) {
        return <LoadingSpinner />;
    }

    const policyTypeToFind = UrlHelper.tryGetValueFromSearch(location, "policyTypeName");

    let categoriesToUse: PolicyCategory[] = [];
    const policies = Object.keys(worldState.PolicyTypeSettings.data).map(k => {
        const policyType = worldState.PolicyTypeSettings!.data![k];

        if (!category && policyType.typeName === policyTypeToFind) {
            setCategory(policyType.category);
        }

        if (!availableCategories && !categoriesToUse.includes(policyType.category)) {
            categoriesToUse.push(policyType.category);
        }

        return policyType;
    });

    if (!availableCategories && categoriesToUse.length > 0) {
        setAvailableCategories(categoriesToUse);
        if ((!category && !policyTypeToFind) || (category && !categoriesToUse.includes(category))) {
            setCategory(categoriesToUse[0]);
        }
    }

    return <>
        <TabMenu
            active={category}
            changeTab={t => setCategory(t)}
            tabs={availableCategories ? availableCategories.map(c => {
                return {
                    id: c,
                    title: PolicyCategory[c],
                    icons: <PolicyCategoryIcon policyCategory={c} />,
                    isAvailable: true
                };
            }) : []}
        />
        {policies.filter(i => i.category === category)
            .sort(((a, b) => a.order > b.order ? 1 : -1))
            .map(c => <PolicyTypeControl
                key={c.typeName} policyType={c} commodityHelper={worldState.CommodityHelper!} />)}
    </>;
});

export const PolicyTypesView = () => {
    return <TypeDataBaseView title="Policy" icon={IconHelper.Politics.Politics}
        links={[
            { icon: IconHelper.Politics.Politics, to: help_page("politics"), text: "Politics" }
        ]}
    >
        <PolicyTypesContent />
    </TypeDataBaseView>
};