import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BasicSimulatedFleet, IBasicSimulatedFleet, ISimulatedCelestial, ISimulatedInstallation, ISimulatedSolarSystem, SimulatedCelestial, SimulatedInstallation } from '../../../ApplicationState/ApiClient';
import { SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { CloseButton } from '../../../Components/Base/Buttons/CloseButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Checkbox } from '../../../Components/Base/Form/Checkbox';
import { ChooseOwnSolarSystemFromState } from '../../../Components/FusionShift/SolarSystems/ChooseOwnSolarSystem';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    defender: ISimulatedSolarSystem,
    setDefender: (defender: ISimulatedSolarSystem | undefined) => any
}

export const DefenderSystemFromOwnSolarSystem = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    const [isExpanded, setIsExpanded] = React.useState(false);

    const [solarSystemId, setSolarSystemId] = React.useState<number | undefined>(undefined);
    const [doShips, setDoShips] = React.useState(true);
    const [doTactics, setDoTactics] = React.useState(true);
    const [doCelestials, setDoCelestials] = React.useState(true);
    const [doReinforcements, setDoReinforcements] = React.useState(true);
    const [doGroundedShips, setDoGroundedShips] = React.useState(true);

    function setFromOwnSolarSystem() {
        const solarSystem = solarSystemState.SolarSystemsDetail!.find(x => x.solarSystemId === solarSystemId)?.solarSystem;

        if (solarSystem !== undefined) {
            const fleets = FleetHelper.solarSystemFleets(solarSystem);

            const ships = !doShips ? props.defender.ships : solarSystem.availableShips ?? {};
            const tactics = !doTactics ? props.defender.tactics : solarSystem.tactics ?? {};
            const groundedShips = !doGroundedShips ? props.defender.groundedShips : solarSystem.groundedShips ?? {};
            const reinforcements = !doReinforcements ? props.defender.reinforcements : fleets.presentForeign.map((x, i) => {
                const fleet: IBasicSimulatedFleet = {
                    fleetId: i + 1,
                    ships: x.ships ?? {},
                    tactics: {}
                }
                return BasicSimulatedFleet.fromJS(fleet);
            });

            let installationId = 0;

            const celestials = !doCelestials ? props.defender.celestials : solarSystem.celestials.map((x, i) => {

                const installations = (x.installations ?? []).map(y => {

                    const installation: ISimulatedInstallation = {
                        installationId: installationId++,
                        celestialId: i + 1,
                        installationTypeName: y.installationTypeName,
                        level: y.level,
                        damage: 0
                    }

                    return SimulatedInstallation.fromJS(installation);
                })

                const celestial: ISimulatedCelestial = {
                    celestialId: i + 1,
                    name: x.name,
                    size: x.size,
                    celestialTypeName: x.celestialTypeName ?? "unknown",
                    installations,
                    order: 0
                };
                return SimulatedCelestial.fromJS(celestial);
            });

            props.setDefender({
                tactics,
                ships,
                groundedShips,
                reinforcements,
                celestials,
                megacityBonusPercent: props.defender.megacityBonusPercent
            });
        }
        setIsExpanded(false);
    }

    if (!isExpanded || !solarSystemState.SolarSystemsDetail) {
        return <Button type="nav" action={() => setIsExpanded(true)} icon={IconHelper.SolarSystems.Colony} text="Set from one of your systems" />;
    }

    const can = solarSystemId && (doShips || doCelestials || doReinforcements || doGroundedShips);

    return <SubPanel
        heading={{ text: "Choose Own Solar System", icon: IconHelper.SolarSystems.Colony }}
        footerContent={<ButtonHolder isPulledRight>
            <CloseButton action={() => setIsExpanded(false)} />
            <Button type="action" action={() => setFromOwnSolarSystem()} icon={IconHelper.General.Confirm} text="Confirm" confirm="Are you sure you want to configure from your own solar system? Any checked items will be overwritten" isDisabled={!can} />
        </ButtonHolder>}
    >
        <ChooseOwnSolarSystemFromState solarSystemFoundCallback={s => setSolarSystemId(s?.solarSystemId)} prompt="Solar system to configure from:" />
        <br />
        <br />
        <ul>
            <li><Checkbox label="Ships" value={doShips} valueChanged={setDoShips} /> </li>
            <li><Checkbox label="Tactics" value={doTactics} valueChanged={setDoTactics} /> </li>
            <li><Checkbox label="Celestials" value={doCelestials} valueChanged={setDoCelestials} /> </li>
            <li><Checkbox label="Reinforcements" value={doReinforcements} valueChanged={setDoReinforcements} /> </li>
            <li><Checkbox label="Grounded Ships" value={doGroundedShips} valueChanged={setDoGroundedShips} /> </li>
        </ul>
    </SubPanel>
});