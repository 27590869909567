import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import useMeasure from 'react-use-measure';
import { ISolarSystemWithCelestials } from '../../../ApplicationState/ApiClient';
import { DevStateContext } from '../../../ApplicationState/ContextRoot';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { TutorialIds } from '../../../Navigation/Tutorial/TutorialIds';
import { BulmaSizes } from '../../Base/BulmaTypes';
import { CelestialRender } from '../Celestials/CelestialRender';

type Props = {
    solarSystem: ISolarSystemWithCelestials,
    selectedCelestialId?: number | undefined,
    style?: React.CSSProperties,
    allowClick?: boolean
}

const maxCelestials = 15;
const largestScreenSize = BulmaSizes.widescreen;
const largestCelestialSize = 65;
const minimumMarginPerCelestial = 10;
const maximumSpacePerCelestial = largestScreenSize / maxCelestials;
const minimumSpacePerCelestial = largestCelestialSize + minimumMarginPerCelestial;
const sunSize = 150;
const sunOffset = -50;
const celestialFromSunOffset = 250;

export const SolarSystemRender = observer((props: Props) => {

    const devState = React.useContext(DevStateContext);

    const [ref, bounds] = useMeasure();

    const [dimensions, setDimensions] = React.useState({
        width: 0,
        height: 0,
    });

    React.useEffect(() => {
        setDimensions({
            width: bounds.width,
            height: bounds.height
        });
    }, [bounds]);


    if (devState.data.hideCelestialRender) {
        return null;
    }

    const fromRight = props.solarSystem.celestials.length < 4 ? 1 : props.solarSystem.celestials.length * 0.3;

    const width = dimensions.width;

    const sizeRatio = width > largestScreenSize ? 1 : width / largestScreenSize;
    const sunPosition = -((1 - sizeRatio) * sunSize) + sunOffset;
    const availableWidthExcludingSun = (width - (sunPosition + celestialFromSunOffset));

    const availableSpacePerCelestial = availableWidthExcludingSun / props.solarSystem.celestials.length;

    const spaceToUse = Math.max(availableSpacePerCelestial, minimumSpacePerCelestial);

    function calculatePosition(index: number) {

        const offset = sunPosition + celestialFromSunOffset;
        const thisPosition = (index * spaceToUse);
        const actualPosition = offset + thisPosition;

        return actualPosition;
    }

    const lastCelestial = props.solarSystem.celestials.length - 1;
    const lastCelestialPosition = calculatePosition(lastCelestial)
    const wrapperWidth = lastCelestialPosition + maximumSpacePerCelestial;
    const showScrollBars = lastCelestialPosition + (spaceToUse / 2) > width;

    const solarSystemClassName = CelestialHelper.solarSystemStarClassNames(props.solarSystem);

    return <div ref={ref} className="solar-system-render" style={props.style} id={TutorialIds.SolarSystemRender}>
        <ScrollContainer className="scroll-container" vertical={false} hideScrollbars={!showScrollBars}>
            <div className="wrapper" style={{ width: wrapperWidth > width ? wrapperWidth : width }}>
                <div className={`universe ${solarSystemClassName}`}>
                    <div className="galaxy">
                        <div className="solar-system">
                            <div className="sun" style={{ zIndex: 1, left: sunPosition }}>
                            </div>
                            {props.solarSystem.celestials.map((c, i) => <CelestialRender
                                style={{ left: calculatePosition(i) }}
                                key={c.celestialId}
                                solarSystem={props.solarSystem}
                                celestial={c}
                                order={i + 1}
                                isRight={i >= props.solarSystem.celestials.length - fromRight}
                                isSelected={c.celestialId === props.selectedCelestialId}
                                allowClick={props.allowClick}
                            />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ScrollContainer>
    </div>;
});