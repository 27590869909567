import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, InGameProduct, InGameProductCategory } from '../../../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext, PlayerStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../Components/Base/Buttons/ButtonHolder';
import { FlexDiv } from '../../../../Components/Base/Containers/FlexDiv';
import { SplitLayout } from '../../../../Components/Base/Containers/SplitLayout';
import { Checkbox } from '../../../../Components/Base/Form/Checkbox';
import { FieldHolder } from '../../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../../Components/Base/Form/Input';
import { Slider } from '../../../../Components/Base/Form/Slider';
import { Icon } from '../../../../Components/Base/Icon';
import { LoadingSpinner } from '../../../../Components/Base/Loading/LoadingSpinner';
import { ErrorMessageBox } from '../../../../Components/Base/MessageBox';
import { Modal } from '../../../../Components/Base/Portal/Modal';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { CollectionHelper } from '../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { SendShipsHelper } from '../../../../Helpers/SendShipsHelper';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { SendShipsBag } from '../../SendShipsBag';

type Props = {
    sendShipsBag: SendShipsBag,
}

export const RepeatControl = observer((props: Props) => {

    const usedRepeats = (props.sendShipsBag.init.sourceSolarSystem.repeatFleets && props.sendShipsBag.init.sourceSolarSystem.repeatFleets.length) ?? 0;

    const [duration, setDuration] = React.useState<number | undefined>(undefined);

    const [repeatFleetProduct, setRepeatFleetProduct] = React.useState<InGameProduct | undefined>(undefined);
    const [purchasingState, setPurchasingState] = React.useState<"requesting" | "failed">();
    const [showPurchaseRepeat, setShowPurchaseRepeat] = React.useState<boolean>(false);
    const [allowedRepeats, setAllowedRepeats] = React.useState<number | undefined>(undefined);
    const [hasRepeats, setHasRepeats] = React.useState<boolean>(false);

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    React.useEffect(() => {
        (async () => {
            if (appState.IsPremiumEnabled) {
                const playerSettingsInGameProducts = await apiState.PremiumClient.getInGameProductsByCategory(InGameProductCategory.PlayerSettings);

                setRepeatFleetProduct(playerSettingsInGameProducts.filter(x => x.name === playerState.PlayerSettingsPremiumInGameProductNames?.repeatFleet)[0])
            }
        })();
    }, []);

    React.useEffect(() => {
        if (playerState.PlayerSettings) {
            const maxRepeats = playerState.PlayerSettings.properties.numberOfRepeatFleetsPerSolarSystem ?? 0;

            setAllowedRepeats(maxRepeats);
            setHasRepeats(usedRepeats !== undefined ? usedRepeats < maxRepeats : false);

            if (showPurchaseRepeat) {
                setShowPurchaseRepeat(false);
                setPurchasingState(undefined);
                appState.scrollToBottom();
            }
        }
    }, [playerState.PlayerSettings]);

    React.useEffect(() => {
        const steps = SendShipsHelper.durationSteps(props.sendShipsBag);

        if (steps !== undefined) {
            const duration = CollectionHelper.sumOfArrayValue(steps, s => s !== false && s !== undefined ? s.durationHours ?? 0 : 0);
            setDuration(duration);

            if (props.sendShipsBag.RepeatConfiguration.delayHours === undefined || props.sendShipsBag.RepeatConfiguration.delayHours <= 0) {
                let repeatDelay = duration;

                while (repeatDelay > 0.25) {
                    repeatDelay -= 0.25;
                }

                props.sendShipsBag.RepeatConfiguration.delayHours = duration - repeatDelay;
                if (props.sendShipsBag.RepeatConfiguration.delayHours < duration) {
                    props.sendShipsBag.RepeatConfiguration.delayHours = props.sendShipsBag.RepeatConfiguration.delayHours + 0.25;
                }
            }
        }
    }, [props.sendShipsBag]);

    const buyRepeat = async () => {
        if (appState.PremiumBalanceState.Balance >= repeatFleetProduct!.unitCost) {
            setPurchasingState("requesting");

            try {
                await apiState.PremiumClient.buyInGameProduct(repeatFleetProduct!.inGameProductId);
            }
            catch {
                setPurchasingState("failed");
                return;
            }
        }
    };

    function createWarning() {

        if (props.sendShipsBag.MovementType === FleetMovementType.Rebase) {
            return undefined;
        }

        if (duration === undefined) {
            return undefined;
        }
        const showWarning = props.sendShipsBag.RepeatConfiguration.delayHours === undefined || duration > props.sendShipsBag.RepeatConfiguration.delayHours;

        if (!showWarning) {
            return undefined;
        }

        if (props.sendShipsBag.MovementType === FleetMovementType.Mine) {
            return "The chosen delay is shorter than the time it takes to reach the target, mine until full cargo and then return home"
        }

        return "The chosen delay is shorter than the time it takes to reach the target and then return home";
    }

    const warning = createWarning();

    if (props.sendShipsBag.RepeatConfiguration.delayHours === undefined) {
        return null;
    }

    return <>
        <hr />
        {!props.sendShipsBag.IsEditingRepeatFleet &&
            <ButtonHolder>
                <Button type="nav" icon={IconHelper.Ships.RepeatOff} text="Once Only" action={() => props.sendShipsBag.setRepeat(false, props.sendShipsBag.RepeatConfiguration.delayHours!, props.sendShipsBag.RepeatConfiguration.numberOfTimes)} isDisabled={!props.sendShipsBag.repeat} isSelected={!props.sendShipsBag.repeat} />
                <Button type="nav" icon={IconHelper.Ships.Repeat} text="Repeat" action={() => props.sendShipsBag.setRepeat(true, props.sendShipsBag.RepeatConfiguration.delayHours!, props.sendShipsBag.RepeatConfiguration.numberOfTimes)} isDisabled={props.sendShipsBag.repeat} isSelected={props.sendShipsBag.repeat} />
            </ButtonHolder>
        }
        {(props.sendShipsBag.repeat || props.sendShipsBag.IsEditingRepeatFleet) &&
            <>
                {!props.sendShipsBag.IsEditingRepeatFleet && <>
                    <Paragraph type="prompt" className={!hasRepeats ? "has-text-danger" : ""}>
                        Used {usedRepeats} out of {allowedRepeats} repeats in this solar system
                    </Paragraph>
                    {repeatFleetProduct && !hasRepeats && <>
                        <span>Buy extra repeat for <Icon icon={IconHelper.Premium.Currency} />{repeatFleetProduct.unitCost}</span>&nbsp;&nbsp;
                        <Button
                            type="nav"
                            text="Buy"
                            icon={IconHelper.General.Confirm}
                            action={() => {
                                appState.scrollToTop();
                                setShowPurchaseRepeat(true);
                            }} />
                    </>}
                </>}
                {(hasRepeats || props.sendShipsBag.IsEditingRepeatFleet) && <>
                    <SplitLayout
                        left={
                            <FieldHolder label={`Every ${ValueFormatter.decimalHoursToText(props.sendShipsBag.RepeatConfiguration.delayHours)}`}>
                                <div className="control">
                                    <Slider
                                        hideValue
                                        step={0.25}
                                        value={props.sendShipsBag.RepeatConfiguration.delayHours}
                                        min={props.sendShipsBag.init.gameSettings.fleets.repeatDelayHoursMinimum}
                                        max={24}
                                        valueChanged={v => props.sendShipsBag.setRepeat(true, v, props.sendShipsBag.RepeatConfiguration.numberOfTimes)}
                                    />
                                </div>
                            </FieldHolder>
                        }
                        right={
                            <FlexDiv>
                                <FieldHolder label={"Number of times (blank for indefinite)"}>
                                    <div className="control">
                                        <NumberInput
                                            size={5}
                                            value={props.sendShipsBag.RepeatConfiguration.numberOfTimes ? props.sendShipsBag.RepeatConfiguration.numberOfTimes : undefined}
                                            valueChanged={value => props.sendShipsBag.setRepeat(true, props.sendShipsBag.RepeatConfiguration.delayHours!, value)}
                                            valueCleared={() => props.sendShipsBag.setRepeat(true, props.sendShipsBag.RepeatConfiguration.delayHours!, undefined)}
                                            highlightOnClick
                                            required={false}
                                        />
                                    </div>
                                </FieldHolder>
                                <Checkbox label="Only send if all ships are available" value={props.sendShipsBag.RepeatConfiguration.onlySendIfAllShipsAvailable} valueChanged={v => props.sendShipsBag.setRepeatConfiguration(x => {
                                    return {
                                        ...x,
                                        onlySendIfAllShipsAvailable: v
                                    }
                                })} />
                            </FlexDiv>
                        } />
                    {props.sendShipsBag.RepeatConfiguration.numberOfTimes && <Paragraph>
                        Last repeat sent in {ValueFormatter.decimalHoursToText((1 + props.sendShipsBag.RepeatConfiguration.numberOfTimes) * props.sendShipsBag.RepeatConfiguration.delayHours)}.
                    </Paragraph>}
                    {!!warning && <Paragraph type="prompt" className="has-text-warning">{warning}</Paragraph>}
                </>}
                {showPurchaseRepeat && <Modal
                    title="Buy Repeat"
                    bodyTextAlign="left"
                    bodyContent={() => <>
                        {(purchasingState === "requesting" || !repeatFleetProduct) && <LoadingSpinner />}
                        {purchasingState === "failed" && <ErrorMessageBox text="Failed to purchase premium emblem" />}
                        {!purchasingState && repeatFleetProduct && <>
                            <Paragraph>
                                Cost: <Icon icon={IconHelper.Premium.Currency} /> {repeatFleetProduct.unitCost}<br />
                                Current balance: <Icon icon={IconHelper.Premium.Currency} /> {appState.PremiumBalanceState.Balance}
                            </Paragraph>
                        </>}
                    </>}
                    footerContent={() => <>
                        <ButtonHolder isPulledRight>
                            <Button
                                type="action"
                                isPulledRight
                                text="Buy"
                                icon={IconHelper.General.Confirm}
                                action={() => buyRepeat()}
                                isDisabled={showPurchaseRepeat && !purchasingState && appState.PremiumBalanceState.Balance < (repeatFleetProduct?.unitCost ?? 0)} />
                            <Button
                                type="danger"
                                isPulledRight
                                text="Cancel"
                                icon={IconHelper.General.Cancel}
                                action={() => setShowPurchaseRepeat(false)}
                                isDisabled={showPurchaseRepeat && purchasingState === "requesting"} />
                        </ButtonHolder>
                    </>}
                />}
            </>
        }
    </>;
});