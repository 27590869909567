import { IItemTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemBuilder, ProblemCategory, ProblemsMaybe } from "../Problem";
import { agentProblems } from "./AgentProblems";
import { buildQueueProblems } from "./BuildQueueProblems";
import { operationLevelProblems } from "./OperationLevelProblems";
import { otherItemsProblems } from "./OtherItemsProblems";
import { resourceProblems } from "./ResourceProblems";
import { runningSchematicProblems } from "./RunningSchematicProblems";

export const economyProblemBuilder = new ProblemBuilder(ProblemCategory.Economy);

export function economyProblems(itemTypeSettings: IItemTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    return economyProblemBuilder.merge(
        buildQueueProblems(wrapper),
        resourceProblems(itemTypeSettings, wrapper),
        otherItemsProblems(itemTypeSettings, wrapper),
        runningSchematicProblems(wrapper),
        operationLevelProblems(wrapper),
        agentProblems(itemTypeSettings, wrapper)
    );
}