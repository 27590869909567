import * as React from 'react';
import { Link } from 'react-router-dom';
import { CelestialSize, Cost, IFactionTypeSettings, IGameSettings, IInstallationType, IItemTypeSettings, ISchematicTypeSettings, ISectorTypeSettings, IShipTypeSettings, IWorldEffects, ShipClass, ShipType, SolarSystemClaimType } from '../../../ApplicationState/ApiClient';
import { BuildHelper } from '../../../Helpers/BuildHelper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { InstallationHelper } from '../../../Helpers/InstallationHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { help_shipType } from '../../../Navigation/Routing/Help';
import { Icon } from '../../Base/Icon';
import { TextAndIcon } from '../../Base/Text/TextAndIcon';
import { CommoditiesIcon } from '../Icons/Items/CommoditiesIcon';
import { ComponentIcon } from '../Icons/Items/ComponentIcon';
import { ItemIconWithQuantity } from '../Icons/Items/ItemIconWithQuantity';
import { RawResourceIcon } from '../Icons/Items/RawResourceIcon';
import { UpkeepIcon } from '../Icons/Items/UpkeepIcon';
import { ShipClassIcon } from '../Icons/ShipClassIcon';
import { CostControl } from '../Items/CostControl';
import { CostModifiedLink } from '../Links/CostModified';
import { SchematicTypeLink } from '../Links/SchematicTypeLink';
import { SectorTypeLink } from '../Links/SectorTypeLink';

export type ShipBuildDisplay = "all" | "levelAndBelow" | "levelOnly" | "levelAndLater";

export type InstallationStat = {
    label: string,
    dependsOnOperationLevel?: boolean,
    content: React.ReactNode,
    isUnlabelled?: boolean
}

export type InstallationStatsDisplay = {
    showLimits: boolean,
    showAllows: boolean,
    shipBuildDisplay: ShipBuildDisplay,
    onlyOperationLevel?: boolean,
    sectorBonusesForMaxLevel: boolean
}

export class InstallationStatsBuilder {

    private static lazyContent(content: React.ReactNode, dependsOnOperationLevel: boolean): InstallationStat {
        return {
            label: "",
            dependsOnOperationLevel,
            content,
            isUnlabelled: true
        }
    }

    private static manufactoryStats(itemTypeSettings: IItemTypeSettings, gameSettings: IGameSettings, installationType: IInstallationType, level: number, operationLevel: number) {
        const content: InstallationStat[] = [];

        const inputNodes = installationType.factoryBaseInputNodes + (installationType.factoryInputNodesPerLevel * level);
        if (inputNodes > 0) {
            content.push(this.lazyContent(`Provides ${inputNodes} manufactory input ${ValueFormatter.pluralize("node", inputNodes)}`, false));
        }
        const doesInputThroughput = installationType.factoryBaseInputThroughput + installationType.factoryInputThroughputPerLevel > 0;
        const inputThroughput = (operationLevel <= 0 ? 0 : installationType.factoryBaseInputThroughput) + (installationType.factoryInputThroughputPerLevel * operationLevel);
        if (doesInputThroughput) {
            content.push(this.lazyContent(`Provides ${inputThroughput.toLocaleString()} manufactory input throughput per node`, true));
        }
        const outputNodes = installationType.factoryBaseOutputNodes + (installationType.factoryOutputNodesPerLevel * level);
        if (outputNodes > 0) {
            content.push(this.lazyContent(`Provides ${outputNodes} manufactory output ${ValueFormatter.pluralize("node", outputNodes)}`, false));
        }
        const doesOutputThroughput = installationType.factoryBaseOutputThroughput + installationType.factoryOutputThroughputPerLevel > 0;
        const outputThroughput = (operationLevel <= 0 ? 0 : installationType.factoryBaseOutputThroughput) + (installationType.factoryOutputThroughputPerLevel * operationLevel);
        if (doesOutputThroughput) {
            content.push(this.lazyContent(`Provides ${outputThroughput.toLocaleString()} manufactory output throughput per node`, true));
        }
        return content;
    }

    private static resourceCapacitiesAdded(itemTypeSettings: IItemTypeSettings, installationType: IInstallationType, level: number, operationLevel: number) {
        if (!installationType.resourceCapacitiesAdded) {
            return null;
        }

        const resources = Object.keys(installationType.resourceCapacitiesAdded).map(k => {
            return {
                itemType: itemTypeSettings.data![k],
                quantity: installationType.resourceCapacitiesAdded![k]
            }
        });

        if (resources.length === 0) {
            return null;
        }

        return {
            label: "Storage",
            content: <>{
                resources.map((r, i) =>
                    <ItemIconWithQuantity key={i} itemType={r.itemType} quantity={InstallationHelper.storageAtLevel(level, r.quantity)} />
                )
            }</>
        }
    }

    private static describeShip(shipType: ShipType, shipTypeOnly: boolean) {
        let text = shipTypeOnly ? ShipClass[shipType.class] : shipType.name;

        text += ` (at level ${shipType.builtAtMinimumLevel}`

        if (shipType.limitPerInstallationLevel > 0) {
            text += `, limit ${shipType.limitPerInstallationLevel} per level`
        }

        text += ")";

        return text;
    }

    private static buildShips(shipTypeSettings: IShipTypeSettings, factionTypeSettings: IFactionTypeSettings, installationType: IInstallationType, factionTypeName: string | undefined, level: number, shipBuildDisplay: ShipBuildDisplay) {

        const factionTypeNameToUse = factionTypeName || CollectionHelper.firstFromDictionary(factionTypeSettings.data)!.typeName;

        const ships = shipTypeSettings && Object.keys(shipTypeSettings.data)
            .map(m => {
                return {
                    ship: shipTypeSettings.data[m],
                    description: this.describeShip(shipTypeSettings.data[m], factionTypeName === undefined),
                    shipClass: shipTypeSettings.data[m].class
                }
            })
            .filter(m => m.ship.builtAtInstallationTypeName === installationType.typeName &&
                m.ship.factionTypeName === factionTypeNameToUse)
            .filter(m => shipBuildDisplay === "all" ||
                (shipBuildDisplay === "levelAndBelow" && m.ship.builtAtMinimumLevel <= level) ||
                (shipBuildDisplay === "levelOnly" && m.ship.builtAtMinimumLevel === level) ||
                (shipBuildDisplay === "levelAndLater" && m.ship.builtAtMinimumLevel >= level)
            )
            .sort(((a, b) =>
                a.ship.builtAtMinimumLevel === b.ship.builtAtMinimumLevel ?
                    (a.ship.order > b.ship.order ? 1 : -1) :
                    a.ship.builtAtMinimumLevel > b.ship.builtAtMinimumLevel ? 1 : -1));

        if (!CollectionHelper.isAnyInArray(ships)) {
            return undefined;
        }

        const laterShips = shipBuildDisplay === "levelAndLater" ? ships.filter(x => x.ship.builtAtMinimumLevel > level) : [];
        const shipsToRender = shipBuildDisplay === "levelAndLater" ? ships.filter(x => x.ship.builtAtMinimumLevel === level) : ships;

        const allows = shipsToRender.length > 0 ? {
            label: "Allows ships",
            content: <ul>
                {shipsToRender.map((s, i) => <li key={i}>
                    <Link to={help_shipType(s.ship.typeName)}>
                        <ShipClassIcon shipClass={s.shipClass} />
                        {s.description}
                    </Link>
                </li>)}
            </ul>
        } : undefined;

        const later = laterShips.length > 0 ? {
            label: "Allows ships at later levels",
            content: <ul>
                {laterShips.map((s, i) => <li key={i}>
                    <Link to={help_shipType(s.ship.typeName)}>
                        <ShipClassIcon shipClass={s.shipClass} />
                        {s.description}
                    </Link>
                </li>)}
            </ul>
        } : undefined;

        return { allows, later };
    }

    public static buildInstallationStats(
        worldEffects: IWorldEffects,
        itemTypeSettings: IItemTypeSettings,
        shipTypeSettings: IShipTypeSettings,
        factionTypeSettings: IFactionTypeSettings,
        schematicTypeSettings: ISchematicTypeSettings,
        sectorTypeSettings: ISectorTypeSettings,
        gameSettings: IGameSettings,
        celestialSize: CelestialSize | undefined,
        installationType: IInstallationType,
        factionTypeName: string | undefined,
        level: number,
        operationLevel: number,
        display: InstallationStatsDisplay,
        cost: Cost | undefined,
        availableItems?: { [key: string]: number } | undefined,) {
        const stats: (InstallationStat | null)[] = [];

        if (installationType.levelToFireApocalypseWeapon !== null && installationType.levelToFireApocalypseWeapon !== undefined && installationType.levelToFireApocalypseWeapon > 0) {
            stats.push(this.lazyContent(<><Icon icon={IconHelper.Celestials.Apocalypse} />  Can fire Apocalypse Weapon at level {installationType.levelToFireApocalypseWeapon}</>, false));
        }

        stats.push({
            label: "Total health",
            content: ValueFormatter.formatLocaleNumber(InstallationHelper.totalInstallationHealthAtLevel(level, installationType.healthPerLevel))
        });

        if (installationType.hasOperationLevel) {
            stats.push(this.lazyContent(<><Icon icon={IconHelper.Installations.OperationLevel} />  Can set operational level</>, false));
        }
        if (installationType.reactionProcessingPerLevel > 0) {
            stats.push(this.lazyContent(<>
                Reaction Processing: {installationType.reactionProcessingPerLevel * operationLevel}x
            </>, true));
        }


        if (installationType.resourceProduced) {
            const resource = itemTypeSettings.data![installationType.resourceProduced];

            stats.push({
                label: "Produces",
                content: <>
                    <ItemIconWithQuantity itemType={resource} quantity={InstallationHelper.resourceProductionPerHour(level, gameSettings)} showFullName /> per hour
                </>
            });
        }

        stats.push(this.resourceCapacitiesAdded(itemTypeSettings, installationType, level, operationLevel));

        if (installationType.otherCapacitiesAdded !== undefined) {

            if (installationType.otherCapacitiesAdded.Commodity) {
                stats.push({
                    label: "Storage",
                    content: <CommoditiesIcon quantity={installationType.otherCapacitiesAdded.Commodity * level} />
                });
            }
            if (installationType.otherCapacitiesAdded.RawResource) {
                stats.push({
                    label: "Storage",
                    content: <RawResourceIcon quantity={installationType.otherCapacitiesAdded.RawResource * level} />
                });
            }
            if (installationType.otherCapacitiesAdded.Component) {
                stats.push({
                    label: "Storage",
                    content: <ComponentIcon quantity={installationType.otherCapacitiesAdded.Component * level} />
                });
            }
        }
        if (installationType.hangarSpacePerLevel > 0) {

            const levelForHangarSpace = installationType.isHangarSpaceExponential && level > 0 ? (level + level - 1) : level;

            stats.push({
                label: "Hangar space",
                content: <><UpkeepIcon quantity={installationType.hangarSpacePerLevel * levelForHangarSpace} /></>
            });
        }
        if (installationType.defencePerLevel > 0) {
            stats.push(this.lazyContent(`Adds ${ValueFormatter.formatLocaleNumber(installationType.defencePerLevel * level)} defence`, false));
        }
        if (installationType.megacityPopulationCapacityPerLevel > 0) {
            stats.push(this.lazyContent(`Provides ${ValueFormatter.formatPopulation(installationType.megacityPopulationCapacityPerLevel * level)} Megacity population capacity`, false));
        }
        if (installationType.megacityPopulationAssignmentPerLevel > 0 && installationType.megacitySectorTypeName.length > 0) {
            stats.push(this.lazyContent(<>Can assign {ValueFormatter.formatPopulation(installationType.megacityPopulationAssignmentPerLevel * level)} population to <SectorTypeLink sectorType={sectorTypeSettings.data[installationType.megacitySectorTypeName]} /></>, false));
        }

        stats.push(...this.manufactoryStats(itemTypeSettings, gameSettings, installationType, level, operationLevel));

        if (display.showAllows) {
            if (installationType.solarSystemClaimType === SolarSystemClaimType.Capital) {
                stats.push(this.lazyContent("Allows claiming of systems", false));
            }
            if (installationType.allowsFederations) {
                stats.push(this.lazyContent("Allows federations", false));
            }
        }

        if (installationType.marketOrdersPerLevel > 0) {
            const quantity = installationType.marketOrdersPerLevel * level;
            const pluralize = ValueFormatter.pluralize("order", quantity);
            stats.push(this.lazyContent(`Provides ${quantity} market ${pluralize}`, false));
        }

        if (installationType.marketCapacityPerLevel > 0) {
            stats.push(this.lazyContent(`Provides ${ValueFormatter.formatLocaleNumber(installationType.marketCapacityPerLevel * level)} market capacity`, false));
        }

        if (installationType.agentCanBeTrainedAtLevel && installationType.agentCanBeTrainedAtLevel > 0) {
            stats.push(this.lazyContent(`Allows an agent at level ${installationType.agentCanBeTrainedAtLevel}`, false));
        }

        const ships = this.buildShips(shipTypeSettings, factionTypeSettings, installationType, factionTypeName, level, display.shipBuildDisplay);

        if (ships !== undefined) {
            if (ships.allows !== undefined) {
                stats.push(ships.allows);
            }
            if (ships.later !== undefined) {
                stats.push(ships.later);
            }
        }

        if ((installationType.dronesPerLevel ?? 0) > 0) {
            stats.push(this.lazyContent(<TextAndIcon icon={IconHelper.Ships.Drone} text={`Allows ${installationType.dronesPerLevel * level} stored drones`} />, false))
        }
        if ((installationType.dronesActivePerLevel ?? 0) > 0) {
            stats.push(this.lazyContent(<TextAndIcon icon={IconHelper.Ships.Drone} text={`Allows ${installationType.dronesActivePerLevel * level} active drones`} />, false))
        }

        if (installationType.canProduceFromSchematicTypeNames.length > 0) {
            stats.push({
                label: "Can Run Schematics",
                content: <>
                    {installationType.canProduceFromSchematicTypeNames.map(c => c in schematicTypeSettings.data ? schematicTypeSettings.data[c] : undefined)
                        .filter(c => c !== undefined)
                        .map(c => c!)
                        .map((c, i) => <React.Fragment key={c.typeName}>
                            {i > 0 && ", "}
                            <SchematicTypeLink schematicType={c} />
                        </React.Fragment>)
                    }
                </>
            })
        }

        if (cost) {
            stats.push({
                label: "Costs",
                content: <CostControl cost={cost} availableItems={availableItems} />
            });

            if (worldEffects.installationCostModification !== 0) {
                stats.push(this.lazyContent(<CostModifiedLink modifier={worldEffects.installationCostModification} />, false))
            }
        }

        if (display.showLimits) {
            stats.push({
                label: "Max level",
                content: BuildHelper.effectiveMaxLevel(installationType, celestialSize ?? CelestialSize.Giant)
            });
            if (installationType.maxLevelsPerCelestialSize) {
                stats.push({
                    label: "Max levels per celestial size",
                    content: installationType.maxLevelsPerCelestialSize
                });
            }
            if (installationType.limitPerCelestial) {
                stats.push({
                    label: "Limit per celestial",
                    content: installationType.limitPerCelestial
                });
            }
            if (installationType.limitPerSolarSystem) {
                stats.push({
                    label: "Limit per solar system",
                    content: installationType.limitPerSolarSystem
                });
            }
            if (installationType.limitPerPlayer) {
                stats.push({
                    label: "Limit per player",
                    content: installationType.limitPerPlayer
                });
            }
        }

        return stats
            .filter(s => s !== null && (!display.onlyOperationLevel || s.dependsOnOperationLevel))
            .map(s => s!);
    }
}