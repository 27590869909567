import Showdown from "showdown";
import { ChangeBlob } from "../../Blobs/Changes/ChangeBlob";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { CollectionHelper } from "../../Helpers/CollectionHelper";

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
});

type Props = {
    changeBlob: ChangeBlob
}

export const ChangeRender = (props: Props) => {

    const d = props.changeBlob;

    return <SubPanel className="content" heading={d.date.toDateString()}>
        {CollectionHelper.isAnyInArray(d.introduction) &&
            d.introduction!.map((d2, i2) => <Paragraph key={i2}>{d2}</Paragraph>)}
        {
            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(d.changes.join("\n")) }}></div>
        }
    </SubPanel>
}