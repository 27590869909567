import { makeAutoObservable } from "mobx";
import { CommodityHelper } from "../../Helpers/CommodityHelper";
import { GameSettings, IAgentActionTypeSettings, ICelestialTypeSettings, IFactionTypeSettings, IFederation, IInstallationTypeSettings, IItemTypeSettings, IMissionTypeSettings, IPolicyTypeSettings, ISchematicTypeSettings, ISectorTypeSettings, IShipTypeSettings, IWorldMinimal, WorldEffects, WorldSettings } from "../ApiClient";
import { ApiState } from "./ApiState";

export class WorldState {

    VictoriousFederation: IFederation | undefined;

    World: IWorldMinimal | undefined;
    private _factoryVersion: string | undefined;

    get Effects(): WorldEffects | undefined { return this.World && this.World.effects; }
    get GameSettings(): GameSettings | undefined { return this._settings && this._settings.gameSettings; }

    private _settings: WorldSettings | undefined = undefined;

    constructor(private apiState: ApiState) {
        makeAutoObservable(this);
    }

    public loadWorld(world: IWorldMinimal | undefined, factoryVersion: string) {
        this.World = world;
        this._factoryVersion = factoryVersion;
        if (this.World) {

            return Promise.all([
                // Settings required for solarsystem wrapper of solarsystem state
                this.loadItemTypeSettings(),
                this.loadAgentActionTypeSettings(),
                this.loadInstallationTypeSettings(),
                this.loadShipTypeSettings(),
                this.loadSectorTypeSettings(),
                this.apiState.WorldSettingsClient.getWorldSettings(this._factoryVersion).then(s => {
                    this._settings = s;
                })
            ]);
        }
    }

    public loadVictoriousFederation(federation: IFederation | undefined) {
        this.VictoriousFederation = federation;
    }

    get AgentActionTypeSettings() {
        if (this._AgentActionTypeSettings === undefined) {
            this.loadAgentActionTypeSettings();
        }

        return this._AgentActionTypeSettings;
    }
    private _AgentActionTypeSettings: IAgentActionTypeSettings | undefined = undefined;
    private loadAgentActionTypeSettings() {
        if (this._AgentActionTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getAgentActionTypes(this._factoryVersion).then(x => {
                this._AgentActionTypeSettings = x;
            })
        }
    }

    get ItemTypeSettings() {
        if (this._ItemTypeSettings === undefined) {
            this.loadItemTypeSettings();
        }

        return this._ItemTypeSettings;
    }
    private _ItemTypeSettings: IItemTypeSettings | undefined = undefined;
    private loadItemTypeSettings() {
        if (this._ItemTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getItemTypes(this._factoryVersion).then(x => {
                this._ItemTypeSettings = x;
            })
        }
    }

    get SchematicTypeSettings() {
        if (this._SchematicTypeSettings === undefined) {
            this.loadSchematicTypeSettings();
        }

        return this._SchematicTypeSettings;
    }
    private _SchematicTypeSettings: ISchematicTypeSettings | undefined = undefined;
    private loadSchematicTypeSettings() {
        if (this._SchematicTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getSchematicTypes(this._factoryVersion).then(x => {
                this._SchematicTypeSettings = x;
            })
        }
    }


    get ShipTypeSettings() {
        if (this._ShipTypeSettings === undefined) {
            this.loadShipTypeSettings();
        }

        return this._ShipTypeSettings;
    }
    private _ShipTypeSettings: IShipTypeSettings | undefined = undefined;
    private loadShipTypeSettings() {
        if (this._ShipTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getShipTypes(this._factoryVersion).then(x => {
                this._ShipTypeSettings = x;
            })
        }
    }

    get InstallationTypeSettings() {
        if (this._InstallationTypeSettings === undefined) {
            this.loadInstallationTypeSettings();
        }

        return this._InstallationTypeSettings;
    }
    private _InstallationTypeSettings: IInstallationTypeSettings | undefined = undefined;
    private loadInstallationTypeSettings() {
        if (this._InstallationTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getInstallationTypes(this._factoryVersion).then(x => {
                this._InstallationTypeSettings = x;
            })
        }
    }

    get FactionTypeSettings() {
        if (this._FactionTypeSettings === undefined) {
            this.loadFactionTypeSettings();
        }

        return this._FactionTypeSettings;
    }
    private _FactionTypeSettings: IFactionTypeSettings | undefined = undefined;
    private loadFactionTypeSettings() {
        if (this._FactionTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getFactionTypes(this._factoryVersion).then(x => {
                this._FactionTypeSettings = x;
            })
        }
    }

    get SectorTypeSettings() {
        if (this._SectorTypeSettings === undefined) {
            this.loadSectorTypeSettings();
        }

        return this._SectorTypeSettings;
    }
    private _SectorTypeSettings: ISectorTypeSettings | undefined = undefined;
    private loadSectorTypeSettings() {
        if (this._SectorTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getSectorTypes(this._factoryVersion).then(x => {
                this._SectorTypeSettings = x;
            })
        }
    }

    get MissionTypeSettings() {
        if (this._MissionTypeSettings === undefined) {
            this.loadMissionTypeSettings();
        }

        return this._MissionTypeSettings;
    }
    private _MissionTypeSettings: IMissionTypeSettings | undefined = undefined;
    private loadMissionTypeSettings() {
        if (this._MissionTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getMissionTypes(this._factoryVersion).then(x => {
                this._MissionTypeSettings = x;
            })
        }
    }

    get CelestialTypeSettings() {
        if (this._CelestialTypeSettings === undefined) {
            this.loadCelestialTypeSettings();
        }

        return this._CelestialTypeSettings;
    }
    private _CelestialTypeSettings: ICelestialTypeSettings | undefined = undefined;
    private loadCelestialTypeSettings() {
        if (this._CelestialTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getCelestialTypes(this._factoryVersion).then(x => {
                this._CelestialTypeSettings = x;
            })
        }
    }

    get PolicyTypeSettings() {
        if (this._PolicyTypeSettings === undefined) {
            this.loadPolicyTypeSettings();
        }

        return this._PolicyTypeSettings;
    }
    private _PolicyTypeSettings: IPolicyTypeSettings | undefined = undefined;
    private loadPolicyTypeSettings() {
        if (this._PolicyTypeSettings === undefined && this._factoryVersion !== undefined) {
            return this.apiState.WorldSettingsClient.getPolicyTypes(this._factoryVersion).then(x => {
                this._PolicyTypeSettings = x;
            })
        }
    }

    get CommodityHelper() {
        if (this._commodityHelper === undefined) {
            this.loadCommodityHelper();
        }

        return this._commodityHelper;
    }
    private _commodityHelper: CommodityHelper | undefined = undefined;
    private loadCommodityHelper() {
        const itemTypeSettings = this.ItemTypeSettings;
        const sectorTypeSettings = this.SectorTypeSettings;

        if (itemTypeSettings !== undefined && sectorTypeSettings !== undefined) {
            this._commodityHelper = new CommodityHelper(itemTypeSettings, sectorTypeSettings);
        }
    }
}