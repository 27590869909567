import React from "react";
import { AgentStatus } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { BaseProps, Icon } from "../../Base/Icon";

type Props = BaseProps & {
    status: AgentStatus | undefined | null
}

export const AgentStatusIcon = (props: Props) => {

    if (props.status === undefined || props.status === null) {
        return null;
    }

    return <Icon icon={IconHelper.Agents.status(props.status)} {...props} title={AgentStatus[props.status]} />;
};