import classNames from "classnames";
import { Link } from "react-router-dom";
import { FederationStatus, IFederation, IPlayerFederation, IPlayerFederationDetail } from "../../../ApplicationState/ApiClient";
import { federation_view } from "../../../Navigation/Routing/Federation";

type Props = {
    federation: IFederation | IPlayerFederationDetail | IPlayerFederation | undefined,
    shortNameOnMobile?: boolean,
    shortNameOnly?: boolean
}

export const FederationLink = (props: Props) => {

    if (!props.federation || props.federation.federationId === undefined) {
        return null;
    }

    const className = classNames({
        "is-defeated": props.federation.status === FederationStatus.Defeated,
        "relation victor": props.federation.status === FederationStatus.Victorious
    });

    const doFullName = !props.shortNameOnly && props.federation.hasOwnProperty("name");
    const fullName = doFullName ? `[${props.federation.shortName}] ${(props.federation as any).name}` : props.federation.shortName;

    return <Link className={className} to={federation_view(props.federation.federationId)}>
        <span className={props.shortNameOnMobile ? "is-hidden-mobile" : ""}>
            {fullName}
        </span>
        {props.shortNameOnMobile && <span className="is-hidden-tablet">
            {props.federation.shortName}
        </span>}

    </Link>;
};