import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class ManufactoriesIcons {


    public get Manufactory() { return MaterialDesignIcons.factory; }
    public get Schematic() { return MaterialDesignIcons.factory; }
    public get Input() { return MaterialDesignIcons.import; }
    public get Output() { return MaterialDesignIcons.export; }
    public get Efficiency() { return MaterialDesignIcons.speedometer; }
}