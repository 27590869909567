import React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { QuantityIcon, QuantityIconProps } from "../QuantityIcon";

type Props = QuantityIconProps & {
    rawResourceType?: ItemType | undefined | false,
    isGiant?: boolean,
    label?: string | undefined
}

export const RawResourceIcon = (props: Props) => {

    const name = props.rawResourceType ? props.rawResourceType.name : "Raw Resources";
    const label = props.label ? props.label : name;

    return <QuantityIcon name={label} icon={IconHelper.Items.RawResource} className="rawresources" {...props} />;
};

