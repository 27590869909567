import { makeAutoObservable } from "mobx";
import { AgentHelper } from "../../Helpers/AgentHelper";
import { AgentDetail, AgentSummary, IAgentDetail } from "../ApiClient";
import { UnreadState } from "./UnreadState";

export class AgentState {

    private _agentsSummary: AgentSummary | undefined;

    private _agents: IAgentDetail[] | undefined;

    get Agents(): IAgentDetail[] | undefined { return this._agents; }

    get AgentSummary(): AgentSummary | undefined { return this._agentsSummary; }

    constructor(private _unreadState: UnreadState) {
        makeAutoObservable(this);
    }

    public loadAgents(agents: AgentSummary | undefined) {
        this._agentsSummary = agents;
        this._agents = agents?.agents;

        this.checkNotifications();
    }

    loadAgent(agent: AgentDetail) {
        const newAgents = (this._agentsSummary?.agents ?? []).map(x => x.agentId === agent.agentId ? agent : x);

        if (this._agentsSummary !== undefined) {
            this._agentsSummary.agents = newAgents;
        }
        this._agents = newAgents;

        this.checkNotifications();
    }

    private checkNotifications() {
        if (this._agents) {
            for (const agent of this._agents) {
                const canAct = AgentHelper.readyForAction(agent);

                if (canAct) {
                    this._unreadState.addUnread("agentAction");
                    return;
                }
            }

            this._unreadState.clearUnread("agentAction");
        }
    }
}