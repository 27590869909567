import React from 'react';
import { ContractType } from '../../../ApplicationState/ApiClient';
import { FlexDiv } from '../../../Components/Base/Containers/FlexDiv';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Checkbox } from '../../../Components/Base/Form/Checkbox';
import { Select } from '../../../Components/Base/Form/Select';
import { ContractHelper } from '../../../Helpers/ContractHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

export type Filters = {
    type: ContractType,
    federationOnly: boolean,
    allowExiles: boolean,
    includeAccepted: boolean
}

type Props = {
    filters: Filters,
    setFilters: (filters: Filters) => any
}

export const FilterControl = (props: Props) => {

    return <SubPanel heading="Filters">
        <FlexDiv>
            <Select
                value={props.filters.type}
                valueChanged={v => props.setFilters({
                    ...props.filters,
                    type: Number(v)
                })}
                values={ContractHelper.browsableContractTypes().map(x => {
                    return {
                        label: ValueFormatter.friendlyNameForContractType(x),
                        value: x
                    }
                })}
            />
            <Checkbox
                label="Federation only"
                value={props.filters.federationOnly}
                valueChanged={v => props.setFilters({
                    ...props.filters,
                    federationOnly: v
                })}
            />
            <Checkbox
                label="Include accepted"
                value={props.filters.includeAccepted}
                valueChanged={v => props.setFilters({
                    ...props.filters,
                    includeAccepted: v
                })}
            />
            <Checkbox
                label="Exiles permitted"
                value={props.filters.allowExiles}
                valueChanged={v => props.setFilters({
                    ...props.filters,
                    allowExiles: v
                })}
            />
        </FlexDiv>
    </SubPanel>;
}