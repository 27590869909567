import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, IGameSettings, ISolarSystem, ISolarSystemDetail, ShipClass, ShipType } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Table } from '../../../Components/Base/Containers/Table';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { Select } from '../../../Components/Base/Form/Select';
import { SendShipsBetweenSystemButtons } from '../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { HoursIcon } from '../../../Components/FusionShift/Icons/HoursIcon';
import { ShipTypeImageWithLabel } from '../../../Components/FusionShift/Images/ShipTypeImage';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { TextHelper } from '../../../Helpers/TextHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type RowProps = {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    gameSettings: IGameSettings,
    solarSystem: ISolarSystemDetail,
    solarSystems: ISolarSystemDetail[],
    solarSystemPerHour: number
}

type CellProps = {
    gameSettings: IGameSettings,
    solarSystem: ISolarSystemDetail,
    to: ISolarSystem,
    solarSystemPerHour: number,
    isSelected: boolean
}


const movementTypes = [
    FleetMovementType.Reinforce,
    FleetMovementType.Rebase
];

const Cell = (props: CellProps) => {

    if (props.to.solarSystemId === props.solarSystem.solarSystemId) {
        return <td />;
    }

    const hours = FleetHelper.estimateHoursJourneyDuration(props.solarSystemPerHour, props.solarSystem, props.to, props.gameSettings);

    return <td className={props.isSelected ? "has-text-success" : ""}>
        {hours !== undefined && <HoursIcon quantity={hours} alwaysCompact />}
    </td>;
};

const Row = (props: RowProps) => {

    return <tr>
        <td>
            <SelectSolarSystemLink solarSystem={props.solarSystem} />
        </td>
        {props.solarSystems.map(x => <Cell key={x.solarSystemId} {...props} to={x} isSelected={x.solarSystemId === props.sourceSolarSystemId} />)}
        <td className="is-double-button">
            {props.showSendButtons && props.solarSystem !== undefined &&
                <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem} movementTypes={movementTypes} />
            }
        </td>
    </tr>;
};

export const TravelTime = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [shipType, setShipType] = React.useState<ShipType | undefined>(undefined);
    const [shipTypes, setShipTypes] = React.useState<ShipType[]>([]);
    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (playerState.Player && worldState.GameSettings && worldState.ShipTypeSettings) {
            const shipTypes = Object.values(worldState.ShipTypeSettings.data)
                .filter(x => x.factionTypeName == playerState.Player!.factionTypeName && x.class !== ShipClass.Drone)
                .sort((a, b) => a.order - b.order);

            setShipTypes(shipTypes);
        }

    }, [playerState.Player, worldState.GameSettings, worldState.ShipTypeSettings]);

    React.useEffect(() => {
        if (shipTypes.length > 0 && shipType === undefined) {
            setShipType(shipTypes[0]);
        }

    }, [shipTypes]);

    if (!worldState.ShipTypeSettings || !worldState.GameSettings || !playerState.Player) {
        return null;
    }

    const solarSystems = props.solarSystems.map(x => x.solarSystem);

    return <>
        {shipTypes.length > 0 &&
            <div className="ship-selection">
                <FieldHolder label="Ship Type">
                    <Select
                        value={shipType !== undefined ? shipType.typeName : ""}
                        valueChanged={v => setShipType(shipTypes.find(x => x.typeName == v))}
                        values={shipTypes.map(s => {
                            return {
                                value: s.typeName,
                                label: `${s.name} (${TextHelper.tidyText(ShipClass[s.class])})`
                            }
                        })}
                    />
                </FieldHolder>
                {shipType &&
                    <ShipTypeImageWithLabel size={'small'} shipType={shipType} label={`Speed: ${ValueFormatter.formatSpeed(shipType.solarSystemsPerHour)}`} />
                }
            </div>
        }
        {shipType &&
            <Table isFullWidth heading={
                <>
                    <th></th>
                    {solarSystems.map(x => <th key={x.solarSystemId}>{x.name}</th>)}
                    <th></th>
                </>
            }>
                {solarSystems.map(x => <Row
                    key={x.solarSystemId}
                    solarSystem={x}
                    solarSystems={solarSystems}
                    sourceSolarSystemId={sourceSolarSystemId}
                    setSourceSolarSystemId={setSourceSolarSystemId}
                    showSendButtons={props.solarSystems.length > 1}
                    solarSystemPerHour={shipType.solarSystemsPerHour}
                    gameSettings={worldState.GameSettings!}
                />)}
            </Table>
        }
    </>;
});