import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { economyProblemBuilder } from "./EconomyProblems";

export function buildQueueProblems(wrapper: SolarSystemWrapper): ProblemsMaybe {

    if (!wrapper.solarSystem.installationBuildQueue || wrapper.solarSystem.installationBuildQueue.length === 0) {
        return economyProblemBuilder.warningProblem(`Build Queue Empty`, `There are no installations under construction at ${wrapper.solarSystem.name}`);
    }

    return undefined;
}