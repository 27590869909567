import classNames from "classnames";

type Props = {
    isSmall?: boolean
}

export const LoadingSpinnerOnly = (props: Props) => {

    const className = classNames(
        "lds-dual-ring",
        props.isSmall && "is-small"
    );

    return <div className={className}></div>;
};

export const LoadingSpinner = (props: Props) => {
    return <div className="spinner-holder">
        <LoadingSpinnerOnly {...props} />
    </div>;
};