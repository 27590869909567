import React from "react";
import { PolicyType } from "../../../ApplicationState/ApiClient";
import { IconFromHint } from "./IconFromHint";
import { PolicyCategoryIcon } from "./PolicyCategoryIcon";

type Props = {
    policyType: PolicyType,
    isGiant?: boolean
}

export const PolicyTypeIcon = (props: Props) => {


    if (props.policyType.iconHint === undefined || props.policyType.iconHint === null) {
        return <PolicyCategoryIcon policyCategory={props.policyType.category} isGiant={props.isGiant} />;
    }

    return <IconFromHint iconHint={props.policyType.iconHint} subType={props.policyType.iconHintSubType} isGiant={props.isGiant} />;
};