import * as React from 'react';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    date: Date | undefined
}

export const NextResearchPointDate = (props: Props) => {

    if (props.date === undefined) {
        return null;
    }

    return <>Next Research Point: {ValueFormatter.formatDateFromNowWithMoment(props.date)}</>;
};