import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IAgent, ITerms, RansomTerms, ValidationResult } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { ValidationHelper } from '../../../Components/Base/Form/ValidationHelper';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { AgentPortrait } from '../../../Components/FusionShift/Images/AgentPortrait';
import { AgentLink } from '../../../Components/FusionShift/Links/AgentLink';
import { AgentHelper } from '../../../Helpers/AgentHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Configuration } from '../CreateContractView';

type Props = {
    terms: RansomTerms,
    configuration: Configuration,
    setTermsAndConfigure: (terms: ITerms, configuration: Configuration) => any,
    setValidation: (validation: ValidationResult | undefined) => any,
}

export const RansomForm = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const [cost, setCost] = React.useState(0);
    const [agent, setAgent] = React.useState<IAgent | undefined>(undefined);

    React.useEffect(() => {

        if (solarSystemState.SolarSystemsDetail) {
            setAgent(AgentHelper.findAgent(solarSystemState.SolarSystemsDetail.map(x => x.solarSystem), props.terms.agentId));
        }

    }, [solarSystemState.SolarSystemsDetail, props.terms.agentId]);

    React.useEffect(() => {
        props.setValidation(validate());
    }, [agent]);

    React.useEffect(() => {
        props.setValidation(validate());

        const terms = createTerms();

        props.setTermsAndConfigure(terms, {
            ...props.configuration
        });
    }, [cost]);

    function createTerms() {
        return RansomTerms.fromJS({
            ...props.terms,
            cost: cost,
            type: "RansomTerms"
        });
    }

    function validate() {

        if (agent === undefined) {
            return ValidationHelper.invalid("Unable to find agent");
        }

        return undefined;
    }

    if (!playerState.Player) {
        return <LoadingSpinner />;
    }

    return <>
        <FieldHolder label="Payment">
            <NumberInput value={cost} valueChanged={setCost} />
        </FieldHolder>
        <SubPanel heading={{ text: "Agent", icon: IconHelper.Agents.Captured }}>
            {agent === undefined && "Unknown"}
            {agent !== undefined && <SplitLayout
                left={<AgentPortrait agent={agent} size='large' />}
                right={
                    <>
                        <Paragraph>
                            <AgentLink agent={agent} />
                        </Paragraph>
                    </>
                }
            />}
        </SubPanel>
    </>;
});