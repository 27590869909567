import { observer } from "mobx-react-lite";
import * as React from "react";
import { PlayerWithCapitalSolarSystem } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { FancyTable, thSort, thSortHiddenMobile } from "../../Components/Base/Containers/FancyTable";
import { Level, levelContentWithHeading } from "../../Components/Base/Containers/Level";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { SolarSystemActionIcons } from "../../Components/FusionShift/Icons/SolarSystemActionIcons";
import { EmblemImage } from "../../Components/FusionShift/Images/EmblemImage";
import { FactionTypeImage } from "../../Components/FusionShift/Images/FactionTypeImage";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { SolarSystemLink } from "../../Components/FusionShift/Links/SolarSystemLink";
import { PlayerGraphWrapper } from "../../Components/FusionShift/Reports/PlayerGraph";
import { IconHelper } from "../../Helpers/IconHelper";
import { nameof } from "../../Helpers/TypeHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { player_view } from "../../Navigation/Routing/Player";
import { SimpleBaseView } from "../BaseView";

export const PlayerIndex = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [players, setPlayers] = React.useState<PlayerWithCapitalSolarSystem[] | undefined>(undefined);

    React.useEffect(() => {
        if (!players) {
            apiState.PlayerClient.list().then(players => {
                if (players) {
                    setPlayers(players);
                }
            });
        }
    }, [players]);

    const playerStats = React.useMemo(() => {
        if (players === undefined) {
            return undefined;
        }

        return players.reduce((s, p) => {

            return {
                players: s.players + 1,
                population: s.population + p.population,
                size: s.size + p.size,
                numberOfSolarSystems: s.numberOfSolarSystems + p.numberOfSolarSystems
            }
        }, {
            players: 0, population: 0, size: 0, numberOfSolarSystems: 0
        });

    }, [players]);

    if (!players) {
        return <LoadingSpinner />;
    }

    if (players.length === 0) {
        return <Paragraph type="missing">No players</Paragraph>
    }

    const content = [
        levelContentWithHeading("Players", ValueFormatter.formatLocaleNumber(playerStats?.players)),
        levelContentWithHeading("Total Size", ValueFormatter.formatLocaleNumber(playerStats?.size)),
        levelContentWithHeading("Total Pop.", ValueFormatter.formatPopulation(playerStats?.population ?? 0)),
        levelContentWithHeading("Total Systems", ValueFormatter.formatLocaleNumber(playerStats?.numberOfSolarSystems)),
    ];

    const header = [
        thSort("Rank", (p: PlayerWithCapitalSolarSystem) => p.rank <= 0 ? Number.MAX_SAFE_INTEGER : p.rank),
        {},
        thSort("Player", nameof<PlayerWithCapitalSolarSystem>("name")),
        thSortHiddenMobile("Faction", nameof<PlayerWithCapitalSolarSystem>("factionTypeName")),
        { label: "Capital", sorter: (d: PlayerWithCapitalSolarSystem) => d.capitalSolarSystem && d.capitalSolarSystem.name, colSpan: 2, isHiddenMobile: true },
        thSort("Size", nameof<PlayerWithCapitalSolarSystem>("size")),
        thSortHiddenMobile("Pop.", nameof<PlayerWithCapitalSolarSystem>("population")),
        thSortHiddenMobile("Systems", nameof<PlayerWithCapitalSolarSystem>("numberOfSolarSystems"))
    ];

    return <SimpleBaseView heading={{ text: "Players", icon: IconHelper.Categories.Player }}>
        <Level content={content} />
        <PlayerGraphWrapper players={players} hideIfInsufficientData />
        <FancyTable isStriped isHoverable isFullwidth hasBreakAll
            header={header}
            data={players}
            rowClick={(p: PlayerWithCapitalSolarSystem) => appState.navigate(player_view(p.playerId))}
            renderRow={p =>
                <>
                    <td>
                        {ValueFormatter.rank(p.rank)}
                    </td>
                    <td>
                        <EmblemImage size="tiny" filename={p.emblemFilename} />
                    </td>
                    <td>
                        <PlayerLink player={p} />
                        <span className="is-hidden-tablet">
                            <br />
                            {!!p.capitalSolarSystem && <SolarSystemLink solarSystem={p.capitalSolarSystem} hideActionIcons />}
                            {!!p.capitalSolarSystem && <SolarSystemActionIcons solarSystem={p.capitalSolarSystem} />}
                        </span>
                    </td>
                    <td className="is-hidden-mobile">
                        <FactionTypeImage factionTypeName={p.factionTypeName} size="tiny" onlyLogo />
                    </td>
                    <td className="is-hidden-mobile">
                        {!!p.capitalSolarSystem && <SolarSystemLink solarSystem={p.capitalSolarSystem} hideActionIcons />}
                    </td>
                    <td className="is-hidden-mobile">
                        {!!p.capitalSolarSystem && <SolarSystemActionIcons solarSystem={p.capitalSolarSystem} />}
                    </td>
                    <td>
                        {ValueFormatter.toShorthand(p.size)}
                    </td>
                    <td className="is-hidden-mobile">
                        {ValueFormatter.formatPopulation(p.population)}
                    </td>
                    <td className="is-hidden-mobile">
                        {p.numberOfSolarSystems}
                    </td>
                </>
            } />
    </SimpleBaseView>;
});