import { makeAutoObservable } from "mobx";

export class CreditsState {

    Balance: number = 0;
    PerHour: number = 0;
    LastLoadedDate: Date = new Date();

    constructor() {
        makeAutoObservable(this);
    }

    public loadCredits(balance: number, perHour: number) {
        this.Balance = balance;
        this.PerHour = perHour;
        this.LastLoadedDate = new Date();
    }
}