import { FleetMovementType, IShipTypeSettings, ISolarSystemDetail, ShipClass } from "../ApplicationState/ApiClient";


function fromDictionary(miningShipTypeNames: string[], target: { [key: string]: number }, dictionary: { [key: string]: number } | undefined) {
    if (dictionary === undefined) {
        return;
    }

    for (const shipType of Object.keys(dictionary)) {
        if (miningShipTypeNames.includes(shipType)) {
            target[shipType] += dictionary[shipType];
        }
    }
}

export class MiningHelper {

    public static systemMiners(shipTypeSettings: IShipTypeSettings, solarSystem: ISolarSystemDetail) {

        const minerShipTypeNames = Object.values(shipTypeSettings.data).filter(x => x.class === ShipClass.MiningBarge || x.class === ShipClass.AdvancedMiningBarge)
            .map(x => x.typeName);

        const repeatMiners: { [key: string]: number } = {};
        const systemMiners: { [key: string]: number } = {};
        const rebasingMiners: { [key: string]: number } = {};

        for (const minerTypeName of minerShipTypeNames) {
            repeatMiners[minerTypeName] = 0;
            systemMiners[minerTypeName] = 0;
            rebasingMiners[minerTypeName] = 0;
        }

        for (const repeat of solarSystem.repeatFleets ?? []) {
            if (repeat.movementType !== FleetMovementType.Mine) {
                continue;
            }

            fromDictionary(minerShipTypeNames, repeatMiners, repeat.ships);
        }

        fromDictionary(minerShipTypeNames, systemMiners, solarSystem.availableShips);
        for (const activating of solarSystem.activatingShips ?? []) {
            fromDictionary(minerShipTypeNames, systemMiners, activating.ships);
        }

        for (const fleet of solarSystem.fleets ?? []) {
            if (fleet.homeSolarSystemId === solarSystem.solarSystemId && fleet.movementType !== FleetMovementType.Rebase) {
                fromDictionary(minerShipTypeNames, systemMiners, fleet.ships);
            } else if (fleet.targetSolarSystem?.solarSystemId === solarSystem.solarSystemId && fleet.movementType === FleetMovementType.Rebase) {
                fromDictionary(minerShipTypeNames, rebasingMiners, fleet.ships);
            }
        }

        const val: { [key: string]: { needs: number, has: number, rebasing: number } } = {};

        for (const shipTypeName of minerShipTypeNames) {

            const data = {
                needs: repeatMiners[shipTypeName],
                has: systemMiners[shipTypeName],
                rebasing: rebasingMiners[shipTypeName]
            };

            if (data.needs === 0 && data.has === 0 && data.rebasing === 0) {
                continue;
            }

            val[shipTypeName] = data;
        }

        return val;
    }
}