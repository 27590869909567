import { IInstallationTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { Problem, ProblemBuilder, ProblemCategory } from "../Problem";
import { adminLevelProblems } from "./AdminLevelProblems";
import { agentProblems } from "./AgentProblems";
import { combatStatsProblems } from "./CombatStatsProblems";
import { damagedInstallationProblems } from "./DamagedInstallationProblems";
import { incomingProblems } from "./IncomingProblems";

export const militaryProblemBuilder = new ProblemBuilder(ProblemCategory.Military);

export function militaryProblems(installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper): Problem[] {

    return militaryProblemBuilder.merge(
        incomingProblems(wrapper),
        combatStatsProblems(wrapper),
        damagedInstallationProblems(installationTypeSettings, wrapper),
        adminLevelProblems(installationTypeSettings, wrapper),
        agentProblems(wrapper)
    );
}