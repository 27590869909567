import { observer } from "mobx-react-lite";
import * as React from 'react';
import ReactPlayer from "react-player";
import { AudioStateContext } from "../../ApplicationState/ContextRoot";
import { IconHelper } from "../../Helpers/IconHelper";
import { AudioButtons } from "./Buttons/AudioButtons";
import { MessageBoxWithIcon } from "./MessageBox";

export const AudioPlayer = observer(() => {

    const audioState = React.useContext(AudioStateContext);

    if (audioState.State === "Nothing" || audioState.Url === undefined) {
        return null;
    }

    return <MessageBoxWithIcon type="info" className="audio-player" icon={IconHelper.Audio.Audio} text={`Now Playing: ${audioState.UrlDescription !== undefined ? audioState.UrlDescription : "Unknown audio"}`}
        button={
            <AudioButtons />
        }    >
        <ReactPlayer url={audioState.Url} style={{ display: "none" }} playing={audioState.State === "Playing"} onEnded={() => audioState.stop()} />
    </MessageBoxWithIcon>;
});