import { IInstallationTypeSettings } from "../../../../ApplicationState/ApiClient";
import { CelestialHelper } from "../../../../Helpers/CelestialHelper";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { militaryProblemBuilder } from "./MilitaryProblems";

export function damagedInstallationProblems(installationTypeSettings: IInstallationTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;

    const damagedInstallations = solarSystem.celestials.map(c => {
        return {
            celestial: c,
            damagedInstallations: CelestialHelper.mapInstallations(c, i => i).filter(i => i.damage > 0).map(i => {
                return {
                    level: i.level,
                    installationType: installationTypeSettings.data[i.installationTypeName]
                }
            })
        }
    }).filter(c => c.damagedInstallations.length > 0);

    return damagedInstallations.map(c => c.damagedInstallations.map(i =>
        militaryProblemBuilder.dangerProblem("Installation Damaged",
            `${solarSystem.name}: ${c.celestial.name} has a damaged ${i.installationType.name} [${i.level}]`
        )
    )).flat();
}