import * as React from 'react';
import { ActivateActionResponse, AgentAction, AgentActionType, AgentOutcomeResponse, ApplyEffectParameters, ArrestAgentParameters, BaseParameters, IAgentDetail } from '../../../../ApplicationState/ApiClient';
import { ApiStateContext, DescriptionStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { LoadingWrapper } from '../../../../Components/Base/Loading/LoadingWrapper';
import { AgentActionImage } from '../../../../Components/FusionShift/Images/AgentActionImage';
import { TypeDataDescriptionParagraph } from '../../../../Components/FusionShift/TypeDataDescriptionParagraph';
import { AgentHelper } from '../../../../Helpers/AgentHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { OutcomeComponent } from './OutcomeComponent';
import { ParametersComponent } from './ParametersComponent';

type Props = {
    agent: IAgentDetail,
    actionType: AgentActionType,
    reloadCallback: (response: ActivateActionResponse) => any
}

export const ActionDetails = (props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [oldOutcome, setOldOutcome] = React.useState<AgentOutcomeResponse | undefined>(undefined);
    const [outcome, setOutcome] = React.useState<AgentOutcomeResponse | undefined>(undefined);
    const [parameters, setParameters] = React.useState<BaseParameters | undefined>(undefined);
    const [areParametersValid, setAreParametersValid] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState(false);

    function changeOutcome(newOutcome: AgentOutcomeResponse | undefined) {
        if (outcome !== undefined) {
            setOldOutcome(outcome);
        }
        setOutcome(newOutcome);
    }

    React.useEffect(() => {
        changeOutcome(undefined);
        setParameters(
            props.actionType.action === AgentAction.ApplyEffect ? ApplyEffectParameters.fromJS({
                agentActionTypeName: props.actionType.typeName
            }) :
                props.actionType.action === AgentAction.ArrestAgent ? ArrestAgentParameters.fromJS({
                    agentId: -1
                }) : undefined);
    }, [props.actionType]);

    React.useEffect(() => {
        changeOutcome(undefined);
        setAreParametersValid(AgentHelper.areParametersValid(props.actionType.action, parameters, props.agent));
    }, [parameters]);

    React.useEffect(() => {

        if (outcome === undefined && areParametersValid) {
            setIsLoading(true);
            apiState.AgentClient.calculateActionOutcome(props.agent.agentId, props.actionType.typeName, parameters === undefined || parameters === null ? new BaseParameters() : parameters)
                .then(changeOutcome)
                .finally(() => setIsLoading(false));
        }

    }, [areParametersValid, outcome, props.actionType.action, props.actionType, parameters]);

    function act() {
        return apiState.AgentClient.performAction(props.agent.agentId, props.actionType.typeName, parameters === undefined || parameters === null ? new BaseParameters() : parameters).then(x => {
            setAreParametersValid(false);
            props.reloadCallback(x);
        });
    }

    const description = descriptionState.AgentActions?.find(x => x.typeName === props.actionType.typeName);

    return <SubPanel
        heading={{ text: props.actionType.action === AgentAction.ApplyEffect ? "Apply Effect" : props.actionType.name, icon: IconHelper.Agents.action(props.actionType.action) }}
        footerContent={
            <Button type="action" action={act} text={props.actionType.name} icon={IconHelper.Agents.action(props.actionType.action)} isPulledRight isDisabled={outcome === undefined || !outcome.isPossible} />
        }>
        <TypeDataDescriptionParagraph description={description} />
        <ParametersComponent parameters={parameters} setParameters={setParameters} {...props} />
        {(outcome !== undefined || oldOutcome !== undefined) &&
            <LoadingWrapper isLoading={isLoading}>
                <OutcomeComponent {...props} outcome={(outcome ?? oldOutcome)!} />
            </LoadingWrapper>
        }
        <AgentActionImage {...props} />
    </SubPanel>;
}