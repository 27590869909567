import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ActivateActionResponse, AgentStatus, IAgentDetail } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { Badge, Badges } from '../../../Components/Base/Badge';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { MessageBoxWithIcon, WarningMessageBox } from '../../../Components/Base/MessageBox';
import { ProgressBar } from '../../../Components/Base/ProgressBar';
import { AgentStatusIcon } from '../../../Components/FusionShift/Icons/AgentStatusIcon';
import { AgentPortrait } from '../../../Components/FusionShift/Images/AgentPortrait';
import { EmblemImage } from '../../../Components/FusionShift/Images/EmblemImage';
import { AgentLocationLink } from '../../../Components/FusionShift/Links/AgentLocationLink';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ActionsComponent } from './Actions/ActionForm';
import { CelestialPositionComponent } from './CelestialPositionComponent';
import { CelestialPositionExtras } from './CelestialPositionExtras';
import { FleetPositionComponent } from './FleetPositionComponent';
import { RansomComponent } from './RansomComponent';

type Props = {
    agent: IAgentDetail,
    reloadCallback: (response: ActivateActionResponse) => any
}

export const AgentDetailComponent = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!playerState.Player) {
        return null;
    }

    const isOwned = props.agent.playerId === playerState.Player.playerId;
    const isCapturedAtOwn = props.agent.celestialPosition?.solarSystem?.playerId === playerState.Player.playerId && props.agent.status === AgentStatus.Captured;

    return <SubPanel
        heading={props.agent.name}
        backgroundImage={CelestialHelper.celestialBackground(props.agent.celestialPosition?.celestial, props.agent.celestialPosition?.solarSystem?.owner !== undefined)}
    >
        <Badges>
            <Badge label="Reports To" content={<PlayerLink player={props.agent.player} />} icon={<EmblemImage filename={props.agent.player?.emblemFilename} size='small' />} />
            <Badge label="Status" content={ValueFormatter.friendlyNameForAgentStatus(props.agent.status).toLocaleUpperCase()} icon={<AgentStatusIcon status={props.agent.status} />} />
            <Badge label="Location" content={<AgentLocationLink agent={props.agent} isBrief />} icon={IconHelper.Map.TacticalMap} />
            <Badge label="Level" content={props.agent.level === undefined || props.agent.level === null ? "UNKNOWN" : props.agent.level} icon={IconHelper.Agents.Experience} />
        </Badges>
        {props.agent.status === AgentStatus.Captured && <MessageBoxWithIcon type={"warning"} icon={IconHelper.Agents.status(AgentStatus.Captured)} text={`${props.agent.name} has been captured`} />}
        {props.agent.status === AgentStatus.Deceased && <MessageBoxWithIcon type={"danger"} icon={IconHelper.Agents.status(AgentStatus.Deceased)} text={`${props.agent.name} has been killed`} />}
        <SplitLayout
            isRightEmpty={!isOwned && !isCapturedAtOwn}
            left={
                <SubPanel heading="Overview">
                    <SplitLayout
                        left={
                            <AgentPortrait {...props} size='large' />
                        }
                        right={<>
                            {isOwned && <>
                                <ProgressBar
                                    leftIcon={IconHelper.Agents.Experience}
                                    value={props.agent.experience ?? 0}
                                    max={props.agent.experienceForNextLevel ?? 0}
                                    uncoloured
                                    text={`XP to next level: ${ValueFormatter.formatLocaleNumber(props.agent.experience ?? 0)} / ${ValueFormatter.formatLocaleNumber(props.agent.experienceForNextLevel ?? 0)}`}
                                />
                                <br />
                            </>
                            }
                            <FleetPositionComponent {...props} />
                            <CelestialPositionComponent {...props} />
                            {props.agent.celestialPosition === undefined && props.agent.fleetPosition === undefined && <WarningMessageBox text="Unable to determine agent's location" />}
                        </>}
                    />
                    <CelestialPositionExtras {...props} />
                </SubPanel>
            }
            right={<>
                {isOwned &&
                    <SubPanel heading={"Actions"}>
                        <ActionsComponent {...props} />
                    </SubPanel>}
                {isCapturedAtOwn &&
                    <RansomComponent {...props} />
                }
            </>
            }
        />
    </SubPanel>;
});