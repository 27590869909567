import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { CreditsStateContext } from '../../../ApplicationState/ContextRoot';
import { CreditsIconWithQuantity } from '../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { credits } from '../../Routing/Personal';

type Props = {
    className?: string,
    isMobile?: boolean,
    dontCountUp?: boolean
}

export const CreditsLink = observer((props: Props) => {

    const creditsState = React.useContext(CreditsStateContext);

    return <Link to={credits} className={props.className ?? ""}>
        <CreditsIconWithQuantity
            quantity={creditsState.Balance}
            hideDecimals
            isShorthand={props.isMobile}
            isCounting={!props.dontCountUp}
        />
    </Link >;
});