import { Button } from "../../../../Components/Base/Buttons/Button";
import { ButtonChooser, choice } from "../../../../Components/Base/Buttons/ButtonChooser";
import { FieldHolder } from "../../../../Components/Base/Form/FieldHolder";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { AcquireTargetClosestConfiguration, ClosestType } from "../Entities";

type Props = {
    config: AcquireTargetClosestConfiguration,
    setConfig: (config: AcquireTargetClosestConfiguration) => any
}

export const ClosestConfigurer = (props: Props) => {

    const choices = [
        choice<ClosestType>("absolute", "Best"),
        choice<ClosestType>("self", "Self"),
        choice<ClosestType>("ally", "Ally")
    ];

    return <div className="is-flex mobile-no-flex">
        <FieldHolder label="Closest Display">
            <ButtonChooser
                value={props.config.closest}
                valueChanged={v => props.setConfig({
                    ...props.config,
                    closest: v
                })}
                values={choices}
                hideTextOnMobile={false}
            >
                <Button
                    type="nav"
                    icon={IconHelper.SolarSystems.Outpost}
                    isSelected={!props.config.excludeOutposts}
                    action={() => props.setConfig({
                        ...props.config,
                        excludeOutposts: !props.config.excludeOutposts
                    })}
                    text="Include Outposts"
                />
            </ButtonChooser>
        </FieldHolder>
    </div>;
}