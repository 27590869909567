import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { ActivateActionResponse, AgentDetail } from "../../ApplicationState/ApiClient";
import { AgentStateContext, ApiStateContext } from "../../ApplicationState/ContextRoot";
import { BackNavButton } from "../../Components/Base/Buttons/BackButton";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { IconHelper } from "../../Helpers/IconHelper";
import { agents } from "../../Navigation/Routing/Agents";
import { SimpleBaseView } from "../BaseView";
import { AgentDetailComponent } from "./Components/AgentDetailComponent";
import { ResponseMessage } from "./Components/ResponseMessage";

export const AgentView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const agentState = React.useContext(AgentStateContext);

    const { agentId } = useParams();

    const [agent, setAgent] = React.useState<AgentDetail | undefined>(undefined);
    const [response, setResponse] = React.useState<ActivateActionResponse | undefined>(undefined);

    React.useEffect(() => {

        apiState.AgentClient.byAgentId(Number(agentId)).then(x => {
            agentState.loadAgent(x);
            setAgent(x);
            setResponse(undefined);
        });

    }, [agentId]);

    React.useEffect(() => {
        if (response !== undefined) {
            agentState.loadAgent(response.agent);
            setAgent(response.agent);
        }
    }, [response]);

    function reloadCallback(response: ActivateActionResponse) {
        setResponse(response);
    }

    return <SimpleBaseView
        heading={{ text: "Agent Detail", icon: IconHelper.Agents.Agent }}
        headingContent={
            <ButtonHolder>
                <BackNavButton to={agents} />
                <HelpButton to="agents" />
            </ButtonHolder>
        }>
        {agent !== undefined && <ResponseMessage response={response} agent={agent} />}
        {agent === undefined && <LoadingSpinner />}
        {agent !== undefined && <AgentDetailComponent agent={agent} reloadCallback={reloadCallback} />}
    </SimpleBaseView>
});