import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext } from '../../../ApplicationState/ContextRoot';
import { AdminMenu } from '../Submenus/AdminMenu';
import { HelpMenu } from '../Submenus/HelpMenu';
import { InfoMenu } from '../Submenus/InfoMenu';
import { MarketMenu } from '../Submenus/MarketMenu';

export const DesktopLinks = observer(() => {

    const appState = React.useContext(AppStateContext);

    if (!appState.IsReady) {
        return null;
    }

    return <>
        <MarketMenu />
        <InfoMenu />
        <HelpMenu />
        <AdminMenu />
    </>;
});