import * as React from 'react';
import { PlayerSecurityStatusChange } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { TextAndIcon } from '../../../Components/Base/Text/TextAndIcon';
import { SecurityStatusIcon } from '../../../Components/FusionShift/Icons/SecurityStatusIcon';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

export const SecurityStatusChangeTable = React.forwardRef((props, ref) => {

    const apiState = React.useContext(ApiStateContext);

    const [playerSecurityStatusChanges, setplayerSecurityStatusChanges] = React.useState<PlayerSecurityStatusChange[] | undefined>(undefined);

    React.useImperativeHandle(ref, () => ({

        reload() {
            apiState.PlayerClient.getSecurityStatusChanges().then(setplayerSecurityStatusChanges);
        }

    }));

    React.useEffect(() => {
        if (playerSecurityStatusChanges === undefined) {
            apiState.PlayerClient.getSecurityStatusChanges().then(setplayerSecurityStatusChanges);
        }
    });

    return <SubPanel
        heading={{
            text: "Security Status Changes",
            icon: IconHelper.Security.Security
        }}
        isUnpadded={playerSecurityStatusChanges !== undefined && playerSecurityStatusChanges.length > 0}
    >
        {playerSecurityStatusChanges === undefined && <LoadingSpinner />}
        {playerSecurityStatusChanges !== undefined && playerSecurityStatusChanges.length === 0 && <Paragraph>
            No security status changes.
        </Paragraph>}
        {playerSecurityStatusChanges !== undefined && playerSecurityStatusChanges.length > 0 &&
            <Table
                isFixed
                isFullWidth
                heading={<>
                    <th>
                        Date
                    </th>
                    <th>
                        From
                    </th>
                    <th>
                        To
                    </th>
                    Reason
                </>}
            >
                {playerSecurityStatusChanges.map(x => <tr key={x.playerSecurityStatusChangeId}>
                    <td>
                        {ValueFormatter.formatDateFromNowWithMoment(x.date)}
                    </td>
                    <td>
                        <TextAndIcon
                            hideTextOnMobile
                            text={ValueFormatter.friendlyNameForSecurityStatus(x.previousSecurityStatus)}
                            icon={
                                <SecurityStatusIcon securityStatus={x.previousSecurityStatus} />
                            } />
                    </td>
                    <td>
                        <TextAndIcon
                            hideTextOnMobile
                            text={ValueFormatter.friendlyNameForSecurityStatus(x.newSecurityStatus)}
                            icon={
                                <SecurityStatusIcon securityStatus={x.newSecurityStatus} />
                            } />
                    </td>
                    <td>
                        {x.reason}
                    </td>
                </tr>)}
            </Table>
        }
    </SubPanel>
});