import { ICombatReportDetail, IShipTypeSettings } from "../../../ApplicationState/ApiClient";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Ships } from "./Ships";

type Props = {
    shipTypeSettings: IShipTypeSettings,
    combatReport: ICombatReportDetail
}

export const AllShips = (props: Props) => {

    const attackerRows = props.combatReport.attackingShips ? Object.keys(props.combatReport.attackingShips).length : 0;
    const defenderRows = props.combatReport.defendingShips ? Object.keys(props.combatReport.defendingShips).length : 0;

    return <SubPanel heading="Participants and Losses">
        <SplitLayout
            left={
                <Ships matchRowsTo={defenderRows} ships={props.combatReport.attackingShips} shipTypeSettings={props.shipTypeSettings} isAttacker />
            }
            right={
                <Ships matchRowsTo={attackerRows} ships={props.combatReport.defendingShips} shipTypeSettings={props.shipTypeSettings} isAttacker={false} />
            }
        />
    </SubPanel>;
};
