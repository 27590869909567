import { observer } from "mobx-react-lite";
import * as React from 'react';
import { Link } from "react-router-dom";
import { MobileMenuStateContext, SolarSystemStateContext } from "../../ApplicationState/ContextRoot";
import { selected_system } from "../Routing/SolarSystem";

export const SelectSolarsystemMobileItem = observer(() => {

    const mobileMenuState = React.useContext(MobileMenuStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (solarSystemState.SolarSystem === undefined) {
        return null;
    }

    return <>
        <span className="selected-mobile-solar-system" onClick={() => mobileMenuState.close()}>
            <Link to={selected_system} className="solar-system-link">
                {solarSystemState.SolarSystem.name}
            </Link>
        </span>
    </>
});