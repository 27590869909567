import { observer } from "mobx-react-lite";
import * as React from "react";
import { User } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonChooser, choice } from "../../Components/Base/Buttons/ButtonChooser";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { Table } from "../../Components/Base/Containers/Table";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { DateText } from "../../Components/Base/Text/DateText";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { IconHelper } from "../../Helpers/IconHelper";
import { admin_cheat } from "../../Navigation/Routing/Admin";
import { SimpleBaseView } from "../BaseView";

export const UserIndex = observer(() => {

    const appState = React.useContext(AppStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [users, setUsers] = React.useState<User[] | undefined>(undefined);
    const [emailConsentOnly, setEmailConsentOnly] = React.useState(false);

    React.useEffect(() => {
        if (!users && appState.IsAdmin) {
            apiState.AdminClient.listUsers().then(users => {
                if (users) {
                    setUsers(users);
                }
            });
        }
    }, [users, appState.IsAdmin]);

    if (!users) {
        return <LoadingSpinner />;
    }

    function makeAdmin(playerId: number) {
        return apiState.AdminClient.makeAdmin(playerId).then(x => {
            setUsers(undefined);
        })
    }

    const options = [
        choice(false, "Show all"),
        choice(true, "Email Consent"),
    ];

    return <SimpleBaseView heading="Users">
        <ButtonChooser values={options} value={emailConsentOnly} valueChanged={setEmailConsentOnly} />
        <Table isHoverable isFullWidth heading={
            <>
                <td>Email</td>
                <td>Player</td>
                <td>Created</td>
                <td>Last Seen</td>
                <td></td>
                <td></td>
            </>
        }>
            {users.filter(u => !emailConsentOnly || u.hasGivenEmailConsent).map(u =>
                <tr key={u.userId}>
                    <td>
                        {u.emailAddress}
                    </td>
                    <td>
                        <PlayerLink player={u.player} />
                    </td>
                    <td>
                        {u.createdDate.toDateString()}
                    </td>
                    <td>
                        <DateText date={u.lastSeenDate} />
                    </td>
                    <td>
                        {!u.isAdmin && <Button type="danger" icon={IconHelper.Federations.Promote} text="Make Admin" confirm="Are you sure you want to make this user an admin?" action={() => makeAdmin(u.player!.playerId)} />}
                        {!!u.isAdmin && "Admin"}
                    </td>
                    <td>
                        {!!u.player && <ButtonLink to={admin_cheat(u.player.playerId)} icon={IconHelper.Installations.Build} text="Cheat" />}
                    </td>
                </tr>)}
        </Table>
    </SimpleBaseView>;
});