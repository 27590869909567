import React from "react";
import { EmblemPiece, InGameProduct, Manifest } from "../../../../ApplicationState/ApiClient";
import { EmblemCard } from "./EmblemCard";

type Props = {
  manifest: Manifest;
  onEmblemBadgeClick: (emblemPiece: EmblemPiece) => void;
  getPremiumEmblem: (filename: string) => InGameProduct | undefined;
  hasPurchasedPremiumEmblem: (filename: string) => boolean | undefined;
  selectedCategoryName: string;
  selectedEmblemFilename: string | undefined;
  emblemsCdnUrl: string | undefined;
}

export const CategoryEmblemCards = (props: Props) => {
  const emblems = props.manifest?.categories[
    props.selectedCategoryName
  ];

  return (
    <>
      <div className={"horizontal-scroll-wrapper"}>
        {(emblems &&
          emblems.length > 0 &&
          emblems.map(x => <EmblemCard
            key={x.fileName}
            emblemsCdnUrl={props.emblemsCdnUrl}
            emblem={x}
            premiumEmblem={props.getPremiumEmblem(x.fileName)}
            hasPurchased={props.hasPurchasedPremiumEmblem(x.fileName)}
            isSelected={props.selectedEmblemFilename == x.fileName}
            onEmblemBadgeClick={() => props.onEmblemBadgeClick(x)} />
          )) || <p>No badge found</p>}
      </div>
    </>
  );
}
