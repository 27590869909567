import { CheatView } from "../../Views/Admin/Cheats/CheatView";
import { CostOfUpgradesView } from "../../Views/Admin/Dev/Balance/CostOfUpgradesView";
import { ProductionView } from "../../Views/Admin/Dev/Balance/ProductionView";
import { SolarSystemSamples } from "../../Views/Admin/Dev/SolarSystemSamples";
import { UserIndex } from "../../Views/Admin/UserIndex";
import { WorldView } from "../../Views/Admin/World/WorldView";
import { WorldIndex } from "../../Views/Admin/WorldIndex";
import { route } from "./Root";

export const admin = "/admin";
export const admin_users = admin + "/users";
export const admin_worlds = admin + "/worlds";
export function admin_world(worldId: number | string) {
    return admin_worlds + "/" + worldId;
}
export function admin_cheat(playerId: number | string) {
    return admin + "/cheat/" + playerId;
}

export const dev = admin;
export const dev_solarSystemSamples = dev + "/solarsystemsamples";
export const dev_balance = dev + "/balance";
export const dev_balance_production = dev_balance + "/production";
export const dev_balance_costOfUpgrades = dev_balance + "/costofupgrades";

export const adminRoutes = [

    route(admin_worlds, WorldIndex, "Admin - Worlds"),
    route(admin_world(":worldId"), WorldView, "Admin - World"),
    route(admin_users, UserIndex, "Admin - Users"),
    route(admin_cheat(":playerId"), CheatView, "Admin - Cheats"),

    route(dev_solarSystemSamples, SolarSystemSamples, "Admin - Solar System Samples"),
    route(dev_balance_production, ProductionView, "Admin - Prduction"),
    route(dev_balance_costOfUpgrades, CostOfUpgradesView, "Admin - Cost of Upgrades")
];