import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { BribeForManifestoTerms, ITerms, PresidentialTermDetail, ValidationResult } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { ValidationHelper } from '../../../Components/Base/Form/ValidationHelper';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { Configuration } from '../CreateContractView';
import { ManifestoTable } from './ManifestoTable';

type Props = {
    terms: BribeForManifestoTerms,
    configuration: Configuration,
    setTermsAndConfigure: (terms: ITerms, configuration: Configuration) => any,
    setValidation: (validation: ValidationResult | undefined) => any,
}

export const BribeForManifestoForm = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [payment, setPayment] = React.useState(0);
    const [presidentialManifestoId, setPresidentialManifestoId] = React.useState<number | undefined>(undefined);

    const [presidentialTerm, setPresidentialTerm] = React.useState<PresidentialTermDetail | undefined>(undefined);

    React.useEffect(() => {
        apiState.PoliticsClient.getTermOpenForVoting().then(presidentialTerm => {
            setPresidentialTerm(presidentialTerm);
        });
    }, []);

    React.useEffect(() => {
        if (presidentialTerm && playerState.Player) {
            let bestManifestoId: number | undefined = undefined;

            for (let manifesto of presidentialTerm.presidentialManifestos) {
                if (manifesto.playerId === playerState.Player.playerId) {
                    bestManifestoId = undefined;
                    setPresidentialManifestoId(manifesto.presidentialManifestoId);
                    break;
                } else if (manifesto.didObservingPlayerVoteForThis) {
                    bestManifestoId = manifesto.presidentialManifestoId;
                }
            }
            if (bestManifestoId !== undefined) {
                setPresidentialManifestoId(bestManifestoId);
            }
        }
    }, [presidentialTerm, playerState.Player]);

    React.useEffect(() => {
        props.setValidation(validate());

        const terms = createTerms();

        props.setTermsAndConfigure(terms, {
            ...props.configuration
        });
    }, [presidentialManifestoId, payment]);

    function createTerms() {
        return BribeForManifestoTerms.fromJS({
            presidentialManifestoId,
            payment,
            type: "BribeForManifestoTerms"
        });
    }

    function validate() {
        if (presidentialManifestoId === undefined) {
            return ValidationHelper.invalid("Pick a valid manifesto");
        }
    }

    if (!presidentialTerm || !playerState.Player) {
        return <LoadingSpinner />;
    }

    return <>
        <FieldHolder label="Payment">
            <NumberInput value={payment} valueChanged={setPayment} />
        </FieldHolder>
        <SubPanel heading="Manifestos" isUnpadded={presidentialTerm.presidentialManifestos.length > 0}>
            <ManifestoTable
                presidentialManifestoId={presidentialManifestoId}
                setPresidentialManifestoId={setPresidentialManifestoId}
                presidentialManifestos={presidentialTerm.presidentialManifestos}
            />
        </SubPanel>
    </>;
});