import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ISimulationInput, SimulationResult } from '../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { InfoMessageBox } from '../../Components/Base/MessageBox';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { IconHelper } from '../../Helpers/IconHelper';
import { CombatReportRender } from '../CombatReport/CombatReportRender';
import { validateSimulation } from './Validation';

type Props = {
    simulation: ISimulationInput,
    isDirty: boolean,
    isSimulating: boolean,
    result: SimulationResult | undefined,
    simulate: () => any
}

export const Result = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    const [showAttacker, setShowAttacker] = React.useState(false);

    const { validAttacker, validDefender, isValid } = validateSimulation(props.simulation);

    if (!playerState.Player) {
        return <LoadingSpinner />;
    }

    return <>
        {!validDefender && <Paragraph type="warning">
            No defender has been configured
        </Paragraph>}
        {!validAttacker && <Paragraph type="warning">
            The configured attacker has no ships
        </Paragraph>}
        {isValid && props.isDirty && !props.isSimulating && <InfoMessageBox text="Simulation configuration has changed"
            button={
                <Button type="nav" action={props.simulate} icon={IconHelper.Combat.Simulation} text="Simulate" isPulledRight />
            }
        />}
        {props.isSimulating && <LoadingSpinner />}
        {props.result && <SubPanel
            heading={{ text: "Results", icon: IconHelper.Categories.Summary }}
            headingContent={<ButtonHolder>
                <Button type="nav" icon={IconHelper.Combat.Attack} text="Attacker's Report" action={() => setShowAttacker(true)} isDisabled={showAttacker} />
                <Button type="nav" icon={IconHelper.Combat.Defence} text="Defender's Report" action={() => setShowAttacker(false)} isDisabled={!showAttacker} />
            </ButtonHolder>}
        >
            <CombatReportRender combatReport={showAttacker ? props.result.attackerCombatReport : props.result.defenderCombatReport} />
        </SubPanel>}
    </>;
});