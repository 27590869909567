import * as React from 'react';

type Props = {
    label: React.ReactNode,
    children: React.ReactNode
}

export const ContentWithLabel = (props: Props) => {

    return <div className="content-with-label">
        <div>
            {props.children}
        </div>
        <div className="label">
            {props.label}
        </div>
    </div>
};