import { observer } from "mobx-react-lite";
import * as React from "react";
import { HomepageStats } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { Panel } from "../../Components/Base/Containers/Panel";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { MessageBoxWithIcon } from "../../Components/Base/MessageBox";
import { IconHelper } from "../../Helpers/IconHelper";
import { join_world } from "../../Navigation/Routing/AppRouteValues";
import { AuthenticationWrapper } from "../Authentication/AuthenticationWrapper";
import { RecentChangesPanel } from "../Changes/RecentChangesPanel";
import { FlatMenu } from "./FlatMenu";
import { HypeSection } from "./HypeSection";
import { StatsControl } from "./StatsControl";
import { WelcomeSection } from "./WelcomeSection";

export const HomeView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    const [stats, setStats] = React.useState<HomepageStats | undefined>(undefined);

    React.useEffect(() => {
        if (appState.IsLoggedIn && appState.WorldState.World !== undefined) {
            apiState.GameClient.getHomepageStats().then(stats => {
                setStats(stats);
            });
        }
    }, [appState.IsLoggedIn, appState.ApplicationState, appState.WorldState.World]);

    if (appState.ApplicationState === "PerformingInitialLoad") {
        return null;
    }

    if (appState.ApplicationState === "Unauthenticated") {
        return <AuthenticationWrapper>
            <Panel heading="Welcome, Galactician" isPaddedMobile>
                <WelcomeSection />
            </Panel>
        </AuthenticationWrapper>;
    }

    return <Panel heading={"Fusion Shift"}
        headingContent={appState.IsLoggedIn && <Button type="warning" action={() => appState.logout()} text="Logout" icon={IconHelper.General.Exit} />}
    >
        {appState.ApplicationState === "NoWorld" && <>
            <MessageBoxWithIcon icon={IconHelper.Statuses.Info} type="info" text="You haven't joined any worlds yet"
                button={
                    <ButtonLink to={join_world} icon={IconHelper.General.Plus} text="Join World" />
                } />
            <HypeSection />
        </>}
        {appState.ApplicationState === "Ready" &&
            <>
                <SplitLayout
                    isRightEmpty={stats === undefined}
                    left={
                        <>
                            {appState.IsLoggedIn && <FlatMenu />}
                            {!appState.IsLoggedIn &&
                                <div>
                                    <WelcomeSection />
                                    <HypeSection />
                                </div>
                            }
                        </>
                    }
                    right={<>
                        {!!stats && <StatsControl homepageStats={stats} />}
                    </>
                    }
                />
                <RecentChangesPanel />
            </>
        }
    </Panel>
});