import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DroneStatus } from '../../../../ApplicationState/ApiClient';
import { Icon } from '../../../../Components/Base/Icon';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const DroneStatusRequired = observer((props: Props) => {

    if (!props.mission.MissionType.droneStatusRequired) {
        return null;
    }

    return <ObjectiveRow has={0} requires={1} {...props}>
        <Icon icon={IconHelper.Ships.droneStatus(props.mission.MissionType.droneStatusRequired)} />
        {props.mission.MissionType.droneStatusRequired === DroneStatus.Deactivating ? "De-activate" : "Activate"} a Drone
    </ObjectiveRow>;
});