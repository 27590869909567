import classNames from "classnames";
import { ReactNode } from "react";
import { IconHelper } from "../../Helpers/IconHelper";
import { BulmaTypes } from "./BulmaTypes";
import { Icon } from "./Icon";

type Props = {
    type: BulmaTypes,
    className?: string | undefined
}

type WithIconProps = Props & {
    hasSuperGiantIcon?: boolean,
    text?: ReactNode | string,
    icon: string,
    children?: ReactNode | undefined,
    button?: ReactNode | undefined
}

type MessageBoxProps = Props & {
    children: ReactNode | undefined
}

type ComposedProps = {
    text?: ReactNode | string,
    children?: ReactNode | undefined,
    button?: ReactNode | undefined
}

export const SuccessMessageBox = (props: ComposedProps) => {
    return <MessageBoxWithIcon {...props} icon={IconHelper.Statuses.Success} type="success" />;
};

export const WarningMessageBox = (props: ComposedProps) => {
    return <MessageBoxWithIcon {...props} icon={IconHelper.Statuses.Warning} type="warning" />;
};

export const ErrorMessageBox = (props: ComposedProps) => {
    return <MessageBoxWithIcon {...props} icon={IconHelper.Statuses.Error} type="danger" />;
};

export const InfoMessageBox = (props: ComposedProps) => {
    return <MessageBoxWithIcon {...props} icon={IconHelper.Statuses.Info} type="info" />;
};

export const MessageBoxWithIcon = (props: WithIconProps) => {

    const hasText = props.text !== undefined && (typeof props.text !== "string" || props.text.length > 0);
    const hasButton = !!props.button;

    const className = classNames(
        props.className,
        hasButton && "has-button",
        "has-icon",
        {
            "has-super-giant-icon": props.hasSuperGiantIcon
        }
    );

    return <MessageBox className={className} type={props.type}>
        <Icon icon={props.icon} />
        {hasText &&
            <p>
                {props.text}
            </p>
        }
        {hasButton && <span className="message-button">
            {props.button}
        </span>}
        {!!props.children && <span className="children">{props.children}</span>}
    </MessageBox>
};

const MessageBox = (props: MessageBoxProps) => {

    return <article className={`message is-${props.type} ${props.className ? props.className : ""}`}>
        <div className="message-body">
            {props.children}
        </div>
    </article>
};

MessageBoxWithIcon.defaultProps = {
    style: {}
};

MessageBox.defaultProps = {
    style: {}
};