import { Drones, DroneStatus, ICelestialDetail, IInstallation, IInstallationTypeSettings, ISolarSystemDetail } from "../ApplicationState/ApiClient";
import { CollectionHelper } from "./CollectionHelper";
import { InstallationHelper } from "./InstallationHelper";

export class DroneHelper {

    public static droneSummary(installationTypeSettings: IInstallationTypeSettings, solarSystem: ISolarSystemDetail | undefined) {

        return {
            currentInactive: solarSystem && solarSystem.drones ? CollectionHelper.sumOfDictionary(solarSystem.drones.inactiveDrones) : 0,
            currentActive: solarSystem && solarSystem.drones ? solarSystem.drones.activeDrones.filter(d => d.status !== DroneStatus.Returning).length : 0,
            currentReturning: solarSystem && solarSystem.drones ? solarSystem.drones.activeDrones.filter(d => d.status === DroneStatus.Returning).length : 0,
            maxActive: InstallationHelper.sumOfStatPerLevelForSolarSystem(installationTypeSettings, solarSystem, i => i.dronesActivePerLevel),
            maxTotal: InstallationHelper.sumOfStatPerLevelForSolarSystem(installationTypeSettings, solarSystem, i => i.dronesPerLevel)
        };
    }

    public static celestialForDronesAtSystem(installationTypeSettings: IInstallationTypeSettings, solarSystem: ISolarSystemDetail): ICelestialDetail | undefined {
        for (let celestial of solarSystem.celestials) {
            if (this.hasDronesAtCelestial(installationTypeSettings, celestial)) {
                return celestial;
            }
        }

        return undefined;
    }

    public static hasDronesAtCelestial(installationTypeSettings: IInstallationTypeSettings, celestial: ICelestialDetail) {
        if (celestial.installations) {
            for (let i of celestial.installations) {
                if (this.hasDronesAtInstallation(installationTypeSettings, i)) {
                    return true;
                }
            }
        }
        return false;
    }

    public static hasDronesAtInstallation(installationTypeSettings: IInstallationTypeSettings, installation: IInstallation) {
        if (installation.level > 0 && installation.installationTypeName in installationTypeSettings.data &&
            installationTypeSettings.data[installation.installationTypeName].dronesActivePerLevel > 0) {
            return true;
        }
        return false;
    }

    public static activeDronesAsShips(drones: Drones | undefined): { [key: string]: number } {
        if (!drones) {
            return {}
        }

        const asShips = {};

        for (let drone of drones.activeDrones) {
            if (drone.status === DroneStatus.Active) {
                if (drone.shipTypeName in asShips) {
                    asShips[drone.shipTypeName] = asShips[drone.shipTypeName] + 1;
                } else {
                    asShips[drone.shipTypeName] = 1;
                }
            }
        }

        return asShips;
    }
}