import { PlayerIndex } from "../../Views/Player/PlayerIndex";
import { PlayerView } from "../../Views/Player/PlayerView";
import { SecurityStatusView } from "../../Views/Player/SecurityStatusView";
import { route } from "./Root";

export const player_securitystatus = "/securitystatus";
export const players = "/players";
export function player_view(playerId: number | string) {
    return players + "/" + playerId;
}

export const playerRoutes = [
    route(player_view(":playerId"), PlayerView, "Player"),
    route(players, PlayerIndex, "Players"),
    route(player_securitystatus, SecurityStatusView, "Security Status")
];