import { ReactNode } from "react";

export type ValidationWithContent = {
    valid: boolean,
    content: ReactNode
}

export type Validation = {
    value: string,
    valid: boolean
}

export function valueValid(valid: boolean, value?: string): Validation {
    return {
        value: value ? value : "",
        valid: valid
    };
}