import { IGameSettings } from "../ApplicationState/ApiClient";
import { SimpleTimeSpan } from "../Entities/Shared";
import { TimeSpan } from "../Entities/TimeSpan";
import { ValueFormatter } from "./ValueFormatter";


export class TimeHelper {

    public static isSameDayAsToday(date: Date) {
        const now = new Date();

        return this.isSameDay(date, now);
    }

    public static isSameDay(a: Date, b: Date) {
        return b.getUTCDate() === a.getUTCDate() &&
            b.getUTCMonth() === a.getUTCMonth() &&
            b.getUTCFullYear() === a.getUTCFullYear();
    }

    public static sortDates(a: Date | undefined, b: Date | undefined) {
        if (!a && b) {
            return -1;
        } else if (!b && a) {
            return 1;
        } else if (!a && !b) {
            return 0;
        }

        return a!.getTime() - b!.getTime();
    }

    static tickValueToPerHour(gameSettings: IGameSettings, tickValue: number) {

        const ticksPerHour = (60 * 60) / gameSettings.secondsPerTick;

        return (ticksPerHour * tickValue) * gameSettings.gameSpeed;
    }

    public static formatTimeSpanByWords(ts: SimpleTimeSpan | undefined) {
        if (!ts) {
            return "";
        }

        return [
            { value: ts.days, label: ValueFormatter.pluralize("day", ts.days !== undefined ? ts.days : 0) },
            { value: ts.hours, label: "hr" },
            { value: ts.minutes, label: "min" },
            { value: ts.seconds, label: "sec" }
        ].filter(e => e.value !== undefined && e.value > 0)
            .map(e => `${e.value} ${e.label}`)
            .join(", ");
    }

    public static formatTimeSpanByNumbers(ts: SimpleTimeSpan | undefined) {
        if (!ts) {
            return "";
        }

        const cutoff = ts.days && ts.days > 0 ? 0 :
            ts.hours && ts.hours > 0 ? 1 :
                ts.minutes && ts.minutes > 0 ? 2 :
                    ts.seconds > 0 ? 3 : 1;

        let text = [
            ts.days,
            ts.hours,
            ts.minutes,
            ts.seconds
        ].filter(e => e !== undefined)
            .map(e => {
                let asString = e!.toString();
                if (asString.length === 1) {
                    asString = "0" + asString;
                }
                return asString;
            })
            .filter((t, i) => t !== "00" || i > cutoff)
            .join(":");

        if (text.startsWith("0")) {
            text = text.substring(1);
        }

        return text;
    }

    public static createTimeSpanFromNow(date: Date): SimpleTimeSpan {
        const diff = Math.round((date.getTime() - Date.now())) / 1000;
        return this.createTimeSpanFromSeconds(diff);
    }

    public static createTimeSpanFromTimeSpanString(text: string): SimpleTimeSpan {
        return this.createTimeSpanFromTimeSpan(TimeSpan.fromString(text));
    }

    private static createTimeSpanFromTimeSpan(timeSpan: TimeSpan): SimpleTimeSpan {
        return this.createTimeSpanFromHours(timeSpan.totalHours);
    }

    public static createTimeSpanFromHours(hours: number): SimpleTimeSpan {
        return this.createTimeSpanFromSeconds(hours * 60 * 60);
    }

    private static fallbackToZero(value: number | undefined, higherLevel: number | undefined) {
        if (value !== undefined) {
            return value;
        }
        if (higherLevel !== undefined && higherLevel > 0) {
            return 0;
        }
        return undefined;
    }

    private static createTimeSpanFromSeconds(sec: number): SimpleTimeSpan {

        sec = Math.floor(sec);

        var days = Math.floor(sec / (3600 * 24));
        var hours = Math.floor(sec / 3600) % 24;
        var minutes = Math.floor(sec / 60) % 60
        var seconds = sec % 60

        return {
            seconds,
            minutes,
            hours,
            days
        };
    }

    public static weekNumber(start: Date, current: Date) {
        let num = 0;

        while (start < current) {
            start = new Date(start);
            start.setDate(start.getDate() + 7);
            num++;
        }

        return num;
    }

    public static isWithin24Hours(date: Date) {
        const today = new Date();
        const less24 = new Date(date);
        less24.setDate(less24.getDate() - 1);

        return less24 <= today;
    }

    public static addDays(date: Date, days: number) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    public static addHours(date: Date, hours: number) {
        var result = new Date(date);
        result.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        return result;
    }
}