import { makeAutoObservable } from "mobx";
import { IAuthenticationWorld, IPendingInvitation, ValidationResult, WorldQuandrant, WorldStatus } from "../../../ApplicationState/ApiClient";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { JoinWorldHelper } from "../../../Helpers/JoinWorldHelper";
import { JoinWorldStage } from "./StagesControl";

export type StartLocation = WorldQuandrant | "random" | "byFriend";

export class JoinWorldBag {

    public get IsValid() { return this._isValid; }
    private _isValid: boolean = false;

    public get Error() { return this._error; }
    private _error: ValidationResult | undefined = undefined;

    public get AdminPassword() { return this._adminPassword; }
    private _adminPassword: string | undefined = undefined;

    public get StartLocation() { return this._startLocation; }
    private _startLocation: StartLocation | undefined = undefined;

    public get FactionTypeName() { return this._factionTypeName; }
    private _factionTypeName: string | undefined = undefined;

    public get InvitationId() { return this._invitationId; }
    private _invitationId: number | undefined = undefined;

    public get Invitation() { return this._invitation; }
    private _invitation: IPendingInvitation | undefined = undefined;

    public get World() { return this._world; }
    private _world: IAuthenticationWorld | undefined = undefined;

    public get Worlds() { return this._worlds; }
    private _worlds: IAuthenticationWorld[];

    public get AllowedStages() { return this._allowedStages; }
    private _allowedStages: JoinWorldStage[] = [];

    constructor(worlds: IAuthenticationWorld[]) {
        this._worlds = worlds;
        this.validate();

        makeAutoObservable(this);
    }

    public setWorld(world: IAuthenticationWorld) {

        this._factionTypeName = undefined;
        this._invitationId = undefined;
        this._startLocation = undefined;
        this._adminPassword = undefined;

        this._world = world;

        const factions = Object.keys(this._world.settings.factionTypeSettings.data);
        if (factions.length > 0) {
            const faction = CollectionHelper.randomElement(factions);
            this._factionTypeName = faction;
        }

        this.validate();
    }

    public setFactionTypeName(factionTypeName: string) {
        this._factionTypeName = factionTypeName;
        this.validate();
    }

    public setStartLocation(location: StartLocation) {
        this._startLocation = location;
        this.validate();
    }

    public setAdminPassword(adminPassword: string) {
        this._adminPassword = adminPassword;
        this.validate();
    }

    public setInvitationId(invitationId: number) {
        this._invitationId = invitationId;
        this.validate();
    }

    private validate() {
        let valid = true;

        if (this._world === undefined && this._worlds.length === 1) {
            this._world = this._worlds[0];

            const factions = Object.keys(this._world.settings.factionTypeSettings.data);
            if (factions.length > 0) {
                const faction = CollectionHelper.randomElement(factions);
                this._factionTypeName = faction;
            }
        }

        if (this._world === undefined || this._world.status === WorldStatus.Generating) {
            valid = false;
        }
        if (this._factionTypeName === undefined) {
            valid = false;
        }
        if (this._world !== undefined && this._world.invitations.length > 1 && this._invitationId === undefined) {
            valid = false;
        }
        if (this._world !== undefined && this._world.invitations.length === 1 && this._invitationId === undefined) {
            this._invitationId = this._world.invitations[0].friendInviteId;
        }
        if (this._world !== undefined && this._world.invitations.length > 0 && this._invitationId !== undefined) {
            this._invitation = this._world.invitations.find(x => x.friendInviteId === this._invitationId);
        }
        if (this._startLocation === undefined && this._invitation !== undefined) {
            this._startLocation = "byFriend";
        }

        this._allowedStages = JoinWorldHelper.allowedStages(this);
        this._isValid = valid;
    }

    public setError(error: ValidationResult) {
        this._error = error;
    }
}