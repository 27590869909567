import { Coordinate } from "../Entities/Shared";

export class MathHelper {

    static roundingRanges: number[] = [1, 5, 10, 15, 25, 35, 50];

    public static roundTo(value: number, to: number): number {
        return Math.round(value / to) * to
    }

    public static min(values: number[]): number {
        return Math.min(...values);
    }

    public static max(values: number[]): number {
        return Math.max(...values);
    }

    public static clamp(value: number, min: number, max: number) {
        return value <= min ? min : value >= max ? max : value;
    }

    public static generateTicks(quantity: number, maxValue: number): number[] {
        const values: number[] = new Array(quantity + 1);
        const increment = maxValue / quantity;

        for (let i = 0; i <= quantity; i++) {
            values[i] = increment * i;
        }

        return values;
    }

    public static radiansAngleBetweenPoints(xA: number, yA: number, xB: number, yB: number): number {
        const xD = xA - xB;
        const yD = yA - yB;

        return Math.atan2(yD, xD);
    }

    public static rotatePoint(xCenter: number, yCenter: number, angle: number, x: number, y: number): Coordinate {

        const rotatedX = Math.cos(angle) * (x - xCenter) - Math.sin(angle) * (y - yCenter) + xCenter;
        const rotatedY = Math.sin(angle) * (x - xCenter) + Math.cos(angle) * (y - yCenter) + yCenter;

        return {
            x: rotatedX,
            y: rotatedY
        };
    }

    public static calculateInterceptionPoint(targetPosition: Coordinate, targetVelocity: Coordinate, interceptorPosition: Coordinate, interceptorSpeed: number): Coordinate | undefined {

        interceptorSpeed *= 10;
        targetVelocity = {
            x: targetVelocity.x * 10,
            y: targetVelocity.y * 10,
        };

        const ox = targetPosition.x - interceptorPosition.x;
        const oy = targetPosition.y - interceptorPosition.y;

        const h1 = targetVelocity.x * targetVelocity.x + targetVelocity.y * targetVelocity.y - interceptorSpeed * interceptorSpeed;
        const h2 = ox * targetVelocity.x + oy * targetVelocity.y;
        var t;

        if (h1 == 0) {
            // problem collapses into a simple linear equation 
            t = -(ox * ox + oy * oy) / (2 * h2);
        }
        else {
            // solve the quadratic equation
            const minusPHalf = -h2 / h1;

            const discriminant = minusPHalf * minusPHalf - (ox * ox + oy * oy) / h1; // term in brackets is h3
            if (discriminant < 0) {
                // no (real) solution then...
                return undefined;
            }

            var root = Math.sqrt(discriminant);

            var t1 = minusPHalf + root;
            var t2 = minusPHalf - root;

            var tMin = Math.min(t1, t2);
            var tMax = Math.max(t1, t2);

            // get the smaller of the two times, unless it's negative
            t = tMin > 0 ? tMin : tMax;
            if (t < 0) {
                // we don't want a solution in the past
                return undefined;
            }
        }

        // calculate the point of interception using the found intercept time and return it
        return {
            x: targetPosition.x + t * targetVelocity.x,
            y: targetPosition.y + t * targetVelocity.y
        };
    }

    public static distance(x1: number, y1: number, x2: number, y2: number) {
        const a = x1 - x2;
        const b = y1 - y2;

        return Math.sqrt(a * a + b * b);
    }
}