import '@firebase/messaging';
import firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';
import { DevicePlatform, DeviceRegistration, DevicesClient } from './ApplicationState/NotificationsApiClient';

function initFcm() {
    if (firebase.apps.length === 0) {
        firebase.initializeApp({
            appId: "1:576545065673:web:fb5111346f374a5a881b18",
            apiKey: "AIzaSyCSnWSC8MVgETzyYFpncb5zEYe9qyzdy8E",
            projectId: "fusionshift",
            messagingSenderId: "576545065673"
        });
    }

    if (firebase.messaging.isSupported()) {
        return firebase.messaging();
    }
}

const firebaseCloudMessaging = initFcm();

async function checkRequestFcmPermission(devicesClient: DevicesClient) {
    if (firebaseCloudMessaging && Notification.permission !== 'granted') {
        try {
            await Notification.requestPermission();

            await registerFcmToken(devicesClient);
        }
        catch (_) { }
    }

    return Notification.permission;
}

async function registerFcmToken(devicesClient: DevicesClient) {
    if (firebaseCloudMessaging) {
        const token = await firebaseCloudMessaging.getToken();

        await devicesClient.register(new DeviceRegistration({
            installationId: getOrCreateInstallationId(),
            token: token,
            platform: DevicePlatform.WebBrowser
        }));
    }
}

const notificationsInstallationIdStorageKey = "notificationsInstallationId";

function getOrCreateInstallationId() {
    let installationId = localStorage.getItem(notificationsInstallationIdStorageKey);

    if (installationId === null) {
        installationId = uuid();

        localStorage.setItem(notificationsInstallationIdStorageKey, installationId);
    }

    return installationId;
}

export { firebaseCloudMessaging, checkRequestFcmPermission };

