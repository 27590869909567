import classNames from 'classnames';
import * as React from 'react';

type Props<T> = {
    data: T[],
    isSmall?: boolean,
    hasAddons?: boolean,
    isStriped?: boolean,
    filter?: (x: T) => boolean,
    render: (data: T) => {
        key: string,
        label: React.ReactNode,
        content: React.ReactNode,
        extra?: React.ReactNode[] | undefined
    }
}

export const FormTable = <T extends {}>(props: Props<T>) => {

    const data = props.filter !== undefined ?
        props.data.filter(props.filter) :
        props.data;

    const tableClassName = classNames(
        "form is-fixed",
        props.isStriped && "is-striped"
    );

    return <table className={tableClassName}>
        <tbody>
            {data.map(d => {
                const rendered = props.render(d);
                const className = classNames(
                    {
                        "is-small": props.isSmall,
                        "has-addons": props.hasAddons
                    });

                const extras = rendered.extra?.filter(x => x !== null && x !== undefined && x !== false);

                return <tr key={rendered.key}>
                    <td className={`label ${props.isSmall ? "is-small" : ""}`}>
                        <label className="label">
                            {rendered.label}
                        </label>
                    </td>
                    <td className="is-content-width">
                        <div className={`field ${className}`}>
                            {rendered.content}
                        </div>
                    </td>
                    {extras !== undefined &&
                        extras.map((x, i) => <React.Fragment key={i}>
                            <td className={`${className} is-content-width`}>
                                {x}
                            </td>
                        </React.Fragment>)
                    }
                </tr>;
            })}
        </tbody>
    </table>
};