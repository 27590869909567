import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { HelpSection, HelpType } from "../../ApplicationState/ApiClient";
import { HelpStateContext } from "../../ApplicationState/ContextRoot";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { H2 } from "../../Components/Base/Text/H";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { RichText, RichTextWrapper } from "../../Components/FusionShift/RichText";
import { TypeDataVoice } from "../../Components/FusionShift/TypeDataVoice";
import { IconHelper } from "../../Helpers/IconHelper";
import { TextHelper } from "../../Helpers/TextHelper";
import { SimpleBaseView } from "../BaseView";
import { HelpNavButtons } from "./HelpNavButtons";
import { HelpTitle } from "./HelpTitle";

type CompiledHelp = {
    helpSection: HelpSection,
    subSections: HelpSection[]
};

export const HelpView = observer(() => {

    const helpState = React.useContext(HelpStateContext);

    const { page } = useParams();

    const [helpType, setHelpType] = React.useState<HelpType | undefined>(undefined)
    const [compiledHelp, setCompiledHelp] = React.useState<CompiledHelp[]>([])

    React.useEffect(() => {
        if (helpState.Help && page !== undefined) {
            setHelpType(helpState.Help.find(d => d.typeName.toLowerCase() === page.toLowerCase()));
        }
    }, [page, helpState.Help]);

    React.useEffect(() => {
        if (helpType) {
            const compiled: CompiledHelp[] = [];

            for (let section of helpType.sections) {
                if (section.isSub) {
                    if (compiled.length > 0) {
                        compiled[compiled.length - 1].subSections.push(section)
                    }
                } else {
                    const comp = {
                        helpSection: section,
                        subSections: []
                    };
                    compiled.push(comp);
                }
            }

            setCompiledHelp(compiled);
        }
    }, [helpType]);

    if (!helpState.Help) {
        return <LoadingSpinner />;
    }

    const text = `Help: ${helpType ? TextHelper.tidyText(helpType.name) : "Not Found"}`;

    return <SimpleBaseView
        heading={HelpTitle({ helpType })}
        headingContent={<HelpNavButtons>
            {helpType !== undefined &&
                <TypeDataVoice type="HelpType" typeData={helpType} />
            }
        </HelpNavButtons>}
    >
        {!helpType &&
            <>
                <H2>Not Found</H2>
                <Paragraph type="missing">The help page you are looking for doesn't exist. </Paragraph>
            </>
        }
        {helpType !== undefined && helpType.description && <SubPanel heading={{ text: "Overview", icon: IconHelper.Categories.Summary }}>
            <RichTextWrapper text={helpType.description} />
        </SubPanel>}
        {compiledHelp.length > 0 &&
            compiledHelp.map(h => <SubPanel heading={{ content: <RichText text={h.helpSection.title} /> }}>
                <RichTextWrapper text={h.helpSection.contents} />
                {h.subSections.map(s => <SubPanel heading={{ content: <RichText text={s.title} /> }}>
                    <RichTextWrapper text={s.contents} />
                </SubPanel>
                )}
            </SubPanel>
            )}
    </SimpleBaseView >;
});