import classNames from 'classnames';
import * as React from 'react';
import { BackgroundImage } from '../../../Helpers/BackgroundImageHelper';
import { Icon } from '../Icon';
import { Panel } from './Panel';
import { HeadingContent } from './PanelHeading';

export type TabProps<T> = {
    id: T,
    title: string,
    icons: React.ReactNode | string | undefined,
    isAvailable: boolean,
    idPrefix?: string | undefined
}

type Props<T> = {
    active: T,
    setActive: (active: T) => any
    heading: HeadingContent,
    backgroundImage?: BackgroundImage,
    children: React.ReactNode,
    className?: string | undefined,
    isClipped?: boolean,
    tabs: TabProps<T>[],
    idPrefix?: string | undefined
}

const PanelTabs = <T extends {}>(props: Props<T>) => {

    const tabsToUse = props.tabs.filter(x => x.isAvailable);

    if (tabsToUse.length <= 1) {
        return null;
    }

    return <div className="panel-tabs">
        {tabsToUse.map(x => {

            const className = classNames(
                "panel-tab",
                "is-clickable",
                {
                    "is-active": props.active === x.id
                });

            return <span
                id={`${props.idPrefix !== undefined ? props.idPrefix + "_" : ""}${x.id.toString().toLowerCase()}`}
                key={x.id.toString()}
                className={className}
                onClick={() => props.setActive(x.id)}
            >
                {x.icons !== undefined &&
                    <>
                        {typeof x.icons === "string" && <Icon icon={x.icons} />}
                        {typeof x.icons !== "string" && x.icons}
                    </>
                }
                <span className={x.icons !== undefined ? "is-hidden-touch" : ""} >
                    {x.title}
                </span>
            </span>;
        })}
    </div>;
};

export const PanelWithTabs = <T extends {}>(props: Props<T>) => {

    const className = classNames("has-tabs", props.className);

    return <Panel
        {...props}
        className={className}
        headingContent={<PanelTabs {...props} />}
    />;
};