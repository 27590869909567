import * as React from 'react';
import { Manifest } from '../../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../Components/Base/Containers/Table';

type Props = {
    selectedCategoryName: string,
    setSelectedCategory: (category: string) => any,
    manifest: Manifest
}

export const CategoryChooser = (props: Props) => {

    return <SubPanel heading="Category"
        isUnpadded
        className="category-chooser">
        <Table isHoverable>
            {Object.keys(props.manifest?.categories).map(x => <tr
                key={x}
                onClick={_ => props.setSelectedCategory(x)}>
                <td className={x === props.selectedCategoryName ? "is-selected" : ""}>
                    {x}
                </td>
            </tr>)}
        </Table>
    </SubPanel>;
};