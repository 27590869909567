import { makeAutoObservable } from "mobx";
import Cookies, { CookieSetOptions } from "universal-cookie";

export type RecentSolarSystem = {
    solarSystemId: number,
    name: string
}

export class UserPrefs {

    private _cookieNames = {
        token: "token",
        playerId: "playerId",
        selectedSolarSystemId: "solarSystemId",
        recentSolarSystems: "recentSolarSystems"
    };

    private _cookies: Cookies = new Cookies();
    private createOptions(): CookieSetOptions {
        return {
            path: '/',
            maxAge: 31536000
        };
    }

    private _token: string | undefined;
    public get Token(): string | undefined { return this._token; }

    private _playerId: number | undefined;
    public get PlayerId(): number | undefined { return this._playerId; }

    private _recentSolarSystems: RecentSolarSystem[] | undefined
    public get RecentSolarSystems(): RecentSolarSystem[] | undefined { return this._recentSolarSystems; }

    private _selectedSolarSystemId: number | undefined;
    public get SelectedSolarSystemId(): number | undefined { return this._selectedSolarSystemId; }

    constructor() {
        this._token = this.stringFromCookies(this._cookieNames.token);
        this._playerId = this.numberFromCookies(this._cookieNames.playerId);
        this._selectedSolarSystemId = this.playerNumberFromCookie(this._cookieNames.selectedSolarSystemId);

        makeAutoObservable(this);
    }

    private stringFromCookies(name: string): string | undefined {
        const value = this._cookies.get(name);
        if (value && value.length > 0 && value !== "undefined" && value !== "null") {
            return value;
        }

        return undefined;
    }

    private numberFromCookies(name: string): number | undefined {
        const stringValue = this.stringFromCookies(name);
        const number = Number(stringValue);

        return isNaN(number) ? undefined : number;
    }

    private valueToCookie<T>(name: string, value: T | undefined): T | undefined {
        if (value && typeof value === "string" && value.length === 0) {
            value = undefined;
        }
        if (value) {
            this._cookies.set(name, value, this.createOptions());
        } else {
            this._cookies.remove(name, this.createOptions());
        }
        return value;
    }

    private playerStringFromCookie(name: string): string | undefined {
        if (!this.PlayerId) {
            return undefined;
        }
        return this.stringFromCookies(`${this.PlayerId}_${name}`);
    }

    private playerNumberFromCookie(name: string): number | undefined {
        if (!this.PlayerId) {
            return undefined;
        }
        return this.numberFromCookies(`${this.PlayerId}_${name}`);
    }

    private playerAnyFromCookie(name: string): any | undefined {
        if (!this.PlayerId) {
            return undefined;
        }
        return this._cookies.get(`${this.PlayerId}_${name}`);
    }

    private playerValueToCookie<T>(name: string, value: T | undefined): T | undefined {
        if (!this.PlayerId) {
            return undefined;
        }
        return this.valueToCookie(`${this.PlayerId}_${name}`, value);
    }

    private playerAnyToCookie(name: string, value: any | undefined): any | undefined {
        if (!this.PlayerId) {
            return undefined;
        }
        this._cookies.set(`${this.PlayerId}_${name}`, value, this.createOptions());
        return value;
    }

    public setSelectedSolarSystemId(selectedSolarSystemId: number | undefined) {
        this._selectedSolarSystemId = this.playerValueToCookie(this._cookieNames.selectedSolarSystemId, selectedSolarSystemId);
    }

    public logout() {
        this._token = undefined;
        this._playerId = undefined;

        this._cookies.remove(this._cookieNames.token, this.createOptions());
        this._cookies.remove(this._cookieNames.playerId, this.createOptions());

        this._recentSolarSystems = undefined;
        this._selectedSolarSystemId = undefined;
    }

    public login(token: string) {
        this._token = this.valueToCookie(this._cookieNames.token, token);
    }

    public initForPlayerId(playerId: number) {
        this._playerId = this.valueToCookie(this._cookieNames.playerId, playerId);

        this._selectedSolarSystemId = this.playerNumberFromCookie(this._cookieNames.selectedSolarSystemId);

        const recentSolarSystems = this.playerAnyFromCookie(this._cookieNames.recentSolarSystems);
        if (recentSolarSystems !== null && recentSolarSystems !== undefined) {
            this._recentSolarSystems = recentSolarSystems;
        }
    }

    public addRecentSolarSystem(solarSystemId: number, name: string) {
        if (!this.PlayerId) {
            return;
        }

        let recentSolarSystems = (this.RecentSolarSystems || []);

        recentSolarSystems = recentSolarSystems.filter(s => s.solarSystemId !== solarSystemId);
        recentSolarSystems.unshift({
            solarSystemId: solarSystemId,
            name: name
        });
        recentSolarSystems.splice(10);

        this.playerAnyToCookie(this._cookieNames.recentSolarSystems, recentSolarSystems);
        this._recentSolarSystems = recentSolarSystems;
    }

    public getUserPrefsObject<T>(name: string): T | undefined {
        return this.playerAnyFromCookie(name);
    }

    public getUserPrefsObjectOrDefault<T>(name: string, df: T): T {
        const val = this.playerAnyFromCookie(name);
        if (!!val) {
            return val;
        }
        return df;
    }

    public isUserPrefsObjectSet(name: string) {
        return !!this.playerAnyFromCookie(name);
    }

    public setUserPrefsObject<T>(name: string, value: T | undefined): T | undefined {
        return this.playerAnyToCookie(name, value);
    }

    public isCookieConsentGiven() {
        return this._cookies.get("allowCookies") === "yes";
    }

    public setCookieConsent(consented: boolean) {
        if (consented) {
            this._cookies.set("allowCookies", "yes", {
                path: "/",
                maxAge: 31536000
            });
        } else {
            this._cookies.remove("allowCookies");
        }
    }
}