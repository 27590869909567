import { IItemTypeSettings, ISectorTypeSettings, ItemCategory, ItemType } from "../ApplicationState/ApiClient";
import { ValueFormatter } from "./ValueFormatter";

export class ItemsHelper {

    public static findNumeral(itemTypeSettings: IItemTypeSettings, sectorTypeSettings: ISectorTypeSettings, item: ItemType) {

        let items = Object.values(itemTypeSettings.data)
            .filter(x => x.category === item.category);

        if (item.category === ItemCategory.Commodity) {
            const sector = Object.values(sectorTypeSettings.data)
                .find(x => Object.values(x.demandedItemTypeNames).includes(item.typeName));

            if (sector !== undefined) {
                items = items.filter(x => Object.values(sector.demandedItemTypeNames).includes(x.typeName));
            }
        }

        const base = item.order;

        const lowest = items
            .sort((a, b) => a.order - b.order)
            .find(x => true);

        const localOrder = 1 + (base - (lowest?.order ?? 0));

        return ValueFormatter.toRomanNumeral(localOrder);
    }

    public static buildItems(items: { [key: string]: number }, itemTypes: { [key: string]: ItemType }) {
        return Object.keys(items)
            .filter(c => c in itemTypes)
            .map(c => {
                return {
                    quantity: items[c],
                    itemType: itemTypes[c]
                }
            });
    }

    public static getItemTypeFromName(itemName: string, itemTypes: { [key: string]: ItemType }) {
        for (const itemKey of Object.keys(itemTypes)) {
            const itemType = itemTypes[itemKey];
            if (itemType.name === itemName) {
                return itemType;
            }
        }

        return undefined;
    }

    public static splitItems(items: { [key: string]: number }, itemTypes: { [key: string]: ItemType }) {
        const builtItems = this.buildItems(items, itemTypes);

        return {
            resources: this.filteredDictionary(builtItems, ItemCategory.Resource),
            commodities: this.filteredDictionary(builtItems, ItemCategory.Commodity),
            rawResource: this.filteredDictionary(builtItems, ItemCategory.RawResource),
            components: this.filteredDictionary(builtItems, ItemCategory.Component),
            relics: this.filteredDictionary(builtItems, ItemCategory.Relic)
        }
    }

    private static filteredDictionary(items: { quantity: number; itemType: ItemType; }[], category: ItemCategory) {
        const dictionary: { [key: string]: number } = {};

        for (let item of items.filter(i => i.itemType.category === category)) {
            dictionary[item.itemType.typeName] = item.quantity;
        }

        return dictionary;
    }

    public static buildItemsFancyDetail(
        items: { [key: string]: number; },
        resourceProductionNet: { [key: string]: number; } | undefined,
        resourceCapacities: { [key: string]: number; } | undefined,
        otherCapacities: { [key in keyof typeof ItemCategory]?: number; } | undefined,
        hideIfZero: "hide" | "hide-by-typesettings" | undefined,
        itemTypeSettings: IItemTypeSettings): FancyItem[] {

        const extraKeys = Object.values(itemTypeSettings.data)
            .filter(x => x.category === ItemCategory.Resource)
            .map(x => x.typeName)
            .filter(x => !(x in items));

        const allKeys = [
            ...Object.keys(items),
            ...extraKeys];

        const itemsToUse = allKeys.map(k => {

            const itemType = itemTypeSettings!.data![k];

            const income = resourceProductionNet && k in resourceProductionNet ? resourceProductionNet[k] : 0;
            const balancePerSec = income / (60 * 60);
            const capacity = ItemsHelper.itemTypeCapacity(itemType, resourceCapacities, otherCapacities);

            return {
                itemType,
                quantity: items![k] ?? 0,
                capacity: capacity,
                hideIfZero: hideIfZero === "hide-by-typesettings" ? itemTypeSettings!.data![k].hideIfZero : true,
                balancePerSec: balancePerSec,
                isDanger: this.isDanger(items![k], balancePerSec, capacity),
                isWarning: balancePerSec < 0
            };
        })
            .filter(c => c.quantity > 0 || !c.hideIfZero)
            .sort(((a, b) => a.itemType.order > b.itemType.order ? 1 : -1));;

        return itemsToUse;
    }

    public static expiresInLessThanAnHour(quantity: number, perSec: number) {
        if (perSec < 0) {
            const secondsRemaining = quantity / Math.abs(perSec);
            if (secondsRemaining <= 60 * 60) {
                return true;
            }
        }

        return false;
    }

    public static isDanger(quantity: number, perSec: number, capacity: number) {

        if (this.expiresInLessThanAnHour(quantity, perSec)) {
            return true;
        }

        return quantity >= capacity;
    }

    public static itemTypeCapacity(itemType: ItemType, resourceCapacities: { [key: string]: number; } | undefined, otherCapacities: { [key in keyof typeof ItemCategory]?: number; } | undefined) {

        if (itemType.category === ItemCategory.Resource) {
            if (resourceCapacities !== undefined && itemType.typeName in resourceCapacities) {
                return resourceCapacities[itemType.typeName];
            }
        } else {
            return this.nonResourceItemCategoryCapacity(itemType.category, otherCapacities);
        }

        return 0;
    }

    public static nonResourceItemCategoryCapacity(itemCategory: ItemCategory | undefined, otherCapacities: { [key in keyof typeof ItemCategory]?: number; } | undefined) {

        if (itemCategory !== undefined && otherCapacities !== undefined) {
            if (ItemCategory[itemCategory] in otherCapacities) {
                return otherCapacities[ItemCategory[itemCategory]];
            }
        }

        return 0;
    }
}

export type FancyItem = {
    itemType: ItemType,
    quantity: number,
    capacity: number,
    hideIfZero: boolean,
    balancePerSec: number,
    isDanger: boolean,
    isWarning: boolean
};
