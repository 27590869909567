import { observer } from "mobx-react-lite";
import React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { CommodityUsage } from "../../../../Helpers/CommodityHelper";
import { SectorTypeIcon } from "../SectorTypeIcon";

type Props = {
    commodityType: ItemType | undefined | false,
    usage?: CommodityUsage | undefined,
    hideColon?: boolean | undefined,
    isGiant?: boolean
}

export const CommodityIcon = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!props.commodityType || !worldState.CommodityHelper) {
        return null;
    }

    const usage = !props.usage ? worldState.CommodityHelper.commodityTypeUsage(props.commodityType.typeName) : props.usage;

    if (!usage) {
        return null;
    }

    return <SectorTypeIcon sectorTypeName={usage.sectorType.typeName} isGiant={props.isGiant} />;
});