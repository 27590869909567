import * as React from 'react';
import { ISolarSystem } from '../../../../../ApplicationState/ApiClient';
import { Table } from '../../../../../Components/Base/Containers/Table';

export type ExistingShipsProps = {
    solarSystem: ISolarSystem | undefined,
    existingPresent: number,
    existingMoving: number,
    existingGrounded: number,
    existingBuilding: number,
    existingActivating: number,
};

type LazyRowProps = {
    quantity: number,
    name: string
}

const LazyRow = (props: LazyRowProps) => {

    if (props.quantity <= 0) {
        return null;
    }

    return <tr>
        <td>{props.name}</td>
        <td>{props.quantity}</td>
    </tr>
}

export const ExistingShips = (props: ExistingShipsProps) => {

    if (props.existingActivating + props.existingGrounded + props.existingMoving + props.existingPresent + props.existingBuilding === 0) {
        return null;
    }

    return <Table className="is-pulled-right" heading={
        <>
            <th colSpan={2}>
                Existing Ships
                {props.solarSystem && <span className="is-hidden-mobile">
                    {" "}at {props.solarSystem.name}
                </span>}
            </th>
        </>
    }>
        <LazyRow quantity={props.existingPresent} name="Stationed" />
        <LazyRow quantity={props.existingMoving} name="Moving" />
        <LazyRow quantity={props.existingBuilding} name="Building" />
        <LazyRow quantity={props.existingActivating} name="Activating" />
        <LazyRow quantity={props.existingGrounded} name="Grounded" />
    </Table>;
}