import { AgentStatus, FleetMovementType, IAgent, ISolarSystem } from '../../../ApplicationState/ApiClient';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';

type Props = {
    agent: IAgent,
    targetSolarSystem: ISolarSystem | undefined,
    playerId: number,
    movementType: FleetMovementType
}

export const AgentMovementOutcome = (props: Props) => {

    const agent = props.agent;

    if (agent.status === AgentStatus.Captured) {
        if (props.targetSolarSystem?.playerId === agent.playerId) {
            return <>
                Will be released to <PlayerLink player={props.targetSolarSystem.owner} />
            </>
        }
        if (agent.status === AgentStatus.Captured && props.targetSolarSystem?.playerId !== props.playerId && props.targetSolarSystem) {
            return <>
                Will become a prisoner of <PlayerLink player={props.targetSolarSystem.owner} />
            </>;
        }
        if (props.targetSolarSystem !== undefined) {
            return <>Will remain a prisoner at <SolarSystemLink solarSystem={props.targetSolarSystem} hideActionIcons /></>;
        }
        return <>Will remain a prisoner</>;
    }
    if (props.movementType === FleetMovementType.Attack) {
        if (props.targetSolarSystem !== undefined) {
            return <>Will become a covert agent at <SolarSystemLink solarSystem={props.targetSolarSystem} hideActionIcons /> if the attack is successful. <span className="has-text-warning">Will be captured if the attack fails.</span></>;
        }
        return <>Will become a covert agent if the attack is successful. <span className="has-text-warning">Will be captured if the attack fails.</span></>;
    }
    if (props.movementType === FleetMovementType.Delivery) {
        if (props.targetSolarSystem !== undefined) {
            return <>Will become a diplomatic agent at <SolarSystemLink solarSystem={props.targetSolarSystem} hideActionIcons /></>;
        }
        return <>Will become a diplomatic agent</>;
    }
    if (props.movementType === FleetMovementType.Collection) {
        if (props.targetSolarSystem !== undefined) {
            return <>Will be collected and returned to <SolarSystemLink solarSystem={props.targetSolarSystem} hideActionIcons /></>;
        }
        return <>Will be collected</>;
    }

    return null;
};