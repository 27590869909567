import { Link } from 'react-router-dom';
import { IWorldEffects, ShipType } from '../../../ApplicationState/ApiClient';
import { ActiveEffectsHelper } from '../../../Helpers/ActiveEffectsHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { politics_home } from '../../../Navigation/Routing/Politics';
import { Paragraph } from '../../Base/Text/Paragraph';

type Props = {
    wrapped?: boolean,
    modifier: number | undefined
}

export const CostModifiedLink = (props: Props) => {
    if (props.modifier === undefined || props.modifier === 0) {
        return null;
    }

    const content = <>This cost is {ValueFormatter.fromModifierToDisplayPercentLonghand(props.modifier)} from <Link to={politics_home}>active policies</Link></>;

    return props.wrapped ? <Paragraph>{content}</Paragraph> : content;
};


type ShipClassProps = {
    wrapped?: boolean,
    shipType: ShipType,
    worldEffects: IWorldEffects
}

export const ShipCostModifiedLink = (props: ShipClassProps) => {
    const modifier = ActiveEffectsHelper.rawCostModifierForShipClass(props.worldEffects, props.shipType.class);
    return <CostModifiedLink modifier={modifier} {...props} />;
};