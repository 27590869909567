import * as React from 'react';
import { help_factionTypes } from '../../../Navigation/Routing/Help';
import { ImageSize, SvgImage } from './SvgImage';

type Props = {
  factionTypeName: string,
  size?: ImageSize,
  onlyLogo?: boolean,
  disableLink?: boolean
}

export const FactionTypeImage = (props: Props) => {

  const filename = props.onlyLogo ? `${props.factionTypeName}_2` : `${props.factionTypeName}_1`;

  return <SvgImage type="faction" filename={filename} size={props.size} to={props.disableLink ? undefined : help_factionTypes} />;
};