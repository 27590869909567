import * as React from 'react';
import { H4 } from '../../Components/Base/Text/H';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { INotification } from "../ApiClient";

type Props = {
    notification: INotification
}

export const NotificationRender = (props: Props) => {

    return <div className="notification-render">
        <H4>{props.notification.title}</H4>
        <Paragraph>
            {props.notification.body}
        </Paragraph>
    </div>;
}