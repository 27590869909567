import classNames from 'classnames';
import * as React from 'react';
import { QuadrantPlayerCount, WorldQuandrant } from '../../../ApplicationState/ApiClient';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    playerCounts: QuadrantPlayerCount,
    selectedWorldQuadrant: WorldQuandrant | undefined,
    setQuadrant: (quadrant: WorldQuandrant | undefined) => any
}

type QuadrantProps = {
    playerCounts: QuadrantPlayerCount,
    worldQuadrant: WorldQuandrant,
    selectedWorldQuadrant: WorldQuandrant | undefined,
    setQuadrant: (quadrant: WorldQuandrant | undefined) => any
}

const Quadrant = (props: QuadrantProps) => {

    const toQuadrant = WorldQuandrant[props.worldQuadrant];
    const className = classNames(
        "quadrant",
        "is-clickable",
        {
            "selected": props.selectedWorldQuadrant === props.worldQuadrant,
            "quadrant-bottom": props.worldQuadrant === WorldQuandrant.BottomLeft || props.worldQuadrant === WorldQuandrant.BottomRight,
            "quadrant-top": props.worldQuadrant === WorldQuandrant.TopLeft || props.worldQuadrant === WorldQuandrant.TopRight,
            "quadrant-left": props.worldQuadrant === WorldQuandrant.BottomLeft || props.worldQuadrant === WorldQuandrant.TopLeft,
            "quadrant-right": props.worldQuadrant === WorldQuandrant.TopRight || props.worldQuadrant === WorldQuandrant.BottomRight,
        });

    const players = toQuadrant in props.playerCounts.players ? props.playerCounts.players[toQuadrant] : 0;
    const icon = props.selectedWorldQuadrant === props.worldQuadrant ? IconHelper.Categories.Home : IconHelper.General.Up;

    return <div className={className} onClick={_ => props.setQuadrant(props.worldQuadrant)}>
        <span>{ValueFormatter.spacesBeforeCapitals(toQuadrant)}</span>
        <span className={`mdi mdi-${icon}`}></span>
        <span>{players} Players</span>
    </div>;
}

export const QuadrantSummary = (props: Props) => {

    const selectorClassName = classNames(
        "is-clickable",
        "selector",
        {
            "none-selected": props.selectedWorldQuadrant === undefined
        });

    return <div className="quadrants">
        <Quadrant worldQuadrant={WorldQuandrant.TopLeft} {...props} />
        <Quadrant worldQuadrant={WorldQuandrant.TopRight} {...props} />
        <Quadrant worldQuadrant={WorldQuandrant.BottomLeft} {...props} />
        <Quadrant worldQuadrant={WorldQuandrant.BottomRight} {...props} />
        <div className={selectorClassName} onClick={_ => props.setQuadrant(undefined)}>
            <div className="inner">
                <span className={`mdi mdi-${IconHelper.General.Random}`} />
            </div>
            <div className="outer"></div>
        </div>
    </div>;
};