import { FleetMovementType, IFleet, IPlayerMinimal } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { InterceptFleetButton } from '../../../Components/FusionShift/Buttons/InterceptFleetButton';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    player: IPlayerMinimal,
    fleet: IFleet,
    availableShips: FleetWrapper,

    isSendHomeExpanded: boolean,
    setIsSendHomeExpanded: (isSendHomeExpanded: boolean) => any,
    sendHome: () => any,

    isJoinSolarSystemExpanded: boolean,
    setIsJoinSolarSystemExpanded: (isSendHomeExpanded: boolean) => any

    hasDetailExpand: boolean,
    isDetailExpanded: boolean,
    setIsDetailExpanded: (isDetailExpanded: boolean) => any
}

export const FleetButtons = (props: Props) => {

    const className = "is-small is-pulled-right";
    const hasArrived = FleetHelper.isArrivedAndPresentInSolarSystem(props.fleet);

    return <ButtonHolder>
        {FleetHelper.canReinforceJoinSystem(props.fleet, props.player.playerId) &&
            <Button type="nav" hideTextOnMobile className={className} icon={IconHelper.Ships.movementType(FleetMovementType.Rebase)} action={() => props.setIsJoinSolarSystemExpanded(!props.isJoinSolarSystemExpanded)} title="Join solar system" />
        }
        {FleetHelper.canSendHome(props.fleet, props.player.playerId) &&
            <>
                {(!hasArrived || props.fleet.movementType !== FleetMovementType.Reinforce || !CollectionHelper.isAnyQuantityInDictionary(props.fleet.ships)) &&
                    <Button type="warning" hideTextOnMobile className={className} icon={IconHelper.Ships.movementType(FleetMovementType.ReturnHome)} action={() => props.sendHome()} confirm="Are you sure you want to send this fleet home?" title="Call home" />
                }
                {hasArrived && props.fleet.movementType === FleetMovementType.Reinforce && CollectionHelper.isAnyQuantityInDictionary(props.fleet.ships) &&
                    <Button type="nav" hideTextOnMobile className={className} icon={IconHelper.Ships.movementType(FleetMovementType.ReturnHome)} action={() => props.setIsSendHomeExpanded(!props.isSendHomeExpanded)} title="Call home" />
                }
            </>
        }
        <InterceptFleetButton className={className} availableShips={props.availableShips} targetFleet={props.fleet} />
        {props.hasDetailExpand &&
            <ExpandButton className={className} isExpanded={props.isDetailExpanded} setIsExpanded={props.setIsDetailExpanded} />
        }
    </ButtonHolder>;
};