import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CelestialSize, FleetMovementType, ICelestialTypeSettings, IGameSettings, IItemTypeSettings, IRepeatEditMultipleShipsInput, IRepeatFleet, IShipTypeSettings, ISolarSystem, ISolarSystemDetail, RepeatEditMultipleShipsInput, ShipClass, SolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { CancelButton } from '../../../Components/Base/Buttons/CancelButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { TextAbbreviator } from '../../../Components/Base/Text/TextAbbreviator';
import { TextAndIcon } from '../../../Components/Base/Text/TextAndIcon';
import { HoursIcon } from '../../../Components/FusionShift/Icons/HoursIcon';
import { ItemTypeIcon } from '../../../Components/FusionShift/Icons/Items/ItemTypeIcon';
import { ShipTypeLink } from '../../../Components/FusionShift/Links/ShipTypeLink';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ShipTabs } from '../FleetControl';

type Props = {
    wrapper?: SolarSystemWrapper,
    solarSystem: ISolarSystemDetail,
    fleets: IRepeatFleet[],
    reloadCallback: (solarSystem: SolarSystemDetail, goToTab?: ShipTabs | undefined) => any,
    cancelCallback: () => any
}

type RowProps = {
    solarSystem: ISolarSystem,
    shipTypeSettings: IShipTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    gameSettings: IGameSettings,
    fleet: IRepeatFleet,
    ships: { [key: string]: number },
    shipsOrder: string[],
    setShips: (repeatFleetId: number, ships: { [key: string]: number }) => any
}

const Row = (props: RowProps) => {

    function edit(shipTypeName: string, value: number) {

        props.ships[shipTypeName] = value;

        props.setShips(props.fleet.repeatFleetId, props.ships);
    }

    const itemType = CelestialHelper.itemTypeFromCelestial(props.celestialTypeSettings, props.itemTypeSettings, props.fleet.targetCelestial);

    const duration = React.useMemo(() => {

        const target = props.fleet.targetSolarSystem;

        if (target === undefined ||
            props.fleet.ships === undefined) {
            return undefined;
        }

        const fleetWrapper = new FleetWrapper(props.shipTypeSettings, props.gameSettings, props.fleet.ships)

        return FleetHelper.estimateHoursJourneyDuration(fleetWrapper.SolarSystemsPerHour, props.solarSystem, target, props.gameSettings);
    }, [props.ships, props.shipTypeSettings, props.fleet, props.solarSystem]);

    return <tr>
        <td>
            {props.fleet.targetSolarSystem !== undefined && <SolarSystemLink solarSystem={props.fleet.targetSolarSystem} hideActionIcons />}
        </td>
        <td>
            <TextAndIcon
                noWrap
                icon={itemType !== undefined && <ItemTypeIcon itemType={itemType} />}
                text=
                {props.fleet.targetCelestial !== undefined && <TextAbbreviator
                    text={CelestialSize[props.fleet.targetCelestial.size]}
                    length={1}
                    mode='substring'
                />}
            />
        </td>
        <td>
            {duration !== undefined && <HoursIcon quantity={1 + (2 * duration)} alwaysCompact />}
        </td>
        {props.shipsOrder.map(x => <td key={x}>
            <NumberInput
                size={1}
                required={false}
                value={props.ships[x]}
                valueChanged={v => edit(x, v)}
            />
        </td>)}
    </tr>
}

export const EditRepeatMinersForm = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [repeatFleetShips, setRepeatFleetShips] = React.useState(shipsFromProps());
    const [totals, setTotals] = React.useState<{ [key: string]: number }>({});

    const miningShipTypes = React.useMemo(() => {

        if (worldState.ShipTypeSettings && playerState.Player) {

            return Object.values(worldState.ShipTypeSettings.data)
                .filter(x => x.factionTypeName === playerState.Player?.factionTypeName && (x.class === ShipClass.MiningBarge || x.class === ShipClass.AdvancedMiningBarge))
                .sort((a, b) => a.order - b.order);
        }

        return [];

    }, [worldState.ShipTypeSettings, playerState.Player]);

    React.useEffect(() => {
        updateTotals();
    }, [repeatFleetShips]);

    function updateTotals() {

        const val: { [key: string]: number } = {};

        for (const repeat of Object.values(repeatFleetShips)) {
            for (const key of Object.keys(repeat)) {
                if (key in val) {
                    val[key] += repeat[key];
                } else {
                    val[key] = repeat[key];
                }
            }
        }

        setTotals(val);
    }

    if (!worldState.ShipTypeSettings || !worldState.CelestialTypeSettings || !worldState.ItemTypeSettings || !playerState.Player || !worldState.GameSettings) {
        return null;
    }

    function shipsFromProps() {
        const ships: { [key: number]: { [key: string]: number } } = {};

        for (let fleet of props.fleets) {
            if (fleet.movementType === FleetMovementType.Mine && fleet.ships !== undefined) {
                ships[fleet.repeatFleetId] = fleet.ships;
            }
        }

        return ships;
    }

    function save() {

        const input: IRepeatEditMultipleShipsInput = {
            repeatFleetShips
        };

        return apiState.FleetClient.editMultipleRepeatShips(props.solarSystem.solarSystemId, new RepeatEditMultipleShipsInput(input)).then(x => {
            props.reloadCallback(x, "Repeats");
            props.cancelCallback();
        });
    }

    function edit(repeatFleetId: number, ships: { [key: string]: number }) {
        repeatFleetShips[repeatFleetId] = ships;

        setRepeatFleetShips(repeatFleetShips);

        updateTotals();
    }

    const shipsOrder = miningShipTypes.map(x => x.typeName);

    return <Table numberOfColumns={4 + miningShipTypes.length} isFullWidth heading={
        <>
            <th />
            <th />
            <th />
            {miningShipTypes.map(x => <th key={x.typeName} className="has-text-centered">
                <ShipTypeLink shipType={x} />
            </th>)}
        </>
    }
        footer={
            <ButtonHolder isPulledRight>
                <CancelButton action={props.cancelCallback} />
                <Button type="action" text="Save" icon={IconHelper.General.Confirm} action={save} />
            </ButtonHolder>
        }
    >
        {props.solarSystem.availableShips !== undefined && <tr>
            <td colSpan={2} className="has-text-weight-bold">Available in system</td>
            <td className="has-text-weight-bold">
                Total
            </td>
            {shipsOrder.map(x => <td key={x} className="has-text-centered">
                {props.wrapper !== undefined && x in props.wrapper.systemMiners && <>
                    {ValueFormatter.formatLocaleNumber(props.wrapper.systemMiners[x].has)}
                    {props.wrapper.systemMiners[x].rebasing > 0 && <>
                        &nbsp;+ {ValueFormatter.formatLocaleNumber(props.wrapper.systemMiners[x].rebasing)}
                    </>}
                </>
                }
            </td>)}
        </tr>}
        {Object.keys(repeatFleetShips).map(x => <Row key={x}
            fleet={props.fleets.find(y => y.repeatFleetId === Number(x))!}
            ships={repeatFleetShips[x]}
            shipsOrder={shipsOrder}
            setShips={edit}
            solarSystem={props.solarSystem}
            shipTypeSettings={worldState.ShipTypeSettings!}
            celestialTypeSettings={worldState.CelestialTypeSettings!}
            itemTypeSettings={worldState.ItemTypeSettings!}
            gameSettings={worldState.GameSettings!}
        />)}
        <tr>
            <td />
            <td />
            <td />
            {miningShipTypes.map(x => <td key={x.typeName} className="has-text-centered">
                {x.typeName in totals ? ValueFormatter.formatLocaleNumber(totals[x.typeName]) : 0}
            </td>)}
        </tr>
    </Table>;
});