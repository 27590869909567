import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { TabMenuWithChildren } from "../../../Components/Base/Containers/TabMenu";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../../Helpers/IconHelper";
import { help_page } from "../../../Navigation/Routing/Help";
import { SimpleBaseView } from "../../BaseView";
import { HelpNavButtons } from "../HelpNavButtons";
import { HelpTitle } from "../HelpTitle";
import { CelestialList } from "./InstallationCelestials/CelestialList";
import { InstallationTable } from "./InstallationCelestials/InstallationTable";

export const InstallationCelestialsView = observer(() => {

    const worldState = React.useContext(WorldStateContext);
    const [tab, setTab] = React.useState<"celestial" | "installations">("installations");

    if (worldState.CelestialTypeSettings === undefined || worldState.InstallationTypeSettings === undefined) {
        return <LoadingSpinner />;
    }

    const tabs = [
        {
            id: "installations",
            title: "Installations",
            icons: IconHelper.Installations.Installation,
            isAvailable: true
        },
        {
            id: "celestial",
            title: "Celestials",
            icons: IconHelper.Celestials.Habitable,
            isAvailable: true
        }
    ];

    return <SimpleBaseView
        heading={HelpTitle({ text: "Installation Celestials", icon: IconHelper.Installations.Installation })}
        headingContent={<HelpNavButtons />}
    >
        <SubPanel heading={{ text: "Overview", icon: IconHelper.Categories.Summary }}>
            <Paragraph>
                Different celestials support the construction of different installations. See the <Link to={help_page("installations")}>Installations</Link> help page for more information.
            </Paragraph>
        </SubPanel>
        <TabMenuWithChildren
            active={tab}
            tabs={tabs}
            changeTab={setTab}>
            {tab === "installations" && <InstallationTable celestialTypeSettings={worldState.CelestialTypeSettings!} installationTypeSettings={worldState.InstallationTypeSettings} />}
            {tab === "celestial" && <CelestialList celestialTypeSettings={worldState.CelestialTypeSettings!} installationTypeSettings={worldState.InstallationTypeSettings} />}
        </TabMenuWithChildren>
    </SimpleBaseView>;
});