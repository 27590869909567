import * as React from 'react';

type Props = {
    value: Date,
    valueChanged: (date: Date) => any
}

export const DateTimeInput = (props: Props) => {

    function onChanged(value: string) {
        props.valueChanged(new Date(value));
    }

    return <input
        className="datetime-picker"
        type="datetime-local"
        value={props.value.toISOString().replace(".000Z", "")}
        onChange={e => onChanged(e.target.value)}
    />;
};