import * as React from 'react';
import { Link } from "react-router-dom";
import { MobileMenuStateContext } from "../../ApplicationState/ContextRoot";
import { map_tactical } from "../Routing/Map";
import { empire } from "../Routing/Misc";

type Props = {
    showEmpire: boolean
}

export const LinksTray = (props: Props) => {

    const mobileMenuState = React.useContext(MobileMenuStateContext);

    return <div className="links-tray" onClick={_ => mobileMenuState.close()}>
        {props.showEmpire &&
            <><Link to={empire}>Empire</Link> |</>
        }
        <Link to={map_tactical}>Map</Link>
    </div >;
};