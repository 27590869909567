import { ContractType } from '../../../ApplicationState/ApiClient';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    contractType: ContractType
}

const attack = [
    "Request that a target solar system is attacked",
    "Your payment is taken up front",
    "Only one player may accept",
    "The contractor is paid when the fleet is dispatched",
    "The contractor is paid even if the attack is defeated",
    "The contractor is paid even if the attack is intercepted",
    "You will receieve a copy of the attacker's combat report",
    "Your payment will be refunded if the contract is not completed before the chosen date",
    "You can withdraw the contract for a refund if no one has accepted it"
];

const recon = [
    "Request that a target solar system is reconned",
    "Your payment is taken up front",
    "Many players can attempt the contract but only the first player to succesfully recon the target is paid",
    "You will receieve a copy of the successful combat report",
    "Your payment will be refunded if the contract is not completed before the chosen date",
    "You can withdraw the contract for a refund if no one has accepted it"
];

const defend = [
    "Request that a target solar system is reinforced",
    "Your payment is taken up front",
    "Many players can accept the contract and are paid proportionally to the defence they provide",
    "Contractors are paid when their fleets are dispatched",
    "Contractors are paid even if their fleets is intercepted",
    "Contractors are paid even if their fleets are destroyed in defence",
    "Fleets on contract will defend against allied attackers",
    "Your payment will be refunded if no defenders arrive before the start date",
    "All defenders will be sent home at the end date",
    "You will be refunded any remaining balance at the end date",
    "You can withdraw the contract for a refund if no one has accepted it"
];

const courier = [
    "Request that goods are moved from one of your solar systems to another",
    "No payment is taken up front (except the processing fee)",
    "Many players can attempt the contract",
    "Contractors are paid upon collection of the items from the source system",
    "Contractor payment is limited by your available credits, the items in the source system, the cargo space of the collecting fleet and the quantity remaining on the contract at the time of collection",
    "The contract can be configured to periodically refresh the quantity to be transported",
    "Contractors can configure repeat fleets",
    "You can withdraw the contract at any time but any fleets en-route to the source will finish their journey"
];

const bribeForPolicy = [
    "Request that a player votes for a policy in a given way",
    "Your payment is taken up front",
    "A specific player must be assigned the contract",
    "If the player accepts then their vote is cast immediately",
    "If the player accepts then they can't change their vote",
    "If the player accepts then they can accept another bribe for the same policy, which will issue you with a refund",
    "You will be refunded if the player declines or does not accept by the end of voting",
    "You can withdraw the contract for a refund if the assigned player has not accepted it"
];

const bribeForManifesto = [
    "Request that a player votes for a presidential manifesto",
    "Your payment is taken up front",
    "A specific player must be assigned the contract",
    "If the player accepts then their vote is cast immediately",
    "If the player accepts then they can't change their vote",
    "If the player accepts then they can accept another bribe in the same presidential term, which will issue you with a refund",
    "You will be refunded if the player declines or does not accept by the end of voting",
    "You can withdraw the contract for a refund if the assigned player has not accepted it"
];

const ransom = [
    "Offer to sell a captured agent",
    "The contract can be offered to players other than the owner of the agent",
    "You will be paid the ransom upon collection of the agent from the target system",
    "The contract is completed upon collection of the agent from the target system",
    "You can can withdraw the contract if it has not been completed"
];

export const ContractTypeSpecificsForOwner = (props: Props) => {

    function getSpecifics() {
        switch (props.contractType) {
            case ContractType.Attack:
                return attack;
            case ContractType.Recon:
                return recon;
            case ContractType.Defend:
                return defend;
            case ContractType.Courier:
                return courier;
            case ContractType.BribeForManifesto:
                return bribeForManifesto;
            case ContractType.BribeForPolicy:
                return bribeForPolicy;
            case ContractType.Ransom:
                return ransom;
        }
    }

    const specifics = getSpecifics();

    return <SubPanel
        heading={{ text: ValueFormatter.friendlyNameForContractType(props.contractType), icon: IconHelper.Economy.contractType(props.contractType) }}
    >
        <div className="content">
            <ul>
                {specifics.map((x, i) => <li key={`${props.contractType}_${i}`}>
                    {x}
                </li>)}
            </ul>
        </div>
    </SubPanel>;
}