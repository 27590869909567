import classNames from "classnames";
import * as React from 'react';
import { Icon } from "./Icon";
import { Paragraph } from "./Text/Paragraph";

export function stepWithIcon<T>(id: T, icon: string, heading?: React.ReactNode | undefined, description?: React.ReactNode | undefined): StepData<T> {
    return {
        id,
        title: <Icon icon={icon} />,
        heading,
        description
    };
};

export type StepData<T> = {
    id: T,
    title: React.ReactNode,
    heading?: React.ReactNode | undefined,
    description?: React.ReactNode | undefined
};

type StepProps<T> = StepData<T> & {
    isActive: boolean,
    onClick?: (() => any) | undefined
};

type Props<T> = {
    className?: string,
    currentStep?: T | undefined,
    steps: StepData<T>[],
    allowSkippingToFutureSteps?: boolean,
    onStepClicked?: ((step: T) => any) | undefined,
    forceAllActive?: boolean,
    isMobileHorizontal?: boolean
};

const Step = <T extends {}>(props: StepProps<T>) => {

    const segmentClasses = classNames("steps-segment",
        {
            "is-active": props.isActive
        });

    const markerClasses = classNames("steps-marker",
        {
            "is-clickable": props.onClick !== undefined
        });

    return <li className={segmentClasses}>
        <span className={markerClasses} onClick={props.onClick}>
            {props.title}
        </span>
        {(props.heading !== undefined || props.description !== undefined) &&
            <div className="steps-content">
                {props.heading !== undefined && <Paragraph className="is-size-6">{props.heading}</Paragraph>}
                {props.description !== undefined && <Paragraph>{props.description}</Paragraph>}
            </div>}
    </li>;
};

export const Steps = <T extends {}>(props: Props<T>) => {

    if (props.steps.length === 0) {
        return null;
    }

    const anyContent = props.steps.find(x => x.description !== undefined || x.heading !== undefined) !== undefined;

    const classes = classNames("steps",
        props.className,
        {
            "has-content-centered": anyContent,
            "force-all-active": props.forceAllActive,
            "is-horizontal": props.isMobileHorizontal
        });

    const indexOfActive = props.steps.findIndex(s => s.id === props.currentStep);

    return <ul className={classes}>
        {props.steps.map((s, i) => <Step
            key={i}
            isActive={i === indexOfActive}
            onClick={
                (i < indexOfActive || props.allowSkippingToFutureSteps) &&
                    props.onStepClicked !== undefined ?
                    () => props.onStepClicked!(s.id)
                    : undefined
            }
            {...s} />)}
    </ul>;
};