import { CombatReportRound, CombatRoundType, CombatStats, IShipTypeSettings } from "../../../ApplicationState/ApiClient";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../Components/Base/Containers/Table";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Ships } from "./Ships";

type Props = {
    shipTypeSettings: IShipTypeSettings,
    combatRound: CombatReportRound,
    number: number
}

type StatsProps = {
    title: string,
    combatStats: CombatStats
}

const CombatStatsRow = (props: StatsProps) => {

    return <tr>
        <td>
            {props.title}
        </td>
        <td>
            {ValueFormatter.formatLocaleNumber(props.combatStats.baseStrength)}
        </td>
        <td className={props.combatStats.totalBonusPercent < 0 ? "has-text-danger" : props.combatStats.totalBonusPercent > 0 ? "has-text-success" : undefined}>
            {ValueFormatter.fromDecimalToDisplayPercent(props.combatStats.totalBonusPercent, false, 0)}
        </td>
        <td>
            {ValueFormatter.formatLocaleNumber(props.combatStats.reinforcementStrength)}
        </td>
        <td>
            {ValueFormatter.formatLocaleNumber(props.combatStats.installationStrength)}
        </td>
        <td>
            {ValueFormatter.fromDecimalToDisplayPercentAsModifier(props.combatStats.totalModifierPercent, false, 0)}
        </td>
        <td>
            {ValueFormatter.formatLocaleNumber(props.combatStats.totalStrength)}
        </td>
    </tr>;
};

const CombatReportStatsTableHorizontal = (props: Props) => {
    return <Table isHoverable isFullWidth className="is-hidden-mobile" heading={
        <>
            <td></td>
            <td>Base Strength</td>
            <td>Bonus</td>
            <td>Reinforcements</td>
            <td>Installations</td>
            <td>Modifier</td>
            <td>Total</td>
        </>
    }>
        <CombatStatsRow title="Attacker" combatStats={props.combatRound.attackerStats} />
        <CombatStatsRow title="Defender" combatStats={props.combatRound.defenderStats} />
    </Table>;
};


const CombatReportStatsTableVertical = (props: Props) => {
    return <Table isHoverable isFullWidth className="is-hidden-tablet" heading={
        <>
            <td></td>
            <td>Attacker</td>
            <td>Defender</td>
        </>
    }>
        <tr>
            <td>Base Strength</td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.attackerStats.baseStrength)}
            </td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.defenderStats.baseStrength)}
            </td>
        </tr>
        <tr>
            <td>Bonus</td>
            <td>
                {ValueFormatter.fromDecimalToDisplayPercent(props.combatRound.attackerStats.totalBonusPercent, false, 0)}
            </td>
            <td>
                {ValueFormatter.fromDecimalToDisplayPercent(props.combatRound.defenderStats.totalBonusPercent, false, 0)}
            </td>
        </tr>
        <tr>
            <td>Reinforcements</td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.attackerStats.reinforcementStrength)}
            </td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.defenderStats.reinforcementStrength)}
            </td>
        </tr>
        <tr>
            <td>Installations</td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.attackerStats.installationStrength)}
            </td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.defenderStats.installationStrength)}
            </td>
        </tr>
        <tr>
            <td>Modifier</td>
            <td>
                {ValueFormatter.fromDecimalToDisplayPercentAsModifier(props.combatRound.attackerStats.totalModifierPercent, false, 0)}
            </td>
            <td>
                {ValueFormatter.fromDecimalToDisplayPercentAsModifier(props.combatRound.defenderStats.totalModifierPercent, false, 0)}
            </td>
        </tr>
        <tr>
            <td>Total</td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.attackerStats.totalStrength)}
            </td>
            <td>
                {ValueFormatter.formatLocaleNumber(props.combatRound.defenderStats.totalStrength)}
            </td>
        </tr>
    </Table>;
};

export const Round = (props: Props) => {

    const attackerRows = props.combatRound.attackingShips ? Object.keys(props.combatRound.attackingShips).length : 0;
    const defenderRows = props.combatRound.defendingShips ? Object.keys(props.combatRound.defendingShips).length : 0;

    const text = `Round ${props.number}${props.combatRound.type === CombatRoundType.Grounded ? " - Against Grounded Ships" : ""}`;

    return <SubPanel heading={text}>
        <CombatReportStatsTableHorizontal {...props} />
        <CombatReportStatsTableVertical {...props} />
        <SplitLayout
            left={
                <Ships matchRowsTo={defenderRows} ships={props.combatRound.attackingShips} shipTypeSettings={props.shipTypeSettings} isAttacker />
            }
            right={
                <Ships matchRowsTo={attackerRows} ships={props.combatRound.defendingShips} shipTypeSettings={props.shipTypeSettings} isAttacker={false} />
            }
        />
    </SubPanel>;
};
