import * as React from 'react';
import { PlayerNotificationHint } from '../../ApplicationState/ApiClient';
import { NavButton } from '../../Components/Base/Buttons/NavButton';
import { IconHelper } from '../../Helpers/IconHelper';
import { agentById, agents } from '../../Navigation/Routing/Agents';
import { contractById, contracts } from '../../Navigation/Routing/Contracts';
import { federations_own } from '../../Navigation/Routing/Federation';
import { politics_home } from '../../Navigation/Routing/Politics';

type Props = {
    hint: PlayerNotificationHint,
    hintReference?: string | undefined
}

export const Hint = (props: Props) => {

    if (props.hint === PlayerNotificationHint.FederationInvitation) {
        return <NavButton className="is-pulled-right" icon={IconHelper.General.Friend} to={federations_own} text="View Invitation" />;
    }
    if (props.hint === PlayerNotificationHint.Politics) {
        return <NavButton className="is-pulled-right" icon={IconHelper.Politics.Politics} to={politics_home} text="Politics" />;
    }
    if (props.hint === PlayerNotificationHint.Contract) {

        const contractId = Number(props.hintReference);

        if (!isNaN(contractId)) {
            return <NavButton className="is-pulled-right" icon={IconHelper.Economy.Contract} to={contractById(contractId)} text="View Contract" />;
        }
        return <NavButton className="is-pulled-right" icon={IconHelper.Economy.Contract} to={contracts} text="Contracts" />;
    }

    if (props.hint === PlayerNotificationHint.Agent) {

        const agentId = Number(props.hintReference);

        if (!isNaN(agentId)) {
            return <NavButton className="is-pulled-right" icon={IconHelper.Agents.Agent} to={agentById(agentId)} text="View Agent" />;
        }
        return <NavButton className="is-pulled-right" icon={IconHelper.Agents.Agent} to={agents} text="Agents" />;
    }

    return null;
};