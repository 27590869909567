import { IInstallationAutomation, IInstallationTypeSettings, ISolarSystemAutomationConfiguration, ISolarSystemDetail, InstallationAutomation } from '../../../../ApplicationState/ApiClient';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../Components/Base/Buttons/ButtonHolder';
import { OnOffButtons } from '../../../../Components/Base/Buttons/OnOffButtons';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../Components/Base/Containers/Table';
import { Checkbox } from '../../../../Components/Base/Form/Checkbox';
import { LoadingWrapper } from '../../../../Components/Base/Loading/LoadingWrapper';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { InstallationTypeImage } from '../../../../Components/FusionShift/Images/InstallationTypeImage';
import { IconHelper } from '../../../../Helpers/IconHelper';

type Props = {
    isEnabled: boolean,
    save: (x: ISolarSystemAutomationConfiguration) => any,
    configuration: ISolarSystemAutomationConfiguration,
    setConfiguration: (x: ISolarSystemAutomationConfiguration) => any,
    installationTypeSettings: IInstallationTypeSettings,
    solarSystem: ISolarSystemDetail,
    isSaving: boolean
}

export const InstallationAutomationEditor = (props: Props) => {

    function edit(modify: (e: IInstallationAutomation) => IInstallationAutomation) {

        const edited = modify(props.configuration.installations);
        const c = {
            ...props.configuration,
            installations: new InstallationAutomation(edited)
        };
        props.setConfiguration(c);

        props.save(c);
    }

    function setAutomateInstallation(installationId: number, automate: boolean) {
        if (automate) {
            edit(e => {
                return {
                    ...e,
                    ignoreInstallationIds: e.ignoreInstallationIds.filter(x => x !== installationId)
                }
            });
        } else {
            edit(e => {
                return {
                    ...e,
                    ignoreInstallationIds: [...e.ignoreInstallationIds, installationId]
                }
            });
        }
    }

    function ignoreNone() {
        edit(e => {
            return {
                ...e,
                ignoreInstallationIds: []
            }
        });
    }

    function ignoreAll() {

        const installationIds: number[] = [];

        for (const celestial of props.solarSystem.celestials) {
            installationIds.push(...(celestial.installations ?? []).map(x => x.installationId));
        }

        edit(e => {
            return {
                ...e,
                ignoreInstallationIds: installationIds
            }
        });
    }

    const installationConfiguration = props.configuration.installations;
    const enabled = props.isEnabled && props.configuration.installations.isEnabled;

    const numInstallations = props.solarSystem.celestials.map(x => x.installations?.length ?? 0).reduce((a, b) => a + b);
    const areAllEnabled = props.configuration.installations.ignoreInstallationIds.length === 0;
    const areAllIgnored = props.configuration.installations.ignoreInstallationIds.length === numInstallations;

    return <SubPanel
        heading={{ text: "Installations", icon: IconHelper.Installations.Installation }}
        headingContent={<OnOffButtons
            isOn={installationConfiguration.isEnabled}
            setIsOn={e => edit(x => {
                return { ...x, isEnabled: e }
            })}
            isDisabled={!props.isEnabled}
        />}
    >
        <LoadingWrapper isLoading={props.isSaving}>
            <Paragraph>Automated construction of installations is {enabled ? "enabled" : "disabled"}.</Paragraph>
            <Paragraph>Automation of installation construction will try to add an installation upgrade if the build queue is empty. New installations will not be constructed.</Paragraph>
            <ButtonHolder>
                <Button
                    isDisabled={areAllIgnored}
                    text="Disable All"
                    icon={IconHelper.General.Cancel}
                    action={ignoreAll}
                    type='action'
                />
                <Button
                    isDisabled={areAllEnabled}
                    text="Enable All"
                    icon={IconHelper.General.Confirm}
                    action={ignoreNone}
                    type='action'
                />
            </ButtonHolder>
            <Paragraph>
                The following installations will be considered for upgrade via automation:
            </Paragraph>
            <Table
                isClickable
                isFullWidth
            >
                {props.solarSystem.celestials.map(c => (c.installations ?? []).map(i => {

                    const installationType = props.installationTypeSettings.data[i.installationTypeName];
                    const isAutomated = !props.configuration.installations.ignoreInstallationIds.includes(i.installationId);

                    return <tr key={i.installationId}
                        onClick={() => setAutomateInstallation(i.installationId, !isAutomated)}
                    >
                        <td className="is-content-width has-image-tiny">
                            <InstallationTypeImage installationType={installationType} size="tiny" />
                        </td>
                        <td>
                            {installationType.name} [{i.level}]
                        </td>
                        <td>
                            <Checkbox
                                value={isAutomated}
                                valueChanged={v => setAutomateInstallation(i.installationId, v)}
                                label=""
                            />
                        </td>
                    </tr>;
                }))}
            </Table>
        </LoadingWrapper>
    </SubPanel>;
};