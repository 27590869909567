import * as React from 'react';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { SimpleBaseView } from '../../Views/BaseView';
import { InboxButtons } from '../Base/Buttons/InboxButtons';
import { SubPanel } from '../Base/Containers/SubPanel';
import { LoadingSpinner } from '../Base/Loading/LoadingSpinner';
import { Paragraph } from '../Base/Text/Paragraph';

type Props<T> = {
    list: () => Promise<T[]>,
    markAllAsRead: () => Promise<any>,
    deleteAll: () => Promise<any>,
    entityName: string,
    icon: string,
    canShowInboxButtons?: (() => boolean) | undefined,
    render: (entities: T[]) => React.ReactNode
}

export function InboxContainer<T>(props: Props<T>) {

    const [entities, setEntities] = React.useState<T[] | undefined>(undefined);

    React.useEffect(() => {
        if (entities === undefined) {
            props.list().then(e => setEntities(e));
        }
    }, [entities]);

    function markAllAsRead() {
        return props.markAllAsRead().then(_ => setEntities(undefined));
    }

    function deleteAll() {
        return props.deleteAll().then(_ => setEntities(undefined));
    }


    function canShowInboxButtons() {
        return props.canShowInboxButtons === undefined || props.canShowInboxButtons();
    }

    return <SimpleBaseView heading={{ text: ValueFormatter.pluralize(props.entityName, 2), icon: props.icon }}>
        {entities === undefined && <LoadingSpinner />}
        {entities !== undefined && <SubPanel
            heading={`${entities.length} ${ValueFormatter.pluralize(props.entityName, entities.length)}`}
            isUnpadded={entities.length !== 0}
            headingContent={entities.length > 0 && canShowInboxButtons() && <InboxButtons
                entityName={props.entityName.toLocaleLowerCase()}
                deleteAll={deleteAll}
                markAllAsRead={markAllAsRead}
            />}
        >
            {entities.length === 0 && <Paragraph type="missing">No {ValueFormatter.pluralize(props.entityName, entities.length)}</Paragraph>}
            {entities.length > 0 && props.render(entities)}
        </SubPanel>}
    </SimpleBaseView>;
}