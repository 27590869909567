import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AutomationInput, AutomationLogs, ISolarSystemAutomationConfiguration, InstallationAutomation, ShipConstructionAutomation, SolarSystemAutomationConfiguration } from '../../../../ApplicationState/ApiClient';
import { ApiStateContext, SolarSystemStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { OnOffButtons } from '../../../../Components/Base/Buttons/OnOffButtons';
import { SubPanel } from '../../../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { AutomationConfigurationEditor } from './AutomationConfigurationEditor';
import { AutomationLogsRender } from './AutomationLogsRender';

type Props = {
    solarSystemWrapper: SolarSystemWrapper,
}

function createDefaultAutomation() {

    const installations = new InstallationAutomation({
        isEnabled: true,
        ignoreInstallationIds: []
    });

    const shipConstruction = new ShipConstructionAutomation({
        isEnabled: true,
        shipTypeNamesToMaintain: {}
    });

    const logs = new AutomationLogs();

    return new SolarSystemAutomationConfiguration({
        installations,
        shipConstruction,
        logs
    });
}

export const Automation = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);

    const originallyEnabled = props.solarSystemWrapper.solarSystem.automation?.isEnabled ?? false;

    const [configuration, setConfiguration] = React.useState<ISolarSystemAutomationConfiguration>(props.solarSystemWrapper.solarSystem.automation?.configuration ?? createDefaultAutomation());

    const [isSaving, setIsSaving] = React.useState(false);

    React.useEffect(() => {
        setConfiguration(props.solarSystemWrapper.solarSystem.automation?.configuration ?? createDefaultAutomation());
    }, [props.solarSystemWrapper]);

    if (!worldState.InstallationTypeSettings || !worldState.ShipTypeSettings) {
        return <LoadingSpinner />;
    }

    function edit(newConfiguration: ISolarSystemAutomationConfiguration) {
        setConfiguration(newConfiguration);
    }

    function changeEnabled(enabled: boolean) {
        setIsSaving(true);

        apiState.SolarSystemClient.setAutomation(props.solarSystemWrapper.solarSystemId, new AutomationInput({
            configuration: new SolarSystemAutomationConfiguration(configuration),
            isEnabled: enabled
        }))
            .then(x => solarSystemState.loadSolarSystem(x))
            .finally(() => setIsSaving(false))
    }

    function save(newConfiguration: ISolarSystemAutomationConfiguration) {
        setIsSaving(true);

        apiState.SolarSystemClient.setAutomation(props.solarSystemWrapper.solarSystemId, new AutomationInput({
            configuration: new SolarSystemAutomationConfiguration(newConfiguration),
            isEnabled: originallyEnabled
        }))
            .then(x => solarSystemState.loadSolarSystem(x))
            .finally(() => setIsSaving(false))
    }

    const lastDate = props.solarSystemWrapper.solarSystem.automation?.lastProcessedDate;

    return <SubPanel
        key={props.solarSystemWrapper.solarSystemId}
        heading={{ text: "Automation", icon: IconHelper.SolarSystems.Automation }}
        headingContent={<OnOffButtons
            isOn={originallyEnabled}
            setIsOn={changeEnabled}
            isPulledRight
            isDisabled={isSaving}
        />}
    >
        <Paragraph>
            Automation is {originallyEnabled ? "enabled" : "disabled"} for this system.
        </Paragraph>
        <div className={originallyEnabled ? "" : "is-disabled-mask"}>
            <AutomationLogsRender
                lastDate={lastDate}
                automationLogs={configuration.logs}
                installationTypeSettings={worldState.InstallationTypeSettings}
                shipTypeSettings={worldState.ShipTypeSettings}
            />
            <AutomationConfigurationEditor
                isSaving={isSaving}
                isEnabled={originallyEnabled}
                configuration={configuration}
                solarSystem={props.solarSystemWrapper.solarSystem}
                setConfiguration={edit}
                save={x => save(x)}
                installationTypeSettings={worldState.InstallationTypeSettings}
                shipTypeSettings={worldState.ShipTypeSettings}
            />
        </div>
    </SubPanel>;
});