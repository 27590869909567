import classNames from "classnames";
import * as React from "react";
import { AdminWorld } from "../../../ApplicationState/ApiClient";
import { useApiState } from "../../../ApplicationState/ContextRoot";
import { Button } from "../../../Components/Base/Buttons/Button";
import { IconHelper } from "../../../Helpers/IconHelper";

type Props = {
  world: AdminWorld
  setMessage: (message: string) => void;
}

export const DebugControls = (props: Props) => {
  const apiState = useApiState();
  const [isOpen, setIsOpen] = React.useState(false);

  function debugCommand() {
    return apiState.AdminClient.debugCommand(props.world.worldId).then(
      (info) => {
        props.setMessage(`Executed debug command: ${info}`);
      }
    );
  }

  function debugNotification() {
    return apiState.AdminClient.debugNotification(props.world.worldId).then(
      (info) => {
        props.setMessage(`Executed debug command: ${info}`);
      }
    );
  }

  return (
    <div className={classNames("dropdown", { "is-active": isOpen })}>
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={setIsOpen.bind(null, !isOpen)}
        >
          <span>Debug Commands</span>
          <span className="icon is-small">
            <i className="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item">
            <Button
              type="danger"
              icon={IconHelper.Categories.Settings}
              text="Debug Command"
              action={debugCommand}
              confirm="Are you sure you want to execute the debug command? This is irreversible"
            />
          </div>
          <div className="dropdown-item">
            <Button
              type="warning"
              icon={IconHelper.Statuses.Warning}
              text="Debug Notification"
              action={debugNotification}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
