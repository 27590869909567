import { CombatReportIndex } from "../../Views/CombatReport/CombatReportIndex";
import { CombatReportView } from "../../Views/CombatReport/CombatReportView";
import { MessageIndex } from "../../Views/Message/MessageIndex";
import { MessageView } from "../../Views/Message/MessageView";
import { SendMessageView } from "../../Views/Message/SendMessageView";
import { PlayerNotificationIndex } from "../../Views/PlayerNotification/PlayerNotificationIndex";
import { PlayerNotificationView } from "../../Views/PlayerNotification/PlayerNotificationView";
import { route } from "./Root";

export const combatReports = "/combatreports";
export function combatReport(combatReportId: number | string) {
    return combatReports + "/" + combatReportId;
}

export const playerNotifications = "/notification";
export function playerNotification(playerNotificationId: number | string) {
    return playerNotifications + "/" + playerNotificationId;
}

export const messages = "/messages";
export const messages_send = messages + "/send";
export function message(messageId: number | string) {
    return messages + "/" + messageId;
}

export const notificationsEtcRoutes = [
    route(combatReport(":combatReportId"), CombatReportView, "Combat Report"),
    route(combatReports, CombatReportIndex, "Combat Reports"),

    route(playerNotification(":playerNotificationId"), PlayerNotificationView, "Notification"),
    route(playerNotifications, PlayerNotificationIndex, "Notifications"),

    route(messages_send, SendMessageView, "Send Message"),
    route(message(":messageId"), MessageView, "Message"),
    route(messages, MessageIndex, "Messages")
];