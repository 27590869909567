import * as React from 'react';
import { BasicSimulatedFleet, FleetMovementType, IBasicSimulatedFleet, IFactionTypeSettings, IGameSettings, IShipTypeSettings } from '../../../ApplicationState/ApiClient';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { defaultReinforcingFleet } from '../Data';
import { ShipsForm } from './ShipForm';

type Props = {
    gameSettings: IGameSettings,
    shipTypeSettings: IShipTypeSettings,
    factionTypeSettings: IFactionTypeSettings,
    reinforcements: IBasicSimulatedFleet[],
    setReinforcements: (reinforcements: IBasicSimulatedFleet[]) => any
}

type RowProps = {
    shipTypeSettings: IShipTypeSettings,
    factionTypeSettings: IFactionTypeSettings,
    gameSettings: IGameSettings,
    reinforcement: IBasicSimulatedFleet,
    setReinforcement: (reinforcement: IBasicSimulatedFleet) => any,
    removeReinforcement: () => any,
}

const ReinforcementsRow = (props: RowProps) => {

    const [factionTypeName, setFactionTypeName] = React.useState<string | undefined>(undefined);
    const [isExpanded, setIsExpanded] = React.useState(false);

    const totalShips = CollectionHelper.sumOfDictionary(props.reinforcement.ships);

    return <>
        <tr>
            <td>
                {factionTypeName !== undefined && `${props.factionTypeSettings.data[factionTypeName].name} `}
                Reinforcement
            </td>
            <td>
                {ValueFormatter.formatLocaleNumber(totalShips)} Ships
            </td>
            <td>
                <ButtonHolder>
                    <Button type="danger" icon={IconHelper.General.Delete} action={props.removeReinforcement} confirm="Are you sure you want to remove this reinforcement?" />
                    <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </ButtonHolder>
            </td>
        </tr>
        {isExpanded && <tr>
            <td colSpan={3}>
                <ShipsForm
                    movementType={FleetMovementType.Reinforce}
                    shipTypeSettings={props.shipTypeSettings}
                    factionTypeSettings={props.factionTypeSettings}
                    gameSettings={props.gameSettings}
                    ships={props.reinforcement.ships}
                    tactics={props.reinforcement.tactics}
                    setFactionTypeName={setFactionTypeName}
                    setShipsAndTactics={(ships, tactics) => props.setReinforcement({
                        ...props.reinforcement,
                        ships,
                        tactics
                    })}
                />
            </td>
        </tr>}
    </>;
}

export const ReinforcementsForm = (props: Props) => {

    function add() {
        let fleetId = 0;
        while (props.reinforcements.find(x => x.fleetId === fleetId) !== undefined) {
            fleetId++;
        }

        const added = BasicSimulatedFleet.fromJS({
            ...defaultReinforcingFleet,
            fleetId
        });

        props.reinforcements.push(added);
        props.setReinforcements(props.reinforcements);
    }

    function remove(fleetId: number) {
        const index = props.reinforcements.findIndex(x => x.fleetId === fleetId);
        if (index !== undefined) {
            props.reinforcements.splice(index, 1);
            props.setReinforcements(props.reinforcements);
        }
    }

    function modify(reinforcement: IBasicSimulatedFleet) {
        const index = props.reinforcements.findIndex(x => x.fleetId === reinforcement.fleetId);

        if (index !== undefined) {
            props.reinforcements[index] = reinforcement;
            props.setReinforcements(props.reinforcements);
        }
    }

    return <SubPanel
        isUnpadded={props.reinforcements.length !== 0}
        heading={{ text: "Reinforcements", icon: IconHelper.Ships.movementType(FleetMovementType.Reinforce) }}
        headingContent={<ButtonHolder>
            <Button type="danger" text="Clear all" icon={IconHelper.General.Delete} action={() => props.setReinforcements([])} confirm="Are you sure you want to clear all reinforcements?" isDisabled={props.reinforcements.length === 0} />
            <Button type="nav" text="Add" icon={IconHelper.General.Plus} action={add} />
        </ButtonHolder>}
    >
        {props.reinforcements.length === 0 && <Paragraph>No reinforcements configured</Paragraph>}
        {props.reinforcements.length !== 0 &&
            <Table isFullWidth isHoverable isFixed>
                {props.reinforcements.map(x => <ReinforcementsRow
                    key={x.fleetId}
                    reinforcement={x}
                    shipTypeSettings={props.shipTypeSettings}
                    factionTypeSettings={props.factionTypeSettings}
                    gameSettings={props.gameSettings}
                    removeReinforcement={() => remove(x.fleetId)}
                    setReinforcement={modify}
                />)}
            </Table>
        }
    </SubPanel>;
};