import { ItemCategory } from "../../../ApplicationState/ApiClient";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { FancyItem } from "../../../Helpers/ItemsHelper";
import { QuantityIconDisplayProps } from "../Icons/QuantityIcon";
import { CommodityBarBlock, ComponentBarBlock, RawResourceBarBlock, ResourceBarBlock } from "./ItemBarBlock";

type Props = QuantityIconDisplayProps & {
    items: FancyItem[],
    otherCapacities?: { [key in keyof typeof ItemCategory]?: number; } | undefined
}

export const FancyItemsWithCapacityControl = (props: Props) => {

    const items = props.items;

    if (items.length === 0) {
        return null;
    }

    const resources = items.filter(i => i.itemType.category === ItemCategory.Resource);
    const commodities = items.filter(i => i.itemType.category === ItemCategory.Commodity);
    const rawResources = items.filter(i => i.itemType.category === ItemCategory.RawResource);
    const components = items.filter(i => i.itemType.category === ItemCategory.Component);

    const commoditiesSum = CollectionHelper.sumOfArray(commodities.map(c => c.quantity));
    const rawResourcesSum = CollectionHelper.sumOfArray(rawResources.map(c => c.quantity));
    const componentsSum = CollectionHelper.sumOfArray(components.map(c => c.quantity));

    const commodityDanger = props.otherCapacities && props.otherCapacities.Commodity && commoditiesSum >= props.otherCapacities.Commodity;
    const rawResourceDanger = props.otherCapacities && props.otherCapacities.RawResource && rawResourcesSum >= props.otherCapacities.RawResource;
    const componentDanger = props.otherCapacities && props.otherCapacities.Component && componentsSum >= props.otherCapacities.Component;

    const commoditiesIncreasing = commodities.find(x => x.balancePerSec > 0) !== undefined;
    const rawResourcesIncreasing = rawResources.find(x => x.balancePerSec > 0) !== undefined;
    const componentIncreasing = components.find(x => x.balancePerSec > 0) !== undefined;

    return <>
        {resources.map(c => <ResourceBarBlock key={c.itemType.typeName} item={c} {...props} />)}
        <CommodityBarBlock quantity={commoditiesSum} isIncreasing={commoditiesIncreasing} isDanger={!!commodityDanger} capacity={props.otherCapacities?.Commodity} {...props} />
        <RawResourceBarBlock quantity={rawResourcesSum} isIncreasing={rawResourcesIncreasing} isDanger={!!rawResourceDanger} capacity={props.otherCapacities?.RawResource} {...props} />
        <ComponentBarBlock quantity={componentsSum} isIncreasing={componentIncreasing} isDanger={!!componentDanger} capacity={props.otherCapacities?.Component} {...props} />
    </>;
};