import { observer } from "mobx-react-lite";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../Components/Base/Containers/Table";
import { Checkbox } from "../../../Components/Base/Form/Checkbox";
import { handleOnClick } from "../../../Components/Base/Util";
import { AgentPortrait } from "../../../Components/FusionShift/Images/AgentPortrait";
import { AgentLink } from "../../../Components/FusionShift/Links/AgentLink";
import { AgentAvailability } from "../../../Helpers/AgentHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { SendShipsBag } from "../SendShipsBag";
import { AgentMovementOutcome } from "./AgentMovementOutcome";

type Props = {
    sendShipsBag: SendShipsBag,
    current: number[],
    available: AgentAvailability[],
    setValue: (agentId: number, send: boolean) => any
}

export const AgentInputList = observer((props: Props) => {

    if (props.available.length === 0 || props.sendShipsBag.MovementType === undefined) {
        return null;
    }

    function send(agentId: number, send: boolean) {

        props.setValue(agentId, send);
    }

    return <SubPanel
        heading={{ text: "Agents", icon: IconHelper.Agents.Agent }}
        className="agents-selection"
        isUnpadded>
        <Table isFullWidth>
            {props.available.map(x => {

                const isSelected = props.current.includes(x.agent.agentId);

                return <tr key={x.agent.agentId} className={x.isAvailable ? "is-clickable" : ""} onClick={e => handleOnClick(e, () => {
                    if (x.isAvailable) {
                        send(x.agent.agentId, !isSelected);
                    }
                })} >
                    <td>
                        <AgentPortrait agent={x.agent} size="tiny" />
                    </td>
                    <td>
                        <AgentLink agent={x.agent} />
                        {isSelected && <>
                            <br />
                            <AgentMovementOutcome
                                agent={x.agent}
                                movementType={props.sendShipsBag.MovementType!}
                                playerId={props.sendShipsBag.init.player.playerId}
                                targetSolarSystem={props.sendShipsBag.TargetSolarSystem}
                            />
                        </>}
                        {!x.isAvailable && x.reason !== undefined && <>
                            <br />
                            <span className="has-text-warning">Unable to move: {x.reason}</span>
                        </>}
                    </td>
                    <td>
                        {x.isAvailable && <Checkbox label="" value={isSelected} valueChanged={value => send(x.agent.agentId, value)} />}
                    </td>
                </tr>;
            })}
        </Table>
    </SubPanel>;
});