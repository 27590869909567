import * as React from 'react';
import { HistoryBackButton } from '../../Components/Base/Buttons/BackButton';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { HelpButton } from '../../Components/Base/Buttons/HelpButton';
import { help } from '../../Navigation/Routing/Help';

type Props = {
    children?: React.ReactNode
}

export const HelpNavButtons = (props: Props) => {

    return <ButtonHolder>
        {props.children}
        <HelpButton text="Home" to={help} />
        <HistoryBackButton />
    </ButtonHolder>;
};