import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { FactionType } from "../../../ApplicationState/ApiClient";
import { help_factionTypes } from "../../../Navigation/Routing/Help";

type Props = {
    factionType: FactionType | undefined | false
}

export const FactionTypeLink = (props: Props) => {

    if (!props.factionType) {
        return null;
    }

    return <Link to={`${help_factionTypes}#${props.factionType.typeName}`}>
        {props.factionType.name}
    </Link>;
};