import * as React from 'react';
import { AdminWorld } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { TileHolder } from '../../../Components/Base/Containers/TileHolder';
import { SuccessMessageBox } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';
import { DebugControls } from './DebugControls';

type Props = {
    world: AdminWorld
}

export const WorldButtons = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const [message, setMessage] = React.useState<string | undefined>(undefined);

    function tick() {
        return apiState.DebugClient.ticks(1).then(_ => {
            setMessage("Ticked once");
        });
    }

    function tickBots() {
        return apiState.DebugClient.tickBots().then(_ => {
            setMessage("Ticked bots");
        });
    }

    function tickProcesses() {
        return apiState.DebugClient.tickProcesses().then(result => {
            setMessage("Ticked processes: " + result);
        });
    }

    function reloadTypes() {
        return apiState.AdminClient.reloadWorldTypeData(props.world.worldId).then(_ => {
            setMessage("Reloaded types - remember to restart functions apps");
        });
    }

    function reloadSettings() {
        return apiState.AdminClient.reloadGameAndGenerationSettings(props.world.worldId).then(_ => {
            setMessage("Reloaded game and generation settings - remember to restart functions apps");
        });
    }

    function deleteAllMarketOrders() {
        return apiState.AdminClient.deleteAllMarketOrders(props.world.worldId).then(_ => {
            setMessage("Deleted all market orders");
        });
    }

    function regenerateBots() {
        return apiState.AdminClient.regenerateBots(props.world.worldId).then(info => {
            setMessage(`Regenerated Bots: ${info}`);
        });
    }

    function generateRelics() {
        return apiState.AdminClient.generateRelics(props.world.worldId).then(_ => {
            setMessage(`Generated Relics`);
        });
    }

    function tickAutomation() {
        return apiState.AdminClient.automation().then(_ => {
            setMessage(`Processed Solar System automation`);
        });
    }

    function generateBotEmblems() {
        return apiState.AdminClient.generateBotEmblems(props.world.worldId).then(_ => {
            setMessage("Generated bot emblems");
        });
    }

    return <>
        <TileHolder>
            <Button type="warning" text="Tick Once" action={tick} icon={IconHelper.General.Time} />
            <Button type="warning" text="Tick Bots" action={tickBots} icon={IconHelper.Categories.Settings} />
            <Button type="warning" text="Tick Processes" action={tickProcesses} icon={IconHelper.Categories.Settings} />
            <Button type="warning" text="Process Automation" action={tickAutomation} icon={IconHelper.SolarSystems.Automation} />
            <Button type="warning" icon={IconHelper.Categories.Settings} text="Reload Types" action={reloadTypes} confirm="Are you sure you want to reload types? This is irreversible" />
            <Button type="warning" icon={IconHelper.Categories.Settings} text="Reload Settings" action={reloadSettings} confirm="Are you sure you want to reload settings? This is irreversible" />
            <Button type="warning" icon={IconHelper.Categories.Settings} text="Regenerate Bots" action={regenerateBots} confirm="Are you sure you want to regenerate Bots? This is irreversible" />
            <DebugControls setMessage={setMessage} world={props.world} />
            <Button type="danger" icon={IconHelper.General.Delete} text="Delete all Market Orders" action={deleteAllMarketOrders} confirm="Are you sure you want to delete all market orders? This is irreversible" />
            <Button type="warning" icon={IconHelper.Items.Relic} text="Generate Relics" action={generateRelics} confirm="Are you sure you want to generate relics in the world? This is irreversible" />

            <Button type="warning" text="Generate Bot Emblems" action={generateBotEmblems} icon={IconHelper.Categories.Settings} />
        </TileHolder>
        {!!message && <SuccessMessageBox text={message} />}
    </>
};