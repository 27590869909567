import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ItemCategory, SolarSystemClaimType } from '../../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { MessageBoxWithIcon } from '../../../../Components/Base/MessageBox';
import { NextResearchPointDate } from '../../../../Components/FusionShift/Items/NextResearchPointDate';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../Helpers/IconHelper';

type Props = {
    solarSystemWrapper: SolarSystemWrapper
}

export const RelicMessage = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (!worldState.ItemTypeSettings || playerState.Player === undefined) {
        return null;
    }

    const relicCount = Object.keys(props.solarSystemWrapper.solarSystem.items ?? {}).filter(x => x in worldState.ItemTypeSettings!.data && worldState.ItemTypeSettings!.data[x].category === ItemCategory.Relic).length;

    if (relicCount === 0) {
        return null;
    }

    if (props.solarSystemWrapper.solarSystem.claimType === SolarSystemClaimType.Outpost) {
        return <MessageBoxWithIcon
            type="warning"
            icon={IconHelper.Items.Relic}
            text={<>This system controls a relic but won't generate research points because it is an outpost.</>}
        />;
    }

    if (playerState.Player.federation === undefined) {
        return <MessageBoxWithIcon
            type="warning"
            icon={IconHelper.Items.Relic}
            text={<>This system controls a relic but won't generate research points until you join a federation.</>}
        />;
    }

    if (relicCount === 1) {
        return <MessageBoxWithIcon
            type="info"
            icon={IconHelper.Items.Relic}
            text={<>This system controls a relic and is generating research points. <NextResearchPointDate date={props.solarSystemWrapper.solarSystem.nextResearchPointDate} /> </>}
        />;
    }

    return <MessageBoxWithIcon
        type="warning"
        icon={IconHelper.Items.Relic}
        text={<>This system controls multiple relics and is generating research points. Only 1 relic per system can generate research points. Consider moving one of the relics to another system to generate Research Points faster. <NextResearchPointDate date={props.solarSystemWrapper.solarSystem.nextResearchPointDate} /> </>}
    />;
});