import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AccountSubscriptionLevelResponse, IPagedOfCurrencyTransactionDetail, PremiumDetails } from '../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext } from '../../ApplicationState/ContextRoot';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { SimpleBaseView } from '../BaseView';
import { CurrencyTransactionDetails } from './Components/CurrencyTransactionDetails';
import { SubscriptionControl } from './Components/Subscription/Overview/SubscriptionControl';

export const PremiumView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    const [baseDetails, setBaseDetails] = React.useState<PremiumDetails | undefined>(undefined);
    const [accountSubscriptionLevel, setAccountSubscriptionLevel] = React.useState<AccountSubscriptionLevelResponse | undefined>(undefined);
    const [currencyTransactions, setcurrencyTransactions] = React.useState<IPagedOfCurrencyTransactionDetail | undefined>(undefined);
    const [currencyTransactionsPage, setcurrencyTransactionsPage] = React.useState<number>(0);

    React.useEffect(() => {
        if (!baseDetails) {
            apiState.PremiumClient.getDetails().then(details => {
                if (details) {
                    setBaseDetails(details);
                }
            });
        }

        if (!accountSubscriptionLevel) {
            apiState.AuthenticationClient.accountSubscriptionLevel().then(subscriptionLevel => {
                if (subscriptionLevel) {
                    setAccountSubscriptionLevel(subscriptionLevel);
                }
            });
        }
    }, [baseDetails]);

    React.useEffect(() => {
        apiState.PremiumClient.getCurrencyTransactions(currencyTransactionsPage).then(currencyTransactions => {
            if (currencyTransactions) {
                setcurrencyTransactions(currencyTransactions);
            }
        });
    }, [currencyTransactionsPage]);

    if (!baseDetails || !accountSubscriptionLevel || !appState.IsReady) {
        return <LoadingSpinner />;
    }

    if (!appState.IsPremiumEnabled) {
        appState.navigate("/");
    }

    return <SimpleBaseView heading={"Premium"}>
        <SubscriptionControl accountSubscriptionLevel={accountSubscriptionLevel} details={baseDetails} />
        <CurrencyTransactionDetails currencyTransactions={currencyTransactions} {...baseDetails} onLinkClicked={(pageIndex) => { setcurrencyTransactionsPage(pageIndex) }} />
    </SimpleBaseView>;
});