import React from "react";
import { IShipTypeSettings } from "../../../ApplicationState/ApiClient";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { ShipClassIcon } from "./ShipClassIcon";

type Props = {
    ships: { [key: string]: number } | undefined,
    shipTypeSettings: IShipTypeSettings
}

export const ShipQuantityIcons = (props: Props) => {

    const quantityClasses = React.useMemo(() => {

        if (props.ships === undefined) {
            return [];
        }

        return Object.keys(props.ships).map(x => {
            return {
                class: props.shipTypeSettings.data[x].class,
                quantity: props.ships![x]
            };
        })
            .filter(x => x.quantity > 0)
            .sort((a, b) => a.class - b.class);

    }, [props.ships, props.shipTypeSettings]);

    if (quantityClasses.length === 0) {
        return null;
    }

    return <>
        {quantityClasses.map(x => <span key={x.class} className="has-nowrap">
            <ShipClassIcon shipClass={x.class} />
            {ValueFormatter.formatLocaleNumber(x.quantity)}
        </span>)}
    </>;
};