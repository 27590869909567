import { observer } from "mobx-react-lite";
import * as React from "react";
import { ItemCategory } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Select } from "../../Base/Form/Select";

const anyValue = "ANY";

type Option = {
    value: string,
    text: string
}

type Props = {
    defaultItemTypeName?: string | undefined,
    allowEmpty?: boolean,
    allowedItems?: string[],
    setItemTypeName: (itemTypeName: string | undefined) => any,
    setItemCategory?: (itemCategory: ItemCategory | undefined) => any,
    hideCredits?: boolean,
    categoryPrompt?: string | undefined,
    itemPrompt?: string | undefined,
    forceHideItemPrompt?: boolean
}

type Item = {
    value: string,
    text: string,
    category?: ItemCategory | undefined,
    subcategory?: string | undefined
};

export const ItemChooser = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [availableItems, setAvailableItems] = React.useState<Item[] | undefined>(undefined);

    const [availableCategories, setAvailableCategories] = React.useState<Option[] | undefined>(undefined);
    const [itemCategory, setItemCategory] = React.useState<(ItemCategory | "Credits") | undefined>(undefined);
    const [itemTypeName, setItemTypeName] = React.useState<string | undefined>(props.defaultItemTypeName)

    React.useEffect(() => {
        if (availableItems === undefined && worldState.ItemTypeSettings) {

            const categories: Option[] = [];
            const options = Object.values(worldState.ItemTypeSettings.data)
                .filter(x => x.category !== ItemCategory.Relic)
                .filter(x => props.allowedItems === undefined || props.allowedItems.includes(x.typeName))
                .sort((a, b) => a.order - b.order)
                .map(x => {
                    if (categories.find(y => y.value === x.category.toString()) === undefined) {
                        categories.push({
                            value: x.category.toString(),
                            text: ValueFormatter.friendlyNameForItemCategory(x.category)
                        })
                    }

                    const option: Item = {
                        value: x.typeName,
                        text: x.name,
                        category: x.category,
                        subcategory: x.category === ItemCategory.Commodity ? worldState.CommodityHelper!.commodityTypeUsage(x.typeName)?.sectorType?.name : undefined
                    };

                    return option;
                });

            if (!props.hideCredits) {
                options.unshift({ text: "Credits", value: worldState.GameSettings!.economy.creditsPlaceholderItemTypeName });
                categories.unshift({ text: "Credits", value: "Credits" });
            }
            if (!props.forceHideItemPrompt) {
                options.unshift({ text: props.allowEmpty ? props.itemPrompt ?? "Any" : props.itemPrompt ?? "Please select...", value: anyValue });
            }
            categories.unshift({ text: props.allowEmpty ? props.categoryPrompt ?? "Any" : props.categoryPrompt ?? "Please select...", value: anyValue });

            setAvailableItems(options);
            setAvailableCategories(categories);
        }
    }, [worldState.ItemTypeSettings, availableItems, props.allowedItems, props.allowEmpty]);

    React.useEffect(() => {

        if (availableCategories !== undefined && availableCategories.length > 1) {
            if (availableCategories.length === 2) {
                // Default selection if only 1 actual category is available
                onItemCategoryChanged(availableCategories[1].value);
            } else if (availableCategories.find(x => x.value === itemCategory) === undefined) {

                if (props.defaultItemTypeName !== undefined) {
                    const defaultItem = availableItems?.find(x => x.value === props.defaultItemTypeName);

                    if (defaultItem !== undefined && defaultItem.category !== undefined) {
                        const index = availableCategories.findIndex(x => x.value === defaultItem!.category!.toString());
                        if (index >= 0) {
                            onItemCategoryChanged(availableCategories[index].value);
                            return;
                        }
                    }
                }

                // Select prompt if an unavailable category is selected
                onItemCategoryChanged(availableCategories[0].value);
            }
        }

    }, [availableCategories]);

    React.useEffect(() => {
        const selectedItem = availableItems?.find(x => x.value === itemTypeName);
        if (selectedItem !== undefined && selectedItem.category !== undefined && selectedItem.category !== itemCategory) {
            setItemTypeName(undefined);
        }
        if (props.setItemCategory) {
            props.setItemCategory(itemCategory !== "Credits" ? itemCategory : undefined)
        }
    }, [itemCategory]);

    React.useEffect(() => {
        if (props.allowEmpty || itemTypeName !== undefined) {
            props.setItemTypeName(itemTypeName === anyValue ? undefined : itemTypeName);
        }
    }, [itemTypeName]);

    React.useEffect(() => {
        if (itemCategory === "Credits") {
            setItemTypeName("Credits");
        } else {
            if (!props.allowEmpty && itemTypeName === undefined && availableItems && availableItems.length > 0) {
                setItemTypeName(availableItems[0].value);
            }
        }

    }, [itemCategory]);

    if (!worldState.ItemTypeSettings) {
        return null;
    }

    function onTypeNameChanged(value: string) {
        setItemTypeName(value === anyValue ? undefined : value);
    }

    function onItemCategoryChanged(value: string) {
        if (value === "Credits") {
            setItemCategory("Credits");
        } else {
            setItemCategory(value === anyValue ? undefined : Number(value) as ItemCategory);
        }
    }

    const itemsToUse = worldState.ItemTypeSettings && itemCategory !== undefined && availableItems !== undefined &&
        (itemCategory !== "Credits" ?
            availableItems.filter(x => x.value === anyValue || x.value !== "Credits" && worldState.ItemTypeSettings!.data[x.value].category === itemCategory) :
            availableItems.filter(x => x.value === "Credits")
        );

    return <div className="field-body">
        {availableCategories !== undefined && availableCategories.length > 2 &&
            <Select
                isNotFullWidthMobile
                valueChanged={e => onItemCategoryChanged(e)}
                value={itemCategory === undefined ? anyValue : itemCategory}
                values={availableCategories.map(v => {
                    return {
                        value: v.value,
                        label: v.text
                    }
                })}
            />
        }
        {itemsToUse &&
            <Select
                isNotFullWidthMobile
                value={itemTypeName === undefined ? anyValue : itemTypeName}
                valueChanged={e => onTypeNameChanged(e)}
                values={itemsToUse.map(v => {
                    return {
                        value: v.value,
                        label: v.text,
                        group: v.subcategory
                    }
                })}
            />
        }
    </div>;
});