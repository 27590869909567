import { observer } from "mobx-react-lite";
import * as React from "react";
import Linkify from 'react-linkify';
import { useParams } from "react-router-dom";
import { Message } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { BackNavButton } from "../../Components/Base/Buttons/BackButton";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { DeleteButton } from "../../Components/Base/Buttons/DeleteButton";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { H2 } from "../../Components/Base/Text/H";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { SendMessageButton } from "../../Components/FusionShift/Buttons/SendMessageButton";
import { RichText } from "../../Components/FusionShift/RichText";
import { IconHelper } from "../../Helpers/IconHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { messages } from "../../Navigation/Routing/NotificationsEtc";
import { SimpleBaseView } from "../BaseView";
import { MessageSenderImageOnly, MessageSenderText } from "./MessageSender";

export const MessageView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const { messageId } = useParams();

    const [message, setMessage] = React.useState<Message | undefined>(undefined);

    React.useEffect(() => {
        apiState.MessageClient.get(Number(messageId)).then(messageResponse => {
            if (messageResponse) {
                setMessage(messageResponse);
            }
        });
    }, [messageId]);

    function deleteMessage() {
        return apiState.MessageClient.delete(Number(messageId)).then(_ => {
            appState.navigate(messages)
        });
    }

    const body = message && (message.isAdmin ? <RichText text={message.body} /> : message.body);

    return <SimpleBaseView
        heading={{ text: "Messages", icon: IconHelper.General.Message }}
        headingContent={
            <BackNavButton to={messages} />
        }>
        {(!message || !playerState.Player) && <LoadingSpinner />}
        {message && playerState.Player &&
            <SubPanel
                heading={"Message"}
                headingContent={
                    <ButtonHolder>
                        {message.fromPlayer &&
                            <SendMessageButton overrideText="Reply" subject={`RE: ${message.subject}`} player={message.fromPlayer} />
                        }
                        {message && <DeleteButton action={() => deleteMessage()} confirm="Are you sure you want to delete this message?" />}
                    </ButtonHolder>
                }
            >
                <MessageSenderImageOnly message={message} />
                <div>
                    <H2>From <MessageSenderText message={message} /></H2><br className="is-hidden-tablet" /><br className="is-hidden-tablet" />
                    <Paragraph>Subject: {message.subject}</Paragraph>
                    <Paragraph>{ValueFormatter.formatTimeOrDate(message.date)}</Paragraph>
                </div>
                <hr />
                <div className="has-linebreaks">
                    <Linkify>{body}</Linkify>
                </div>
            </SubPanel>}
    </SimpleBaseView>
});