import React from "react";
import { ManufactoryNode } from "../../../../../ApplicationState/ApiClient";
import { H3 } from "../../../../../Components/Base/Text/H";
import { NodeButton } from "./NodeButton";

type Props = {
    type: "Input" | "Output",
    nodes: ManufactoryNode[] | undefined,
    selectedManufactoryNodeId: string | undefined,
    setSelectedManufactoryNodeId: (manufactoryNodeId: string) => any
}

export const NodeList = (props: Props) => {

    if (!props.nodes || props.nodes.length === 0) {
        return null;
    }

    return <div className={`${props.type.toLocaleLowerCase()}-nodes`}>
        <H3>{props.type}</H3>
        <div className="is-column-flex">
            {props.nodes.map(c => <NodeButton key={c.manufactoryNodeId}
                isSelected={props.selectedManufactoryNodeId === c.manufactoryNodeId}
                node={c}
                onClick={() => props.setSelectedManufactoryNodeId(c.manufactoryNodeId)}
            />)}
        </div>
    </div>;
};