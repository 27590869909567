import React from "react";
import { InstallationCategory } from "../../../../../ApplicationState/ApiClient";
import { TabMenu } from "../../../../../Components/Base/Containers/TabMenu";
import { Paragraph } from "../../../../../Components/Base/Text/Paragraph";
import { InstallationCategoryDescription } from "../../../../../Components/FusionShift/Descriptions/InstallationCategoryDescription";
import { InstallationCategoryIcon } from "../../../../../Components/FusionShift/Icons/InstallationCategoryIcon";
import { CollectionHelper } from "../../../../../Helpers/CollectionHelper";
import { CelestialViewProps } from "../CelestialViewProps";
import { BuildDescription } from "./BuildDescription";

export const BuildView = (props: CelestialViewProps) => {

    const [category, setCategory] = React.useState<InstallationCategory | undefined>(undefined);
    const [availableCategories, setAvailableCategories] = React.useState<InstallationCategory[] | undefined>(undefined);

    React.useEffect(() => {
        setAvailableCategories(undefined);
    }, [props.celestial]);

    if (!CollectionHelper.isAnyInArray(props.celestial.installationTypeNamesThatCanBeBuilt)) {
        return <Paragraph>
            Nothing can be built here.
        </Paragraph>;
    }

    const categoriesToUse: InstallationCategory[] = [];
    const installationsThatCanBeBuilt = props.celestial.installationTypeNamesThatCanBeBuilt!.map(t => {
        const installationType = props.installationTypeSettings!.data![t];

        if (!availableCategories && !categoriesToUse.includes(installationType.category)) {
            categoriesToUse.push(installationType.category);
        }
        return installationType;
    })
        .sort(((a, b) => a.order > b.order ? 1 : -1));

    if (!availableCategories && categoriesToUse.length > 0) {
        setAvailableCategories(categoriesToUse);
        if (category !== undefined && !categoriesToUse.includes(category)) {
            setCategory(categoriesToUse[0]);
        }
        if (category === undefined) {
            if (categoriesToUse.includes(InstallationCategory.Harvesting)) {
                setCategory(InstallationCategory.Harvesting);
            } else {
                setCategory(categoriesToUse[0]);
            }
        }
    }

    return <>
        <TabMenu
            active={category}
            changeTab={t => setCategory(t)}
            tabs={availableCategories ? availableCategories.map(c => {
                return {
                    id: c,
                    title: InstallationCategory[c],
                    icons: <InstallationCategoryIcon installationCategory={c} />,
                    isAvailable: true
                };
            }) : []}
        />
        {category !== undefined && <>
            <InstallationCategoryDescription installationCategory={category} />
            {installationsThatCanBeBuilt.filter(t => t.category === category)
                .map(t => <BuildDescription
                    key={t.typeName}
                    solarSystem={props.solarSystem}
                    installationType={t}
                    celestial={props.celestial}
                    reloadCallback={props.reloadCallback} />
                )}
        </>
        }
    </>;
};