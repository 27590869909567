import React from "react"
import { Portal } from "react-portal"
import { Property } from "csstype"

type Props = {
    title: string,
    bodyTextAlign?: Property.TextAlign,
    bodyContent: () => React.ReactNode,
    footerContent: () => React.ReactNode
}

export const Modal = (props: Props) => {
    return <Portal node={document && document.getElementById('modal-root')}>
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                </header>
                <section className="modal-card-body content" style={{ textAlign: props.bodyTextAlign ?? "center" }}>
                    {props.bodyContent()}
                </section>
                <footer className="modal-card-foot">
                    {props.footerContent()}
                </footer>
            </div>
        </div>
    </Portal>
}