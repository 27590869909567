import * as React from 'react';
import { ErrorMessageBox } from '../../Base/MessageBox';

type Props = {
    response: {
        wasSuccess: boolean;
        error: string;
    } | undefined
}

export const RequestResponseMessage = (props: Props) => {

    if (props.response === undefined || props.response.wasSuccess || props.response.error.length === 0) {
        return null;
    }

    return <ErrorMessageBox text={props.response.error} />;
};