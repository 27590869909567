import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IFleet, IMapSolarSystem } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { Coordinate } from '../../../Entities/Shared';
import { MapHelper } from '../MapHelper';
import { mapSize } from '../MapSize';
import { RightClick } from '../RightClick';
import { Cell, MapCellRender } from './Cell';
import { GridStore } from './GridStore';
import { MapPerspective } from './MapEntities';

type Props = {
    coord: Coordinate,
    celestialTypeName: string | undefined,
    grid: GridStore,
    perspective: MapPerspective,
    fleets: IFleet[],
    selectedCoord: Coordinate | undefined,
    setSelectedSolarSystem: (solarSystem: IMapSolarSystem | undefined) => any
    reloadCallback?: (() => any) | undefined
}

function starsOffset(coord: Coordinate) {
    const wrapBy = mapSize.fullhd;
    function wrap(i) {
        while (i >= wrapBy) {
            i -= wrapBy;
        }
        while (i < 0) {
            i += wrapBy;
        }
        return i;
    }

    const x = wrap(-coord.x);
    const y = wrap(-coord.y);

    return `x-${x} y-${y}`;
}


export const Grid = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);

    const ref = React.useRef(null);

    function calculateBreakpoint(pos: number, min: number, max: number) {

        const mid = ((max - min) / 2.0) + min;
        const diff = Math.abs(pos - mid);
        let toUse = mapSize.none;

        for (let item in mapSize) {
            const asNum = Number(item);
            if (!isNaN(asNum)) {
                if (asNum / 2 < diff) {
                    toUse = mapSize[item as keyof mapSize];
                }
            }
        }
        return toUse;
    }

    const mapArray: Cell[][] = [];

    const diff = MapHelper.coordinateDiff(props.coord, props.perspective);

    // The current map position may have been moved before the data has been fetched from the API
    // Offset the rendering position and draw based on the previous data until the fetch is complete
    const yStart = props.perspective.minY + diff.y;
    const xStart = props.perspective.minX + diff.x;

    for (let y = yStart; y < yStart + props.perspective.height; y++) {
        let row: Cell[] = [];
        for (let x = xStart; x < xStart + props.perspective.width; x++) {

            const solarSystem = x in props.grid.cells && y in props.grid.cells[x] ? props.grid.cells[x][y] : undefined;

            const isLoaded = solarSystem !== undefined || props.grid.isLoaded(x, y);

            const fleets = solarSystem ? props.fleets.filter(m => m.targetSolarSystem && m.targetSolarSystem.solarSystemId === solarSystem.solarSystemId) : [];
            const hideFor = calculateBreakpoint(x, xStart, xStart + props.perspective.width);

            row.push({
                x: x,
                y: y,
                solarSystem: solarSystem,
                fleets: fleets,
                sizeFor: hideFor,
                celestialTypeName: props.celestialTypeName,
                isLoaded
            });
        }
        mapArray.push(row);
    }

    const starsClassName = starsOffset(props.coord);

    return <>
        <table className={`map stars ${starsClassName}`} ref={ref}>
            <tbody>
                {mapArray.map((row, rowIndex) =>
                    <tr key={rowIndex}>
                        {row.map((cell: Cell) => <MapCellRender key={cell.x}
                            cell={cell}
                            isSelected={props.selectedCoord !== undefined && cell.x === props.selectedCoord.x && cell.y === props.selectedCoord.y}
                            setSelectedSolarSystem={props.setSelectedSolarSystem}
                            player={playerState.Player}
                            currentSolarSystem={solarSystemState.SolarSystem}
                        />)}
                    </tr>)
                }
            </tbody>
        </table>
        <RightClick
            parentRef={ref}
            reloadCallback={props.reloadCallback}
            grid={props.grid}
        />
    </>;
});