import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AutomationInput, FleetMovementType, InstallationAutomation, IShipTypeSettings, ISolarSystem, ShipConstructionAutomation, SolarSystemAutomationConfiguration } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, SolarSystemStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { NumberInput } from '../../../Components/Base/Form/Input';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { SuccessMessageBox } from '../../../Components/Base/MessageBox';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { ShipTypeImage } from '../../../Components/FusionShift/Images/ShipTypeImage';
import { ShipTypeLink } from '../../../Components/FusionShift/Links/ShipTypeLink';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ShipTypeQuantity } from '../../SendShips/Components/ShipInputList';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type SolarSystemProps = {
    factionTypeName: string,
    solarSystem: ISolarSystem,
    shipTypeSettings: IShipTypeSettings,
    automation: ModifiedAutomation,
    setAutomation: (config: ModifiedAutomation) => any
}

const SolarSystemAutomationEdit = observer((props: SolarSystemProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);
    const [automation, setAutomation] = React.useState(props.automation);

    function setEnabled(isEnabled: boolean) {

        const updated = {
            ...automation,
            isEnabled
        };

        setAutomation(updated);

        props.setAutomation(updated);
    }

    function setInstallationEnabled(isEnabled: boolean) {

        const installations = new InstallationAutomation({
            ...automation.configuration.installations,
            isEnabled: isEnabled
        });

        const configuration = new SolarSystemAutomationConfiguration({
            ...automation.configuration,
            installations: installations
        });

        const updated = {
            ...automation,
            configuration: configuration
        };

        setAutomation(updated);

        props.setAutomation(updated);
    }

    function setShipEnabled(isEnabled: boolean) {

        const ships = new ShipConstructionAutomation({
            ...automation.configuration.shipConstruction,
            isEnabled: isEnabled
        });

        const configuration = new SolarSystemAutomationConfiguration({
            ...automation.configuration,
            shipConstruction: ships
        });

        const updated = {
            ...automation,
            configuration: configuration
        };

        setAutomation(updated);

        props.setAutomation(updated);
    }

    function modifyShip(shipTypeName: string, quantity: number) {
        const shipQuantities = automation.configuration.shipConstruction.shipTypeNamesToMaintain;
        shipQuantities[shipTypeName] = quantity;

        const ships = new ShipConstructionAutomation({
            ...automation.configuration.shipConstruction,
            shipTypeNamesToMaintain: shipQuantities
        });

        const configuration = new SolarSystemAutomationConfiguration({
            ...automation.configuration,
            shipConstruction: ships
        });

        const updated = {
            ...automation,
            configuration: configuration
        };

        setAutomation(updated);

        props.setAutomation(updated);
    }

    const ships = Object.values(props.shipTypeSettings.data)
        .filter(x => x.factionTypeName === props.factionTypeName)
        .map(x => {
            const ship: ShipTypeQuantity = {
                quantity: x.typeName in automation.configuration.shipConstruction.shipTypeNamesToMaintain ? automation.configuration.shipConstruction.shipTypeNamesToMaintain[x.typeName] : 0,
                ship: x
            };

            return ship;
        })
        .sort(((a, b) => a.ship.order > b.ship.order ? 1 : -1));

    return <>
        <tr>
            <td colSpan={2}>
                <SelectSolarSystemLink solarSystem={props.solarSystem} />
            </td>
            <td>
                <span className='is-pulled-right'>
                    <ButtonHolder className="button-chooser">
                        <Button
                            type="nav"
                            icon={IconHelper.General.Off}
                            text="Off"
                            isSelected={!automation.isEnabled}
                            action={() => setEnabled(false)}
                            hideTextOnMobile
                        />
                        <Button
                            type="nav"
                            icon={IconHelper.General.On}
                            text="On"
                            isSelected={automation.isEnabled}
                            action={() => setEnabled(true)}
                            hideTextOnMobile
                        />
                        <Button
                            type="nav"
                            icon={IconHelper.Installations.Installation}
                            text="Installations"
                            isSelected={automation.configuration.installations.isEnabled}
                            isDisabled={!automation.isEnabled}
                            action={() => setInstallationEnabled(!automation.configuration.installations.isEnabled)}
                            hideTextOnMobile
                        />
                        <Button
                            type="nav"
                            icon={IconHelper.Ships.Ship}
                            text="Ships"
                            isSelected={automation.configuration.shipConstruction.isEnabled}
                            isDisabled={!automation.isEnabled}
                            action={() => setShipEnabled(!automation.configuration.shipConstruction.isEnabled)}
                            hideTextOnMobile
                        />
                        <ExpandButton
                            isExpanded={isExpanded}
                            setIsExpanded={setIsExpanded}
                            isDisabled={!(automation.isEnabled && automation.configuration.shipConstruction.isEnabled)}
                        />
                    </ButtonHolder>
                </span>
            </td>
        </tr>
        {automation.isEnabled && automation.configuration.shipConstruction.isEnabled && isExpanded && <>
            {ships.map(x => <tr key={x.ship.typeName}>
                <td>
                    <ShipTypeImage shipType={x.ship} size='tiny' />
                </td>
                <td>
                    <ShipTypeLink shipType={x.ship} hideTextOnMobile />
                </td>
                <td>
                    <NumberInput
                        size={5}
                        value={x.quantity}
                        valueChanged={value => modifyShip(x.ship.typeName, value)}
                    />
                </td>

            </tr>)}
        </>}
    </>
});

const reducer = (state: "Nothing" | "Done" | number[], loaded: number | number[]): "Nothing" | "Done" | number[] => {

    // If an array of Ids is provided then start loading those
    if (typeof loaded !== "number") {
        return loaded;
    }

    // State shouldn't be anything other than an array but check anyway
    if (state !== "Nothing" && state !== "Done") {
        // Remove the completed id from the state array
        state = state.filter(x => x !== loaded);

        if (state.length === 0) {
            return "Done";
        }

        // More to load so carry on
        return state;
    }

    // Probably shouldn't get here
    return "Done";
}

type ModifiedAutomation = {
    isEnabled: boolean,
    configuration: SolarSystemAutomationConfiguration
}

export const AutomationView = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [state, dispatchState] = React.useReducer(reducer, "Nothing");
    const [modifiedAutomations, setModifiedAutomations] = React.useState<{ [key: number]: ModifiedAutomation }>({});
    const [isDirty, setIsDirty] = React.useState(false);

    function modify(solarSystemId: number, config: ModifiedAutomation) {
        modifiedAutomations[solarSystemId] = config;

        setModifiedAutomations(modifiedAutomations);
        setIsDirty(true);
    }

    function submit() {
        const toSave = Object.keys(modifiedAutomations).map(x => Number(x));

        if (toSave.length === 0) {
            return;
        }

        dispatchState(toSave);

        for (const solarSystemId of toSave) {
            const configuration = modifiedAutomations[solarSystemId];

            apiState.SolarSystemClient.setAutomation(solarSystemId, new AutomationInput(configuration))
                .then(s => {
                    solarSystemState.loadSolarSystem(s);
                })
                .finally(() => {
                    dispatchState(solarSystemId);
                });
        }

        setModifiedAutomations({});
    }

    if (!solarSystemState.SolarSystems || !worldState.ShipTypeSettings) {
        return null;
    }

    return <>
        {state === "Done" && <SuccessMessageBox text="Saved automation configuration" />}
        {state !== "Done" && state !== "Nothing" && <>
            <Paragraph>Saving {state.length} {ValueFormatter.pluralize("automation configuration", state.length)}...</Paragraph>
            <LoadingSpinner />
        </>}
        {(state === "Done" || state === "Nothing") && <Table
            numberOfColumns={3}
            className='form'
            isFullWidth isFixed
            footer={
                <div className='is-pulled-right is-flex'>
                    <Button
                        type="action"
                        text='Save'
                        icon={IconHelper.Ships.movementType(FleetMovementType.Rebase)}
                        action={submit}
                        forceClip='bottom-right'
                        isDisabled={!isDirty}
                    />
                </div>}
        >
            {props.solarSystems.map(x => <SolarSystemAutomationEdit
                key={x.solarSystemId}
                factionTypeName={playerState.Player?.factionTypeName ?? ""}
                solarSystem={x.solarSystem}
                shipTypeSettings={worldState.ShipTypeSettings!}
                automation={x.solarSystemId in modifiedAutomations ? modifiedAutomations[x.solarSystemId] : x.solarSystem.automation!}
                setAutomation={config => modify(x.solarSystemId, config)}
            />)}
        </Table>}
    </>;
});