import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Accolade, AccoladeType } from '../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../ApplicationState/ContextRoot';
import { ItemsControl } from '../../Components/FusionShift/Items/ItemsControl';
import { buildShips, ShipListInner } from '../../Components/FusionShift/Ships/ShipList';
import { CollectionHelper } from '../../Helpers/CollectionHelper';

type Props = {
    accolade: Accolade
}

export const AccoladeDetail = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ShipTypeSettings || !CollectionHelper.isAnyQuantityInDictionary(props.accolade.scoreDetail) || !props.accolade.scoreDetail) {
        return null;
    }

    if (props.accolade.accoladeType === AccoladeType.Raider) {
        const itemKeys = Object.keys(props.accolade.scoreDetail)
            .filter(k => k in worldState.ItemTypeSettings!.data);

        const items: { [key: string]: number } = {};

        for (let k of itemKeys) {
            items[k] = props.accolade.scoreDetail[k];
        }

        return <ItemsControl items={items} />
    }

    if (props.accolade.accoladeType === AccoladeType.Attacker || props.accolade.accoladeType === AccoladeType.Defender) {

        const allShips = buildShips(worldState.ShipTypeSettings, props.accolade.scoreDetail);
        if (allShips) {
            const factionTypeNames: string[] = [];
            for (let c of allShips) {
                if (!factionTypeNames.includes(c.ship.factionTypeName)) {
                    factionTypeNames.push(c.ship.factionTypeName)
                }
            }
            return <div className="is-flex">
                {factionTypeNames.map(c => <ShipListInner key={c} ships={allShips.filter(s => s.ship.factionTypeName === c)} />)}
            </div>
        }
    }
    return null;
});