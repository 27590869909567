import * as React from 'react';

type Props = {
    vote: boolean | null | undefined
}

export const PolicyVoteDescription = (props: Props) => {

    if (props.vote === true) {
        return <span className="has-text-success">Yea</span>;
    }

    if (props.vote === false) {
        return <span className="has-text-danger">Nay</span>;
    }

    return <span className="has-text-warning">Abstain</span>;
}