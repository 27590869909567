import classNames from "classnames"
import { Button, ButtonAction, ButtonTypes } from "./Button"

export type Props = {
    icon: string,
    confirm?: string,
    isSelected?: boolean,
    isDisabled?: boolean,
    className?: string
    isTiny?: boolean,
    isPulledRight?: boolean,
    isSmall?: boolean,
    scrollToTop?: boolean,
    isSubmit?: boolean,
    title?: string,
    isGiant?: boolean,
    type: ButtonTypes,
    action: ButtonAction
}

export const IconButton = (props: Props) => {

    const className = classNames("icon-button",
        {
            "is-size-1": props.isGiant
        },
        props.className);

    return <Button
        {...props}
        className={className}
    />;
}