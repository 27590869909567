import React from "react";
import { Link } from "react-router-dom";

type Props = {
    icon: string,
    to: string,
    className?: string,
    colour?: string,
    title?: string,
    onClick?: (() => any) | undefined
}

export const IconLink = (props: Props) => {

    function click() {
        if (props.onClick !== undefined) {
            props.onClick();
        }
    }

    return (
        <span className={"icon " + props.className} style={{ color: props.colour }} title={props.title}>
            <Link to={props.to} className={props.className ? props.className : ""} onClick={click} >
                <i className={"mdi mdi-" + props.icon} aria-hidden="true" />
            </Link>
        </span>
    );
};


IconLink.defaultProps = {
    className: "",
    colour: "",
    title: ""
};