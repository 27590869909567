import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FederationRank, IFederation, IPlayer, IPlayerMinimal } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { HeadingContent } from '../../../Components/Base/Containers/PanelHeading';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { PlayerEmblemAndLink } from '../../../Components/FusionShift/Images/EmblemImage';
import { FactionTypeImage } from '../../../Components/FusionShift/Images/FactionTypeImage';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    federation: IFederation,
    heading: HeadingContent,
    players: IPlayer[] | undefined,
    controls: (player: IPlayer) => React.ReactNode,
    showRank?: boolean,
    showControlsInsideExpand?: boolean,
    showControlsForOfficers?: boolean,
    hideControls?: boolean
}

type RowProps = {
    federation: IFederation,
    player: IPlayer,
    observingPlayer: IPlayerMinimal | undefined,
    controls: (player: IPlayer) => React.ReactNode,
    showRank?: boolean,
    rank: FederationRank,
    showControlsInsideExpand?: boolean,
    showControlsForOfficers?: boolean,
    hideControls?: boolean
}

const PlayerRow = (props: RowProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);

    const p = props.player;
    const isSelf = p.playerId == props?.observingPlayer?.playerId;

    const isLeaderOrOfficer = props.rank !== FederationRank.Member;

    return <>
        <tr>
            <td>
                {<PlayerEmblemAndLink player={p} hideFederationLink />}
            </td>
            {props.showRank &&
                <td className="is-hidden-mobile">
                    {p.rank}
                </td>
            }
            <td>
                {ValueFormatter.formatSize(p.size)}
            </td>
            <td className="is-hidden-touch">
                {ValueFormatter.formatPopulation(p.population)}
            </td>
            <td className="is-hidden-touch">
                {p.numberOfSolarSystems}
            </td>
            <td className="is-hidden-touch">
                <FactionTypeImage factionTypeName={p.factionTypeName} size="tiny" onlyLogo />
            </td>
            <td>
                {!props.hideControls && (props.rank === FederationRank.Leader || (props.showControlsForOfficers && isLeaderOrOfficer)) &&
                    <>
                        {!isSelf && props.showControlsInsideExpand && <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />}
                        {!isSelf && !props.showControlsInsideExpand && props.controls(p)}
                    </>
                }
            </td>
        </tr>
        {isExpanded && props.showControlsInsideExpand && <tr>
            <td colSpan={100}>
                {props.controls(p)}
            </td>
        </tr>}
    </>
}

export const PlayerList = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (props.players === undefined || props.players.length === 0 || playerState.Player === undefined) {
        return null;
    }

    const isSameFederation = props.federation.federationId === playerState.Player?.federation?.federationId;
    const observingRank = isSameFederation ? playerState.Player?.federation?.federationRank ?? FederationRank.Member : FederationRank.Member;

    return <SubPanel
        heading={props.heading}
        isUnpadded>
        <Table isHoverable isFullWidth heading={
            <>
                <td>Player</td>
                {props.showRank &&
                    <td className="is-hidden-mobile">Rank</td>
                }
                <td>Size</td>
                <td className="is-hidden-touch">Pop.</td>
                <td className="is-hidden-touch">Systems</td>
                <td className="is-hidden-touch">Faction</td>
                <td></td>
            </>
        }>
            {props.players.map(p => <PlayerRow
                key={p.playerId}
                player={p}
                {...props}
                observingPlayer={playerState.Player}
                rank={observingRank}
            />)}
        </Table>
    </SubPanel>;
});
