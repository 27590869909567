import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, IFleet } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { RelationHelper } from '../../../Helpers/RelationHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { FleetMovementTypeIcon } from './FleetMovementTypeIcon';

type Props = {
    fleets: IFleet[] | false | undefined,
    except?: FleetMovementType | undefined,
    showCount?: boolean
}

export const FleetSummaryIcons = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!props.fleets || !playerState.Player) {
        return null;
    }

    const movementTypes: {
        movementType: FleetMovementType,
        isPlayerAllied: boolean,
        isPlayerOwner: boolean,
        count: number
    }[] = [];

    for (let s of props.fleets) {

        const isPlayerOwner = s.ownerPlayerId === playerState.Player.playerId;
        const movementType = s.movementType;
        const isPlayerAllied = RelationHelper.isAlliedWith(playerState.Player, s.owner);

        const index = movementTypes.findIndex(m =>
            m.movementType === movementType &&
            m.isPlayerAllied === isPlayerAllied &&
            m.isPlayerAllied === m.isPlayerAllied);

        if (movementType === props.except) {
            continue;
        }

        if (index < 0) {
            movementTypes.push({ movementType, isPlayerOwner, isPlayerAllied, count: 1 });
        } else {
            const existing = movementTypes[index];
            movementTypes[index] = {
                ...existing,
                count: existing.count + 1
            };
        }
    }

    return <>
        {movementTypes.map((m, i) => <span key={i}>
            <FleetMovementTypeIcon key={m.movementType} {...m} />
            {props.showCount && ValueFormatter.formatLocaleNumber(m.count)}
        </span>)}
    </>;
});