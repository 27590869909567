import * as React from 'react';
import { SolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { AppStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { ErrorMessageBox, SuccessMessageBox } from '../../../Components/Base/MessageBox';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { selected_system_arrivals } from '../../../Navigation/Routing/SolarSystem';
import { FleetTable } from '../../Fleets/SubParts/FleetTable';
import { PostSendShipTarget } from '../SendShipsBag';
import { SendShipsResult } from '../SendShipsForm';
import { DurationStepsRender } from './DurationSteps';
import { PostSendCreateRepeat } from './PostSendCreateRepeat';

type Props = {
    result: SendShipsResult,
    thenGoTo: (target: PostSendShipTarget) => any
}

const Buttons = (props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    const targetName = props.result.targetSolarSystem ? props.result.targetSolarSystem.name : props.result.targetCoordinate ? `${props.result.targetCoordinate.x}, ${props.result.targetCoordinate.y}` : undefined;
    const mapName = targetName !== undefined ? `Map at ${targetName}` : `Map`;

    return <ButtonHolder isVerticalOnMobile>
        <Button type="nav" text="System" icon={IconHelper.Categories.Home} action={() => props.thenGoTo("System")} />
        {!props.result.wasEditRepeat &&
            <Button type="nav" text="Send Another" icon={IconHelper.Ships.Repeat} action={() => props.thenGoTo("Send Another")} />
        }
        {props.result.showRepeat &&
            <Button type="nav" text="Repeats" icon={IconHelper.Ships.Repeat} action={() => props.thenGoTo("Repeats")} />
        }
        <Button type="nav" text={mapName} icon={IconHelper.Map.TacticalMap} action={() => props.thenGoTo("Map at Target")} />
        {solarSystemState.SolarSystems && solarSystemState.SolarSystems.length > 1 &&
            <Button type="nav" text={"Empire"} icon={IconHelper.Categories.Empire} action={() => props.thenGoTo("Empire")} />
        }
    </ButtonHolder>;
}

export const PostSendShipsControl = (props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const appState = React.useContext(AppStateContext);

    const result = props.result;

    if (!result.wasSuccess) {
        return <SubPanel heading={{ text: "Failed to send", icon: IconHelper.Statuses.Error }}>
            <ErrorMessageBox text={result.error ?? "Unable to send ships"} />
            <div>
                <label className="label">
                    Then
                </label>
                <Buttons {...props} />
            </div>
        </SubPanel>;
    }

    function reloadCallback(solarSystem: SolarSystemDetail) {
        solarSystemState.loadSolarSystem(solarSystem);

        appState.navigate(selected_system_arrivals);
    }

    const description = result ?
        result.wasEditRepeat ? "Edited repeat fleet" :
            ValueFormatter.friendlyDescriptionOfFleetDeparture(result.movementType) : "";

    return <SubPanel heading={{ text: "Success", icon: IconHelper.General.Confirm }}>
        <SuccessMessageBox text={`${description}.`} />
        {!!result.duration && <DurationStepsRender steps={result.duration} movementType={result.movementType} />}
        {result.fleets !== undefined && result.fleets.length > 0 &&
            <FleetTable solarSystemId={result.sourceSolarSystemId} fleets={result.fleets} reloadCallback={reloadCallback} availableShips={FleetWrapper.empty()}
                display={{
                    arrivalDate: true,
                    target: true
                }} />
        }
        <div>
            <label className="label">
                Then
            </label>
            <Buttons {...props} />
        </div>
        <PostSendCreateRepeat {...props} />
    </SubPanel>;
};