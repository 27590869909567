import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SolarSystemDetail } from '../../ApplicationState/ApiClient';
import { SolarSystemStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { PanelWithTabs } from '../../Components/Base/Containers/PanelWithTabs';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { EmpireCategoryIcon } from '../../Components/FusionShift/Icons/EmpireCategoryIcon';
import { ProblemCategory } from '../../Entities/SolarSystem/Problems/Problem';
import { IconHelper } from '../../Helpers/IconHelper';
import { valuesFromEnum } from '../../Helpers/TypeHelper';
import { BaseViewWrapper } from '../BaseView';
import { AdvisorAndAutomation } from './Advisor/AdvisorAndAutomation';
import { EconomyView } from './Economy/EconomyView';
import { MilitaryView } from './Military/MilitaryView';
import { NetworkView } from './Network/NetworkView';
import { SummaryView } from './Summary/SummaryView';
import { MegacitiesView } from './Views/MegacitiesView';

export type EmpireCategory = "Summary" | "Network" | "Advisor" | ProblemCategory;

function allCategories(): EmpireCategory[] {
    const categories: EmpireCategory[] = [
        "Summary",
        "Network",
        "Advisor"
    ];
    return categories.concat(valuesFromEnum(ProblemCategory));
}

export const EmpireView = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);
    const [category, setCategory] = React.useState<EmpireCategory>("Summary");

    const tabs = allCategories()
        .filter(c => c !== ProblemCategory.Ships && c !== ProblemCategory.Manufactories)
        .map(t => {
            return {
                id: t,
                icons: <EmpireCategoryIcon category={t} />,
                title: typeof t === "string" ? t : ProblemCategory[t],
                isAvailable: true
            };
        });

    function reloadCallback(solarSystem: SolarSystemDetail) {
        solarSystemState.loadSolarSystem(solarSystem);
    }

    const solarSystems = solarSystemState.SolarSystemsDetail;

    if (
        !solarSystems ||
        !worldState.GameSettings || !worldState.Effects ||
        !worldState.ShipTypeSettings ||
        !worldState.InstallationTypeSettings ||
        !worldState.ItemTypeSettings) {
        return <LoadingSpinner />;
    }

    const sharedProps = {
        reloadCallback,
        solarSystems,
        installationTypeSettings: worldState.InstallationTypeSettings,
        shipTypeSettings: worldState.ShipTypeSettings,
        itemTypeSettings: worldState.ItemTypeSettings
    }

    return <BaseViewWrapper>
        <PanelWithTabs
            heading={{ text: "Empire", icon: IconHelper.Categories.Empire }}
            tabs={tabs}
            active={category}
            setActive={setCategory}
            idPrefix="solar-system"
        >
            {category === "Summary" && <SummaryView {...sharedProps} />}
            {category === "Network" && <NetworkView {...sharedProps} />}
            {category === "Advisor" && <AdvisorAndAutomation {...sharedProps} />}
            {category === ProblemCategory.Economy && <EconomyView {...sharedProps} />}
            {category === ProblemCategory.Military && <MilitaryView {...sharedProps} />}
            {category === ProblemCategory.Megacities && <MegacitiesView {...sharedProps} />}
        </PanelWithTabs>
    </BaseViewWrapper>;
});