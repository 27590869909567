import { InstallationCategory } from "../../ApplicationState/ApiClient";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class InstallationIcons {

    public get Installation() { return MaterialDesignIcons.buildings };
    public get Build() { return MaterialDesignIcons.hammer; };
    public get EmptySlot() { return MaterialDesignIcons.gridPlus; };
    public get Deconstruct() { return MaterialDesignIcons.delete; };
    public get Upgrade() { return MaterialDesignIcons.arrowUp; };
    public get Damaged() { return MaterialDesignIcons.fire; };
    public get NotDamaged() { return MaterialDesignIcons.heart; };
    public get OperationLevel() { return MaterialDesignIcons.chartBar; }

    public installationCategory(installationCategory: InstallationCategory): string {
        switch (installationCategory) {
            case InstallationCategory.Government:
                return MaterialDesignIcons.bank;
            case InstallationCategory.Military:
                return MaterialDesignIcons.sword;
            case InstallationCategory.Manufactory:
                return MaterialDesignIcons.factory;
            case InstallationCategory.Misc:
                return "";
            case InstallationCategory.Economy:
                return MaterialDesignIcons.cube;
            case InstallationCategory.Harvesting:
                return MaterialDesignIcons.basket;
            case InstallationCategory.Megacity:
                return MaterialDesignIcons.city;
        }
        return MaterialDesignIcons.missing;
    }
}