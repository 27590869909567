import { HelpType } from "../../ApplicationState/ApiClient";
import { TextAndIcon } from "../../Components/Base/Text/TextAndIcon";
import { IconHelper } from "../../Helpers/IconHelper";
import { TextHelper } from "../../Helpers/TextHelper";

type Props = {
    helpType?: HelpType,
    text?: string,
    icon?: string
}

export const HelpTitle = (props: Props) => {

    const text = props.text !== undefined && props.text.length > 0 ? props.text :
        props.helpType !== undefined ? TextHelper.tidyText(props.helpType.name) :
            "Not Found";

    const icon = props.icon !== undefined && props.icon.length > 0 ? props.icon : IconHelper.General.Help;

    return {
        content: <TextAndIcon icon={icon} text={<><span className="is-hidden-mobile">Help: </span>{text}</>} />
    };
}