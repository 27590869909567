import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IPlayer, ITerms, ValidationResult } from '../../../ApplicationState/ApiClient';
import { CreditsStateContext, WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { Checkbox } from '../../../Components/Base/Form/Checkbox';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { FormFooterValidation } from '../../../Components/Base/Form/FormFooter';
import { ValidationHelper } from '../../../Components/Base/Form/ValidationHelper';
import { CreditsIconWithQuantity } from '../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { valueValid } from '../../../Entities/Validation';
import { ContractCost, ContractHelper } from '../../../Helpers/ContractHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { Configuration } from '../CreateContractView';
import { TermsSummary } from '../TermsSummary';

type Props = {
    terms: ITerms,
    configuration: Configuration,
    setConfiguration: (configuration: Configuration) => any,
    validation: ValidationResult | undefined,
    submit: () => any,
    forPlayer: IPlayer | undefined
}

export const Summary = observer((props: Props) => {

    const creditsState = React.useContext(CreditsStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [payment, setPayment] = React.useState<ContractCost & {
        total: number,
        processingFee: number | undefined,
    } | undefined>(undefined);

    React.useEffect(() => {
        if (worldState.GameSettings) {
            const payment = ContractHelper.calculateAmountAndProcessingFee(props.terms, worldState.GameSettings);
            setPayment(payment);
        }
    }, [props.terms, worldState.GameSettings]);

    if (!worldState.GameSettings) {
        return null;
    }

    function calculateValidation() {
        if (props.validation !== undefined) {
            return props.validation;
        }
        if (payment === undefined || payment.amount === undefined || payment.amount <= 0) {
            return ValidationHelper.invalid("Invalid payment amount");
        }
        if (payment.total > creditsState.Balance) {
            return ValidationHelper.invalid("Not enough credits");
        }
    }

    const validation = calculateValidation();

    return <SubPanel heading={{ text: "Summary", icon: IconHelper.Categories.Summary }}
        footerContent={
            <Button type="action" action={props.submit} text={"Create Contract"} icon={IconHelper.General.Confirm} isDisabled={validation !== undefined && !validation.isValid} isPulledRight />
        }>
        {props.configuration.forPlayerId === undefined && <SplitLayout
            left={<Checkbox value={props.configuration.isFederationOnly} label={"Federation Only"} valueChanged={x => props.setConfiguration({ ...props.configuration, isFederationOnly: x })} />}
            right={<Checkbox value={props.configuration.areExilesAllowed} label={"Exiles Allowed"} valueChanged={x => props.setConfiguration({ ...props.configuration, areExilesAllowed: x })} />}
        />}
        {props.forPlayer !== undefined && <FieldHolder label="Player">
            <PlayerLink player={props.forPlayer} />
        </FieldHolder>}
        {(validation === undefined || validation.isValid) && <TermsSummary {...props} />}
        {payment !== undefined && payment.amount !== undefined &&
            <Table isFullWidth>
                <tr>
                    <td>
                        Contract Amount
                    </td>
                    <td>
                        <CreditsIconWithQuantity quantity={payment.amount} />
                    </td>
                    <td>
                        {payment.isTakenImmediately ? "Taken Immediately" : payment.isPaidByContractor ? "Paid by Contractor" : "Future Liability"}
                    </td>
                </tr>
                <tr>
                    <td>
                        {ValueFormatter.fromDecimalToDisplayPercent(worldState.GameSettings.economy.contractProcessingFeePercent)} Processing Fee
                    </td>
                    <td>
                        <CreditsIconWithQuantity quantity={payment.processingFee} />
                    </td>
                    <td className="has-text-danger">
                        Non-refundable
                    </td>
                </tr>
                <tr className="is-total">
                    <td>
                        Total
                    </td>
                    <td>
                        <CreditsIconWithQuantity quantity={payment.total} />
                    </td>
                    <td>
                        Taken Immediately
                    </td>
                </tr>
            </Table>}
        <FormFooterValidation validation={validation !== undefined ? valueValid(validation.isValid, validation.error) : undefined} />
    </SubPanel>;
});