import { CelestialFeatureIcon } from '../../../../../Components/FusionShift/Icons/CelestialFeatureIcon';
import { DroneHelper } from '../../../../../Helpers/DroneHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';
import { SummaryTile } from './SummaryTile';
import { SummaryTileProps } from './SummaryTileProps';

export const DronesTile = (props: SummaryTileProps) => {

    const summary = DroneHelper.droneSummary(props.installationTypeSettings, props.solarSystem);

    return <SummaryTile
        {...props}
        title="Drones"
        feature="Drones"
        thumbnail={<CelestialFeatureIcon celestialFeature={"Drones"} isGiant />}
    >
        <ul>
            <li>{ValueFormatter.formatLocaleNumber(summary.currentActive)} out of {ValueFormatter.formatLocaleNumber(summary.maxActive)} active drones</li>
            <li>{ValueFormatter.formatLocaleNumber(summary.currentInactive)} out of {ValueFormatter.formatLocaleNumber(summary.maxTotal - summary.maxActive)} inactive drones</li>
        </ul>
    </SummaryTile>;
};