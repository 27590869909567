import { observer } from "mobx-react-lite";
import React from "react";
import { Player } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { contract_createForPlayer } from "../../../Navigation/Routing/Contracts";
import { ButtonLink } from "../../Base/Buttons/ButtonLink";

type Props = {
    player: Player
}

export const ContractForPlayerButton = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (props.player.playerId === playerState.Player?.playerId) {
        return null;
    }

    const url = contract_createForPlayer(props.player.playerId);

    return <ButtonLink
        to={url}
        text="Offer Contract"
        icon={IconHelper.Economy.ContractCreate}
    />;
});