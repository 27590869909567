import * as React from 'react';
import { ItemCategory, ItemType } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { Icon } from '../../../Base/Icon';
import { CommodityTypeLink } from './CommodityTypeLink';
import { ComponentTypeLink } from './ComponentTypeLink';
import { RawResourceTypeLink } from './RawResourceTypeLink';
import { RelicTypeLink } from './RelicTypeLink';
import { ResourceTypeLink } from './ResourceTypeLink';

type Props = {
    itemType: ItemType,
    hideTextOnMobile?: boolean,
    noText?: boolean,
    maxLength?: number
}

export const ItemTypeLink = (props: Props) => {


    if (props.itemType.category === ItemCategory.Commodity) {
        return <CommodityTypeLink commodityType={props.itemType} {...props} />
    }

    if (props.itemType.category === ItemCategory.Resource) {
        return <ResourceTypeLink resourceType={props.itemType} {...props} />;
    }

    if (props.itemType.category === ItemCategory.RawResource) {
        return <RawResourceTypeLink rawResourceType={props.itemType} {...props} />;
    }

    if (props.itemType.category === ItemCategory.Component) {
        return <ComponentTypeLink componentType={props.itemType} {...props} />;
    }

    if (props.itemType.category === ItemCategory.Relic) {
        return <RelicTypeLink relicType={props.itemType} {...props} />;
    }

    return null;
};

type PropsFromTypeName = {
    itemTypeName?: string | undefined,
    hideTextOnMobile?: boolean,
    noText?: boolean
}

export const ItemTypeLinkFromTypeName = (props: PropsFromTypeName) => {

    const worldState = React.useContext(WorldStateContext);

    if (!props.itemTypeName || !worldState.ItemTypeSettings || !worldState.GameSettings) {
        return null;
    }

    if (props.itemTypeName === worldState.GameSettings.economy.creditsPlaceholderItemTypeName) {
        return <Icon icon={IconHelper.Economy.Credits} />;
    }

    if (!(props.itemTypeName in worldState.ItemTypeSettings.data)) {
        return null;
    }

    return <ItemTypeLink itemType={worldState.ItemTypeSettings.data[props.itemTypeName]} {...props} />;
};