export const celestialsImageConfig = {
  "Barren": 3,
  "Crystalline": 54,
  "Gaseous": 8,
  "Habitable": 10,
  "HabitableOccupied": 183,
  "Mineral": 3,
  "Ores": 3,
  "RawGaseous": 6,
  "RawMineral": 1,
  "RawOres": 1,
  "RawVegetative": 1,
  "Unknown": 5,
  "Vegetative": 3
}