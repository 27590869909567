import { Link } from 'react-router-dom';
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Icon } from '../../../Components/Base/Icon';
import { PendingAgentActionIcon } from '../../../Components/FusionShift/Icons/UnreadIcons';
import { IconHelper } from '../../../Helpers/IconHelper';
import { agents } from '../../../Navigation/Routing/Agents';
import { map_galaxy, map_tactical } from '../../../Navigation/Routing/Map';
import { chat, fleets } from '../../../Navigation/Routing/Misc';

export const Links = () => {

    return <SubPanel heading="Links">
        <SplitLayout
            left={
                <ul>
                    <li>
                        <Link to={map_tactical}>Tactical Map <Icon icon={IconHelper.Map.TacticalMap} /></Link>
                    </li>
                    <li>
                        <Link to={map_galaxy}>Galaxy Map</Link>
                    </li>
                    <li>
                        <Link to={agents}>Agents </Link><PendingAgentActionIcon />
                    </li>
                </ul>}
            right={
                <ul>
                    <li>
                        <Link to={chat}>Chat <Icon icon={IconHelper.Chat.Chat} /></Link>
                    </li>
                    <li>
                        <Link to={fleets}>Fleets </Link>
                    </li>
                </ul>
            }
        />
    </SubPanel>;
};