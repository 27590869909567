import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class MegacityIcons {

    public get Megacity() { return MaterialDesignIcons.city; }
    public get Population() { return MaterialDesignIcons.accountGroup; }
    public get PopulationAssignment() { return MaterialDesignIcons.accountSwitch; }
    public get Sectors() { return MaterialDesignIcons.grid; }
    public get SellCommodities() { return MaterialDesignIcons.cubeSend; }

    public get PopulationAssignNone() { return MaterialDesignIcons.arrowDown; }
    public get PopulationAssignAll() { return MaterialDesignIcons.arrowUp; }

    public get Residential() { return MaterialDesignIcons.bedEmpty; }
    public get Agricultural() { return MaterialDesignIcons.wheat; }
    public get Industrial() { return MaterialDesignIcons.anvil; }
    public get Manufacturing() { return MaterialDesignIcons.factory; }
    public get Commercial() { return MaterialDesignIcons.cart; }
    public get Military() { return MaterialDesignIcons.castle; }
    public get Scientific() { return MaterialDesignIcons.computer; }

    public sectorType(sectorTypeName: string): string {
        switch (sectorTypeName) {
            case "Residential":
                return this.Residential;
            case "Agricultural":
                return this.Agricultural;
            case "Industrial":
                return this.Industrial;
            case "Manufacturing":
                return this.Manufacturing;
            case "Commercial":
                return this.Commercial;
            case "Military":
                return this.Military;
            case "Scientific":
                return this.Scientific;
        }
        return MaterialDesignIcons.missing;
    }
}