import * as React from 'react';
import { CelestialFeature } from '../../../Helpers/CelestialHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { HelpButton } from '../../Base/Buttons/HelpButton';

type Props = {
    celestialFeature: CelestialFeature | undefined
}

export const CelestialFeatureHelpButton = (props: Props) => {

    if (props.celestialFeature === undefined) {
        return null;
    }

    function buildTarget() {

        if (props.celestialFeature === undefined) {
            return "";
        }

        switch (props.celestialFeature) {
            case "Build":
                return "installations";
            case "Ships":
                return "shipTypes";
            case "Megacity":
                return "megacities";
            case "Manufactory":
                return "manufactories";
            case "Drones":
                return "drones";

        }

        if (props.celestialFeature.installationId !== undefined) {
            return "installations";
        }

        return undefined;
    }

    const to = buildTarget();

    if (to === undefined) {
        return null;
    }

    return <HelpButton to={to} text={`Help - ${ValueFormatter.spacesBeforeCapitals(to)}`} />;
};