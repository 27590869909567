import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Contract, IContract } from '../../../ApplicationState/ApiClient';
import { AppStateContext, SolarSystemStateContext } from '../../../ApplicationState/ContextRoot';
import { ViewButton } from '../../../Components/Base/Buttons/ViewButton';
import { Table } from '../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { handleOnClick } from '../../../Components/Base/Util';
import { CreditsIconWithQuantity } from '../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { PlayerLink } from '../../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { contractById } from '../../../Navigation/Routing/Contracts';
import { ContractStatusDescription } from '../Components/ContractStatusDescription';

type Result = IContract & {
    distance: number | undefined
}

type Props = {
    contracts: Contract[]
}

type RowProps = {
    contract: Result
}

const Row = (props: RowProps) => {

    const appState = React.useContext(AppStateContext);
    const showTo = props.contract.sourceSolarSystemId && props.contract.targetSolarSystemId;

    return <tr onClick={e => handleOnClick(e, () => appState.navigate(contractById(props.contract.contractId)))}>
        <td>
            <PlayerLink player={props.contract.player} />
        </td>
        <td>
            <CreditsIconWithQuantity quantity={props.contract.remainingCredits} />
        </td>
        <td>
            <ContractStatusDescription contractStatus={props.contract.status} />
        </td>
        <td>
            {props.contract.sourceSolarSystem !== undefined && <SolarSystemLink solarSystem={props.contract.sourceSolarSystem} />}
            {showTo && " to "}
            {props.contract.targetSolarSystem !== undefined && <SolarSystemLink solarSystem={props.contract.targetSolarSystem} />}
        </td>
        <td>
            {ValueFormatter.formatDistance(props.contract.distance)}
        </td>
        <td>
            <ViewButton to={contractById(props.contract.contractId)} />
        </td>
    </tr>;
}

export const ContractTable = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (props.contracts.length === 0) {
        return <Paragraph>No contracts matching the search parameters</Paragraph>;
    }


    function calculateDistance(contract: IContract) {
        if (solarSystemState.SolarSystem) {
            if (contract.sourceSolarSystem !== undefined) {
                return FleetHelper.distance(contract.sourceSolarSystem, solarSystemState.SolarSystem);
            }
            if (contract.targetSolarSystem !== undefined) {
                return FleetHelper.distance(contract.targetSolarSystem, solarSystemState.SolarSystem);
            }
        }

        return undefined;
    }

    const contractsWithDistance = props.contracts.map(x => {

        const distance = calculateDistance(x);

        return {
            ...x,
            distance
        }
    }).sort((a, b) => (!a.distance || !b.distance) ? 0 : a.distance - b.distance);

    return <Table isFullWidth isHoverable isClickable
        heading={
            <>
                <th>
                    Owner
                </th>
                <th>
                    Credits
                </th>
                <th>
                    Status
                </th>
                <th>
                    Location
                </th>
                <th>
                    Distance
                </th>
                <th></th>
            </>
        }>
        {contractsWithDistance.map(x => <Row key={x.contractId} {...props} contract={x} />)}
    </Table>;
});