import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ApiStateContext, AppStateContext, PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { IconHelper } from '../../../Helpers/IconHelper';
import { Button } from '../../Base/Buttons/Button';
import { ButtonHolder } from '../../Base/Buttons/ButtonHolder';
import { DismissButton } from '../../Base/Buttons/DismissButton';
import { InfoMessageBox } from '../../Base/MessageBox';

export const VeryifyEmailMessage = observer(() => {
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const apiState = React.useContext(ApiStateContext);

    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    const [isDismissed, setIsDismissed] = React.useState<boolean>(false);

    async function sendEmail() {
        await apiState.AuthenticationClient.sendVerifyEmail();

        setEmailSent(true);
    }

    if (!playerState.Player || appState.HasVerifiedEmail || isDismissed) {
        return null;
    }

    return <InfoMessageBox
        text="To unlock the full experience of Fusion Shift please to verify your email address"
        button={
            <ButtonHolder>
                <DismissButton action={() => setIsDismissed(true)} />
                <Button type="action" text={emailSent ? "Check your inbox" : "Send email"} icon={IconHelper.Account.Email} action={sendEmail} isDisabled={emailSent} isPulledRight />
            </ButtonHolder>}
    />
});