import * as React from 'react';
import { DroneStatus, FleetMovementType, ICelestialTypeSettings, IInstallationTypeSettings, IItemTypeSettings, IShipTypeSettings, ISolarSystemDetail, MakeActiveInput, ShipType, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../../../Components/Base/Text/Paragraph';
import { CelestialSelectOnly } from '../../../../../Components/FusionShift/Celestials/CelestialSelect';
import { ShipTypeLink } from '../../../../../Components/FusionShift/Links/ShipTypeLink';
import { Validation, valueValid } from '../../../../../Entities/Validation';
import { CollectionHelper } from '../../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type ExpandState = "none" | "activate" | "destroy";

type Props = {
    solarSystem: ISolarSystemDetail,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    canActivateAny: boolean,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type RowProps = {
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    solarSystem: ISolarSystemDetail,
    droneChange: DroneChange,
    expandState: ExpandState,
    change: (droneChange: DroneChange) => any
}

type DroneChange = {
    shipType: ShipType,
    available: number,
    quantity: number,
    celestialId?: number | undefined,
    valid: Validation
};

const DroneRow = (props: RowProps) => {

    function validate(shipType: ShipType, quantity: number, available: number, celestialId: number | undefined) {

        if (quantity <= 0) {
            return valueValid(false, "Quantity must be at least 1");
        }
        if (quantity > available) {
            return valueValid(false, `Quantity must not exceed available (${ValueFormatter.formatLocaleNumber(available)})`);
        }

        if (props.expandState === "activate") {
            if (shipType.miningStrength > 0) {
                const celestial = props.solarSystem.celestials.find(c => c.celestialId === celestialId);
                const celestialType = celestial && celestial.celestialTypeName && celestial.celestialTypeName in props.celestialTypeSettings.data ? props.celestialTypeSettings.data[celestial.celestialTypeName] : undefined;
                const valid = celestialType && celestialType.generatedItemTypeName in props.itemTypeSettings.data;

                if (!valid) {
                    return valueValid(false, "Select a celestial that generates resources");
                }
            }
        }

        return valueValid(true);
    }

    function change(droneChange: DroneChange) {
        const validation = validate(droneChange.shipType, droneChange.quantity, droneChange.available, droneChange.celestialId);

        props.change({
            ...droneChange,
            valid: validation
        });
    }

    function changeQuantity(newQuantity: number) {
        change({
            ...props.droneChange,
            quantity: newQuantity
        });
    }

    function changeCelestial(newCelestialId: number | undefined) {
        change({
            ...props.droneChange,
            celestialId: newCelestialId
        });
    }

    return <>
        <tr>
            <td><ShipTypeLink shipType={props.droneChange.shipType} /> </td>
            <td>
                {props.expandState === "none" && ValueFormatter.formatLocaleNumber(props.droneChange.available)}
                {props.expandState !== "none" &&
                    <div className="control">
                        <input
                            className="input is-small input-5"
                            type="number"
                            size={5}
                            onFocus={e => e.target.select()}
                            value={ValueFormatter.formatLocaleNumber(props.droneChange ? props.droneChange.quantity : 0)}
                            onChange={e => changeQuantity(Number(e.target.value))}
                        />
                    </div>
                }
            </td>
            {props.expandState === "activate" &&
                <td>
                    {props.droneChange.shipType.miningStrength > 0 &&
                        <CelestialSelectOnly celestialId={props.droneChange ? props.droneChange.celestialId : undefined} setCelestialId={changeCelestial} celestials={props.solarSystem.celestials} promptMovementType={FleetMovementType.Mine} />
                    }
                </td>
            }
        </tr>
        {props.expandState !== "none" && props.droneChange && !props.droneChange.valid.valid &&
            <tr>
                <td colSpan={props.expandState === "activate" ? 3 : 2}>
                    <Paragraph type="danger">
                        {props.droneChange.valid.value}
                    </Paragraph>
                </td>
            </tr>
        }
    </>;
};

export const InactiveControl = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const [expandState, setExpandState] = React.useState<ExpandState>("none");
    const [droneChanges, setDroneChanges] = React.useState<{ [key: string]: DroneChange }>({});

    const [valid, setValid] = React.useState(valueValid(false));

    React.useEffect(() => {
        const changes: { [key: string]: DroneChange } = {};

        if (props.shipTypeSettings && props.solarSystem.drones?.inactiveDrones !== undefined) {
            for (let droneTypeName in props.solarSystem.drones!.inactiveDrones!) {
                changes[droneTypeName] = {
                    available: props.solarSystem.drones!.inactiveDrones![droneTypeName],
                    shipType: props.shipTypeSettings.data[droneTypeName],
                    quantity: droneTypeName in droneChanges ? droneChanges[droneTypeName].quantity : 0,
                    valid: valueValid(false)
                };
            }
        }

        setDroneChanges(changes);
    }, [props.solarSystem.drones, props.shipTypeSettings, expandState]);

    function change(droneChange: DroneChange) {

        droneChanges[droneChange.shipType.typeName] = droneChange;

        const nonValid = Object.values(droneChanges).find(d => d.quantity > 0 && !d.valid.valid);
        if (nonValid) {
            setValid(nonValid.valid);
        } else {
            setValid(valueValid(true));
        }
    }

    function activate() {

        const input = Object.values(droneChanges).filter(x => x.quantity > 0).map(d => new MakeActiveInput({
            ...d,
            shipTypeName: d.shipType.typeName
        }));

        return apiState.DroneClient.makeDronesActive(props.solarSystem.solarSystemId, input).then(solarSystem => {
            if (solarSystem) {
                setExpandState("none");
                props.reloadCallback(solarSystem);
            }
        });
    }

    function destroy() {

        const input = CollectionHelper.selectFromDictionary(droneChanges, x => x.quantity);

        return apiState.DroneClient.destroyDrones(props.solarSystem.solarSystemId, input).then(solarSystem => {
            if (solarSystem) {
                setExpandState("none");
                props.reloadCallback(solarSystem);
            }
        });
    }

    if (!props.solarSystem.drones || !CollectionHelper.isAnyQuantityInDictionary(props.solarSystem.drones.inactiveDrones)) {
        return null;
    }

    return <SubPanel heading={`Inactive (${ValueFormatter.formatLocaleNumber(CollectionHelper.sumOfDictionary(props.solarSystem.drones.inactiveDrones))})`}
        isUnpadded
        headingContent={expandState === "none" &&
            <ButtonHolder>
                <Button hideTextOnMobile type="nav" isDisabled={!props.canActivateAny} text="Activate" action={() => setExpandState("activate")} icon={IconHelper.Ships.droneStatus(DroneStatus.Activating)} />
                <Button hideTextOnMobile type="nav" text="Destroy" action={() => setExpandState("destroy")} icon={IconHelper.General.Delete} />
            </ButtonHolder>
        }
        footerContent={expandState !== "none" && <ButtonHolder isPulledRight>
            <Button hideTextOnMobile type="nav" text="Cancel" action={() => setExpandState("none")} icon={IconHelper.General.Cancel} />
            {expandState === "activate" &&
                <Button hideTextOnMobile type="action" isDisabled={!valid.valid} text="Activate" action={() => activate()} icon={IconHelper.Ships.droneStatus(DroneStatus.Activating)} />
            }
            {expandState == "destroy" &&
                <Button hideTextOnMobile type="danger" isDisabled={!valid.valid} text="Destroy" action={() => destroy()} icon={IconHelper.General.Delete} />
            }
        </ButtonHolder>}
    >
        <Table isClickable={props.canActivateAny} isFullWidth heading={
            <>
                <th>Drone</th>
                <th>
                    {expandState === "none" && "Available"}
                    {expandState !== "none" && "Quantity"}
                </th>
                {expandState === "activate" &&
                    <th>
                        Celestial
                    </th>
                }
            </>
        }  >
            {Object.keys(droneChanges).map(d => <DroneRow key={d}
                droneChange={droneChanges[d]}
                expandState={expandState}
                change={change}
                {...props} />)}
        </Table>
    </SubPanel>;
};