import classNames from "classnames";
import React from "react";
import { EmblemPiece, InGameProduct } from "../../../../ApplicationState/ApiClient";
import { AppStateContext } from "../../../../ApplicationState/ContextRoot";
import { Icon } from "../../../../Components/Base/Icon";
import { IconHelper } from "../../../../Helpers/IconHelper";

type Props = {
    emblemsCdnUrl: string | undefined,
    emblem: EmblemPiece,
    premiumEmblem: InGameProduct | undefined,
    hasPurchased: boolean | undefined,
    isSelected: boolean,
    onEmblemBadgeClick: () => any
}

export const EmblemCard = (props: Props) => {

    const appState = React.useContext(AppStateContext);

    if (props.premiumEmblem !== undefined && !appState.IsPremiumEnabled) {
        return null;
    }

    const className = classNames("is-clickable",
        {
            "selected": props.isSelected
        }
    );

    return <div
        onClick={() => {
            props.onEmblemBadgeClick();
        }}
        className={className}
        key={props.emblem.displayName}
    >
        <div className={"template-card is-clickable"}>
            <span>
                {props.emblem.displayName}
                {props.premiumEmblem && props.hasPurchased && <Icon icon={IconHelper.Premium.ProductPurchased} />}
                {props.premiumEmblem && !props.hasPurchased && <Icon icon={IconHelper.Premium.PurchasedNotPurchased} />}
            </span>
            <img
                src={`${props.emblemsCdnUrl}${props.emblem.fileName}`}
                alt={props.emblem.fileName}
            ></img>
        </div>
    </div>
}