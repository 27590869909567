import { Link } from "react-router-dom";
import { DiscordLink } from "../Components/FusionShift/Links/DiscordLink";
import { BugReportLink, FeedbackLink } from "../Components/FusionShift/Links/SurveyLinks";
import { developedBy } from "./Routing/Misc";
import { VersionNumbers } from "./VersionNumbers";

export const Footer = () => {
    return <footer className="footer">
        <div>
            <div>
                <VersionNumbers />
            </div>
            <div >
                <Link to={developedBy}>Credits</Link>
            </div>
            <div>
                <DiscordLink />
            </div>
            <div>
                <FeedbackLink />
            </div>
            <div>
                <BugReportLink />
            </div>
        </div>
    </footer>;
}
