import { observer } from "mobx-react-lite";
import React from "react";
import { IAgentDetail } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CelestialHelper } from "../../../Helpers/CelestialHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { SolarSystemLink } from "./SolarSystemLink";

type Props = {
    agent: IAgentDetail,
    isBrief?: boolean
}

export const AgentLocationLink = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CelestialTypeSettings) {
        return null;
    }

    if (props.agent.celestialPosition !== undefined && props.agent.celestialPosition !== null) {
        return <SolarSystemLink solarSystem={props.agent.celestialPosition.solarSystem} extra={!props.isBrief ? CelestialHelper.fullname(props.agent.celestialPosition.celestial, worldState.CelestialTypeSettings) : undefined} />;
    }

    if (props.agent.fleetPosition !== undefined && props.agent.fleetPosition !== null) {
        return <>{`In fleet: ${ValueFormatter.formatLocaleNumber(props.agent.fleetPosition.currentX)}, ${ValueFormatter.formatLocaleNumber(props.agent.fleetPosition.currentY)}`}</>;
    }

    return <>UNKNOWN</>;
});