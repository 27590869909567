import { observer } from "mobx-react-lite";
import React from "react";
import { IconHint, SecurityStatus, ShipClass } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";
import { InstallationCategoryIcon } from "./InstallationCategoryIcon";
import { ItemTypeIcon } from "./Items/ItemTypeIcon";
import { SecurityStatusIcon } from "./SecurityStatusIcon";
import { ShipClassIcon } from "./ShipClassIcon";

type Props = {
    iconHint: IconHint | undefined,
    subType?: string | undefined,
    isGiant?: boolean
}

export const IconFromHint = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.ItemTypeSettings || !worldState.InstallationTypeSettings) {
        return null;
    }

    if (props.subType && props.iconHint !== undefined) {

        switch (props.iconHint) {
            case IconHint.ShipClass:
                const shipClass = ShipClass[props.subType] as any;
                return <ShipClassIcon shipClass={shipClass} isGiant={props.isGiant} />;

            case IconHint.Resource:
            case IconHint.Items:
            case IconHint.ItemType:
                return <ItemTypeIcon isGiant={props.isGiant} itemType={worldState.ItemTypeSettings.data[props.subType]} />;

            case IconHint.Installation:
                const installationType = worldState.InstallationTypeSettings.data[props.subType];
                return <InstallationCategoryIcon isGiant={props.isGiant} installationCategory={installationType.category} />;

            case IconHint.SecurityStatus:
                const securityStatus = SecurityStatus[props.subType] as any;
                return <SecurityStatusIcon securityStatus={securityStatus} isGiant={props.isGiant} />;
        }
    }

    return <Icon icon={IconHelper.iconHint(props.iconHint)} isGiant={props.isGiant} />;
});