import * as React from 'react';
import { AccoladeType, FederationAccoladeWithFederation, PlayerAccoladeWithPlayer } from '../../ApplicationState/ApiClient';
import { ExpandButton } from '../../Components/Base/Buttons/ExpandButton';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Table } from '../../Components/Base/Containers/Table';
import { AccoladeTypeIcon } from '../../Components/FusionShift/Icons/AccoladeTypeIcon';
import { EmblemImage } from '../../Components/FusionShift/Images/EmblemImage';
import { FederationLink } from '../../Components/FusionShift/Links/FederationLink';
import { PlayerLink } from '../../Components/FusionShift/Links/PlayerLink';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { AccoladeDetail } from './AccoladeDetail';
import { AccoladeTypeDescription } from './AccoladeTypeDescription';

type PlayerProps = {
    accolades: PlayerAccoladeWithPlayer[] | undefined
}

type PlayerTableProps = {
    accolades: PlayerAccoladeWithPlayer[],
    accoladeType: AccoladeType
}

type FederationProps = {
    accolades: FederationAccoladeWithFederation[] | undefined
}

type FederationTableProps = {
    accolades: FederationAccoladeWithFederation[],
    accoladeType: AccoladeType
}

type RowProps = {
    tryEmblem?: boolean,
    accolade: PlayerAccoladeWithPlayer | FederationAccoladeWithFederation
}

function accoladeTypeHasDetail(accoladeType: AccoladeType) {
    return accoladeType !== AccoladeType.Murderer &&
        accoladeType !== AccoladeType.Devastator;
}

const AccoladeRow = (props: RowProps) => {

    const hasDetail = accoladeTypeHasDetail(props.accolade.accoladeType);

    const [isExpanded, setIsExpanded] = React.useState(false);

    return <>
        <tr>
            {props.tryEmblem && <td className="is-content-width">
                {props.accolade instanceof PlayerAccoladeWithPlayer && props.accolade.player && <EmblemImage filename={props.accolade.player.emblemFilename} size="tiny" />}
            </td>}
            <td>
                {props.accolade.rank}
            </td>
            <td>
                {props.accolade instanceof PlayerAccoladeWithPlayer && <PlayerLink player={props.accolade.player} />}
                {props.accolade instanceof FederationAccoladeWithFederation && <FederationLink federation={props.accolade.federation} />}
            </td>
            <td>
                {props.accolade.accoladeType !== AccoladeType.Murderer && ValueFormatter.formatLocaleNumber(props.accolade.score)}
                {props.accolade.accoladeType === AccoladeType.Murderer && ValueFormatter.formatPopulation(props.accolade.score)}
            </td>
            {hasDetail &&
                <td>
                    <ExpandButton className="is-small is-pulled-right" isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </td>
            }
        </tr>
        {isExpanded && hasDetail &&
            <tr>
                <td colSpan={4}>
                    <AccoladeDetail accolade={props.accolade} />
                </td>
            </tr>}
    </>
};

const PlayerAccoladeTable = (props: PlayerTableProps) => {

    if (props.accolades.length === 0) {
        return null;
    }

    return <SubPanel heading={{ text: AccoladeType[props.accoladeType], icon: <AccoladeTypeIcon accoladeType={props.accoladeType} /> }}>
        <AccoladeTypeDescription {...props} />
        <Table isFullWidth isFixed heading={
            <>
                <th className="is-content-width"></th>
                <th>Rank</th>
                <th>Player</th>
                <th>Score</th>
                {accoladeTypeHasDetail(props.accoladeType) &&
                    <th></th>
                }
            </>
        }>
            {props.accolades.sort((a, b) => a.rank - b.rank)
                .map(a => <AccoladeRow key={a.playerAccoladeId} accolade={a} tryEmblem />)}
        </Table>
    </SubPanel>
};

export const PlayerAccoladesTables = (props: PlayerProps) => {

    if (!props.accolades || props.accolades.length === 0) {
        return null;
    }

    const accoladeTypes: AccoladeType[] = [];

    for (let a of props.accolades) {
        if (!accoladeTypes.includes(a.accoladeType)) {
            accoladeTypes.push(a.accoladeType);
        }
    }

    return <>
        {accoladeTypes
            .sort((a, b) => a - b)
            .map(a => <PlayerAccoladeTable key={a} accoladeType={a} accolades={props.accolades!.filter(b => b.accoladeType == a)} />)}
    </>;
};


const FederationAccoladeTable = (props: FederationTableProps) => {

    if (props.accolades.length === 0) {
        return null;
    }

    return <SubPanel heading={{ text: AccoladeType[props.accoladeType], icon: <AccoladeTypeIcon accoladeType={props.accoladeType} /> }}>
        <AccoladeTypeDescription {...props} />
        <Table isFullWidth isFixed heading={
            <>
                <th>Rank</th>
                <th>Player</th>
                <th>Score</th>
                {accoladeTypeHasDetail(props.accoladeType) &&
                    <th></th>
                }
            </>
        }>
            {props.accolades.sort((a, b) => a.rank - b.rank)
                .map(a => <AccoladeRow key={a.federationAccoladeId} accolade={a} />)}
        </Table>
    </SubPanel>
};

export const FederationAccoladesTables = (props: FederationProps) => {

    if (!props.accolades || props.accolades.length === 0) {
        return null;
    }

    const accoladeTypes: AccoladeType[] = [];

    for (let a of props.accolades) {
        if (!accoladeTypes.includes(a.accoladeType)) {
            accoladeTypes.push(a.accoladeType);
        }
    }

    return <>
        {accoladeTypes
            .sort((a, b) => a - b)
            .map(a => <FederationAccoladeTable key={a} accoladeType={a} accolades={props.accolades!.filter(b => b.accoladeType == a)} />)}
    </>;
};