import * as React from "react";
import { useLocation } from "react-router-dom";
import { ContractType, CreateInput, ICreateInput, IPlayer, ITerms, ValidationResult } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { ContractHelper } from "../../Helpers/ContractHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { UrlHelper } from "../../Helpers/UrlHelper";
import { contracts } from "../../Navigation/Routing/Contracts";
import { SimpleBaseView } from "../BaseView";
import { ContractTypeSpecificsForOwner } from "./Components/ContractTypeSpecificsForOwner";
import { ContractTypeChooser } from "./Create/ContractTypeChooser";
import { Summary } from "./Create/Summary";
import { TermsRouter } from "./Create/TermsRouter";

export type Configuration = {
    sourceSolarSystemId?: number | undefined,
    targetSolarSystemId?: number | undefined,
    forPlayerId?: number | undefined,
    isFederationOnly: boolean,
    areExilesAllowed: boolean
}

export const CreateContractView = () => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    const [forPlayer, setForPlayer] = React.useState<IPlayer | undefined>(undefined);
    const [contractType, setContractType] = React.useState<ContractType | undefined>(undefined);
    const [terms, setTerms] = React.useState<ITerms | undefined>(undefined);
    const [configuration, setConfiguration] = React.useState<Configuration>({
        isFederationOnly: false,
        areExilesAllowed: true
    });
    const [validation, setValidation] = React.useState<ValidationResult | undefined>(undefined);

    const location = useLocation();

    React.useEffect(() => {

        let forPlayerId: number | undefined = undefined;
        let sourceSolarSystemId: number | undefined = undefined;
        let targetSolarSystemId: number | undefined = undefined;

        UrlHelper.tryParseSearch(location, parsedFromSearch => {
            UrlHelper.tryGetNumberValue(parsedFromSearch, "forPlayerId", value => {
                forPlayerId = value;
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "sourceSolarSystemId", value => {
                sourceSolarSystemId = value;
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "targetSolarSystemId", value => {
                targetSolarSystemId = value;
            });
            UrlHelper.tryGetNumberValue(parsedFromSearch, "type", value => {
                const type = value;
                setContractType(type);
            });
        });

        setConfiguration({
            forPlayerId,
            sourceSolarSystemId,
            targetSolarSystemId,
            isFederationOnly: false,
            areExilesAllowed: true
        });
    }, []);

    React.useEffect(() => {
        if (configuration.forPlayerId !== undefined) {
            apiState.PlayerClient.get(configuration.forPlayerId).then(player => {
                setForPlayer(player);
            });
        }
    }, [configuration.forPlayerId]);

    React.useEffect(() => {
        if (contractType !== undefined) {
            const agentId = UrlHelper.tryGetNumberValueFromSearch(location, "agentId");
            setTerms(ContractHelper.instantiateTerms(contractType, agentId));
        } else {
            setTerms(undefined);
            setValidation(undefined);
        }
    }, [contractType]);

    function setTermsAndConfigure(terms: ITerms, configuration: Configuration) {
        setTerms(terms);
        setConfiguration(configuration);
    }

    function submit() {
        if ((validation === undefined || validation.isValid) && terms !== undefined) {
            const input: ICreateInput = {
                terms,
                ...configuration
            };

            return apiState.ContractsClient.create(new CreateInput(input)).then(response => {
                if (response.wasSuccess) {
                    appState.navigate(contracts);
                } else {
                    setValidation(ValidationHelper.invalid(response.error));
                }
            });
        }
    }

    return <SimpleBaseView heading={{ text: "Create Contract", icon: IconHelper.Economy.ContractCreate }}
        headingContent={<HelpButton to="contracts" />}
    >
        {contractType === undefined && <ContractTypeChooser
            contractType={contractType}
            setContractType={setContractType}
            forPlayerId={configuration.forPlayerId}
        />}
        {terms !== undefined && contractType !== undefined &&
            <SplitLayout
                left={<TermsRouter
                    terms={terms}
                    configuration={configuration}
                    setTermsAndConfigure={setTermsAndConfigure}
                    contractType={contractType}
                    setValidation={setValidation}
                    changeType={() => setContractType(undefined)}
                />}
                right={
                    <>
                        <ContractTypeSpecificsForOwner contractType={contractType} />
                        <Summary
                            forPlayer={forPlayer}
                            configuration={configuration}
                            setConfiguration={setConfiguration}
                            terms={terms}
                            submit={submit}
                            validation={validation}
                        />
                    </>
                }
            />
        }
    </SimpleBaseView>;
};