import React from "react";
import { AgentAction } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { BaseProps, Icon } from "../../Base/Icon";

type Props = BaseProps & {
    action: AgentAction
}

export const AgentActionIcon = (props: Props) => {
    return <Icon icon={IconHelper.Agents.action(props.action)} {...props} />;
};