import { observer } from "mobx-react-lite";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { ISchematicTypeSettings, ItemType, SectorType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { TabMenu } from "../../../Components/Base/Containers/TabMenu";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { CommodityTypeDescription } from "../../../Components/FusionShift/Descriptions/CommodityTypeDescription";
import { SectorTypeIcon } from "../../../Components/FusionShift/Icons/SectorTypeIcon";
import { CostControl } from "../../../Components/FusionShift/Items/CostControl";
import { IconHelper } from "../../../Helpers/IconHelper";
import { UrlHelper } from "../../../Helpers/UrlHelper";
import { help_page } from "../../../Navigation/Routing/Help";
import { TypeDataBaseView } from "./TypeDataBaseView";

type Props = {
    commodityType: ItemType,
    schematicTypeSettings: ISchematicTypeSettings
}

const CommodityTypeControl = (props: Props) => {

    const schematic = Object.values(props.schematicTypeSettings.data).find(s => s.producedItemTypeName === props.commodityType.typeName);

    return <SubPanel id={props.commodityType.typeName} heading={props.commodityType.name}>
        <SplitLayout
            left={
                <CommodityTypeDescription commodityType={props.commodityType} />
            }
            right={
                <div>
                    {schematic && schematic.costPerRun &&
                        <>
                            <Paragraph>Cost:</Paragraph>
                            <CostControl cost={schematic.costPerRun} hideDuration />
                        </>
                    }
                </div>
            }
        />
    </SubPanel>;
};

const CommodityTypesContent = observer(() => {

    const worldState = React.useContext(WorldStateContext);

    const [sectorTypeName, setSectorTypeName] = React.useState<string | undefined>(undefined);
    const [availableSectorTypes, setAvailableSectorTypes] = React.useState<SectorType[] | undefined>(undefined);

    const location = useLocation();

    if (!worldState.SchematicTypeSettings || !worldState.ItemTypeSettings || !worldState.CommodityHelper) {
        return <LoadingSpinner />;
    }

    const commodityTypeToFind = UrlHelper.tryGetValueFromSearch(location, "commodityTypeName");

    let sectorTypesToUse: SectorType[] = [];
    const commodities = worldState.CommodityHelper.allCommodities().map(k => {

        if (!sectorTypeName && k.itemType.typeName === commodityTypeToFind) {
            setSectorTypeName(k.sectorType.typeName);
        }

        if (!availableSectorTypes && !sectorTypesToUse.includes(k.sectorType)) {
            sectorTypesToUse.push(k.sectorType);
        }

        return k;
    }).sort(((a, b) => a.tier > b.tier ? 1 : -1));

    if (!availableSectorTypes && sectorTypesToUse.length > 0) {
        setAvailableSectorTypes(sectorTypesToUse);
        if (sectorTypesToUse.findIndex(c => c.typeName === sectorTypeName) < 0 && !commodityTypeToFind) {
            setSectorTypeName(sectorTypesToUse[0].typeName);
        }
    }

    return <>
        <TabMenu
            active={sectorTypeName}
            changeTab={(t: any) => setSectorTypeName(t)}
            tabs={availableSectorTypes ? availableSectorTypes.map(c => {
                return {
                    id: c.typeName,
                    title: c.name,
                    icons: <SectorTypeIcon sectorTypeName={c.typeName} />,
                    isAvailable: true
                };
            }) : []}
        />
        {commodities.filter(i => i.sectorType.typeName === sectorTypeName).map(i => <CommodityTypeControl key={i.itemType.typeName} commodityType={i.itemType} schematicTypeSettings={worldState.SchematicTypeSettings!} />)}
    </>;
});

export const CommodityTypesView = () => {
    return <TypeDataBaseView
        title="Commodity"
        icon={IconHelper.Items.Commodity}
        links={[
            { icon: IconHelper.Manufactories.Manufactory, to: help_page("manufactories"), text: "Manufactories" },
            { icon: IconHelper.Megacities.Megacity, to: help_page("megacities"), text: "Megacities" }
        ]}
    >
        <CommodityTypesContent />
    </TypeDataBaseView>;
};