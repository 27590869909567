import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import { ErrorPage } from "./ErrorPage";
import { routes } from "./Routing/_Routes";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: routes
    }
]);