import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { IInstallationTypeSettings, InstallationBuildQueueType, SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { ExpandButton } from '../../../../Components/Base/Buttons/ExpandButton';
import { Table } from '../../../../Components/Base/Containers/Table';
import { handleOnClick } from '../../../../Components/Base/Util';
import { DateIcon } from '../../../../Components/FusionShift/Icons/DateIcon';
import { InstallationBuildQueueItemIcon } from '../../../../Components/FusionShift/Icons/InstallationBuildQueueItemIcon';
import { InstallationImage } from '../../../../Components/FusionShift/Images/InstallationImage';
import { InstallationTypeLink } from '../../../../Components/FusionShift/Links/InstallationTypeLink';
import { SelectSolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { TimeHelper } from '../../../../Helpers/TimeHelper';
import { Upgrades } from '../../../SolarSystem/SubViews/Summary/Advisor/Upgrades';

type Props = {
    solarSystems: SolarSystemWrapper[],
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type RowProps = {
    solarSystemWrapper: SolarSystemWrapper,
    installationTypeSettings: IInstallationTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

const Row = (props: RowProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);

    React.useEffect(() => {
        if (props.solarSystemWrapper.upgrades.length === 0) {
            setIsExpanded(false);
        }
    }, [props.solarSystemWrapper]);

    const queue = props.solarSystemWrapper.solarSystem.installationBuildQueue;
    const currentItem = queue !== undefined ? queue.sort((a, b) => TimeHelper.sortDates(a.completedDate, b.completedDate))[0] : undefined;
    const finalQueueItem = queue !== undefined && queue.length > 1 ? queue.sort((a, b) => TimeHelper.sortDates(b.completedDate, a.completedDate))[0] : undefined;
    const installationType = currentItem && props.installationTypeSettings.data[currentItem.typeName];
    const noUpgrades = props.solarSystemWrapper.upgrades.length === 0;
    const level = props.solarSystemWrapper.solarSystem.celestials.find(x => x.installations?.find(i => i.installationId === currentItem?.installationId))?.installations?.find(i => i.installationId === currentItem?.installationId)?.level;

    const queueText = currentItem !== undefined ? `${queue === undefined || queue.length <= 1 ? 0 : queue.length - 1} more` : "";

    return <>
        <tr className="is-hidden-tablet">
            <td colSpan={5}>
                <SelectSolarSystemLink hideActionIcons solarSystem={props.solarSystemWrapper.solarSystem} />
            </td>
        </tr>
        <tr className="is-clickable" onClick={e => handleOnClick(e, () => setIsExpanded(!isExpanded))}>
            <td className='is-hidden-mobile'>
                <SelectSolarSystemLink hideActionIcons solarSystem={props.solarSystemWrapper.solarSystem} />
            </td>
            <td>
                {installationType &&
                    <InstallationImage
                        installationType={installationType}
                        size="tiny"
                        isConstructing={true}
                        installation={{ installationId: currentItem?.installationId ?? 0, damage: 0, level }}
                        celestialId={currentItem?.celestialId ?? 0}
                    />
                }
            </td>
            <td>
                {currentItem && <InstallationBuildQueueItemIcon installationBuildQueueItem={currentItem} />}
                {installationType && <InstallationTypeLink installationType={installationType} />}
                {currentItem && currentItem.type === InstallationBuildQueueType.Build && ` [${currentItem.targetLevel}]`}
                {!currentItem && "Nothing building"}
                <br className="is-hidden-tablet" />
                {currentItem && <DateIcon date={currentItem.completedDate} className='is-hidden-tablet' />}
            </td>
            <td className='is-hidden-mobile'>
                {currentItem && <DateIcon date={currentItem.completedDate} />}
            </td>
            <td colSpan={finalQueueItem ? 1 : 2} className='is-hidden-mobile'>
                {queueText}
            </td>
            {finalQueueItem &&
                <td className='is-hidden-mobile'>
                    <DateIcon key={finalQueueItem.installationBuildQueueItemId} date={finalQueueItem.completedDate} />
                </td>
            }
            <td className="is-hidden-tablet">
                {queueText}
                {finalQueueItem &&
                    <>
                        <br />
                        <DateIcon key={finalQueueItem.installationBuildQueueItemId} date={finalQueueItem.completedDate} />
                    </>
                }
            </td>
            <td>
                <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} isDisabled={noUpgrades} />
            </td>
        </tr >
        {isExpanded && <tr>
            <td colSpan={7}>
                <Upgrades {...props} />
            </td>
        </tr>
        }
    </>;
};

export const InstallationBuildQueues = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.InstallationTypeSettings) {
        return null;
    }

    return <Table isHoverable isFullWidth >
        {props.solarSystems.map(s => <Row key={s.solarSystem.solarSystemId} solarSystemWrapper={s} installationTypeSettings={worldState.InstallationTypeSettings!} {...props} />)}
    </Table>;
});