import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { CommodityBuyOffer, IItemTypeSettings, ItemCategory, ItemsDetail, ItemType } from "../../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { NumberInput } from "../../../../Components/Base/Form/Input";
import { Icon } from "../../../../Components/Base/Icon";
import { H4 } from "../../../../Components/Base/Text/H";
import { Paragraph } from "../../../../Components/Base/Text/Paragraph";
import { TextAndIcon } from "../../../../Components/Base/Text/TextAndIcon";
import { ItemTypeLink } from "../../../../Components/FusionShift/Links/Items/ItemTypeLink";
import { CollectionHelper } from "../../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { ItemsHelper } from "../../../../Helpers/ItemsHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { SetQuantityButtons } from "./SetQuantityButtons";

export type ItemTypeQuantity = {
    itemType: ItemType,
    quantity: number
}

type Props = {
    commodityBuyOffers?: { [key: string]: CommodityBuyOffer },
    itemsAtTarget: ItemsDetail | undefined,
    categories: ItemCategory[],
    current: { [key: string]: number },
    available: ItemTypeQuantity[] | undefined,
    cargoCapacityRemaining: number,
    itemTypeSettings: IItemTypeSettings,
    setValue: (item: ItemTypeQuantity, quantity: number) => any,
    isCommoditySell?: boolean
}

type ItemAndCapacity = {
    quantityAtTarget: number,
    capacityAtTarget: number,
    resultingAtTarget: number
}

export const ItemInputList = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const available = props.available && props.available
        .filter(r => props.categories.includes(r.itemType.category))
        .sort((a, b) => a.itemType.order - b.itemType.order);

    const updatedCapacities = React.useMemo(() => {

        const capacities: { [key: string]: ItemAndCapacity } = {};

        function processItemCategory(itemCategory: ItemCategory) {

            const capacity = props.itemsAtTarget !== undefined && ItemCategory[itemCategory] in props.itemsAtTarget.otherCapacities ? props.itemsAtTarget.otherCapacities[ItemCategory[itemCategory]] : 0;

            let current = 0;
            let selected = 0;

            for (const itemTypeName of Object.values(props.itemTypeSettings.data).filter(x => x.category === itemCategory).map(x => x.typeName)) {
                if (itemTypeName in props.current) {
                    selected += props.current[itemTypeName];
                }
                if (props.itemsAtTarget !== undefined && itemTypeName in props.itemsAtTarget.lastCalculatedItems) {
                    current += props.itemsAtTarget.lastCalculatedItems[itemTypeName];
                }
            }

            return {
                quantityAtTarget: current,
                capacityAtTarget: capacity,
                resultingAtTarget: current + selected
            };
        }

        const otherUsedCapacities: {
            Component: ItemAndCapacity,
            Commodity: ItemAndCapacity,
            RawResource: ItemAndCapacity
        } = {
            Component: processItemCategory(ItemCategory.Component),
            Commodity: processItemCategory(ItemCategory.Commodity),
            RawResource: processItemCategory(ItemCategory.RawResource),
        };

        if (props.available !== undefined) {
            for (const available of props.available) {

                const itemTypeName = available.itemType.typeName;

                if (available.itemType.category === ItemCategory.Resource) {

                    const capacity = props.itemsAtTarget !== undefined && itemTypeName in props.itemsAtTarget.resourceCapacities ? props.itemsAtTarget.resourceCapacities[itemTypeName] : 0;
                    const selected = itemTypeName in props.current ? props.current[itemTypeName] : 0;
                    const existing = props.itemsAtTarget !== undefined && itemTypeName in props.itemsAtTarget.lastCalculatedItems ? props.itemsAtTarget.lastCalculatedItems[itemTypeName] : 0;

                    capacities[itemTypeName] = {
                        quantityAtTarget: existing,
                        capacityAtTarget: capacity,
                        resultingAtTarget: existing + selected
                    };
                } else if (ItemCategory[available.itemType.category] in otherUsedCapacities) {
                    capacities[itemTypeName] = otherUsedCapacities[ItemCategory[available.itemType.category]];
                }
            }
        }

        return capacities;

    }, [props.itemsAtTarget, props.available, props.current, props.itemTypeSettings]);

    if (!worldState.CommodityHelper || !available || !CollectionHelper.isAnyInArray(available)) {
        return null;
    }

    function labelFor(itemType: ItemType) {
        if (itemType.category !== ItemCategory.Commodity) {
            return {
                label: <TextAndIcon text={ValueFormatter.pluralize(ValueFormatter.friendlyNameForItemCategory(itemType.category), 2)} icon={IconHelper.Items.itemCategory(itemType.category)} />,
                for: ItemCategory[itemType.category]
            };
        }

        const commodityUsage = worldState.CommodityHelper?.commodityTypeUsage(itemType.typeName);

        if (commodityUsage === undefined) {
            return {
                label: itemType.name,
                for: itemType.typeName
            }
        }

        return {
            label: <TextAndIcon text={commodityUsage.sectorType.name} icon={IconHelper.Megacities.sectorType(commodityUsage.sectorType.typeName)} />,
            for: commodityUsage.sectorType.typeName
        };
    }

    const hasItemsAtTarget = props.itemsAtTarget !== undefined;
    const hasCommodityBuyOffers = props.commodityBuyOffers !== undefined;

    const className = classNames("item-input-list",
        {
            "has-extra": hasItemsAtTarget || hasCommodityBuyOffers
        });

    let lastLabel: {
        label: React.ReactNode,
        for: string
    } | undefined = undefined;

    return <div className={className}>
        {available
            .filter(x => !props.isCommoditySell || (props.commodityBuyOffers && x.itemType.typeName in props.commodityBuyOffers))
            .map((x, i) => {

                const thisLabel = labelFor(x.itemType);
                const renderLabel = thisLabel.for !== lastLabel?.for;
                lastLabel = thisLabel;

                const altClassName = i % 2 === 0 ? "is-alt" : "";
                const totalAltClassName = i % 2 !== 0 ? "is-alt" : "";

                const hasAtTarget = hasItemsAtTarget && x.itemType.typeName in props.itemsAtTarget!.lastCalculatedItems ? props.itemsAtTarget!.lastCalculatedItems[x.itemType.typeName] : 0;
                const capacityAtTarget = hasItemsAtTarget && ItemsHelper.itemTypeCapacity(x.itemType, props.itemsAtTarget?.resourceCapacities, props.itemsAtTarget?.otherCapacities);
                const spareCapacityAtTArget = capacityAtTarget - hasAtTarget;

                const current = x.itemType.typeName in props.current ? props.current[x.itemType.typeName] : 0;

                const showItemCapacity = x.itemType.category === ItemCategory.Resource;
                const capacityInfo = x.itemType.typeName in updatedCapacities ? updatedCapacities[x.itemType.typeName] : undefined;

                // Don't show capacity information if no information is available about the target
                const willFillTarget = capacityInfo !== undefined && hasItemsAtTarget && capacityInfo.resultingAtTarget > capacityInfo.capacityAtTarget;

                const buyOffer = props.commodityBuyOffers && x.itemType.typeName in props.commodityBuyOffers ?
                    props.commodityBuyOffers[x.itemType.typeName] : undefined;

                const willBuy = buyOffer && buyOffer.quantity > 0 && buyOffer.price > 0;

                const isDanger = willFillTarget && current > 0;

                const renderSummary = !hasCommodityBuyOffers && !showItemCapacity &&
                    // It's the last one
                    (i + 1 >= available.length ||
                        // The next one is different
                        labelFor(available[i + 1].itemType).for != thisLabel.for);

                return <React.Fragment key={x.itemType.typeName}>
                    {renderLabel && <>
                        <div className="heading-cell category">
                            <H4>{thisLabel.label}</H4>
                        </div>
                        <div className="is-hidden-mobile">
                        </div>
                        <div className="is-hidden-mobile" />
                        <div className="heading-cell heading-label is-hidden-mobile">
                            <span>
                                Available
                            </span>
                        </div>
                        {hasItemsAtTarget && <div className="heading-cell heading-label is-hidden-mobile">
                            <span className="has-nowrap">
                                At Target
                            </span>
                        </div>}
                        {hasCommodityBuyOffers && <div className="heading-cell heading-label is-hidden-mobile">
                            <span>
                                Will buy
                            </span>
                        </div>}
                        <div className="heading-cell filler is-hidden-mobile" />
                    </>}

                    <div className={`cell item-type ${altClassName}`}>
                        <ItemTypeLink itemType={x.itemType} maxLength={25} />
                    </div>
                    <div className={`cell ${altClassName}`}>
                        {isDanger &&
                            <Icon icon={IconHelper.Statuses.Warning} className="has-text-warning" />
                        }
                    </div>
                    <div className={`cell available available-quantity is-hidden-mobile ${altClassName}`}>
                        {ValueFormatter.toShorthand(x.quantity)}
                    </div>
                    {hasItemsAtTarget &&
                        <div className={`cell available available-at-target is-hidden-mobile ${altClassName} ${isDanger ? "has-text-warning" : ""}`}>
                            {ValueFormatter.toShorthand(hasAtTarget)}
                            {showItemCapacity && <>
                                / <a
                                    onClick={() => props.setValue(x, Math.min(spareCapacityAtTArget, x.quantity, props.cargoCapacityRemaining))}
                                >
                                    {ValueFormatter.toShorthand(capacityAtTarget)}
                                </a>
                            </>}
                        </div>
                    }
                    {hasCommodityBuyOffers &&
                        <div className={`cell available available-at-target is-hidden-mobile ${altClassName}`}>
                            <Paragraph >
                                <span className={willBuy ? "" : "wont-buy"}>
                                    {willBuy && <>
                                        <Icon icon={IconHelper.Economy.Credits} />
                                        {`${buyOffer!.price} (x${buyOffer!.quantity})`}
                                    </>
                                    }
                                    {!willBuy && "   None"}
                                </span>
                            </Paragraph>
                        </div>
                    }
                    <div className={`cell control quantity-input ${altClassName}`}>
                        <NumberInput
                            size={10}
                            value={current}
                            valueChanged={value => props.setValue(x, value)}
                        />
                    </div>
                    <SetQuantityButtons
                        additionalClassName="cell quantity-button"
                        available={x.quantity}
                        current={current}
                        cargoCapacityRemaining={props.cargoCapacityRemaining}
                        setValue={v => props.setValue(x, v)}
                    />
                    {renderSummary && <>
                        <div className={`cell is-total ${totalAltClassName}`}>
                            {ValueFormatter.friendlyNameForItemCategory(x.itemType.category)} Total
                        </div>
                        <div className={`cell ${totalAltClassName}`} />
                        <div className={`cell is-total total-value ${totalAltClassName}`}>
                            {capacityInfo !== undefined && <span className={willFillTarget ? "has-text-warning" : ""}>
                                {ValueFormatter.toShorthand(capacityInfo.resultingAtTarget)} {hasItemsAtTarget && <span>/ {ValueFormatter.toShorthand(capacityInfo.capacityAtTarget)}</span>}
                            </span>
                            }
                        </div>
                    </>}
                </React.Fragment>;
            })}
    </div>;
});