import { AgentAction, AgentActionType, AgentStatus, ApplyEffectParameters, ArrestAgentParameters, BaseParameters, ChangeCelestialParameters, IAgent, IAgentActionTypeSettings, IAgentDetail, IPlayerMinimal, ISolarSystemDetail, ISolarSystemForObserver, RangeByLevel, SolarSystemRelation } from "../ApplicationState/ApiClient";
import { agentActionsImageConfig } from '../images/AgentActionsImageConfig';
import { BackgroundImage } from "./BackgroundImageHelper";
import { ContractHelper } from "./ContractHelper";
import { RelationHelper } from "./RelationHelper";
import { nameof } from "./TypeHelper";

export type AgentAvailability = {
    agent: IAgent,
    isAvailable: boolean,
    reason?: string
}

export class AgentHelper {

    public static actionBackgroundImage(agent: IAgentDetail, actionType: AgentActionType | undefined): BackgroundImage | undefined {

        const variation = this.agentImageVariation(agent, actionType);

        if (variation === undefined) {
            return undefined;
        }

        return {
            variant: variation.variant,
            variationSource: variation.variantKey,
            type: "agentAction"
        }

    }

    public static agentImageVariation(agent: IAgentDetail, actionType: AgentActionType | undefined) {

        const celestialId = agent.celestialPosition?.celestial?.celestialId;

        if (celestialId === undefined || actionType === undefined) {
            return undefined;
        }

        const variantKey = this.actionBackgroundImageKey(actionType);

        if (variantKey === undefined || !(variantKey in agentActionsImageConfig)) {
            return undefined;
        }

        let variant = agent.agentId + celestialId + actionType.order;
        const num = agentActionsImageConfig[variantKey];
        while (variant >= num) {
            variant -= num;
        }

        return {
            variantKey,
            variant
        };
    }

    private static actionBackgroundImageKey(actionType: AgentActionType) {
        switch (actionType.action) {
            case AgentAction.Rest:
                return "Rest";
            case AgentAction.Train:
                return "Train";
            case AgentAction.ChangeCelestial:
            case AgentAction.ChangeCelestialWhileCovert:
                return "ChangeCelestial";
            case AgentAction.StowawayOnShip:
            case AgentAction.PrepareForStowawayWhileCovert:
            case AgentAction.PrepareForStowawayWhileOvert:
                return "Stowaway";
            case AgentAction.CounterEspionage:
                return "CounterEspionage";
            case AgentAction.ApplyEffect:
                return actionType.requiredStatus === AgentStatus.Covert ? "ApplyEffectCovert" : "ApplyEffectOvert";
        }

        return undefined;
    }

    public static canBeArrested(agent: IAgent, player: IPlayerMinimal | undefined) {
        return agent.status === AgentStatus.Overt && !RelationHelper.isAlliedWith(agent.player, player);
    }

    public static readyForAction(agent: IAgentDetail) {
        const noCooldown = agent.actionCooldown === undefined ||
            agent.actionCooldown === null || agent.actionCooldown < new Date();

        const atCelestial = agent.celestialPosition !== undefined;

        return noCooldown && atCelestial;
    }

    public static findAgent(solarSystems: ISolarSystemDetail[] | undefined, agentId: number): IAgent | undefined {

        if (solarSystems !== undefined) {
            for (let solarSystem of solarSystems) {
                for (let celestial of solarSystem.celestials) {
                    for (let agent of celestial.agents) {
                        if (agent.agentId == agentId) {
                            return agent;
                        }
                    }
                }
            }
        }

        return undefined;
    }

    private static reasonForNoAvailability(isPlayerOwner: boolean, agentStatus: AgentStatus | undefined, isInContract: boolean) {
        if (isInContract) {
            return "Target of contract";
        }
        if (!isPlayerOwner && agentStatus !== AgentStatus.Captured) {
            return "Agent does not report to you";
        }

        return "Unavailable";
    }

    public static agentAvailability(solarSystem: ISolarSystemForObserver | undefined, playerId: number) {

        if (solarSystem === undefined) {
            return [];
        }

        const isSolarSystemOwned = solarSystem.playerId === playerId;

        const agents: AgentAvailability[] = solarSystem.celestials.flatMap(x => x.agents)
            .map(x => {

                const isPlayerOwner = x.playerId === playerId;
                const ownSystemAndCaptured = isSolarSystemOwned && x.status === AgentStatus.Captured;
                const ownAgentAndOvert = isPlayerOwner && x.status === AgentStatus.Overt;
                const isInContract = ContractHelper.findRansomContract(solarSystem.contracts, x);

                const isAvailable = ownSystemAndCaptured || ownAgentAndOvert;

                return {
                    agent: x,
                    isAvailable: !isInContract && isAvailable,
                    reason: this.reasonForNoAvailability(isPlayerOwner, x.status, isInContract !== undefined)
                }
            });

        return agents;
    }

    static areParametersValid(action: AgentAction, parameters: BaseParameters | undefined, agent: IAgentDetail) {

        if (action === AgentAction.ChangeCelestial) {
            const name = nameof<ChangeCelestialParameters>("celestialId");
            if (parameters !== undefined && name in parameters) {
                const celestial = agent.celestialPosition?.otherCelestials?.find(x => x.celestialId === (parameters as any).celestialId);
                return celestial !== undefined && celestial.celestialId !== agent.celestialPosition?.celestial?.celestialId;
            }

            return false;
        } else if (action === AgentAction.ApplyEffect) {

            const name = nameof<ApplyEffectParameters>("agentActionTypeName");
            return parameters !== undefined && name in parameters;

        } else if (action === AgentAction.ArrestAgent) {

            const name = nameof<ArrestAgentParameters>("agentId");
            if (parameters !== undefined && name in parameters) {
                const targetAgent = agent.celestialPosition?.celestial?.agents.find(x => x.agentId === (parameters as any).agentId);

                return targetAgent !== undefined && this.canBeArrested(targetAgent, agent.player);
            }

            return false;
        }

        return true;
    }

    public static availableActions(agent: IAgentDetail, agentActionTypeSettings: IAgentActionTypeSettings) {

        if (agent.celestialPosition === undefined) {
            return undefined;
        }

        if (agent.status !== AgentStatus.Covert && agent.status !== AgentStatus.Overt && agent.status !== AgentStatus.Stowaway) {
            return undefined;
        }

        const isPlayerAlliedWithSystem = RelationHelper.isAlliedWith(agent.player, agent.celestialPosition.solarSystem.owner);
        const isUnoccupiedSystem = agent.celestialPosition.solarSystem.owner === undefined;

        const possibleActions: AgentActionType[] = [
            ...Object.values(agentActionTypeSettings.data)
        ].filter(x => {

            if (x.isInternalOnly) {
                return false;
            }

            if (x.level.min > (agent.level ?? 0)) {
                return false;
            }

            if (x.requiredStatus !== agent.status) {
                return false;
            }

            if (x.isHostile && isPlayerAlliedWithSystem) {
                return false;
            }

            if (x.requiresInstallationTypeName !== undefined && x.requiresInstallationTypeName.length > 0) {
                let found = false;

                if (agent.celestialPosition?.celestial.installations !== undefined) {
                    for (let requiredInstallationTypeName of x.requiresInstallationTypeName) {
                        for (let installation of agent.celestialPosition?.celestial.installations) {
                            if (installation.installationTypeName === requiredInstallationTypeName) {
                                found = true;
                                break;
                            }
                        }
                        if (found) {
                            break;
                        }
                    }
                }

                if (!found) {
                    return false;
                }
            }

            if (x.requiredSolarSystemRelations !== undefined && x.requiredSolarSystemRelations.length > 0) {
                let found = false;

                for (let requiredRelation of x.requiredSolarSystemRelations) {
                    if (requiredRelation === SolarSystemRelation.OccupiedAllied) {
                        found = isPlayerAlliedWithSystem;
                    } else if (requiredRelation === SolarSystemRelation.Unoccupied) {
                        found = isUnoccupiedSystem;
                    } else if (requiredRelation === SolarSystemRelation.OccupiedUnAllied) {
                        found = !isPlayerAlliedWithSystem && !isUnoccupiedSystem;
                    }

                    if (found) {
                        break;
                    }
                }

                if (!found) {
                    return false;
                }
            }

            return true;
        })
            .sort((a, b) => {

                if (a.action === AgentAction.ApplyEffect && b.action !== AgentAction.ApplyEffect) {
                    return 1;
                }

                if (a.action !== AgentAction.ApplyEffect && b.action === AgentAction.ApplyEffect) {
                    return -1;
                }

                return a.order - b.order;
            });

        return possibleActions;
    }

    public static calculateFromLevelModifier(levelRange: RangeByLevel, modifier: RangeByLevel | undefined | null, level: number) {
        if (modifier == null) {
            return 1;
        }

        var value = modifier.optimal;

        if (level > levelRange.optimal) {
            var lerp = this.calculateLerp(level, levelRange.optimal, levelRange.max);

            value = modifier.optimal + ((modifier.max - modifier.optimal) * lerp);
        }
        else if (level < levelRange.optimal) {
            var lerp = this.calculateLerp(level, levelRange.min, levelRange.optimal);
            value = modifier.min + ((modifier.optimal - modifier.min) * lerp);
        }

        if (value < modifier.min) {
            return modifier.min;
        }
        else if (value > modifier.max) {
            return modifier.max;
        }

        return value;
    }

    private static calculateLerp(level: number, min: number, max: number) {
        if (level >= max || Math.abs(min - max) < 0.01) {
            return 1;
        }

        return (level - min) / (max - min);
    }
}