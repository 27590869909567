import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ICelestial, ISolarSystem, Megacity, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../../../Components/Base/Containers/Table';
import { FormFooter } from '../../../../../Components/Base/Form/FormFooter';
import { Slider } from '../../../../../Components/Base/Form/Slider';
import { Paragraph } from '../../../../../Components/Base/Text/Paragraph';
import { SectorTypeBonusList } from '../../../../../Components/FusionShift/Descriptions/SectorTypeBonusList';
import { SectorTypeLink } from '../../../../../Components/FusionShift/Links/SectorTypeLink';
import { valueValid } from '../../../../../Entities/Validation';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { MegacityHelper } from '../../../../../Helpers/MegacityHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    megacity: Megacity,
    celestial: ICelestial,
    solarSystem: ISolarSystem,
    reloadCallback: (solarSystem: SolarSystemDetail) => any,
    cancelCallback: () => any
}

export const PopulationAssignmentControl = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);
    const [assignments, setAssignments] = React.useState(createInitialAssignments());

    function assign() {
        return apiState.MegacityClient.assignPopulation(props.celestial.celestialId, assignments)
            .then(solarSystem => {
                if (solarSystem) {
                    props.reloadCallback(solarSystem);
                }
            });
    }

    function createInitialAssignments() {
        const assigns: { [key: string]: number } = {};

        if (props.megacity.sectors) {
            for (let s of Object.keys(props.megacity.sectors)) {
                assigns[s] = props.megacity.sectors[s].assignedPopulation;
            }
        }

        return assigns;
    }

    function changed(value: number, sectorTypeName: string) {
        if (value > props.megacity.currentPopulation) {
            value = props.megacity.currentPopulation;
        }
        if (props.megacity.sectors && value > props.megacity.sectors[sectorTypeName].maximumPopulation) {
            value = props.megacity.sectors[sectorTypeName].maximumPopulation;
        }

        const keys = props.megacity.sectors ? Object.keys(props.megacity.sectors) : [];
        const assigns: { [key: string]: number } = {};
        let runningTotal = 0;

        for (let s of keys) {
            assigns[s] = s === sectorTypeName ? value : assignments[s];
            runningTotal += assigns[s];
        }

        const canBeChanged = keys.filter(c => c !== sectorTypeName);

        while (runningTotal > props.megacity.currentPopulation) {
            runningTotal = value;

            for (let s of canBeChanged) {
                if (assigns[s] <= 0) {
                    continue;
                }
                if (runningTotal + assigns[s] > props.megacity.currentPopulation) {
                    assigns[s] = assigns[s] - 1;
                }

                runningTotal += assigns[s];
            }
        }
        setAssignments(assigns);
    }

    if (!worldState.GameSettings) {
        return null;
    }

    const sectors = Object.keys(assignments).map(k => {
        const assigned = assignments[k];
        const sectorType = worldState.SectorTypeSettings!.data[k];
        const sector = props.megacity.sectors![k];

        return {
            assigned: assigned,
            sector: sector,
            sectorType: sectorType,
            max: sector.maximumPopulation > props.megacity.currentPopulation ? props.megacity.currentPopulation : sector.maximumPopulation,
            bonus: MegacityHelper.calculateBonusForSector(worldState.GameSettings!, sector, sectorType, assigned)
        }
    });

    let total = 0;
    for (let c of Object.keys(assignments)) {
        total += assignments[c];
    }

    return <SubPanel
        heading={{ text: "Assign Population ", icon: IconHelper.Megacities.PopulationAssignment }}
        isUnpadded
        footerContent={
            <FormFooter validation={valueValid(total <= props.megacity.currentPopulation)} validationPosition={"before"} extra={
                <Paragraph>
                    Total assigned: {ValueFormatter.formatPopulation(total) + " "}
                    of {ValueFormatter.formatPopulation(props.megacity.currentPopulation)}
                </Paragraph>
            }>
                <ButtonHolder isPulledRight>
                    <Button type="danger" icon={IconHelper.General.Cancel} action={props.cancelCallback} text="Cancel" />
                    <Button type="action" icon={IconHelper.Megacities.PopulationAssignment} action={() => assign()} text="Set Assignments" />
                </ButtonHolder>
            </FormFooter>
        }>
        <Table isFullWidth heading={
            <>
                <th>
                    Sector
                </th>
                <th>
                    Population
                </th>
                <th className="is-hidden-mobile">
                    Bonuses
                </th>
            </>
        }>
            {sectors.map(s => <tr key={s.sectorType.typeName}>
                <td>
                    <SectorTypeLink sectorType={s.sectorType} />
                </td>
                <td>
                    <Slider step={0.01} max={s.max} value={s.assigned} valueChanged={v => changed(v, s.sectorType.typeName)} valueFormatter={ValueFormatter.formatPopulation} />
                </td>
                <td className="is-hidden-mobile">
                    <SectorTypeBonusList isBrief assignedPopulation={s.assigned} sectorType={s.sectorType} bonus={s.bonus} />
                </td>
            </tr>)}
        </Table>
    </SubPanel>;
});