import classNames from "classnames";
import { Link } from "react-router-dom";
import { FederationStatus, PlayerFederation } from "../../../ApplicationState/ApiClient";
import { federation_view } from "../../../Navigation/Routing/Federation";
import { FederationRankIcon } from "../Icons/FederationRankIcon";

type Props = {
    federation: PlayerFederation | undefined
}

export const PlayerFederationLink = (props: Props) => {

    if (!props.federation || props.federation.federationId === undefined) {
        return null;
    }

    const className = classNames({
        "is-defeated": props.federation.status === FederationStatus.Defeated,
        "relation victor": props.federation.status === FederationStatus.Victorious,
    });

    return <Link className={className} to={federation_view(props.federation.federationId)}>
        [{props.federation.shortName}]
        <FederationRankIcon federationRank={props.federation.federationRank} /></Link>;
};