import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { ISolarSystem, WorldRegion } from "../../../../ApplicationState/ApiClient";
import { PlayerStateContext } from "../../../../ApplicationState/ContextRoot";
import { Badge, Badges } from "../../../../Components/Base/Badge";
import { GoToMapLocationIcon } from "../../../../Components/FusionShift/Icons/GoToMapLocationIcon";
import { EmblemImage } from "../../../../Components/FusionShift/Images/EmblemImage";
import { PlayerLink } from "../../../../Components/FusionShift/Links/PlayerLink";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { SolarSystemHelper } from "../../../../Helpers/SolarSystemHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { map_tactical_x_y } from "../../../../Navigation/Routing/Map";

type Props = {
    solarSystem: ISolarSystem
}

export const SolarSystemStats = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!props.solarSystem || !playerState.Player) {
        return null;
    }

    const founded = props.solarSystem.foundedDate ? ValueFormatter.formatTimeOrDate(props.solarSystem.foundedDate, false, false, false) : undefined;
    const claimed = props.solarSystem.claimedDate ? ValueFormatter.formatTimeOrDate(props.solarSystem.claimedDate, false, false, false) : undefined;

    const pos = <><Link to={map_tactical_x_y(props.solarSystem.x, props.solarSystem.y)}>{`${props.solarSystem.x}, ${props.solarSystem.y}`}</Link><GoToMapLocationIcon x={props.solarSystem.x} y={props.solarSystem.y} /> </>;

    const player = props.solarSystem.owner;

    return <Badges>
        {player !== undefined &&
            <Badge label="Player" content={<PlayerLink player={player} />} icon={<EmblemImage filename={player.emblemFilename} size='small' />} />
        }
        <Badge label="Pos." content={pos} icon={IconHelper.Map.TacticalMap} />
        <Badge label="Region" content={`${WorldRegion[props.solarSystem.region]} ${SolarSystemHelper.coordinateDescription(props.solarSystem.x, props.solarSystem.y)}`} icon={IconHelper.Map.ShowOnMap} />
        <Badge label="Size" content={ValueFormatter.formatSize(props.solarSystem.size)} icon={IconHelper.Player.Size} />
        <Badge label="Pop." content={ValueFormatter.formatPopulation(props.solarSystem.population)} icon={IconHelper.Megacities.Population} />
        {founded !== undefined && <Badge label="Founded" content={founded} icon={IconHelper.General.Flag} />}
        {founded !== claimed && claimed !== undefined && <Badge label="Claimed" content={claimed} icon={IconHelper.General.Flag} />}
    </Badges>
});