import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { PlayerNotificationDetail } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext } from "../../ApplicationState/ContextRoot";
import { BackNavButton } from "../../Components/Base/Buttons/BackButton";
import { DeleteButton } from "../../Components/Base/Buttons/DeleteButton";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { ItemsControl } from "../../Components/FusionShift/Items/ItemsControl";
import { RichText } from "../../Components/FusionShift/RichText";
import { ShipList } from "../../Components/FusionShift/Ships/ShipList";
import { CollectionHelper } from "../../Helpers/CollectionHelper";
import { IconHelper } from "../../Helpers/IconHelper";
import { NotificationHelper } from "../../Helpers/NotificationHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { playerNotifications } from "../../Navigation/Routing/NotificationsEtc";
import { SimpleBaseView } from "../BaseView";
import { Hint } from "./Hint";
import { NotificationImage } from "./NotificationImage";
import { VotesBarChart } from "./VotesBarChart";


export const PlayerNotificationView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const { playerNotificationId } = useParams();

    const [playerNotification, setPlayerNotification] = React.useState<PlayerNotificationDetail | undefined>(undefined);

    React.useEffect(() => {
        apiState.PlayerNotificationClient.get(Number(playerNotificationId)).then(notificationResponse => {
            if (notificationResponse) {
                setPlayerNotification(notificationResponse);
            }
        });
    }, [playerNotificationId]);

    function deleteNotification() {
        return apiState.PlayerNotificationClient.delete(Number(playerNotificationId)).then(_ => {
            appState.navigate(playerNotifications)
        });
    }

    const hasItems = playerNotification && (CollectionHelper.isAnyQuantityInDictionary(playerNotification.items) || playerNotification.credits !== undefined && playerNotification.credits !== null);
    const hasShips = playerNotification && CollectionHelper.isAnyQuantityInDictionary(playerNotification.ships)

    const titleContent = !!playerNotification ? <RichText text={playerNotification.subject} knownEnrichables={playerNotification.enrichables} isTextOnly /> : undefined;

    const imagePath = playerNotification !== undefined ? NotificationHelper.imageUrl(playerNotification) : undefined;

    const hasImage = imagePath !== undefined && imagePath.length != 0;

    return <SimpleBaseView
        heading={{ text: "Notifications", icon: IconHelper.Categories.Notification }}
        headingContent={<BackNavButton to={playerNotifications} />}
    >
        {(!playerNotification || !playerState.Player) && <LoadingSpinner />}
        {playerNotification && playerState.Player &&
            <SubPanel
                heading={{ content: titleContent ?? "" }}
                headingContent={
                    playerNotification && <DeleteButton action={() => deleteNotification()} confirm="Are you sure you want to delete this notification?" />}
                footerContent={<Hint {...playerNotification}
                />}
                isUnpadded={hasImage}
            >
                <SplitLayout
                    hasRightImage={hasImage}
                    isRightEmpty={!hasImage}
                    left={<>
                        <Paragraph>
                            {ValueFormatter.formatTimeOrDate(playerNotification.date)}
                        </Paragraph>
                        <Paragraph>
                            <RichText text={playerNotification.body} knownEnrichables={playerNotification.enrichables} />
                        </Paragraph>
                        {CollectionHelper.isAnyInDictionary(playerNotification.votes) &&
                            <VotesBarChart votes={playerNotification.votes!} />
                        }
                    </>
                    }
                    right={<NotificationImage image={imagePath} />}
                />
                {(hasShips || hasItems) &&
                    <SplitLayout
                        isLeftEmpty={!hasItems}
                        left={hasItems &&
                            <SubPanel heading={{ text: "Items", icon: IconHelper.Items.Item }}>
                                <ItemsControl items={playerNotification.items} credits={playerNotification.credits} expandDetails />
                            </SubPanel>
                        }
                        isRightEmpty={!hasShips}
                        right={hasShips &&
                            <SubPanel heading={{ text: "Ships", icon: IconHelper.Ships.Ship }}>
                                <ShipList ships={playerNotification.ships} />
                            </SubPanel>
                        }
                    />}

            </SubPanel>}
    </SimpleBaseView>;
});