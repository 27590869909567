import { CheckoutFailed as CheckoutFailed } from "../../Views/Premium/CheckoutFailed";
import { PaymentSuccess as PaymentSuccess } from "../../Views/Premium/PaymentSuccess";
import { PremiumView } from "../../Views/Premium/PremiumView";
import { ProductsIndex } from "../../Views/Premium/ProductsIndex";
import { SubscriptionSuccess } from "../../Views/Premium/SubscriptionSuccess";
import { route } from "./Root";

export const premium = "/premium";
export const premium_products = premium + "/products";

export const checkout = "/checkout"
export const checkout_payment_success = checkout + "/success/payment";
export const checkout_subscription_success = checkout + "/success/subscription";
export const checkout_failed = checkout + "/failed";

export const premiumRoutes = [

    route(premium_products, ProductsIndex, "Premium Products"),
    route(checkout_payment_success, PaymentSuccess, "Payment Success"),
    route(checkout_subscription_success, SubscriptionSuccess, "Subscription Success"),
    route(checkout_failed, CheckoutFailed, "Checkout Failed"),
    route(premium, PremiumView, "Premium")
];