import { observer } from "mobx-react-lite";
import React from "react";
import { ISolarSystemDetail, SolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { buildShips, ShipListInner } from "../../../Components/FusionShift/Ships/ShipList";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { DronesList } from "./DronesList";

type Props = {
    hideExtras?: boolean,
    solarSystem: ISolarSystemDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const StationedShipsControl = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const hasDrones = props.solarSystem && props.solarSystem.drones && props.solarSystem.drones.activeDrones.length > 0;
    const hasShips = props.solarSystem && CollectionHelper.isAnyQuantityInDictionary(props.solarSystem.availableShips);

    if ((!hasDrones && !hasShips) || !worldState.ShipTypeSettings) {
        return null;
    }

    const ships = buildShips(worldState.ShipTypeSettings, props.solarSystem.availableShips);

    return <ShipListInner ships={ships}>
        <DronesList {...props} />
    </ShipListInner>;
});