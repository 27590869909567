import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CelestialSize, ICelestialDetail, IInstallationTypeSettings, IShipTypeSettings, ShipBuildQueueItem, SolarSystemDetail } from '../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { ExpandButton } from '../../../../Components/Base/Buttons/ExpandButton';
import { Table } from '../../../../Components/Base/Containers/Table';
import { FieldHolder } from '../../../../Components/Base/Form/FieldHolder';
import { NumberInput } from '../../../../Components/Base/Form/Input';
import { H3 } from '../../../../Components/Base/Text/H';
import { handleOnClick } from '../../../../Components/Base/Util';
import { CelestialImage } from '../../../../Components/FusionShift/Celestials/CelestialImage';
import { DateIcon } from '../../../../Components/FusionShift/Icons/DateIcon';
import { ShipTypeImage } from '../../../../Components/FusionShift/Images/ShipTypeImage';
import { ShipTypeLink } from '../../../../Components/FusionShift/Links/ShipTypeLink';
import { SelectSolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { Validation } from '../../../../Entities/Validation';
import { CollectionHelper } from '../../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { InstallationHelper } from '../../../../Helpers/InstallationHelper';
import { TimeHelper } from '../../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { EmpireViewProps } from '../../EmpireViewProps';

type RowProps = {
    solarSystemWrapper: SolarSystemWrapper,
    shipTypeSettings: IShipTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type CelestialRowProps = {
    isFirst: boolean,
    celestial: ICelestialDetail,
    queue: ShipBuildQueueItem[],
    solarSystemWrapper: SolarSystemWrapper,
    shipTypeSettings: IShipTypeSettings,
    installationTypeSettings: IInstallationTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}


type BuildShipsProps = {
    celestial: ICelestialDetail,
    solarSystemWrapper: SolarSystemWrapper,
    shipTypeSettings: IShipTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

const BuildShips = (props: BuildShipsProps) => {

    const apiState = React.useContext(ApiStateContext);

    const [shipQuantities, setShipQuantites] = React.useState<{ [key: string]: number; }>({});

    const [validation, setValidation] = React.useState<Validation>({
        valid: false,
        value: "Select some ships to build"
    });

    function setShipQuantity(shipTypeName: string, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }

        shipQuantities[shipTypeName || ""] = quantity;
        setShipQuantites(shipQuantities);

        if (CollectionHelper.isAnyQuantityInDictionary(shipQuantities)) {
            setValidation({
                valid: true,
                value: ""
            });
        } else {
            setValidation({
                valid: false,
                value: "Select some ships to build"
            });
        }
    }

    function build() {
        return apiState.ShipClient.buildShips(props.celestial.celestialId, shipQuantities).then(solarSystem => {
            if (solarSystem) {
                props.reloadCallback(solarSystem);
            }
        });
    }

    if (props.celestial.shipTypeNamesThatCanBeBuilt === undefined) {
        return null;
    }

    const shipTypes = Object.keys(props.celestial.shipTypeNamesThatCanBeBuilt).map(x => {
        return {
            shipType: props.shipTypeSettings.data[x],
            quantity: x in shipQuantities ? shipQuantities[x] : 0,
            maxQuantity: props.celestial.shipTypeNamesThatCanBeBuilt![x]
        };
    })
        .sort((a, b) => a.shipType.order - b.shipType.order);

    return <>
        {shipTypes.map((x, i) => <tr key={x.shipType.typeName}>
            <td>
                <ShipTypeImage shipType={x.shipType} size="tiny" />
            </td>
            <td colSpan={3}>
                <ShipTypeLink shipType={x.shipType} />
            </td>
            <td colSpan={2}>
                <FieldHolder label="" hasAddons >
                    <NumberInput
                        size={5}
                        value={x.quantity}
                        valueChanged={e => setShipQuantity(x.shipType.typeName, e)}
                    />
                    <Button type="nav" icon="" text="0" action={() => setShipQuantity(x.shipType.typeName, 0)} isDisabled={x.quantity === 0} />
                    <Button type="nav" icon="" text={ValueFormatter.formatLocaleNumber(x.maxQuantity)} action={() => setShipQuantity(x.shipType.typeName, x.maxQuantity)} isDisabled={x.quantity === x.maxQuantity} />
                </FieldHolder>
            </td>
            <td>
                {i === shipTypes.length - 1 && <Button type="action" action={build} icon={IconHelper.Ships.Build} text="Build" isDisabled={!validation.valid} hideTextOnMobile />}
            </td>
        </tr>)}
    </>;
}

const CelestialRow = (props: CelestialRowProps) => {

    const [isExpanded, setIsExpanded] = React.useState(false);

    const installationsWhichBuildShips = InstallationHelper.installationsWhichBuildShips(props.installationTypeSettings, props.shipTypeSettings, props.celestial);
    const installationNames = installationsWhichBuildShips && installationsWhichBuildShips.map(i => `${i.installationType.name} [${i.level}]`).join(", ");

    const first = props.queue.length > 0 ? props.queue[0] : undefined;
    const lastItem = props.queue.sort((a, b) => TimeHelper.sortDates(b.completedDate, a.completedDate))[0];
    const shipType = first && first.typeName in props.shipTypeSettings.data ? props.shipTypeSettings.data[first.typeName] : undefined;

    function reloadAndClose(solarSystem: SolarSystemDetail) {
        setIsExpanded(false);
        props.reloadCallback(solarSystem);
    }

    const canExpand = CollectionHelper.isAnyQuantityInDictionary(props.celestial.shipTypeNamesThatCanBeBuilt);

    const key = first !== undefined ? first.shipBuildQueueItemId : `${props.celestial.celestialId}_empty`;

    return <React.Fragment key={key}>
        {props.isFirst &&
            <tr className='is-hidden-tablet'>
                <td colSpan={7}>
                    {props.isFirst && <SelectSolarSystemLink hideActionIcons solarSystem={props.solarSystemWrapper.solarSystem} />}
                </td>
            </tr>
        }
        <tr className="is-clickable" onClick={e => handleOnClick(e, () => canExpand && setIsExpanded(!isExpanded))}>
            <td>
                {props.isFirst && <SelectSolarSystemLink
                    className='is-hidden-mobile'
                    hideActionIcons
                    solarSystem={props.solarSystemWrapper.solarSystem}
                />}
                <H3 className="celestial-name is-hidden-tablet">{props.celestial.name} </H3>

            </td>
            <td>
                <H3 className="celestial-name is-hidden-mobile">{props.celestial.name} </H3>
            </td>
            <td>
                <CelestialImage celestial={props.celestial} forcedSize={CelestialSize.Tiny} isLink />
            </td>
            <td>
                {shipType && <ShipTypeImage shipType={shipType} size="tiny" />}
            </td>
            <td>
                {first === undefined && "Nothing"}
                {first === undefined && !!installationNames && `: ${installationNames}`}
                {shipType !== undefined && <ShipTypeLink shipType={shipType} />}
                {first !== undefined && ` x${first.quantity}`}
                {props.queue.length > 1 && ` (${props.queue.length - 1} more)`}
            </td>
            <td>
                {lastItem !== undefined && <DateIcon key={lastItem.shipBuildQueueItemId} date={lastItem.allCompletedDate} />}
            </td>
            <td>
                <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} isDisabled={!canExpand} />
            </td>
        </tr>
        {isExpanded && <BuildShips {...props} reloadCallback={reloadAndClose} />}
    </React.Fragment>;
}

const Row = (props: RowProps) => {


    const fromBuildQueue = props.solarSystemWrapper.solarSystem.shipBuildQueueByCelestial ? Object.keys(props.solarSystemWrapper.solarSystem.shipBuildQueueByCelestial).map(x => Number(x)) : [];

    const celestials = props.solarSystemWrapper.solarSystem.celestials.filter(x => CollectionHelper.isAnyQuantityInDictionary(x.shipTypeNamesThatCanBeBuilt))
        .map(x => x.celestialId)
        .filter(x => !fromBuildQueue.includes(x))
        .concat(fromBuildQueue)
        .map(x => props.solarSystemWrapper.solarSystem.celestials.find(c => c.celestialId === x))
        .filter(x => x !== undefined)
        .map(x => x!)
        .map(x => {
            return {
                celestial: x,
                queue: props.solarSystemWrapper.solarSystem.shipBuildQueueByCelestial !== undefined && x.celestialId in props.solarSystemWrapper.solarSystem.shipBuildQueueByCelestial ? props.solarSystemWrapper.solarSystem.shipBuildQueueByCelestial![x.celestialId] : []
            }
        })
        .sort((a, b) => a.celestial.order - b.celestial.order);

    return <>
        {celestials.map((c, i) => <CelestialRow
            key={c.celestial.celestialId}
            {...props}
            isFirst={i === 0}
            {...c} />)}
    </>;
};

export const ShipBuildQueues = observer((props: EmpireViewProps) => {

    return <Table isHoverable isFullWidth >
        {props.solarSystems.map(s => <Row key={s.solarSystem.solarSystemId} solarSystemWrapper={s} {...props} />)}
    </Table>;
});