import * as React from 'react';
import { archiveChanges } from "../../Blobs/Changes/ArchiveChangeStore";
import { recentChanges } from "../../Blobs/Changes/RecentChangeStore";
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";
import { ArchiveButtons } from './ArchiveButtonts';
import { ChangeRender } from './ChangeRender';
import { ChangeArchive, ChangeKey } from './ChangeTypes';

export const ChangeArchiveView = () => {

    const [key, setKey] = React.useState<ChangeKey>({
        year: new Date().getFullYear(),
        month: new Date().getMonth()
    });

    const { archive, keys } = React.useMemo(() => {

        const changes = [...recentChanges, ...archiveChanges]
            .sort((a, b) => a.date > b.date ? -1 : 1);

        const data: ChangeArchive = {};
        const dates: ChangeKey[] = [];

        for (const change of changes) {
            const y = change.date.getFullYear();
            const m = change.date.getMonth();

            if (!(y in data)) {
                data[y] = [];
            }
            if (!(m in data[y])) {
                data[y][m] = [];
                dates.push({
                    year: y,
                    month: m
                });
            }

            data[y][m].push(change);
        }

        return { archive: data, keys: dates };

    }, [recentChanges, archiveChanges]);


    const { year, month } = key;
    const toRender = year in archive && month in archive[year] ? archive[year][month] : [];
    const date = new Date(year, month, 1);
    const monthName = date.toLocaleString('default', { month: 'long' });
    const title = `${monthName} ${year}`;

    return <SimpleBaseView
        heading={{ text: "Change Archive", icon: IconHelper.General.ChangeArchive }}
    >
        <SubPanel
            heading={title}
            headingContent={<ArchiveButtons keys={keys} currentKey={key} setKey={setKey} />}
            footerContent={<ArchiveButtons keys={keys} currentKey={key} setKey={setKey} />}
        >
            {toRender.length === 0 &&
                <Paragraph>
                    Nothing to show for the selected period.
                </Paragraph>
            }
            {toRender
                .sort((a, b) => a.date < b.date ? -1 : 1)
                .map((x, i) => <ChangeRender key={i} changeBlob={x} />)}
        </SubPanel>
    </SimpleBaseView>;
}