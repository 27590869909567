import { observer } from "mobx-react-lite";
import * as React from "react";
import { SolarSystemStateContext } from "../../ApplicationState/ContextRoot";
import { LoadingSpinnerOnly } from "../../Components/Base/Loading/LoadingSpinner";

export const SolarSystemSwitcher = observer(() => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    const show = solarSystemState.HasLoaded && solarSystemState.SwitchState === "Loading";

    return <div className="solar-system-switcher">
        {show && <LoadingSpinnerOnly isSmall />}
    </div>;
});
