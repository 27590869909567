import { observer } from "mobx-react-lite";
import * as React from "react";
import { BrowseQuery, IBrowseQuery, ItemCategory, MarketOrder } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, WorldStateContext } from "../../ApplicationState/ContextRoot";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { Checkbox } from "../../Components/Base/Form/Checkbox";
import { FieldHolder } from "../../Components/Base/Form/FieldHolder";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { ItemChooser } from "../../Components/FusionShift/Items/ItemChooser";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";
import { BrowseSummary } from "./Components/BrowseSummary";
import { MarketOrderTable } from "./Components/MarketOrderTable";

export type MarketSearch = string | ItemCategory | undefined;

export const MarketOrderBrowse = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [marketOrders, setMarketOrders] = React.useState<MarketOrder[] | undefined>(undefined);

    const [desiredItem, setDesiredItem] = React.useState<MarketSearch>(undefined);
    const [offeredItem, setOfferedItem] = React.useState<MarketSearch>(undefined);
    const [federationOnly, setFederationOnly] = React.useState(false);

    function changeDesired(itemTypeName: string | undefined) {
        setDesiredItem(itemTypeName);
    }

    function changeOffered(itemTypeName: string | undefined) {
        setOfferedItem(itemTypeName);
    }

    function handleItem(itemTypeName: string | undefined, itemCategory: ItemCategory | undefined, set: (value: MarketSearch) => any) {
        set(itemTypeName !== undefined && itemTypeName.length > 0 ? itemTypeName : itemCategory);
    }

    React.useEffect(() => {
        if (!marketOrders) {

            const browse: IBrowseQuery = {
                desiredItemTypeName: typeof desiredItem === "string" ? desiredItem : undefined,
                desiredItemCategory: typeof desiredItem !== "string" ? desiredItem : undefined,
                offeredItemTypeName: typeof offeredItem === "string" ? offeredItem : undefined,
                offeredItemCategory: typeof offeredItem !== "string" ? offeredItem : undefined,
                federationOnly: federationOnly
            }

            apiState.MarketOrderClient.browse(new BrowseQuery(browse)).then(marketOrders => {
                if (marketOrders) {
                    setMarketOrders(marketOrders);
                }
            });
        }
    }, [marketOrders]);

    React.useEffect(() => {
        setMarketOrders(undefined);
    }, [desiredItem, offeredItem, federationOnly]);

    return <SimpleBaseView heading={{ text: "Browse Marketplace", icon: IconHelper.Market.MarketOrder }} headingContent={<HelpButton to="marketplace" />}>
        <SplitLayout
            horizontalAfter="widescreen"
            left={
                <FieldHolder label="They Offer">
                    <ItemChooser
                        allowEmpty
                        setItemTypeName={i => handleItem(i, undefined, setOfferedItem)}
                        setItemCategory={c => handleItem(undefined, c, setOfferedItem)}
                    />
                </FieldHolder>
            }
            right={
                <FieldHolder label="They Desire">
                    <ItemChooser
                        allowEmpty
                        setItemTypeName={i => handleItem(i, undefined, setDesiredItem)}
                        setItemCategory={c => handleItem(undefined, c, setDesiredItem)}
                    />
                </FieldHolder>
            }
        />
        {playerState.Player && playerState.Player.federation &&
            <Checkbox label="Federation only" value={federationOnly} valueChanged={f => setFederationOnly(f)} />
        }
        <BrowseSummary theyDesireItem={desiredItem} theyOfferItem={offeredItem} isWrapped />
        <hr />
        {!marketOrders && <LoadingSpinner />}
        {marketOrders && marketOrders.length === 0 && <Paragraph type="missing">No market orders</Paragraph>}
        {marketOrders && marketOrders.length > 0 && <MarketOrderTable marketOrders={marketOrders} showLocation />}
    </SimpleBaseView>;
});