import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { EmblemLayer } from "../../../../ApplicationState/ApiClient";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../../Components/Base/Buttons/ButtonHolder";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../../Components/Base/Containers/Table";
import { handleOnClick } from "../../../../Components/Base/Util";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { Direction, initialEmblem } from "../../State/EmblemEditActionReducer";
import { EmblemEditorContext } from "../../State/EmblemEditorContext";

type Props = {
  selected: boolean;
  layer: EmblemLayer;
  index: number;
  canDelete: boolean;
  canUp: boolean;
  canDown: boolean;
};

const Row = observer((props: Props) => {

  const emblemEditorState = React.useContext(EmblemEditorContext);

  function displayNameFromManifest(filename: string | undefined) {
    if (!filename || !emblemEditorState.manifest) {
      return "";
    }

    for (let c in emblemEditorState.manifest.categories) {
      for (let e of emblemEditorState.manifest.categories[c]) {
        if (e.fileName === filename) {
          return e.displayName;
        }
      }
    }
    return "";
  }

  const className = classNames("is-clickable", {
    selected: props.selected,
  });

  return (
    <tr
      className={className}
      onClick={(e) => {
        handleOnClick(e, () => {
          emblemEditorState.setCurrentLayerIndex(props.index);
        });
      }}
    >
      <td>
        {`${props.index + 1} - ${displayNameFromManifest(
          props.layer.filename
        )}`}
      </td>
      <td>
        <ButtonHolder>
          <Button
            type="action"
            icon={IconHelper.General.Up}
            isDisabled={!props.canUp}
            action={() => {
              emblemEditorState.dispatchMoveLayer(props.index, Direction.Up);
            }}
          />
          <Button
            type="action"
            icon={IconHelper.General.Down}
            isDisabled={!props.canDown}
            action={() => {
              emblemEditorState.dispatchMoveLayer(props.index, Direction.Down);
            }}
          />
          <Button
            type="danger"
            isDisabled={!props.canDelete}
            action={() => {
              emblemEditorState.dispatchRemoveLayer(props.index);
            }}
            icon={IconHelper.General.Delete}
            confirm="Are you sure you want to delete this layer?"
          />
        </ButtonHolder>
      </td>
    </tr>
  );
});

export const LayerPickerPanel = observer(() => {
  const emblemEditorState = React.useContext(EmblemEditorContext);

  if (!emblemEditorState.emblemLayers) {
    return null;
  }

  const layers = emblemEditorState.emblemLayers;

  return (
    <SubPanel
      heading="Layers"
      className="layer-picker"
      isUnpadded
      headingContent={
        <Button
          isDisabled={!layers || layers.length >= 15}
          type={"action"}
          action={() => {
            emblemEditorState.dispatchAddLayer(initialEmblem);
          }}
          text={"Add"}
          icon={IconHelper.General.Plus}
        />
      }
    >
      {layers && (
        <Table isHoverable>
          {layers.map((x, i) => (
            <Row
              key={i}
              index={i}
              layer={x}
              canDelete={layers.length > 1}
              selected={i === emblemEditorState.curentLayerIndex}
              canUp={i !== 0}
              canDown={i !== layers.length - 1}
            />
          ))}
        </Table>
      )}
    </SubPanel>
  );
});
