import * as React from 'react';
import { JoinWorldHelper } from '../../../Helpers/JoinWorldHelper';
import { ConfirmStage } from './ConfirmStage';
import { FactionStage } from './FactionStage';
import { FriendStage } from './FriendStage';
import { JoinWorldBag } from './JoinWorldBag';
import { LocationStage } from './LocationStage';
import { LockedStage } from './LockedStage';
import { JoinWorldStage } from './StagesControl';
import { WorldStage } from './WorldStage';

export type StageProps = {
    previousStage: () => any,
    nextStage: () => any,
    joinWorldBag: JoinWorldBag
}

type Props = {
    currentStage: JoinWorldStage,
    changeStage: (stage: JoinWorldStage) => any,
    joinWorldBag: JoinWorldBag,
    startPlaying: () => any
}

export const StageRender = (props: Props) => {

    function createNextStage(stage: JoinWorldStage) {
        return () => props.changeStage(JoinWorldHelper.nextStageFor(stage, props.joinWorldBag));
    }

    function createPreviousStage(stage: JoinWorldStage) {
        return () => props.changeStage(JoinWorldHelper.previousStageFor(stage, props.joinWorldBag));
    }

    function render(stage: JoinWorldStage, toRender: (p: StageProps) => React.ReactNode) {
        if (props.currentStage !== stage) {
            return null;
        }

        const propsHere = {
            previousStage: createPreviousStage(stage),
            nextStage: createNextStage(stage),
            joinWorldBag: props.joinWorldBag
        };

        return toRender(propsHere);
    }

    return <>
        {render("World", p => <WorldStage {...p} />)}
        {render("Faction", p => <FactionStage {...p} hasBackButton={props.joinWorldBag.AllowedStages.includes("World")} />)}
        {render("Friend", p => <FriendStage {...p} />)}
        {render("Location", p => <LocationStage {...p} />)}
        {render("Locked", p => <LockedStage {...p} />)}
        {render("Confirm", p => <ConfirmStage {...p} startPlaying={props.startPlaying} />)}
    </>;
};