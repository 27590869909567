import { ISectorTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemBuilder, ProblemCategory, ProblemsMaybe } from "../Problem";
import { commodityProblems } from "./CommodityProblems";
import { populationProblems } from "./PopulationProblems";
import { undevelopedSectorProblems } from "./UndevelopedSectorProblems";

export const megacityProblemBuilder = new ProblemBuilder(ProblemCategory.Megacities);

export function megacityProblems(sectorTypeSettings: ISectorTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const solarSystem = wrapper.solarSystem;

    const celestialWithMegacity = solarSystem.celestials.filter(c => c.megacity !== undefined).find(c => c)
    const megacity = celestialWithMegacity !== undefined ? celestialWithMegacity.megacity : undefined;

    if (megacity !== undefined && celestialWithMegacity !== undefined) {
        return megacityProblemBuilder.merge(
            populationProblems(wrapper, megacity, celestialWithMegacity),
            commodityProblems(wrapper, megacity, celestialWithMegacity, sectorTypeSettings),
            undevelopedSectorProblems(wrapper, megacity, celestialWithMegacity)
        )
    }

    return undefined;
}