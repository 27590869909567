import { IconHelper } from "../../../Helpers/IconHelper";
import { contract_createForTargetSolarSystemId } from "../../../Navigation/Routing/Contracts";
import { ButtonLink } from "../../Base/Buttons/ButtonLink";

type Props = {
    solarSystemId: number
}

export const ContractForTargetSolarSystem = (props: Props) => {

    const url = contract_createForTargetSolarSystemId(props.solarSystemId);

    return <ButtonLink
        hideTextOnMobile
        to={url}
        text="Create Contract"
        icon={IconHelper.Economy.ContractCreate}
    />;
};