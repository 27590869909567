import * as React from 'react';
import { AdminWorld } from '../../../ApplicationState/ApiClient';
import { TabMenuWithChildren } from '../../../Components/Base/Containers/TabMenu';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ConfigureAuthenticationControl } from './ConfigureAuthenticationControl';
import { SendMessageToAllControl } from './SendMessageToAllControl';
import { SettingsView } from './SettingsView';

type Props = {
    world: AdminWorld,
    reloadCallback: (world: AdminWorld) => any
}

type WorldTabsType = "Processes" | "MessageToAll" | "Settings" | "Authentication";

export const WorldTabs = (props: Props) => {

    const [tab, setTab] = React.useState<WorldTabsType>("Processes")

    return <TabMenuWithChildren
        active={tab}
        changeTab={t => setTab(t)}
        tabs={[
            {
                id: "Settings",
                title: "Settings",
                isAvailable: !!props.world.settings.gameSettings,
                icons: IconHelper.Categories.Settings
            },
            {
                id: "MessageToAll",
                title: "Message To All",
                isAvailable: true,
                icons: IconHelper.General.Message
            },
            {
                id: "Authentication",
                title: "Authentication",
                isAvailable: true,
                icons: IconHelper.Account.Password
            }
        ]}>

        {tab === "Settings" &&
            <SettingsView {...props} />
        }
        {tab === "MessageToAll" &&
            <SendMessageToAllControl worldId={props.world.worldId} />
        }
        {tab === "Authentication" &&
            <ConfigureAuthenticationControl {...props} />
        }
    </TabMenuWithChildren>;
};