import { IGameSettings, IPlayer, IPlayerForSelf, IWorldMinimal } from "../ApplicationState/ApiClient";

export class PlayerHelper {

    public static isNew(player: IPlayer, solarSystems: any[] | undefined) {

        if (solarSystems && solarSystems.length > 1) {
            return false;
        }

        return player.hasBeginnersProtection;
    }

    public static redemptionCost(player: IPlayerForSelf, world: IWorldMinimal, gameSettings: IGameSettings) {

        return (player.numberOfTimesRedeemed + 1) * world.weekNumber * gameSettings.security.redemptionBaseCost;
    }
}