import * as React from 'react';
import { CombatRoundType, FleetMovementType, IFactionTypeSettings, IGameSettings, IShipTypeSettings, ShipClass, ShipType } from '../../../ApplicationState/ApiClient';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { ShipInputList, ShipTypeQuantity } from '../../SendShips/Components/ShipInputList';
import { FactionSelect } from './FactionSelect';

type Props = {
    movementType: FleetMovementType,
    shipTypeSettings: IShipTypeSettings,
    factionTypeSettings: IFactionTypeSettings,
    gameSettings: IGameSettings,
    allowDrones?: boolean,
    ships: { [key: string]: number },
    tactics: { [key: string]: CombatRoundType },
    setShipsAndTactics: (ships: { [key: string]: number }, tactics: { [key: string]: CombatRoundType }) => any,
    setFactionTypeName?: (factionTypeName: string | undefined) => any
}

export const ShipsForm = (props: Props) => {

    const [factionTypeName, setFactionTypeName] = React.useState<string | undefined>(undefined);

    const [availableShips, setAvailableShips] = React.useState<FleetWrapper | undefined>(undefined);

    React.useEffect(() => {
        if (factionTypeName !== undefined) {

            const newShips: { [key: string]: number } = {};
            const newTactics: { [key: string]: CombatRoundType } = {};

            for (let key in props.ships) {
                const existing = key in props.shipTypeSettings.data ? props.shipTypeSettings.data[key] : undefined;
                const tactic = key in props.tactics ? props.tactics[key] : undefined;

                if (existing !== undefined) {

                    let alternate: ShipType | undefined = undefined;

                    if (existing.class === ShipClass.Drone) {
                        alternate = Object.values(props.shipTypeSettings.data).find(x => x.name === existing.name && x.factionTypeName === factionTypeName);
                    } else {
                        alternate = Object.values(props.shipTypeSettings.data).find(x => x.class === existing.class && x.factionTypeName === factionTypeName);
                    }

                    if (alternate !== undefined) {
                        newShips[alternate.typeName] = props.ships[existing.typeName];
                        if (tactic !== undefined) {
                            newTactics[alternate.typeName] = tactic;
                        }
                    }
                }
            }

            props.setShipsAndTactics(newShips, newTactics);

            const ships = Object.values(props.shipTypeSettings.data).filter(x => x.factionTypeName === factionTypeName && (x.class !== ShipClass.Drone || props.allowDrones)).map(x => { return { key: x.typeName, value: 0 }; });
            setAvailableShips(new FleetWrapper(props.shipTypeSettings, props.gameSettings, CollectionHelper.arrayToDictionary(ships)));

            if (props.setFactionTypeName !== undefined) {
                props.setFactionTypeName(factionTypeName);
            }
        }
    }, [factionTypeName]);

    function setShipTactic(shipTypeName: string, tactic: CombatRoundType) {
        props.tactics[shipTypeName] = tactic;
        props.setShipsAndTactics(props.ships, props.tactics);
    }

    function setShipQuantity(ship: ShipTypeQuantity, quantity: number) {
        props.ships[ship.ship.typeName] = quantity;
        props.setShipsAndTactics(props.ships, props.tactics);
    }

    return <>
        <FactionSelect factionTypeName={factionTypeName} setFactionTypeName={setFactionTypeName} factionTypeSettings={props.factionTypeSettings} shipTypeSettings={props.shipTypeSettings} ships={props.ships} />
        {availableShips &&
            <ShipInputList
                selectedShips={new FleetWrapper(props.shipTypeSettings, props.gameSettings, props.ships)}
                tactics={props.tactics}
                shipQuantities={props.ships}
                availableShips={availableShips}
                hideAvailableQuantityButton
                showTactics
                movementType={props.movementType}
                shipTypeSettings={props.shipTypeSettings}
                setShipQuantity={setShipQuantity}
                setShipTactic={setShipTactic}
                hideStats
            />
        }
    </>;
};