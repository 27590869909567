import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MobileMenuStateContext } from '../../ApplicationState/ContextRoot';

export const OpenButton = observer(() => {

    const mobileMenuState = React.useContext(MobileMenuStateContext);

    function toggle() {
        mobileMenuState.IsOpen = !mobileMenuState.IsOpen;
    }

    const classnames = classNames(
        "mobile-nav-open-button",
        "is-branding",
        "brand-image",
        mobileMenuState.IsOpen && "is-open"
    );

    return <button className={classnames} onClick={toggle} >
        <img src="/images/main_logo_notext.png" />
    </button>;
});