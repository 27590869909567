import * as React from 'react';
import { CombatReport, CombatReportStatus, ICombatReport } from '../../../ApplicationState/ApiClient';
import { ApiStateContext, AppStateContext, PlayerStateContext } from '../../../ApplicationState/ContextRoot';
import { BackNavButton } from '../../../Components/Base/Buttons/BackButton';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { DeleteButton } from '../../../Components/Base/Buttons/DeleteButton';
import { IconHelper } from '../../../Helpers/IconHelper';
import { combatReports } from '../../../Navigation/Routing/NotificationsEtc';

type Props = {
    combatReport: ICombatReport,
    setCombatReport: (combatReport: CombatReport | undefined) => any
}

export const Controls = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [status, setStatus] = React.useState(props.combatReport.status);

    function deleteCombatReport() {
        return apiState.CombatReportClient.delete(props.combatReport.combatReportId).then(_ => {
            appState.navigate(combatReports)
        });
    }

    function changeStatus(combatReportStatus: CombatReportStatus) {
        return apiState.CombatReportClient.changeStatus(props.combatReport.combatReportId, combatReportStatus).then(c => {
            if (c && c.result) {
                setStatus(c.result.status)
                const cr: ICombatReport = {
                    ...props.combatReport,
                    status: combatReportStatus
                };
                props.setCombatReport(new CombatReport(cr));
            }
        })
    }
    const combatReport = props.combatReport;

    const isPlayerOwned = playerState.Player && combatReport && combatReport.playerId === playerState.Player.playerId;

    if (!isPlayerOwned) {
        return null;
    }

    return <ButtonHolder>
        <Button type="action" hideTextOnMobile text="Private" isDisabled={status === CombatReportStatus.Private} action={() => changeStatus(CombatReportStatus.Private)} icon={IconHelper.Combat.combatReportStatus(CombatReportStatus.Private)} />
        <Button type="action" hideTextOnMobile text="Archived" isDisabled={status === CombatReportStatus.Archived} action={() => changeStatus(CombatReportStatus.Archived)} icon={IconHelper.Combat.combatReportStatus(CombatReportStatus.Archived)} />
        <Button type="action" hideTextOnMobile text="Public" isDisabled={status === CombatReportStatus.Public} action={() => changeStatus(CombatReportStatus.Public)} icon={IconHelper.Combat.combatReportStatus(CombatReportStatus.Public)} />
        <DeleteButton action={() => deleteCombatReport()} confirm="Are you sure you want to delete this combat report?" />
        <BackNavButton to={combatReports} />
    </ButtonHolder>;
}