import * as React from 'react';
import { FleetMovementTypeIcon } from '../../../../Components/FusionShift/Icons/FleetMovementTypeIcon';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const FleetMovementTypeRequired = (props: Props) => {

    if (!props.mission.MissionType.fleetMovementTypeRequired) {
        return null;
    }

    const movementRequired = props.mission.MissionType.fleetMovementTypeRequired;
    const required = 1;
    const has = 0;
    const friendlyName = ValueFormatter.friendlyNameForMovementType(movementRequired);

    return <ObjectiveRow has={has} requires={required} {...props} >
        {`Send  ${ValueFormatter.aOrAn(friendlyName)} `}
        <FleetMovementTypeIcon movementType={movementRequired} setColour={false} />
        {" "}
        {friendlyName}
    </ObjectiveRow>;
};