import * as React from 'react';
import { AgentAction, AgentActionType, AgentOutcomeResponse, IAgentDetail } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Table } from '../../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { TextAndIcon } from '../../../../Components/Base/Text/TextAndIcon';
import { CreditsIconWithQuantityAndAffordability } from '../../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { SecurityStatusChange } from '../../../../Components/FusionShift/Messages/SecurityStatusChange';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { EffectDetails } from './EffectDetails';
import { PercentComponent } from './PercentComponent';

type Props = {
    agent: IAgentDetail,
    actionType: AgentActionType,
    outcome: AgentOutcomeResponse
};

export const OutcomeComponent = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.GameSettings) {
        return null;
    }

    return <>
        <Table isFullWidth>
            <tr>
                <td>Success chance</td>
                <td>
                    <PercentComponent percent={props.outcome.outcome.successChance} />
                </td>
            </tr>
            {props.outcome.outcome.successCaptureChance > 0 && <tr>
                <td>Success capture chance</td>
                <td>
                    <PercentComponent percent={props.outcome.outcome.successCaptureChance} higherIsWorse />
                </td>
            </tr>
            }
            {props.outcome.outcome.failureCaptureChance > 0 && <tr>
                <td>Failure capture chance</td>
                <td>
                    <PercentComponent percent={props.outcome.outcome.failureCaptureChance} higherIsWorse />
                </td>
            </tr>
            }
            {props.outcome.outcome.cost > 0 && <tr>
                <td>Cost</td>
                <td>
                    <CreditsIconWithQuantityAndAffordability quantity={props.outcome.outcome.cost} />
                </td>
            </tr>
            }
            {props.outcome.outcome.fatgiue > 0 && <tr>
                <td>Additional fatigue</td>
                <td>
                    {props.outcome.outcome.fatgiue}
                </td>
            </tr>
            }
            <tr>
                <td>Cooldown after acting</td>
                <td>
                    {props.outcome.outcome.actionCooldownHours} {ValueFormatter.pluralize("hour", props.outcome.outcome.actionCooldownHours)}
                </td>
            </tr>
            {props.actionType.action === AgentAction.ApplyEffect && <>
                <EffectDetails {...props} strength={props.outcome.outcome.strength} />
                <tr>
                    <td>
                        Duration
                    </td>
                    <td>
                        {ValueFormatter.formatLocaleNumber(props.actionType.durationHours * worldState.GameSettings.gameSpeed)} hours
                    </td>
                </tr>
            </>
            }
            {props.actionType.rewardsExperience && <tr>
                <td colSpan={2}>
                    <TextAndIcon icon={IconHelper.Agents.Experience} text="Rewards experience if successful" />
                </td>
            </tr>}
            {props.actionType.level.optimal > (props.agent.level ?? 0) &&
                <tr>
                    <td colSpan={2} className="has-text-warning">
                        {props.agent.name} is below the optimal level ({props.actionType.level.optimal}) and will have a lower chance of success. Depending on the mission type the chance of capture may also be higher and/or the strength of the applied effect may be reduced.
                    </td>
                </tr>
            }
            {!props.outcome.isPossible &&
                <tr>
                    <td colSpan={2} className="has-text-danger">
                        {props.outcome.error}
                    </td>
                </tr>
            }
        </Table>
        {props.outcome.outcome.securityStatusChange !== undefined && props.outcome.outcome.securityStatusChange !== null &&
            <SecurityStatusChange reason={`Ordering this action`} newSecurityStatus={props.outcome.outcome.securityStatusChange} />
        }
        {((props.actionType.action === AgentAction.PrepareForStowawayWhileCovert || props.actionType.action === AgentAction.PrepareForStowawayWhileOvert) && props.outcome.outcomeForRelatedAction !== undefined) && <>
            <Paragraph>
                If the agent later makes a stowaway attempt then it will have the following outcomes:
            </Paragraph>
            <Table isFullWidth>
                <tr>
                    <td>Success chance</td>
                    <td>
                        <PercentComponent percent={props.outcome.outcomeForRelatedAction.successChance} />
                    </td>
                </tr>
                {props.outcome.outcomeForRelatedAction.successCaptureChance > 0 && <tr>
                    <td>Success capture chance</td>
                    <td>
                        <PercentComponent percent={props.outcome.outcomeForRelatedAction.successCaptureChance} higherIsWorse />
                    </td>
                </tr>
                }
                {props.outcome.outcomeForRelatedAction.failureCaptureChance > 0 && <tr>
                    <td>Failure capture chance</td>
                    <td>
                        <PercentComponent percent={props.outcome.outcomeForRelatedAction.failureCaptureChance} higherIsWorse />
                    </td>
                </tr>
                }
            </Table>
        </>}
    </>;
};