import React from "react";
import { SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { SplitLayout } from "../../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { ErrorMessageBox } from "../../../../../Components/Base/MessageBox";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { CelestialViewProps } from "../CelestialViewProps";
import { PopulationAssignmentControl } from "./PopulationAssignmentControl";
import { PopulationSummary } from "./PopulationSummary";
import { SectorCommodityList } from "./SectorCommodityList";
import { SectorSummary } from "./SectorSummary";

export const MegacityView = (props: CelestialViewProps) => {

    const [showPopulationControl, setShowPopulationControl] = React.useState(false);
    const [showCommodityControl, setShowCommodityControl] = React.useState(false);

    function reloadLocally(solarSystem: SolarSystemDetail) {
        setShowPopulationControl(false);
        setShowCommodityControl(false);
        props.reloadLocallyCallback(solarSystem);
    }

    if (!props.celestial.megacity) {
        return <ErrorMessageBox text={"There is no Megacity at this celestial"} />;
    }

    const hasSectors = !!props.celestial.megacity.sectors && Object.keys(props.celestial.megacity.sectors).length > 0;

    return <>
        {!showPopulationControl && !showCommodityControl &&
            <SplitLayout
                left={
                    <SubPanel heading={{ text: "Population ", icon: IconHelper.Megacities.Population }}
                        headingContent={!!props.celestial.megacity.sectors && Object.keys(props.celestial.megacity.sectors).length > 0 &&
                            <Button
                                type="nav"
                                icon={IconHelper.Megacities.PopulationAssignment}
                                action={() => setShowPopulationControl(true)} text="Assignment"
                                hideTextOnMobile
                            />
                        }>
                        <PopulationSummary megacity={props.celestial.megacity} sectorTypeSettings={props.sectorTypeSettings} hideSectors />
                    </SubPanel>
                }
                right={
                    <SubPanel
                        heading={{ text: "Sectors ", icon: IconHelper.Megacities.Sectors }}
                        isUnpadded={hasSectors}
                        headingContent={hasSectors &&
                            <Button
                                type="nav"
                                icon={IconHelper.Items.Commodity}
                                action={() => setShowCommodityControl(true)}
                                text="Commodities"
                                hideTextOnMobile
                            />
                        }>
                        <SectorSummary megacity={props.celestial.megacity} celestial={props.celestial} />
                    </SubPanel>
                } />
        }
        {showPopulationControl &&
            <PopulationAssignmentControl
                megacity={props.celestial.megacity}
                celestial={props.celestial}
                solarSystem={props.solarSystem}
                reloadCallback={reloadLocally}
                cancelCallback={() => setShowPopulationControl(false)}
            />
        }
        {showCommodityControl &&
            <SectorCommodityList
                megacity={props.celestial.megacity}
                celestial={props.celestial}
                solarSystem={props.solarSystem}
                reloadCallback={reloadLocally}
                cancelCallback={() => setShowCommodityControl(false)}
            />
        }

    </>;
};