import { ICelestialDetail } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { ProblemsMaybe } from "../Problem";
import { manufactoryProblemBuilder } from "./ManufactoryProblems";

export function noNodeProblems(wrapper: SolarSystemWrapper, celestial: ICelestialDetail): ProblemsMaybe {

    if (!celestial.manufactory) {
        return undefined;
    }

    return [
        celestial.manufactory.inputNodes.length === 0 && manufactoryProblemBuilder.infoProblem("No Input Nodes", `The Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} has no input nodes`),
        celestial.manufactory.outputNodes.length === 0 && manufactoryProblemBuilder.infoProblem("No Output Nodes", `The Manufactory at ${wrapper.solarSystem.name}: ${celestial.name} has no output nodes`),
    ];
}