import * as React from "react";
import { Contract } from "../../ApplicationState/ApiClient";
import { ApiStateContext, UnreadStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";
import { contract_create } from "../../Navigation/Routing/Contracts";
import { SimpleBaseView } from "../BaseView";
import { ContractTable } from "./Components/ContractTable";

export const ContractIndex = () => {

    const apiState = React.useContext(ApiStateContext);
    const unreadState = React.useContext(UnreadStateContext);

    const [assignedContracts, setAssignedContracts] = React.useState<Contract[] | undefined>(undefined);
    const [ownedContracts, setOwnedContracts] = React.useState<Contract[] | undefined>(undefined);
    const [fleetContracts, setFleetContracts] = React.useState<Contract[] | undefined>(undefined);

    React.useEffect(() => {
        unreadState.clearUnread("contract");

        apiState.ContractsClient.getOwned().then(setOwnedContracts);
        apiState.ContractsClient.getAssigned().then(setAssignedContracts);
        apiState.ContractsClient.getByFleets().then(setFleetContracts);
    }, []);

    if (!assignedContracts || !ownedContracts || !fleetContracts) {
        return <LoadingSpinner />;
    }

    const hasAny = assignedContracts.length + ownedContracts.length + fleetContracts.length > 0;

    return <SimpleBaseView
        heading={{ text: "Your Contracts", icon: IconHelper.Economy.Contract }}
        headingContent={<ButtonHolder>
            <ButtonLink icon={IconHelper.Economy.ContractCreate} text="Create Contract" hideTextOnMobile to={contract_create} />
            <HelpButton to="contracts" />
        </ButtonHolder>}
    >
        {!hasAny && <Paragraph>No contracts at this time</Paragraph>}
        {assignedContracts.length > 0 && <SubPanel heading="Assigned Contracts" isUnpadded>
            <ContractTable contracts={assignedContracts} hideAssignedTo />
        </SubPanel>}
        {ownedContracts.length > 0 && <SubPanel heading="Your Contracts" isUnpadded>
            <ContractTable contracts={ownedContracts} hideOwner />
        </SubPanel>}
        {fleetContracts.length > 0 && <SubPanel heading="Fleet Contracts" isUnpadded>
            <ContractTable contracts={fleetContracts} hideAssignedTo />
        </SubPanel>}
    </SimpleBaseView>;
};