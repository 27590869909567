import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import { PlayerStateContext } from '../../../../ApplicationState/ContextRoot';
import { MathHelper } from '../../../../Helpers/MathHelper';
import { RelationHelper } from '../../../../Helpers/RelationHelper';
import { OwnedCoordinate, RenderContext } from '../RenderContext';
import { fleetSettings } from './Shared';

type Props = {
    renderContext: RenderContext
    scale: number
}

const targetSize = fleetSettings.targetSize;

const markerLength = fleetSettings.markerLength;
const angleOffset = fleetSettings.angleOffset;

type MarkerProps = {
    renderContext: RenderContext
    a: OwnedCoordinate,
    b: OwnedCoordinate,
    scale: number
}

type AnimatedMarkerProps = {
    renderContext: RenderContext,
    a: OwnedCoordinate,
    b: OwnedCoordinate,
    scale: number,
    progress: number,
    angle: number
}

const AnimatedMarker = animated((props: AnimatedMarkerProps) => {

    const currentX = props.a.x + (props.progress * (props.b.x - props.a.x));
    const currentY = props.a.y + (props.progress * (props.b.y - props.a.y));

    const rc = props.renderContext;

    const rotatedA = MathHelper.rotatePoint(currentX, currentY, props.angle - angleOffset, currentX, currentY + (markerLength / props.scale));
    const rotatedB = MathHelper.rotatePoint(currentX, currentY, props.angle + angleOffset, currentX, currentY - (markerLength / props.scale));

    return <>
        <line
            className={`selected is-marker`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={1}
            x1={rc.tX(currentX)}
            y1={rc.tY(currentY)}
            x2={rc.tX(rotatedA.x)}
            y2={rc.tY(rotatedA.y)}

        />
        <line
            className={`selected is-marker`}
            vectorEffect="non-scaling-stroke"
            strokeWidth={1}
            x1={rc.tX(currentX)}
            y1={rc.tY(currentY)}
            x2={rc.tX(rotatedB.x)}
            y2={rc.tY(rotatedB.y)}
        />
    </>;
});

const Marker = (props: MarkerProps) => {

    const angle = MathHelper.radiansAngleBetweenPoints(props.a.x, props.a.y, props.b.x, props.b.y);

    const progressSpring = useSpring({
        loop: true,
        config: { duration: 3000 },
        to: { progress: 1 },
        from: { progress: 0 }
    });

    return <AnimatedMarker
        angle={angle}
        progress={progressSpring.progress}
        {...props}
    />;
}

export const SourceAndTargets = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    const linesToRender = React.useMemo(() => {

        const lines: { a: OwnedCoordinate, b: OwnedCoordinate }[] = [];

        if (props.renderContext.coordinatesToRender !== undefined) {
            let previous: OwnedCoordinate | undefined = undefined;

            for (let current of props.renderContext.coordinatesToRender) {
                if (previous !== undefined && current !== undefined) {
                    lines.push({ a: previous, b: current });
                }

                previous = current;
            }
        }

        return lines;
    }, [playerState.Player, props.renderContext.coordinatesToRender]);

    if (playerState.Player === undefined) {
        return null;
    }

    const rc = props.renderContext;

    const scaledTargetSize = targetSize / props.scale;

    return <>
        {props.renderContext.coordinatesToRender.map(x => {
            const className = "target " + RelationHelper.relationClassName(x.owner, playerState.Player!);
            return <React.Fragment key={`${x.x}_${x.y}}`}>
                <line
                    strokeWidth={1}
                    vectorEffect="non-scaling-stroke"
                    className={className}
                    x1={rc.tX(x.x) + scaledTargetSize}
                    x2={rc.tX(x.x) - scaledTargetSize}
                    y1={rc.tY(x.y) + scaledTargetSize}
                    y2={rc.tY(x.y) - scaledTargetSize}
                />
                <line
                    strokeWidth={1}
                    vectorEffect="non-scaling-stroke"
                    className={className}
                    x1={rc.tX(x.x) - scaledTargetSize}
                    x2={rc.tX(x.x) + scaledTargetSize}
                    y1={rc.tY(x.y) + scaledTargetSize}
                    y2={rc.tY(x.y) - scaledTargetSize}
                />
            </React.Fragment>;
        })}
        {linesToRender.map(x => {

            const className = `selected`;
            return <React.Fragment key={`${x.a.x}_${x.a.y}_${x.b.x}_${x.b.y}`}>
                <line
                    strokeWidth={1 / props.scale}
                    className={className}
                    strokeDasharray={5 / props.scale}
                    x1={rc.tX(x.a.x)}
                    x2={rc.tX(x.b.x)}
                    y1={rc.tY(x.a.y)}
                    y2={rc.tY(x.b.y)}
                />
                <Marker {...x} {...props} />
            </React.Fragment>;
        })}
    </>;
});