import React from "react";
import { AccoladeType } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { BaseProps, Icon } from "../../Base/Icon";

type Props = BaseProps & {
    accoladeType: AccoladeType
}

export const AccoladeTypeIcon = (props: Props) => {
    return <Icon icon={IconHelper.Accolades.accoladeType(props.accoladeType)} {...props} />;
};