import classNames from "classnames";
import React from "react";
import { AppStateContext } from "../../../ApplicationState/ContextRoot";
import { Icon } from "../Icon";

export type ButtonBaseProps = {
    icon: string,
    text: string,
    confirm?: string,
    isSelected?: boolean,
    isDisabled?: boolean,
    className?: string
    iconPlacement?: "left" | "right",
    isTiny?: boolean,
    isPulledRight?: boolean,
    isSmall?: boolean,
    hideTextOnMobile?: boolean,
    scrollToTop?: boolean,
    isSubmit?: boolean,
    title?: string,
    forceClip?: ClipPosition
}

export type ClipPosition = "bottom-left" | "bottom-right" | "top-right" | "top-left";
export type ButtonTypes = "nav" | "query" | "action" | "warning" | "danger";
export const allButtonTypes: ButtonTypes[] = ["nav", "query", "action", "warning", "danger"];

export type ButtonAction = () => void | boolean | Promise<void> | undefined;

type Props = ButtonBaseProps & {
    type: ButtonTypes,
    action: ButtonAction
}

export const Button = (props: Props) => {

    const appState = React.useContext(AppStateContext);
    const [isLoading, setIsLoading] = React.useState(false);

    function finishExecution(loading: boolean) {
        setIsLoading(loading);
        if (props.scrollToTop) {
            appState.scrollToTop();
        }
    }

    function click() {
        if (!props.confirm || props.confirm.length === 0 || window.confirm(props.confirm)) {
            setIsLoading(true);
            const result = props.action();

            if (result === undefined) {
                finishExecution(false);
            } else if (typeof result === "boolean") {
                finishExecution(!result);
            } else if (typeof result.then === "function" && typeof result.catch === "function") {
                result
                    .then(r => {
                        finishExecution(false);
                    })
                    .catch(_ => {
                        finishExecution(true);
                    });
            } else {
                finishExecution(false);
            }
        }
    }

    function classNameForType(type: string): string {
        switch (type) {
            case "action":
                return "is-success";
        }

        return `is-${type}`;
    }

    const forceClipClass = props.forceClip !== undefined ? `force-clip is-${props.forceClip}-clipped` : undefined;

    const classes = classNames(
        "button",
        forceClipClass,
        classNameForType(props.type),
        props.className,
        {
            "is-tiny": props.isTiny,
            "is-small": props.isSmall,
            "selected": props.isSelected,
            "is-pulled-right": props.isPulledRight
        });

    const title = props.title ?? props.text;
    return (
        <button
            title={title}
            type={props.isSubmit ? "submit" : "button"}
            className={classes}
            onClick={() => click()}
            disabled={isLoading || props.isDisabled}
        >
            {props.iconPlacement === "left" && !!props.icon && props.icon.length > 0 && <Icon icon={props.icon} title={title} />}
            {props.text && props.text.length > 0 &&
                <span className={`is-button-text${props.hideTextOnMobile ? " is-hidden-mobile" : ""}`}>
                    {props.text}
                </span>
            }
            {props.iconPlacement === "right" && !!props.icon && props.icon.length > 0 && <Icon icon={props.icon} title={title} />}
        </button>
    );
};

const iconPlacement: "left" | "right" = "left";

Button.defaultProps = {
    confirm: "",
    text: "",
    className: "",
    isDisabled: false,
    iconPlacement: iconPlacement,
    isTiny: false,
    icon: undefined
};
