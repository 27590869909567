import { observer } from "mobx-react-lite";
import { ItemCategory, ItemType } from "../../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { Checkbox } from "../../../../Components/Base/Form/Checkbox";
import { CollectionHelper } from "../../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../../Helpers/IconHelper";

export type ItemTypeQuantity = {
    itemType: ItemType,
    quantity: number
}

type Props = {
    current: { [key: string]: number },
    available: ItemTypeQuantity[] | undefined,
    cargoCapacityRemaining: number,
    setValue: (item: ItemTypeQuantity, quantity: number) => any
}

export const RelicInputList = observer((props: Props) => {

    const available = props.available && props.available
        .filter(r => r.itemType.category === ItemCategory.Relic)
        .sort((a, b) => a.itemType.order - b.itemType.order);

    if (!available || !CollectionHelper.isAnyInArray(available)) {
        return null;
    }

    return <SubPanel heading={{ text: "Relics", icon: IconHelper.Items.Relic }}>
        <ul>
            {available.map(x => <li key={x.itemType.typeName}>
                <Checkbox
                    label={x.itemType.name}
                    value={x.itemType.typeName in props.current && props.current[x.itemType.typeName] > 0}
                    valueChanged={v => props.setValue(x, v ? 1 : 0)}
                />
            </li>)}
        </ul>
    </SubPanel>;
});