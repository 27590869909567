import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { MarketSearchRender } from "./MarketTradeRender";

type Props = {
    desiredItemTypeName: string,
    offeredItemTypeName: string,
    quantityOfOffered: number | undefined,
    quantityOfDesired: number | undefined,
    isWrapped?: boolean,
    verbText?: string | undefined
}

export const MarketTradeSummary = (props: Props) => {

    function content() {
        return <>{props.verbText !== undefined ? props.verbText : "Trading"} {ValueFormatter.formatLocaleNumber(props.quantityOfOffered)}<MarketSearchRender item={props.offeredItemTypeName} />  for {ValueFormatter.formatLocaleNumber(props.quantityOfDesired)}<MarketSearchRender item={props.desiredItemTypeName} /></>
    }

    if (!props.isWrapped) {
        return content();
    }

    return <Paragraph>
        {content()}
    </Paragraph>;
};
