import * as React from "react";
import { IFleet } from "../../../../ApplicationState/ApiClient";
import { PlayerStateContext, WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { FleetHelper } from "../../../../Helpers/FleetHelper";
import { MathHelper } from "../../../../Helpers/MathHelper";
import { RelationHelper } from "../../../../Helpers/RelationHelper";
import { MapHelper } from "../../MapHelper";
import { MapPreferences } from "../MapPreferences";

type Props = {
    selectedFleetId: number | undefined,
    setSelectedFleetId: (fleetId: number) => void,
    offsetX: number,
    offsetY: number,
    fleets: IFleet[] | undefined | null,
    preferences: MapPreferences,
    selectedSolarSystemId: number | undefined
}

type FleetProps = {
    playerId: number | undefined,
    setSelectedFleetId: (fleetId: number) => void,
    offsetX: number,
    offsetY: number,
    fleet: IFleet,
    className: string,
    secondsPerTick: number,
    highlight: FleetHighlight
}

type FleetHighlight = "none" | "highlight" | "faded" | "selected";

const angleOffset = Math.PI / 3;
const markerLength = 0.15;

function classNameForHighlight(highlight: FleetHighlight) {
    if (highlight === "none") {
        return "";
    }
    return highlight;
}

const nonEstimatedSize = 4;

const FleetLine = (props: FleetProps) => {

    if (props.fleet.sourceX === undefined ||
        props.fleet.sourceY === undefined ||
        props.fleet.targetX === undefined ||
        props.fleet.targetY === undefined) {
        return null;
    }

    const highlight = classNameForHighlight(props.highlight);

    return <>
        <line
            className={`fleet ${props.className} ${highlight}`}
            x1={MapHelper.fixOffsetX(props.fleet.sourceX, props.offsetX)}
            y1={MapHelper.fixOffsetY(props.fleet.sourceY, props.offsetY)}
            x2={MapHelper.fixOffsetX(props.fleet.targetX, props.offsetX)}
            y2={MapHelper.fixOffsetY(props.fleet.targetY, props.offsetY)}
            style={{
                strokeWidth: 1
            }}
        />
    </>;
}

const FleetMarkers = (props: FleetProps) => {

    const [currentX, setCurrentX] = React.useState<number | undefined>(props.fleet.currentX);
    const [currentY, setCurrentY] = React.useState<number | undefined>(props.fleet.currentY);
    const [secondsRemaining, setSecondsRemaining] = React.useState<number | undefined>(props.fleet.secondsRemaining);

    const [startDate, setStartDate] = React.useState<Date>(new Date());

    React.useEffect(() => {
        setStartDate(new Date());
        setCurrentY(props.fleet.currentX);
        setCurrentY(props.fleet.currentY);
        setSecondsRemaining(secondsRemaining);
    }, [props.fleet]);

    React.useEffect(() => {

        const interval = setInterval(() => {

            if (props.fleet.currentX !== undefined &&
                props.fleet.currentX !== null &&
                props.fleet.currentY !== undefined &&
                props.fleet.currentY !== null) {

                const seconds = (new Date().getTime() - startDate.getTime()) / 1000;

                if (seconds > props.fleet.secondsRemaining) {
                    setCurrentX(undefined);
                    setCurrentY(undefined);
                } else {
                    setCurrentX(props.fleet.currentX + ((props.fleet.perSecondX ?? 0) * seconds));
                    setCurrentY(props.fleet.currentY + ((props.fleet.perSecondY ?? 0) * seconds));
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const canSee = props.fleet.isInRadarRange || props.fleet.ownerPlayerId === props.playerId;
    const hasArrived = props.fleet.arrivalDate !== undefined && props.fleet.arrivalDate.getTime() < startDate.getTime();

    if (!canSee || currentX === undefined || currentY === undefined || hasArrived) {
        return null;
    }

    if (props.fleet.sourceX === undefined ||
        props.fleet.sourceY === undefined ||
        props.fleet.targetX === undefined ||
        props.fleet.targetY === undefined) {
        return null;
    }

    const angle = MathHelper.radiansAngleBetweenPoints(props.fleet.sourceX, props.fleet.sourceY, props.fleet.targetX, props.fleet.targetY);

    const rotatedA = MathHelper.rotatePoint(currentX, currentY, angle - angleOffset, currentX, currentY + markerLength);
    const rotatedB = MathHelper.rotatePoint(currentX, currentY, angle + angleOffset, currentX, currentY - markerLength);

    const highlight = classNameForHighlight(props.highlight);

    return <>
        {!props.fleet.isSourceEstimated && <circle
            className={`fleet ${props.className} ${highlight} is-marker`}
            cx={MapHelper.fixOffsetX(props.fleet.sourceX, props.offsetX)}
            cy={MapHelper.fixOffsetY(props.fleet.sourceY, props.offsetY)}
            r={nonEstimatedSize}
            style={{
                strokeWidth: 1,
                fillOpacity: 0
            }}
        />}
        {!props.fleet.isTargetEstimated && <>
            <line
                className={`fleet ${props.className} ${highlight} is-marker`}
                x1={MapHelper.fixOffsetX(props.fleet.targetX, props.offsetX) - nonEstimatedSize}
                y1={MapHelper.fixOffsetY(props.fleet.targetY, props.offsetY) + nonEstimatedSize}
                x2={MapHelper.fixOffsetX(props.fleet.targetX, props.offsetX) + nonEstimatedSize}
                y2={MapHelper.fixOffsetY(props.fleet.targetY, props.offsetY) - nonEstimatedSize}
                style={{
                    strokeWidth: 1
                }}
            />
            <line
                className={`fleet ${props.className} ${highlight} is-marker`}
                x1={MapHelper.fixOffsetX(props.fleet.targetX, props.offsetX) - nonEstimatedSize}
                y1={MapHelper.fixOffsetY(props.fleet.targetY, props.offsetY) - nonEstimatedSize}
                x2={MapHelper.fixOffsetX(props.fleet.targetX, props.offsetX) + nonEstimatedSize}
                y2={MapHelper.fixOffsetY(props.fleet.targetY, props.offsetY) + nonEstimatedSize}
                style={{
                    strokeWidth: 1
                }}
            />
        </>}
        <line
            key={`${props.fleet.fleetId}_a`}
            className={`fleet ${props.className} ${classNameForHighlight(props.highlight)} is-marker`}
            x1={MapHelper.fixOffsetX(currentX, props.offsetX)}
            y1={MapHelper.fixOffsetY(currentY, props.offsetY)}
            x2={MapHelper.fixOffsetX(rotatedA.x, props.offsetX)}
            y2={MapHelper.fixOffsetY(rotatedA.y, props.offsetY)}
            style={{
                strokeWidth: 2
            }}
        />
        <line
            key={`${props.fleet.fleetId}_b`}
            className={`fleet ${props.className} ${classNameForHighlight(props.highlight)} is-marker`}
            x1={MapHelper.fixOffsetX(currentX, props.offsetX)}
            y1={MapHelper.fixOffsetY(currentY, props.offsetY)}
            x2={MapHelper.fixOffsetX(rotatedB.x, props.offsetX)}
            y2={MapHelper.fixOffsetY(rotatedB.y, props.offsetY)}
            style={{
                strokeWidth: 2
            }}
        />
    </>;
}

export const FleetLayer = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    React.useEffect(() => {
    },
        [props.preferences.showFleets,
        props.preferences.showFleetsSelf,
        props.preferences.showFleetsAlly,
        props.preferences.showFleetsEnemy,
        props.preferences.showFleetsHostileOnly]);

    if (!props.fleets || !props.preferences.showFleets || !worldState.GameSettings) {
        return null;
    }

    function checkPreferences(fleet: IFleet): boolean {

        if (props.preferences.showFleetsHostileOnly && !FleetHelper.isHostileMovement(fleet)) {
            return false;
        }
        if (props.preferences.hideFleetMovementTypes && props.preferences.hideFleetMovementTypes.includes(fleet.movementType)) {
            return false;
        }
        if (playerState.Player && fleet.ownerPlayerId === playerState.Player.playerId) {
            return props.preferences.showFleetsSelf;
        }
        if (RelationHelper.isAlliedWith(fleet.owner, playerState.Player)) {
            return props.preferences.showFleetsAlly;
        }

        return props.preferences.showFleetsEnemy;
    }

    let movements = props.fleets.filter(checkPreferences)
        .map(m => {
            return {
                fleet: m,
                className: RelationHelper.movementRelationClassName(playerState.Player, m.owner, m)
            }
        }).sort((a, b) => {
            if (a.fleet.fleetId === props.selectedFleetId) {
                return -1;
            } else if (b.fleet.fleetId === props.selectedFleetId) {
                return 1;
            }
            return 0;
        });

    function highlight(fleet: IFleet): FleetHighlight {
        if (fleet.fleetId === props.selectedFleetId) {
            return "selected";
        }
        if (props.selectedSolarSystemId) {
            if (fleet.targetSolarSystem && props.selectedSolarSystemId === fleet.targetSolarSystem.solarSystemId) {
                return "highlight";
            }
            if (fleet.sourceSolarSystem && props.selectedSolarSystemId === fleet.sourceSolarSystem.solarSystemId) {
                return "highlight";
            }
            return "faded";
        }

        return "none";
    }

    return <>
        <svg className="overlay middle">
            {movements
                .map(m => <FleetLine
                    key={m.fleet.fleetId}
                    playerId={playerState.Player?.playerId}
                    className={m.className}
                    fleet={m.fleet}
                    secondsPerTick={worldState.GameSettings!.secondsPerTick}
                    highlight={highlight(m.fleet)}
                    {...props}
                />)}
        </svg>
        <svg className="overlay top">
            {movements
                .map(m => <FleetMarkers
                    key={m.fleet.fleetId}
                    playerId={playerState.Player?.playerId}
                    className={m.className}
                    fleet={m.fleet}
                    secondsPerTick={worldState.GameSettings!.secondsPerTick}
                    highlight={highlight(m.fleet)}
                    {...props}
                />)}
        </svg>
    </>
};