import { ItemCategory, ItemType } from "../../ApplicationState/ApiClient";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class ItemIcons {

    public get Commodity() { return MaterialDesignIcons.cubeOutline; }
    public get Component() { return MaterialDesignIcons.quadcopter; }
    public get Item() { return MaterialDesignIcons.package; }
    public get Resource() { return MaterialDesignIcons.basket };
    public get RawResource() { return MaterialDesignIcons.barrel };
    public get Schematic() { return MaterialDesignIcons.group; }
    public get Relic() { return MaterialDesignIcons.floppy; }

    public itemType(itemType: ItemType): string {
        switch (itemType.category) {
            case ItemCategory.Resource:
                return this.resource(itemType.typeName);
            case ItemCategory.Commodity:
                return this.Commodity;
            case ItemCategory.Component:
                return this.Component;
            case ItemCategory.RawResource:
                return this.RawResource;
            case ItemCategory.Relic:
                return this.Relic;
        }
    }

    public itemCategory(itemCategory: ItemCategory): string {
        switch (itemCategory) {
            case ItemCategory.Resource:
                return this.Resource;
            case ItemCategory.Commodity:
                return this.Commodity;
            case ItemCategory.Component:
                return this.Component;
            case ItemCategory.RawResource:
                return this.RawResource;
            case ItemCategory.Relic:
                return this.Relic;
        }
    }

    public resource(resourceTypeName: string | undefined): string {

        if (!resourceTypeName) {
            return "";
        }

        switch (resourceTypeName) {
            case "Gas":
                return MaterialDesignIcons.cloud;
            case "Metal":
            case "Ore":
                return MaterialDesignIcons.cog;
            case "Crystal":
                return MaterialDesignIcons.star;
            case "Biomass":
                return MaterialDesignIcons.wheat;
            case "Mineral":
                return MaterialDesignIcons.atom;
            case "Credit":
            case "Credits":
                return MaterialDesignIcons.currency;
        }
        return MaterialDesignIcons.missing;
    }

}