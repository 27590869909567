import React from "react";
import { ItemCategory, ItemType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { CommoditiesIcon } from "../Icons/Items/CommoditiesIcon";
import { ComponentIcon } from "../Icons/Items/ComponentIcon";
import { CreditsIconWithQuantity } from "../Icons/Items/CreditsIconWithQuantity";
import { ItemIconWithQuantity } from "../Icons/Items/ItemIconWithQuantity";
import { RelicIcon } from "../Icons/Items/RelicIcon";
import { RelicTypeLink } from "../Links/Items/RelicTypeLink";

type Props = {
    items: { [key: string]: number } | undefined,
    availableItems?: { [key: string]: number } | undefined,
    multipliedBy?: number,
    credits?: number | undefined,
    expandDetails?: boolean,
    onePerLine?: boolean,
    compressOnMobile?: boolean,
    showFullName?: boolean
}

export const ItemsControl = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);
    const itemsCount = CollectionHelper.sumOfDictionary(props.items);

    if (itemsCount <= 0 && props.credits === undefined) {
        return null;
    }

    function classNameFor(cost: { quantity: number, itemType: ItemType }) {
        if (!props.availableItems) {
            return "";
        }

        let available = 0;
        if (cost.itemType.typeName in props.availableItems) {
            available = props.availableItems[cost.itemType.typeName];
        }

        return cost.quantity > available ? "has-text-danger" : "";
    }

    const items = props.items ? Object.keys(props.items!).map(k => {
        return {
            itemType: worldState.ItemTypeSettings!.data![k],
            quantity: props.items![k] * (props.multipliedBy !== undefined ? props.multipliedBy : 1)
        }
    }).filter(c => c.quantity > 0)
        .sort(((a, b) => a.itemType.order > b.itemType.order ? 1 : -1)) : [];

    const resources = items.filter(c => c.itemType.category === ItemCategory.Resource);
    const rawResources = items.filter(c => c.itemType.category === ItemCategory.RawResource);

    const commoditiesCount = CollectionHelper.sumOfArray(items.filter(c => c.itemType.category === ItemCategory.Commodity).map(c => c.quantity));

    const components = props.expandDetails ? items.filter(c => c.itemType.category === ItemCategory.Component) : [];
    const componentsCount = !props.expandDetails ? CollectionHelper.sumOfArray(items.filter(c => c.itemType.category === ItemCategory.Component).map(c => c.quantity)) : 0;

    const relics = items.filter(x => x.itemType.category === ItemCategory.Relic);
    const relicsCount = relics.reduce((v, i) => v + i.quantity, 0);

    return <>
        <ul className={props.onePerLine ? "" : "inline"}>
            {props.credits !== undefined && props.credits !== null && props.credits !== 0 && <li><CreditsIconWithQuantity quantity={props.credits * (props.multipliedBy !== undefined ? props.multipliedBy : 1)} /></li>}
            {resources.map(c => <li key={c.itemType.typeName}>
                <ItemIconWithQuantity
                    itemType={c.itemType}
                    quantity={c.quantity}
                    className={classNameFor(c)}
                    compressOnMobile={props.compressOnMobile}
                    showFullName={props.showFullName}
                />
            </li>)}
            {commoditiesCount > 0 &&
                <li>
                    <CommoditiesIcon
                        quantity={commoditiesCount}
                        compressOnMobile={props.compressOnMobile}
                    />
                </li>}
            {!props.expandDetails && componentsCount > 0 &&
                <li>
                    <ComponentIcon
                        quantity={componentsCount}
                        compressOnMobile={props.compressOnMobile}
                    />
                </li>}
        </ul>
        {props.expandDetails && rawResources.length > 0 && <ul>
            {rawResources.map(c => <li key={c.itemType.typeName}>
                <ItemIconWithQuantity
                    itemType={c.itemType}
                    quantity={c.quantity}
                    className={classNameFor(c)}
                    compressOnMobile={props.compressOnMobile}
                    showFullName
                />
            </li>)}
        </ul>}
        {props.expandDetails && components.length > 0 && <ul>
            {components.map(c => <li key={c.itemType.typeName}>
                <ItemIconWithQuantity
                    itemType={c.itemType}
                    quantity={c.quantity}
                    showFullName
                    className={classNameFor(c)}
                    compressOnMobile={props.compressOnMobile}
                />
            </li>)}
        </ul>}
        {!props.expandDetails && relicsCount > 0 && <RelicIcon quantity={relicsCount} />}
        {props.expandDetails && relics.length > 0 && <ul>
            {relics.map(c => <li key={c.itemType.typeName}>
                <RelicTypeLink relicType={c.itemType} />
            </li>)}
        </ul>}
    </>;
};
