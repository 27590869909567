import * as React from 'react';
import { ItemCategory } from '../../../../ApplicationState/ApiClient';
import { TabMenuWithChildren } from '../../../../Components/Base/Containers/TabMenu';
import { SolarSystemWrapper } from '../../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { Commodities } from './Commodities';
import { ItemCategoryTable } from './ItemCategoryTable';
import { Resources } from './Resources';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type Tabs = "Resources" | "Commodities" | "Components" | "Raw Resources";

export const Items = (props: Props) => {

    const [tab, setTab] = React.useState<Tabs>("Resources");

    const tabs = [
        {
            id: "Resources",
            title: "Resources",
            isAvailable: true,
            icons: IconHelper.Items.Resource
        },
        {
            id: "Commodities",
            title: "Commodities",
            isAvailable: true,
            icons: IconHelper.Items.Commodity
        },
        {
            id: "Components",
            title: "Components",
            isAvailable: true,
            icons: IconHelper.Items.Component
        },
        {
            id: "Raw Resources",
            title: "Raw Resources",
            isAvailable: true,
            icons: IconHelper.Items.RawResource
        },
    ];

    return <TabMenuWithChildren
        active={tab}
        changeTab={t => setTab(t)}
        tabs={tabs}>
        {tab === "Resources" && <Resources {...props} />}
        {tab === "Commodities" && <Commodities {...props} />}
        {tab === "Components" && <ItemCategoryTable category={ItemCategory.Component} {...props} />}
        {tab === "Raw Resources" && <ItemCategoryTable category={ItemCategory.RawResource} {...props} />}
    </TabMenuWithChildren>;
};
