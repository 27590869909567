import * as React from 'react';
import { IContract, ReconTerms } from '../../../../ApplicationState/ApiClient';
import { CreditsIconWithQuantity } from '../../../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { SolarSystemLink } from '../../../../Components/FusionShift/Links/SolarSystemLink';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';

type Props = {
    contract: IContract,
    terms: ReconTerms
}

export const ReconTermsDetail = (props: Props) => {

    return <div className="content">
        <ul >
            <li>Recon <SolarSystemLink solarSystem={props.contract.targetSolarSystem!} /></li>
            <li>Arrive before {ValueFormatter.formatTimeOrDate(props.terms.arriveByDate, true)}</li>
            <li>Payment is <CreditsIconWithQuantity quantity={props.terms.payment} /></li>
        </ul>
    </div>;
};