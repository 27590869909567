import React from "react";
import { FederationRank } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { BaseProps, Icon } from "../../Base/Icon";

type Props = BaseProps & {
    federationRank: FederationRank
}

export const FederationRankIcon = (props: Props) => {

    const rank = IconHelper.Federations.rank(props.federationRank);

    if (rank === undefined) {
        return null;
    }

    return <Icon icon={rank} {...props} />;
};