import { AgentActionCategory } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    category: AgentActionCategory | undefined,
    isGiant?: boolean
}

export const AgentActionCategoryIcon = (props: Props) => {

    if (props.category === undefined) {
        return null;
    }

    return <Icon title={AgentActionCategory[props.category]} icon={IconHelper.Agents.actionCategory(props.category)} isGiant={props.isGiant} />;
};