import * as React from "react";
import { ISolarSystemDetail, SolarSystemDetail } from "../../../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext, WorldStateContext } from "../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../../Components/Base/Buttons/ButtonHolder";
import { HelpButton } from "../../../../Components/Base/Buttons/HelpButton";
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel";
import { Paragraph } from "../../../../Components/Base/Text/Paragraph";
import { TacticsTable } from "../../../../Components/FusionShift/Ships/TacticsTable";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { help_page } from "../../../../Navigation/Routing/Help";

type Props = {
    solarSystem: ISolarSystemDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const Tactics = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [tactics, setTactics] = React.useState(props.solarSystem.tactics);

    function submit() {
        if (!tactics) {
            return true;
        }

        return apiState.ShipClient.setTactics(props.solarSystem.solarSystemId, tactics).then(s => {
            props.reloadCallback(s);
        });
    }

    return <SubPanel heading={{ text: "Defensive Tactics", icon: IconHelper.Combat.Tactics }}
        headingContent={<ButtonHolder>
            <HelpButton to={help_page("combat")} />
        </ButtonHolder>
        }
        footerContent={<Button type="action" icon={IconHelper.General.Confirm} text="Confirm" action={submit} isPulledRight />}>
        <Paragraph type="prompt">
            Change the defensive tactics to control the order that ships will be used to defend the solar system if it is attacked.
        </Paragraph>
        <TacticsTable setTactics={setTactics} tactics={tactics} shipTypeSettings={worldState.ShipTypeSettings!} factionTypeName={playerState.Player!.factionTypeName} />
    </SubPanel>;
};