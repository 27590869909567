import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { IconHelper } from '../../Helpers/IconHelper';
import { VersionNumbers } from '../../Navigation/VersionNumbers';
import { SimpleBaseView } from '../BaseView';
import { ChangePassword } from './ChangePassword';
import { DeleteAccount } from './DeleteAccount';
import { DevSettings } from './DevSettings';
import { ForceUpdate } from './ForceUpdate';
import { NotificationSettings } from './NotificationsSettings';

export const SettingsView = () => {
    return <SimpleBaseView heading={{ text: "Your Settings", icon: IconHelper.Categories.Settings }}>
        <SplitLayout
            left={<>
                <NotificationSettings />
                <ForceUpdate />
                <SubPanel
                    heading={{ text: "Version", icon: IconHelper.Categories.Settings }}>
                    <VersionNumbers />
                </SubPanel>
                <DevSettings />
            </>}
            right={<>
                <ChangePassword />
                <DeleteAccount />
            </>}
        />
    </SimpleBaseView>;
};