import { observer } from "mobx-react-lite";
import React from "react";
import { ISolarSystemForObserver } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext, SolarSystemStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { FleetHelper } from "../../../Helpers/FleetHelper";
import { ButtonHolder } from "../../Base/Buttons/ButtonHolder";
import { SendShipsWithMovementTypeButton } from "./SendShipsButton";

type Props = {
    solarSystem: ISolarSystemForObserver,
    icon?: string | undefined
    additionalParams?: string | undefined
    noText?: boolean
}

export const SendShipsButtons = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);
    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    if (!solarSystemState.CurrentSolarSystem ||
        props.solarSystem.solarSystemId === solarSystemState.CurrentSolarSystem.solarSystemId ||
        !worldState.GameSettings ||
        !playerState.Player) {
        return null;
    }

    const fleetWrapper = solarSystemState.CurrentSolarSystem.availableShips;
    const allowedMovementTypes = FleetHelper.allowedMovementTypes(solarSystemState.CurrentSolarSystem.solarSystem, fleetWrapper, props.solarSystem, undefined, playerState.Player, undefined, false);

    if (allowedMovementTypes.length == 0 || !fleetWrapper.HasAnyShips) {
        return null;
    }

    return <ButtonHolder>
        {allowedMovementTypes.map(t => <SendShipsWithMovementTypeButton
            key={t}
            solarSystem={props.solarSystem}
            movementType={t}
            hideTextOnMobile
            noText={props.noText}
        />)}
    </ButtonHolder>;
});