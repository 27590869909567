import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Celestial, CelestialSize, IMapSolarSystem, ISolarSystemForObserver, SolarSystemForObserver } from '../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, SolarSystemStateContext } from '../../ApplicationState/ContextRoot';
import { ContextMenu } from '../../Components/Base/ContextMenu';
import { Icon } from '../../Components/Base/Icon';
import { LoadingSpinner, LoadingSpinnerOnly } from '../../Components/Base/Loading/LoadingSpinner';
import { ContractForTargetSolarSystem } from '../../Components/FusionShift/Buttons/ContractForTargetSolarSystemButton';
import { ExploreButton } from '../../Components/FusionShift/Buttons/ExploreButton';
import { SelectAndGoToSolarSystemButton } from '../../Components/FusionShift/Buttons/SelectAndGoToSystemButton';
import { SendShipsWithMovementTypeButton } from '../../Components/FusionShift/Buttons/SendShipsButton';
import { EmblemImage } from '../../Components/FusionShift/Images/EmblemImage';
import { PlayerLink } from '../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../Components/FusionShift/Links/SolarSystemLink';
import { Coordinate } from '../../Entities/Shared';
import { CollectionHelper } from '../../Helpers/CollectionHelper';
import { FleetHelper } from '../../Helpers/FleetHelper';
import { IconHelper } from '../../Helpers/IconHelper';
import { map_galaxy_x_y } from '../../Navigation/Routing/Map';
import { GridStore } from './Tactical/GridStore';

type Props = {
    parentRef: any,
    grid: GridStore,
    reloadCallback?: () => any
}

type ContentProps = {
    coordinate: Coordinate | undefined,
    solarSystem: IMapSolarSystem | undefined,
    reloadCallback?: () => any
}

const ContextContent = observer((props: ContentProps) => {

    const apiState = React.useContext(ApiStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [isLoading, setIsLoading] = React.useState(false);
    const [solarSystem, setSolarSystem] = React.useState<ISolarSystemForObserver | undefined>(undefined);

    React.useEffect(() => {

        if (props.solarSystem) {

            const celestials = props.solarSystem.celestialTypeNames.map(x => {
                return new Celestial({
                    celestialId: 0,
                    name: x,
                    celestialTypeName: x,
                    size: CelestialSize.Tiny,
                    population: 0,
                    order: 0,
                    resources: 0,
                    resourceCapacity: 0,
                    agents: []
                });
            });

            const data: ISolarSystemForObserver = {
                ...props.solarSystem,
                radarRangeModified: false,
                radarRange: undefined,
                marketOrders: [],
                contracts: [],
                celestials
            };

            const fakeSolarSystem = new SolarSystemForObserver(data);

            fakeSolarSystem.marketOrders = [];
            fakeSolarSystem.contracts = [];
            fakeSolarSystem.celestials = celestials;

            setSolarSystem(fakeSolarSystem)
        }

        // Always reload the solar system because more information such as market orders may be available
        if (!props.coordinate) {
            setSolarSystem(undefined);
            setIsLoading(false);
        } else {
            setIsLoading(true);
            apiState.SolarSystemClient.getByCoordinate(props.coordinate.x, props.coordinate.y).then(solarSystem => {
                setSolarSystem(solarSystem);
            }).finally(() => {
                setIsLoading(false);
            });
        }

    }, [props.coordinate, props.solarSystem]);

    if (!solarSystem || !playerState.Player || !solarSystemState.CurrentSolarSystem) {
        return <LoadingSpinner />;
    }

    const hasAnyShips = CollectionHelper.isAnyQuantityInDictionary(solarSystemState.SolarSystem?.availableShips);

    const availableShips = solarSystemState.CurrentSolarSystem.availableShips;
    const allowedMovementTypes = availableShips &&
        hasAnyShips &&
        solarSystemState.SolarSystem &&
        solarSystem &&
        playerState.Player
        ? FleetHelper.allowedMovementTypes(solarSystemState.SolarSystem, availableShips, solarSystem, undefined, playerState.Player, undefined, false) : [];

    const canSend = solarSystemState.SolarSystem !== undefined &&
        hasAnyShips &&
        allowedMovementTypes.length > 0 &&
        solarSystem.solarSystemId !== solarSystemState.SolarSystem.solarSystemId;

    const canSelectAndGoTo = solarSystemState.SolarSystem && solarSystem.playerId === playerState.Player.playerId && solarSystem.solarSystemId !== solarSystemState.SolarSystem.solarSystemId;

    return <>
        <ul>
            {solarSystem.owner && solarSystem.owner.emblemFilename && <li>
                <EmblemImage filename={solarSystem.owner.emblemFilename} isCentered />
            </li>}
            <li>
                <SolarSystemLink solarSystem={solarSystem} />
                {isLoading && <LoadingSpinnerOnly isSmall />}
            </li>
            {!!solarSystem.owner &&
                <li>
                    <PlayerLink player={solarSystem.owner} />
                </li>
            }
            <li>
                <Link to={map_galaxy_x_y(solarSystem.x, solarSystem.y)} >
                    <Icon icon={IconHelper.Map.ShowOnMap} />
                    Galaxy Map
                </Link>
            </li>
            {(canSend || canSelectAndGoTo) && <li><hr /></li>}
            {canSelectAndGoTo &&
                <li>
                    <SelectAndGoToSolarSystemButton solarSystemId={solarSystem.solarSystemId} text="Select and go to" />
                </li>
            }
            {availableShips !== undefined && FleetHelper.couldBeExplored(solarSystem) &&
                <li>
                    <ExploreButton solarSystem={solarSystem} reloadCallback={props.reloadCallback} />
                </li>
            }
            <li>
                <ContractForTargetSolarSystem solarSystemId={solarSystem.solarSystemId} />
            </li>
            {allowedMovementTypes.map(t => <li key={t}>
                <SendShipsWithMovementTypeButton solarSystem={solarSystem} movementType={t} />
            </li>)}
        </ul>
    </>
});

export const RightClick = (props: Props) => {

    const [coordinate, setCoordinate] = React.useState<Coordinate | undefined>(undefined);
    const [solarSystem, setSolarSystem] = React.useState<IMapSolarSystem | undefined>(undefined);

    React.useEffect(() => {

        const fromMap = coordinate &&
            coordinate.x in props.grid.cells &&
            coordinate.y in props.grid.cells[coordinate.x] ?
            props.grid.cells[coordinate.x][coordinate.y] :
            undefined;

        setSolarSystem(fromMap);

    }, [props.grid, coordinate]);

    function handleShouldOpen(event: any) {

        let current = event.target;

        while (current !== undefined && current !== props.parentRef.current) {

            const x = current.getAttribute('data-x');
            const y = current.getAttribute('data-y');

            if (x !== undefined && y !== undefined && x !== null && y !== null) {

                const xNum = Number(x);
                const yNum = Number(y);

                if (!isNaN(xNum) && !isNaN(yNum)) {
                    setCoordinate({ x: xNum, y: yNum });
                    return true;
                }
            }

            current = current.parentElement;
        }

        return false;
    }

    return <ContextMenu {...props} handleShouldOpen={handleShouldOpen} dontWrapChildren>
        <ContextContent
            coordinate={coordinate}
            solarSystem={solarSystem}
            {...props}
        />
    </ContextMenu>;
};