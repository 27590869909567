import React from "react";
import { AppStateContext, PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { IconHelper } from "../../../Helpers/IconHelper";
import { selected_system } from "../../../Navigation/Routing/SolarSystem";
import { Icon } from "../../Base/Icon";
import { SendShipsIconLink } from "../Buttons/SendShipsIconLink";
import { ISolarSystemLink } from "../Links/SolarSystemLink";
import { GoToMapLocationIcon } from "./GoToMapLocationIcon";

type Props = {
    solarSystem: ISolarSystemLink,
    hideSelectAndGoTo?: boolean
}

export const SolarSystemActionIcons = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const appState = React.useContext(AppStateContext);

    return <>
        <GoToMapLocationIcon x={props.solarSystem.x} y={props.solarSystem.y} />
        <SendShipsIconLink solarSystem={props.solarSystem} />
        {!props.hideSelectAndGoTo && props.solarSystem.solarSystemId !== appState.SolarSystemId && playerState.Player && props.solarSystem.owner?.playerId == playerState.Player.playerId && <Icon
            onClick={() => {
                appState.changeSolarSystemId(props.solarSystem.solarSystemId);
                appState.navigate(selected_system);
            }}
            title={"Select and go to"}
            icon={IconHelper.SolarSystems.SelectAndGoTo}
            className={"is-clickable"} />
        }
    </>;
};