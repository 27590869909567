import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { CombatReport, RequestResponseOfCombatReport } from "../../ApplicationState/ApiClient";
import { ApiStateContext, PlayerStateContext } from "../../ApplicationState/ContextRoot";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { ErrorMessageBox } from "../../Components/Base/MessageBox";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";
import { CombatReportRender } from "./CombatReportRender";
import { Controls } from "./ViewParts/Controls";

export const CombatReportView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const { combatReportId } = useParams();

    const [combatReport, setCombatReport] = React.useState<CombatReport | undefined>(undefined);
    const [combatReportResponse, setCombatReportResponse] = React.useState<RequestResponseOfCombatReport | undefined>(undefined);

    React.useEffect(() => {
        apiState.CombatReportClient.get(Number(combatReportId)).then(setCombatReportResponse);
    }, [combatReportId]);

    React.useEffect(() => {
        setCombatReport(combatReportResponse?.result)
    }, [combatReportResponse]);

    return <SimpleBaseView
        className="combat-report"
        heading={{ text: "Combat Report", icon: IconHelper.Combat.Combat }}
        headingContent={combatReport && !combatReport.isSimulated && <Controls combatReport={combatReport} setCombatReport={setCombatReport} />}
    >
        {(!combatReportResponse || !playerState.Player) && <LoadingSpinner />}
        {!!combatReportResponse && combatReportResponse.error && <ErrorMessageBox text={combatReportResponse.error} />}
        {combatReport && playerState.Player && <CombatReportRender combatReport={combatReport} />}
    </SimpleBaseView>;
});