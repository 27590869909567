import { observer } from "mobx-react-lite";
import * as React from "react";
import { CombatReportStatus, FleetMovementType, ICombatReport } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext, PlayerStateContext, UnreadStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { ButtonChooser, choice, maybeChoice } from "../../Components/Base/Buttons/ButtonChooser";
import { ViewButton } from "../../Components/Base/Buttons/ViewButton";
import { SplitLayout } from "../../Components/Base/Containers/SplitLayout";
import { Table } from "../../Components/Base/Containers/Table";
import { NewIcon } from "../../Components/Base/Icon";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { handleOnClick } from "../../Components/Base/Util";
import { FleetMovementTypeIcon } from "../../Components/FusionShift/Icons/FleetMovementTypeIcon";
import { InboxContainer } from "../../Components/FusionShift/InboxContainer";
import { LocationLink } from "../../Components/FusionShift/Links/LocationLink";
import { PlayerLink } from "../../Components/FusionShift/Links/PlayerLink";
import { SolarSystemLink } from "../../Components/FusionShift/Links/SolarSystemLink";
import { IconHelper } from "../../Helpers/IconHelper";
import { RelationHelper } from "../../Helpers/RelationHelper";
import { ValueFormatter } from "../../Helpers/ValueFormatter";
import { combatReport } from "../../Navigation/Routing/NotificationsEtc";

type Props = {
    combatReports: ICombatReport[]
    combatReportStatus: CombatReportStatus | undefined,
    setCombatReportStatus: (status: CombatReportStatus | undefined) => void
}

const CombatReportIndexInner = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const [movementType, setMovementType] = React.useState<FleetMovementType | undefined>(undefined);
    const [attackerDefender, setAttackerDefender] = React.useState<boolean | undefined>(undefined);
    const [interceptOnly, setInterceptOnly] = React.useState(false);

    const appState = React.useContext(AppStateContext);

    if (!playerState.Player) {
        return null;
    }

    function combatReportResult(combatReport: ICombatReport) {

        if (combatReport.victory) {
            return <span className={RelationHelper.Relation_Friend}>Victory</span>
        }

        if (combatReport.attackingFleetMovementType === FleetMovementType.Recon && combatReport.defendingPlayer?.playerId === playerState?.Player?.playerId) {
            return <span className={RelationHelper.Relation_Enemy}>Reconned</span>
        }

        return <span className={RelationHelper.Relation_Enemy}>Defeat</span>
    }

    const combatReports = interceptOnly ? props.combatReports.filter(x => x.defendingSolarSystem === undefined) : props.combatReports;

    const statusOptions = [
        choice<CombatReportStatus | undefined>(undefined, "All", IconHelper.Combat.AllCombatReports),
        choice<CombatReportStatus | undefined>(CombatReportStatus.Private, "Private", IconHelper.Combat.combatReportStatus(CombatReportStatus.Private)),
        choice<CombatReportStatus | undefined>(CombatReportStatus.Archived, "Archived", IconHelper.Combat.combatReportStatus(CombatReportStatus.Archived)),
        choice<CombatReportStatus | undefined>(CombatReportStatus.Public, "Public", IconHelper.Combat.combatReportStatus(CombatReportStatus.Public))
    ]

    const seenAttackerDefender: boolean[] = [];
    const seenMovementTypes: FleetMovementType[] = [];
    if (combatReports) {
        for (let c of combatReports) {
            if (!seenMovementTypes.includes(c.attackingFleetMovementType)) {
                seenMovementTypes.push(c.attackingFleetMovementType);
            }
            if (c.attackingPlayer) {
                const aOrD = c.attackingPlayer.playerId === playerState.Player!.playerId;
                if (!seenAttackerDefender.includes(aOrD)) {
                    seenAttackerDefender.push(aOrD);
                }
            }
        }
    }

    const typeOptions = [
        maybeChoice<FleetMovementType | undefined>(seenMovementTypes.length > 1, undefined, "All", "")
    ];
    if (seenMovementTypes.length > 1) {
        for (let m of seenMovementTypes) {
            typeOptions.push(maybeChoice<FleetMovementType | undefined>(seenMovementTypes.length > 1, m, ValueFormatter.friendlyNameForMovementType(m), IconHelper.Ships.movementType(m)));
        }
    }

    const attackerDefenderOptions = [
        maybeChoice<boolean | undefined>(seenAttackerDefender.length > 1, undefined, "Either", ""),
        maybeChoice<boolean | undefined>(seenAttackerDefender.length > 1, true, "Attacker", IconHelper.Combat.Attack),
        maybeChoice<boolean | undefined>(seenAttackerDefender.length > 1, false, "Defender", IconHelper.Combat.Defence)
    ];
    const combatReportsToRender = combatReports.filter(c =>
        (props.combatReportStatus === undefined || props.combatReportStatus === c.status) &&
        (movementType === undefined || movementType === c.attackingFleetMovementType) &&
        (attackerDefender === undefined || (c.attackingPlayer && attackerDefender === (c.attackingPlayer.playerId === playerState.Player!.playerId)))
    );

    return <>
        <SplitLayout
            left={
                <>
                    <ButtonChooser
                        hideTextOnMobile={false}
                        values={typeOptions}
                        valueChanged={setMovementType}
                        value={movementType} >
                        <Button
                            type="nav"
                            text="Intercept"
                            icon={IconHelper.Combat.Intercept}
                            action={() => setInterceptOnly(!interceptOnly)}
                            isSelected={interceptOnly}
                            hideTextOnMobile
                        />
                    </ButtonChooser>
                    <ButtonChooser hideTextOnMobile={false} values={attackerDefenderOptions} valueChanged={setAttackerDefender} value={attackerDefender} />
                </>
            }
            right={
                <ButtonChooser
                    className="is-pulled-right"
                    hideTextOnMobile
                    values={statusOptions}
                    valueChanged={props.setCombatReportStatus}
                    value={props.combatReportStatus}
                />
            } />
        {combatReportsToRender.length === 0 && <Paragraph type="missing">No combat reports</Paragraph>}
        {combatReportsToRender.length > 0 &&
            <Table
                isHoverable
                isFullWidth
                isMobileMultirow
                hideHeaderMobile
                heading={
                    <>
                        <td></td>
                        <td>Solar System</td>
                        <td className="is-hidden-mobile">Defender</td>
                        <td className="is-hidden-mobile">Attacker</td>
                        <td>Result</td>
                        <td>Date</td>
                        <td className="is-hidden-mobile"></td>
                    </>
                }>
                {combatReportsToRender.map(s => <React.Fragment key={s.combatReportId}>
                    <tr className="is-clickable is-hidden-tablet" onClick={e => handleOnClick(e, () => appState.navigate(combatReport(s.combatReportId)))}>
                        <td>
                            <FleetMovementTypeIcon
                                movementType={s.attackingFleetMovementType}
                                isPlayerOwner={s.attackingPlayer && s.attackingPlayer.playerId === playerState.Player!.playerId}
                                isPlayerAllied={RelationHelper.isAlliedWith(s.attackingPlayer, playerState.Player)}
                            />
                            {s.isUnread && <NewIcon />}
                        </td>
                        <td colSpan={3}>
                            {!!s.defendingSolarSystem && <SolarSystemLink solarSystem={s.defendingSolarSystem} />}
                            {!s.defendingSolarSystem && <LocationLink x={s.locationX} y={s.locationY} />}

                        </td>
                    </tr>
                    <tr className="is-clickable" onClick={e => handleOnClick(e, () => appState.navigate(combatReport(s.combatReportId)))}>
                        <td >
                            <span className="is-hidden-mobile">
                                <FleetMovementTypeIcon
                                    movementType={s.attackingFleetMovementType}
                                    isPlayerOwner={s.attackingPlayer && s.attackingPlayer.playerId === playerState.Player!.playerId}
                                    isPlayerAllied={RelationHelper.isAlliedWith(s.attackingPlayer, playerState.Player)}
                                />
                                {s.isUnread && <NewIcon />}
                            </span>
                        </td>
                        <td className="is-hidden-mobile">
                            {!!s.defendingSolarSystem && <SolarSystemLink solarSystem={s.defendingSolarSystem} />}
                            {!s.defendingSolarSystem && <LocationLink x={s.locationX} y={s.locationY} />}

                        </td>
                        <td className="is-hidden-mobile">
                            {!!s.defendingPlayer && <PlayerLink player={s.defendingPlayer} />}
                        </td>
                        <td className="is-hidden-mobile">
                            {!!s.attackingPlayer && <PlayerLink player={s.attackingPlayer} />}
                        </td>
                        <td>
                            {combatReportResult(s)}
                        </td>
                        <td>
                            {ValueFormatter.formatDateFromNowWithMoment(s.date)}
                        </td>
                        <td>
                            <ViewButton to={combatReport(s.combatReportId)} />
                        </td>
                    </tr>
                </React.Fragment>)}
            </Table>
        }
    </>;
});

export const CombatReportIndex = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const unreadState = React.useContext(UnreadStateContext);

    const [combatReportStatus, setCombatReportStatus] = React.useState<CombatReportStatus | undefined>(undefined);

    function list() {
        return apiState.CombatReportClient.list(undefined).then(combatReports => {
            unreadState.clearUnread("combat");
            return combatReports;
        });
    }

    return <InboxContainer
        entityName="Combat Report"
        list={list}
        icon={IconHelper.Combat.CombatReport}
        deleteAll={() => apiState.CombatReportClient.deleteAll()}
        markAllAsRead={() => apiState.CombatReportClient.markAllRead()}
        render={combatReports => {
            return <CombatReportIndexInner
                combatReports={combatReports}
                combatReportStatus={combatReportStatus}
                setCombatReportStatus={setCombatReportStatus}
            />;
        }}
    />;
});
