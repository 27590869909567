import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, ISolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { CancelButton } from '../../../Components/Base/Buttons/CancelButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ShipInputList, ShipTypeQuantity } from '../../SendShips/Components/ShipInputList';
import { ActivatingPromptText } from '../Components/Prompts';
import { UpkeepSummary } from '../Components/UpkeepSummary';

type Props = {
    solarSystem: ISolarSystemDetail,
    ships: { [key: string]: number },
    activateShipsCallback: (ships: { [key: string]: number }) => any,
    closeCallback: () => any
}

export const ActivateShipsForm = observer((props: Props) => {

    const [shipQuantities, setShipQuantities] = React.useState<{ [key: string]: number }>({});

    const [availableFleetWrapper, setAvailableFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);
    const [selectedFleetWrapper, setSelectedFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);

    const worldState = React.useContext(WorldStateContext);

    React.useEffect(() => {
        if (!availableFleetWrapper && worldState.ShipTypeSettings && worldState.GameSettings) {
            setAvailableFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, props.ships));
        }
    }, [worldState.ShipTypeSettings, worldState.GameSettings, props.ships.ships])

    React.useEffect(() => {
        if (worldState.ShipTypeSettings && worldState.GameSettings) {
            setSelectedFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities));
        }
    }, [worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities]);

    function setShipQuantity(ship: ShipTypeQuantity, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }
        if (quantity > ship.quantity) {
            quantity = ship.quantity;
        }
        const newObject = {
            ...shipQuantities
        };
        newObject[ship.ship.typeName || ""] = quantity;
        setShipQuantities(newObject);
    }

    if (!worldState.ShipTypeSettings || !worldState.ItemTypeSettings || !availableFleetWrapper || !selectedFleetWrapper) {
        return null;
    }

    const upkeepQuantityCost = CollectionHelper.sumOfDictionaryValue(shipQuantities, (shipTypeName, quantity) => shipTypeName in worldState.ShipTypeSettings!.data ? worldState.ShipTypeSettings!.data[shipTypeName].upkeepPerHour * quantity : 0);

    return <SubPanel heading={{ text: "Activate Ships", icon: IconHelper.Ships.Activate }}
        footerContent={<ButtonHolder isPulledRight>
            <CancelButton action={props.closeCallback} />
            <Button type="action" icon={IconHelper.Ships.Activate} text="Activate ships" action={() => props.activateShipsCallback(shipQuantities)} />
        </ButtonHolder>}>
        <Paragraph type="prompt">Choose which ships to activate.</Paragraph>
        <ActivatingPromptText />
        <ShipInputList
            shipTypeSettings={worldState.ShipTypeSettings}
            availableShips={availableFleetWrapper}
            selectedShips={selectedFleetWrapper}
            movementType={FleetMovementType.ReturnHome}
            setShipQuantity={(s, q) => setShipQuantity(s, q)}
            setShipTactic={(s, t) => { }}
            shipQuantities={shipQuantities}
            showTactics={false}
            showUpkeep
            onlyShowAvailable
            tactics={{}}
        />
        {upkeepQuantityCost > 0 && <UpkeepSummary
            key="activate-ships-form"
            additionalUpkeep={upkeepQuantityCost}
            additionalUpkeepIcon={IconHelper.Ships.Activate}
            additionalUpkeepLabel="Activated Ships"
            items={props.solarSystem.items}
            itemsDetail={props.solarSystem.itemsDetail}
            itemTypeSettings={worldState.ItemTypeSettings}
        />}
    </SubPanel>;
});