import { observer } from "mobx-react-lite";
import React from "react";
import { CombatRoundType, IShipTypeSettings } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { valuesFromEnum } from "../../../Helpers/TypeHelper";
import { ButtonChooser, choice } from "../../Base/Buttons/ButtonChooser";
import { Table } from "../../Base/Containers/Table";
import { ShipTypeImageAndLink } from "../Images/ShipTypeImage";
import { ShipTypeShortStats } from "./ShipTypeShortStats";

type Tactics = { [key: string]: CombatRoundType };

type Props = {
    tactics: Tactics | undefined,
    setTactics: (tactics: Tactics) => void,
    shipTypeSettings: IShipTypeSettings,
    factionTypeName: string,
    shipTypeNames?: string[] | undefined
}

export const TacticsTable = observer((props: Props) => {

    const [tactics, setTactics] = React.useState<Tactics | undefined>(undefined);

    React.useEffect(() => {
        setTactics(undefined);
    }, [props.tactics, props.shipTypeNames])

    function setTactic(shipTypeName: string, tactic: CombatRoundType) {
        if (tactics !== undefined) {
            tactics[shipTypeName] = tactic;
            props.setTactics(tactics);
        }
        return true;
    }

    const shipTypes = Object.keys(props.shipTypeSettings.data).map(c => {
        return {
            tactic: props.tactics && c in props.tactics ? props.tactics[c] : props.shipTypeSettings.data[c].defaultCombatRoundType,
            shipType: props.shipTypeSettings.data[c]
        };
    })
        .filter(c => c.shipType.factionTypeName === props.factionTypeName && (props.shipTypeNames === undefined || props.shipTypeNames.includes(c.shipType.typeName)))
        .sort((a, b) => a.shipType.order - b.shipType.order);

    if (tactics === undefined) {
        const tactic: Tactics = {};
        for (let s of shipTypes) {
            tactic[s.shipType.typeName] = s.tactic;
        }
        setTactics(tactic);
    }

    const options = valuesFromEnum(CombatRoundType)
        .filter(c => c != CombatRoundType.Grounded)
        .map(k => choice<CombatRoundType>(k, CombatRoundType[k], IconHelper.Combat.combatRoundType(k)));

    return <Table isHoverable>
        {shipTypes.map(s => <tr key={s.shipType.typeName}>
            <td>
                <ShipTypeImageAndLink shipType={s.shipType} />
            </td>
            <td className="is-hidden-mobile">
                <ShipTypeShortStats shipType={s.shipType} />
            </td>
            <td>
                <ButtonChooser isPulledRight values={options} value={s.tactic} valueChanged={t => setTactic(s.shipType.typeName, t)} />
            </td>
        </tr>)}
    </Table>
});