import * as React from 'react';
import { FleetMovementType, IItemsDetail, ISolarSystemDetail, ItemCategory } from '../../../ApplicationState/ApiClient';
import { ButtonChooser, choice } from '../../../Components/Base/Buttons/ButtonChooser';
import { Table } from '../../../Components/Base/Containers/Table';
import { DateText } from '../../../Components/Base/Text/DateText';
import { SendShipsBetweenSystemButtons } from '../../../Components/FusionShift/Buttons/SendShipsBetweenSystemButtons';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { EmpireViewProps } from '../EmpireViewProps';
import { Cell } from './Shared';

type RowProps = {
    setSourceSolarSystemId: (sourceSolarSystemId: number | undefined) => any,
    sourceSolarSystemId: number | undefined,
    showSendButtons: boolean,
    solarSystem: SolarSystemWrapper,
    resourceTypeName: string
}

const movementTypes = [
    FleetMovementType.Delivery,
    FleetMovementType.Collection
];

const Row = (props: RowProps) => {

    function value(get: (solarSystem: ISolarSystemDetail) => { [key: string]: number } | undefined): number {
        const collection = get(props.solarSystem.solarSystem);
        if (collection && props.resourceTypeName in collection) {
            return collection[props.resourceTypeName];
        }

        return 0;
    }

    function valueFromItems(get: (itemsDetail: IItemsDetail) => { [key: string]: number } | undefined): number {
        if (props.solarSystem.solarSystem.itemsDetail) {
            const collection = get(props.solarSystem.solarSystem.itemsDetail);
            if (collection && props.resourceTypeName in collection) {
                return collection[props.resourceTypeName];
            }
        }

        return 0;
    }

    const quantity = value(e => e.items);
    const capacity = value(e => e.itemsDetail && e.itemsDetail.resourceCapacities);
    const net = value(e => e.itemsDetail && e.itemsDetail.netFinal);
    const remainingUntilExpiry =
        net && net > 0 ?
            (quantity && capacity ? capacity - quantity : 0) :
            (quantity ? quantity : 0);
    const hours = remainingUntilExpiry > 0 && net ? remainingUntilExpiry / Math.abs(net) : undefined;
    const expiryDate = hours ? new Date() : undefined;

    if (hours && expiryDate) {
        expiryDate.setTime(expiryDate.getTime() + (hours * 60 * 60 * 1000));
    }

    return <tr>
        <td>
            <SelectSolarSystemLink solarSystem={props.solarSystem.solarSystem} hideActionIcons />
        </td>
        <Cell value={valueFromItems(s => s.harvested.totalIncome)} />
        <Cell value={valueFromItems(s => s.harvested.totalBonusesPercent)} type="percent" dangerIfNegative />
        <Cell value={valueFromItems(s => s.upkeep.netTotal) + valueFromItems(s => s.production.netTotal) + valueFromItems(s => s.manufactories.consumedByInputNodes)} dangerIfNegative negated />
        <Cell value={valueFromItems(s => s.netFinal)} dangerIfZero />
        <Cell value={value(s => s.items)} />
        <Cell value={value(e => e.itemsDetail && e.itemsDetail.resourceCapacities)} />
        <td>
            {expiryDate &&
                <span className={net !== undefined && net < 0 ? "has-text-danger" : ""}>
                    <DateText date={expiryDate} />
                </span>
            }
            {quantity !== undefined && net !== undefined && quantity <= 0 && net < 0 &&
                <span className="has-text-danger">
                    EMPTY
                </span>
            }
            {quantity !== undefined && quantity >= capacity &&
                <span className="has-text-danger">
                    FULL
                </span>
            }
        </td>
        <td className="is-double-button">
            <SendShipsBetweenSystemButtons {...props} solarSystem={props.solarSystem.solarSystem} movementTypes={movementTypes} />
        </td>
    </tr>;
};

export const Items = (props: EmpireViewProps) => {

    const [selectedResourceTypeName, setSelectedResourceTypeName] = React.useState("Gas");

    const [sourceSolarSystemId, setSourceSolarSystemId] = React.useState<number | undefined>(undefined);

    function lazyValueFrom(key: string, dictionary: { [key: string]: number } | undefined) {
        if (dictionary === undefined || dictionary === null) {
            return 0;
        }

        return key in dictionary ? dictionary[key] : 0;
    }

    const totals = React.useMemo(() => {

        let harvested = 0;
        let upkeep = 0;
        let net = 0;
        let current = 0;
        let capacity = 0;

        for (const solarSystem of props.solarSystems) {
            if (solarSystem.solarSystem.items && selectedResourceTypeName in solarSystem.solarSystem.items) {
                current += solarSystem.solarSystem.items[selectedResourceTypeName];
            }

            const items = solarSystem.solarSystem.itemsDetail;

            if (items) {

                upkeep += lazyValueFrom(selectedResourceTypeName, items.upkeep.netTotal) +
                    lazyValueFrom(selectedResourceTypeName, items.production.netTotal) +
                    lazyValueFrom(selectedResourceTypeName, items.manufactories.consumedByInputNodes);

                harvested += lazyValueFrom(selectedResourceTypeName, items.harvested.netTotal);
                net += lazyValueFrom(selectedResourceTypeName, items.netFinal);
                capacity += lazyValueFrom(selectedResourceTypeName, items.resourceCapacities);
            }
        }

        return {
            harvested,
            upkeep,
            net,
            current,
            capacity
        }

    }, [selectedResourceTypeName, props.solarSystems]);

    const options = Object.keys(props.itemTypeSettings.data)
        .map(r => props.itemTypeSettings.data[r])
        .filter(r => r.category === ItemCategory.Resource)
        .sort((a, b) => a.order - b.order)
        .map(r => choice(r.typeName, r.name, IconHelper.Items.resource(r.typeName), r.typeName.toLocaleLowerCase()));

    return <>
        <ButtonChooser values={options} valueChanged={setSelectedResourceTypeName} value={selectedResourceTypeName} />
        <Table isHoverable isFullWidth heading={
            <>
                <th>
                </th>
                <th>
                    Harvested
                </th>
                <th>
                    Bonus
                </th>
                <th>
                    Upkeep,<br />Manufactories &<br />Production
                </th>
                <th>
                    Net Income
                </th>
                <th>
                    Current
                </th>
                <th>
                    Capacity
                </th>
                <th>
                    Capacity Reached /<br />Fully Consumed
                </th>
                <th></th>
            </>
        }>
            {props.solarSystems.map(s => <Row
                key={s.solarSystem.solarSystemId}
                solarSystem={s}
                resourceTypeName={selectedResourceTypeName}
                sourceSolarSystemId={sourceSolarSystemId}
                setSourceSolarSystemId={setSourceSolarSystemId}
                showSendButtons={props.solarSystems.length > 1}
            />)}
            <tr className="is-total">
                <td>Total</td>
                <Cell value={totals.harvested} />
                <td />
                <Cell value={totals.upkeep} dangerIfNegative negated />
                <Cell value={totals.net} dangerIfNegative />
                <Cell value={totals.current} />
                <Cell value={totals.capacity} />
                <td />
                <td />
            </tr>
        </Table>
    </>;
};

