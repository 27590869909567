import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ContractStatus, ContractType, FleetMovementType, SecurityStatus, ShipClass, SolarSystemClaimType, WorldRegion } from '../../../ApplicationState/ApiClient';
import { Icon } from '../../../Components/Base/Icon';
import { TextAndIcon } from '../../../Components/Base/Text/TextAndIcon';
import { ItemIconWithQuantity } from '../../../Components/FusionShift/Icons/Items/ItemIconWithQuantity';
import { ResourceIcon } from '../../../Components/FusionShift/Icons/Items/ResourceIcon';
import { SecurityStatusIcon } from '../../../Components/FusionShift/Icons/SecurityStatusIcon';
import { ShipClassIcon } from '../../../Components/FusionShift/Icons/ShipClassIcon';
import { SolarSystemClaimTypeIcon } from '../../../Components/FusionShift/Icons/SolarSystemClaimTypeIcon';
import { ItemTypeLink } from '../../../Components/FusionShift/Links/Items/ItemTypeLink';
import { SolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { CelestialHelper } from '../../../Helpers/CelestialHelper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { ContractHelper } from '../../../Helpers/ContractHelper';
import { FleetHelper } from '../../../Helpers/FleetHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { MarketOrderHelper } from '../../../Helpers/MarketOrderHelper';
import { TimeHelper } from '../../../Helpers/TimeHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { MarketTradeSummary } from '../../Marketplace/Components/MarketTradeSummary';
import { SendShipsBag } from '../SendShipsBag';
import { ContractPayout } from './ContractPayout';
import { DurationStepsBreadcrumbsWrapper } from './DurationSteps';
import { TargetTextDescription } from './Target/TargetTextDescription';

type Props = {
    sendShipsBag: SendShipsBag
}

export const Summary = observer((props: Props) => {

    function cargoCapacityOnly() {

        const byMovementType = props.sendShipsBag.MovementType && (props.sendShipsBag.MovementType == FleetMovementType.Attack || props.sendShipsBag.MovementType == FleetMovementType.Mine);
        const byContractType = props.sendShipsBag.Contract && props.sendShipsBag.Contract.type === ContractType.Courier;

        const capacity = props.sendShipsBag.MovementType === FleetMovementType.Mine ? props.sendShipsBag.fleetWrapper.MiningCargoCapacity : props.sendShipsBag.fleetWrapper.TotalCargoCapacity;

        if (byContractType || byMovementType) {
            return <li><Icon icon={IconHelper.Items.Item} />{`${ValueFormatter.formatLocaleNumber(capacity)} capacity`}</li>;
        }
    }

    function cargoCapacityAndUse() {

        const show = props.sendShipsBag.allowedConfigurations.itemsToSend ||
            props.sendShipsBag.allowedConfigurations.commoditiesToSend ||
            props.sendShipsBag.allowedConfigurations.itemsToCollect ||
            props.sendShipsBag.MovementType === FleetMovementType.Trade ||
            props.sendShipsBag.Orders.trade ||
            CollectionHelper.isAnyQuantityInDictionary(props.sendShipsBag.itemsToCollect);

        if (show) {
            const sum = CollectionHelper.sumOfDictionary(props.sendShipsBag.itemsToSend) +
                (props.sendShipsBag.Orders.trade ? props.sendShipsBag.Orders.trade.quantity : 0)
                + CollectionHelper.sumOfDictionary(props.sendShipsBag.itemsToCollect);
            return <li><Icon icon={IconHelper.Items.Item} />{`${ValueFormatter.formatLocaleNumber(sum)} / ${ValueFormatter.formatLocaleNumber(props.sendShipsBag.fleetWrapper.TotalCargoCapacity)} capacity`}</li>;
        }
    }

    function combatStats() {

        const totalDefence = props.sendShipsBag.fleetWrapper.TotalDefence;
        const defenceRequired = props.sendShipsBag.Contract !== undefined && ContractHelper.isCourierTerms(props.sendShipsBag.Contract.terms) ? props.sendShipsBag.Contract.terms.minimumTotalDefence : undefined;
        const notEnoughDefence = defenceRequired && totalDefence < defenceRequired;

        const defence = <li className={notEnoughDefence ? "has-text-danger" : ""}>
            <Icon icon={IconHelper.Combat.Defence} />
            Total defence: {ValueFormatter.formatLocaleNumber(totalDefence)} {!!defenceRequired && ` (${ValueFormatter.formatLocaleNumber(defenceRequired)} required)`}
        </li>;

        if (props.sendShipsBag.MovementType === FleetMovementType.Attack) {
            const siege = props.sendShipsBag.fleetWrapper.TotalSiegeDamage;
            const siegeIcon = siege > 0 ? <li>
                <Icon icon={IconHelper.Combat.Siege} />
                Total siege damage: {ValueFormatter.formatLocaleNumber(siege)}
            </li> : null;

            const totalAttack = props.sendShipsBag.fleetWrapper.TotalAttack(props.sendShipsBag.IsIntercept);
            const attackRequired = props.sendShipsBag.Contract !== undefined && ContractHelper.isAttackTerms(props.sendShipsBag.Contract.terms) ? props.sendShipsBag.Contract.terms.minimumTotalAttack : undefined;
            const notEnoughAttack = attackRequired && totalAttack < attackRequired;

            return <>
                <li className={notEnoughAttack ? "has-text-danger" : ""}>
                    <Icon icon={IconHelper.Combat.Attack} />
                    Total attack: {ValueFormatter.formatLocaleNumber(totalAttack)} {!!attackRequired && ` (${ValueFormatter.formatLocaleNumber(attackRequired)} required)`}
                </li>
                {siegeIcon}
                {defence}
            </>;
        }

        return defence;
    }

    function duration() {

        const time = props.sendShipsBag.timeToTarget();

        if (time) {

            return <li>
                <DurationStepsBreadcrumbsWrapper {...props} toTarget={time} />
            </li>;
        }
    }

    function beginnersProtectionWarning() {

        if (props.sendShipsBag.MovementType && FleetHelper.isHostileMovementType(props.sendShipsBag.MovementType) &&
            props.sendShipsBag.TargetSolarSystem &&
            props.sendShipsBag.TargetSolarSystem.owner &&
            props.sendShipsBag.init.player.hasBeginnersProtection) {
            return <li className="has-text-warning">
                <Icon icon={IconHelper.Security.BeginnersProtection} />
                {`Sending this ${ValueFormatter.friendlyNameForMovementType(props.sendShipsBag.MovementType)} will end your beginner's protection.`}
            </li>
        }
    }

    function claimNoItemsWarning() {

        if (props.sendShipsBag.MovementType === FleetMovementType.Claim) {

            if (!CollectionHelper.isAnyNonZeroQuantityInDictionary(props.sendShipsBag.itemsToSend)) {
                return <li className="has-text-warning">
                    <Icon icon={IconHelper.Items.Item} />
                    {`You are sending a fleet to claim a system but have not selected any resources to send.`}
                </li>;
            }
        }
    }

    function claimResult() {

        if (props.sendShipsBag.MovementType === FleetMovementType.Claim && props.sendShipsBag.validation.valid) {

            const claimType = props.sendShipsBag.Orders.claimType ?? SolarSystemClaimType.Colony;
            const claimTypeName = SolarSystemClaimType[claimType].toString().toLocaleLowerCase();
            const targetCelestial = props.sendShipsBag.TargetCelestial ? CelestialHelper.fullname(props.sendShipsBag.TargetCelestial, props.sendShipsBag.init.celestialTypeSettings) : "Target";

            return <li>
                <SolarSystemClaimTypeIcon solarSystem={{ claimType }} />
                {`${targetCelestial} will be claimed as ${ValueFormatter.aOrAn(claimTypeName)} ${claimTypeName}.`}
            </li>;
        }
    }

    function securityStatusWarning() {

        if (props.sendShipsBag.MovementType && props.sendShipsBag.TargetSolarSystem) {

            if (props.sendShipsBag.hasCivilianCasualties() && props.sendShipsBag.TargetSolarSystem.region === WorldRegion.Core && props.sendShipsBag.init.player.securityStatus !== SecurityStatus.Exile) {
                return <li className="has-text-warning">
                    <SecurityStatusIcon securityStatus={SecurityStatus.Exile} />
                    {`Causing civilian casualties in the core will change your security status to ${ValueFormatter.friendlyNameForSecurityStatus(SecurityStatus.Exile)}.`}
                </li>;
            }

            const newSecurityStatus = FleetHelper.securityStatusChange(props.sendShipsBag.MovementType, props.sendShipsBag.init.player, props.sendShipsBag.TargetSolarSystem);

            if (newSecurityStatus !== undefined) {

                return <li className="has-text-warning">
                    <SecurityStatusIcon securityStatus={newSecurityStatus} />
                    {`Sending this ${ValueFormatter.friendlyNameForMovementType(props.sendShipsBag.MovementType)} will change your security status to ${ValueFormatter.friendlyNameForSecurityStatus(newSecurityStatus)}.`}
                </li>;
            }
        }
    }

    function siegeWarning() {
        if (!props.sendShipsBag.TargetSolarSystem || props.sendShipsBag.MovementType !== FleetMovementType.Attack) {
            return null;
        }

        if (props.sendShipsBag.TargetCelestialId === undefined && props.sendShipsBag.fleetWrapper.TotalSiegeDamage > 0) {
            return <li className="has-text-warning">
                <ShipClassIcon shipClass={ShipClass.OrbitalSiege} />
                No target specified for siege damage
            </li>;
        }

        return null;
    }

    function populationWarning() {
        if (!props.sendShipsBag.hasCivilianCasualties()) {
            return null;
        }
        const targetCelestial = props.sendShipsBag.TargetCelestialId && props.sendShipsBag.TargetSolarSystem ? props.sendShipsBag.TargetSolarSystem.celestials.find(c => c.celestialId === props.sendShipsBag.TargetCelestialId) : undefined;

        if (targetCelestial?.celestialTypeName === undefined || targetCelestial?.celestialTypeName === null) {
            return <li className="has-text-danger">
                <Icon icon={IconHelper.Combat.Murder} />
                {`${props.sendShipsBag.TargetSolarSystem!.name} has a civlian population. You may become a murderer if you cause siege damage to a civilian population.`}
            </li>;
        }

        return <li className="has-text-danger">
            <Icon icon={IconHelper.Combat.Murder} />
            {`${props.sendShipsBag.TargetSolarSystem!.name}: ${targetCelestial!.name} has a population of ${ValueFormatter.formatPopulation(targetCelestial!.population)}. You will be a murderer if you execute this order.`}
        </li>;
    }

    function commodities() {
        if (props.sendShipsBag.MovementType === FleetMovementType.CommoditySell &&
            props.sendShipsBag.TargetSolarSystem &&
            props.sendShipsBag.TargetSolarSystem.commodityBuyOffersByCelestialId &&
            props.sendShipsBag.TargetCelestialId) {

            const celestial = props.sendShipsBag.TargetSolarSystem.celestials.find(c => c.celestialId === props.sendShipsBag.TargetCelestialId);
            const celestialName = celestial ? celestial.name : "target celestial";
            const celestialOrders = props.sendShipsBag.TargetSolarSystem.commodityBuyOffersByCelestialId[props.sendShipsBag.TargetCelestialId];
            const warnings: React.ReactNode[] = [];

            for (let i in props.sendShipsBag.itemsToSend) {
                const quantity = props.sendShipsBag.itemsToSend[i];
                const buyingQuantity = i in celestialOrders ? celestialOrders[i].quantity : 0;

                if (quantity > buyingQuantity) {
                    warnings.push(<>
                        {ValueFormatter.formatLocaleNumber(quantity)} {props.sendShipsBag.init.itemTypeSettings.data[i].name} in cargo but {props.sendShipsBag.TargetSolarSystem.name}:{celestialName} will buy {buyingQuantity === 0 ? "none" : `only ${ValueFormatter.formatLocaleNumber(buyingQuantity)}`}
                    </>)
                }
            }

            if (warnings.length > 0) {
                return <>
                    {warnings.map((w, i) => <li key={i} className="has-text-warning">{w}</li>)}
                </>;
            }
        }

        return null;
    }

    function contract() {
        if (props.sendShipsBag.Contract === undefined) {
            return null;
        }

        const isContractGainForPlayer = props.sendShipsBag.Contract.type !== ContractType.Ransom

        return <>
            <li className="has-text-danger">
                Fleets on a contract can't be called home
            </li>
            {props.sendShipsBag.Contract.status === ContractStatus.Accepted && <li className="has-text-warning">
                The contract has already been accepted {isContractGainForPlayer && " and so you may not get paid"}
            </li>}
            {isContractGainForPlayer &&
                <li>
                    <ContractPayout contract={props.sendShipsBag.Contract} fleetWrapper={props.sendShipsBag.fleetWrapper} />
                </li>
            }
        </>;
    }

    function resourceToBeMined() {
        if (props.sendShipsBag.TargetSolarSystem && props.sendShipsBag.MovementType === FleetMovementType.Mine) {
            const celestial = props.sendShipsBag.TargetSolarSystem.celestials.find(c => c.celestialId === props.sendShipsBag.TargetCelestialId);
            const itemType = CelestialHelper.itemTypeFromCelestial(props.sendShipsBag.init.celestialTypeSettings, props.sendShipsBag.init.itemTypeSettings, celestial);

            const hours = props.sendShipsBag.init.gameSettings.fleets.miningDurationHours * props.sendShipsBag.init.gameSettings.gameSpeed;
            const timeSpan = hours !== undefined ? TimeHelper.createTimeSpanFromHours(hours) : undefined;
            const timeToMine = timeSpan ? TimeHelper.formatTimeSpanByWords(timeSpan) : "";

            const desires = props.sendShipsBag.fleetWrapper.MiningCargoCapacity;

            if (itemType) {
                return <>
                    <li>
                        <ItemTypeLink itemType={itemType} /> will be mined for {timeToMine}
                    </li>
                    {celestial !== undefined && celestial.resources < desires &&
                        <li className="has-text-warning">
                            {CelestialHelper.fullname(celestial, props.sendShipsBag.init.celestialTypeSettings)} only has <ItemIconWithQuantity quantity={celestial.resources} itemType={itemType} />
                        </li>
                    }
                </>;
            }
        }
    }

    function trade() {

        if (props.sendShipsBag.Orders.trade && props.sendShipsBag.TargetSolarSystem && props.sendShipsBag.TargetSolarSystem.marketOrders) {
            const hypotheticalAmount = MarketOrderHelper.hypotheticallyAcceptMarketOrders(props.sendShipsBag.Orders.trade, props.sendShipsBag.TargetSolarSystem.marketOrders);

            return <>
                <li>
                    <MarketTradeSummary quantityOfOffered={props.sendShipsBag.Orders.trade.quantity} offeredItemTypeName={props.sendShipsBag.Orders.trade.offeredItemTypeName} quantityOfDesired={hypotheticalAmount} desiredItemTypeName={props.sendShipsBag.Orders.trade.desiredItemTypeName} />
                </li>
                {hypotheticalAmount !== undefined && props.sendShipsBag.fleetWrapper.TotalCargoCapacity > 0 && hypotheticalAmount > props.sendShipsBag.fleetWrapper.TotalCargoCapacity &&
                    props.sendShipsBag.Orders.trade.desiredItemTypeName !== props.sendShipsBag.init.gameSettings.economy.creditsPlaceholderItemTypeName &&
                    <li className="has-text-warning">
                        Cargo capacity would be exceeded on return journey
                    </li>}
            </>;
        }

        return null;
    }

    function mass() {
        const items = CollectionHelper.sumOfDictionary(props.sendShipsBag.itemsToSend);
        const ships = props.sendShipsBag.fleetWrapper.TotalMass;

        const mass = items + ships;

        if (mass > 0) {
            return <li>
                Mass: {ValueFormatter.formatMass(mass, false)}
            </li>;
        }

        return null;
    }

    function target() {
        if (!props.sendShipsBag.HasTarget) {
            return null;
        }

        return <li><TargetTextDescription {...props} /></li>
    }

    function source() {
        return <li>Sending from: <SolarSystemLink solarSystem={props.sendShipsBag.init.sourceSolarSystem} /></li>
    }

    function cost() {

        if (!props.sendShipsBag.fleetWrapper.HasAnyShips || props.sendShipsBag.MovementType === undefined) {
            return null;
        }

        const target = props.sendShipsBag.TargetFleetInterception !== undefined ? props.sendShipsBag.TargetFleetInterception.coordinate : props.sendShipsBag.TargetSolarSystem;

        if (target === undefined) {
            return null;
        }

        const resourceType = props.sendShipsBag.init.itemTypeSettings.data[props.sendShipsBag.init.gameSettings.solarSystem.shipUpkeepItemTypeName];

        const distance = FleetHelper.distance(props.sendShipsBag.init.sourceSolarSystem, target);
        const cost = FleetHelper.calculateCostToSendShips(props.sendShipsBag.init.gameSettings, props.sendShipsBag.fleetWrapper, distance, props.sendShipsBag.MovementType);
        const text = `Sending this fleet will cost ${ValueFormatter.formatLocaleNumber(cost)} ${resourceType.name}`;

        return <li><TextAndIcon
            icon={<ResourceIcon resourceType={resourceType} quantityForTitle={cost} />}
            text={text}
        />
        </li>
    }

    return <ul>
        {duration()}
        {source()}
        {target()}
        {cost()}
        {contract()}
        {populationWarning()}
        {siegeWarning()}
        {beginnersProtectionWarning()}
        {securityStatusWarning()}
        {claimNoItemsWarning()}
        {trade()}
        {commodities()}
        {cargoCapacityAndUse()}
        {cargoCapacityOnly()}
        {resourceToBeMined()}
        {claimResult()}
        {combatStats()}
        {mass()}
    </ul>;
});