import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PlayerStateContext } from '../../../../ApplicationState/ContextRoot';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const EmblemRequired = observer((props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!props.mission.MissionType.isEmblemRequired || !playerState.Player) {
        return null;
    }

    const hasEmblem = playerState.Player.emblemFilename && playerState.Player.emblemFilename.length > 0;

    return <ObjectiveRow has={hasEmblem ? 1 : 0} requires={1} {...props}>
        Create an emblem
    </ObjectiveRow>;
});