import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PresidentialTermDetail } from '../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext } from '../../ApplicationState/ContextRoot';
import { HistoryBackButton } from '../../Components/Base/Buttons/BackButton';
import { Button } from '../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { HelpButton } from '../../Components/Base/Buttons/HelpButton';
import { Level, levelContentWithHeading } from '../../Components/Base/Containers/Level';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { PlayerLink } from '../../Components/FusionShift/Links/PlayerLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { PoliticsHelper } from '../../Helpers/PoliticsHelper';
import { UrlHelper } from '../../Helpers/UrlHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { SimpleBaseView } from '../BaseView';
import { CantVoteMessage } from './Components/CantVoteMessage';
import { ManifestoForm } from './Manifestos/ManifestoForm';
import { ManifestosTable } from './Manifestos/ManifestoTable';
import { PolicyTableInPanel } from './PolicyTable';


export const TermView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const { presidentialTermId } = useParams();

    const [term, setTerm] = React.useState<PresidentialTermDetail | undefined>(undefined);

    const [isEditManifestoVisible, setIsEditManifestoVisible] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {

        UrlHelper.tryParseSearch(location, p => {
            if (p.get("submitManifesto") !== null) {
                setIsEditManifestoVisible(true);
            }
        });
    }, []);

    React.useEffect(() => {
        if (term === undefined) {
            apiState.PoliticsClient.getTermDetail(Number(presidentialTermId)).then(term => {
                if (term) {
                    setTerm(term);
                }
            });
        }
    }, [presidentialTermId, term]);

    if (!term || playerState.Player === undefined) {
        return <LoadingSpinner />;
    }

    function reloadCallback() {
        setIsEditManifestoVisible(false);
        setTerm(undefined);
    }

    const content = [

        levelContentWithHeading("Status", PoliticsHelper.termStatus(term)),
        !term.hasVotesBeenCounted && levelContentWithHeading("Voting Ends", ValueFormatter.formatDateFromNowWithMoment(term.startDate)),
        levelContentWithHeading("Term Start", ValueFormatter.formatShortDate(term.startDate)),
        levelContentWithHeading("Term End", ValueFormatter.formatShortDate(term.endDate))
    ];

    const yourManifesto = term.presidentialManifestos.find(x => x.playerId === playerState.Player!.playerId);

    return <SimpleBaseView
        heading={{ text: "Presidential Term", icon: IconHelper.Politics.Politics }}
        headingContent={<ButtonHolder isPulledRight>
            <HistoryBackButton />
            <HelpButton to={"politics"} />
        </ButtonHolder>}>
        {!term.hasVotesBeenCounted && <CantVoteMessage />}
        <Level content={content} />
        {isEditManifestoVisible && <ManifestoForm reloadCallback={reloadCallback} presidentialTermId={term.presidentialTermId} presidentialManifesto={yourManifesto} />}
        {!isEditManifestoVisible && <>
            <SubPanel heading="Overview" footerContent={!term.hasVotesBeenCounted && <Button isPulledRight type="nav" icon={yourManifesto !== undefined ? IconHelper.General.Edit : IconHelper.General.Plus} action={() => setIsEditManifestoVisible(true)} text={yourManifesto !== undefined ? "Edit Manifesto" : "Submit Manifesto"} />}>
                <>
                    {!term.hasVotesBeenCounted && <>
                        <Paragraph>
                            This is an upcoming presidential term and manifestos can be submitted and then voted on. Voting will end {ValueFormatter.formatDateFromNowWithMoment(term.startDate)}.
                        </Paragraph>
                        {yourManifesto !== undefined && <Paragraph>
                            You have submitted a manifesto titled "{yourManifesto.title}".
                        </Paragraph>}
                    </>}
                    {term.hasVotesBeenCounted && <Paragraph>
                        Voting has closed on this term and {term.winningPresidentialManifesto !== undefined ? <PlayerLink player={term.winningPresidentialManifesto.player} /> : "no one"} {" "}was declared the winner.
                    </Paragraph>}
                </>
            </SubPanel>
            {term.policies.length > 0 &&
                <PolicyTableInPanel policies={term.policies} reloadCallback={reloadCallback} />
            }
            <SubPanel heading="Manifestos" isUnpadded={term.presidentialManifestos.length > 0}>
                <ManifestosTable reloadCallback={reloadCallback} presidentialTerm={term} areVotesCounted={term.hasVotesBeenCounted} />
            </SubPanel>
        </>}
    </SimpleBaseView>;
});