import React from "react";
import { IRepeatFleet, ISolarSystemDetail } from "../../../ApplicationState/ApiClient";
import { SolarSystemStateContext } from "../../../ApplicationState/ContextRoot";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { IconHelper } from "../../../Helpers/IconHelper";
import { RepeatFleetList } from "../../Fleets/SubParts/RepeatFleetList";
import { SendShipsBag } from "../SendShipsBag";

type Props = {
    sendShipsBag: SendShipsBag
}

export const ExistingRepeats = (props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    const sourceOwnSystem = props.sendShipsBag.init.sourceSolarSystem;
    const targetOwnSystem = props.sendShipsBag.TargetSolarSystem as ISolarSystemDetail;

    function reloadSolarSystem(solarSystem: ISolarSystemDetail) {
        solarSystemState.loadSolarSystem(solarSystem);
    }

    if (targetOwnSystem === undefined) {
        return null;
    }

    const sourceToTarget: IRepeatFleet[] = [];
    const targetToSource: IRepeatFleet[] = [];

    for (const repeat of sourceOwnSystem.repeatFleets ?? []) {
        if (repeat.targetSolarSystemId === targetOwnSystem.solarSystemId) {
            sourceToTarget.push(repeat);
        }
    }

    for (const repeat of targetOwnSystem.repeatFleets ?? []) {
        if (repeat.targetSolarSystemId === sourceOwnSystem.solarSystemId) {
            targetToSource.push(repeat);
        }
    }

    if (sourceToTarget.length === 0 && targetToSource.length === 0) {
        return null;
    }

    return <>
        {sourceToTarget.length > 0 &&
            <SubPanel
                isUnpadded
                heading={{ text: `Repeats from ${sourceOwnSystem.name}`, icon: IconHelper.Ships.Repeat }}
            >
                <RepeatFleetList
                    fleets={sourceToTarget}
                    solarSystem={sourceOwnSystem}
                    reloadCallback={reloadSolarSystem}
                />
            </SubPanel>
        }
        {targetToSource.length > 0 &&
            <SubPanel
                isUnpadded
                heading={{ text: `Repeats from ${targetOwnSystem.name}`, icon: IconHelper.Ships.Repeat }}
            >
                <RepeatFleetList
                    fleets={targetToSource}
                    solarSystem={targetOwnSystem}
                    reloadCallback={reloadSolarSystem}
                />
            </SubPanel>
        }
    </>
};