import React from "react";
import { CelestialFeature } from "../../../Helpers/CelestialHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { BaseProps, Icon } from "../../Base/Icon";

type Props = BaseProps & {
    celestialFeature: CelestialFeature
}

export const CelestialFeatureIcon = (props: Props) => {

    return <Icon icon={IconHelper.Celestials.celestialFeature(props.celestialFeature)} {...props} />;
};