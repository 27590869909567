import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { SearchResult } from '../../ApplicationState/ApiClient';
import { HelpStateContext } from '../../ApplicationState/ContextRoot';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { FederationLink } from '../../Components/FusionShift/Links/FederationLink';
import { InstallationLink } from '../../Components/FusionShift/Links/InstallationLink';
import { PlayerLink } from '../../Components/FusionShift/Links/PlayerLink';
import { SolarSystemLink } from '../../Components/FusionShift/Links/SolarSystemLink';
import { CollectionHelper } from '../../Helpers/CollectionHelper';
import { IconHelper } from '../../Helpers/IconHelper';
import { TextHelper } from '../../Helpers/TextHelper';
import { help_page } from '../../Navigation/Routing/Help';
import { TypeDataRenderer } from './TypeDataRenderer';

type Props = {
    result: SearchResult
}

export const ResultRender = observer((props: Props) => {

    const helpState = React.useContext(HelpStateContext);

    if (!helpState.Help) {
        return <LoadingSpinner />;
    }

    const help = props.result.helpPages.map(x => helpState.Help?.find(y => y.typeName === x))
        .filter(x => x !== undefined)
        .map(x => x!);

    const hasTypeData = CollectionHelper.isAnyInDictionaryAndChildren(props.result.typeData);
    const hasHelpData = help.length > 0;

    const hasHelpOrTypeData = hasTypeData || hasHelpData;

    const hasPlayers = props.result.players.length > 0;
    const hasFederations = props.result.federations.length > 0;
    const hasSolarSystems = props.result.solarSystems.length > 0;
    const hasInstallations = props.result.installations.length > 0;

    const hasGameData = hasPlayers || hasFederations || hasSolarSystems || hasInstallations;

    const hasAny = hasGameData || hasHelpOrTypeData;

    if (!hasAny) {
        return <SubPanel heading="Results">
            <Paragraph>
                No search results
            </Paragraph>
        </SubPanel>;
    }

    return <SplitLayout
        isLeftEmpty={!hasGameData}
        isRightEmpty={!hasHelpOrTypeData}
        left={
            <>
                {hasPlayers && <SubPanel heading={{ text: "Players", icon: IconHelper.Categories.Player }}>
                    <ul>
                        {props.result.players.map(x => <li key={x.playerId}>
                            <PlayerLink player={x} />
                        </li>)}
                    </ul>
                </SubPanel>}
                {hasFederations && <SubPanel heading={"Federations"}>
                    <ul>
                        {props.result.federations.map(x => <li key={x.federationId}>
                            <FederationLink federation={x} />
                        </li>)}
                    </ul>
                </SubPanel>}
                {hasSolarSystems && <SubPanel heading={"Solar Systems"}>
                    <ul>
                        {props.result.solarSystems.map(x => <li key={x.solarSystemId}>
                            <SolarSystemLink solarSystem={x} />
                        </li>)}
                    </ul>
                </SubPanel>}
                {hasInstallations && <SubPanel heading={"Your Installations"}>
                    <ul>
                        {props.result.installations.map(x => <li key={x.installationId}>
                            <SolarSystemLink solarSystem={x.solarSystem} /> {x.celestial.name} <InstallationLink installation={x} />
                        </li>)}
                    </ul>
                </SubPanel>}
            </>
        }
        right={
            <>
                {hasHelpData && <SubPanel heading={{ text: "Help", icon: IconHelper.Categories.Help }}>
                    <ul>
                        {help.map(x => <li key={x.typeName}>
                            <Link to={help_page(x.typeName.toLocaleLowerCase())}>{TextHelper.tidyText(x.name)}</Link>
                        </li>)}
                    </ul>
                </SubPanel>}
                {hasTypeData && <TypeDataRenderer typeData={props.result.typeData} />}
            </>
        }
    />;
});