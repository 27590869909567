import React from "react";
import { IconHelper } from "../../../Helpers/IconHelper";
import { FancyItem } from "../../../Helpers/ItemsHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { BarBlock } from "../../Base/BarBlock";
import { Icon } from "../../Base/Icon";
import { CommoditiesIcon } from "../Icons/Items/CommoditiesIcon";
import { ComponentIcon } from "../Icons/Items/ComponentIcon";
import { ItemIconWithQuantity } from "../Icons/Items/ItemIconWithQuantity";
import { RawResourceIcon } from "../Icons/Items/RawResourceIcon";
import { QuantityIconDisplayProps } from "../Icons/QuantityIcon";

function describeCapacity(has: number, capacity: number | undefined) {
    if (capacity === undefined) {
        return ValueFormatter.formatLocaleNumber(has);
    }
    const percent = has / capacity;
    return `${ValueFormatter.formatLocaleNumber(has)} / ${ValueFormatter.formatLocaleNumber(capacity)} (${ValueFormatter.fromDecimalToDisplayPercent(percent, false, 0)})`
}

function describe(name: string, has: number, capacity: number | undefined) {
    return `${name}: ${describeCapacity(has, capacity)}`;
}

type ResourceProps = QuantityIconDisplayProps & {
    item: FancyItem
}

export const ResourceBarBlock = (props: ResourceProps) => {

    const item = props.item;

    const title = describe(item.itemType.name, item.quantity, item.capacity)

    const iconClassName = item.isDanger ? "has-text-danger-darker" : item.isWarning ? "has-text-warning-darker" : "";

    return <BarBlock
        className="resource"
        isDanger={item.isDanger}
        isWarning={item.isWarning}
        value={item.quantity}
        total={item.capacity}
        title={title}
        rightIcon={item.balancePerSec < 0 ? <Icon icon={IconHelper.General.Down} className={iconClassName} /> : item.balancePerSec > 0 ? <Icon icon={IconHelper.General.Up} className={iconClassName} /> : undefined}>
        <ItemIconWithQuantity
            isUnwrapped
            itemType={item.itemType}
            quantity={item.quantity}
            capacity={item.capacity}
            quantityPerSec={item.balancePerSec}
            {...props}
        />
    </BarBlock>;
};

type AggregateProps = QuantityIconDisplayProps & {
    isDanger?: boolean,
    quantity: number,
    capacity: number | undefined,
    isIncreasing?: boolean
}

type RightIconProps = {
    isDanger?: boolean,
    isIncreasing?: boolean
}

const RightIcon = (props: RightIconProps) => {

    if (!props.isIncreasing) {
        return null;
    }

    return <Icon icon={IconHelper.General.Up} className={props.isDanger ? "has-text-danger" : undefined} />;
};

export const CommodityBarBlock = (props: AggregateProps) => {

    if (props.quantity === 0) {
        return null;
    }

    return <BarBlock
        className="resource"
        isDanger={props.isDanger}
        value={props.quantity}
        total={props.capacity}
        title={describe("Commodities", props.quantity, props.capacity)}
        rightIcon={<RightIcon {...props} />}
    >
        <CommoditiesIcon {...props} isUnwrapped />
    </BarBlock>;
}

export const RawResourceBarBlock = (props: AggregateProps) => {

    if (props.quantity === 0) {
        return null;
    }

    return <BarBlock
        className="rawresources"
        isDanger={props.isDanger}
        value={props.quantity}
        total={props.capacity}
        title={describe("Raw Resources", props.quantity, props.capacity)}
        rightIcon={<RightIcon {...props} />}
    >
        <RawResourceIcon {...props} isUnwrapped />
    </BarBlock>;
};

export const ComponentBarBlock = (props: AggregateProps) => {

    if (props.quantity === 0) {
        return null;
    }

    return <BarBlock
        className="components"
        isDanger={props.isDanger}
        value={props.quantity}
        total={props.capacity}
        title={describe("Components", props.quantity, props.capacity)}
        rightIcon={<RightIcon {...props} />}
    >
        <ComponentIcon {...props} isUnwrapped />
    </BarBlock>;
};