import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ActivateActionResponse, AgentActionType, IAgentDetail } from '../../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../Components/Base/Buttons/Button';
import { Table } from '../../../../Components/Base/Containers/Table';
import { Paragraph } from '../../../../Components/Base/Text/Paragraph';
import { handleOnClick } from '../../../../Components/Base/Util';
import { AgentActionTypeLink } from '../../../../Components/FusionShift/Links/AgentActionTypeLink';
import { AgentHelper } from '../../../../Helpers/AgentHelper';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { ActionReadiness } from '../ActionReadiness';
import { LevelRelation } from '../LevelRelation';
import { ResponseMessage } from '../ResponseMessage';
import { ActionDetails } from './ActionDetails';

type Props = {
    agent: IAgentDetail,
    reloadCallback: (response: ActivateActionResponse) => any
}

export const ActionsComponent = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    const [selectedAction, setSelectedAction] = React.useState<AgentActionType | undefined>(undefined);
    const [response, setResponse] = React.useState<ActivateActionResponse | undefined>(undefined);

    function reload(response: ActivateActionResponse) {
        setSelectedAction(undefined);
        setResponse(response);
        props.reloadCallback(response);
    }

    const availableActions = React.useMemo(() => {

        if (worldState.AgentActionTypeSettings !== undefined && worldState.GameSettings !== undefined) {
            return AgentHelper.availableActions(props.agent, worldState.AgentActionTypeSettings);
        }

        return undefined;

    }, [props.agent, worldState.AgentActionTypeSettings]);

    if (worldState.AgentActionTypeSettings === undefined) {
        return null;
    }

    if (props.agent.celestialPosition === undefined) {
        return <Paragraph type="warning">
            The agent must be at a celestial to take actions.
        </Paragraph>
    }

    return <>
        <Paragraph>
            <ActionReadiness {...props} /> ({props.agent.fatigue} fatigue)
        </Paragraph>
        {(availableActions === undefined || availableActions.length === 0) &&
            <Paragraph type="warning">
                There are no actions available to the agent at this time.
            </Paragraph>
        }
        {availableActions !== undefined && availableActions.length > 0 &&
            <Table isHoverable isUnstyled isFullWidth className="action-list" heading={<>
                <td>Action</td>
                <td>Level</td>
                <td></td>
            </>}>
                {availableActions.map(x => {
                    const isSelected = selectedAction !== undefined && selectedAction.action === x.action && selectedAction.typeName === x.typeName;

                    return <tr key={`${x.action}_${x.typeName}`} className={isSelected ? "selected" : "is-clickable"} onClick={e => handleOnClick(e, () => setSelectedAction(x))}>
                        <td>
                            <AgentActionTypeLink actionType={x} />
                        </td>
                        <td className="is-hidden-mobile">
                            <LevelRelation level={props.agent.level ?? 0} levels={x.level} />
                        </td>
                        <td>
                            <Button type="nav" text="" icon={IconHelper.General.Confirm} isDisabled={isSelected} action={() => setSelectedAction(x)} isPulledRight />
                        </td>
                    </tr>;
                })}
            </Table>
        }
        {selectedAction !== undefined && <ActionDetails {...props} actionType={selectedAction} reloadCallback={reload} />}
        {response !== undefined && <ResponseMessage {...props} response={response} />}
    </>;
});