import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ICelestialDetail, IInstallationTypeSettings, IItemTypeSettings, Installation, ISolarSystemDetail, ItemType, ManufactoryNode, ManufactoryNodeConfiguration, SchematicCategory, SchematicType, SolarSystemDetail } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext, WorldStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { CancelButton } from '../../../../../Components/Base/Buttons/CancelButton';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../../../Components/Base/Form/FieldHolder';
import { Paragraph } from '../../../../../Components/Base/Text/Paragraph';
import { ItemChooser } from '../../../../../Components/FusionShift/Items/ItemChooser';
import { ItemTypeLink } from '../../../../../Components/FusionShift/Links/Items/ItemTypeLink';
import { valueValid } from '../../../../../Entities/Validation';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ManufactoryHelper } from '../../../../../Helpers/ManufactoryHelper';

type Props = {
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    solarSystem: ISolarSystemDetail,
    celestial: ICelestialDetail,
    inputNodes: ManufactoryNode[],
    outputNodes: ManufactoryNode[],
    cancelCallback: () => any,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const ConfigureByOutput = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [itemTypeName, setItemTypeName] = React.useState<string | undefined>(undefined);

    const [outputNodeToChange, setOutputNodeToChange] = React.useState<ManufactoryNode | undefined>(undefined);
    const [schematic, setSchematic] = React.useState<SchematicType | undefined>(undefined);
    const [neededInputs, setNeededInputs] = React.useState<{
        itemType: ItemType,
        isAlreadyPresent: boolean,
        inputNodeToUse: { manufactoryNode: ManufactoryNode, installation: Installation } | undefined,
        isInvalid: boolean
    }[]>([]);

    const [valid, setValid] = React.useState(valueValid(false, "Choose a commodity"));

    React.useEffect(() => {

        if (worldState.SchematicTypeSettings) {

            setOutputNodeToChange(props.outputNodes.find(n => n.itemTypeName === undefined || n.itemTypeName.length === 0));

            const s = Object.values(worldState.SchematicTypeSettings.data).find(s => s.producedItemTypeName === itemTypeName);

            setSchematic(s);

            if (s && s.costPerRun) {
                const availableInputNodes = props.inputNodes.filter(n => n.itemTypeName === undefined || n.itemTypeName.length === 0);

                setNeededInputs(Object.keys(s.costPerRun.items).map(r => {

                    const alreadyPresent = props.inputNodes.filter(i => i.itemTypeName === r);
                    const isAlreadyPresent = alreadyPresent.length > 0;

                    let inputNodeToUse: { manufactoryNode: ManufactoryNode, installation: Installation } | undefined = undefined;

                    if (!isAlreadyPresent && availableInputNodes.length > 0) {
                        const nodeToUse = availableInputNodes[0];
                        availableInputNodes.shift();

                        inputNodeToUse = {
                            manufactoryNode: nodeToUse,
                            installation: props.celestial.installations!.find(i => i.installationId === nodeToUse.installationId)!
                        };
                    }

                    return {
                        itemType: props.itemTypeSettings.data[r],
                        inputNodeToUse: inputNodeToUse,
                        isInvalid: !isAlreadyPresent && inputNodeToUse === undefined,
                        isAlreadyPresent: isAlreadyPresent
                    };
                }));
            }
            else {
                setNeededInputs([]);
            }
        }
    }, [itemTypeName, worldState.SchematicTypeSettings]);

    React.useEffect(() => {
        setValid(validate());
    }, [schematic, outputNodeToChange, neededInputs])

    function validate() {
        if (itemTypeName === undefined || itemTypeName.length === 0) {
            return valueValid(false, "Choose an output item");
        }

        if (!outputNodeToChange) {
            return valueValid(false, "No output nodes are available to configure. Unassign one to continue.");
        }

        if (!ManufactoryHelper.isValidOutput(schematic) || !schematic!.costPerRun) {
            return valueValid(false, "Unable to find a valid schematic");
        }

        for (let n of neededInputs) {
            if (n.isInvalid) {
                return valueValid(false, "Unable to configure an input node for 1 or more resources");
            }
        }

        return valueValid(true);
    }

    function configure() {

        const configuration = {};

        for (let i of neededInputs) {
            if (!i.isInvalid && i.inputNodeToUse !== undefined) {
                configuration[i.inputNodeToUse.manufactoryNode.manufactoryNodeId] = new ManufactoryNodeConfiguration({
                    itemTypeName: i.itemType.typeName,
                    throughputLimitRatio: 1
                });
            }
        }

        if (outputNodeToChange && itemTypeName) {

            configuration[outputNodeToChange.manufactoryNodeId] = new ManufactoryNodeConfiguration({
                itemTypeName,
                throughputLimitRatio: 1
            });

            return apiState.ManufactoryClient.setNodeConfigurations(props.celestial.celestialId, configuration).then(s => {
                props.reloadCallback(s);
            });
        }
    }

    const allowedItems = React.useMemo(() => {

        return Object.values(props.itemTypeSettings.data)
            .filter(x => {
                const schematic = Object.values(worldState.SchematicTypeSettings?.data ?? {}).find(s => s.producedItemTypeName === x.typeName);
                return ManufactoryHelper.isValidOutput(schematic);
            })
            .map(x => x.typeName);

    }, [props.itemTypeSettings, worldState.SchematicTypeSettings]);

    return <SubPanel heading="Configure By Output"
        footerContent={
            <ButtonHolder isPulledRight>
                <CancelButton action={() => props.cancelCallback()} />
                <Button type="action" text="Configure" icon={schematic && schematic.category === SchematicCategory.Component ? IconHelper.Items.Component : IconHelper.Items.Commodity} action={configure} isDisabled={!valid.valid} />
            </ButtonHolder>
        }>
        <Paragraph type="prompt">Choose an item to produce. The manufactory will configure unused input and output nodes to produce the desired item.</Paragraph>
        <FieldHolder label="Item Type">
            <ItemChooser setItemTypeName={setItemTypeName} allowedItems={allowedItems} hideCredits />
        </FieldHolder>
        {neededInputs.length > 0 && <ul>
            {neededInputs.map(n => <li key={n.itemType.typeName} className={n.isInvalid ? "has-text-danger" : ""}>
                <ItemTypeLink itemType={n.itemType} />
                {": "}
                {n.isAlreadyPresent && "Already present in Manufactory"}
                {n.inputNodeToUse !== undefined && `Input node from ${props.installationTypeSettings.data[n.inputNodeToUse.installation.installationTypeName].name} [${n.inputNodeToUse.installation.level}] will be used`}
                {n.isInvalid && "Unable to find an input node to configure"}
            </li>)}
        </ul>}
        {!valid.valid && <Paragraph type="danger">{valid.value}</Paragraph>}
    </SubPanel>;
});