import { HashLink as Link } from 'react-router-hash-link';
import { ShipType } from "../../../ApplicationState/ApiClient";
import { help_shipType } from "../../../Navigation/Routing/Help";
import { ShipClassIcon } from "../Icons/ShipClassIcon";

type Props = {
    shipType: ShipType | undefined | false,
    className?: string | undefined,
    hideTextOnMobile?: boolean
}

export const ShipTypeLink = (props: Props) => {

    if (!props.shipType) {
        return null;
    }

    return <Link to={help_shipType(props.shipType.typeName)} className={props.className ? props.className : ""}>
        <ShipClassIcon shipClass={props.shipType.class} />
        <span className={props.hideTextOnMobile ? "is-hidden-mobile" : ""}>
            {props.shipType.name}
        </span>
    </Link>;
};

export const ShipTypeLinkResponsive = (props: Props) => {

    if (!props.shipType) {
        return null;
    }

    return <Link to={help_shipType(props.shipType.typeName)} className={props.className ? props.className : ""}>
        <ShipClassIcon shipClass={props.shipType.class} />
        <span className="is-not-shown-on-desktop">
            {props.shipType.name}
        </span>
    </Link>;
};