import { ISolarSystemWithCelestials } from '../../../ApplicationState/ApiClient';
import { CelestialResourcesTable } from '../Celestials/CelestialResourcesTable';
import { SolarSystemRender } from './SolarSystemRender';

type Props = {
    solarSystem: ISolarSystemWithCelestials | undefined,
    forceSmall?: boolean,
    showDetails?: boolean,
    showResources?: boolean
}

export const SolarSystemRenderOrRow = (props: Props) => {

    if (!props.solarSystem) {
        return null;
    }

    return <>
        <div className={props.solarSystem.owner === undefined ? "is-hidden-touch" : undefined}>
            <SolarSystemRender solarSystem={props.solarSystem} />
        </div>
        {props.showResources && <CelestialResourcesTable solarSystem={props.solarSystem} />}
    </>
}


