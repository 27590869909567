import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TabMenuWithChildren } from '../../../../Components/Base/Containers/TabMenu';
import { IconHelper } from '../../../../Helpers/IconHelper';
import { EmpireViewProps } from '../../EmpireViewProps';
import { InstallationBuildQueues } from './InstallationBuildQueues';
import { ShipBuildQueues } from './ShipBuildQueues';

type Tabs = "Installations" | "Ships";

export const BuildQueues = observer((props: EmpireViewProps) => {

    const [tab, setTab] = React.useState<Tabs>("Installations");

    const tabs = [
        {
            id: "Installations",
            title: "Installations",
            isAvailable: true,
            icons: IconHelper.Installations.Build
        },
        {
            id: "Ships",
            title: "Ships",
            isAvailable: true,
            icons: IconHelper.Ships.Build
        }
    ];

    return <TabMenuWithChildren
        active={tab}
        changeTab={t => setTab(t)}
        tabs={tabs}>
        {tab === "Installations" && <InstallationBuildQueues {...props} />}
        {tab === "Ships" && <ShipBuildQueues {...props} />}
    </TabMenuWithChildren>;
});
