import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type CellProps = {
    value: number,
    type?: "number" | "percent",
    dangerIfZero?: boolean,
    dangerIfNegative?: boolean,
    negated?: boolean
}


export const Cell = (props: CellProps) => {

    const value = props.negated && props.value > 0 ? -props.value : props.value;

    const dangerIfZero = props.dangerIfZero && props.type !== "percent" && value <= 0;
    const dangerIfNegative = props.dangerIfNegative && value < 0;
    const isDanger = dangerIfNegative || dangerIfZero;

    return <td className={isDanger ? "has-text-danger" : ""}>
        {props.type === "percent" ? ValueFormatter.fromDecimalToDisplayPercent(value) : ValueFormatter.formatLocaleNumber(value)}
    </td>;
};
