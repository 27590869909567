import React from "react";
import { IItemTypeSettings, ItemType } from "../../../ApplicationState/ApiClient";
import { WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { H3 } from "../../Base/Text/H";
import { ItemTypeLink } from "../Links/Items/ItemTypeLink";

type Props = {
    items: { [key: string]: number } | undefined,
    title?: string | undefined
}

type InnerProps = {
    items: ItemTypeQuantity[]
}

type ItemTypeQuantity = {
    item: ItemType,
    quantity: number
}

export const ItemListInner = (props: InnerProps) => {
    return <ul>
        {props.items.map((q, i) =>
            <li key={i}>
                <ItemTypeLink itemType={q.item} />{` x${q.quantity.toLocaleString()}`}
            </li>)}
    </ul>;
};

export function buildItems(itemTypeSettings: IItemTypeSettings, items: { [key: string]: number } | undefined): ItemTypeQuantity[] | null {
    if (!items) {
        return null;
    }

    const itemsToDisplay = Object.keys(items)
        .map(s => {
            return {
                item: itemTypeSettings!.data![s],
                quantity: items![s]
            }
        })
        .filter(s => s.quantity > 0)
        .sort(((a, b) => a.item.order > b.item.order ? 1 : -1));

    return itemsToDisplay;
};

export const ItemList = (props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!props.items || !worldState.ItemTypeSettings) {
        return null;
    }

    const itemsToDisplay = buildItems(worldState.ItemTypeSettings, props.items);

    if (!itemsToDisplay || itemsToDisplay.length === 0) {
        return null;
    }

    return <>
        {!!props.title && props.title.length > 0 &&
            <H3>{props.title}</H3>
        }
        <ItemListInner items={itemsToDisplay} />
    </>;
};