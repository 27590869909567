import { SecurityStatus } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { Icon } from "../../Base/Icon";

type Props = {
    isGiant?: boolean,
    securityStatus: SecurityStatus
}

export const SecurityStatusIcon = (props: Props) => {

    return <Icon
        icon={IconHelper.Security.securityStatus(props.securityStatus)}
        title={ValueFormatter.friendlyNameForSecurityStatus(props.securityStatus)}
        {...props}
    />;
};
