import * as React from "react";
import { ChangePasswordInput, IChangePasswordInput, ValidationResult } from "../../ApplicationState/ApiClient";
import { ApiStateContext, AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { FormFooter } from "../../Components/Base/Form/FormFooter";
import { ValidatingStringInput } from "../../Components/Base/Form/ValidatingStringInput";
import { ValidationHelper } from "../../Components/Base/Form/ValidationHelper";
import { ErrorMessageBox, SuccessMessageBox } from "../../Components/Base/MessageBox";
import { valueValid } from "../../Entities/Validation";
import { IconHelper } from "../../Helpers/IconHelper";
import { SimpleBaseView } from "../BaseView";

export const ChangePassword = () => {

    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);

    const [currentPassword, setCurrentPassword] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [newPassword, setNewPassword] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [confPassword, setConfPassword] = React.useState<ValidationResult>(ValidationHelper.empty());
    const [validation, setValidation] = React.useState(valueValid(false));
    const [serverHasError, setServerHasError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {
        setValidation(() => validate());
    }, [currentPassword.validText, newPassword.validText, confPassword.validText]);

    function validateNewPassword(value: string) {
        return apiState.AuthenticationClient.validatePassword(value);
    }

    function validate() {
        if (newPassword.validText && confPassword.validText) {
            if (newPassword.validText !== confPassword.validText) {
                return valueValid(false, "Passwords do not match");
            }
            return valueValid(true);
        }
        return valueValid(false);
    }

    function changePassword() {
        if (!validation.valid) {
            return;
        }

        const data: IChangePasswordInput = {
            oldPassword: currentPassword.validText!,
            newPassword: newPassword.validText!
        };
        const input = new ChangePasswordInput(data);

        setServerHasError(false);

        return apiState.UserSettingClient.changePassword(input).then(result => {
            if (result) {
                appState.setAuthToken(result);
                setSuccess(true);
            }
            else {
                setServerHasError(true);
            }
        })
    }

    if (success) {
        return <SimpleBaseView heading="Change Password">
            <SuccessMessageBox text="Your password has been changed" />
        </SimpleBaseView>;
    }

    const buttonDisabled = !validation.valid;

    return <SubPanel heading={{ text: "Change Password", icon: IconHelper.General.Locked }}>
        {serverHasError && <ErrorMessageBox text="Password could not be changed" />}
        <ValidatingStringInput
            label="Current Password"
            icon={IconHelper.Account.Password}
            type="password"
            valueChanged={setCurrentPassword}
        />
        <ValidatingStringInput
            label="New Password"
            icon={IconHelper.Account.Password}
            type="password"
            valueChanged={setNewPassword}
            validation={validateNewPassword}
        />
        <ValidatingStringInput
            label="Confirm Password"
            icon={IconHelper.Account.Password}
            type="password"
            valueChanged={setConfPassword}
        />
        <FormFooter validation={validation}>
            <Button isPulledRight type="action" text="Set Password" icon={IconHelper.General.Confirm} action={() => changePassword()} isDisabled={buttonDisabled} />
        </FormFooter>
    </SubPanel>;
};