import { IItemTypeSettings } from "../../../../ApplicationState/ApiClient";
import { SolarSystemWrapper } from "../../SolarSystemWrapper";
import { mergeProblemMaybes, ProblemsMaybe } from "../Problem";
import { economyProblemBuilder } from "./EconomyProblems";

export function agentProblems(itemTypeSettings: IItemTypeSettings, wrapper: SolarSystemWrapper): ProblemsMaybe {

    const problems: ProblemsMaybe[] = [];

    for (let agentEffect of wrapper.agentEffects) {

        if (agentEffect.agentActionType.resourceHarvestingModifierPercent !== undefined) {
            for (let itemTypeName in agentEffect.agentActionType.resourceHarvestingModifierPercent) {
                const itemType = itemTypeName in itemTypeSettings.data ? itemTypeSettings.data[itemTypeName] : undefined;

                if (itemType !== undefined) {
                    problems.push(economyProblemBuilder.agentPercentProblem(agentEffect, `${itemType.name} harvesting`, x => x.resourceHarvestingModifierPercent![itemTypeName], "positiveIsGood"));
                }
            }
        }
    }

    return mergeProblemMaybes(...problems);
}