import { IPolicyTypeSettings, Policy } from '../../../ApplicationState/ApiClient';
import { PolicyTypeLink } from './PolicyTypeLink';

type Props = {
    policy: Policy,
    policyTypeSettings: IPolicyTypeSettings
};

export const PolicyLink = (props: Props) => {

    return <PolicyTypeLink isRepeal={props.policy.isRepeal} policyType={props.policyTypeSettings.data[props.policy.policyTypeName]} />;
};