import { makeAutoObservable } from "mobx";
import { FactionTypeDescription, ShipTypeDescription, TypeDataDescription } from "../ApiClient";
import { ApiState } from "./ApiState";

export class DescriptionState {

    public factoryVersion: string = "";

    constructor(private apiState: ApiState) {
        makeAutoObservable(this);
    }


    get Relics() {
        if (this._Relics === undefined) {
            this.loadRelics();
        }

        return this._Relics;
    }

    private _Relics: TypeDataDescription[] | undefined;

    private loadRelics() {
        if (this._Relics === undefined) {
            this._Relics = [];
            this.apiState.DescriptionClient.relics(this.factoryVersion).then(h => {
                this._Relics = h;
            });
        }
    }

    get Ships() {
        if (this._Ships === undefined) {
            this.loadShips();
        }

        return this._Ships;
    }

    private _Ships: ShipTypeDescription[] | undefined;

    private loadShips() {
        if (this._Ships === undefined) {
            this._Ships = [];
            this.apiState.DescriptionClient.ships(this.factoryVersion).then(h => {
                this._Ships = h;
            });
        }
    }
    get Policies() {
        if (this._Policies === undefined) {
            this.loadPolicies();
        }

        return this._Policies;
    }

    private _Policies: TypeDataDescription[] | undefined;

    private loadPolicies() {
        if (this._Policies === undefined) {
            this._Policies = [];
            this.apiState.DescriptionClient.policies(this.factoryVersion).then(h => {
                this._Policies = h;
            });
        }
    }
    get Schematics() {
        if (this._Schematics === undefined) {
            this.loadSchematics();
        }

        return this._Schematics;
    }

    private _Schematics: TypeDataDescription[] | undefined;

    private loadSchematics() {
        if (this._Schematics === undefined) {
            this._Schematics = [];
            this.apiState.DescriptionClient.schematics(this.factoryVersion).then(h => {
                this._Schematics = h;
            });
        }
    }
    get Installations() {
        if (this._Installations === undefined) {
            this.loadInstallations();
        }

        return this._Installations;
    }

    private _Installations: TypeDataDescription[] | undefined;

    private loadInstallations() {
        if (this._Installations === undefined) {
            this._Installations = [];
            this.apiState.DescriptionClient.installations(this.factoryVersion).then(h => {
                this._Installations = h;
            });
        }
    }
    get Items() {
        if (this._Items === undefined) {
            this.loadItems();
        }

        return this._Items;
    }

    private _Items: TypeDataDescription[] | undefined;

    private loadItems() {
        if (this._Items === undefined) {
            this._Items = [];
            this.apiState.DescriptionClient.items(this.factoryVersion).then(h => {
                this._Items = h;
            });
        }
    }
    get Missions() {
        if (this._Missions === undefined) {
            this.loadMissions();
        }

        return this._Missions;
    }

    private _Missions: TypeDataDescription[] | undefined;

    private loadMissions() {
        if (this._Missions === undefined) {
            this._Missions = [];
            this.apiState.DescriptionClient.missions(this.factoryVersion).then(h => {
                this._Missions = h;
            });
        }
    }
    get Sectors() {
        if (this._Sectors === undefined) {
            this.loadSectors();
        }

        return this._Sectors;
    }

    private _Sectors: TypeDataDescription[] | undefined;

    private loadSectors() {
        if (this._Sectors === undefined) {
            this._Sectors = [];
            this.apiState.DescriptionClient.sectors(this.factoryVersion).then(h => {
                this._Sectors = h;
            });
        }
    }
    get Factions() {
        if (this._Factions === undefined) {
            this.loadFactions();
        }

        return this._Factions;
    }

    private _Factions: FactionTypeDescription[] | undefined;

    private loadFactions() {
        if (this._Factions === undefined) {
            this._Factions = [];
            this.apiState.DescriptionClient.factions(this.factoryVersion).then(h => {
                this._Factions = h;
            });
        }
    }
    get Celestials() {
        if (this._Celestials === undefined) {
            this.loadCelestials();
        }

        return this._Celestials;
    }

    private _Celestials: TypeDataDescription[] | undefined;

    private loadCelestials() {
        if (this._Celestials === undefined) {
            this._Celestials = [];
            this.apiState.DescriptionClient.celestials(this.factoryVersion).then(h => {
                this._Celestials = h;
            });
        }
    }
    get AgentActions() {
        if (this._AgentActions === undefined) {
            this.loadAgentActions();
        }

        return this._AgentActions;
    }

    private _AgentActions: TypeDataDescription[] | undefined;

    private loadAgentActions() {
        if (this._AgentActions === undefined) {
            this._AgentActions = [];
            this.apiState.DescriptionClient.agentActions(this.factoryVersion).then(h => {
                this._AgentActions = h;
            });
        }
    }
}