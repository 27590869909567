import React from "react";
import { ItemType } from "../../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../../Helpers/ValueFormatter";
import { Icon } from "../../../Base/Icon";

type Props = {
    resourceType: ItemType | undefined,
    className?: string | undefined,
    isGiant?: boolean,
    quantityForTitle?: number | undefined
}

export const ResourceIcon = (props: Props) => {

    if (!props.resourceType) {
        return null;
    }

    const qty = props.quantityForTitle !== undefined ? ` x${ValueFormatter.formatLocaleNumber(props.quantityForTitle)}` : "";

    return <Icon title={`${props.resourceType.name} ${qty}`} icon={IconHelper.Items.resource(props.resourceType.typeName)} className={`${props.resourceType.typeName!.toLowerCase()} ${props.className ? props.className : ""}`} isGiant={props.isGiant} />;
};