import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ActivePolitics, PolicyStatus } from '../../ApplicationState/ApiClient';
import { ApiStateContext, PlayerStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { BackNavButton } from '../../Components/Base/Buttons/BackButton';
import { ButtonHolder } from '../../Components/Base/Buttons/ButtonHolder';
import { HelpButton } from '../../Components/Base/Buttons/HelpButton';
import { NavButton } from '../../Components/Base/Buttons/NavButton';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { LoadingSpinner } from '../../Components/Base/Loading/LoadingSpinner';
import { ErrorMessageBox } from '../../Components/Base/MessageBox';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { TickOrCrossIcon } from '../../Components/FusionShift/Icons/TickOrCrossIcon';
import { IconHelper } from '../../Helpers/IconHelper';
import { PoliticsHelper } from '../../Helpers/PoliticsHelper';
import { ValueFormatter } from '../../Helpers/ValueFormatter';
import { politics_home, politics_propose } from '../../Navigation/Routing/Politics';
import { SimpleBaseView } from '../BaseView';
import { ActivePoliciesComponent } from './ActivePoliciesComponent';
import { OpenPolicyVote } from './OpenPolicyVote';
import { PolicyTableInPanel } from './PolicyTable';

export const PresidentsView = observer(() => {

    const apiState = React.useContext(ApiStateContext);
    const worldState = React.useContext(WorldStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const [activePolitics, setActivePolitics] = React.useState<ActivePolitics | undefined>(undefined);

    React.useEffect(() => {
        if (!activePolitics) {
            apiState.PoliticsClient.getActive().then(t => {
                setActivePolitics(t);
            });
        }
    }, [activePolitics]);

    function reloadCallback() {
        setActivePolitics(undefined);
    }

    if (activePolitics === undefined || playerState.Player === undefined) {
        return <LoadingSpinner />;
    }

    if (activePolitics.activePresident === undefined || activePolitics.activePresident.president.playerId !== playerState.Player.playerId) {
        return <ErrorMessageBox text={"There is no active presidential term or you are not the active president"} />;
    }

    const canPropose = PoliticsHelper.canProposePolicy(playerState.Player, activePolitics.activePresident);
    const openPolicyVote = activePolitics.activePresident.presidentialTerm.policies.find(x => x.status === PolicyStatus.Voting);
    const policyText = openPolicyVote ? "Presidential Orders" : "Propose Policy";

    return <SimpleBaseView
        heading={{ text: `President ${activePolitics.activePresident.president.name}`, icon: IconHelper.Politics.President }}
        headingContent={<ButtonHolder isPulledRight>
            <BackNavButton to={politics_home} />
            <HelpButton to={"politics"} />
        </ButtonHolder>}>
        <SplitLayout
            isRightEmpty={openPolicyVote === undefined}
            left={
                <SubPanel heading="Summary" footerContent={canPropose && <NavButton icon={IconHelper.Politics.Propose} to={politics_propose} text={policyText} className="is-pulled-right" />}>
                    <SplitLayout
                        left={
                            <>
                                <Paragraph>
                                    You are the current president and your term ends in {ValueFormatter.formatDateFromNowWithMoment(activePolitics.activePresident.presidentialTerm.endDate)}.
                                </Paragraph>
                                <Paragraph>
                                    {openPolicyVote ? "There is already an open policy vote and so you can't propose a policy for voting at this time. " : "As president you may propose a policy to be implemented. "}
                                </Paragraph>
                            </>
                        }
                        right={
                            <ul>
                                <li>
                                    <TickOrCrossIcon type={activePolitics.activePresident.presidentialTerm.hasDemocraticReformationBeenUsed ? "cross" : "tick"} />
                                    {activePolitics.activePresident.presidentialTerm.hasDemocraticReformationBeenUsed ? "Democratic reformation already used this term. " : "Democratic reformation still available this term. "}
                                </li>
                                <li>
                                    <TickOrCrossIcon type={activePolitics.activePresident.presidentialTerm.hasExecutiveOrderBeenUsed ? "cross" : "tick"} />
                                    {activePolitics.activePresident.presidentialTerm.hasExecutiveOrderBeenUsed ? "Executive order already used this term. " : "Executive order still available this term. "}
                                </li>
                            </ul>
                        }
                    />
                </SubPanel>
            }
            right={!!openPolicyVote &&
                <SubPanel heading="Open Policy Vote">
                    <OpenPolicyVote policy={openPolicyVote} reloadCallback={() => setActivePolitics(undefined)} />
                </SubPanel>
            }
        />
        {activePolitics.activePolicies.policies.length > 0 &&
            <SubPanel heading="Active Policies">
                <ActivePoliciesComponent policies={activePolitics.activePolicies.policies} />
            </SubPanel>
        }
        <PolicyTableInPanel policies={activePolitics.activePresident.presidentialTerm.policies} reloadCallback={reloadCallback} />
    </SimpleBaseView>;
});