import { observer } from 'mobx-react-lite';
import { ISolarSystemForObserver } from '../../../ApplicationState/ApiClient';
import { LoadingSpinner } from '../../../Components/Base/Loading/LoadingSpinner';
import { SolarSystemDetail } from '../SolarSystemDetail';

type Props = {
    isLoading: boolean,
    solarSystem: ISolarSystemForObserver | undefined
}

export const SolarSystemView = observer((props: Props) => {

    if (props.solarSystem === undefined) {
        if (props.isLoading) {
            return <LoadingSpinner />;
        }

        return null;
    }

    return <SolarSystemDetail solarSystem={props.solarSystem} />;
});