import * as React from "react";
import { ISolarSystemDetail, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { SplitLayout } from "../../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { CollectionHelper } from "../../../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { BuildShipsInSolarSystemButton } from "../../Ships/BuildShipsInSolarSystemButton";
import { ChooseWhereToBuildShips } from "../../Ships/ChooseWhereToBuildShips";
import { InstallationBuildQueue } from "./InstallationBuildQueue";
import { ShipBuildQueue } from "./ShipBuildQueue";

type Props = {
    solarSystem: ISolarSystemDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

export const BuildQueueControl = (props: Props) => {

    const [showBuildShips, setShowBuildShips] = React.useState(false);

    if (!props.solarSystem) {
        return null;
    }

    return <SplitLayout
        left={
            <SubPanel
                heading={{
                    text: "Installation Build Queue",
                    mobileText: "Installation Queue",
                    icon: IconHelper.Installations.Installation
                }}
                className="installation-build-queue"
            >
                <InstallationBuildQueue {...props} />
            </SubPanel>
        }
        right={
            <SubPanel
                heading={{
                    text: "Ship Build Queue",
                    mobileText: "Ship Queue",
                    icon: IconHelper.Ships.Build
                }}
                headingContent={<BuildShipsInSolarSystemButton
                    isPulledRight
                    isExpanded={showBuildShips}
                    setExpanded={setShowBuildShips}
                />}
                isUnpadded={CollectionHelper.isAnyInDictionary(props.solarSystem.shipBuildQueueByCelestial) || showBuildShips}
            >
                {showBuildShips && <ChooseWhereToBuildShips {...props} />}
                <ShipBuildQueue {...props} showBuildShips={showBuildShips} />
            </SubPanel>
        }
    />;
};