import * as React from 'react';
import { IFleet } from '../../../ApplicationState/ApiClient';
import { PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from '../../../Components/Base/Containers/SplitLayout';
import { Table } from '../../../Components/Base/Containers/Table';
import { Icon } from '../../../Components/Base/Icon';
import { AgentPortrait } from '../../../Components/FusionShift/Images/AgentPortrait';
import { ItemsControl } from '../../../Components/FusionShift/Items/ItemsControl';
import { AgentLink } from '../../../Components/FusionShift/Links/AgentLink';
import { FleetCargoCapacityBar } from '../../../Components/FusionShift/Ships/FleetCargoCapacityBar';
import { ShipList } from '../../../Components/FusionShift/Ships/ShipList';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { CollectionText } from './CollectionText';

type Props = {
    fleet: IFleet
}

export const FleetDetail = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const hasAgents = props.fleet.agents.length > 0;
    const hasShips = CollectionHelper.isAnyQuantityInDictionary(props.fleet.ships);
    const hasCargo = CollectionHelper.isAnyQuantityInDictionary(props.fleet.itemsCargo);
    const hasCollection = CollectionHelper.isAnyQuantityInDictionary(props.fleet.itemsToCollect);

    if (!props.fleet.ships || !worldState.ShipTypeSettings) {
        return null;
    }

    if (!hasCargo && !hasShips && !hasCollection) {
        return null;
    }

    const fleetWrapper = new FleetWrapper(worldState.ShipTypeSettings!, worldState.GameSettings!, props.fleet.ships);

    return <>
        {(hasCargo || hasShips || hasAgents) &&
            <SplitLayout
                isRightEmpty={!hasShips}
                left={<ShipList ships={props.fleet.ships} />}
                right={<>
                    {hasShips && <div className="is-pulled-right">
                        {ValueFormatter.formatMass(props.fleet.mass, props.fleet.owner ? props.fleet.owner.playerId !== playerState.Player?.playerId : true)}{" / "}
                        <Icon icon={IconHelper.Combat.Attack} /> {ValueFormatter.formatLocaleNumber(fleetWrapper.TotalAttack(props.fleet.targetFleetId !== undefined))}{" / "}
                        <Icon icon={IconHelper.Combat.Defence} /> {ValueFormatter.formatLocaleNumber(fleetWrapper.TotalDefence)}
                    </div>}
                    {hasCargo && <>
                        <ItemsControl items={props.fleet.itemsCargo} expandDetails compressOnMobile />
                        <FleetCargoCapacityBar fleetWrapper={fleetWrapper} cargo={props.fleet.itemsCargo} />
                    </>}
                    {hasAgents && <Table isFullWidth>
                        {props.fleet.agents.map(x => <tr key={x.agentId}>
                            <td>
                                <AgentPortrait agent={x} size='tiny' />
                            </td>
                            <td>
                                <AgentLink agent={x} />
                            </td>
                        </tr>)}
                    </Table>}
                </>}
            />}
        {hasCollection && <CollectionText
            movementType={props.fleet.movementType}
            items={props.fleet.itemsToCollect}
        />}
    </>;
};