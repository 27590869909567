import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { MissionLogStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { BackNavButton } from '../../Components/Base/Buttons/BackButton';
import { IconHelper } from '../../Helpers/IconHelper';
import { SimpleBaseView } from '../BaseView';
import { MissionContent } from './MissionContent';
import { MissionWrapper } from './MissionWrapper';

export const MissionView = observer(() => {

    const missionLogState = React.useContext(MissionLogStateContext);
    const worldState = React.useContext(WorldStateContext);

    let { missionTypeName } = useParams();

    const [mission, setMission] = React.useState<MissionWrapper | undefined>(undefined);

    React.useEffect(() => {
        if (missionLogState.MissionLog && worldState.MissionTypeSettings && missionTypeName !== undefined && missionTypeName in worldState.MissionTypeSettings) {
            setMission(new MissionWrapper(missionLogState.MissionLog, worldState.MissionTypeSettings[missionTypeName]));
        }
    }, [missionLogState.MissionLog, worldState.MissionTypeSettings, missionTypeName]);

    return <SimpleBaseView
        heading={{ text: mission !== undefined ? mission.MissionType.name : "Mission", icon: IconHelper.Missions.Missions }}
        headingContent={<BackNavButton to="/missions" />}
    >
        <MissionContent mission={mission} redirectOnComplete />
    </SimpleBaseView>;
});