import { IPlayerDetail, IWorldMinimal } from '../../ApplicationState/ApiClient';
import { FlexDiv } from '../../Components/Base/Containers/FlexDiv';
import { SplitLayout } from '../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { PlayerGraphWrapper } from '../../Components/FusionShift/Reports/PlayerGraph';
import { IconHelper } from '../../Helpers/IconHelper';
import { AccoladeBadge } from '../Accolades/AccoladesBadge';

type Props = {
    player: IPlayerDetail,
    world: IWorldMinimal
}

export const PlayerStats = (props: Props) => {

    const sortedAccolades = props.player.accolades.sort((a, b) => b.date.getTime() - a.date.getTime());
    const date = sortedAccolades.length > 0 ? sortedAccolades[0].date.toISOString() : undefined;
    const accolades = date !== undefined ? props.player.accolades.filter(x => x.date.toISOString() == date) : [];

    return <SplitLayout
        left={<SubPanel heading={{ text: "Recent Accolades", icon: IconHelper.Accolades.Accolade }}>
            {accolades.length === 0 && <Paragraph>No accolades</Paragraph>}
            {accolades.length > 0 && <FlexDiv >
                {accolades.map(a => <AccoladeBadge key={a.playerAccoladeId} accolade={a} world={props.world!} />)}
            </FlexDiv>
            }
        </SubPanel>}
        right={<SubPanel heading={{ text: "History", icon: IconHelper.General.Chart }}>
            <PlayerGraphWrapper players={[props.player]} />
        </SubPanel>}
    />;
};