import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { SolarSystemStateContext } from '../../../../ApplicationState/ContextRoot';
import { ValueFormatter } from '../../../../Helpers/ValueFormatter';
import { MissionWrapper } from '../../MissionWrapper';
import { ObjectiveRow } from '../Objectives';

type Props = {
    mission: MissionWrapper
}

export const MegacityDesignatedAssignedPopulationRequired = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (!props.mission.MissionType.megacityDesignatedAssignedPopulationRequired || !solarSystemState.SolarSystem) {
        return null;
    }

    const required = props.mission.MissionType.megacityDesignatedAssignedPopulationRequired;
    var has = 0;

    for (let c of solarSystemState.SolarSystem.celestials) {
        if (c.megacity != null) {
            let thisHas = 0;
            for (let s of Object.keys(c.megacity.sectors)) {
                thisHas += c.megacity.sectors[s].assignedPopulation;
            }

            if (thisHas > has) {
                has = thisHas;
            }
        }
    }

    return <ObjectiveRow has={has} requires={required} {...props} >
        {`Assign ${ValueFormatter.formatPopulation(required)} citizens to work in a Megacity sector`}
    </ObjectiveRow>;
});