import * as React from 'react';
import { ICelestialDetail, IInstallationTypeSettings, IItemTypeSettings, ManufactoryNode, SolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { ExpandButton } from '../../../Components/Base/Buttons/ExpandButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Table } from '../../../Components/Base/Containers/Table';
import { ProgressBarPercent } from '../../../Components/Base/ProgressBar';
import { H3 } from '../../../Components/Base/Text/H';
import { ProblemIcons } from '../../../Components/FusionShift/Icons/ProblemIcons';
import { InstallationTypeLink } from '../../../Components/FusionShift/Links/InstallationTypeLink';
import { ItemTypeLinkFromTypeName } from '../../../Components/FusionShift/Links/Items/ItemTypeLink';
import { SelectSolarSystemLink } from '../../../Components/FusionShift/Links/SolarSystemLink';
import { manufactoryProblemsForCelestial } from '../../../Entities/SolarSystem/Problems/Manufactory/ManufactoryProblems';
import { Problem, mergeProblemMaybes } from '../../../Entities/SolarSystem/Problems/Problem';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ManufactoryHelper } from '../../../Helpers/ManufactoryHelper';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';
import { ProblemTable } from '../../SolarSystem/SubViews/Summary/Advisor/ProblemTable';
import { EmpireViewProps } from '../EmpireViewProps';

type RowProps = {
    solarSystemWrapper: SolarSystemWrapper,
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type CelestialRowProps = {
    isFirst: boolean,
    celestial: ICelestialDetail,
    solarSystemWrapper: SolarSystemWrapper,
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

type ExpandRowProps = CelestialRowProps & {
    problems: Problem[]
}

type NodeProps = {
    solarSystemWrapper: SolarSystemWrapper,
    celestial: ICelestialDetail
    installationTypeSettings: IInstallationTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    node: ManufactoryNode,
    isInputNode: boolean
}

const NodeRow = (props: NodeProps) => {

    const installation = props.celestial.installations?.find(x => x.installationId === props.node.installationId);
    const installationType = installation && installation.installationTypeName in props.installationTypeSettings.data ? props.installationTypeSettings.data[installation.installationTypeName] : undefined;

    const actualThroughput = ManufactoryHelper.actualThroughput(props.solarSystemWrapper.solarSystem.itemsDetail, props.celestial.celestialId, props.node.manufactoryNodeId);

    const efficiency = props.node.optimalThroughput > 0 ? actualThroughput / props.node.optimalThroughput : 0;

    return <tr >
        <td>
            {installationType !== undefined && <InstallationTypeLink installationType={installationType} />}
            {installation !== undefined && ` [${installation.level}]`}
        </td>
        <td>
            {props.node.itemTypeName.length === 0 && "Unconfigured"}
            {props.node.itemTypeName.length > 0 && <ItemTypeLinkFromTypeName itemTypeName={props.node.itemTypeName} />}
        </td>
        <td className="is-hidden-mobile">
            {ValueFormatter.formatLocaleNumber(actualThroughput)} / {ValueFormatter.formatLocaleNumber(props.node.optimalThroughput)}
        </td>
        <td>
            <ProgressBarPercent leftIcon={props.isInputNode ? IconHelper.Manufactories.Input : IconHelper.Manufactories.Output} value={efficiency} />
        </td>
    </tr>;
};

const ExpandRow = (props: ExpandRowProps) => {

    const manufactory = props.celestial.manufactory!;

    return <tr >
        <td colSpan={8}>
            {props.problems.length > 0 &&
                <ProblemTable problems={props.problems} />
            }
            <Table isFixed isFullWidth>
                {manufactory.inputNodes.map(x => <NodeRow key={x.manufactoryNodeId} node={x} {...props} isInputNode />)}
                {manufactory.outputNodes.map(x => <NodeRow key={x.manufactoryNodeId} node={x} {...props} isInputNode={false} />)}
            </Table>
        </td>
    </tr>;
};

const CelestialRow = (props: CelestialRowProps) => {

    const apiState = React.useContext(ApiStateContext);

    const [isExpanded, setIsExpanded] = React.useState(false);

    const manufactory = props.celestial.manufactory!;

    function setActive(isActive: boolean) {
        return apiState.ManufactoryClient.setManufactoryActive(props.celestial.celestialId, isActive).then(solarSystem => {
            if (solarSystem) {
                props.reloadCallback(solarSystem);
            }
        })
    }


    const stats = React.useMemo(() => {

        const inputEfficiency = ManufactoryHelper.sumNodeEfficiency(props.solarSystemWrapper.solarSystem.itemsDetail, props.celestial.celestialId, manufactory.inputNodes);
        const outputEfficiency = ManufactoryHelper.sumNodeEfficiency(props.solarSystemWrapper.solarSystem.itemsDetail, props.celestial.celestialId, manufactory.outputNodes);
        const overallEfficiency = ManufactoryHelper.sumNodeEfficiency(props.solarSystemWrapper.solarSystem.itemsDetail, props.celestial.celestialId, manufactory.outputNodes.concat(manufactory.inputNodes));

        return {
            inputEfficiency,
            outputEfficiency,
            overallEfficiency
        };

    }, [manufactory, props.celestial]);

    const problems = React.useMemo(() => {
        return mergeProblemMaybes(manufactoryProblemsForCelestial(props.itemTypeSettings, props.installationTypeSettings, props.solarSystemWrapper, props.celestial));
    }, [props.itemTypeSettings, props.installationTypeSettings, props.solarSystemWrapper, props.celestial]);

    const bonuses = props.solarSystemWrapper.solarSystem.itemsDetail?.manufactories?.totalBonusesPercent;
    const bonus = bonuses ? Object.values(bonuses).find(x => true) ?? 0 : 0;

    return <React.Fragment>
        {props.isFirst &&
            <tr className="is-hidden-tablet">
                <td colSpan={6}>
                    <SelectSolarSystemLink hideActionIcons solarSystem={props.solarSystemWrapper.solarSystem} />
                </td>
            </tr>
        }
        <tr className="is-hidden-tablet">
            <td>
                <H3 className="celestial-name">{props.celestial.name} </H3>
            </td>
            <td colSpan={2}>
                <ProblemIcons problems={problems} />
                <ButtonHolder>
                    {props.celestial.manufactory !== undefined && props.celestial.manufactory.isActive && <Button type="danger" text="Turn Off" action={() => setActive(false)} icon={IconHelper.General.Off} />}
                    {props.celestial.manufactory !== undefined && !props.celestial.manufactory.isActive && <Button type="action" text="Turn On" action={() => setActive(true)} icon={IconHelper.General.On} />}
                    <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </ButtonHolder>
            </td>
        </tr>
        <tr key={props.celestial.celestialId}>
            <td className="is-hidden-mobile">
                {props.isFirst && <SelectSolarSystemLink hideActionIcons solarSystem={props.solarSystemWrapper.solarSystem} />}
            </td>
            <td className="is-hidden-mobile">
                <H3 className="celestial-name">{props.celestial.name} </H3>
            </td>
            <td>
                <ProgressBarPercent leftIcon={IconHelper.Manufactories.Input} value={stats.inputEfficiency} />
            </td>
            <td>
                <ProgressBarPercent leftIcon={IconHelper.Manufactories.Output} value={stats.outputEfficiency} />
            </td>
            <td>
                <ProgressBarPercent leftIcon={IconHelper.Categories.Summary} value={stats.overallEfficiency} />
            </td>
            <td className='is-hidden-mobile'>
                {ValueFormatter.fromDecimalToDisplayPercent(bonus)}
            </td>
            <td className='is-hidden-mobile'>
                <ProblemIcons problems={problems} />
            </td>
            <td className='is-hidden-mobile'>
                <ButtonHolder>
                    {props.celestial.manufactory !== undefined && props.celestial.manufactory.isActive && <Button type="danger" text="Turn Off" action={() => setActive(false)} icon={IconHelper.General.Off} />}
                    {props.celestial.manufactory !== undefined && !props.celestial.manufactory.isActive && <Button type="action" text="Turn On" action={() => setActive(true)} icon={IconHelper.General.On} />}
                    <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </ButtonHolder>
            </td>
        </tr>
        {isExpanded && <ExpandRow key={`${props.celestial.celestialId}_expand`} {...props} problems={problems} />}
    </React.Fragment>;
}

const Row = (props: RowProps) => {

    const celestials = props.solarSystemWrapper.solarSystem.celestials.filter(x => x.manufactory != undefined && x.manufactory !== null)
        .sort((a, b) => a.order - b.order);

    return <>
        {celestials.map((c, i) => <CelestialRow
            key={c.celestialId}
            {...props}
            isFirst={i === 0}
            celestial={c}
        />)}
    </>;
};

export const Manufactories = (props: EmpireViewProps) => {

    return <SubPanel heading={{ text: "Manufactories", icon: IconHelper.Manufactories.Manufactory }} isUnpadded>
        <Table
            isHoverable
            isFullWidth
            hideHeaderMobile
            heading={<>
                <th colSpan={2}></th>
                <th>
                    Input
                </th>
                <th>
                    Output
                </th>
                <th>
                    Overall
                </th>
                <th className='is-hidden-mobile'>
                    Bonus
                </th>
                <th colSpan={2}></th>
            </>}>
            {props.solarSystems.map(s => <Row key={s.solarSystem.solarSystemId} solarSystemWrapper={s} {...props} />)}
        </Table>
    </SubPanel>;
};