import { ICombatReportDetail } from "../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { Table } from "../../../Components/Base/Containers/Table";
import { AgentPortrait } from "../../../Components/FusionShift/Images/AgentPortrait";
import { AgentLink } from "../../../Components/FusionShift/Links/AgentLink";
import { IconHelper } from "../../../Helpers/IconHelper";

type Props = {
    combatReportDetail: ICombatReportDetail
}

export const CapturedAgents = (props: Props) => {

    const agents = props.combatReportDetail.capturedAgents;

    if (agents?.length === 0) {
        return null;
    }

    return <SubPanel
        heading={{ text: "Captured Agents", icon: IconHelper.Agents.Agent }}
        isUnpadded
    >
        <Table isFullWidth isFixed>
            {agents?.map(x => <tr key={x.agentId}>
                <td>
                    <AgentPortrait agent={x} size="tiny" />
                </td>
                <td>
                    <AgentLink agent={x} />
                </td>
            </tr>)}
        </Table>
    </SubPanel>;
};
