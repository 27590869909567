import * as React from "react";
import { Contract, ContractType } from "../../ApplicationState/ApiClient";
import { ApiStateContext } from "../../ApplicationState/ContextRoot";
import { ButtonHolder } from "../../Components/Base/Buttons/ButtonHolder";
import { ButtonLink } from "../../Components/Base/Buttons/ButtonLink";
import { HelpButton } from "../../Components/Base/Buttons/HelpButton";
import { SubPanel } from "../../Components/Base/Containers/SubPanel";
import { LoadingSpinner } from "../../Components/Base/Loading/LoadingSpinner";
import { IconHelper } from "../../Helpers/IconHelper";
import { contract_create } from "../../Navigation/Routing/Contracts";
import { SimpleBaseView } from "../BaseView";
import { ContractTable } from "./Browse/ContractTable";
import { FilterControl, Filters } from "./Browse/FilterControl";

export const ContractBrowse = () => {

    const apiState = React.useContext(ApiStateContext);

    const [contracts, setContracts] = React.useState<Contract[] | undefined>(undefined);

    const [filters, setFilters] = React.useState<Filters>({
        allowExiles: true,
        federationOnly: false,
        includeAccepted: false,
        type: ContractType.Courier
    });

    React.useEffect(() => {
        setContracts(undefined);
        apiState.ContractsClient.browse(filters.type, filters.federationOnly, filters.allowExiles, filters.includeAccepted).then(setContracts);
    }, [filters.type, filters.federationOnly, filters.allowExiles, filters.includeAccepted]);

    return <SimpleBaseView
        heading={{ text: "Browse Contracts", icon: IconHelper.Economy.Contract }}
        headingContent={
            <ButtonHolder>
                <ButtonLink icon={IconHelper.Economy.ContractCreate} text="Create Contract" hideTextOnMobile to={contract_create} />
                <HelpButton to="contracts" />
            </ButtonHolder>
        }
    >
        <FilterControl filters={filters} setFilters={setFilters} />
        {contracts === undefined && <LoadingSpinner />}
        {contracts !== undefined &&
            <SubPanel heading="Results">
                <ContractTable contracts={contracts} />
            </SubPanel>
        }
    </SimpleBaseView>;
};