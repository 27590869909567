import * as React from 'react';
import { Level, levelContentWithHeading } from '../../../../../Components/Base/Containers/Level';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    currentInactive: number,
    currentActive: number,
    maxActive: number,
    maxTotal: number
}

export const SummaryControl = (props: Props) => {

    const content = [
        levelContentWithHeading("Active Drones", `${ValueFormatter.formatLocaleNumber(props.currentActive)} / ${ValueFormatter.formatLocaleNumber(props.maxActive)}`),
        levelContentWithHeading("Inactive Drones", `${ValueFormatter.formatLocaleNumber(props.currentInactive)} / ${ValueFormatter.formatLocaleNumber(props.maxTotal)}`),
        levelContentWithHeading("Total Drones", `${ValueFormatter.formatLocaleNumber(props.currentActive + props.currentInactive)} / ${ValueFormatter.formatLocaleNumber(props.maxTotal)}`)
    ];

    return <Level content={content} />
};