import * as React from 'react';

const numberOfLines: number = 10;

type Props = {
    size: number
}

export const Gridlines = (props: Props) => {

    const lines: React.ReactNode[] = [];

    function size(idx: number) {
        return props.size * idx * (1 / numberOfLines);
    }

    for (let x = 1; x < numberOfLines; x++) {
        lines.push(<line key={"x" + x} x1={size(x)} x2={size(x)} y1={0} y2={props.size} />)
    }
    for (let y = 1; y < numberOfLines; y++) {
        lines.push(<line key={"y" + y} y1={size(y)} y2={size(y)} x1={0} x2={props.size} />)
    }
    return <svg className="gridlines" width={props.size} height={props.size}>
        {lines}
    </svg>;
};