import { IconHelper } from "../../../Helpers/IconHelper";
import { TimeHelper } from "../../../Helpers/TimeHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    quantity: number,
    alwaysCompact?: boolean
}

export const HoursIcon = (props: Props) => {

    if (props.quantity <= 0) {
        return null;
    }

    const timespan = TimeHelper.createTimeSpanFromHours(props.quantity);;

    if (props.alwaysCompact) {
        return <span className="has-nowrap">
            <Icon icon={IconHelper.General.Time} />
            <span>{TimeHelper.formatTimeSpanByNumbers(timespan)}</span>
        </span>;
    }

    return <span className="has-nowrap">
        <Icon icon={IconHelper.General.Time} />
        {!props.alwaysCompact && <span className="is-hidden-mobile">{TimeHelper.formatTimeSpanByNumbers(timespan)}</span>}
        {props.alwaysCompact && <span className="is-hidden-mobile">{TimeHelper.formatTimeSpanByWords(timespan)}</span>}
        <span className={"is-hidden-tablet"}>{TimeHelper.formatTimeSpanByWords(timespan)}</span>
    </span>;
};