import * as React from 'react';
import { FieldHolder } from '../../../Components/Base/Form/FieldHolder';
import { Slider } from '../../../Components/Base/Form/Slider';
import { ValueFormatter } from '../../../Helpers/ValueFormatter';

type Props = {
    value: number | undefined,
    valueChanged: (value: number) => any
}

export const BonusSlider = (props: Props) => {

    return <FieldHolder label="Bonus %">
        <Slider
            min={-1} max={1} step={0.01}
            valueFormatter={v => ValueFormatter.fromDecimalToDisplayPercent(v, false, 0)}
            value={props.value === undefined ? 0 : props.value}
            valueChanged={props.valueChanged} />
    </FieldHolder>;
}