import * as React from 'react';
import { RenderContext } from '../RenderContext';

type Props = {
    renderContext: RenderContext
}

export const Axis = (props: Props) => {

    const rc = props.renderContext;

    return <>
        <line
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
            className="axis"
            x1={rc.tX(rc.props.minX)}
            x2={rc.tY(rc.props.maxX)}
            y1={rc.tY(0)}
            y2={rc.tY(0)}
        />
        <line
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
            className="axis"
            y1={rc.tY(rc.props.minY)}
            y2={rc.tY(rc.props.maxY)}
            x1={rc.tX(0)}
            x2={rc.tX(0)}
        />
    </>;
}