import { IconHelper } from "../../../Helpers/IconHelper";
import { NavButton } from "./NavButton";

type Props = {
    to: string,
    hideTextOnMobile?: boolean
}

export const ViewButton = (props: Props) => {

    return <NavButton className="is-pulled-right" text="View" icon={IconHelper.General.View} {...props} />
};