import { FleetTrade, MarketOrder } from "../ApplicationState/ApiClient";

export class MarketOrderHelper {

    public static usedMarketQuantity(marketOrders: MarketOrder[] | undefined) {
        if (!marketOrders) {
            return 0;
        }
        let quantity = 0;
        for (let m of marketOrders) {
            quantity += m.quantityRemaining;
        }

        return quantity;
    }

    public static hypotheticallyAcceptMarketOrders(trade: FleetTrade | undefined, marketOrders: MarketOrder[]) {
        if (!trade) {
            return undefined;
        }

        const availableOrders = marketOrders.filter(m => m.ratioOfferedToDesired >= trade.lowestRatioAccepted && this.equivalent(trade.desiredItemTypeName, m.offeredItemTypeName) && this.equivalent(trade.offeredItemTypeName, m.desiredItemTypeName))
            .sort((a, b) => a.ratioOfferedToDesired < b.ratioOfferedToDesired ? 1 : -1);

        let saleQuantity = trade.quantity;
        let quantity = 0;

        for (let m of availableOrders) {
            if (saleQuantity <= 0) {
                break;
            }
            const toUse = m.quantityRemaining > saleQuantity ? saleQuantity : m.quantityRemaining;
            quantity += toUse * m.ratioOfferedToDesired;
            saleQuantity -= toUse;
        }

        return quantity;
    }

    public static availableQuantityInMarketOrders(trade: FleetTrade | undefined, marketOrders: MarketOrder[]) {
        if (!trade) {
            return 0;
        }

        const availableOrders = marketOrders.filter(m => m.ratioOfferedToDesired >= trade.lowestRatioAccepted && this.equivalent(trade.desiredItemTypeName, m.offeredItemTypeName) && this.equivalent(trade.offeredItemTypeName, m.desiredItemTypeName))
            .sort((a, b) => a.ratioOfferedToDesired < b.ratioOfferedToDesired ? 1 : -1);

        let quantity = 0;

        for (let m of availableOrders) {
            quantity += m.quantityRemaining;
        }

        return quantity;
    }

    public static equivalent(itemTypeNameA: string, itemTypeNameB: string) {
        return itemTypeNameA === itemTypeNameB;
    }
}