import { IFederationDetail, IGameSettings, IPlayer } from '../../../ApplicationState/ApiClient';
import { IconHelper } from '../../../Helpers/IconHelper';
import { MemberControls } from './MemberControls';
import { PlayerList } from './PlayerList';

type Props = {
    observingPlayer: IPlayer,
    gameSettings: IGameSettings,
    federation: IFederationDetail,
    reloadCallback: (federation: IFederationDetail) => any
}

export const LeadershipList = (props: Props) => {

    const players = [
        props.federation.leader,
        ...props.federation.officers
    ].filter(x => x !== undefined).map(x => x!);

    return <PlayerList
        federation={props.federation}
        controls={p => <MemberControls player={p} {...props} />}
        heading={{ text: "Leadership", icon: IconHelper.Federations.Leader }}
        players={players}
        showRank
        showControlsInsideExpand
    />;
};