import { observer } from "mobx-react-lite";
import React from "react";
import { CelestialSize, IInstallationTypeSettings, InstallationType } from "../../../ApplicationState/ApiClient";
import { DescriptionStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../Base/Containers/SplitLayout";
import { InstallationTypeImage } from "../Images/InstallationTypeImage";
import { RichTextArray } from "../RichTextArray";
import { ShipBuildDisplay } from "./InstallationStatsBuilder";
import { InstallationTypeStats } from "./InstallationTypeStats";

type BaseProps = {
    installationTypeSettings: IInstallationTypeSettings
    factionTypeName: string,
    installationType: InstallationType
}

type Props = BaseProps & {
    celestialSize: CelestialSize,
    level: number,
    operationLevel: number,
    shipBuildDisplay: ShipBuildDisplay,
    imageAsMaxLevel?: boolean,
    preStatsContent?: React.ReactNode
}

export const InstallationTypeTextAndImage = (props: BaseProps) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const description = descriptionState.Installations?.find(x => x.typeName === props.installationType.typeName);

    return <SplitLayout
        fitContent
        left={
            <InstallationTypeImage {...props} />
        }
        right={
            <RichTextArray text={description?.description} />
        } />;
};

export const InstallationTypeDescription = observer((props: Props) => {

    const descriptionState = React.useContext(DescriptionStateContext);

    const level = props.imageAsMaxLevel ? props.installationType.maxLevel ?? 1 : props.level;
    const description = descriptionState.Installations?.find(x => x.typeName === props.installationType.typeName);

    return <SplitLayout
        isRightExpand
        left={
            <InstallationTypeImage {...props} level={level} />
        }
        right={<>
            <RichTextArray text={description?.description} />
            {props.preStatsContent !== undefined && props.preStatsContent}
            <br />
            <InstallationTypeStats {...props} display={{
                showLimits: true,
                showAllows: true,
                shipBuildDisplay: props.shipBuildDisplay,
                sectorBonusesForMaxLevel: true
            }} />
        </>
        } />;
});