import { IconHelper } from "../../Helpers/IconHelper";

export type BaseProps = {
    title?: string,
    className?: string,
    isGiant?: boolean
}

type Props = BaseProps & {
    icon: string,
    onClick?: (() => any) | undefined
}

type NewIconProps = BaseProps & {
    isHiddenTablet?: boolean | undefined
}

export const Icon = (props: Props) => {

    if (props.icon.length === 0) {
        return null;
    }

    return <span className={`icon ${props.className !== undefined ? props.className : ""} ${props.isGiant ? "is-size-1" : ""}`} title={props.title} onClick={props.onClick}>
        <i className={"mdi mdi-" + props.icon} aria-hidden="true" />
    </span>;
};

export const NewIcon = (props: NewIconProps) => {
    return <Icon className={`${props.className != undefined ? props.className : ""} unread ${props.isHiddenTablet ? "is-hidden-tablet-only" : ""}`} icon={IconHelper.Statuses.Unread} title={props.title} />
};

export const ErrorIcon = (props: BaseProps) => {
    return <Icon className={`${props.className != undefined ? props.className : ""} has-text-danger`} icon={IconHelper.Statuses.Error} title={props.title} />
};

Icon.defaultProps = {
    title: "",
    className: "",
    colour: ""
};