import { append, move, remove, update } from "ramda";
import { Emblem, EmblemLayer, IEmblemLayer } from "../../../ApplicationState/ApiClient";

export interface IEmblemEditingState {
  emblem: Emblem | undefined;
  isDirty: boolean;
}

export const defaultTransformValues = {
  rotation: 0,
  scale: 1,
  translationX: 0,
  translationY: 0,
};

export const initialEmblem = {
  filename: "Basic/arrow_upwards.svg",
  colourHex: "#FFFFFF",
  ...defaultTransformValues,
} as EmblemLayer;

export const defaultEmblem = Emblem.fromJS({
  configuration: {
    layers: [initialEmblem],
  },
});

export const GET_LAYERS_FROM_API = "GET_LAYERS_FROM_API";
export const UPDATE_CURRENT_LAYER = "UPDATE_CURRENT_LAYER";
export const CREATE_NEW_LAYER = "CREATE_NEW_LAYER";
export const REMOVE_LAYER = "REMOVE_LAYER";
export const SAVED_EMBLEM = "SAVED_EMBLEM";
export const MOVE_LAYER = "MOVE_LAYER";

interface IGetLayersFromApi {
  type: typeof GET_LAYERS_FROM_API;
  emblem: Emblem;
}

interface IUpdateCurrentLayer {
  type: typeof UPDATE_CURRENT_LAYER;
  index: number;
  layer: IEmblemLayer;
}

interface ICreateNewLayer {
  type: typeof CREATE_NEW_LAYER;
  layer: EmblemLayer;
}

interface IRemoveLayer {
  type: typeof REMOVE_LAYER;
  layerIndex: number;
}

interface IEmblemSave {
  type: typeof SAVED_EMBLEM;
}

export enum Direction {
  Up = "down",
  Down = "up",
}

interface IMoveLayer {
  type: typeof MOVE_LAYER;
  index: number;
  direction: Direction;
}

export const initialState: IEmblemEditingState = {
  emblem: undefined,
  isDirty: true,
};

export type EmblemEditActions =
  | IGetLayersFromApi
  | IUpdateCurrentLayer
  | ICreateNewLayer
  | IRemoveLayer
  | IEmblemSave
  | IMoveLayer;

function emblemEditActionReducer(state: IEmblemEditingState, action: EmblemEditActions): IEmblemEditingState {
  switch (action.type) {
    case GET_LAYERS_FROM_API:
      return { ...state, emblem: action.emblem, isDirty: false };
    case UPDATE_CURRENT_LAYER:
      if (state.emblem?.configuration?.layers) {
        state.emblem.configuration.layers = update(
          action.index,
          EmblemLayer.fromJS(action.layer)
        )(state.emblem.configuration.layers);
      }
      return { ...state, isDirty: true };
    case CREATE_NEW_LAYER:
      if (state.emblem && state.emblem.configuration?.layers) {
        const newLayer = EmblemLayer.fromJS({
          ...action.layer,
          ...defaultTransformValues,
        });
        state.emblem.configuration.layers = append(
          newLayer,
          state.emblem.configuration?.layers
        );
      }
      return { ...state, isDirty: true };
    case REMOVE_LAYER:
      const numberOfLayers = state.emblem?.configuration?.layers?.length;
      if (
        numberOfLayers &&
        numberOfLayers > 1 &&
        state.emblem!.configuration!.layers
      ) {
        state.emblem!.configuration!.layers = remove(
          action.layerIndex,
          1,
          state.emblem!.configuration!.layers
        );

        return { ...state, isDirty: true };
      }
      return state;
    case MOVE_LAYER:
      if (state.emblem && state.emblem.configuration?.layers) {
        state.emblem!.configuration!.layers = move(
          action.index,
          action.direction === Direction.Up
            ? action.index - 1
            : action.direction === Direction.Down
              ? action.index + 1
              : action.index,
          state.emblem!.configuration!.layers
        );
      }
      return { ...state, isDirty: true };
    case SAVED_EMBLEM:
      return { ...state, isDirty: false };
    default:
      return state;
  }
}

export default emblemEditActionReducer;
