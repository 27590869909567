import { observer } from "mobx-react-lite";
import React from "react";
import { ICelestialDetail, ICelestialTypeSettings, IGameSettings, IInstallationTypeSettings, IItemTypeSettings, Installation, IShipTypeSettings, ISolarSystemDetail, IWorldEffects, RequestResponseOfSolarSystemDetail, SolarSystemClaimType, SolarSystemDetail } from "../../../../../ApplicationState/ApiClient";
import { ApiStateContext, DescriptionStateContext, PlayerStateContext } from "../../../../../ApplicationState/ContextRoot";
import { Button } from "../../../../../Components/Base/Buttons/Button";
import { ButtonHolder } from "../../../../../Components/Base/Buttons/ButtonHolder";
import { ButtonLink } from "../../../../../Components/Base/Buttons/ButtonLink";
import { SplitLayout } from "../../../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../../../Components/Base/Containers/SubPanel";
import { BuildValidation } from "../../../../../Components/FusionShift/Build/BuildValidation";
import { InstallationTypeStats } from "../../../../../Components/FusionShift/Descriptions/InstallationTypeStats";
import { InstallationImage } from "../../../../../Components/FusionShift/Images/InstallationImage";
import { CostControl } from "../../../../../Components/FusionShift/Items/CostControl";
import { RequestResponseMessage } from "../../../../../Components/FusionShift/Messages/RequestResponseMessage";
import { RichTextArray } from "../../../../../Components/FusionShift/RichTextArray";
import { TypeDataVoice } from "../../../../../Components/FusionShift/TypeDataVoice";
import { BuildHelper } from "../../../../../Helpers/BuildHelper";
import { IconHelper } from "../../../../../Helpers/IconHelper";
import { system_subView } from "../../../../../Navigation/Routing/SolarSystem";
import { ArmApocalypseWeapon } from "./ArmApocalypseWeapon";
import { ContinualUpgrade } from "./ContinualUpgrade";
import { FeaturesControl } from "./FeaturesControl";
import { SchematicAndOperationLevelControl } from "./SchematicAndOperationLevelControl";
import { InstallationStatsSummary } from "./StatsSummary";

type Props = {
    installation: Installation,
    solarSystem: ISolarSystemDetail,
    worldEffects: IWorldEffects,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    itemTypeSettings: IItemTypeSettings,
    shipTypeSettings: IShipTypeSettings,
    gameSettings: IGameSettings,
    celestial: ICelestialDetail,
    reloadCallback: (solarSystem: SolarSystemDetail) => any
}

const DECONSTRUCT = "DECONSTRUCT";
const REPLACE_CAPITAL = "REPLACE_CAPITAL";

export const ExistingInstallation = observer((props: Props) => {

    const apiState = React.useContext(ApiStateContext);
    const playerState = React.useContext(PlayerStateContext);

    const descriptionState = React.useContext(DescriptionStateContext);

    const [overrideConfirmed, setOverrideConfirmed] = React.useState<string | undefined>(undefined);
    const [response, setResponse] = React.useState<RequestResponseOfSolarSystemDetail | undefined>(undefined);

    const destructionConfirmed = overrideConfirmed === DECONSTRUCT;
    const replaceCapitalConfirmed = overrideConfirmed === REPLACE_CAPITAL;

    const installationTypeName = props.installation.installationTypeName;

    if (!installationTypeName || !playerState.Player) {
        return null;
    }

    const installationType = props.installationTypeSettings.data![installationTypeName];

    const requiresDeconstructOverride = installationType.solarSystemClaimType !== undefined && installationType.solarSystemClaimType !== null;
    const canDeconstruct = BuildHelper.canDeconstruct(props.installation, props.celestial, props.solarSystem, props.gameSettings, props.installationTypeSettings, destructionConfirmed);

    const upgradeDetails = BuildHelper.upgradeDetails(props.installation, props.celestial, props.solarSystem, props.installationTypeSettings, props.itemTypeSettings, props.gameSettings, props.worldEffects);

    const couldBeCapital = installationType.solarSystemClaimType === SolarSystemClaimType.Colony;
    const capitalTypeName = Object.values(props.installationTypeSettings.data).filter(x => x.solarSystemClaimType === SolarSystemClaimType.Capital).map(x => x.typeName)[0];
    const changeCapitalError = couldBeCapital ? BuildHelper.upgradeError(props.installation, props.celestial, props.solarSystem, props.gameSettings, props.installationTypeSettings, props.itemTypeSettings, props.worldEffects) : undefined;
    const changeCapitalCost = couldBeCapital ? BuildHelper.costForLevel(props.worldEffects, props.installationTypeSettings, capitalTypeName, props.installation.level, props.gameSettings, props.celestial.size) : undefined;

    const buildQueueItem = props.solarSystem.installationBuildQueue && props.solarSystem.installationBuildQueue.find(f => f.installationId === props.installation.installationId);

    function reloadCallback(response: RequestResponseOfSolarSystemDetail) {
        setResponse(response);
        if (response.result !== undefined) {
            props.reloadCallback(response.result);
        }
    }

    function upgrade() {
        return apiState.InstallationClient.upgrade(
            props.solarSystem.solarSystemId,
            props.celestial.celestialId,
            props.installation.installationId)
            .then(reloadCallback);
    }

    function deconstruct() {

        if (requiresDeconstructOverride && !destructionConfirmed) {
            setOverrideConfirmed(DECONSTRUCT);
            return;
        }

        return apiState.InstallationClient.deconstruct(
            props.solarSystem.solarSystemId,
            props.celestial.celestialId,
            props.installation.installationId)
            .then(reloadCallback);
    }

    function changeCapital() {
        if (requiresDeconstructOverride && replaceCapitalConfirmed) {
            setOverrideConfirmed(REPLACE_CAPITAL);
            return;
        }

        return apiState.InstallationClient.changeCapital(
            props.solarSystem.solarSystemId,
            props.celestial.celestialId,
            props.installation.installationId
        ).then(reloadCallback);
    }

    const description = descriptionState.Installations?.find(x => x.typeName === props.installation.installationTypeName);

    const hasAgents = (installationType.agentCanBeTrainedAtLevel ?? 0) > 0;

    return <>
        <RequestResponseMessage response={response} />
        <SplitLayout
            left={
                <SubPanel
                    heading={"Description"}
                    headingContent={<TypeDataVoice type="InstallationType" typeData={installationType} />}>
                    <SplitLayout
                        left={
                            <InstallationImage
                                installation={props.installation}
                                installationType={installationType}
                                size="large"
                                isConstructing={buildQueueItem !== undefined}
                                celestialId={props.celestial.celestialId}
                            />
                        }
                        right={
                            <RichTextArray text={description?.description} />
                        }
                    />
                </SubPanel>
            }
            right={
                <SubPanel heading="Status"
                    headingContent={<FeaturesControl {...props}>
                        {hasAgents && <ButtonLink
                            icon={IconHelper.Agents.Agent}
                            text="Agents"
                            to={system_subView("agents")}
                        />}
                    </FeaturesControl>}
                >
                    <InstallationStatsSummary {...props} installationType={installationType} />
                    <ContinualUpgrade {...props} installationType={installationType} />
                </SubPanel>
            }
        />
        <ArmApocalypseWeapon {...props} installationType={installationType} />
        {(installationType.hasOperationLevel || installationType.canProduceFromSchematicTypeNames.length > 0) && <SchematicAndOperationLevelControl {...props} installationType={installationType} />}
        <SplitLayout
            left={
                <SubPanel heading={`Current Level ${props.installation.level}`}
                    footerContent={<ButtonHolder isPulledRight>
                        {couldBeCapital && <Button type="warning" icon={IconHelper.SolarSystems.Capital} action={() => changeCapital()} text="Change Capital" isDisabled={!!changeCapitalError} confirm="Are you sure you want to move your capital?" />}
                        {!overrideConfirmed &&
                            <Button type="danger" icon={IconHelper.General.Delete} action={() => deconstruct()} text={`Deconstruct`} isDisabled={!canDeconstruct} confirm="Are you sure you want to deconstruct and completely remove this installation? Any ships in the build queue will be removed and refunded." />
                        }
                        {overrideConfirmed &&
                            <Button type="danger" icon={IconHelper.Combat.Defeated} action={() => deconstruct()} text="Lose control of System" confirm="Deconstructing this installation to level 0 will cause you to lose control of the solar system. Are you sure you want to deconstruct this installation?" />
                        }
                    </ButtonHolder>
                    }
                >
                    <InstallationTypeStats
                        celestialSize={props.celestial.size}
                        installationType={installationType}
                        level={props.installation.level}
                        factionTypeName={playerState.Player!.factionTypeName}
                        display={{
                            showLimits: true,
                            showAllows: true,
                            shipBuildDisplay: "levelAndBelow",
                            sectorBonusesForMaxLevel: false
                        }}
                        operationLevel={props.installation.operationLevel}
                        cost={changeCapitalCost}
                    />
                    {couldBeCapital && changeCapitalCost && <>
                        <br /><b>Change Capital</b><br />
                        <CostControl cost={changeCapitalCost} />
                    </>}
                    {couldBeCapital && <BuildValidation buildError={changeCapitalError} />}
                </SubPanel>
            }
            right={(upgradeDetails.canUpgrade || upgradeDetails.hasNextLevel) &&
                <SubPanel heading={`Next Level ${props.installation.level + 1}`}
                    footerContent={
                        <Button type="action" icon={IconHelper.Installations.Upgrade} text={`Upgrade to level ${upgradeDetails.nextLevel}`} action={() => upgrade()} isDisabled={!!upgradeDetails.upgradeError} isPulledRight />
                    }>
                    <InstallationTypeStats
                        celestialSize={props.celestial.size}
                        installationType={installationType}
                        level={props.installation.level + 1}
                        operationLevel={props.installation.level + 1}
                        factionTypeName={playerState.Player!.factionTypeName}
                        display={{
                            shipBuildDisplay: "levelAndLater",
                            showAllows: false,
                            showLimits: false,
                            sectorBonusesForMaxLevel: false
                        }}
                        cost={upgradeDetails.nextCost}
                        availableItems={props.solarSystem.items}
                    />
                    <BuildValidation buildError={upgradeDetails.upgradeError} />
                </SubPanel>
            }
        />
    </>;
});