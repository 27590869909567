import * as React from "react";

type LevelContent = {
    heading?: React.ReactNode | undefined,
    content: React.ReactNode | undefined,
    isStandalone?: boolean
}

type Props = {
    content: (LevelContent | false | undefined)[],
    className?: string,
    isVerticalOnSmallerViewports?: boolean,
    renderContentAsTitle?: boolean
}

export function levelContentWithHeading(heading: React.ReactNode, content: React.ReactNode | undefined) {
    return {
        heading, content
    };
}

export function levelContent(content: React.ReactNode | undefined) {
    return {
        heading: undefined, content
    };
}

export function levelContentStandalone(content: React.ReactNode | undefined) {
    return {
        heading: undefined,
        content,
        isStandalone: true
    };
}

export const Level = (props: Props) => {

    function shouldTreatAsText(something: any): boolean {
        if (typeof something === 'string') {
            return true;
        }
        if (typeof something === 'number') {
            return true;
        }
        return false;
    }

    const content = props.content.filter(c => c && (c.content !== undefined || c.heading !== undefined));

    if (content.length === 0) {
        return null;
    }

    return <nav className={`level ${!props.isVerticalOnSmallerViewports ? "is-mobile" : ""} ${props.className}`} >
        {content.map((c, i) => c === false || c === undefined ? null :
            <div key={i} className={`level-item has-text-centered ${c.isStandalone ? "is-standalone" : ""}`}>
                <div>
                    {c.heading !== undefined && <p className="heading">{c.heading}</p>}
                    {c.content !== undefined &&
                        <>
                            {shouldTreatAsText(c.content) && <p className={props.renderContentAsTitle ? "title" : ""}>{c.content}</p>}
                            {!shouldTreatAsText(c.content) && c.content}
                        </>
                    }
                </div>
            </div>
        )}
    </nav>;
};

Level.defaultProps = {
    className: "",
    isVerticalOnSmallerViewports: false,
    isContentTitle: true
};