import { ICelestialDetail, IGameSettings, IInstallationTypeSettings, Installation, InstallationType, ISolarSystemDetail, IWorldEffects } from '../../../../../ApplicationState/ApiClient';
import { BlockProgress } from '../../../../../Components/Base/BlockProgress';
import { FieldHolder } from '../../../../../Components/Base/Form/FieldHolder';
import { ProgressBar } from '../../../../../Components/Base/ProgressBar';
import { BuildHelper } from '../../../../../Helpers/BuildHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { InstallationHelper } from '../../../../../Helpers/InstallationHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    installation: Installation,
    installationType: InstallationType,
    solarSystem: ISolarSystemDetail,
    celestial: ICelestialDetail,
    gameSettings: IGameSettings,
    installationTypeSettings: IInstallationTypeSettings,
    worldEffects: IWorldEffects
}

export const InstallationStatsSummary = (props: Props) => {

    const maxHealth = InstallationHelper.healthForLevel(props.installation.level, props.installationType.healthPerLevel);
    const fixedDamage = maxHealth - (props.installation.damage !== undefined ? props.installation.damage : 0);

    const maxLevel = BuildHelper.effectiveMaxLevel(props.installationType, props.celestial.size);

    function levelClassNameCallback(level: number) {

        if (level <= props.installation.level) {
            return "is-complete";
        }

        if (props.solarSystem.installationBuildQueue && props.solarSystem.installationBuildQueue.find(x => x.installationId === props.installation.installationId && x.targetLevel === level)) {
            return "is-active";
        }

        const costForLevel = BuildHelper.costForLevel(props.worldEffects, props.installationTypeSettings, props.installation.installationTypeName, level, props.gameSettings, props.celestial.size);
        if (costForLevel && BuildHelper.canAfford(costForLevel, props.solarSystem)) {
            return "is-affordable";
        }

        return undefined;
    }

    return <>
        <FieldHolder label="Health">
            <ProgressBar {...{
                leftIcon: fixedDamage !== 0 ? IconHelper.Installations.NotDamaged : IconHelper.Installations.Damaged,
                value: maxHealth === 0 ? 0 : fixedDamage / maxHealth,
                text: ValueFormatter.fromDecimalToDisplayPercent(maxHealth === 0 ? 0 : fixedDamage / maxHealth, false, 0)
            }} />
        </FieldHolder>
        <FieldHolder label="Level" isExpand>
            <BlockProgress {...{
                value: props.installation.level,
                max: maxLevel,
                classNameCallback: levelClassNameCallback
            }} />
        </FieldHolder>
    </>;
};