import React from "react";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    celestialTypeName: string | undefined,
    quantity?: number | null,
    capacity?: number | null
}

export const CelestialTypeIcon = (props: Props) => {

    return <Icon icon={IconHelper.Celestials.celestialType(props.celestialTypeName)} className={(props.celestialTypeName ?? "unknown").toLowerCase()} title={props.celestialTypeName} />;
};