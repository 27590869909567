import * as React from 'react';
import { AdminWorld, ConfigureAuthenticationInput, IConfigureAuthenticationInput, WorldStatus } from '../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Select } from '../../../Components/Base/Form/Select';
import { SuccessMessageBox } from '../../../Components/Base/MessageBox';
import { IconHelper } from '../../../Helpers/IconHelper';

type Props = {
    world: AdminWorld,
    reloadCallback: (world: AdminWorld) => any
}

export const ConfigureAuthenticationControl = (props: Props) => {

    const apiState = React.useContext(ApiStateContext);

    const [registrationPassword, setRegistrationPassword] = React.useState<string>(props.world.settings.serverSettings ? props.world.settings.serverSettings.registrationPassword : "");
    const [welcomeMessage, setWelcomeMessage] = React.useState<string>(props.world.welcomeMessage);
    const [status, setStatus] = React.useState(props.world.status);
    const [isSaved, setIsSaved] = React.useState(false);

    function save() {
        const input: IConfigureAuthenticationInput = {
            registrationPassword: registrationPassword,
            welcomeMessage: welcomeMessage,
            status: status
        };

        return apiState.AdminClient.configureAuthentication(props.world.worldId, new ConfigureAuthenticationInput(input)).then(w => {
            props.reloadCallback(w);
            setIsSaved(true);
        });
    }

    return <SubPanel heading="Configure Authentication">
        {isSaved && <SuccessMessageBox text="Saved authentication configuration" />}
        <div className="field">
            <label className="label">Status</label>
            <div className="control">
                <Select defaultValue={status} valueChanged={s => setStatus(WorldStatus[s])} values={[
                    WorldStatus.Active,
                    WorldStatus.LockedToAdmins
                ].map(s => {
                    return {
                        label: WorldStatus[s],
                        value: s
                    };
                })} />
            </div>
        </div>
        <div className="field">
            <label className="label">Registration Password</label>
            <div className="control">
                <input className="input" value={registrationPassword} onChange={e => setRegistrationPassword(e.target.value)} />
            </div>
        </div>
        <div className="field">
            <label className="label">Welcome Message</label>
            <div className="control">
                <textarea className="textarea" value={welcomeMessage} onChange={e => setWelcomeMessage(e.target.value)} rows={8} />
            </div>
            <Button type="action" text="Save" icon={IconHelper.General.Confirm} action={save} />
        </div>
    </SubPanel>;
};