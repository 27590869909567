import React from "react"
import { InGameProduct, Manifest } from "../../../../ApplicationState/ApiClient"
import { SubPanel } from "../../../../Components/Base/Containers/SubPanel"
import { Table } from "../../../../Components/Base/Containers/Table"
import { Icon } from "../../../../Components/Base/Icon"
import { IconHelper } from "../../../../Helpers/IconHelper"

type Props = {
    selectedPremiumBundleId: number,
    setSelectedPremiumBundleId: (inGameProductId: number) => any,
    manifest: Manifest,
    premiumBundles: InGameProduct[],
    hasPurchasedPremiumBundle: (inGameProductId: number) => boolean
}

export const PremiumBundleChooser = (props: Props) => {
    return <SubPanel heading="Bundle"
        isUnpadded
        className="category-chooser">
        <Table isHoverable>
            {props.premiumBundles.map(x => <tr
                key={x.inGameProductId}
                onClick={_ => props.setSelectedPremiumBundleId(x.inGameProductId)}>
                <td className={x.inGameProductId === props.selectedPremiumBundleId ? "is-selected" : ""}>
                    {x.name}
                    {props.hasPurchasedPremiumBundle(x.inGameProductId) && <Icon icon={IconHelper.Premium.ProductPurchased} />}
                    {!props.hasPurchasedPremiumBundle(x.inGameProductId) && <Icon icon={IconHelper.Premium.PurchasedNotPurchased} />}
                </td>
            </tr>)}
        </Table>
    </SubPanel>
}