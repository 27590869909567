import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CelestialDetail, Installation, ISolarSystemDetail } from '../../ApplicationState/ApiClient';
import { AgentStateContext, ApiStateContext, AppStateContext, CreditsStateContext, SolarSystemStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { Button } from '../../Components/Base/Buttons/Button';
import { SubPanel } from '../../Components/Base/Containers/SubPanel';
import { Table } from '../../Components/Base/Containers/Table';
import { ErrorMessageBox } from '../../Components/Base/MessageBox';
import { Paragraph } from '../../Components/Base/Text/Paragraph';
import { CreditsIconWithQuantityAndAffordability } from '../../Components/FusionShift/Icons/Items/CreditsIconWithQuantity';
import { InstallationTypeLink } from '../../Components/FusionShift/Links/InstallationTypeLink';
import { SolarSystemLink } from '../../Components/FusionShift/Links/SolarSystemLink';
import { IconHelper } from '../../Helpers/IconHelper';
import { agentById } from '../../Navigation/Routing/Agents';

export const HireAgent = observer(() => {

    const worldState = React.useContext(WorldStateContext);
    const agentState = React.useContext(AgentStateContext);
    const solarSystemState = React.useContext(SolarSystemStateContext);
    const apiState = React.useContext(ApiStateContext);
    const appState = React.useContext(AppStateContext);
    const creditsState = React.useContext(CreditsStateContext);

    const [error, setError] = React.useState<string | undefined>(undefined);
    const [isShowingHire, setIsShowingHire] = React.useState(false);

    const requiredInstallation = worldState.InstallationTypeSettings !== undefined ? Object.values(worldState.InstallationTypeSettings.data).find(x => x.agentCanBeTrainedAtLevel !== undefined && x.agentCanBeTrainedAtLevel !== null) : undefined;
    const current = agentState.AgentSummary?.agents.length ?? 0;
    const capacity = agentState.AgentSummary?.agentCapacity ?? 0;

    function hire(solarSystemId: number) {
        return apiState.AgentClient.hire(solarSystemId).then(x => {
            setIsShowingHire(false);

            if (x.result === undefined) {
                setError(x.error);
            } else {
                agentState.loadAgents(x.result);
                appState.navigate(agentById(x.result.hiredAgentId))
            }
        });
    }

    const validSystems = React.useMemo(() => {

        const solarSystems: {
            installation: Installation;
            celestial: CelestialDetail;
            solarSystem: ISolarSystemDetail;
            isValid: boolean;
        }[] = [];

        if (solarSystemState.SolarSystemsDetail !== undefined && requiredInstallation !== undefined) {
            for (let solarSystem of solarSystemState.SolarSystemsDetail) {
                const target = solarSystem.solarSystem.celestials.map(celestial => {
                    const installation = celestial.installations?.find(i => i?.installationTypeName === requiredInstallation.typeName);

                    return installation !== undefined ?
                        {
                            installation,
                            celestial,
                            solarSystem: solarSystem.solarSystem,
                            isValid: installation.level >= requiredInstallation.agentCanBeTrainedAtLevel!
                        } : undefined;
                })
                    .filter(x => x !== undefined)
                    .map(x => x!);

                if (target !== undefined && target.length > 0) {
                    solarSystems.push(target[0]);
                }
            }
        }
        return solarSystems;

    }, [requiredInstallation, solarSystemState.SolarSystemsDetail]);

    if (agentState.AgentSummary === undefined || worldState.InstallationTypeSettings === undefined) {
        return null;
    }

    const canHire = validSystems.length > 0 && current < capacity;
    const canAFford = agentState.AgentSummary.costForNextAgent <= creditsState.Balance;

    return <SubPanel heading={"Summary"}
        footerContent={canHire && !isShowingHire && <Button
            type="nav"
            text="Hire new agent"
            icon={IconHelper.Agents.Hire}
            action={() => setIsShowingHire(true)}
            isPulledRight
            isDisabled={!canAFford}
        />
        }
    >
        {error !== undefined && error.length > 0 && <ErrorMessageBox text={error} />}
        {
            !isShowingHire && <>
                {requiredInstallation !== undefined &&
                    <Paragraph>
                        An agent can be trained for every <InstallationTypeLink installationType={requiredInstallation} /> that you own across all of your systems.
                    </Paragraph>
                }
                {requiredInstallation !== undefined && capacity == 0 &&
                    <Paragraph>
                        You have no capacity for any agents. Build a new <InstallationTypeLink installationType={requiredInstallation} />.
                    </Paragraph>
                }
            </>
        }
        {
            capacity > 0 &&
            <Paragraph>
                You currently have {current} out of {capacity} agents. The next agent will cost <CreditsIconWithQuantityAndAffordability quantity={agentState.AgentSummary.costForNextAgent} />.
            </Paragraph>
        }
        {
            isShowingHire && <>
                <Paragraph>
                    Select where to hire an agent.
                    {requiredInstallation != null && <>
                        {` You can only select a system with a level ${requiredInstallation.agentCanBeTrainedAtLevel}`} <InstallationTypeLink installationType={requiredInstallation} />.
                    </>
                    }
                </Paragraph>
                <br />
                <Table
                    hideHeaderMobile
                    heading={
                        <>
                            <th>System</th>
                            <th>Celestial</th>
                            <th>{requiredInstallation?.name} Level</th>
                            <th></th>
                        </>
                    }>
                    {validSystems.map(x => <tr key={x.solarSystem.solarSystemId}>
                        <td>
                            <SolarSystemLink solarSystem={x.solarSystem} />
                        </td>
                        <td>
                            {x.celestial.name}
                        </td>
                        <td className={x.isValid ? "is-hidden-mobile" : "is-hidden-mobile has-text-danger"}>
                            {x.installation.level}
                        </td>
                        <td>
                            <Button
                                type="action"
                                text="Hire new agent"
                                icon={IconHelper.Agents.Hire}
                                action={() => hire(x.solarSystem.solarSystemId)}
                                isPulledRight
                                isDisabled={!canAFford}
                            />
                        </td>
                    </tr>)}
                </Table>
            </>
        }
    </SubPanel >;
});