import { DamagedInstallation, ICelestialTypeSettings, IInstallationTypeSettings, InstallationType, ISolarSystemMinimal, SiegeDamage } from "../../../ApplicationState/ApiClient";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";
import { CelestialList } from "./CelestialList";

type Props = {
    siegeDamage: SiegeDamage,
    installationTypeSettings: IInstallationTypeSettings,
    celestialTypeSettings: ICelestialTypeSettings,
    solarSystem: ISolarSystemMinimal | undefined
}

export const DamagedInstallations = (props: Props) => {

    if (!props.siegeDamage || !props.siegeDamage.damagedInstallations || !CollectionHelper.isAnyInArray(props.siegeDamage.damagedInstallations)) {
        return null;
    }

    function makeText(c: DamagedInstallation) {

        const damage = c.reducedToLevel === null || c.reducedToLevel === undefined ? "damaged" :
            c.reducedToLevel <= 0 ? "destroyed"
                : `reduced to level ${c.reducedToLevel}`;

        return `${c.fromLevel ? `level ${c.fromLevel} ` : ""}${props.installationTypeSettings.data[c.installationTypeName || ""].name} was ${damage}`
    }

    const installationsToUse: DamagedInstallation[] = [];
    const byCelestial: { [key: string]: { [key: string]: number } } = {};

    for (let c of props.siegeDamage.damagedInstallations) {
        if (!(c.celestialId in byCelestial)) {
            byCelestial[c.celestialId] = {};
        }
        const text = makeText(c);
        if (!(text in byCelestial[c.celestialId])) {
            byCelestial[c.celestialId][text] = 1;
            installationsToUse.push(c);
        } else {
            byCelestial[c.celestialId][text] = byCelestial[c.celestialId][text] + 1;
        }
    }

    function extra(c: DamagedInstallation, i: InstallationType) {

        let text = makeText(c);
        const count = byCelestial[c.celestialId][text];

        if (count != 1) {
            text = text.replace("was", "were")
                .replace(i.name, ValueFormatter.pluralize(i.name, count));
        }

        return `${count == 1 ? "A" : `${count}x`} ${text}`;
    }

    return <SubPanel
        heading={{ text: "Damaged Installations", icon: IconHelper.Combat.Siege }}
    >
        <CelestialList
            celestials={installationsToUse}
            installationTypeSettings={props.installationTypeSettings}
            celestialTypeSettings={props.celestialTypeSettings}
            solarSystem={props.solarSystem}
            renderExtra={extra}
        />
    </SubPanel>;
};