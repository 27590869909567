import * as React from 'react';
import { BarChart } from '../../Components/Base/Charts/BarChart';
import { ValueFormatter } from '../../Helpers/ValueFormatter';

type Props = {
    votes: { [key: string]: number; }
}

export const VotesBarChart = (props: Props) => {

    let max = 0;
    const data = Object.keys(props.votes).map(x => {

        if (max < props.votes[x]) {
            max = props.votes[x];
        }

        return {
            vote: x,
            votes: props.votes[x]
        };
    });

    if (max < 5) {
        max = 5;
    }

    return <BarChart
        isHorizontal
        data={data}
        min={0}
        max={max}
        x={{
            property: "vote"
        }}
        y={{
            property: "votes",
            format: x => ValueFormatter.formatLocaleNumber(x),
            label: "Number of Votes"
        }}
    />;
};