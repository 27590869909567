import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ISolarSystemMinimal } from '../../../../ApplicationState/ApiClient';
import { PlayerStateContext, SolarSystemStateContext } from '../../../../ApplicationState/ContextRoot';
import { RelationHelper } from '../../../../Helpers/RelationHelper';
import { RenderContext } from '../RenderContext';

type Props = {
    renderContext: RenderContext,
    scale: number
}

type AdditionalSolarSystemsProps = Props & {
    additionalSolarSystems?: (ISolarSystemMinimal | undefined)[]
}

export const OwnSolarSystems = observer((props: Props) => {

    const solarSystemState = React.useContext(SolarSystemStateContext);

    if (solarSystemState.SolarSystems === undefined) {
        return null;
    }

    const rc = props.renderContext;

    return <>
        {solarSystemState.SolarSystems.map(x => <React.Fragment key={x.solarSystemId}>
            {x.radarRange !== undefined &&
                <circle
                    className={`radar ${RelationHelper.Relation_Self}`}
                    strokeWidth={1 / props.scale}
                    strokeDasharray={5 / props.scale}
                    r={x.radarRange}
                    cx={rc.tX(x.x)}
                    cy={rc.tY(x.y)}
                />
            }
            <circle
                className={`solar-system ${RelationHelper.Relation_Self}`}
                strokeWidth={1 / props.scale}
                r={2 / props.scale}
                cx={rc.tX(x.x)}
                cy={rc.tY(x.y)}
            />
        </React.Fragment>)}
    </>;
});

export const AdditionalSolarSystems = observer((props: AdditionalSolarSystemsProps) => {

    const playerState = React.useContext(PlayerStateContext);

    const solarSystems = (props.additionalSolarSystems ?? []).filter(x => x !== undefined).map(x => x!);

    if (playerState.Player === undefined || solarSystems.length === 0) {
        return null;
    }

    const rc = props.renderContext;

    return <>
        {solarSystems.map(x => <circle key={x.solarSystemId}
            className={`solar-system ${RelationHelper.relationClassName(x.owner, playerState.Player)}`}
            strokeWidth={1 / props.scale}
            r={2 / props.scale}
            cx={rc.tX(x.x)}
            cy={rc.tY(x.y)}
        />)}
    </>;
});