import classNames from "classnames"
import { Paragraph } from "./Paragraph"

type Props = {
    children: React.ReactNode,
    isTitle?: boolean
}

export const QuoteParagraph = (props: Props) => {

    if (props.children === undefined || props.children === null ||
        (typeof props.children === "string" && props.children.length === 0)) {
        return null
    }

    const className = classNames(
        "is-italic",
        props.isTitle && "is-title"
    );

    return <Paragraph className={className}>
        "{props.children}"
    </Paragraph>
}