import * as React from 'react';
import { AppState } from './States/AppState';
import { DevState } from './States/DevState';
import { MobileMenuState } from './States/MobileMenuState';

const appState = new AppState(true);

export const AppStateContext = React.createContext(appState);

export const ApiStateContext = React.createContext(appState.ApiState);
export const useApiState = () => React.useContext(ApiStateContext);

export const AgentStateContext = React.createContext(appState.AgentState);
export const AudioStateContext = React.createContext(appState.AudioState);
export const ChatStateContext = React.createContext(appState.ChatState);
export const DescriptionStateContext = React.createContext(appState.DescriptionState);
export const HelpStateContext = React.createContext(appState.HelpState);
export const MissionLogStateContext = React.createContext(appState.MissionLogState);
export const PlayerStateContext = React.createContext(appState.PlayerState);
export const PremiumBalanceStateContext = React.createContext(appState.PremiumBalanceState);
export const SolarSystemStateContext = React.createContext(appState.SolarSystemState);
export const UnreadStateContext = React.createContext(appState.UnreadState);
export const WorldStateContext = React.createContext(appState.WorldState);
export const ApplicationInsightsStateContext = React.createContext(appState.ApplicationInsightsState);
export const TutorialStateContext = React.createContext(appState.TutorialState);
export const CreditsStateContext = React.createContext(appState.CreditsState);

export const AuthenticateStateContext = React.createContext(appState.AuthenticateState);

export const MobileMenuStateContext = React.createContext(new MobileMenuState());

export const DevStateContext = React.createContext(new DevState(appState));