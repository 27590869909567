import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TabMenuWithChildren } from '../../../Components/Base/Containers/TabMenu';
import { SolarSystemWrapper } from '../../../Entities/SolarSystem/SolarSystemWrapper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { AdvisorView } from './AdvisorView';
import { AutomationView } from './AutomationView';

type Props = {
    solarSystems: SolarSystemWrapper[]
}

type NetworkTab = "Advisor" | "Automation";

export const AdvisorAndAutomation = observer((props: Props) => {

    const [tab, setTab] = React.useState<NetworkTab>("Advisor");

    const tabs = [
        {
            id: "Advisor",
            title: "Advisor",
            isAvailable: true,
            icons: IconHelper.Categories.Advisor
        },
        {
            id: "Automation",
            title: "Automation",
            isAvailable: true,
            icons: IconHelper.SolarSystems.Automation
        }
    ];

    return <TabMenuWithChildren
        tabs={tabs}
        active={tab}
        changeTab={setTab}
    >
        {tab === "Advisor" && <AdvisorView {...props} />}
        {tab === "Automation" && <AutomationView {...props} />}
    </TabMenuWithChildren>;
});