import * as React from "react";
import { CombatReportDetail, ICombatReport, IGameSettings, IShipTypeSettings } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext } from "../../../ApplicationState/ContextRoot";
import { FlexDiv } from "../../../Components/Base/Containers/FlexDiv";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { ItemsControl } from "../../../Components/FusionShift/Items/ItemsControl";
import { FleetWrapper } from "../../../Entities/FleetWrapper";
import { CollectionHelper } from "../../../Helpers/CollectionHelper";
import { IconHelper } from "../../../Helpers/IconHelper";
import { ValueFormatter } from "../../../Helpers/ValueFormatter";

type Props = {
    combatReport: ICombatReport,
    combatReportDetail: CombatReportDetail,
    shipTypeSettings: IShipTypeSettings,
    gameSettings: IGameSettings
}

export const Loot = (props: Props) => {

    const playerState = React.useContext(PlayerStateContext);

    if (!CollectionHelper.isAnyQuantityInDictionary(props.combatReportDetail.items, true)) {
        return null;
    }

    const attackingShips = props.combatReportDetail.attackingShips;
    const needFleet = attackingShips && props.combatReport.attackingPlayer && props.combatReport.attackingPlayer.playerId === playerState.Player!.playerId;
    const survivingAttackers: { [key: string]: number } = {};
    if (needFleet) {
        for (let key of Object.keys(attackingShips!)) {
            survivingAttackers[key] = attackingShips![key].survivors === undefined ? 0 : attackingShips![key].survivors!;
        }
    }
    const survivingAttackersFleet = needFleet && new FleetWrapper(props.shipTypeSettings, props.gameSettings, survivingAttackers);

    return <SubPanel heading={{ text: "Stolen Items", icon: IconHelper.Items.Item }}>
        <FlexDiv>
            <ItemsControl items={props.combatReportDetail.items} expandDetails />
        </FlexDiv>
        {needFleet && survivingAttackersFleet && survivingAttackersFleet.HasAnyShips &&
            <>
                <br />
                {`Cargo capacity: ${ValueFormatter.formatLocaleNumber(Math.floor(CollectionHelper.sumOfDictionary(props.combatReportDetail.items)))} / ${ValueFormatter.formatLocaleNumber(survivingAttackersFleet.TotalCargoCapacity)}`}
            </>
        }
    </SubPanel>;
};
