import * as React from 'react';
import { Harvested, ItemsDetail, ManufactoryProduction, Production, Upkeep } from '../../../../../ApplicationState/ApiClient';
import { TextAndIcon } from '../../../../../Components/Base/Text/TextAndIcon';
import { IconHelper } from '../../../../../Helpers/IconHelper';

export const contentRowCssClasses = "is-tabbed";
export const totalRowCssClasses = "has-text-weight-semibold";
export const contentTotalRowCssClasses = "has-text-weight-semibold " + contentRowCssClasses;
export const netTotalRowCssClasses = totalRowCssClasses + " has-underline";

export type RowConfiguration = {
    propertyName: string,
    label: React.ReactNode,
    format: "locale" | "localeNegated" | "percentBonus",
    dangerIf?: "lessThanZero" | "greaterThanZero" | undefined,
    rowClassName?: string | undefined,
    showEvenIfZero?: boolean
}

export function rowConfiguration(property: keyof ItemsDetail, label, format, dangerIf, rowClassName, showEvenIfZero?: boolean): RowConfiguration {
    return {
        propertyName: property,
        label,
        format,
        dangerIf,
        rowClassName,
        showEvenIfZero
    }
}

export function harvestedRowConfiguration(property: keyof Harvested, label, format, dangerIf, rowClassName): RowConfiguration {
    return {
        propertyName: property,
        label,
        format,
        dangerIf,
        rowClassName
    }
}

export function upkeepRowConfiguration(property: keyof Upkeep, label, format, dangerIf, rowClassName): RowConfiguration {
    return {
        propertyName: property,
        label,
        format,
        dangerIf,
        rowClassName
    }
}

export function productionRowConfiguration(property: keyof Production, label, format, dangerIf, rowClassName): RowConfiguration {
    return {
        propertyName: property,
        label,
        format,
        dangerIf,
        rowClassName
    }
}

export function manufactoryRowConfiguration(property: keyof ManufactoryProduction, label, format, dangerIf, rowClassName): RowConfiguration {
    return {
        propertyName: property,
        label,
        format,
        dangerIf,
        rowClassName
    }
}

export const harvestedRowConfigurations = [
    harvestedRowConfiguration(
        "byInstallations",
        <TextAndIcon text={" Harvested "} icon={IconHelper.Installations.Installation} />,
        "locale",
        undefined,
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "byDrones",
        <TextAndIcon text={" Harvested "} icon={IconHelper.Ships.Drone} />,
        "locale",
        undefined,
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "megacityBonusPercent",
        <TextAndIcon text={" Megacity Bonus "} icon={IconHelper.Megacities.Megacity} />,
        "percentBonus",
        undefined,
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "policyBonusPercent",
        <TextAndIcon text={" Policy Bonus "} icon={IconHelper.Politics.Politics} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "securityBonusPercent",
        <TextAndIcon text={" Security Penalty "} icon={IconHelper.Security.Exile} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "colonyBonusPercent",
        <TextAndIcon text={" Colony Penalty "} icon={IconHelper.SolarSystems.Colony} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "agentBonusPercent",
        <TextAndIcon text={" Agents "} icon={IconHelper.Agents.Agent} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "totalBonusesPercent",
        <TextAndIcon text={" Total Bonuses "} icon={IconHelper.Categories.Bonuses} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),
    harvestedRowConfiguration(
        "netTotal",
        "Total Income",
        "locale",
        "lessThanZero",
        contentTotalRowCssClasses
    )
];

export const upkeepRowConfigurations = [
    upkeepRowConfiguration(
        "fromShips",
        <TextAndIcon text={" Ship Upkeep "} icon={IconHelper.Ships.Ship} />,
        "localeNegated",
        "greaterThanZero",
        contentRowCssClasses
    ),
    upkeepRowConfiguration(
        "negatedByHangarSpace",
        "Hangar Space",
        "locale",
        undefined,
        contentRowCssClasses
    ),
    upkeepRowConfiguration(
        "netTotal",
        "Total Upkeep",
        "localeNegated",
        "greaterThanZero",
        contentTotalRowCssClasses
    )
];

export const productionRowConfigurations = [
    productionRowConfiguration(
        "consumed",
        "Consumption",
        "localeNegated",
        undefined,
        contentRowCssClasses
    ),
    productionRowConfiguration(
        "produced",
        "Produced",
        "locale",
        undefined,
        contentRowCssClasses
    ),

    productionRowConfiguration(
        "securityBonusPercent",
        <TextAndIcon text={" Security Penalty "} icon={IconHelper.Security.Exile} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),
    productionRowConfiguration(
        "colonyBonusPercent",
        <TextAndIcon text={" Colony Penalty "} icon={IconHelper.SolarSystems.Colony} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),
    productionRowConfiguration(
        "totalBonusesPercent",
        <TextAndIcon text={" Total Bonuses "} icon={IconHelper.Categories.Bonuses} />,
        "percentBonus",
        "lessThanZero",
        contentRowCssClasses
    ),

    productionRowConfiguration(
        "netTotal",
        "Total",
        "locale",
        "lessThanZero",
        contentTotalRowCssClasses
    )
];

export const manufactoryRowConfigurations = [
    manufactoryRowConfiguration(
        "consumedByInputNodes",
        "Consumption",
        "localeNegated",
        undefined,
        contentRowCssClasses
    )
];

export const netAfterUpkeepRowConfiguration = rowConfiguration(
    "netAfterUpkeep",
    "Income after Upkeep",
    "locale",
    "lessThanZero",
    netTotalRowCssClasses
);

export const netAfterProductionRowConfiguration = rowConfiguration(
    "netAfterProduction",
    "Income after Production",
    "locale",
    "lessThanZero",
    netTotalRowCssClasses
);

export const capacityRowConfiguration = rowConfiguration(
    "resourceCapacities",
    "Capacity",
    "locale",
    "lessThanZero",
    undefined
);

export const finalRowConfiguration = rowConfiguration(
    "netFinal",
    "FINAL NET",
    "locale",
    "lessThanZero",
    netTotalRowCssClasses,
    true
);

export const currentRowConfiguration: RowConfiguration = {
    propertyName: "resources",
    label: "Current",
    format: "locale",
    dangerIf: "lessThanZero",
    rowClassName: undefined
};
