import { FleetMovementType } from "../../ApplicationState/ApiClient";

export class MovementTypes {

    public static describe(movementType: FleetMovementType): string {

        switch (movementType) {

            case FleetMovementType.Attack:
                return "An Attack is a fight to the death. Combat will continue until either the attacking or defending forces are completely wiped out.";
            case FleetMovementType.Collection:
                return "You can send ships to collect goods from another solar system that you own. When the ships arrive they will try to fill up with as much of the requested goods are available.";
            case FleetMovementType.Claim:
                return "A claim mission will attempt to settle an unoccupied solar system and establish a base there.";
            case FleetMovementType.CommoditySell:
                return "Take commodities from your system to a system owned by another player, targeting a planet with a Megacity. When the ships arrive they will try to sell as much of their cargo to the Megacity as possible. The amount of goods that can be sold depends on the Megacity's needs.";
            case FleetMovementType.Delivery:
                return "A Delivery simply sends goods from one system to another.";
            case FleetMovementType.Mine:
                return "Perform a mining mission on target celestial in an unoccupied solar system. The fleet will mine resources from the target planet until their cargo is full or ordered to return home.";
            case FleetMovementType.Rebase:
                return "Send ships from one of your solar systems to another one you own. The ships will “join” the target solar system and not return to the source.";
            case FleetMovementType.Recon:
                return "A Recon mission can only be carried out by recon class ships. When the recon force arrives they will attempt to gather intelligence about the target system. The attackers will not apply any combat damage and instead attempt to evade the defence forces while gathering intel.";
            case FleetMovementType.Explore:
                return "An Explore mission can only be carried out by recon class ships and can only target an unexplored system. When the ships arrive they will explore the system then immediately return home.";
            case FleetMovementType.Reinforce:
                return "Ships sent to Reinforce another system will take part in the defence of the target system. They won't assist in the defence against any attacks that belong to an ally";
            case FleetMovementType.ReturnHome:
                return "";
            case FleetMovementType.Trade:
                return "You can send a Trade mission to any solar system that you don't own if it has any market orders that you can accept. Select which market order to accept from the list of available orders and then specify a quantity to send, as well as a minimum exchange ratio. The ships will be loaded with the desired goods and dispatched to the target system to facilitate the trade.";
        }
    }
}