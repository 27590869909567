import * as React from "react";
import Cookies from "universal-cookie";
import { AppStateContext } from "../../ApplicationState/ContextRoot";
import { Button } from "../../Components/Base/Buttons/Button";
import { Paragraph } from "../../Components/Base/Text/Paragraph";
import { IconHelper } from "../../Helpers/IconHelper";

export const CookieWarning = () => {

    const appState = React.useContext(AppStateContext);

    const _cookies: Cookies = new Cookies();
    const [allowCookies, setAllowCookies] = React.useState(appState.UserPrefs.isCookieConsentGiven());

    React.useEffect(() => {
        if (allowCookies) {
            appState.giveCookieConsent();
        }
    }, [allowCookies])

    if (allowCookies) {
        return null;
    }

    return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Allow Cookies?</p>
            </header>
            <section className="modal-card-body content">
                <Paragraph>Fusion Shift Online uses cookies to allow you to login. It won't work without them.</Paragraph>
                <Paragraph>Accept the use of cookies?</Paragraph>
            </section>
            <footer className="modal-card-foot">
                <Button className="is-pulled-right" type="action" text="Accept Cookies" icon={IconHelper.General.Confirm} action={() => { setAllowCookies(true); return true; }} />
            </footer>
        </div>
    </div>;
}