import * as React from 'react';
import { BulmaTypes } from '../BulmaTypes';

type BaseProps = {
    type?: BulmaTypes | "prompt" | "content" | "missing" | "unstyled";
    className?: string | undefined,
    style?: React.CSSProperties | undefined
}

type Props = BaseProps & {
    children: React.ReactNode
}

type ArrayProps = BaseProps & {
    content: React.ReactNode[] | undefined
}

export const Paragraph = (props: Props) => {

    const className = props.type === "content" ? "is-content" :
        props.type === "prompt" ? "is-prompt" :
            `has-text-${props.type}`

    const contentClassName = props.type !== "unstyled" ? "content" : "";

    return <p style={props.style} className={`${contentClassName} ${className} ${props.className ? props.className : ""}`}>
        {props.children}
    </p>;
};

export const ParagraphArray = (props: ArrayProps) => {

    if (props.content === undefined) {
        return null;
    }

    return <>
        {props.content.map((x, i) => <Paragraph key={i} {...props}>
            {x}
        </Paragraph>)}
    </>;
}

Paragraph.defaultProps = {
    type: "content"
};