import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AppStateContext, MissionLogStateContext, WorldStateContext } from '../../ApplicationState/ContextRoot';
import { FancyMessageBox } from '../../Components/Base/FancyMessageBox';
import { TutorialIds } from '../../Navigation/Tutorial/TutorialIds';
import { MissionContent } from './MissionContent';
import { MissionWrapper } from './MissionWrapper';

type MissionState = "None" | "Active" | "Reward Pending";

export const TutorialMissionControl = observer(() => {

    const worldState = React.useContext(WorldStateContext);
    const missionLogState = React.useContext(MissionLogStateContext);
    const appState = React.useContext(AppStateContext);

    const [tutorialMissionTypeName, setTutorialMissionTypeName] = React.useState<string | undefined>(undefined);
    const [mission, setMission] = React.useState<MissionWrapper | undefined>(undefined);
    const [missionState, setMissionState] = React.useState<MissionState>("None");

    // The animation is applied with CSS so if the classname is re-used then the animation doesn't play again
    // Do some fiddling to temporarily remove the class
    const [isAnimationBlocked, setIsAnimationBlocked] = React.useState(false);

    React.useEffect(() => {
        const foundMission = findTutorialMission();

        if (foundMission === undefined) {
            setTutorialMissionTypeName(undefined);
            setMissionState("None");
        } else {
            setTutorialMissionTypeName(foundMission.missionTypeName);
            setMissionState(foundMission.state);
        }
        setIsAnimationBlocked(true);

        const timer = setTimeout(() => {
            setIsAnimationBlocked(false);
        }, 100);

        return () => clearTimeout(timer);
    }, [worldState.MissionTypeSettings,
    missionLogState.MissionLog,
    missionLogState.MissionLog ? missionLogState.MissionLog.activeMissions : undefined,
    missionLogState.MissionLog ? missionLogState.MissionLog.rewardPendingMissions : undefined]);

    React.useEffect(() => {
        if (worldState.MissionTypeSettings && missionLogState.MissionLog && tutorialMissionTypeName) {
            setMission(new MissionWrapper(missionLogState.MissionLog, worldState.MissionTypeSettings[tutorialMissionTypeName]));
        } else {
            setMission(undefined);
        }
    }, [tutorialMissionTypeName, worldState.MissionTypeSettings, missionLogState.MissionLog]);

    function findTutorialMission(): { missionTypeName: string, state: MissionState } | undefined {
        if (worldState.MissionTypeSettings && missionLogState.MissionLog) {

            if (missionLogState.MissionLog.rewardPendingMissions) {

                for (let k of missionLogState.MissionLog.rewardPendingMissions) {
                    if (k in worldState.MissionTypeSettings && worldState.MissionTypeSettings[k].isTutorial) {
                        return {
                            missionTypeName: k,
                            state: "Reward Pending"
                        };
                    }
                }
            }
            if (missionLogState.MissionLog.activeMissions) {
                const keys = Object.keys(missionLogState.MissionLog.activeMissions);
                for (let k of keys) {
                    if (k in worldState.MissionTypeSettings && worldState.MissionTypeSettings[k].isTutorial) {
                        return {
                            missionTypeName: k,
                            state: "Active"
                        };
                    }
                }
            }
        }

        return undefined;
    }

    if (mission === undefined || appState.currentPathName().startsWith("/history")) {
        return null;
    }

    const titleText = <span className="tutorial-header">Tutorial: {mission.MissionType.name}</span>;
    const title = missionLogState.isFirstMissionPending ? <>{titleText} <i>Click to expand and follow the tutorial</i></> : titleText;

    const className = missionState === "Active" ? "has-highlight-bg-active" :
        missionState === "Reward Pending" ? "has-highlight-bg-success" : "";

    return <FancyMessageBox id={TutorialIds.TutorialMission} type="info" title={title} className={isAnimationBlocked ? "" : className} >
        <MissionContent mission={mission} />
    </FancyMessageBox>;
});