import * as React from 'react';
import { ICelestialTypeSettings, IGameSettings, Installation, InstallationType, IRequestResponseOfArmResponse, ISolarSystemDetail, ISolarSystemWithCelestials } from '../../../../../ApplicationState/ApiClient';
import { ApiStateContext } from '../../../../../ApplicationState/ContextRoot';
import { Button } from '../../../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../../../Components/Base/Buttons/ButtonHolder';
import { SplitLayout } from '../../../../../Components/Base/Containers/SplitLayout';
import { SubPanel } from '../../../../../Components/Base/Containers/SubPanel';
import { FieldHolder } from '../../../../../Components/Base/Form/FieldHolder';
import { ErrorMessageBox, SuccessMessageBox } from '../../../../../Components/Base/MessageBox';
import { Paragraph } from '../../../../../Components/Base/Text/Paragraph';
import { CelestialSelectOnly } from '../../../../../Components/FusionShift/Celestials/CelestialSelect';
import { SolarSystemLink } from '../../../../../Components/FusionShift/Links/SolarSystemLink';
import { FindSolarSystemByCoordinates } from '../../../../../Components/FusionShift/SolarSystems/FindSolarSystemByCoordinates';
import { ApocalypseHelper } from '../../../../../Helpers/ApocalpyseHelper';
import { CelestialHelper } from '../../../../../Helpers/CelestialHelper';
import { IconHelper } from '../../../../../Helpers/IconHelper';
import { ValueFormatter } from '../../../../../Helpers/ValueFormatter';

type Props = {
    solarSystem: ISolarSystemDetail,
    installation: Installation,
    installationType: InstallationType,
    celestialTypeSettings: ICelestialTypeSettings,
    gameSettings: IGameSettings
}

export const ArmApocalypseWeapon = (props: Props) => {

    const [isExpanded, setIsExpanded] = React.useState(false);
    const [targetSolarSystem, setTargetSolarSystem] = React.useState<ISolarSystemWithCelestials | undefined>(undefined);
    const [targetCelestialId, setTargetCelestialId] = React.useState<number | undefined>(undefined);
    const [response, setResponse] = React.useState<IRequestResponseOfArmResponse | undefined>(undefined);

    const apiState = React.useContext(ApiStateContext);

    const [armedDate, setArmedDate] = React.useState<Date | undefined>();

    React.useEffect(() => {

        const interval = setInterval(() => {

            if (targetSolarSystem !== undefined) {
                setArmedDate(ApocalypseHelper.armedDate(props.gameSettings, props.solarSystem, targetSolarSystem));
            } else {
                setArmedDate(undefined);
            }
        }, 1000);

        return () => clearInterval(interval);

    }, [targetSolarSystem])

    function arm() {
        setResponse(undefined);

        if (targetCelestialId !== undefined) {
            return apiState.InstallationClient.armApocalypseWeapon(props.solarSystem.solarSystemId, props.installation.celestialId, targetCelestialId).then(setResponse);
        }
    }

    if (props.installationType.levelToFireApocalypseWeapon === undefined || props.installationType.levelToFireApocalypseWeapon === null) {
        return null;
    }

    if (response !== undefined && response.wasSuccess) {
        return <SuccessMessageBox text={`Apocalypse weapon is now arming and will fire at ${ValueFormatter.formatDateAndFromNowWithMoment(response.result!.armedDate)}`} />;
    }

    const targetCelestial = targetSolarSystem?.celestials.find(x => x.celestialId === targetCelestialId);

    const firingDateText = armedDate !== undefined ? ValueFormatter.formatDateAndFromNowWithMoment(armedDate) : "";

    return <SubPanel
        heading={{ text: "Arm Apocalypse Weapon", icon: IconHelper.Celestials.Apocalypse }}
        headingContent={
            !isExpanded &&
            <Button
                type="nav"
                icon={IconHelper.General.toggleExpanded(isExpanded)}
                text={!isExpanded ? "Arm" : "Cancel"}
                action={() => setIsExpanded(!isExpanded)} isDisabled={props.installationType.levelToFireApocalypseWeapon > props.installation.level}
                isPulledRight />
        }
        footerContent={
            isExpanded &&
            <ButtonHolder isPulledRight>
                <Button type="nav" icon={IconHelper.General.Cancel} text={"Cancel"} action={() => setIsExpanded(!isExpanded)} />
                <Button type="action"
                    icon={IconHelper.Combat.Target}
                    text="Arm Apocalypse Weapon"
                    isPulledRight
                    action={arm}
                    isDisabled={targetCelestialId === undefined}
                />
            </ButtonHolder>
        }
    >
        <Paragraph>
            The Apocalypse Weapon can be armed at level {props.installationType.levelToFireApocalypseWeapon}.
        </Paragraph>
        {isExpanded && <SubPanel heading={{ text: "Find Target", icon: IconHelper.Combat.Target }}>
            <SplitLayout
                isRightEmpty={targetSolarSystem === undefined}
                left={<>
                    <FindSolarSystemByCoordinates
                        initialTarget={targetSolarSystem}
                        solarSystemFoundCallback={setTargetSolarSystem}
                    />
                    {targetSolarSystem !== undefined && <FieldHolder label={"Target Celestial"}>
                        <CelestialSelectOnly
                            celestialId={targetCelestialId}
                            setCelestialId={setTargetCelestialId}
                            celestials={targetSolarSystem.celestials}
                        />
                    </FieldHolder>}
                </>
                }
                right={targetSolarSystem !== undefined && <div className="content">
                    <ul>
                        <li>
                            Targetting: <SolarSystemLink
                                solarSystem={targetSolarSystem}
                                extra={targetCelestial !== undefined ? CelestialHelper.fullname(targetCelestial, props.celestialTypeSettings) : undefined}
                            />
                        </li>
                        <li>
                            Based on the distance to target, the apocalpyse weapon will fire at {firingDateText}
                        </li>
                        <li>
                            Arming the apocalypse weapon will reveal its location to the target
                        </li>
                        <li>
                            If you arm the weapon then you will become an exile
                        </li>
                        <li>
                            When the weapon fires all members of your federation will become exiles
                        </li>
                        <li>
                            The weapon will be reset to level 1 when it fires
                        </li>
                    </ul>
                </div>}
            />
            {response !== undefined && !response.wasSuccess &&
                <ErrorMessageBox text={response.error} />
            }
        </SubPanel>}
    </SubPanel>;
};