import React from "react";
import { InstallationCategory } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    installationCategory: InstallationCategory | undefined,
    isGiant?: boolean
}

export const InstallationCategoryIcon = (props: Props) => {

    if (props.installationCategory === undefined) {
        return null;
    }

    return <Icon title={InstallationCategory[props.installationCategory]} icon={IconHelper.Installations.installationCategory(props.installationCategory)} isGiant={props.isGiant} />;
};