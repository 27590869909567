import { observer } from "mobx-react-lite";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { FactionType, ShipType } from "../../../ApplicationState/ApiClient";
import { PlayerStateContext, WorldStateContext } from "../../../ApplicationState/ContextRoot";
import { SplitLayout } from "../../../Components/Base/Containers/SplitLayout";
import { SubPanel } from "../../../Components/Base/Containers/SubPanel";
import { TabMenu } from "../../../Components/Base/Containers/TabMenu";
import { LoadingSpinner } from "../../../Components/Base/Loading/LoadingSpinner";
import { Paragraph } from "../../../Components/Base/Text/Paragraph";
import { ShipTypeDescription } from "../../../Components/FusionShift/Descriptions/ShipTypeDescription";
import { UpkeepIcon } from "../../../Components/FusionShift/Icons/Items/UpkeepIcon";
import { ShipClassIcon } from "../../../Components/FusionShift/Icons/ShipClassIcon";
import { CostControl } from "../../../Components/FusionShift/Items/CostControl";
import { TypeDataVoice } from "../../../Components/FusionShift/TypeDataVoice";
import { IconHelper } from "../../../Helpers/IconHelper";
import { UrlHelper } from "../../../Helpers/UrlHelper";
import { help_page } from "../../../Navigation/Routing/Help";
import { TypeDataBaseView } from "./TypeDataBaseView";

type Props = {
    shipType: ShipType
}

const ShipTypeControl = (props: Props) => {

    return <SubPanel
        id={props.shipType.typeName}
        heading={{ text: props.shipType.name, icon: <ShipClassIcon shipClass={props.shipType.class} /> }}
        headingContent={<TypeDataVoice type="ShipType" typeData={props.shipType} />}
    >
        <ShipTypeDescription shipType={props.shipType} />
        <hr />
        <SplitLayout
            isLeftEmpty={!props.shipType.cost}
            isRightEmpty={!props.shipType.upkeepPerHour}
            left={props.shipType.cost &&
                <>
                    <Paragraph>Cost:</Paragraph>
                    <CostControl cost={props.shipType.cost} />
                </>
            }
            right={props.shipType.upkeepPerHour &&
                <>
                    <Paragraph>Upkeep:</Paragraph>
                    <UpkeepIcon quantity={props.shipType.upkeepPerHour} /> per hour
                </>
            }
        />
    </SubPanel>;
};

const ShipTypesContent = observer(() => {

    const playerState = React.useContext(PlayerStateContext);
    const worldState = React.useContext(WorldStateContext);

    const [factionTypeName, setFactionTypeName] = React.useState<string | undefined>(undefined);
    const [availableFactionTypes, setAvailableFactionTypes] = React.useState<FactionType[] | undefined>(undefined);

    const location = useLocation();

    if (!worldState.ShipTypeSettings || !worldState.FactionTypeSettings || !playerState.Player) {
        return <LoadingSpinner />;
    }

    const shipTypeToFind = UrlHelper.tryGetValueFromSearch(location, "shipTypeName");

    let factionTypesToUse: FactionType[] = [];
    const ships = Object.keys(worldState.ShipTypeSettings.data).map(k => {
        const shipType = worldState.ShipTypeSettings!.data![k];
        const factionType = worldState.FactionTypeSettings!.data![shipType.factionTypeName!];

        if (!factionTypeName && shipType.typeName === shipTypeToFind) {
            setFactionTypeName(shipType.factionTypeName);
        }

        if (!availableFactionTypes && !factionTypesToUse.includes(factionType)) {
            factionTypesToUse.push(factionType);
        }

        return shipType;
    }).sort(((a, b) => a.order > b.order ? 1 : -1));

    if (!availableFactionTypes && factionTypesToUse.length > 0) {
        setAvailableFactionTypes(factionTypesToUse);
        if (factionTypesToUse.findIndex(c => c.typeName === factionTypeName) < 0 && !shipTypeToFind) {

            if (playerState.Player) {
                setFactionTypeName(playerState.Player.factionTypeName);
            } else {
                setFactionTypeName(factionTypesToUse[0].typeName);
            }
        }
    }

    return <>
        <TabMenu
            active={factionTypeName}
            changeTab={(t: any) => setFactionTypeName(t)}
            tabs={availableFactionTypes ? availableFactionTypes.map(c => {
                return {
                    id: c.typeName,
                    title: c.name,
                    icons: null,
                    isAvailable: true
                };
            }) : []}
        />
        {ships.filter(i => i.factionTypeName === factionTypeName).map(i => <ShipTypeControl key={i.typeName} shipType={i} />)}
    </>;
});

export const ShipTypesView = () => {
    return <TypeDataBaseView title="Ships" icon={IconHelper.Ships.Ship}
        links={[
            { icon: IconHelper.Ships.Ship, to: help_page("ships"), text: "Ships" },
            { icon: IconHelper.Combat.Combat, to: help_page("combat"), text: "Combat" }
        ]}
    >
        <ShipTypesContent />
    </TypeDataBaseView>;
};