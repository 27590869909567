import React from "react";
import { PolicyCategory } from "../../../ApplicationState/ApiClient";
import { IconHelper } from "../../../Helpers/IconHelper";
import { Icon } from "../../Base/Icon";

type Props = {
    policyCategory: PolicyCategory,
    isGiant?: boolean
}

export const PolicyCategoryIcon = (props: Props) => {

    return <Icon title={PolicyCategory[props.policyCategory]} icon={IconHelper.Politics.policyCategory(props.policyCategory)} {...props} />;
};