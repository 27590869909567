import { HubConnection } from "@microsoft/signalr";
import { createMappings } from "../Notifications/NotificationMapper";
import { AppState } from "../States/AppState";

export function mapHub(hub: HubConnection, appState: AppState) {

    const mappings = createMappings(appState);
    const keys = Object.keys(mappings);

    for (let k of keys) {
        hub.on(k, args => mappings[k](args));
    }
};