import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FleetMovementType, ISolarSystemDetail } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { Button } from '../../../Components/Base/Buttons/Button';
import { ButtonHolder } from '../../../Components/Base/Buttons/ButtonHolder';
import { CancelButton } from '../../../Components/Base/Buttons/CancelButton';
import { SubPanel } from '../../../Components/Base/Containers/SubPanel';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';
import { FleetWrapper } from '../../../Entities/FleetWrapper';
import { CollectionHelper } from '../../../Helpers/CollectionHelper';
import { IconHelper } from '../../../Helpers/IconHelper';
import { ShipInputList, ShipTypeQuantity } from '../../SendShips/Components/ShipInputList';
import { GroundedPromptText } from '../Components/Prompts';
import { UpkeepSummary } from '../Components/UpkeepSummary';

type Props = {
    solarSystem: ISolarSystemDetail,
    ships: { [key: string]: number },
    groundShipsCallback: (ships: { [key: string]: number }) => any,
    closeCallback: () => any
}

export const GroundShipsForm = observer((props: Props) => {

    const [shipQuantities, setShipQuantities] = React.useState<{ [key: string]: number }>({});

    const [availableFleetWrapper, setAvailableFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);
    const [selectedFleetWrapper, setSelectedFleetWrapper] = React.useState<FleetWrapper | undefined>(undefined);

    const worldState = React.useContext(WorldStateContext);

    React.useEffect(() => {
        setAvailableFleetWrapper(undefined);
    }, [props.solarSystem]);

    React.useEffect(() => {
        if (!availableFleetWrapper && worldState.ShipTypeSettings && worldState.GameSettings) {
            setAvailableFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, props.ships));
        }
    }, [availableFleetWrapper, worldState.ShipTypeSettings, worldState.GameSettings, props.ships.ships])

    React.useEffect(() => {
        if (worldState.ShipTypeSettings && worldState.GameSettings) {
            setSelectedFleetWrapper(new FleetWrapper(worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities));
        }
    }, [worldState.ShipTypeSettings, worldState.GameSettings, shipQuantities]);

    function setShipQuantity(ship: ShipTypeQuantity, quantity: number) {
        if (quantity < 0) {
            quantity = 0;
        }
        if (quantity > ship.quantity) {
            quantity = ship.quantity;
        }
        const newObject = {
            ...shipQuantities
        };
        newObject[ship.ship.typeName || ""] = quantity;
        setShipQuantities(newObject);
    }

    if (!worldState.ShipTypeSettings || !worldState.ItemTypeSettings || !availableFleetWrapper || !selectedFleetWrapper) {
        return null;
    }

    const upkeepQuantitySaved = CollectionHelper.sumOfDictionaryValue(shipQuantities, (shipTypeName, quantity) => shipTypeName in worldState.ShipTypeSettings!.data ? worldState.ShipTypeSettings!.data[shipTypeName].upkeepPerHour * quantity : 0);

    const anySelected = CollectionHelper.isAnyQuantityInDictionary(shipQuantities);

    return <SubPanel heading={{ text: "Ground Ships", icon: IconHelper.Ships.Grounded }}
        footerContent={<ButtonHolder isPulledRight>
            <CancelButton action={props.closeCallback} />
            <Button
                type="action"
                icon={IconHelper.Ships.Grounded}
                text="Ground ships"
                action={() => props.groundShipsCallback(shipQuantities)}
                isDisabled={!anySelected}
            />
        </ButtonHolder>}>
        <Paragraph type="prompt">Choose which ships to ground.</Paragraph>
        <GroundedPromptText />
        <ShipInputList
            shipTypeSettings={worldState.ShipTypeSettings}
            availableShips={availableFleetWrapper}
            selectedShips={selectedFleetWrapper}
            movementType={FleetMovementType.ReturnHome}
            setShipQuantity={(s, q) => setShipQuantity(s, q)}
            setShipTactic={(s, t) => { }}
            shipQuantities={shipQuantities}
            showTactics={false}
            showUpkeep
            tactics={{}}
        />
        {upkeepQuantitySaved > 0 && <UpkeepSummary
            key="activate-ships-form"
            additionalUpkeep={-upkeepQuantitySaved}
            additionalUpkeepIcon={IconHelper.Ships.Grounded}
            additionalUpkeepLabel="Grounded Ships"
            items={props.solarSystem.items}
            itemsDetail={props.solarSystem.itemsDetail}
            itemTypeSettings={worldState.ItemTypeSettings}
        />}
    </SubPanel>
});