import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ICelestial } from '../../../ApplicationState/ApiClient';
import { WorldStateContext } from '../../../ApplicationState/ContextRoot';
import { BarBlock } from '../../Base/BarBlock';
import { ItemTypeLink } from '../Links/Items/ItemTypeLink';

type Props = {
    className?: string
    celestial: ICelestial
}

export const CelestialResourceBar = observer((props: Props) => {

    const worldState = React.useContext(WorldStateContext);

    if (!worldState.CelestialTypeSettings || props.celestial.celestialTypeName === undefined) {
        return null;
    }

    const celestialType = props.celestial.celestialTypeName in worldState.CelestialTypeSettings!.data ? worldState.CelestialTypeSettings!.data[props.celestial.celestialTypeName] : undefined;
    const itemType = celestialType !== undefined && celestialType.generatedItemTypeName in worldState.ItemTypeSettings!.data ? worldState.ItemTypeSettings!.data[celestialType.generatedItemTypeName] : undefined;

    if (itemType === undefined) {
        return null;
    }

    return <BarBlock
        className={props.className}
        isWarning={props.celestial.resources < props.celestial.resourceCapacity / 2}
        isDanger={props.celestial.resources < props.celestial.resourceCapacity / 4}
        leftIcon={<ItemTypeLink itemType={itemType} />}
        value={props.celestial.resources}
        total={props.celestial.resourceCapacity}
    />;
});