import React from "react";

type Props = {
    quantity: number,
    capacity: number | undefined
}

export const CapacityBar = (props: Props) => {

    const percent = props.capacity && Math.min((props.quantity / props.capacity) * 100, 100);

    return <div className="capacity-bar">
        {percent !== undefined && <div className="capacity-percent" style={{
            width: `${percent}%`
        }} />}
    </div>;
};
