import * as React from 'react';
import { Paragraph } from '../../../Components/Base/Text/Paragraph';

export const GroundedPromptText = () => {

    return <Paragraph type="prompt">
        Grounded ships cost no upkeep and are unable to defend but are still vulnerable in combat if the system is attacked.
    </Paragraph>
};

export const ActivatingPromptText = () => {

    return <Paragraph type="prompt">
        Ships will take time to activate during which time they incur an upkeep cost. Activating ships are unable to participate in the defence of the solar system but will still be vulnerable to attack.
    </Paragraph>;
};