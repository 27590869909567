import { CombatReportStatus, CombatRoundType } from "../../ApplicationState/ApiClient";
import { MaterialDesignIcons } from "./MaterialDesignIcons";

export class CombatIcons {

    public get Simulation() { return MaterialDesignIcons.applicationCog };
    public get Combat() { return MaterialDesignIcons.sword };
    public get CombatReport() { return MaterialDesignIcons.sword };
    public get Attack() { return MaterialDesignIcons.sword };
    public get Defence() { return MaterialDesignIcons.shield };
    public get Raid() { return MaterialDesignIcons.axe };
    public get Siege() { return MaterialDesignIcons.bomb };
    public get Recon() { return MaterialDesignIcons.binoculars };
    public get Murder() { return MaterialDesignIcons.skullAndCrossBones };

    public get Intercept() { return MaterialDesignIcons.intercept; }
    public get Tactics() { return MaterialDesignIcons.tactics; }

    public get Defeated() { return MaterialDesignIcons.skullAndCrossBones; }

    public get Target() { return MaterialDesignIcons.target; };

    public combatRoundType(combatRoundType: CombatRoundType) {
        switch (combatRoundType) {
            case CombatRoundType.First:
                return MaterialDesignIcons.roman1;
            case CombatRoundType.Middle:
                return MaterialDesignIcons.roman2;
            case CombatRoundType.Last:
                return MaterialDesignIcons.roman3;
            case CombatRoundType.Grounded:
                return MaterialDesignIcons.roman4;
        }
    }

    public get AllCombatReports() { return "expand-all"; }

    public combatReportStatus(combatReportStatus: CombatReportStatus) {
        switch (combatReportStatus) {
            case CombatReportStatus.Archived:
                return MaterialDesignIcons.lockTick;
            case CombatReportStatus.Private:
                return MaterialDesignIcons.lock;
            case CombatReportStatus.Public:
                return MaterialDesignIcons.lockOpen;
        }
    }
}