import { SolarSystemWrapper } from "../../../Entities/SolarSystem/SolarSystemWrapper"
import { MathHelper } from "../../../Helpers/MathHelper"

export type SolarSystem = {
    index: number,
    solarSystem: SolarSystemWrapper,
    position: { x: number, y: number }
}

export type Fleet = {
    sourceSolarSystem: SolarSystem,
    targetSolarSystem: SolarSystem,
    itemTypeNames: string[]
}

export type Line = {
    x1: number,
    x2: number,
    y1: number,
    y2: number,
    className: string
}


export const availableSpace = 740;
export const middle = point(availableSpace / 2, availableSpace / 2);
export const extrusionRatio = 0.85;
export const extrusionFromMiddle = (availableSpace / 2) * extrusionRatio;

export function circleAngle(index: number, count: number) {
    const circle = (Math.PI * 2);

    return circle * (index / count);
}

export function point(x: number, y: number) {
    return {
        x, y
    };
}

export function solarSystemPositionToRenderPosition(a: { x: number, y: number }) {
    return { x: a.x + middle.x, y: a.y + middle.y };
}

export function solarSystemPosition(solarSystem: SolarSystem) {
    return solarSystem.position;
}

export function createSolarSystem(x: SolarSystemWrapper, i: number, total: number): SolarSystem {
    const angle = circleAngle(i, total);
    const rotated = MathHelper.rotatePoint(0, 0, angle, 0, -1);
    const position = point((extrusionFromMiddle * rotated.x), (extrusionFromMiddle * rotated.y));

    return {
        solarSystem: x,
        index: i,
        position
    };
}
